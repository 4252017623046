// @flow strict

import { fromJS } from 'immutable';

// Helpers
import { getKPISettingRelatedName, getKPISettingUnit, getRoundedValue } from 'utils/kpiHelpers';

// Constants
import { RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION } from 'utils/recommendationConstants';

// Types
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type {
    ImmutableRecommendation,
    ImmutableRecommendationSet,
    ImmutableRecommendationSetMessage,
    ImmutableRecommendationItem,
} from 'services/Recommendation/types';
import type { IntlType, ImmutableList } from 'types';

/**
 * Get all recommendations from recommendation set
 */
export const getAllRecommendations = (
    recommendationSet: ImmutableRecommendationSet
): ImmutableList<ImmutableRecommendation> => recommendationSet.get('recommendations');

/**
 * Get all recommendations from recommendation set
 */
export const hasSubmittedFeedback = (recommendation: ImmutableRecommendation): boolean =>
    Boolean(recommendation.get('recommendationFeedback'));

/**
 * Get recommendations that were included in submission
 */
export const getIncludedRecommendations = (
    recommendationSet: ImmutableRecommendationSet
): ImmutableList<ImmutableRecommendation> =>
    recommendationSet
        .get('recommendations')
        .filter((recommendation: ImmutableRecommendation) =>
            recommendation.get('includeInSubmission')
        );

/**
 * Return sorted recommendations that are included in submission of recommendation list
 */
export const getSortedRecommendations = (
    recommendationSet: ImmutableRecommendationSet,
    onlyIncludedInSubmission: boolean
) => {
    let recommendations = [];
    if (onlyIncludedInSubmission) {
        recommendations = getIncludedRecommendations(recommendationSet);
    } else {
        recommendations = getAllRecommendations(recommendationSet);
    }
    return recommendations.sort(
        (r1: ImmutableRecommendation, r2: ImmutableRecommendation) =>
            r1.get('order') - r2.get('order')
    );
};

/**
 * Get number of recommendation set messages
 */
export const getRecommendationSetMessageCount = (
    recommendationSet: ImmutableRecommendationSet
): number => (recommendationSet ? recommendationSet.get('recommendationSetMessages').size : 0);

/**
 * Get number of recommendation set messages
 */
export const getRecommendationCount = (recommendationSet: ImmutableRecommendationSet): number =>
    recommendationSet ? recommendationSet.getIn(['recommendations'], fromJS([])).size : 0;

/**
 * Get recommendation set messages
 */
export const getRecommendationSetMessage = (
    rsm: ImmutableRecommendationSetMessage,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    circuit: ImmutableCircuit,
    intl: IntlType
): ImmutableRecommendationSetMessage => {
    const messageType = rsm.get('messageType');
    if (!messageType) {
        return rsm.get('comment');
    }

    const kpiId = rsm.get('kpiId');
    let relatedName = null;
    let kpiName = null;

    if (kpiId) {
        const kpi = kpiSettings.find(
            (kpiSetting: ImmutableKPISetting) => kpiSetting.get('id') === kpiId
        );
        if (!kpi) {
            throw new Error(
                'Recommendation set display message using KPI Id was not found in KpiSettings'
            );
        }
        relatedName = getKPISettingRelatedName(kpi, circuit);
        kpiName = kpi.get('name');
    }

    return intl.formatMessage(
        {
            id: `constants.DecisionTreeMessageType.${messageType}`,
        },
        {
            kpiName,
            relatedName,
        }
    );
};

/**
 * Get recommendation set messages
 */
export const getRecommendationSetMessages = (
    recommendationSet: ImmutableRecommendationSet,
    kpiSettings: ?ImmutableList<ImmutableKPISetting>,
    circuit: ?ImmutableCircuit,
    intl: IntlType
): ImmutableList<ImmutableRecommendationSetMessage> =>
    recommendationSet
        .get('recommendationSetMessages')
        .map((rsm: ImmutableRecommendationSetMessage) =>
            fromJS({
                id: rsm.get('id'),
                value: getRecommendationSetMessage(rsm, kpiSettings, circuit, intl),
            })
        );

/**
 * Get formatted submitted by SAM name
 */
export const getSubmittedBy = (
    recommendationSet: ?ImmutableRecommendationSet,
    intl: IntlType
): string =>
    (recommendationSet &&
        recommendationSet.get('sentByName') &&
        intl.formatMessage(
            {
                id: `helpers.recommendations.submittedBy`,
            },
            { samName: recommendationSet.get('sentByName') }
        )) ||
    intl.formatMessage({ id: `helpers.recommendations.unknownSAMName` });

const getKpiSettingById = (kpiSettings: ImmutableList<ImmutableKPISetting>, id: number) =>
    kpiSettings.find((kpi: ImmutableKPISetting) => kpi.get('id') === id);

export const getRecommendationItemFromRecommendationType = (
    item: ImmutableRecommendationItem,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    circuit: ImmutableCircuit,
    intl: IntlType
) => {
    const type = item.get('recommendationType');
    if (!type) {
        return null;
    }

    const toKpi = getKpiSettingById(kpiSettings, item.get('toKpiId'));
    const fromKpi = getKpiSettingById(kpiSettings, item.get('fromKpiId'));

    const toKpiName = toKpi && toKpi.get('name');
    const fromKpiName = fromKpi && fromKpi.get('name');

    const toStageCode = toKpi && getKPISettingRelatedName(toKpi, circuit);
    const fromStageCode = fromKpi && getKPISettingRelatedName(fromKpi, circuit);

    const toKpiUnit = toKpi && getKPISettingUnit(toKpi, intl);
    const fromKpiUnit = fromKpi && getKPISettingUnit(fromKpi, intl);

    let relatedKpi = fromKpi;
    // In the case of a recommendation type for impact calcs, the related value is the delta, which means the
    // related KPI is the to KPI.
    if (RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION.indexOf(type) !== -1) {
        if (!toKpi || item.get('setValueTo') === null) {
            return null;
        }
        relatedKpi = toKpi;
    }
    const value = getRoundedValue(item.get('setValueTo'), toKpi);
    const relatedValue = getRoundedValue(item.get('relatedValue'), relatedKpi);

    return intl.formatMessage(
        {
            id: `constants.RecommendationTypes.${type}`,
        },
        {
            toKpiName,
            fromKpiName,
            value,
            relatedValue,
            toStageCode,
            fromStageCode,
            toKpiUnit,
            fromKpiUnit,
        }
    );
};
