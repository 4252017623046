// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const PredictCreateIsothermBody = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionWrapper = styled.div`
    padding: 0 26px;

    ${({ topPadding }) => topPadding && 'padding-top: 26px;'} ${({ bottomPadding }) =>
        bottomPadding && 'padding-bottom: 26px;'}

    & + & {
        padding-top: 26px;
    }
`;

export const HeaderTitle = styled.h2`
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    line-height: 24px;
`;

export const HeaderRadioWrapper = styled.div`
    margin-bottom: 24px;
`;

export const HeaderTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 0;
    align-items: center;

    cursor: pointer;
    color: ${LegacyTheme.black};

    :hover {
        color: ${LegacyTheme.defaultColor};
    }
`;

export const StickyFooterSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: ${LegacyTheme.sidebarLayoutSidebar};

    width: 100%;
    padding: 8px 26px;
    border-top: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
`;

export const IsothermTypeRadioStyles = {
    fontSize: '15px',
    lineHeight: '18px',
    marginRight: '39.5px',
};

export const InputNote = styled.div`
    font-size: 11px;
    line-height: 18px;
    margin-right: 39.5px;
    color: ${LegacyTheme.defaultSubtleColor};
`;
