// @flow strict

/*
 *
 * Plant reducer
 *
 */

import { fromJS } from 'immutable';

import type { GenericActionType, ImmutableList } from 'types';
import { deleteEntityFromSubStateLists, setEntityIntoSubStateLists } from '../utils';

import {
    CREATE_SUCCESS,
    CREATE_FAILURE,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DESTROY_SUCCESS,
    DESTROY_FAILURE,
    QUERY_LIST_SUCCESS,
    QUERY_LIST_FAILURE,
    DASHBOARD_PLANTS_LIST_SUCCESS,
    DASHBOARD_PLANTS_LIST_FAILURE,
    DOWNLOAD_TEMPLATE_SUCCESS,
    DOWNLOAD_TEMPLATE_FAILURE,
    IS_FETCHING,
    IS_UPDATING,
    IS_DELETING,
    IS_CREATING,
    IS_QUERYING,
    IS_QUERYING_DASHBOARD_PLANTS,
    IS_DOWNLOADING_TEMPLATE,
} from './actions';
import { IS_COMPUTING } from '../PlantDataset/actions';
import { FETCH_PLANT_TREND_SUCCESS } from 'services/Trends/actions';

import type { ImmutablePlantState } from 'services/Plant/types';

const initialState: ImmutablePlantState = fromJS({
    plants: [],
    dashboardPlants: {
        data: [],
        currentPage: 0,
        lastPage: 0,
    },
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isQuerying: false,
    isQueryingDashboardPlants: false,
    isDownloadingTemplate: false,
    isComputing: false, // when a user is recomputing a solvextract plant.
    query: {
        data: [],
        lastPage: 0,
    },
    errors: {},
});

function plantServiceReducer(state: ImmutablePlantState = initialState, action: GenericActionType) {
    switch (action.type) {
        case IS_COMPUTING:
            return state.set('isComputing', action.payload.isComputing);
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);
        case IS_DELETING:
            return state.set('isDeleting', action.payload.isDeleting);
        case IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);
        case IS_QUERYING:
            return state.set('isQuerying', action.payload.isQuerying);
        case IS_QUERYING_DASHBOARD_PLANTS:
            return state.set('isQueryingDashboardPlants', action.payload.isQueryingDashboardPlants);
        case IS_DOWNLOADING_TEMPLATE:
            return state.set('isDownloadingTemplate', action.payload.isDownloadingTemplate);

        case CREATE_SUCCESS: {
            const plant = fromJS(action.payload.data);

            const newState = setEntityIntoSubStateLists(state, 'plants', plant);
            return newState.set('errors', fromJS({})).set('isCreating', false);
        }
        case CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);

        case QUERY_LIST_SUCCESS: {
            const data = action.payload.data;

            return state
                .set('query', fromJS(data || {}))
                .set('errors', fromJS({}))
                .set('isQuerying', false);
        }
        case QUERY_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isQuerying', false);

        case DASHBOARD_PLANTS_LIST_SUCCESS: {
            const data = action.payload.data;

            return state
                .set('dashboardPlants', fromJS(data || {}))
                .set('errors', fromJS({}))
                .set('isQueryingDashboardPlants', false);
        }
        case DASHBOARD_PLANTS_LIST_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isQueryingDashboardPlants', false);

        case FETCH_LIST_SUCCESS: {
            const plants = action.payload.data;

            return state
                .set('plants', fromJS(plants || []))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        }
        case FETCH_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isFetching', false);

        case UPDATE_SUCCESS: {
            const plant = fromJS(action.payload.data);

            const newState = setEntityIntoSubStateLists(state, 'plants', plant);
            return newState.set('errors', fromJS({})).set('isUpdating', false);
        }
        case UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isUpdating', false);

        case DESTROY_SUCCESS: {
            const plant = fromJS(action.payload.data);

            const newState = deleteEntityFromSubStateLists(state, 'plants', plant);
            return newState.set('errors', fromJS({})).set('isDeleting', false);
        }
        case DESTROY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isDeleting', false);

        case FETCH_PLANT_TREND_SUCCESS: {
            const plant = fromJS(action.payload.data.plant);
            const newState = setEntityIntoSubStateLists(state, 'plants', plant);
            return newState;
        }
        case DOWNLOAD_TEMPLATE_SUCCESS: {
            const url = window.URL.createObjectURL(
                new Blob([action.payload.blob], {
                    type: 'text/json;charset=utf-8',
                })
            );
            const a = document.createElement('a');
            a.href = url;
            a.download = `${action.payload.fileName}.json`;
            a.click();
            window.URL.revokeObjectURL(url);
            return state.set('isDownloadingTemplate', false);
        }
        case DOWNLOAD_TEMPLATE_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isDownloadingTemplate', false);
        }

        default:
            return state;
    }
}

export default plantServiceReducer;
