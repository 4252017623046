// @flow strict

import styled from 'styled-components';

// Components
import { LegacyTheme } from 'components/_ReactUI_V1';

// Constants
import { STYLE_VALUES } from 'utils/constants';

export const StepBlock = styled.div`
    margin-bottom: ${STYLE_VALUES.GUTTERS.NORMAL};
    padding-bottom: ${STYLE_VALUES.GUTTERS.LARGE};

    border-bottom: 1px solid ${LegacyTheme.defaultBorder};

    :last-child {
        margin-bottom: 0;
        border: 0 none;
    }
`;
