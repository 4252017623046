// @noflow

import styled from 'styled-components';

// Utils
import { themePropertyFetcher } from '../tools/helpers';

export const StyledColumn = styled.div`
    display: flex;
    flex: ${({ flex, theme }: Object) =>
        typeof flex !== 'undefined' ? flex : themePropertyFetcher(theme, ['column', 'flex'])};
    align-content ${({ alignContent, theme }: Object) =>
        alignContent || themePropertyFetcher(theme, ['column', 'alignContent'])};
    align-items ${({ alignItems, theme }: Object) =>
        alignItems || themePropertyFetcher(theme, ['column', 'alignItems'])};
    justify-content ${({ justifyContent, theme }: Object) =>
        justifyContent || themePropertyFetcher(theme, ['column', 'justifyContent'])};
    flex-direction: column;
`;

export const StyledRow = styled.div`
    display: flex;
    flex: ${({ flex, theme }: Object) => flex || themePropertyFetcher(theme, ['row', 'flex'])};
    align-content ${({ alignContent, theme }: Object) =>
        alignContent || themePropertyFetcher(theme, ['row', 'alignContent'])};
    align-items ${({ alignItems, theme }: Object) =>
        alignItems || themePropertyFetcher(theme, ['row', 'alignItems'])};
    justify-content ${({ justifyContent, theme }: Object) =>
        justifyContent || themePropertyFetcher(theme, ['row', 'justifyContent'])};
    flex-direction: row;

    ${({ gutter, theme }: Object) => `
        > ${StyledColumn} {
            padding: 0 ${(gutter || themePropertyFetcher(theme, ['row', 'gutter'])) / 2}px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    `};

    ${({ padded, theme }: Object) =>
        padded &&
        `
            > ${StyledColumn} {
                padding: ${themePropertyFetcher(theme, ['row', 'column', 'padding'])};
            }
    `}

    width: 100%;

    margin: 10px auto;

    -webkit-margin-collapse: separate;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledCentered = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    > div {
        text-align: center;
    }

    ${({ style }: Object) => style};
`;

export const StyledBlankButton = styled.button`
    padding: 0;

    appearance: none;
    background-color: transparent;
    border: 0 none;

    &:focus {
        outline: none;
    }

    cursor: ${({ onClick }: Object) => (onClick ? 'pointer' : 'default')};
`;

export const StyledOverflowContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    height: 100%;
`;

export const StyledOverflowBody = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
`;

export const StyledOverflowEnd = styled.div`
    position: relative;
    display: flex;
    flex: 0;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
`;
