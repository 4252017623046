// @flow

import styled, { css } from 'styled-components';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

const ButtonBase = css`
    width: ${({ inline }: Object) => (inline ? 'auto' : '100%')};
    min-width: ${({ theme }: Object) => themePropertyFetcher(theme, ['buttons', 'minWidth'])};
    height: ${({ theme }: Object) => themePropertyFetcher(theme, ['buttons', 'height'])};
    padding: ${({ borderRadius, theme }: Object) => {
        let value = themePropertyFetcher(theme, ['buttons', 'padding']);
        // TODO: Figure out clean way to add this logic
        if (parseInt(borderRadius, 10) > 10) {
            value = themePropertyFetcher(theme, ['buttons', 'paddingForLargeRadius']) || value;
        }
        return value;
    }}; // Larger border radi deserve more padding

    font-size: ${({ theme }: Object) => themePropertyFetcher(theme, ['buttons', 'fontSize'])};
    letter-spacing: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['buttons', 'letterSpacing'])};
    text-align: center;
    font-weight: ${({ theme }: Object) => themePropertyFetcher(theme, ['buttons', 'fontWeight'])};

    border: 0;
    border-radius: ${({ borderRadius, theme }: Object) =>
        borderRadius || themePropertyFetcher(theme, ['buttons', 'borderRadius'])};

    cursor: ${({ disabled }: Object) => (disabled ? 'not-allowed' : 'pointer')};
    transition: all ease 0.25s;

    white-space: nowrap;

    &:focus {
        transition: none;
        outline: none;
        border-radius: ${({ borderRadius, theme }: Object) =>
            borderRadius || themePropertyFetcher(theme, ['buttons', 'borderRadius'])};
        box-shadow: 0 0 4px 1px
            ${({ buttonPrimaryFocus, theme }: Object) =>
                buttonPrimaryFocus ||
                themePropertyFetcher(theme, ['buttons', 'primary', 'buttonPrimaryFocus'])};
    }
`;

const ButtonDisabled = css`
    ${({ disabled, theme, loading }: Object) =>
        disabled && !loading
            ? `
        background-color: ${themePropertyFetcher(theme, [
            'buttons',
            'disabled',
            'backgroundColor',
        ])};
        color: ${themePropertyFetcher(theme, ['buttons', 'disabled', 'color'])};
    `
            : ``}
`;

export const StyledPrimaryButton = styled.button`
    ${ButtonBase}

    background-color: ${({ backgroundColor, theme }: Object) =>
        backgroundColor || themePropertyFetcher(theme, ['buttons', 'primary', 'backgroundColor'])};
    color: ${({ textColor, theme }: Object) =>
        textColor || themePropertyFetcher(theme, ['buttons', 'primary', 'color'])};

    ${ButtonDisabled}

    ${({ disabled, hoverBackgroundColor, hoverTextColor, loading, theme }: Object) =>
        !(disabled || loading)
            ? `
        &:hover {
            background-color: ${hoverBackgroundColor ||
                themePropertyFetcher(theme, ['buttons', 'primary', 'backgroundColorHover'])};
            color: ${hoverTextColor ||
                themePropertyFetcher(theme, ['buttons', 'primary', 'colorHover'])};
        }
    `
            : ``}
`;

export const StyledSecondaryButton = styled.button`
    ${ButtonBase}

    background-color: ${({ backgroundColor, theme }: Object) =>
        backgroundColor ||
        themePropertyFetcher(theme, ['buttons', 'secondary', 'backgroundColor'])};
    color: ${({ textColor, theme }: Object) =>
        textColor || themePropertyFetcher(theme, ['buttons', 'secondary', 'color'])};

    ${ButtonDisabled}

    ${({ disabled, hoverBackgroundColor, hoverTextColor, loading, theme }: Object) =>
        !(disabled || loading)
            ? `
        &:hover {
            background-color: ${hoverBackgroundColor ||
                themePropertyFetcher(theme, ['buttons', 'secondary', 'backgroundColorHover'])};
            color: ${hoverTextColor ||
                themePropertyFetcher(theme, ['buttons', 'secondary', 'colorHover'])};
        }
    `
            : ``}
`;

export const StyledTertiaryButton = styled.button`
    ${ButtonBase}

    background-color: ${({ backgroundColor, theme }: Object) =>
        backgroundColor || themePropertyFetcher(theme, ['buttons', 'tertiary', 'backgroundColor'])};
    color: ${({ textColor, theme }: Object) =>
        textColor || themePropertyFetcher(theme, ['buttons', 'tertiary', 'color'])};

    ${({ disabled, hoverBackgroundColor, hoverTextColor, loading, theme }: Object) =>
        !(disabled || loading)
            ? `
        &:hover {
            background-color: ${hoverBackgroundColor ||
                themePropertyFetcher(theme, ['buttons', 'tertiary', 'backgroundColorHover'])};
            color: ${hoverTextColor ||
                themePropertyFetcher(theme, ['buttons', 'tertiary', 'colorHover'])}
        }
    `
            : ``}
`;

export const StyledWarningButton = styled.button`
    ${ButtonBase}

    background-color: ${({ backgroundColor, theme }: Object) =>
        backgroundColor || themePropertyFetcher(theme, ['buttons', 'warning', 'backgroundColor'])};
    color: ${({ textColor, theme }: Object) =>
        textColor || themePropertyFetcher(theme, ['buttons', 'warning', 'color'])};

    ${({ disabled, hoverBackgroundColor, hoverTextColor, loading, theme }: Object) =>
        !(disabled || loading)
            ? `
        &:hover {
            background-color: ${hoverBackgroundColor ||
                themePropertyFetcher(theme, ['buttons', 'warning', 'backgroundColorHover'])};
            color: ${hoverTextColor ||
                themePropertyFetcher(theme, ['buttons', 'warning', 'colorHover'])};
        }
    `
            : ``}
`;

export const StyledInverseButton = styled.button`
    ${ButtonBase}

    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['buttons', 'inverse', 'backgroundColor'])};
    border: ${({ borderColor, theme }: Object) => {
        const borderWidth = themePropertyFetcher(theme, ['buttons', 'inverse', 'borderWidth']);
        const borderColorValue =
            borderColor || themePropertyFetcher(theme, ['buttons', 'inverse', 'borderColor']);
        return borderWidth && borderColorValue && `${borderWidth} solid ${borderColorValue}`;
    }};
    color: ${({ textColor, theme }: Object) =>
        textColor || themePropertyFetcher(theme, ['buttons', 'inverse', 'color'])};

    ${({
        disabled,
        hoverBackgroundColor,
        hoverBorderColor,
        hoverTextColor,
        loading,
        theme,
    }: Object) =>
        !(disabled || loading)
            ? `
        &:hover {
            background-color: ${hoverBackgroundColor ||
                themePropertyFetcher(theme, ['buttons', 'inverse', 'backgroundColorHover'])};
            border-color: ${hoverBorderColor ||
                themePropertyFetcher(theme, ['buttons', 'inverse', 'borderColorHover'])};
            color: ${hoverTextColor ||
                themePropertyFetcher(theme, ['buttons', 'inverse', 'colorHover'])};
        }
    `
            : ``}
`;

export const StyledButtonWrapper = styled.div`
    white-space: ${({ nowrap }: Object) => (nowrap ? 'nowrap' : 'normal')};
    text-align: ${({ right }: Object) => (right ? 'right' : 'left')};

    button,
    a {
        + button,
        + a {
            margin-left: ${({ gutter, theme }: Object) =>
                gutter || themePropertyFetcher(theme, ['buttons', 'buttonWrapper', 'gutter'])};
        }
    }
`;
