// @flow

const colors = {
    white: '#FFFFFF',
    black: '#000000',
    black05: `rgba(0,0,0,0.05)`,
    transparent: 'transparent',

    greyFC: '#FCFCFC',
    greyFA: '#FAFAFA',
    greyF2: '#F2F2F2',
    greyE9: '#E9E9E9',
    greyE6: '#E6E6E6',
    greyDD: '#DDDDDD',
    greyC6: '#C6C7C8',
    greyA6: '#A6A8A9',
    grey9C: '#9C9E9F',
    grey9B: `#9B9B9B`,
    grey7B: '#7B7C7E',
    grey4A: '#4A4A4A',
    grey33: '#333333',
    grey27: '#272727',
    grey1A: '#1A1A1A',

    blueEBF: '#EBF8FF',
    blue009: '#009EE0',
    blue007: '#0079BA',
    blueC2D: '#C2D9E5',
    blueBEE: '#BEE1F4',

    orangeF5A: '#F5A623',

    purple763: '#763689',
    purple946: '#9464A3',
    purpleB19: '#B197C3',

    redFA4: '#FA4620',
    redE20: '#E20031',
    redDE0: '#DE002B',
    redB50: '#B50027',

    green6AB: '#6AB023',
    green007: '#007F33',

    pinkE3: '#E3006E',
};

export default colors;
