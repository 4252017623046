// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { MicroFrontEnds } from '../../microfrontendURIs';

import MicroFrontend from '..';
import { ContainerCentered, LayoutContainer } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';

import type { IntlType } from 'types';

import type { ImmutableUser } from 'services/Authentication/types';
import { selectUser } from 'services/Authentication/selectors';
import { isSysAdmin } from 'utils/authentication';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    application: 'CYANEX' | 'MINCHEM',
};

type State = {
    isUnLoadable: boolean,
};

class ReagentsManagement extends React.PureComponent<Props, State> {
    state = {
        isUnLoadable: false,
    };

    static getHost = () => {
        return MicroFrontEnds.reagentsManagement; // name must match the secret object's structure in GCP
    };

    static getUniqueName = () => {
        return 'ReagentsManagementMFE'; // name must match the container name in the MFE code
    };

    getMountProps = () => {
        return {
            application: this.props.application,
            locale: this.props.intl.locale.toUpperCase(),
        };
    };

    onError = () => {
        this.setState({ isUnLoadable: true });
    };

    render() {
        const isAdmin = isSysAdmin(this.props.user);
        if (!isAdmin) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: 'views.ReagentsManagementMFE.userMustBeAdmin',
                        })}
                    />
                </ContainerCentered>
            );
        }
        if (this.state.isUnLoadable) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: 'views.ReagentsManagementMFE.anErrorOccurredDuringLoad',
                        })}
                    />
                </ContainerCentered>
            );
        }
        return (
            <MicroFrontend
                name={ReagentsManagement.getUniqueName()}
                host={ReagentsManagement.getHost()}
                mountedMicroFrontendProps={this.getMountProps()}
                onError={this.onError}
                // the legacy sidebar means this app is "not" in full screen
                // because the sidebar layout component already removes the header's 61px height
                mfeIsInFullScreen={false}
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
    });

export default connect(mapStateToProps)(injectIntl(ReagentsManagement));
