// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type {
    ImmutableDataset,
    ImmutableTwoDAnalysis,
    ImmutableThreeDAnalysis,
    LooseDatasetValue,
    LooseStageValue,
    LooseStreamValue,
} from 'services/Dataset/types';

export const CREATE_SUCCESS = 'app/services/Dataset/CREATE_SUCCESS';
export const CREATE_FAILURE = 'app/services/Dataset/CREATE_FAILURE';
// export const FETCH_LIST_SUCCESS = 'app/services/Dataset/FETCH_LIST_SUCCESS';
// export const FETCH_LIST_FAILURE = 'app/services/Dataset/FETCH_LIST_FAILURE';
export const FETCH_SUCCESS = 'app/services/Dataset/FETCH_SUCCESS';
export const FETCH_FAILURE = 'app/services/Dataset/FETCH_FAILURE';
export const UPDATE_SUCCESS = 'app/services/Dataset/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Dataset/UPDATE_FAILURE';
export const DESTROY_SUCCESS = 'app/services/Dataset/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'app/services/Dataset/DESTROY_FAILURE';
export const COMPUTE_SUCCESS = 'app/services/Dataset/COMPUTE_SUCCESS';
export const COMPUTE_FAILURE = 'app/services/Dataset/COMPUTE_FAILURE';
export const COMPUTE_2D_SUCCESS = 'app/services/Dataset/COMPUTE_2D_SUCCESS';
export const COMPUTE_2D_FAILURE = 'app/services/Dataset/COMPUTE_2D_FAILURE';
export const COMPUTE_2D_DATASET_SUCCESS = 'app/services/Dataset/COMPUTE_2D_DATASET_SUCCESS';
export const COMPUTE_2D_DATASET_FAILURE = 'app/services/Dataset/COMPUTE_2D_DATASET_FAILURE';
export const EXPORT_2D_SENSITIVITY_CSV_SUCCESS =
    'app/services/Dataset/EXPORT_2D_SENSITIVITY_CSV_SUCCESS';
export const EXPORT_2D_SENSITIVITY_CSV_FAILURE =
    'app/services/Dataset/EXPORT_2D_SENSITIVITY_CSV_FAILURE';
export const IS_EXPORTING = 'app/services/Dataset/IS_EXPORTING';
export const COMPUTE_3D_SUCCESS = 'app/services/Dataset/COMPUTE_3D_SUCCESS';
export const COMPUTE_3D_FAILURE = 'app/services/Dataset/COMPUTE_3D_FAILURE';
export const IS_FETCHING = 'app/services/Dataset/IS_FETCHING';
export const IS_UPDATING = 'app/services/Dataset/IS_UPDATING';
export const IS_DELETING = 'app/services/Dataset/IS_DELETING';
export const IS_CREATING = 'app/services/Dataset/IS_CREATING';
export const IS_COMPUTING = 'app/services/Dataset/IS_COMPUTING';
export const IS_COMPUTING_2D = 'app/services/Dataset/IS_COMPUTING_2D';
export const IS_COMPUTING_3D = 'app/services/Dataset/IS_COMPUTING_3D';
export const IS_COMPUTING_2D_DATASET = 'app/services/Dataset/IS_COMPUTING_2D_DATASET';
export const IS_SCENARIO_SUBMITTING = 'app/services/Dataset/IS_SCENARIO_SUBMITTING';
export const SCENARIO_SUCCESS = 'app/services/Dataset/SCENARIO_SUCCESS';
export const SCENARIO_FAILURE = 'app/services/Dataset/SCENARIO_FAILURE';
export const CLEAR_SCENARIO = 'app/services/Dataset/CLEAR_SCENARIO';
export const COMPUTE_FINISHED = 'app/services/Dataset/COMPUTE_FINISHED';
export const DATASET_SAVED = 'app/services/Dataset/DATASET_SAVED';

export const receivedCreateSuccess = (data: ImmutableDataset) => ({
    type: CREATE_SUCCESS,
    payload: { data },
});

export const receivedCreateFailure = (errors: {}) => ({
    type: CREATE_FAILURE,
    payload: { errors },
});

// export const receivedFetchListSuccess = (data: Array<ImmutableDataset>) => ({
//     type: FETCH_LIST_SUCCESS,
//     payload: { data },
// });

// export const receivedFetchListFailure = (errors: {}) => ({
//     type: FETCH_LIST_FAILURE,
//     payload: { errors },
// });

export const receivedFetchSuccess = (data: ImmutableDataset) => ({
    type: FETCH_SUCCESS,
    payload: { data },
});

export const receivedFetchFailure = (errors: {}) => ({
    type: FETCH_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: ImmutableDataset) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const receivedDestroySuccess = (data: ImmutableDataset) => ({
    type: DESTROY_SUCCESS,
    payload: { data },
});

export const receivedDestroyFailure = (errors: {}) => ({
    type: DESTROY_FAILURE,
    payload: { errors },
});

export const receivedComputeSuccess = (data: ImmutableDataset) => ({
    type: COMPUTE_SUCCESS,
    payload: { data },
});

export const receivedComputeFailure = (errors: {}) => ({
    type: COMPUTE_FAILURE,
    payload: { errors },
});

export const receivedCompute2DSuccess = (data: ImmutableTwoDAnalysis) => ({
    type: COMPUTE_2D_SUCCESS,
    payload: { data },
});

export const receivedCompute2DFailure = (errors: {}) => ({
    type: COMPUTE_2D_FAILURE,
    payload: { errors },
});

export const receivedCompute2DDatasetSuccess = (data: ImmutableDataset) => ({
    type: COMPUTE_2D_DATASET_SUCCESS,
    payload: { data },
});

export const receivedCompute2DDatasetFailure = (errors: {}) => ({
    type: COMPUTE_2D_DATASET_FAILURE,
    payload: { errors },
});

export const receivedExport2DSensitivityCSVSuccess = (data) => ({
    type: EXPORT_2D_SENSITIVITY_CSV_SUCCESS,
    payload: { data },
});

export const receivedExport2DSensitivityCSVFailure = (errors: {}) => ({
    type: EXPORT_2D_SENSITIVITY_CSV_FAILURE,
    payload: { errors },
});

export const setIsExportingStatus = (isExporting: boolean = true) => ({
    type: IS_EXPORTING,
    payload: { isExporting },
});

export const receivedCompute3DSuccess = (data: ImmutableThreeDAnalysis) => ({
    type: COMPUTE_3D_SUCCESS,
    payload: { data },
});

export const receivedCompute3DFailure = (errors: {}) => ({
    type: COMPUTE_3D_FAILURE,
    payload: { errors },
});

export const setIsFetchingStatus = (isFetching: boolean = true) => ({
    type: IS_FETCHING,
    payload: { isFetching },
});

export const setIsUpdatingStatus = (isUpdating: boolean = true) => ({
    type: IS_UPDATING,
    payload: { isUpdating },
});

export const setIsDeletingStatus = (isDeleting: boolean = true) => ({
    type: IS_DELETING,
    payload: { isDeleting },
});

export const setIsCreatingStatus = (isCreating: boolean = true) => ({
    type: IS_CREATING,
    payload: { isCreating },
});

export const setIsComputingStatus = (isComputing: boolean = true) => ({
    type: IS_COMPUTING,
    payload: { isComputing },
});

export const setIsComputing2DStatus = (isComputing2D: boolean = true) => ({
    type: IS_COMPUTING_2D,
    payload: { isComputing2D },
});

export const setIsComputing3DStatus = (isComputing3D: boolean = true) => ({
    type: IS_COMPUTING_3D,
    payload: { isComputing3D },
});

export const setIsComputing2DDatasetStatus = (isComputing2DDataset: boolean = true) => ({
    type: IS_COMPUTING_2D_DATASET,
    payload: { isComputing2DDataset },
});

export const setIsScenarioSubmittingStatus = (isScenarioSubmitting: boolean = true) => ({
    type: IS_SCENARIO_SUBMITTING,
    payload: { isScenarioSubmitting },
});

export const receivedSubmitScenarioSuccess = (data: Object) => ({
    type: SCENARIO_SUCCESS,
    payload: { data },
});

export const receivedSubmitScenarioFailure = (errors: Object) => ({
    type: SCENARIO_FAILURE,
    payload: { errors },
});

export const clearScenarioResults = () => ({
    type: CLEAR_SCENARIO,
});

export const setIsDatasetComputed = (isComputeCompleted: boolean = false) => ({
    type: COMPUTE_FINISHED,
    payload: { isComputeCompleted },
});

export const setIsDatasetSaved = (isDatasetSaved: boolean = false) => ({
    type: DATASET_SAVED,
    payload: { isDatasetSaved },
});

/**
 * Applied via the Trends reducer
 *
 * TODO: Refactor trends store to not have nested datasets
 */
export const CREATE_OR_UPDATE_DATASET_VALUE_SUCCESS =
    'app/services/Dataset/CREATE_OR_UPDATE_DATASET_VALUE_SUCCESS';
export const CREATE_OR_UPDATE_DATASET_VALUE_FAILURE =
    'app/services/Dataset/CREATE_OR_UPDATE_DATASET_VALUE_FAILURE';
export const IS_CREATING_OR_UPDATING_DATASET_VALUE =
    'app/services/Dataset/IS_CREATING_OR_UPDATING_DATASET_VALUE';
export const CREATE_OR_UPDATE_STAGE_VALUE_SUCCESS =
    'app/services/Dataset/CREATE_OR_UPDATE_STAGE_VALUE_SUCCESS';
export const CREATE_OR_UPDATE_STAGE_VALUE_FAILURE =
    'app/services/Dataset/CREATE_OR_UPDATE_STAGE_VALUE_FAILURE';
export const IS_CREATING_OR_UPDATING_STAGE_VALUE =
    'app/services/Dataset/IS_CREATING_OR_UPDATING_STAGE_VALUE';
export const CREATE_OR_UPDATE_STREAM_VALUE_SUCCESS =
    'app/services/Dataset/CREATE_OR_UPDATE_STREAM_VALUE_SUCCESS';
export const CREATE_OR_UPDATE_STREAM_VALUE_FAILURE =
    'app/services/Dataset/CREATE_OR_UPDATE_STREAM_VALUE_FAILURE';
export const IS_CREATING_OR_UPDATING_STREAM_VALUE =
    'app/services/Dataset/IS_CREATING_OR_UPDATING_STREAM_VALUE';

export const receivedDatasetValueCreateOrUpdateSuccess = (data: LooseDatasetValue) => ({
    type: CREATE_OR_UPDATE_DATASET_VALUE_SUCCESS,
    payload: { data },
});

export const receivedDatasetValueCreateOrUpdateFailure = (errors: {}) => ({
    type: CREATE_OR_UPDATE_DATASET_VALUE_FAILURE,
    payload: { errors },
});

export const setIsCreatingOrUpdatingDatasetValue = (
    isCreatingOrUpdatingDatasetValue: boolean = true
) => ({
    type: IS_CREATING_OR_UPDATING_DATASET_VALUE,
    payload: { isCreatingOrUpdatingDatasetValue },
});

export const receivedStageValueCreateOrUpdateSuccess = (data: LooseStageValue) => ({
    type: CREATE_OR_UPDATE_STAGE_VALUE_SUCCESS,
    payload: { data },
});

export const receivedStageValueCreateOrUpdateFailure = (errors: {}) => ({
    type: CREATE_OR_UPDATE_STAGE_VALUE_FAILURE,
    payload: { errors },
});

export const setIsCreatingOrUpdatingStageValue = (
    isCreatingOrUpdatingStageValue: boolean = true
) => ({
    type: IS_CREATING_OR_UPDATING_STAGE_VALUE,
    payload: { isCreatingOrUpdatingStageValue },
});

export const receivedStreamValueCreateOrUpdateSuccess = (data: LooseStreamValue) => ({
    type: CREATE_OR_UPDATE_STREAM_VALUE_SUCCESS,
    payload: { data },
});

export const receivedStreamValueCreateOrUpdateFailure = (errors: {}) => ({
    type: CREATE_OR_UPDATE_STREAM_VALUE_FAILURE,
    payload: { errors },
});

export const setIsCreatingOrUpdatingStreamValue = (
    isCreatingOrUpdatingStreamValue: boolean = true
) => ({
    type: IS_CREATING_OR_UPDATING_STREAM_VALUE,
    payload: { isCreatingOrUpdatingStreamValue },
});
