// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    // justify-content: space-between; // TODO: Do we remove the Apply to whole cascade code
    justify-content: flex-end;
    padding: 16px 24px 0px 24px;

    label {
        line-height: 30px;
    }

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 7px;

    * + * {
        // The input fields of each field.
        margin-top: 8px;
    }
`;

export const IsothermInputSelectWrapper = styled.div`
    margin-top: 8px;
    margin-bottom: 16px;
    margin-left: 40px;
    width: 240px;
`;

export const Label = styled.label`
    font-size: 12px;
    line-height: 16px;
`;

export const LargeInputBox = styled.textarea`
    width: 100%;
    height: 83px;
    min-height: 83px;
    padding: 7px 16px;
    resize: none;

    font-size: 12px;
    color: ${(props) =>
        props.disabled ? LegacyTheme.disabledInputColor : LegacyTheme.defaultColor};

    background-color: ${LegacyTheme.defaultBackground};
    border-radius: 4px;
    border: 1px solid
        ${(props) => (props.disabled ? LegacyTheme.disabledBorder : LegacyTheme.defaultBorder)};
    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: ${LegacyTheme.blue009};
    }

    &::placeholder {
        color: ${(props) =>
            props.disabled ? LegacyTheme.disabledPlaceholder : LegacyTheme.disabledInputColor};
    }
`;

export const MainContentFrame = styled.div`
    margin-top: 16px;
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
`;

export const RadioButtonWrapper = styled.div`
    padding: 10px 0px 8px 19px;
`;

export const TitleWrapper = styled.div`
    margin: 8px 0;
`;

export const SubTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const RadioButtonSeperatorLine = styled.hr`
    width: calc(100% + 20px);
    margin-left: -20px;
    margin-bottom: 0px;
    height: 1px;

    border-top: 1px solid #e6e6e6;
    border-bottom: none;
    border-right: none;
    border-left: none;
`;

export const ChartWrapper = styled.div`
    width: 480px;
    height: 386px;
`;
// height: 330px;
//     width: 330px;
//     margin: auto;
