// @flow

import React from 'react';

import type { InputValueType, ReactNode } from '../../types';

// Styles
import { Radiobutton, RadiobuttonLabel, RadiobuttonWrapper, Label } from './styles';

type SuffixType = 'BOTTOM' | 'RIGHT';
type OverflowType = 'auto' | 'visible' | 'hidden';

type Props = {
    active?: boolean,
    disabled?: boolean,
    icon?: Object,
    label?: string,
    value?: InputValueType,
    onClickHandler?: ?() => void,
    renderSuffix?: () => ReactNode,
    suffixLocation?: SuffixType,
    styles?: Object,
    theme?: ?Object,
    wrapperOverflow?: OverflowType,
    radioButtonRef?: ?HTMLDivElement,
};

type State = {};

export class RadioButton extends React.Component<Props, State> {
    static defaultProps = {
        active: false,
        disabled: false,
        icon: null,
        label: '',
        onClickHandler: null,
        renderSuffix: null,
        styles: {},
        suffixLocation: 'BOTTOM',
        theme: null,
        value: null,
        wrapperOverflow: 'visible',
        radioButtonRef: null,
    };

    render() {
        if (!this.props.label || this.props.value === 'undefined') {
            return null;
        }

        const inner = (
            <RadiobuttonLabel
                disabled={this.props.disabled}
                onClick={!this.props.disabled ? this.props.onClickHandler : null}
                theme={this.props.theme}
                {...this.props.styles}
            >
                <Radiobutton checked={this.props.active} disabled={this.props.disabled} />
                <Label wrapperOverflow={this.props.wrapperOverflow}>{this.props.label}</Label>
                <img src={this.props.icon} alt="" />
            </RadiobuttonLabel>
        );

        if (this.props.renderSuffix) {
            return (
                <RadiobuttonWrapper
                    ref={this.props.radioButtonRef}
                    flexDirection={this.props.suffixLocation === 'RIGHT' ? 'row' : 'column'}
                    wrapperOverflow={this.props.wrapperOverflow}
                >
                    {inner}
                    {this.props.renderSuffix(this.props.value, this.props.active)}
                </RadiobuttonWrapper>
            );
        } else {
            return (
                <RadiobuttonWrapper
                    ref={this.props.radioButtonRef}
                    wrapperOverflow={this.props.wrapperOverflow}
                >
                    {inner}
                </RadiobuttonWrapper>
            );
        }
    }
}
