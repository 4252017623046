// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    max-width: 100%;
    max-height: ${(props) => (props.styles.maxHeight ? props.styles.maxHeight : '190px')};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid ${LegacyTheme.tableBorder};
    border-radius: 4px;
    box-shadow: inset 0 -1px 0 0 ${LegacyTheme.tableBorder},
        inset 0 1px 0 0 ${LegacyTheme.tableBorder}, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: ${({ withMargin }) => (withMargin ? '24px' : '0')};
`;

export const Head = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    line-height: 17px;
    font-size: 14px;
    background-color: ${LegacyTheme.tableHeader};
    color: ${LegacyTheme.tableHeaderColor};
    border-bottom: 1px solid ${LegacyTheme.tableBorder};
`;

export const Title = styled.span`
    min-width: 134px;
    max-width: calc(100% - 18px);
    padding-left: 16px;
`;

export const LoadingContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const Row = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: ${(props) => (props.loading ? 'center' : 'space-between')};
    align-items: center;
    width: 100%;
    max-height: ${(props) => (props.styles.rowHeight ? props.styles.rowHeight : '46px')};
    min-height: ${(props) => (props.styles.rowMinHeight ? props.styles.rowMinHeight : '46px')};
    background-color: ${LegacyTheme.tableBackground};
    color: ${LegacyTheme.tableColor};
    border-bottom: 1px solid ${LegacyTheme.tableBorder};
`;

export const Body = styled.div`
    width: 100%;
    max-height: calc(100%-40px);
    background-color: ${LegacyTheme.tableBackground};
    overflow-y: auto;

    > ${Row} {
        &:last-child {
            border-bottom: 0;
        }
    }
`;
