// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Selectors
import { selectKPIHistories } from 'services/Trends/selectors';
import { fetchKPITrend } from 'services/Trends/thunks';

// Components
import MajorKPITrends from 'components/MajorKPITrends';
import MinorKPITrends from 'components/MinorKPITrends';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import { TRENDS_PAGE_TYPE, DEFAULT_PERIOD, APPLICATION_TYPES } from 'utils/constants';

// Styles
import { Wrapper } from './styles';

// Types
import type { IntlType, ReduxDispatch, ImmutableList } from 'types';
import type {
    TrendsPeriodConstant,
    TrendsPageType,
    ImmutableTrendData,
    ImmutableKPIHistory,
} from 'services/Trends/types';
import type { ImmutableKPISetting, ImmutableKPICard } from 'services/KPISetting/types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableClient } from 'services/Client/types';
import type { ImmutablePlant } from 'services/Plant/types';

// Helpers
import { getUsersLanguage, getLoginUserName } from 'utils/authentication';
import { getMajorKPIs, getMinorKPIs } from 'containers/TrendsContainer/helpers';

import { logUserAnalyticsInteraction } from 'utils/userAnalytics';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    clients: ImmutableList<ImmutableClient>,
    sidebarIsCollapsed: boolean,

    kpiHistories: ImmutableList<ImmutableKPIHistory>,

    circuit: ?ImmutableCircuit,
    trend: ImmutableTrendData,
    loading: boolean,
    trendType: TrendsPageType,

    onSettingsClicked?: ?() => void,
    onViewMimicClicked: () => void,

    fetchKPITrend: (kpiId: number, period: TrendsPeriodConstant) => void,
};

type State = {
    selectedMajorKPISettingId: number | null,
};

class TrendsPageDashboard extends React.PureComponent<Props, State> {
    static defaultProps = {
        onSettingsClicked: null,
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (!nextProps.trend) {
            return {
                selectedMajorKPISettingId: null,
            };
        }

        if (prevState.selectedMajorKPISettingId === null) {
            return {
                selectedMajorKPISettingId: nextProps.trend.getIn([
                    'initialHistory',
                    'kpiSettingId',
                ]),
            };
        } else {
            const existsInTrend = nextProps.trend
                .get('kpis')
                .find(
                    (kpi: ImmutableKPICard) =>
                        kpi.get('kpiSettingId') === prevState.selectedMajorKPISettingId
                );
            if (!existsInTrend) {
                return {
                    selectedMajorKPISettingId: nextProps.trend.getIn([
                        'initialHistory',
                        'kpiSettingId',
                    ]),
                };
            } else {
                return prevState;
            }
        }
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedMajorKPISettingId: props.trend
                ? props.trend.getIn(['initialHistory', 'kpiSettingId'])
                : null,
        };
    }

    getRawDate = () => {
        if (!this.props.trend) {
            return null;
        }
        return this.props.trend.get('createdAt');
    };

    shouldFetchMajorKPI = () => {
        const hasSelectedMajorKPIHistory = this.props.kpiHistories.find(
            (kpiHistory: ImmutableKPIHistory) =>
                kpiHistory.get('kpiSettingId') === this.state.selectedMajorKPISettingId
        );
        return (
            !hasSelectedMajorKPIHistory ||
            (hasSelectedMajorKPIHistory.get('history') === null &&
                !hasSelectedMajorKPIHistory.get('isLoading'))
        );
    };

    handleChangeMajorKPI = (newMajorKPIId: number) =>
        this.setState(
            {
                selectedMajorKPISettingId: newMajorKPIId,
            },
            () => {
                if (this.shouldFetchMajorKPI()) {
                    this.props.fetchKPITrend(
                        this.state.selectedMajorKPISettingId,
                        DEFAULT_PERIOD,
                        this.getRawDate()
                    );
                }
            }
        );

    /**
     * When the user changes the time period of the data
     */
    handleChangePeriod = (period: TrendsPeriodConstant) => {
        logUserAnalyticsInteraction({moduleName: APPLICATION_TYPES.SOLVEXTRACT, plant: this.getPlantName(),
            userName: getLoginUserName(this.props.user), kpi: this.getKPIName(this.state.selectedMajorKPISettingId), circuit: this.props.circuit?.get('name') });

        return this.props.fetchKPITrend(this.state.selectedMajorKPISettingId, period, this.getRawDate());
    }

    handleChangePeriodForMinor = (kpiId: number, period: TrendsPeriodConstant) => {
        logUserAnalyticsInteraction({moduleName: APPLICATION_TYPES.SOLVEXTRACT, plant: this.getPlantName(),
            userName: getLoginUserName(this.props.user), kpi: this.getKPIName(kpiId), circuit: this.props.circuit?.get('name') });

        return this.props.fetchKPITrend(kpiId, period, this.getRawDate());
    }

    getPlantName = () => {
        let plantName = '';
        this.props.clients.forEach((client: ImmutableClient) => {
            client?.get('plants')
            .forEach((p: ImmutablePlant) => { 
                if(p.get('id') === this.props?.trend.get('plantId')) {
                    plantName = p?.get('name');
                }
            })
        });
        return plantName;
    }

    getKPIName = (kpiId: number) =>
        this.props.trend.get('kpiSettings').find(
            (kpiSetting: ImmutableKPISetting) => kpiSetting.get('id') === kpiId)
            ?.get('name');

    shouldShowMaxCuTransfer = () => {
        if (this.props.trendType === TRENDS_PAGE_TYPE.PLANT) {
            // always show Cu Transfer max in plant view
            return true;
        }
        const plantId = this.props.trend.get('plantId');
        return Boolean(
            this.props.clients.find((client: ImmutableClient) => {
                const plant = client
                    .get('plants')
                    .find((p: ImmutablePlant) => p.get('id') === plantId);
                return plant && plant.get('circuits').size === 1;
            })
        );
    };

    render() {
        if (!this.props.trend && !this.props.loading) {
            return (
                <div>
                    <Wrapper>
                        <ErrorMessage
                            errorCode="components.TrendsPageDashboard.noTrendsLoaded"
                            errorMessage="Trend is null in renderMainContent"
                        />
                    </Wrapper>
                </div>
            );
        }

        const kpis = this.props.trend.get('kpis');

        const usersUiLanguage = getUsersLanguage(this.props.user);

        // should we show the max or the target for cu transfer?
        const showMaxCuTransfer = this.shouldShowMaxCuTransfer();

        const majorKpis = getMajorKPIs(kpis, this.props.trendType);
        const minorKpis = getMinorKPIs(kpis, this.props.trendType);
        const timezone = this.props.trend.get('timezone');

        // if we can find it then it is loading.
        const loadingMajorKpi = Boolean(
            this.props.kpiHistories.find(
                (kpiHistory: ImmutableKPIHistory) =>
                    kpiHistory.get('kpiSettingId') === this.state.selectedMajorKPISettingId &&
                    kpiHistory.get('isLoading')
            )
        );

        return (
            // Reset flexbox to avoid collapse on iOS by setting a non-flex block element
            <div>
                <Wrapper>
                    <MajorKPITrends
                        trendType={this.props.trendType}
                        trend={this.props.trend}
                        kpis={majorKpis}
                        user={this.props.user}
                        kpiHistories={this.props.kpiHistories}
                        userLanguage={usersUiLanguage}
                        sidebarIsCollapsed={this.props.sidebarIsCollapsed}
                        showMaxCuTransfer={showMaxCuTransfer}
                        loadingMajorHistory={loadingMajorKpi}
                        selectedMajorKPISettingId={this.state.selectedMajorKPISettingId}
                        onChangePeriod={this.handleChangePeriod}
                        onChangeMajorKPI={this.handleChangeMajorKPI}
                        onSettingsClicked={this.props.onSettingsClicked}
                        onViewMimicClicked={this.props.onViewMimicClicked}
                    />
                    <MinorKPITrends
                        kpis={minorKpis}
                        circuit={this.props.circuit}
                        kpiHistories={this.props.kpiHistories}
                        timezone={timezone}
                        userLanguage={usersUiLanguage}
                        onChangePeriodForMinor={this.handleChangePeriodForMinor}
                    />
                </Wrapper>
            </div>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        kpiHistories: selectKPIHistories(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchKPITrend,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TrendsPageDashboard));
