// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';

// Styles
import {
    NotificationsListItem,
    NotificationWrapper,
    NotificationContent,
    NotificationTitle,
    NotificationTime,
    DotWrapper,
    ToolTipContent,
} from './styles';
import { Dot, LegacyTheme, ToolTip } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import type { ImmutableNotification } from 'services/Notifications/types';

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    notificationData: ImmutableNotification,
    onClickToggle: (notificationId: number, readState: boolean) => void,
    onClickRow: (
        notificationId: number,
        currentReadState: boolean,
        notificationLink: string
    ) => void,
};

class NotificationRow extends React.PureComponent<Props, State> {
    handleToggle = (e) => {
        e.stopPropagation();
        this.props.onClickToggle(
            this.props.notificationData.get('id'),
            !this.props.notificationData.get('read')
        );
    };

    handleClick = () => {
        this.props.onClickRow(
            this.props.notificationData.get('id'),
            this.props.notificationData.get('read'),
            this.props.notificationData.get('link')
        );
    };

    getClientTimezone = () => {
        try {
            return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (err) {
            return 'UTC';
        }
    };

    getFormattedTime = (createdAt: string) => {
        const clientTimezone = this.getClientTimezone();
        const notificationTime = moment
            .utc(this.props.notificationData.get('createdAt'))
            .tz(clientTimezone);
        const now = moment().tz(clientTimezone);
        const diffInDays = notificationTime.diff(now, 'days');
        const dayString = this.props.intl.formatMessage({
            id: `common.${diffInDays === -1 ? 'yesterday' : 'today'}`,
        });

        if (diffInDays < -1) {
            return ` - ${notificationTime.format('lll')}`;
        } else {
            return ` - ${dayString} ${notificationTime.format('LT')}`;
        }
    };

    render() {
        return (
            <NotificationsListItem onClick={this.handleClick}>
                <NotificationWrapper>
                    <div>
                        <NotificationTitle>
                            {this.props.notificationData.get('title')}
                            <NotificationTime
                                color={
                                    this.props.notificationData.get('read')
                                        ? LegacyTheme.defaultSubtleColor7B
                                        : LegacyTheme.radiobuttonActive
                                }
                            >
                                {this.getFormattedTime(
                                    this.props.notificationData.get('created_at')
                                )}
                            </NotificationTime>
                        </NotificationTitle>
                        <NotificationContent>
                            {this.props.notificationData.get('content')}
                        </NotificationContent>
                    </div>

                    <ToolTip
                        content={
                            <ToolTipContent>
                                {this.props.intl.formatMessage({
                                    id: `components.Header.NotificationsDrawer.${
                                        this.props.notificationData.get('read')
                                            ? 'markAsUnread'
                                            : 'markAsRead'
                                    }`,
                                })}
                            </ToolTipContent>
                        }
                        position="left"
                        triggerType="mouseenter"
                        interactive
                        trigger={
                            <div onClick={this.handleToggle} role="button" tabIndex={0}>
                                <DotWrapper>
                                    <Dot
                                        fill={
                                            this.props.notificationData.get('read')
                                                ? LegacyTheme.radiobuttonLabelDisabledColor
                                                : LegacyTheme.radiobuttonActive
                                        }
                                        margin="0"
                                    />
                                </DotWrapper>
                            </div>
                        }
                    />
                </NotificationWrapper>
            </NotificationsListItem>
        );
    }
}

export default injectIntl(NotificationRow);
