// @flow strict

import styled from 'styled-components';

import { LegacyTheme, InputField } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 0px 24px 24px 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0px 24px 31px 24px;

    border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;

    & + & {
        margin-top: 31px;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    line-height: 17px;
    flex-shrink: 0;
    margin-right: 13px;
    color: ${LegacyTheme.defaultColor};
    margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '10px')};
    font-weight: ${({ boldLabel }) => (boldLabel ? 'bold' : 'normal')};
`;

export const WrapperCheckboxTextInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & + & {
        margin-top: 31px;
    }
`;

export const InputNameField = styled(InputField)`
    height: 30px;
    width: 140px;
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
    background-color: ${LegacyTheme.disabledBorder};
    font-size: 16px;
`;

export const NameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CheckBoxStyle = {
    fontSize: '14px',
    color: LegacyTheme.defaultColor,
    marginRight: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
};
