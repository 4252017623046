// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Field, FieldHeader, Label, PlantSettingWrapper } from './styles';

// Components
import { Toggle } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import type { ImmutableNotificationPlantSettings } from 'services/Notifications/types';

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    notificationPlantSettings: ImmutableNotificationPlantSettings,
    onToggleNotificationPlantSetting: (plantId: number) => void,
};

class UserNotificationPlantSettings extends React.PureComponent<Props> {
    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.UserSettings.NotificationSettings.${key}`,
            },
            data
        );

    handleToggle(plantId: number) {
        this.props.onToggleNotificationPlantSetting(plantId);
    }

    render() {
        return (
            <Field>
                <FieldHeader>{this.getTranslation('plantSettingsTitle')}</FieldHeader>

                {this.props.notificationPlantSettings.map((plantSetting) => (
                    <PlantSettingWrapper key={plantSetting.get('plantId')}>
                        <Toggle
                            checked={plantSetting.get('active')}
                            onClickHandler={() => this.handleToggle(plantSetting.get('plantId'))}
                        />
                        <Label style={{ marginBottom: 0 }}>{plantSetting.get('plantName')}</Label>
                    </PlantSettingWrapper>
                ))}
            </Field>
        );
    }
}

export default injectIntl(UserNotificationPlantSettings);
