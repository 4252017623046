// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Types
import type { IntlType } from 'types';
import type { TrendsPeriodConstant } from 'services/Trends/types';

// Styles
import { GraphPeriodButtons, GraphPeriodButton } from './styles';

// Constants
import { TRENDS_PERIODS } from 'utils/constants';

type Props = {
    intl: IntlType,
    period: TrendsPeriodConstant,
    loading: boolean,

    onChangePeriod: (newPeriod: TrendsPeriodConstant) => void,

    center?: boolean,
};

/**
 * Period selector for KPI Trends (Major & Minor)
 */
class GraphPeriodSelector extends React.PureComponent<Props, null> {
    static defaultProps = {
        center: false,
    };

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.GraphPeriodSelector.${id}`,
        });

    handlePeriodButtonClicked = (period: TrendsPeriodConstant) => () => {
        this.props.onChangePeriod(period);
    };

    render() {
        return (
            <GraphPeriodButtons center={this.props.center}>
                {Object.values(TRENDS_PERIODS).map((period: TrendsPeriodConstant) => (
                    <GraphPeriodButton
                        key={period}
                        text={this.getTranslation(period)}
                        // loading={this.props.loading && this.props.period === period}
                        disabled={this.props.loading || this.props.period === period}
                        active={this.props.period === period}
                        onClick={this.handlePeriodButtonClicked(period)}
                    />
                ))}
            </GraphPeriodButtons>
        );
    }
}

export default injectIntl(GraphPeriodSelector);
