// @flow strict

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import NotificationsDrawer from 'components/Header/NotificationsDrawer';

// Constants
import { NOTIFICATIONS_REFRESH_INTERVAL } from 'utils/constants';

// Services
import { selectUser } from 'services/Authentication/selectors';
import {
    queryUserNotifications,
    fetchTotalUnreadNotifications,
} from 'services/Notifications/thunks';
import {
    selectQueriedNotifications,
    selectNotificationsAreQuerying,
    selectTotalUnreadNotifications,
} from 'services/Notifications/selectors';

// Types
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableNotifications } from 'services/Notifications/types';

type InjectedProps = {
    user: ImmutableUser,
    isQueryingNotifications: boolean,
    queriedNotifications: ImmutableNotifications,
    totalUnreadNotifications: number,
    queryUserNotifications: (userId: number, page?: number, perPage?: number) => void,
    fetchTotalUnreadNotifications: (userId: number) => void,
};

type Props = InjectedProps;

class NotificationsContainer extends React.PureComponent<Props> {
    componentDidMount() {
        // Long polling of notifications
        this.timer = setInterval(this.initNotifications, NOTIFICATIONS_REFRESH_INTERVAL);
        this.initNotifications();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    initNotifications = () => {
        this.props.fetchTotalUnreadNotifications(this.props.user.get('id'));
        this.props.queryUserNotifications(this.props.user.get('id'), 1);
    };

    timer = null;

    render() {
        return (
            <NotificationsDrawer
                user={this.props.user}
                isQueryingNotifications={this.props.isQueryingNotifications}
                queriedNotifications={this.props.queriedNotifications}
                totalUnreadNotifications={this.props.totalUnreadNotifications}
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
        isQueryingNotifications: selectNotificationsAreQuerying(),
        queriedNotifications: selectQueriedNotifications(),
        totalUnreadNotifications: selectTotalUnreadNotifications(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            queryUserNotifications,
            fetchTotalUnreadNotifications,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsContainer);
