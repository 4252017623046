// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    flex: 1;

    border-left: 1px solid ${LegacyTheme.defaultBorder};
    cursor: pointer;

    min-width: 0; // Text-overflow fix
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 0 16px;

    height: 100%;

    > span {
        margin-left: 0;
        margin-right: 12px;
    }
`;
