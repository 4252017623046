// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

// Plants cards
export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 80px 0 80px;
`;

// Plant card
export const PlantCardWrapper = styled.div`
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 15px;
`;

export const PlantCardHeader = styled.div`
    background-color: #f2f2f2;
    border-bottom: 1px solid ${LegacyTheme.defaultBorder};
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PlantCardHeaderTitle = styled.span`
    margin-right: 8px;
`;

export const PlantCardHeaderControls = styled.div`
    display: flex;
    align-items: center;
`;

export const PlantCardBody = styled.div``;

export const PlantKPIsWrapper = styled.div`
    border-bottom: 1px dashed ${LegacyTheme.defaultBorder};
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
`;

export const PlantKPIsNA = styled.p`
    font-size: 28px;
    margin: 10px;
`;

export const CircuitsCardsWrapper = styled.div`
    padding: 10px 8px;
`;

export const RowExtraWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
`;

export const MenuToolTip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${LegacyTheme.defaultSubtleColor};
    transform: rotateZ(90deg);
    margin-right: -12px;
    font-size: 11px;

    cursor: pointer;
    text-decoration: none;

    white-space: nowrap;

    > span {
        margin-right: 5px;
    }
`;

export const MenuToolTipContent = styled.div`
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 5px;
    padding: 4px 0px;
    background-color: ${LegacyTheme.toolTip};

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ToolTipButton = styled.span`
    padding: 8px 16px;
    cursor: pointer;
    text-align: start;
    background-color: ${LegacyTheme.toolTip};

    &:hover {
        background-color: ${LegacyTheme.buttonActiveHover};
    }
`;

export const BtnIconWrapper = styled.span`
    padding-right: 6px;
    position: relative;
    bottom: 1px;
`;
