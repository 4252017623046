// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Close } from 'components/_ReactUI_V1';

// Styles
import { FixedPosition, MessageBlock, MessageBlockIcon } from './styles';
import { InfoToastColor } from 'styles/colors';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,

    onClose: () => void,
};

const NonResponsivePageWarning = (props: Props) => (
    <FixedPosition>
        <MessageBlock color={InfoToastColor}>
            {props.intl.formatMessage({ id: 'components.NonResponsivePageWarning.text' })}
            <MessageBlockIcon onClick={props.onClose}>
                <Close strokeWidth={1} fill="white" />
            </MessageBlockIcon>
        </MessageBlock>
    </FixedPosition>
);

export default injectIntl(NonResponsivePageWarning);
