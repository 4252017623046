// @flow strict

import React from 'react';
import { QuickNavigatorObservable } from 'components/_FrontendObservables';
import type {
    QuickNavigatorObserverMessage,
    SetQuickNavigatorToBack,
    SetQuickNavigatorToCustom,
    SetQuickNavigatorToMinChem,
    SetQuickNavigatorToSolvExtract,
} from 'components/_FrontendObservables';

import { QUICK_NAVIGATOR_TYPES } from 'utils/quickNavigator';
import type { QuickNavigatorTypes } from 'utils/quickNavigator';

import BackToNavigator from '../BackToNavigator';
import MinchemQuickNavigator from '../MinchemQuickNavigator/MinChemQuickNavigator';
import SolvExtractQuickNavigator from '../SolvExtractQuickNavigator/SolvExtractQuickNavigator';

import type { RouteType } from 'types';

type Props = {
    route: RouteType,
    currentLocation: string,
};

type State = {
    type: QuickNavigatorTypes,
    backNavigatorData: ?SetQuickNavigatorToBack,
    customNavigatorRenderer: ?SetQuickNavigatorToCustom,
};

class QuickNavigator extends React.PureComponent<Props, State> {
    state = {
        type: QUICK_NAVIGATOR_TYPES.NOTHING,
        backNavigatorData: null,
        customNavigatorRenderer: null,
    };

    componentDidMount() {
        QuickNavigatorObservable.observable.subscribe(this.updateQuickNavigator);
    }

    componentWillUnmount() {
        QuickNavigatorObservable.observable.unsubscribe(this.updateQuickNavigator);
    }

    setBackNavigator = (data: SetQuickNavigatorToBack) => {
        this.setState({
            type: data.type,
            backNavigatorData: data,
        });
    };

    setQuickNavigator = (data: SetQuickNavigatorToMinChem | SetQuickNavigatorToSolvExtract) => {
        this.setState({
            type: data.type,
        });
    };

    setCustomNavigator = (data: SetQuickNavigatorToCustom) => {
        this.setState({
            type: data.type,
            customNavigatorRenderer: data,
        });
    };

    removeQuickNavigator = () => {
        this.setState({
            type: QUICK_NAVIGATOR_TYPES.NOTHING,
            backNavigatorData: null,
            customNavigatorRenderer: null,
        });
    };

    updateQuickNavigator = (topic: string, data: QuickNavigatorObserverMessage) => {
        console.debug(`[Quick Navigator] Received update to ${topic} with ${data.type}`);
        switch (data.type) {
            case QUICK_NAVIGATOR_TYPES.NOTHING:
                return this.removeQuickNavigator();
            case QUICK_NAVIGATOR_TYPES.QUICK_NAVIGATOR_BACK:
                return this.setBackNavigator(data);
            case QUICK_NAVIGATOR_TYPES.MINCHEM_QUICK_NAVIGATOR:
            case QUICK_NAVIGATOR_TYPES.SOLVEXTRACT_QUICK_NAVIGATOR:
                return this.setQuickNavigator(data);
            case QUICK_NAVIGATOR_TYPES.CUSTOM_QUICK_NAVIGATOR:
                return this.setCustomNavigator(data);
            default: {
                console.warn(
                    `[Quick Navigator] Received a quick navigator (${topic}) that doesn't match known types, received: `,
                    data
                );
                throw new Error('Unknown Quick Navigator message received.');
            }
        }
    };

    renderBackNavigator = () => {
        if (!this.state.backNavigatorData) {
            return null;
        }
        return (
            <BackToNavigator
                backToLabel={this.state.backNavigatorData.label}
                backToLink={this.state.backNavigatorData.link}
                onBackTo={this.state.backNavigatorData.callback}
            />
        );
    };

    renderMinChemQuickNavigator = () => {
        return (
            <MinchemQuickNavigator
                route={this.props.route}
                currentLocation={this.props.currentLocation}
            />
        );
    };

    renderSolvExtractQuickNavigator = () => {
        return (
            <SolvExtractQuickNavigator
                route={this.props.route}
                currentLocation={this.props.currentLocation}
            />
        );
    };

    renderCustomQuickNavigator = () => {
        if (!this.state.customNavigatorRenderer) {
            return null;
        }
        return this.state.customNavigatorRenderer.render();
    };

    render() {
        switch (this.state.type) {
            case QUICK_NAVIGATOR_TYPES.NOTHING:
                return null;
            case QUICK_NAVIGATOR_TYPES.QUICK_NAVIGATOR_BACK:
                return this.renderBackNavigator();
            case QUICK_NAVIGATOR_TYPES.MINCHEM_QUICK_NAVIGATOR:
                return this.renderMinChemQuickNavigator();
            case QUICK_NAVIGATOR_TYPES.SOLVEXTRACT_QUICK_NAVIGATOR:
                return this.renderSolvExtractQuickNavigator();
            case QUICK_NAVIGATOR_TYPES.CUSTOM_QUICK_NAVIGATOR:
                return this.renderCustomQuickNavigator();
            default:
                return null;
        }
    }
}

export default QuickNavigator;
