// @flow strict

import React from 'react';

// Styles
import { LegendWrapper, DiagramLegendItem } from './styles';

// Components
import { Dot } from 'components/_ReactUI_V1';

// Types
export type LegendItem = {
    color: string,
    label: string,
    hideDot?: boolean,
};

type Props = {
    legendItems: Array<LegendItem>,
    direction?: 'ROW' | 'COLUMN',
};

/**
 * Displays the legend for a diagram
 */
class DiagramLegend extends React.PureComponent<Props> {
    static defaultProps = {
        direction: 'ROW',
    };

    render() {
        return (
            <LegendWrapper direction={this.props.direction}>
                {this.props.legendItems.map((legendItem: LegendItem) => (
                    <DiagramLegendItem
                        key={`${legendItem.color}-${legendItem.label}`}
                        color={legendItem.color}
                        direction={this.props.direction}
                    >
                        {!legendItem.hideDot && <Dot fill="useCurrent" margin="0 8px 0 8px" />}
                        {legendItem.label}
                    </DiagramLegendItem>
                ))}
            </LegendWrapper>
        );
    }
}

export default DiagramLegend;
