// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { debounce } from 'lodash';

// Services
import {
    Common,
    PrimaryButton,
    TertiaryButton,
    RadioButtonSet,
    Modal,
    InputSelect,
    LegacyTheme,
    InputSearch,
} from 'components/_ReactUI_V1';

import { fetchAllPlants } from 'services/Plant/thunks';
import { selectAllPlants, selectPlantsAreFetching } from 'services/Plant/selectors';

import { queryUsers } from 'services/User/thunks';
import {
    selectUsersAreQuerying,
    selectTotalPageCount,
    selectUsersLeftCount,
    selectUserQuery,
} from 'services/User/selectors';

import { setIsothermShareAccess } from 'services/Isotherm/thunks';
import { selectIsothermsAreUpdating, selectIsothermErrors } from 'services/Isotherm/selectors';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    RadioStyles,
    ImageStyle,
    InputStyles,
    InputSelectWrapper,
    DeleteButtonWrapper,
    UserListWrapper,
    UserListRow,
    OptionalLabel,
} from './styles';
import CircleDeleteIcon from 'assets/icons/circle-delete-icon';

// Components
import { Title, SectionDesc, DefaultScrollableContent } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';
import SelectWithInfiniteScroll from 'components/SelectWithInfiniteScroll';

// Types
import type {
    IntlType,
    ReduxDispatch,
    ReactSelectObject,
    ImmutableList,
    ErrorType,
    ImmutableQueryStructure,
} from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableIsotherm, ImmutableIsothermShareAccess } from 'services/Isotherm/types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutablePartialUser, UserSearchCriteria } from 'services/User/types';

// Constant
import { MODAL_WIDTH, STYLE_VALUES, ROLES } from 'utils/constants';

const USER_DEFAULT_SORTBY = 'id';
const USER_DEFAULT_SORTORDER = 'desc';

const defaultSearchCriteria = {
    search: '',
    role: ROLES.PM,
    sortBy: USER_DEFAULT_SORTBY,
    sortOrder: USER_DEFAULT_SORTORDER,
};

type Props = {
    intl: IntlType,
    isotherm: ImmutableIsotherm,
    isUpdatingIsotherm: boolean,
    isothermUpdateErrors: ErrorType,

    isLoadingPlants: boolean,
    plants: ImmutableList<ImmutablePlant>,
    fetchAllPlants: () => void,

    isLoadingUsers: boolean,
    userQueryData: ?ImmutableQueryStructure<ImmutablePartialUser>,
    totalPagesForQuery: number,
    usersLeft: number,
    queryUsers: (searchCriteria: UserSearchCriteria, page?: number, perPage?: number) => void,

    setIsothermShareAccess: (id: number, shareAccessSettings: ImmutableIsothermShareAccess) => void,
    onCloseModal: () => void,
};

type State = {
    showErrors: boolean,

    selectedPlantId: ?number,
    selectedUser: ?ImmutablePartialUser,

    shareAccess: ImmutableIsothermShareAccess,
};

/**
 * Shown to a SAM or Admin when they want to share an isotherm with PMs or Plants.
 */
class IsothermShareModal extends React.PureComponent<Props, State> {
    userListScrollRef = React.createRef();

    userSelectRef = React.createRef();

    constructor(props: Props) {
        super(props);

        const selectedPlantId = this.props.isotherm.getIn(['sharingAccess', 'plantId'], null);

        const defaultSharingAccess = fromJS({
            directUserAccesses: [],
            plantId: null,
        });

        this.state = {
            showErrors: false, // no error has occurred yet.

            selectedPlantId,
            selectedUser: null,

            shareAccess: this.props.isotherm.get('sharingAccess') || defaultSharingAccess,
        };
    }

    componentDidMount() {
        if (this.props.plants.isEmpty()) {
            this.props.fetchAllPlants();
        }
    }

    /**
     * When the component updates,
     * we need to check if we are done saving
     *      If we are, then close the modal.
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.isUpdatingIsotherm && !this.props.isUpdatingIsotherm) {
            if (this.props.isothermUpdateErrors.isEmpty()) {
                this.props.onCloseModal();
            }
            // Else: errors will be displayed by the renderError() function if prop errors not empty
        }
    }

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.Modals.IsothermShareModal.${id}`,
        });

    /**
     * Convert the item from the mimic diagram to a drop down select object
     */
    itemToReactSelectObject = (id: number, label: string): ReactSelectObject => ({
        value: id,
        label,
    });

    /**
     * Converts a plant to a react select object
     * @param {*} plant
     */
    getPlantToItem = (plant: ImmutablePlant): ReactSelectObject =>
        this.itemToReactSelectObject(plant.get('id'), plant.get('name'));

    /**
     * Converts all plants to react select object
     */
    getPlantOptions = () => this.props.plants.map(this.getPlantToItem);

    /**
     * Converts the currently selected plant to a react select object
     * If null, returns no object.
     */
    getSelectedPlantRSO = () => {
        if (!this.state.selectedPlantId) {
            return null;
        }
        const selectedPlant = this.props.plants.find(
            (plant: ImmutablePlant) => plant.get('id') === this.state.selectedPlantId
        );
        if (!selectedPlant) {
            // Probably still fetching.
            return null;
        }
        return this.getPlantToItem(selectedPlant);
    };

    /**
     * Unselects a plant from being shared
     */
    handleRemovePlant = () =>
        this.setState((prevState: State) => ({
            selectedPlantId: null,
        }));

    handleSelectPlant = (rso: ReactSelectObject) =>
        this.setState({
            selectedPlantId: Number(rso.value),
        });

    /**
     * When a user clicks on the "Allow" button we need to add the user to our state
     */
    handleAddUser = () => {
        return this.setState(
            (prevState: State) => {
                const selectedUser = prevState.selectedUser;
                if (!selectedUser) {
                    throw new Error('No user to add...');
                }
                return {
                    selectedUser: null,
                    // add the user to our state immediately so we can see it in the list:
                    shareAccess: prevState.shareAccess.updateIn(
                        ['directUserAccesses'],
                        (existingUserIds: ImmutableList<ImmutablePartialUser>) =>
                            existingUserIds.push(
                                fromJS({
                                    userId: selectedUser.get('id'),
                                    email: selectedUser.get('email'),
                                })
                            )
                    ),
                };
            },
            () => {
                // First when adding a user, scroll to the bottom of the users with access list.
                if (this.userListScrollRef && this.userListScrollRef.current) {
                    this.userListScrollRef.current.scrollTop = this.userListScrollRef.current.scrollHeight;
                }
                if (this.userSelectRef && this.userSelectRef.current) {
                    const userSelect = this.userSelectRef.current;
                    // clear the user from the box
                    userSelect.clearSelection();

                    // Did we reach the end of the list?
                    // And are there still users left in the backend?
                    if (this.props.usersLeft > 0 && !userSelect.hasOptionsLeft()) {
                        // Ensure we aren't loading
                        if (this.props.isLoadingUsers) {
                            return;
                        }
                        userSelect.queryNextPage();
                    }
                }
            }
        );
    };

    /**
     * When the user clicks on an item in the select dropdown.
     */
    handleSelectUser = (selectedUser: ImmutablePartialUser) => {
        this.setState({
            selectedUser,
        });
    };

    /**
     * Filter any user from being selectable if they exist in the
     * already selected accesses.
     */
    handleFilterSelectableUser = (user: ImmutablePartialUser) => {
        return !this.state.shareAccess
            .get('directUserAccesses')
            .find((userAccess) => user.get('id') === userAccess.get('userId'));
    };

    /**
     * Removes the user from our shared access
     */
    handleRemoveUser = (user: ImmutablePartialUser) => () => {
        return this.setState((prevState: State) => ({
            shareAccess: prevState.shareAccess.updateIn(
                ['directUserAccesses'],
                (existingUsers: ImmutableList<ImmutablePartialUser>) =>
                    existingUsers.filter(
                        (existingUser: ImmutablePartialUser) =>
                            // Remove the user by filtering
                            existingUser.get('userId') !== user.get('userId')
                    )
            ),
        }));
    };

    /**
     * When the user saves their sharing access
     * we format the share access to the appropriate format and send a set request.
     */
    handleConfirm = () =>
        this.setState(
            {
                showErrors: true,
            },
            () => {
                const shareAccess = this.state.shareAccess
                    .set('sharedToPlantId', this.state.selectedPlantId)
                    .set(
                        'sharedToUserIds',
                        this.state.shareAccess
                            .get('directUserAccesses')
                            .map((userId: ImmutablePartialUser) => userId.get('userId'))
                    );
                this.props.setIsothermShareAccess(this.props.isotherm.get('id'), shareAccess);
            }
        );

    /**
     * Renders the list of users with direct access or renders an optional label
     */
    renderUserAccesses = () => {
        const users = this.state.shareAccess.get('directUserAccesses');
        const title = users.size > 0 ? 'ListOfUsers' : 'NotShared';
        const LabelClass = users.size > 0 ? Label : OptionalLabel;
        return (
            <Common.Column>
                <LabelClass>{this.getTranslation(`shareWithUsers.${title}`)}</LabelClass>
                {users.size > 0 && (
                    <UserListWrapper ref={this.userListScrollRef}>
                        {users.map((user: ImmutablePartialUser) => (
                            <UserListRow key={user.get('userId')}>
                                <p>{user.get('email')}</p>
                                <DeleteButtonWrapper onClick={this.handleRemoveUser(user)}>
                                    <CircleDeleteIcon />
                                </DeleteButtonWrapper>
                            </UserListRow>
                        ))}
                    </UserListWrapper>
                )}
            </Common.Column>
        );
    };

    /**
     * Renders any errors that have occurred.
     */
    renderErrors = () => {
        if (this.props.isUpdatingIsotherm) {
            return null;
        }
        if (this.props.isothermUpdateErrors.isEmpty()) {
            return null;
        }
        // const code = this.props.isothermUpdateErrors.get('errorCode');
        const code = 'feedback.error.updateIsothermFailed';
        return (
            <ErrorMessage
                errorCode={code}
                style={{
                    marginBottom: '10px',
                    marginTop: '0',
                    textAlign: 'center',
                }}
                isRed
                isSmall
            />
        );
    };

    render() {
        return (
            <Modal onHandleClose={this.props.onCloseModal} modalWidth={MODAL_WIDTH.SMALL}>
                <Wrapper>
                    <Header>
                        <Title>{this.getTranslation('title')}</Title>
                    </Header>
                    <Body>
                        <Field>
                            <Label>
                                {this.getTranslation('shareWithPlants.Label')}
                                <OptionalLabel>{this.getTranslation('optional')}</OptionalLabel>
                            </Label>
                            <Common.Row
                                style={{
                                    marginTop: 0,
                                    alignItems: 'center',
                                }}
                            >
                                <InputSelect
                                    selectedOption={this.getSelectedPlantRSO()}
                                    options={this.getPlantOptions()}
                                    onSelect={this.handleSelectPlant}
                                    placeholder={this.getTranslation('shareWithPlants.Placeholder')}
                                    isDisabled={this.props.isLoadingPlants}
                                    isLoading={this.props.isLoadingPlants}
                                    maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                                    controlShouldRenderValue
                                />
                                {this.state.selectedPlantId && (
                                    <DeleteButtonWrapper onClick={this.handleRemovePlant}>
                                        <CircleDeleteIcon />
                                    </DeleteButtonWrapper>
                                )}
                            </Common.Row>
                            {this.state.selectedPlantId && (
                                <OptionalLabel>
                                    {this.getTranslation('shareWithPlants.Caution')}
                                </OptionalLabel>
                            )}
                        </Field>
                        <Field>
                            <Label>
                                {this.getTranslation('shareWithUsers.Label')}
                                <OptionalLabel>{this.getTranslation('optional')}</OptionalLabel>
                            </Label>
                            <Common.Row
                                style={{
                                    marginTop: 0,
                                }}
                            >
                                <SelectWithInfiniteScroll
                                    ref={this.userSelectRef}
                                    placeholder={this.getTranslation('shareWithUsers.Placeholder')}
                                    noOptionsMessage={this.getTranslation('shareWithUsers.NoUsers')}
                                    isLoading={this.props.isLoadingUsers}
                                    onSelect={this.handleSelectUser}
                                    queryData={this.props.userQueryData}
                                    queryPage={this.props.queryUsers}
                                    dataIdKey="id"
                                    dataLabelKey="email"
                                    filterSelect={this.handleFilterSelectableUser}
                                    additionalSearchCriteria={defaultSearchCriteria}
                                />
                                <PrimaryButton
                                    text={this.getTranslation('shareWithUsers.AddUserButton')}
                                    onClick={this.handleAddUser}
                                    disabled={!this.state.selectedUser}
                                    style={{
                                        flexShrink: 0,
                                        marginLeft: '10px',
                                    }}
                                />
                            </Common.Row>
                            {this.renderUserAccesses()}
                        </Field>
                    </Body>
                    <Footer>
                        {this.renderErrors()}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                width: '100%',
                            }}
                        >
                            <TertiaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.cancelButton',
                                })}
                                onClick={this.props.onCloseModal}
                            />
                            <PrimaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.saveButton',
                                })}
                                loading={this.props.isUpdatingIsotherm}
                                onClick={this.handleConfirm}
                            />
                        </div>
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

const mapStateToProps = (_, props: Props) =>
    createStructuredSelector({
        plants: selectAllPlants(),
        isLoadingPlants: selectPlantsAreFetching(),

        userQueryData: selectUserQuery(),
        totalPagesForQuery: selectTotalPageCount(), // for the current query
        usersLeft: selectUsersLeftCount(),
        isLoadingUsers: selectUsersAreQuerying(),

        isUpdatingIsotherm: selectIsothermsAreUpdating(),
        isothermUpdateErrors: selectIsothermErrors(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllPlants,
            queryUsers,
            setIsothermShareAccess,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(IsothermShareModal));
