// @flow strict

import { ISOTHERM_NAME_MAX_LENGTH } from 'services/Isotherm/types';

/**
 * Isotherm name must not be null, and must be below the max length.
 * There is also a min length (at least 1 char) but is confirmed elsewhere.
 *
 * @param {string} isothermName
 * @returns the newly validated isotherm name.
 */
export const validateIsothermName = (isothermName: string | null): string => {
    // Validate against null:
    if(!isothermName) {
        return '';
    }
    // Validate max length:
    if(isothermName.length > ISOTHERM_NAME_MAX_LENGTH) {
        return isothermName.substring(0, ISOTHERM_NAME_MAX_LENGTH);
    }
    return isothermName;
};
