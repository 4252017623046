// @flow strict

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Styles
import { ContainerCentered } from 'styles/common';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
};

const NotFound = (props: Props) => (
    <React.Fragment>
        <Helmet>
            <title>
                {props.intl.formatMessage({
                    id: 'views.NotFound.helmetTitle',
                })}
            </title>
        </Helmet>
        <ContainerCentered>
            <div>
                <h4>
                    {props.intl.formatMessage({
                        id: 'views.NotFound.notExist',
                    })}
                </h4>
                <Link to="/">
                    {props.intl.formatMessage({
                        id: 'views.NotFound.returnToDashboard',
                    })}
                </Link>
            </div>
        </ContainerCentered>
    </React.Fragment>
);

export default injectIntl(NotFound);
