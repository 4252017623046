// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Authentication
import {
    Column,
    SecondaryButton,
    PrimaryButton,
    Trash,
    CheckBox,
    ButtonHover,
} from 'components/_ReactUI_V1';

import { isSolvayUser } from 'utils/authentication';

// Constants
import { MAX_ISOTHERM_SELECT_COUNT } from 'utils/constants';

// Styles
import { SidebarBody, BodySection, FooterSection } from '../styles';
import {
    IsothermNavigationButtonStyles,
    Label,
    RowWrapper,
    CheckboxLabelWrapper,
    TrashStyles,
    CustomRow,
    IconsWrapper,
} from './styles';

// Components
import MultiList from 'components/MultiList';
import ShareIcon from 'assets/icons/share-icon';
import IsothermShareModal from 'components/Modals/IsothermShareModal';

// Types
import type { InputEvent, IntlType, ImmutableList } from 'types';
import type { ImmutableIsotherm, IsothermBaseConstant } from 'services/Isotherm/types';
import type { OpenDeleteIsothermModalFunction } from 'containers/IsothermManagementContainer/IsothermsContainer';
import type { ImmutableUser } from 'services/Authentication/types';

type ModalTypes = 'SHARE';

type OpenedModalType = {
    modalType: ModalTypes,
    isotherm: ImmutableIsotherm,
};

type Props = {
    intl: IntlType,

    isotherms: ImmutableList<ImmutableIsotherm>,
    loadingIsotherms: boolean,
    displayedIsotherms: ImmutableList<ImmutableIsotherm>,

    onOpenDeleteIsothermModal: OpenDeleteIsothermModalFunction,

    handleNewIsothermButtonClicked: (sidebarType: 'PREDICT' | 'CREATE') => void,
    handleGenerateDiagramClicked: (selectedIsotherms: Array<ImmutableIsotherm>) => void,

    user: ImmutableUser,
};

type State = {
    // Array containing IDs of the selected isotherms
    selectedIsotherms: Array<ImmutableIsotherm>,
    openedModal: ?OpenedModalType,
};

/*
    Isotherms Sidebar section for the Isotherm Management view
 */
class IsothermsSidebarSection extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        // Default values
        this.state = {
            selectedIsotherms: [],
            openedModal: null,
        };
    }

    // Check whether the isotherms that we have selected still exists in our next props,
    // when this component updates.
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const nextStateIsotherms = prevState.selectedIsotherms.filter(
            (stateIsotherm: ImmutableIsotherm) =>
                Boolean(
                    nextProps.isotherms.find(
                        (propsIsotherm: ImmutableIsotherm) => propsIsotherm === stateIsotherm
                    )
                )
        );
        return {
            selectedIsotherms: nextStateIsotherms,
        };
    }

    // Get isotherm rows for MultiList
    getIsothermRows = (isothermType: IsothermBaseConstant) =>
        this.props.isotherms
            // Filter by type, depending on the list being displayed
            .filter((isotherm: ImmutableIsotherm) => isotherm.get('isothermType') === isothermType)
            // Prepare each element for the list to handle
            .map((isotherm: ImmutableIsotherm) => ({
                id: isotherm.get('id'),
                render: this.renderIsothermContentRow(isotherm),
            }))
            .toArray();

    isMaxSelected = () => this.state.selectedIsotherms.length >= MAX_ISOTHERM_SELECT_COUNT;

    isSameTypeAsSelected = (isothermType: IsothermBaseConstant) =>
        this.state.selectedIsotherms.length === 0 ||
        Boolean(
            this.state.selectedIsotherms.find(
                (isotherm: ImmutableIsotherm) => isotherm.get('isothermType') === isothermType
            )
        );

    /**
     * The button is disabled when no isotherm is selected
     * Or when the isotherms displayed and selected are the same ones
     */
    isButtonDisabled = () => {
        // Are ALL the selected isotherms already displayed in graph ?
        const areSelectedIsothermAlreadyDisplayed = this.state.selectedIsotherms.every(
            (isotherm: ImmutableIsotherm) =>
                this.props.displayedIsotherms.find(
                    (displayedIsotherm: ImmutableIsotherm) => displayedIsotherm === isotherm
                )
        );
        // Are ALL the displayed isotherms also selected ?
        const areDisplayedisothermsSelected = this.props.displayedIsotherms.every(
            (displayedIsotherm: ImmutableIsotherm) =>
                this.state.selectedIsotherms.find(
                    (isotherm: ImmutableIsotherm) => displayedIsotherm === isotherm
                )
        );
        // If both const are true it means the displayed isotherms and selected ones are the same
        return (
            this.state.selectedIsotherms.length < 1 ||
            (areSelectedIsothermAlreadyDisplayed && areDisplayedisothermsSelected)
        );
    };

    handlePredictNewClicked = () => this.props.handleNewIsothermButtonClicked('PREDICT');

    handleCreateNewClicked = () => this.props.handleNewIsothermButtonClicked('CREATE');

    handleGenerateClicked = () =>
        this.props.handleGenerateDiagramClicked(this.state.selectedIsotherms);

    // Handle selection/de-selection of an isotherm
    handleIsothermToggling = (isotherm: ImmutableIsotherm) => (event: InputEvent) => {
        const isChecked = Boolean(event.target.checked);
        return this.setState((prevState: State) => {
            let selectedIsothermsImmutableList = prevState.selectedIsotherms.slice();
            if (isChecked) {
                selectedIsothermsImmutableList = selectedIsothermsImmutableList.filter(
                    (currentIsotherm: ImmutableIsotherm) => isotherm !== currentIsotherm
                );
            } else if (prevState.selectedIsotherms.length < 5) {
                // No more than 5 isotherms comparison at a time allowed
                selectedIsothermsImmutableList.push(isotherm);
            }
            return { selectedIsotherms: selectedIsothermsImmutableList };
        });
    };

    /**
     * Opens the share isotherm modal with the given isotherm.
     * @param {*} isotherm
     */
    handleOpenIsothermShareModal = (isotherm: ImmutableIsotherm) => () =>
        this.setState({
            openedModal: {
                modalType: 'SHARE',
                isotherm,
            },
        });

    /**
     * Closes any opened modal.
     */
    handleCloseModal = () =>
        this.setState({
            openedModal: null,
        });

    /**
     * Delete an isotherm when trash is clicked.
     */
    handleDeleteIsothermConfirm = (deleteIsotherm: ImmutableIsotherm) => () =>
        this.props.onOpenDeleteIsothermModal(deleteIsotherm);

    // render the specific row content for each row
    renderIsothermContentRow = (isotherm: ImmutableIsotherm) => () => {
        const isSelected = Boolean(
            this.state.selectedIsotherms.find(
                (currentIsotherm: ImmutableIsotherm) => currentIsotherm === isotherm
            )
        );
        const ownsIsotherm = this.props.user.get('id') === isotherm.get('userId');
        return (
            <RowWrapper>
                <CheckboxLabelWrapper>
                    <CheckBox
                        styles={{ margin: '16px 6px 16px 16px' }}
                        onClickHandler={this.handleIsothermToggling(isotherm)}
                        disabled={
                            !isSelected &&
                            (this.isMaxSelected() ||
                                !this.isSameTypeAsSelected(isotherm.get('isothermType')))
                        }
                        checked={isSelected}
                        stateless
                    />
                    <Label>{isotherm.get('name')}</Label>
                </CheckboxLabelWrapper>
                <IconsWrapper>
                    {isSolvayUser(this.props.user) && (
                        <ButtonHover
                            onClick={this.handleOpenIsothermShareModal(isotherm)}
                            style={TrashStyles}
                        >
                            <ShareIcon />
                        </ButtonHover>
                    )}
                    {ownsIsotherm && (
                        <ButtonHover
                            onClick={this.handleDeleteIsothermConfirm(isotherm)}
                            style={TrashStyles}
                        >
                            <Trash />
                        </ButtonHover>
                    )}
                </IconsWrapper>
            </RowWrapper>
        );
    };

    // Render Visualize or Compare button according to component state
    renderVisualizeOrCompareButton = () => (
        <PrimaryButton
            text={this.props.intl.formatMessage({
                id: `components.IsothermManagementSidebar.Isotherms.buttons.${
                    this.state.selectedIsotherms.length > 1 ? 'compare' : 'visualize'
                }`,
            })}
            style={{ width: '95px' }}
            onClick={this.handleGenerateClicked}
            disabled={this.isButtonDisabled()}
        />
    );

    renderModals = () => {
        if (!this.state.openedModal) {
            return null;
        }
        switch (this.state.openedModal.modalType) {
            case 'SHARE':
                return (
                    <IsothermShareModal
                        isotherm={this.state.openedModal.isotherm}
                        onCloseModal={this.handleCloseModal}
                    />
                );
            default:
                throw new Error('Unknown modal type.');
        }
    };

    render() {
        const solvayUser = isSolvayUser(this.props.user);
        return (
            <SidebarBody>
                {this.renderModals()}
                <CustomRow>
                    <Column>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id:
                                    'components.IsothermManagementSidebar.Isotherms.buttons.predictNew',
                            })}
                            style={IsothermNavigationButtonStyles}
                            onClick={this.handlePredictNewClicked}
                        />
                    </Column>
                    {solvayUser && (
                        <Column>
                            <SecondaryButton
                                text={this.props.intl.formatMessage({
                                    id:
                                        'components.IsothermManagementSidebar.Isotherms.buttons.createNew',
                                })}
                                style={IsothermNavigationButtonStyles}
                                onClick={this.handleCreateNewClicked}
                            />
                        </Column>
                    )}
                </CustomRow>
                <BodySection>
                    <MultiList
                        title={this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.Isotherms.multiLists.extraction',
                        })}
                        rows={this.getIsothermRows('EXTRACT')}
                        loading={this.props.loadingIsotherms}
                        withMargin
                    />
                    <MultiList
                        title={this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.Isotherms.multiLists.stripping',
                        })}
                        rows={this.getIsothermRows('STRIP')}
                        loading={this.props.loadingIsotherms}
                    />
                </BodySection>
                <FooterSection>{this.renderVisualizeOrCompareButton()}</FooterSection>
            </SidebarBody>
        );
    }
}

export default injectIntl(IsothermsSidebarSection);
