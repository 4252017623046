// @flow strict

import type { DataPoint } from '.';
import type { ImmutableKPIHistory } from 'services/Trends/types';
import { TARGET_TYPES } from 'utils/kpiConstants';

/**
 * Converts the list of points to an array of data point used by victory.
 * If data is limited to 1, pad it to display target
 */
export const mapArrayToDataPoint = (kpiHistory: ImmutableKPIHistory): Array<DataPoint> => {
    const dotSize = 6;
    const dataPointLimitForDotSize = 12;

    const data = kpiHistory
        .get('history')
        .reverse() // data points are received in order => createdAt DESC
        .map(
            (
                point: ImmutableKPIDataPoint,
                index: number,
                list: ImmutableList<ImmutableKPIDataPoint>
            ) => {
                const obj = {
                    x: point.get('createdAt'),
                    y: point.get('value'),
                };

                // If list size is over limit, return with x, y values
                if (list.size > dataPointLimitForDotSize) {
                    return obj;
                }

                // If list size is not over limit, add size property for scatter graph
                const previousIndex = index - 1;
                const previousValue = previousIndex !== -1 && list.getIn([previousIndex, 'value']);

                const nextIndex = index + 1;
                const nextValue = nextIndex < list.size && list.getIn([nextIndex, 'value']);

                const firstItem = index === 0;
                const lastItem = index === list.size - 1;

                return {
                    ...obj,
                    // Rules to cover datapoints discontinuity, in order to show the dot or not
                    size:
                        (!previousValue && !nextValue) ||
                        (previousValue && !nextValue && !lastItem) ||
                        (!previousValue && nextValue && !firstItem)
                            ? dotSize
                            : 0,
                };
            }
        )
        .toJS();

    // If we only have one data point, pad it to display target
    if (data.length === 1) {
        data[0].size = dotSize;
        data.unshift({ y: null, x: `${data[0].x.split(' ')} 00:00:00` });
        data.push({ y: null, x: `${data[data.length - 1].x.split(' ')} 23:59:59` });

        return data;
    }

    return data;
};

/**
 * Create domain; if dynamicDomain; get min and max y value and apply a 5% margin
 * Else, if the kpi has min/maxValues use those
 * Else return null so VictoryChart can create its own
 */
export const getDomain = (kpiSetting, data: Array<DataPoint>, dynamicDomain) => {
    let domain;

    const minValid = kpiSetting && kpiSetting.get('minValid');
    const maxValid = kpiSetting && kpiSetting.get('maxValid');

    const hasMinValid = minValid !== null && minValid !== undefined;
    const hasMaxValid = maxValid !== null && maxValid !== undefined;

    if (dynamicDomain && hasMinValid && hasMaxValid) {
        const values = data.map((d: DataPoint) => d.y).filter(Boolean);

        const min = Math.min(...values);
        const lowTarget = kpiSetting.get(TARGET_TYPES.LOW_TARGET);

        // Cap our lowest y value at our minValid
        let finalMin = minValid;
        if (lowTarget && min > lowTarget) {
            // If our min value is greater than our low target, adjust cap to target
            finalMin = lowTarget;
        } else if (min < lowTarget && min > minValid) {
            // If our min value is less than our target, but greater than the min valid
            // Adjust cap to our min value with tolerance
            finalMin = min - min * 0.05;
        }

        const max = Math.max(...values);
        const highTarget = kpiSetting.get(TARGET_TYPES.HIGH_TARGET);

        // Cap our highest y value at our maxValid
        let finalMax = maxValid;
        if (highTarget && max < highTarget) {
            // If our max value is less than the high target, adjust cap to target
            finalMax = highTarget;
        } else if (max > highTarget && max < maxValid) {
            // If our max value is greater than the high target, but less than the max valid,
            // Adjust cap to our max value with tolerance
            finalMax = max + max * 0.05;
        }

        domain = {
            y: [finalMin, finalMax],
        };
    } else if (hasMinValid && hasMaxValid) {
        domain = {
            y: [minValid, maxValid],
        };
    }

    return domain;
};
