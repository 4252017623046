// @flow

import React from 'react';

import { AvatarWrapper, Avatar } from './styles';

export function ProfileIcon(props: {
    id?: ?string,
    cursorPointer?: ?boolean,
    defaultImage: string,
    onClick?: ?() => void,
    profileImage?: string,
    size?: string,
}) {
    return (
        <AvatarWrapper id={props.id} cursorPointer={props.cursorPointer} onClick={props.onClick}>
            <Avatar backgroundSrc={props.profileImage || props.defaultImage} size={props.size} />
        </AvatarWrapper>
    );
}

ProfileIcon.defaultProps = {
    cursorPointer: false,
    id: null,
    onClick: null,
    profileImage: '',
    size: '26px',
};
