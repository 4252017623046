// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Containers
import CircuitElevationContainer from 'containers/CircuitElevationContainer';
import MinchemPreferencesFetcher from 'containers/MinchemPreferencesFetcher';

// Types
import type { IntlType, MatchType } from 'types';

type Props = {
    intl: IntlType,
    match: MatchType,
};

export class CircuitElevation extends React.PureComponent<Props, null> {
    static getPrevCircuitId(props: Props) {
        const circuitId = props.match.params.circuitId;
        if (circuitId) {
            return parseInt(circuitId, 10);
        }
        return null;
    }

    getCircuitId() {
        const circuitId = this.props.match.params.circuitId;
        if (circuitId) {
            return parseInt(circuitId, 10);
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.CircuitElevation.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.CircuitElevation.helmetDescription',
                        })}
                    />
                </Helmet>
                <MinchemPreferencesFetcher>
                    <CircuitElevationContainer circuitId={this.getCircuitId()} />
                </MinchemPreferencesFetcher>
            </React.Fragment>
        );
    }
}

export default injectIntl(CircuitElevation);
