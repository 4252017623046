// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';

export const Content = styled.div`
    color: ${legacyTheme.defaultColor};

    p {
        color: ${legacyTheme.defaultColor};
        font-size: 12px;
    }
`;

export const TooltipTrigger = styled.span`
    display: inline-block;
    width: ${({ size }: Object) => size || 16}px;
    height: ${({ size }: Object) => size || 16}px;

    line-height: ${({ size }: Object) => size || 16}px;
    text-align: center;

    font-size: 12px;

    background-color: ${legacyTheme.toolTipTrigger};
    border-radius: 50%;

    color: ${legacyTheme.toolTipTriggerColor};

    cursor: pointer;
`;
