// @flow strict

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';

import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import configureStore from './store';
import { translationMessages } from './i18n';

import { getFirebaseAuth } from 'utils/authentication';

// Create redux store with history
const initialState = {};
const history = createHistory({
    getUserConfirmation(dialogKey: string, callback: (allowed: boolean) => void) {
        // use "message" as Symbol-based key
        const dialogTrigger = window[Symbol.for(dialogKey)];

        if (dialogTrigger) {
            // pass the callback to delegate
            // to the invoked dialog
            return dialogTrigger(callback);
        }

        // Fallback to allowing navigation
        callback(true);
    },
});
history.listen(() => {
    const currentUser = getFirebaseAuth().currentUser;
    if (currentUser) {
        currentUser
            // Refresh users token on history change
            .getIdToken(true)
            .then((idToken: string) => {
                localStorage.setItem('api_token', idToken);
            })
            .catch((error: {}) => {
                console.error(error);
            });
    }
});

export const store = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById('app');

// TODO: Check if need to do special hot reloading for i18n files (like in react-boilerplate)
const renderApp = (messages: {}) => {
    console.log('App is running on', process.env.REACT_APP_ENV);
    ReactDOM.render(
        <Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>,
        MOUNT_NODE
    );
};

renderApp(translationMessages);

if (module.hot) {
    module.hot.accept('containers/App', () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        renderApp(translationMessages);
    });
}
