// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { MicroFrontEnds } from '../../microfrontendURIs';

import MicroFrontend from '..';
import { ContainerCentered, LayoutContainer } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';

import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
};

type State = {
    isUnLoadable: boolean,
};

class PlatformAuthenticationLogin extends React.PureComponent<Props, State> {
    state = {
        isUnLoadable: false,
    };

    static getHost = () => {
        return MicroFrontEnds.platformAuthenticationLogin; // must match the secret object's structure in GCP
    };

    static getUniqueName = () => {
        return 'PlatformAuthenticationLoginMFE'; // name must match the container name in the MFE code
    };

    getMountProps = () => {
        return {
            locale: this.props.intl.locale.toUpperCase(),
        };
    };

    onError = () => {
        this.setState({ isUnLoadable: true });
    };

    render() {
        if (this.state.isUnLoadable) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: `views.${PlatformAuthenticationLogin.getUniqueName()}.anErrorOccurredDuringLoad`,
                        })}
                    />
                </ContainerCentered>
            );
        }
        return (
            <MicroFrontend
                name={PlatformAuthenticationLogin.getUniqueName()}
                host={PlatformAuthenticationLogin.getHost()}
                mountedMicroFrontendProps={this.getMountProps()}
                onError={this.onError}
            />
        );
    }
}

export default injectIntl(PlatformAuthenticationLogin);
