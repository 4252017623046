// @flow strict

import styled, { css } from 'styled-components';

import { Common } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

export const ShowForDesktopOnlyCSS = css`
    display: block;
    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        display: none;
    }
`;

export const ShowForDesktopOnly = styled.div`
    ${ShowForDesktopOnlyCSS}
`;

export const ShowForMobileOnlyCSS = css`
    display: none;
    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        display: block;
    }
`;

export const ShowForMobileOnly = styled.div`
    ${ShowForMobileOnlyCSS}
`;

export const ColumnForDesktopOnly = styled(Common.Column)`
    ${ShowForDesktopOnlyCSS}
`;
