// @flow

import styled from 'styled-components';

import { themePropertyFetcher } from '../../tools/helpers';

export const StyledMessageBlockIcon = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 12px;

    min-width: 24px;
    width: 24px;
    height: 24px;
    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['messageBlock', 'icon', 'backgroundColor'])};
    border-radius: 50%;
`;

export const StyledMessageBlock = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    padding: ${({ theme }: Object) => themePropertyFetcher(theme, ['messageBlock', 'padding'])};
    background-color: ${({ color, theme }: Object) =>
        color || themePropertyFetcher(theme, ['messageBlock', 'backgroundColor'])};
    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['messageBlock', 'color'])};
    border-radius: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['messageBlock', 'borderRadius'])};

    ${StyledMessageBlockIcon} {
        > svg {
            fill: ${({ color, theme }: Object) =>
                color || themePropertyFetcher(theme, ['messageBlock', 'icon', 'color'])};
        }
    }
`;
