/* eslint-disable flowtype/no-weak-types */
// @flow strict

import React from 'react';

import { Common, LoadingDots } from 'components/_ReactUI_V1';

import type { TableHeaderType } from 'types';

// Styles
import {
    Card,
    CardTitle,
    CardBody,
    CardSection,
    CardSectionHeader,
    CardListWrapper,
    LoadingBlock,
} from './styles';

// Types
type Props = {
    controlsId?: string,
    header: Array<TableHeaderType>,
    loading: boolean,
    rows: Array<Object>,
    titleId?: string,
    cardMaxWidth?: string,
    cardMargin?: string,
    flexDirection?: string,
    solvExtract?: boolean,
};

export default class CardList extends React.PureComponent<Props> {
    render() {
        if (!this.props.rows || !this.props.header) {
            return null;
        }

        if (this.props.loading) {
            return (
                <LoadingBlock>
                    <LoadingDots />
                </LoadingBlock>
            );
        }

        const titleId = this.props.titleId || this.props.header[0].id;
        const filteredHeaders = [titleId, this.props.controlsId].filter(Boolean);

        const cards = this.props.rows.map(
            (row: Object) =>
                row && (
                    <Card
                        key={row.id || Object.keys(row)[0]}
                        cardMaxWidth={this.props.cardMaxWidth}
                        cardMargin={this.props.cardMargin}
                    >
                        <CardTitle>
                            <Common.Row alignItems="center">
                                <Common.Column alignItems="flex-start">
                                    {row[titleId]}
                                </Common.Column>
                                {row[this.props.controlsId] && (
                                    <Common.Column alignItems="flex-end">
                                        {row[this.props.controlsId]}
                                    </Common.Column>
                                )}
                            </Common.Row>
                        </CardTitle>
                        <CardBody solvExtract={this.props.solvExtract}>
                            {this.props.header
                                .filter((h: TableHeaderType) => !filteredHeaders.includes(h.id))
                                .map((h: TableHeaderType) => (
                                    <CardSection
                                        key={h.id}
                                        id={h.id}
                                        solvExtract={this.props.solvExtract}
                                    >
                                        {h.label && (
                                            <CardSectionHeader>{h.label}</CardSectionHeader>
                                        )}
                                        {row[h.id]}
                                    </CardSection>
                                ))}
                        </CardBody>
                    </Card>
                )
        );

        return <CardListWrapper flexDirection={this.props.flexDirection}>{cards}</CardListWrapper>;
    }
}
