// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { colors } from 'styles/colors';

export const HeaderTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 25px;
    padding-bottom: 40px;

    cursor: pointer;
    color: ${LegacyTheme.black};

    :hover {
        color: ${LegacyTheme.defaultColor};
    }
`;

export const HeaderTitle = styled.h2`
    font-size: 17px;
    font-weight: normal;
    margin: 0;
    line-height: 20px;
`;

export const SectionWrapper = styled.div`
    padding: 0 26px;
`;

export const SectionTitle = styled.h3`
    font-size: 17px;
    font-weight: normal;
    line-height: 20px;

    margin-top: 0;
    margin-bottom: 20px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    ${({ extraMargin }) =>
        extraMargin &&
        `
        margin-top: 16px;
    `};
`;

export const SectionInfoWrapper = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction};
    justify-content: space-between;

    width: 100%;

    ${({ direction }) =>
        direction === 'row' &&
        `
        & > ${InfoWrapper} {
            width: 50%;
        }
    `};
`;

export const Label = styled.span`
    margin-bottom: 15px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-size: 13px;
    line-height: 16px;
`;

export const Value = styled.span`
    font-size: 16px;
    color: ${colors.grey3A};
`;
