// @flow

import React from 'react';

import type { InputValueType } from '../../types';

import { Button, Item, Label, Wrapper } from './styles';

type OptionType = { label: string, value: InputValueType, danger?: boolean };

type Props = {
    bold?: boolean,
    disabled?: boolean,
    multiLine?: boolean,
    onClick?: ?() => void,
    options: Array<OptionType>,
    theme?: Object,
    value?: number,
};

// TODO: Replace prop.bold for more clear prop name, such as 'readOnly'

export class ButtonGrid extends React.PureComponent<Props> {
    static defaultProps = {
        bold: false,
        disabled: false,
        multiLine: false,
        onClick: null,
        theme: null,
        value: null,
    };

    render() {
        // Get current option based on this.props.value matching option.value
        const currentOption =
            this.props.options &&
            this.props.value &&
            this.props.options.find((option: OptionType) => option.value === this.props.value);
        const label = currentOption ? (
            <Label
                bold={this.props.bold}
                danger={currentOption.danger}
                hasValue={currentOption.value !== null}
                theme={this.props.theme}
            >
                {currentOption.label}
            </Label>
        ) : null;

        // Loop over options and create list of buttons
        const buttons =
            this.props.options &&
            this.props.options.map((option: OptionType) => (
                <Item key={option.value}>
                    <Button
                        active={option.value === this.props.value}
                        onClick={!this.props.disabled ? this.props.onClick : null}
                        theme={this.props.theme}
                        type="button"
                        value={option.value}
                    >
                        {option.label}
                    </Button>
                </Item>
            ));

        // If this.props.onClick is present return buttons, else static label
        return this.props.onClick ? (
            <Wrapper multiLine={this.props.multiLine}>{buttons}</Wrapper>
        ) : (
            label
        );
    }
}
