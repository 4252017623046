// @flow

import React from 'react';

// Components
import * as Common from '../../base';
import { PaginationControls } from './PaginationControls';

// Styles
import { StyledPagination } from './styles';

type Props = {
    currentPage?: number,
    isLoading?: boolean,
    pagesTotal: number,
    onPageSelection: () => void,
    summaryJoinner?: string,
    summaryPrefix?: string,
};

export default class Pagination extends React.PureComponent<Props> {
    static defaultProps = {
        currentPage: 1,
        isLoading: false,
        summaryJoinner: 'to',
        summaryPrefix: 'Page',
    };

    render() {
        if (!this.props.onPageSelection || !this.props.pagesTotal) {
            return null;
        }
        const summary = `${this.props.summaryPrefix || ''} ${this.props.currentPage || ''} ${this
            .props.summaryJoinner || ''} ${this.props.pagesTotal}`;

        return (
            <StyledPagination>
                <Common.Row alignItems="center">
                    <Common.Column>{summary}</Common.Column>
                    <Common.Column flex="3" justifyContent="center">
                        <PaginationControls
                            currentPage={this.props.currentPage}
                            isLoading={this.props.isLoading}
                            pagesTotal={this.props.pagesTotal}
                            onPageSelection={this.props.onPageSelection}
                            {...this.props}
                        />
                    </Common.Column>
                    <Common.Column />
                </Common.Row>
            </StyledPagination>
        );
    }
}
