// @flow

import styled, { css } from 'styled-components';
import defaultTheme from '../../themes/defaultTheme';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

export const HiddenInput = styled.input`
    display: none;
`;

export const RadiobuttonWrapper = styled.div`
    display: flex;
    flex-direction: ${({ flexDirection }: Object) => flexDirection};
    overflow: ${({ wrapperOverflow }: Object) => wrapperOverflow};
`;

export const Label = styled.span`
    overflow: ${({ wrapperOverflow }: Object) => wrapperOverflow};
    text-overflow: ellipsis;
`;

export const RadiobuttonLabel = styled.label`
    display: flex;
    align-items: center;
    flex-shrink: 1;

    color: ${({ disabled, labelColor, theme }: Object) =>
        disabled
            ? themePropertyFetcher(theme, ['formElements', 'radioButton', 'colorDisabled']) ||
              defaultTheme.radiobuttonLabelDisabledColor
            : labelColor ||
              themePropertyFetcher(theme, ['formElements', 'radioButton', 'color']) ||
              defaultTheme.radiobuttonLabelColor};

    cursor: ${({ onClick }: Object) => (onClick ? 'pointer' : 'default')};

    font-size: ${({ labelFontSize }: Object) => labelFontSize || '15px'};

    ${({ labelMinWidth }: Object) => labelMinWidth && `min-width: ${labelMinWidth};`}

    > span {
        vertical-align: sub;
        &:first-child {
            margin-right: 7.5px;
        }
    }

    img {
        padding-top: 2px;
    }
`;

export const Radiobutton = styled.span`
    position: relative;
    flex-shrink: 0;

    height: 14px;
    width: 14px;
    display: inline-block;

    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['formElements', 'radioButton', 'backgroundColor']) ||
        defaultTheme.radiobuttonBackground};
    border: 1px solid
        ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['formElements', 'radioButton', 'border']) ||
            defaultTheme.radiobuttonBorder};
    border-radius: 50%;

    cursor: ${({ disabled }: Object) => !disabled && 'pointer'};

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;

        display: block;
        width: 8px;
        height: 8px;

        margin-left: -4px;
        margin-top: -4px;

        border-radius: 50%;
    }

    ${RadiobuttonLabel}:hover & {
        ${({ disabled, theme }: Object) =>
            !disabled &&
            css`
                background-color: ${themePropertyFetcher(theme, [
                    'formElements',
                    'radioButton',
                    'backgroundColorHover',
                ]) || defaultTheme.radiobuttonHoverBackground};
                border-color: ${themePropertyFetcher(theme, [
                    'formElements',
                    'radioButton',
                    'borderHover',
                ]) || defaultTheme.radiobuttonHoverBorder};
            `};

        ${({ checked, disabled, theme }: Object) =>
            checked &&
            !disabled &&
            css`
                &:after {
                    background-color: ${themePropertyFetcher(theme, [
                        'formElements',
                        'radioButton',
                        'dotColorActive',
                    ]) || defaultTheme.radiobuttonActive};
                }
            `};
    }

    ${({ checked, disabled, theme }: Object) =>
        checked &&
        css`
            &:after {
                background-color: ${disabled
                    ? themePropertyFetcher(theme, [
                          'formElements',
                          'radioButton',
                          'dotColorDisabled',
                      ]) || defaultTheme.radiobuttonLabelDisabledColor
                    : themePropertyFetcher(theme, [
                          'formElements',
                          'radioButton',
                          'dotColorActive',
                      ]) || defaultTheme.radiobuttonActive};
            }
        `};
`;

export const StyledRadioButtonSet = styled.div`
    > label,
    ${RadiobuttonWrapper} {
        &:not(:last-child) {
            margin-bottom: ${({ orientation }: Object) =>
                orientation === 'HORIZONTAL' ? '0' : '15px'};
            margin-right: ${({ orientation }: Object) =>
                orientation === 'HORIZONTAL' ? '15px' : '0'};
        }
    }

    display: flex;
    flex-direction: ${({ orientation }: Object) =>
        orientation === 'HORIZONTAL' ? 'row' : 'column'};
`;
