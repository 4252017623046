// @flow strict

import { fromJS } from 'immutable';

// Actions
import {
    DISCLAIMERS_IS_FETCHING,
    DISCLAIMERS_FETCH_SUCCESS,
    DISCLAIMERS_FETCH_FAIL,
    DISCLAIMER_CREATE_SUCCESS,
    DISCLAIMER_CREATE_FAIL,
    DISCLAIMER_IS_CREATING,
} from './actions';

// Types
import type { GenericActionType } from 'types';
import type { ImmutableDisclaimer, ImmutableDisclaimerState } from 'services/Disclaimer/types';

const initialState = fromJS({
    disclaimersList: [],
    isFetching: false,
    isCreating: false,
    errors: null,
});

function disclaimerServiceReducer(
    state: ImmutableDisclaimerState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case DISCLAIMER_IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);

        case DISCLAIMERS_IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);

        case DISCLAIMERS_FETCH_SUCCESS:
            return state
                .set('disclaimersList', fromJS(action.payload.disclaimersList))
                .set('isFetching', false);

        case DISCLAIMERS_FETCH_FAIL:
            return state.set('isFetching', false).set('errors', fromJS(action.payload.errors));

        case DISCLAIMER_CREATE_SUCCESS: {
            const updatedDisclaimer = fromJS(action.payload.disclaimer);
            const idx = state
                .getIn(['disclaimersList'])
                .findIndex(
                    (disclaimer: ImmutableDisclaimer) =>
                        disclaimer.get('type') === updatedDisclaimer.get('type')
                );
            return state
                .setIn(['disclaimersList', idx], updatedDisclaimer)
                .set('isCreating', false)
                .set('errors', fromJS(null));
        }
        case DISCLAIMER_CREATE_FAIL:
            return state.set('isCreating', false).set('errors', fromJS(action.payload.errors));

        default:
            return state;
    }
}

export default disclaimerServiceReducer;
