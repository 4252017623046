// @noflow

// TODO: Refactor to use flow over prop-types

import React from 'react';
import PropTypes from 'prop-types';
import { HandleTooltip, handleTooltipComp } from './styles';

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
    divOrButton: Comp,
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
    disabled,
}: Object) {
    return (
        <Comp
            role="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            style={handleTooltipComp(percent, disabled)}
            {...getHandleProps(id)}
        >
            <HandleTooltip value={value}>{value}%</HandleTooltip>
        </Comp>
    );
}

Handle.propTypes = {
    divOrButton: PropTypes.oneOf(['div', 'button']), // button allows keyboard events
    domain: PropTypes.arrayOf(PropTypes.number).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        percent: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }).isRequired,
};

Handle.defaultProps = {
    divOrButton: 'div',
};
