// @flow strict

import React from 'react';

// Style
import { StreamValuesWrapper } from './styles';

// Constants
import { DIAGRAM_DISPLAY_MODES, STAGE_TYPES } from 'utils/constants';
import { COMPUTE_GRID, SETUP_GRID } from 'components/MimicDiagram/constants';

// Components
import StreamValueInputBox from 'components/MimicDiagram/Stream/StreamValueInputBox';

// Types
import type { UnitsConstant } from 'types';
import type { StreamEntity, StageTypeConstant } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    streamData: StreamEntity,
    circuitUnits: UnitsConstant,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,

    // Bleeds have a fromStage
    fromStageColumn: number,
    fromStageRow: number,
    fromStageType: StageTypeConstant,
    // Bleeds dont have a toStage.

    values: ?Array<LooseStreamValue>,

    setStreamValue: SetStreamValueFunction,
};

/**
 * Vertical Stream Values are those that belong to organic circuit and placed between two stages of different type
 * on the vertical organic stream path.
 */
export default class BypassBleedStream extends React.PureComponent<Props, null> {
    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * Get the starting column of the stream values.
     */
    getStartingColumn = () => {
        const grid = this.getGrid();
        switch (this.props.fromStageType) {
            case STAGE_TYPES.EXTRACT:
                return this.props.fromStageColumn - grid.STREAM_VALUES.COLUMN_SPAN;
            case STAGE_TYPES.ORGANIC_TANK:
                return (
                    this.props.fromStageColumn -
                    grid.STREAM_VALUES.COLUMN_SPAN +
                    grid.TANK_BYPASS_STREAM.BLEED_HORIZONTAL_OFFSET_FROM_TANK
                );
            case STAGE_TYPES.STRIP:
                return this.props.fromStageColumn + grid.STAGE.COLUMN_SPAN;
            default:
                throw new Error(`Unknown stage type: ${this.props.fromStageType}`);
        }
    };

    /**
     * Calculate stream value starting row position on the CSS grid
     */
    getStartingRow = () => {
        const grid = this.getGrid();
        switch (this.props.fromStageType) {
            case STAGE_TYPES.EXTRACT:
                return this.props.fromStageRow + grid.STAGE.ROW_SPAN / 2;
            case STAGE_TYPES.ORGANIC_TANK:
                return this.props.fromStageRow + grid.TANK.ROW_SPAN;
            case STAGE_TYPES.STRIP:
                return this.props.fromStageRow;
            default:
                throw new Error(`Unknown stage type: ${this.props.fromStageType}`);
        }
    };

    getColumnSpan = () => {
        if (this.props.fromStageType === STAGE_TYPES.ORGANIC_TANK) {
            return COMPUTE_GRID.TANK_BYPASS_STREAM.COLUMN_SPAN;
        }
        return COMPUTE_GRID.BYPASS_STREAM.COLUMN_SPAN;
    };

    getRowSpan = () => {
        if (this.props.fromStageType === STAGE_TYPES.ORGANIC_TANK) {
            return COMPUTE_GRID.TANK_BYPASS_STREAM.ROW_SPAN;
        }
        return COMPUTE_GRID.BYPASS_STREAM.BLEED_ROW_SPAN;
    };

    renderStreamValues = () =>
        this.props.values.map((streamValue: LooseStreamValue) => (
            <StreamValuesWrapper
                key={`${streamValue.valueType}`}
                startingColumn={this.getStartingColumn()}
                startingRow={this.getStartingRow()}
                columnSpan={this.getColumnSpan()}
                rowSpan={this.getRowSpan()}
            >
                <StreamValueInputBox
                    streamValue={streamValue}
                    circuitUnits={this.props.circuitUnits}
                    streamCircuit={this.props.streamData.streamCircuit}
                    setStreamValue={this.props.setStreamValue}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                />
            </StreamValuesWrapper>
        ));

    render() {
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            return null; // there is nothing else in setup mode for bypass bleeds.
        } else {
            return this.renderStreamValues();
        }
    }
}
