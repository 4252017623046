// @flow

import React from 'react';

// Styles
import { TabNavItem, TabNavList } from './styles';

type NavItemType = {
    label: string,
    to: string,
};

type Props = {
    current?: string,
    demo?: boolean,
    maxWidth?: number,
    minWidth?: number,
    options: Array<NavItemType>,
    theme?: ?{},
};

export class TabNav extends React.PureComponent<Props> {
    static defaultProps = {
        current: '',
        demo: false,
        maxWidth: null,
        minWidth: null,
        theme: null,
    };

    render() {
        const { options } = this.props;

        if (!options) {
            return null;
        }

        const items =
            options &&
            options.map((option: NavItemType) => (
                <TabNavItem
                    key={option.to}
                    current={option.to === this.props.current}
                    demo={this.props.demo}
                    to={option.to}
                    theme={this.props.theme}
                >
                    {option.label}
                </TabNavItem>
            ));

        return (
            <TabNavList
                minWidth={this.props.minWidth}
                maxWidth={this.props.maxWidth}
                theme={this.props.theme}
            >
                {items}
            </TabNavList>
        );
    }
}
