// @flow

import styled from 'styled-components';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

export const Label = styled.div`
    color: ${({ hasValue, theme }: Object) =>
        themePropertyFetcher(theme, [
            'buttons',
            'buttonGrid',
            'label',
            hasValue ? 'colorActive' : 'color',
        ])};
    font-size: 14px;
    font-weight: ${({ hasValue }: Object) => (hasValue ? 'bold' : 'normal')};
    line-height: 20px;

    ${({ bold, danger, theme }: Object) =>
        bold &&
        `
            width: 100%;
            height: 30px;
            line-height: 29px;
            padding: 0 5px;

            background-color: ${themePropertyFetcher(theme, [
                'buttons',
                'buttonGrid',
                'label',
                danger ? 'backgroundColorDanger' : 'backgroundColorActive',
            ])};
            border: 1px solid ${themePropertyFetcher(theme, [
                'buttons',
                'buttonGrid',
                'label',
                danger ? 'borderColorDanger' : 'borderColorActive',
            ])};

            border-radius: 15px;
            color: ${themePropertyFetcher(theme, [
                'buttons',
                'buttonGrid',
                'label',
                danger ? 'colorDanger' : 'colorActive',
            ])};

            font-size: 16px;
            font-weight: normal;

            text-align: center;

            box-sizing: border-box;
        `};
`;

export const Button = styled.button`
    width: 100%;
    height: 100%;

    background-color: ${({ active, theme }: Object) =>
        themePropertyFetcher(theme, [
            'buttons',
            'buttonGrid',
            'button',
            active ? 'backgroundColorActive' : 'backgroundColor',
        ])};
    overflow: hidden;

    ${({ active, onClick, theme }: Object) =>
        onClick
            ? `
        color: ${themePropertyFetcher(theme, [
            'buttons',
            'buttonGrid',
            'button',
            active ? 'colorActive' : 'color',
        ])};
        &:hover {
            background-color: ${themePropertyFetcher(theme, [
                'buttons',
                'buttonGrid',
                'backgroundColorHover',
            ])};
        }
    `
            : `
        color: ${themePropertyFetcher(theme, [
            'buttons',
            'buttonGrid',
            'button',
            active ? 'colorActive' : 'color',
        ])};
    `};

    font-weight: ${({ active }: Object) => (active ? 'bold' : 'normal')};
    border: 0;
    cursor: ${({ onClick }: Object) => onClick && 'pointer'};
    &:focus {
        outline: none;
    }
`;

export const Item = styled.div`
    display: flex;
    flex: 1;
    flex-basis: auto;
    justify-content: center;

    width: auto;
    padding: 0 !important;

    ${Button} {
        height: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['buttons', 'buttonGrid', 'height'])}px;
        border: 1px solid
            ${({ theme }: Object) =>
                themePropertyFetcher(theme, ['buttons', 'buttonGrid', 'button', 'borderColor'])};
        border-right-width: 0px;
        font-size: 13px;
    }

    &:first-child {
        ${Button} {
            border-top-left-radius: ${({ theme }: Object) => {
                const heightValue = themePropertyFetcher(theme, [
                    'buttons',
                    'buttonGrid',
                    'height',
                ]);
                return typeof heightValue === 'number' ? `${heightValue / 2}px` : heightValue;
            }};
            border-bottom-left-radius: ${({ theme }: Object) => {
                const heightValue = themePropertyFetcher(theme, [
                    'buttons',
                    'buttonGrid',
                    'height',
                ]);
                return typeof heightValue === 'number' ? `${heightValue / 2}px` : heightValue;
            }};
        }
    }

    &:last-child {
        ${Button} {
            border-top-right-radius: ${({ theme }: Object) => {
                const heightValue = themePropertyFetcher(theme, [
                    'buttons',
                    'buttonGrid',
                    'height',
                ]);
                return typeof heightValue === 'number' ? `${heightValue / 2}px` : heightValue;
            }};
            border-bottom-right-radius: ${({ theme }: Object) => {
                const heightValue = themePropertyFetcher(theme, [
                    'buttons',
                    'buttonGrid',
                    'height',
                ]);
                return typeof heightValue === 'number' ? `${heightValue / 2}px` : heightValue;
            }};
            border-right: 1px solid
                ${({ theme }: Object) =>
                    themePropertyFetcher(theme, [
                        'buttons',
                        'buttonGrid',
                        'button',
                        'borderColor',
                    ])};
        }
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    margin-top: 5px;

    ${({ multiLine, theme }: Object) => {
        if (!multiLine) {
            return '';
        }

        const heightValue = themePropertyFetcher(theme, ['buttons', 'buttonGrid', 'height']);
        const borderRadius = typeof heightValue === 'number' ? `${heightValue / 2}px` : heightValue;

        return `
            ${Item} {
                &:first-child {
                    ${Button} {
                        border-bottom-left-radius: 0;
                    }
                }

                &:nth-child(2) {
                    ${Button} {
                        border-right: 1px solid
                            ${themePropertyFetcher(theme, [
                                'buttons',
                                'buttonGrid',
                                'button',
                                'borderColor',
                            ])};
                        border-top-right-radius: ${borderRadius};
                    }
                }

                &:last-child {
                    width: 100%;
                    ${Button} {
                        border-top-width: 0;
                        border-top-right-radius: 0;
                        border-bottom-left-radius: ${borderRadius};
                        border-bottom-right-radius: ${borderRadius};
                    }
                }
            }
        `;
    }};
`;
