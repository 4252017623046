// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Message = styled.p`
    color: ${({ isRed }) => (isRed ? LegacyTheme.defaultWarningColor : LegacyTheme.defaultColor)};
    font-size: ${({ isSmall }) => (isSmall ? '13px' : '1em')};
    ${({ noMargins }) => noMargins && 'margin: 0;'}
`;
