// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Container
import DisclaimerManagementContainer from 'containers/DisclaimerManagementContainer';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
};

export class DisclaimerManagement extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.DisclaimerManagement.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.DisclaimerManagement.helmetDescription',
                        })}
                    />
                </Helmet>
                <DisclaimerManagementContainer />
            </React.Fragment>
        );
    }
}

export default injectIntl(DisclaimerManagement);
