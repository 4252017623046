// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

// Styles
import type { ChildrenArray } from 'react';

import { ContainerCentered, DetailsErrorBlock } from 'styles/common';
import { ErrorMessageWrapper } from './styles';

// Services
import { reportError } from 'services/Errors/thunks';

// Types
import type { IntlType, ReactNode, ErrorType, ReduxDispatch } from 'types';

type Props = {
    intl: IntlType,
    children: ChildrenArray<ReactNode> | ReactNode,
    reportError: (error: ErrorType) => void,
};

type State = {
    error: ?any,
    errorInfo: ?any,
};

/**
 * An error boundary is a component that catches error in the React DOM while rendering.
 * Instead of crashing the entire app, the error boundary's componentDidCatch is triggered and
 * the error boundary's render method is called instead of the crashed component.
 * Multiple error boundaries can be placed within the app to create an error handling hierarchy.
 */
class MimicDiagramErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.props.reportError({
            error,
            message: error
                ? error.toString()
                : 'Something went wrong while rendering the MimicDiagram',
            stackTrace: errorInfo.componentStack || 'No componentStack provided',
            type: 'mimic-diagram-error',
        });
        this.setState({
            error,
            errorInfo,
        });
    }

    /**
     * Reset the state of errors when a user navigates back to the home page.
     */
    handleResetState = () =>
        this.setState({
            error: null,
            errorInfo: null,
        });

    render() {
        // Do we have an error?
        if (this.state.errorInfo) {
            // Display the default error screen.
            return (
                <ContainerCentered>
                    <ErrorMessageWrapper>
                        <h3>
                            {this.props.intl.formatMessage({
                                id: 'components.MimicDiagram.Error.title',
                            })}
                        </h3>
                        {this.state.errorInfo && (
                            <DetailsErrorBlock>
                                {this.state.error && this.state.error.toString()}
                                <br />
                                {this.state.errorInfo.componentStack}
                            </DetailsErrorBlock>
                        )}
                        <p>
                            {this.props.intl.formatMessage({
                                id: 'components.MimicDiagram.Error.message',
                            })}
                        </p>
                    </ErrorMessageWrapper>
                </ContainerCentered>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            reportError,
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(injectIntl(MimicDiagramErrorBoundary));
