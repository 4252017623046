// @flow strict

import React from 'react';
import domtoimage from 'dom-to-image';
import { isEmpty, isEqual, xorWith } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';

import { API_URL, API_VER } from 'env';

// Constants
import {
    STAGE_TYPES,
    STREAM_TYPES,
    STREAM_CIRCUITS,
    STREAM_VALUE_TYPES,
    DATASET_VALUE_TYPES,
    OXIME_CONCENTRATION_DATA_POINT_COUNT,
    ISOTHERM_VALUE_MODES,
    ADVANCED_STREAMS_SETUP_TYPES,
    SUCCESS_TYPE,
    ERROR_TYPE,
    STREAM_ARROW_FORWARD,
    STAGE_TYPES_WITH_ISOTHERMS,
} from 'utils/constants';

// Helpers
import { getCurrentTimeZone, getFormattedDateFromString } from 'utils/dateHelpers';

// Styles
import { Code } from 'styles/common';

// Types
import type {
    AdvancedStreamTypeConstant,
    ImmutableCircuit,
    ImmutableStage,
    ImmutableStream,
    LooseStage,
    LooseStream,
    RelatedStages,
    Stage,
    StageTypeConstant,
} from 'services/Circuit/types';
import type { ImmutableUser } from 'services/Authentication/types';
import type {
    ImmutableIsothermStageValue,
    ImmutableDataset,
    StreamValuesConstant,
    DatasetValuesConstant,
} from 'services/Dataset/types';
import type { ImmutableIsotherm } from 'services/Isotherm/types';
import type { ImmutableMap, ImmutableList, LooseNumberType, ReactNode, IntlType } from 'types';
import type { ImmutableDataPoint } from 'services/Oxime/types';
import type { FeedbackType } from 'services/Feedback/types';
import type { ImmutableDisclaimer } from 'services/Disclaimer/types';

export function parseJwt(token: string) {
    if (!token) {
        return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
}

// Function to clamp a number (num) between a min and a max number
export function clamp(num: number, min: ?number, max: ?number) {
    if (!max) max = Infinity;
    if (!min) min = -Infinity;
    return Math.min(Math.max(num, min), max);
}

export function tryParseNumberOrNull(numberStr: LooseNumberType): number | null {
    // if numberStr is null or '', return null early as Number(null) and Number('') returns 0;
    if (numberStr === null || numberStr === '') {
        return null;
    }
    try {
        return Number(numberStr);
    } catch (error) {
        return null;
    }
}

/**
 * Tries to parse a number, if it is not a number, throws an error.
 */
export function tryParseNumber(numberStr: LooseNumberType) {
    try {
        return Number(numberStr);
    } catch (error) {
        throw new Error(`Cannot parse number provided: ${error.message}`);
    }
}

/**
 * Returns true or false if the length of the significant figures (right side of decimal place) is greater than the supplied limit
 * User case would be to limit the precision of an input
 */
export const isValueOverSigFigureLimitOf = (value: LooseNumberType, limit: number) => {
    if (value === null) {
        return false;
    }

    const valueParts = value.toString().split('.');
    if (valueParts[1]) {
        return valueParts[1].length > limit;
    }

    return false;
};

// Function to be used in order to round a number to a number of decimal place.
// Multiplies float by some power of 10 (subsequently reversing it for the final output) in order to leverage Math.round()
export function round(value: LooseNumberType, decimalPlaces?: LooseNumberType = 2) {
    tryParseNumber(value);

    // In case the provided decimalPlaces is null rather than undefined, resort to default of 2
    const precision = decimalPlaces !== null ? decimalPlaces : 2;

    // return Number(Math.round(Number(value+'e'+decimalPlaces))+'e-'+decimalPlaces);
    const wholeNumber = Math.round(Number(`${value}e${precision}`));

    return Number(`${wholeNumber}e-${precision}`);
}

/**
 * Round to the provided nearest whole number
 */
export const roundToNearest = (value: LooseNumberType, roundTo: number) => {
    tryParseNumber(value);
    if (roundTo < 1) {
        const decimalPlaces = roundTo.toString().split('.')[1].length;
        const precision = Math.pow(10, decimalPlaces);
        return Number((Math.round(Number(value) * precision) / precision).toFixed(decimalPlaces));
    }
    return Math.round(Number(value) / roundTo) * roundTo;
};

/**
 * If the number is null or it isn't a number, our input wants an empty string.
 * Otherwise return the number value.
 */
export const formatNumberAsStringValue = (value: ?number) => {
    if (value === null || Number.isNaN(value)) return '';
    return value;
};

export const getStageKey = (stage: LooseStage): LooseNumberType => {
    if (stage.stageType === STAGE_TYPES.ORGANIC_TANK) {
        return `${stage.stageType.substring(0, 2)} TK${stage.location}`;
    }
    return `${stage.stageType.charAt(0)}${stage.location}`;
};

export const getStageFromKey = (stageKey: LooseNumberType, stages: Array<LooseStage>) =>
    stages.find((stage: LooseStage) => getStageKey(stage) === stageKey);

export const getStreamKey = (stream: LooseStream): LooseNumberType => {
    const fromPortion = `${stream.fromStageId ||
        (stream.from ? `${stream.from.stageType}-${stream.from.location}` : 'null')}`;
    const toPortion = `${stream.toStageId ||
        (stream.to ? `${stream.to.stageType}-${stream.to.location}` : 'null')}`;

    return `${stream.streamCircuit}-${stream.streamType}-${fromPortion}-${toPortion}`;
};

export const getStageCode = (stage: LooseStage): string => {
    if (stage.stageType === STAGE_TYPES.ORGANIC_TANK) {
        return `${stage.stageType.substring(0, 2)} TK${stage.location}`;
    }
    return `${stage.stageType.charAt(0)}${stage.location}`;
};

// Return "Stream Code" with from and to stages, if neither are provided return null;
export const getStreamCode = (fromStageCode: string = '', toStageCode: string = ''): string => {
    if (toStageCode && fromStageCode) {
        return `${fromStageCode}${STREAM_ARROW_FORWARD}${toStageCode}`;
    }

    if (toStageCode) {
        return `${STREAM_ARROW_FORWARD}${toStageCode}`;
    }

    if (fromStageCode) {
        return `${fromStageCode}${STREAM_ARROW_FORWARD}`;
    }

    return '';
};

export const getIsothermKey = (isotherm: ImmutableIsotherm): LooseNumberType => {
    if (isotherm.has('id')) {
        return isotherm.get('id');
    }

    const stageStart = isotherm.get('stageStart').toJS();
    const stageEnd = isotherm.get('stageEnd').toJS();

    return `${getStageKey(stageStart)}-${getStageKey(stageEnd)}`;
};

export const hasExtractorStages = (circuit: ImmutableCircuit): boolean =>
    Boolean(
        circuit
            .get('stages')
            .find((stage: ImmutableStage) => stage.get('stageType') === STAGE_TYPES.EXTRACT)
    );

export const getExtractorStages = (circuit: ImmutableCircuit): ImmutableList<ImmutableStage> =>
    circuit.get('stages') &&
    circuit
        .get('stages')
        .filter((stage: ImmutableStage) => stage.get('stageType') === STAGE_TYPES.EXTRACT);

export const hasStripperStages = (circuit: ImmutableCircuit): boolean =>
    Boolean(
        circuit
            .get('stages')
            .find((stage: ImmutableStage) => stage.get('stageType') === STAGE_TYPES.STRIP)
    );

export const getStripperStages = (circuit: ImmutableCircuit): ImmutableList<ImmutableStage> =>
    circuit.get('stages') &&
    circuit
        .get('stages')
        .filter((stage: ImmutableStage) => stage.get('stageType') === STAGE_TYPES.STRIP);

export const getStreamById = (circuit: ImmutableCircuit, id: number): ImmutableStream =>
    circuit.get('streams').find((stream: ImmutableStream) => stream.get('id') === id);

export const getStageById = (circuit: ImmutableCircuit, id: ?number): ImmutableStage =>
    circuit.get('stages').find((stage: ImmutableStage) => stage.get('id') === id);

export const getStageByDescription = (
    circuit: ImmutableCircuit,
    stageType: StageTypeConstant,
    location: number
) =>
    circuit
        .get('stages')
        .find(
            (stage: ImmutableStage) =>
                stage.get('location') === location && stage.get('stageType') === stageType
        );

/**
 * Get all advanced streams in the circuit.
 * Advanced streams include: skips, bypasses, blends, feeds and bleeds between stages and continue streams that
 * go or come from the same stages as blends, bypasses, skips and advanced bleeds.
 * @param {ImmutableCircuit} circuit The circuit for which we want to get all the advanced streams
 */
export const getStreamAdvancedType = (
    stream: ImmutableStream,
    circuit: ImmutableCircuit
): AdvancedStreamTypeConstant => {
    const stages = circuit.get('stages');
    const streams = circuit.get('streams');
    // Get first and last stage in Extraction section
    const extractStages = getExtractorStages(circuit);
    const firstExtractStage = extractStages.find(
        (stage: ImmutableStage) => stage.get('location') === 1
    );
    const lastExtractStage = extractStages.find(
        (stage: ImmutableStage) => stage.get('location') === extractStages.size
    );
    // Get first and last stage in Stripping section
    const stripStages = stages.filter(
        (stage: ImmutableStage) => stage.get('stageType') === STAGE_TYPES.STRIP
    );
    const firstStripStage = stripStages.find(
        (stage: ImmutableStage) => stage.get('location') === 1
    );
    const lastStripStage = stripStages.find(
        (stage: ImmutableStage) => stage.get('location') === stripStages.size
    );

    // Check advanced steam on PLS circuit
    if (stream.get('streamCircuit') === STREAM_CIRCUITS.AQUEOUS) {
        // Check if it's a bleed (not on the last stage), feed(not on the first stage) or blend
        if (stream.get('streamType') === STREAM_TYPES.BLEND) {
            // Check if there is a bleed between stages
            const toStage = getStageById(circuit, stream.get('toStageId'));
            const fromStage = stages.find(
                (stage: ImmutableStage) =>
                    stage.get('stageType') === toStage.get('stageType') &&
                    stage.get('location') === toStage.get('location') - 1
            );
            const advancedRelatedStream = streams.find(
                (relatedAdvancedStream: ImmutableStream) =>
                    relatedAdvancedStream.get('streamCircuit') === STREAM_CIRCUITS.AQUEOUS &&
                    relatedAdvancedStream.get('streamType') === STREAM_TYPES.BLEED &&
                    relatedAdvancedStream.get('fromStageId') === fromStage.get('id')
            );
            if (advancedRelatedStream) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEED_BLEND;
            } else {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEND;
            }
        } else if (
            stream.get('streamType') === STREAM_TYPES.FEED &&
            stream.get('toStageId') !== firstExtractStage.get('id')
        ) {
            return ADVANCED_STREAMS_SETUP_TYPES.FEED;
        } else if (
            stream.get('streamType') === STREAM_TYPES.BLEED &&
            stream.get('fromStageId') !== lastExtractStage.get('id')
        ) {
            // Check if there is a bleed or blend between stages
            const fromStage = stages.find(
                (stage: ImmutableStage) => stage.get('id') === stream.get('fromStageId')
            );
            const toStage = stages.find(
                (stage: ImmutableStage) =>
                    stage.get('stageType') === fromStage.get('stageType') &&
                    stage.get('location') === fromStage.get('location') + 1
            );
            const advancedRelatedStream = streams.find(
                (relatedAdvancedStream: ImmutableStream) =>
                    relatedAdvancedStream.get('streamCircuit') === STREAM_CIRCUITS.AQUEOUS &&
                    (relatedAdvancedStream.get('streamType') === STREAM_TYPES.BLEND ||
                        relatedAdvancedStream.get('streamType') === STREAM_TYPES.FEED) &&
                    relatedAdvancedStream.get('toStageId') === toStage.get('id')
            );
            if (
                advancedRelatedStream &&
                advancedRelatedStream.get('streamType') === STREAM_TYPES.BLEND
            ) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEED_BLEND;
            } else if (
                advancedRelatedStream &&
                advancedRelatedStream.get('streamType') === STREAM_TYPES.FEED
            ) {
                return ADVANCED_STREAMS_SETUP_TYPES.FEED;
            } else {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEED;
            }
        } else if (stream.get('streamType') === STREAM_TYPES.CONTINUE) {
            // Return true if it's a continue stream to or from same stage as advanced bleed or blend
            const blendStream = streams.find(
                (relatedAdvancedStream: ImmutableStream) =>
                    relatedAdvancedStream.get('streamCircuit') === STREAM_CIRCUITS.AQUEOUS &&
                    relatedAdvancedStream.get('streamType') === STREAM_TYPES.BLEND &&
                    relatedAdvancedStream.get('toStageId') === stream.get('toStageId')
            );

            const bleedStream = streams.find(
                (relatedAdvancedStream: ImmutableStream) =>
                    relatedAdvancedStream.get('streamCircuit') === STREAM_CIRCUITS.AQUEOUS &&
                    relatedAdvancedStream.get('streamType') === STREAM_TYPES.BLEED &&
                    relatedAdvancedStream.get('fromStageId') === stream.get('fromStageId')
            );

            if (blendStream && bleedStream) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEED_BLEND;
            } else if (blendStream) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEND;
            } else if (bleedStream) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEED;
            } else {
                return ADVANCED_STREAMS_SETUP_TYPES.NONE;
            }
            // TODO: Check if this a skip stream
        }
    } else if (stream.get('streamCircuit') === STREAM_CIRCUITS.ELECTROLYTE) {
        // Check advanced streams on Electrolyte circuit
        // Return true if it's a bleed (not on the first stage), feed(not on the last stage)
        if (
            (stream.get('streamType') === STREAM_TYPES.FEED &&
                stream.get('toStageId') !== lastStripStage.get('id')) ||
            (stream.get('streamType') === STREAM_TYPES.BLEED &&
                stream.get('fromStageId') !== firstStripStage.get('id'))
        ) {
            return ADVANCED_STREAMS_SETUP_TYPES.FEED; // Parallel setup in Electrolyte circuit is a FEED advanced stream type
        } else {
            return ADVANCED_STREAMS_SETUP_TYPES.NONE;
        }
    } else if (stream.get('streamCircuit') === STREAM_CIRCUITS.ORGANIC) {
        // Organic advanced streams can be of blend and bypass types
        if (stream.get('streamType') === STREAM_TYPES.BLEND) {
            return ADVANCED_STREAMS_SETUP_TYPES.BLEND;
        } else if (stream.get('streamType') === STREAM_TYPES.BYPASS) {
            // We also consider continue streams with bypasses coming in or out as advanced streams
            // Check this is a continue with an advance organic blend going in the same to-stage
            const toStage = getStageById(circuit, stream.get('toStageId'));
            const fromStage = getStageById(circuit, stream.get('fromStageId'));

            if (fromStage.get('stageType') !== toStage.get('stageType')) {
                // Check if it's a bypass feed or bleed
                if (toStage.get('stageType') === STAGE_TYPES.EXTRACT) {
                    if (toStage.get('location') === lastExtractStage.get('location')) {
                        return ADVANCED_STREAMS_SETUP_TYPES.BYPASS_BLEED;
                    } else {
                        return ADVANCED_STREAMS_SETUP_TYPES.BYPASS_FEED;
                    }
                } else if (toStage.get('stageType') === STAGE_TYPES.STRIP) {
                    if (toStage.get('location') === 1) {
                        return ADVANCED_STREAMS_SETUP_TYPES.BYPASS_BLEED;
                    } else {
                        return ADVANCED_STREAMS_SETUP_TYPES.BYPASS_FEED;
                    }
                }
                return ADVANCED_STREAMS_SETUP_TYPES.NONE;
            } else {
                // Return true if it's a blend stream to same stage as continue stream
                const blendStream = streams.find((relatedAdvancedStream: ImmutableStream) => {
                    const relatedToStage = getStageById(
                        circuit,
                        relatedAdvancedStream.get('toStageId')
                    );

                    return (
                        relatedAdvancedStream.get('streamCircuit') === STREAM_CIRCUITS.ORGANIC &&
                        relatedAdvancedStream.get('streamType') === STREAM_TYPES.BLEND &&
                        relatedToStage.get('stageType') === toStage.get('stageType') &&
                        relatedToStage.get('location') === toStage.get('location')
                    );
                });

                if (blendStream) {
                    return ADVANCED_STREAMS_SETUP_TYPES.BLEND;
                } else {
                    return ADVANCED_STREAMS_SETUP_TYPES.NONE;
                }
            }
        }
    }
    return ADVANCED_STREAMS_SETUP_TYPES.NONE;
};

/**
 * Check if stream value is enabled in user settings
 */
export const isStreamValueEnabled = (
    user: ImmutableUser,
    streamValueType: StreamValuesConstant | DatasetValuesConstant
) => {
    switch (streamValueType) {
        case DATASET_VALUE_TYPES.CU_TRANSFERRED:
            return user.getIn(['preferences', 'minchem', 'displayCopperTransferred']);
        case STREAM_VALUE_TYPES.LOADED_PERCENT:
            return user.getIn(['preferences', 'minchem', 'displayLoadedPercent']);
        case STREAM_VALUE_TYPES.NET_TRANSFER:
            return user.getIn(['preferences', 'minchem', 'displayNetTransfer']);
        case STREAM_VALUE_TYPES.STRIPPED_PERCENT:
            return user.getIn(['preferences', 'minchem', 'displayStrippedPercent']);
        default:
            return true;
    }
};

export const getRelatedStageInCascade = (
    circuit: ImmutableCircuit,
    stage: ImmutableStage,
    direction: 'FORWARD' | 'BACKWARD'
) => {
    const filterKey = direction === 'FORWARD' ? 'toStageId' : 'fromStageId';
    const currentStageKey = direction === 'FORWARD' ? 'fromStageId' : 'toStageId';
    const wantedStage = direction === 'FORWARD' ? 'toStageId' : 'fromStageId';

    const streams = circuit.get('streams');
    const streamCircuit =
        stage.get('stageType') === STAGE_TYPES.EXTRACT
            ? STREAM_CIRCUITS.AQUEOUS
            : STREAM_CIRCUITS.ELECTROLYTE;
    const nextStreams = streams.filter(
        (stream: ImmutableStream) =>
            stream.get(filterKey) !== null && // Filter out bleeds
            stream.get('streamCircuit') === streamCircuit &&
            stream.get(currentStageKey) === stage.get('id')
    );
    if (
        nextStreams.find(
            (stream: ImmutableStream) => stream.get('streamType') === STREAM_TYPES.FEED
        )
    ) {
        return null; // There is no previous when a new feed is a present. this starts a new cascade.
    }
    if (nextStreams.size > 1) {
        // TODO: Bypasses.
        throw new Error('Cannot handle previous stage with multi inputs.?!');
    } else if (nextStreams.size === 1) {
        return getStageById(circuit, nextStreams.first().get(wantedStage));
    } else {
        return null;
    }
};

/**
 * Get the previous stage for the given stage.
 * @param {ImmutableCircuit} circuit The circuit for which we want to get the prev stage of
 * @param {ImmutableStage} stage The stage for which you want to get the previous stage
 */
export const getPreviousStageInCascade = (circuit: ImmutableCircuit, stage: ImmutableStage) =>
    getRelatedStageInCascade(circuit, stage, 'BACKWARD');

/**
 * Get the next stage for the given stage.
 * @param {ImmutableCircuit} circuit The circuit for which we want to get the next stage of
 * @param {ImmutableStage} stage The stage for which you want to get the next stage
 */
export const getNextStageInCascade = (circuit: ImmutableCircuit, stage: ImmutableStage) =>
    getRelatedStageInCascade(circuit, stage, 'FORWARD');

/**
 * Find the first stage for the cascade the stage belongs to.
 * @param {ImmutableCircuit} circuit the circuit with the streams for the stage.
 * @param {ImmutableStage} stage the stage for which we want to find the first stage of.
 */
export const getFirstStageInCascadeForStage = (
    circuit: ImmutableCircuit,
    stage: ImmutableStage
) => {
    let previousStage = stage;
    do {
        const newPreviousStage = getPreviousStageInCascade(circuit, previousStage);
        if (!newPreviousStage) {
            break;
        }
        previousStage = newPreviousStage;
    } while (previousStage !== null);

    return previousStage;
};

/**
 * Find the last stage for the cascade the stage belongs to.
 * @param {ImmutableCircuit} circuit the circuit with the streams for the stage.
 * @param {ImmutableStage} stage the stage for which we want to find the first stage of.
 */
export const getLastStageInCascadeForStage = (circuit: ImmutableCircuit, stage: ImmutableStage) => {
    let nextStage = stage;
    do {
        const newNextStage = getNextStageInCascade(circuit, nextStage);
        if (!newNextStage) {
            break;
        }
        nextStage = newNextStage;
    } while (nextStage !== null);

    return nextStage;
};

/**
 * Given the circuit and the stage, find out if the stage causes a cascade to break.
 * This will occur if there is a new feed, a bleed, or a blend on that stage.
 * @param {ImmutableCircuit} circuit The circuit with which the streams and stage configuration is defined
 * @param {ImmutableStage} stage The stage for which we want to know
 */
export const doesStageCausesCascadeBreak = (
    circuit: ImmutableCircuit,
    stage: ImmutableStage
): boolean => {
    const streams = circuit.get('streams');

    const breakOnStreamType = [STREAM_TYPES.BLEND, STREAM_TYPES.FEED];
    const circuitType =
        stage.get('stageType') === STAGE_TYPES.EXTRACT
            ? STREAM_CIRCUITS.AQUEOUS
            : STREAM_CIRCUITS.ELECTROLYTE;

    return Boolean(
        streams.find(
            (forStream: ImmutableStream) =>
                breakOnStreamType.indexOf(forStream.get('streamType')) !== -1 &&
                forStream.get('toStageId') === stage.get('id') &&
                forStream.get('streamCircuit') === circuitType
        )
    );
};

export const getFirstStageUntilNewFeedBlendStage = (
    circuit: ImmutableCircuit,
    stage: ImmutableStage
) => {
    let currentStage = stage;
    do {
        if (doesStageCausesCascadeBreak(circuit, currentStage)) {
            break; // We've reached a newfeed or a blend. We have our stage.
        }

        const newCurrentStage = getPreviousStageInCascade(circuit, currentStage);
        if (!newCurrentStage) {
            break;
        }
        currentStage = newCurrentStage;
    } while (currentStage !== null);

    return currentStage;
};

export const getStagesInCascadeForStage = (
    circuit: ImmutableCircuit,
    stage: ImmutableStage
): Array<ImmutableStage> => {
    const firstStage = getFirstStageInCascadeForStage(circuit, stage);

    const stages = [firstStage];

    let nextStage = firstStage;
    do {
        const newNextStage = getNextStageInCascade(circuit, nextStage);
        if (!newNextStage) {
            break;
        }
        stages.push(newNextStage);
        nextStage = newNextStage;
    } while (nextStage !== null);

    return stages;
};

/**
 * Provide array of empty datapoint objects, count of DATA_POINT_COUNT
 */
export const provideEmptyDataPoints = (
    id: number,
    count: number = OXIME_CONCENTRATION_DATA_POINT_COUNT
): ImmutableList<ImmutableDataPoint> => {
    const dataPoints = [];
    for (let i = 0; i < count; i++) {
        dataPoints.push({
            id: `datapoint-${id}-${i}`,
            mdr: undefined,
            metalInOrganic: undefined,
        });
    }
    return dataPoints;
};

/**
 * Loop over provided array and return true if there are duplicates
 */
export const containsDuplicates = (array: Array<string>) => {
    const filteredArray = array.filter((str: string) => str);
    let hasDuplicates = false;
    const alreadySeen = [];

    filteredArray.forEach((str: string) => {
        if (alreadySeen.includes(str)) {
            hasDuplicates = true;
        } else {
            alreadySeen.push(str);
        }
    });

    return hasDuplicates;
};

/**
 * Get from and to stages for that stream. In case stream has only one stage, the other one would be just null.
 */
export const getStagesForStream = (
    stream: LooseStream,
    stages: Array<LooseStage>
): RelatedStages => {
    const stagesByStream = {
        from: null,
        to: null,
    };

    if (stream.fromStageId) {
        stagesByStream.from = stages.find((stage: Stage) => stage.id === stream.fromStageId);
    } else if (stream.from) {
        stagesByStream.from = stream.from;
    }

    if (stream.toStageId) {
        stagesByStream.to = stages.find((stage: Stage) => stage.id === stream.toStageId);
    } else if (stream.to) {
        stagesByStream.to = stream.to;
    }

    return stagesByStream;
};

/**
 * Get all streams associated with this stage.
 */
export const getStreamsForStage = (
    stage: LooseStage,
    streams: Array<LooseStream>,
    stages: Array<LooseStage>
): Array<LooseStream> => {
    const stageStreams = streams.filter((stream: LooseStream) => {
        const relatedStages = getStagesForStream(stream, stages);
        return (
            (relatedStages.to &&
                relatedStages.to.location === stage.location &&
                relatedStages.to.stageType === stage.stageType) ||
            (relatedStages.from &&
                relatedStages.from.location === stage.location &&
                relatedStages.from.stageType === stage.stageType)
        );
    });

    return stageStreams;
};

/**
 * Get next stage of the same type in an array of stages.
 */
export const getNextStage = (stage: LooseStage, stages: Array<LooseStage>): LooseStage =>
    stages.find(
        (stageToFind: Stage) =>
            stageToFind.location === stage.location + 1 && stageToFind.stageType === stage.stageType
    );

/**
 * Get previous stage of the same type in an array of stages.
 */
export const getPreviousStage = (stage: LooseStage, stages: Array<LooseStage>): LooseStage =>
    stages.find(
        (stageToFind: Stage) =>
            stageToFind.location === stage.location - 1 && stageToFind.stageType === stage.stageType
    );

/**
 * Gets the stage data for the given stage id.
 */
export const getStageStructureFromStageId = (stageId: number, stages: Array<Stage>): Stage =>
    stages.find((stage: Stage) => stage.id === stageId);

/**
 * Returns value as string, prevents Number(0) from being falsy
 *
 * @param {LooseNumberType} value
 */
export const getValueAsString = (value?: LooseNumberType): string =>
    value === null ? '' : value && value.toString();

/**
 * Determines if all the isotherm stage values are currently predicted.
 */
export const areAllStageIsothermsPredicted = (
    circuit: ImmutableCircuit,
    dataset: ImmutableDataset
) => {
    if (!circuit || !dataset) {
        return false;
    }
    // Find the first isotherm stage value that is not predicted
    return circuit
        .get('stages')
        .filter((stage: ImmutableStage) =>
            STAGE_TYPES_WITH_ISOTHERMS.includes(stage.get('stageType'))
        )
        .every((stage: ImmutableStage) => {
            const firstStage = getFirstStageUntilNewFeedBlendStage(circuit, stage);
            const isothermStageValue = dataset
                .get('isothermStageValues')
                .find(
                    (isoStageValue: ImmutableIsothermStageValue) =>
                        isoStageValue.get('stageId') === firstStage.get('id')
                );
            if (isothermStageValue) {
                return isothermStageValue.get('valueType') === ISOTHERM_VALUE_MODES.PREDICT;
            }
            return false;
        });
};

/**
 * Helper function which converts provided ref into a jpeg and copies it to clipboard
 *
 * @param {ReactNode} target
 */
export const nodeToClipboard = async (
    target: ReactNode,
    createFeedbackHandler?: (feedbackType: FeedbackType, messageId: string) => void,
    callback?: () => void
) => {
    const successFeedbackType = SUCCESS_TYPE;
    const successFeedbackMessageId = 'feedback.success.copiedToClipboardSuccess';
    const errorFeedbackType = ERROR_TYPE;
    const errorFeedbackMessageId = 'feedback.error.copiedToClipboardFailed';

    try {
        if (!target) {
            throw new Error('No target provided');
        }

        const blob = await domtoimage.toBlob(target).then((b: Blob) => {
            /**
             * Download image
             */
            // window.saveAs(b, 'copied-image.png');
            return b;
        });

        // eslint-disable-next-line no-undef
        const item = await new ClipboardItem({ [blob.type]: blob });

        await navigator.clipboard
            .write([item])
            .then(() => {
                if (createFeedbackHandler) {
                    createFeedbackHandler(successFeedbackType, successFeedbackMessageId);
                }
            })
            .then(() => {
                if (callback) {
                    callback();
                }
            });
    } catch (error) {
        if (createFeedbackHandler) {
            createFeedbackHandler(errorFeedbackType, errorFeedbackMessageId);
        }
    }
};

/**
 * Counts the amount of zeros after the decimal place;
 *
 * 0.000001 = 5
 * 0.00001 = 4
 * 0.0001 = 3
 * 0.001 = 2
 * 0.01 = 1
 * 0.1 = 0
 * 1 = 0
 * 10 = 0
 *
 * @param {number} value
 */
export const getPostDecimalZeroCount = (value: number) =>
    value >= 1 ? 0 : Math.abs(Math.floor(Math.log(value) / Math.log(10) + 1));

/**
 * Takes a positive integer and returns the corresponding column name.
 *
 * @param {number} num  The positive integer to convert to a column name.
 * @return {string}  The column name.
 */
export const toColumnNameFromIndex = (num: number) => {
    let ret = '';
    let a = 1;
    let b = 26;
    // eslint-disable-next-line no-cond-assign, no-param-reassign
    for (ret = ''; (num -= a) >= 0; a = b, b *= 26) {
        // eslint-disable-next-line radix
        ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
    }
    return ret;
};

export const formatDisclaimer = (
    disclaimer: ImmutableDisclaimer,
    intl: IntlType,
    userLocale: string
): ImmutableDisclaimer => {
    if (!disclaimer.has('id')) {
        return null;
    }

    const DATE_FORMAT = {
        minute: 'numeric',
        hour: 'numeric',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    };

    return disclaimer
        .set(
            'actionButtonText',
            intl.formatMessage({
                id: `helpers.formatDisclaimer.disclaimerActionButtonKey.${disclaimer.get('type')}`,
            })
        )
        .set('type', disclaimer.get('type'))
        .set(
            'info',
            intl.formatMessage(
                { id: 'helpers.formatDisclaimer.info' },
                {
                    version: disclaimer.get('version'),
                    date: getFormattedDateFromString(
                        disclaimer.get('updatedAt'),
                        userLocale,
                        getCurrentTimeZone(),
                        DATE_FORMAT
                    ),
                }
            )
        )
        .set(
            'warningNote',
            intl.formatMessage({
                id: 'helpers.formatDisclaimer.disclaimerWarningNote',
            })
        );
};

export const formatDisclaimers = (
    disclaimers: ImmutableList<ImmutableDisclaimer>,
    intl: IntlType,
    userLocale: string
): ImmutableList<ImmutableDisclaimer> =>
    disclaimers.map((disclaimer: ImmutableDisclaimer) =>
        formatDisclaimer(disclaimer, intl, userLocale)
    );

/**
 * Deletes the provided keys from the map
 */
export const immutableMapKeyRemover = (
    immutableMap: ImmutableMap<string, any>,
    keys: Array<string>
) => {
    let result = immutableMap;
    for (const key of keys) {
        result = result.delete(key);
    }
    return result;
};

/**
 * Appends the provided plant id to backend api url set by the env
 */
export const getImportURLForPlant = (plantId: number, renderAsString: boolean = false) => {
    const url = `${API_URL}${API_VER}import/${plantId}`;
    return renderAsString ? url : <Code>{url}</Code>;
};

export const areObjectsDifferent = (object1: Object, object2: Object) =>
    !isEmpty(xorWith(object1, object2, isEqual));

export const formatPhoneNumber = (phoneNumber: string) => {
    const parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`);
    return parsedPhoneNumber.format('INTERNATIONAL');
};
