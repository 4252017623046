// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type { ImmutableMetalState } from './types';

/**
 * Direct selector to the metal state domain
 */
export const selectMetalState = () => (state: ImmutableReduxState) => state.get('metal');

/**
 * Select list of metals
 */
export const selectAllMetals = () =>
    createSelector(
        selectMetalState(),
        (substate: ImmutableMetalState) => substate.get('metals').toList()
    );

/**
 * Select metal errors
 */
export const selectMetalErrors = () =>
    createSelector(
        selectMetalState(),
        (substate: ImmutableMetalState) => substate.get('errors')
    );

/**
 * Gets isFetching status while metals are being fetched: to hide list or show loading animation
 */
export const selectMetalsAreFetching = () =>
    createSelector(
        selectMetalState(),
        (substate: ImmutableMetalState) => substate.get('isFetching')
    );
