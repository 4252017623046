// @flow strict

import { fromJS } from 'immutable';

// Actions
import { FETCH_LIST_SUCCESS, FETCH_LIST_FAILURE, IS_FETCHING } from './actions';

// Types
import type { GenericActionType } from 'types';
import type { ImmutableMetalState } from './types';

const initialState: ImmutableMetalState = fromJS({
    metals: [],
    isFetching: false,
    errors: {},
});

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function metalServiceReducer(state: ImmutableMetalState = initialState, action: GenericActionType) {
    switch (action.type) {
        case FETCH_LIST_SUCCESS:
            return state
                .set('metals', fromJS(action.payload.data))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        case FETCH_LIST_FAILURE:
            return initialState.set('errors', fromJS(action.payload.errors));
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);
        default:
            return state;
    }
}

export default metalServiceReducer;
