// @flow

import React from 'react';
import { StyledInputValue as InputValue } from '../input-field';

import type { InputValueType } from '../../types';

// Styles
import { NumberInput, InputWrapper } from './styles';

type Props = {
    disabled?: boolean,
    id?: number,
    key?: ?string,
    max?: number,
    min?: number,
    minWidth?: string,
    name?: string,
    renderString?: boolean,
    textAlign?: string,
    type?: string,
    unit?: string,
    value?: InputValueType,
    width?: string,
    height?: string,
    padding?: string,
    placeholder?: InputValueType,
    noSpinner?: boolean,
    handleOnChange?: () => void,
};

export default class InputNumber extends React.PureComponent<Props> {
    static defaultProps = {
        disabled: false,
        handleOnChange: null,
        height: '45px',
        id: null,
        key: null,
        max: 100,
        min: 0,
        minWidth: 'auto',
        name: 'InputNumber',
        noSpinner: false,
        padding: '0 5px 0 15px',
        placeholder: 0,
        renderString: false,
        textAlign: 'initial',
        type: 'number',
        unit: '',
        value: 0,
        width: 'auto',
    };

    render() {
        if (this.props.renderString) {
            return (
                <InputValue>
                    {this.props.value}
                    {this.props.unit}
                </InputValue>
            );
        }

        const input = (
            <NumberInput
                key={this.props.key || this.props.name || this.props.id}
                value={this.props.value}
                disabled={this.props.disabled && this.props.disabled !== false}
                placeholder={this.props.placeholder}
                onChange={this.props.handleOnChange}
                {...this.props}
            />
        );

        return this.props.unit ? (
            <InputWrapper>
                {input}
                {this.props.unit}
            </InputWrapper>
        ) : (
            input
        );
    }
}
