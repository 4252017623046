// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import { Dot, LegacyTheme } from 'components/_ReactUI_V1';

import { TARGET_TYPES } from 'utils/kpiConstants';

// Helpers
import { getRoundedValue } from 'utils/kpiHelpers';

// Styles
import {
    ToolTipContainer,
    ToolTipWrapper,
    ToolTipHeader,
    ToolTipDate,
    ToolTipBody,
    ToolTipArrow,
} from './styles';

// Types
import type { IntlType } from 'types';
import type { DataPoint } from 'components/KPIGraph';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

type Props = {
    intl: IntlType,

    kpi: ImmutableKPISetting,
    toolTipDateFormatter: (x: string) => string,
    unit: ?string,

    datum: DataPoint,
    x: number,
    y: number,
};

/*
    Minor KPI Trends component
 */
class KPIGraphTooltip extends React.PureComponent<Props> {
    render() {
        const width = 150;
        const height = 90;
        const highTarget = this.props.kpi.get(TARGET_TYPES.HIGH_TARGET);
        const lowTarget = this.props.kpi.get(TARGET_TYPES.LOW_TARGET);
        let showTarget = false;
        let isOnTarget = false;
        if (highTarget !== null && lowTarget !== null) {
            isOnTarget = this.props.datum.y <= highTarget && this.props.datum.y >= lowTarget;
            showTarget = true;
        }
        return (
            <g style={{ pointerEvents: 'none' }}>
                <foreignObject
                    x={this.props.x - width / 2}
                    y={this.props.y - height + 20}
                    width={width}
                    height={height}
                >
                    <ToolTipContainer>
                        <ToolTipWrapper>
                            <ToolTipHeader>
                                <ToolTipDate>
                                    {this.props.toolTipDateFormatter(this.props.datum.x)}
                                </ToolTipDate>
                                {showTarget && (
                                    <Dot
                                        fill={
                                            isOnTarget
                                                ? LegacyTheme.defaultSuccessColor
                                                : LegacyTheme.defaultWarningColor
                                        }
                                        margin="0"
                                    />
                                )}
                            </ToolTipHeader>
                            <ToolTipBody>
                                {this.props.intl.formatMessage(
                                    {
                                        id: 'components.KPIGraph.kpiValueWithUnit',
                                    },
                                    {
                                        value: getRoundedValue(this.props.datum.y, this.props.kpi),
                                        unit: this.props.unit,
                                    }
                                )}
                            </ToolTipBody>
                        </ToolTipWrapper>
                        <ToolTipArrow />
                    </ToolTipContainer>
                </foreignObject>
            </g>
        );
    }
}

export default injectIntl(KPIGraphTooltip);
