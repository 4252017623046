// @flow strict

/**
 * The versions of minchem organized by traditional naming,
 * OLD indicates the version from 1980s
 * LEGACY is this repository
 * MFE is the first version of the monorepo's minchem microfrontend
 */
export const MinChemVersions = {
    OLD: 0,
    LEGACY: 1,
    MFE: 2,
}
