// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Styles
import {
    SummaryWrapper,
    Header,
    DateTitle,
    StatusWrapper,
    Label,
    Dot,
    Content,
    MajorKPIWrapper,
    ClientTitleWrapper,
    PlantName,
    CircuitName,
    KpiCard,
    KpiCardHeader,
    KpiName,
    KpiIndicator,
    KpiValue,
    KpiUnit,
} from './styles';

// Helpers
import { getFormattedDateFromString } from 'utils/dateHelpers';
import {
    getValue,
    getDisplayValue,
    renderIndicator,
    getValueColor,
} from 'components/KPICard/helpers';
import { getDatasetStatusDotColor } from 'containers/TrendsContainer/helpers';
import { getKPISettingUnit } from 'utils/kpiHelpers';

// selectors
import { selectAllClients } from 'services/Client/selectors';

// Types
import type { IntlType, ImmutableList, LanguageCodeConstant } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableKPICard, ImmutableKPISetting } from 'services/KPISetting/types';
import type { DatasetStatusType } from 'services/Dataset/types';
import type { ImmutableClient } from 'services/Client/types';
import type { ImmutablePlant } from 'services/Plant/types';

const DATE_FORMAT = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};

type Props = {
    intl: IntlType,
    clients: ImmutableList<ImmutableClient>,
    timezone: string,
    userLocale: LanguageCodeConstant,
    createdAt: string,
    circuit: ImmutableCircuit,

    majorKPIdata: ImmutableList<ImmutableKPICard>,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    datasetStatus: DatasetStatusType,
    isSingleCircuit: boolean, // if loading from plant trends page, we need to check if plant has single circuit for showing the target
};

class MajorKPISummary extends React.PureComponent<Props> {
    getPlant = () => {
        const plantId = this.props.circuit.get('plantId');
        let plant = null;
        this.props.clients.find((c: ImmutableClient) => {
            plant = c.get('plants').find((p: ImmutablePlant) => p.get('id') === plantId);
            return Boolean(plant);
        });
        return plant;
    };

    getKPISetting = (kpiId: number) =>
        this.props.kpiSettings.find((kpi: ImmutableKPISetting) => kpi.get('id') === kpiId);

    renderMajorKPICard = (kpi: ImmutableKPICard) => {
        const kpiSetting = this.getKPISetting(kpi.get('kpiSettingId'));
        return (
            <KpiCard key={kpi.get('kpiSettingId')}>
                <KpiCardHeader>
                    <KpiName>{kpiSetting.get('name')}</KpiName>
                    <KpiIndicator>{renderIndicator(kpi, true)}</KpiIndicator>
                </KpiCardHeader>
                <KpiValue color={getValueColor(kpi, kpiSetting, this.props.isSingleCircuit)}>
                    {getDisplayValue(kpi, kpiSetting, this.props.intl)}
                </KpiValue>
                {getValue(kpi) !== null ? (
                    <KpiUnit>{getKPISettingUnit(kpiSetting, this.props.intl)}</KpiUnit>
                ) : null}
            </KpiCard>
        );
    };

    render() {
        const datasetStatus = this.props.intl.formatMessage({
            id: `components.MajorKPITrends.DatasetStatuses.${this.props.datasetStatus}`,
        });

        const plant = this.getPlant();

        return (
            <SummaryWrapper>
                <Header>
                    <DateTitle>
                        {getFormattedDateFromString(this.props.createdAt, this.props.userLocale, {
                            ...DATE_FORMAT,
                            timeZone: this.props.timezone,
                        })}
                    </DateTitle>
                    <StatusWrapper color={getDatasetStatusDotColor(this.props.datasetStatus)}>
                        <Dot />
                        <Label>{datasetStatus}</Label>
                    </StatusWrapper>
                </Header>
                <Content>
                    <ClientTitleWrapper>
                        <PlantName>{plant && plant.get('name')}</PlantName>
                        <CircuitName>{this.props.circuit.get('name')}</CircuitName>
                    </ClientTitleWrapper>
                    <MajorKPIWrapper>
                        {this.props.majorKPIdata.map(this.renderMajorKPICard)}
                    </MajorKPIWrapper>
                </Content>
            </SummaryWrapper>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        clients: selectAllClients(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(MajorKPISummary))
);
