// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Dot, SecondaryButton, GearIcon, Loader } from 'components/_ReactUI_V1';

import MajorKPIBlocks from 'components/MajorKPITrends/MajorKPIBlocks';
import GraphPeriodSelector from 'components/GraphPeriodSelector';
import KPIGraph from 'components/KPIGraph';
import EditKPISettingsModal from 'components/Modals/EditKPISettingsModal';

// Constants
import { TRENDS_PAGE_TYPE, DATASET_STATUSES } from 'utils/constants';
import { KPIGraphColorSchemes } from 'styles/colors';

// Helpers
import { getDatasetStatusDotColor } from 'containers/TrendsContainer/helpers';
import { getFormattedDateFromString } from 'utils/dateHelpers';

// Types
import type { IntlType, ImmutableList, LanguageCodeConstant } from 'types';
import type {
    TrendsPeriodConstant,
    ImmutableTrendData,
    TrendsPageType,
    ImmutableKPIHistory,
} from 'services/Trends/types';
import type { ImmutableKPICard } from 'services/KPISetting/types';

// Utils
import { isSuperPMUser, isSolvayUser } from 'utils/authentication';

// Styles
import {
    MajorKPIWrapper,
    ControlsAndGraphWrapper,
    GraphWrapper,
    Controls,
    RightControls,
    SettingsButtonHover,
    MobileStatusBar,
    MobileStatusBarItem,
} from './styles';

type Props = {
    intl: IntlType,
    trendType: TrendsPageType,
    trend: ImmutableTrendData,
    kpis: ImmutableList<ImmutableKPICard>,
    kpiHistories: ImmutableList<ImmutableKPIHistory>,
    loadingMajorHistory: boolean,
    selectedMajorKPISettingId: number,
    user: ?ImmutableUser,

    userLanguage: LanguageCodeConstant,
    sidebarIsCollapsed: boolean,
    showMaxCuTransfer: boolean,
    onChangeMajorKPI: (newKPIId: number) => void,
    onChangePeriod: (newPeriod: TrendsPeriodConstant) => void,
    onSettingsClicked?: ?() => void,
    onViewMimicClicked: () => void,
};

type State = {
    graphWidth: ?number,
    graphHeight: ?number,
    showEditSettingsModal: ?boolean,
};

const DATE_FORMAT = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
};

/*
    Major KPI Trends component
 */
class MajorKPITrends extends React.PureComponent<Props, State> {
    static defaultProps = {
        onSettingsClicked: null,
    };

    constructor(props: Props) {
        super(props);

        this.graphWrapperRef = React.createRef();

        this.state = {
            graphWidth: null,
            graphHeight: null,
            showEditSettingsModal: false,
        };
    }

    componentDidMount() {
        const element = this.graphWrapperRef.current;
        if (!element) {
            throw new Error('Graph wrapper not initialized...');
        }
        this.setState({
            graphWidth: element.clientWidth,
            graphHeight: element.clientHeight,
        });
        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.sidebarIsCollapsed !== prevProps.sidebarIsCollapsed) {
            // After the sidebar state is triggered, update the graph dimensions every 10ms. This animates the new graph resizing while the sidebar transitions for 500ms.
            this.intervalId = setInterval(this.updateDimensions, 10); // Every 10ms, resize the graph, gives a more fluid animation of graph resizing than only at the end.
            this.timeoutId = setTimeout(this.clearInterval, 750); // sidebar transition time is 500ms, however give extra time for set states and etc.
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        this.clearInterval();
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    intervalId = null;

    timeoutId = null;

    graphWrapperRef = null;

    getUpdatedAt = () => {
        return getFormattedDateFromString(
            this.props.trend.get('createdAt'),
            this.props.userLanguage,
            {
                ...DATE_FORMAT,
                timeZoned: this.props.trend.get('timezone'),
            }
        );
    };

    clearInterval = () => {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    };

    handleChangeCurrentKPI = (newKPI: ImmutableKPICard) =>
        this.props.onChangeMajorKPI(newKPI.get('kpiSettingId'));

    updateDimensions = () => {
        const element = this.graphWrapperRef.current;
        if (!element) {
            throw new Error('Graph wrapper not initialized...');
        }
        if (
            this.state.graphWidth !== element.clientWidth ||
            this.state.graphHeight !== element.clientHeight
        ) {
            this.setState({
                graphWidth: element.clientWidth,
                graphHeight: element.clientHeight,
            });
        }
    };

    /**
     * Render mobile status bar if we are at specified responsive maximum width
     */
    renderMobileStatusBlock = () => (
        <MobileStatusBar>
            <MobileStatusBarItem>{this.getUpdatedAt()}</MobileStatusBarItem>
            <MobileStatusBarItem>
                <Dot
                    fill={getDatasetStatusDotColor(this.props.trend.get('status'))}
                    margin="0 5px 0 5px"
                />
                {this.props.intl.formatMessage({
                    id: `components.MajorKPITrends.MobileDatasetStatuses.${this.props.trend.get(
                        'status'
                    )}`,
                })}
            </MobileStatusBarItem>
        </MobileStatusBar>
    );

    renderGraph = () => {
        if (!this.props.selectedMajorKPISettingId) {
            return null;
        }
        if (this.props.loadingMajorHistory) {
            return <Loader loading />;
        }
        const kpiHistory = this.props.kpiHistories.find(
            (kpi: ImmutableKPIHistory) =>
                kpi.get('kpiSettingId') === this.props.selectedMajorKPISettingId
        );
        if (!kpiHistory || kpiHistory.size === 0) {
            // Yikes, we have a race condition...
            return null;
        }

        const kpiSettingIndex = this.props.kpis.findIndex(
            (kpi: ImmutableKPICard) =>
                kpi.get('kpiSettingId') === this.props.selectedMajorKPISettingId
        );
        let colorSchemeIndex = kpiSettingIndex;
        if (colorSchemeIndex === -1 || colorSchemeIndex > KPIGraphColorSchemes.length) {
            colorSchemeIndex = 0;
        }
        return (
            <KPIGraph
                kpiId={this.props.selectedMajorKPISettingId}
                kpiHistory={kpiHistory}
                period={kpiHistory.get('period')}
                timezone={this.props.trend.get('timezone')}
                userLanguage={this.props.userLanguage}
                width={this.state.graphWidth}
                height={this.state.graphHeight}
                colorScheme={KPIGraphColorSchemes[colorSchemeIndex]}
                showTargetRange={true}
            />
        );
    };

    handleSettingsClicked = () => {
        this.setState({
            showEditSettingsModal: true,
        });
    };

    handleHideSettingsModal = () =>
        this.setState({
            showEditSettingsModal: false,
        });

    renderEditSettingsModal = () => (
        <EditKPISettingsModal
            kpiId={this.props.selectedMajorKPISettingId}
            onCloseModal={this.handleHideSettingsModal}
        />
    );

    renderSettingIcon = () => {
        if (this.props.onSettingsClicked) {
            return (
                <SettingsButtonHover onClick={this.props.onSettingsClicked}>
                    <GearIcon width="25px" height="25px" margin="0" />
                </SettingsButtonHover>
            );
        }
        if (isSuperPMUser(this.props.user) || isSolvayUser(this.props.user)) {
            return (
                <SettingsButtonHover onClick={this.handleSettingsClicked}>
                    <GearIcon width="25px" height="25px" margin="0" />
                </SettingsButtonHover>
            );
        }
        return null;
    };

    renderControls = () => {
        const kpiHistory = this.props.kpiHistories.find(
            (kpi: ImmutableKPIHistory) =>
                kpi.get('kpiSettingId') === this.props.selectedMajorKPISettingId
        );
        if (!kpiHistory || kpiHistory.size === 0) {
            // Yikes, we have a race condition...
            return null;
        }

        return (
            <Controls>
                <GraphPeriodSelector
                    period={kpiHistory.get('period')}
                    loading={this.props.loadingMajorHistory}
                    onChangePeriod={this.props.onChangePeriod}
                />
                <RightControls>
                    {this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT && (
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: `components.MajorKPITrends.viewMimicDiagramButtonText`,
                            })}
                            onClick={this.props.onViewMimicClicked}
                            style={{ marginLeft: '12px' }}
                        />
                    )}
                    {this.renderSettingIcon()}
                    {this.state.showEditSettingsModal && this.renderEditSettingsModal()}
                </RightControls>
            </Controls>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.renderMobileStatusBlock()}
                <MajorKPIWrapper>
                    <MajorKPIBlocks
                        kpis={this.props.kpis}
                        currentKPISettingId={this.props.selectedMajorKPISettingId}
                        updatedAt={this.getUpdatedAt()}
                        datasetStatus={this.props.trend.get('status')}
                        timezone={this.props.trend.get('timezone')}
                        userLanguage={this.props.userLanguage}
                        showMaxCuTransfer={this.props.showMaxCuTransfer}
                        onChangeCurrentKPI={this.handleChangeCurrentKPI}
                    />
                    <ControlsAndGraphWrapper>
                        {this.renderControls()}
                        <GraphWrapper ref={this.graphWrapperRef}>{this.renderGraph()}</GraphWrapper>
                    </ControlsAndGraphWrapper>
                </MajorKPIWrapper>
            </React.Fragment>
        );
    }
}

export default injectIntl(MajorKPITrends);
