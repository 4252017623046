// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const VariableBodySection = styled.div`
    display: flex;
    flex-direction: column;

    margin: 0 35px;

    & + & {
        margin-top: 32px;
    }
`;

export const VariableTitle = styled.span`
    color: ${LegacyTheme.defaultColor};
    font-size: 16px;
    line-height: 19px;

    margin-bottom: 13px;
`;

export const RangeWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RangeTitle = styled.span`
    color: ${LegacyTheme.defaultColor};
    font-size: 14px;
    line-height: 16px;

    margin-top: 34px;
    margin-bottom: 11px;
`;

export const RangeInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
        color: ${LegacyTheme.defaultColor};
        font-size: 13px;
        height: fit-content;
        min-width: 50px;

        margin-right: 8px;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

export const RangeInputWrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > ${RangeInput} + ${RangeInput} {
        margin-top: 6px;
    }
`;
