// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    padding: 31px;
`;

export const DiagramHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    // In order to avoid overflow hidden of graph during export
    > div {
        width: 100%;
        height: 100%;
        > div {
            width: 100%;
            height: 100%;
        }
    }
`;

export const tooltipStyle = {
    content: {
        background: LegacyTheme.toolTip,
        border: '0px none',
        borderRadius: '4px',
        boxShadow: '0 0 10px 2px rgba(0,0,0,0.1)',
        color: LegacyTheme.toolTipColor,
    },
    dot: {
        height: '0',
        width: '0',
    },
    line: {
        borderLeft: '0px none',
    },
};
