// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

// Styles
import { Title, Subtitle, FormContainer, FormBlock, FormLabel } from 'styles/common';

// Components
import { Table, InputField, Toggle, Common, SecondaryButton } from 'components/_ReactUI_V1';
import ReorderControls from 'components/ReorderControls';

// Constants
import {
    CIRCUIT_ELEVATION_STEPS,
    NUMBER_INPUT_PLACEHOLDER,
    DECISION_TREE_RECOVERY_TYPES,
} from 'utils/constants';

// Types
import type {
    IntlType,
    ImmutableList,
    LooseKeyArrayType,
    LooseInputValueTypes,
    InputEvent,
} from 'types';
import type {
    CircuitElevationStepConstant,
    ImmutableCircuitSettings,
} from 'services/Circuit/types';
import type {
    ImmutableRecoveryKPIOption,
    ImmutableDecisionTreeOptions,
} from 'services/DecisionTreeOptions/types';

type Props = {
    intl: IntlType,
    decisionTreeOptions: ImmutableDecisionTreeOptions,
    circuitSettings: ImmutableCircuitSettings,

    onReturnToStep?: ?(step: CircuitElevationStepConstant) => () => void,
    onInputChange?: ?(key: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onReorderKPI?: ?(kpiOption: ImmutableRecoveryKPIOption) => (newOrder: number) => void,
};

const INPUT_FIELD_STYLES = {
    width: '64px',
    paddingRight: '6px',
};

class CircuitElevationDecisionTreeOptionsStep extends React.PureComponent<Props, null> {
    static defaultProps = {
        onInputChange: null,
        onReorderKPI: null,
        onReturnToStep: null,
    };

    STEP_KEY = CIRCUIT_ELEVATION_STEPS.DECISION_TREE;

    isReadOnly = () => Boolean(this.props.onReturnToStep);

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.CircuitElevation.${this.STEP_KEY}.${id}`,
            },
            data
        );

    getTableHeader = () =>
        [
            !this.isReadOnly() && {
                id: 'order',
                label: '',
            },
            {
                id: 'optionType',
                label: this.getTranslation('kpiHeader'),
            },
        ].filter(Boolean); // remove nulls/false elements.

    getOrderableRow = (
        kpiOption: ImmutableRecoveryKPIOption,
        kpiOptionsCount: number,
        keyPrefix: string
    ) =>
        Map()
            .set('id', `${keyPrefix}-${kpiOption.get('optionType')}`)
            .set('order', this.renderOrderControls(kpiOption, kpiOptionsCount))
            .set('optionType', kpiOption.get('optionType'));

    getTableRows = (kpiOptions: ImmutableList<ImmutableRecoveryKPIOption>, keyPrefix: string) =>
        kpiOptions &&
        kpiOptions
            .map((kpiOption: ImmutableRecoveryKPIOption) =>
                this.getOrderableRow(kpiOption, kpiOptions.size, keyPrefix)
            )
            .toJS();

    /**
     * Update provided toggle input by key
     */
    handleToggleChange = (key: LooseKeyArrayType) => (checked: boolean) =>
        this.props.onInputChange && this.props.onInputChange(key, checked);

    /**
     * Update provided field by key
     */
    handleInputChange = (key: LooseKeyArrayType) => (event: InputEvent) =>
        this.props.onInputChange && this.props.onInputChange(key, event.target.value);

    renderHeader = (displayAsReadOnly: boolean) => (
        <Common.Row alignItems="center">
            <Common.Column>
                {displayAsReadOnly ? (
                    <Subtitle>{this.getTranslation('title')}</Subtitle>
                ) : (
                    <Title>{this.getTranslation('title')}</Title>
                )}
            </Common.Column>
            {displayAsReadOnly && (
                <Common.Column alignItems="flex-end">
                    <SecondaryButton
                        text={this.getTranslation('edit')}
                        onClick={
                            this.props.onReturnToStep && this.props.onReturnToStep(this.STEP_KEY)
                        }
                    />
                </Common.Column>
            )}
        </Common.Row>
    );

    /**
     * Handles rendering of reorder & delete controls
     * Prevents removing of KPI setting if isRequired is true
     */
    renderOrderControls = (kpiOption: ImmutableRecoveryKPIOption, totalKPICount: number) =>
        kpiOption && (
            <ReorderControls
                currentOrder={kpiOption.get('order')}
                itemCount={totalKPICount}
                onReorderItem={
                    totalKPICount > 1 && this.props.onReorderKPI
                        ? this.props.onReorderKPI(kpiOption)
                        : null
                }
            />
        );

    renderInputOptions = (isReadOnly: boolean) =>
        this.props.circuitSettings && (
            <FormContainer>
                <FormBlock>
                    <FormLabel>{this.getTranslation('cuTransferRecommendationsEnabled')}</FormLabel>
                    <Toggle
                        checked={this.props.circuitSettings.get('cuTransferRecommendationsEnabled')}
                        onClickHandler={this.handleToggleChange('cuTransferRecommendationsEnabled')}
                        disabled={isReadOnly}
                    />
                </FormBlock>
                <FormBlock>
                    <FormLabel>{this.getTranslation('maxReagentConcentrationDelta')}</FormLabel>
                    <InputField
                        value={this.props.circuitSettings.get('maxReagentConcentrationDelta') || ''}
                        onChange={this.handleInputChange('maxReagentConcentrationDelta')}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={INPUT_FIELD_STYLES}
                        renderString={isReadOnly}
                        type="number"
                        min="0"
                    />
                </FormBlock>
                <FormBlock>
                    <FormLabel>{this.getTranslation('maxRecommendations')}</FormLabel>
                    <InputField
                        value={this.props.circuitSettings.get('maxRecommendations') || ''}
                        onChange={this.handleInputChange('maxRecommendations')}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={INPUT_FIELD_STYLES}
                        renderString={isReadOnly}
                        type="number"
                        min="0"
                    />
                </FormBlock>
                <FormBlock>
                    <FormLabel>{this.getTranslation('maxTotalPlsFlowrate')}</FormLabel>
                    <InputField
                        value={this.props.circuitSettings.get('maxTotalPlsFlowrate') || ''}
                        onChange={this.handleInputChange('maxTotalPlsFlowrate')}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={INPUT_FIELD_STYLES}
                        renderString={isReadOnly}
                        type="number"
                        min="0"
                    />
                </FormBlock>
            </FormContainer>
        );

    render() {
        const highRecoveryKpiOrder =
            this.props.decisionTreeOptions &&
            this.props.decisionTreeOptions.get('highRecoveryKpiOrder');
        const lowRecoveryKpiOrder =
            this.props.decisionTreeOptions &&
            this.props.decisionTreeOptions.get('lowRecoveryKpiOrder');

        return (
            <FormContainer>
                {this.renderHeader(this.isReadOnly())}
                {this.renderInputOptions(this.isReadOnly())}
                {!highRecoveryKpiOrder.isEmpty() && (
                    <Subtitle>{this.getTranslation('highRecovery')}</Subtitle>
                )}
                <Table
                    header={this.getTableHeader()}
                    rows={this.getTableRows(
                        highRecoveryKpiOrder,
                        DECISION_TREE_RECOVERY_TYPES.HIGH_RECOVERY
                    )}
                    tdVerticalAlign="middle"
                />
                {!lowRecoveryKpiOrder.isEmpty() && (
                    <Subtitle>{this.getTranslation('lowRecovery')}</Subtitle>
                )}
                <Table
                    header={this.getTableHeader()}
                    rows={this.getTableRows(
                        lowRecoveryKpiOrder,
                        DECISION_TREE_RECOVERY_TYPES.LOW_RECOVERY
                    )}
                    tdVerticalAlign="middle"
                />
            </FormContainer>
        );
    }
}

export default injectIntl(CircuitElevationDecisionTreeOptionsStep);
