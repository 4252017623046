// @flow

/*
 * Modal using React Portals.
 * Source: https://assortment.io/posts/accessible-modal-component-react-portals-part-1
 * Unable to test: https://github.com/facebook/react/issues/11565
 */

import * as React from 'react';

// Types
import type { ReactNode } from '../../types';

// Components
import ModalContent from './ModalContent';

// Styles
import { PauseScroll, Trigger } from './styles';

type ChildrenType = Array<ReactNode> | ReactNode;
type Props = {
    children: ?ChildrenType,
    disableClose?: boolean,
    hideCloseIcon?: boolean,
    inlineTrigger?: boolean,
    modalHeight?: ?string,
    modalWidth?: ?string,
    onHandleClose?: ?() => void,
    overlay?: boolean,
    padding?: ?string,
    trigger?: ReactNode,
    style?: ?Object,
};

type State = {
    isOpen: boolean,
};

class Modal extends React.Component<Props, State> {
    static defaultProps = {
        disableClose: false,
        hideCloseIcon: false,
        inlineTrigger: false,
        modalHeight: null,
        modalWidth: null,
        onHandleClose: null,
        overlay: true,
        padding: null,
        style: {},
        trigger: null,
    };

    state = {
        isOpen: !this.props.trigger,
    };

    // Open Modal
    handleOpen = () => this.setState({ isOpen: true });

    // Close Modal
    handleClose = () =>
        this.setState(
            { isOpen: false },
            () => this.props.onHandleClose && this.props.onHandleClose()
        );

    // Close Modal
    createCloseHandler = () => () => this.handleClose();

    renderChildren = (children: Array<React.Node>) =>
        React.Children.map(children, (child: React.Node) =>
            React.cloneElement(child, {
                createModalClosingHandler: this.createCloseHandler(),
            })
        );

    render() {
        const {
            trigger,
            inlineTrigger,
            disableClose,
            hideCloseIcon,
            modalHeight,
            modalWidth,
            overlay,
            children,
            padding,
            style,
        } = this.props;

        return (
            <React.Fragment>
                {trigger && (
                    <Trigger inline={inlineTrigger} onClick={this.handleOpen}>
                        {trigger}
                    </Trigger>
                )}
                {this.state.isOpen && (
                    <React.Fragment>
                        <PauseScroll />
                        <ModalContent
                            disableClose={disableClose}
                            handleClose={this.createCloseHandler()}
                            hideCloseIcon={hideCloseIcon}
                            modalHeight={modalHeight}
                            modalWidth={modalWidth}
                            overlay={overlay}
                            padding={padding}
                            style={style}
                        >
                            {this.renderChildren(children)}
                        </ModalContent>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Modal;
