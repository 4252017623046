// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import { TextField, CheckBox, ButtonGrid, InputNumber, SecondaryButton } from 'components/_ReactUI_V1';
import { NotificationBell } from 'components/_ReactUI_V1';
import ReorderControls from 'components/ReorderControls';
import RecommendationItemList from 'components/RecommendationItemList';
import KPIValue from 'components/KPIValue';
import ErrorMessage from 'components/ErrorMessage';

// Services
import {
    selectIsCalculatingRecommendationImpact,
    selectCalculatedRecommendationImpact,
} from 'services/Recommendation/selectors';
import { calculateRecommendationImpact } from 'services/Recommendation/thunks';

// Styles
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardBodyTitle,
    FeedbackWrapper,
    FeedbackLabel,
    FeedbackText,
    FeedbackButtonWrapper,
    FeedbackAuthorWrapper,
    FeedbackAuthorName,
    FeedbackDate,
    RecommendationImpactWrapper,
    CuTransferImpactWrapper,
    CuTransferImpactLabel,
    CardBodyText
} from './styles';

// constants & helpers
import {
    RECOMMENDATION_FEEDBACK_TYPES,
    DATASET_VALUE_TYPES,
    NO_RESULT_STRING,
} from 'utils/constants';
import {
    RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION,
    RECOMMENDATION_RATIONALE_MAX_LENGTH,
} from 'utils/recommendationConstants';
import { getFormattedDateFromString } from 'utils/dateHelpers';
import { round, tryParseNumberOrNull } from 'utils/helpers';
import { getKPISettingUnit } from 'utils/kpiHelpers';

// Types
import type {
    IntlType,
    ReduxDispatch,
    ImmutableList,
    LanguageCodeConstant,
    InputEvent,
} from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type {
    ImmutableRecommendation,
    ImmutableRecommendationItem,
    ImmutableRecommendationFeedback,
    RecommendationFeedbackTypeConstants,
} from 'services/Recommendation/types';

type Props = {
    intl: IntlType,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    circuit: ImmutableCircuit,
    timezone: string,
    userLocale: LanguageCodeConstant,
    isUserPM: boolean,

    feedback?: ?ImmutableRecommendationFeedback, // feedback currently being modified.
    loadingSubmittingFeedback?: boolean,

    recommendation: ImmutableRecommendation,
    cardIndex: number,
    readOnly: boolean,
    recommendationSetWasSubmitted: boolean,
    showSubmissionTitle: boolean, // should we show if the recommendation was submitted in the title

    maxRecommendationCount?: number,

    datasetId?: number,
    hasModifiedItems?: boolean,
    isCalculatingImpact: boolean,
    calculatedImpact: ?ImmutableRecommendation,
    calculateRecommendationImpact: (
        circuitId: number,
        datasetId: number,
        recommendationId: number,
        data: any
    ) => void,
    onResetRecommendation?: ?(newRecommendation: ImmutableRecommendation) => void,

    onReorder?: ?(newOrder: number) => void,
    onDeleteRecommendation?: ?() => void,
    onChangeRationale?: ?(value: string) => void,
    onChangeIncludeInSubmission?: ?(isIncluded: boolean) => void,
    onChangeCuTransferImpact?: ?(cuTransferImpact: number) => void,
    onChangeOverallRecoveryImpact?: ?(overallRecoveryImpact: number) => void,

    onChangeItemToCustom?: ?(itemId: number) => void,
    onChangeItemKPI?: ?(itemId: number, newKPIId: number) => void,
    onChangeItemValue?: ?(itemId: number, newValue: number | string) => void,
    onAddItem?: ?() => void,
    onRemoveItem?: ?(itemId: number) => void,

    onChangeFeedback?: ?(
        recommendationId: number,
        feedbackType: ?RecommendationFeedbackTypeConstants,
        comment: ?string
    ) => void,
    onSubmitFeedback?: ?(recommendationId: number) => void, // Used in archive page
};

type State = {
    didImpactConverge: ?boolean,
    disableCalculateButton: ?boolean,
};

/**
 * Recommendation Sidebar component
 */
class RecommendationCard extends React.PureComponent<Props, State> {
    static defaultProps = {
        feedback: null,
        loadingSubmittingFeedback: false,
        maxRecommendationCount: 0,
        onChangeFeedback: null,
        onChangeIncludeInSubmission: null,
        onChangeRationale: null,
        onDeleteRecommendation: null,
        onReorder: null,

        onChangeItemToCustom: null,
        onChangeItemKPI: null,
        onChangeItemValue: null,
        onAddItem: null,
        onRemoveItem: null,
    };

    state = {
        didImpactConverge: null,
        disableCalculateButton: false,
    };

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.onResetRecommendation &&
            prevProps.isCalculatingImpact &&
            !this.props.isCalculatingImpact &&
            this.props.calculatedImpact
        ) {
            const didImpactConverge = this.props.calculatedImpact.get('converged');
            if (didImpactConverge) {
                // impact is reset when pressing calculate button, no need to reset it here.
                this.props.onResetRecommendation(
                    this.updateRecommendationWithCalculatedImpact(
                        this.props.calculatedImpact.get('recommendation')
                    )
                );
            } else {
                this.setState(
                    {
                        didImpactConverge,
                    },
                    () =>
                        this.props.onResetRecommendation(
                            this.updateRecommendationWithCalculatedImpact(
                                this.props.calculatedImpact.get('recommendation')
                            )
                        )
                );
            }
        }
    }

    // eslint-disable-next-line flowtype/no-weak-types
    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.RecommendationCard.${id}`,
            },
            data
        );

    updateRecommendationWithCalculatedImpact = (calcRecommendation: ImmutableRecommendation) =>
        this.props.recommendation
            .set('cuTransferImpact', calcRecommendation.get('cuTransferImpact'))
            .set('overallRecoveryImpact', calcRecommendation.get('overallRecoveryImpact'))
            .updateIn(
                ['recommendationItems'],
                (rItems: ImmutableList<ImmutableRecommendationItem>) =>
                    rItems.map((rItem: ImmutableRecommendationItem) => {
                        const calcRItem = calcRecommendation
                            .get('recommendationItems')
                            .find(
                                (crItem: ImmutableRecommendationItem) =>
                                    crItem.get('id') === rItem.get('id')
                            );
                        if (!calcRItem) {
                            return rItem;
                        }
                        return rItem
                            .set('relatedValue', calcRItem.get('relatedValue'))
                            .set('recommendationType', calcRItem.get('recommendationType'));
                    })
            );

    getRecommendationFeedback = () => this.props.recommendation.get('recommendationFeedback');

    getCalculatableItems = () =>
        this.props.recommendation
            .get('recommendationItems')
            .filter(
                (rItem: ImmutableRecommendationItem) =>
                    RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION.indexOf(
                        rItem.get('recommendationType')
                    ) !== -1 &&
                    rItem.get('toKpiId') !== null &&
                    rItem.get('setValueTo') !== null &&
                    rItem.get('setValueTo') !== ''
            );

    getPMFeedbackOptions = () =>
        Object.keys(RECOMMENDATION_FEEDBACK_TYPES).map((feedbackOption: string) => ({
            label: this.getTranslation(`feedbackOptions.${feedbackOption}`),
            value: feedbackOption,
        }));

    // Gray out recommendations that were not included in recommendation set and are not grayed out when recommendation is being edited
    isGrayedOut = () =>
        !this.props.recommendation.get('includeInSubmission') && this.props.readOnly;

    handleRationaleChange = (event: InputEvent) =>
        this.props.onChangeRationale && this.props.onChangeRationale(event.target.value);

    /**
     * When the user changes the feedback type
     */
    handleChangeFeedbackType = (event: InputEvent) =>
        this.props.onChangeFeedback &&
        this.props.onChangeFeedback(
            this.props.recommendation.get('id'),
            event.target.value,
            this.props.feedback && this.props.feedback.get('comment')
        );

    /**
     * When the user changes the feedback comment
     */
    handleChangeFeedbackComment = (event: InputEvent) =>
        this.props.onChangeFeedback &&
        this.props.onChangeFeedback(
            this.props.recommendation.get('id'),
            this.props.feedback && this.props.feedback.get('feedbackType'),
            event.target.value
        );

    handleIncludeRecommendationChange = () =>
        this.props.onChangeIncludeInSubmission &&
        this.props.onChangeIncludeInSubmission(
            !this.props.recommendation.get('includeInSubmission')
        );

    /**
     * When the user wants to delete this recommendation card.
     */
    handleDeleteRecommendationClick = () =>
        this.props.onDeleteRecommendation && this.props.onDeleteRecommendation();

    handleChangeImpact = (event: InputEvent) => {
        const newImpact = round(tryParseNumberOrNull(event.target.value) || 0, 2);
        this.props.onChangeCuTransferImpact(newImpact);
    };

    handleCalculate = () =>
        this.setState(
            {
                didImpactConverge: null,
            },
            () =>
                this.props.calculateRecommendationImpact(
                    this.props.circuit.get('id'),
                    this.props.datasetId,
                    this.props.recommendation.get('id'),
                    {
                        recommendation: this.props.recommendation
                            .set('recommendationItems', this.getCalculatableItems())
                            .toJS(),
                    }
                )
        );

    handleDisableCalculationButton = (disableCalculateButton: boolean) =>
        this.setState({ disableCalculateButton });

    isCalculateDisabled = () =>
        this.props.isCalculatingImpact ||
        this.getCalculatableItems().size === 0 ||
        !this.props.hasModifiedItems ||
        this.state.disableCalculateButton;

    /**
     * Render the controls to reorder the recommendations
     */
    renderControls = () =>
        this.props.onReorder && (
            <ReorderControls
                currentOrder={this.props.recommendation.get('order')}
                itemCount={this.props.maxRecommendationCount}
                onReorderItem={this.props.onReorder}
                onRemoveItem={this.handleDeleteRecommendationClick}
            />
        );

    renderRecommendationFeedback = () => {
        // feedbacks are only possible on submitted recommendations.
        if (!this.props.recommendationSetWasSubmitted) {
            return null;
        }
        const feedback = this.getRecommendationFeedback();

        if (feedback) {
            return this.renderSubmittedFeedback(feedback);
        }

        if (this.props.isUserPM) {
            // feedback not submitted and user is a PM
            return this.renderUnsubmittedFeedback();
        }

        // feedback not submitted and user is not a PM.
        return null;
    };

    renderUnsubmittedFeedback = () => {
        const labelText = 'provideFeedbackLabel';
        const comment = this.props.feedback ? this.props.feedback.get('comment') : '';
        const type = this.props.feedback ? this.props.feedback.get('feedbackType') : null;
        return (
            <FeedbackWrapper>
                <FeedbackLabel>{this.getTranslation(labelText)}</FeedbackLabel>
                <FeedbackButtonWrapper>
                    <ButtonGrid
                        options={this.getPMFeedbackOptions()}
                        onClick={this.handleChangeFeedbackType}
                        value={type}
                        disabled={this.props.loadingSubmittingFeedback}
                    />
                </FeedbackButtonWrapper>
                {type && ( // only show text field if user has selected a feedback type.
                    <TextField
                        minHeight="75px"
                        placeholder={this.getTranslation('provideFeedbackTextPlaceholder')}
                        onChange={this.handleChangeFeedbackComment}
                        value={comment || ''}
                        style={{ marginTop: '10px' }}
                        disabled={this.props.loadingSubmittingFeedback}
                    />
                )}
            </FeedbackWrapper>
        );
    };

    renderSubmittedFeedback = (feedback: ImmutableRecommendationFeedback) => {
        if (!this.props.isUserPM && !feedback) {
            // the user is not a PM and there is no feedback submitted
            return null;
        }
        const labelText = `${this.props.isUserPM ? 'PM' : 'SAM'}.providedFeedbackLabel`;
        const comment = feedback.get('comment');
        return (
            <FeedbackWrapper>
                <FeedbackLabel>{this.getTranslation(labelText)}</FeedbackLabel>
                <FeedbackButtonWrapper hasFeedback>
                    <ButtonGrid
                        options={this.getPMFeedbackOptions()}
                        onClick={null}
                        value={feedback.get('feedbackType')}
                        disabled
                        bold
                    />
                    <FeedbackAuthorWrapper>
                        <FeedbackAuthorName>{feedback.get('sentByName')}</FeedbackAuthorName>
                        <FeedbackDate>
                            {getFormattedDateFromString(
                                feedback.get('createdAt'),
                                this.props.userLocale,
                                {
                                    timeZone: this.props.timezone,
                                }
                            )}
                        </FeedbackDate>
                    </FeedbackAuthorWrapper>
                </FeedbackButtonWrapper>
                {Boolean(comment) && <FeedbackText>{comment}</FeedbackText>}
            </FeedbackWrapper>
        );
    };

    renderRecommendationItems = () => {
        return (
            <RecommendationItemList
                items={this.props.recommendation.get('recommendationItems')}
                kpiSettings={this.props.kpiSettings}
                circuit={this.props.circuit}
                readOnly={this.props.readOnly}
                isUserPM={this.props.isUserPM}
                onInvalidValueCheck={this.handleDisableCalculationButton}
                onChangeItemToCustom={this.props.onChangeItemToCustom}
                onChangeItemKPI={this.props.onChangeItemKPI}
                onChangeItemValue={this.props.onChangeItemValue}
                onAddItem={this.props.onAddItem}
                onRemoveItem={this.props.onRemoveItem}
            />
        );
    };

    renderRecommendationImpact = () => {
        const cuTransferImpact = this.props.recommendation.get('cuTransferImpact');
        const overallRecovery = this.props.recommendation.get('overallRecoveryImpact');
        if (this.props.readOnly && cuTransferImpact === null && overallRecovery === null) {
            // Impact was not saved, and the recommendation was submitted.
            // Therefore do not display the impact
            return null;
        }
        const cuTransferKpi = this.props.kpiSettings.find(
            (kpi: ImmutableKPISetting) => kpi.get('kpiType') === DATASET_VALUE_TYPES.CU_TRANSFERRED
        );
        if (!cuTransferKpi) {
            throw new Error('No Cu Transfer KPI found in settings...');
        }
        const overallRecoveryKpi = this.props.kpiSettings.find(
            (kpi: ImmutableKPISetting) =>
                kpi.get('kpiType') === DATASET_VALUE_TYPES.OVERALL_RECOVERY
        );
        if (!overallRecoveryKpi) {
            throw new Error('No Cu Transfer KPI found in settings...');
        }

        const cuTransferUnit = getKPISettingUnit(cuTransferKpi, this.props.intl);
        const cuTransferImpactRounded = round(cuTransferImpact, 2);

        const canSAMEdit = !this.props.readOnly && !this.props.isUserPM;

        return (
            <RecommendationImpactWrapper withoutMargin={!canSAMEdit}>
                {canSAMEdit && (
                    <SecondaryButton
                        onClick={this.handleCalculate}
                        disabled={this.isCalculateDisabled()}
                        text={this.getTranslation('impact.calculateButton')}
                        loading={this.props.isCalculatingImpact}
                    />
                )}
                {canSAMEdit && this.state.didImpactConverge === false && (
                    <CuTransferImpactWrapper>
                        <ErrorMessage
                            errorCode="components.RecommendationCard.impact.didNotConverge"
                            isRed
                            isSmall
                            noMargins
                        />
                    </CuTransferImpactWrapper>
                )}
                <CuTransferImpactWrapper>
                    <CuTransferImpactLabel>
                        {this.getTranslation('impact.cuTransferLabel', {
                            cuTransferKpiName: cuTransferKpi.get('name'),
                        })}
                    </CuTransferImpactLabel>
                    <InputNumber
                        value={cuTransferImpactRounded || ''}
                        unit={` ${cuTransferUnit}`}
                        renderString={this.props.readOnly || this.props.isUserPM}
                        disabled={this.props.readOnly || this.props.isCalculatingImpact}
                        height={'32px'}
                        width={'50%'}
                        handleOnChange={this.handleChangeImpact}
                        noSpinner
                    />
                </CuTransferImpactWrapper>
                <CuTransferImpactWrapper>
                    <CuTransferImpactLabel>{overallRecoveryKpi.get('name')}</CuTransferImpactLabel>
                    <KPIValue fontSize={18} kpiSetting={overallRecoveryKpi} value={overallRecovery} />
                </CuTransferImpactWrapper>
            </RecommendationImpactWrapper>
        );
    };

    render() {
        const canSAMEdit = !this.props.readOnly && !this.props.isUserPM;
        const order = this.props.isUserPM
            ? this.props.cardIndex + 1
            : this.props.recommendation.get('order');
        const isIncluded = this.props.recommendation.get('includeInSubmission');
        const darken = this.isGrayedOut();

        let titleTranslationId = 'title';
        if (this.props.showSubmissionTitle) {
            if (isIncluded && this.props.recommendationSetWasSubmitted) {
                titleTranslationId += '.submitted';
            } else {
                titleTranslationId += '.notSubmitted';
            }
        }

        const rationaleValue = this.props.recommendation.get('rationale') || '';

        return (
            <Card darken={darken} editing={canSAMEdit}>
                <CardHeader editing={canSAMEdit}>
                    <CardTitle darken={darken} editing={canSAMEdit}>
                        {this.getTranslation(titleTranslationId, {
                            order,
                        })}
                    </CardTitle>
                    {!canSAMEdit && (
                          <NotificationBell
                                fill="white"
                                width="22px"
                                margin="0"
                                exclamationBell={true}

                            />
                            )}
                    {canSAMEdit && this.renderControls()}
                </CardHeader>
                <CardBody darken={darken}>
                    <CardBodyTitle>
                        {this.getTranslation('recommendationItemsHeader')}
                    </CardBodyTitle>
                    {this.renderRecommendationItems()}
                    {this.renderRecommendationImpact()}
                </CardBody>
                <CardBody darken={darken}>
                    <CardBodyTitle>{this.getTranslation('rationaleHeader')}</CardBodyTitle>
                    <CardBodyText>
                    <TextField
                        minHeight="175px"
                        placeholder={this.getTranslation('rationalePlaceholder')}
                        value={!canSAMEdit && !rationaleValue ? NO_RESULT_STRING : rationaleValue}
                        renderString={!canSAMEdit}
                        onChange={this.handleRationaleChange}
                        maxLength={RECOMMENDATION_RATIONALE_MAX_LENGTH}
                    />
                   </CardBodyText>
                    {canSAMEdit && (
                        <CheckBox
                            label={this.getTranslation('includeRecommendationCheckboxLabel')}
                            checked={isIncluded}
                            onClickHandler={this.handleIncludeRecommendationChange}
                        />
                    )}
                    {this.renderRecommendationFeedback()}
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (_, ownProps: Props) =>
    createStructuredSelector({
        isCalculatingImpact: selectIsCalculatingRecommendationImpact(
            ownProps.recommendation.get('id')
        ),
        calculatedImpact: selectCalculatedRecommendationImpact(ownProps.recommendation.get('id')),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            calculateRecommendationImpact,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RecommendationCard));
