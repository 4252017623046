// @flow strict

import styled from 'styled-components';

import { STYLE_VALUES } from 'utils/constants';

export const FixedPosition = styled.div`
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 9999999;

    min-width: ${STYLE_VALUES.MOBILE.APP_WRAPPER_MIN_WIDTH};
    padding: 16px;

    @media (min-width: ${STYLE_VALUES.SCREEN.APP_WRAPPER_MIN_WIDTH}) {
        display: none;
    }
`;

export const MessageBlockIcon = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 12px;

    min-width: 24px;
    width: 24px;
    height: 24px;

    cursor: pointer;

    fill: white;
`;

export const MessageBlock = styled.div`
    display: flex;
    align-items: start;
    flex-wrap: nowrap;

    width: fit-content;

    margin: 0 0 0 auto;
    padding: 12px 16px;

    background-color: ${({ color }: Object) => color};
    color: white;
    border-radius: 4px;

    font-size: 16px;
    line-height: 1.4;
`;
