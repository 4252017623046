// @flow strict

// Constants
import { STREAM_TYPES, STREAM_CIRCUITS, STAGE_TYPES, DESIGN_PRESET_TYPES } from 'utils/constants';

// Helpers
import { getStageById, hasExtractorStages, hasStripperStages } from 'utils/helpers';

import type {
    ImmutableCircuit,
    StreamEntity,
    ImmutableStage,
    ImmutableStream,
} from 'services/Circuit/types';

import type { Preset } from 'services/Dataset/types';

/**
 * Return organic stream direction key used to identify the DEFAULT_STREAM_VALUES_STATUSES
 * of organic streams
 */
export const getOrganicStreamDirectionKey = (
    circuit: ImmutableCircuit,
    stream: StreamEntity
): string => {
    if (stream.streamType === STREAM_TYPES.CONTINUE) {
        const fromStage = getStageById(circuit, stream.fromStageId);
        const toStage = getStageById(circuit, stream.toStageId);
        return `${fromStage.get('stageType')}-TO-${toStage.get('stageType')}`;
    } else if (stream.streamType === STREAM_TYPES.BLEED) {
        const fromStage = getStageById(circuit, stream.fromStageId);
        return `FROM-${fromStage.get('stageType')}`;
    } else if (stream.streamType === STREAM_TYPES.FEED) {
        const toStage = getStageById(circuit, stream.toStageId);
        return `TO-${toStage.get('stageType')}`;
    }
    throw new Error('Get organic stream direction key of incompatible stream type...');
};

/**
 * Checks if all preset data was fetched for extractors if there are any and for strippers if there are any
 */
export const isValidPreset = (circuit: ImmutableCircuit, preset: Preset): boolean => {
    let isInvalidExtract = false;
    let isInvalidStrip = false;
    if (hasExtractorStages(circuit)) {
        isInvalidExtract = !(
            preset[STAGE_TYPES.EXTRACT] &&
            preset[STAGE_TYPES.EXTRACT].compute &&
            (preset[STAGE_TYPES.EXTRACT].compute === DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.OA_RATIO ||
                preset[STAGE_TYPES.EXTRACT].computeUsing)
        );
    }
    if (hasStripperStages(circuit)) {
        isInvalidStrip = !(preset[STAGE_TYPES.STRIP] && preset[STAGE_TYPES.STRIP].compute);
        if (!isInvalidStrip) {
            isInvalidStrip = !(
                preset[STAGE_TYPES.STRIP].compute === DESIGN_PRESET_TYPES.STRIP.COMPUTE.OA_RATIO ||
                preset[STAGE_TYPES.STRIP].computeUsing
            );
        }
    }
    return !isInvalidExtract && !isInvalidStrip;
};

export const isTankFlowrateComputeUsing = (
    circuit: ImmutableCircuit,
    tank: ImmutableStage
): boolean => {
    const firstExtractor = circuit
        .get('stages')
        .find(
            (stage: ImmutableStage) =>
                stage.get('stageType') === STAGE_TYPES.EXTRACT && stage.get('location') === 1
        );
    const etsWasher = circuit
        .get('stages')
        .find(
            (stage: ImmutableStage) =>
                stage.get('stageType') === STAGE_TYPES.WASHER && stage.get('location') === 1
        );
    if (!firstExtractor && !etsWasher) {
        return false;
    }
    // ETS Washer must be checked first, because it occurs first.
    const fromStageId =
        (etsWasher && etsWasher.get('id')) || (firstExtractor && firstExtractor.get('id'));
    if (!fromStageId) {
        return false;
    }
    return Boolean(
        circuit
            .get('streams')
            .find(
                (stream: ImmutableStream) =>
                    stream.get('toStageId') === tank.get('id') &&
                    stream.get('fromStageId') === fromStageId
            )
    );
};
