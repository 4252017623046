// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import type { IntlType } from 'types';

// UI Components
import { WasherContent, WasherWrapper } from './styles';

type Props = {
    intl: IntlType,
};

/**
 * The wash stage for Continue streams between same type stages.
 */
export class Washer extends React.PureComponent<Props, null> {
    render() {
        return (
            <WasherWrapper>
                <WasherContent>
                    {this.props.intl.formatMessage({
                        id: 'components.MimicDiagram.Washer',
                    })}
                </WasherContent>
            </WasherWrapper>
        );
    }
}

export default injectIntl(Washer);
