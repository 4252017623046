// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

export const MenuToolTip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${LegacyTheme.defaultSubtleColor};

    font-size: 11px;

    cursor: pointer;
    text-decoration: none;

    white-space: nowrap;

    > span {
        margin-right: 5px;
    }
`;

export const MenuToolTipContent = styled.div`
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 5px;
    padding: 4px 0px;
    background-color: ${LegacyTheme.toolTip};

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ToolTipButton = styled.span`
    padding: 8px 16px;
    cursor: pointer;
    text-align: start;
    background-color: ${LegacyTheme.toolTip};

    &:hover {
        background-color: ${LegacyTheme.buttonActiveHover};
    }
`;

export const TdStyle = {
    maxWidth: '20%',
    minWidth: '60px',
    wordBreak: 'break-word',
};

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 15px 0;
`;

export const Header = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

export const CaretWrapper = styled.div`
    display: inline-block;
    margin-left: 15px;

    transform: rotate(${({ open }: Object) => (open ? '0' : '180')}deg);
`;

export const RowExpander = styled.div`
    cursor: pointer;

    min-width: 160px;
`;

// TODO: needs refactor, MS-304
export const Label = styled.div`
    font-size: 13px;
    margin-bottom: 4px;
    margin-top: 10px;
    color: ${LegacyTheme.defaultSubtleColor7B};

    & + & {
        margin-top: 4px;
        margin-bottom: 20px;
    }
`;

// TODO: needs refactor, MS-304
export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0px 24px;

    > ul {
        padding-left: 27px;
        margin: 14px 0;
    }
`;

export const TableWrapper = styled.div``;

export const PaginationWrapper = styled.div``;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: ${STYLE_VALUES.GUTTERS.NORMAL} 40px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: ${STYLE_VALUES.GUTTERS.NORMAL} 0
            ${STYLE_VALUES.RESPONSIVE_SIDEBAR.COLLAPSED_HEIGHT};

        ${TableWrapper} {
            overflow-x: auto;
            > div {
                width: fit-content;
                min-width: 100%;
                border-radius: 0;
                border-left: 0;
                border-right: 0;
            }
        }

        ${HeaderWrapper}, ${PaginationWrapper} {
            padding: 0 ${STYLE_VALUES.GUTTERS.NORMAL};
        }
    }
`;

export const SmallHeader = styled.div`
    max-width: 100px;
`;

export const RecommendationCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
        margin-right: 32px !important;
        margin-top: 20px !important;
    }
`;

export const UpdatedAtLabel = styled.div`
    margin-top: 4px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-size: 13px;
`;

export const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
