// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */
import type { RecommendationSet, PlantDatasetMessage } from 'services/Recommendation/types';

export const SUBMIT_SUCCESS = 'app/services/Recommendation/SUBMIT_SUCCESS';
export const SUBMIT_FAILURE = 'app/services/Recommendation/SUBMIT_FAILURE';
export const IS_SUBMITTING = 'app/services/Recommendation/IS_SUBMITTING';

export const UPDATE_SUCCESS = 'app/services/Recommendation/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Recommendation/UPDATE_FAILURE';
export const IS_UPDATING = 'app/services/Recommendation/IS_UPDATING';

export const SUBMIT_FEEDBACK_SUCCESS = 'app/services/Recommendation/SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_FAILURE = 'app/services/Recommendation/SUBMIT_FEEDBACK_FAILURE';
export const IS_FEEDBACK_SUBMITTING = 'app/services/Recommendation/IS_FEEDBACK_SUBMITTING';

export const RUN_RECOMMENDATIONS_SUCCESS =
    'app/services/Recommendation/RUN_RECOMMENDATIONS_SUCCESS';
export const RUN_RECOMMENDATIONS_FAILURE =
    'app/services/Recommendation/RUN_RECOMMENDATIONS_FAILURE';
export const IS_RUNNING_RECOMMENDATIONS = 'app/services/Recommendation/IS_RUNNING_RECOMMENDATIONS';

export const IS_FETCHING_CIRCUIT_RECOMMENDATION =
    'app/services/Recommendation/IS_FETCHING_CIRCUIT_RECOMMENDATION';
export const FETCH_CIRCUIT_RECOMMENDATION_SUCCESS =
    'app/services/Recommendation/FETCH_CIRCUIT_RECOMMENDATION_SUCCESS';
export const FETCH_CIRCUIT_RECOMMENDATION_FAILURE =
    'app/services/Recommendation/FETCH_CIRCUIT_RECOMMENDATION_FAILURE';

export const IS_FETCHING_PLANT_RECOMMENDATION =
    'app/services/Recommendation/IS_FETCHING_PLANT_RECOMMENDATION';
export const FETCH_PLANT_RECOMMENDATION_SUCCESS =
    'app/services/Recommendation/FETCH_PLANT_RECOMMENDATION_SUCCESS';
export const FETCH_PLANT_RECOMMENDATION_FAILURE =
    'app/services/Recommendation/FETCH_PLANT_RECOMMENDATION_FAILURE';

export const IS_SUBMITTING_PLANT_DATASET_MESSAGE =
    'app/services/Trends/IS_SUBMITTING_PLANT_DATASET_MESSAGE';
export const SUBMIT_PLANT_DATASET_MESSAGE_SUCCESS =
    'app/services/Trends/SUBMIT_PLANT_DATASET_MESSAGE_SUCCESS';
export const SUBMIT_PLANT_DATASET_MESSAGE_FAILURE =
    'app/services/Trends/SUBMIT_PLANT_DATASET_MESSAGE_FAILURE';

export const IS_CALCULATING_RECOMMENDATION_IMPACT =
    'app/services/Recommendation/IS_CALCULATING_RECOMMENDATION_IMPACT';
export const CALCULATE_RECOMMENDATION_IMPACT_SUCCESS =
    'app/services/Recommendation/CALCULATE_RECOMMENDATION_IMPACT_SUCCESS';
export const CALCULATE_RECOMMENDATION_IMPACT_FAILURE =
    'app/services/Recommendation/CALCULATE_RECOMMENDATION_IMPACT_FAILURE';

export const receivedSubmitSuccess = (data: RecommendationSet) => ({
    // data will be JS
    type: SUBMIT_SUCCESS,
    payload: { data },
});

export const receivedSubmitFailure = (errors: {}) => ({
    type: SUBMIT_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: RecommendationSet) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const setIsSubmittingStatus = (isSubmitting: boolean = true) => ({
    type: IS_SUBMITTING,
    payload: { isSubmitting },
});

export const setIsUpdatingStatus = (isUpdating: boolean = true) => ({
    type: IS_UPDATING,
    payload: { isUpdating },
});

export const receivedSubmitFeedbackSuccess = (data: RecommendationSet) => ({
    type: SUBMIT_FEEDBACK_SUCCESS,
    payload: { data },
});

export const receivedSubmitFeedbackFailure = (errors: {}) => ({
    type: SUBMIT_FEEDBACK_FAILURE,
    payload: { errors },
});

export const setIsFeedbackSubmittingStatus = (isFeedbackSubmitting: boolean = true) => ({
    type: IS_FEEDBACK_SUBMITTING,
    payload: { isFeedbackSubmitting },
});

export const receivedRunRecommendationsSuccess = (data: RecommendationSet) => ({
    type: RUN_RECOMMENDATIONS_SUCCESS,
    payload: { data },
});

export const receivedRunRecommendationsFailure = (errors: {}) => ({
    type: RUN_RECOMMENDATIONS_FAILURE,
    payload: { errors },
});

export const setIsRunningRecommendations = (isRunningRecommendations: boolean = true) => ({
    type: IS_RUNNING_RECOMMENDATIONS,
    payload: { isRunningRecommendations },
});

export const setIsLoadingCircuitRecommendation = (isFetchingRecommendation: boolean = true) => ({
    type: IS_FETCHING_CIRCUIT_RECOMMENDATION,
    payload: { isFetchingRecommendation },
});

export const setIsLoadingPlantRecommendation = (isFetchingRecommendation: boolean = true) => ({
    type: IS_FETCHING_PLANT_RECOMMENDATION,
    payload: { isFetchingRecommendation },
});

export const receivedCircuitRecommendationSuccess = (data: RecommendationSet) => ({
    type: FETCH_CIRCUIT_RECOMMENDATION_SUCCESS,
    payload: { data },
});

export const receivedCircuitRecommendationFailure = (errors: {}) => ({
    type: FETCH_CIRCUIT_RECOMMENDATION_FAILURE,
    payload: { errors },
});

export const receivedPlantRecommendationSuccess = (data: PlantDatasetMessage) => ({
    type: FETCH_PLANT_RECOMMENDATION_SUCCESS,
    payload: { data },
});

export const receivedPlantRecommendationFailure = (errors: {}) => ({
    type: FETCH_PLANT_RECOMMENDATION_FAILURE,
    payload: { errors },
});

export const setIsSubmittingPlantDatasetMessageStatus = (
    isSubmittingPlantDatasetMessage: boolean = true
) => ({
    type: IS_SUBMITTING_PLANT_DATASET_MESSAGE,
    payload: { isSubmittingPlantDatasetMessage },
});

export const receivedSubmitPlantDatasetMessageFailure = (errors: {}) => ({
    type: SUBMIT_PLANT_DATASET_MESSAGE_FAILURE,
    payload: { errors },
});

export const receivedSubmitPlantDatasetMessageSuccess = (data: PlantDatasetMessage) => ({
    type: SUBMIT_PLANT_DATASET_MESSAGE_SUCCESS,
    payload: { data },
});

export const setIsCalculatingRecommendationImpactStatus = (
    recommendationId: number,
    isCalculatingRecommendationImpact: boolean = true
) => ({
    type: IS_CALCULATING_RECOMMENDATION_IMPACT,
    payload: {
        recommendationId,
        isCalculatingRecommendationImpact,
    },
});

export const receivedCalculateRecommendationImpactSuccess = (data: any) => ({
    type: CALCULATE_RECOMMENDATION_IMPACT_SUCCESS,
    payload: { data },
});

export const receivedCalculateRecommendationImpactFailure = (
    recommendationId: number,
    errors: {}
) => ({
    type: CALCULATE_RECOMMENDATION_IMPACT_FAILURE,
    payload: {
        recommendationId,
        errors,
    },
});
