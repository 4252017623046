// @flow strict

import React from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import {
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryLabel,
    VictoryLine,
    VictoryArea,
    VictoryScatter,
    VictoryTooltip,
    VictoryClipContainer,
} from 'victory';

// Styles
import { CheckBox, Dot, LegacyTheme, InputNumber } from 'components/_ReactUI_V1';

import { GraphStyles } from './GraphStyles';
import { Container, CheckboxContainer, YAxisInputContainer, InputBlock } from './styles';
import { InputBlockLabel } from 'styles/common';
import { KPIGraphColorSchemes } from 'styles/colors';

// Components
import KPIGraphTooltip from './KPIGraphTooltip';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import { TRENDS_PERIODS, NUMBER_INPUT_PLACEHOLDER } from 'utils/constants';
import { TARGET_TYPES, KPI_INVALID_VALUE_REASONS } from 'utils/kpiConstants';

// Helpers
import { getKPISettingUnit, getRoundedValue } from 'utils/kpiHelpers';
import { getFormattedDateFromString } from 'utils/dateHelpers';
import { mapArrayToDataPoint, getDomain } from './utils';
import { tryParseNumberOrNull } from 'utils/helpers';

// Selectors
import { selectAllKPISettings } from 'services/KPISetting/selectors';

// Types
import type {
    ImmutableList,
    ImmutableMap,
    InputEvent,
    IntlType,
    KPIColorSchemeType,
    LanguageCodeConstant,
    ReduxDispatch,
    TargetType,
} from 'types';
import type { ImmutableKPIHistory, TrendsPeriodConstant } from 'services/Trends/types';
import type { ImmutableKPISetting, KPIInvalidValueReasonType } from 'services/KPISetting/types';

const TREND_TICK_COUNTS = {
    [TRENDS_PERIODS.DAILY]: 4,
    [TRENDS_PERIODS.WEEKLY]: 7,
    [TRENDS_PERIODS.MONTHLY]: 8,
    [TRENDS_PERIODS.YEARLY]: 12,
};

const PERIOD_TO_MOMENT = {
    [TRENDS_PERIODS.DAILY]: 'ddd h:mm A',
    [TRENDS_PERIODS.WEEKLY]: 'MMM D',
    [TRENDS_PERIODS.MONTHLY]: 'M/D',
    [TRENDS_PERIODS.YEARLY]: 'MMM',
};

const PERIOD_TO_DATE = {
    [TRENDS_PERIODS.DAILY]: {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
    },
    [TRENDS_PERIODS.WEEKLY]: {
        month: 'short',
        day: 'numeric',
    },
    [TRENDS_PERIODS.MONTHLY]: {
        month: 'numeric',
        day: 'numeric',
    },
    [TRENDS_PERIODS.YEARLY]: {
        month: 'short',
    },
};

const TOOL_TIP_DATE = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
};

const GRAPH_WIDTH = 770;
const GRAPH_HEIGHT = 410;

const AXES = {
    X: 'x',
    Y: 'y',
};

const DOMAIN_INDEXES = {
    LOW: 0,
    HIGH: 1,
};

// Each axis can have a list of min to high domain values
// Currently, we only use y, x is defined by the data
export type Domain = {
    y: Array<number>,
};

export type DataPoint = {
    x: string,
    y: number,
};

type Props = {
    intl: IntlType,
    width?: number,
    height?: number,

    kpiId: number,
    kpiHistory: ImmutableKPIHistory,
    additionalKpiHistories?: ImmutableList<ImmutableKPIHistory>,
    kpiSettings: ImmutableList<ImmutableKPISetting>,

    userLanguage: LanguageCodeConstant,
    timezone: string,
    period: TrendsPeriodConstant,
    colorScheme: KPIColorSchemeType,
    showViewAverageCheckbox?: boolean,
    showTargetRange?: boolean,
    hideMainTarget?: boolean,
    hideAreaChart?: boolean,
    showYAxisLabel?: boolean,
    showYAxisInputs?: boolean,

    noDomain?: boolean,
    dynamicDomain?: boolean,
};

type State = {
    // eslint-disable-next-line flowtype/no-weak-types
    graphStyles: Object,
    data: Array<DataPoint>,
    userDomain: ImmutableMap<string, Domain> | null,
    activeDomain: Domain | null,
    showAverage: boolean,
};

class KPIGraph extends React.Component<Props, State> {
    static defaultProps = {
        colorScheme: KPIGraphColorSchemes[0],
        dynamicDomain: true,
        height: GRAPH_HEIGHT,
        showTargetRange: false,
        showYAxisLabel: false,
        showYAxisInputs: false,
        hideMainTarget: false,
        hideAreaChart: false,
        width: GRAPH_WIDTH,
    };

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        if (nextProps.kpiHistory) {
            const data = mapArrayToDataPoint(nextProps.kpiHistory);
            const kpiSetting = nextProps.kpiSettings.find(
                (kpi: ImmutableKPISetting) => kpi.get('id') === nextProps.kpiId
            );

            let calculatedDomain = null;

            // DGM-1284 To prevent user-input for graph-axes from getting overridden
            if (state?.userDomain?.getIn([AXES.Y]).size) {
                calculatedDomain = {[AXES.Y]: state.userDomain.getIn([AXES.Y]).toArray()};
            } else {
                calculatedDomain = nextProps.noDomain
                ? null
                : getDomain(kpiSetting, data, nextProps.dynamicDomain);
            }

            return {
                ...state,
                data,
                activeDomain: calculatedDomain,
                userDomain: fromJS({ ...calculatedDomain }),
                graphStyles: GraphStyles(nextProps.colorScheme),
            };
        }
    }

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id,
            },
            data
        );

    parseToMomentDate = (d: string, timeZone: string) => moment(d).tz(timeZone);

    /**
     * Get the format of the hour based on the period.
     */
    formatByPeriodMoment = () => PERIOD_TO_MOMENT[this.props.period];

    /**
     * Get the format of a single tick
     */
    getTickFormat = (x: string) => {
        if (this.props.period === TRENDS_PERIODS.DAILY) {
            // We only care about timezones when the tick period is a day because ticks are
            // displayed as a time like HH:MM (12:00 AM).
            // TODO: Day light savings is not being taken into account if we only use the timezone in daily trends. this could lead to timestamp errors.
            const momentDate = this.parseToMomentDate(x, this.props.timezone);
            const format = this.formatByPeriodMoment();
            return momentDate.format(format);
        } else {
            return getFormattedDateFromString(x, this.props.userLanguage, {
                ...PERIOD_TO_DATE[this.props.period],
                timeZone: this.props.timezone,
            });
        }
    };

    getKPISetting = (kpiId: number) =>
        this.props.kpiSettings.find((kpi: ImmutableKPISetting) => kpi.get('id') === kpiId);

    /**
     * Get the date for the tooltip
     */
    getToolTipDate = (x: string) =>
        getFormattedDateFromString(x, this.props.userLanguage, {
            ...TOOL_TIP_DATE,
            timeZone: this.props.timezone,
        });

    /**
     * Get the amount of tickets for the period selected
     */
    getTickCountFromPeriod = () => TREND_TICK_COUNTS[this.props.period];

    /**
     * Get the KPI's name with the unit
     */
    getKPIWithUnit = () => {
        const kpiSetting = this.getKPISetting(this.props.kpiId);
        const unit = getKPISettingUnit(kpiSetting, this.props.intl);
        const kpiName = kpiSetting.get('name');
        if (!unit) {
            // if there is no unit only render the name.
            return kpiName;
        }

        return this.props.intl.formatMessage(
            {
                id: `components.KPIGraph.kpiNameWithUnit`,
            },
            {
                kpiName,
                unit,
            }
        );
    };

    /**
     * Provided there is a userDomain, ensure the high domain is greater than the lower domain
     */
    isUserProvidedRangeInvalid = () => {
        if (!this.state.userDomain) {
            return true;
        }
        const min = this.state.userDomain.getIn([AXES.Y, DOMAIN_INDEXES.LOW], 0);
        const max = this.state.userDomain.getIn([AXES.Y, DOMAIN_INDEXES.HIGH], 0);
        return min >= max;
    };

    /**
     * Handles the user inputted y domains and sets them to state
     */
    handleOnChangeYAxisInput = (index: number) => (event: InputEvent) => {
        const value = tryParseNumberOrNull(event.target.value);

        this.setState((prevState: State) => {
            if (!prevState.userDomain) {
                return prevState;
            }
            const newUserDomain = prevState.userDomain.setIn([AXES.Y, index], value);
            return {
                ...prevState,
                userDomain: newUserDomain,
            };
        });
    };

    /**
     * Sets the activeDomain to the userDomain value if it is valid
     */
    handleOnBlurYAxisInput = () => {
        const isUserProvidedRangeInvalid = this.isUserProvidedRangeInvalid();
        if (!isUserProvidedRangeInvalid) {
            this.setState({ activeDomain: this.state.userDomain.toJS() });
        }
    };

    /**
     * Sets showAverage when view average checkbox is clicked
     */
    handleShowAverageChange = () => (event: InputEvent) => {
        this.setState({ showAverage: event.target.checked });
    };

    /**
     * Renders the Y axis on the left
     */
    renderYAxisLeft = () => (
        <VictoryAxis
            name="yAxis-left"
            label={this.props.showYAxisLabel ? this.getKPIWithUnit() : null}
            orientation="left"
            style={this.state.graphStyles.axisY}
            tickLabelComponent={
                <VictoryLabel
                    dx={
                        this.state.graphStyles.axisY.axisLabel.padding -
                        this.state.graphStyles.axisY.tickLabels._padding
                    }
                    verticalAnchor="end"
                    textAnchor="end"
                />
            }
            dependentAxis
        />
    );

    /**
     * Renders the Y axis on the right
     */
    renderYAxisRight = () => (
        <VictoryAxis
            name="yAxis-right"
            label={this.props.showYAxisLabel ? this.getKPIWithUnit() : null}
            orientation="right"
            style={this.state.graphStyles.axisY}
            tickLabelComponent={
                <VictoryLabel
                    dx={
                        -this.state.graphStyles.axisY.axisLabel.padding +
                        this.state.graphStyles.axisY.tickLabels._padding
                    }
                    verticalAnchor="end"
                    textAnchor="start"
                />
            }
            dependentAxis
        />
    );

    /**
     * Renders the X Axis
     */
    renderXAxis = () => (
        <VictoryAxis
            name="xAxis"
            scale={{ x: 'time' }}
            tickCount={this.getTickCountFromPeriod()}
            tickFormat={this.getTickFormat}
            standalone={false}
            style={this.state.graphStyles.axisX}
            fixLabelOverlap={true}
        />
    );

    /**
     * Renders the KPI Data area on the graph, as well as the scatter dots used for the tooltips
     */
    renderKPIData = (
        kpiSetting: ImmutableKPISetting,
        data: Array<DataPoint>,
        graphStyles?: Object = this.state.graphStyles
    ) => {
        if (!data || data.length === 0) {
            return null;
        }
        const emptyFunc = () => null;

        const kpiId = kpiSetting.get('id');

        return (
            <VictoryGroup interpolation="linear" data={data} key={kpiId}>
                <VictoryArea style={graphStyles.AreaStyles(this.props.hideAreaChart)} />
                <VictoryScatter
                    groupComponent={<VictoryClipContainer />}
                    style={graphStyles.ScatterStyles}
                    labels={emptyFunc} // required for custom tooltip.
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <KPIGraphTooltip
                                    toolTipDateFormatter={this.getToolTipDate}
                                    kpi={kpiSetting}
                                    unit={getKPISettingUnit(kpiSetting, this.props.intl)}
                                />
                            }
                        />
                    }
                />
            </VictoryGroup>
        );
    };

    renderAdditionalSeries = () =>
        this.props.additionalKpiHistories &&
        this.props.additionalKpiHistories.map((kpiHistory: ImmutableKPIHistory, idx: number) => {
            if (!kpiHistory) {
                return null;
            }
            const data = mapArrayToDataPoint(kpiHistory);
            const colorScheme = GraphStyles(
                KPIGraphColorSchemes[(idx + 1) % KPIGraphColorSchemes.length]
            );
            const kpiId = kpiHistory.get('kpiSettingId');
            return this.renderKPIData(this.getKPISetting(kpiId), data, colorScheme);
        });

    /**
     * Returns horizontal line data
     */
    getLineData = (data: Array<DataPoint>, yValue: number) => {
        if (!data || data.length === 0 || !data[0].x) {
            return null;
        }
        return [
            {
                x: data[0].x,
                y: yValue,
            },
            {
                x: data[data.length - 1].x,
                y: yValue,
            },
        ];
    };

    /**
     * Render the target line
     */
    renderTarget = (
        data: Array<DataPoint>,
        kpiId: number,
        targetType: TargetType = TARGET_TYPES.MAIN_TARGET,
        colorScheme: Object = this.state.graphStyles
    ) => {
        const target = this.getKPISetting(kpiId).get(targetType);
        if (!target || !data.length) {
            return null;
        }

        const targetData = this.getLineData(data, target);

        return (
            <VictoryGroup interpolation="linear" data={targetData}>
                <VictoryLine
                    style={
                        targetType === TARGET_TYPES.MAIN_TARGET
                            ? colorScheme.MainTargetLineStyles
                            : colorScheme.TargetLineStyles
                    }
                />
            </VictoryGroup>
        );
    };

    /**
     * Renders the kpi history's average value line
     */
    renderAverageValue = (data: Array<DataPoint>) => {
        const averageValue = this.props.kpiHistory.get('averageValue');

        if (!averageValue || !this.state.showAverage || !this.props.showViewAverageCheckbox) {
            return null;
        }

        const averageData = this.getLineData(data, averageValue);

        return (
            <VictoryGroup interpolation="linear" data={averageData}>
                <VictoryLine style={this.state.graphStyles.averageLineStyles} />
            </VictoryGroup>
        );
    };

    /**
     * Renders the checkbox to toggle the kpi history's average value line
     */
    renderToggleAverageCheckbox = () => {
        const averageValue = this.props.kpiHistory.get('averageValue');

        if (!averageValue || !this.props.showViewAverageCheckbox) {
            return null;
        }

        const kpiSetting = this.getKPISetting(this.props.kpiId);
        const unit = getKPISettingUnit(kpiSetting, this.props.intl);

        const label = this.props.intl.formatMessage(
            {
                id: 'components.KPIGraph.viewAverage',
            },
            { averageValue: getRoundedValue(averageValue, kpiSetting), unit }
        );

        return (
            <CheckboxContainer>
                <Dot fill={this.state.graphStyles.averageDotColor} margin="0 5px 0 0" />
                <CheckBox
                    label={label}
                    checked={this.state.showAverage}
                    onClickHandler={this.handleShowAverageChange()}
                    name={`toggle-average-${this.props.kpiId}`}
                />
            </CheckboxContainer>
        );
    };

    renderYAxisInput = (value: number, index: number, inputStyle: Object) => (
        <InputNumber
            handleOnChange={this.handleOnChangeYAxisInput(index)}
            onBlur={this.handleOnBlurYAxisInput}
            value={value}
            placeholder={NUMBER_INPUT_PLACEHOLDER}
            style={inputStyle}
            min={null}
            max={null}
            noSpinner // If required, set step increment to kpi's round to value
        />
    );

    renderYAxisInputError = (
        invalidReason: KPIInvalidValueReasonType,
        inputErrorData: { min: number, max: number, unit: string }
    ) => (
        <ErrorMessage
            errorMessage={this.getTranslation(
                `models.kpiSettings.invalidReasonsWithData.${invalidReason}`,
                inputErrorData
            )}
            style={{ marginTop: '6px' }}
            isRed
            isSmall
        />
    );

    renderYAxisInputs = (paddingTop: number, paddingBottom: number) => {
        const { userDomain } = this.state;

        if (!this.props.showYAxisInputs || userDomain === null) {
            return;
        }

        const HALF_INPUT_HEIGHT = 15;

        const lowDomain: number = userDomain.getIn([AXES.Y, DOMAIN_INDEXES.LOW], 0);
        const highDomain: number = userDomain.getIn([AXES.Y, DOMAIN_INDEXES.HIGH], 0);

        const isUserProvidedRangeInvalid = this.isUserProvidedRangeInvalid();

        const INPUT_STYLE = {
            width: '80px', // Possible improvement - Make dynamic based on average min/max length
            height: `${HALF_INPUT_HEIGHT * 2}px`,
            fontSize: '12px',
        };
        if (isUserProvidedRangeInvalid) {
            // $FlowFixMe
            INPUT_STYLE.borderColor = LegacyTheme.defaultWarningColor;
        }

        const unit = getKPISettingUnit(this.getKPISetting(this.props.kpiId), this.props.intl);
        const inputErrorData = { min: lowDomain, max: highDomain, unit };

        return (
            <YAxisInputContainer style={{ width: '150px' }}>
                <InputBlock>
                    <InputBlockLabel style={{ marginBottom: '6px' }}>
                        {this.getTranslation('components.KPIGraph.yAxisMaxLabel')}
                    </InputBlockLabel>
                    {this.renderYAxisInput(highDomain, DOMAIN_INDEXES.HIGH, INPUT_STYLE)}
                    {isUserProvidedRangeInvalid
                        ? this.renderYAxisInputError(
                              KPI_INVALID_VALUE_REASONS.BELOW_MIN,
                              inputErrorData
                          )
                        : null}
                </InputBlock>
                {this.props.showViewAverageCheckbox && this.renderToggleAverageCheckbox()}
                <InputBlock>
                    <InputBlockLabel style={{ marginBottom: '6px' }}>
                        {this.getTranslation('components.KPIGraph.yAxisMinLabel')}
                    </InputBlockLabel>
                    {this.renderYAxisInput(lowDomain, DOMAIN_INDEXES.LOW, INPUT_STYLE)}
                    {isUserProvidedRangeInvalid
                        ? this.renderYAxisInputError(
                              KPI_INVALID_VALUE_REASONS.ABOVE_MAX,
                              inputErrorData
                          )
                        : null}
                </InputBlock>
            </YAxisInputContainer>
        );
    };

    render() {
        const { data, activeDomain, graphStyles } = this.state;
        const { kpiId, showTargetRange, hideMainTarget } = this.props;
        const mainGraphPadding = graphStyles.mainGraphPadding;

        return (
            <Container>
                <VictoryChart
                    height={this.props.height || GRAPH_HEIGHT}
                    width={this.props.width || GRAPH_WIDTH}
                    style={graphStyles.mainGraph}
                    padding={mainGraphPadding}
                    domain={activeDomain}
                    domainPadding={{ y: [1, 1] }} // Offset in case our low/hightTargets are our domain values (prevents line trim)
                >
                    {/* Axis Components */}
                    {this.renderXAxis()}
                    {this.renderYAxisLeft()}
                    {this.renderYAxisRight()}

                    {/* render KPI Data in area */}
                    {this.renderKPIData(this.getKPISetting(kpiId), data)}
                    {this.renderAdditionalSeries()}

                    {showTargetRange && this.renderTarget(data, kpiId, TARGET_TYPES.HIGH_TARGET)}

                    {/* render KPI Target (mainTarget) if it has one */}
                    {!hideMainTarget && this.renderTarget(data, kpiId)}

                    {/* render KPI Target (lowTarget) if it has one */}
                    {showTargetRange && this.renderTarget(data, kpiId, TARGET_TYPES.LOW_TARGET)}

                    {this.state.showAverage && this.renderAverageValue(data)}
                </VictoryChart>
                {this.renderYAxisInputs(mainGraphPadding.top, mainGraphPadding.bottom)}
            </Container>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        kpiSettings: selectAllKPISettings(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(KPIGraph))
);
