// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { first } from 'lodash';

// Constants
import { OverflowBody, OverflowContainer, OverflowEnd } from 'components/_ReactUI_V1';

import { ISOTHERM_SIDEBAR_TYPES, NAVIGATION_ROUTES, SIDEBAR_STATES } from 'utils/constants';

// Services
import {
    selectAllIsotherms,
    selectIsothermsAreFetching,
    selectIsothermErrors,
    selectIsothermsAreDeleting,
    selectIsothermsAreCreating,
    selectIsothermsAreUpdating,
    selectCurrentlyVisualizedIsotherm,
    selectIsothermIsVisualizing,
} from 'services/Isotherm/selectors';
import {
    fetchAllIsotherms,
    destroyIsotherm,
    createIsotherm,
    updateIsotherm,
    predictVisualizeIsotherm,
    visualizeIsotherm,
} from 'services/Isotherm/thunks';
import { selectAllMetals, selectMetalsAreFetching } from 'services/Metal/selectors';
import { fetchAllMetals } from 'services/Metal/thunks';
import { selectAllReagents, selectReagentsAreFetching } from 'services/Reagent/selectors';
import { fetchAllReagents } from 'services/Reagent/thunks';
import { selectAllOximes, selectOximesAreFetching } from 'services/Oxime/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { newFeedback } from 'services/Feedback/thunks';

// Helpers
import { nodeToClipboard } from 'utils/helpers';

// Components
import ComputationInstructions from 'components/ComputationInstructions';
import IsothermDiagram from 'components/IsothermDiagram';
import IsothermFooter from 'components/IsothermFooter';
import IsothermsSidebarSection from 'components/IsothermManagementSidebar/IsothermsSidebarSection';
import IsothermsDataSidebarSection from 'components/IsothermManagementSidebar/IsothermsDataSidebarSection';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import IsothermSaveModal from 'components/Modals/IsothermSaveModal';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';
import { ColumnFlex } from 'styles/common';

// Types
import type { ReduxDispatch, ImmutableList, IntlType, ErrorType } from 'types';
import type {
    ImmutableIsotherm,
    RawIsotherm,
    IsothermPolynomialType,
    IsothermBaseConstant,
    IsothermSidebarTypes,
} from 'services/Isotherm/types';
import type { ImmutableMetal } from 'services/Metal/types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';
import type { SidebarRenderer } from 'containers/IsothermManagementContainer';
import type { ImmutableUser } from 'services/Authentication/types';
import type { FeedbackType } from 'services/Feedback/types';

export type DataPoint = {
    id: number,
    aqueousConcentration: ?number,
    organicConcentration: ?number,
    type: 'REFERENCE' | 'COMPUTED',
};

export type IsothermOption = {
    name: ?string,

    selectedIsothermType: IsothermBaseConstant,
    selectedReagent: ?ImmutableReagent,
    selectedOxime: ?ImmutableOxime,
    selectedMetal: ImmutableMetal,
    isothermStoichiometryFactor: ?number,

    reagentConcentration: ?number, // if reagent type === SOLVAY

    oximeRatio: ?number, // if reagent type === CUSTOM
    oximeGpl: ?number,

    plsCu: ?number, // if isotherm type === EXTRACT
    plsPh: ?number,

    electrolyteSpent: ?number, // if isotherm type === STRIP
    electrolyteAdvance: ?number,

    selectedIsothermPolynomialType: ?IsothermPolynomialType,
    selectedIsothermPolynomialOrder: ?number,
    referenceDataPoints?: Array<DataPoint>, // if creating isotherm (as opposed to predicting.)
};

export type OpenDeleteIsothermModalFunction = (value: ImmutableIsotherm) => void;

export type IsothermIdRouteType =
    | number
    | typeof ISOTHERM_SIDEBAR_TYPES.CREATE
    | typeof ISOTHERM_SIDEBAR_TYPES.PREDICT;

type Props = {
    intl: IntlType,
    errors: ErrorType,
    isothermId: IsothermIdRouteType,
    handleSidebarContent: (sidebarContent: SidebarRenderer) => void,

    fetchAllIsotherms: () => void,
    destroyIsotherm: (id: number) => void,
    createIsotherm: (isotherm: RawIsotherm) => void,
    updateIsotherm: (id: number, isotherm: ImmutableIsotherm) => void,
    predictVisualizeIsotherm: (Isotherm: RawIsotherm) => void,
    visualizeIsotherm: (Isotherm: RawIsotherm) => void,
    isotherms: ImmutableList<ImmutableIsotherm>,
    currentlyVisualizedIsotherm: ?RawIsotherm,
    loadingIsotherms: boolean,
    loadingVizualisation: boolean,
    isDeletingIsotherm: boolean,
    isCreatingIsotherm: boolean,
    isUpdatingIsotherm: boolean,

    fetchAllMetals: () => void,
    metals: ImmutableList<ImmutableMetal>,
    loadingMetals: boolean,

    fetchAllReagents: () => void,
    reagents: ImmutableList<ImmutableReagent>,
    loadingReagents: boolean,
    oximes: ImmutableList<ImmutableOxime>,
    loadingOximes: boolean,

    user: ImmutableUser,

    newFeedback: (feedbackType: FeedbackType, message: string) => void,
};

type State = {
    sidebarType: IsothermSidebarTypes | 'VISUALIZE',
    generateDiagram: boolean,
    isModified: boolean,
    viewComputed: boolean,
    updateDiagram: boolean,
    displayedIsotherms: Array<ImmutableIsotherm>,
    modal: ?{
        modalType: 'DELETE_ISOTHERM' | 'SAVE_ISOTHERM',
        selectedIsotherm: ImmutableIsotherm | RawIsotherm,
    },
};

/**
 * Main content area container for the Isotherms option in the Isotherm Management view
 */
class IsothermsContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        let sidebarType = 'VISUALIZE';
        if (this.props.isothermId) {
            if (
                this.props.isothermId === ISOTHERM_SIDEBAR_TYPES.CREATE ||
                this.props.isothermId === ISOTHERM_SIDEBAR_TYPES.PREDICT
            ) {
                sidebarType = this.props.isothermId; // this can only be create or predict.
            } else {
                // we have an isotherm id. it must be a number.
                const wantedIsotherm = this.props.isotherms.find(
                    (isotherm: ImmutableIsotherm) => isotherm.get('id') === this.props.isothermId
                );
                if (wantedIsotherm) {
                    const displayedIsotherms = [wantedIsotherm];
                    this.state = {
                        sidebarType: this.getSidebarType(displayedIsotherms),
                        displayedIsotherms,
                        generateDiagram: true,
                        viewComputed: false,
                        updateDiagram: false,
                        isModified: false,
                        modal: null,
                    };
                    return;
                }
            }
        }

        this.state = {
            sidebarType,
            isModified: false,
            viewComputed: false,
            generateDiagram: false,
            updateDiagram: false,
            displayedIsotherms: [],
            modal: null,
        };
    }

    exportTarget = null;

    // when this component updates.
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        // Check whether the isotherms that we are displayed still exists in our next props (they can be deleted) - We are in VISUALIZING mode
        if (prevState.displayedIsotherms.length > 1) {
            const nextStateDisplayedIsotherms = prevState.displayedIsotherms.filter(
                (stateIsotherm: ImmutableIsotherm) =>
                    Boolean(
                        nextProps.isotherms.find(
                            (propsIsotherm: ImmutableIsotherm) =>
                                propsIsotherm.get('id') === stateIsotherm.get('id')
                        )
                    )
            );
            return {
                displayedIsotherms: nextStateDisplayedIsotherms, // if we delete an isotherm, delete it from our state.
            };
            // Check whether the isotherm that we are displayed has been updated in our next props - We are in UPDATE_CREATE or UPDATE_PREDICT mode
        }
        return null;
    }

    /**
     * When this container is mounted, set sidebar default settings,
     * Also fetch metals and isotherms
     */
    componentDidMount() {
        this.props.handleSidebarContent(this.renderSidebar());

        // If isotherms have not been fetched, fetch'em from them backend
        if (this.props.isotherms.isEmpty()) {
            this.props.fetchAllIsotherms();
        }

        if (this.props.metals.isEmpty()) {
            this.props.fetchAllMetals();
        }

        if (this.props.reagents.isEmpty() && this.props.oximes.isEmpty()) {
            this.props.fetchAllReagents();
        }
    }

    /**
     * When the component is updated, check if the action of the confirmation modal has terminated
     * And update state accordingly.
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props, prevState: State) {
        this.props.handleSidebarContent(this.renderSidebar());
        const isCreatingNew =
            this.props.isothermId !== prevProps.isothermId &&
            this.props.isothermId === ISOTHERM_SIDEBAR_TYPES.CREATE;
        const isPredictingNew =
            this.props.isothermId !== prevProps.isothermId &&
            this.props.isothermId === ISOTHERM_SIDEBAR_TYPES.PREDICT;
        if (isCreatingNew) {
            this.setState({
                sidebarType: ISOTHERM_SIDEBAR_TYPES.CREATE,
                generateDiagram: false,
                viewComputed: false,
                isModified: false,
                displayedIsotherms: [],
                modal: null,
            });
        }
        if (isPredictingNew) {
            this.setState({
                sidebarType: ISOTHERM_SIDEBAR_TYPES.PREDICT,
                generateDiagram: false,
                viewComputed: false,
                isModified: false,
                displayedIsotherms: [],
                modal: null,
            });
        }
        if (
            !isCreatingNew &&
            !isPredictingNew &&
            !this.props.isothermId &&
            prevState.sidebarType !== 'VISUALIZE'
        ) {
            this.setState({
                sidebarType: 'VISUALIZE',
                displayedIsotherms: [],
                viewComputed: false,
                isModified: false,
                generateDiagram: false,
                modal: null,
            });
        }
        if (
            !isCreatingNew &&
            !isPredictingNew &&
            this.props.isothermId &&
            (this.props.isothermId !== prevProps.isothermId ||
                this.props.isotherms !== prevProps.isotherms)
        ) {
            const wantedIsotherm = this.props.isotherms.find(
                (isotherm: ImmutableIsotherm) => isotherm.get('id') === this.props.isothermId
            );
            if (wantedIsotherm) {
                const displayedIsotherms = [wantedIsotherm];
                this.setState({
                    displayedIsotherms,
                    sidebarType: this.getSidebarType(displayedIsotherms),
                    generateDiagram: true,
                    viewComputed: false,
                    isModified: false,
                    modal: null,
                });
            }
        }
        if (prevProps.loadingVizualisation && !this.props.loadingVizualisation) {
            this.setState({
                viewComputed: true,
                generateDiagram: true,
                updateDiagram: true,
                isModified: true, // after displaying a visualization, we should warn when moving away.
            });
        }
        if (prevProps.isDeletingIsotherm && !this.props.isDeletingIsotherm) {
            this.setState({
                modal: null,
                viewComputed: false,
                generateDiagram: false,
                updateDiagram: false,
            });
        }
        if (prevProps.isCreatingIsotherm && !this.props.isCreatingIsotherm) {
            this.setState(
                {
                    modal: null,
                    viewComputed: false,
                    generateDiagram: true,
                    displayedIsotherms: [this.props.isotherms.first()],
                    isModified: false,
                    updateDiagram: false,
                },
                () =>
                    this.props.history.push(
                        `${NAVIGATION_ROUTES.ISOTHERMS}/${this.props.isotherms.first().get('id')}`
                    )
            );
        }

        if (prevProps.isUpdatingIsotherm && !this.props.isUpdatingIsotherm) {
            this.setState((prevState2: State) => ({
                modal: null,
                viewComputed: false,
                displayedIsotherms: [
                    this.props.isotherms.find(
                        (isotherm: ImmutableIsotherm) =>
                            isotherm.get('id') === this.props.isothermId
                    ),
                ],
                isModified: false,
                generateDiagram: prevState2.generateDiagram,
                updateDiagram: false,
            }));
        }
    }

    /**
     * Get sidebar type when updating according to the type of isotherm (created or predicted)
     */
    getSidebarType = (isotherms: Array<ImmutableIsotherm>) => {
        if (isotherms.length === 1) {
            const type =
                isotherms[0].get('calculationType') === 'PREDICTED'
                    ? ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT
                    : ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE;
            return type;
        }
        return 'VISUALIZE';
    };

    /**
     * Reset the active mode to the visualize mode.
     */
    onReturnToVisualizeClicked = () => this.props.history.push(NAVIGATION_ROUTES.ISOTHERMS);

    /**
     * When the predict or create button is clicked
     */
    onNewIsothermButtonClicked = (
        sidebarType: ISOTHERM_SIDEBAR_TYPES.PREDICT | ISOTHERM_SIDEBAR_TYPES.CREATE
    ) => this.props.history.push(`${NAVIGATION_ROUTES.ISOTHERMS}/${sidebarType.toLowerCase()}`);

    /**
     * When the user wants to Visualize, Compare
     */
    onGenerateDiagramClicked = (isotherms: Array<ImmutableIsotherm>) => {
        if (isotherms.length === 1) {
            this.props.history.push(`${NAVIGATION_ROUTES.ISOTHERMS}/${isotherms[0].get('id')}`);
        } else {
            this.setState({
                generateDiagram: true,
                isModified: false,
                viewComputed: false,
                sidebarType: this.getSidebarType(isotherms),
                displayedIsotherms: isotherms,
            });
        }
    };

    /**
     * When the user clicked to predict or create an isotherm
     */
    onPredictOrCreateDiagramClicked = (
        activeMode: IsothermSidebarTypes,
        isothermOption: IsothermOption
    ) => {
        const isothermToVisualize = {
            metalId: isothermOption.selectedMetal.get('id'),
            reagentId: isothermOption.selectedReagent
                ? isothermOption.selectedReagent.get('id')
                : null,
            oximeId: isothermOption.selectedOxime ? isothermOption.selectedOxime.get('id') : null,
            name: isothermOption.name || '',
            isothermType: isothermOption.selectedIsothermType,
            polynomialType: isothermOption.selectedIsothermPolynomialType,
            polynomialOrder: isothermOption.selectedIsothermPolynomialOrder,

            // when reagent is a Solvay
            reagentConcentration: isothermOption.reagentConcentration,
            // When reagent is a Custom reagent
            oximeRatio: isothermOption.oximeRatio,
            oximeGpl: isothermOption.oximeGpl,

            // For isothermType === EXTRACT
            plsCu: isothermOption.plsCu,
            plsPh: isothermOption.plsPh,
            isothermStoichiometryFactor: isothermOption.isothermStoichiometryFactor,
            // For isothermType === STRIP
            spentMetal: isothermOption.electrolyteSpent,
            spentAcid: isothermOption.electrolyteAdvance,

            referenceDataPoints: isothermOption.referenceDataPoints || [],
        };
        this.setState(
            {
                generateDiagram: true,
                updateDiagram: true,
                isModified: true,
                viewComputed: true,
            },
            () => {
                if (
                    activeMode === ISOTHERM_SIDEBAR_TYPES.PREDICT ||
                    activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT
                ) {
                    return this.props.predictVisualizeIsotherm(isothermToVisualize);
                } else if (
                    activeMode === ISOTHERM_SIDEBAR_TYPES.CREATE ||
                    activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
                ) {
                    return this.props.visualizeIsotherm(isothermToVisualize);
                }
            }
        );
    };

    /**
     * When save isotherm is clicked on the footer when predicting or creating isotherm: open the save isotherm modal
     */
    onSaveIsothermClicked = () => {
        const selectedIso = this.props.currentlyVisualizedIsotherm.toJS();
        if (
            this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT ||
            this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            selectedIso.name = this.state.displayedIsotherms[0].get('name');
        } else {
            selectedIso.name = '';
        }
        this.setState({
            modal: {
                modalType: 'SAVE_ISOTHERM',
                selectedIsotherm: selectedIso,
            },
        });
    };

    setGraphRef = (ref: React.RefObject<HTMLDivElement>) => {
        this.exportTarget = ref;
    };

    /**
     * When copy to clipboard button is clicked on the footer after generating the diagram.
     */
    onCopyToClipboardClicked = () => {
        nodeToClipboard(this.exportTarget, (feedbackType: FeedbackType, messageId: string) =>
            this.props.newFeedback(
                feedbackType,
                this.props.intl.formatMessage({
                    id: messageId,
                })
            )
        );
    };

    /**
     * Opens the delete isotherm confirm Modal if the trash button is clicked
     */
    handleOpenDeleteIsothermModal = (isothermToDelete: ImmutableIsotherm) =>
        this.setState({
            modal: {
                modalType: 'DELETE_ISOTHERM',
                selectedIsotherm: isothermToDelete,
            },
        });

    /**
     * Deletes the given isotherm when confirm button on modal is clicked
     */
    handleDeleteIsothermConfirm = () =>
        this.state.modal &&
        this.state.modal.selectedIsotherm &&
        this.props.destroyIsotherm(this.state.modal.selectedIsotherm.get('id'));

    /**
     * Save the given isotherm when confirm button on modal is clicked
     */
    handleSaveIsothermConfirm = (isothermName: string) => {
        const isotherm = this.state.modal && this.state.modal.selectedIsotherm;
        isotherm.name = isothermName;
        if (
            this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT ||
            this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            return this.props.updateIsotherm(this.state.displayedIsotherms[0].get('id'), isotherm);
        }
        return this.props.createIsotherm(isotherm);
    };

    /**
     * Close the opened modal
     */
    handleCloseModal = () => this.setState({ modal: null });

    getAllowSaveChangesStatus = () => {
        const isotherm = this.state.modal && this.state.modal.selectedIsotherm;
        if (this.state.displayedIsotherms[0] !== isotherm && !this.state.updateDiagram) {
            return true;
        }

        return false;
    };

    /**
     * Renders the delete confirmation modal if modal state type is DELETE_ISOTHERM
     */
    renderConfirmModal = () => {
        if (!this.state.modal || this.state.modal.modalType !== 'DELETE_ISOTHERM') return null;
        return (
            <ConfirmationModal
                title={this.props.intl.formatMessage({
                    id: 'components.Modals.DeleteIsothermModal.title',
                })}
                confirmButtonText={this.props.intl.formatMessage({
                    id: 'components.Modals.DeleteIsothermModal.confirmButton',
                })}
                areYouSureStart={this.props.intl.formatMessage({
                    id: 'components.Modals.DeleteIsothermModal.areYouSure.start',
                })}
                areYouSureDanger={this.props.intl.formatMessage({
                    id: 'components.Modals.DeleteIsothermModal.areYouSure.danger',
                })}
                areYouSureEnd={this.props.intl.formatMessage({
                    id: 'components.Modals.DeleteIsothermModal.areYouSure.end',
                })}
                errors={this.props.errors}
                loading={this.props.isDeletingIsotherm}
                onConfirm={this.handleDeleteIsothermConfirm}
                onCancel={this.handleCloseModal}
                danger
            />
        );
    };

    /**
     * Renders the save isotherm modal if modal state type is SAVE_ISOTHERM
     */
    renderSaveModal = () => {
        if (!this.state.modal || this.state.modal.modalType !== 'SAVE_ISOTHERM') return null;
        if (
            this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT ||
            this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            return (
                <IsothermSaveModal
                    errors={this.props.errors}
                    loading={this.props.isUpdatingIsotherm}
                    updateIsothermName={this.state.modal.selectedIsotherm.name}
                    onConfirm={this.handleSaveIsothermConfirm}
                    onCancel={this.handleCloseModal}
                />
            );
        }
        return (
            <IsothermSaveModal
                errors={this.props.errors}
                loading={this.props.isCreatingIsotherm}
                onConfirm={this.handleSaveIsothermConfirm}
                onCancel={this.handleCloseModal}
            />
        );
    };

    /**
     * Renders the sidebar.
     */
    renderSidebar = (): SidebarRenderer => {
        let sidebarType = null;
        let node = null;
        switch (this.state.sidebarType) {
            case ISOTHERM_SIDEBAR_TYPES.PREDICT:
            case ISOTHERM_SIDEBAR_TYPES.CREATE:
            case ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT:
            case ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE:
                sidebarType = SIDEBAR_STATES.FULL;
                // eslint-disable-next-line no-case-declarations
                const isotherm =
                    this.state.displayedIsotherms.length === 1
                        ? this.state.displayedIsotherms[0]
                        : null;
                // eslint-disable-next-line no-case-declarations
                const isNewIsotherm =
                    this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.PREDICT ||
                    this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.CREATE;
                // eslint-disable-next-line no-case-declarations
                const ownsIsotherm =
                    (isotherm && isotherm.get('userId')) === this.props.user.get('id');
                // If it is a new isotherm, then it is always editable (not readonly)
                // Otherwise, it is read only if the user does not own the isotherm.
                // eslint-disable-next-line no-case-declarations
                const isReadOnly = isNewIsotherm ? false : !ownsIsotherm;
                node = (
                    <IsothermsDataSidebarSection
                        activeMode={this.state.sidebarType}
                        readOnly={isReadOnly}
                        loadingIsotherms={this.props.loadingIsotherms}
                        isothermData={
                            this.state.displayedIsotherms.length === 1
                                ? this.state.displayedIsotherms[0]
                                : null
                        }
                        metals={this.props.metals}
                        loadingMetals={this.props.loadingMetals}
                        reagents={this.props.reagents}
                        oximes={this.props.oximes}
                        loadingReagents={this.props.loadingReagents}
                        loadingOximes={this.props.loadingOximes}
                        generatedDiagram={this.state.generateDiagram}
                        handlePredictOrCreateClicked={this.onPredictOrCreateDiagramClicked}
                        loadingPredictOrCreate={this.props.loadingVizualisation}
                        handleReturnToVisualizeClicked={this.onReturnToVisualizeClicked}
                        user={this.props.user}
                    />
                );
                break;
            case 'VISUALIZE':
                sidebarType = 'TAB';
                node = (
                    <React.Fragment>
                        <IsothermsSidebarSection
                            loadingIsotherms={this.props.loadingIsotherms}
                            isotherms={this.props.isotherms}
                            displayedIsotherms={this.state.displayedIsotherms}
                            generatedDiagram={this.state.generateDiagram}
                            handleGenerateDiagramClicked={this.onGenerateDiagramClicked}
                            handleNewIsothermButtonClicked={this.onNewIsothermButtonClicked}
                            onOpenDeleteIsothermModal={this.handleOpenDeleteIsothermModal}
                            user={this.props.user}
                        />
                        {this.renderConfirmModal()}
                    </React.Fragment>
                );
                break;
            default:
                throw new Error('Unknown sidebar type in Isotherm Container');
        }
        return {
            sidebarType,
            node,
        };
    };

    renderVisualize = () => {
        // Did the user ask the diagram to be generated?
        if (!this.state.generateDiagram || this.state.displayedIsotherms.length === 0) {
            return (
                <ComputationInstructions messageId="IsothermManagement.selectIsothermsMessage" />
            );
        }
        const isotherm = first(this.state.displayedIsotherms);
        if (!isotherm) {
            return (
                <ComputationInstructions messageId="IsothermManagement.selectIsothermsMessage" />
            );
        }
        return (
            <OverflowContainer>
                <OverflowBody>
                    <IsothermDiagram
                        isotherms={this.state.displayedIsotherms}
                        loading={this.props.loadingIsotherms}
                        diagramType={isotherm.get('isothermType')}
                        setGraphRef={this.setGraphRef}
                    />
                </OverflowBody>
            </OverflowContainer>
        );
    };

    renderCreate = () => {
        // Did the user ask the diagram to be generated?
        if (!this.state.generateDiagram || !this.props.currentlyVisualizedIsotherm) {
            if (this.state.sidebarType === ISOTHERM_SIDEBAR_TYPES.CREATE) {
                return (
                    <ComputationInstructions messageId="IsothermManagement.createIsothermMessage" />
                );
            } else {
                return (
                    <ComputationInstructions messageId="IsothermManagement.predictIsothermMessage" />
                );
            }
        }
        const isotherms = this.state.viewComputed ? [this.props.currentlyVisualizedIsotherm] : [];
        return (
            <OverflowContainer>
                <OverflowBody>
                    <IsothermDiagram
                        isotherms={isotherms}
                        loading={this.props.loadingVizualisation}
                        diagramType={this.props.currentlyVisualizedIsotherm.get('isothermType')}
                        setGraphRef={this.setGraphRef}
                    />
                </OverflowBody>
                <OverflowEnd>
                    {!this.props.loadingVizualisation && (
                        <IsothermFooter
                            allowSaveChange={this.state.updateDiagram}
                            handleCopyToClipboardClicked={this.onCopyToClipboardClicked}
                            handleSaveIsothermClicked={this.onSaveIsothermClicked}
                        />
                    )}
                </OverflowEnd>
                {this.renderSaveModal()}
                <PreventNavigationPrompt
                    shouldBlock={Boolean(
                        this.state.isModified ||
                            (this.state.viewComputed && this.props.currentlyVisualizedIsotherm)
                    )}
                />
            </OverflowContainer>
        );
    };

    renderUpdate = () => {
        // Update isotherm.
        const visualizedIsotherms =
            this.state.updateDiagram &&
            this.state.viewComputed &&
            this.props.currentlyVisualizedIsotherm
                ? [this.props.currentlyVisualizedIsotherm]
                : this.state.displayedIsotherms;
        if (visualizedIsotherms.length !== 1) {
            throw new Error('Wrong number of isotherm to update...');
        }
        const isotherm = visualizedIsotherms[0];
        const ownsIsotherm =
            (visualizedIsotherms[0] && isotherm.get('userId')) === this.props.user.get('id');
        return (
            <OverflowContainer>
                <OverflowBody>
                    <IsothermDiagram
                        isotherms={[isotherm]}
                        loading={this.props.loadingVizualisation}
                        diagramType={isotherm.get('isothermType')}
                        setGraphRef={this.setGraphRef}
                    />
                </OverflowBody>
                <OverflowEnd>
                    {!this.props.loadingVizualisation && (
                        <IsothermFooter
                            allowSaveChange={this.state.updateDiagram}
                            showSaveButton={true} // TODO: MS-912 -- should be `ownsIsotherm` however look at
                            handleCopyToClipboardClicked={this.onCopyToClipboardClicked}
                            handleSaveIsothermClicked={this.onSaveIsothermClicked}
                            saveUpdate
                        />
                    )}
                </OverflowEnd>
                {this.renderSaveModal()}
                <PreventNavigationPrompt
                    shouldBlock={Boolean(
                        this.state.isModified ||
                            (this.state.viewComputed && this.props.currentlyVisualizedIsotherm)
                    )}
                />
            </OverflowContainer>
        );
    };

    render() {
        switch (this.state.sidebarType) {
            case 'VISUALIZE':
                return this.renderVisualize();
            case ISOTHERM_SIDEBAR_TYPES.CREATE:
            case ISOTHERM_SIDEBAR_TYPES.PREDICT:
                return this.renderCreate();
            case ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE:
            case ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT:
                return this.renderUpdate();
            default:
                return null;
        }
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectIsothermErrors(),
    isotherms: selectAllIsotherms(),
    currentlyVisualizedIsotherm: selectCurrentlyVisualizedIsotherm(),
    loadingIsotherms: selectIsothermsAreFetching(),
    loadingVizualisation: selectIsothermIsVisualizing(),
    metals: selectAllMetals(),
    loadingMetals: selectMetalsAreFetching(),
    reagents: selectAllReagents(),
    oximes: selectAllOximes(),
    loadingReagents: selectReagentsAreFetching(),
    loadingOximes: selectOximesAreFetching(),
    isDeletingIsotherm: selectIsothermsAreDeleting(),
    isCreatingIsotherm: selectIsothermsAreCreating(),
    isUpdatingIsotherm: selectIsothermsAreUpdating(),
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllIsotherms,
            fetchAllMetals,
            fetchAllReagents,
            destroyIsotherm,
            createIsotherm,
            updateIsotherm,
            visualizeIsotherm,
            predictVisualizeIsotherm,
            newFeedback,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(IsothermsContainer))
);
