// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState, ImmutableList } from '../types';
import type { ImmutableKPISettingState, ImmutableKPISetting } from 'services/KPISetting/types';

/**
 * Direct selector to the trend state domain
 */
export const selectKPISettingsState = () => (state: ImmutableReduxState) => state.get('kpiSetting');

/**
 * Select list of KPI settings
 */
export const selectAllKPISettings = () =>
    createSelector(
        selectKPISettingsState(),
        (substate: ImmutableKPISettingState) => substate.get('kpiSettings').toList()
    );

export const selectKPISetting = (kpiSettingId: number) =>
    createSelector(
        selectAllKPISettings(),
        (list: ImmutableList<ImmutableKPISetting>) =>
            list.find((kpiSetting: ImmutableKPISetting) => kpiSetting.get('id') === kpiSettingId)
    );

/**
 * Select whether the any KPI settings is updating
 * @returns A boolean for the state of the update
 */
export const selectIsUpdatingKPISettings = () =>
    createSelector(
        selectKPISettingsState(),
        (substate: ImmutableKPISettingState) => substate.get('isUpdatingKPISetting')
    );

/**
 * Select the errors that occured during update
 * @returns A list of errors
 */
export const selectUpdateKPISettingsErrors = () =>
    createSelector(
        selectKPISettingsState(),
        (substate: ImmutableKPISettingState) => substate.get('updateErrorsKPISetting')
    );
