// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Container
import ClientPlantUserManagementContainer from 'containers/ClientPlantUserManagementContainer';

// Types
import type { Navigation, IntlType } from 'types';

type Props = {
    intl: IntlType,
};

export class ClientPlantUserManagement extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.ClientPlantUserManagement.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.ClientPlantUserManagement.helmetDescription',
                        })}
                    />
                </Helmet>
                <ClientPlantUserManagementContainer />
            </React.Fragment>
        );
    }
}

export default injectIntl(ClientPlantUserManagement);
