// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, MarginedWrapper } from './styles';

// Components
import { PrimaryButton } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import type { NextButtonTypeConstant } from 'containers/ElevationContainer';

type Props = {
    intl: IntlType,
    maxWidth?: string,

    nextButtonType: NextButtonTypeConstant,
    isNextEnabled: boolean,
    isNextLoading: boolean,
    onNextClicked: () => void,
    onBackClicked: () => void,
};

class ElevationFooter extends React.PureComponent<Props> {
    static defaultProps = {
        maxWidth: '',
    };

    getTranslation = (translationId: string) =>
        this.props.intl.formatMessage({
            id: `components.ElevationFooter.${translationId}`,
        });

    render() {
        return (
            <Wrapper>
                <MarginedWrapper maxWidth={this.props.maxWidth}>
                    <PrimaryButton
                        text={this.getTranslation('backButton')}
                        onClick={this.props.onBackClicked}
                    />
                    <PrimaryButton
                        text={this.getTranslation(`nextButton.${this.props.nextButtonType}`)}
                        loading={this.props.isNextLoading}
                        disabled={!this.props.isNextEnabled}
                        onClick={this.props.onNextClicked}
                    />
                </MarginedWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(ElevationFooter);
