// @flow strict

import type {
    NotificationReadState,
    TotalUnreadNotifications,
    NotificationSetting,
    NotificationScheduleDay,
    NotificationTypeUserStatus,
    NotificationPlantSetting,
} from 'services/Notifications/types';
import type { ErrorType, QueryStructure } from 'types';

export const NOTIFICATIONS_ARE_QUERYING = 'app/services/Notifications/NOTIFICATIONS_ARE_QUERYING';
export const NOTIFICATIONS_QUERY_SUCCESS = 'app/services/Notifications/NOTIFICATIONS_QUERY_SUCCESS';
export const NOTIFICATIONS_QUERY_FAILURE = 'app/services/Notifications/NOTIFICATIONS_QUERY_FAILURE';

export const SET_READ_STATE = 'app/services/Notifications/SET_READ_STATE';
export const READ_STATE_UPDATE_SUCCESS = 'app/services/Notifications/READ_STATE_UPDATE_SUCCESS';
export const READ_STATE_UPDATE_FAILURE = 'app/services/Notifications/READ_STATE_UPDATE_FAILURE';

export const MARK_ALL_AS_READ_IS_UPDATING =
    'app/services/Notifications/MARK_ALL_AS_READ_IS_UPDATING';
export const MARK_ALL_AS_READ_UPDATE_SUCCESS =
    'app/services/Notifications/MARK_ALL_AS_READ_UPDATE_SUCCESS';
export const MARK_ALL_AS_READ_UPDATE_FAILURE =
    'app/services/Notifications/MARK_ALL_AS_READ_UPDATE_FAILURE';

export const UNREAD_TOTAL_IS_FETCHING = 'app/services/Notifications/UNREAD_TOTAL_IS_FETCHING';
export const UNREAD_TOTAL_FETCH_SUCCESS = 'app/services/Notifications/UNREAD_TOTAL_FETCH_SUCCESS';
export const UNREAD_TOTAL_FETCH_FAILURE = 'app/services/Notifications/UNREAD_TOTAL_FETCH_FAILURE';

export const SETTINGS_ARE_FETCHING = 'app/services/Notifications/SETTINGS_ARE_FETCHING';
export const SETTINGS_FETCH_SUCCESS = 'app/services/Notifications/SETTINGS_FETCH_SUCCESS';
export const SETTINGS_FETCH_FAILURE = 'app/services/Notifications/SETTINGS_FETCH_FAILURE';

export const SETTINGS_ARE_UPDATING = 'app/services/Notifications/SETTINGS_ARE_UPDATING';
export const SETTINGS_UPDATE_SUCCESS = 'app/services/Notifications/SETTINGS_UPDATE_SUCCESS';
export const SETTINGS_UPDATE_FAILURE = 'app/services/Notifications/SETTINGS_UPDATE_FAILURE';

export const PLANT_SETTINGS_ARE_FETCHING = 'app/services/Notifications/PLANT_SETTINGS_ARE_FETCHING';
export const PLANT_SETTINGS_FETCH_SUCCESS =
    'app/services/Notifications/PLANT_SETTINGS_FETCH_SUCCESS';
export const PLANT_SETTINGS_FETCH_FAILURE =
    'app/services/Notifications/PLANT_SETTINGS_FETCH_FAILURE';

export const PLANT_SETTINGS_ARE_UPDATING = 'app/services/Notifications/PLANT_SETTINGS_ARE_UPDATING';
export const PLANT_SETTINGS_UPDATE_SUCCESS =
    'app/services/Notifications/PLANT_SETTINGS_UPDATE_SUCCESS';
export const PLANT_SETTINGS_UPDATE_FAILURE =
    'app/services/Notifications/PLANT_SETTINGS_UPDATE_FAILURE';

export const SCHEDULE_IS_FETCHING = 'app/services/Notifications/SCHEDULE_IS_FETCHING';
export const SCHEDULE_FETCH_SUCCESS = 'app/services/Notifications/SCHEDULE_FETCH_SUCCESS';
export const SCHEDULE_FETCH_FAILURE = 'app/services/Notifications/SCHEDULE_FETCH_FAILURE';

export const SCHEDULE_IS_UPDATING = 'app/services/Notifications/SCHEDULE_IS_UPDATING';
export const SCHEDULE_UPDATE_SUCCESS = 'app/services/Notifications/SCHEDULE_UPDATE_SUCCESS';
export const SCHEDULE_UPDATE_FAILURE = 'app/services/Notifications/SCHEDULE_UPDATE_FAILURE';

export const NOTIFICATION_TYPE_USERS_STATUSES_ARE_FETCHING =
    'app/services/Notifications/NOTIFICATION_TYPE_USERS_STATUSES_ARE_FETCHING';
export const NOTIFICATION_TYPE_USERS_STATUSES_FETCH_SUCCESS =
    'app/services/Notifications/NOTIFICATION_TYPE_USERS_STATUSES_FETCH_SUCCESS';
export const NOTIFICATION_TYPE_USERS_STATUSES_FETCH_FAILURE =
    'app/services/Notifications/NOTIFICATION_TYPE_USERS_STATUSES_FETCH_FAILURE';

export const PHONE_REMOVAL_SUCCESS = 'app/services/Notifications/PHONE_REMOVAL_SUCCESS';
export const PHONE_REMOVAL_FAILURE = 'app/services/Notifications/PHONE_REMOVAL_FAILURE';
export const PHONE_REMOVAL_IS_LOADING = 'app/services/Notifications/PHONE_REMOVAL_IS_LOADING';

export const setNotificationsAreQuerying = (notificationsAreQuerying: boolean = true) => ({
    type: NOTIFICATIONS_ARE_QUERYING,
    payload: { notificationsAreQuerying },
});

export const receivedNotificationsQuerySuccess = (data: QueryStructure) => ({
    type: NOTIFICATIONS_QUERY_SUCCESS,
    payload: { data },
});

export const receivedNotificationsQueryFailure = (errors: ErrorType) => ({
    type: NOTIFICATIONS_QUERY_FAILURE,
    payload: { errors },
});

export const setReadState = (notificationId: number, read: boolean) => ({
    type: SET_READ_STATE,
    payload: { notificationId, read },
});

export const receivedReadStateUpdateSuccess = (data: NotificatonReadState) => ({
    type: READ_STATE_UPDATE_SUCCESS,
    payload: { data },
});

export const receivedReadStateUpdateFailure = (errors: ErrorType) => ({
    type: READ_STATE_UPDATE_FAILURE,
    payload: { errors },
});

export const setMarkAllAsReadIsUpdating = (markAllAsReadIsUpdating: boolean = true) => ({
    type: MARK_ALL_AS_READ_IS_UPDATING,
    payload: { markAllAsReadIsUpdating },
});

export const receivedMarkAllAsReadUpdateSuccess = () => ({
    type: MARK_ALL_AS_READ_UPDATE_SUCCESS,
});

export const receivedMarkAllAsReadUpdateFailure = (errors: ErrorType) => ({
    type: MARK_ALL_AS_READ_UPDATE_FAILURE,
    payload: { errors },
});

export const setUnreadTotalIsFetching = (unreadTotalIsFetching: boolean = true) => ({
    type: UNREAD_TOTAL_IS_FETCHING,
    payload: { unreadTotalIsFetching },
});

export const receivedUnreadTotalFetchSuccess = (data: TotalUnreadNotifications) => ({
    type: UNREAD_TOTAL_FETCH_SUCCESS,
    payload: { data },
});

export const receivedUnreadTotalFetchFailure = (errors: ErrorType) => ({
    type: UNREAD_TOTAL_FETCH_FAILURE,
    payload: { errors },
});

export const setSettingsAreFetching = (settingsAreFetching: boolean = true) => ({
    type: SETTINGS_ARE_FETCHING,
    payload: { settingsAreFetching },
});

export const receivedSettingsFetchSuccess = (data: Array<NotificationSetting>) => ({
    type: SETTINGS_FETCH_SUCCESS,
    payload: { data },
});

export const receivedSettingsFetchFailure = (errors: ErrorType) => ({
    type: SETTINGS_FETCH_FAILURE,
    payload: { errors },
});

export const setSettingsAreUpdating = (settingsAreUpdating: boolean = true) => ({
    type: SETTINGS_ARE_UPDATING,
    payload: { settingsAreUpdating },
});

export const receivedSettingsUpdateSuccess = (data: Array<NotificationSetting>) => ({
    type: SETTINGS_UPDATE_SUCCESS,
    payload: { data },
});

export const receivedSettingsUpdateFailure = (errors: ErrorType) => ({
    type: SETTINGS_UPDATE_FAILURE,
    payload: { errors },
});

export const setPlantSettingsAreFetching = (plantSettingsAreFetching: boolean = true) => ({
    type: PLANT_SETTINGS_ARE_FETCHING,
    payload: { plantSettingsAreFetching },
});

export const receivedPlantSettingsFetchSuccess = (data: Array<NotificationPlantSetting>) => ({
    type: PLANT_SETTINGS_FETCH_SUCCESS,
    payload: { data },
});

export const receivedPlantSettingsFetchFailure = (errors: ErrorType) => ({
    type: PLANT_SETTINGS_FETCH_FAILURE,
    payload: { errors },
});

export const setPlantSettingsAreUpdating = (plantSettingsAreUpdating: boolean = true) => ({
    type: PLANT_SETTINGS_ARE_UPDATING,
    payload: { plantSettingsAreUpdating },
});

export const receivedPlantSettingsUpdateSuccess = (data: Array<NotificationPlantSetting>) => ({
    type: PLANT_SETTINGS_UPDATE_SUCCESS,
    payload: { data },
});

export const receivedPlantSettingsUpdateFailure = (errors: ErrorType) => ({
    type: PLANT_SETTINGS_UPDATE_FAILURE,
    payload: { errors },
});

export const setScheduleIsFetching = (scheduleIsFetching: boolean = true) => ({
    type: SCHEDULE_IS_FETCHING,
    payload: { scheduleIsFetching },
});

export const receivedScheduleFetchSuccess = (data: Array<NotificationScheduleDay>) => ({
    type: SCHEDULE_FETCH_SUCCESS,
    payload: { data },
});

export const receivedScheduleFetchFailure = (errors: ErrorType) => ({
    type: SCHEDULE_FETCH_FAILURE,
    payload: { errors },
});

export const setScheduleIsUpdating = (scheduleIsUpdating: boolean = true) => ({
    type: SCHEDULE_IS_UPDATING,
    payload: { scheduleIsUpdating },
});

export const receivedScheduleUpdateSuccess = (data: Array<NotificationScheduleDay>) => ({
    type: SCHEDULE_UPDATE_SUCCESS,
    payload: { data },
});

export const receivedScheduleUpdateFailure = (errors: ErrorType) => ({
    type: SCHEDULE_UPDATE_FAILURE,
    payload: { errors },
});

export const setNotificationTypeUsersStatusesAreFetching = (
    notificationTypeUsersStatusesAreFetching: boolean = true
) => ({
    type: NOTIFICATION_TYPE_USERS_STATUSES_ARE_FETCHING,
    payload: { notificationTypeUsersStatusesAreFetching },
});

export const receivedNotificationTypeUsersStatusesFetchSuccess = (
    data: Array<NotificationTypeUserStatus>
) => ({
    type: NOTIFICATION_TYPE_USERS_STATUSES_FETCH_SUCCESS,
    payload: { data },
});

export const receivedNotificationTypeUsersStatusesFetchFailure = (errors: ErrorType) => ({
    type: NOTIFICATION_TYPE_USERS_STATUSES_FETCH_FAILURE,
    payload: { errors },
});

export const receivedPhoneRemovalFailure = (errors: ErrorType) => ({
    type: PHONE_REMOVAL_FAILURE,
    payload: { errors },
});

export const setPhoneRemovalIsLoading = (phoneRemovalIsLoading: boolean = true) => ({
    type: PHONE_REMOVAL_IS_LOADING,
    payload: { phoneRemovalIsLoading },
});

export const receivedPhoneRemovalSuccess = (data: Array<NotificationTypeUserStatus>) => ({
    type: PHONE_REMOVAL_SUCCESS,
    payload: { data },
});
