// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
`;

export const LoaderText = styled.p`
    font-size: 14px;
    color: ${LegacyTheme.defaultColor};
`;
