// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { SidebarBody, BodySection } from '../styles';
import {
    HeaderTitleWrapper,
    HeaderTitle,
    SectionWrapper,
    SectionTitle,
    SectionInfoWrapper,
    InfoWrapper,
    Label,
    Value,
} from './styles';
import { colors } from 'styles/colors';

// Components
import { NavigationChevron } from 'components/_ReactUI_V1';

// Constants
import { SENSITIVITY_OPTIONS, DATASET_VALUE_TYPES, STREAM_VALUE_TYPES } from 'utils/constants';

// Helpers
import { round } from 'utils/helpers';

// Types
import type { IntlType } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type {
    ImmutableDataset,
    SensitivityOptionConstant,
    ImmutableDatasetValue,
    ImmutableStreamValue,
    StreamValuesConstant,
    DatasetValuesConstant,
} from 'services/Dataset/types';

const ROUND_TO_DECIMAL = 2;

type Props = {
    intl: IntlType,

    circuit: ImmutableCircuit,
    dataset: ImmutableDataset,

    variableX: SensitivityOptionConstant,
    variableXMin: number,
    variableXMax: number,

    // only in 3D
    variableY?: SensitivityOptionConstant,
    variableYMin?: number,
    variableYMax?: number,

    returnTo: '3D' | '2D',
    handleReturnToSelection: () => void,
};

/**
 * The sidebar body section for the 2D and 3D sensitivity plot.
 */
class SensitivitySidebarSummarySection extends React.PureComponent<Props> {
    static defaultProps = {
        variableY: null,
        variableYMax: 0,
        variableYMin: 0,
    };

    getReagentName = (): string => this.props.circuit.getIn(['reagent', 'name']);

    getOximeName = (): string => this.props.circuit.getIn(['oxime', 'name']);

    isOxime = (): boolean =>
        this.props.circuit.has('oxime') && Boolean(this.props.circuit.get('oxime'));

    getDatasetValue = (valueType: DatasetValuesConstant): number => {
        const value = this.props.dataset
            .get('datasetValues')
            .find(
                (datasetValue: ImmutableDatasetValue) => datasetValue.get('valueType') === valueType
            );
        if (!value) {
            return 0;
        }
        return round(value.get('value'), ROUND_TO_DECIMAL);
    };

    getStreamValue = (valueType: StreamValuesConstant): number => {
        const value = this.props.dataset
            .get('streamValues')
            .find(
                (streamValue: ImmutableStreamValue) => streamValue.get('valueType') === valueType
            );
        if (!value) {
            return 0;
        }
        return round(value.get('value'), ROUND_TO_DECIMAL);
    };

    isVariableVaried = (sensitivityOption: SensitivityOptionConstant): boolean =>
        this.props.variableX === sensitivityOption || this.props.variableY === sensitivityOption;

    getVariableMin = (sensitivityOption: SensitivityOptionConstant): number => {
        if (this.props.variableX === sensitivityOption) {
            return this.props.variableXMin;
        }
        if (this.props.variableY === sensitivityOption) {
            return this.props.variableYMin;
        }
        return 0;
    };

    getVariableMax = (sensitivityOption: SensitivityOptionConstant): number => {
        if (this.props.variableX === sensitivityOption) {
            return this.props.variableXMax;
        }
        if (this.props.variableY === sensitivityOption) {
            return this.props.variableYMax;
        }
        return 0;
    };

    getReagentConcentration = (isOxime: boolean) =>
        isOxime
            ? this.getDatasetValue(DATASET_VALUE_TYPES.OXIME_GPL)
            : this.getDatasetValue(DATASET_VALUE_TYPES.REAGENT_CONCENTRATION);

    renderReagentBody = (isOxime: boolean) => (
        <SectionWrapper>
            <SectionTitle>
                {this.props.intl.formatMessage({
                    id: `components.CircuitComputationSidebar.SensitivitySummary.${
                        isOxime ? 'oxime' : 'reagent'
                    }.title`,
                })}
            </SectionTitle>
            <SectionInfoWrapper direction="column">
                <InfoWrapper>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.SensitivitySummary.${
                                isOxime ? 'oxime' : 'reagent'
                            }.name`,
                        })}
                    </Label>
                    <Value>{isOxime ? this.getOximeName() : this.getReagentName()}</Value>
                </InfoWrapper>
                <InfoWrapper extraMargin>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.SensitivitySummary.${
                                isOxime ? 'oxime' : 'reagent'
                            }.concentration`,
                        })}
                    </Label>
                    <Value>
                        {this.isVariableVaried(SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION)
                            ? this.props.intl.formatMessage(
                                  {
                                      id: `components.CircuitComputationSidebar.SensitivitySummary.variableVariedMessage`,
                                  },
                                  {
                                      min: round(
                                          this.getVariableMin(
                                              SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION
                                          ),
                                          ROUND_TO_DECIMAL
                                      ),
                                      max: round(
                                          this.getVariableMax(
                                              SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION
                                          ),
                                          ROUND_TO_DECIMAL
                                      ),
                                  }
                              )
                            : this.getReagentConcentration(isOxime)}
                    </Value>
                </InfoWrapper>
                {isOxime && (
                    <InfoWrapper extraMargin>
                        <Label>
                            {this.props.intl.formatMessage({
                                id: `components.CircuitComputationSidebar.SensitivitySummary.oxime.ratio`,
                            })}
                        </Label>
                        <Value>{this.getDatasetValue(DATASET_VALUE_TYPES.OXIME_RATIO)}</Value>
                    </InfoWrapper>
                )}
            </SectionInfoWrapper>
        </SectionWrapper>
    );

    renderPLS = () => (
        <SectionWrapper>
            <SectionTitle>
                {this.props.intl.formatMessage({
                    id: `components.CircuitComputationSidebar.SensitivitySummary.pls.title`,
                })}
            </SectionTitle>
            <SectionInfoWrapper direction="row">
                <InfoWrapper>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.SensitivitySummary.pls.plsCu`,
                        })}
                    </Label>
                    <Value>
                        {this.isVariableVaried(SENSITIVITY_OPTIONS.PLS_COMPOSITION)
                            ? this.props.intl.formatMessage(
                                  {
                                      id: `components.CircuitComputationSidebar.SensitivitySummary.variableVariedMessage`,
                                  },
                                  {
                                      min: round(
                                          this.getVariableMin(SENSITIVITY_OPTIONS.PLS_COMPOSITION),
                                          ROUND_TO_DECIMAL
                                      ),
                                      max: round(
                                          this.getVariableMax(SENSITIVITY_OPTIONS.PLS_COMPOSITION),
                                          ROUND_TO_DECIMAL
                                      ),
                                  }
                              )
                            : this.getStreamValue(STREAM_VALUE_TYPES.PLS)}
                    </Value>
                </InfoWrapper>
                <InfoWrapper>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.SensitivitySummary.pls.pH`,
                        })}
                    </Label>
                    <Value>
                        {this.isVariableVaried(SENSITIVITY_OPTIONS.PLS_PH)
                            ? this.props.intl.formatMessage(
                                  {
                                      id: `components.CircuitComputationSidebar.SensitivitySummary.variableVariedMessage`,
                                  },
                                  {
                                      min: round(
                                          this.getVariableMin(SENSITIVITY_OPTIONS.PLS_PH),
                                          ROUND_TO_DECIMAL
                                      ),
                                      max: round(
                                          this.getVariableMax(SENSITIVITY_OPTIONS.PLS_PH),
                                          ROUND_TO_DECIMAL
                                      ),
                                  }
                              )
                            : this.getStreamValue(STREAM_VALUE_TYPES.PH)}
                    </Value>
                </InfoWrapper>
            </SectionInfoWrapper>
        </SectionWrapper>
    );

    renderElectrolyte = () => (
        <SectionWrapper>
            <SectionTitle>
                {this.props.intl.formatMessage({
                    id: `components.CircuitComputationSidebar.SensitivitySummary.electrolyte.title`,
                })}
            </SectionTitle>
            <SectionInfoWrapper direction="row">
                <InfoWrapper>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.SensitivitySummary.electrolyte.spent`,
                        })}
                    </Label>
                    <Value>
                        {this.isVariableVaried(SENSITIVITY_OPTIONS.LEAN_ELECTROLYTE)
                            ? this.props.intl.formatMessage(
                                  {
                                      id: `components.CircuitComputationSidebar.SensitivitySummary.variableVariedMessage`,
                                  },
                                  {
                                      min: round(
                                          this.getVariableMin(SENSITIVITY_OPTIONS.LEAN_ELECTROLYTE),
                                          ROUND_TO_DECIMAL
                                      ),
                                      max: round(
                                          this.getVariableMax(SENSITIVITY_OPTIONS.LEAN_ELECTROLYTE),
                                          ROUND_TO_DECIMAL
                                      ),
                                  }
                              )
                            : this.getStreamValue(STREAM_VALUE_TYPES.SPENT)}
                    </Value>
                </InfoWrapper>
                <InfoWrapper>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.SensitivitySummary.electrolyte.acid`,
                        })}
                    </Label>
                    <Value>
                        {this.isVariableVaried(SENSITIVITY_OPTIONS.ACID)
                            ? this.props.intl.formatMessage(
                                  {
                                      id: `components.CircuitComputationSidebar.SensitivitySummary.variableVariedMessage`,
                                  },
                                  {
                                      min: round(
                                          this.getVariableMin(SENSITIVITY_OPTIONS.ACID),
                                          ROUND_TO_DECIMAL
                                      ),
                                      max: round(
                                          this.getVariableMax(SENSITIVITY_OPTIONS.ACID),
                                          ROUND_TO_DECIMAL
                                      ),
                                  }
                              )
                            : this.getStreamValue(STREAM_VALUE_TYPES.ACID)}
                    </Value>
                </InfoWrapper>
            </SectionInfoWrapper>
        </SectionWrapper>
    );

    render() {
        return (
            <SidebarBody>
                <HeaderTitleWrapper onClick={this.props.handleReturnToSelection}>
                    <NavigationChevron width="7px" height="10px" margin="5px 10px 5px 5px" />
                    <HeaderTitle>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.SensitivitySummary.sensitivityPlotBackButton.${
                                this.props.returnTo
                            }`,
                        })}
                    </HeaderTitle>
                </HeaderTitleWrapper>
                <BodySection borderColor={colors.greyF2}>
                    {this.renderReagentBody(this.isOxime())}
                </BodySection>
                <BodySection borderColor={colors.greyF2}>{this.renderPLS()}</BodySection>
                <BodySection borderColor={colors.greyF2}>{this.renderElectrolyte()}</BodySection>
            </SidebarBody>
        );
    }
}

export default injectIntl(SensitivitySidebarSummarySection);
