// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;

    width: 100%;

    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;

export const MarginedWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    max-width: ${({ maxWidth }: Object) => maxWidth || STYLE_VALUES.SCREEN.MAX_WIDTH};
    margin: 0 auto;
    padding: 8px 48px;
`;
