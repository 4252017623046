// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import { RemovableLabel } from 'components/_ReactUI_V1';

import type { IntlType } from 'types';

// Styles
import { RemovableLabelWrapper } from './styles';

// UI Components

// Constants
import { STREAM_TYPES, STREAM_CIRCUITS } from 'utils/constants';

// type
import type { StreamCircuitConstant, StreamTypeConstant } from 'services/Circuit/types';

type Props = {
    intl: IntlType,

    streamType: StreamTypeConstant,
    streamCircuit: StreamCircuitConstant,
    showCloseButton: boolean,
    shouldHalfWidth?: boolean, // for skip streams.
    isStreamInterstageBleed?: boolean,

    handleRemoveAdvancedValue: () => void,
};

/**
 * Setup Stream Option is a selected advanced stream between stages such as blend, feed or bleed.
 */
export class SetupStreamOption extends React.PureComponent<Props, null> {
    static defaultProps = {
        shouldHalfWidth: false,
    };

    getBleedLabelId = () => {
        let id = 'components.MimicDiagram.AdvancedStreamValues.Bleed';
        if (
            !this.props.isStreamInterstageBleed &&
            this.props.streamCircuit === STREAM_CIRCUITS.AQUEOUS
        ) {
            id = 'components.MimicDiagram.AdvancedStreamValues.Raffinate';
        }
        return id;
    };

    // Get the label for custom option: New Feed, Bleed or Blend
    getLabel = () => {
        switch (this.props.streamType) {
            case STREAM_TYPES.FEED:
                return this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.AdvancedStreamValues.NewFeed',
                });
            case STREAM_TYPES.BLEED:
                return this.props.intl.formatMessage({
                    id: this.getBleedLabelId(),
                });
            case STREAM_TYPES.BLEND:
                return this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.AdvancedStreamValues.Blend',
                });
            case STREAM_TYPES.BYPASS_FEED:
                return this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.AdvancedStreamValues.BypassFeed',
                });
            case STREAM_TYPES.BYPASS_BLEND:
                return this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.AdvancedStreamValues.BypassBlend',
                });
            case STREAM_TYPES.SKIP:
                return this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.AdvancedStreamValues.Skip',
                });
            default:
                return this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.AdvancedStreamValues.NewFeed',
                });
        }
    };

    // Reduce Skip label width
    getLabelWidth = () => {
        if (this.props.streamType === STREAM_TYPES.SKIP && this.props.shouldHalfWidth) {
            return '50%';
        } else {
            return '100%';
        }
    };

    render() {
        return (
            <RemovableLabelWrapper
                streamCircuit={this.props.streamCircuit}
                width={this.getLabelWidth()}
            >
                <RemovableLabel
                    text={this.getLabel()}
                    onClose={this.props.handleRemoveAdvancedValue}
                    showCloseButton={this.props.showCloseButton}
                    style={{
                        removableLabel: { fontSize: '11px' },
                    }}
                />
            </RemovableLabelWrapper>
        );
    }
}

export default injectIntl(SetupStreamOption);
