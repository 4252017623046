// @flow strict

import request from 'services/request';

import type { KPISetting } from 'services/KPISetting/types';

/**
 * Fetch the circuit trend for single KPI
 */
export const _updateKPISettings = (kpiId: number, data: $Shape<KPISetting>) => {
    return request(`kpi/${kpiId}/settings`, {
        data,
        method: 'PUT',
    });
};
