// @flow

// Documentation: https://github.com/tvkhoa/react-tippy

import React from 'react';
import type { Node } from 'react';
import { Tooltip } from 'react-tippy';

// Styles
import { TippyStyles } from './injectedStyles';
import { Content, TooltipTrigger } from './styles';

type TriggerProps = {
    label?: string,
    size?: number,
};

export function ToolTipTrigger(props: TriggerProps) {
    return (
        <TooltipTrigger size={props.size} {...props}>
            {props.label}
        </TooltipTrigger>
    );
}

ToolTipTrigger.defaultProps = {
    label: '?',
    size: 20,
};

type ContentType = Node | {} | string;
type PositionType = 'top' | 'right' | 'bottom' | 'left';
type TriggerType = 'mouseenter' | 'click';

type Props = {
    arrow?: boolean,
    closeOnInternalClick?: boolean,
    content: ContentType,
    disabled?: boolean,
    interactive?: boolean,
    position?: PositionType,
    theme?: string,
    trigger?: ContentType,
    triggerType?: TriggerType,
    onOpen?: () => void,
    onClose?: () => void,
};

type State = {
    open?: boolean,
};

export class ToolTip extends React.Component<Props, State> {
    static defaultProps = {
        arrow: true,
        closeOnInternalClick: false,
        disabled: false,
        interactive: false,
        onClose: null,
        onOpen: null,
        position: 'right',
        theme: 'base',
        trigger: null,
        triggerType: 'mouseenter',
    };

    state = {
        open: false,
    };

    handleManualClose = () =>
        this.setState(
            {
                open: false,
            },
            () => this.props.onClose && this.props.onClose()
        );

    handleContentOnClick = () => {
        this.setState(
            {
                open: false,
            },
            () => this.props.onClose && this.props.onClose()
        );
    };

    handleTriggerOnClick = (e: Event) => {
        if (this.props.triggerType === 'click') {
            e.stopPropagation();
            const currentlyOpened = this.state.open;
            this.setState(
                {
                    open: !currentlyOpened,
                },
                () =>
                    this.state.open
                        ? this.props.onOpen && this.props.onOpen()
                        : this.props.onClose && this.props.onClose()
            );
        }
    };

    handleMouseOver = () => {
        if (this.props.triggerType === 'mouseenter') {
            this.setState(
                {
                    open: true,
                },
                () => this.props.onOpen && this.props.onOpen()
            );
        }
    };

    handleMouseLeave = () => {
        if (this.props.triggerType === 'mouseenter') {
            this.setState(
                {
                    open: false,
                },
                () => this.props.onClose && this.props.onClose()
            );
        }
    };

    render() {
        if (!this.props.content) {
            return null;
        }

        return (
            <Tooltip
                arrow={this.props.arrow}
                disabled={this.props.disabled}
                html={
                    <Content
                        onClick={this.props.closeOnInternalClick ? this.handleContentOnClick : null}
                    >
                        {this.props.content}
                    </Content>
                }
                interactive={this.props.interactive}
                position={this.props.position}
                trigger={this.props.triggerType}
                theme={this.props.theme}
                onRequestClose={this.handleManualClose}
                open={this.state.open}
            >
                {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                <span
                    role="button"
                    onClick={this.props.triggerType === 'click' ? this.handleTriggerOnClick : null}
                    onMouseOver={
                        this.props.triggerType === 'mouseenter' ? this.handleMouseOver : null
                    }
                    onMouseLeave={
                        this.props.triggerType === 'mouseenter' ? this.handleMouseLeave : null
                    }
                >
                    {this.props.trigger || <TooltipTrigger>?</TooltipTrigger>}
                </span>
            </Tooltip>
        );
    }
}

export function ToolTipGlobalStyles(props: Object) {
    return <TippyStyles {...props} />;
}
