/* eslint-disable flowtype/no-weak-types */
/* eslint-disable flowtype/no-existential-type */
// @flow strict

import React from 'react';

import type { Dispatch } from 'redux';

import type { Map, List } from 'immutable';

import {
    CIRCUIT_TYPES,
    LANGUAGE_OPTIONS,
    ROUTE_NAMES,
    ROLES,
    USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES,
    UNIT_TYPES,
    PRODUCTION_UNIT_TYPES,
    UNIT_NAMES,
    KPI_UNIT_TYPES,
    COMPLEX_KPI_UNIT_TYPES,
    SIDEBAR_STATES,
} from 'utils/constants';
import { TARGET_TYPES } from 'utils/kpiConstants';

export type LanguageCodeConstant = $Keys<typeof LANGUAGE_OPTIONS>;

export type ReactNode = Node | Object | React.Element;

export type ChildrenType = Array<ReactNode> | ReactNode;

export type ImmutableMap<X, Y> = Map<X, Y>;
export type ImmutableList<X> = List<X>;

export type ReduxDispatch = Dispatch<*>;

export type LooseNumberType = number | string;
export type LooseKeyArrayType = string | Array<LooseNumberType>;
export type LooseInputValueTypes = number | string | boolean;

// export type InputEvent = SyntheticEvent<>;
type targetType = {
    value: LooseNumberType,
    max: number,
    min: number,
    name: string,
} & HTMLInputElement;
export type InputEvent = {
    key: string,
    keyCode: number,
    currentTarget: targetType,
    target: targetType,
} & Event;

export type IntlType = Object;

export type ErrorType = ImmutableMap<any, any>;

// We redefine a custom error object with only data & status
export type ResponseErrorType = {|
    data: Object,
    status: number,
    message: string,
|};

export type GenericActionType = {
    type: string,
    payload: Object,
    meta?: Object,
};

// https://github.com/ReactTraining/history/pull/549/files
export type LocationType = {
    pathname: string,
    search: string,
    hash: string,
    state: any,
    key?: string,
};

// https://github.com/ReactTraining/history/pull/549/files
export type HistoryType = {
    length: number,
    action: string,
    location: LocationType,
    index?: number,
    entries?: Array<LocationType>,
    createHref: (any: any) => any,
    push: (any: any) => any,
    replace: (any: any) => any,
    go: (any: any) => any,
    goBack: (any: any) => any,
    goForward: (any: any) => any,
    canGo: (any: any) => any,
    block: (any: any) => any,
    listen: (any: any) => any,
};

export type MatchType = {
    params: { [key: string]: ?string },
    isExact: boolean,
    path: string,
    url: string,
};

// Single element of array to be passed through the `options` prop to `react-ui_input-select`
export type ReactSelectObject = {
    value: LooseNumberType,
    label: LooseNumberType,
};

export type ReactRef = {
    current: ?HTMLElement,
};

export type SidebarStateType = $Keys<typeof SIDEBAR_STATES>;

export type LanguageOptionType = 'EN' | 'ES';

export type Navigation = {
    handleChangeCircuit: (circuitId: ?number) => void,
    handleChangeDataset: (datasetId: ?number) => void,
    handleChangePlant: (plantId: ?number) => void,
    handleElevateCircuit: (onLeaveCb: ?() => boolean) => void,
    handleCreateOrUpdatePlant: (onLeaveCb: ?() => boolean) => void,
    handleDisableBackToNavigator: () => void,
};

export type CircuitTypes = $Keys<typeof CIRCUIT_TYPES>;

export type SearchCriteria = {
    search?: ?string,
    sortBy?: string,
    sortOrder?: string,
};

export type QueryStructure<T> = {
    currentPage: number,
    data: Array<T>,
    firstPageUrl: string,
    from: number,
    lastPage: number,
    lastPageUrl: string,
    nextPageUrl: string,
    path: string,
    perPage: string,
    prevPageUrl: string,
    to: number,
    total: number,
    withType?: CircuitTypes,
    withSearch?: string,
};

export type ImmutableQueryStructure<T> = ImmutableMap<string, QueryStructure<T>>;

export type KPIColorSchemeType = {
    targetLine: string,
    areaFill: string,
    areaLine: string,
};

export type PageNameConstant = $Keys<typeof ROUTE_NAMES>;

export type ClientPlantUserPageNameConstant = $Keys<typeof USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES>;

export type RoleConstant = $Keys<typeof ROLES>;

export type RouteType = {
    hasTitle: boolean,
    hasPageNavigator: boolean,
    path: string,
    match: string,
    name: PageNameConstant,
    isPageResponsive: boolean,
    canBeUnauthorized?: boolean,
    userRoles: Array<RoleConstant>,
    refreshUserTokenOnInterval: boolean,
};

export type SidebarTabType = { component?: ReactNode, label: string, key: string };

export type UnitsConstant = $Keys<typeof UNIT_TYPES>;

export type ProductionUnitsConstant = $Keys<typeof PRODUCTION_UNIT_TYPES>;

type TableHeaderType = {
    label: string,
    id: string,
    sortable?: ?boolean,
    styles?: ?Object,
};

export type SimpleKpiUnitType = $Keys<typeof KPI_UNIT_TYPES>;
export type ComplexKpiUnitType = $Keys<typeof COMPLEX_KPI_UNIT_TYPES>;
export type KpiUnitType = SimpleKpiUnitType | ComplexKpiUnitType;
export type KpiUnitName = $Keys<typeof UNIT_NAMES>;

export type TargetType = $Values<typeof TARGET_TYPES>;
