// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableFeedbackState } from './types';

/**
 * Direct selector to the Feedback state domain
 *
 * TODO: Replace Object type for proper Redux Store Type
 */
export const selectFeedbackState = () => (state: ImmutableFeedbackState) => state.get('feedback');

/**
 * Select list of Feedbacks
 */
export const selectAllFeedbacks = () =>
    createSelector(
        selectFeedbackState(),
        (substate: ImmutableFeedbackState) => substate.get('feedbacks')
    );
