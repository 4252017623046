// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Constants
import {
    LegacyTheme,
    Column,
    InputField,
    InputSelect,
    PrimaryButton,
    Close,
    InfoIcon,
    Row,
    ToolTip,
} from 'components/_ReactUI_V1';

import {
    DATASET_SCENARIO_PRECISION_OVERRIDE,
    DATASET_STATUSES,
    DATASET_VALUE_TYPES,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
} from 'utils/constants';
import { TARGET_TYPES } from 'utils/kpiConstants';

// Components
import ErrorMessage from 'components/ErrorMessage';

// Styles
import {
    CloseButton,
    Instructions,
    KpiRow,
    Label,
    SidebarBody,
    SidebarFooter,
    Spacer,
    TooltipContainer,
} from './styles';
import { SimpleList, Subtitle } from 'styles/common';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardBodyTitle,
} from 'components/RecommendationCard/styles';

// Types
import type { IntlType, ImmutableList, ImmutableMap, InputEvent, ReactSelectObject } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type {
    ScenarioKpi,
    ImmutableScenarioKpi,
    ImmutableScenarioResults,
} from 'services/Dataset/types';
import type { ImmutableTrendData } from 'services/Trends/types';
import type { ImmutableKPISetting, AllKpiTypes } from 'services/KPISetting/types';
import type { ImmutableError } from 'services/Errors/types';

// Utils
import { getKPISettingUnit, getRecommendableKPIs } from 'utils/kpiHelpers';
import { clamp, round, isValueOverSigFigureLimitOf } from 'utils/helpers';

// SCENARIO_RESULT_KEYS act as both the key on the provided scenarioResults and as translation key for the title of each value.
// It's important that if these value changes, they are updated in the translations as well
const SCENARIO_RESULT_KEYS = {
    RESULTING_OVERALL_RECOVERY: 'resultingOverallRecovery',
    CU_TRANSFER_IMPACT: 'cuTransferImpact',
};
// Limits the length of the significant figured on the input's onChange event
const USER_INPUTTED_KPI_VALUE_SIG_FIGURE_LIMIT = 8;

type ScenarioResultKey = $Values<typeof SCENARIO_RESULT_KEYS>;

type Props = {
    intl: IntlType,

    trend: ImmutableTrendData,

    circuit: ImmutableCircuit,
    kpiSettings: ImmutableList<ImmutableKPISetting>,

    isLoadingData: boolean,

    submittingScenario: boolean,
    runScenario: (kpis: Array<ScenarioKpi>) => void,
    clearScenarioResults: () => void,

    scenarioResults: ImmutableScenarioResults | null,

    errors: ImmutableError | null,
};

type State = {
    kpis: ImmutableList<ImmutableScenarioKpi>,
    isModified: boolean,
};

/**
 * Dataset Scenario Sidebar component
 */
class ScenarioSidebar extends React.PureComponent<Props, State> {
    state = {
        kpis: fromJS([]),
        isModified: false,
    };

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id,
            },
            data
        );

    getComponentTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.ScenarioSidebar.${id}`,
            },
            data
        );

    componentDidUpdate(prevProps: Props) {
        const { scenarioResults, errors } = this.props;
        if (
            (prevProps.scenarioResults && !prevProps.scenarioResults.equals(scenarioResults)) ||
            (prevProps.errors && !prevProps.errors.equals(errors))
        ) {
            this.setState({
                isModified: false,
            });
        }
    }

    /**
     * Handles submit action by converting state.kpis to Array<ScenarioKpi> and fires prop.runScenario
     */
    handleSubmitScenarioButton = () => {
        const formattedKpis = this.state.kpis
            .filter((kpi: ImmutableScenarioKpi) => {
                const value = kpi.get('value');
                return value !== null || value !== '';
            })
            .map((kpi: ImmutableScenarioKpi) => ({
                value: kpi.get('value'),
                kpiSettingId: kpi.getIn(['kpiSetting', 'id']),
            }))
            .toArray();
        this.props.runScenario(formattedKpis);
    };

    /**
     * Converts provided kpi settings to a list of ReactSelectObjects for InputSelect
     */
    getKpiOptions = (): Array<ReactSelectObject> =>
        getRecommendableKPIs(this.props.circuit, this.props.kpiSettings)
            .filter((kpiSetting: ImmutableKPISetting) => {
                const hasBeenSelected = this.state.kpis.find(
                    (kpi: ImmutableScenarioKpi) =>
                        kpi.getIn(['kpiSetting', 'id']) === kpiSetting.get('id')
                );
                return !hasBeenSelected;
            })
            .map(
                (kpiSetting: ImmutableKPISetting): ReactSelectObject =>
                    kpiSetting && {
                        value: kpiSetting.get('id'),
                        label: kpiSetting.get('name'),
                    }
            )
            .toArray();

    /**
     * Handle the InputSelect onSelect to select a kpi to add to state.kpis
     *
     * @param {ReactSelectObject} selectedOption
     */
    handleKpiSelected = (selectedOption: ReactSelectObject) => {
        this.handleClearingOfResults();

        const selectedKpiSetting = this.props.kpiSettings.find(
            (kpiSetting: ImmutableKPISetting) => kpiSetting.get('id') === selectedOption.value
        );

        if (!selectedKpiSetting) {
            throw new Error('Unable to find kpi setting');
        }

        const foundKpiInTrend = this.props.trend
            .get('kpis')
            .find((kpiInTrend) => kpiInTrend.get('kpiSettingId') === selectedKpiSetting.get('id'));

        const value =
            foundKpiInTrend && foundKpiInTrend.get('value') !== null
                ? round(foundKpiInTrend.get('value'), selectedKpiSetting.get('precision'))
                : '';

        this.setState((prevState: State) => {
            return {
                kpis: prevState.kpis.push(
                    fromJS({
                        kpiSetting: selectedKpiSetting,
                        value,
                    })
                ),
            };
        });
    };

    /**
     * Clear the scenario results as the user is making a change
     */
    handleClearingOfResults = () => {
        if (this.props.scenarioResults) {
            this.props.clearScenarioResults();
        }
    };

    /**
     * Handle the input onChange event to update kpi value
     *
     * @param {number} index
     * @returns void
     */
    handleInputChange = (index: number) => (event: InputEvent) => {
        this.handleClearingOfResults();

        const value = event.target.value;
        if (isValueOverSigFigureLimitOf(value, USER_INPUTTED_KPI_VALUE_SIG_FIGURE_LIMIT)) {
            return;
        }

        this.setState((prevState: State) => {
            return {
                isModified: true,
                kpis: prevState.kpis.setIn([index, 'value'], value),
            };
        });
    };

    /**
     * Handle the on blur in range start or finish.
     */
    handleOnBlurInput = (index: number) => (event: InputEvent) => {
        const { value, min, max } = event.target;
        const clampedValue = value !== '' ? clamp(value, min, max) : null;
        this.setState((prevState: State) => {
            return {
                kpis: prevState.kpis.setIn([index, 'value'], clampedValue),
            };
        });
    };

    /**
     * Handle the removal of a kpi
     *
     * @param {number} index
     * @returns void
     */
    handleDeleteKpi = (index: number) => () => {
        this.handleClearingOfResults();

        this.setState((prevState: State) => {
            return {
                isModified: true,
                kpis: prevState.kpis.delete(index),
            };
        });
    };

    /**
     * Returns a simple list of kpi settings such as mins and maxes
     *
     * @param {ImmutableKPISetting} kpi
     * @returns ReactNode
     */
    renderKpiSettingTooltip = (kpi: ImmutableKPISetting) => {
        const kpiSetting = kpi.get('kpiSetting', null);
        if (!kpiSetting) {
            return;
        }

        const unit = getKPISettingUnit(kpiSetting, this.props.intl);
        return (
            <TooltipContainer>
                <SimpleList>
                    <li>
                        {this.getTranslation('models.kpiSettings.minValid', {
                            value: kpiSetting.get('minValid') || NUMBER_INPUT_PLACEHOLDER,
                            unit,
                        })}
                    </li>
                    <li>
                        {this.getTranslation('models.kpiSettings.maxValid', {
                            value: kpiSetting.get('maxValid') || NUMBER_INPUT_PLACEHOLDER,
                            unit,
                        })}
                    </li>
                    <li>
                        {this.getTranslation('models.kpiSettings.lowTarget', {
                            value: kpiSetting.get('lowTarget') || NUMBER_INPUT_PLACEHOLDER,
                            unit,
                        })}
                    </li>
                    <li>
                        {this.getTranslation('models.kpiSettings.highTarget', {
                            value: kpiSetting.get('highTarget') || NUMBER_INPUT_PLACEHOLDER,
                            unit,
                        })}
                    </li>
                    <li>
                        {this.getTranslation('models.kpiSettings.mainTarget', {
                            value: kpiSetting.get('mainTarget') || NUMBER_INPUT_PLACEHOLDER,
                            unit,
                        })}
                    </li>
                </SimpleList>
            </TooltipContainer>
        );
    };

    /**
     * Render the kpi rows by looping over the state.kpis and returning a ReactNode
     *
     * @returns ReactNode
     */
    renderKpiRows = () => {
        return this.state.kpis.map((kpi: ImmutableScenarioKpi, index: number) => {
            const errorMessage = !this.state.isModified &&
                this.props.errors &&
                this.props.errors.getIn(['errors', `kpis.${index}.value`]) && (
                    <ErrorMessage
                        errorMessage={this.getTranslation('common.validation.required')}
                        isRed
                        isSmall
                        style={{ margin: 0, fontSize: 10 }}
                    />
                );
            return (
                <KpiRow key={kpi.getIn(['kpiSetting', 'id'])} flex="0">
                    <Column>
                        <Label>
                            {`${kpi.getIn(['kpiSetting', 'name'])} (${getKPISettingUnit(
                                kpi.get('kpiSetting'),
                                this.props.intl
                            )})`}
                            <Spacer />
                            <ToolTip
                                content={this.renderKpiSettingTooltip(kpi)}
                                position="bottom"
                                trigger={<InfoIcon width="14px" height="14px" />}
                                triggerType="click"
                            />
                        </Label>
                        <Row alignItems="center">
                            <Column>
                                <InputField
                                    value={kpi.get('value')}
                                    onChange={this.handleInputChange(index)}
                                    onBlur={this.handleOnBlurInput(index)}
                                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                                    type="number"
                                    min={kpi.getIn(['kpiSetting', 'minValid'])}
                                    max={kpi.getIn(['kpiSetting', 'maxValid'])}
                                    disabled={this.props.submittingScenario}
                                />
                            </Column>
                            <Column flex="0">
                                <CloseButton
                                    enabled={!this.props.submittingScenario}
                                    onClick={
                                        !this.props.submittingScenario
                                            ? this.handleDeleteKpi(index)
                                            : null
                                    }
                                >
                                    <Close
                                        strokeWidth={0}
                                        width="16px"
                                        height="16px"
                                        fill="useCurrent"
                                        clickable={!this.props.submittingScenario}
                                    />
                                </CloseButton>
                            </Column>
                        </Row>
                        {errorMessage}
                    </Column>
                </KpiRow>
            );
        });
    };

    /**
     * Render a single <CardBody> component with the title & value
     *
     *
     * @param {ImmutableScenarioResults} scenarioResults
     * @param {ScenarioResultKey} valueAndTranslationKey
     * @param {AllKpiTypes} kpiType
     * @returns ReactNode
     */
    renderScenarioResult = (
        scenarioResults: ImmutableScenarioResults,
        valueAndTranslationKey: ScenarioResultKey,
        kpiType: AllKpiTypes
    ) => {
        const kpiSetting = this.props.kpiSettings.find(
            (k: ImmutableKPISetting) => k.get('kpiType') === kpiType
        );

        if (!kpiSetting) {
            throw Error(`Unable to find expected kpi setting for ${kpiType}`);
        }

        const value = scenarioResults.get(valueAndTranslationKey);
        const precision =
            DATASET_SCENARIO_PRECISION_OVERRIDE &&
            DATASET_SCENARIO_PRECISION_OVERRIDE[kpiType] !== null
                ? DATASET_SCENARIO_PRECISION_OVERRIDE[kpiType]
                : kpiSetting.get('precision');

        return (
            <CardBody>
                <CardBodyTitle>
                    {this.getComponentTranslation(valueAndTranslationKey)}
                </CardBodyTitle>
                {round(value, precision)} {getKPISettingUnit(kpiSetting, this.props.intl)}
            </CardBody>
        );
    };

    /**
     * If props.scenarioResults is not null and if the local state.kpis are not empty, return a ReactNode of the results
     *
     * @returns ReactNode
     */
    renderScenarioResults = () => {
        if (!this.props.scenarioResults || this.state.kpis.isEmpty()) {
            return;
        }

        const converged = this.props.scenarioResults.get('converged');
        if (!converged) {
            return (
                <ErrorMessage
                    errorMessage={this.getComponentTranslation('scenarioDidNotConverge')}
                    isRed
                    isSmall
                />
            );
        }

        return (
            <React.Fragment>
                <br />
                <Card>
                    <CardHeader>
                        <CardTitle>{this.getComponentTranslation('results')}</CardTitle>
                    </CardHeader>
                    {this.renderScenarioResult(
                        this.props.scenarioResults,
                        SCENARIO_RESULT_KEYS.RESULTING_OVERALL_RECOVERY,
                        DATASET_VALUE_TYPES.OVERALL_RECOVERY
                    )}
                    {this.renderScenarioResult(
                        this.props.scenarioResults,
                        SCENARIO_RESULT_KEYS.CU_TRANSFER_IMPACT,
                        DATASET_VALUE_TYPES.CU_TRANSFERRED
                    )}
                </Card>
            </React.Fragment>
        );
    };

    /**
     * Returns sidebar footer with submit button
     *
     * @returns ReactNode
     */
    renderSidebarFooter = () => {
        // Disable submit button if we have results, no local kpis or we have local kpis without a value
        const disabled =
            this.props.scenarioResults ||
            this.state.kpis.isEmpty() ||
            this.state.kpis.some((kpi: ImmutableScenarioKpi) => !kpi.get('value'));
        return (
            <SidebarFooter style={{ padding: '14px 24px' }}>
                <PrimaryButton
                    onClick={this.handleSubmitScenarioButton}
                    disabled={disabled}
                    text={this.getComponentTranslation('submitScenarioButton')}
                    loading={this.props.submittingScenario}
                />
            </SidebarFooter>
        );
    };

    render() {
        let sidebarBodyContent;
        if (this.props.trend.get('status') !== DATASET_STATUSES.CONVERGED) {
            sidebarBodyContent = (
                <ErrorMessage
                    errorMessage={this.getComponentTranslation('datasetNotConverged')}
                    isRed
                />
            );
        } else if (this.props.isLoadingData) {
            sidebarBodyContent = (
                <Instructions>{this.getComponentTranslation('loadingData')}</Instructions>
            );
        } else {
            sidebarBodyContent = (
                <React.Fragment>
                    <Instructions>{this.getComponentTranslation('instructions')}</Instructions>
                    <div>{this.renderKpiRows()}</div>
                    <InputSelect
                        options={this.getKpiOptions()}
                        onSelect={this.handleKpiSelected}
                        placeholder={this.getComponentTranslation('selectVariable')}
                        maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                        isDisabled={this.props.submittingScenario}
                    />
                    {this.renderScenarioResults()}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <SidebarBody>{sidebarBodyContent}</SidebarBody>
                {this.renderSidebarFooter()}
            </React.Fragment>
        );
    }
}

export default injectIntl(ScenarioSidebar);
