// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { MessageBlock, Checkmark, Close, FloppyDiskIcon, LegacyTheme } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import type { FeedbackType } from 'services/Feedback/types';

// Styles
import { AnimatedToast } from './styles';
import { InfoToastColor } from 'styles/colors';

type Props = {
    intl: IntlType,

    feedback: Feedback,
};

/**
 * This component hold the UI and animation for the toast
 * switch colors and icon according to the toast type
 */
class Toast extends React.PureComponent<Props> {
    /**
     * Get the toast color
     */
    getColor = () => {
        switch (this.props.feedback.feedbackType) {
            case 'ERROR':
                return LegacyTheme.defaultWarningColor;
            case 'INFO':
                return InfoToastColor;
            case 'SUCCESS':
                return LegacyTheme.defaultSuccessColor;
            default:
                throw new Error('Unknown feedback type');
        }
    };

    /**
     * Get the toast icon
     */
    getIcon = () => {
        switch (this.props.feedback.feedbackType) {
            case 'ERROR':
                return <Close fill={this.getColor()} />;
            case 'INFO':
                return <FloppyDiskIcon fill={this.getColor()} />;
            case 'SUCCESS':
                return <Checkmark fill={this.getColor()} />;
            default:
                throw new Error('Unknown feedback type');
        }
    };

    getText = () => {
        if (this.props.feedback.__MUST_BE_TRANSLATED) {
            return this.props.intl.formatMessage(
                {
                    id: this.props.feedback.messageId,
                },
                this.props.feedback.data || undefined
            );
        } else {
            return this.props.feedback.message;
        }
    };

    render() {
        return (
            <AnimatedToast>
                <MessageBlock text={this.getText()} color={this.getColor()} icon={this.getIcon()} />
            </AnimatedToast>
        );
    }
}

export default injectIntl(Toast);
