// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    padding: 30px 40px;

    display: flex;
    flex-direction: column;
`;

export const TableContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    width: 100%;
`;

export const ToolTipContent = styled.ul`
    padding: 16px;
    margin: 0;

    list-style: none;
    text-align: left;

    > li {
        display: block;
        margin: 12px 0;

        font-size: 12px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: ${LegacyTheme.defaultColor};
        }
    }
`;

export const ContentContainer = styled.div`
    padding: 0 16px;
`;
