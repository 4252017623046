/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled, { css } from 'styled-components';

import { LegacyTheme, OverflowContainer } from 'components/_ReactUI_V1';
import { Wrapper as MimicDiagramHeaderWrapper } from 'components/MimicDiagramHeader/styles';
import { STYLE_VALUES } from 'utils/constants';
import { colors } from 'styles/colors';

export const AppWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    width: 100%;
    min-width: ${(props: { isResponsive: boolean }) =>
        props.isResponsive
            ? STYLE_VALUES.MOBILE.APP_WRAPPER_MIN_WIDTH
            : STYLE_VALUES.SCREEN.APP_WRAPPER_MIN_WIDTH};
    height: 100vh;

    margin: 0 auto;

    box-sizing: border-box;
    overflow: hidden;

    background-color: #fff;
`;

export const DefaultScrollableContent = css`
    display: flex;
    flex-direction: column;
    height: 100%;

    overflow-y: auto;

    box-sizing: border-box;
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    box-sizing: border-box;

    color: ${LegacyTheme.defaultColor};
    background-color: ${LegacyTheme.defaultBackground};
`;

export const LayoutContainer = styled.div`
    position: relative;

    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: ${({ justifyContent }: Object) => justifyContent || 'center'};
    align-items: ${({ alignItems }: Object) => alignItems || 'center'};

    width: 100%;
    max-width: ${({ fullWidth }: Object) => (fullWidth ? 'none' : STYLE_VALUES.SCREEN.MAX_WIDTH)};
    margin: 0 auto;
    padding: ${STYLE_VALUES.GUTTERS.NORMAL} ${STYLE_VALUES.GUTTERS.LARGE};
`;

export const ContainerCentered = styled.div`
    position: relative;

    display: flex;
    flex: 1;
    height: ${({ withHeader }: Object) =>
        withHeader ? `calc(100% - ${STYLE_VALUES.HEADER.HEIGHT})` : '100%'};
    justify-content: center;
    align-items: center;

    background-color: ${LegacyTheme.defaultBackground};
`;

export const Title = styled.h1`
    color: ${LegacyTheme.defaultBoldColor};

    font-size: 20px;
    line-height: 1.2;
    font-weight: normal;
`;

export const Count = styled.span`
    margin-left: 10px;
    font-size: 12px;
`;

export const Subtitle = styled.h2`
    color: ${LegacyTheme.defaultColor};

    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;

    letter-spacing: -0.36px;
`;

export const SectionDesc = styled.p`
    color: ${LegacyTheme.defaultColor};

    font-size: 12px;
    line-height: 1.2;
`;

export const SimpleList = styled.ul`
    padding: 0;
    margin: 20px 10px;

    list-style: none;

    > li {
        display: block;
        margin: 10px 0;
    }
`;

export const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const SnackBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: calc(100% - ${STYLE_VALUES.GUTTERS.NORMAL} - ${STYLE_VALUES.GUTTERS.NORMAL});
    max-width: 300px;
    overflow: hidden;

    position: absolute;
    right: ${STYLE_VALUES.GUTTERS.NORMAL};
    bottom: 64px;
    z-index: 9999999;
`;

export const BlankButton = styled.button`
    padding: 0;

    appearance: none;
    background-color: transparent;
    border: 0 none;

    &:focus {
        outline: none;
    }

    cursor: pointer;
`;

export const DataToggler = styled(BlankButton)`
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 15px;

    span {
        margin-right: 8px;
    }

    span,
    img {
        vertical-align: middle;
    }
`;

export const ExportContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
`;

export const ExportFixedContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    background-color: #fff;

    padding: 20px;

    ${MimicDiagramHeaderWrapper} {
        max-width: none;
        padding: 0 0 20px;
    }
`;

export const HeaderOverflowContainer = styled(OverflowContainer)`
    height: calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT});
`;

export const FormLabel = styled.label`
    display: block;
    font-size: 13px;
    line-height: 16px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    margin-bottom: ${({ noMargin }: Object) => (!noMargin ? '6px' : '')};
`;

export const FormContainer = styled.div`
    text-align: left;
    width: 100%;
`;

export const FormBlock = styled.div`
    margin-bottom: ${STYLE_VALUES.GUTTERS.NORMAL};
    width: 100%;
    max-width: ${({ fullWidth, maxWidth }: Object) => (fullWidth ? 'none' : maxWidth || '400px')};
`;

export const FormInputWrapper = styled.div`
    min-width: ${({ minWidth }: Object) => minWidth || '64px'};
`;

export const InputBlockLabel = styled.label`
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 13px;
`;

export const InputBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 6px;

    ${InputBlockLabel} {
        margin-right: 12px;
    }
`;

export const DetailsErrorBlock = styled.details`
    white-space: pre-wrap;
    max-height: 80vh;
    overflow: auto;
    margin-bottom: 10px;
`;

export const Code = styled.code`
    padding: 2px 6px;

    color: ${colors.grey27};

    border: 1px solid ${colors.grey7B};
    background-color: ${colors.greyDD};
    border-radius: 4px;

    font-size: 90%;
`;

export const Note = styled.p`
    margin-top: 6px;

    color: ${({ warning }: { warning: boolean }) =>
        warning ? LegacyTheme.defaultWarningColor : LegacyTheme.defaultColor};

    font-size: 12px;
`;
