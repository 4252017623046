// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Constants
import { USER_DISCLAIMER_TYPES } from 'utils/constants';

// Components
import { Centered, Loader } from 'components/_ReactUI_V1';
import DisclaimerManager from 'components/DisclaimerManager';

// Helpers
import { formatDisclaimers } from 'utils/helpers';
import { getUsersLanguage } from 'utils/authentication';

// Services
import {
    selectDisclaimers,
    selectDisclaimersAreFetching,
    selectDisclaimerIsBeingCreated,
    selectErrors,
} from 'services/Disclaimer/selectors';
import { fetchDisclaimers, createNewDisclaimerVersion } from 'services/Disclaimer/thunks';
import { selectUser } from 'services/Authentication/selectors';

// Types
import type { ErrorType, ImmutableList, IntlType, ReduxDispatch } from 'types';
import type { ImmutableDisclaimer } from 'services/Disclaimer/types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    error: ErrorType,
    disclaimers: ImmutableList<ImmutableDisclaimer>,
    isFetching: boolean,
    isCreating: boolean,
    fetchDisclaimers: () => void,
    intl: IntlType,
    user: ImmutableUser,
    createNewDisclaimerVersion: (number, ImmutableDisclaimer) => void,
};

export class DisclaimerManagerContainer extends React.PureComponent<Props> {
    componentDidMount() {
        if (this.props.disclaimers.isEmpty()) {
            this.props.fetchDisclaimers();
        }
    }

    handleOnSave = (id: number, disclaimer: ImmutableDisclaimer) => {
        this.props.createNewDisclaimerVersion(id, disclaimer);
    };

    render() {
        if (this.props.isFetching) {
            return (
                <Centered>
                    <Loader loading />
                </Centered>
            );
        }

        if (this.props.disclaimers.isEmpty()) {
            return (
                <Centered>
                    {this.props.intl.formatMessage({
                        id: 'containers.DisclaimerManagerContainer.noDisclaimers',
                    })}
                </Centered>
            );
        }

        const userDisclaimers = this.props.disclaimers.filter((disclaimer: ImmutableDisclaimer) =>
            Object.keys(USER_DISCLAIMER_TYPES).includes(disclaimer.get('type'))
        );

        return (
            <DisclaimerManager
                disclaimers={formatDisclaimers(
                    userDisclaimers,
                    this.props.intl,
                    getUsersLanguage(this.props.user)
                )}
                error={this.props.error}
                isCreating={this.props.isCreating}
                onSave={this.handleOnSave}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    disclaimers: selectDisclaimers(),
    error: selectErrors(),
    isCreating: selectDisclaimerIsBeingCreated(),
    isFetching: selectDisclaimersAreFetching(),
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchDisclaimers,
            createNewDisclaimerVersion,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DisclaimerManagerContainer))
);
