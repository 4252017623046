// @flow strict

import request from 'services/request';

import {
    ImmutableNotificationSettings,
    ImmutableNotificationPlantSettings,
    ImmutableNotificationSchedule,
} from 'services/Notifications/types';

import type { ImmutableList } from 'types';

export const queryNotifications = (userId: number, page: number = 1, perPage: number = 10) =>
    request(`notifications/user/${userId}?page=${page}&per_page=${perPage}`, { method: 'GET' });

export const updateReadState = (userId: number, notificationId: number, readState: boolean) =>
    request(`notifications/${notificationId}/user/${userId}`, {
        method: 'PUT',
        data: { read: readState },
    });

export const markAllAsRead = (userId: number) =>
    request(`notifications/user/${userId}/markAllAsRead`, { method: 'PUT' });

export const fetchUnreadTotal = (userId: number) =>
    request(`notifications/user/${userId}/unread/total`, { method: 'GET' });

export const indexSettings = (userId: number) =>
    request(`notifications/user/${userId}/settings`, { method: 'GET' });

export const indexPlantSettings = (userId: number) =>
    request(`notifications/user/${userId}/plants`, { method: 'GET' });

export const indexSchedule = (userId: number) =>
    request(`notifications/user/${userId}/schedule`, { method: 'GET' });

export const updateSettings = (userId: number, settings: ImmutableNotificationSettings) =>
    request(`notifications/user/${userId}/settings`, {
        method: 'POST',
        data: { notificationSettings: settings.toJS() },
    });

export const updatePlantSettings = (
    userId: number,
    plantSettings: ImmutableNotificationPlantSettings
) =>
    request(`notifications/user/${userId}/plants`, {
        method: 'POST',
        data: { notificationPlantSettings: plantSettings.toJS() },
    });

export const updateSchedule = (userId: number, schedule: ImmutableNotificationSchedule) =>
    request(`notifications/user/${userId}/schedule`, {
        method: 'POST',
        data: { notificationSchedule: schedule.toJS() },
    });

export const indexNotificationTypeUsersStatuses = (notificationTypeName: string, userIDs: string) =>
    request(`notifications/type/${notificationTypeName}/users/status?userIDs=${userIDs}`, {
        method: 'GET',
    });

/**
 *
 * Remove the user's phone number and update their sms setting
 *
 * @param {number} userId
 */
export const removePhoneNumber = (userId: number) =>
    request(`user/${userId}/phone`, {
        method: 'DELETE',
    });
