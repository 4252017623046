// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { SidebarSectionWrapper, SectionTitle, SectionBody } from '../styles';

// Components
import { RadioButtonSet } from 'components/_ReactUI_V1';

// types
import type { IntlType } from 'types';

import { type RecommendationSetsOptions, RECOMMENDATION_SETS_OPTIONS } from '..';

type Props = {
    intl: IntlType,

    disabled: boolean,
    radioStatus: RecommendationSetsOptions,

    onRadioClick: (radioStatus: DateRangeStatus) => void,
};

class RecommendationSetsOptionsSection extends React.PureComponent<Props> {
    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.ArchiveSidebar.RecommendationSetsOptionsSection.${id}`,
        });

    renderRadioOptions = () => (
        <RadioButtonSet
            onClick={this.props.onRadioClick}
            options={[
                {
                    label: this.getTranslation('RadioOptions.All'),
                    value: RECOMMENDATION_SETS_OPTIONS.ALL,
                },
                {
                    label: this.getTranslation('RadioOptions.WithSubmittedRecommendations'),
                    value: RECOMMENDATION_SETS_OPTIONS.WITH_SUBMITTED_RECOMMENDATIONS,
                },
            ]}
            disabled={this.props.disabled}
            value={this.props.radioStatus}
            renderSuffix={this.renderDateInputs}
        />
    );

    render() {
        return (
            <SidebarSectionWrapper>
                <SectionTitle>{this.getTranslation('title')}</SectionTitle>
                <SectionBody>{this.renderRadioOptions()}</SectionBody>
            </SidebarSectionWrapper>
        );
    }
}

export default injectIntl(RecommendationSetsOptionsSection);
