// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    padding: ${({ smallPadding }) => (smallPadding ? '5px 19px 19px 19px' : '31px')};
`;

export const DiagramHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GraphWrapper = styled.div`
    flex-grow: 1;
`;

export const GraphCopyToClipboardTarget = styled.div`
    background-color: ${LegacyTheme.white};
`;

export const LegendWrapper = styled.div`
    margin-top: 34px;
    width: 350px;
    max-width: 350px;
`;

export const DataTableContainer = styled.div`
    width: 100%;
    max-width: 256px;
    table {
        max-width: 256px;
        thead {
            tr {
                th {
                    width: 50%;
                    text-align: center;
                }
            }
        }
        tbody {
            tr {
                border-bottom: 0 none;
                td {
                    height: 36px;
                    padding: 6px 12px;
                    text-align: center;
                    div {
                        justify-content: center;
                    }
                }
            }
        }
    }
`;

export const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
