// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// UI Components
import { SecondaryButton } from 'components/_ReactUI_V1';

import { ButtonWrapper } from './styles';

// type
import type { IntlType } from 'types';
import type { OpenModalFunction } from 'containers/CircuitSetupContainer/MimicDiagramContainer';
import type { StreamEntity } from 'services/Circuit/types';

type Props = {
    intl: IntlType,

    onOpenModal: OpenModalFunction,
    streamData: StreamEntity,
};

/**
 * The edit button for Continue streams between same type stages.
 */
class StreamEditButton extends React.PureComponent<Props, null> {
    /**
     * Handles the opening of the right modal
     */
    handleOpenModal = () => this.props.onOpenModal(this.props.streamData);

    render() {
        return (
            <ButtonWrapper>
                <SecondaryButton
                    onClick={this.handleOpenModal}
                    text={this.props.intl.formatMessage({
                        id: 'components.MimicDiagram.button.Edit',
                    })}
                />
            </ButtonWrapper>
        );
    }
}

export default injectIntl(StreamEditButton);
