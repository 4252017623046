// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    padding: 30px;
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GraphWrapper = styled.div`
    flex-grow: 1;
`;

export const LegendWrapper = styled.div`
    width: 100%;
    max-width: 160px;
`;

export const HeaderWrapper = styled.div`
    margin-bottom: 20px;
    margin-left: 10px;
`;

export const Header = styled.h1`
    color: ${LegacyTheme.black};
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
`;
