// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableNotificationsState } from 'services/Notifications/types';

import type { ImmutableReduxState } from '../types';

export const selectNotificationsState = () => (state: ImmutableReduxState) =>
    state.get('notifications');

export const selectNotificationsAreQuerying = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('notificationsAreQuerying')
    );

export const selectQueriedNotifications = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('queriedNotifications')
    );

export const selectMarkAllAsReadIsUpdating = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('markAllAsReadIsUpdating')
    );

export const selectTotalUnreadNotifications = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('unreadTotal')
    );

export const selectQueriedNotificationsCurrentPage = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('currentPage')
    );

export const selectQueriedNotificationsLastPage = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('lastPage')
    );

export const selectNotificationSettingsAreFetching = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('settingsAreFetching')
    );

export const selectNotificationSettings = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('settings')
    );

export const selectNotificationSettingsAreUpdating = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('settingsAreUpdating')
    );

export const selectNotificationPlantSettingsAreFetching = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('plantSettingsAreFetching')
    );

export const selectNotificationPlantSettings = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('plantSettings')
    );

export const selectNotificationPlantSettingsAreUpdating = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('plantSettingsAreUpdating')
    );

export const selectNotificationScheduleIsFetching = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('scheduleIsFetching')
    );

export const selectNotificationSchedule = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('schedule')
    );

export const selectNotificationScheduleIsUpdating = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('scheduleIsUpdating')
    );

export const selectNotificationTypeUsersStatusesAreFetching = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) =>
            substate.get('notificationTypeUsersStatusesAreFetching')
    );

export const selectNotificationTypeUsersStatuses = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('notificationTypeUsersStatuses')
    );

export const selectErrors = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('errors')
    );

export const selectPhoneRemoveIsLoading = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('phoneRemovalIsLoading')
    );

export const selectRemoveUserPhoneNumberErrors = () =>
    createSelector(
        selectNotificationsState(),
        (substate: ImmutableNotificationsState) => substate.get('phoneRemovalErrors')
    );
