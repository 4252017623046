// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableList } from 'types';
import type { ImmutableReduxState } from '../types';
import type { ImmutableDatasetState, ImmutableDataset } from 'services/Dataset/types';
import type { ImmutableTrendsState } from 'services/Trends/types';

/**
 * Direct selector to the Circuit state domain
 */
export const selectDatasetState = () => (state: ImmutableReduxState) => state.get('dataset');

/**
 * Select dataset errors
 */
export const selectErrors = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('errors')
    );

/**
 * Select list of datasets
 */
export const selectAllDatasets = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('datasets')
    );

/**
 * Select a specific circuit
 */
export const selectDataset = (datasetId: number) =>
    createSelector(
        selectAllDatasets(),
        (datasets: ImmutableList<ImmutableDataset>) =>
            datasets.find((dataset: ImmutableDataset) => dataset.get('id') === datasetId)
    );

/**
 * Select computed dataset
 */
export const selectComputedDataset = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('computedDataset')
    );

/**
 * Get the 2D sensitivity
 */
export const select2DAnalysis = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('computed2D')
    );

/**
 * Get the 3D sensitivity
 */
export const select3DAnalysis = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('computed3D')
    );

/*
 * Get the 2D Dataset
 */
export const select2DDataset = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('computed2DDataset')
    );

/**
 * Get the circuit errors
 */
export const selectDatasetErrors = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('errors')
    );

/**
 * Get the fetch errors
 */
export const selectDatasetFetchErrors = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('fetchErrors')
    );

/**
 * Gets isFetching status while Datasets are being fetched: to hide list or show loading animation
 */
export const selectDatasetsAreFetching = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isFetching')
    );

/**
 * Gets isUpdating status while Datasets are being updated: to disable inputs
 */
export const selectDatasetsAreUpdating = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isUpdating')
    );

/**
 * Gets isFetching status while Datasets are being fetched: to hide list or show loading animation
 */
export const selectDatasetsAreDeleting = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isDeleting')
    );

/**
 * Gets isUpdating status while Datasets are being updated: to disable inputs
 */
export const selectDatasetsAreCreating = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isCreating')
    );

/**
 * Gets whether the dataset is currently being computed.
 */
export const selectDatasetIsComputing = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isComputing')
    );

/**
 * Gets whether the 2d sensitivity for the dataset is currently being computed.
 */
export const selectDatasetIsComputing2D = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isComputing2D')
    );

/**
 * Get whether the 2d diagrams is generating the export
 */
export const selectIsExporting = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isExporting')
    );

/**
 * Get the export of the 2d sensitivity
 */
export const selectExported2DSensitivityCSV = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('exported2DSensitivityCSV')
    );

/*
 * Gets whether the 3D sensitivity for the dataset is currently being computed.
 */
export const selectDatasetIsComputing3D = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isComputing3D')
    );

/**
 * Gets whether the 2d sensitivity for the dataset is currently being computed.
 */
export const selectIsComputing2DDataset = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isComputing2DDataset')
    );

/**
 * Gets scenario results
 */
export const selectScenarioResults = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('scenarioResults')
    );

/**
 * Gets whether a scenario is being submitted for the dataset
 */
export const selectScenarioIsSubmitting = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isScenarioSubmitting')
    );

/**
 * Gets the dataset computed status
 */
export const selectIsComputeCompleted = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isComputeCompleted')
    );

/**
 * Gets the dataset saved status
 */
export const selectIsDatasetSaved = () =>
    createSelector(
        selectDatasetState(),
        (substate: ImmutableDatasetState) => substate.get('isDatasetSaved')
    );

/**
 * Direct selector to the trends state
 */
export const selectTrendsState = () => (state: ImmutableReduxState) => state.get('trends');

/**
 * Select whether the any dataset value is being updated (used between all value types...)
 * TODO: This is silly?
 * @returns A boolean for the state of the update
 */
export const selectIsUpdatingValue = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) =>
            substate.get('isCreatingOrUpdatingDatasetValue') ||
            substate.get('isCreatingOrUpdatingStageValue') ||
            substate.get('isCreatingOrUpdatingStreamValue') ||
            substate.get('isCreatingOrUpdatingPlantValue')
    );
