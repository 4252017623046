/* eslint-disable flowtype-errors/show-errors */
// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';
import { colors } from 'styles/colors';

// Constants
import { LAYOUT_SIDEBAR } from '../../tools/constants';

export const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;

    flex: 1;

    height: 100%;
`;

export const Main = styled.div`
    display: flex;
    flex: 3;
    flex-direction: column;
    overflow-y: scroll;

    ${({ fullWidth }: Object) =>
        fullWidth &&
        `
        align-items: center;
    `}
`;

export const SidebarWrapper = styled.div`
    position: relative;
`;

export const Sidebar = styled.div`
    display: flex;
    width: ${({ sidebarWidth }: Object) => `${sidebarWidth}`};
    height: 100%;
    flex-direction: column;

    background-color: ${legacyTheme.sidebarLayoutSidebar};
    border-right: 1px solid ${legacyTheme.defaultBorder};

    overflow: hidden;

    transition: width 0.5s;
    transition-timing-function: ease-in-out;
    will-change: width;

    box-sizing: border-box;

    ${(props: Object) =>
        props.responsive &&
        `
        @media (max-width: ${props.responsiveMaxDeviceWidth}) {
            border-top: 1px solid ${legacyTheme.defaultBorder};
            border-left: none;
            border-right: none;

            height: 100% !important;
            width: 100%;
            max-width: 100%;

            position: fixed;
            top: ${
                props.open
                    ? '0px'
                    : `calc(100% - ${LAYOUT_SIDEBAR.RESPONSIVE_SIDEBAR.collapsedHeight})`
            };
            left: 0;
            z-index: 50;

            transition: top 0.5s;
            transition-timing-function: ease-in-out;
            will-change: top;
        }
    `}
`;

export const ContentWrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1;

    width: ${({ minWidth }: Object) => minWidth || '100%'};
    @media (max-width: ${(props) => props.responsiveMaxDeviceWidth}) {
        width: 100% !important;
    }

    overflow-x: hidden;

    padding: ${({ flush }: Object) => (flush ? '0' : '24px')};
`;

export const StyledSidebarFooter = styled.div`
    display: flex;
    align-items: flex-end;
    flex: 1;
`;

export const StyledSidebarSection = styled.div`
    width: 100%;

    padding: 20px 24px;

    border-bottom: 1px solid ${legacyTheme.defaultBorder};

    &:last-child {
        border-bottom: 0;
    }

    box-sizing: border-box;
`;

export const StyledSidebarTab = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${legacyTheme.sidebarLayoutSidebar};
    flex-shrink: 0;
`;

export const SidebarTabHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    cursor: ${({ onClick }: Object) => (onClick ? 'pointer' : 'initial')};
    color: ${({ active }: Object) =>
        active ? legacyTheme.black : legacyTheme.defaultSubtleColor7B};

    width: 100%;
    padding: 20px 24px;
    border-bottom: 1px solid ${legacyTheme.defaultBorder};

    &:hover {
        color: ${({ active, onClick }: Object) =>
            onClick || active ? legacyTheme.black : legacyTheme.defaultSubtleColor7B};
    }

    box-sizing: border-box;
`;

export const SidebarTabTitle = styled.h1`
    font-size: 20px;
    font-weight: normal;
`;

export const StyledSidebarTabBody = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${legacyTheme.sidebarLayoutSidebarSubcontent};
    padding: 24px;
    border-bottom: 1px solid ${legacyTheme.defaultBorder};
`;

export const DesktopCollapseIconWrapper = styled.div`
    display: none;
    @media (max-width: ${(props) => props.responsiveMaxDeviceWidth}) {
        display: block;
    }
`;

export const MobileCollapseIconWrapper = styled.div`
    display: block;
    @media (max-width: ${(props) => props.responsiveMaxDeviceWidth}) {
        display: none;
    }
`;

export const CollapseIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    top: ${({ top }: Object) => top || '20px'};
    left: ${({ leftOffset }: Object) => leftOffset || '300px'};

    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    color: rgb(107, 119, 140);
    cursor: pointer;
    height: 24px;
    width: 24px;
    transform: translate(-50%);
    background:  ${({inverseBackgroundColor}) => inverseBackgroundColor? `0px center ${colors.blue019}` : '0px center white'};
    border-radius: 50%;

    transition: all 0.5s;
    transition-timing-function: ease-in-out;
    will-change: all;

    > span {
        margin: 8px;
    }

    ${(props: Object) =>
        props.responsive &&
        `
        @media (max-width: ${props.responsiveMaxDeviceWidth}) {
            position: inherit;

            height: auto;
            width: auto;

            box-shadow: none;
            transform: rotate(${props.open ? '0' : '180'}deg);

            color: ${legacyTheme.sidebarLayoutResponsiveCaretColor};

            transition: top 0.5s;
            transition-timing-function: ease-in-out;
            will-change: top;

            > span {
                width: 18px;
                height: 11px;
                margin: 0px 12px 1px 12px;
            }
        }
    `}
`;

export const ResponsiveTitleWrapper = styled.div`
    ${(props: Object) =>
        props.responsive &&
        `
        @media (max-width: ${props.responsiveMaxDeviceWidth}) {
            display: flex;
            flex-direction: row;
            justify-content: center;

            margin: 10px;
            padding: 7px 10px;
            font-size: 18px;
            line-height: 20px;

            background-color: ${legacyTheme.white};
            border: 1px solid ${legacyTheme.defaultBorder};
            box-shadow: 3px 2px 1px 1px ${legacyTheme.disabledBorder};
            border-radius: 30px;

            color: ${legacyTheme.sidebarLayoutResponsiveTitle};
            font-size: 18px;
        }
    `}
`;

export const ResponsiveTitle = styled.div`
    display: none;

    ${(props: Object) =>
        props.responsive &&
        `
        @media (max-width: ${props.responsiveMaxDeviceWidth}) {
            display: flex;
            align-items: center;
        }
    `}
`;
