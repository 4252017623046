// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import FileSaver from 'file-saver';

// Components
import Export2DSensitivityCSVModal from 'components/Modals/Export2DSensitivityCSVModal';

// Types
import type { ReduxDispatch } from 'types';
import type { TwoDExport } from 'services/Dataset/types';

// Services
import { export2DSensitivityToCSV } from 'services/Dataset/thunks';
import { selectIsExporting, selectExported2DSensitivityCSV } from 'services/Dataset/selectors';

type Props = {
    circuitId: number,
    datasetId: number,
    handleOnClose?: ?() => void,
    isExporting: boolean,
    export2DSensitivityToCSV: (settings: TwoDExport) => boolean,
    exportResult: ?Object,
    sensitivityVariable: string,
    rangeMin: number,
    rangeMax: number,
};

/**
 * The container that handles all the export to CSV logic
 */
class Export2DSensitivityCSVModalContainer extends React.PureComponent<Props, null> {
    static defaultProps = {
        handleOnClose: null,
    };

    /**
     * When the component updates (called when updating the settings or when fetching the user)
     * check if the update finished, and if so, close the modal.
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        if (!this.props.handleOnClose) return; // If there is no close handler, do nothing

        const wasDoingAction = prevProps.isExporting;
        const isDoingAction = this.props.isExporting;

        if (wasDoingAction && !isDoingAction) {
            this.props.handleOnClose();
        }
    }

    /**
     * Handle the save button clicked.
     */
    handleExportClicked = (exportSettings: TwoDExport) =>
        this.props.export2DSensitivityToCSV &&
        this.props
            .export2DSensitivityToCSV({
                circuitId: this.props.circuitId,
                datasetId: this.props.datasetId,
                ...exportSettings,
                sensitivityVariable: this.props.sensitivityVariable,
                rangeMin: this.props.rangeMin,
                rangeMax: this.props.rangeMax,
            })
            .then((response: boolean) => {
                if (response) {
                    const blob = new Blob([this.props.exportResult], {
                        type: 'text/csv;charset=utf-8',
                    });
                    FileSaver.saveAs(blob, `${exportSettings.title}.csv`);
                }
            });

    render() {
        return (
            <Export2DSensitivityCSVModal
                loading={this.props.isExporting}
                onCancel={this.props.handleOnClose}
                onConfirm={this.handleExportClicked}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isExporting: selectIsExporting(),
    exportResult: selectExported2DSensitivityCSV(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            export2DSensitivityToCSV,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Export2DSensitivityCSVModalContainer)
);
