// @flow strict

import React from 'react';

// Mimic Engine
import type { ISkipStream } from 'components/_McCabeThiele';

// Style
import { StreamValuesWrapper } from './styles';

// Constants
import { DIAGRAM_DISPLAY_MODES, STREAM_VALUE_TYPES } from 'utils/constants';
import { COMPUTE_GRID, SETUP_GRID } from 'components/MimicDiagram/constants';

// Components
import StreamValueInputBox from 'components/MimicDiagram/Stream/StreamValueInputBox';
import SetupStreamOption from 'components/MimicDiagram/Stream/SetupStreamOption';
import EditButton from 'components/MimicDiagram/Stream/EditButton';

// Types
import type { UnitsConstant } from 'types';
import type { StreamEntity } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';

import type { OpenModalFunction } from 'containers/CircuitSetupContainer/MimicDiagramContainer';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    mimicStream: ISkipStream,
    streamData: StreamEntity,

    circuitUnits: UnitsConstant,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,

    // Feeds dont have a fromStage.
    fromStageColumn: number,
    fromStageRow: number,
    // Bleeds dont have a toStage.
    toStageColumn: number,

    // TODO: we must use an additional prop for the "Max stream values below skip", for now we assume 3 always.
    // This will make the skip streams more modular.

    values: ?Array<LooseStreamValue>,
    setStreamValue: SetStreamValueFunction,

    showEditLabel: boolean,
    onOpenModal: OpenModalFunction,
    onRemoveAdvancedStreamValueClicked: (streamData: StreamEntity) => void,
};

/**
 * Vertical Stream Values are those that belong to organic circuit and placed between two stages of different type
 * on the vertical organic stream path.
 */
export default class SkipStream extends React.PureComponent<Props, null> {
    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * Calculate edit button starting column position on the CSS grid
     */
    getEditButtonStartingColumn = () => {
        const skipLabelColumn = this.getStartingColumn();
        return skipLabelColumn + 2;
    };

    getStreamValueStartingColumn = (streamValue: LooseStreamValue) => {
        const grid = this.getGrid();
        if (streamValue.valueType === STREAM_VALUE_TYPES.PRE_COMPOSITION) {
            // this can only be a PLS stream value.
            return (
                this.props.fromStageColumn +
                grid.STAGE.COLUMN_SPAN +
                grid.STREAM_VALUES.COLUMN_SPAN / 2
            );
        } else if (streamValue.valueType === STREAM_VALUE_TYPES.POST_COMPOSITION) {
            // this can only be a PLS stream value.
            return (
                this.props.toStageColumn -
                grid.STREAM_VALUES.COLUMN_SPAN -
                grid.STREAM_VALUES.COLUMN_SPAN / 2
            );
        } else if (streamValue.valueType === STREAM_VALUE_TYPES.COMPOSITION) {
            return Math.floor((this.props.fromStageColumn + this.props.toStageColumn) / 2); // if there is only 1 stream type.
        } else {
            throw new Error(
                `Unknown stream value type given to Skip Stream: ${streamValue.valueType}`
            );
        }
    };

    /**
     * Get the span of the
     */
    getColumnSpan = () => {
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            const avg = (this.props.fromStageColumn + this.props.toStageColumn) / 2;
            // if the skip stream is positioned in an even number of columns, then the skip label takes up 2 column and is centered.
            // otherwise, it takes 1 column and max width.
            return Number.isInteger(avg) ? 2 : 1;
        } else {
            return this.getGrid().STREAM_VALUES.COLUMN_SPAN;
        }
    };

    /**
     * Get the starting column of the stream values.
     */
    getStartingColumn = () => {
        const skipLabelPosition = Math.floor(
            (this.props.fromStageColumn + this.props.toStageColumn) / 2
        );
        const editButtonModifier = -1;
        if (this.shouldShowEditButton()) {
            return skipLabelPosition + editButtonModifier;
        }
        return skipLabelPosition;
    };

    /**
     * Calculate stream value starting row position on the CSS grid
     */
    getStartingRow = () =>
        this.props.fromStageRow -
        this.getGrid().SKIP.TOP_OFFSET * this.props.mimicStream.getSkipStreamCountBelow();

    // Check if it's a Setup mode
    shouldShowEditButton = (): boolean =>
        this.props.showEditLabel && this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP;

    /**
     * If the user wants to delete the skip stream.
     */
    onRemoveAdvancedValues = () =>
        this.props.onRemoveAdvancedStreamValueClicked &&
        this.props.onRemoveAdvancedStreamValueClicked(this.props.streamData);

    renderStreamValues = () =>
        this.props.values &&
        this.props.values.map((streamValue: LooseStreamValue) => (
            <StreamValuesWrapper
                key={`${streamValue.valueType}`}
                startingColumn={this.getStreamValueStartingColumn(streamValue)}
                startingRow={this.getStartingRow()}
                columnSpan={COMPUTE_GRID.CONTINUE_STREAM_VALUES.COLUMN_SPAN}
                rowSpan={COMPUTE_GRID.STREAM_VALUES.ROW_SPAN}
            >
                <StreamValueInputBox
                    streamValue={streamValue}
                    circuitUnits={this.props.circuitUnits}
                    streamCircuit={this.props.streamData.streamCircuit}
                    setStreamValue={this.props.setStreamValue}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                />
            </StreamValuesWrapper>
        ));

    renderEditButton = () => (
        <StreamValuesWrapper
            startingColumn={this.getEditButtonStartingColumn()}
            startingRow={this.getStartingRow()}
            columnSpan={SETUP_GRID.CONTINUE_STREAM_VALUES.COLUMN_SPAN}
            rowSpan={SETUP_GRID.CONTINUE_STREAM_VALUES.ROW_SPAN}
        >
            <EditButton onOpenModal={this.props.onOpenModal} streamData={this.props.streamData} />
        </StreamValuesWrapper>
    );

    renderCloseLabel = () => (
        <StreamValuesWrapper
            startingColumn={this.getStartingColumn()}
            startingRow={this.getStartingRow()}
            columnSpan={this.getColumnSpan()}
            rowSpan={SETUP_GRID.CONTINUE_STREAM_VALUES.ROW_SPAN}
        >
            <SetupStreamOption
                handleRemoveAdvancedValue={this.onRemoveAdvancedValues}
                streamType={this.props.streamData.streamType}
                streamCircuit={this.props.streamData.streamCircuit}
                shouldHalfWidth={this.getColumnSpan() === 2}
                showCloseButton // always show the close label.
            />
        </StreamValuesWrapper>
    );

    renderSetup = () => {
        return (
            <React.Fragment>
                {this.renderCloseLabel()}
                {this.shouldShowEditButton() && this.renderEditButton()}
            </React.Fragment>
        );
    };

    render() {
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            return this.renderSetup();
        } else {
            return this.renderStreamValues();
        }
    }
}
