// @flow strict

import type { ReduxDispatch, ResponseErrorType } from 'types';

import { createUntranslatedFeedback } from 'services/Feedback/actions';
import { report } from 'services/Errors/resources';

import type { Isotherm, RawIsotherm, ImmutableIsothermShareAccess } from 'services/Isotherm/types';

import {
    create,
    index,
    update,
    destroy,
    visualize,
    predict,
    createMany,
    _setIsothermShareAccess,
} from './resources';

import {
    receivedCreateSuccess,
    receivedCreateFailure,
    receivedCreateManySuccess,
    receivedCreateManyFailure,
    receivedFetchListSuccess,
    receivedFetchListFailure,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    receivedDestroySuccess,
    receivedDestroyFailure,
    receivedVisualizeSuccess,
    receivedVisualizeFailure,
    deVisualize,
    setIsFetchingStatus,
    setIsUpdatingStatus,
    setIsDeletingStatus,
    setIsCreatingStatus,
    setIsVisualizingStatus,
} from './actions';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Visualize the isotherm
 */
export const visualizeIsotherm = (isotherm: RawIsotherm) => (dispatch: ReduxDispatch) => {
    dispatch(setIsVisualizingStatus());
    visualize(isotherm)
        .then((response: RawIsotherm) => {
            dispatch(receivedVisualizeSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.visualizeIsothermSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedVisualizeFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.visualizeIsothermFailed'));
        });
};

/**
 * Destroy the visualized isotherm
 */
export const deVisualizeIsotherm = () => (dispatch: ReduxDispatch) => {
    dispatch(deVisualize());
};

/**
 * Predict and visualize the isotherm
 */
export const predictVisualizeIsotherm = (isotherm: RawIsotherm) => (dispatch: ReduxDispatch) => {
    dispatch(setIsVisualizingStatus());
    predict(isotherm)
        .then((response: RawIsotherm) => {
            dispatch(receivedVisualizeSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.predictVisualizeIsothermSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedVisualizeFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.predictVisualizeIsothermFailed')
            );
        });
};

/**
 * Create new isotherm
 */
export const createIsotherm = (isotherm: RawIsotherm) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingStatus());
    create(isotherm)
        .then((response: Isotherm) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createIsothermSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createIsothermFailed'));
        });
};

/**
 * Create many new isotherms
 */
export const createManyIsotherms = (isotherms: Array<RawIsotherm>) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingStatus());
    createMany(isotherms)
        .then((response: Array<Isotherm>) => {
            dispatch(receivedCreateManySuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createIsothermSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateManyFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createIsothermFailed'));
        });
};

/**
 * Fetch all isotherms
 */
export const fetchAllIsotherms = () => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingStatus());
    index()
        .then((response: Array<Isotherm>) => {
            dispatch(receivedFetchListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchAllIsothermsFailed'));
        });
};

/**
 * Update isotherm
 */
export const updateIsotherm = (id: number, isotherm: Isotherm) => (dispatch: ReduxDispatch) => {
    dispatch(setIsUpdatingStatus());
    update(id, isotherm)
        .then((response: Isotherm) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateIsotherm'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateIsothermFailed'));
        });
};

/**
 * Destroy isotherm
 */
export const destroyIsotherm = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsDeletingStatus());
    destroy(id)
        .then((response: Isotherm) => {
            dispatch(receivedDestroySuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.destroyIsothermSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDestroyFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.destroyIsothermFailed'));
        });
};

/**
 * Update isotherm
 */
export const setIsothermShareAccess = (id: number, shareAccess: ImmutableIsothermShareAccess) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingStatus());
    _setIsothermShareAccess(id, shareAccess)
        .then((response: Isotherm) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateIsotherm'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateIsothermFailed'));
        });
};
