// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { colors } from 'styles/colors';

export const WasherWrapper = styled.div`
    display: flex;
    flex-direction: column;

    border-radius: 4px;
    overflow: hidden;
    background-color: ${LegacyTheme.white};
    border: 1px solid ${colors.greyE5};
    width: 100%;
`;

export const WasherContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: ${colors.greyE5};
    padding: 16px 16px;

    font-weight: normal;
    font-size: 11px;
`;
