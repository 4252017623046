// @flow strict

import React from 'react';

// Mimic Engine
import type { IMimicStream } from 'components/_McCabeThiele';

// Constants
import { SETUP_GRID, COMPUTE_GRID } from 'components/MimicDiagram/constants';
import { STREAM_TYPES, DIAGRAM_DISPLAY_MODES } from 'utils/constants';

// Helpers
import { getStreamKey } from 'utils/helpers';

// Types
import type { UnitsConstant } from 'types';
import type {
    StageTypeConstant,
    StreamEntity,
    StreamTypeConstant,
    StreamCircuitConstant,
    OrganicCircuitConstant,
    LooseStream,
} from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';

// Components
import ContinueStream from './ContinueStream';
import ConnectorStream from './ConnectorStream';
import FeedBleedStream from './FeedBleedStream';
import SkipStream from './SkipStream';
import SkipFeedBleedStream from './SkipFeedBleedStream';
import BypassFeedBlendStream from './BypassFeedBlendStream';
import BypassBleedStream from './BypassBleedStream';

// Types
import type { OpenModalFunction } from 'containers/CircuitSetupContainer/MimicDiagramContainer';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type StreamKeyType = number | string;

type Props = {
    displayMode: DiagramDisplayModesConstant,
    organicCircuitType: OrganicCircuitConstant,
    circuitUnits: UnitsConstant,
    datasetMode: ?DatasetModesConstant,

    mimicStream: IMimicStream,
    streamData: StreamEntity,

    showEditLabel: boolean, // will be true only if it is a continue stream not tied to any other stream.
    isRemovableStream: boolean, // will be false if this is a feed stream and it is connected to a bleed stream.
    isEdgeStream: boolean, // required for proper calculation of edge feed/bleed streams
    isSkipInOutStream: boolean, // required for proper calculation of bleed/blend streams into SKIP streams.
    isFromOrToLOTank: boolean, // required for proper calculation of the stream setup label + stream value
    isToNearestExtractorStreamFromLOTank: boolean,

    // Feeds dont have a fromStage.
    fromStageColumn: ?number,
    fromStageRow: ?number,
    fromStageType: ?StageTypeConstant,
    fromStageLocation: ?number,
    // Bleeds dont have a toStage.
    toStageColumn: ?number,
    toStageRow: ?number,
    toStageType: ?StageTypeConstant,
    toStageLocation: ?number,

    values: ?Array<LooseStreamValue>,

    hasExtractBypass: boolean, // for proper calculation of washer / streamvalues positioning.
    hasOrganicBlend: boolean, // for proper calculation of washer / streamvalues positioning.
    hasStripBypass: boolean, // for proper calculation of washer / streamvalues positioning.

    // Setup mimic diagram
    onOpenModal: OpenModalFunction,

    // Computation mimic diagram
    onRemoveAdvancedStreamValueClicked: (streamData: LooseStream) => void,
    setStreamValue: SetStreamValueFunction,

    isStreamInterstageBleed?: boolean,
};

/**
 * Stream Component is the component handling all representation and interaction with
 * Streams(between Stages, going in into Stages or coming out of Stages)
 * and their values for further calculations.
 */
class Stream extends React.PureComponent<Props> {
    getStreamCircuit = (): StreamCircuitConstant => this.props.streamData.streamCircuit;

    getStreamType = (): StreamTypeConstant => this.props.streamData.streamType;

    getStreamId = (): number => this.props.streamData.id;

    getStreamKey = (): StreamKeyType => getStreamKey(this.props.streamData);

    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * For some reason, (I believe this is an issue with babel/webpack).
     * The sub components of this Stream component MUST be written in the file BEFORE the render function.
     * Otherwise, the sub components of this component gets unmounted and remounted.
     * This causes the input box to be deleted from the DOM and re-added.
     * This unmount and remount causes the user to lose focus of the field after every change in state.
     *
     * By having the sub components written in the file before the render function, the componentDidUpdate function gets called
     * as we expect, and we get the wanted behavior. This happens in the production builds only. Local works.
     *
     * THIS SHOULD NOT BE CALLED FROM ANYWHERE.
     */
    initializeSubComponents = () => [
        <ConnectorStream key="1" />,
        <ContinueStream key="2" />,
        <FeedBleedStream key="3" />,
        <SkipStream key="4" />,
        <BypassFeedBlendStream key="5" />,
        <BypassBleedStream key="6" />,
        <SkipFeedBleedStream key="7" />,
    ];

    render() {
        const streamType = this.getStreamType();
        if (streamType === STREAM_TYPES.CONTINUE) {
            if (this.props.fromStageType !== this.props.toStageType) {
                return (
                    <ConnectorStream
                        key={this.props.mimicStream.getCode()}
                        streamData={this.props.streamData}
                        circuitUnits={this.props.circuitUnits}
                        displayMode={this.props.displayMode}
                        datasetMode={this.props.datasetMode}
                        // connector streams has no edit buttons and is not removable.
                        // Bypass extras:
                        hasExtractBypass={this.props.hasExtractBypass}
                        hasOrganicBlend={this.props.hasOrganicBlend}
                        hasStripBypass={this.props.hasStripBypass}
                        values={this.props.values}
                        // positions
                        fromStageColumn={this.props.fromStageColumn}
                        fromStageRow={this.props.fromStageRow}
                        fromStageType={this.props.fromStageType}
                        fromStageLocation={this.props.fromStageLocation}
                        toStageColumn={this.props.toStageColumn}
                        toStageRow={this.props.toStageRow}
                        toStageType={this.props.toStageType}
                        toStageLocation={this.props.toStageLocation}
                        // Handler functions
                        setStreamValue={this.props.setStreamValue}
                    />
                );
            } else {
                return (
                    <ContinueStream
                        key={this.props.mimicStream.getCode()}
                        streamData={this.props.streamData}
                        circuitUnits={this.props.circuitUnits}
                        displayMode={this.props.displayMode}
                        datasetMode={this.props.datasetMode}
                        // Setup mode:
                        showEditLabel={this.props.showEditLabel}
                        // computation mode values:
                        values={this.props.values}
                        // positions
                        fromStageColumn={this.props.fromStageColumn}
                        fromStageRow={this.props.fromStageRow}
                        fromStageType={this.props.fromStageType}
                        toStageColumn={this.props.toStageColumn}
                        // Handler functions
                        setStreamValue={this.props.setStreamValue}
                        onOpenModal={this.props.onOpenModal}
                    />
                );
            }
        } else if (
            streamType === STREAM_TYPES.FEED ||
            streamType === STREAM_TYPES.BLEED ||
            streamType === STREAM_TYPES.BLEND // PLS Blend
        ) {
            if (this.props.isSkipInOutStream) {
                return (
                    <SkipFeedBleedStream
                        key={this.props.mimicStream.getCode()}
                        mimicStream={this.props.mimicStream}
                        streamData={this.props.streamData}
                        circuitUnits={this.props.circuitUnits}
                        displayMode={this.props.displayMode}
                        datasetMode={this.props.datasetMode}
                        // computation mode values:
                        values={this.props.values}
                        setStreamValue={this.props.setStreamValue}
                        // positions
                        fromStageColumn={this.props.fromStageColumn}
                        fromStageRow={this.props.fromStageRow}
                        fromStageType={this.props.fromStageType}
                        toStageColumn={this.props.toStageColumn}
                        toStageRow={this.props.toStageRow}
                        toStageType={this.props.toStageType}
                        // Setup mode
                        onRemoveAdvancedStreamValueClicked={
                            this.props.onRemoveAdvancedStreamValueClicked
                        }
                    />
                );
            }
            // If it is a feed, a bleed or a PLS blend, then we render the feed stream.
            return (
                <FeedBleedStream
                    key={this.props.mimicStream.getCode()}
                    streamData={this.props.streamData}
                    circuitUnits={this.props.circuitUnits}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                    organicCircuitType={this.props.organicCircuitType}
                    isEdgeStream={this.props.isEdgeStream}
                    // Setup mode:
                    isRemovableStream={this.props.isRemovableStream && !this.props.isEdgeStream}
                    // computation mode values:
                    values={this.props.values}
                    // positions
                    fromStageColumn={this.props.fromStageColumn}
                    fromStageRow={this.props.fromStageRow}
                    fromStageType={this.props.fromStageType}
                    toStageColumn={this.props.toStageColumn}
                    toStageRow={this.props.toStageRow}
                    toStageType={this.props.toStageType}
                    // Handler functions
                    setStreamValue={this.props.setStreamValue}
                    onRemoveAdvancedStreamValueClicked={
                        this.props.onRemoveAdvancedStreamValueClicked
                    }
                    isStreamInterstageBleed={this.props.isStreamInterstageBleed}
                />
            );
        } else if (streamType === STREAM_TYPES.SKIP) {
            // If it is a skip, then we render a skip stream.
            return (
                <SkipStream
                    key={this.props.mimicStream.getCode()}
                    mimicStream={this.props.mimicStream}
                    streamData={this.props.streamData}
                    circuitUnits={this.props.circuitUnits}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                    // Setup mode:
                    showEditLabel={this.props.showEditLabel}
                    // computation mode values:
                    values={this.props.values}
                    // positions
                    fromStageColumn={this.props.fromStageColumn}
                    fromStageRow={this.props.fromStageRow}
                    toStageColumn={this.props.toStageColumn}
                    // Handler functions
                    setStreamValue={this.props.setStreamValue}
                    onRemoveAdvancedStreamValueClicked={
                        this.props.onRemoveAdvancedStreamValueClicked
                    }
                    onOpenModal={this.props.onOpenModal}
                />
            );
        } else if (
            streamType === STREAM_TYPES.BYPASS_FEED ||
            streamType === STREAM_TYPES.BYPASS_BLEND
        ) {
            // If it is a bypass feed/blend, then we render a bypass feed stream.
            return (
                <BypassFeedBlendStream
                    key={this.props.mimicStream.getCode()}
                    streamData={this.props.streamData}
                    circuitUnits={this.props.circuitUnits}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                    // computation mode values:
                    values={this.props.values}
                    // positions
                    toStageColumn={this.props.toStageColumn}
                    toStageRow={this.props.toStageRow}
                    toStageType={this.props.toStageType}
                    isFromOrToLOTank={this.props.isFromOrToLOTank}
                    isToNearestExtractorStreamFromLOTank={
                        this.props.isToNearestExtractorStreamFromLOTank
                    }
                    // Handler functions
                    setStreamValue={this.props.setStreamValue}
                    onRemoveAdvancedStreamValueClicked={
                        this.props.onRemoveAdvancedStreamValueClicked
                    }
                />
            );
        } else if (streamType === STREAM_TYPES.BYPASS_BLEED) {
            // If it is a bypass feed, then we render a bypass feed stream.
            return (
                <BypassBleedStream
                    key={this.props.mimicStream.getCode()}
                    streamData={this.props.streamData}
                    circuitUnits={this.props.circuitUnits}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                    isEdgeStream={this.props.isEdgeStream} // is the bypass bleed the first bleed in the section
                    // computation mode values:
                    values={this.props.values}
                    // positions
                    fromStageColumn={this.props.fromStageColumn}
                    fromStageRow={this.props.fromStageRow}
                    fromStageType={this.props.fromStageType}
                    // Handler functions
                    setStreamValue={this.props.setStreamValue}
                    onRemoveAdvancedStreamValueClicked={
                        this.props.onRemoveAdvancedStreamValueClicked
                    }
                />
            );
        } else {
            throw new Error(`Unknown stream type: ${streamType}.`);
        }
    }
}

export default Stream;
