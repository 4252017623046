// @flow

import React from 'react';

// Styles
import { StyledSubtleAnchor, StyledSubtleLink } from './styles';

// Types
import type { ChildrenType } from '../../types';

export const SubtleAnchor = (props: { children: ChildrenType }) => (
    <StyledSubtleAnchor {...props}>{props.children}</StyledSubtleAnchor>
);

export const SubtleLink = (props: { children: ChildrenType, to: string }) => (
    <StyledSubtleLink to={props.to} {...props}>
        {props.children}
    </StyledSubtleLink>
);
