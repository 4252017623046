// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import KPIStatusTable from './KPIStatusTable';
import { Common, Toggle } from 'components/_ReactUI_V1';

// Styles
import { Subtitle } from 'styles/common';

// Types
import type { IntlType, ImmutableList } from 'types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutableDataset } from 'services/Dataset/types';

type Props = {
    intl: IntlType,
    dataset: ImmutableDataset,
    kpiSettings?: ImmutableList<ImmutableKPISetting>,
};

export const STATUS_TYPES = {
    VALIDITY: 'kpiValidityStatuses',
    CALCULATION: 'kpiCalculationStatuses',
};
type StatusType = $Values<typeof STATUS_TYPES>;

type State = {
    showTable: {
        kpiValidityStatuses: boolean,
        kpiCalculationStatuses: boolean,
    },
};

class KPIStatusTables extends React.PureComponent<Props, State> {
    static defaultProps = {
        kpiSettings: null,
    };

    state = {
        showTable: {
            kpiValidityStatuses: true,
            kpiCalculationStatuses: true,
        },
    };

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.KPIStatusTables.${id}`,
        });

    /**
     * Update provided toggle input by key
     */
    handleShowTableToggle = (key: StatusType) => (checked: boolean) =>
        this.setState((prevState: State) => ({
            showTable: {
                ...prevState.showTable,
                [key]: checked,
            },
        }));

    renderKPIStatusTableByKey = (key: StatusType) => {
        if (!this.props.dataset.has(key) || this.props.dataset.get(key).isEmpty()) {
            return null;
        }

        // KpIStatusTable requires a list of kpi settings to pull their names from, if empty don't render the table
        const kpiSettings = this.props.kpiSettings;
        if (!kpiSettings || (kpiSettings && kpiSettings.isEmpty())) {
            return null;
        }

        const shouldShowTable = this.state.showTable[key];

        return (
            <React.Fragment>
                <Common.Row>
                    <Common.Column flex="initial">
                        <Subtitle>{this.getTranslation(key)}</Subtitle>
                    </Common.Column>
                    <Common.Column>
                        <Toggle
                            checked={shouldShowTable}
                            onClickHandler={this.handleShowTableToggle(key)}
                        />
                    </Common.Column>
                </Common.Row>
                {shouldShowTable && (
                    <React.Fragment>
                        <KPIStatusTable
                            statuses={this.props.dataset.get(key)}
                            kpiSettings={kpiSettings}
                        />
                        <br />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.renderKPIStatusTableByKey(STATUS_TYPES.VALIDITY)}
                {this.renderKPIStatusTableByKey(STATUS_TYPES.CALCULATION)}
            </React.Fragment>
        );
    }
}

export default injectIntl(KPIStatusTables);
