// @flow strict

import styled from 'styled-components';
import { LegacyTheme } from 'components/_ReactUI_V1';

import { COMPUTE_GRID } from 'components/MimicDiagram/constants';

export const UnitWrapper = styled.div`
    flex: 2;
`;

export const InputWrapper = styled.div`
    flex: 4;
`;

export const InputLabelWrapper = styled.div`
    display: flex;
    flex: 4;
`;

type IBodyWrapperProps = {
    backgroundColor: string,
};

export const DiagramValueBoxWrapper = styled.div`
    z-index: 2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0px 10px;

    border-radius: 4px;
    overflow: hidden;

    background-color: ${({ backgroundColor }: IBodyWrapperProps) => backgroundColor};

    ${({ backgroundColor }: IBodyWrapperProps) => `
        box-shadow: inset 0 -1px 0 0 ${backgroundColor},
            inset 0 1px 0 0 ${backgroundColor},
            0 1px 2px 0 rgba(0, 0, 0, 0.05);
    `}

    width: 100%;
    height: ${COMPUTE_GRID.GRID.ROW_HEIGHT}px;

    color: ${LegacyTheme.defaultColor};
    font-size: 11px;

    > ${UnitWrapper}, > ${InputWrapper}, > ${InputLabelWrapper} {
        margin-right: 6px;
        &:last-child {
            margin-right: 0;
        }
    }
`;
