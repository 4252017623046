// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { Map, fromJS } from 'immutable';

// Components
import { Table, InputField, InputSelect, Toggle } from 'components/_ReactUI_V1';

import ReorderControls from 'components/ReorderControls';

// Constants
import {
    NUMBER_INPUT_PLACEHOLDER,
    KPI_SETTINGS_PRECISION_OPTIONS,
    NO_RESULT_STRING,
    KPI_SETUP_TABLE_CONTEXT_TYPES,
    UNITS_FOR_UNIT_TYPE,
    STYLE_VALUES,
} from 'utils/constants';
import { TARGET_TYPES } from 'utils/kpiConstants';

// Helpers
import { formatNumberAsStringValue, tryParseNumberOrNull } from 'utils/helpers';
import {
    getKPISettingContextId,
    getKPIUnitLabelFromValue,
    getKPIUnitType,
    getKPIUnitValueFromOptions,
    getDefaultKPIUnitValueForUnitType,
} from 'utils/kpiHelpers';

// Styles
import { InputBlock, InputBlockLabel, FormInputWrapper } from 'styles/common';
import { TableContainer } from './styles';

// Types
import type {
    InputEvent,
    IntlType,
    ReactSelectObject,
    LooseInputValueTypes,
    LooseKeyArrayType,
    LooseNumberType,
    ImmutableList,
    KpiUnitName,
    UnitsConstant,
} from 'types';
import type {
    ImmutableKPISetting,
    AllKpiTypes,
    KPISetupTableContextType,
} from 'services/KPISetting/types';

type Props = {
    intl: IntlType,
    units: UnitsConstant,
    kpis: ImmutableList<ImmutableKPISetting>,
    availableKpiTypes?: Array<AllKpiTypes>, // plant KPIs
    possibleKPITypes?: ImmutableList<ImmutableKPISetting>, // circuit KPIs
    readonly: boolean,
    noKPIMessage: string,
    isUpdating?: boolean,
    context?: KPISetupTableContextType,

    onInputChange?: ?(keys: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onRemoveKPI?: ?(kpiSetting: ImmutableKPISetting) => () => void,
    onReorderKPI?: ?(originalKPISetting: ImmutableKPISetting) => (newOrder: number) => void,

    onChangeKPIContext?: ?(kpiSetting: ImmutableKPISetting, newContextId: number) => void,
    onChangeKPIType?: ?(kpiSetting: ImmutableKPISetting, newType: AllKpiTypes) => void,
};

const INPUT_BLOCK_INPUT_STYLES = {
    width: '64px',
};
const INPUT_BLOCK_NUMBER_INPUT_STYLES = {
    ...INPUT_BLOCK_INPUT_STYLES,
    paddingRight: '6px',
};
const KPI_TYPE_INPUT_FIELD_STYLES = { width: '100%', minWidth: '200px' };
const KPI_CONTEXT_INPUT_FIELD_STYLES = { width: '120px' };
const KPI_TYPE_INPUT_FIELD_VALIDATION = {
    maxCharacters: '255',
    maxNumber: 999999.999999,
    maxWholePartLength: 6,
    maxFractionPartLength: 6
};

class KPISetupTable extends React.PureComponent<Props> {
    static defaultProps = {
        availableKpiTypes: [],
        isUpdating: false,
        onChangeKPIContext: null,
        onChangeKPIType: null,
        onInputChange: null,
        onRemoveKPI: null,
        onReorderKPI: null,
        possibleKPITypes: fromJS([]),
    };

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.KPISetupTable.${id}`,
        });

    getTableHeader = () =>
        [
            this.props.onReorderKPI && {
                id: 'order',
                label: this.getTranslation('headers.order'),
            },
            {
                id: 'kpiType',
                label: this.getTranslation('headers.kpiType'),
            },
            this.shouldDisplayAtLeastOneContexts() && {
                id: 'context',
                label: this.getTranslation('headers.context'),
            },
            {
                id: 'name',
                label: this.getTranslation('headers.name'),
            },
            {
                id: 'kpiUnit',
                label: this.getTranslation('headers.kpiUnit'),
            },
            this.shouldDisplayAtLeastOneTargets() && {
                id: 'target',
                label: this.getTranslation('headers.target'),
            },
            {
                id: 'validityRange',
                label: this.getTranslation('headers.validityRange'),
            },
            this.shouldDisplayAtLeastOneRecommendationLimits() && {
                id: 'recommendationLimits',
                label: this.getTranslation('headers.recommendationLimits'),
            },
            this.shouldDisplayAtLeastOneRecommendationLimits() && {
                id: 'roundTo',
                label: this.getTranslation('headers.roundTo'),
            },
            {
                id: 'precision',
                label: this.getTranslation('headers.precision'),
            },
            this.shouldDisplayAtLeastOneIsRequired() && {
                id: 'isRequired',
                label: this.getTranslation('headers.isRequired'),
            },
            {
                id: 'show',
                label: this.getTranslation('headers.show'),
            },
            this.shouldDisplayDisplayInPlant() && {
                id: 'displayInPlant',
                label: this.getTranslation('headers.displayInPlant'),
            },
        ].filter(Boolean); // remove nulls/false elements.

    handleChangeKPIType = (kpiSetting: ImmutableKPISetting, kpiIndex: number) => (
        selectedObject: ReactSelectObject
    ) => {
        if (this.props.onChangeKPIType) {
            this.props.onChangeKPIType(kpiSetting, selectedObject.value);
        } else {
            // for plants setup:
            // TODO: UNIFY This code :(
            this.handleInputSelectChange([kpiIndex, 'kpiType'])(selectedObject);
            const unitType = getKPIUnitType(selectedObject.value);
            const unitValue = getDefaultKPIUnitValueForUnitType(unitType, this.props.units);
            this.props.onInputChange([kpiIndex, 'kpiUnitType'], unitType);
            this.props.onInputChange([kpiIndex, 'kpiUnit'], unitValue);
        }
    };

    handleChangeKPIContext = (kpiSetting: ImmutableKPISetting) => (
        selectedObject: ReactSelectObject
    ) => {
        if (this.props.onChangeKPIContext) {
            this.props.onChangeKPIContext(kpiSetting, selectedObject.value);
        }
    };

    handleInputSelectChange = (key: LooseKeyArrayType) => (selectedObject: ReactSelectObject) =>
        this.props.onInputChange && this.props.onInputChange(key, selectedObject.value);

    handleInputChange = (key: LooseKeyArrayType) => (event: InputEvent) => {
        if (!this.props.onInputChange) {
            return;
        }
        let value = event.target.value;
        // if the text box is of type number, cast it to a number.
        if (event.target.type === 'number') {

            const isValid = this.checkValidity(
                value,
                KPI_TYPE_INPUT_FIELD_VALIDATION.maxWholePartLength,
                KPI_TYPE_INPUT_FIELD_VALIDATION.maxFractionPartLength
            );

            if (isValid) {
                value = tryParseNumberOrNull(value);
            } else {
                event.preventDefault();
                return;
            }
        }

        this.props.onInputChange(key, value === null ? '' : value);
    };

    checkValidity = (value: number, wholeNumberLength: number, precisionLength: number) => {
        const [wholeNumber, precisionNumber] = value.toString().split('.');

        if (wholeNumber.length > wholeNumberLength) {
            return false;
        }

        else if (precisionNumber && precisionNumber.length > precisionLength) {
            return false;
        }

        return true;
    }

    handleToggleChange = (key: LooseKeyArrayType) => (checked: boolean) =>
        this.props.onInputChange && this.props.onInputChange(key, checked);

    getGenericValueToReactSelectObject = (value?: LooseNumberType): ?ReactSelectObject => {
        if (value !== 0 && !value) {
            return null;
        }
        const valueAsString = value.toString();
        return {
            value: valueAsString,
            label: valueAsString,
        };
    };

    // If we can find at least 1 kpi that needs to have a context shown, then we must show the context column.
    shouldDisplayAtLeastOneIsRequired = () => this.props.kpis.find(this.shouldDisplayIsRequired);

    // If we can find at least 1 kpi that needs to have a context shown, then we must show the context column.
    shouldDisplayAtLeastOneContexts = () => this.props.kpis.find(this.shouldDisplayContexts);

    // If we can find at least 1 kpi that needs to have targets, then we must show the targets column.
    shouldDisplayAtLeastOneTargets = () => this.props.kpis.find(this.shouldDisplayTargets);

    // If we can find at least 1 kpi that needs to have recommend min/max, then we must show the recommend column.
    shouldDisplayAtLeastOneRecommendationLimits = () =>
        this.props.kpis.find(this.shouldDisplayRecommendationLimits);

    shouldDisplayIsRequired = (kpiSetting: ImmutableKPISetting) =>
        Boolean(kpiSetting.has('isRequired'));

    shouldDisplayDisplayInPlant = () =>
        this.props.context === KPI_SETUP_TABLE_CONTEXT_TYPES.CIRCUIT_MINOR_KPIS;

    /**
     * The recommendation limits should be displayed if all of the following 3 values are
     * set on the KPI Setting.
     */
    shouldDisplayRecommendationLimits = (kpiSetting: ImmutableKPISetting) =>
        Boolean(
            kpiSetting.has('minRecommend') &&
                kpiSetting.has('maxRecommend') &&
                kpiSetting.has('roundTo')
        );

    /**
     * The kpi targets should be displayed if the KPI Setting has any of the 3 values
     */
    shouldDisplayTargets = (kpiSetting: ImmutableKPISetting) =>
        Boolean(
            kpiSetting.has(TARGET_TYPES.MAIN_TARGET) ||
                kpiSetting.has(TARGET_TYPES.LOW_TARGET) ||
                kpiSetting.has(TARGET_TYPES.HIGH_TARGET)
        );

    /**
     * Should we display the context of the KPI Setting?
     */
    shouldDisplayContexts = (kpiSetting: ImmutableKPISetting) =>
        Boolean(kpiSetting.has('context') && kpiSetting.get('context') !== null);

    /**
     * Get the column row content for the KPI's unit
     */
    getKPIUnitColumn = (kpiSetting: ImmutableKPISetting, index: number) => {
        const unitType = kpiSetting.get('kpiUnitType');
        if (!unitType) {
            // some KPIs do not have units at all
            return null;
        }
        const unitOptions = UNITS_FOR_UNIT_TYPE[unitType].map(
            (option: KpiUnitName | Array<KpiUnitName>) => {
                const value = getKPIUnitValueFromOptions(option);
                return {
                    label: getKPIUnitLabelFromValue(value, this.props.intl),
                    value,
                };
            }
        );

        const selectedOption = {
            value: kpiSetting.get('kpiUnit'),
            label: getKPIUnitLabelFromValue(kpiSetting.get('kpiUnit'), this.props.intl),
        };

        return (
            <InputSelect
                selectedOption={selectedOption}
                options={unitOptions}
                onSelect={this.handleInputSelectChange([index, 'kpiUnit'])}
                placeholder={NUMBER_INPUT_PLACEHOLDER}
                maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                renderString={this.props.readonly}
                controlShouldRenderValue
            />
        );
    };

    /**
     * Get the column row content for the KPI's type
     */
    getKPITypeColumn = (kpiSetting: ImmutableKPISetting, index: number) => {
        let kpiTypeContent = (
            <InputField
                value={kpiSetting.get('kpiType')}
                renderString={this.props.readonly}
                disabled
            />
        );
        // If the kpi does not have an id (create)
        // If the kpi is not a disableKpiTypeInput: created by getAllKPIsForCircuit()
        // And if the availableKpiTypes list has content, allow the user to switch the kpiType
        if (!kpiSetting.get('id') && !kpiSetting.get('disableKpiTypeInput')) {
            let options = [];
            if (this.props.availableKpiTypes && this.props.availableKpiTypes.length > 0) {
                options = this.props.availableKpiTypes.map((kpiType: AllKpiTypes) =>
                    this.getGenericValueToReactSelectObject(kpiType)
                );
            } else if (this.props.possibleKPITypes && this.props.possibleKPITypes.size > 0) {
                options = this.props.possibleKPITypes
                    .filter(
                        (kpi: ImmutableKPISetting) =>
                            kpi.get('kpiType') !== kpiSetting.get('kpiType')
                    )
                    .groupBy((possibleKPISetting: ImmutableKPISetting) =>
                        possibleKPISetting.get('kpiType')
                    )
                    .map((kpiTypes: ImmutableList<ImmutableKPISetting>) =>
                        this.getGenericValueToReactSelectObject(kpiTypes.first().get('kpiType'))
                    )
                    .toList()
                    .toJS();
            }
            if (options.length > 0) {
                kpiTypeContent = (
                    <InputSelect
                        selectedOption={this.getGenericValueToReactSelectObject(
                            kpiSetting.get('kpiType')
                        )}
                        options={options}
                        onSelect={this.handleChangeKPIType(kpiSetting, index)}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        renderString={this.props.readonly}
                        controlShouldRenderValue
                        maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                    />
                );
            }
        }
        return kpiTypeContent;
    };

    /**
     * Get the column-row content for the KPI's context.
     */
    getKPIContextColumn = (kpiSetting: ImmutableKPISetting) => {
        let kpiContextContent = (
            <InputField
                value={kpiSetting.get('context') || NO_RESULT_STRING}
                renderString={this.props.readonly}
                style={KPI_CONTEXT_INPUT_FIELD_STYLES}
                disabled
            />
        );
        if (!kpiSetting.get('id') && !kpiSetting.get('disableKpiContextInput')) {
            let options = [];
            if (this.props.possibleKPITypes && this.props.possibleKPITypes.size > 0) {
                options = this.props.possibleKPITypes
                    .filter(
                        (possibleKPISetting: ImmutableKPISetting) =>
                            possibleKPISetting.get('kpiType') === kpiSetting.get('kpiType')
                    )
                    .groupBy((possibleKPISetting: ImmutableKPISetting) =>
                        getKPISettingContextId(possibleKPISetting)
                    )
                    .map((possibleKPISettings: ImmutableList<ImmutableKPISetting>) => ({
                        value: getKPISettingContextId(possibleKPISettings.first()),
                        label: possibleKPISettings.first().get('context') || NO_RESULT_STRING,
                    }))
                    .toList();
                if (options.size > 0) {
                    options = options.toJS();
                }
            }
            if (options.length > 0) {
                kpiContextContent = (
                    <InputSelect
                        selectedOption={{
                            value: getKPISettingContextId(kpiSetting),
                            label: kpiSetting.get('context') || NO_RESULT_STRING,
                        }}
                        options={options}
                        onSelect={this.handleChangeKPIContext(kpiSetting)}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        renderString={this.props.readonly}
                        controlShouldRenderValue
                    />
                );
            }
        }
        return kpiContextContent;
    };

    provideInteractiveRow = (
        kpiSetting: ImmutableKPISetting,
        index: number,
        kpiSettingsCount: number,
        displayReorderControlsVertically: boolean
    ) => {
        let interactiveRow = Map()
            .set(
                'id',
                kpiSetting.get(
                    'key',
                    `${kpiSetting.get('specificityType')}-${kpiSetting.get('kpiType')}`
                )
            )
            .set(
                'order',
                this.renderOrderControls(
                    kpiSetting,
                    kpiSettingsCount,
                    displayReorderControlsVertically
                )
            )
            .set(
                'kpiType',
                <div style={KPI_TYPE_INPUT_FIELD_STYLES}>
                    {this.getKPITypeColumn(kpiSetting, index)}
                </div>
            )
            .set(
                'name',
                <InputField
                    value={kpiSetting.get('name')}
                    onChange={this.handleInputChange([index, 'name'])}
                    renderString={this.props.readonly}
                    style={KPI_TYPE_INPUT_FIELD_STYLES}
                    maxLength={KPI_TYPE_INPUT_FIELD_VALIDATION.maxCharacters}
                />
            )
            .set(
                'kpiUnit',
                <div style={KPI_CONTEXT_INPUT_FIELD_STYLES}>
                    {this.getKPIUnitColumn(kpiSetting, index)}
                </div>
            )
            .set(
                'validityRange',
                <div>
                    <InputBlock>
                        <InputBlockLabel>
                            {this.getTranslation('inputBlockLabels.min')}
                        </InputBlockLabel>
                        <InputField
                            onChange={this.handleInputChange([index, 'minValid'])}
                            value={formatNumberAsStringValue(kpiSetting.get('minValid'))}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                            renderString={this.props.readonly}
                            type="number"
                            min="0"
                            max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                        />
                    </InputBlock>
                    <InputBlock>
                        <InputBlockLabel>
                            {this.getTranslation('inputBlockLabels.max')}
                        </InputBlockLabel>
                        <InputField
                            onChange={this.handleInputChange([index, 'maxValid'])}
                            value={formatNumberAsStringValue(kpiSetting.get('maxValid'))}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                            renderString={this.props.readonly}
                            type="number"
                            min="0"
                            max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                        />
                    </InputBlock>
                </div>
            )
            .set(
                'precision',
                <FormInputWrapper>
                    <InputSelect
                        selectedOption={this.getGenericValueToReactSelectObject(
                            kpiSetting.get('precision')
                        )}
                        options={KPI_SETTINGS_PRECISION_OPTIONS.map((option: number) => ({
                            value: option,
                            label: option,
                        }))}
                        onSelect={this.handleInputSelectChange([index, 'precision'])}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        renderString={this.props.readonly}
                        controlShouldRenderValue
                    />
                </FormInputWrapper>
            )
            .set(
                'show',
                <Toggle
                    checked={kpiSetting.get('show')}
                    onClickHandler={this.handleToggleChange([index, 'show'])}
                    disabled={this.props.readonly}
                />
            )
            .set(
                'displayInPlant',
                <Toggle
                    checked={kpiSetting.get('displayInPlant')}
                    onClickHandler={this.handleToggleChange([index, 'displayInPlant'])}
                    disabled={this.props.readonly}
                />
            );

        if (this.shouldDisplayTargets(kpiSetting)) {
            interactiveRow = interactiveRow.set(
                'target',
                <div>
                    {kpiSetting.has(TARGET_TYPES.MAIN_TARGET) && (
                        <InputBlock>
                            <InputBlockLabel>
                                {this.getTranslation('inputBlockLabels.target')}
                            </InputBlockLabel>
                            <InputField
                                onChange={this.handleInputChange([index, TARGET_TYPES.MAIN_TARGET])}
                                value={formatNumberAsStringValue(
                                    kpiSetting.get(TARGET_TYPES.MAIN_TARGET)
                                )}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                                renderString={this.props.readonly}
                                type="number"
                                min="0"
                                max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                            />
                        </InputBlock>
                    )}
                    <InputBlock>
                        <InputBlockLabel>
                            {this.getTranslation('inputBlockLabels.min')}
                        </InputBlockLabel>
                        <InputField
                            onChange={this.handleInputChange([index, TARGET_TYPES.LOW_TARGET])}
                            value={formatNumberAsStringValue(
                                kpiSetting.get(TARGET_TYPES.LOW_TARGET)
                            )}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                            renderString={this.props.readonly}
                            type="number"
                            min="0"
                            max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                        />
                    </InputBlock>
                    <InputBlock>
                        <InputBlockLabel>
                            {this.getTranslation('inputBlockLabels.max')}
                        </InputBlockLabel>
                        <InputField
                            onChange={this.handleInputChange([index, TARGET_TYPES.HIGH_TARGET])}
                            value={formatNumberAsStringValue(
                                kpiSetting.get(TARGET_TYPES.HIGH_TARGET)
                            )}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                            renderString={this.props.readonly}
                            type="number"
                            min="0"
                            max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                        />
                    </InputBlock>
                </div>
            );
        }

        if (this.shouldDisplayIsRequired(kpiSetting)) {
            interactiveRow = interactiveRow.set(
                'isRequired',
                <Toggle
                    checked={kpiSetting.get('isRequired')}
                    onClickHandler={this.handleToggleChange([index, 'isRequired'])}
                    disabled={kpiSetting.get('isForcedRequire') || this.props.readonly}
                />
            );
        }

        if (this.shouldDisplayRecommendationLimits(kpiSetting)) {
            interactiveRow = interactiveRow
                .set(
                    'recommendationLimits',
                    <div>
                        <InputBlock>
                            <InputBlockLabel>
                                {this.getTranslation('inputBlockLabels.min')}
                            </InputBlockLabel>
                            <InputField
                                onChange={this.handleInputChange([index, 'minRecommend'])}
                                value={formatNumberAsStringValue(kpiSetting.get('minRecommend'))}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                                renderString={this.props.readonly}
                                type="number"
                                min="0"
                                max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                            />
                        </InputBlock>
                        <InputBlock>
                            <InputBlockLabel>
                                {this.getTranslation('inputBlockLabels.max')}
                            </InputBlockLabel>
                            <InputField
                                onChange={this.handleInputChange([index, 'maxRecommend'])}
                                value={formatNumberAsStringValue(kpiSetting.get('maxRecommend'))}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                                renderString={this.props.readonly}
                                type="number"
                                min="0"
                                max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                            />
                        </InputBlock>
                    </div>
                )
                .set(
                    'roundTo',
                    <InputField
                        onChange={this.handleInputChange([index, 'roundTo'])}
                        value={formatNumberAsStringValue(kpiSetting.get('roundTo'))}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={INPUT_BLOCK_NUMBER_INPUT_STYLES}
                        renderString={this.props.readonly}
                        type="number"
                        min="0"
                        max={KPI_TYPE_INPUT_FIELD_VALIDATION.maxNumber}
                    />
                );
        }

        if (this.shouldDisplayContexts(kpiSetting)) {
            interactiveRow = interactiveRow.set(
                'context',
                <div style={KPI_CONTEXT_INPUT_FIELD_STYLES}>
                    {this.getKPIContextColumn(kpiSetting)}
                </div>
            );
        }

        return interactiveRow;
    };

    getTableRows = (displayReorderControlsVertically: boolean) => {
        const { kpis } = this.props;

        return kpis
            .map((kpiSetting: ImmutableKPISetting, index: number) =>
                this.provideInteractiveRow(
                    kpiSetting,
                    index,
                    kpis.size,
                    displayReorderControlsVertically
                )
            )
            .toJS();
    };

    /**
     * Handles rendering of reorder & delete controls
     * Prevents removing of kpi setting if isRequired is true
     */
    renderOrderControls = (
        kpiSetting: ImmutableKPISetting,
        totalKPICount: number,
        displayVertically: boolean
    ) =>
        kpiSetting && (
            <ReorderControls
                currentOrder={kpiSetting.get('order')}
                itemCount={totalKPICount}
                onRemoveItem={
                    this.props.onRemoveKPI &&
                    !kpiSetting.get('isRequired') &&
                    !kpiSetting.get('isUndeletable')
                        ? this.props.onRemoveKPI(kpiSetting)
                        : null
                }
                onReorderItem={
                    totalKPICount > 1 && this.props.onReorderKPI
                        ? this.props.onReorderKPI(kpiSetting)
                        : null
                }
                displayVertically={displayVertically}
            />
        );

    render() {
        const header = this.getTableHeader();
        // Display the reorder/delete controls vertically if header has more than 6 items? Magic number
        const displayReorderControlsVertically = Boolean(header.length > 6);

        return (
            <TableContainer displayReorderControlsVertically={displayReorderControlsVertically}>
                <Table
                    header={this.getTableHeader()}
                    rows={this.getTableRows(displayReorderControlsVertically)}
                    tdVerticalAlign="top"
                    footerMessage={this.props.kpis.isEmpty() && this.props.noKPIMessage}
                />
            </TableContainer>
        );
    }
}

export default injectIntl(KPISetupTable);
