// @flow strict

import { fromJS } from 'immutable';

import { deleteEntityFromSubStateLists, setEntityIntoSubStateLists } from '../utils';

// Actions
import {
    FETCH_SUCCESS,
    FETCH_FAILURE,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    QUERY_MINCHEM_SUCCESS,
    QUERY_SOLVEXTRACT_SUCCESS,
    QUERY_MINCHEM_FAILURE,
    QUERY_SOLVEXTRACT_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DESTROY_SUCCESS,
    DESTROY_FAILURE,
    ELEVATION_SUCCESS,
    ELEVATION_FAILURE,
    IS_FETCHING,
    IS_UPDATING,
    IS_DELETING,
    IS_CREATING,
    IS_QUERYING_MINCHEM,
    IS_QUERYING_SOLVEXTRACT,
    IS_ELEVATING,
} from './actions';
import {
    FETCH_SUCCESS as DATASET_FETCH_SUCCESS,
    DESTROY_SUCCESS as DATASET_DESTROY_SUCCESS,
    CREATE_SUCCESS as DATASET_CREATE_SUCCESS,
    UPDATE_SUCCESS as DATASET_UPDATE_SUCCESS,
} from 'services/Dataset/actions';

// Types
import type { GenericActionType, ImmutableList } from 'types';
import type { ImmutableDataset } from 'services/Dataset/types';
import type { ImmutableCircuitState, ImmutableCircuit } from 'services/Circuit/types';
import { CIRCUIT_TYPES } from 'utils/constants';

const initialState: ImmutableCircuitState = fromJS({
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isQueryingMinChem: false,
    isQueryingSolvExtract: false,
    isElevating: false,
    queryMinChem: {
        data: [],
        currentPage: 0,
        lastPage: 0,
    },
    querySolvExtract: {
        data: [],
        currentPage: 0,
        lastPage: 0,
    },
    errors: {},
    fetchErrors: {},
    elevationErrors: {},
});

function addCircuitToState(
    state: ImmutableCircuitState,
    keyPath: Array<string>,
    newCircuit: ImmutableCircuit
) {
    return state.updateIn(keyPath, (circuits: ImmutableList<ImmutableCircuit>) => {
        const idx = circuits.findIndex(
            (circuit: ImmutableCircuit) => circuit.get('id') === newCircuit.get('id')
        );
        return idx !== -1 ? circuits.setIn([idx], newCircuit) : circuits.unshift(newCircuit);
    });
}

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function circuitServiceReducer(
    state: ImmutableCircuitState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);
        case IS_QUERYING_MINCHEM:
            return state.set('isQueryingMinChem', action.payload.isQueryingMinChem);
        case IS_QUERYING_SOLVEXTRACT:
            return state.set('isQueryingSolvExtract', action.payload.isQueryingSolvExtract);
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);
        case IS_DELETING:
            return state.set('isDeleting', action.payload.isDeleting);
        case IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);
        case IS_ELEVATING:
            return state.set('isElevating', action.payload.isElevating);
        case QUERY_MINCHEM_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('queryMinChem', fromJS(data || {}))
                .set('errors', fromJS({}))
                .set('isQueryingMinChem', false);
        }
        case QUERY_MINCHEM_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isQueryingMinChem', false);
        case QUERY_SOLVEXTRACT_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('querySolvExtract', fromJS(data || {}))
                .set('errors', fromJS({}))
                .set('isQueryingSolvExtract', false);
        }
        case QUERY_SOLVEXTRACT_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isQueryingSolvExtract', false);
        case FETCH_SUCCESS: {
            const circuit = fromJS(action.payload.data);

            let newState = state;
            if (circuit.get('type') === CIRCUIT_TYPES.MINCHEM_CIRCUIT) {
                newState = setEntityIntoSubStateLists(state, '', circuit, 'queryMinChem');
            } else {
                newState = setEntityIntoSubStateLists(state, '', circuit, 'querySolvExtract');
            }

            return newState
                .set('fetchErrors', fromJS({}))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        }
        case FETCH_FAILURE:
            return state
                .set('fetchErrors', fromJS(action.payload.errors))
                .set('errors', fromJS(action.payload.errors))
                .set('isFetching', false);
        case CREATE_SUCCESS: {
            const newCircuit = fromJS(action.payload.data);
            const newState = setEntityIntoSubStateLists(state, '', newCircuit, 'queryMinChem');

            return newState.set('errors', fromJS({})).set('isCreating', false);
        }
        case UPDATE_SUCCESS: {
            const updatedCircuit = fromJS(action.payload.data);
            const newState = setEntityIntoSubStateLists(state, '', updatedCircuit, 'queryMinChem');

            return newState.set('errors', fromJS({})).set('isUpdating', false);
        }
        case DESTROY_SUCCESS: {
            const deletedCircuit = fromJS(action.payload.data);
            const newState = deleteEntityFromSubStateLists(
                state,
                '',
                deletedCircuit,
                'queryMinChem'
            );

            return newState.set('errors', fromJS({})).set('isDeleting', false);
        }
        case CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);
        case UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isUpdating', false);
        case DESTROY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isDeleting', false);

        case DATASET_FETCH_SUCCESS:
        case DATASET_UPDATE_SUCCESS:
        case DATASET_CREATE_SUCCESS: {
            const updatedCircuit = fromJS(action.payload.data.circuit);
            let newState = state;
            if (updatedCircuit.get('type') === CIRCUIT_TYPES.MINCHEM_CIRCUIT) {
                newState = setEntityIntoSubStateLists(state, '', updatedCircuit, 'queryMinChem');
            }
            return newState;
        }

        case DATASET_DESTROY_SUCCESS: {
            const deletedDataset = action.payload.data;
            const datasetFilterer = (circuits: ImmutableList<ImmutableCircuit>) =>
                // update the circuits with the dataset.
                circuits.map((circuit: ImmutableCircuit) =>
                    circuit.updateIn(['datasets'], (datasets: ImmutableList<ImmutableDataset>) =>
                        datasets.filter(
                            (dataset: ImmutableDataset) =>
                                `${dataset.get('id')}` !== deletedDataset.id
                        )
                    )
                );
            return state.updateIn(['queryMinChem', 'data'], datasetFilterer);
        }

        case ELEVATION_SUCCESS: {
            const newCircuit = fromJS(action.payload.data);

            // Delete circuit from MinChem lists
            const statePostDeleteMinChemCircuit = deleteEntityFromSubStateLists(
                state,
                '',
                newCircuit,
                'queryMinChem'
            );

            // Set circuit into SolvExtract list
            return addCircuitToState(
                statePostDeleteMinChemCircuit,
                ['querySolvExtract', 'data'],
                newCircuit
            )
                .set('isElevating', false)
                .set('elevationErrors', fromJS({}));
        }

        case ELEVATION_FAILURE: {
            return state
                .set('isElevating', false)
                .set('elevationErrors', fromJS(action.payload.errors));
        }

        default:
            return state;
    }
}

export default circuitServiceReducer;
