type UA_PARAMS = {
    moduleName: string, 
    searchKeyword?: string, 
    searchCount?: number, 
    userName?: string,
    circuit?: string,
    plant?: string,
    kpi?: string,
    report?: string
}

type PIWIK_PRO_PARAMS = {
    dimension1?: string;
    dimension2?: string;
    dimension3?: string;
    dimension4?: string;
    dimension5?: string;
}

export const logUserAnalyticsInteraction = (
    piwikParams: UA_PARAMS
) => {
    try {
        let piwikData: PIWIK_PRO_PARAMS = {};
        piwikData.dimension1 = piwikParams.userName;
        if (piwikParams.circuit) 
            piwikData.dimension2 = piwikParams.circuit;
        if (piwikParams.plant) 
            piwikData.dimension3 = piwikParams.plant;
        if (piwikParams.kpi) 
            piwikData.dimension4 = piwikParams.kpi;
        if (piwikParams.report) 
            piwikData.dimension5 = piwikParams.report;
        _paq?.push(['trackSiteSearch', piwikParams.moduleName, piwikParams.searchKeyword, 
        piwikParams.searchCount, piwikData]);
    } catch(ex) {
    }
}
