// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

import { DefaultScrollableContent } from 'styles/common';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 0px 24px 0px 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px;

    border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;

    & + & {
        margin-top: 31px;
    }
`;

export const Label = styled.label`
    font-size: 12px;
    line-height: 17px;
    color: ${LegacyTheme.black};
    margin-bottom: 7px;

    & > label {
        margin-left: 5px;
    }
`;

export const OptionalLabel = styled(Label)`
    color: ${LegacyTheme.defaultSubtleColor};
`;

export const RadioStyles = {
    labelFontSize: '14px',
    labelColor: LegacyTheme.defaultColor,
    labelMinWidth: '50px',
};

export const InputStyles = {
    valueContainerFontSize: '16px',
};

export const ImageStyle = {
    width: '310px',
    height: '116px',
};

export const InputSelectWrapper = styled.div`
    margin-left: 8px;
    width: 112px;
    margin-top: -8px;
    margin-bottom: -8px;
`;

export const UserListWrapper = styled.div`
    max-height: 150px;
    width: 100%;

    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 5px;

    & > :first-child {
        margin-top: 0;
    }

    ${DefaultScrollableContent}
`;

export const UserListRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 5px;

    border-bottom: 1px solid ${LegacyTheme.defaultBorder};

    &:last-child {
        border-bottom: none;
    }

    > p {
        overflow-wrap: break-word;
        overflow: wrap;
        margin: 0;
        font-size: 12px;
        color: ${LegacyTheme.defaultColor};
    }
`;

export const DeleteButtonWrapper = styled.div`
    color: ${LegacyTheme.defaultBorder};

    &:last-child {
        margin-left: 5px;
    }
    &:first-child {
        margin-right: 5px;
    }

    cursor: pointer;

    &:hover {
        color: ${LegacyTheme.buttonPrimaryHover};
    }

    transition: color 0.2s ease-in-out;
`;
