// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';
import { StyledInputBase } from '../input-field';

// Assets
import search from './assets/Search/search.png';
import searchClear from './assets/Search/clear.png';

export const InputWrapper = styled.div`
    ${StyledInputBase}
    display: flex;
    padding: 0;
`;

export const InnerInput = styled.input`
    width: 100%;
    padding: 5px 16px;

    border: 0 none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${({ disabled }: Object) =>
            disabled ? legacyTheme.disabledPlaceholder : legacyTheme.disabledInputColor};
    }
`;

export const SearchButton = styled.button`
    display: flex;
    flex: 0;

    min-width: 30px;

    border: 0 none;
    border-left: 1px solid ${legacyTheme.defaultBorder};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    background-color: ${legacyTheme.searchButtonBackground};

    cursor: ${({ onClick }: Object) => (onClick ? 'pointer' : 'default')};

    background-image: url(${({ withSearch }: Object) => (withSearch ? searchClear : search)});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &:focus {
        outline: none;
    }
`;
