/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { DefaultScrollableContent } from 'styles/common';
import { STYLE_VALUES } from 'utils/constants';
import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    ${DefaultScrollableContent}

    padding: ${STYLE_VALUES.GUTTERS.NORMAL} ${STYLE_VALUES.GUTTERS.LARGE};

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: ${STYLE_VALUES.GUTTERS.NORMAL} ${STYLE_VALUES.GUTTERS.NORMAL} ${
    STYLE_VALUES.RESPONSIVE_SIDEBAR.COLLAPSED_HEIGHT
};
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 15px;
`;

export const Dot = styled.span`
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: black;
`;

export const Label = styled.span`
    display: flex;
`;

export const Statuses = styled.div`
    display: flex;
`;

export const Status = styled.div`
    display: flex;
    align-items: center;

    color: ${(props: Object) => props.color};

    ${Dot} {
        margin-right: 10px;
        background-color: ${(props: Object) => props.color};
    }

    & + & {
        margin-left: 24px;
    }
`;

export const IconsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0 10px;
`;

export const IconWrapper = styled.div`
    border: ${({ active }: Object) => (active ? `1px solid ${LegacyTheme.defaultBorder}` : 'none')};
    border-radius: 5px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ active }: Object) =>
        active ? LegacyTheme.defaultSubtleColorFA : LegacyTheme.defaultBackground};
    cursor: ${({ active }: Object) => (active ? 'default' : 'pointer')};

    svg {
        fill: ${({ active }: Object) =>
            active ? LegacyTheme.defaultColor : LegacyTheme.defaultSubtleColor};

        &:hover {
            fill: ${LegacyTheme.defaultColor};
        }
    }
`;
