// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Helpers & Constants
import {
    LegacyTheme,
    InputSelect,
    InputNumber,
    RadioButtonSet,
    PrimaryButton,
    Table,
    NavigationChevron,
    CheckBox,
} from 'components/_ReactUI_V1';

import { clamp, getValueAsString, tryParseNumberOrNull } from 'utils/helpers';
import { isSolvayUser } from 'utils/authentication';
import {
    ISOTHERM_BASE_CONSTANT,
    ISOTHERM_POLYNOMIAL_TYPE,
    CREATE_ISOTHERM_DATAPOINTS_COUNT,
    ISOTHERM_DATAPOINTS_TYPE,
    STAGE_TYPES,
    ISOTHERM_SIDEBAR_TYPES,
    NUMBER_INPUT_PLACEHOLDER,
    DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR,
} from 'utils/constants';

// Types
import type {
    IntlType,
    ImmutableList,
    ReactSelectObject,
    InputEvent,
    LooseNumberType,
} from 'types';
import type { ImmutableMetal } from 'services/Metal/types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type {
    IsothermOption,
    DataPoint,
} from 'containers/IsothermManagementContainer/IsothermsContainer';
import type {
    IsothermBaseConstant,
    IsothermPolynomialType,
    IsothermDataPointsType,
    IsothermSidebarTypes,
    ImmutableIsotherm,
} from 'services/Isotherm/types';
import type { ImmutableOxime } from 'services/Oxime/types';

// Components
import ReagentSelectBody from 'components/ReagentSelectBody';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';
import IsothermStoichiometryInput from 'components/IsothermStoichiometryInput';

// Styles
import {
    SelectField,
    Label,
    SectionTitle,
    SidebarBody,
    BodySection,
    OverflowBody,
    WrapperInputNumber,
    WrapperMultipleInput,
    WrapperPlsTenor,
    Wrapper,
} from '../styles';
import {
    IsothermTypeRadioStyles,
    SectionWrapper,
    HeaderTitleWrapper,
    HeaderTitle,
    HeaderRadioWrapper,
    StickyFooterSection,
    InputNote,
} from './styles';

const MAXIMUMS = {
    PLS_CU: 100,
    PLS_PH: 100,
    LEAN_CU: 100,
    LEAN_ACID: 500,
    REAGENT_CONCENTRATION: 100, // its a percent max 100
    OXIME_GPL: 500,
    OXIME_RATIO: 100,
};

type InputSateKeyType =
    | 'reagentConcentration'
    | 'oximeRatio'
    | 'oximeGpl'
    | 'plsCu'
    | 'plsPh'
    | 'electrolyteSpent'
    | 'electrolyteAdvance'
    | 'selectedIsothermPolynomialOrder';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    activeMode: IsothermSidebarTypes,
    readOnly: boolean,

    metals: ImmutableList<ImmutableMetal>,
    loadingMetals: boolean,

    reagents: ImmutableList<ImmutableReagent>,
    oximes: ImmutableList<ImmutableOxime>,
    loadingReagents: boolean,
    loadingOximes: boolean,

    // when updating this is available
    isothermData: ?ImmutableIsotherm, // nullable
    loadingPredictOrCreate: boolean,

    handlePredictOrCreateClicked: (
        activeMode: IsothermSidebarTypes,
        isothermOption: IsothermOption
    ) => void,
    handleReturnToVisualizeClicked: () => void,
};

type State = {
    selectedIsothermType: IsothermBaseConstant,
    selectedMetal: ImmutableMetal,
    selectedReagent: ?ImmutableReagent,
    selectedOxime: ?ImmutableOxime,
    reagentConcentration: ?number,
    oximeRatio: ?number,
    oximeGpl: ?number,

    plsCu: ?number, // if isotherm type === EXTRACT
    plsPh: ?number,
    isothermStoichiometryFactor: ?number,

    electrolyteSpent: ?number, // if isotherm type === STRIP
    electrolyteAdvance: ?number,

    selectedIsothermPolynomialType: IsothermPolynomialType,
    selectedIsothermPolynomialOrder: ?number,
    referenceDataPoints: ?Array<DataPoint>,

    isModified: boolean,
};

const DEFAULT_INPUT_NUMBER_STYLES = {
    height: '30px',
    textAlign: 'center',
};

const MIN_DATA_POINTS_FOR_POLYNOMIAL_ORDER = 2;
const MAX_POLYNOMIAL_ORDER = 4;

export const MIN_POLYNOMIAL_ORDER = {
    [ISOTHERM_POLYNOMIAL_TYPE.MINCHEM]: 0,
    [ISOTHERM_POLYNOMIAL_TYPE.SIMPLE]: 0,
    [ISOTHERM_POLYNOMIAL_TYPE.RATIONAL]: 1,
};

/**
 * Isotherm predict and create sidebar section
 */
class IsothermsDataSidebarSection extends React.PureComponent<Props, State> {
    /**
     * Convert an immutable metal into a drop down select object.
     */
    static metalToReactSelectObject = (metal: ImmutableMetal): ReactSelectObject => ({
        value: metal.get('id'),
        label: `${metal.get('name')} (${metal.get('symbol')})`,
    });

    constructor(props: Props) {
        super(props);

        this.state = this.getDefaultState();
    }

    /**
     * Preselect the metal option.
     * When our props updates, check to see if they are different then update our state depending
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        // When we receive metals, select the first one.
        if (prevProps.metals.isEmpty() && !this.props.metals.isEmpty()) {
            this.setState({
                selectedMetal: this.props.metals.first(),
            });
        }
        // When we receive reagents, select the first one.
        if (prevProps.reagents.isEmpty() && !this.props.reagents.isEmpty()) {
            const selectedReagent = this.props.reagents.first();
            // if there is no reagent select the first oxime.
            const selectedOxime =
                !selectedReagent && !this.props.oximes.isEmpty() && this.props.oximes.first();
            this.setState({
                selectedReagent,
                selectedOxime,
            });
        }
        if (
            this.props.isothermData &&
            (!prevProps.isothermData ||
                prevProps.isothermData.get('id') !== this.props.isothermData.get('id'))
        ) {
            this.setState({
                selectedReagent: this.props.reagents.find(
                    (reagent: ImmutableReagent) =>
                        reagent.get('id') === this.props.isothermData.get('reagentId')
                ),
                selectedOxime: this.props.oximes.find(
                    (oxime: ImmutableOxime) =>
                        oxime.get('id') === this.props.isothermData.get('oximeId')
                ),
            });
        }
        if (prevProps.loadingPredictOrCreate && !this.props.loadingPredictOrCreate) {
            this.setState({
                isModified: false,
            });
        }
    }

    /**
     * Get the default isotherm stoichiometry value
     */
    getDefaultIsothermStoichiometryValue = () =>
        this.props.user
            ? this.props.user.getIn(
                  ['preferences', 'minchem', 'defaultIsothermStoichiometryFactor'],
                  DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR
              )
            : DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR;

    /**
     * Get the default state
     */
    getDefaultState = (): State => {
        if (
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.PREDICT ||
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.CREATE ||
            !this.props.isothermData
        ) {
            const selectedReagent = !this.props.reagents.isEmpty() && this.props.reagents.first();
            // if there is no reagent select the first oxime.
            const selectedOxime =
                !selectedReagent && !this.props.oximes.isEmpty() && this.props.oximes.first();
            return {
                selectedMetal: !this.props.metals.isEmpty() && this.props.metals.first(),
                selectedReagent,
                selectedOxime,
                selectedIsothermType: STAGE_TYPES.EXTRACT,
                reagentConcentration: null,
                oximeRatio: null,
                oximeGpl: null,

                plsCu: null,
                plsPh: null,
                isothermStoichiometryFactor:
                    this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.PREDICT
                        ? this.getDefaultIsothermStoichiometryValue()
                        : null,

                electrolyteSpent: null,
                electrolyteAdvance: null,
                selectedIsothermPolynomialType: ISOTHERM_POLYNOMIAL_TYPE.MINCHEM,
                selectedIsothermPolynomialOrder: null,
                referenceDataPoints: this.getDefaultDataPoints(STAGE_TYPES.EXTRACT),

                isModified: false,
            };
        } else {
            // Update an isotherm.
            const isoData = this.props.isothermData;
            return {
                selectedMetal: this.props.metals.find(
                    (metal: ImmutableMetal) => metal.get('id') === isoData.get('metalId')
                ),
                selectedReagent: this.props.reagents.find(
                    (reagent: ImmutableReagent) => reagent.get('id') === isoData.get('reagentId')
                ),
                selectedOxime: this.props.oximes.find(
                    (oxime: ImmutableOxime) => oxime.get('id') === isoData.get('oximeId')
                ),
                selectedIsothermType: isoData.get('isothermType'),
                reagentConcentration: isoData.get('reagentConcentration'),
                oximeRatio: isoData.get('oximeRatio'),
                oximeGpl: isoData.get('oximeGpl'),

                plsCu: isoData.get('plsCu'),
                plsPh: isoData.get('plsPh'),
                isothermStoichiometryFactor: isoData.get('isothermStoichiometryFactor'),
                electrolyteSpent: isoData.get('spentMetal'),
                electrolyteAdvance: isoData.get('spentAcid'),
                selectedIsothermPolynomialType: isoData.get('polynomialType'),
                selectedIsothermPolynomialOrder: this.getIsothermPolynomialOrder(isoData),
                referenceDataPoints: this.getIsothermDataPoints(isoData),

                isModified: false,
            };
        }
    };

    /**
     * Convert an isotherm constant into a drop down select object.
     */
    isothermModelToReactSelectObject = (
        selectedIsothermPolynomialType: IsothermPolynomialType
    ): ?ReactSelectObject =>
        this.state.selectedIsothermPolynomialType
            ? {
                  value: selectedIsothermPolynomialType,
                  label: this.props.intl.formatMessage({
                      id: `components.IsothermManagementSidebar.CreatePredictIsotherms.curveFittingSection.modelType.${
                          ISOTHERM_POLYNOMIAL_TYPE[selectedIsothermPolynomialType]
                      }`,
                  }),
              }
            : null;

    /**
     * Get the default data points
     */
    getDefaultDataPoints = (isothermType: IsothermBaseConstant): Array<DataPoint> => {
        const dataPointsInitial = [];
        if (isothermType === STAGE_TYPES.EXTRACT) {
            dataPointsInitial.push({
                aqueousConcentration: 0,
                organicConcentration: 0,
                type: 'REFERENCE',
            });
        }
        const start = STAGE_TYPES.EXTRACT ? 1 : 0;
        for (let i = start; i < CREATE_ISOTHERM_DATAPOINTS_COUNT; i++) {
            dataPointsInitial.push({
                aqueousConcentration: null,
                organicConcentration: null,
                type: 'REFERENCE',
            });
        }
        return dataPointsInitial;
    };

    /**
     * Get the current data points
     */
    getCurrentDataPoints = (
        referenceDataPoints: Array<DataPoint>,
        isothermType: IsothermBaseConstant
    ): Array<DataPoint> => {
        const dataPointsCurrent = referenceDataPoints;
        if (isothermType === STAGE_TYPES.EXTRACT) {
            dataPointsCurrent.unshift({
                aqueousConcentration: 0,
                organicConcentration: 0,
                type: 'REFERENCE',
            });
        } else {
            // remove first row
            dataPointsCurrent.shift();
        }
        return dataPointsCurrent;
    };

    /**
     * Get the isotherm data points when updating an isotherm
     */
    getIsothermDataPoints = (isothermData: ImmutableIsotherm) => {
        const dataPointsInitial = isothermData
            .get('referenceDataPoints')
            .toJS()
            .map((isoDataPoint: DataPoint) => ({
                id: isoDataPoint.id,
                aqueousConcentration: parseFloat(isoDataPoint.aqueousConcentration),
                organicConcentration: parseFloat(isoDataPoint.organicConcentration),
                type: isoDataPoint.type,
            }));
        for (let i = dataPointsInitial.length; i < CREATE_ISOTHERM_DATAPOINTS_COUNT; i++) {
            dataPointsInitial.push({
                aqueousConcentration: null,
                organicConcentration: null,
                type: 'REFERENCE',
            });
        }
        return dataPointsInitial;
    };

    /**
     * Get the isotherm polynomial order when updating an isotherm
     */
    getIsothermPolynomialOrder = (isothermData: ImmutableIsotherm) => {
        if (
            [
                ISOTHERM_POLYNOMIAL_TYPE.MINCHEM,
                ISOTHERM_POLYNOMIAL_TYPE.SIMPLE,
                ISOTHERM_POLYNOMIAL_TYPE.RATIONAL,
            ].includes(isothermData.get('polynomialType'))
        ) {
            return isothermData.get('coefficients').size - 1;
        }
        return null;
    };

    /**
     * Get all possible metal options in the drop down.
     */
    getMetalOptions = (): Array<ReactSelectObject> =>
        this.props.metals.map(IsothermsDataSidebarSection.metalToReactSelectObject).toArray();

    /**
     * Get all possible isotherm model options in the drop down.
     */
    getIsothermModelOptions = (): Array<ReactSelectObject> =>
        Object.keys(ISOTHERM_POLYNOMIAL_TYPE).map((isothermModel: IsothermPolynomialType) =>
            this.isothermModelToReactSelectObject(isothermModel)
        );

    /**
     * Get maximum polynomial order as per specs depending on polynomial type:
     * MinChem Polynomial <= 4, Simple Polynomial <= 4,
     * Rational Polynomial: < number of data points rows with data
     */
    getMaxPolynomialOrder = (dataPoints: Array<DataPoint> = this.state.referenceDataPoints) => {
        switch (this.state.selectedIsothermPolynomialType) {
            case ISOTHERM_POLYNOMIAL_TYPE.MINCHEM:
            case ISOTHERM_POLYNOMIAL_TYPE.SIMPLE:
                return MAX_POLYNOMIAL_ORDER;
            case ISOTHERM_POLYNOMIAL_TYPE.RATIONAL:
                if (this.getDataPoints(dataPoints).length > 0) {
                    return this.getDataPoints(dataPoints).length - 1;
                } else {
                    return 0;
                }
            default:
                return 0;
        }
    };

    /**
     * Get minimum polynomial order as per specs depending on polynomial type:
     * MinChem Polynomial >= 0, Simple Polynomial >= 0, Rational Polynomial: >= 1
     */
    getMinPolynomialOrder = () =>
        MIN_POLYNOMIAL_ORDER[this.state.selectedIsothermPolynomialType] || 0;

    /**
     * Get the referenceDataPoints in the table. If active mode is create, get the points from our state.
     * Otherwise, return no referenceDataPoints for predicting.
     */
    getDataPoints = (dataPoints: Array<DataPoint> = this.state.referenceDataPoints) => {
        if (
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.CREATE ||
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            return dataPoints
                .filter(
                    // Filter points where both points are null
                    (dataPoint: DataPoint) =>
                        !(
                            dataPoint.aqueousConcentration === null &&
                            dataPoint.organicConcentration === null
                        )
                )
                .map((dataPoint: DataPoint) => ({
                    // what remains are points where one point could be null (or both aren't null.)
                    ...dataPoint,
                    aqueousConcentration: dataPoint.aqueousConcentration || 0,
                    organicConcentration: dataPoint.organicConcentration || 0,
                }));
        }
        return [];
    };

    /**
     * Get the title of the sidebar
     */
    getSidebarTitle = () => {
        if (
            this.props.activeMode !== ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT &&
            this.props.activeMode !== ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            return this.props.intl.formatMessage({
                id: `components.IsothermManagementSidebar.CreatePredictIsotherms.${this.props.activeMode.toLowerCase()}Isotherms.Title`,
            });
        }
        return this.props.isothermData.get('name');
    };

    /**
     * Check if all data point sets of aqueous(PLS) and organic concentration are complete sets without nulls
     */
    isDataPointSetComplete = (): boolean =>
        !this.state.referenceDataPoints.find(
            (dataPoint: DataPoint) =>
                (dataPoint.aqueousConcentration === null &&
                    dataPoint.organicConcentration !== null) ||
                (dataPoint.organicConcentration === null && dataPoint.aqueousConcentration !== null)
        );

    /**
     * Are all the fields filled to enable the visualize button
     */
    isVisualizeButtonEnabled = () => {
        if (this.props.readOnly) {
            return false;
        }

        // Validate pls and electrolyte options
        if (this.state.selectedIsothermType === ISOTHERM_BASE_CONSTANT.EXTRACT) {
            if (!this.state.plsCu || !this.state.plsPh) {
                return false;
            }
        } else {
            if (!this.state.electrolyteSpent || !this.state.electrolyteAdvance) {
                return false;
            }
        }

        // Validate polynomial type and data points
        if (
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.CREATE ||
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            if (!this.state.selectedIsothermPolynomialType) {
                return null;
            }
            if (this.getDataPoints().length > 1) {
                if (
                    this.state.selectedIsothermPolynomialOrder === null ||
                    this.state.selectedIsothermPolynomialOrder > this.getMaxPolynomialOrder() ||
                    this.state.selectedIsothermPolynomialOrder < this.getMinPolynomialOrder()
                ) {
                    return false;
                }
            }
            // Check if data points sets are complete when creating/updating isotherm
            if (!this.isDataPointSetComplete()) {
                return false;
            }
        }

        // Validate reagent options
        if (this.state.selectedReagent) {
            if (!this.state.reagentConcentration) {
                return false;
            }
        } else {
            if (!this.state.oximeRatio || !this.state.oximeGpl) {
                return false;
            }
        }

        return true;
    };

    /**
     * Handle the selection of a metal.
     */
    handleMetalSelected = (selectedOption: ReactSelectObject) =>
        !this.props.readOnly &&
        this.setState({
            selectedMetal: this.props.metals.find(
                (metal: ImmutableMetal) => metal.get('id') === selectedOption.value
            ),
            isModified: true,
        });

    /**
     * Handle the selection of a reagent.
     */
    handleReagentSelected = (selectedReagent: ImmutableReagent) =>
        !this.props.readOnly &&
        this.setState({
            selectedReagent,
            selectedOxime: null,
            isModified: true,
        });

    /**
     * Handle the selection of an oxime.
     */
    handleOximeSelected = (selectedOxime: ImmutableOxime) =>
        !this.props.readOnly &&
        this.setState({
            selectedOxime,
            selectedReagent: null,
            isModified: true,
        });

    /**
     * Handle the change in radio button extract or strip isotherm type.
     */
    handleIsothermTypeSelected = (selectedIsothermType: IsothermBaseConstant) =>
        !this.props.readOnly &&
        this.setState((prevState: State) => {
            // get the new data points
            const referenceDataPoints = this.getCurrentDataPoints(
                prevState.referenceDataPoints,
                selectedIsothermType
            );
            // make sure our order is still ok
            const maxPolynomialOrder = this.getMaxPolynomialOrder(referenceDataPoints);
            const prevOrder = prevState.selectedIsothermPolynomialOrder;
            const selectedIsothermPolynomialOrder =
                prevOrder && prevOrder > maxPolynomialOrder ? maxPolynomialOrder : prevOrder;
            return {
                selectedIsothermType,
                referenceDataPoints,
                selectedIsothermPolynomialOrder,
                isModified: true,
            };
        });

    /**
     * Handle the selection of a polynomial type for the isotherm model curve fitting
     */
    handleIsothermModelSelected = (selectedOption: ReactSelectObject) =>
        !this.props.readOnly &&
        this.setState({
            selectedIsothermPolynomialType: selectedOption.value,
            isModified: true,
        });

    /**
     * Update local state with the new isotherm stociometry factor provided
     * @param {number} isothermStoichiometryFactor The new value of the isotherm stoichiometry factor
     */
    handleChangeIsothermStoichiometryFactor = (isothermStoichiometryFactor: number) =>
        !this.props.readOnly &&
        this.setState({
            isothermStoichiometryFactor,
            isModified: true,
        });

    /**
     * Handle the change in range start or finish.
     */
    handleInputChange = (stateKey: InputSateKeyType) => (event: InputEvent) => {
        if (this.props.readOnly) {
            return false;
        }

        const value = event.target.value === '' ? null : event.target.value;

        this.setState({
            [stateKey]: value,
            isModified: true,
        });
    };

    /**
     * Handle the on blur in range start or finish.
     */
    handleOnBlurInput = (stateKey: InputSateKeyType) => (event: InputEvent) => {
        if (this.props.readOnly) {
            return false;
        }
        const { value, min, max } = event.target;
        const clampedValue = value !== '' ? clamp(value, min, max).toString() : null;
        this.setState({
            [stateKey]: clampedValue,
            isModified: true,
        });
    };

    /**
     * Handle the change of a dataPoint value
     */
    handleDataPointChange = (index: number, type: IsothermDataPointsType) => (
        event: InputEvent
    ) => {
        if (this.props.readOnly) {
            return false;
        }
        const dataPoints = [...this.state.referenceDataPoints];
        const value = event.target.value === '' ? null : event.target.value;
        if (type === ISOTHERM_DATAPOINTS_TYPE.AQUEOUS_CONCENTRATION) {
            dataPoints[index].aqueousConcentration = value;
        } else {
            dataPoints[index].organicConcentration = value;
        }
        this.setState({
            referenceDataPoints: dataPoints,
            isModified: true,
        });
    };

    /**
     * Handle on blur of a dataPoint value
     */
    handleOnBlurDataPoint = (index: number, type: IsothermDataPointsType) => (
        event: InputEvent
    ) => {
        if (this.props.readOnly) {
            return false;
        }
        const dataPoints = [...this.state.referenceDataPoints];
        const value =
            event.target.value !== ''
                ? clamp(event.target.value, event.target.min, event.target.max)
                : null;

        if (type === ISOTHERM_DATAPOINTS_TYPE.AQUEOUS_CONCENTRATION) {
            dataPoints[index].aqueousConcentration = value;
        } else {
            dataPoints[index].organicConcentration = value;
        }
        this.setState({
            referenceDataPoints: dataPoints,
            isModified: true,
        });
    };

    handlePaste = (index: number, pointType: IsothermDataPointsType) => (e) => {
        if (this.props.readOnly) {
            return false;
        }
        // get the clipboard data.
        const data = e.clipboardData.getData('text/plain');
        const rows = data.split('\n'); // rows are seperated by new lines.
        const pastedDataPoints = rows.map((row: string) => {
            // cells are seperated by tabs.
            const cells = row.split('\t').map((cell: string) => {
                const number = tryParseNumberOrNull(cell);
                // try to parse the number, and min/max it to the values.
                return number !== null ? clamp(number, e.target.min, e.target.max) : null;
            });
            const newEntry = {
                type: 'REFERENCE',
            };
            if (cells.length === 0) {
                return newEntry;
            }

            const primaryKey =
                this.state.selectedIsothermType === STAGE_TYPES.EXTRACT
                    ? 'aqueousConcentration'
                    : 'organicConcentration';
            const secondaryKey =
                this.state.selectedIsothermType === STAGE_TYPES.STRIP
                    ? 'aqueousConcentration'
                    : 'organicConcentration';
            const primaryPointType =
                this.state.selectedIsothermType === STAGE_TYPES.EXTRACT
                    ? 'AQUEOUS_CONCENTRATION'
                    : 'ORGANIC_CONCENTRATION';
            // if there are more than 1 cells pasted, get the proper table column to put it in
            if (pointType === primaryPointType) {
                newEntry[primaryKey] = tryParseNumberOrNull(cells[0]);
                if (cells.length > 1) {
                    newEntry[secondaryKey] = tryParseNumberOrNull(cells[1]);
                }
            } else {
                newEntry[secondaryKey] = tryParseNumberOrNull(cells[0]);
            }

            return newEntry;
        });
        e.preventDefault(); // stop the paste from continuing since we handled it ourselves.
        this.setState((prevState: State) => ({
            referenceDataPoints: prevState.referenceDataPoints.map(
                (dataPoint: DataPoint, stateIndex: number) => {
                    if (stateIndex < index || stateIndex > index + rows.length) {
                        return dataPoint; // do not modify the data point if it falls outside of the range of the paste selection.
                    }
                    const pastedDataPoint = pastedDataPoints[stateIndex - index];
                    return {
                        // spread the original data point that was at this location to preserve ids.
                        ...dataPoint,
                        ...pastedDataPoint,
                    };
                }
            ),
            isModified: true,
        }));
    };

    /**
     * Handle the visualize button click.
     */
    handleVisualizeClicked = () =>
        !this.props.readOnly &&
        this.props.handlePredictOrCreateClicked(this.props.activeMode, {
            name: this.props.isothermData ? this.props.isothermData.get('name') : '',
            selectedIsothermType: this.state.selectedIsothermType,
            selectedReagent: this.state.selectedReagent,
            selectedMetal: this.state.selectedMetal,
            selectedOxime: this.state.selectedOxime,
            reagentConcentration: this.state.reagentConcentration || 0,
            oximeRatio: this.state.oximeRatio || 0,
            oximeGpl: this.state.oximeGpl || 0,
            plsCu: this.state.plsCu || 0,
            plsPh: this.state.plsPh || 0,
            isothermStoichiometryFactor: this.state.isothermStoichiometryFactor || null,
            electrolyteSpent: this.state.electrolyteSpent || 0,
            electrolyteAdvance: this.state.electrolyteAdvance || 0,
            selectedIsothermPolynomialOrder: this.state.selectedIsothermPolynomialOrder,
            selectedIsothermPolynomialType: this.state.selectedIsothermPolynomialType,
            referenceDataPoints: this.getDataPoints(),
        });

    /**
     * Renders the sharing options. Only accessible to PM users.
     */
    renderSharingOptions = () => {
        if (isSolvayUser(this.props.user)) {
            // perhaps SAMs/admins can have personalized messages?
            return null;
        }
        if (!this.props.isothermData) {
            // was never saved
            return null;
        }
        const sharingAccess = this.props.isothermData.get('sharingAccess');
        if (!sharingAccess) {
            // Never shared, don't show anything.
            // this means the user owns this isotherm.
            return null;
        }
        return (
            <SectionWrapper>
                <SectionTitle>
                    {this.props.intl.formatMessage({
                        id: `components.IsothermManagementSidebar.CreatePredictIsotherms.sharingSection.title`,
                    })}
                </SectionTitle>
                <Label>
                    {this.props.intl.formatMessage(
                        {
                            id: `components.IsothermManagementSidebar.CreatePredictIsotherms.sharingSection.pmNotice`,
                        },
                        {
                            samName: sharingAccess.get('sharedByName'),
                        }
                    )}
                </Label>
            </SectionWrapper>
        );
    };

    renderExtractOptions = () => (
        <SectionWrapper>
            <SectionTitle>
                {this.props.intl.formatMessage({
                    id: `components.IsothermManagementSidebar.CreatePredictIsotherms.extractSection.Title`,
                })}
            </SectionTitle>
            <WrapperMultipleInput>
                <WrapperInputNumber>
                    <Label>
                        {this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.extractSection.plsCuLabel',
                        })}
                    </Label>
                    <WrapperPlsTenor>
                        <InputNumber
                            max={MAXIMUMS.PLS_CU}
                            min={0}
                            onChange={this.handleInputChange('plsCu')}
                            onBlur={this.handleOnBlurInput('plsCu')}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={DEFAULT_INPUT_NUMBER_STYLES}
                            value={this.state.plsCu || ''}
                            width="100px"
                            padding="0 5px 0 5px" // no spinner requires custom padding, otherwise
                            disabled={this.props.readOnly}
                            noSpinner
                        />
                        <Label>gpl</Label>
                    </WrapperPlsTenor>
                </WrapperInputNumber>
                <WrapperInputNumber>
                    <Label>
                        {this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.extractSection.phLabel',
                        })}
                    </Label>
                    <InputNumber
                        max={MAXIMUMS.PLS_PH}
                        min={0}
                        onChange={this.handleInputChange('plsPh')}
                        onBlur={this.handleOnBlurInput('plsPh')}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={DEFAULT_INPUT_NUMBER_STYLES}
                        value={this.state.plsPh || ''}
                        width="100px"
                        padding="0 5px 0 5px" // no spinner requires custom padding, otherwise
                        disabled={this.props.readOnly}
                        noSpinner
                    />
                </WrapperInputNumber>
            </WrapperMultipleInput>
            {(this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.PREDICT ||
                this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT) && (
                <IsothermStoichiometryInput
                    user={this.props.user}
                    isothermStoichiometryFactor={this.state.isothermStoichiometryFactor}
                    onChangeIsothermStoichiometryFactor={
                        this.handleChangeIsothermStoichiometryFactor
                    }
                    disabled={this.props.readOnly}
                    loading={false}
                    isIsothermPage
                />
            )}
        </SectionWrapper>
    );

    renderStripOptions = () => (
        <SectionWrapper>
            <SectionTitle>
                {this.props.intl.formatMessage({
                    id: `components.IsothermManagementSidebar.CreatePredictIsotherms.stripSection.Title`,
                })}
            </SectionTitle>
            <WrapperMultipleInput>
                <WrapperInputNumber>
                    <Label>
                        {this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.stripSection.spentLabel',
                        })}
                    </Label>
                    <InputNumber
                        max={MAXIMUMS.LEAN_CU}
                        min={0}
                        width="100px"
                        onChange={this.handleInputChange('electrolyteSpent')}
                        onBlur={this.handleOnBlurInput('electrolyteSpent')}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={DEFAULT_INPUT_NUMBER_STYLES}
                        value={this.state.electrolyteSpent || ''}
                        disabled={this.props.readOnly}
                        padding="0 5px 0 5px" // no spinner requires custom padding, otherwise
                        noSpinner
                    />
                </WrapperInputNumber>
                <WrapperInputNumber>
                    <Label>
                        {this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.stripSection.acidLabel',
                        })}
                    </Label>
                    <InputNumber
                        // NOTE: THIS IS ACTUALLY THE LEAN ACID! NOT the advance/rich Cu!
                        max={MAXIMUMS.LEAN_ACID}
                        min={0}
                        onChange={this.handleInputChange('electrolyteAdvance')}
                        onBlur={this.handleOnBlurInput('electrolyteAdvance')}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={DEFAULT_INPUT_NUMBER_STYLES}
                        value={this.state.electrolyteAdvance || ''}
                        disabled={this.props.readOnly}
                        width="100px"
                        padding="0 5px 0 5px" // no spinner requires custom padding, otherwise
                        noSpinner
                    />
                </WrapperInputNumber>
            </WrapperMultipleInput>
        </SectionWrapper>
    );

    renderReagentOptions = () => (
        <SelectField>
            <WrapperMultipleInput marginTop>
                {(this.state.selectedReagent || this.state.selectedOxime) && (
                    <WrapperInputNumber>
                        <Label>
                            {this.props.intl.formatMessage({
                                id: `components.IsothermManagementSidebar.CreatePredictIsotherms.reagentSection.${
                                    this.state.selectedOxime ? 'oximeLabel' : 'volumeLabel'
                                }`,
                            })}
                        </Label>
                        <InputNumber
                            min={0}
                            max={
                                this.state.selectedReagent
                                    ? MAXIMUMS.REAGENT_CONCENTRATION
                                    : MAXIMUMS.OXIME_GPL
                            }
                            onChange={
                                this.state.selectedReagent
                                    ? this.handleInputChange('reagentConcentration')
                                    : this.handleInputChange('oximeGpl')
                            }
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={DEFAULT_INPUT_NUMBER_STYLES}
                            value={
                                this.state.selectedReagent
                                    ? this.state.reagentConcentration || ''
                                    : this.state.oximeGpl || ''
                            }
                            disabled={this.props.readOnly}
                            width="100px"
                            padding="0 5px 0 5px" // no spinner requires custom padding, otherwise
                            noSpinner
                        />
                    </WrapperInputNumber>
                )}
                {this.state.selectedOxime && (
                    <WrapperInputNumber>
                        <Label>
                            {this.props.intl.formatMessage({
                                id:
                                    'components.IsothermManagementSidebar.CreatePredictIsotherms.reagentSection.ratioLabel',
                            })}
                        </Label>
                        <InputNumber
                            min={0}
                            max={MAXIMUMS.OXIME_RATIO}
                            onChange={this.handleInputChange('oximeRatio')}
                            onBlur={this.handleOnBlurInput('oximeRatio')}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={DEFAULT_INPUT_NUMBER_STYLES}
                            value={this.state.oximeRatio || ''}
                            disabled={this.props.readOnly}
                            width="100px"
                            padding="0 5px 0 5px" // no spinner requires custom padding, otherwise
                            noSpinner
                        />
                    </WrapperInputNumber>
                )}
            </WrapperMultipleInput>
        </SelectField>
    );

    renderCurveFittingOptions = () => {
        if (
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.CREATE ||
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            const selectedPolynomialType = this.isothermModelToReactSelectObject(
                this.state.selectedIsothermPolynomialType
            );
            const showPolynomialOrder = [
                ISOTHERM_POLYNOMIAL_TYPE.MINCHEM,
                ISOTHERM_POLYNOMIAL_TYPE.SIMPLE,
                ISOTHERM_POLYNOMIAL_TYPE.RATIONAL,
            ].includes(this.state.selectedIsothermPolynomialType);
            const numberOfDataPointsWithData = this.getDataPoints(this.state.referenceDataPoints)
                .length;
            const isDisabledPolynomialOrder =
                numberOfDataPointsWithData < MIN_DATA_POINTS_FOR_POLYNOMIAL_ORDER;
            return (
                <SectionWrapper>
                    <SectionTitle>
                        {this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.curveFittingSection.Title',
                        })}
                    </SectionTitle>
                    <SelectField>
                        <Label>
                            {this.props.intl.formatMessage({
                                id:
                                    'components.IsothermManagementSidebar.CreatePredictIsotherms.curveFittingSection.modelLabel',
                            })}
                        </Label>
                        <InputSelect
                            selectedOption={selectedPolynomialType}
                            options={this.getIsothermModelOptions()}
                            onSelect={this.handleIsothermModelSelected}
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'components.IsothermManagementSidebar.CreatePredictIsotherms.curveFittingSection.modelPlaceholder',
                            })}
                            onChange={this.handleInputChange('selectedIsothermPolynomialType')}
                            disabled={this.props.readOnly}
                            controlShouldRenderValue
                        />
                    </SelectField>
                    {showPolynomialOrder && (
                        <Wrapper>
                            <WrapperInputNumber marginTop>
                                <Label>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'components.IsothermManagementSidebar.CreatePredictIsotherms.curveFittingSection.polynomialLabel',
                                    })}
                                </Label>
                                <InputNumber
                                    max={this.getMaxPolynomialOrder()}
                                    min={this.getMinPolynomialOrder()}
                                    minWidth="100px"
                                    onChange={this.handleInputChange(
                                        'selectedIsothermPolynomialOrder'
                                    )}
                                    onBlur={this.handleOnBlurInput(
                                        'selectedIsothermPolynomialOrder'
                                    )}
                                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                                    style={DEFAULT_INPUT_NUMBER_STYLES}
                                    value={this.state.selectedIsothermPolynomialOrder || ''}
                                    disabled={this.props.readOnly || isDisabledPolynomialOrder}
                                    controlShouldRenderValue
                                />
                            </WrapperInputNumber>
                            {isDisabledPolynomialOrder && (
                                <InputNote>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'components.IsothermManagementSidebar.CreatePredictIsotherms.curveFittingSection.polynomialNote',
                                    })}
                                </InputNote>
                            )}
                        </Wrapper>
                    )}
                </SectionWrapper>
            );
        }
        return null;
    };

    renderIsothermDataPoint = (
        value: LooseNumberType,
        index: number,
        type: IsothermDataPointsType
    ) => (
        <InputNumber
            disabled={this.state.selectedIsothermType === STAGE_TYPES.EXTRACT && index === 0}
            onPaste={this.handlePaste(index, type)}
            max={100}
            min={0}
            onChange={this.handleDataPointChange(index, type)}
            onBlur={this.handleOnBlurDataPoint(index, type)}
            placeholder={NUMBER_INPUT_PLACEHOLDER}
            style={DEFAULT_INPUT_NUMBER_STYLES}
            disabled={this.props.readOnly}
            value={getValueAsString(value)}
            width="100%"
            padding="0 5px 0 5px" // no spinner requires custom padding, otherwise
            controlShouldRenderValue
            noSpinner
        />
    );

    renderTableDataRows = () => {
        const tableRows = this.state.referenceDataPoints.map(
            (dataPoint: DataPoint, index: number) => ({
                id: index,
                aqueous: this.renderIsothermDataPoint(
                    dataPoint.aqueousConcentration,
                    index,
                    ISOTHERM_DATAPOINTS_TYPE.AQUEOUS_CONCENTRATION
                ),
                organic: this.renderIsothermDataPoint(
                    dataPoint.organicConcentration,
                    index,
                    ISOTHERM_DATAPOINTS_TYPE.ORGANIC_CONCENTRATION
                ),
            })
        );

        for (let index = tableRows.length; index < CREATE_ISOTHERM_DATAPOINTS_COUNT; index++) {
            tableRows.push({
                id: index,
                aqueous: this.renderIsothermDataPoint(
                    null,
                    index,
                    ISOTHERM_DATAPOINTS_TYPE.AQUEOUS_CONCENTRATION
                ),
                organic: this.renderIsothermDataPoint(
                    null,
                    index,
                    ISOTHERM_DATAPOINTS_TYPE.ORGANIC_CONCENTRATION
                ),
            });
        }
        return tableRows;
    };

    renderCreateTable = () => {
        if (
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.CREATE ||
            this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
        ) {
            const TABLE_HEADERS = [
                {
                    label: this.props.intl.formatMessage(
                        {
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.dataPointsSection.aqueousColumn',
                        },
                        {
                            axisLabel:
                                this.state.selectedIsothermType === ISOTHERM_BASE_CONSTANT.EXTRACT
                                    ? 'x'
                                    : 'y',
                        }
                    ),
                    id: 'aqueous',
                },
                {
                    label: this.props.intl.formatMessage(
                        {
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.dataPointsSection.organicColumn',
                        },
                        {
                            axisLabel:
                                this.state.selectedIsothermType === ISOTHERM_BASE_CONSTANT.EXTRACT
                                    ? 'y'
                                    : 'x',
                        }
                    ),
                    id: 'organic',
                },
            ];
            const headersTableDataPoints =
                this.state.selectedIsothermType === ISOTHERM_BASE_CONSTANT.EXTRACT
                    ? TABLE_HEADERS
                    : TABLE_HEADERS.reverse();

            return (
                <SectionWrapper topPadding bottomPadding>
                    <SectionTitle>
                        {this.props.intl.formatMessage({
                            id:
                                'components.IsothermManagementSidebar.CreatePredictIsotherms.dataPointsSection.Label',
                        })}
                    </SectionTitle>
                    <Table
                        header={headersTableDataPoints}
                        rows={this.renderTableDataRows()}
                        gridStyling
                    />
                </SectionWrapper>
            );
        }
        return null;
    };

    render() {
        const selectedMetal = this.state.selectedMetal
            ? IsothermsDataSidebarSection.metalToReactSelectObject(this.state.selectedMetal)
            : null;

        return (
            <React.Fragment>
                <SidebarBody>
                    <SectionWrapper>
                        <HeaderTitleWrapper onClick={this.props.handleReturnToVisualizeClicked}>
                            <NavigationChevron
                                width="7px"
                                height="10px"
                                margin="5px 10px 5px 5px"
                            />
                            <HeaderTitle>{this.getSidebarTitle()}</HeaderTitle>
                        </HeaderTitleWrapper>
                        <HeaderRadioWrapper>
                            <RadioButtonSet
                                orientation="HORIZONTAL"
                                value={this.state.selectedIsothermType}
                                onClick={this.handleIsothermTypeSelected}
                                disabled={
                                    this.props.readOnly ||
                                    this.props.activeMode ===
                                        ISOTHERM_SIDEBAR_TYPES.UPDATE_PREDICT ||
                                    this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
                                }
                                options={[
                                    {
                                        value: ISOTHERM_BASE_CONSTANT.EXTRACT,
                                        label: this.props.intl.formatMessage({
                                            id:
                                                'components.IsothermManagementSidebar.CreatePredictIsotherms.isothermTypeSection.extraction',
                                        }),
                                    },
                                    {
                                        value: ISOTHERM_BASE_CONSTANT.STRIP,
                                        label: this.props.intl.formatMessage({
                                            id:
                                                'components.IsothermManagementSidebar.CreatePredictIsotherms.isothermTypeSection.stripping',
                                        }),
                                    },
                                ]}
                                styles={{
                                    style: IsothermTypeRadioStyles,
                                }}
                            />
                        </HeaderRadioWrapper>
                    </SectionWrapper>
                    <OverflowBody>
                        <BodySection
                            borderColor={LegacyTheme.defaultBuildingBlockBackground}
                            borderTop
                            borderBottom
                        >
                            <SectionWrapper>
                                <SectionTitle>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'components.IsothermManagementSidebar.CreatePredictIsotherms.metalSection.Title',
                                    })}
                                </SectionTitle>
                                <SelectField>
                                    <Label>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'components.IsothermManagementSidebar.CreatePredictIsotherms.metalSection.Label',
                                        })}
                                    </Label>
                                    <InputSelect
                                        isDisabled={this.props.loadingMetals || this.props.readOnly}
                                        isLoading={this.props.loadingMetals}
                                        selectedOption={selectedMetal}
                                        options={this.getMetalOptions()}
                                        onSelect={this.handleMetalSelected}
                                        placeholder={this.props.intl.formatMessage({
                                            id:
                                                'components.IsothermManagementSidebar.CreatePredictIsotherms.metalSection.Placeholder',
                                        })}
                                        controlShouldRenderValue
                                    />
                                </SelectField>
                            </SectionWrapper>
                            <SectionWrapper>
                                <SectionTitle>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'components.IsothermManagementSidebar.CreatePredictIsotherms.reagentSection.Title',
                                    })}
                                </SectionTitle>
                                <ReagentSelectBody
                                    readOnly={this.props.readOnly}
                                    selectedReagent={this.state.selectedReagent}
                                    selectedOxime={this.state.selectedOxime}
                                    onSelectReagent={this.handleReagentSelected}
                                    onSelectOxime={this.handleOximeSelected}
                                />
                                {this.renderReagentOptions()}
                            </SectionWrapper>
                            {this.state.selectedIsothermType === STAGE_TYPES.EXTRACT &&
                                this.renderExtractOptions()}
                            {this.state.selectedIsothermType === STAGE_TYPES.STRIP &&
                                this.renderStripOptions()}
                            {this.renderCurveFittingOptions()}
                            {this.renderCreateTable()}
                            {this.renderSharingOptions()}
                        </BodySection>
                    </OverflowBody>
                </SidebarBody>
                {!this.props.readOnly && (
                    <StickyFooterSection>
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: `components.IsothermManagementSidebar.CreatePredictIsotherms.${
                                    this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.CREATE ||
                                    this.props.activeMode === ISOTHERM_SIDEBAR_TYPES.UPDATE_CREATE
                                        ? 'createButton'
                                        : 'predictButton'
                                }`,
                            })}
                            disabled={!this.isVisualizeButtonEnabled() || !this.state.isModified}
                            loading={this.props.loadingPredictOrCreate}
                            onClick={this.handleVisualizeClicked}
                        />
                        <PreventNavigationPrompt shouldBlock={this.state.isModified} />
                    </StickyFooterSection>
                )}
            </React.Fragment>
        );
    }
}

export default injectIntl(IsothermsDataSidebarSection);
