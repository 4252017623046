// @flow strict

import { LegacyTheme } from 'components/_ReactUI_V1';

export const GraphStyles = {
    mainGraph: {
        parent: {
            width: 'initial',
            height: 'initial',
            overflow: 'visible',
            boxSizing: 'border-box',
            fontFamily: 'Helvetica',
        },
    },

    mainGraphPadding: {
        top: 34,
        bottom: 100,
        left: 200,
        right: 200,
    },

    baseIsothermLine: (color, index) => ({
        data: {
            stroke: color.accent[index],
            strokeWidth: '4px',
        },
        labels: {
            fill: color.primary[index],
        },
    }),

    scatterSize: 7,
    scatterColor: (color, index) => ({
        data: {
            fill: color.primary[index],
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 2,
        },
        labels: {
            fill: color.primary[index],
        },
    }),

    axisY: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        ticks: {
            size: 55,
            strokeWidth: 1,
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            padding: 5,
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontFamily: 'inherit',
            fontSize: 12,
            padding: 100,
        },
        tickLabels: {
            fill: LegacyTheme.defaultSubtleColor,
            fontFamily: 'inherit',
            fontSize: 12,
        },
    },

    axisYTickLabelTranslate: {
        dy: -20,
        dx: 40, // axisY.ticks.size - dx = spacing between axis and tick number.
    },

    axisX: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        tickLabels: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 12,
            fontFamily: 'inherit',
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 12,
            fontFamily: 'inherit',
            padding: 74,
        },
    },
};

export const SmallGraphStyles = {
    mainGraphPadding: {
        top: 30, // 20px padding from chart wrapper + 10 padding for the axis ticks
        right: 20, // 20px padding from chart wrapper
        bottom: 60, // 20px padding from chart wrapper + 40 padding for the axis label
        left: 42, // 20px padding from chart wrapper + 40 padding for the axis label
    },

    axisY: {
        ticks: {
            size: 20,
            padding: 30,
        },
        axisLabel: {
            padding: 30,
        },
    },

    axisYTickLabelTranslate: {
        dy: -20,
        dx: 40, // axisY.ticks.size - dx = spacing between axis and tick number.
    },

    axisX: {
        axisLabel: {
            padding: 40,
        },
    },
};
