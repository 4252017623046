// @flow strict

import type { ImmutableList } from 'types';
import type { ImmutableTwoDAnalysis } from 'services/Dataset/types';

export const getDomainMin = (twoDAnalysis: ImmutableTwoDAnalysis) =>
    twoDAnalysis.get('minimumXValue');

export const getDomainMax = (twoDAnalysis: ImmutableTwoDAnalysis) =>
    twoDAnalysis.get('maximumXValue');

export const getRangeMin = (twoDAnalysis: ImmutableTwoDAnalysis, dataKey: string) =>
    Math.min(
        ...twoDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableList<number>) => dataPoint.get(dataKey))
            .toJS()
    );

export const getRangeMax = (twoDAnalysis: ImmutableTwoDAnalysis, dataKey: string) =>
    Math.max(
        ...twoDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableList<number>) => dataPoint.get(dataKey))
            .toJS()
    );

export const getRecoveryMin = (twoDAnalysis: ImmutableTwoDAnalysis) =>
    getRangeMin(twoDAnalysis, 'overallRecovery');

export const getRecoveryMax = (twoDAnalysis: ImmutableTwoDAnalysis) =>
    getRangeMax(twoDAnalysis, 'overallRecovery');

export const getNetTransferMin = (twoDAnalysis: ImmutableTwoDAnalysis) =>
    Math.min(
        ...twoDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableList<number>) => dataPoint.get('netTransfer'))
            .toJS()
    );

export const getNetTransferMax = (twoDAnalysis: ImmutableTwoDAnalysis) =>
    Math.max(
        ...twoDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableList<number>) => dataPoint.get('netTransfer'))
            .toJS()
    );

export const hasNetTransfer = (twoDAnalysis: ImmutableTwoDAnalysis) =>
    twoDAnalysis
        .get('analysis')
        .some((dataPoint: ImmutableList<object>) => dataPoint.get('netTransfer') !== null);
