// @flow strict

import styled from 'styled-components';

import { LegacyTheme, PrimaryButton } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const GraphPeriodButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${({ center }: { center: boolean }) => (center ? 'center' : 'flex-start')};
    flex-wrap: wrap;

    max-width: 100%;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        max-width: auto;
        width: 100%;
        justify-content: stretch;

        border-bottom: 1px solid ${LegacyTheme.defaultBorder};
    }
`;

export const GraphPeriodButton = styled(PrimaryButton)`
    display: block;
    width: auto;
    padding: 5px 20px;
    margin: 5px;

    font-size: 15px;
    box-shadow: none;

    border-radius: 15px;

    transition: background-color ease 0.25s, color ease 0.25s;

    ${(props: { active: boolean }) =>
        props.active
            ? `
                background-color: ${LegacyTheme.buttonPrimary};
                color: ${LegacyTheme.white};
            `
            : `
                background-color: ${LegacyTheme.defaultSubtleColorFA};
                color: ${LegacyTheme.defaultSubtleColor};
            `};

    &:focus {
        border-radius: 15px;
    }

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        display: flex;
        flex-grow: 1;
        justify-content: center;

        width: auto;
        padding: 8px 5px;
        margin: 0;

        font-size: 12px;
        line-height: normal;
        background-color: transparent;
        border-radius: 0px;

        ${(props: { active: boolean }) =>
            props.active
                ? `
                color: ${LegacyTheme.defaultColor};
                border-bottom: solid 4px ${LegacyTheme.buttonPrimary};
            `
                : `
                color: ${LegacyTheme.defaultSubtleColor};
                border-bottom: solid 4px transparent;
            `};

        &:focus {
            border-radius: 0px;
        }
    }
`;
