// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Styles
import {
    Content,
    Footer,
    Field,
    FieldHeader,
    CheckboxWrapper,
    CheckBoxStyle,
    CheckBoxNumberField,
    Label,
} from './styles';

// Components
import { Title } from 'styles/common';
import {
    PrimaryButton,
    SecondaryButton,
    CheckBox,
    InputNumber,
    SidebarFooter,
} from 'components/_ReactUI_V1';

// Constants
import { NUMBER_INPUT_PLACEHOLDER, DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR } from 'utils/constants';

// Containers
import MinchemPreferencesFetcher from 'containers/MinchemPreferencesFetcher';

// Helpers
import { clamp } from 'utils/helpers';

// Services
import { selectIsGettingMinchemPreferences } from 'services/Authentication/selectors';
import { getMinchemPreferences } from 'services/Authentication/thunks';

// Types
import type { IntlType, InputEvent } from 'types';

import type { ImmutableUser, MinchemPreferences } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    loading: boolean,
    onConfirm: (minchemPreferences: MinchemPreferences) => void,

    isGettingMinchemPreferences: boolean,
};

type State = MinchemPreferences;

/**
 * The user MinChem settings
 */
class MinChemSettings extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.getFreshState();
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.user) {
            throw new Error('No user supplied to update MinChem user settings.');
        }
        // The component was loaded before the user was loaded, and now we have the user
        // or are the two users different?
        if (
            (!prevProps.user && this.props.user) ||
            prevProps.user.get('id') !== this.props.user.get('id') ||
            // update local state if the preferences are done fetching
            this.props.isGettingMinchemPreferences !== prevProps.isGettingMinchemPreferences
        ) {
            this.setState(this.getFreshState());
        }
    }

    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.UserSettings.MinChemSettings.${key}`,
            },
            data
        );

    /**
     * Get a new state object from the prop user settings.
     */
    getFreshState = (): State => {
        // Grab the settings or use an empty settings object.
        const settings = this.props.user.getIn(['preferences', 'minchem'], fromJS({}));
        return {
            displayLoadedPercent: settings.get('displayLoadedPercent', true),
            displayNetTransfer: settings.get('displayNetTransfer', true),
            displayStrippedPercent: settings.get('displayStrippedPercent', true),
            displayProductionCalculations: settings.get('displayProductionCalculations', true),
            displayCopperTransferred: settings.get('displayCopperTransferred', true),

            defaultExtractorsEnabled: settings.get('defaultExtractorsEnabled', true),
            defaultExtractors: settings.get('defaultExtractors', 2),

            defaultExtractStageEfficiencyEnabled: settings.get(
                'defaultExtractStageEfficiencyEnabled',
                true
            ),
            defaultExtractStageEfficiency: Number(
                settings.get('defaultExtractStageEfficiency', 95)
            ),

            defaultStrippersEnabled: settings.get('defaultStrippersEnabled', true),
            defaultStrippers: settings.get('defaultStrippers', 2),

            defaultStripStageEfficiencyEnabled: settings.get(
                'defaultStripStageEfficiencyEnabled',
                true
            ),
            defaultStripStageEfficiency: Number(settings.get('defaultStripStageEfficiency', 95)),

            showIsothermStoichiometryFactors: settings.get(
                'showIsothermStoichiometryFactors',
                false
            ),
            defaultIsothermStoichiometryFactor: settings.get(
                'defaultIsothermStoichiometryFactor',
                DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR
            ),
        };
    };

    /**
     * Return false if any checkbox is enabled and its corresponding value is falsy
     */
    isValid = (): boolean => {
        return !(
            (this.state.defaultExtractorsEnabled &&
                (this.state.defaultExtractors === null ||
                    this.state.defaultExtractors === 'undefined' ||
                    this.state.defaultExtractors === '')) ||
            (this.state.defaultExtractStageEfficiencyEnabled &&
                !this.state.defaultExtractStageEfficiency) ||
            (this.state.defaultStrippersEnabled &&
                (this.state.defaultStrippers === null ||
                    this.state.defaultStrippers === 'undefined' ||
                    this.state.defaultStrippers === '')) ||
            (this.state.defaultStripStageEfficiencyEnabled &&
                !this.state.defaultStripStageEfficiency)
        );
    };

    isModified = (): boolean => {
        const settings = this.getFreshState();
        return fromJS(settings).findKey(
            (value: any, key: string) => this.state.hasOwnProperty(key) && value !== this.state[key]
        );
    };

    /**
     * Handle the save button click
     */
    handleSaveClicked = () => {
        if (!this.isValid() || !this.isModified()) {
            return;
        }
        const data = this.state;

        // Fire the save update to the container
        this.props.onConfirm(data);
    };

    /**
     * When a checkbox is clicked, get the checkbox name and update the state with the checked value
     */
    handleCheckboxClicked = (event: InputEvent) =>
        this.setState({ [event.target.name]: event.target.checked });

    /**
     * When an input box (number) is changed, check with the number input box's min/max
     * and update the state if it passes.
     */
    handleNumberChange = (event: InputEvent) =>
        this.setState({
            [event.target.name]:
                event.target.value !== ''
                    ? clamp(Number(event.target.value), event.target.min, event.target.max)
                    : '',
        });

    render() {
        const isModified = this.isModified();
        const isValid = this.isValid();

        return (
            <React.Fragment>
                <MinchemPreferencesFetcher>
                    <Content>
                        <Title>{this.getTranslation('title')}</Title>
                        <Field>
                            <FieldHeader>{this.getTranslation('outputOptions.Header')}</FieldHeader>
                            <CheckboxWrapper>
                                <CheckBox
                                    name="displayLoadedPercent"
                                    checked={this.state.displayLoadedPercent}
                                    label={this.getTranslation(
                                        'outputOptions.displayLoadedPercent'
                                    )}
                                    onClickHandler={this.handleCheckboxClicked}
                                    styles={CheckBoxStyle}
                                />
                                <CheckBox
                                    name="displayStrippedPercent"
                                    checked={this.state.displayStrippedPercent}
                                    label={this.getTranslation(
                                        'outputOptions.displayStrippedPercent'
                                    )}
                                    onClickHandler={this.handleCheckboxClicked}
                                    styles={{ fontSize: '14px', lineHeight: '17px' }}
                                />
                                <CheckBox
                                    name="displayNetTransfer"
                                    checked={this.state.displayNetTransfer}
                                    label={this.getTranslation('outputOptions.displayNetTransfer')}
                                    onClickHandler={this.handleCheckboxClicked}
                                    styles={CheckBoxStyle}
                                />
                                <CheckBox
                                    name="displayCopperTransferred"
                                    checked={this.state.displayCopperTransferred}
                                    label={this.getTranslation(
                                        'outputOptions.displayCopperTransferred'
                                    )}
                                    onClickHandler={this.handleCheckboxClicked}
                                    styles={CheckBoxStyle}
                                />
                            </CheckboxWrapper>
                        </Field>
                        <Field>
                            <FieldHeader>{this.getTranslation('setDefaults.Header')}</FieldHeader>
                            <CheckboxWrapper>
                                <CheckBoxNumberField>
                                    <CheckBox
                                        name="defaultExtractorsEnabled"
                                        checked={this.state.defaultExtractorsEnabled}
                                        label={this.getTranslation('setDefaults.extractionUnits')}
                                        onClickHandler={this.handleCheckboxClicked}
                                        styles={CheckBoxStyle}
                                    />
                                    <InputNumber
                                        disabled={!this.state.defaultExtractorsEnabled}
                                        height="30px"
                                        max={10}
                                        min={0}
                                        name="defaultExtractors"
                                        onChange={this.handleNumberChange}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        textAlign="center"
                                        value={this.state.defaultExtractors}
                                        width="62px"
                                    />
                                </CheckBoxNumberField>
                                <CheckBoxNumberField>
                                    <CheckBox
                                        name="defaultExtractStageEfficiencyEnabled"
                                        checked={this.state.defaultExtractStageEfficiencyEnabled}
                                        label={this.getTranslation(
                                            'setDefaults.extractionStageEfficiency'
                                        )}
                                        onClickHandler={this.handleCheckboxClicked}
                                        styles={CheckBoxStyle}
                                    />
                                    <InputNumber
                                        disabled={!this.state.defaultExtractStageEfficiencyEnabled}
                                        height="30px"
                                        max={100}
                                        min={0}
                                        name="defaultExtractStageEfficiency"
                                        onChange={this.handleNumberChange}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        textAlign="center"
                                        value={this.state.defaultExtractStageEfficiency}
                                        width="75px"
                                    />
                                </CheckBoxNumberField>
                                <CheckBoxNumberField>
                                    <CheckBox
                                        name="defaultStrippersEnabled"
                                        checked={this.state.defaultStrippersEnabled}
                                        label={this.getTranslation('setDefaults.strippingUnits')}
                                        onClickHandler={this.handleCheckboxClicked}
                                        styles={CheckBoxStyle}
                                    />
                                    <InputNumber
                                        disabled={!this.state.defaultStrippersEnabled}
                                        height="30px"
                                        max={10}
                                        min={0}
                                        name="defaultStrippers"
                                        onChange={this.handleNumberChange}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        textAlign="center"
                                        value={this.state.defaultStrippers}
                                        width="62px"
                                    />
                                </CheckBoxNumberField>
                                <CheckBoxNumberField>
                                    <CheckBox
                                        name="defaultStripStageEfficiencyEnabled"
                                        checked={this.state.defaultStripStageEfficiencyEnabled}
                                        label={this.getTranslation(
                                            'setDefaults.strippingStageEfficiency'
                                        )}
                                        onClickHandler={this.handleCheckboxClicked}
                                        styles={CheckBoxStyle}
                                    />
                                    <InputNumber
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        name="defaultStripStageEfficiency"
                                        disabled={!this.state.defaultStripStageEfficiencyEnabled}
                                        value={this.state.defaultStripStageEfficiency}
                                        onChange={this.handleNumberChange}
                                        height="30px"
                                        width="75px"
                                        textAlign="center"
                                        max={100}
                                        min={0}
                                    />
                                </CheckBoxNumberField>
                            </CheckboxWrapper>
                        </Field>
                        <Field>
                            <FieldHeader>
                                {this.getTranslation('StoichiometryFactors.Header')}
                            </FieldHeader>
                            <CheckboxWrapper>
                                <CheckBoxNumberField>
                                    <CheckBox
                                        name="showIsothermStoichiometryFactors"
                                        checked={this.state.showIsothermStoichiometryFactors}
                                        label={this.getTranslation('StoichiometryFactors.show')}
                                        onClickHandler={this.handleCheckboxClicked}
                                        styles={CheckBoxStyle}
                                    />
                                    <div /> {/* Spacer :( */}
                                </CheckBoxNumberField>
                                <CheckBoxNumberField>
                                    <Label>
                                        {this.getTranslation('StoichiometryFactors.default')}
                                    </Label>
                                    <InputNumber
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        name="defaultIsothermStoichiometryFactor"
                                        value={this.state.defaultIsothermStoichiometryFactor}
                                        onChange={this.handleNumberChange}
                                        height="30px"
                                        width="75px"
                                        textAlign="center"
                                        min={0}
                                    />
                                </CheckBoxNumberField>
                            </CheckboxWrapper>
                        </Field>
                    </Content>
                    <Footer>
                        <PrimaryButton
                            text={this.getTranslation('saveButtonText')}
                            onClick={this.handleSaveClicked}
                            loading={this.props.loading || this.props.isGettingMinchemPreferences}
                            disabled={!isValid || !isModified}
                        />
                    </Footer>
                </MinchemPreferencesFetcher>
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isGettingMinchemPreferences: selectIsGettingMinchemPreferences(),
});

export default connect(mapStateToProps)(injectIntl(MinChemSettings));
