// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    padding: 0px 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0px 24px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    border-top: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    }
`;

export const FieldHeader = styled.label`
    color: ${LegacyTheme.defaultColor};
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;

    margin-bottom: 10px;
`;
