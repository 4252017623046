// @flow strict

import React from 'react';

import { StyledSVG } from './styles';

export default function HeartMonitorIcon(props?: { fill?: string, width?: number }) {
    return (
        <StyledSVG
            width={`${props.width}px`}
            height={`${props.width}px`}
            viewBox="0 0 14.537 14.537"
        >
            <path
                fill={props.fill}
                d="M13.66,0H0.877C0.393,0,0,0.394,0,0.877V13.66c0,0.483,0.394,0.877,0.877,0.877H13.66
			c0.483,0,0.877-0.394,0.877-0.877V0.878C14.537,0.394,14.143,0,13.66,0z M13.484,13.485H1.052V1.053h12.432V13.485z"
            />
            <path
                fill={props.fill}
                d="M2.126,8.744h1.605c0.15,0,0.282-0.101,0.322-0.245l0.492-1.78l0.703,3.904
			c0.031,0.171,0.185,0.291,0.358,0.273c0.172-0.015,0.305-0.159,0.305-0.332v-1.82h0.95c0.166,0,0.307-0.121,0.331-0.286
			l0.526-3.619l1.142,6.305c0.029,0.158,0.167,0.273,0.327,0.273c0.001,0,0.001,0,0.002,0c0.16,0,0.298-0.113,0.328-0.27l0.51-2.627
			h0.256l0.385,0.652c0.059,0.1,0.165,0.162,0.281,0.164c0.109,0.004,0.225-0.055,0.287-0.152l0.383-0.59h0.461
			c0.185,0,0.334-0.15,0.334-0.334c0-0.186-0.149-0.335-0.334-0.335h-0.642c-0.112,0-0.219,0.058-0.28,0.152l-0.188,0.29
			l-0.207-0.352c-0.06-0.102-0.17-0.164-0.288-0.164H9.752c-0.16,0-0.298,0.113-0.328,0.27L9.201,9.269L8.012,2.702
			C7.983,2.541,7.836,2.419,7.677,2.427C7.513,2.43,7.375,2.551,7.352,2.714l-0.779,5.36H5.578c-0.037,0-0.072,0.006-0.105,0.017
			L4.944,5.154C4.916,5.001,4.786,4.886,4.63,4.88C4.458,4.856,4.334,4.974,4.293,5.125l-0.815,2.95H2.126
			c-0.185,0-0.334,0.149-0.334,0.334S1.941,8.744,2.126,8.744z"
            />
        </StyledSVG>
    );
}

HeartMonitorIcon.defaultProps = {
    fill: 'currentColor',
    width: 20,
};
