// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Label, Field, CustomInputNumber } from './styles';

// Helpers
import { isSolvayUser } from 'utils/authentication';

// Types
import type { IntlType, InputEvent } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    loading: boolean,
    isothermStoichiometryFactor: number,

    isIsothermPage?: boolean,

    onChangeIsothermStoichiometryFactor: (value: number) => void,
};

class IsothermStoichiometryInput extends React.PureComponent<Props, null> {
    static defaultProps = {
        isIsothermPage: false,
    };

    shouldBeDisplayed = () =>
        this.props.user.getIn(
            ['preferences', 'minchem', 'showIsothermStoichiometryFactors'],
            false
        );

    handleChangeIsothermStoichiometryFactor = (event: InputEvent) =>
        this.props.onChangeIsothermStoichiometryFactor(Number(event.target.value));

    render() {
        if (!this.shouldBeDisplayed()) {
            return null;
        }
        return (
            <Field isIsothermPage={this.props.isIsothermPage}>
                <Label fullWidth={this.props.isIsothermPage}>
                    {this.props.intl.formatMessage({
                        id: 'components.CircuitSetupSidebar.isothermStoichiometry.label',
                    })}
                </Label>
                <CustomInputNumber
                    value={this.props.isothermStoichiometryFactor || ''}
                    disabled={this.props.loading}
                    onChange={this.handleChangeIsothermStoichiometryFactor}
                    min={0}
                    step={0.1}
                />
            </Field>
        );
    }
}

export default injectIntl(IsothermStoichiometryInput);
