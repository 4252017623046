// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type { ImmutableTrendsState } from 'services/Trends/types';

/**
 * Direct selector to the trend state domain
 */
export const selectTrendsState = () => (state: ImmutableReduxState) => state.get('trends');

/**
 * Select list of trends
 */
export const selectAllCircuitTrends = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('circuitTrends').toList()
    );

/**
 * Select list of trends
 */
export const selectAllPlantTrends = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('plantTrends').toList()
    );

/**
 * Select the archive for the circuit
 */
export const selectCircuitArchive = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('circuitArchive')
    );

/**
 * Select the archive for the plant
 */
export const selectPlantArchive = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('plantArchive')
    );

/**
 * Select the current query for circuit archive
 */
export const selectCircuitArchiveQuery = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('circuitArchiveQuery')
    );

/**
 * Select the current query for plant archive
 */
export const selectPlantArchiveQuery = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('plantArchiveQuery')
    );

/**
 * Select trend errors
 */
export const selectTrendsErrors = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('errors')
    );

/**
 * Gets isFetching status while plant trends are being fetched: to hide list or show loading animation
 */
export const selectPlantTrendsAreFetching = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('isFetchingPlantTrend')
    );

/**
 * Gets isFetching status while circuit trends are being fetched: to hide list or show loading animation
 */
export const selectCircuitTrendsAreFetching = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('isFetchingCircuitTrend')
    );

/**
 * Gets isFetching status while plant trends are being fetched: to hide list or show loading animation
 */
export const selectPlantArchiveIsFetching = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('isFetchingPlantArchive')
    );

/**
 * Gets isFetching status while circuit trends are being fetched: to hide list or show loading animation
 */
export const selectCircuitArchiveIsFetching = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('isFetchingCircuitArchive')
    );

/**
 * Select trends circuit
 */
export const selectCircuit = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('circuit')
    );

/**
 * Select circuit's kpi settings
 */
export const selectCircuitArchiveKpiSettings = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('circuitKpiSettings')
    );

/**
 * Select plant's kpi settings
 */
export const selectPlantArchiveKpiSettings = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('plantKpiSettings')
    );

/**
 * Select plant's timezone
 */
export const selectCircuitArchivePlantTimezone = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('plantTimezone')
    );

/**
 * Select single KPI trend data for modal (alternative period selected)
 */
export const selectKPIHistories = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('kpiHistories')
    );

export const selectLoadingKPIHistories = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('isFetchingKPIHistory')
    );

/**
 * Gets isFetching status while a single KPI trend is being fetched: to hide list or show loading animation
 */
export const selectKPIHistoryIsFetching = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('isFetchingKPIHistory')
    );

/**
 * Gets isFetching status while dataset values are being fetched: to hide list or show loading animation
 */
export const selectDatasetValuesAreFetching = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('isFetchingDatasetValues')
    );

/**
 * Select dataset values for single dataset
 */
export const selectModalDatasetValuesData = () =>
    createSelector(
        selectTrendsState(),
        (substate: ImmutableTrendsState) => substate.get('modalDatasetValues')
    );
