// @flow strict

import { push } from 'react-router-redux';

import type {
    ReduxDispatch,
    ResponseErrorType,
    SearchCriteria,
    QueryStructure,
    CircuitTypes,
    ImmutableList,
    CircuitExportRequestData,
} from 'types';
import {
    create,
    createAdvanced,
    find,
    update,
    updateAdvanced,
    destroy,
    query,
    elevateToSolvExtract,
    exportToMinchem,
} from './resources';
import {
    receivedFetchSuccess,
    receivedFetchFailure,
    receivedCreateFailure,
    receivedCreateSuccess,
    receivedDestroyFailure,
    receivedDestroySuccess,
    receivedQueryMinChemFailure,
    receivedQueryMinChemSuccess,
    receivedQuerySolvExtractFailure,
    receivedQuerySolvExtractSuccess,
    receivedUpdateFailure,
    receivedUpdateSuccess,
    receivedElevationSuccess,
    receivedElevationFailure,
    setIsElevatingStatus,
    setIsCreatingStatus,
    setIsDeletingStatus,
    setIsFetchingStatus,
    setIsQueryingMinChemStatus,
    setIsQueryingSolvExtractStatus,
    setIsUpdatingStatus,
} from './actions';

import { createUntranslatedFeedback } from 'services/Feedback/actions';

import type {
    ImmutableCircuit,
    Circuit,
    ImmutableStream,
    ImmutableCircuitSettings,
} from 'services/Circuit/types';
import type { ImmutableDecisionTreeOptions } from 'services/DecisionTreeOptions/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

import { report } from 'services/Errors/resources';

import { CIRCUIT_TYPES, NAVIGATION_ROUTES } from 'utils/constants';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Create new circuit
 */
export const createCircuit = (circuit: Circuit) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingStatus());
    create(circuit)
        .then((response: Circuit) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createCircuitSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createCircuitFailed'));
        });
};

/**
 * Create a new advanced circuit
 */
export const createAdvancedCircuit = (circuit: Circuit, redirectPostSave: boolean = false) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsCreatingStatus());
    createAdvanced(circuit)
        .then((response: Circuit) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.createAdvancedCircuitSuccess'
                )
            );

            if (redirectPostSave) {
                const circuitId = response.id;
                dispatch(push(`${NAVIGATION_ROUTES.CIRCUIT}${circuitId}`));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.createAdvancedCircuitFailed')
            );
        });
};

/**
 * Export a SolvExtract circuit to Minchem
 */
export const exportCircuitToMinchem = (requestBody: CircuitExportRequestData) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsCreatingStatus());
    exportToMinchem(requestBody)
        .then((response: Circuit) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.createAdvancedCircuitSuccess'
                )
            );
            const circuitId = response.id;
            const datasetId = response.datasets[0].id;
            dispatch(
                push(
                    `${NAVIGATION_ROUTES.COMPUTATION}${circuitId}${
                        NAVIGATION_ROUTES.COMPUTATION_DATASET
                    }${datasetId}`
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.createAdvancedCircuitFailed')
            );
        });
};

/**
 * Elevate a regular minchem circuit into a solvextract circuit.
 */
export const elevateToSolvExtractCircuit = (
    circuitId: number,
    circuitName: string,
    plantId: number,
    kpiData: ImmutableList<ImmutableKPISetting>,
    decisionTreeOptions: ImmutableDecisionTreeOptions,
    circuitSettings: ImmutableCircuitSettings,
    reagentId: ?number
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsElevatingStatus());
    elevateToSolvExtract(
        circuitId,
        circuitName,
        plantId,
        kpiData,
        decisionTreeOptions,
        circuitSettings,
        reagentId
    )
        .then((response: ImmutableCircuit) => {
            dispatch(receivedElevationSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.elevateToSolvExtractCircuitSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedElevationFailure(error));
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.elevateToSolvExtractCircuitFailed'
                )
            );
        });
};

/**
 * Fetch all circuits with type
 */
export const queryCircuits = (type: CircuitTypes, searchCriteria: SearchCriteria, page: number) => (
    dispatch: ReduxDispatch
) => {
    if (type === CIRCUIT_TYPES.MINCHEM_CIRCUIT) {
        dispatch(setIsQueryingMinChemStatus());
        query(type, searchCriteria, page)
            .then((response: QueryStructure<Circuit>) => {
                dispatch(receivedQueryMinChemSuccess(response));
            })
            .catch((error: ResponseErrorType) => {
                report(error);
                dispatch(receivedQueryMinChemFailure(error));
                dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchAllFailed'));
            });
    } else if (type === CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT) {
        dispatch(setIsQueryingSolvExtractStatus());
        query(type, searchCriteria, page)
            .then((response: QueryStructure<Circuit>) => {
                dispatch(receivedQuerySolvExtractSuccess(response));
            })
            .catch((error: ResponseErrorType) => {
                report(error);
                dispatch(receivedQuerySolvExtractFailure(error));
                dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchAllFailed'));
            });
    }
};

/**
 * Fetch a specific circuit
 */
export const fetchCircuit = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingStatus());
    find(id)
        .then((response: Circuit) => {
            dispatch(receivedFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchCircuitFailed'));
        });
};

/**
 * Update circuit
 */
export const updateCircuit = (id: number, circuit: Circuit) => (dispatch: ReduxDispatch) => {
    dispatch(setIsUpdatingStatus());
    update(id, circuit)
        .then((response: Circuit) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateCircuit'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateCircuitFailed'));
        });
};

/**
 * Update advanced circuit
 */
export const updateAdvancedCircuit = (id: number, circuit: Circuit) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingStatus());
    updateAdvanced(id, circuit)
        .then((response: Circuit) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateAdvancedCircuit'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateAdvancedCircuitFailed')
            );
        });
};

/**
 * Destroy circuit
 */
export const destroyCircuit = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsDeletingStatus());
    destroy(id)
        .then((response: Circuit) => {
            dispatch(receivedDestroySuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.destroyCircuitSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDestroyFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.destroyCircuitFailed'));
        });
};
