// @flow

import colors from '../colors';

const greyKeys = Object.keys(colors).filter((key: string) => key.includes('grey'));
const greyThumbs: Array<{ value: string }> = greyKeys.map((key: string) => ({
    value: colors[key],
}));

const themeColors = [
    {
        title: 'Primary',
        thumbs: [
            {
                label: 'Primary General',
                value: colors.blue007,
            },
            {
                label: 'Primary Hover',
                value: colors.blue009,
            },
        ],
    },
    {
        title: 'Alternative',
        thumbs: [
            {
                label: 'Alternative General',
                value: colors.blueEBF,
            },
            {
                label: 'Alternative Hover',
                value: colors.blueBEE,
            },
        ],
    },
    {
        title: 'Feedback',
        thumbs: [
            {
                label: 'Success',
                value: colors.green6AB,
            },
            {
                label: 'Fail/Error',
                value: colors.redE20,
            },
        ],
    },
    {
        title: 'Grey',
        thumbs: greyThumbs,
    },
];

const defaultTheme = {
    name: 'Default',
    themeColors,
    colors: {
        primary: colors.blue007,
        primaryHover: colors.blue009,
        alternative: colors.blueEBF,
        alternativeHover: colors.blueBEE,
    },
    buttonHover: {
        backgroundColor: 'transparent',
        backgroundColorActive: colors.greyF2,
        backgroundColorHover: colors.greyF2,
    },
    buttons: {
        minWidth: '80px',
        height: '30px',
        padding: '5px 10px',
        paddingForLargeRadius: '5px 15px',
        borderRadius: '4px',
        fontSize: '15px',
        fontWeight: 'normal',
        letterSpacing: '-0.4px',
        disabled: {
            backgroundColor: colors.greyC6,
            color: colors.white,
        },
        primary: {
            backgroundColor: colors.blue007,
            backgroundColorFocus: colors.blue009,
            backgroundColorHover: colors.blue009,
            color: colors.white,
            colorHover: colors.white,
        },
        secondary: {
            backgroundColor: colors.blueEBF,
            backgroundColorFocus: colors.blueEBF,
            backgroundColorHover: colors.blueBEE,
            color: colors.blue007,
            colorHover: colors.blue007,
        },
        tertiary: {
            backgroundColor: colors.greyC6,
            backgroundColorFocus: colors.greyC6,
            backgroundColorHover: colors.greyC6,
            color: colors.white,
            colorHover: colors.white,
        },
        inverse: {
            backgroundColor: 'transparent',
            backgroundColorHover: colors.blue009,
            borderColor: colors.blue007,
            borderColorHover: colors.blue009,
            borderWidth: '1px',
            color: colors.blue007,
            colorHover: colors.white,
        },
        warning: {
            backgroundColor: colors.redE20,
            backgroundColorFocus: colors.redE20,
            backgroundColorHover: colors.redB50,
            color: colors.white,
            colorHover: colors.white,
        },
        buttonGrid: {
            height: 30,
            button: {
                color: colors.grey9C,
                colorActive: colors.blue007,
                backgroundColor: colors.greyFC,
                backgroundColorActive: colors.white,
                backgroundColorHover: colors.white,
                borderColor: colors.greyE6,
            },
            label: {
                color: colors.grey9C,
                colorActive: colors.blue007,
                colorDanger: colors.redDE0,
                backgroundColorActive: colors.white,
                backgroundColorDanger: colors.white,
                borderColor: colors.greyE6,
                borderColorActive: colors.blue007,
                borderColorDanger: colors.redDE0,
            },
        },
        buttonWrapper: {
            gutter: '24px',
        },
    },
    dotMenu: {
        color: colors.grey9C,
    },
    hr: {
        borderColor: colors.greyE6,
        borderStyle: 'solid',
        borderWidth: '2px',
    },
    column: {
        flex: '1',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    row: {
        flex: 'auto',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gutter: 24,
        column: {
            padding: '0 5px',
        },
    },
    messageBlock: {
        color: colors.white,
        backgroundColor: colors.grey33,
        borderRadius: '4px',
        padding: '12px 16px',
        icon: {
            backgroundColor: colors.white,
            color: colors.grey33,
        },
    },
    formElements: {
        textFieldRich: {
            padding: '12px 16px',
            fontSize: '14px',
            toolbar: {
                borderColor: colors.greyE6,
            },
        },
        radioButton: {
            color: colors.grey7B,
            colorDisabled: colors.greyC6,
            backgroundColor: colors.white,
            backgroundColorHover: colors.greyFA,
            border: colors.greyC6,
            borderHover: colors.grey9C,
            dotColorActive: colors.blue007,
            dotColorDisabled: colors.greyC6,
        },
        toggle: {
            borderRadius: '10px',
            width: '40px',
            height: '20px',
            color: colors.grey7B,
            colorDisabled: colors.greyC6,
            toggler: {
                width: '14px',
                height: '14px',
                margin: '3px',
            },
            checked: {
                backgroundColor: colors.white,
                borderColor: colors.blue007,
                togglerColor: colors.blue007,
                disabled: {
                    backgroundColor: colors.greyF2,
                    borderColor: colors.greyE6,
                    togglerColor: colors.blue009,
                },
                hover: {
                    backgroundColor: colors.white,
                    borderColor: colors.blue009,
                    togglerColor: colors.blue009,
                },
            },
            unchecked: {
                backgroundColor: colors.white,
                borderColor: colors.grey9C,
                togglerColor: colors.grey9C,
                disabled: {
                    backgroundColor: colors.greyF2,
                    borderColor: colors.greyE6,
                    togglerColor: colors.greyC6,
                },
                hover: {
                    backgroundColor: colors.white,
                    borderColor: colors.grey7B,
                    togglerColor: colors.grey7B,
                },
            },
        },
    },
    loader: {
        color: colors.blue009,
        backgroundColor: colors.blueEBF,
    },
    subtleLink: {
        color: colors.grey9C,
        colorHover: colors.black,
    },
    tabNav: {
        color: colors.grey7B,
        colorActive: colors.grey33,
        border: colors.blue007,
        borderHover: colors.greyF2,
    },
    navDropdown: {
        width: '160px',
        padding: '10px 24px',
        backgroundColor: colors.white,
        borderColor: colors.greyE6,
        color: colors.grey7B,
        colorHover: colors.black,
        fontSize: '16px',
        dropdown: {
            padding: '12px 0',
            backgroundColor: colors.white,
            color: colors.grey7B,
            colorHover: colors.black,
        },
    },
    icons: {
        plusDotIcon: {
            backgroundColor: colors.blueEBF,
            color: colors.blue007,
            margin: '0',
            size: 22,
        },
    },
    removableLabel: {
        backgroundColor: colors.greyF2,
        color: colors.black,
        fontSize: '12px',
        closeButton: {
            fill: colors.grey9B,
            backgroundColor: colors.greyF2,
        },
    },
    tagList: {
        margin: '-4px 0',
        item: {
            height: '26px',
            margin: '4px 8px 4px 0',
            padding: '4px 8px',
            backgroundColor: colors.greyFA,
            boxShadow: `0 2px 4px 0 ${colors.black05}`,
            color: colors.grey33,
            borderRadius: '13px',
            fontSize: '15px',
            close: {
                fill: colors.grey9B,
            },
        },
    },
};

export default defaultTheme;
