// @flow strict

import { fromJS } from 'immutable';

import {
    NOTIFICATIONS_ARE_QUERYING,
    NOTIFICATIONS_QUERY_SUCCESS,
    NOTIFICATIONS_QUERY_FAILURE,
    SET_READ_STATE,
    READ_STATE_UPDATE_SUCCESS,
    READ_STATE_UPDATE_FAILURE,
    MARK_ALL_AS_READ_IS_UPDATING,
    MARK_ALL_AS_READ_UPDATE_SUCCESS,
    MARK_ALL_AS_READ_UPDATE_FAILURE,
    UNREAD_TOTAL_IS_FETCHING,
    UNREAD_TOTAL_FETCH_SUCCESS,
    UNREAD_TOTAL_FETCH_FAILURE,
    SETTINGS_ARE_FETCHING,
    SETTINGS_FETCH_SUCCESS,
    SETTINGS_FETCH_FAILURE,
    SETTINGS_ARE_UPDATING,
    SETTINGS_UPDATE_SUCCESS,
    SETTINGS_UPDATE_FAILURE,
    PLANT_SETTINGS_ARE_FETCHING,
    PLANT_SETTINGS_FETCH_SUCCESS,
    PLANT_SETTINGS_FETCH_FAILURE,
    PLANT_SETTINGS_ARE_UPDATING,
    PLANT_SETTINGS_UPDATE_SUCCESS,
    PLANT_SETTINGS_UPDATE_FAILURE,
    SCHEDULE_IS_FETCHING,
    SCHEDULE_FETCH_SUCCESS,
    SCHEDULE_FETCH_FAILURE,
    SCHEDULE_IS_UPDATING,
    SCHEDULE_UPDATE_SUCCESS,
    SCHEDULE_UPDATE_FAILURE,
    NOTIFICATION_TYPE_USERS_STATUSES_ARE_FETCHING,
    NOTIFICATION_TYPE_USERS_STATUSES_FETCH_SUCCESS,
    NOTIFICATION_TYPE_USERS_STATUSES_FETCH_FAILURE,
    PHONE_REMOVAL_SUCCESS,
    PHONE_REMOVAL_FAILURE,
    PHONE_REMOVAL_IS_LOADING,
} from './actions';

// Types
import type { GenericActionType } from 'types';
import type { ImmutableNotificationsState } from 'services/Notifications/types';

const initialState: ImmutableNotificationsState = fromJS({
    errors: {},
    notificationsAreQuerying: false,
    queriedNotifications: [],
    currentPage: 0,
    lastPage: 0,
    markAllAsReadIsUpdating: false,
    unreadTotalIsFetching: false,
    unreadTotal: 0,
    settingsAreFetching: false,
    settingsAreUpdating: false,
    settings: [],
    plantSettingsAreFetching: false,
    plantSettingsAreUpdating: false,
    plantSettings: [],
    scheduleIsFetching: false,
    scheduleIsUpdating: false,
    schedule: [],
    notificationTypeUsersStatusesAreFetching: false,
    notificationTypeUsersStatuses: [],
    phoneRemovalIsLoading: false,
});

function notificationsServiceReducer(
    state: ImmutableNotificationsState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case NOTIFICATIONS_ARE_QUERYING: {
            return state.set('notificationsAreQuerying', action.payload.notificationsAreQuerying);
        }
        case NOTIFICATIONS_QUERY_SUCCESS: {
            const data = action.payload.data;

            const updatedQueriedNotifications =
                data.currentPage > 1
                    ? state.get('queriedNotifications').concat(fromJS(data.data))
                    : fromJS(data.data);

            return state
                .set('queriedNotifications', updatedQueriedNotifications)
                .set('currentPage', data.currentPage)
                .set('lastPage', data.lastPage)
                .set('notificationsAreQuerying', false)
                .set('errors', fromJS({}));
        }
        case NOTIFICATIONS_QUERY_FAILURE: {
            return state
                .set('notificationsAreQuerying', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case SET_READ_STATE: {
            const { notificationId, read } = action.payload;

            const notificationIndex = state
                .get('queriedNotifications')
                .findIndex((el) => el.get('id') === notificationId);
            const updatedQueriedNotifications = state
                .get('queriedNotifications')
                .setIn([notificationIndex, 'read'], read);

            const updatedUnreadTotal = read
                ? state.get('unreadTotal') - 1
                : state.get('unreadTotal') + 1;

            return state
                .set('queriedNotifications', updatedQueriedNotifications)
                .set('unreadTotal', updatedUnreadTotal);
        }
        case READ_STATE_UPDATE_SUCCESS: {
            return state.set('errors', fromJS({}));
        }
        case READ_STATE_UPDATE_FAILURE: {
            return state.set('errors', fromJS(action.payload.errors));
        }
        case MARK_ALL_AS_READ_IS_UPDATING: {
            return state.set('markAllAsReadIsUpdating', action.payload.markAllAsReadIsUpdating);
        }
        case MARK_ALL_AS_READ_UPDATE_SUCCESS: {
            const updatedQueriedNotifications = state
                .get('queriedNotifications')
                .map((el) => el.set('read', true));

            return state
                .set('queriedNotifications', updatedQueriedNotifications)
                .set('markAllAsReadIsUpdating', false)
                .set('unreadTotal', 0)
                .set('errors', fromJS({}));
        }
        case MARK_ALL_AS_READ_UPDATE_FAILURE: {
            return state
                .set('markAllAsReadIsUpdating', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case UNREAD_TOTAL_IS_FETCHING: {
            return state.set('unreadTotalIsFetching', action.payload.unreadTotalIsFetching);
        }
        case UNREAD_TOTAL_FETCH_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('unreadTotal', data.totalUnreadNotifications)
                .set('unreadTotalIsFetching', false)
                .set('errors', fromJS({}));
        }
        case UNREAD_TOTAL_FETCH_FAILURE: {
            return state
                .set('unreadTotalIsFetching', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case SETTINGS_ARE_FETCHING: {
            return state.set('settingsAreFetching', action.payload.settingsAreFetching);
        }
        case SETTINGS_FETCH_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('settings', fromJS(data))
                .set('settingsAreFetching', false)
                .set('errors', fromJS({}));
        }
        case SETTINGS_FETCH_FAILURE: {
            return state
                .set('settingsAreFetching', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case SETTINGS_ARE_UPDATING: {
            return state.set('settingsAreUpdating', action.payload.settingsAreUpdating);
        }
        case SETTINGS_UPDATE_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('settings', fromJS(data))
                .set('settingsAreUpdating', false)
                .set('errors', fromJS({}));
        }
        case SETTINGS_UPDATE_FAILURE: {
            return state
                .set('settingsAreUpdating', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case PLANT_SETTINGS_ARE_FETCHING: {
            return state.set('plantSettingsAreFetching', action.payload.plantSettingsAreFetching);
        }
        case PLANT_SETTINGS_FETCH_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('plantSettings', fromJS(data))
                .set('plantSettingsAreFetching', false)
                .set('errors', fromJS({}));
        }
        case PLANT_SETTINGS_FETCH_FAILURE: {
            return state
                .set('plantSettingsAreFetching', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case PLANT_SETTINGS_ARE_UPDATING: {
            return state.set('plantSettingsAreUpdating', action.payload.plantSettingsAreUpdating);
        }
        case PLANT_SETTINGS_UPDATE_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('plantSettings', fromJS(data))
                .set('plantSettingsAreUpdating', false)
                .set('errors', fromJS({}));
        }
        case PLANT_SETTINGS_UPDATE_FAILURE: {
            return state
                .set('plantSettingsAreUpdating', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case SCHEDULE_IS_FETCHING: {
            return state.set('scheduleIsFetching', action.payload.scheduleIsFetching);
        }
        case SCHEDULE_FETCH_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('schedule', fromJS(data))
                .set('scheduleIsFetching', false)
                .set('errors', fromJS({}));
        }
        case SCHEDULE_FETCH_FAILURE: {
            return state
                .set('scheduleIsFetching', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case SCHEDULE_IS_UPDATING: {
            return state.set('scheduleIsUpdating', action.payload.scheduleIsUpdating);
        }
        case SCHEDULE_UPDATE_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('schedule', fromJS(data))
                .set('scheduleIsUpdating', false)
                .set('errors', fromJS({}));
        }
        case SCHEDULE_UPDATE_FAILURE: {
            return state
                .set('scheduleIsUpdating', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case NOTIFICATION_TYPE_USERS_STATUSES_ARE_FETCHING: {
            return state.set(
                'notificationTypeUsersStatusesAreFetching',
                action.payload.notificationTypeUsersStatusesAreFetching
            );
        }
        case NOTIFICATION_TYPE_USERS_STATUSES_FETCH_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('notificationTypeUsersStatuses', fromJS(data))
                .set('notificationTypeUsersStatusesAreFetching', false)
                .set('errors', fromJS({}));
        }
        case NOTIFICATION_TYPE_USERS_STATUSES_FETCH_FAILURE: {
            return state
                .set('notificationTypeUsersStatusesAreFetching', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case PHONE_REMOVAL_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('notificationTypeUsersStatuses', fromJS(data))
                .set('errors', fromJS({}))
                .set('phoneRemovalIsLoading', false);
        }
        case PHONE_REMOVAL_FAILURE: {
            return state
                .set('phoneRemovalIsLoading', false)
                .set('errors', fromJS(action.payload.errors));
        }
        case PHONE_REMOVAL_IS_LOADING: {
            return state.set('phoneRemovalIsLoading', action.payload.phoneRemovalIsLoading);
        }
        default:
            return state;
    }
}

export default notificationsServiceReducer;
