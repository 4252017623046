// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { reportError } from 'services/Errors/thunks';

import type { ReduxDispatch, ErrorType } from 'types';

// Components
import AppLoader from 'components/AppLoader';
import { MicroFrontendWrapper } from './styles';

type Props = {
    host: string,
    name: string,
    mountedMicroFrontendProps: any,
    isLegacyScriptModule?: boolean,
    /**
     * An MFE is in full screen when it is the only component (except the header) on the page.
     * In pages that contain a sidebar still rendered by this repo (the MS-frontend, i.e.  the host),
     * then the sidebar layout component already removes the header's 61px height, so we don't need
     * to calc the height to remove the header since the sidebar layout removes it.
     *
     * In future iterations of the host application, this will not be necessary since the applications
     * themselves will be responsible for rendering their sidebars.
     *
     * Ex: In MinChem this is not necessary because it renders its own Sidebar.
     * Ex: In SolvExtract plant dashboard and configuration it is necessary
     *  because we use the legacy sidebars.
     */
    mfeIsInFullScreen?: boolean,
    onError: () => void,
    reportError: (error: ErrorType) => void,
};

class MicroFrontend extends React.PureComponent<Props> {
    componentDidMount() {
        this.loadMFE();
    }

    componentWillUnmount() {
        this.unloadMFE();
    }

    componentDidUpdate(prevProps: Props) {
        this.mountMicroFrontend();
    }

    /**
     * Unloads the MFE by calling the unmount function registered by the MFE.
     */
    unloadMFE = () => {
        const unmountFn = window[`unmount${this.props.name}`];
        if (unmountFn) {
            try {
                window[`unmount${this.props.name}`](`${this.props.name}-container`);
            } catch (e) {
                this.onError(e);
            }
        }
    };

    /**
     * When ever an error occurs during loading, let the parent know and report the error.
     */
    onError = (reason) => {
        this.props.onError();
        this.props.reportError({
            type: `front-end-error:error-loading-${this.props.name}-micro-frontend`,
            message: reason instanceof Error ? reason.message : 'Could not load script.',
            stack: reason instanceof Error ? reason.stack : 'No Stack Available.',
        });
    };

    /**
     * Fetch the micro-frontend manifest file, and then add a script with the main file of the MFE.
     */
    loadMFE = () => {
        const scriptElement = document.getElementById(this.getScriptId());
        if (scriptElement) {
            this.mountMicroFrontend();
            return;
        }

        const loadNextScript = this.loadNextMfeScript;
        const renderFn = this.mountMicroFrontend;
        const errorFn = this.onError;
        fetch(`${this.props.host}/static/asset-manifest.json`)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Response is not OK.');
                }
            })
            .then((manifest: any) => {
                return Promise.all(
                    Object.values(manifest.files).map((file) => {
                        if (file.endsWith('.js')) {
                            return loadNextScript(file);
                        }
                        return Promise.resolve(); // Add this line to handle non-JS files - like CSS - in the manifest. Otherwise, the promise chain will break. This does nothing, but resolves the promise.
                    })
                );
            })
            .then(renderFn)
            .catch(errorFn);
    };

    loadNextMfeScript = (file: string) => {
        const that = this;
        return new Promise(function(resolve, reject) {
            const script = document.createElement('script');
            script.id = that.getScriptId();
            script.crossOrigin = '';
            script.src = `${that.props.host}${file}`;
            if (!that.props.isLegacyScriptModule) {
                script.type = 'module';
            }

            // script.onload = renderFn;
            script.onload = resolve;
            script.onerror = reject;
            script.onabort = reject;
            (document.head || document.body || document).appendChild(script);
        });
    };

    /**
     * A unique ID for each microfrontend script to identify and remove later.
     */
    getScriptId = () => {
        const scriptId = `micro-frontend-script-${this.props.name}`;
        return scriptId;
    };

    getContainerId = () => `${this.props.name}-container`;

    /**
     * Mount the MFE in react in the container `{mfe-name}-container`
     */
    mountMicroFrontend = () => {
        const mountFnName = `mount${this.props.name}`;
        const mountFn = window[mountFnName];
        if (mountFn) {
            const params = this.props.mountedMicroFrontendProps;
            try {
                mountFn(this.getContainerId(), params);
            } catch (e) {
                this.onError(e);
            }
        }
    };

    render() {
        return (
            <MicroFrontendWrapper
                id={this.getContainerId()}
                excludeHeader={this.props.mfeIsInFullScreen}
            >
                <AppLoader messageId="microfrontends.loadingMFE" loading />
            </MicroFrontendWrapper>
        );
    }
}

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            reportError,
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(MicroFrontend);
