// @flow strict

import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;

    label {
        text-indent: -25px;
        padding-left: 25px;
    }
`;

export const YAxisInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
