/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Controls = styled.div`
    display: flex;
    flex-direction: ${({ displayVertically }: Object) => (displayVertically ? 'column' : 'row')};
`;

export const ReorderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
    padding: 2px;
    background-color: ${LegacyTheme.defaultBorder};
    border-radius: 50%;

    transition: 0.25s;
    cursor: pointer;

    :hover {
        background-color: ${LegacyTheme.buttonPrimary};
    }

    & + & {
        margin-left: ${({ displayVertically }: Object) => (displayVertically ? 0 : '8px')};
        margin-top: ${({ displayVertically }: Object) => (displayVertically ? '8px' : 0)};
    }
`;
