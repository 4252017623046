// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    padding: 0px 24px 12px 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0px 24px 20px 24px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    border-top: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 7px;

    * + * {
        // The input fields of each field.
        margin-top: 8px;
    }
`;

export const Label = styled.label`
    font-size: 12px;
    line-height: 16px;
`;

export const LargeInputBox = styled.textarea`
    width: 100%;
    height: 83px;
    min-height: 83px;
    padding: 7px 16px;
    resize: none;

    font-size: 12px;
    color: ${(props) =>
        props.disabled ? LegacyTheme.disabledInputColor : LegacyTheme.defaultColor};

    background-color: ${LegacyTheme.defaultBackground};
    border-radius: 4px;
    border: 1px solid
        ${(props) => (props.disabled ? LegacyTheme.disabledBorder : LegacyTheme.defaultBorder)};
    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: ${LegacyTheme.blue009};
    }

    &::placeholder {
        color: ${(props) =>
            props.disabled ? LegacyTheme.disabledPlaceholder : LegacyTheme.disabledInputColor};
    }
`;
