// @flow

import legacyTheme from '../../themes/legacyTheme';

export const victoryAxisProperties = {
    axisLabel: {
        padding: 50,
        fontSize: 12,
        fill: legacyTheme.graphAxisLabelColor,
        fontFamily: 'Helvetica, Arial, sans-serif',
    },
    axis: {
        stroke: legacyTheme.graphAxisStrokeColor,
        strokeWidth: 1,
    },
    grid: {
        stroke: legacyTheme.graphAxisGridStrokeColor,
        strokeWidth: 1,
    },
    tickLabels: {
        fill: legacyTheme.graphAxisLabelColor,
        fontSize: 12,
        fontFamily: 'Helvetica, Arial, sans-serif',
    },
};

export const victoryYAxisSpecificProperties = {
    ticks: {
        size: 25,
        strokeWidth: 1,
        stroke: legacyTheme.graphAxisGridStrokeColor,
        padding: 5,
    },
    tickLabels: {
        fill: legacyTheme.graphAxisLabelColor,
        fontSize: 12,
        fontFamily: 'Helvetica, Arial, sans-serif',
    },
};

export const victoryData = {
    data: {
        fillOpacity: 0.2,
        strokeWidth: 3,
    },
};

export const victoryChartPadding = {
    top: 25,
    right: 15,
    bottom: 80,
    left: 80,
};

export const victoryChartDimensions = {
    width: 330,
    height: 330,
};
