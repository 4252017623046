// @flow

import React from 'react';

import type { InputValueType } from '../../types';

// Styles
import { Input } from './styles';
import { InputBase as StyledInputBase, InputValue as StyledInputValue } from './base';

type Props = {
    id?: number,
    key?: ?string,
    name?: string,
    placeholder?: string,
    renderString?: boolean,
    type?: string,
    value?: InputValueType,
};

export default class InputField extends React.PureComponent<Props> {
    static defaultProps = {
        id: null,
        key: null,
        name: 'InputField',
        placeholder: '',
        renderString: false,
        type: 'text',
        value: '',
    };

    render() {
        if (!this.props.type) {
            return null;
        }

        if (this.props.renderString) {
            return <StyledInputValue>{this.props.value}</StyledInputValue>;
        }

        return (
            <Input
                key={this.props.key || this.props.name || this.props.id}
                type={this.props.type}
                value={this.props.value}
                placeholder={this.props.placeholder}
                {...this.props}
            />
        );
    }
}

export { StyledInputBase, StyledInputValue };
