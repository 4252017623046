// @flow strict

import React from 'react';

// Style
import { StreamValuesWrapper } from './styles';

// Constants
import { DIAGRAM_DISPLAY_MODES, STREAM_TYPES, STAGE_TYPES } from 'utils/constants';
import { COMPUTE_GRID, SETUP_GRID } from 'components/MimicDiagram/constants';

// Components
import StreamValueInputBox from 'components/MimicDiagram/Stream/StreamValueInputBox';
import SetupStreamOption from 'components/MimicDiagram/Stream/SetupStreamOption';

// Types
import type { UnitsConstant } from 'types';
import type { StreamEntity, StageTypeConstant } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    streamData: StreamEntity,
    circuitUnits: UnitsConstant,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,

    toStageColumn: number,
    toStageRow: number,
    toStageType: StageTypeConstant,
    isFromOrToLOTank: boolean,
    isToNearestExtractorStreamFromLOTank: boolean,

    values: ?Array<LooseStreamValue>,

    setStreamValue: SetStreamValueFunction,
    onRemoveAdvancedStreamValueClicked: (streamData: StreamEntity) => void,
};

/**
 * Vertical Stream Values are those that belong to organic circuit and placed between two stages of different type
 * on the vertical organic stream path.
 */
export default class BypassFeedBlendStream extends React.PureComponent<Props, null> {
    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * Get the starting column of the stream values.
     */
    getStartingColumn = () => {
        const grid = this.getGrid();
        if (this.props.toStageType === STAGE_TYPES.EXTRACT) {
            if (this.props.isToNearestExtractorStreamFromLOTank) {
                return (
                    this.props.toStageColumn +
                    grid.STAGE.COLUMN_SPAN +
                    grid.TANK_BYPASS_STREAM.FEED_HORIZONTAL_OFFSET_FIRST
                );
            }
            // TODO: We should use the stage position of the NEXT stage...
            // This will allow us to get rid of below:
            let blendOffset = 1; // Leave 1 column for the feed entry.
            if (
                this.props.streamData.streamType === STREAM_TYPES.BYPASS_BLEND &&
                this.props.displayMode !== DIAGRAM_DISPLAY_MODES.SETUP
            ) {
                blendOffset = grid.STREAM_VALUES.COLUMN_SPAN;
            }
            // Otherwise the starting column will not be properly calculated when a bleed/blend is present in PLS stream.
            return (
                this.props.toStageColumn +
                grid.STAGE.COLUMN_SPAN +
                grid.STREAM_VALUES.COLUMN_SPAN +
                blendOffset
            );
        } else {
            return (
                this.props.toStageColumn -
                grid.STAGE.COLUMN_SPAN -
                grid.STREAM_VALUES.COLUMN_SPAN -
                1
            ); // minus 1 column for the feed entry.
        }
    };

    /**
     * Calculate stream value starting row position on the CSS grid
     */
    getStartingRow = () => {
        const grid = this.getGrid();
        if (this.props.toStageType === STAGE_TYPES.EXTRACT) {
            if (this.props.isToNearestExtractorStreamFromLOTank) {
                return this.props.toStageRow + grid.STAGE.ROW_SPAN;
            } else if (this.props.isFromOrToLOTank) {
                return (
                    this.props.toStageRow +
                    grid.STAGE.ROW_SPAN +
                    grid.TANK_BYPASS_STREAM.FEED_VERTICAL_OFFSET -
                    grid.TANK_BYPASS_STREAM.ROW_SPAN -
                    1
                );
            }
            return this.props.toStageRow + grid.STAGE.ROW_SPAN;
        } else {
            return this.props.toStageRow - grid.BYPASS_STREAM.FEED_ROW_SPAN;
        }
    };

    getComputeRowSpan = () => {
        if (this.props.isToNearestExtractorStreamFromLOTank) {
            return COMPUTE_GRID.TANK_BYPASS_STREAM.ROW_SPAN;
        } else {
            return COMPUTE_GRID.BYPASS_STREAM.FEED_ROW_SPAN;
        }
    };

    /**
     * If the user wants to delete the skip stream.
     */
    onRemoveAdvancedValues = () =>
        this.props.onRemoveAdvancedStreamValueClicked &&
        this.props.onRemoveAdvancedStreamValueClicked(this.props.streamData);

    renderStreamValues = () =>
        this.props.values.map((streamValue: LooseStreamValue) => (
            <StreamValuesWrapper
                key={`${streamValue.valueType}`}
                startingColumn={this.getStartingColumn()}
                startingRow={this.getStartingRow()}
                columnSpan={COMPUTE_GRID.BYPASS_STREAM.COLUMN_SPAN}
                rowSpan={this.getComputeRowSpan()}
            >
                <StreamValueInputBox
                    streamValue={streamValue}
                    circuitUnits={this.props.circuitUnits}
                    streamCircuit={this.props.streamData.streamCircuit}
                    setStreamValue={this.props.setStreamValue}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                />
            </StreamValuesWrapper>
        ));

    renderCloseLabel = () => {
        if (this.props.isToNearestExtractorStreamFromLOTank) {
            return null;
        }
        return (
            <StreamValuesWrapper
                startingColumn={this.getStartingColumn()}
                startingRow={this.getStartingRow()}
                columnSpan={SETUP_GRID.CONTINUE_STREAM_VALUES.COLUMN_SPAN}
                rowSpan={SETUP_GRID.CONTINUE_STREAM_VALUES.ROW_SPAN}
            >
                <SetupStreamOption
                    handleRemoveAdvancedValue={this.onRemoveAdvancedValues}
                    streamType={this.props.streamData.streamType}
                    streamCircuit={this.props.streamData.streamCircuit}
                    showCloseButton // feeds always have their close button shown.
                />
            </StreamValuesWrapper>
        );
    };

    render() {
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            return this.renderCloseLabel();
        } else {
            return this.renderStreamValues();
        }
    }
}
