// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

export const GroupWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 40px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: 0px;
        margin-bottom: ${STYLE_VALUES.RESPONSIVE_SIDEBAR.COLLAPSED_HEIGHT};
    }
`;

export const CardGroup = styled.div`
    display: flex;
    flex-direction: column;

    margin: 20px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        margin: 20px 0;
    }
`;

export const GroupTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: ${LegacyTheme.defaultSubtleColor};

    margin: 0 10px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        margin: 0 5px;
    }
`;

export const GroupLine = styled.div`
    display: flex;
    flex: 1;
    height: 1px;
    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;

export const GroupTitle = styled.div`
    flex: 0;
    padding: 0 24px;
    font-size: 16px;

    white-space: nowrap;
`;

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 20px;

    /* KPI Cards are "Triggers" to the Modal component, we want the "Trigger" wrapper to be a flex element */
    > div {
        display: flex;
    }
`;
