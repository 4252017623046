/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { STYLE_VALUES } from 'utils/constants';

export const ElevationContent = styled.div`
    display: flex;
    flex: 1;

    width: 100%;
    max-width: ${({ maxWidth }: Object) => maxWidth || STYLE_VALUES.SCREEN.MAX_WIDTH};

    padding: ${STYLE_VALUES.GUTTERS.LARGE} ${STYLE_VALUES.GUTTERS.LARGE}
        ${STYLE_VALUES.GUTTERS.NORMAL};
`;

export const ElevationWrapper = styled.div`
    position: relative;

    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    max-width: none; // Else the scroll bar will be inset
    margin: 0 auto;
    padding: ${STYLE_VALUES.GUTTERS.LARGE} 0 0;

    overflow: hidden;
    overflow-y: auto;

    max-height: calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT} - ${STYLE_VALUES.FOOTER.MIN_HEIGHT});
`;
