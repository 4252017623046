// @flow

import React from 'react';
import type { ChildrenType } from '../types';

import {
    StyledBlankButton,
    StyledCentered,
    StyledColumn,
    StyledOverflowBody,
    StyledOverflowContainer,
    StyledOverflowEnd,
    StyledRow,
} from './styles';

export const Column = (props: { children?: ChildrenType } = { children: null }) => (
    <StyledColumn {...props}>{props.children}</StyledColumn>
);

export const Row = (props: { children?: ChildrenType } = { children: null }) => (
    <StyledRow {...props}>{props.children}</StyledRow>
);

export const Centered = (props: { children?: ChildrenType } = { children: null }) => (
    <StyledCentered {...props}>{props.children}</StyledCentered>
);

export const BlankButton = (props: { children?: ChildrenType } = { children: null }) => (
    <StyledBlankButton {...props}>{props.children}</StyledBlankButton>
);

export const OverflowContainer = (props: { children?: ChildrenType } = { children: null }) => (
    <StyledOverflowContainer {...props}>{props.children}</StyledOverflowContainer>
);

export const OverflowBody = (props: { children?: ChildrenType } = { children: null }) => (
    <StyledOverflowBody {...props}>{props.children}</StyledOverflowBody>
);

export const OverflowEnd = (props: { children?: ChildrenType } = { children: null }) => (
    <StyledOverflowEnd {...props}>{props.children}</StyledOverflowEnd>
);
