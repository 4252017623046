// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';
import { colors } from 'styles/colors';
import { Z_INDEX_VALUES } from 'styles/zIndexes';

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 25px;
    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        margin-right: 12px;
    }

    height: 100%;
    width: 30px;

    :hover {
        cursor: pointer;
        color: ${colors.grey7B};
    }
`;

export const NotificationsDrawerWrapper = styled.div`
    display: flex;
    position: absolute;
    top: ${STYLE_VALUES.HEADER.HEIGHT};
    margin-top: -1px;
    right: 0;
    z-index: ${Z_INDEX_VALUES.HEADER_SECTION};

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        top: 0;
    }
`;

export const NotificationsDrawerContent = styled.div`
    display: flex;
    flex-direction: column;

    border: 1px solid ${LegacyTheme.defaultBorder};
    background-color: ${LegacyTheme.white};
    width: 100vw;
    max-width: 550px;

    padding: 16px 0 16px 16px;
    max-height: 600px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        height: calc(100vh - ${STYLE_VALUES.RESPONSIVE_SIDEBAR.COLLAPSED_HEIGHT_RAW - 2}px);
        max-height: unset;
    }
`;

export const CloseButton = styled.div`
    display: none;
    margin: 0 6px 0 auto;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        display: block;
    }
`;

export const NotificationsDrawerTopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NotificationsList = styled.ul`
    overflow-y: auto;
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

export const LoadingDotsWrapper = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
`;

export const MarkAllAsRead = styled.p`
    color: ${colors.grey7B};
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 4px;
    text-align: right;
    font-size: 14px;

    &:hover {
        color: ${LegacyTheme.black};
    }
`;

export const ToolTipContent = styled.div`
    color: ${colors.grey7B};
    font-size: 15px;
    padding: 6px;
`;

export const InlineLoadingDotsWrapper = styled.div`
    padding: 6px 6px 6px 0;
    text-align: right;
`;
