// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Mimic Engine
import type {
    IMimicCircuit,
    IContinueStream,
    IMimicLoadedOrganicTank,
} from 'components/_McCabeThiele';

// Assets
import {
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
    RadioButtonSet,
    CheckBox,
    Modal,
} from 'components/_ReactUI_V1';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    BypassLabel,
    RadioStyles,
    ImageStyle,
    RadioSuffixWrapper,
} from './styles';

// Components
import { Title } from 'styles/common';
import TankSelectBody, {
    TANK_STREAM_DIRECTIONS,
    type TankStreamDirections,
} from 'components/TankSelectBody';

// Types
import type { IntlType, InputEvent } from 'types';
import type { OrganicInExtractOptionConstant } from 'services/Circuit/types';

// constants
import {
    MODAL_WIDTH,
    ORGANIC_IN_EXTRACT_STREAM_OPTIONS,
    NUMBER_INPUT_PLACEHOLDER,
    NEW_TANK_OPTION,
} from 'utils/constants';

// Preview Assets:
import DirectAqueousStreamImage from 'assets/mimic-diagrams-stream-images/organic-aqueous-direct-feed.jpg';
import DirectElectrolyteStreamImage from 'assets/mimic-diagrams-stream-images/organic-electrolyte-direct-feed.jpg';

import BypassAqueousStreamImage from 'assets/mimic-diagrams-stream-images/organic-aqueous-bypass-feed.jpg';
import BlendInAqueousStreamImage from 'assets/mimic-diagrams-stream-images/organic-aqueous-blend-in-feed.jpg';

import BypassElectrolyteStreamImage from 'assets/mimic-diagrams-stream-images/organic-electrolyte-bypass-feed.jpg';

import OrganicExtractToExistingLOTank from 'assets/mimic-diagrams-stream-images/organic-extract-to-existing-lo_tank.jpg';
import OrganicExtractToNewLOTank from 'assets/mimic-diagrams-stream-images/organic-extract-to-new-lo_tank.jpg';

import OrganicBypassLOTankToExtract from 'assets/mimic-diagrams-stream-images/organic-bypass-lo_tank-to-extract.jpg';
import OrganicBlendLOTankToExtract from 'assets/mimic-diagrams-stream-images/organic-blend-lo_tank-to-extract.jpg';

import OrganicFromAndToNewLOTank from 'assets/mimic-diagrams-stream-images/organic-from-and-to-new-lo_tank.jpg';
import OrganicFromAndToExistingLOTank from 'assets/mimic-diagrams-stream-images/organic-from-and-to-existing-lo_tank.jpg';
import OrganicFromExistingLOAndToNewLO from 'assets/mimic-diagrams-stream-images/organic-from-existing-lo_tank-and-to-new-lo_tank.jpg';

export const PREVIEW_TYPES = {
    // Defaults:
    DIRECT_PLS: 'DIRECT_PLS',

    // Extract Stage Bypassing:
    EXTRACT_ONLY_BYPASS: 'EXTRACT_ONLY_BYPASS',
    EXTRACT_ONLY_BLEND: 'EXTRACT_ONLY_BLEND',

    // Extract to a Loaded Organic Tank
    EXTRACT_TO_EXISTING_LO_TANK: 'EXTRACT_TO_EXISTING_LO_TANK',
    EXTRACT_TO_NEW_LO_TANK: 'EXTRACT_TO_NEW_LO_TANK',

    // From Loaded Organic Tank to Extract
    BYPASS_LO_TANK_TO_EXTRACT: 'BYPASS_LO_TANK_TO_EXTRACT',
    BLEND_LO_TANK_TO_EXTRACT: 'BLEND_LO_TANK_TO_EXTRACT',

    // Mixes:
    FROM_AND_TO_NEW_LO_TANK: 'FROM_AND_TO_NEW_LO_TANK',
    FROM_AND_TO_EXISTING_LO_TANK: 'FROM_AND_TO_EXISTING_LO_TANK',
    FROM_EXISTING_LO_AND_TO_NEW_LO: 'FROM_EXISTING_LO_AND_TO_NEW_LO',
};

const PREVIEW_IMAGES = {
    // Defaults:
    [PREVIEW_TYPES.DIRECT_PLS]: DirectAqueousStreamImage,

    // Extract Stage Bypassing:
    [PREVIEW_TYPES.EXTRACT_ONLY_BYPASS]: BypassAqueousStreamImage,
    [PREVIEW_TYPES.EXTRACT_ONLY_BLEND]: BlendInAqueousStreamImage,

    // Extract to a Loaded Organic Tank
    [PREVIEW_TYPES.EXTRACT_TO_EXISTING_LO_TANK]: OrganicExtractToExistingLOTank,
    [PREVIEW_TYPES.EXTRACT_TO_NEW_LO_TANK]: OrganicExtractToNewLOTank,

    // From Loaded Organic Tank to Extract
    [PREVIEW_TYPES.BYPASS_LO_TANK_TO_EXTRACT]: OrganicBypassLOTankToExtract,
    [PREVIEW_TYPES.BLEND_LO_TANK_TO_EXTRACT]: OrganicBlendLOTankToExtract,

    // Mixes:
    [PREVIEW_TYPES.FROM_AND_TO_NEW_LO_TANK]: OrganicFromAndToNewLOTank,
    [PREVIEW_TYPES.FROM_AND_TO_EXISTING_LO_TANK]: OrganicFromAndToExistingLOTank,
    [PREVIEW_TYPES.FROM_EXISTING_LO_AND_TO_NEW_LO]: OrganicFromExistingLOAndToNewLO,
};

type OptionType = { label: string, value: OrganicInExtractOptionConstant };

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    mimicCircuit: IMimicCircuit,
    mimicStream: IContinueStream,

    currentLocation: number, // Stage location of the previous settler in the stream

    onConfirm: (
        organicStreamOption: OrganicInExtractOptionConstant,
        currentLocation: number,
        isExtractionSection: boolean,
        tankOptions: Object | null
    ) => void,
    onCancel: () => void,
};

type State = {
    // the keys used by the radio button set
    selectedOrganicStreamOption: OrganicInExtractOptionConstant,
    toTank: IMimicLoadedOrganicTank | typeof NEW_TANK_OPTION | null,
    fromTank: IMimicLoadedOrganicTank | typeof NEW_TANK_OPTION | null,
    isAlsoToPreviousLoadedOrganicTank: boolean,
};

/**
 * Shown when one of the mimic diagram edit button is clicked between an aqueous and an organic stage or between an electrolyte and organic stage
 */
class OrganicInExtractStreamSelectModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedOrganicStreamOption: ORGANIC_IN_EXTRACT_STREAM_OPTIONS.DIRECT,
            toTank: null,
            fromTank: null,
            isAlsoToPreviousLoadedOrganicTank: false,
        };
    }

    getTranslation(id: string) {
        return this.props.intl.formatMessage({
            id: `components.Modals.OrganicStreamSelectModal.${id}`,
        });
    }

    /**
     * Get the Organic stream radio options depending on the modalType
     */
    getOrganicStreamOptions = (): Array<OptionType> =>
        Object.keys(ORGANIC_IN_EXTRACT_STREAM_OPTIONS).map(
            (value: OrganicInExtractOptionConstant) => ({
                value,
                label: this.props.intl.formatMessage({
                    id: `components.Modals.OrganicStreamSelectModal.${value}`,
                }),
            })
        );

    getExtractBypassPreviewImage = () => {
        const isToTank = Boolean(this.state.toTank);
        const isFromTank = Boolean(this.state.fromTank);
        const isToNew = isToTank && this.state.toTank === NEW_TANK_OPTION;
        const isFromNew = isFromTank && this.state.fromTank === NEW_TANK_OPTION;

        if (isToNew && isFromNew) {
            return PREVIEW_TYPES.FROM_AND_TO_NEW_LO_TANK;
        }
        if (isToTank && !isToNew && isFromTank && !isFromNew) {
            return PREVIEW_TYPES.FROM_AND_TO_EXISTING_LO_TANK;
        }
        if (isFromTank && !isFromNew && isToNew) {
            return PREVIEW_TYPES.FROM_EXISTING_LO_AND_TO_NEW_LO;
        }
        if (isToTank && !isToNew) {
            return PREVIEW_TYPES.EXTRACT_TO_EXISTING_LO_TANK;
        }
        if (isToNew) {
            return PREVIEW_TYPES.EXTRACT_TO_NEW_LO_TANK;
        }
        if (isFromTank && !isFromNew) {
            return PREVIEW_TYPES.BYPASS_LO_TANK_TO_EXTRACT;
        }
        return PREVIEW_TYPES.EXTRACT_ONLY_BYPASS;
    };

    getPreviewImageType = () => {
        switch (this.state.selectedOrganicStreamOption) {
            case ORGANIC_IN_EXTRACT_STREAM_OPTIONS.DIRECT:
                return PREVIEW_TYPES.DIRECT_PLS;

            case ORGANIC_IN_EXTRACT_STREAM_OPTIONS.BYPASS:
                return this.getExtractBypassPreviewImage();
            case ORGANIC_IN_EXTRACT_STREAM_OPTIONS.BYPASS_BLEND:
                if (this.state.fromTank) {
                    return PREVIEW_TYPES.BLEND_LO_TANK_TO_EXTRACT;
                } else {
                    return PREVIEW_TYPES.EXTRACT_ONLY_BLEND;
                }

            default:
                throw new Error('Unknown organic stream radio choice selected...');
        }
    };

    getTankOptionsForOnConfirm = () => {
        if (!this.state.toTank && !this.state.fromTank) {
            return null;
        }
        return {
            toTank: this.state.toTank,
            fromTank: this.state.fromTank,
            isAlsoToPreviousLoadedOrganicTank: this.state.isAlsoToPreviousLoadedOrganicTank,
        };
    };

    /**
     * Buttons is disabled when Series configuration is selected since it is the default selection and does not require any change
     */
    isSelectButtonDisabled = (): boolean =>
        this.state.selectedOrganicStreamOption === ORGANIC_IN_EXTRACT_STREAM_OPTIONS.DIRECT;

    /**
     * When the select button is clicked, call our parent with the options selected
     */
    handleSelectClicked = () =>
        this.props.onConfirm(
            this.state.selectedOrganicStreamOption,
            this.props.currentLocation,
            true,
            this.getTankOptionsForOnConfirm()
        );

    /**
     * When the Cascade RF radio buttons is changed
     */
    handleOrganicStreamOptionChange = (
        selectedOrganicStreamOption: OrganicInExtractOptionConstant
    ) =>
        this.setState({
            selectedOrganicStreamOption,
            fromTank: null,
            toTank: null,
        });

    handleSelectToPreviousLoadedOrganic = (isAlsoToPreviousLoadedOrganicTank: boolean): void => {
        this.setState({
            isAlsoToPreviousLoadedOrganicTank,
        });
    };

    handleSelectTank = (direction: TankStreamDirections) => (
        tank: IMimicLoadedOrganicTank | typeof NEW_TANK_OPTION | null
    ) => {
        if (direction === TANK_STREAM_DIRECTIONS.FROM_TANK) {
            this.setState({
                fromTank: tank,
            });
        } else {
            this.setState({
                toTank: tank,
            });
        }
    };

    renderTankSelector = (direction: TankStreamDirections) => (
        <TankSelectBody
            mimicCircuit={this.props.mimicCircuit}
            mimicStream={this.props.mimicStream}
            streamDirection={direction}
            isBlendMode={
                this.state.selectedOrganicStreamOption ===
                ORGANIC_IN_EXTRACT_STREAM_OPTIONS.BYPASS_BLEND
            }
            hasNewToTank={this.state.toTank === NEW_TANK_OPTION}
            onSelect={this.handleSelectTank(direction)}
            onSelectToPreviousLoadedOrganic={this.handleSelectToPreviousLoadedOrganic}
        />
    );

    renderExtractionBypassSuffix = (value: OrganicInExtractOptionConstant) => {
        if (value === ORGANIC_IN_EXTRACT_STREAM_OPTIONS.BYPASS) {
            return (
                <React.Fragment>
                    {this.renderTankSelector(TANK_STREAM_DIRECTIONS.FROM_TANK)}
                    {this.renderTankSelector(TANK_STREAM_DIRECTIONS.TO_TANK)}
                </React.Fragment>
            );
        } else if (value === ORGANIC_IN_EXTRACT_STREAM_OPTIONS.BYPASS_BLEND) {
            return this.renderTankSelector(TANK_STREAM_DIRECTIONS.FROM_TANK);
        }
        return null; // no suffix for these types.
    };

    /**
     * Render the warning label for the bypass option when the modalType is ELECTROLYTE-ORGANIC
     */
    renderBypassSuffixes = (value: OrganicInExtractOptionConstant, isActive: boolean) => {
        if (!isActive) {
            return null;
        }
        return <RadioSuffixWrapper>{this.renderExtractionBypassSuffix(value)}</RadioSuffixWrapper>;
    };

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.OrganicStreamSelectModal.title',
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <RadioButtonSet
                                value={this.state.selectedOrganicStreamOption}
                                options={this.getOrganicStreamOptions()}
                                onClick={this.handleOrganicStreamOptionChange}
                                styles={RadioStyles}
                                renderSuffix={this.renderBypassSuffixes}
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.Modals.preview',
                                })}
                            </Label>
                            <img
                                src={PREVIEW_IMAGES[this.getPreviewImageType()]}
                                alt={this.props.intl.formatMessage({
                                    id: `components.Modals.OrganicStreamSelectModal.${
                                        this.state.selectedOrganicStreamOption
                                    }`,
                                })}
                                style={ImageStyle}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <TertiaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.selectButton',
                            })}
                            onClick={this.handleSelectClicked}
                            disabled={this.isSelectButtonDisabled()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(OrganicInExtractStreamSelectModal);
