// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Message } from './styles';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
    messageId: string,
};

class ComputationInstructions extends React.PureComponent<Props, null> {
    render() {
        return (
            <Wrapper>
                <Message>
                    {this.props.intl.formatMessage({
                        id: `components.ComputationInstructions.${this.props.messageId}`,
                    })}
                </Message>
            </Wrapper>
        );
    }
}

export default injectIntl(ComputationInstructions);
