// @flow

import React from 'react';
import { Caret } from '../../icons';

import type { ReactNode } from '../../types';

// Styles
import { CaretWrapper, Content, Header, Title, Wrapper } from './styles';

type Props = {
    children?: ReactNode,
    closedByDefault?: boolean,
    padding?: string,
    title?: string,
};

type State = {
    open: boolean,
};

export default class CollapsibleBlock extends React.Component<Props, State> {
    static defaultProps = {
        children: null,
        closedByDefault: false,
        padding: null,
        title: 'Title',
    };

    state = {
        open: !this.props.closedByDefault,
    };

    handleToggleOpenState = () => {
        this.setState((prevState: State) => ({
            open: !prevState.open,
        }));
    };

    render() {
        if (!this.props.children) {
            return null;
        }

        return (
            <Wrapper open={this.state.open}>
                <Header onClick={this.handleToggleOpenState} open={this.state.open}>
                    <Title>{this.props.title}</Title>
                    <CaretWrapper>
                        <Caret up={!this.state.open} down={this.state.open} black />
                    </CaretWrapper>
                </Header>
                {this.state.open && (
                    <Content padding={this.props.padding}>{this.props.children}</Content>
                )}
            </Wrapper>
        );
    }
}
