// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import OximeManager from 'components/OximeManager';

// Selectors
import {
    selectOximeErrors,
    selectOximesAreDeleting,
    selectOximesAreQuerying,
    selectOximesAreUpdating,
    selectQueriedOximes,
    selectQueriedOximesLastPage,
} from 'services/Oxime/selectors';

// Thunks
import { createOxime, destroyOxime, queryAllOximes, updateOxime } from 'services/Oxime/thunks';

// Types
import type { ErrorType, ImmutableList, ReduxDispatch, SearchCriteria } from 'types';
import type { ImmutableOxime, Oxime } from 'services/Oxime/types';

type Props = {
    errors: ErrorType,

    lastPage: number,
    oximes: ImmutableList<ImmutableOxime>,
    oximeIsDeleting: boolean,
    oximeIsUpdating: boolean,
    oximesAreQuerying: boolean,

    destroyOxime: (number) => void,
    queryAllOximes: (SearchCriteria, number) => void,
    updateOxime: (number, Oxime) => void,
    createOxime: (Oxime) => void,
};

type State = {
    pagination: {
        page: number,
    },
    searchCriteria: SearchCriteria,
};

// Constants
const DEFAULT_PAGE = 1;
const DEFAULT_SORTBY = 'id';
const DEFAULT_SORTORDER = 'desc';

class OximeContainer extends React.PureComponent<Props, State> {
    state = {
        pagination: {
            page: DEFAULT_PAGE,
        },
        searchCriteria: {
            sortBy: DEFAULT_SORTBY,
            sortOrder: DEFAULT_SORTORDER,
        },
    };

    componentDidMount() {
        if (this.props.oximes.isEmpty()) {
            this.props.queryAllOximes(this.state.searchCriteria, this.state.pagination.page);
        }
    }

    /**
     * On pagination change, update state with provided page
     * Fire props.queryAllOximes() with updated searchCriteria and page
     */
    handlePaginationPageSelection = (page: number, total: number) => () => {
        if (page > total || page < 0) {
            return null;
        }

        return this.setState(
            {
                pagination: {
                    page,
                },
            },
            () => this.onQueryAllOximes()
        );
    };

    /**
     * On table sorting, set provided sortBy & sortOrder to state.searchCriteria
     * Reset state.pagination.page to default
     * Fire props.queryAllOximes() with updated searchCriteria and page
     */
    handleSortBy = (searchCriteria: SearchCriteria) => {
        this.setState(
            {
                searchCriteria,
                pagination: {
                    page: DEFAULT_PAGE,
                },
            },
            () => this.onQueryAllOximes()
        );
    };

    onCreateOxime = (oxime: ImmutableOxime) => {
        this.props.createOxime(oxime.toJS(), true);
    };

    /**
     * Fires props.queryAllOximes
     * If provided reset boolean is true, reset searchCriteria to defaults first.
     */
    onQueryAllOximes = (reset: boolean = false) => {
        if (reset) {
            this.setState(
                {
                    searchCriteria: {
                        sortBy: DEFAULT_SORTBY,
                        sortOrder: DEFAULT_SORTORDER,
                    },
                    pagination: {
                        page: DEFAULT_PAGE,
                    },
                },
                () =>
                    this.props.queryAllOximes(this.state.searchCriteria, this.state.pagination.page)
            );
        } else {
            return this.props.queryAllOximes(this.state.searchCriteria, this.state.pagination.page);
        }
    };

    render() {
        return (
            <OximeManager
                errors={this.props.errors}
                handleCreateNewOxime={this.onCreateOxime}
                handleDeleteOxime={this.props.destroyOxime}
                handleUpdateOxime={this.props.updateOxime}
                lastPage={this.props.lastPage}
                onHandlePaginationPageSelection={this.handlePaginationPageSelection}
                onHandleSortBy={this.handleSortBy}
                oximes={this.props.oximes}
                pagination={this.state.pagination}
                oximeIsDeleting={this.props.oximeIsDeleting}
                oximeIsUpdating={this.props.oximeIsUpdating}
                oximesAreQuerying={this.props.oximesAreQuerying}
                searchCriteria={this.state.searchCriteria}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectOximeErrors(),
    lastPage: selectQueriedOximesLastPage(),
    oximes: selectQueriedOximes(),
    oximeIsDeleting: selectOximesAreDeleting(),
    oximeIsUpdating: selectOximesAreUpdating(),
    oximesAreQuerying: selectOximesAreQuerying(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createOxime,
            destroyOxime,

            queryAllOximes,
            updateOxime,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OximeContainer)
);
