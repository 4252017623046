// @flow strict

import type { ReduxDispatch, ResponseErrorType } from 'types';
import { index } from './resources';
import { receivedFetchListSuccess, receivedFetchListFailure, setIsFetchingStatus } from './actions';

import { createUntranslatedFeedback } from 'services/Feedback/actions';

import { report } from 'services/Errors/resources';
import type { Metal } from 'services/Metal/types';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Fetch all metals
 */
export const fetchAllMetals = () => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingStatus());
    index()
        .then((response: Array<Metal>) => {
            dispatch(receivedFetchListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchAllMetalsFailed'));
        });
};
