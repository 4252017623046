// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header, Body, Footer, Field, Label, RadioStyles } from './styles';
import { InfoToastColor } from 'styles/colors';

// Components
import { Title } from 'styles/common';
import {
    LegacyTheme,
    PrimaryButton,
    SecondaryButton,
    RadioButtonSet,
    MessageBlock,
    Close,
    Modal,
    InfoIcon,
} from 'components/_ReactUI_V1';

// Types
import type { IntlType, ErrorType } from 'types';

// Constant
import { MODAL_WIDTH, CASCADE_RF_OPTIONS, RETURN_RF_OPTIONS } from 'utils/constants';

type OptionType = { label: string, value: number | string, disabled: boolean };

type Props = {
    intl: IntlType,
    errors: ErrorType,
    loading: boolean,

    selectedCascadeRelaxationFactor: number,
    selectedReturnRelaxationFactor: number,

    onConfirm: (cascadeRelaxationFactor: number, returnRelaxationFactor: number) => void,
    onCancel: () => void,
};

type State = {
    // the keys used by the radio button set, not the value of the Relaxaction Factor.
    cascadeRelaxationFactorKey: number,
    returnRelaxationFactorKey: number,
};

/**
 * Shown when the circuit computation did not converge.
 */
class CircuitConvergenceModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            cascadeRelaxationFactorKey: CASCADE_RF_OPTIONS.findIndex(
                (value: number) => value === this.props.selectedCascadeRelaxationFactor
            ),
            returnRelaxationFactorKey: RETURN_RF_OPTIONS.findIndex(
                (value: number) => value === this.props.selectedReturnRelaxationFactor
            ),
        };
    }

    /**
     * Fix number to a minimum amount of decimal places
     */
    fixNumber = (num: number, minimumDecimalPlaces: number = 1) =>
        num.toFixed(Math.max(minimumDecimalPlaces, (num.toString().split('.')[1] || []).length));

    /**
     * Get the cascade Relaxation Factor (RF) radio options
     */
    getCascadeRFOptions = (): Array<OptionType> =>
        CASCADE_RF_OPTIONS.map((value: number, index: number) => ({
            value: index,
            label: this.fixNumber(value),
            disabled:
                this.props.selectedCascadeRelaxationFactor === value &&
                RETURN_RF_OPTIONS[this.state.returnRelaxationFactorKey] ===
                    this.props.selectedReturnRelaxationFactor,
        }));

    /**
     * Get the return Relaxation Factor (RF) radio options
     */
    getReturnRFOptions = (): Array<OptionType> =>
        RETURN_RF_OPTIONS.map((value: number, index: number) => ({
            value: index,
            label: this.fixNumber(value),
            disabled:
                this.props.selectedReturnRelaxationFactor === value &&
                CASCADE_RF_OPTIONS[this.state.cascadeRelaxationFactorKey] ===
                    this.props.selectedCascadeRelaxationFactor,
        }));

    /**
     * We cannot use the same relaxation factors than the one that caused the diagram not to converge.
     */
    isComputeEnabled = () => {
        const selectedCRFValue = CASCADE_RF_OPTIONS[this.state.cascadeRelaxationFactorKey];
        const selectedRRFValue = RETURN_RF_OPTIONS[this.state.returnRelaxationFactorKey];
        return (
            selectedCRFValue !== this.props.selectedCascadeRelaxationFactor ||
            selectedRRFValue !== this.props.selectedReturnRelaxationFactor
        );
    };

    /**
     * When the compute button is clicked, call our parent with the options selected
     */
    handleComputeClicked = () =>
        this.props.onConfirm(
            CASCADE_RF_OPTIONS[this.state.cascadeRelaxationFactorKey],
            RETURN_RF_OPTIONS[this.state.returnRelaxationFactorKey]
        );

    /**
     * When the cancel button is clicked
     */
    handleCancelClicked = () => this.props.onCancel();

    /**
     * When the Cascade RF radio buttons is changed
     */
    handleCascadeRFChange = (cascadeRelaxationFactorKey: number) =>
        this.setState({ cascadeRelaxationFactorKey });

    /**
     * When the Return RF radio buttons is changed
     */
    handleReturnRFChange = (returnRelaxationFactorKey: number) =>
        this.setState({ returnRelaxationFactorKey });

    renderMessageBlock = () => {
        if (!this.props.errors || this.props.errors.get('converged')) {
            return null;
        }
        if (this.props.loading) {
            return (
                <MessageBlock
                    color={InfoToastColor}
                    text={this.props.intl.formatMessage({
                        id: 'components.Modals.CircuitConvergenceModal.loadingConvergenceMessage',
                    })}
                    icon={<InfoIcon fill={InfoToastColor} />}
                />
            );
        }
        return (
            <MessageBlock
                color={LegacyTheme.defaultWarningColor}
                text={this.props.intl.formatMessage({
                    id: 'components.Modals.CircuitConvergenceModal.failedToConvergeMessage',
                })}
                icon={<Close />}
            />
        );
    };

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.BIG} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.CircuitConvergenceModal.title',
                            })}
                        </Title>
                        {this.renderMessageBlock()}
                    </Header>
                    <Body>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id:
                                        'components.Modals.CircuitConvergenceModal.cascadeRelaxationFactorLabel',
                                })}
                            </Label>
                            <RadioButtonSet
                                orientation="HORIZONTAL"
                                value={this.state.cascadeRelaxationFactorKey}
                                options={this.getCascadeRFOptions()}
                                onClick={this.handleCascadeRFChange}
                                styles={RadioStyles}
                                disabled={this.props.loading}
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id:
                                        'components.Modals.CircuitConvergenceModal.returnRelaxationFactorLabel',
                                })}
                            </Label>
                            <RadioButtonSet
                                orientation="HORIZONTAL"
                                value={this.state.returnRelaxationFactorKey}
                                options={this.getReturnRFOptions()}
                                onClick={this.handleReturnRFChange}
                                styles={RadioStyles}
                                disabled={this.props.loading}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.handleCancelClicked}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.CircuitConvergenceModal.computeButton',
                            })}
                            onClick={this.handleComputeClicked}
                            loading={this.props.loading}
                            disabled={!this.isComputeEnabled()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(CircuitConvergenceModal);
