// @flow strict

import styled from 'styled-components';

import { LegacyTheme, InputNumber } from 'components/_ReactUI_V1';

export const Field = styled.div`
    display: flex;
    flex-direction: ${({ isIsothermPage }) => (isIsothermPage ? 'column' : 'row')};
    align-items: ${({ isIsothermPage }) => (isIsothermPage ? 'start' : 'center')};
    flex-wrap: wrap;

    margin-top: 16px;

    width: 100%;
`;

export const Label = styled.label`
    min-width: 130px;
    font-size: 13px;
    line-height: 16px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    margin-right: 16px;
    margin-bottom: 5px;
    width: ${({ isFullWidth }: { isFullWidth: boolean }) => (isFullWidth ? '100%' : 'fit-content')};
`;

export const CustomInputNumber = styled(InputNumber)`
    height: 30px;
    width: 119px;
    margin-right: 0;
    text-align: center;
`;
