// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Components
import CircuitComputationContainer from 'containers/CircuitComputationContainer';

// Containers
import MinchemPreferencesFetcher from 'containers/MinchemPreferencesFetcher';

// Types
import type { IntlType, Navigation } from 'types';

// Helpers
import { BLANK_TEMPLATE, NEW_DATASET_POSTFIX } from 'utils/constants';

type Props = {
    intl: IntlType,
};

export class CircuitComputation extends React.PureComponent<Props> {
    getCircuitId() {
        const circuitId = this.props.match.params.circuitId;
        if (circuitId) {
            return parseInt(circuitId, 10);
        }
        return null;
    }

    getDatasetId() {
        const datasetId = this.props.match.params.datasetId;
        if (datasetId === NEW_DATASET_POSTFIX) {
            return BLANK_TEMPLATE;
        }
        if (datasetId) {
            return parseInt(datasetId, 10);
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.CircuitComputation.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.CircuitComputation.helmetDescription',
                        })}
                    />
                </Helmet>
                <MinchemPreferencesFetcher>
                    <CircuitComputationContainer
                        circuitId={this.getCircuitId()}
                        datasetId={this.getDatasetId()}
                    />
                </MinchemPreferencesFetcher>
            </React.Fragment>
        );
    }
}

export default injectIntl(CircuitComputation);
