// @flow strict

import { report } from 'services/Errors/resources';
import { createUntranslatedFeedback } from 'services/Feedback/actions';
import {
    receivedDisclaimersListSuccess,
    receivedDisclaimersListFail,
    receivedDisclaimerUpdateSuccess,
    receivedDisclaimerUpdateFail,
    setIsFetching,
    setIsCreating,
} from './actions';

import { index, createNewVersion } from './resources';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { Disclaimer, ImmutableDisclaimer } from 'services/Disclaimer/types';

export const fetchDisclaimers = () => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetching());
    index()
        .then((response: Array<Disclaimer>) => {
            dispatch(receivedDisclaimersListSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchDisclaimersSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDisclaimersListFail(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchDisclaimersFailed'));
        });
};

export const createNewDisclaimerVersion = (id: number, disclaimer: ImmutableDisclaimer) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsCreating());
    createNewVersion(id, disclaimer)
        .then((response: Disclaimer) => {
            dispatch(receivedDisclaimerUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.createNewDisclaimerVersionSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDisclaimerUpdateFail(error));
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.createNewDisclaimerVersionFailed'
                )
            );
        });
};
