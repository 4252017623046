// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, ButtonWrapper } from './styles';

// Components
import { PrimaryButton, SecondaryButton } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
    allowSaveChange?: boolean,
    saveUpdate?: boolean,
    showSaveButton?: boolean,
    handleSaveIsothermClicked: () => void,
    handleCopyToClipboardClicked: () => void,
};

class IsothermFooter extends React.PureComponent<Props, null> {
    static defaultProps = {
        allowSaveChange: false,
        saveUpdate: false,
        showSaveButton: true,
    };

    render() {
        return (
            <Wrapper>
                <ButtonWrapper>
                    <SecondaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.IsothermFooter.copyToClipboard',
                        })}
                        onClick={this.props.handleCopyToClipboardClicked}
                    />
                    {this.props.showSaveButton && (
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: `components.IsothermFooter.${
                                    this.props.saveUpdate ? 'saveUpdateButton' : 'saveButton'
                                }`,
                            })}
                            onClick={this.props.handleSaveIsothermClicked}
                            disabled={!this.props.allowSaveChange}
                        />
                    )}
                </ButtonWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(IsothermFooter);
