// @flow

import React from 'react';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// Styles
import { ReactDraftWysiwygStyles, Wrapper } from './styles';

// Local Utils
export function TextFieldRichGlobalStyles(props: Object) {
    return <ReactDraftWysiwygStyles {...props} />;
}

type HTMLOrStringType = HTMLElement | string;
/**
 * Helper function which returns an EditorState Object,
 * if the provided input is empty, an empty state will be returned, else the input will be converted
 *
 * @param {HTMLOrStringType} input
 */
export const editorStateFromHtml = (input?: ?HTMLOrStringType): EditorState => {
    if (!input) {
        return EditorState.createEmpty();
    } else if (typeof input === 'string') {
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(input).contentBlocks)
        );
    } else {
        return input;
    }
};

/**
 * Helper function which converts EditorState to HTML
 * An empty string will be replaced if no content can be extracted from EditorState
 *
 * @param {EditorState} editorState
 */
export const htmlFromEditorState = (editorState: EditorState): HTMLOrStringType => {
    const html = editorState && editorState.getCurrentContent();
    if (!html) {
        return '';
    }
    return draftToHtml(convertToRaw(html));
};

type Props = {
    disabled?: boolean,
    id?: ?string,
    initialValue?: ?string,
    key?: ?string,
    name?: string,
    onChange: (editorState: EditorState) => void,
    placeholder?: string,
    renderString?: boolean,
    toolbarConfig?: ?Object,
};

type State = {
    editorState: EditorState,
};

export class TextFieldRich extends React.Component<Props, State> {
    static defaultProps = {
        disabled: false,
        id: null,
        initialValue: '',
        key: null,
        name: 'TextFieldRich',
        placeholder: '',
        renderString: false,
        toolbarConfig: {
            options: ['inline', 'list'],
            inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
        },
    };

    state = {
        editorState: editorStateFromHtml(this.props.initialValue),
    };

    handleOnChange = (editorState: EditorState) => {
        this.setState(
            {
                editorState,
            },
            () => this.props.onChange && this.props.onChange(editorState)
        );
    };

    createMarkup() {
        return { __html: this.state.editorState && htmlFromEditorState(this.state.editorState) };
    }

    render() {
        const {
            initialValue,
            disabled,
            id,
            key,
            name,
            placeholder,
            renderString,
            toolbarConfig,
            ...rest
        } = this.props;

        if (renderString && this.state.editorState) {
            return (
                /* eslint-disable-next-line react/no-danger */
                <div dangerouslySetInnerHTML={this.createMarkup()} />
            );
        }

        // Remove onChange as <Editor /> accepts prop but we want to replace it with handleOnChange
        const restProps = { ...rest };
        delete restProps.onChange;

        return (
            <Wrapper>
                <Editor
                    key={key || name || id}
                    readOnly={disabled && disabled !== false}
                    placeholder={placeholder}
                    editorState={this.state.editorState}
                    defaultEditorState={initialValue}
                    onEditorStateChange={this.handleOnChange}
                    toolbar={toolbarConfig}
                    {...restProps}
                />
            </Wrapper>
        );
    }
}
