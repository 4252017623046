/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const StreamUnitWrapper = styled.div`
    flex: 2;
`;

export const StreamInputWrapper = styled.div`
    flex: 4;
`;

export const StreamInputLabelWrapper = styled.div`
    display: flex;
    flex: 4;
`;

export const StreamValuesBodyWrapper = styled.div`
    z-index: 2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0px 10px;

    border-radius: 4px;
    overflow: hidden;

    background-color: ${({ streamValueColors }: Object) =>
        streamValueColors.streamValuesBackground};

    box-shadow: inset 0 -1px 0 0 ${({ streamValueColors }: Object) => streamValueColors.streamValuesBoxShadow},
        inset 0 1px 0 0
            ${({ streamValueColors }: Object) => streamValueColors.streamValuesBoxShadow},
        0 1px 2px 0 rgba(0, 0, 0, 0.05);

    width: 100%;
    height: 35px;

    color: ${LegacyTheme.defaultColor};
    font-size: 11px;

    > ${StreamUnitWrapper}, > ${StreamInputWrapper}, > ${StreamInputLabelWrapper} {
        margin-right: 6px;
        &:last-child {
            margin-right: 0;
        }
    }
`;
