// @flow strict

export const colors = {
    white: '#FFFFFF',
    greyF2: '#F2F2F2',
    greyE5: '#E5E5E5',
    greyE6: '#E6E6E6',
    greyDD: '#DDDDDD',
    greyBB: '#BBBBBB',
    greyAF: '#AFAFAF',
    grey7B: '#7B7C7E',
    grey75: '#757575',
    grey27: '#272727',

    orangeF6A: '#F6A906',
    orangeFEF: '#FEF7E7',
    orangeEB6: '#EB6B4A',

    purple99: '#99699B',
    purple94: '#9464A3',
    purpleF4: '#F4EFF6',

    turquoise2D: '#2DA9AC',
    turquoise25: '#25ADAD',
    turquoiseE9: '#E9F6F6',

    green007: '#007F33',

    redE20: '#E20031',

    blue019: '#019EE1'
};

export const CascadeColors = {
    EXTRACT: colors.orangeF6A,
    STRIP: colors.turquoise2D,
};

export const InfoToastColor = '#0079BA';

export const isothermColors = {
    EXTRACT: {
        primary: ['#9464A3', '#25ADAD', '#E3435F', '#82CDED', '#F9C100'],
        accent: ['#B492BE', '#51DADA', '#EB7B8E', '#A7DBF2', '#FED548'],
    },
    STRIP: {
        primary: ['#FFAA4F', '#3F51B5', '#FF99D2', '#B1AAA3', '#95BD0C'],
        accent: ['#FEC383', '#7482CE', '#FEB7DF', '#C8C3BE', '#C7F233'],
    },
};

export const isothermMDRCurvesColors = {
    primary: ['#FFAA4F', '#95BD0C', '#F9C100', '#82CDED', '#9464A3', '#E3435F'],
    accent: ['#FFDAB2', '#DFEBB6', '#FEEDB2', '#DAF0FA', '#B8A1C9', '#F7C6CF'],
};

export const twoDSensitivityDiagramColors = {
    netTransfer: {
        primaryColor: '#9464A3',
        accentColor: '#B492BE',
    },
    recoveryPercent: {
        primaryColor: '#25ADAD',
        accentColor: '#51DADA',
    },
};

export const threeDSensitivityDiagramColors = {
    backgroundColor: '#FFFFFF',
    xAxis: '#9464A3',
    yAxis: '#25ADAD',
    zAxis: '#E3435F',
    surfaceColors: ['#004EFF', '#2AFADF'],
};

export const streamCircuitColors = {
    AQUEOUS: {
        main: colors.orangeF6A,
        streamValuesBackground: colors.orangeFEF,
        streamValuesBoxShadow: colors.orangeFEF,
    },
    ORGANIC: {
        main: colors.purple99,
        streamValuesBackground: colors.purpleF4,
        streamValuesBoxShadow: colors.purpleF4,
    },
    ELECTROLYTE: {
        main: colors.turquoise2D,
        streamValuesBackground: colors.turquoiseE9,
        streamValuesBoxShadow: colors.turquoiseE9,
    },
};

export const advancedStreamValue = {
    backgroundColor: colors.greyF2,
    fontColor: colors.grey27,
};

export const KPIGraphColorSchemes = [
    {
        targetLine: colors.purple99,
        areaFill: '#B492BE',
        areaLine: '#9464A3',
    },
    {
        targetLine: '#F6A906',
        areaFill: '#FFDAB2',
        areaLine: '#FFAA4F',
    },
    {
        targetLine: '#95BD0C',
        areaFill: '#DFEBB6',
        areaLine: '#95BD00',
    },
    {
        targetLine: '#82CDED',
        areaFill: '#DAF0FA',
        areaLine: '#82CDED',
    },
    {
        targetLine: '#E3435F',
        areaFill: '#F7C6CF',
        areaLine: '#E3435F',
    },
    {
        targetLine: '#3F51B5',
        areaFill: '#7482CE',
        areaLine: '#3F51B5',
    },
    {
        targetLine: '#FF99D2',
        areaFill: '#FEB7DF',
        areaLine: '#FF99D2',
    },
];

export const datasetStatusColors = {
    converged: colors.green007,
    diverged: colors.orangeEB6,
    notComputedYet: colors.orangeF6A,
    invalid: colors.redE20,
};
