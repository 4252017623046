// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Info = styled.p`
    color: ${LegacyTheme.defaultColor};

    font-size: 12px;
`;
