// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const WrapperRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    margin-bottom: 14px;
    width: 100%;
    justify-content: space-between;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0 24px;
`;

export const Title = styled.h1`
    font-size: 20px;
    color: ${LegacyTheme.modalTitle};
    font-weight: normal;
    margin: 0;
    line-height: 40px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Subtitle = styled.h2`
    font-size: 12px;
    color: ${LegacyTheme.black};
    font-weight: normal;
    margin: 0;
    line-height: 24px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 362px;

    padding: 16px 24px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    border-top: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const RadioLabel = styled.span`
    margin-left: 7.5px;
    margin-right: 15px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const SubRadioLabel = styled.span`
    font-size: 12px;
    line-height: 16px;
    margin-left: 55px;
    margin-top: 8px;
    margin-right: 15px;
    color: ${LegacyTheme.black};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const TrashStyles = {
    width: '36px',
    height: '36px',
    flexShrink: '0',
    marginRight: '19px',
};

export const RadioStyles = {
    color: LegacyTheme.black,
    marginLeft: '25px',
    marginRight: '19px',
};
