// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// components
import KPICard from 'components/KPICard';

// styles
import {
    GroupWrapper,
    CardGroup,
    GroupTitleWrapper,
    GroupLine,
    GroupTitle,
    CardWrapper,
} from './styles';

// Services
import { selectAllKPISettings } from 'services/KPISetting/selectors';

// constants
import { MINOR_KPI_SECTIONS, MINOR_KPI_SECTIONS_ORDER } from 'utils/constants';
import { getKPISection } from 'utils/kpiHelpers';

// types
import type { IntlType, ImmutableList } from 'types';
import type { TrendsPeriodConstant, ImmutableKPIHistory } from 'services/Trends/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type {
    ImmutableKPISetting,
    ImmutableKPICard,
    KPISectionType,
} from 'services/KPISetting/types';

type KPISection = {
    type: KPISectionType,
    kpis: ImmutableList<ImmutableKPISetting>,
};

type Props = {
    intl: IntlType,
    userLanguage: string,
    timezone: string,

    circuit: ?ImmutableCircuit,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    kpis: ImmutableList<ImmutableKPICard>,
    kpiHistories: ImmutableList<ImmutableKPIHistory>,

    onChangePeriodForMinor: (kpiId: number, newPeriod: TrendsPeriodConstant) => void,
};

/*
    Minor KPI Trends component
 */
class MinorKPITrends extends React.PureComponent<Props, null> {
    getKPISettingForKPICard = (kpi: ImmutableKPICard): ImmutableKPISetting =>
        this.props.kpiSettings.find(
            (kpiSetting: ImmutableKPISetting) => kpiSetting.get('id') === kpi.get('kpiSettingId')
        );

    /**
     * Get the kpis per section, getKPISection requires a circuit
     * If in Plant trends, loop up matching circuit for each kpi
     */
    getKPIsForSection = (kpiSection: KPISectionType): ImmutableList<ImmutableKPICard> =>
        this.props.kpis.filter((kpi: ImmutableKPICard) => {
            let section = kpi.get('section');
            // All KPIs with a section of "OTHER" means that the section might be computed
            if (section === MINOR_KPI_SECTIONS.OTHER && this.props.circuit) {
                section = getKPISection(this.getKPISettingForKPICard(kpi), this.props.circuit);
            }
            return kpiSection === section;
        });

    getKPIHistory = (kpi: ImmutableKPICard) =>
        this.props.kpiHistories.find(
            (kpiHistory: ImmutableKPIHistory) =>
                kpiHistory.get('kpiSettingId') === kpi.get('kpiSettingId')
        );

    renderGroupTitle = (section: KPISectionType) => {
        const title = this.props.intl.formatMessage({
            id: `constants.MINOR_KPI_SECTIONS.${section}`,
        });
        return (
            <GroupTitleWrapper>
                <GroupLine />
                <GroupTitle>{title}</GroupTitle>
                <GroupLine />
            </GroupTitleWrapper>
        );
    };

    renderSections = () =>
        Object.values(MINOR_KPI_SECTIONS)
            .map((kpiSection: KPISectionType) => ({
                type: kpiSection,
                kpis: this.getKPIsForSection(kpiSection),
            }))
            .filter((section: KPISection) => section.kpis.size > 0)
            .sort(
                (sectionA: KPISection, sectionB: KPISection) =>
                    MINOR_KPI_SECTIONS_ORDER[sectionA.type] -
                    MINOR_KPI_SECTIONS_ORDER[sectionB.type]
            )
            .map((kpiSection: KPISection) => (
                <CardGroup key={kpiSection.type}>
                    {this.renderGroupTitle(kpiSection.type)}
                    <CardWrapper>
                        {kpiSection.kpis.map((kpi: ImmutableKPICard) => (
                            <KPICard
                                key={kpi.get('kpiSettingId')}
                                kpi={kpi}
                                kpiSetting={this.getKPISettingForKPICard(kpi)}
                                kpiHistory={this.getKPIHistory(kpi)}
                                stages={
                                    this.props.circuit ? this.props.circuit.get('stages') : null
                                }
                                timezone={this.props.timezone}
                                userLanguage={this.props.userLanguage}
                                onChangePeriodForMinor={this.props.onChangePeriodForMinor}
                            />
                        ))}
                    </CardWrapper>
                </CardGroup>
            ));

    render() {
        return <GroupWrapper>{this.renderSections()}</GroupWrapper>;
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        kpiSettings: selectAllKPISettings(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MinorKPITrends));
