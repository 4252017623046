// @flow strict

import React from 'react';

// UI Components
import { DiagramEntityWrapper, HighLightOnHover } from './styles';

type Props = {
    startingColumn: number,
    startingRow: number,
    columnSpan: number,
    rowSpan: number,
    onClick?: ?() => void,

    backgroundColor?: string | null,
    borderColor?: string | null,

    children?: any,
};

/**
 * A wrapper for any entity that should be added to the mimic diagram.
 * These entities should have :
 * - a location (startingColumn and startingRow)
 * - a size (columnSpan and rowSpan)
 */
class DiagramEntity extends React.PureComponent<Props, null> {
    static defaultProps = {
        onClick: null,
    };

    render() {
        return (
            <DiagramEntityWrapper
                startingColumn={this.props.startingColumn}
                startingRow={this.props.startingRow}
                columnSpan={this.props.columnSpan}
                rowSpan={this.props.rowSpan}
                onClick={this.props.onClick}
                borderColor={this.props.borderColor}
                backgroundColor={this.props.backgroundColor}
            >
                {this.props.onClick && <HighLightOnHover />}
                {this.props.children}
            </DiagramEntityWrapper>
        );
    }
}

export default DiagramEntity;
