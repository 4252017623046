// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Title, Subtitle, FormContainer, FormBlock } from 'styles/common';

// Components
import KPISetupTable from 'components/KPISetupTable';
import { Common, SecondaryButton } from 'components/_ReactUI_V1';

// Constants
import { CIRCUIT_ELEVATION_STEPS } from 'utils/constants';

// Types
import type {
    IntlType,
    ImmutableList,
    LooseKeyArrayType,
    LooseInputValueTypes,
    UnitsConstant,
} from 'types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { CircuitElevationStepConstant } from 'services/Circuit/types';

type Props = {
    intl: IntlType,
    units: UnitsConstant,
    majorKPISettings: ImmutableList<ImmutableKPISetting>,

    onInputChange?: ?(keys: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onReturnToStep?: ?(step: CircuitElevationStepConstant) => () => void,

    isCircuitElevating: boolean,
};

class CircuitElevationMajorKpiStep extends React.PureComponent<Props, null> {
    static defaultProps = {
        onInputChange: null,
        onReturnToStep: null,
    };

    STEP_KEY = CIRCUIT_ELEVATION_STEPS.MAJOR_KPIS;

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.CircuitElevation.${this.STEP_KEY}.${id}`,
            },
            data
        );

    render() {
        const displayAsReadOnly = Boolean(this.props.onReturnToStep);

        return (
            <FormContainer>
                <Common.Row alignItems="center">
                    <Common.Column>
                        {displayAsReadOnly ? (
                            <Subtitle>{this.getTranslation('title')}</Subtitle>
                        ) : (
                            <Title>{this.getTranslation('title')}</Title>
                        )}
                    </Common.Column>
                    {displayAsReadOnly && (
                        <Common.Column alignItems="flex-end">
                            <SecondaryButton
                                text={this.getTranslation('edit')}
                                onClick={
                                    this.props.onReturnToStep &&
                                    this.props.onReturnToStep(this.STEP_KEY)
                                }
                            />
                        </Common.Column>
                    )}
                </Common.Row>

                <FormBlock fullWidth>
                    <KPISetupTable
                        units={this.props.units}
                        kpis={this.props.majorKPISettings}
                        onInputChange={this.props.onInputChange}
                        readonly={displayAsReadOnly}
                        noKPIMessage={this.getTranslation('noKPIs')}
                        isUpdating={!this.props.isCircuitElevating}
                    />
                </FormBlock>
            </FormContainer>
        );
    }
}

export default injectIntl(CircuitElevationMajorKpiStep);
