// @flow strict

import {
    STAGE_TYPES,
    STREAM_TYPES,
    STREAM_CIRCUITS,
    STREAM_VALUE_TYPES,
    STREAM_VALUE_ORDER,
} from 'utils/constants';

import {
    getExtractorStages,
    getStripperStages,
    getStagesForStream,
    getStreamsForStage,
    getPreviousStage,
    getNextStage,
    getLastStageInCascadeForStage,
} from 'utils/helpers';

import type {
    LooseStream,
    LooseStage,
    Stage,
    Stream,
    ImmutableStage,
    ImmutableStream,
    ImmutableCircuit,
} from 'services/Circuit/types';
import type { LooseStreamValue } from 'services/Dataset/types';

/**
 * Gets the stage data for the given stage id.
 */
export const getStageById = (stageId: number, stages: Array<Stage>): ?Stage =>
    stageId !== null ? stages.find((stage: Stage) => stage.id === stageId) : null;

export const getExtractors = (stages: Array<LooseStage>): Array<LooseStage> =>
    stages.filter((stage: LooseStage) => stage.stageType === STAGE_TYPES.EXTRACT);

export const getStrippers = (stages: Array<LooseStage>): Array<LooseStage> =>
    stages.filter((stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP);

/**
 * Returns true if the provided stream value is a minchem stream.
 * Otherwise it is most likely a SolvExtract stream value.
 */
export const isMinChemStreamValue = (streamValue: LooseStreamValue): boolean => {
    const order = STREAM_VALUE_ORDER[streamValue.valueType];
    return order !== null && order !== undefined;
};

/**
 * Return PLS blend stream to stage. Blends are only possible on extraction stages.
 */
export const getPlsBlendToStage = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    stage.stageType === STAGE_TYPES.EXTRACT
        ? streams.find((plsBlendStream: Stream) => {
              if (
                  plsBlendStream.streamType !== STREAM_TYPES.BLEND ||
                  plsBlendStream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
              ) {
                  return false;
              }
              let toStage = null;
              if (plsBlendStream.toStageId) {
                  toStage = getStageById(plsBlendStream.toStageId, stages);
              } else {
                  toStage = plsBlendStream.to;
              }
              return (
                  toStage &&
                  (toStage.stageType === stage.stageType && toStage.location === stage.location)
              );
          })
        : null;

export const getPlsContinueToStageFromStage = (
    toStage: LooseStage,
    fromStage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    toStage.stageType === STAGE_TYPES.EXTRACT && fromStage.stageType === STAGE_TYPES.EXTRACT
        ? streams.find((plsContinueStream: Stream) => {
              if (
                  plsContinueStream.streamType !== STREAM_TYPES.CONTINUE ||
                  plsContinueStream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
              ) {
                  return false;
              }
              let toStreamStage = null;
              let fromStreamStage = null;
              if (plsContinueStream.toStageId) {
                  toStreamStage = getStageById(plsContinueStream.toStageId, stages);
              } else {
                  toStreamStage = plsContinueStream.to;
              }
              if (plsContinueStream.fromStageId) {
                  fromStreamStage = getStageById(plsContinueStream.fromStageId, stages);
              } else {
                  fromStreamStage = plsContinueStream.to;
              }
              return (
                  toStage &&
                  (toStreamStage.stageType === toStage.stageType &&
                      toStreamStage.location === toStage.location) &&
                  fromStage &&
                  (fromStreamStage.stageType === fromStage.stageType &&
                      fromStreamStage.location === fromStage.location)
              );
          })
        : null;

export const getPlsSkipToStageFromStage = (
    toStage: LooseStage,
    fromStage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    toStage.stageType === STAGE_TYPES.EXTRACT && fromStage.stageType === STAGE_TYPES.EXTRACT
        ? streams.find((plsSkipStream: Stream) => {
              if (
                  plsSkipStream.streamType !== STREAM_TYPES.SKIP ||
                  plsSkipStream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
              ) {
                  return false;
              }
              let toStreamStage = null;
              let fromStreamStage = null;
              if (plsSkipStream.toStageId) {
                  toStreamStage = getStageById(plsSkipStream.toStageId, stages);
              } else {
                  toStreamStage = plsSkipStream.to;
              }
              if (plsSkipStream.fromStageId) {
                  fromStreamStage = getStageById(plsSkipStream.fromStageId, stages);
              } else {
                  fromStreamStage = plsSkipStream.to;
              }
              return (
                  toStage &&
                  (toStreamStage.stageType === toStage.stageType &&
                      toStreamStage.location === toStage.location) &&
                  fromStage &&
                  (fromStreamStage.stageType === fromStage.stageType &&
                      fromStreamStage.location === fromStage.location)
              );
          })
        : null;

export const getPlsSkipToStage = (
    toStage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    toStage.stageType === STAGE_TYPES.EXTRACT
        ? streams.find((plsSkipStream: Stream) => {
              if (
                  plsSkipStream.streamType !== STREAM_TYPES.SKIP ||
                  plsSkipStream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
              ) {
                  return false;
              }
              let toStreamStage = null;
              if (plsSkipStream.toStageId) {
                  toStreamStage = getStageById(plsSkipStream.toStageId, stages);
              } else {
                  toStreamStage = plsSkipStream.to;
              }
              return (
                  toStage &&
                  (toStreamStage.stageType === toStage.stageType &&
                      toStreamStage.location === toStage.location)
              );
          })
        : null;

/**
 * Return PLS feed stream to stage.
 */
export const getPlsFeedToStage = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    stage.stageType === STAGE_TYPES.EXTRACT
        ? streams.find((plsFeedStream: Stream) => {
              if (
                  plsFeedStream.streamType !== STREAM_TYPES.FEED ||
                  plsFeedStream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
              ) {
                  return false;
              }
              let toStage = null;
              if (plsFeedStream.toStageId) {
                  toStage = getStageById(plsFeedStream.toStageId, stages);
              } else {
                  toStage = plsFeedStream.to;
              }
              return (
                  toStage &&
                  (toStage.stageType === stage.stageType && toStage.location === stage.location)
              );
          })
        : null;

/**
 * Return PLS bleed stream from previous stage. Pls bleeds are only possible on extraction stages.
 */
export const getPlsBleedFromStage = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    stage.stageType === STAGE_TYPES.EXTRACT
        ? streams.find((plsBleedStream: Stream) => {
              if (
                  plsBleedStream.streamType !== STREAM_TYPES.BLEED ||
                  plsBleedStream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
              ) {
                  return false;
              }
              let fromStage = null;
              if (plsBleedStream.fromStageId) {
                  fromStage = getStageById(plsBleedStream.fromStageId, stages);
              } else {
                  fromStage = plsBleedStream.from;
              }
              return (
                  fromStage &&
                  (fromStage.stageType === stage.stageType && fromStage.location === stage.location)
              );
          })
        : null;

/**
 * Return organic blend stream that goes to previous stage. Blends are only possible on extraction stages.
 */
export const getOrganicBlendToStage = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    stage.stageType === STAGE_TYPES.EXTRACT
        ? streams.find((organicBlendStream: Stream) => {
              if (
                  organicBlendStream.streamType !== STREAM_TYPES.BYPASS_BLEND ||
                  organicBlendStream.streamCircuit !== STREAM_CIRCUITS.ORGANIC
              ) {
                  return false;
              }
              let toStage = null;
              if (organicBlendStream.toStageId) {
                  toStage = getStageById(organicBlendStream.toStageId, stages);
              } else {
                  toStage = organicBlendStream.to;
              }
              return (
                  toStage &&
                  (toStage.stageType === stage.stageType && toStage.location === stage.location)
              );
          })
        : null;

/**
 * Return Organic bypass bleed stream from previous extract stage.
 */
export const getOrganicBypassBleed = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    streams.find((bleedStream: Stream) => {
        if (
            bleedStream.streamType !== STREAM_TYPES.BYPASS_BLEED ||
            bleedStream.streamCircuit !== STREAM_CIRCUITS.ORGANIC
        ) {
            return false;
        }
        let fromStage = null;
        if (bleedStream.fromStageId) {
            fromStage = getStageById(bleedStream.fromStageId, stages);
        } else {
            fromStage = bleedStream.from;
        }
        return (
            fromStage &&
            (fromStage.stageType === stage.stageType && fromStage.location === stage.location)
        );
    });

/**
 * Return Organic bypass bleed stream from previous extract stage.
 */
export const getOrganicBypassFeed = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    streams.find((feedStream: Stream) => {
        if (
            feedStream.streamType !== STREAM_TYPES.BYPASS_FEED ||
            feedStream.streamCircuit !== STREAM_CIRCUITS.ORGANIC
        ) {
            return false;
        }
        let toStage = null;
        if (feedStream.toStageId) {
            toStage = getStageById(feedStream.toStageId, stages);
        } else {
            toStage = feedStream.to;
        }
        return (
            toStage &&
            (toStage.stageType === stage.stageType && toStage.location === stage.location)
        );
    });

/**
 * Return the electrolyte feed stream from the stage.
 * @param {LooseStage} stage The stage for which we want the feed stream of
 * @param {Array<LooseStage>} stages The stages in our circuit
 * @param {Array<LooseStream>} streams The streams in our circuit
 */
export const getElectrolyteFeedToStage = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    stage.stageType === STAGE_TYPES.STRIP
        ? streams.find((feedStream: Stream) => {
              if (
                  feedStream.streamType !== STREAM_TYPES.FEED ||
                  feedStream.streamCircuit !== STREAM_CIRCUITS.ELECTROLYTE
              ) {
                  return false;
              }
              let toStage = null;
              if (feedStream.toStageId) {
                  toStage = getStageById(feedStream.toStageId, stages);
              } else {
                  toStage = feedStream.to;
              }
              return (
                  toStage &&
                  (toStage.stageType === stage.stageType && toStage.location === stage.location)
              );
          })
        : null;

/**
 * Return electrolyte bleed stream from previous stage.
 */
export const getElectrolyteBleedFromStage = (
    stage: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?LooseStream =>
    stage.stageType === STAGE_TYPES.STRIP
        ? streams.find((bleedStream: Stream) => {
              if (
                  bleedStream.streamType !== STREAM_TYPES.BLEED ||
                  bleedStream.streamCircuit !== STREAM_CIRCUITS.ELECTROLYTE
              ) {
                  return false;
              }
              let fromStage = null;
              if (bleedStream.fromStageId) {
                  fromStage = getStageById(bleedStream.fromStageId, stages);
              } else {
                  fromStage = bleedStream.from;
              }
              return (
                  fromStage &&
                  (fromStage.stageType === stage.stageType && fromStage.location === stage.location)
              );
          })
        : null;

/**
 * Return true if a PLS(Aqueous) bleed or feed stream goes to or comes from skipped stage.
 */
export const hasSkipStream = (streams: Array<LooseStream>) =>
    Boolean(streams.find((stream: LooseStream) => stream.streamType === STREAM_TYPES.SKIP));

/**
 * Returns true if there is at least 1 bypass feed going into the extraction section.
 * @param {Array<Stream>} streams List of all streams in the mimic diagram
 * @param {Arary<Stage>} stages List of all stages in the mimic diagram
 */
export const hasExtractBypass = (streams: Array<LooseStream>, stages: Array<LooseStage>): boolean =>
    Boolean(
        streams.find((stream: LooseStream) => {
            if (stream.streamType === STREAM_TYPES.BYPASS_FEED) {
                const relatedStages = getStagesForStream(stream, stages);
                return relatedStages.to && relatedStages.to.stageType === STAGE_TYPES.EXTRACT;
            }
            return false;
        })
    );

/**
 * Returns true if there is at least 1 bypass feed going into the stripping section.
 * @param {Array<Stream>} streams List of all streams in the mimic diagram
 * @param {Arary<Stage>} stages List of all stages in the mimic diagram
 */
export const hasStripBypass = (streams: Array<LooseStream>, stages: Array<LooseStage>): boolean =>
    Boolean(
        streams.find((stream: LooseStream) => {
            if (stream.streamType === STREAM_TYPES.BYPASS_FEED) {
                const relatedStages = getStagesForStream(stream, stages);
                return relatedStages.to && relatedStages.to.stageType === STAGE_TYPES.STRIP;
            }
            return false;
        })
    );

/**
 * Returns true if there is at least 1 organic blend
 * @param {Array<Stream>} streams List of all streams in the mimic diagram
 */
export const hasOrganicBlend = (streams: Array<LooseStream>): boolean =>
    Boolean(
        streams.find(
            (stream: LooseStream) =>
                stream.streamType === STREAM_TYPES.BYPASS_BLEND &&
                stream.streamCircuit === STREAM_CIRCUITS.ORGANIC
        )
    );

/**
 * Returns true if there is a blend in the circuit
 * @param {Array<LooseStage>} extractStages All the extract stages
 * @param {Array<LooseStream>} streams All the streams in the circuit
 */
export const hasExtractBlend = (streams: Array<LooseStream>): boolean =>
    Boolean(
        streams.find(
            (stream: LooseStream) =>
                stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                stream.streamType === STREAM_TYPES.BLEND
        )
    );

/**
 * Returns true if there is a bleed in the middle of a cascade.
 * @param {Array<LooseStage>} extractStages All the extract stages
 * @param {Array<LooseStream>} streams All the streams in the circuit
 */
export const hasExtractInterstageBleed = (circuit: ImmutableCircuit): boolean => {
    const extractors = getExtractorStages(circuit);
    const jsStages = extractors.toJS();
    const jsStreams = circuit.get('streams').toJS();
    return Boolean(
        extractors.find((stage: ImmutableStage) => {
            // if we are the last stage, then it is not interstage.
            const lastStage = getLastStageInCascadeForStage(circuit, stage);
            if (stage === lastStage) {
                return false;
            }
            return getPlsBleedFromStage(stage.toJS(), jsStages, jsStreams);
        })
    );
};

/**
 * Get the advanced stream tied to this continue stream.
 * @param {LooseStream} potentialStream The continue stream
 * @param {Array<LooseStage>} Stages The list of stages in our circuit
 * @param {Array<LooseStream>} streams The list of streams in our circuit
 *
 * @returns {?(Array<LooseStream> | LooseStream)} Returns an array of advanced streams for the continue stream if there is more than 1 stream.
 */
export const getAdvancedStreamForContinue = (
    potentialStream: LooseStream,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): ?(Array<LooseStream> | LooseStream) => {
    const streamsWithEditButtons = [STREAM_TYPES.CONTINUE, STREAM_TYPES.SKIP];
    if (streamsWithEditButtons.indexOf(potentialStream.streamType) === -1) {
        return null; // this is not a continue or skip stream, therefore there is no advanced stream tied to this continue.
    }

    const continueRelatedStages = getStagesForStream(potentialStream, stages);
    switch (potentialStream.streamCircuit) {
        case STREAM_CIRCUITS.AQUEOUS: {
            const bleedStream = getPlsBleedFromStage(continueRelatedStages.from, stages, streams);
            const blendStream = getPlsBlendToStage(continueRelatedStages.to, stages, streams);
            if (bleedStream || blendStream) {
                return [bleedStream, blendStream].filter(Boolean);
            } else {
                return null; // there is no blend/bleed tied to this continue stream.
            }
        }
        case STREAM_CIRCUITS.ORGANIC: {
            const orgBlend = getOrganicBlendToStage(continueRelatedStages.to, stages, streams);
            if (orgBlend) {
                // Organic blend cannot be used in conjunction with org bypass.
                return orgBlend;
            }
            const orgBypass = getOrganicBypassBleed(continueRelatedStages.from, stages, streams);
            if (orgBypass) {
                // Organic bypass cannot be used in conjunction with org blends.
                return orgBypass;
            }
            return null;
        }
        case STREAM_CIRCUITS.ELECTROLYTE:
            // Electrolyte streams cannot have anything tied to their continue stream.
            return null;
        default:
            throw new Error(`Unknown stream circuits ${potentialStream.streamCircuit}`);
    }
};

/**
 * Returns true if there is at least one advanced stream type (feed, bleed, blend)
 * @param {LooseStream} potentialStream The potential continue stream that show have the edit button shown.
 * @param {Array<LooseStage>} stages All the extract stages
 * @param {Array<LooseStream>} streams All the streams in the circuit
 *
 * @returns {boolean} Returns true if we should hide the edit button, because the continue stream is tied to an advanced stream.
 */
export const streamShouldHideEditButton = (
    potentialStream: LooseStream,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): boolean => {
    const streamsWithEditButtons = [STREAM_TYPES.CONTINUE, STREAM_TYPES.SKIP];
    // Is the stream allowed to have edit buttons?
    if (streamsWithEditButtons.indexOf(potentialStream.streamType) === -1) {
        return false;
    }
    if (potentialStream.streamCircuit === STREAM_CIRCUITS.ORGANIC) {
        // is there a stage for the opposite circuit?
        const relatedStages = getStagesForStream(potentialStream, stages);
        if (relatedStages.from.stageType === STAGE_TYPES.EXTRACT) {
            // from and to extract.
            if (getStrippers(stages).length <= 0) {
                return true; // organic continue stream cannot have any bypasses/org blends if there are no strippers.
            }
        } else {
            if (getExtractors(stages).length <= 0) {
                return true; // organic continue stream cannot have any bypasses if there are no extractors.
            }
        }
        // return false; // There are no stages of the opposite type
    }

    const maybeStreams = getAdvancedStreamForContinue(potentialStream, stages, streams);
    // if we have a stream, or we have an array of 1 or more streams, then we must hide the edit button.
    return Boolean(maybeStreams || (Array.isArray(maybeStreams) && maybeStreams.length > 0));
};

/**
 * Please see the: getFirstConnectedFromStage() function in ORGANIC_TANK.js
 */
const getTankFirstConnectedFromExtractor = (
    tank: LooseStage,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): LooseStage | null => {
    const tankStreams = getStreamsForStage(tank, streams, stages);
    const fromStages = tankStreams
        .map((stream: LooseStream) => {
            const relatedStages = getStagesForStream(stream, stages);
            const fromStage = relatedStages.from;
            if (fromStage && fromStage.stageType === STAGE_TYPES.EXTRACT) {
                return fromStage;
            }
            return null;
        })
        .filter(Boolean)
        .sort((aStage: LooseStage, bStage: LooseStage) => aStage.location - bStage.location);
    if (fromStages.length === 0) {
        return null;
    }
    return fromStages[0];
};

/**
 * Is the stream the feed/bleed stream at the edges of our circuit?
 * @param {LooseStream} stream The stream we want to check if it is the edge stream
 * @param {Array<LooseStage>} stages The stages that define our circuit
 */
export const isEdgeStream = (
    stream: LooseStream,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): boolean => {
    if (
        stream.streamType !== STREAM_TYPES.FEED &&
        stream.streamType !== STREAM_TYPES.BLEED &&
        stream.streamType !== STREAM_TYPES.BYPASS_BLEED
    ) {
        // Only feed/bleed/bypass-bleed streams can be edge streams.
        return false;
    }
    const relatedStages = getStagesForStream(stream, stages);
    if (stream.streamType === STREAM_TYPES.BYPASS_BLEED) {
        let stage = relatedStages.from;
        if (!stage) {
            throw new Error('Bypass bleed does not have a from stage?');
        }
        const isInitiallyFromTank = stage.stageType === STAGE_TYPES.ORGANIC_TANK;
        if (isInitiallyFromTank) {
            const fromExtract = getTankFirstConnectedFromExtractor(stage, stages, streams);
            if (!fromExtract) {
                throw new Error('An LO tank must have a first connected extractor.');
            }
            stage = fromExtract;
        }
        do {
            let nextStage = null;
            if (isInitiallyFromTank) {
                nextStage = getPreviousStage(stage, stages);
            } else if (stage.stageType === STAGE_TYPES.EXTRACT) {
                nextStage = getNextStage(stage, stages);
            } else if (stage.stageType === STAGE_TYPES.STRIP) {
                nextStage = getPreviousStage(stage, stages);
            }
            if (nextStage) {
                const orgBypassNextStage = getOrganicBypassBleed(nextStage, stages, streams);
                // if there are no org bypassing, go to the next stage.
                if (orgBypassNextStage) {
                    const orgBypassrelatedStages = getStagesForStream(orgBypassNextStage, stages);
                    if (
                        orgBypassrelatedStages.to &&
                        orgBypassrelatedStages.to.stageType === STAGE_TYPES.ORGANIC_TANK
                    ) {
                        // if the bypass bleed from extract is to a LO tank, then the bypasses reset
                        return false; // it is merging into an existing bypass
                    }
                }
            } else {
                return true; // if we dont have a next stage it MUST be a bypass bleed edge stream.
            }
            stage = nextStage;
        } while (stage !== null);
    }

    let edgeStage = null;
    if (stream.streamType === STREAM_TYPES.FEED) {
        edgeStage = relatedStages.to;
    } else {
        // if it is not a feed stream, it must be a bleed stream.
        edgeStage = relatedStages.from;
    }
    if (!edgeStage) {
        throw new Error('No edgeStage for stream...');
    }
    const extractStages = getExtractors(stages);
    const stripStages = getStrippers(stages);
    const sectionStages = edgeStage.stageType === STAGE_TYPES.EXTRACT ? extractStages : stripStages;

    if (
        (edgeStage.stageType === STAGE_TYPES.EXTRACT &&
            stream.streamCircuit !== STREAM_CIRCUITS.ORGANIC) ||
        (stream.streamCircuit === STREAM_CIRCUITS.ORGANIC && extractStages.length === 0)
    ) {
        return (
            (stream.streamType === STREAM_TYPES.FEED && edgeStage.location === 1) ||
            (stream.streamType === STREAM_TYPES.BLEED &&
                edgeStage.location === sectionStages.length)
        );
    } else if (
        (edgeStage.stageType === STAGE_TYPES.STRIP &&
            stream.streamCircuit !== STREAM_CIRCUITS.ORGANIC) ||
        (stream.streamCircuit === STREAM_CIRCUITS.ORGANIC && stripStages.length === 0)
    ) {
        return (
            (stream.streamType === STREAM_TYPES.BLEED && edgeStage.location === 1) ||
            (stream.streamType === STREAM_TYPES.FEED && edgeStage.location === sectionStages.length)
        );
    }
    return false;
};

/**
 * Does this stream contain a sister stream. I.e. aqueous bleed/blend are advanced if they are both present.
 * This function should only be called in
 * @param {LooseStream} stream The stream we want to know if it is advanced
 * @param {Array<LooseStage>} stages The stages taht belong to our circuit
 * @param {Array<LooseStream>} streams The streams taht belong to our circuit
 */
export const isAdvancedStream = (
    stream: LooseStream,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): boolean => {
    if (
        (stream.streamType !== STREAM_TYPES.BLEED && stream.streamType !== STREAM_TYPES.BLEND) ||
        stream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
    ) {
        // Only aqueous bleed, blend can be of type advanced.
        return false;
    }
    const relatedStages = getStagesForStream(stream, stages);
    if (stream.streamType === STREAM_TYPES.BLEED) {
        const nextStage = getNextStage(relatedStages.from, stages);
        if (!nextStage) return false; // the next stage is at location 11...
        const plsContinue = getPlsContinueToStageFromStage(
            nextStage,
            relatedStages.from,
            stages,
            streams
        );
        return Boolean(plsContinue);
    } else {
        // must be a blend.
        const prevStage = getPreviousStage(relatedStages.to, stages);
        if (!prevStage) return false; // the previous stage is at location 0...
        const plsBleed = getPlsBleedFromStage(prevStage, stages, streams);
        return Boolean(plsBleed);
    }
};

export const isStreamIntoOrOutOfSkip = (
    stream: LooseStream,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): boolean => {
    const streamTypesIntoOrOutOfSkip = [STREAM_TYPES.BLEND, STREAM_TYPES.BLEED];
    if (
        streamTypesIntoOrOutOfSkip.indexOf(stream.streamType) === -1 ||
        stream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
    ) {
        return false;
    }

    const relatedStages = getStagesForStream(stream, stages);
    if (stream.streamType === STREAM_TYPES.BLEND) {
        // blend stream:
        const stageTo = relatedStages.to;
        const skipStreamTo = streams.find((skipStream: LooseStream) => {
            if (skipStream.streamType !== STREAM_TYPES.SKIP) {
                return false;
            }

            const skipToStage = skipStream.to
                ? skipStream.to
                : getStageById(skipStream.toStageId, stages);
            return (
                skipToStage.location === stageTo.location &&
                skipToStage.stageType === stageTo.stageType
            );
        });

        return Boolean(skipStreamTo);
    } else {
        // bleed stream
        const stageFrom = relatedStages.from;

        const skipStreamTo = streams.find((skipStream: LooseStream) => {
            if (skipStream.streamType !== STREAM_TYPES.SKIP) {
                return false;
            }

            const skipToStage = skipStream.from
                ? skipStream.from
                : getStageById(skipStream.fromStageId, stages);
            return (
                skipToStage.location === stageFrom.location &&
                skipToStage.stageType === stageFrom.stageType
            );
        });

        return Boolean(skipStreamTo);
    }
};

/**
 * Return true if a PLS(Aqueous) bleed going from stage prior to the skip-to stage
 * or feed stream to stage that goes right after the skip-from stage
 */
export const isRelatedToSkip = (
    stream: LooseStream,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): boolean => {
    if (
        stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
        stream.streamType === STREAM_TYPES.FEED
    ) {
        // Check if previous extract stage has a skip stream going from it
        const stage = stream.to ? stream.to : getStageById(stream.toStageId, stages);
        return Boolean(
            streams.find((skipStream: LooseStream) => {
                if (skipStream.streamType === STREAM_TYPES.SKIP) {
                    const skipFromStage = skipStream.from
                        ? skipStream.from
                        : getStageById(skipStream.fromStageId, stages);
                    return skipFromStage.location === stage.location - 1;
                }
                return false;
            })
        );
    } else if (
        stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
        stream.streamType === STREAM_TYPES.BLEED
    ) {
        // Check if next extract stage has a skip stream coming into it
        const stage = stream.from ? stream.from : getStageById(stream.fromStageId, stages);
        return Boolean(
            streams.find((skipStream: LooseStream) => {
                if (skipStream.streamType === STREAM_TYPES.SKIP) {
                    const skipToStage = skipStream.to
                        ? skipStream.to
                        : getStageById(skipStream.toStageId, stages);
                    return skipToStage.location === stage.location + 1;
                }
                return false;
            })
        );
    } else {
        return false;
    }
};

/**
 *
 * @param {Stream} stream The stream that is potentially removable
 * @param {Array<LooseStage>} stages The stages that belong to our circuit
 * @param {Array<LooseStream>} streams The streams that belong to our circuit
 */
export const isStreamRemovable = (
    stream: LooseStream,
    stages: Array<LooseStage>,
    streams: Array<LooseStream>
): boolean => {
    if (isEdgeStream(stream, stages, streams)) {
        return false;
    }
    if (stream.streamType === STREAM_TYPES.BYPASS_BLEED) {
        return false;
    }
    if (
        stream.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE &&
        (stream.streamType === STREAM_TYPES.FEED || stream.streamType === STREAM_TYPES.BLEED)
    ) {
        // Must check that feed/bleeds in electrolyte circuit isn't at the same location as a strip bypass
        const relatedStages = getStagesForStream(stream, stages);
        let edgeStage = null;
        if (stream.streamType === STREAM_TYPES.FEED) {
            edgeStage = relatedStages.to;
            // if there is an electrolyte feed, then we must check if there is an organic bleed out of the to stage.
            if (getOrganicBypassBleed(edgeStage, stages, streams)) {
                return false;
            }
            const nextStage = getNextStage(edgeStage, stages);
            if (!nextStage) {
                return false; // this is an edge stream. It should've been caught above.
            }
            if (getElectrolyteBleedFromStage(nextStage, stages, streams)) {
                // This means the next stage has a bleed, and the PREV stage has a new feed.
                // This means the feed stream is NOT removable.
                return false;
            }
        } else if (stream.streamType === STREAM_TYPES.BLEED) {
            edgeStage = relatedStages.from;
            // if there is an electrolyte bleed, then we must check if there is an organic feed into the from stage.
            if (getOrganicBypassFeed(edgeStage, stages, streams)) {
                return false;
            }
        }
    }
    // check for PLS Bleed. It should NOT be removable if the next stage has a new feed, or if the next stage has a blend.
    if (
        stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
        stream.streamType === STREAM_TYPES.BLEED
    ) {
        const relatedStages = getStagesForStream(stream, stages);
        const nextStage = getNextStage(relatedStages.from, stages); // get the next stage to see if there's a new feed in it.
        if (!nextStage) {
            return false; // this is an edge stream. It should've been caught above.
        }
        if (getPlsFeedToStage(nextStage, stages, streams)) {
            // This means the FROM stage had a bleed, and the NEXT stage has a new feed.
            // This means the bleed stream is NOT removable.
            return false;
        }
        if (getPlsBlendToStage(nextStage, stages, streams)) {
            // this means our FROM stage had a bleed, and the NEXT stage has a PLS blend.
            // this means the bleed stream is NOT removable.
            return false;
        }
    }
    // Check for PLS skip. The stage AFTER the skip start should have a non-removable new feed,
    // and the stage BEFORE the skip end should have a non-removable bleed.
    if (
        stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
        (stream.streamType === STREAM_TYPES.FEED || stream.streamType === STREAM_TYPES.BLEED)
    ) {
        if (isRelatedToSkip(stream, stages, streams)) {
            // This stream is related to a skip stream so it is not removable.
            return false;
        }
    }

    return true;
};

/**
 * Get all the stream values for the given stream. This is helpful to determine the height of our mimic diagram.
 * @param {Stream} stream A stream that has an ID
 * @param {Array<LooseStreaMValue>} values All the values our dataset has.
 */
export const countStreamValuesForStream = (values: Array<LooseStreamValue>) => (
    stream: LooseStream
): number =>
    values.filter((streamValue: LooseStreamValue) => streamValue.streamId === stream.id).length;

/**
 * Get the amount of rows before the STRIP stages in compute mode.
 * @param {Array<LooseStage>} stages circuit stages
 * @param {Array<LooseStream>} streams  circuit streams
 * @param {Array<LooseStreamValue>} values  dataset stream values
 */
export const getMaxStreamValuesForOrganic = (
    stages: Array<LooseStage>,
    streams: Array<LooseStream>,
    values: Array<LooseStreamValue>
): number => {
    const containsExtractBypassBlend =
        hasExtractBypass(streams, stages) || hasOrganicBlend(streams);
    const containsStripBypass = hasStripBypass(streams, stages);

    let bypassOffsets = 0;
    // if we contain either one or the other, but not both bypasses
    if (
        (containsExtractBypassBlend && !containsStripBypass) ||
        (containsStripBypass && !containsExtractBypassBlend)
    ) {
        bypassOffsets = 2;
    }
    if (containsExtractBypassBlend && containsStripBypass) {
        bypassOffsets += 4;
    }

    let organicEdgeOffsets = 0;
    // get both connector streams.
    const connectorStreams = streams.filter((stream: LooseStream) => {
        if (
            stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
            stream.streamType === STREAM_TYPES.CONTINUE
        ) {
            const relatedStages = getStagesForStream(stream, stages);
            return relatedStages.from.stageType !== relatedStages.to.stageType;
        }
        return false;
    });
    if (connectorStreams.length !== 0) {
        // remove all stream bypass values, to get the amount of stream values between the stages.
        // the bypasses already take into account the stream values for the bypasses
        const removedBypassValues = values.filter(
            (streamValue: LooseStreamValue) =>
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT, STREAM_VALUE_TYPES.PRE_COMPOSITION].indexOf(
                    streamValue.valueType
                ) === -1
        );
        // the amount of stream values on our edges indicate the offset count.
        organicEdgeOffsets = Math.max(
            ...connectorStreams.map(countStreamValuesForStream(removedBypassValues))
        );
    } else {
        // There will only ever be 1 organic feed stream in single circuit type circuits.
        const organicFeedStream = streams.find(
            (stream: LooseStream) =>
                stream.streamType === STREAM_TYPES.FEED &&
                stream.streamCircuit === STREAM_CIRCUITS.ORGANIC
        );
        const organicBleedStream = streams.find(
            (stream: LooseStream) =>
                stream.streamType === STREAM_TYPES.BLEED &&
                stream.streamCircuit === STREAM_CIRCUITS.ORGANIC
        );

        if (!organicFeedStream || !organicBleedStream) {
            throw new Error('Single circuit type without an organic feed or organic bleed stream?');
        }

        // the amount of stream values on our edges indicate the offset count.
        organicEdgeOffsets =
            1 +
            Math.max(
                countStreamValuesForStream(values)(organicFeedStream),
                countStreamValuesForStream(values)(organicBleedStream)
            );
    }

    return bypassOffsets + organicEdgeOffsets;
};

/**
 * Returns true if we should show the local recoveries on the circuit.
 * If there are skip streams, extract blend streams, or any advanced bleeds,
 * then we must hide recoveries.
 *
 * Only used for analysis mode.
 *
 * @param {ImmutableCircuit} circuit The circuit
 */
export const shouldShowLocalRecovery = (circuit: ImmutableCircuit) => {
    const streams = circuit.get('streams').toJS();

    if (hasSkipStream(streams)) {
        return false;
    }
    if (hasExtractBlend(streams)) {
        return false;
    }
    if (hasExtractInterstageBleed(circuit)) {
        return false;
    }
    return true;
};

export const getFeedStreams = (circuit: ImmutableCircuit, includePLSBlends: boolean) =>
    circuit.get('streams').filter((stream: ImmutableStream) => {
        const isFeed = stream.get('streamType') === STREAM_TYPES.FEED;
        const isBlend = stream.get('streamType') === STREAM_TYPES.BLEND;
        return (
            isFeed ||
            (includePLSBlends && isBlend && stream.get('streamCircuit') === STREAM_CIRCUITS.AQUEOUS)
        );
    });

export const getBleedStreams = (circuit: ImmutableCircuit) =>
    circuit
        .get('streams')
        .filter((stream: ImmutableStream) => stream.get('streamType') === STREAM_TYPES.BLEED);

export const getLoadedOrganicStream = (circuit: ImmutableCircuit) => {
    const strippers = getStripperStages(circuit);
    const firstStripper = strippers.first();
    return circuit
        .get('streams')
        .find(
            (stream: ImmutableStream) =>
                stream.get('toStageId') === firstStripper.get('id') &&
                stream.get('streamType') === STREAM_TYPES.CONTINUE &&
                stream.get('streamCircuit') === STREAM_CIRCUITS.ORGANIC
        );
};

export const getBarrenOrganicStream = (circuit: ImmutableCircuit) => {
    const extractors = getExtractorStages(circuit);
    const lastExtractor = extractors.last();
    return circuit
        .get('streams')
        .find(
            (stream: ImmutableStream) =>
                stream.get('toStageId') === lastExtractor.get('id') &&
                stream.get('streamType') === STREAM_TYPES.CONTINUE &&
                stream.get('streamCircuit') === STREAM_CIRCUITS.ORGANIC
        );
};

/**
 * If the the stream is a cascade outlet and of type bleed, we want
 * to display "Raffinate" instead of "Bleed". We use that passed prop
 * to evaluate that and display the appropriate text in the child component
 */
export const isStreamInterstageBleed = (stream: LooseStream, streams: Array<LooseStream>) => {
    if (
        stream.streamType !== STREAM_TYPES.BLEED ||
        stream.streamCircuit !== STREAM_CIRCUITS.AQUEOUS
    ) {
        return false;
    }

    const hasOutgoingContinueStream = streams.find((el) => {
        const matchPerFromStageId =
            stream.fromStageId && el.fromStageId && stream.fromStageId === el.fromStageId;

        const matchPerFromObject =
            stream.from &&
            el.from &&
            el.from.location === stream.from.location &&
            el.from.streamType === stream.from.streamType;

        return (
            (matchPerFromStageId || matchPerFromObject) &&
            el.streamType === STREAM_TYPES.CONTINUE &&
            el.streamCircuit === STREAM_CIRCUITS.AQUEOUS
        );
    });

    return Boolean(hasOutgoingContinueStream);
};
