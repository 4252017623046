// @flow strict

import type { ReduxDispatch, ResponseErrorType, SearchCriteria } from 'types';
import { createUntranslatedFeedback } from 'services/Feedback/actions';
import { report } from 'services/Errors/resources';

import {
    receivedCreateSuccess,
    receivedCreateFailure,
    receivedFetchListSuccess,
    receivedFetchListFailure,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    receivedDestroySuccess,
    receivedDestroyFailure,
    receivedQueryListSuccess,
    receivedQueryListFailure,
    receivedDownloadTemplateSuccess,
    receivedDownloadTemplateFailure,
    receivedDashboardPlantsListSuccess,
    receivedDashboardPlantsListFailure,
    setIsCreatingStatus,
    setIsDeletingStatus,
    setIsFetchingStatus,
    setIsUpdatingStatus,
    setIsQueryStatus,
    setIsDownloadingTemplateStatus,
    setIsDashboardPlantsStatus,
} from './actions';
import type { Plant, ImmutablePlant, PlantQueryType } from 'services/Plant/types';
import {
    create,
    index,
    update,
    destroy,
    query,
    queryDashboard,
    downloadTemplate as download,
} from './resources';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Create new plant
 */
export const createPlant = (plant: ImmutablePlant) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingStatus());
    create(plant)
        .then((response: Plant) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.createPlantSuccess'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createPlantFailed'));
        });
};

/**
 * Fetch all plants
 */
export const fetchAllPlants = () => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingStatus());
    index()
        .then((response: Array<Plant>) => {
            dispatch(receivedFetchListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchAllPlantsFailed'));
        });
};

/**
 * Query (search, pagination, etc) all plants
 */
export const queryAllPlants = (searchCriteria: SearchCriteria, page: number, perPage: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsQueryStatus());
    query(searchCriteria, page, perPage)
        .then((response: PlantQueryType) => {
            dispatch(receivedQueryListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedQueryListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.queryAllPlantsFailed'));
        });
};

/**
 *
 * Query (with pagination) all user/role plants for the dashboard
 */
export const queryDashboardPlants = (searchCriteria: SearchCriteria, page: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsDashboardPlantsStatus());
    queryDashboard(searchCriteria, page)
        .then((response: PlantQueryType) => {
            dispatch(receivedDashboardPlantsListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDashboardPlantsListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.queryAllPlantsFailed'));
        });
};

/**
 * Update plant
 */
export const updatePlant = (id: number, plant: ImmutablePlant) => (dispatch: ReduxDispatch) => {
    dispatch(setIsUpdatingStatus());
    update(id, plant)
        .then((response: Plant) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updatePlant'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updatePlantFailed'));
        });
};

/**
 * Destroy plant
 */
export const destroyPlant = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsDeletingStatus());
    destroy(id)
        .then((response: Plant) => {
            dispatch(receivedDestroySuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.destroyPlantSuccess'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDestroyFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.destroyPlantFailed'));
        });
};

export const downloadTemplate = (id: number, fileName: string) => (dispatch: ReduxDispatch) => {
    dispatch(setIsDownloadingTemplateStatus());
    download(id)
        .then((response: Blob) => {
            dispatch(receivedDownloadTemplateSuccess(fileName, response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.downloadPlantTemplateSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDownloadTemplateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.downloadPlantTemplateFailed')
            );
        });
};
