// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 29px;
    margin-left: 40px;
`;

export const Message = styled.p`
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-size: 17px;
    line-height: 20px;
    margin: 0;
`;
