// @flow strict

import styled from 'styled-components';

// This contains the stream values and the ports.
export const StreamValuesWrapper = styled.div`
    grid-column-start: ${({ startingColumn }) => startingColumn};
    grid-column-end: span ${({ columnSpan }) => columnSpan};
    grid-row-start: ${({ startingRow }) => startingRow};
    grid-row-end: span ${({ rowSpan }) => rowSpan};
    display: flex;
    position: relative;

    z-index: 2;
`;

export const TopPortsWrapper = styled.div`
    width: 100%;
    height: 0px;
    overflow: hidden;
    position: absolute;
`;

export const BottomPortsWrapper = styled.div`
    width: 100%;
    height: 0px;
    overflow: hidden;
    bottom: 0;
    position: absolute;
`;

export const LeftPortsWrapper = styled.div`
    width: 0px;
    height: 100%;
    overflow: hidden;
    position: absolute;
`;

export const RightPortsWrapper = styled.div`
    width: 0px;
    height: 100%;
    overflow: hidden;
    right: 0;
    position: absolute;
`;
