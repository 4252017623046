// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';

// Components
import { Caret } from 'components/_ReactUI_V1';

// Styles
import { Wrapper, HeaderWrapper } from './styles';

// Types
import type { HistoryType } from 'types';

type Props = {
    history: HistoryType,
    onBackTo?: () => void,
    backToLink?: string,
    backToLabel: string,
};

class BackToNavigator extends React.PureComponent<Props> {
    onBackClicked = () => {
        if(this.props.onBackTo) {
            this.props.onBackTo();
            return;
        } else if(this.props.backToLink) {
            this.props.history.push(this.props.backToLink);
        }
    };

    render() {
        return (
            <Wrapper>
                <HeaderWrapper onClick={this.onBackClicked}>
                    <Caret left grey />
                    <div>{this.props.backToLabel}</div>
                </HeaderWrapper>
            </Wrapper>
        );
    }
}

export default withRouter(BackToNavigator);
