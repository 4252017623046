// @flow strict

import styled from 'styled-components';

export const CascadeDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
`;
