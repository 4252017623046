// @flow

import styled from 'styled-components';
import { StyledInputBase } from '../input-field';

export const Textarea = styled.textarea`
    ${StyledInputBase}

    min-height: ${({ minHeight }: Object) => minHeight || '72px'};
    padding: 12px 16px;

    resize: vertical;
`;
