// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Mimic Engine
import type { IMimicCircuit, IContinueStream } from 'components/_McCabeThiele';

// Assets
import { PrimaryButton, TertiaryButton, RadioButtonSet, Modal } from 'components/_ReactUI_V1';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    BypassLabel,
    RadioStyles,
    ImageStyle,
    RadioSuffixWrapper,
} from './styles';

// Components
import { Title } from 'styles/common';

// Types
import type { IntlType } from 'types';
import type { OrganicInStripOptionConstant } from 'services/Circuit/types';

// constants
import { MODAL_WIDTH, ORGANIC_IN_STRIP_STREAM_OPTIONS } from 'utils/constants';

// Preview Assets:
import DirectElectrolyteStreamImage from 'assets/mimic-diagrams-stream-images/organic-electrolyte-direct-feed.jpg';

import BypassElectrolyteStreamImage from 'assets/mimic-diagrams-stream-images/organic-electrolyte-bypass-feed.jpg';

export const PREVIEW_TYPES = {
    // Defaults:
    DIRECT_ELECTROLYTE: 'DIRECT_ELECTROLYTE',

    // Strip Stage Bypassing:
    STRIP_ONLY_BYPASS: 'STRIP_ONLY_BYPASS',
};

const PREVIEW_IMAGES = {
    // Defaults:
    [PREVIEW_TYPES.DIRECT_ELECTROLYTE]: DirectElectrolyteStreamImage,

    // Strip Stage Bypassing:
    [PREVIEW_TYPES.STRIP_ONLY_BYPASS]: BypassElectrolyteStreamImage,
};

type OptionType = { label: string, value: OrganicInStripOptionConstant };

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    mimicCircuit: IMimicCircuit,
    mimicStream: IContinueStream,

    currentLocation: number, // Stage location of the previous settler in the stream

    onConfirm: (
        organicStreamOption: OrganicInStripOptionConstant,
        currentLocation: number,
        isExtractionSection: boolean
    ) => void,
    onCancel: () => void,
};

type State = {
    // the keys used by the radio button set
    selectedOrganicStreamOption: OrganicInStripOptionConstant,
};

/**
 * Shown when one of the mimic diagram edit button is clicked between an aqueous and an organic stage or between an electrolyte and organic stage
 */
class OrganicStreamSelectModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedOrganicStreamOption: ORGANIC_IN_STRIP_STREAM_OPTIONS.DIRECT,
            toTank: null,
            fromTank: null,
        };
    }

    getTranslation(id: string) {
        return this.props.intl.formatMessage({
            id: `components.Modals.OrganicStreamSelectModal.${id}`,
        });
    }

    /**
     * Get the Organic stream radio options depending on the modalType
     */
    getOrganicStreamOptions = (): Array<OptionType> =>
        Object.keys(ORGANIC_IN_STRIP_STREAM_OPTIONS).map((value: OrganicInStripOptionConstant) => ({
            value,
            label: this.props.intl.formatMessage({
                id: `components.Modals.OrganicStreamSelectModal.${value}`,
            }),
        }));

    getPreviewImageType = () => {
        switch (this.state.selectedOrganicStreamOption) {
            case ORGANIC_IN_STRIP_STREAM_OPTIONS.DIRECT:
                return PREVIEW_TYPES.DIRECT_ELECTROLYTE;

            case ORGANIC_IN_STRIP_STREAM_OPTIONS.BYPASS:
                return PREVIEW_TYPES.STRIP_ONLY_BYPASS;
            default:
                throw new Error('Unknown organic stream radio choice selected...');
        }
    };

    /**
     * Buttons is disabled when Series configuration is selected since it is the default selection and does not require any change
     */
    isSelectButtonDisabled = (): boolean =>
        this.state.selectedOrganicStreamOption === ORGANIC_IN_STRIP_STREAM_OPTIONS.DIRECT;

    /**
     * When the select button is clicked, call our parent with the options selected
     */
    handleSelectClicked = () =>
        this.props.onConfirm(
            this.state.selectedOrganicStreamOption,
            this.props.currentLocation,
            false
        );

    /**
     * When the Cascade RF radio buttons is changed
     */
    handleOrganicStreamOptionChange = (selectedOrganicStreamOption: OrganicInStripOptionConstant) =>
        this.setState({
            selectedOrganicStreamOption,
        });

    renderStrippingBypassSuffix = (value: OrganicInStripOptionConstant) => {
        if (value === ORGANIC_IN_STRIP_STREAM_OPTIONS.BYPASS) {
            return (
                <BypassLabel>
                    {this.props.intl.formatMessage({
                        id: 'components.Modals.OrganicStreamSelectModal.bypassLabelInfo',
                    })}
                </BypassLabel>
            );
        }
        return null;
    };

    /**
     * Render the warning label for the bypass option when the modalType is ELECTROLYTE-ORGANIC
     */
    renderBypassSuffixes = (value: OrganicInStripOptionConstant, isActive: boolean) => {
        if (!isActive) {
            return null;
        }
        return <RadioSuffixWrapper>{this.renderStrippingBypassSuffix(value)}</RadioSuffixWrapper>;
    };

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.OrganicStreamSelectModal.title',
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <RadioButtonSet
                                value={this.state.selectedOrganicStreamOption}
                                options={this.getOrganicStreamOptions()}
                                onClick={this.handleOrganicStreamOptionChange}
                                styles={RadioStyles}
                                renderSuffix={this.renderBypassSuffixes}
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.Modals.preview',
                                })}
                            </Label>
                            <img
                                src={PREVIEW_IMAGES[this.getPreviewImageType()]}
                                alt={this.props.intl.formatMessage({
                                    id: `components.Modals.OrganicStreamSelectModal.${
                                        this.state.selectedOrganicStreamOption
                                    }`,
                                })}
                                style={ImageStyle}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <TertiaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.selectButton',
                            })}
                            onClick={this.handleSelectClicked}
                            disabled={this.isSelectButtonDisabled()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(OrganicStreamSelectModal);
