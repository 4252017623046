// @flow strict

import styled from 'styled-components';
import { colors } from 'styles/colors';
import { LegacyTheme } from 'components/_ReactUI_V1';

export const SidebarTabHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    cursor: ${({ onClick }: Object) => (onClick ? 'pointer' : 'initial')};
    color: ${({ active }: Object) =>
        active ? LegacyTheme.black : LegacyTheme.defaultSubtleColor7B};

    width: 100%;
    padding: 20px;


    &:hover {
        color: ${({ active, onClick }: Object) =>
            onClick || active ? LegacyTheme.black : LegacyTheme.defaultSubtleColor7B};
    }

    box-sizing: border-box;
`;

export const SidebarTabTitle = styled.h1`
    font-size: 20px;
    font-weight: normal;
`;

export const StyledSidebarTabBody = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;

    ${({ backgroundStyling }: Object) =>
        backgroundStyling &&
    `background-color : ${LegacyTheme.sidebarLayoutSidebarSubcontent };`
}

    border-bottom: 1px solid ${LegacyTheme.defaultBorder};
`;
