// @flow strict

import React from 'react';

// Styles
import { Wrapper, Head, Title, LoadingContent, Body, Row } from './styles';

// Components
import { LoadingDots } from 'components/_ReactUI_V1';

// Types
export type RowType = {
    id: number,
    render: () => void,
};

type Props = {
    title: string,
    rows: Array<RowType>,
    loading?: boolean,
    styles?: {},
    withMargin?: boolean,
};

/*
    Allows the display of a list or resources.
    Gives the possibility to:
        - Select/De-select multiple items
        - Trigger delete action for an item (TODO - trigger icon in place, action to be implemented by story to come)
        - Scroll the list
 */
class MultiList extends React.PureComponent<Props, null> {
    static defaultProps = {
        loading: false,
        styles: {},
        withMargin: false,
    };

    render() {
        return (
            <Wrapper withMargin={this.props.withMargin} styles={this.props.styles}>
                <Head>
                    <Title>{this.props.title}</Title>
                </Head>
                <Body>
                    {this.props.loading && (
                        <Row styles={this.props.styles} loading>
                            <LoadingContent>
                                <LoadingDots />
                            </LoadingContent>
                        </Row>
                    )}
                    {this.props.rows.map((row: RowType) => (
                        <Row key={row.id} styles={this.props.styles}>
                            {row.render()}
                        </Row>
                    ))}
                </Body>
            </Wrapper>
        );
    }
}

export default MultiList;
