// @flow strict

import React from 'react';
import { matchPath } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { NAVBAR_DASHBOARD_V2_REDIRECT } from 'env';

// Components
import { TabNav } from 'components/_ReactUI_V1';

// Constants & Helpers
import { NAVIGATION_ROUTES } from 'utils/constants';
import { tryParseNumberOrNull } from 'utils/helpers';
import { SolvExtractNavigation } from 'utils/SolvExtractNavigation';

// Services
import { selectUser } from 'services/Authentication/selectors';
import { selectCircuit, selectAllCircuitTrends } from 'services/Trends/selectors';

// Types
import type { ImmutableList, IntlType, ReduxDispatch, RouteType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableCircuitTrend } from 'services/Trends/types';

type Props = {
    // Provided:
    currentLocation: string,
    route: RouteType,
    // Injected:
    intl: IntlType,
    user: ImmutableUser,
    circuit: ?ImmutableCircuit,
    circuitTrends: ImmutableList<ImmutableCircuitTrend>,
};

class SolvExtractPageNav extends React.PureComponent<Props> {
    getParams = () => {
        const match = matchPath(this.props.currentLocation, {
            path: this.props.route.path,
            exact: true,
            strict: false,
        });
        if (!match) {
            return null;
        }
        return match.params;
    };

    getCircuitId = () => {
        const params = this.getParams();
        if (!params || !params.circuitId) {
            return null;
        }
        const circuitId = tryParseNumberOrNull(params.circuitId);
        return circuitId;
    };

    getPlantId = () => {
        const params = this.getParams();
        if (!params || !params.plantId) {
            return null;
        }
        const plantId = tryParseNumberOrNull(params.plantId);
        return plantId;
    };

    getPlantIdFromCircuit = () => {
        if (this.props.circuit) {
            return this.props.circuit.get('plantId', null);
        } else if (this.props.circuitTrends && this.getCircuitId()) {
            const circuitTrend = this.props.circuitTrends.find(
                (trend: ImmutableCircuitTrend) => trend.get('circuitId') === this.getCircuitId()
            );
            if (circuitTrend) {
                return circuitTrend.get('plantId', null);
            }
        }
        return null;
    }

    isPlantPage = () => Boolean(this.props.route.path.match(`^${NAVIGATION_ROUTES.PLANT}`));

    getBaseRoute = (routeSuffix) => {
        const isArchive = routeSuffix === NAVIGATION_ROUTES.ARCHIVE;
        const plantId = this.getPlantId();
        const circuitId = this.getCircuitId();

        let plantRoute = `${NAVIGATION_ROUTES.PLANT}${plantId}`;

        if (circuitId) {
            const circuitRoute = `${NAVIGATION_ROUTES.CIRCUIT}${circuitId}`;

            if (isArchive || !NAVBAR_DASHBOARD_V2_REDIRECT || plantId === null) {
                return `${circuitRoute}${routeSuffix}`;
            }

            return `${plantRoute}${circuitRoute}`;
        } else if (this.isPlantPage()) {
            return `${plantRoute}${routeSuffix}`;
        }
        throw new Error('Unknown page type provided to SolvExtract page navigation.');
    };

    /**
     * There's a bit of complexity in these URLs because we have to handle a few different URL cases
     *
     * - Plant dashboard: /plant/123/trends
     * - Plant archive: /plant/123/archive
     * - Plant reports: /plant/123/reports
     *
     * - Legacy Circuit trends: /circuit/456/trends
     * - Circuit archive: /circuit/456/archive
     * - v2 Circuit dashboard: /plant/123/circuit/456
     *
     * When we're in a circuit archive, or the legacy circuit trends, we don't have the plant ID in the URL.
     * So we need to find it using the circuit object, if it was loaded.
     *
     * When we're on a plant page (dashboard, archive or reports), we don't have the circuit ID, so
     * we go to the plant trends page using the base route navigation.
     */
    getDashboardUrl = () => {
        const plantIdFromParams = this.getPlantId();
        const circuitIdFromParams = this.getCircuitId();

        if(!circuitIdFromParams) {
            // Seems like we are on the plant dashboard or archive pages, maybe RGS?
            return this.getBaseRoute(NAVIGATION_ROUTES.TRENDS);
        }

        if(plantIdFromParams) {
            // seems like we are most likely already on the new circuit dashboard, since we have
            // both the plant id and the circuit id in the params.
            return SolvExtractNavigation.getCircuitDashboardUrl(plantIdFromParams, circuitIdFromParams);
        }

        // we have a circuit ID, but we don't have a plant id. We need to get the plant id from the circuit.
        // this is because on legacy archive or trends pages we only have the circuit id.
        // ex: the url is most likely: /circuit/272/archive, or /circuit/272/trends
        const plantIdFromCircuit = this.getPlantIdFromCircuit();
        if (plantIdFromCircuit) {
            return SolvExtractNavigation.getCircuitDashboardUrl(plantIdFromCircuit, circuitIdFromParams);
        }
        // if all else fails:
        // well just default to the legacy behavior, most likely will just end up on the trends page
        // and might redirect to the v2 from there.
        return this.getBaseRoute(NAVIGATION_ROUTES.TRENDS);
    }

    getReportRoute = () => {
        let plantId = null;
        if (this.isPlantPage()) {
            plantId = this.getPlantId();
        } else {
            plantId = this.getPlantIdFromCircuit();
        }
        if (!plantId) {
            return null;
        }

        return {
            label: this.props.intl.formatMessage({
                id: 'navigation.Reports',
            }),
            to: `${NAVIGATION_ROUTES.PLANT}${plantId}${NAVIGATION_ROUTES.PLANT_REPORTS}`,
        };
    };

    render() {
        const options = [
            {
                label: this.props.intl.formatMessage({
                    id: 'navigation.Trends',
                }),
                to: this.getDashboardUrl()
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'navigation.Archive',
                }),
                to: this.getBaseRoute(NAVIGATION_ROUTES.ARCHIVE),
            },
        ];
        const reportOption = this.getReportRoute();
        if (reportOption) {
            options.push(reportOption);
        }

        return <TabNav current={this.props.currentLocation} options={options} maxWidth="450" />;
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
    circuit: selectCircuit(),
    circuitTrends: selectAllCircuitTrends(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SolvExtractPageNav));
