// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Components
import PhoneVerificationModal from 'components/Modals/PhoneVerificationModal';

// Services
import {
    selectPhoneVerificationRequestId,
    selectPhoneVerificationIsLoading,
    selectPhoneVerificationErrors,
    selectUserPhoneNumber,
} from 'services/Authentication/selectors';
import {
    startUserPhoneVerification,
    resendUserPhoneVerificationOTC,
    checkUserPhoneVerification,
} from 'services/Authentication/thunks';
import { newFeedback } from 'services/Feedback/thunks';

// Types
import type { ImmutableError } from 'services/Errors/types';
import type { FeedbackType } from 'services/Feedback/types';

type InjectedProps = {
    requestId: string,
    isLoading: boolean,
    errors: ImmutableError,
    userPhoneNumber: string | null,

    startUserPhoneVerification: (phoneNumber: string) => void,
    resendUserPhoneVerificationOTC: (requestId: string) => void,
    checkUserPhoneVerification: (requestId: string, otc: string) => void,
    newFeedback: (feedbackType: FeedbackType, message: string) => void,
};

type Props = InjectedProps & {
    userId: number,
    onClose: () => void,
};

class PhoneVerificationModalContainer extends React.PureComponent<Props> {
    handleCancel = () => {
        this.props.onClose();
    };

    submitPhoneNumber = (phoneNumber: string) => {
        this.props.startUserPhoneVerification(this.props.userId, phoneNumber);
    };

    submitResendOTC = (requestId: string) => {
        this.props.resendUserPhoneVerificationOTC(this.props.userId, requestId);
    };

    submitOTC = (requestId: string, otc: string) => {
        this.props.checkUserPhoneVerification(this.props.userId, requestId, otc);
    };

    displayErrorFeedback = (feedbackType: FeedbackType, messageId: string) => {
        this.props.newFeedback(
            feedbackType,
            this.props.intl.formatMessage({
                id: messageId,
            })
        );
    };

    render() {
        return (
            <PhoneVerificationModal
                loading={this.props.isLoading}
                onCancel={this.handleCancel}
                requestId={this.props.requestId}
                errors={this.props.errors}
                userPhoneNumber={this.props.userPhoneNumber}
                submitPhoneNumber={this.submitPhoneNumber}
                submitResendOTC={this.submitResendOTC}
                submitOTC={this.submitOTC}
                displayErrorFeedback={this.displayErrorFeedback}
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        requestId: selectPhoneVerificationRequestId(),
        isLoading: selectPhoneVerificationIsLoading(),
        errors: selectPhoneVerificationErrors(),
        userPhoneNumber: selectUserPhoneNumber(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            startUserPhoneVerification,
            resendUserPhoneVerificationOTC,
            checkUserPhoneVerification,
            newFeedback,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(PhoneVerificationModalContainer));
