// @flow

import React from 'react';

// Styles
import { Caret } from '../../icons';

import type { InputEventType, ReactNode } from '../../types';
import { ActiveItem, ActiveItemContainer, Container, Item, List } from './styles';

type OptionType = {
    label: string,
    to: string,
};

type Props = {
    current?: string,
    displayActiveLabel?: boolean,
    defaultLabel?: string,
    demo?: boolean,
    options: Array<OptionType>,
    theme?: ?{},
};

type State = {
    isOpen?: ?boolean,
};

class NavDropdown extends React.Component<Props, State> {
    wrapperRef: ReactNode;
    // Unable to type ref functions: https://github.com/facebook/create-react-app/issues/5176
    // setWrapperRef: () => boolean
    // handleClickOutside: () => boolean

    static defaultProps = {
        current: '',
        defaultLabel: null,
        demo: false,
        displayActiveLabel: false,
        theme: null,
    };

    constructor(props: Props) {
        super(props);

        // eslint-disable-next-line flowtype-errors/show-errors
        this.setWrapperRef = this.setWrapperRef.bind(this);
        // eslint-disable-next-line flowtype-errors/show-errors
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node: ReactNode) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event: InputEventType) {
        if (this.state.isOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.handleToggle();
        }
    }

    handleToggle = () => {
        this.setState((prevState: State) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    render() {
        const { options } = this.props;

        if (!options) {
            return null;
        }

        const items =
            options &&
            options.map((option: OptionType) => (
                <Item
                    key={option.to}
                    current={option.to === this.props.current}
                    demo={this.props.demo}
                    to={option.to}
                    theme={this.props.theme}
                >
                    {option.label}
                </Item>
            ));

        const selectedItem: ?OptionType = options.find(
            (option: OptionType) => option.to === this.props.current
        );

        // By default, show first option as active item
        let activeItem = true;
        let activeLabel = options[0].label;

        // If provided prop.displayActiveLabel is false, and prop.defaultLabel is provided, show static defaultLabel
        if (
            (!this.props.displayActiveLabel && this.props.defaultLabel) ||
            (!selectedItem && this.props.defaultLabel)
        ) {
            activeItem = false;
            activeLabel = this.props.defaultLabel;
        } else if (this.props.displayActiveLabel && selectedItem) {
            // Else if props.displayActiveLabel is true and selectedItem is found, show as dynamic label
            activeLabel = selectedItem.label;
        }

        return (
            <Container
                ref={this.setWrapperRef}
                onClick={this.handleToggle}
                theme={this.props.theme}
            >
                <ActiveItemContainer>
                    <ActiveItem activeItem={activeItem} title={activeLabel}>
                        {activeLabel}
                    </ActiveItem>
                    <Caret
                        up={!this.state.isOpen}
                        down={this.state.isOpen}
                        margin="0px"
                        style={{ minWidth: '10px' }}
                        black
                    />
                </ActiveItemContainer>
                {this.state.isOpen && <List theme={this.props.theme}>{items}</List>}
            </Container>
        );
    }
}

export default NavDropdown;
