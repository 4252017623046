// @flow strict

import styled from 'styled-components';

export const StreamValuesWrapper = styled.div`
    grid-column-start: ${({ startingColumn }) => startingColumn};
    grid-column-end: span ${({ columnSpan }) => columnSpan};
    grid-row-start: ${({ startingRow }) => startingRow};
    grid-row-end: span ${({ rowSpan }) => rowSpan};
    display: flex;
    align-items: center;
    position: relative;

    z-index: 2;
`;
