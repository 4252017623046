// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import { Common, SecondaryButton } from 'components/_ReactUI_V1';
import KPISetupTable from 'components/KPISetupTable';

// Constants
import {
    PLANT_VALUE_TYPES,
    PLANT_CREATION_STEPS,
    KPI_SPECIFICITY_TYPES,
    KPI_SETTING_PROPERTIES,
} from 'utils/constants';

// Helpers
import { getKPIUnitType, getDefaultKPIUnitValueForUnitType } from 'utils/kpiHelpers';

// Styles
import { Title, FormContainer, FormBlock, Subtitle } from 'styles/common';

// Types
import type { IntlType, LooseInputValueTypes, LooseKeyArrayType } from 'types';
import type { ImmutablePlant, PlantCreationStepConstant } from 'services/Plant/types';
import type { ImmutableKPISetting, PlantValuesConstant } from 'services/KPISetting/types';

type Props = {
    intl: IntlType,
    onAddKPI?: ?(kpiSetting: ImmutableKPISetting) => void,
    onInputChange?: ?(keys: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onRemoveKPI?: ?(kpiSetting: ImmutableKPISetting) => () => void,
    onReorderKPI?: ?(originalKPISetting: ImmutableKPISetting) => (newOrder: number) => void,
    onReturnToStep?: ?(step: PlantCreationStepConstant) => () => void,
    plant: ImmutablePlant,
};

class KPIStep extends React.PureComponent<Props> {
    static defaultProps = {
        onAddKPI: null,
        onInputChange: null,
        onRemoveKPI: null,
        onReorderKPI: null,
        onReturnToStep: null,
    };

    STEP_KEY = PLANT_CREATION_STEPS.KPIS;

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.PlantManager.PlantSetupSteps.${this.STEP_KEY}.${id}`,
        });

    getListOfPossibleKPITypes = (): Array<PlantValuesConstant> => {
        const possibleKPITypes = Object.keys(PLANT_VALUE_TYPES).filter(
            (valueType: PlantValuesConstant) =>
                !this.props.plant
                    .get('kpiSettings')
                    .find(
                        (kpiSetting: ImmutableKPISetting) => kpiSetting.get('kpiType') === valueType
                    )
        );
        return possibleKPITypes || [];
    };

    handleAddKPISetting = () => {
        if (!this.props.onAddKPI) {
            return null;
        }

        const possibleKPITypes = this.getListOfPossibleKPITypes();
        if (possibleKPITypes.length) {
            // Get the last setting's order value & add 1, else use 1 as default order value for first setting
            const orderValue = this.props.plant.get('kpiSettings').size
                ? this.props.plant
                      .get('kpiSettings')
                      .last()
                      .get('order') + 1
                : 1;

            // Get first possible kpi type as default
            const kpiType = possibleKPITypes[0];

            const kpiUnitType = getKPIUnitType(kpiType);
            const kpiUnit = getDefaultKPIUnitValueForUnitType(
                kpiUnitType,
                this.props.plant.get('units')
            );

            const emptyKPISetting = fromJS({
                ...KPI_SETTING_PROPERTIES.BASE,
                kpiType,
                kpiUnitType,
                kpiUnit,
                specificityType: KPI_SPECIFICITY_TYPES.PLANT,
                order: orderValue,
                disableKpiTypeInput: false, // informs kpiSetupTable if the kpi type input should be disabled or not
            });

            if (this.props.onAddKPI) {
                this.props.onAddKPI(emptyKPISetting);
            }
        }
    };

    render() {
        const possibleKPITypes = this.getListOfPossibleKPITypes();
        const displayAsReadOnly = Boolean(this.props.onReturnToStep);
        return (
            <FormContainer>
                <Common.Row alignItems="center">
                    <Common.Column>
                        {displayAsReadOnly ? (
                            <Subtitle>{this.getTranslation('title')}</Subtitle>
                        ) : (
                            <Title>{this.getTranslation('title')}</Title>
                        )}
                    </Common.Column>
                    {displayAsReadOnly && (
                        <Common.Column alignItems="flex-end">
                            <SecondaryButton
                                text={this.getTranslation('edit')}
                                onClick={
                                    this.props.onReturnToStep &&
                                    this.props.onReturnToStep(this.STEP_KEY)
                                }
                            />
                        </Common.Column>
                    )}
                </Common.Row>

                <FormBlock fullWidth>
                    {!displayAsReadOnly && (
                        <Common.Row justifyContent="flex-end">
                            <SecondaryButton
                                onClick={this.handleAddKPISetting}
                                text={this.getTranslation('addAKPI')}
                                disabled={!possibleKPITypes.length}
                            />
                        </Common.Row>
                    )}
                    <KPISetupTable
                        units={this.props.plant.get('units')}
                        kpis={this.props.plant.get('kpiSettings')}
                        onInputChange={this.props.onInputChange}
                        onReorderKPI={this.props.onReorderKPI}
                        onRemoveKPI={this.props.onRemoveKPI}
                        availableKpiTypes={possibleKPITypes}
                        readonly={displayAsReadOnly}
                        noKPIMessage={this.getTranslation('noKPIs')}
                    />
                </FormBlock>
            </FormContainer>
        );
    }
}

export default injectIntl(KPIStep);
