// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { OrderedMap } from 'immutable';

// Components
import { PrimaryButton, InputSelect } from 'components/_ReactUI_V1';

// Helpers
import { round } from 'utils/helpers';

// Styles
import { SidebarBody, FooterSection } from '../styles';
import { Label, SelectField } from './styles';

// Types
import type { IntlType, ImmutableList, ReactSelectObject, LooseNumberType } from 'types';
import type { Oxime, ImmutableOximeConcentration } from 'services/Oxime/types';

type Props = {
    concentrationList: ?OrderedMap<string, ImmutableList<ImmutableOximeConcentration>>,
    handleVisualizeButtonClicked: () => void,
    intl: IntlType,
    onSelectConcentration: (selectedConcentrationId: ?number) => void,
    onSelectOxime: (oximeId: number) => void,
    oximeList: ImmutableList<Oxime>,
    oximesAreLoading: boolean,
};

type State = {
    selectedConcentration: ?ReactSelectObject,
    selectedOxime: ?ReactSelectObject,
};

/*
    MDR Curves Sidebar section for the Isotherm Management view
 */
class MDRCurvesSidebarSection extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedConcentration: null,
            selectedOxime: null,
        };
    }

    /**
     * Convert the oxime to a drop down select object
     */
    oximeToReactSelectObject = (oxime: ?Oxime): ?ReactSelectObject =>
        oxime && {
            value: oxime.id,
            label: oxime.name,
        };

    /**
     * Convert the concentration to a drop down select object
     */
    concentrationToReactSelectObject = (concentrationValue: LooseNumberType): ?ReactSelectObject =>
        concentrationValue
            ? {
                  value: concentrationValue,
                  label: round(concentrationValue),
              }
            : null;

    /**
     * Get the drop down options for selecting a oxime
     */
    getOximeOptions = (): Array<ReactSelectObject> =>
        this.props.oximeList.map(this.oximeToReactSelectObject).filter(Boolean);

    /**
     * Get the drop down options for selecting a concentration
     */
    getConcentrationOptions = (): Array<ReactSelectObject> => {
        let options = [];

        if (OrderedMap.isOrderedMap(this.props.concentrationList)) {
            const keys = this.props.concentrationList.keySeq().toArray();
            options = keys.map(this.concentrationToReactSelectObject);
        }

        return options;
    };

    /**
     * Handle the selection of a oxime
     */
    handleOximeSelected = (selectedOption: ReactSelectObject) =>
        this.setState(
            {
                selectedOxime: this.props.oximeList.find(
                    (oxime: Oxime) => oxime.id === selectedOption.value
                ),
                selectedConcentration: null,
            },
            () => {
                const id = this.state.selectedOxime && this.state.selectedOxime.id;
                if (id) {
                    this.props.onSelectOxime(id);
                }
                this.props.onSelectConcentration(null);
            }
        );

    /**
     * Handle the selection of a concentration
     */
    handleConcentrationSelected = (selectedOption: ReactSelectObject) => {
        this.setState(
            {
                selectedConcentration: selectedOption,
            },
            () =>
                this.state.selectedConcentration &&
                this.props.onSelectConcentration(this.state.selectedConcentration.value)
        );
    };

    /**
     * Specify if the Visualize button is enabled
     */
    isVisualizeButtonEnabled = () => this.state.selectedOxime && this.state.selectedConcentration;

    /**
     * Handle the visualize button clicked
     */
    handleVisualizeButtonClicked = () => this.props.handleVisualizeButtonClicked();

    render() {
        const concentrationOptions = this.getConcentrationOptions();

        return (
            <SidebarBody>
                <SelectField>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: 'components.IsothermManagementSidebar.MDRCurves.oxime.label',
                        })}
                    </Label>
                    <InputSelect
                        selectedOption={this.oximeToReactSelectObject(this.state.selectedOxime)}
                        options={this.getOximeOptions()}
                        onSelect={this.handleOximeSelected}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.IsothermManagementSidebar.MDRCurves.oxime.placeholder',
                        })}
                        isLoading={this.props.oximesAreLoading}
                        controlShouldRenderValue
                    />
                </SelectField>
                {concentrationOptions.length ? (
                    <SelectField>
                        <Label>
                            {this.props.intl.formatMessage({
                                id:
                                    'components.IsothermManagementSidebar.MDRCurves.concentration.label',
                            })}
                        </Label>
                        <InputSelect
                            selectedOption={this.state.selectedConcentration}
                            options={concentrationOptions}
                            onSelect={this.handleConcentrationSelected}
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'components.IsothermManagementSidebar.MDRCurves.concentration.placeholder',
                            })}
                            controlShouldRenderValue
                        />
                    </SelectField>
                ) : null}
                <FooterSection withMargin>
                    <PrimaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.IsothermManagementSidebar.MDRCurves.visualizeButton',
                        })}
                        disabled={!this.isVisualizeButtonEnabled()}
                        onClick={this.handleVisualizeButtonClicked}
                    />
                </FooterSection>
            </SidebarBody>
        );
    }
}

export default injectIntl(MDRCurvesSidebarSection);
