// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import {
    ButtonWrapper,
    Column,
    DotMenu,
    InputField,
    Pagination,
    PrimaryButton,
    Row,
    SecondaryButton,
    Table,
    ToolTip,
} from 'components/_ReactUI_V1';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';

// Styles
import { ControlWrapper, TableContainer, ToolTipContent } from './styles';
import { BlankButton, Count, Title } from 'styles/common';

// Types
import type {
    ErrorType,
    ImmutableList,
    InputEvent,
    IntlType,
    LooseKeyArrayType,
    SearchCriteria,
} from 'types';
import type { ImmutableClient } from 'services/Client/types';

type Props = {
    clientIsDeleting: boolean,
    clientIsUpdating: boolean,
    clients: ImmutableList<ImmutableClient>,
    clientsAreQuerying: boolean,
    errors: ErrorType,
    handleCreateNewClient: (ImmutableClient) => void,
    handleDeleteClient: (number) => void,
    handleUpdateClient: (number, ImmutableClient) => void,
    intl: IntlType,
    lastPage: number,
    onHandlePaginationPageSelection: (number, number) => void,
    onHandleSortBy: (SearchCriteria) => void,
    pagination: { page: number },
    searchCriteria: SearchCriteria,
};

type State = {
    activeRow: ?ImmutableClient,
    disabledInputs: boolean,
    enableAddRow: boolean,
    isModified: boolean,
    rowIdToDelete: ?number,
};

class ClientManager extends React.Component<Props, State> {
    state = {
        activeRow: null,
        disabledInputs: false,
        enableAddRow: true,
        isModified: false,
        rowIdToDelete: null,
    };

    /**
     * On componentDidUpdate check if props.clients has changed, if so reset UI via state
     * If props.errors have changed, re-enable inputs to allow user to continue
     */
    componentDidUpdate(prevProps: Props) {
        const { clients, errors } = this.props;
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (prevProps.clients !== clients) {
            this.setState({
                activeRow: null,
                isModified: false,
                disabledInputs: false,
                enableAddRow: true,
                rowIdToDelete: null,
            });
        } else if (prevProps.errors && !prevProps.errors.equals(errors)) {
            this.setState({
                disabledInputs: false,
            });
        }
    }

    /**
     * Update provided field by key of active row within state
     *
     * If provided key is an Array: perform setIn() else set()
     */
    handleActiveRowChange = (key: LooseKeyArrayType) => (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => {
            let activeRow: ImmutableClient = prevState.activeRow;
            if (typeof key === 'string') {
                activeRow = prevState.activeRow && prevState.activeRow.set(key, value);
            } else {
                activeRow = prevState.activeRow && prevState.activeRow.setIn(key, value);
            }

            return {
                activeRow,
                disabledInputs: false,
                isModified: Boolean(activeRow),
            };
        });
    };

    /**
     * On row cancel (within row edit/create) update nullify activeRow & reset enableAddRow
     */
    handleCancelActiveRow = () => {
        this.setState({
            activeRow: null,
            isModified: false,
            enableAddRow: true,
        });
    };

    /**
     * On row creation, insert "empty" object to list of entities & set as activeRow within state
     * Set enableAddRow to false to disable [Create New] button
     */
    handleCreateClient = () => {
        const emptyRow = fromJS({
            id: null,
            name: null,
        });

        this.setState({
            activeRow: emptyRow,
            enableAddRow: false,
        });
    };

    /**
     * On save, disable inputs for further editing
     * If state.activeRow has an id (assume update) and fire props.handleUpdateOxime()
     * Else (assume create) and fire props.handleCreateNewClient()
     */
    handleSaveActiveRow = () => {
        this.setState(
            {
                disabledInputs: true, // Disable inputs to avoid unexpected duplicate requests
            },
            () => {
                const { activeRow } = this.state;
                if (activeRow) {
                    if (activeRow.get('id')) {
                        this.props.handleUpdateClient(activeRow.get('id'), activeRow);
                    } else {
                        this.props.handleCreateNewClient(activeRow);
                    }
                }
            }
        );
    };

    /**
     * On row edit, set provided entity as activeRow
     */
    handleSetActiveRow = (client?: ImmutableClient) => () =>
        this.setState({
            activeRow: client || null,
            enableAddRow: false,
        });

    /**
     * On Click, set rowIdToDelete in state for this.renderConditionalDeleteModal()
     */
    handleSetClientIdToDelete = (clientId?: number) => () => {
        this.setState({
            rowIdToDelete: clientId || null,
        });
    };

    handleOnDelete = (id: number) => () => this.props.handleDeleteClient(id);

    /**
     * Provide editable row
     */
    provideEditableRow = () => {
        const client = this.state.activeRow;

        if (!client) {
            return null;
        }

        // Required fields
        const enableSave = client.get('name');

        return client
            .set(
                'name',
                <InputField
                    disabled={this.state.disabledInputs}
                    onChange={this.handleActiveRowChange('name')}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.ClientManager.placeholder.name',
                    })}
                    value={client.get('name') || ''}
                />
            )
            .set(
                'controls',
                <ButtonWrapper style={{ width: '100%' }} right>
                    <PrimaryButton
                        disabled={
                            this.state.disabledInputs || !enableSave || !this.state.isModified
                        }
                        loading={this.props.clientIsUpdating}
                        onClick={this.handleSaveActiveRow}
                        text={this.props.intl.formatMessage({
                            id: 'components.ClientManager.save',
                        })}
                    />
                    <SecondaryButton
                        disabled={this.state.disabledInputs}
                        onClick={this.handleCancelActiveRow}
                        text={this.props.intl.formatMessage({
                            id: 'components.ClientManager.cancel',
                        })}
                    />
                </ButtonWrapper>
            );
    };

    /**
     * Provide interactive row (static with controls)
     */
    provideInteractiveRow = (client: ImmutableClient) => {
        const editClientLabel = this.props.intl.formatMessage({
            id: 'components.ClientManager.editClient',
        });

        return client.set(
            'controls',
            <ControlWrapper>
                <ToolTip
                    content={
                        <ToolTipContent>
                            <li>
                                <BlankButton
                                    onClick={this.handleSetActiveRow(client)}
                                    title={editClientLabel}
                                >
                                    {editClientLabel}
                                </BlankButton>
                            </li>
                            <li>{this.renderDeleteButton(client)}</li>
                        </ToolTipContent>
                    }
                    position="bottom"
                    trigger={<DotMenu />}
                    triggerType="click"
                    interactive
                />
            </ControlWrapper>
        );
    };

    provideTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.ClientManager.table.header.name',
            }),
            id: 'name',
            sortable: true,
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    /**
     * Map over clients and provide either interactive or editable row based on state.activeRow
     */
    provideTableRows = () => {
        const rows = this.props.clients.map((client: ImmutableClient) => {
            if (this.state.activeRow && this.state.activeRow.get('id') === client.get('id')) {
                return this.provideEditableRow();
            } else {
                return this.provideInteractiveRow(client);
            }
        });

        // If state.activeRow is an emptyRow, insert empty editable row at start of row
        let newRows;
        if (this.state.activeRow && !this.state.activeRow.get('id')) {
            newRows = rows.insert(0, this.provideEditableRow());
        }

        return (newRows || rows).toJS();
    };

    /**
     * Provided this.state.rowIdToDelete is set, display Modal and ClientToDeleteModalContent
     */
    renderConditionalDeleteModal = () =>
        this.state.rowIdToDelete && (
            <ConfirmationModal
                title={this.props.intl.formatMessage({
                    id: 'components.ClientManager.deleteModal.title',
                })}
                confirmButtonText={this.props.intl.formatMessage({
                    id: 'components.ClientManager.deleteModal.confirmButton',
                })}
                errors={this.props.errors}
                loading={this.props.clientIsDeleting}
                onConfirm={
                    this.state.rowIdToDelete ? this.handleOnDelete(this.state.rowIdToDelete) : null
                }
                onCancel={this.handleSetClientIdToDelete()}
                danger
            />
        );

    /**
     * Render delete button, onClick sets state.rowIdToDelete with provided client's id
     */
    renderDeleteButton = (client: ImmutableClient) => (
        <BlankButton onClick={this.handleSetClientIdToDelete(client.get('id'))}>
            {this.props.intl.formatMessage({
                id: 'components.ClientManager.deleteClient',
            })}
        </BlankButton>
    );

    render() {
        const rows = this.provideTableRows();

        return (
            <React.Fragment>
                {this.renderConditionalDeleteModal()}
                <PreventNavigationPrompt shouldBlock={this.state.isModified} />
                <Row alignItems="center" flex="0">
                    <Column>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.ClientManager.title',
                            })}
                            <Count>({this.props.clients.size})</Count>
                        </Title>
                    </Column>
                    <Column alignItems="flex-end">
                        <PrimaryButton
                            disabled={!this.state.enableAddRow || this.props.clientsAreQuerying}
                            onClick={this.handleCreateClient}
                            text={this.props.intl.formatMessage({
                                id: 'components.ClientManager.createNew',
                            })}
                        />
                    </Column>
                </Row>
                <TableContainer>
                    <Table
                        currentSorting={this.props.searchCriteria}
                        header={this.provideTableHeaders()}
                        loading={this.props.clientsAreQuerying}
                        onSortBy={this.props.onHandleSortBy}
                        rows={rows}
                        footerMessage={
                            !this.props.clientsAreQuerying &&
                            !rows.length &&
                            this.props.intl.formatMessage({
                                id: 'components.ClientManager.table.noClients',
                            })
                        }
                    />
                    <Pagination
                        currentPage={this.props.pagination.page}
                        isLoading={this.props.clientsAreQuerying}
                        onPageSelection={this.props.onHandlePaginationPageSelection}
                        pagesTotal={this.props.lastPage}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryJoiner',
                        })}
                    />
                </TableContainer>
            </React.Fragment>
        );
    }
}

export default injectIntl(ClientManager);
