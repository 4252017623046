// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Constants
import { CIRCUIT_TYPES, STYLE_VALUES } from 'utils/constants';

// Components
import DashboardContainer from 'containers/DashboardContainer';

// Types
import type { IntlType, CircuitTypes } from 'types';

type Props = {
    intl: IntlType,
    circuitType?: ?CircuitTypes,
};

export class DashboardPage extends React.PureComponent<Props, null> {
    mobileMaxPx = parseInt(STYLE_VALUES.RESPONSIVE_AT_LARGE, 10);

    /**
     * Returns Solvextract for mobile screen and Minchem for larger screens
     */
    defaultCircuitTypeForScreen = () => {
        return window.innerWidth > this.mobileMaxPx
            ? CIRCUIT_TYPES.MINCHEM_CIRCUIT
            : CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT;
    };

    getCircuitType = () => {
        if (this.props.circuitType) {
            return this.props.circuitType;
        }
        return this.defaultCircuitTypeForScreen();
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.HomePage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.HomePage.helmetDescription',
                        })}
                    />
                </Helmet>
                <DashboardContainer
                    circuitType={this.getCircuitType()}
                    mobileMaxPx={this.mobileMaxPx}
                />
            </React.Fragment>
        );
    }
}

export default injectIntl(DashboardPage);
