// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableTrendsState } from 'services/Trends/types';
import type { ImmutablePlantState } from 'services/Plant/types';
import { selectPlantState } from 'services/Plant/selectors';
import { selectTrendsState } from 'services/Trends/selectors';

/**
 * Select whether the plant value is being updated
 * @returns A boolean for the state of the update
 */
export const selectIsCreatingOrUpdatingPlantValue = () =>
    createSelector(
        selectTrendsState(),
        (subState: ImmutableTrendsState) => subState.get('isCreatingOrUpdatingPlantValue')
    );

/**
 * Gets whether the dataset is currently being computed.
 */
export const selectDatasetIsComputing = () =>
    createSelector(
        selectPlantState(),
        (subState: ImmutablePlantState) => subState.get('isComputing')
    );
