// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'react-focus-trap';

// Types
import type { InputEventType, ChildrenType } from '../../types';

// Styles
import { Close, Content, Wrapper } from './styles';

type Props = {
    children: ChildrenType,
    disableClose: boolean,
    handleClose: () => void,
    hideCloseIcon: boolean,
    modalHeight: ?string,
    modalWidth: ?string,
    overlay: ?boolean,
    padding: ?string,
    style?: {
        wrapper: ?Object,
        content: ?Object,
    },
};

class ModalContent extends React.PureComponent<Props> {
    static defaultProps = {
        style: {
            wrapper: {},
            content: {},
        },
    };

    componentDidMount() {
        // Note: The keypress event is never detected when the ESCAPE key is pressed because it
        // is a non-printing character meaning that it doesn't print anything. Hence, we use
        // the 'keyup' event instead.
        window.addEventListener('keyup', this.handleOnEscKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleOnEscKeyPress);
    }

    handleOnEscKeyPress = (event: InputEventType) => {
        const ESCAPE_KEY = 27;

        if (event.keyCode === ESCAPE_KEY) {
            window.removeEventListener('keyup', this.handleOnEscKeyPress);
            this.props.handleClose();
        }
    };

    // Prevent closure of model on content interaction
    handleStopPropagation = (event: InputEventType) => {
        event.stopPropagation();
    };

    render() {
        const {
            children,
            disableClose,
            handleClose,
            modalHeight,
            modalWidth,
            overlay,
            padding,
            hideCloseIcon,
            style,
        } = this.props;

        const closeFunctions = disableClose ? null : handleClose;

        return ReactDOM.createPortal(
            <Wrapper onClick={closeFunctions} overlay={overlay} style={style && style.wrapper}>
                <FocusTrap active>
                    <Content
                        onClick={this.handleStopPropagation}
                        modalHeight={modalHeight}
                        modalWidth={modalWidth}
                        padding={padding}
                        style={style && style.content}
                    >
                        {closeFunctions && !hideCloseIcon && <Close onClick={closeFunctions} />}
                        {children}
                    </Content>
                </FocusTrap>
            </Wrapper>,
            document.body
        );
    }
}

export default ModalContent;
