// @flow strict

import { fromJS } from 'immutable';

import type { GenericActionType, ImmutableList } from 'types';

import { FETCH_CIRCUIT_TREND_SUCCESS, FETCH_PLANT_TREND_SUCCESS } from 'services/Trends/actions';
import {
    UPDATE_KPI_SETTINGS_SUCCESS,
    UPDATE_KPI_SETTINGS_FAILURE,
    IS_UPDATING_KPI_SETTINGS,
} from 'services/KPISetting/actions';

import type { ImmutableKPISetting, ImmutableKPISettingState } from 'services/KPISetting/types';

const initialState: ImmutableKPISettingState = fromJS({
    kpiSettings: [],

    isUpdatingKPISetting: false,
    updateErrorsKPISetting: {},
});

function kpiSettingsServiceReducer(
    state: ImmutableKPISettingState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case IS_UPDATING_KPI_SETTINGS: {
            return state.set('isUpdatingKPISetting', action.payload.isUpdatingKPISetting);
        }
        case UPDATE_KPI_SETTINGS_SUCCESS: {
            const updatedKPISetting = fromJS(action.payload.data);
            return state
                .set('isUpdatingKPISetting', false)
                .set('updateErrorsKPISetting', fromJS({}))
                .updateIn(['kpiSettings'], (kpiSettings: ImmutableList<ImmutableKPISetting>) => {
                    const idx = kpiSettings.findIndex(
                        (kpiSetting: ImmutableKPISetting) =>
                            updatedKPISetting.get('id') === kpiSetting.get('id')
                    );
                    if (idx === -1) {
                        throw new Error('Updated a KPISetting without being in state?');
                    }
                    return kpiSettings.setIn([idx], updatedKPISetting);
                });
        }
        case UPDATE_KPI_SETTINGS_FAILURE: {
            return state
                .set('isUpdatingKPISetting', false)
                .set('updateErrorsKPISetting', fromJS(action.payload.errors));
        }

        case FETCH_PLANT_TREND_SUCCESS:
        case FETCH_CIRCUIT_TREND_SUCCESS: {
            const newKPISettings = fromJS(action.payload.data.kpiSettings);

            return state.updateIn(
                ['kpiSettings'],
                (savedKPISettings: ImmutableList<ImmutableKPISetting>) => {
                    const unsavedSettings = newKPISettings.filter(
                        (potentiallyUnsaved: ImmutableKPISetting) =>
                            savedKPISettings.findIndex(
                                (savedKPISetting: ImmutableKPISetting) =>
                                    savedKPISetting.get('id') === potentiallyUnsaved.get('id')
                            ) === -1
                    );
                    return savedKPISettings.concat(unsavedSettings);
                }
            );
        }

        default:
            return state;
    }
}

export default kpiSettingsServiceReducer;
