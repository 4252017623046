// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RowExtraWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
`;

export const MenuToolTip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 11px;

    cursor: pointer;
    text-decoration: none;

    white-space: nowrap;

    margin-left: 16px;

    > span {
        margin-right: 5px;
    }
`;

export const MenuToolTipContent = styled.div`
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 5px;
    padding: 4px 0px;
    background-color: ${LegacyTheme.toolTip};

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ToolTipButton = styled.span`
    padding: 8px 16px;
    cursor: pointer;
    text-align: start;
    background-color: ${LegacyTheme.toolTip};

    &:hover {
        background-color: ${LegacyTheme.buttonActiveHover};
    }
`;

export const BtnIconWrapper = styled.span`
    padding-right: 6px;
    position: relative;
    bottom: 1px;
`;
