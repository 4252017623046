// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

const TabNav = css`
    display: flex;
    flex: 1;
    height: 60px;

    padding: 0 10px;

    align-items: center;
    justify-content: center;

    border-bottom: 5px solid transparent;
    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tabNav', 'color'])};
    text-decoration: none;
    font-size: 16px;

    text-align: center;

    cursor: pointer;

    &:hover {
        border-color: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['tabNav', 'borderHover'])};
    }

    transition: all ease 0.25s;
`;

const TabNavLink = styled(Link)`
    ${TabNav};
`;

const TabNavCurrentLink = styled(TabNavLink)`
    border-color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tabNav', 'border'])};
    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tabNav', 'colorActive'])};

    &:hover {
        border-color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tabNav', 'border'])};
    }
`;

// Span "demo" version (No react-router's Link)
const TabNavSpan = styled.span`
    ${TabNav};
`;

const TabNavCurrentSpan = styled(TabNavSpan)`
    border-color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tabNav', 'border'])};
    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tabNav', 'colorActive'])};

    &:hover {
        border-color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tabNav', 'border'])};
    }
`;

export const TabNavItem = (
    { current, demo, ...props }: { current: boolean, demo?: ?boolean } = {
        current: false,
        demo: false,
    }
) => {
    let Component;
    if (demo) {
        Component = current ? TabNavCurrentSpan : TabNavSpan;
    } else {
        Component = current ? TabNavCurrentLink : TabNavLink;
    }

    return <Component {...props} />;
};

export const TabNavList = styled.div`
    display: flex;
    justify-content: flex-start;

    width: 100%;
    min-width: ${({ minWidth }: Object) => `${minWidth}px` || 'none'};
    max-width: ${({ maxWidth }: Object) => `${maxWidth}px` || 'none'};
`;
