// @flow strict

import request from 'services/request';

import type { ImmutableDisclaimer } from 'services/Disclaimer/types';

/**
 * GET Request to return disclaimers
 */
export const index = () =>
    request('disclaimers', {
        method: 'GET',
    });

/**
 * POST Request to create a new disclaimer version
 *
 * @param {number} id
 * @param {ImmutableDisclaimer} disclaimer
 */
export const createNewVersion = (id: number, disclaimer: ImmutableDisclaimer) =>
    request(`disclaimer/${id}`, {
        method: 'POST',
        data: { ...disclaimer.toJS() },
    });
