// @noflow

import styled from 'styled-components';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

export const StyledHr = styled.hr`
    border-style: ${({ theme }: Object) => themePropertyFetcher(theme, ['hr', 'borderStyle'])};
    border-color: ${({ theme }: Object) => themePropertyFetcher(theme, ['hr', 'borderColor'])};
    border-top-width: ${({ theme }: Object) => themePropertyFetcher(theme, ['hr', 'borderWidth'])};
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
`;
