// @flow strict

import React from 'react';

import type { IMimicCascade, IMimicStage } from 'components/_McCabeThiele';

// Styles
import CascadeArrow from 'assets/icons/cascade-arrow';

import { CascadeColors } from 'styles/colors';

import { CascadeDescriptionWrapper } from './styles';

type Props = {
    cascade: IMimicCascade,

    /**
     * Will convert the cascade into a string only output. Useful for the sidebar where space is limited.
     */
    asString?: boolean,
    maxWidth?: ?number,
};

/**
 * Used to display a loader and a message when the entire container should be in a loading state
 */
class CascadeDescription extends React.PureComponent<Props> {
    static defaultProps = {
        asString: false,
        maxWidth: null,
    };

    render() {
        const cascadeDescription = this.props.cascade.getDescription();

        if (this.props.asString) {
            const description = cascadeDescription
                .map((partialCascade: Array<IMimicStage>) =>
                    partialCascade.map((stage: IMimicStage) => stage.getCode()).join('->')
                )
                .join(',');
            return description;
        }

        return cascadeDescription.map((partialCascade: Array<IMimicStage>, idx: number) => (
            <CascadeDescriptionWrapper maxWidth={this.props.maxWidth}>
                {partialCascade[0].getCode()}
                {partialCascade.length > 1 && (
                    <React.Fragment>
                        <CascadeArrow
                            fill={CascadeColors[this.props.cascade.type]}
                            margin="0 4 0 4"
                            direction="RIGHT"
                        />
                        {partialCascade[1].getCode()}
                    </React.Fragment>
                )}
                {idx !== cascadeDescription.length - 1 && ','}
            </CascadeDescriptionWrapper>
        ));
    }
}

export default CascadeDescription;
