// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Constants
import { LegacyTheme, InputNumber, RadioButtonSet, PrimaryButton } from 'components/_ReactUI_V1';

import {
    SENSITIVITY_OPTIONS,
    THREE_D_SENSITIVITY_OUTPUT_OPTIONS,
    SENSITIVITY_OPTION_LIMITS,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
} from 'utils/constants';

// Helpers
import { clamp } from 'utils/helpers';

// Styles
import { SidebarBody, BodySection, FooterSection } from '../styles';
import {
    RangeWrapper,
    RangeTitle,
    RangeInputWrapper,
    RangeInput,
    VariableBodySection,
    VariableTitle,
} from './styles';

// Components

// Types
import type { IntlType, InputEvent } from 'types';
import type {
    SensitivityOption,
    SensitivityOutputOption,
} from 'containers/CircuitComputationContainer/ThreeDSensitivityContainer';

type VariableType = 'X' | 'Y';

type Props = {
    intl: IntlType,

    handleGenerateDiagramClicked: (
        xOptions: {
            sensitivityOption: SensitivityOption,
            start: ?number,
            finish: ?number,
        },
        yOptions: {
            sensitivityOption: SensitivityOption,
            start: ?number,
            finish: ?number,
        },
        zOption: SensitivityOutputOption
    ) => void,

    loading: boolean,
};

type State = {
    selectedVariableX: SensitivityOption,
    startX: ?number,
    finishX: ?number,

    selectedVariableY: SensitivityOption,
    startY: ?number,
    finishY: ?number,

    selectedOutputVariable: SensitivityOutputOption,
};

const INPUT_STYLES = {
    ...STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES,
    marginRight: '0px',
};

/**
 * The sidebar body section for the 3D sensitivity plot.
 */
class ThreeDSensitivitySidebarSection extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedVariableX: SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION,
            startX: SENSITIVITY_OPTION_LIMITS.REAGENT_CONCENTRATION.DEFAULT_MINIMUM,
            finishX: SENSITIVITY_OPTION_LIMITS.REAGENT_CONCENTRATION.DEFAULT_MAXIMUM,

            selectedVariableY: SENSITIVITY_OPTIONS.OXMOD_RATIO, // sensitivity option cannot be the same as X
            startY: SENSITIVITY_OPTION_LIMITS.OXMOD_RATIO.DEFAULT_MINIMUM,
            finishY: SENSITIVITY_OPTION_LIMITS.OXMOD_RATIO.DEFAULT_MAXIMUM,

            selectedOutputVariable: THREE_D_SENSITIVITY_OUTPUT_OPTIONS.OVERALL_RECOVERY,
        };
    }

    /**
     * Gets the radio button options for the variable to vary
     */
    getVariableRadioOptions = (optionType: VariableType) =>
        Object.keys(SENSITIVITY_OPTIONS).map((value: string) => ({
            value,
            label: this.props.intl.formatMessage({
                id: `constants.SensitivityOptions.${value}`,
            }),
            // Disable the input if the opposite variable of that type has been selected.
            disabled: this.state[`selectedVariable${optionType === 'X' ? 'Y' : 'X'}`] === value,
        }));

    getVariable = (optionType: VariableType) => this.state[`selectedVariable${optionType}`];

    getVariableMin = (optionType: VariableType) => {
        const variable = this.getVariable(optionType);
        if (!variable) return null;
        return SENSITIVITY_OPTION_LIMITS[variable].MINIMUM;
    };

    getVariableMax = (optionType: VariableType) => {
        const variable = this.getVariable(optionType);
        if (!variable) return null;
        return SENSITIVITY_OPTION_LIMITS[variable].MAXIMUM;
    };

    /**
     * Get the radio button options for the output variable.
     */
    getOutputRadioOptions = () =>
        Object.keys(THREE_D_SENSITIVITY_OUTPUT_OPTIONS).map((value: string) => ({
            value,
            label: this.props.intl.formatMessage({
                id: `components.SensitivityDiagrams.Axis.${value}`,
            }),
        }));

    /**
     * Is the range of the variable input okay?
     */
    isRangeOkay = (optionType: VariableType) => {
        const option = this.state[`selectedVariable${optionType}`];
        if (!option) {
            return false;
        }

        const start = this.state[`start${optionType}`];
        const finish = this.state[`finish${optionType}`];

        // Make sure that if the user has specified both, that the startX is less than the finishX.
        if (start !== null && start !== null) {
            if (start >= finish) {
                return false;
            }
        }

        const min = this.getVariableMin(optionType);
        const max = this.getVariableMax(optionType);

        if (start !== null && min !== null) {
            return start >= min;
        }
        if (finish !== null && max !== null) {
            return finish <= max;
        }
        return true;
    };

    /**
     * Gets whether the generate button is enabled.
     */
    isGenerateButtonEnabled = () =>
        Boolean(this.state.selectedVariableX) &&
        Boolean(this.state.selectedVariableY) &&
        this.isRangeOkay('X') &&
        this.isRangeOkay('Y');

    /**
     * Handle radio button change
     */
    handleVariableSelected = (variableKey: VariableType) => (selectedVariable: SensitivityOption) =>
        this.setState({
            [`selectedVariable${variableKey}`]: selectedVariable,
            [`start${variableKey}`]: SENSITIVITY_OPTION_LIMITS[selectedVariable].DEFAULT_MINIMUM,
            [`finish${variableKey}`]: SENSITIVITY_OPTION_LIMITS[selectedVariable].DEFAULT_MAXIMUM,
        });

    /**
     * Handle radio button change for the output option.
     */
    handleOutputSelected = (selectedOutputVariable: SensitivityOutputOption) =>
        this.setState({ selectedOutputVariable });

    /**
     * Handle the change in range start or finish.
     */
    handleRangeChange = (variableKey: VariableType, inputKey: 'start' | 'finish') => (
        event: InputEvent
    ) => {
        const stateKey = [`${inputKey}${variableKey}`];
        if (event.target.value === '') {
            // the pressed backspace.
            this.setState({ [stateKey]: null });
            return;
        }
        this.setState({ [stateKey]: event.target.value });
    };

    handleOnBlur = (variableKey: VariableType, inputKey: 'start' | 'finish') => (
        event: InputEvent
    ) => {
        const stateKey = [`${inputKey}${variableKey}`];
        if (event.target.value === '') {
            // the pressed backspace.
            this.setState({ [stateKey]: null });
            return;
        }
        // "Clasp" the input value between the min and max of the variable.
        const value = clamp(
            Number(event.target.value),
            this.getVariableMin(variableKey),
            this.getVariableMax(variableKey)
        );
        this.setState({ [stateKey]: value });
    };

    /**
     * Generate the diagram.
     */
    handleGenerateDiagramClicked = () =>
        this.isGenerateButtonEnabled() &&
        this.props.handleGenerateDiagramClicked(
            {
                sensitivityOption: this.state.selectedVariableX,
                start: this.state.startX,
                finish: this.state.finishX,
            },
            {
                sensitivityOption: this.state.selectedVariableY,
                start: this.state.startY,
                finish: this.state.finishY,
            },
            this.state.selectedOutputVariable
        );

    render() {
        return (
            <SidebarBody>
                <BodySection>
                    <VariableBodySection>
                        <VariableTitle>
                            <span>
                                {this.props.intl.formatMessage({
                                    id: `components.CircuitComputationSidebar.3DSensitivity.variableZ.title`,
                                })}
                            </span>
                        </VariableTitle>
                        <RadioButtonSet
                            value={this.state.selectedOutputVariable}
                            options={this.getOutputRadioOptions()}
                            onClick={this.handleOutputSelected}
                            disabled={this.props.loading}
                            styles={{
                                labelColor: LegacyTheme.defaultColor,
                                labelFontSize: '13px',
                            }}
                        />
                    </VariableBodySection>
                    <VariableBodySection>
                        <VariableTitle>
                            <span>
                                {this.props.intl.formatMessage({
                                    id: `components.CircuitComputationSidebar.3DSensitivity.variableX.title`,
                                })}
                            </span>
                        </VariableTitle>
                        <RadioButtonSet
                            value={this.state.selectedVariableX}
                            options={this.getVariableRadioOptions('X')}
                            onClick={this.handleVariableSelected('X')}
                            disabled={this.props.loading}
                            styles={{
                                labelColor: LegacyTheme.defaultColor,
                                labelFontSize: '13px',
                            }}
                        />
                        <RangeWrapper>
                            <RangeTitle>
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: `components.CircuitComputationSidebar.3DSensitivity.rangeX.title`,
                                    })}
                                </span>
                            </RangeTitle>
                            <RangeInputWrapper>
                                <RangeInput>
                                    <span>
                                        {this.props.intl.formatMessage({
                                            id: `components.CircuitComputationSidebar.3DSensitivity.rangeX.startLabel`,
                                        })}
                                    </span>
                                    <InputNumber
                                        handleOnChange={this.handleRangeChange('X', 'start')}
                                        onBlur={this.handleOnBlur('X', 'start')}
                                        value={this.state.startX || ''}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        disabled={this.props.loading}
                                        textAlign="center"
                                        min={this.getVariableMin('X')}
                                        max={this.getVariableMax('X')}
                                        style={INPUT_STYLES}
                                    />
                                </RangeInput>
                                <RangeInput>
                                    <span>
                                        {this.props.intl.formatMessage({
                                            id: `components.CircuitComputationSidebar.3DSensitivity.rangeX.endLabel`,
                                        })}
                                    </span>
                                    <InputNumber
                                        handleOnChange={this.handleRangeChange('X', 'finish')}
                                        onBlur={this.handleOnBlur('X', 'finish')}
                                        value={this.state.finishX || ''}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        disabled={this.props.loading}
                                        textAlign="center"
                                        min={this.getVariableMin('X')}
                                        max={this.getVariableMax('X')}
                                        style={INPUT_STYLES}
                                    />
                                </RangeInput>
                            </RangeInputWrapper>
                        </RangeWrapper>
                    </VariableBodySection>
                    <VariableBodySection>
                        <VariableTitle>
                            <span>
                                {this.props.intl.formatMessage({
                                    id: `components.CircuitComputationSidebar.3DSensitivity.variableY.title`,
                                })}
                            </span>
                        </VariableTitle>
                        <RadioButtonSet
                            value={this.state.selectedVariableY}
                            options={this.getVariableRadioOptions('Y')}
                            onClick={this.handleVariableSelected('Y')}
                            disabled={this.props.loading}
                            styles={{
                                labelColor: LegacyTheme.defaultColor,
                                labelFontSize: '13px',
                            }}
                        />
                        <RangeWrapper>
                            <RangeTitle>
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: `components.CircuitComputationSidebar.3DSensitivity.rangeY.title`,
                                    })}
                                </span>
                            </RangeTitle>
                            <RangeInputWrapper>
                                <RangeInput>
                                    <span>
                                        {this.props.intl.formatMessage({
                                            id: `components.CircuitComputationSidebar.3DSensitivity.rangeY.startLabel`,
                                        })}
                                    </span>
                                    <InputNumber
                                        handleOnChange={this.handleRangeChange('Y', 'start')}
                                        onBlur={this.handleOnBlur('Y', 'start')}
                                        value={this.state.startY || ''}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        disabled={this.props.loading}
                                        textAlign="center"
                                        min={this.getVariableMin('Y')}
                                        max={this.getVariableMax('Y')}
                                        style={INPUT_STYLES}
                                    />
                                </RangeInput>
                                <RangeInput>
                                    <span>
                                        {this.props.intl.formatMessage({
                                            id: `components.CircuitComputationSidebar.3DSensitivity.rangeY.endLabel`,
                                        })}
                                    </span>
                                    <InputNumber
                                        handleOnChange={this.handleRangeChange('Y', 'finish')}
                                        onBlur={this.handleOnBlur('Y', 'finish')}
                                        value={this.state.finishY || ''}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        disabled={this.props.loading}
                                        textAlign="center"
                                        min={this.getVariableMin('Y')}
                                        max={this.getVariableMax('Y')}
                                        style={INPUT_STYLES}
                                    />
                                </RangeInput>
                            </RangeInputWrapper>
                        </RangeWrapper>
                    </VariableBodySection>
                </BodySection>
                <FooterSection withMargin>
                    <PrimaryButton
                        text={this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.3DSensitivity.generatePlotButton`,
                        })}
                        loading={this.props.loading}
                        disabled={!this.isGenerateButtonEnabled() || this.props.loading}
                        onClick={this.handleGenerateDiagramClicked}
                    />
                </FooterSection>
            </SidebarBody>
        );
    }
}

export default injectIntl(ThreeDSensitivitySidebarSection);
