// @flow strict

import styled from 'styled-components';

import { STYLE_VALUES } from 'utils/constants';

export const MicroFrontendWrapper = styled.div`
    width: 100%;
    height: ${({ excludeHeader }) =>
        excludeHeader ? `calc(100% - ${STYLE_VALUES.HEADER.HEIGHT})` : '100%'};
`;
