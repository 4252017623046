// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const SidebarSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px;
    font-size: 12px;

    & + & {
        border-top: 1px solid ${LegacyTheme.defaultBorder};
        padding-top: 23px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow: auto;

    justify-content: space-between;
`;

export const SidebarBody = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled.h3`
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    color: ${LegacyTheme.defaultColor};
`;

export const SectionBody = styled.div`
    margin-left: 7px;

    label {
        font-size: 13px;
    }
`;

export const FooterSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 8px 24px;

    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;
