// @flow strict

import { fromJS } from 'immutable';

// Actions
import { LOCATION_CHANGE } from 'react-router-redux';

import {
    CREATE_SUCCESS,
    CREATE_FAILURE,
    // FETCH_LIST_SUCCESS,
    // FETCH_LIST_FAILURE,
    FETCH_SUCCESS,
    FETCH_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DESTROY_SUCCESS,
    DESTROY_FAILURE,
    COMPUTE_SUCCESS,
    COMPUTE_FAILURE,
    COMPUTE_2D_SUCCESS,
    COMPUTE_2D_FAILURE,
    COMPUTE_2D_DATASET_SUCCESS,
    COMPUTE_2D_DATASET_FAILURE,
    EXPORT_2D_SENSITIVITY_CSV_SUCCESS,
    EXPORT_2D_SENSITIVITY_CSV_FAILURE,
    COMPUTE_3D_SUCCESS,
    COMPUTE_3D_FAILURE,
    IS_FETCHING,
    IS_UPDATING,
    IS_DELETING,
    IS_CREATING,
    IS_EXPORTING,
    IS_COMPUTING,
    IS_COMPUTING_2D,
    IS_COMPUTING_3D,
    IS_COMPUTING_2D_DATASET,
    IS_SCENARIO_SUBMITTING,
    SCENARIO_SUCCESS,
    SCENARIO_FAILURE,
    CLEAR_SCENARIO,
    COMPUTE_FINISHED,
    DATASET_SAVED,
} from './actions';

// Types
import type { GenericActionType, ImmutableList } from 'types';
import type {
    ImmutableDatasetState,
    ImmutableDataset,
    ImmutableDatasetValue,
} from 'services/Dataset/types';

const initialState: ImmutableDatasetState = fromJS({
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isComputing: false,
    isComputing2D: false,
    isComputing2DDataset: false,
    isExporting: false,
    isComputing3D: false,
    datasets: [],
    computedDataset: null,
    computed2D: null,
    computed2DDataset: null,
    exported2DSensitivityCSV: null,
    errors: {},
    fetchErrors: {},
    isScenarioSubmitting: false,
    scenarioResults: null,
    isComputeCompleted: false,
    isDatasetSaved: false,
});

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function datasetServiceReducer(
    state: ImmutableDatasetState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return state.set('computedDataset', null).set('errors', fromJS({}));
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);
        case IS_DELETING:
            return state.set('isDeleting', action.payload.isDeleting);
        case IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);
        case IS_COMPUTING:
            return state.set('isComputing', action.payload.isComputing);
        case IS_COMPUTING_2D:
            return state.set('isComputing2D', action.payload.isComputing2D);
        case IS_COMPUTING_2D_DATASET:
            return state.set('isComputing2DDataset', action.payload.isComputing2DDataset);
        case IS_EXPORTING:
            return state.set('isExporting', action.payload.isExporting);
        case IS_COMPUTING_3D:
            return state.set('isComputing3D', action.payload.isComputing3D);
        case IS_SCENARIO_SUBMITTING:
            return state.set('isScenarioSubmitting', action.payload.isScenarioSubmitting);

        case EXPORT_2D_SENSITIVITY_CSV_SUCCESS:
            return state
                .set('exported2DSensitivityCSV', action.payload.data)
                .set('isExporting', false)
                .set('errors', fromJS({}));

        case EXPORT_2D_SENSITIVITY_CSV_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isExporting', false);

        case CREATE_SUCCESS: {
            const newDataset = fromJS(action.payload.data);
            return state
                .updateIn(['datasets'], (datasets: ImmutableList<ImmutableDataset>) => {
                    const idx = datasets.findIndex(
                        (dataset: ImmutableDataset) => dataset.get('id') === newDataset.get('id')
                    );
                    return idx !== -1
                        ? datasets.setIn([idx], newDataset)
                        : datasets.unshift(newDataset);
                })
                .set('errors', fromJS({}))
                .set('isCreating', false);
        }

        case CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);

        case FETCH_SUCCESS: {
            const fetchedDataset = fromJS(action.payload.data);
            return state
                .updateIn(['datasets'], (datasets: ImmutableList<ImmutableDataset>) => {
                    const idx = datasets.findIndex(
                        (dataset: ImmutableDataset) =>
                            dataset.get('id') === fetchedDataset.get('id')
                    );
                    return idx !== -1
                        ? datasets.setIn([idx], fetchedDataset)
                        : datasets.unshift(fetchedDataset);
                })
                .set('fetchErrors', fromJS({}))
                .set('isFetching', false);
        }
        case FETCH_FAILURE:
            return state.set('fetchErrors', fromJS(action.payload.errors)).set('isFetching', false);

        case UPDATE_SUCCESS: {
            const updatedDataset = fromJS(action.payload.data);
            return state
                .updateIn(['datasets'], (datasets: ImmutableList<ImmutableDataset>) => {
                    const idx = datasets.findIndex(
                        (dataset: ImmutableDataset) =>
                            dataset.get('id') === updatedDataset.get('id')
                    );
                    return idx !== -1
                        ? datasets.setIn([idx], updatedDataset)
                        : datasets.unshift(updatedDataset);
                })
                .set('errors', fromJS({}))
                .set('isUpdating', false);
        }
        case UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isUpdating', false);

        case DESTROY_SUCCESS: {
            const deletedDataset = fromJS(action.payload.data);
            return state
                .updateIn(['datasets'], (datasets: ImmutableList<ImmutableDataset>) => {
                    const idx = datasets.findIndex(
                        (dataset: ImmutableDataset) =>
                            `${dataset.get('id')}` === deletedDataset.get('id')
                    );
                    return idx !== -1 ? datasets.delete(idx) : datasets;
                })
                .set('errors', fromJS({}))
                .set('isDeleting', false);
        }
        case DESTROY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isDeleting', false);

        case COMPUTE_SUCCESS: {
            const { dataset, converged } = action.payload.data;
            return state
                .set('computedDataset', fromJS(dataset))
                .set('errors', fromJS({ converged }))
                .set('isComputing', false);
        }
        case COMPUTE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isComputing', false);

        case COMPUTE_2D_SUCCESS: {
            const sensitivity = action.payload.data;
            return state
                .set('computed2D', fromJS(sensitivity))
                .set('computed2DDataset', null)
                .set('errors', fromJS({}))
                .set('isComputing2D', false);
        }
        case COMPUTE_2D_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isComputing2D', false);

        case COMPUTE_3D_SUCCESS: {
            const sensitivity = action.payload.data;
            return state
                .set('computed3D', fromJS(sensitivity))
                .set('errors', fromJS({}))
                .set('isComputing3D', false);
        }
        case COMPUTE_3D_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isComputing3D', false);

        case COMPUTE_2D_DATASET_SUCCESS: {
            const dataset = action.payload.data;
            if (dataset.converged) {
                return state
                    .set('computed2DDataset', fromJS(dataset))
                    .set('errors', fromJS({}))
                    .set('isComputing2DDataset', false);
            }
            return state
                .set('computed2DDataset', null)
                .set('errors', fromJS({ converged: dataset.converged }))
                .set('isComputing2DDataset', false);
        }
        case COMPUTE_2D_DATASET_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isComputing2DDataset', false);

        case SCENARIO_SUCCESS: {
            const dataset = action.payload.data;
            return state
                .set('scenarioResults', fromJS(dataset))
                .set('errors', fromJS({}))
                .set('isScenarioSubmitting', false);
        }
        case SCENARIO_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isScenarioSubmitting', false);

        case CLEAR_SCENARIO: {
            return state
                .set('scenarioResults', null)
                .set('errors', fromJS({}))
                .set('isScenarioSubmitting', false);
        }
        case COMPUTE_FINISHED:
            return state.set('isComputeCompleted', action.payload.isComputeCompleted);

        case DATASET_SAVED:
            return state.set('isDatasetSaved', action.payload.isDatasetSaved);

        default:
            return state;
    }
}

export default datasetServiceReducer;
