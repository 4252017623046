// @flow strict

import styled from 'styled-components';

import { colors } from 'styles/colors';

type IStageHeaderProps = {
    isSetupMode: boolean,
    isSmallStage: boolean,
};

export const StageHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    flex-grow: 1;

    background-color: ${colors.greyE5};

    ${({ isSetupMode }: IStageHeaderProps) =>
        !isSetupMode &&
        `
        padding: 9px 16px;
        justify-content: space-between;
        flex-grow: unset;
    `}

    ${({ isSmallStage, isSetupMode }: IStageHeaderProps) =>
        isSmallStage &&
        isSetupMode &&
        `
            flex-direction: column;
        `}
`;

export const StageHeaderDescription = styled.h3`
    margin: 0;

    font-weight: normal;
    font-size: 11px;
`;

export const StageHeaderLabel = styled.h3`
    margin: 0;
    font-weight: normal;
    font-size: 16px;

    ${({ isSmallStage }) =>
        isSmallStage &&
        `
            font-size: 13px;
        `}
`;
