// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { CalculatorIcon, InputNumber } from 'components/_ReactUI_V1';

import { DiagramValueBoxWrapper, InputWrapper, InputLabelWrapper, UnitWrapper } from './styles';
import { streamCircuitColors } from 'styles/colors';

// Components

// Constants
import {
    STREAM_VALUE_TYPES,
    STREAM_VALUE_LIMITS,
    DIAGRAM_DISPLAY_MODES,
    DATASET_MODES,
    VALUE_STATUS,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
} from 'utils/constants';

// helpers
import { getValueTypeUnit } from 'utils/kpiHelpers';
import { clamp, round, tryParseNumberOrNull } from 'utils/helpers';

// Types
import type { InputEvent, IntlType, LooseNumberType, UnitsConstant } from 'types';
import type { StreamCircuitConstant } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';
import type { DiagramDisplayModesConstant } from 'components/MimicDiagram/types';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    label: string,
    units: string | null,
    precision: number,
    minValue?: number | null,
    maxValue?: number | null,
    value: number | null,
    setValue: (newValue: number | null) => void,
    backgroundColor: string,
    calculatorColor: string,
    hasBeenCalculated: boolean,
    isCalculated: boolean,
    isMandatory: boolean,
};

/**
 * Diagram value box displays either stream values or stage values input boxes.
 * Depending on the diagram display mode and the state of the computation,
 * various props can be used to change the behaviour of the inputs.
 */
class DiagramValueBox extends React.PureComponent<Props> {
    static defaultProps = {
        minValue: null,
        maxValue: null,
    };

    setValue = (value: number | null) => {
        if (value !== this.props.value) {
            this.props.setValue(value);
        }
    };

    handleOnChange = (event: InputEvent) => {
        const value = tryParseNumberOrNull(event.target.value);
        this.setValue(value);
    };

    handleOnBlur = (event: InputEvent) => {
        const value = tryParseNumberOrNull(event.target.value);
        if (value === null) {
            this.setValue(null);
            return;
        }
        const clampedToMinMax = clamp(value, event.target.min, event.target.max);
        this.setValue(clampedToMinMax);
    };

    getValue = (): number | string => {
        if (this.props.value === null) {
            return '';
        }
        return round(this.props.value || 0, this.props.precision);
    };

    renderNotCalculatedLabel = () => {
        return (
            <React.Fragment>
                {this.props.isCalculated && this.renderCalculatorIcon()}
                <InputLabelWrapper>
                    {this.props.isMandatory &&
                        this.props.intl.formatMessage({
                            id: `components.MimicDiagram.mandatoryFieldChar`,
                        })}
                    {this.props.label}
                </InputLabelWrapper>
            </React.Fragment>
        );
    };

    renderCalculatedLabel = () => {
        const units = this.props.units || '';
        const value = this.getValue().toString();
        return (
            <React.Fragment>
                {this.props.isCalculated && this.renderCalculatorIcon()}
                <InputLabelWrapper>
                    <span title={!__PROD__ ? `${this.props.value || ''}${units}` : ''}>
                        {`${this.props.label} = ${value} ${units}`}
                    </span>
                </InputLabelWrapper>
            </React.Fragment>
        );
    };

    renderUnit = () => {
        let content = <UnitWrapper />;

        const units = this.props.units;

        // if we have a calculator icon, but we aren't in read only mode don't show the units
        if (this.props.isCalculated && !this.props.hasBeenCalculated) {
            return content;
        }

        if (units) {
            content = <UnitWrapper>{units}</UnitWrapper>;
        }

        return content;
    };

    renderCalculatorIcon = () => (
        <CalculatorIcon margin="0 10px 0 0" fill={this.props.calculatorColor} />
    );

    renderValueInput = () => {
        const renderString = this.props.hasBeenCalculated || this.props.isCalculated;
        const value = this.getValue();

        if (renderString && !value) {
            return null;
        }

        const renderValue = !this.props.hasBeenCalculated && !this.props.isCalculated;

        return (
            <InputWrapper>
                <InputNumber
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    renderString={renderString}
                    min={this.props.minValue}
                    max={this.props.maxValue}
                    value={renderValue ? value : ''}
                    style={STYLE_VALUES.DEFAULT_INPUT_NUMBER_STYLES}
                    handleOnChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    noSpinner
                />
            </InputWrapper>
        );
    };

    renderBody = () => {
        if (this.props.hasBeenCalculated) {
            return this.renderCalculatedLabel();
        } else {
            return (
                <React.Fragment>
                    {this.renderNotCalculatedLabel()}
                    {this.renderValueInput()}
                    {this.renderUnit()}
                </React.Fragment>
            );
        }
    };

    render() {
        return (
            <DiagramValueBoxWrapper backgroundColor={this.props.backgroundColor}>
                {this.renderBody()}
            </DiagramValueBoxWrapper>
        );
    }
}

export default injectIntl(DiagramValueBox);
