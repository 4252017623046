// @flow

import React from 'react';

// Styles
import { StyledLoader } from './styles';

type Props = {
    loading?: boolean,
    size?: string,
    speed?: string,
    theme?: ?{},
    title?: string,
    width?: string,
};

export class Loader extends React.PureComponent<Props> {
    static defaultProps = {
        loading: false,
        size: '50px',
        speed: '2s',
        theme: null,
        title: 'Loading',
        width: '5px',
    };

    render() {
        if (!this.props.loading) {
            return null;
        }

        return (
            <StyledLoader
                speed={this.props.speed}
                size={this.props.size}
                theme={this.props.theme}
                title={this.props.title}
                width={this.props.width}
            />
        );
    }
}
