// @noflow

import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import legacyTheme from '../../themes/legacyTheme';

// Assets
import { StyledButtonHover } from '../button-hover/styles';
import caretDown from './assets/Caret/down-grey.png';
import caretUp from './assets/Caret/up-grey.png';

// Components
export const StyledTr = styled.tr`
    width: 100%;
    border-bottom: 1px solid ${legacyTheme.tableBorder};

    background-color: ${({ disabled, trBackgroundColor }: Object) => {
        if (disabled) {
            return legacyTheme.tableRowDisabledBackgroundColor;
        } else if (trBackgroundColor) {
            return trBackgroundColor;
        } else {
            return 'inherit';
        }
    }};

    color: ${legacyTheme.tableColor};

    ${({ onClick, trBackgroundColorHover }: Object) =>
        onClick
            ? `
        cursor: pointer;

        &:hover {
            background-color: ${
                trBackgroundColorHover || legacyTheme.tableRowHover
            };
        }
    `
            : `
        cursor: default;
    `};
`;

export const StyledThead = styled.thead``;

export const StyledTh = styled.th`
    vertical-align: middle;
    height: 49px;

    padding: ${({ flush }: Object) => (flush ? '0 !important' : '10px')};

    background-color: ${legacyTheme.tableHeader};
    color: ${({ headerColor }: Object) => headerColor || legacyTheme.tableHeaderColor};

    font-size: 14px;
    font-weight: normal;

    text-align: left;

    &:first-child {
        padding-left: 20px;
    }

    box-sizing: border-box;
`;

export const StyledTd = styled.td`
    vertical-align: ${({ tdVerticalAlign }: Object) => tdVerticalAlign || 'middle'};
    height: ${({ tdHeight }: Object) => (tdHeight || '49px')};
    color: ${({ tdColor }: Object) => tdColor || 'inherit'};

    padding: ${({ flush, tdPadding }: Object) => {
        if (flush) {
            return '0 !important';
        } else if (tdPadding) {
            return tdPadding;
        } else {
            return '12px';
        }
    }};

    max-width: ${({ tdMaxWidth }: Object) => tdMaxWidth || '100%'};
    word-break: ${({ tdWordBreak }: Object) => tdWordBreak || 'normal'};

    &:first-child {
        padding-left: 20px;
    }

    ${StyledButtonHover} {
        margin-top: -4px;
        margin-left: -6px;
        margin-bottom: -4px;
    }

    font-size: ${({ tdFontSize }: Object) => tdFontSize || '15px'};
`;

export const StyledTdVerticalSpacer = styled.div`
    display: flex;
    align-items: center;

    min-height: ${({ minHeight }: Object) => minHeight || 'none'};
`;

export const StyledTBody = styled.tbody`
    > ${StyledTr} {
        &:last-child {
            border-bottom: 0;
        }
    }
`;

export const StyledExpandedContent = styled.div`
    ${({ maxHeight }: Object) =>
        maxHeight &&
        `
        max-height: ${maxHeight};
        overflow: auto;
    `}

    padding: 24px 20px;
    background-color: ${legacyTheme.tableExpanded};
`;

export const StyledLoadingContent = styled.div`
    padding: 24px 20px;

    display: flex;
    justify-content: center;
`;

export const StyledTable = styled.table`
    position: relative;
    width: 100%;

    background-color: ${legacyTheme.tableBackground};
    border-collapse: collapse;

    z-index: 1;
`;

export const StyledTableContainer = styled.div`
    position: relative;
    width: 100%;

    border: 1px solid ${legacyTheme.tableBorder};
    border-radius 4px;

    ${({ gridStyling }: Object) =>
        gridStyling &&
        css`
            ${StyledTable} {
                ${StyledTh}, ${StyledTd} {
                    border-right: 1px solid ${legacyTheme.tableBorder};

                    &:last-child {
                        border-right-width: 0;
                    }
                }
            }
        `}

    ${({ subtleStyling }: Object) =>
        subtleStyling &&
        css`
            border-color: ${legacyTheme.tableSubtleBorder};

            ${StyledTable} {
                ${StyledTr} {
                    border-color: ${legacyTheme.tableSubtleBorder};
                }

                ${StyledTh}, ${StyledTd} {
                    font-size: 13px !important;
                }

                ${StyledTh} {
                    background-color: ${legacyTheme.tableSubtleHeader};
                    color: ${legacyTheme.tableSubtleHeaderColor};
                }

                ${StyledTd} {
                    color: ${legacyTheme.tableSubtleColor};
                }
            }
        `}
`;

export const StyledOverlayDiv = styled.td`
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height 100%;
`;

export const StyledOverlayTr = styled.tr`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height 100%;

    background-color: ${transparentize(0.25, legacyTheme.tableBackground)};

    z-index: 10;
`;

export const SortToggler = styled.span`
    margin-left: 5px;

    &:after {
        content: '';
        opacity: ${({ active }) => (active ? '1 !important;' : '0')};
        display: inline-block;

        width: 10px;
        height: 10px;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    ${({ asc, desc }: Object) => {
        let dynamicStyles = '&:after {';
        if (asc || (!asc && !desc)) {
            dynamicStyles += `background-image: url(${caretDown});`;
        }
        if (desc) {
            dynamicStyles += `background-image: url(${caretUp});`;
        }
        return `${dynamicStyles} }`;
    }};
`;

export const SortToggle = styled.span`
    white-space: nowrap;

    &:hover {
        cursor: pointer;

        ${SortToggler} {
            &:after {
                opacity: 0.5;
            }
        }
    }
`;

export const StyledFooterMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height 100%;
`;
