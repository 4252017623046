// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { QuickNavigatorObservable } from 'components/_FrontendObservables';

// Constants
import { SidebarLayout, SidebarTab } from 'components/_ReactUI_V1';

import { CIRCUIT_TYPES, NAVIGATION_ROUTES, STYLE_VALUES } from 'utils/constants';

// Components
import MinChemDashboard from 'components/Dashboards/MinChemDashboard';
import SolvExtractDashboard from 'components/Dashboards/SolvExtractDashboard';
import CircuitDetailsModal from 'components/Modals/CircuitDetailsModal';
import DatasetSelectModal from 'components/Modals/DatasetSelectModal';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

// Helpers
import { isSolvExtractUser, getUsersLanguage, isSysAdmin, canAccessCyanex } from 'utils/authentication';
import { MinChemMfeUrls } from '../../microfrontends/MinChemV2/urls';
import { MinChemVersions } from '../../microfrontends/MinChemV2/versions';

// Services
import {
    createCircuit,
    createAdvancedCircuit,
    updateCircuit,
    destroyCircuit,
    queryCircuits,
} from 'services/Circuit/thunks';
import { queryDashboardPlants } from 'services/Plant/thunks';
import {
    selectCircuitErrors,
    selectMinChemCircuitQuery,
    selectCircuitsAreCreating,
    selectCircuitsAreUpdating,
    selectCircuitsAreDeleting,
    selectMinChemIsQueryingStatus,
} from 'services/Circuit/selectors';
import {
    selectSolvExtractDashboardPlants,
    selectQueryingDashboardPlantsStatus,
} from 'services/Plant/selectors';
import { destroyDataset } from 'services/Dataset/thunks';
import { selectDatasetErrors, selectDatasetsAreDeleting } from 'services/Dataset/selectors';
import { selectUser } from 'services/Authentication/selectors';

// Types
import type {
    ReduxDispatch,
    IntlType,
    ErrorType,
    ImmutableQueryStructure,
    SidebarTabType,
    ReactNode,
    SearchCriteria,
    CircuitTypes,
    InputEvent,
} from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableDataset } from 'services/Dataset/types';

type CircuitTypeSidebarTabType = {
    ...$Exact<SidebarTabType>,
    key: CircuitTypes,
};

type ModalType =
    | 'SELECT_DATASET'
    | 'CREATE_CIRCUIT'
    | 'UPDATE_CIRCUIT'
    | 'DELETE_CIRCUIT'
    | 'DELETE_DATASET';

type ImmutableCircuitOrDatabase = ImmutableCircuit | ImmutableDataset;

export type OpenModalFunction = (type: ModalType, value?: ImmutableCircuitOrDatabase) => void;

type Props = {
    intl: IntlType,
    circuitType: CircuitTypes,
    mobileMaxPx: number,
    errors: ErrorType,
    datasetErrors: ErrorType,
    user: ImmutableUser,

    minChemCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,
    solvExtractDashboardPlantsQuery: ImmutableQueryStructure<ImmutablePlant>,

    isQueryingMinChem: boolean,
    isQueryingDashboardPlants: boolean,

    isCreating: boolean,
    isUpdating: boolean,
    isDeletingCircuit: boolean,
    isDeletingDataset: boolean,

    queryCircuits: (type: CircuitTypes, circuitStructure?: ?SearchCriteria, page?: number) => void,
    createCircuit: (circuit: ImmutableCircuit) => void,
    updateCircuit: (id: number, circuit: ImmutableCircuit) => void,
    destroyCircuit: (id: number) => void,
    destroyDataset: (id: number) => void,
    queryDashboardPlants: (page?: number) => void,
};

type State = {
    currentFetch: SearchCriteria,

    openedModal: ?ModalType,
    // Objects used by the currently opened modal.
    selectedCircuitId: ?number,
    selectedDatasetId: ?number,
};

/**
 * Contains the entire dashboard (page after the login)
 */
class DashboardContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.sidebarRef = React.createRef();

        this.state = {
            openedModal: null,
            selectedCircuitId: null,
            selectedDatasetId: null,
            currentFetch: this.defaultFetch(),
        };
    }

    componentDidMount() {
        this.handleDashboardDataQuery();
        QuickNavigatorObservable.removeQuickNavigator();
    }

    /**
     * When the component is updated, check if the action of a modal has terminated
     * And update state accordingly.
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.isDeletingDataset && !this.props.isDeletingDataset) {
            this.setState({
                openedModal: 'SELECT_DATASET',
                selectedDatasetId: null,
            });
            return;
        }

        const wasDoingAction =
            prevProps.isCreating ||
            prevProps.isUpdating ||
            prevProps.isDeletingCircuit ||
            prevProps.isDeletingDataset;
        const isDoingAction =
            this.props.isCreating ||
            this.props.isUpdating ||
            this.props.isDeletingCircuit ||
            this.props.isDeletingDataset;

        if (wasDoingAction && !isDoingAction) {
            this.setState({
                openedModal: null,
                selectedCircuitId: null,
                selectedDatasetId: null,
            });
        }
    }

    defaultFetch = () => ({
        search: '',
    });

    handleDashboardDataQuery = (page: ?number = null) => {
        if (this.props.circuitType === CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT) {
            return this.props.queryDashboardPlants(this.state.currentFetch, page);
        } else {
            return this.props.queryCircuits(this.props.circuitType, this.state.currentFetch, page);
        }
    };

    /**
     * Close the opened modal.
     * If the opened modal was the delete dataset, re-open the select circuit modal.
     */
    handleClose = () => {
        if (this.state.openedModal === 'DELETE_DATASET') {
            this.setState({
                openedModal: 'SELECT_DATASET',
                selectedDatasetId: null,
            });
            return;
        }
        this.setState({
            openedModal: null,
            selectedCircuitId: null,
            selectedDatasetId: null,
        });
    };

    /**
     * Handles the confirmation of the select modal.
     */
    handleSelectConfirm = (circuit: ImmutableCircuit, dataset: 'BLANK' | ImmutableDataset) => {
        const isNewDataset = dataset === 'BLANK';
        const circuitId = circuit.get('id');

        const useMinChemMfe = circuit.get('version', null) === MinChemVersions.MFE;
        if (useMinChemMfe) {
            if (isNewDataset) {
                this.props.history.push(MinChemMfeUrls.getCircuitSetupUrl(circuitId));
                return;
            }
            const datasetId = dataset.get('id');
            this.props.history.push(MinChemMfeUrls.getCircuitDatasetUrl(circuitId, datasetId));
            return;
        }

        // Legacy V1 circuits using MinChem V1:
        if (dataset === 'BLANK') {
            this.props.history.push(`/circuit/${circuitId}`);
            return;
        }
        this.props.history.push(`/compute/${circuitId}/dataset/${dataset.get('id')}`);
    };

    /**
     * Creates a circuit
     */
    handleCreateCircuitConfirm = (name: string, plantName: string, comments: string) =>
        this.props.createCircuit({
            name,
            plantName,
            comments,
        });

    /**
     * Updates the selected circuit
     */
    handleUpdateConfirm = (name: string, plantName: string, comments: string) => {
        if (!this.state.selectedCircuitId) {
            console.error('Received null circuit to update modal.');
            return;
        }
        this.props.updateCircuit(this.state.selectedCircuitId, {
            name,
            plantName,
            comments,
        });
    };

    /**
     * Deletes the selected circuit
     */
    handleDeleteCircuitConfirm = () => {
        if (!this.state.selectedCircuitId) {
            console.error('Received null circuit to delete modal.');
            return;
        }
        this.props.destroyCircuit(this.state.selectedCircuitId);
    };

    /**
     * Deletes the given dataset.
     */
    handleDeleteDatasetConfirm = () => {
        if (!this.state.selectedDatasetId) {
            console.error('Received null dataset to delete modal.');
            return;
        }
        this.props.destroyDataset(this.state.selectedDatasetId);
    };

    /**
     * Opens a modal of type ModalType used for circuits
     */
    handleOpenCircuitModal = (type: ModalType, selectedCircuit: ImmutableCircuit) =>
        this.setState({
            openedModal: type,
            selectedCircuitId: selectedCircuit && selectedCircuit.get('id'),
        });

    /**
     * Opens a modal of type ModalType used for datasets
     */
    handleOpenDatasetModal = (type: ModalType, selectedDataset: ImmutableDataset) =>
        this.setState({
            openedModal: type,
            selectedDatasetId: selectedDataset && selectedDataset.get('id'),
        });

    /**
     * Queries the circuits
     */
    handleDataSortBy = (structure: ?SearchCriteria) => {
        // If the current request is not the same as the new sort by, trigger it.
        let isFetching = false;
        if (this.props.circuitType === CIRCUIT_TYPES.MINCHEM_CIRCUIT) {
            isFetching = this.props.isQueryingMinChem;
        }
        if (this.props.circuitType === CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT) {
            isFetching = this.props.isQueryingDashboardPlants;
        }

        if (!isFetching) {
            this.setState(
                (prevState: State) => ({
                    currentFetch: {
                        ...prevState.currentFetch,
                        ...structure,
                    },
                }),
                () => this.handleDashboardDataQuery()
            );
        }
    };

    /**
     * Fetch a specific page using the current fetch order.
     */
    handleDashboardDataChangePage = (page: number, total: number) => () => {
        if (page > total || page < 0) {
            return null;
        }
        this.handleDashboardDataQuery(page);
    };

    handleSearchSubmit = () => this.handleDashboardDataQuery();

    handleSearchClear = () => {
        this.setState(
            (prevState: State) => ({
                currentFetch: {
                    ...prevState.currentFetch,
                    search: '',
                },
            }),
            () => this.handleDashboardDataQuery()
        );
    };

    handleSearchOnChange = (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => ({
            currentFetch: {
                ...prevState.currentFetch,
                search: value,
            },
        }));
    };

    /**
     * On keypress, check for key === 'Enter' if so fire this.onSearchSubmit()
     */
    handleKeyPress = (event: InputEvent) =>
        event && event.key === 'Enter' && this.handleDashboardDataQuery();

    getSelectedCircuit = (): ?ImmutableCircuit =>
        this.getSelectedCircuitTypeQuery()
            .get('data')
            .find(
                (circuit: ImmutableCircuit) => circuit.get('id') === this.state.selectedCircuitId
            );

    getSelectedCircuitTypeQuery = () => {
        switch (this.props.circuitType) {
            default:
            case CIRCUIT_TYPES.MINCHEM_CIRCUIT:
                return this.props.minChemCircuitQuery;
            case CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT:
                return this.props.solvExtractDashboardPlantsQuery;
        }
    };

    onCircuitTypeSelection = (circuitType: CircuitTypes) => () => {
        if (circuitType === CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT) {
            this.props.history.push(`${NAVIGATION_ROUTES.SOLVEXTRACT_DASHBOARD}`);
        } else {
            this.props.history.push(`${NAVIGATION_ROUTES.MINCHEM_DASHBOARD}`);
        }
    };

    goToCyanex = () => {
        this.props.history.push(NAVIGATION_ROUTES.CYANEX);
    };

    /**
     * Renders the modals
     */
    renderModals = () => {
        if (!this.state.openedModal) return null;
        switch (this.state.openedModal) {
            case 'SELECT_DATASET':
            case 'DELETE_DATASET': {
                return (
                    <React.Fragment>
                        <DatasetSelectModal
                            circuit={this.getSelectedCircuit()}
                            errors={this.props.errors}
                            loading={this.props.isCreating}
                            onOpenDatasetModal={this.handleOpenDatasetModal} // opens the delete modal.
                            onConfirm={this.handleSelectConfirm}
                            onCancel={this.handleClose}
                            hidden={this.state.openedModal === 'DELETE_DATASET'}
                        />
                        {this.state.openedModal === 'DELETE_DATASET' && (
                            <ConfirmationModal
                                title={this.props.intl.formatMessage({
                                    id: 'components.DatasetModals.DeleteModal.title',
                                })}
                                confirmButtonText={this.props.intl.formatMessage({
                                    id: 'components.DatasetModals.DeleteModal.confirmButton',
                                })}
                                areYouSureStart={this.props.intl.formatMessage({
                                    id: 'components.DatasetModals.DeleteModal.areYouSure',
                                })}
                                errors={this.props.datasetErrors}
                                loading={this.props.isDeletingDataset}
                                onConfirm={this.handleDeleteDatasetConfirm}
                                onCancel={this.handleClose}
                                hidden={this.state.openedModal === 'SELECT_DATASET'}
                                danger
                            />
                        )}
                    </React.Fragment>
                );
            }
            case 'CREATE_CIRCUIT': {
                return (
                    <CircuitDetailsModal
                        title={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.CreateModal.title',
                        })}
                        confirmButtonText={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.CreateModal.confirmButton',
                        })}
                        errors={this.props.errors}
                        loading={this.props.isCreating}
                        onConfirm={this.handleCreateCircuitConfirm}
                        onCancel={this.handleClose}
                    />
                );
            }
            case 'UPDATE_CIRCUIT': {
                return (
                    <CircuitDetailsModal
                        title={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.UpdateModal.title',
                        })}
                        confirmButtonText={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.UpdateModal.confirmButton',
                        })}
                        errors={this.props.errors}
                        loading={this.props.isUpdating}
                        circuit={this.getSelectedCircuit()}
                        onConfirm={this.handleUpdateConfirm}
                        onCancel={this.handleClose}
                    />
                );
            }
            case 'DELETE_CIRCUIT': {
                return (
                    <ConfirmationModal
                        title={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.DeleteModal.title',
                        })}
                        confirmButtonText={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.DeleteModal.confirmButton',
                        })}
                        areYouSureStart={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.DeleteModal.areYouSure.start',
                        })}
                        areYouSureDanger={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.DeleteModal.areYouSure.danger',
                        })}
                        areYouSureEnd={this.props.intl.formatMessage({
                            id: 'components.CircuitModals.DeleteModal.areYouSure.end',
                        })}
                        errors={this.props.errors}
                        loading={this.props.isDeletingCircuit}
                        onConfirm={this.handleDeleteCircuitConfirm}
                        onCancel={this.handleClose}
                        danger
                    />
                );
            }
            default:
                throw new Error('Unknown modal type in Dashboard');
        }
    };

    renderMainContent = () => {
        switch (this.props.circuitType) {
            default:
            case CIRCUIT_TYPES.MINCHEM_CIRCUIT:
                return (
                    <MinChemDashboard
                        circuits={this.props.minChemCircuitQuery.get('data')}
                        page={this.props.minChemCircuitQuery.get('currentPage')}
                        lastPage={this.props.minChemCircuitQuery.get('lastPage')}
                        userLocale={getUsersLanguage(this.props.user)}
                        isFetchingCircuits={this.props.isQueryingMinChem}
                        onHandleCircuitsSortBy={this.handleDataSortBy}
                        onOpenModal={this.handleOpenCircuitModal}
                        onHandleCircuitChangePage={this.handleDashboardDataChangePage}
                    />
                );
            case CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT:
                return (
                    isSolvExtractUser(this.props.user) && (
                        <SolvExtractDashboard
                            plants={this.props.solvExtractDashboardPlantsQuery.get('data')}
                            page={this.props.solvExtractDashboardPlantsQuery.get('currentPage')}
                            lastPage={this.props.solvExtractDashboardPlantsQuery.get('lastPage')}
                            isFetchingPlants={this.props.isQueryingDashboardPlants}
                            onHandlePlantsChangePage={this.handleDashboardDataChangePage}
                            onHandlePlantsSortBy={this.handleDataSortBy}
                            user={this.props.user}
                            isAdmin={isSysAdmin(this.props.user)}
                            onSearchClear={this.handleSearchClear}
                            onSearchSubmit={this.handleSearchSubmit}
                            onHandleSearchOnChange={this.handleSearchOnChange}
                            onHandleKeyPress={this.handleKeyPress}
                            searchValue={this.state.currentFetch.search}
                            withSearch={this.props.solvExtractDashboardPlantsQuery.get(
                                'withSearch'
                            )}
                        />
                    )
                );
        }
    };

    renderSidebar = (): Array<ReactNode> => {
        const showMinchem = true;
        const showSolvExtract = isSolvExtractUser(this.props.user);
        const showCyanex = canAccessCyanex(this.props.user);

        const translateCircuitType = (type: string) => this.props.intl.formatMessage({
            id: `containers.DashboardContainer.circuitTypes.${type}`,
        });

        return (
            <>
                {showMinchem && (
                    <SidebarTab
                        active={CIRCUIT_TYPES.MINCHEM_CIRCUIT === this.props.circuitType}
                        handleOnHeaderClick={this.onCircuitTypeSelection(CIRCUIT_TYPES.MINCHEM_CIRCUIT)}
                        title={translateCircuitType(CIRCUIT_TYPES.MINCHEM_CIRCUIT)}
                    />
                )}
                {showSolvExtract && (
                    <SidebarTab
                        active={CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT === this.props.circuitType}
                        handleOnHeaderClick={this.onCircuitTypeSelection(CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT)}
                        title={translateCircuitType(CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT)}
                    />
                )}
                {showCyanex && (
                    <SidebarTab
                        handleOnHeaderClick={this.goToCyanex}
                        title={this.props.intl.formatMessage({
                            id: `containers.DashboardContainer.CyanexSidebarLink`,
                        })}
                    />
                )}
            </>
        );
    };

    render() {
        return (
            <React.Fragment>
                <SidebarLayout
                    ref={this.sidebarRef}
                    styles={{
                        sidebar: {
                            height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        },
                        main: {
                            height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                            overflowY: 'hidden',
                        },
                    }}
                    renderMain={this.renderMainContent}
                    renderSidebar={this.renderSidebar}
                    sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                    responsiveMaxDeviceWidth={STYLE_VALUES.RESPONSIVE_AT_LARGE}
                    responsiveTitle={this.props.intl.formatMessage({
                        id: 'containers.DashboardContainer.mobileSidebarTitle',
                    })}
                    responsive
                    collapsible
                    flush
                    mainFlush
                />
                {this.renderModals()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectCircuitErrors(),
    datasetErrors: selectDatasetErrors(),
    user: selectUser(),
    minChemCircuitQuery: selectMinChemCircuitQuery(),
    solvExtractDashboardPlantsQuery: selectSolvExtractDashboardPlants(),
    isQueryingMinChem: selectMinChemIsQueryingStatus(),
    isQueryingDashboardPlants: selectQueryingDashboardPlantsStatus(),
    isCreating: selectCircuitsAreCreating(),
    isUpdating: selectCircuitsAreUpdating(),
    isDeletingCircuit: selectCircuitsAreDeleting(),
    isDeletingDataset: selectDatasetsAreDeleting(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            queryCircuits,
            createCircuit,
            createAdvancedCircuit,
            updateCircuit,
            destroyCircuit,
            destroyDataset,
            queryDashboardPlants,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DashboardContainer))
);

DashboardContainer.defaultProps = {
    mobileMaxPx: parseInt(STYLE_VALUES.RESPONSIVE_AT_LARGE, 10),
};
