// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import { NAVBAR_DASHBOARD_V2_REDIRECT } from 'env';

// Components
import { SecondaryButton, Caret, RadioButtonSet, ButtonWrapper } from 'components/_ReactUI_V1';
import QuickNavigationSearchBar from './QuickNavigationSearchBar';

// Services
import { selectSolvExtractIsQueryingStatus } from 'services/Circuit/selectors';
import { selectPlantsAreFetching } from 'services/Plant/selectors';
import { selectAllClients } from 'services/Client/selectors';

// Constants
import { TRENDS_PAGE_TYPE, ROUTE_NAMES, NAVIGATION_ROUTES } from 'utils/constants';
import { tryParseNumberOrNull } from 'utils/helpers';

// Styles
import {
    Wrapper,
    HeaderWrapper,
    CircuitDetails,
    CircuitCaret,
    CircuitName,
    CircuitSub,
    MenuToolTip,
    MenuToolTipContent,
    ToolTipSection,
    ToolTipSectionTitle,
    MenuToolTipFooter,
    ScrollableField,
    SubRadioButtonSection,
} from './styles';

// Types
import type {
    IntlType,
    ImmutableList,
    InputEvent,
    RouteType,
    HistoryType,
    ReduxDispatch,
} from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableClient } from 'services/Client/types';
import type { TrendsPageType } from 'services/Trends/types';

const PAGE_TYPES = {
    ARCHIVE: 'ARCHIVE',
    TRENDS: 'TRENDS',
    PLANT_DASHBOARD: 'PLANT_DASHBOARD',
    PLANT_REPORTS: 'PLANT_REPORTS',
};
type PageType = $Keys<typeof PAGE_TYPES>;

type Props = {
    // Must be passed:
    route: RouteType,
    currentLocation: string,

    // Injected:
    intl: IntlType,
    history: HistoryType,
    clients: ImmutableList<ImmutableClient>,
    isLoadingCircuit: boolean,
    isLoadingPlant: boolean,
};

type State = {
    isOpen: boolean,
    selectedCircuitId: ?number,
    selectedPlantId: ?number,

    /**
     * input of the search
     */
    searchTerm: string,

    /**
     * list of clients after search to be displayed.
     */
    clientsToDisplay: ImmutableList<ImmutableClient>,
};

class SolvExtractQuickNavigator extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: false,
            selectedCircuitId: this.getCircuitId(),
            selectedPlantId: this.getPlantId(),
            searchTerm: '',
            clientsToDisplay: this.props.clients,
        };
    }

    componentDidMount() {
        // $FlowIgnore
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * When the dataset in the props changes, change the default preselected dataset, probably due to dataset being loaded
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.route !== this.props.route) {
            this.setState({
                selectedPlantId: this.getPlantId(),
                selectedCircuitId: this.getCircuitId(),
            });
        }

        if (prevProps.clients !== this.props.clients) {
            this.setState({ clientsToDisplay: this.props.clients });
        }
    }

    componentWillUnmount() {
        // $FlowIgnore
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    menuRef = React.createRef();

    /**
     * React reference to the top of the client list
     */
    clientListTopRef = React.createRef();

    circuitRadioButtonRefs = {};

    plantRadioButtonRefs = {};

    isLoading = () => this.props.isLoadingCircuit || this.props.isLoadingPlant;

    getParams = () => {
        const path = this.props.route.path;
        const match = matchPath(this.props.currentLocation, {
            path: this.props.route.path,
            exact: true,
            strict: false,
        });
        if (!match) {
            return null;
        }
        return match.params;
    };

    getPlantId = () => {
        const params = this.getParams();
        if (!params || !params.plantId) {
            return null;
        }
        const plantId = tryParseNumberOrNull(params.plantId);
        return plantId;
    };

    getCircuitId = () => {
        const params = this.getParams();
        if (!params || !params.circuitId) {
            return null;
        }
        const circuitId = tryParseNumberOrNull(params.circuitId);
        return circuitId;
    };

    getCurrentPageType = () => {
        const routeName = this.props.route.name;
        const dashboardPages = [ROUTE_NAMES.PLANT_DASHBOARD];
        const trendPages = [ROUTE_NAMES.CIRCUIT_TRENDS, ROUTE_NAMES.PLANT_TRENDS];
        const archivePages = [ROUTE_NAMES.CIRCUIT_ARCHIVE, ROUTE_NAMES.PLANT_ARCHIVE];
        const reportPages = [ROUTE_NAMES.PLANT_REPORTS];
        if (trendPages.includes(routeName)) {
            return PAGE_TYPES.TRENDS;
        } else if (archivePages.includes(routeName)) {
            return PAGE_TYPES.ARCHIVE;
        } else if (dashboardPages.includes(routeName)) {
            return PAGE_TYPES.PLANT_DASHBOARD;
        } else if (reportPages.includes(routeName)) {
            return PAGE_TYPES.PLANT_REPORTS;
        } else {
            return null;
        }
    };

    circuitToRadioOption = (circuit: ImmutableCircuit) => {
        const circuitRadioButtonRef = React.createRef();
        this.circuitRadioButtonRefs[circuit.get('id')] = circuitRadioButtonRef;

        return {
            label: circuit.get('name'),
            value: circuit.get('id'),
            disabled: false,
            radioButtonRef: circuitRadioButtonRef,
        };
    };

    plantToRadioOption = (plant: ImmutablePlant) => {
        const plantRadioButtonRef = React.createRef();
        this.plantRadioButtonRefs[plant.get('id')] = plantRadioButtonRef;

        return {
            label: plant.get('name').toUpperCase(),
            value: plant.get('id'),
            disabled: false,
            radioButtonRef: plantRadioButtonRef,
        };
    };

    handleScrollToRadioButton = () => {
        const circuitId = this.getCircuitId();
        const plantId = this.getPlantId();
        if (plantId && plantId !== null) {
            if (this.plantRadioButtonRefs[plantId].current !== null) {
                this.plantRadioButtonRefs[plantId].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        } else if (circuitId && circuitId !== null) {
            this.circuitRadioButtonRefs[circuitId].current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    getCircuitOptions = (plant: ImmutablePlant) =>
        plant
            .get('circuits')
            .map(this.circuitToRadioOption)
            .toArray();

    getPlantOptions = (client: ImmutableClient) =>
        client
            .get('plants')
            .map(this.plantToRadioOption)
            .toArray();

    getButtonEntityType = (): TrendsPageType => {
        const entityType = this.state.selectedCircuitId
            ? TRENDS_PAGE_TYPE.CIRCUIT
            : TRENDS_PAGE_TYPE.PLANT;
        return entityType;
    };

    getPageEntityType = (): TrendsPageType => {
        const entityType = this.getCircuitId() ? TRENDS_PAGE_TYPE.CIRCUIT : TRENDS_PAGE_TYPE.PLANT;
        return entityType;
    };

    getViewTrendButtonText = () => {
        const entityType = this.getButtonEntityType();
        return this.props.intl.formatMessage({
            id: `components.Header.SolvExtractQuickNavigator.TRENDS.${entityType}.viewButtonText`,
        });
    };

    getViewArchiveButtonText = () => {
        const entityType = this.getButtonEntityType();
        return this.props.intl.formatMessage({
            id: `components.Header.SolvExtractQuickNavigator.ARCHIVE.${entityType}.viewButtonText`,
        });
    };

    getViewDashboardButtonText = () => {
        return this.props.intl.formatMessage({
            id: `components.Header.SolvExtractQuickNavigator.DASHBOARD.viewButtonText`,
        });
    };

    getViewReportsButtonText = () => {
        return this.props.intl.formatMessage({
            id: `components.Header.SolvExtractQuickNavigator.REPORTS.viewButtonText`,
        });
    };

    getViewButtonIsDisabled = (pageType: PageType) => {
        const currentPageType = this.getCurrentPageType();
        if (
            currentPageType === pageType &&
            this.state.selectedCircuitId &&
            this.getCircuitId() === this.state.selectedCircuitId
        ) {
            return true;
        }
        if (
            currentPageType === pageType &&
            this.state.selectedPlantId &&
            !this.state.selectedCircuitId &&
            this.getPlantId() === this.state.selectedPlantId
        ) {
            return true;
        }
        return false; // not disabled.
    };

    navigateTo = (entities, pageType) => {
        /**
         * Construction of the url
         * URLs can be simple and have one entity such as /circuit/2404 but you can also give multiple entities /plant/32/circuit/2404
         * Page type can be used for trend, archive or nothing like in dashboard page
         */
        const url =
            entities.map(({ id, entityType }) => `${NAVIGATION_ROUTES[entityType]}${id}`).join('') +
            (pageType ? NAVIGATION_ROUTES[pageType] : '');

        this.props.history.push(url);
    };

    handleClickOutside = (event: InputEvent) => {
        if (
            this.state.isOpen &&
            this.menuRef &&
            this.menuRef.current &&
            !this.menuRef.current.contains(event.target)
        ) {
            this.handleMenuToggle();
        }
    };

    /**
     * When the selected circuit changes
     */
    handleCircuitChange = (selectedCircuitId: number) => {
        // Find the plant associated with the selected circuit
        let associatedPlantId = null;

        this.props.clients.some((client: ImmutableClient) => {
            return client.get('plants').some((plant: ImmutablePlant) => {
                if (plant.has('circuits')) {
                    const circuit = plant
                        .get('circuits')
                        .find((c: ImmutableCircuit) => c.get('id') === selectedCircuitId);

                    if (circuit) {
                        associatedPlantId = plant.get('id');
                        return true;
                    }
                }
                return false;
            });
        });

        this.setState({
            selectedCircuitId,
            selectedPlantId: associatedPlantId,
        });
    };

    /**
     * When the selected plant changes
     */
    handlePlantChange = (selectedPlantId: number) =>
        this.setState({
            selectedPlantId,
            selectedCircuitId: null,
        });

    /**
     * Toggle the menu.
     */
    handleMenuToggle = () => {
        this.setState(
            (prevState: State) => ({
                isOpen: !prevState.isOpen,
                selectedCircuitId: this.getCircuitId(),
                selectedPlantId: this.getPlantId(),
            }),
            () => {
                if (this.state.isOpen) {
                    this.handleScrollToRadioButton();
                }
            }
        );
    };

    handleViewDashboardButtonClick = () => {
        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (this.state.selectedPlantId) {
                    this.navigateTo(
                        [{ id: this.state.selectedPlantId, entityType: TRENDS_PAGE_TYPE.PLANT }],
                        PAGE_TYPES.PLANT_DASHBOARD
                    );
                }
            }
        );
    };

    handleViewReportsButtonClick = () => {
        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (this.state.selectedPlantId) {
                    this.navigateTo(
                        [{ id: this.state.selectedPlantId, entityType: TRENDS_PAGE_TYPE.PLANT }],
                        PAGE_TYPES.PLANT_REPORTS
                    );
                }
            }
        );
    };

    handleViewTrendButtonClick = () => {
        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (this.state.selectedCircuitId) {
                    if (NAVBAR_DASHBOARD_V2_REDIRECT) {
                        this.navigateTo([
                            {
                                id: this.state.selectedPlantId,
                                entityType: TRENDS_PAGE_TYPE.PLANT,
                            },
                            {
                                id: this.state.selectedCircuitId,
                                entityType: TRENDS_PAGE_TYPE.CIRCUIT,
                            },
                        ]);

                        return;
                    } else {
                        // TODO: DGM-3646 Remove legacy navigation
                        this.navigateTo(
                            [
                                {
                                    id: this.state.selectedCircuitId,
                                    entityType: TRENDS_PAGE_TYPE.CIRCUIT,
                                },
                            ],
                            PAGE_TYPES.TRENDS
                        );
                        return;
                    }
                }
                if (this.state.selectedPlantId) {
                    this.navigateTo(
                        [{ id: this.state.selectedPlantId, entityType: TRENDS_PAGE_TYPE.PLANT }],
                        PAGE_TYPES.TRENDS
                    );
                    return;
                }
            }
        );
    };

    handleViewArchiveButtonClick = () => {
        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (this.state.selectedCircuitId) {
                    this.navigateTo(
                        [
                            {
                                id: this.state.selectedCircuitId,
                                entityType: TRENDS_PAGE_TYPE.CIRCUIT,
                            },
                        ],
                        PAGE_TYPES.ARCHIVE
                    );
                    return;
                }
                if (this.state.selectedPlantId) {
                    this.navigateTo(
                        [
                            {
                                id: this.state.selectedPlantId,
                                entityType: TRENDS_PAGE_TYPE.PLANT,
                            },
                        ],
                        PAGE_TYPES.ARCHIVE
                    );
                }
            }
        );
    };

    getCircuitPageNames = () => {
        if (!this.getCircuitId()) {
            return null;
        }

        let primaryName = null;
        let secondaryName = null;

        this.props.clients.some((client: ImmutableClient) => {
            // Check to see if client has plants and if there are any... If not, do not display
            if (!this.shouldRenderClient(client)) {
                return false;
            }

            return client.get('plants').some((plant: ImmutablePlant) => {
                // Check to see if plant has circuits and if there are any... If not, do not display
                if (!plant.has('circuits') || plant.get('circuits').isEmpty()) {
                    return false;
                }

                const circuit = plant
                    .get('circuits')
                    .find((c: ImmutableCircuit) => c.get('id') === this.getCircuitId());
                if (circuit) {
                    primaryName = circuit.get('name');
                    secondaryName = plant.get('name');
                    return true;
                }
                return false;
            });
        });

        return {
            primaryName,
            secondaryName,
        };
    };

    getPlantPageNames = () => {
        if (!this.getPlantId()) {
            return null;
        }

        let primaryName = null;
        let secondaryName = null;
        this.props.clients.some((client: ImmutableClient) => {
            const plant =
                client.get('plants') &&
                client.get('plants').find((p: ImmutablePlant) => p.get('id') === this.getPlantId());
            if (plant) {
                primaryName = plant.get('name');
                secondaryName = client.get('name');
                return true;
            }
            return false;
        });

        return {
            primaryName,
            secondaryName,
        };
    };

    /**
     * Allow a circuit to render if it has plants
     */
    shouldRenderClient = (client: ImmutableClient): boolean => {
        if (!client.has('plants')) {
            return false;
        }

        if (client.has('plants') && client.get('plants').isEmpty()) {
            return false;
        }

        return true;
    };

    /**
     * We should only render the plants' circuits if we are not in the RGS
     */
    shouldRenderPlantCircuits = () => this.getCurrentPageType() !== PAGE_TYPES.PLANT_REPORTS;

    renderPlantCircuits = (client: ImmutableClient) => (plantId: number) => (
        <SubRadioButtonSection>
            <RadioButtonSet
                value={this.state.selectedCircuitId || null}
                options={this.getCircuitOptions(
                    client
                        .get('plants')
                        .find((plant: ImmutablePlant) => plant.get('id') === plantId)
                )}
                onClick={this.handleCircuitChange}
            />
        </SubRadioButtonSection>
    );

    renderClient = (client: ImmutableClient) =>
        this.shouldRenderClient(client) && (
            <ToolTipSection key={client.get('id')}>
                <ToolTipSectionTitle>{client.get('name').toUpperCase()}</ToolTipSectionTitle>
                <RadioButtonSet
                    value={this.state.selectedPlantId || null}
                    options={this.getPlantOptions(client)}
                    onClick={this.handlePlantChange}
                    renderSuffix={
                        this.shouldRenderPlantCircuits()
                            ? this.renderPlantCircuits(client)
                            : undefined
                    }
                />
            </ToolTipSection>
        );

    renderViewButtons = () => {
        if (this.getCurrentPageType() === PAGE_TYPES.PLANT_REPORTS) {
            return (
                <ButtonWrapper gutter="12px">
                    <SecondaryButton
                        text={this.getViewReportsButtonText()}
                        onClick={this.handleViewReportsButtonClick}
                        disabled={
                            this.isLoading() ||
                            this.getViewButtonIsDisabled(PAGE_TYPES.PLANT_REPORTS)
                        }
                    />
                </ButtonWrapper>
            );
        } else {
            return (
                <ButtonWrapper gutter="12px">
                    <SecondaryButton
                        text={this.getViewTrendButtonText()}
                        onClick={this.handleViewTrendButtonClick}
                        disabled={
                            this.isLoading() || this.getViewButtonIsDisabled(PAGE_TYPES.TRENDS)
                        }
                    />
                    <SecondaryButton
                        text={this.getViewArchiveButtonText()}
                        onClick={this.handleViewArchiveButtonClick}
                        disabled={
                            this.isLoading() || this.getViewButtonIsDisabled(PAGE_TYPES.ARCHIVE)
                        }
                    />
                </ButtonWrapper>
            );
        }
    };

    /**
     * Renders the circuit menu that opens/closes
     */
    renderMenuTooltip = () => (
        <MenuToolTip>
            <MenuToolTipContent>
                <QuickNavigationSearchBar
                    clients={this.props.clients}
                    searchTerm={this.state.searchTerm}
                    onSearch={(searchState) => {
                        this.setState({
                            searchTerm: searchState.searchTerm,
                            clientsToDisplay: searchState.searchResult,
                        });
                        this.clientListTopRef.current.scrollIntoView({
                            behavior: 'instant',
                            block: 'start',
                        });
                    }}
                />
                <ScrollableField>
                    <div ref={this.clientListTopRef} />
                    {this.state.clientsToDisplay.map((client: ImmutableClient) =>
                        this.renderClient(client)
                    )}
                </ScrollableField>
                <MenuToolTipFooter>{this.renderViewButtons()}</MenuToolTipFooter>
            </MenuToolTipContent>
        </MenuToolTip>
    );

    render() {
        if (this.isLoading() || this.props.clients.isEmpty()) {
            return null;
        }

        let names = null;

        const entityType = this.getPageEntityType();
        if (entityType === TRENDS_PAGE_TYPE.CIRCUIT) {
            names = this.getCircuitPageNames();
        } else if (entityType === TRENDS_PAGE_TYPE.PLANT) {
            names = this.getPlantPageNames();
        }
        if (!names) {
            return null;
        }

        return (
            <Wrapper ref={this.menuRef} data-testid="quick-navigator">
                <HeaderWrapper onClick={this.handleMenuToggle}>
                    <CircuitDetails>
                        <CircuitName>{names.primaryName}</CircuitName>
                        <CircuitSub>{names.secondaryName}</CircuitSub>
                    </CircuitDetails>
                    <CircuitCaret>
                        <Caret up={!this.state.isOpen} down={this.state.isOpen} black />
                    </CircuitCaret>
                </HeaderWrapper>
                {this.state.isOpen && this.renderMenuTooltip()}
            </Wrapper>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isLoadingCircuit: selectSolvExtractIsQueryingStatus(),
    isLoadingPlant: selectPlantsAreFetching(),
    clients: selectAllClients(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(SolvExtractQuickNavigator))
);
