// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { Isotherm, RawIsotherm } from './types';

export const CREATE_SUCCESS = 'app/services/Isotherm/CREATE_SUCCESS';
export const CREATE_MANY_SUCCESS = 'app/services/Isotherm/CREATE_MANY_SUCCESS';
export const CREATE_FAILURE = 'app/services/Isotherm/CREATE_FAILURE';
export const CREATE_MANY_FAILURE = 'app/services/Isotherm/CREATE_MANY_FAILURE';
export const FETCH_LIST_SUCCESS = 'app/services/Isotherm/FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'app/services/Isotherm/FETCH_LIST_FAILURE';
export const UPDATE_SUCCESS = 'app/services/Isotherm/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Isotherm/UPDATE_FAILURE';
export const DESTROY_SUCCESS = 'app/services/Isotherm/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'app/services/Isotherm/DESTROY_FAILURE';
export const VISUALIZE_SUCCESS = 'app/services/Isotherm/VISUALIZE_SUCCESS';
export const VISUALIZE_FAILURE = 'app/services/Isotherm/VISUALIZE_FAILURE';
export const DEVISUALIZE = 'app/services/Isotherm/DEVISUALIZE';
export const IS_FETCHING = 'app/services/Isotherm/IS_FETCHING';
export const IS_UPDATING = 'app/services/Isotherm/IS_UPDATING';
export const IS_DELETING = 'app/services/Isotherm/IS_DELETING';
export const IS_CREATING = 'app/services/Isotherm/IS_CREATING';
export const IS_VISUALIZING = 'app/services/Isotherm/IS_VISUALIZING';

export const receivedCreateSuccess = (data: Isotherm) => ({
    type: CREATE_SUCCESS,
    payload: { data },
});

export const receivedCreateFailure = (errors: {}) => ({
    type: CREATE_FAILURE,
    payload: { errors },
});

export const receivedCreateManySuccess = (data: Array<Isotherm>) => ({
    type: CREATE_MANY_SUCCESS,
    payload: { data },
});

export const receivedCreateManyFailure = (errors: {}) => ({
    type: CREATE_MANY_FAILURE,
    payload: { errors },
});

export const receivedFetchListSuccess = (data: Array<Isotherm>) => ({
    type: FETCH_LIST_SUCCESS,
    payload: { data },
});

export const receivedFetchListFailure = (errors: {}) => ({
    type: FETCH_LIST_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: Isotherm) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const receivedDestroySuccess = (data: Isotherm) => ({
    type: DESTROY_SUCCESS,
    payload: { data },
});

export const receivedDestroyFailure = (errors: {}) => ({
    type: DESTROY_FAILURE,
    payload: { errors },
});

export const receivedVisualizeSuccess = (data: RawIsotherm) => ({
    type: VISUALIZE_SUCCESS,
    payload: { data },
});

export const receivedVisualizeFailure = (errors: {}) => ({
    type: VISUALIZE_FAILURE,
    payload: { errors },
});

export const deVisualize = () => ({
    type: DEVISUALIZE,
    payload: null,
});

export const setIsFetchingStatus = (isFetching: boolean = true) => ({
    type: IS_FETCHING,
    payload: { isFetching },
});

export const setIsUpdatingStatus = (isUpdating: boolean = true) => ({
    type: IS_UPDATING,
    payload: { isUpdating },
});

export const setIsDeletingStatus = (isDeleting: boolean = true) => ({
    type: IS_DELETING,
    payload: { isDeleting },
});

export const setIsCreatingStatus = (isCreating: boolean = true) => ({
    type: IS_CREATING,
    payload: { isCreating },
});

export const setIsCreatingManyStatus = (isCreating: boolean = true) => ({
    type: IS_CREATING,
    payload: { isCreating },
});

export const setIsVisualizingStatus = (isVisualizing: boolean = true) => ({
    type: IS_VISUALIZING,
    payload: { isVisualizing },
});
