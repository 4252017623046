// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Constants
import { CIRCUIT_TYPES } from 'utils/constants';

// Components
import DashboardPage from '../DashboardPage';

// Types
import type { IntlType, CircuitTypes } from 'types';

type Props = {
    intl: IntlType,
    circuitType?: ?CircuitTypes,
};

export class SolvExtractDashboardPage extends React.PureComponent<Props, null> {
    render() {
        return <DashboardPage circuitType={CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT} />;
    }
}

export default injectIntl(SolvExtractDashboardPage);
