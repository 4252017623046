// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import ReagentManager from 'components/ReagentManager';

// Selectors
import {
    selectQueriedReagents,
    selectQueriedReagentsLastPage,
    selectReagentErrors,
    selectReagentsAreDeleting,
    selectReagentsAreQuerying,
    selectReagentsAreUpdating,
} from 'services/Reagent/selectors';
import { selectAllOximes } from 'services/Oxime/selectors';

// Thunks
import {
    createReagent,
    destroyReagent,
    fetchAllReagents,
    queryAllReagents,
    updateReagent,
} from 'services/Reagent/thunks';

// Types
import type { ErrorType, ImmutableList, ReduxDispatch, SearchCriteria } from 'types';
import type { ImmutableReagent, Reagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';

type Props = {
    errors: ErrorType,

    oximes: ImmutableList<ImmutableOxime>,
    queriedReagents: ImmutableList<ImmutableReagent>,
    reagentIsDeleting: boolean,
    reagentIsUpdating: boolean,
    reagentsAreQuerying: boolean,
    lastPage: number,

    destroyReagent: (number) => void,
    fetchAllReagents: () => void,
    queryAllReagents: (SearchCriteria, number) => void,
    updateReagent: (number, Reagent) => void,
    createReagent: (Reagent) => void,
};

type State = {
    pagination: {
        page: number,
    },
    searchCriteria: SearchCriteria,
};

// Constants
const DEFAULT_PAGE = 1;
const DEFAULT_SORTBY = 'id';
const DEFAULT_SORTORDER = 'desc';

class ReagentManagementContainer extends React.PureComponent<Props, State> {
    state = {
        pagination: {
            page: DEFAULT_PAGE,
        },
        searchCriteria: {
            sortBy: DEFAULT_SORTBY,
            sortOrder: DEFAULT_SORTORDER,
        },
    };

    componentDidMount() {
        if (this.props.queriedReagents.isEmpty()) {
            this.props.queryAllReagents(this.state.searchCriteria, this.state.pagination.page);
        }

        if (this.props.oximes.isEmpty()) {
            this.props.fetchAllReagents();
        }
    }

    /**
     * On pagination change, update state with provided page
     * Fire props.queryAllReagents() with updated searchCriteria and page
     */
    handlePaginationPageSelection = (page: number, total: number) => () => {
        if (page > total || page < 0) {
            return null;
        }

        return this.setState(
            {
                pagination: {
                    page,
                },
            },
            () => this.onQueryAllReagents()
        );
    };

    /**
     * On table sorting, set provided sortBy & sortOrder to state.searchCriteria
     * Reset state.pagination.page to default
     * Fire props.queryAllReagents() with updated searchCriteria and page
     */
    handleSortBy = (searchCriteria: SearchCriteria) => {
        this.setState(
            {
                searchCriteria,
                pagination: {
                    page: DEFAULT_PAGE,
                },
            },
            () => this.onQueryAllReagents()
        );
    };

    onCreateReagent = (reagent: ImmutableReagent) => {
        this.props.createReagent(reagent.toJS());
        this.onQueryAllReagents(true);
    };

    /**
     * Fires props.queryAllReagents
     * If provided reset boolean is true, reset searchCriteria to defaults first.
     */
    onQueryAllReagents = (reset: boolean = false) => {
        if (reset) {
            this.setState(
                {
                    searchCriteria: {
                        sortBy: DEFAULT_SORTBY,
                        sortOrder: DEFAULT_SORTORDER,
                    },
                    pagination: {
                        page: DEFAULT_PAGE,
                    },
                },
                () =>
                    this.props.queryAllReagents(
                        this.state.searchCriteria,
                        this.state.pagination.page
                    )
            );
        } else {
            return this.props.queryAllReagents(
                this.state.searchCriteria,
                this.state.pagination.page
            );
        }
    };

    render() {
        return (
            <ReagentManager
                errors={this.props.errors}
                handleCreateNewReagent={this.onCreateReagent}
                handleDeleteReagent={this.props.destroyReagent}
                handleUpdateReagent={this.props.updateReagent}
                lastPage={this.props.lastPage}
                onHandlePaginationPageSelection={this.handlePaginationPageSelection}
                onHandleSortBy={this.handleSortBy}
                oximes={this.props.oximes}
                pagination={this.state.pagination}
                reagentIsDeleting={this.props.reagentIsDeleting}
                reagentIsUpdating={this.props.reagentIsUpdating}
                reagents={this.props.queriedReagents}
                reagentsAreQuerying={this.props.reagentsAreQuerying}
                searchCriteria={this.state.searchCriteria}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectReagentErrors(),
    lastPage: selectQueriedReagentsLastPage(),
    oximes: selectAllOximes(),
    queriedReagents: selectQueriedReagents(),
    reagentIsDeleting: selectReagentsAreDeleting(),
    reagentIsUpdating: selectReagentsAreUpdating(),
    reagentsAreQuerying: selectReagentsAreQuerying(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createReagent,
            destroyReagent,
            fetchAllReagents,
            queryAllReagents,
            updateReagent,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReagentManagementContainer)
);
