// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;

    width: 100%;
    min-width: calc(${STYLE_VALUES.SCREEN.MIN_WIDTH} - ${STYLE_VALUES.SIDEBAR.WIDTH});

    padding: 8px 24px;

    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;

    & > * + * {
        margin-left: 8px;
    }
`;
