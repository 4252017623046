// @flow

import React, { type Node } from 'react';

// Styles
import {
    StyledSidebarTab,
    SidebarTabHeader,
    SidebarTabTitle,
    StyledSidebarTabBody,
} from './styles';

type Props = {
    title: string,
    body: Node,
    active: boolean,
    handleOnHeaderClick: () => void,
};

export default class SidebarTab extends React.PureComponent<Props> {
    render() {
        return (
            <StyledSidebarTab>
                <SidebarTabHeader
                    onClick={this.props.handleOnHeaderClick}
                    active={this.props.active}
                >
                    <SidebarTabTitle>{this.props.title}</SidebarTabTitle>
                </SidebarTabHeader>
                {this.props.active && this.props.body && (
                    <StyledSidebarTabBody>{this.props.body}</StyledSidebarTabBody>
                )}
            </StyledSidebarTab>
        );
    }
}
