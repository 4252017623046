// @flow

import React from 'react';
import legacyTheme from '../../themes/legacyTheme';

type TrendArrowIconType = {
    height?: ?number,
    width?: ?number,
    fill?: ?string,
};

export function TrendArrowIcon(props: TrendArrowIconType) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.66671 0L11.1934 1.52667L7.94004 4.78L5.27337 2.11333L0.333374 7.06L1.27337 8L5.27337 4L7.94004 6.66667L12.14 2.47333L13.6667 4V0H9.66671Z"
                fill={props.fill}
            />
        </svg>
    );
}

TrendArrowIcon.defaultProps = {
    height: 12,
    width: 18,
    fill: legacyTheme.white,
};
