// @flow strict

import request from 'services/request';

import type { Isotherm, RawIsotherm, ImmutableIsothermShareAccess } from 'services/Isotherm/types';

/**
 * Create isotherm
 *
 * @param {RawIsotherm} isotherm
 */
export const create = (isotherm: RawIsotherm) =>
    request('isotherms', {
        data: isotherm,
        method: 'POST',
    });

/**
 * Create many isotherms
 *
 * @param { Array<RawIsotherm>} isotherms
 */
export const createMany = (isotherms: Array<RawIsotherm>) =>
    request('isotherms', {
        data: { isotherms },
        method: 'POST',
    });

/**
 * Fetches all isotherms
 */
export const index = () => request('isotherms', { method: 'GET' });

/**
 * Update isotherm
 *
 * @param {number} id
 * @param {Isotherm} isotherm
 */
export const update = (id: number, isotherm: Isotherm) =>
    request(`isotherms/${id}`, {
        data: isotherm,
        method: 'PUT',
    });

/**
 * Destroy isotherm
 *
 * @param {number} id
 */
export const destroy = (id: number) => request(`isotherms/${id}`, { method: 'DELETE' });

/**
 * Visualize isotherm
 *
 * @param {RawIsotherm} isotherm
 */
export const visualize = (isotherm: RawIsotherm) =>
    request('isotherms/visualize/create', {
        data: isotherm,
        method: 'POST',
    });

/**
 * Predict and Visualize isotherm
 *
 * @param {RawIsotherm} isotherm
 */
export const predict = (isotherm: RawIsotherm) =>
    request('isotherms/visualize/predict', {
        data: isotherm,
        method: 'POST',
    });

export const _setIsothermShareAccess = (id: number, shareAccess: ImmutableIsothermShareAccess) =>
    request(`isotherms/${id}/sharing`, {
        data: shareAccess.toJS(),
        method: 'PUT',
    });
