// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, FooterButton } from './styles';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,

    onHideMimicDiagramClicked: () => void,
    onExportToMinchemClicked: () => void,
};

class TrendsMimicDiagramFooter extends React.PureComponent<Props> {
    render() {
        return (
            <Wrapper>
                <FooterButton
                    text={this.props.intl.formatMessage({
                        id: 'components.TrendsMimicDiagramFooter.returnToTrends',
                    })}
                    onClick={this.props.onHideMimicDiagramClicked}
                />
                <FooterButton
                    text={this.props.intl.formatMessage({
                        id: 'components.TrendsMimicDiagramFooter.exportToMinchem',
                    })}
                    onClick={this.props.onExportToMinchemClicked}
                />
            </Wrapper>
        );
    }
}

export default injectIntl(TrendsMimicDiagramFooter);
