// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';

// Constants
import { NAVIGATION_ROUTES } from 'utils/constants';

// Containers
import MimicDiagramContainer from './MimicDiagramContainer';

// Types
import type { HistoryType } from 'types';

type Props = {
    circuitId: number,
    history: HistoryType,
};

/**
 * TODO: MS-531 - Merge CircuitSetupContainer & MimicDiagramContainer
 * This double container was partially removed when we went to a circuit elevate route
 */
class CircuitSetupContainer extends React.PureComponent<Props> {
    /**
     * When the user wants to elevate their circuit to a SolvExtract circuit.
     */
    handleElevateToSolvExtractCircuit = (circuitId: number) =>
        this.props.history.push(
            `${NAVIGATION_ROUTES.CIRCUIT}${circuitId}${NAVIGATION_ROUTES.ELEVATE}`
        );

    render() {
        return (
            <MimicDiagramContainer
                circuitId={this.props.circuitId}
                onElevateToSolvExtractCircuit={this.handleElevateToSolvExtractCircuit}
            />
        );
    }
}

export default withRouter(CircuitSetupContainer);
