// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Body, Label, SelectField, SectionTitle } from '../styles';

// Components
import { SidebarSection, InputSelect } from 'components/_ReactUI_V1';

// Types
import type {
    IntlType,
    ImmutableList,
    ReactSelectObject,
    UnitsConstant,
    ProductionUnitsConstant,
} from 'types';
import type { ImmutableMetal } from 'services/Metal/types';

// Constants
import { UNIT_TYPES, PRODUCTION_UNIT_TYPES } from 'utils/constants';

type Props = {
    intl: IntlType,
    metals: ImmutableList<ImmutableMetal>,
    selectedMetal: ?ImmutableMetal,
    loadingMetals: boolean,
    loadingCircuit: boolean,
    selectedCircuitUnit: ?UnitsConstant,
    selectedProductionUnit: ?ProductionUnitsConstant,

    onSelectMetal: (selectedMetal: ImmutableMetal) => void,
    onSelectCircuitUnit: (circuitUnit: ?UnitsConstant) => void,
    onSelectProductionUnit: (productionUnit: ?ProductionUnitsConstant) => void,
};

class BasicSidebarSection extends React.PureComponent<Props, null> {
    static metalToReactSelectObject = (metal: ImmutableMetal): ReactSelectObject => ({
        value: metal.get('id'),
        label: `${metal.get('name')} (${metal.get('symbol')})`,
    });

    circuitUnitToReactSelectObject = (circuitUnit: UnitsConstant): ReactSelectObject => ({
        value: circuitUnit,
        label: this.props.intl.formatMessage({ id: `constants.Units.${circuitUnit}` }),
    });

    productionUnitToReactSelectObject = (
        productionUnit: ProductionUnitsConstant
    ): ReactSelectObject => ({
        value: productionUnit,
        label: this.props.intl.formatMessage({ id: `constants.ProductionUnits.${productionUnit}` }),
    });

    getMetalOptions = (): Array<ReactSelectObject> =>
        this.props.metals.map(BasicSidebarSection.metalToReactSelectObject).toArray();

    getCircuitUnitsOptions = (): Array<ReactSelectObject> =>
        Object.keys(UNIT_TYPES).map(this.circuitUnitToReactSelectObject);

    getProductionUnitsOptions = (): Array<ReactSelectObject> =>
        Object.keys(PRODUCTION_UNIT_TYPES).map(this.productionUnitToReactSelectObject);

    handleCircuitUnitsSelected = (selectedOption: ReactSelectObject) =>
        this.props.onSelectCircuitUnit(
            Object.keys(UNIT_TYPES).find(
                (circuitUnit: UnitsConstant) => circuitUnit === selectedOption.value
            )
        );

    handleMetalSelected = (selectedOption: ReactSelectObject) =>
        this.props.onSelectMetal(
            this.props.metals.find(
                (metal: ImmutableMetal) => metal.get('id') === selectedOption.value
            )
        );

    handleProductionUnitsSelected = (selectedOption: ReactSelectObject) =>
        this.props.onSelectProductionUnit(
            Object.keys(PRODUCTION_UNIT_TYPES).find(
                (productionUnit: ProductionUnitsConstant) => productionUnit === selectedOption.value
            )
        );

    render() {
        const selectedMetal = this.props.selectedMetal
            ? BasicSidebarSection.metalToReactSelectObject(this.props.selectedMetal)
            : null;
        const selectedCircuitUnits = this.props.selectedCircuitUnit
            ? this.circuitUnitToReactSelectObject(this.props.selectedCircuitUnit)
            : null;
        const selectedProductionUnits = this.props.selectedProductionUnit
            ? this.productionUnitToReactSelectObject(this.props.selectedProductionUnit)
            : null;
        return (
            <SidebarSection>
                <SectionTitle>
                    {this.props.intl.formatMessage({
                        id: 'components.CircuitSetupSidebar.basicSection.title',
                    })}
                </SectionTitle>
                <Body>
                    <SelectField>
                        <Label>
                            {this.props.intl.formatMessage({
                                id: 'components.CircuitSetupSidebar.basicSection.metal.Label',
                            })}
                        </Label>
                        <InputSelect
                            isDisabled={this.props.loadingMetals || this.props.loadingCircuit}
                            isLoading={this.props.loadingMetals || this.props.loadingCircuit}
                            selectedOption={selectedMetal}
                            options={this.getMetalOptions()}
                            onSelect={this.handleMetalSelected}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.CircuitSetupSidebar.basicSection.metal.Placeholder',
                            })}
                            controlShouldRenderValue
                        />
                    </SelectField>
                    <SelectField>
                        <Label>
                            {this.props.intl.formatMessage({
                                id:
                                    'components.CircuitSetupSidebar.basicSection.circuitUnits.Label',
                            })}
                        </Label>
                        <InputSelect
                            isDisabled={this.props.loadingCircuit}
                            isLoading={this.props.loadingCircuit}
                            selectedOption={selectedCircuitUnits}
                            options={this.getCircuitUnitsOptions()}
                            onSelect={this.handleCircuitUnitsSelected}
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'components.CircuitSetupSidebar.basicSection.circuitUnits.Placeholder',
                            })}
                            controlShouldRenderValue
                        />
                    </SelectField>
                    <SelectField>
                        <Label>
                            {this.props.intl.formatMessage({
                                id:
                                    'components.CircuitSetupSidebar.basicSection.productionUnits.Label',
                            })}
                        </Label>
                        <InputSelect
                            isDisabled={this.props.loadingCircuit}
                            isLoading={this.props.loadingCircuit}
                            selectedOption={selectedProductionUnits}
                            options={this.getProductionUnitsOptions()}
                            onSelect={this.handleProductionUnitsSelected}
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'components.CircuitSetupSidebar.basicSection.productionUnits.Placeholder',
                            })}
                            controlShouldRenderValue
                        />
                    </SelectField>
                </Body>
            </SidebarSection>
        );
    }
}

export default injectIntl(BasicSidebarSection);
