// @flow strict

import request from 'services/request';
import type { ImmutableList } from 'types';
import type {
    ImmutableRecommendationSet,
    ImmutableRecommendationFeedback,
    ImmutablePlantDatasetMessage,
    RecommendationSetIdType,
} from 'services/Recommendation/types';

/**
 * Submit recommendations
 *
 * @param {number} recommendationSetId
 */
export const submit = (
    idOrNew: RecommendationSetIdType,
    recommendationSet: ImmutableRecommendationSet
) =>
    request(`recommendationSets/${idOrNew}/submit`, {
        data: recommendationSet.toJS(),
        method: 'POST',
    });

/**
 * Submit feedback for a recommendation set
 */
export const submitFeedback = (
    id: number,
    feedbacks: ImmutableList<ImmutableRecommendationFeedback>
) =>
    request(`recommendationSets/${id}/submitFeedback`, {
        data: {
            feedbacks: feedbacks.toJS(),
        },
        method: 'POST',
    });

/**
 * Update recommendation
 *
 * @param {number} recommendationId
 * @param {ImmutableRecommendation} recommendation
 */
export const update = (id: number, recommendationSet: ImmutableRecommendationSet) =>
    request(`recommendationSets/${id}`, {
        data: recommendationSet.toJS(),
        method: 'PUT',
    });

/**
 * Run recommendation for circuit
 */
export const runRecommendation = (id: number) =>
    request(`circuits/${id}/recommendations/run`, {
        method: 'GET',
    });

/**
 * Get recommendation for circuit
 */
export const getCircuitRecommendation = (id: number) =>
    request(`circuits/${id}/recommendation`, {
        method: 'GET',
    });

/**
 * Get recommendation for plant
 */
export const getPlantRecommendation = (id: number) =>
    request(`plants/${id}/recommendation`, {
        method: 'GET',
    });

export const submitPlantDatasetMsg = (
    plantId: number,
    plantDatasetId: number,
    message: ImmutablePlantDatasetMessage
) => {
    return request(`recommendations/plant/${plantId}/dataset/${plantDatasetId}/submit`, {
        method: 'POST',
        data: {
            message: message.toJS(),
        },
    });
};

export const _calculateRecommendationImpact = (circuitId: number, datasetId: number, data: any) => {
    return request(`circuits/${circuitId}/datasets/${datasetId}/recommendations/impact`, {
        method: 'POST',
        data,
    });
};
