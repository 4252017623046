// @flow strict

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

// Components
import Login from 'components/Login';

// Selectors
import {
    selectAcceptDisclaimerSaving,
    selectUnacceptedDisclaimers,
    selectErrors,
    selectUser,
} from 'services/Authentication/selectors';

// Styles
import { colors } from 'styles/colors';

// Thunks
import { userAcceptDisclaimer } from 'services/Authentication/thunks';

// Types
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableDisclaimer } from 'services/Disclaimer/types';
import type { IntlType, ReduxDispatch, ErrorType, ImmutableList } from 'types';

// Utils
import { formatDisclaimers } from 'utils/helpers';
import { getUsersLanguage } from 'utils/authentication';

type Props = {
    acceptDisclaimerSaving: boolean,
    unacceptedDisclaimers: ImmutableList<ImmutableDisclaimer>,
    errors?: ErrorType,
    intl: IntlType,
    userAcceptDisclaimer: (id: number) => void,
    user: ?ImmutableUser,
};

export class LoginContainer extends React.PureComponent<Props> {
    static defaultProps = {
        errors: null,
    };

    handleDisclaimerAcceptance = (id: number) => {
        this.props.userAcceptDisclaimer(id);
    };

    render() {
        const disclaimers =
            this.props.user &&
            formatDisclaimers(
                this.props.unacceptedDisclaimers,
                this.props.intl,
                getUsersLanguage(this.props.user)
            );
        return (
            <Login
                disclaimers={disclaimers}
                errors={this.props.errors}
                onDisclaimerAcceptance={this.handleDisclaimerAcceptance}
                isAcceptingDisclaimerPending={this.props.acceptDisclaimerSaving}
                user={this.props.user}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectErrors(),
    user: selectUser(),
    acceptDisclaimerSaving: selectAcceptDisclaimerSaving(),
    unacceptedDisclaimers: selectUnacceptedDisclaimers(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            userAcceptDisclaimer,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(LoginContainer))
);
