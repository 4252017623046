// @flow

import styled, { keyframes } from 'styled-components';

import defaultTheme from '../../themes/defaultTheme';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const StyledLoader = styled.span`
    display: inline-block;
    width: ${({ size }: Object) => size};
    height: ${({ size }: Object) => size};

    margin: 0 auto;

    border: ${({ width }: Object) => width} solid
        ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['loader', 'backgroundColor']) ||
            defaultTheme.loaderSecondary};
    border-radius: 50%;
    border-top: ${({ width }: Object) => width} solid
        ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['loader', 'color']) || defaultTheme.loaderPrimary};

    animation: ${rotate} ${({ speed }: Object) => speed} linear infinite;
`;
