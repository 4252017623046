// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Helpers
import { round } from 'utils/helpers';

// Styles
import { Wrapper, GroupWrapper, InputWrapper } from './styles';

// Components
import { InputNumber, SecondaryButton } from 'components/_ReactUI_V1';

// Constants
import {
    THREE_D_SENSITIVITY_SIGNIFICANT_FIGURES,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
} from 'utils/constants';

// Types
import type { IntlType } from 'types';
import type {
    SensitivityOption,
    SensitivityOutputOption,
} from 'containers/CircuitComputationContainer/ThreeDSensitivityContainer';

type Props = {
    intl: IntlType,

    sensitivityOptionX: SensitivityOption,
    sensitivityOptionY: SensitivityOption,
    outputOptionType: SensitivityOutputOption,

    selectedData: {
        x: ?number,
        y: ?number,
        z: ?number,
    },

    copyToClipboardDisabled: boolean,
    onCopyToClipboardClick: () => void,
};

const inputNumberStyleObj = {
    ...STYLE_VALUES.DEFAULT_INPUT_NUMBER_STYLES,
    width: '64px',
};

/**
 * The footer with the controls used in the 2D Sensitivity plot
 */
class ThreeDSensitivityFooter extends React.PureComponent<Props> {
    render() {
        return (
            <Wrapper>
                <GroupWrapper>
                    <InputWrapper>
                        <span>
                            {this.props.intl.formatMessage({
                                id: `constants.SensitivityOptions.${this.props.sensitivityOptionX}`,
                            })}
                        </span>
                        <InputNumber
                            value={
                                this.props.selectedData.x
                                    ? round(
                                          this.props.selectedData.x,
                                          THREE_D_SENSITIVITY_SIGNIFICANT_FIGURES
                                      )
                                    : ''
                            }
                            style={inputNumberStyleObj}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            disabled
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <span>
                            {this.props.intl.formatMessage({
                                id: `constants.SensitivityOptions.${this.props.sensitivityOptionY}`,
                            })}
                        </span>
                        <InputNumber
                            value={
                                this.props.selectedData.y
                                    ? round(
                                          this.props.selectedData.y,
                                          THREE_D_SENSITIVITY_SIGNIFICANT_FIGURES
                                      )
                                    : ''
                            }
                            style={inputNumberStyleObj}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            disabled
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <span>
                            {this.props.intl.formatMessage({
                                id: `components.3DSensitivityFooter.outputLabel.${
                                    this.props.outputOptionType === 'NET_TRANSFER'
                                        ? 'netTransfer'
                                        : 'percentRecovery'
                                }`,
                            })}
                        </span>
                        <InputNumber
                            value={
                                this.props.selectedData.z
                                    ? round(
                                          this.props.selectedData.z,
                                          THREE_D_SENSITIVITY_SIGNIFICANT_FIGURES
                                      )
                                    : ''
                            }
                            style={inputNumberStyleObj}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            disabled
                        />
                    </InputWrapper>
                </GroupWrapper>

                <SecondaryButton
                    text={this.props.intl.formatMessage({
                        id: 'components.3DSensitivityFooter.copyToClipboard',
                    })}
                    disabled={this.props.copyToClipboardDisabled}
                    onClick={this.props.onCopyToClipboardClick}
                />
            </Wrapper>
        );
    }
}

export default injectIntl(ThreeDSensitivityFooter);
