// @flow strict

import {
    postPlantValue,
    putPlantValue,
    _recomputeSolvExtractDataset,
} from 'services/PlantDataset/resources';
import { report } from 'services/Errors/resources';

import {
    receivedPlantValueCreateOrUpdateSuccess,
    receivedPlantValueCreateOrUpdateFailure,
    setIsCreatingOrUpdatingPlantValue,
    setIsComputingStatus,
} from 'services/PlantDataset/actions';
import { createUntranslatedFeedback } from 'services/Feedback/actions';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { PlantValue, RawPlantValue } from 'services/PlantDataset/types';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

export const createPlantValue = (
    plantId: number,
    plantDatasetId: number,
    data: $Shape<RawPlantValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingPlantValue());
    postPlantValue(plantId, plantDatasetId, data)
        .then((response: PlantValue) => {
            dispatch(receivedPlantValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createPlantValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedPlantValueCreateOrUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createPlantValueFailure'));
        });
};

export const updatePlantValue = (
    plantId: number,
    plantDatasetId: number,
    plantValueId: number,
    data: $Shape<PlantValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingPlantValue());
    putPlantValue(plantId, plantDatasetId, plantValueId, data)
        .then((response: PlantValue) => {
            dispatch(receivedPlantValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.updatePlantValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedPlantValueCreateOrUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updatePlantValueFailure'));
        });
};

export const recomputeSolvExtractDataset = (circuitId: number, datasetId: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsComputingStatus());
    _recomputeSolvExtractDataset(circuitId, datasetId)
        .then(() => {
            dispatch(setIsComputingStatus(false));
            dispatch(
                createUntranslatedFeedback(
                    'INFO',
                    'feedback.info.recomputeSolvExtractDatasetEnqueued'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            dispatch(setIsComputingStatus(false));
            report(error);

            if (error.errorCode === 'plant_dataset.already_not_computed') {
                dispatch(
                    createUntranslatedFeedback(
                        'ERROR',
                        'feedback.error.recomputeSolvExtractDatasetFailure.alreadyNotComputed',
                        null,
                        10000
                    )
                );
                return;
            }
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.recomputeSolvExtractDatasetFailure'
                )
            );
        });
};
