// @flow strict

import {
    receivedRolesFetchFailure,
    receivedRolesFetchSuccess,
    receivedUserCreateFailure,
    receivedUserCreateSuccess,
    receivedUserDeleteFailure,
    receivedUserDeleteSuccess,
    receivedUsersFetchFailure,
    receivedUsersFetchSuccess,
    receivedUsersQueryFailure,
    receivedUsersQuerySuccess,
    receivedUserUpdateFailure,
    receivedUserUpdateSuccess,
    setUserIsCreatingStatus,
    setUserIsUpdatingStatus,
    setUserIsDeletingStatus,
    setUsersAreFetching,
    setUsersAreQuerying,
} from './actions';

import { create, del, index, indexRoles, update, query, queryV2 } from './resources';

import type { QueryStructure, ReduxDispatch, ResponseErrorType } from 'types';
import type { NewUser, UserSearchCriteria } from 'services/User/types';
import type { ImmutableUser, Role, User } from 'services/Authentication/types';

import { createUntranslatedFeedback } from 'services/Feedback/actions';

/**
 * Fetch roles
 */
export const fetchRoles = () => (dispatch: ReduxDispatch) =>
    indexRoles()
        .then((response: Array<Role>) => {
            dispatch(receivedRolesFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedRolesFetchFailure(error));
        });

/**
 * Fetch users
 */
export const fetchUsers = () => (dispatch: ReduxDispatch) => {
    dispatch(setUsersAreFetching());
    index()
        .then((response: Array<User>) => {
            dispatch(receivedUsersFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedUsersFetchFailure(error));
        });
};

/**
 * Query users
 */
export const queryUsers = (searchCriteria: UserSearchCriteria, page: number, perPage: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setUsersAreQuerying());
    query(searchCriteria, page, perPage)
        .then((response: QueryStructure) => {
            dispatch(receivedUsersQuerySuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedUsersQueryFailure(error));
        });
};
/**
 * Query users
 */
export const queryV2Users = (searchCriteria: UserSearchCriteria, page: number, perPage: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setUsersAreQuerying());
    queryV2(searchCriteria, page, perPage)
        .then((response: QueryStructure) => {
            dispatch(receivedUsersQuerySuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedUsersQueryFailure(error));
        });
};
/**
 * Update user
 */
export const updateUser = (user: ImmutableUser) => (dispatch: ReduxDispatch) => {
    dispatch(setUserIsUpdatingStatus());
    update(user)
        .then((response: User) => {
            dispatch(receivedUserUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateUser'));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedUserUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateUserFailed'));
        });
};

/**
 * Create user
 */
export const createUser = (user: NewUser) => (dispatch: ReduxDispatch) => {
    dispatch(setUserIsCreatingStatus());
    create(user)
        .then((response: User) => {
            dispatch(receivedUserCreateSuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.createUserSuccess'));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedUserCreateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createUserFailed'));
        });
};

/**
 * Delete user
 */
export const deleteUser = (userEmail: string) => (dispatch: ReduxDispatch) => {
    dispatch(setUserIsDeletingStatus());
    del(userEmail)
        .then((response: number) => {
            dispatch(receivedUserDeleteSuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.destroyUserSuccess'));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedUserDeleteFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.deleteUserFailed'));
        });
};
