// @flow strict

import React from 'react';

// Constants
import {
    STREAM_TYPES,
    STREAM_CIRCUITS,
    VALUE_STATUS,
    DIAGRAM_DISPLAY_MODES,
    STREAM_VALUE_ORDER,
    STREAM_VALUE_TYPES,
} from 'utils/constants';
import { COMPUTE_GRID, ORGANIC_CIRCUIT_TYPE, SETUP_GRID } from 'components/MimicDiagram/constants';

// Types
import type { UnitsConstant } from 'types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';
import type { LooseStream, StreamEntity, OrganicCircuitConstant } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';

// Components
import StreamValueInputBox from 'components/MimicDiagram/Stream/StreamValueInputBox';
import SetupStreamOption from 'components/MimicDiagram/Stream/SetupStreamOption';

// Styles
import { StreamValuesWrapper } from './styles';

type Props = {
    streamData: StreamEntity,
    circuitUnits: UnitsConstant,
    organicCircuitType: OrganicCircuitConstant,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,

    isEdgeStream: boolean, // required for proper calculation of edge feed/bleed streams
    isRemovableStream: boolean,

    // compute mode:
    values: ?Array<LooseStreamValue>,

    // Either the from stage is null
    fromStageColumn: ?number,
    fromStageRow: ?number,
    // or the to stage is null. but both cannot be null.
    toStageColumn: ?number,
    toStageRow: ?number,

    setStreamValue: SetStreamValueFunction,
    onRemoveAdvancedStreamValueClicked: (streamData: LooseStream) => void,

    isStreamInterstageBleed?: boolean,
};

/**
 * All stream values that belong to Feed or Bleed streams
 */
class FeedBleedStream extends React.PureComponent<Props> {
    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * Calculate the starting column of a feed or bleed stream values on the CSS grid.
     */
    getStartingColumn = (): number => {
        const streamType = this.props.streamData.streamType;
        const streamCircuit = this.props.streamData.streamCircuit;
        const grid = this.getGrid();

        if (streamType === STREAM_TYPES.BLEED) {
            const startColumn = this.props.fromStageColumn;
            if (
                streamCircuit === STREAM_CIRCUITS.AQUEOUS ||
                (streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                    this.props.organicCircuitType === ORGANIC_CIRCUIT_TYPE.STRIP_ONLY)
            ) {
                if (this.props.isEdgeStream) {
                    return startColumn + grid.STAGE.COLUMN_SPAN;
                } else {
                    // this is an interstage bleed stream.
                    return (
                        startColumn +
                        grid.STAGE.COLUMN_SPAN -
                        Math.floor(grid.STREAM_VALUES.COLUMN_SPAN / 2)
                    );
                }
            } else {
                // this is an electrolyte bleed, or an organic bleed.
                if (this.props.isEdgeStream) {
                    return startColumn - grid.STREAM_VALUES.COLUMN_SPAN;
                } else {
                    // this is an interstage bleed stream.
                    return (
                        startColumn -
                        grid.STREAM_VALUES.COLUMN_SPAN +
                        Math.floor(grid.STREAM_VALUES.COLUMN_SPAN / 2)
                    );
                }
            }
        } else if (
            streamType === STREAM_TYPES.FEED ||
            (streamType === STREAM_TYPES.BLEND &&
                this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP)
        ) {
            const startColumn = this.props.toStageColumn;
            if (
                streamCircuit === STREAM_CIRCUITS.AQUEOUS ||
                (streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                    this.props.organicCircuitType === ORGANIC_CIRCUIT_TYPE.STRIP_ONLY)
            ) {
                if (this.props.isEdgeStream) {
                    return startColumn - grid.STREAM_VALUES.COLUMN_SPAN;
                } else {
                    // this is an interstage bleed stream.
                    return (
                        startColumn -
                        grid.STREAM_VALUES.COLUMN_SPAN +
                        Math.floor(grid.STREAM_VALUES.COLUMN_SPAN / 2)
                    );
                }
            } else {
                // this is an electrolyte bleed, or an organic bleed.
                if (this.props.isEdgeStream) {
                    return startColumn + grid.STAGE.COLUMN_SPAN;
                } else {
                    // this is an interstage bleed stream.
                    return (
                        startColumn +
                        grid.STAGE.COLUMN_SPAN -
                        Math.floor(grid.STREAM_VALUES.COLUMN_SPAN / 2)
                    );
                }
            }
        } else if (streamType === STREAM_TYPES.BLEND) {
            // this is a pls blend stream. in COMPUTE mode.
            const startColumn = this.props.toStageColumn;
            return (
                startColumn -
                grid.CONTINUE_STREAM_VALUES.COLUMN_SPAN -
                grid.CONTINUE_STREAM_VALUES.COLUMN_SPAN / 2
            );
        }
        throw new Error(`Unknown stream type ${streamType} passed to FeedBleedStream`);
    };

    /**
     * Calculate the starting row of a feed or bleed stream values on the CSS grid.
     */
    getStartingRow = () => {
        const streamCircuit = this.props.streamData.streamCircuit;
        const streamType = this.props.streamData.streamType;
        const grid = this.getGrid();

        let startRow = 0;

        if (streamType === STREAM_TYPES.BLEED) {
            startRow = this.props.fromStageRow;
        } else if (streamType === STREAM_TYPES.FEED || streamType === STREAM_TYPES.BLEND) {
            startRow = this.props.toStageRow;
        }

        if (
            streamCircuit === STREAM_CIRCUITS.AQUEOUS ||
            (streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                this.props.organicCircuitType === ORGANIC_CIRCUIT_TYPE.STRIP_ONLY)
        ) {
            return (
                startRow -
                grid.STREAM_VALUES.ROW_SPAN -
                (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? 1 : 0)
            );
        } else {
            // this is an electrolyte bleed, or an organic bleed.
            return (
                startRow +
                grid.STAGE.ROW_SPAN +
                (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP
                    ? grid.STREAM_VALUES.ROW_SPAN
                    : 0)
            );
        }
    };

    getStreamValueOrder = (streamValue: LooseStreamValue): ?number => {
        if (
            STREAM_VALUE_ORDER[streamValue.valueType] === null ||
            STREAM_VALUE_ORDER[streamValue.valueType] === undefined
        ) {
            return null;
        }

        return STREAM_VALUE_ORDER[streamValue.valueType];
    };

    onRemoveAdvancedValues = () =>
        this.props.onRemoveAdvancedStreamValueClicked &&
        this.props.onRemoveAdvancedStreamValueClicked(this.props.streamData);

    renderSetup = () =>
        !this.props.isEdgeStream && (
            <StreamValuesWrapper
                startingColumn={this.getStartingColumn()}
                startingRow={this.getStartingRow()}
                columnSpan={SETUP_GRID.STREAM_VALUES.COLUMN_SPAN}
                rowSpan={SETUP_GRID.STREAM_VALUES.ROW_SPAN}
            >
                <SetupStreamOption
                    handleRemoveAdvancedValue={this.onRemoveAdvancedValues}
                    streamType={this.props.streamData.streamType}
                    streamCircuit={this.props.streamData.streamCircuit}
                    showCloseButton={this.props.isRemovableStream}
                    isStreamInterstageBleed={this.props.isStreamInterstageBleed}
                />
            </StreamValuesWrapper>
        );

    renderStreamValue = (streamValue: LooseStreamValue) => (
        <StreamValueInputBox
            streamValue={streamValue}
            circuitUnits={this.props.circuitUnits}
            streamCircuit={this.props.streamData.streamCircuit}
            setStreamValue={this.props.setStreamValue}
            isInput={streamValue.status === VALUE_STATUS.COMPUTE_USING}
            displayMode={this.props.displayMode}
            datasetMode={this.props.datasetMode}
        />
    );

    renderCompute = () => {
        // is the direction of the stream values going UPwards from the starting position?
        const isUpwardsDirection =
            this.props.streamData.streamCircuit === STREAM_CIRCUITS.AQUEOUS ||
            (this.props.streamData.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                this.props.organicCircuitType === ORGANIC_CIRCUIT_TYPE.STRIP_ONLY);
        return (
            this.props.values &&
            this.props.values
                .filter(
                    (steamValue: LooseStreamValue) => this.getStreamValueOrder(steamValue) !== null
                )
                .sort((currentStreamValue: LooseStreamValue, nextStreamValue: LooseStreamValue) => {
                    const first = this.getStreamValueOrder(currentStreamValue);
                    const second = this.getStreamValueOrder(nextStreamValue);
                    if (isUpwardsDirection) {
                        return first - second;
                    }
                    return second - first;
                })
                .map((sortedStreamValue: LooseStreamValue, index: number) => (
                    <StreamValuesWrapper
                        key={`${sortedStreamValue.streamId}-${sortedStreamValue.valueType}`}
                        startingColumn={this.getStartingColumn()}
                        startingRow={this.getStartingRow() + index * (isUpwardsDirection ? -1 : 1)}
                        columnSpan={COMPUTE_GRID.STREAM_VALUES.COLUMN_SPAN}
                        rowSpan={COMPUTE_GRID.STREAM_VALUES.ROW_SPAN}
                    >
                        {this.renderStreamValue(sortedStreamValue)}
                    </StreamValuesWrapper>
                ))
        );
    };

    render() {
        return this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP
            ? this.renderSetup()
            : this.renderCompute();
    }
}

export default FeedBleedStream;
