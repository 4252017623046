// @flow strict

import React from 'react';

// Components
import { LegacyTheme, Triangle } from 'components/_ReactUI_V1';

import { Dash } from 'assets/icons/styles';

// Constants
import { DATASET_VALUE_TYPES, PLANT_VALUE_TYPES } from 'utils/constants';
import { TREND_INDICATOR_TYPES, TARGET_TYPES } from 'utils/kpiConstants';

// Helpers
import { round } from 'utils/helpers';

// Types
import type { ImmutableKPISetting, ImmutableKPICard } from 'services/KPISetting/types';
import type { IntlType } from 'types';

// Get the translation of the N/A values.
export const getNA = (intl: IntlType) =>
    intl.formatMessage({
        id: `components.TrendsPageDashboard.valueIsNA`,
    });

export const getValue = (kpi: ImmutableKPICard) => kpi.get('value');

export const getDisplayValue = (
    kpi: ImmutableKPICard,
    kpiSetting: ImmutableKPISetting,
    intl: IntlType
) => {
    const value = getValue(kpi);
    return value !== null ? round(value, kpiSetting.get('precision')) : getNA(intl);
};

export const shouldShowKPITarget = (kpi: ImmutableKPISetting, showCuTransferTarget: boolean) => {
    if (
        kpi.get('kpiType') === DATASET_VALUE_TYPES.CU_TRANSFERRED ||
        kpi.get('kpiType') === PLANT_VALUE_TYPES.OVERALL_CU_TRANSFER
    ) {
        return showCuTransferTarget;
    }
    return true;
};

export const renderIndicator = (kpi: ImmutableKPICard, small: boolean = false) => {
    const indicatorWidth = small ? 10 : 14;
    const indicatorHeight = small ? 8 : 10;
    const trendType = kpi.get('trend');

    if (!trendType) {
        return null;
    }

    switch (trendType) {
        case TREND_INDICATOR_TYPES.UNCHANGED:
            return <Dash />;
        case TREND_INDICATOR_TYPES.INCREASING:
            return (
                <Triangle margin="0" degrees="0" width={indicatorWidth} height={indicatorHeight} />
            );
        case TREND_INDICATOR_TYPES.DECREASING:
            return (
                <Triangle
                    margin="0"
                    degrees="180"
                    width={indicatorWidth}
                    height={indicatorHeight}
                />
            );
        default:
            return null;
    }
};

export const getValueColor = (
    kpi: ImmutableKPICard,
    kpiSetting: ImmutableKPISetting,
    showCuTransferTarget: boolean
) => {
    const value = kpi.get('value');
    const highTarget = kpiSetting.get(TARGET_TYPES.HIGH_TARGET);
    const lowTarget = kpiSetting.get(TARGET_TYPES.LOW_TARGET);
    let showTargetColor = false;
    let onTarget = false;
    if (value !== null && highTarget !== null && lowTarget !== null) {
        onTarget = value <= highTarget && value >= lowTarget;
        showTargetColor = true;
    }

    const showTarget = shouldShowKPITarget(kpiSetting, showCuTransferTarget);

    let cardColor = LegacyTheme.defaultColor;
    if (showTargetColor && showTarget) {
        if (onTarget) {
            cardColor = LegacyTheme.defaultSuccessColor;
        } else {
            cardColor = LegacyTheme.defaultWarningColor;
        }
    }

    return cardColor;
};
