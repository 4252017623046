// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    max-height: 100%;
    max-width: 360px;
`;

export const SidebarBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 360px;

    height: calc(
        100% - 47px
    ); // this 47 PX comes from the height of the 'visualize' button container.
`;

export const HeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const OverflowBody = styled.div`
    overflow-y: auto;
`;

export const BodySection = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;

    padding: 12px 0 24px;

    ${({ borderTop, borderColor }) =>
        borderTop && `border-top: 1px solid ${borderColor || LegacyTheme.defaultBorder};`} ${({
        borderBottom,
        borderColor,
    }) =>
        borderBottom &&
        `border-bottom: 1px solid ${borderColor || LegacyTheme.defaultBorder};`}

    & + & {
        border-top: 1px solid ${LegacyTheme.defaultBorder};
    }
`;

export const SectionTitle = styled.h1`
    color: ${LegacyTheme.black};
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
`;

export const FooterSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;

    margin-top: ${({ withMargin }) => (withMargin ? '24px' : '0')};
`;

export const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 16px;
    }
`;

export const Label = styled.label`
    min-width: 130px;
    font-size: 13px;
    line-height: 16px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    margin-bottom: ${({ noMargin }) => (!noMargin ? '5px' : '')};
`;

export const SelectField = styled(Field)`
    flex-direction: column;
    align-items: flex-start;

    ${({ bottomBorder }) =>
        bottomBorder &&
        `border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};`} ${({
        bottomBorder,
    }) => bottomBorder && `padding-bottom: 24px;`};
`;

export const WrapperInputNumber = styled.div`
    width: 150px;
    display: flex;
    flex-direction: column;
    margin-top: ${({ marginTop }) => (marginTop ? '18px' : '')};
`;

export const WrapperPlsTenor = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const WrapperMultipleInput = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${({ marginTop }) => (marginTop ? '18px' : '')};
`;

export const SubRadioSetWrapper = styled.div`
    margin-top: 16px;
`;
