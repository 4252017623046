// @flow strict

import type { FeedbackType } from 'services/Feedback/types';

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

export const CREATE_FEEDBACK = 'app/services/Feedback/CREATE_FEEDBACK';
/**
 * @deprecated translate feedbacks before providing them to the toaster
 */
export const CREATE_UNTRANSLATED_FEEDBACK = 'app/services/Feedback/CREATE_UNTRANSLATED_FEEDBACK';

export const DESTROY_FEEDBACK = 'app/services/Feedback/DESTROY_FEEDBACK';
export const DESTROY_ALL_FEEDBACKS = 'app/services/Feedback/DESTROY_ALL_FEEDBACKS';

export const createTranslatedFeedback = (
    feedbackType: FeedbackType,
    message: string,
    duration: ?number
) => ({
    type: CREATE_FEEDBACK,
    payload: {
        feedbackType,
        message,
        duration,
    },
});

/**
 * Creates an UNTRANSLATED feedback,
 * only used during the thunks of other services where translation is not available
 *
 * @deprecated
 */
export const createUntranslatedFeedback = (
    feedbackType: FeedbackType,
    messageId: string,
    data: ?any,
    duration: ?number
) => ({
    type: CREATE_UNTRANSLATED_FEEDBACK,
    payload: {
        feedbackType,
        messageId,
        data,
        duration,
    },
});

export const destroyFeedback = (feedbackId: number) => ({
    type: DESTROY_FEEDBACK,
    payload: {
        feedbackId,
    },
});

export const destroyAllFeedbacks = () => ({
    type: DESTROY_ALL_FEEDBACKS,
    payload: null,
});
