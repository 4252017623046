// @flow strict

// Types
import type { ImmutableList } from 'types';
import type { ImmutableDataset, ImmutableIsothermStageValue } from 'services/Dataset/types';
import type { ImmutableCircuit, ImmutableStream, StageTypeConstant } from 'services/Circuit/types';

// Contacts
import {
    SENSITIVITY_OPTIONS,
    ISOTHERM_VALUE_MODES,
    STAGE_TYPES,
    DESIGN_PRESET_TYPES,
    ADVANCED_STREAMS_SETUP_TYPES,
} from 'utils/constants';

// Helpers
import { getStageById, getStreamAdvancedType } from 'utils/helpers';

const getSelectedIsothermStageValues = (
    isothermStageValues: ImmutableList<ImmutableIsothermStageValue>
) =>
    isothermStageValues.filter(
        (isothermStageValue: ImmutableIsothermStageValue) =>
            isothermStageValue.get('valueType') === ISOTHERM_VALUE_MODES.SELECT
    );

const hasStageWithSelectedIsotherm = (
    selectedIsothermStageValues: ImmutableList<ImmutableIsothermStageValue>,
    stageType: StageTypeConstant,
    circuit: ImmutableCircuit
) =>
    selectedIsothermStageValues.find((selectedIsothermStageValue: ImmutableIsothermStageValue) => {
        const stage = getStageById(circuit, selectedIsothermStageValue.get('stageId'));
        return stage && stage.get('stageType') === stageType;
    });

export const hasAdvancedStreamOnExtractionOrStripping = (
    advancedStreams: Array<ImmutableStream>,
    stageType: StageTypeConstant,
    circuit: ImmutableCircuit
) =>
    advancedStreams.find((advancedStream: ImmutableStream) => {
        const fromStage = getStageById(circuit, advancedStream.get('fromStageId'));
        if (fromStage && fromStage.get('stageType') === stageType) {
            return true;
        }
        const toStage = getStageById(circuit, advancedStream.get('toStageId'));
        if (toStage && toStage.get('stageType') === stageType) {
            return true;
        }
        return false;
    });

export const getAllAdvancedStreams = (circuit: ImmutableCircuit) =>
    circuit
        .get('streams')
        .filter(
            (stream: ImmutableStream) =>
                getStreamAdvancedType(stream, circuit) !== ADVANCED_STREAMS_SETUP_TYPES.NONE
        );

export const getTwoDAndThreeDRestrictions = (dataset: ImmutableDataset) => {
    const selectedIsothermStageValues = getSelectedIsothermStageValues(
        dataset.get('isothermStageValues')
    );
    const circuit = dataset.get('circuit');
    const areAllPredictedIsothermsOnExtraction =
        selectedIsothermStageValues.size === 0 ||
        !hasStageWithSelectedIsotherm(selectedIsothermStageValues, STAGE_TYPES.EXTRACT, circuit);
    const areAllPredictedIsothermsOnStripping =
        selectedIsothermStageValues.size === 0 ||
        !hasStageWithSelectedIsotherm(selectedIsothermStageValues, STAGE_TYPES.STRIP, circuit);
    const hasCustomReagent = dataset.getIn(['circuit', 'reagent'], null) !== null;
    const isInputOToAOnExtraction =
        dataset.get('extractComputeUsing') === DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.OA_RATIO;
    const isStripComputeOA =
        dataset.get('stripCompute') === DESIGN_PRESET_TYPES.STRIP.COMPUTE.OA_RATIO;
    const advancedStreams = getAllAdvancedStreams(circuit);
    const isSimpleExtractionCircuit =
        advancedStreams.size === 0 ||
        !hasAdvancedStreamOnExtractionOrStripping(advancedStreams, STAGE_TYPES.EXTRACT, circuit);
    const isSimpleStrippingCircuit =
        advancedStreams.size === 0 ||
        !hasAdvancedStreamOnExtractionOrStripping(advancedStreams, STAGE_TYPES.STRIP, circuit);

    return {
        /*
        Predicted isotherms must be used in both Extraction and Stripping
        to allow isotherm re-prediction after each step
        */
        [SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION]:
            areAllPredictedIsothermsOnExtraction && areAllPredictedIsothermsOnStripping,
        /*
        1.  Option may be selected only when the database corresponding to the “Product Type”
            contains more than one ox/mod ratio
        2.  While varying the ox/mod ratio, the upper and lower bounds that the user selects
            must be in range of the database
        3.  Predicted isotherms must be used in both Extraction and Stripping to allow isotherm
            re-prediction after each step
        4. Option may be selected only when a “Custom Reagent” is selected in the Circuit setup
        */
        [SENSITIVITY_OPTIONS.OXMOD_RATIO]:
            areAllPredictedIsothermsOnExtraction &&
            areAllPredictedIsothermsOnStripping &&
            hasCustomReagent,
        /*
        1.  Input O/A pre-set must be selected in Extraction
        2.  Varying the Extract O/A may only be selected when simple extraction circuits
            are present (No new feeds, bleeds, blends, alternating streams, or organic bypass)
        */
        [SENSITIVITY_OPTIONS.EXTRACT_OA]: isInputOToAOnExtraction && isSimpleExtractionCircuit,
        /*
        Predicted isotherms must be used in Extraction to allow isotherm re-prediction
        after each step
        */
        [SENSITIVITY_OPTIONS.PLS_COMPOSITION]: areAllPredictedIsothermsOnExtraction,
        /*
        Predicted isotherms must be used in Extraction to allow isotherm re-prediction
        after each step
        */
        [SENSITIVITY_OPTIONS.PLS_PH]: areAllPredictedIsothermsOnExtraction,
        /** Rich Electrolyte Cu
        1.  Compute O/A pre-set must be selected in Stripping. The Strip O/A is varied
            to achieve the target electrolyte
        2.  Varying the Advance Electrolyte Cu may only be selected when simple stripping
            circuits are present (no parallel strip)
        */
        [SENSITIVITY_OPTIONS.ADVANCE_ELECTROLYTE]: isStripComputeOA && isSimpleStrippingCircuit,
        /*
        1.  Varying the Lean Electrolyte Cu may only be selected when simple stripping circuits
            are present (no parallel strip)
        2.  Predicted isotherms must be used in Stripping to allow isotherm re-prediction
            after each step
        */
        [SENSITIVITY_OPTIONS.LEAN_ELECTROLYTE]:
            isSimpleStrippingCircuit && areAllPredictedIsothermsOnStripping,
        /**
        1.  Varying the Lean Electrolyte Cu may only be selected when simple stripping
            circuits are present (no parallel strip)
        2.  Predicted isotherms must be used in Stripping to allow isotherm re-prediction
            after each step
        */
        [SENSITIVITY_OPTIONS.ACID]: isSimpleStrippingCircuit && areAllPredictedIsothermsOnStripping,
    };
};
