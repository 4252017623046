// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

// Components
import { Table } from 'components/_ReactUI_V1';

// Constants
import { NO_RESULT_STRING } from 'utils/constants';

// Types
import type { ImmutableList, IntlType } from 'types';
import type { ImmutableKPISetting, ImmutableKPIStatus } from 'services/KPISetting/types';

type Props = {
    intl: IntlType,
    statuses: ImmutableList<ImmutableKPIStatus>,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
};

class KPIStatusTable extends React.PureComponent<Props> {
    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.KPIStatusTable.${id}`,
            },
            data
        );

    getTableHeader = () =>
        [
            {
                id: 'name',
                label: this.getTranslation('headers.name'),
            },
            this.shouldDisplayAtLeastOneCalculationStatus() && {
                id: 'calculationStatus',
                label: this.getTranslation('headers.calculationStatus'),
            },
            this.shouldDisplayAtLeastOneValidityStatus() && {
                id: 'validityStatus',
                label: this.getTranslation('headers.validityStatus'),
            },
            {
                id: 'reason',
                label: this.getTranslation('headers.reason'),
            },
            this.shouldDisplayAtLeastOneValues() && {
                id: 'value',
                label: this.getTranslation('headers.value'),
            },
        ].filter(Boolean); // remove nulls/false elements.

    getFormattedUncalculatableReason = (status: ImmutableKPIStatus): string => {
        const reason = status.get('reason');
        let displayedReason = NO_RESULT_STRING;

        if (reason) {
            const id = `models.kpiSettings.uncalculatableReasons.${reason}`;
            const idExists = Boolean(this.props.intl.messages[id]);

            // We format reasons in user friendly strings if they don't have existing
            // translations. (Ex. OXIME_CONCENTRATION_NO_OXIME => Oxime concentration no oxime)
            if (idExists) {
                displayedReason = this.props.intl.formatMessage({ id });
            } else {
                displayedReason = (reason[0] + reason.slice(1).toLowerCase()).replace(/_/g, ' ');
            }
        }

        return displayedReason;
    };

    getTableRows = () => {
        const { statuses, kpiSettings } = this.props;

        return statuses
            .map((status: ImmutableKPIStatus) => {
                const kpi = kpiSettings.find(
                    (kpiSetting: ImmutableKPISetting) =>
                        kpiSetting.get('id') === status.get('kpiSettingId')
                );

                // If there is no found kpi there is an issue, return null
                if (!kpi) {
                    return null;
                }

                let keyPrefix = '';
                let interactiveRow = Map().set('name', kpi && kpi.get('name'));

                if (this.shouldDisplayCalculationStatus(status)) {
                    keyPrefix = 'calculationStatus';

                    interactiveRow = interactiveRow
                        .set(
                            'calculationStatus',
                            this.getTranslation(
                                `status.calculationStatus.${status.get('calculationStatus')}`
                            )
                        )
                        .set('reason', this.getFormattedUncalculatableReason(status));
                }

                if (this.shouldDisplayValidityStatus(status)) {
                    keyPrefix = 'validityStatus';
                    interactiveRow = interactiveRow
                        .set(
                            'validityStatus',
                            this.getTranslation(
                                `status.validityStatus.${status.get('validityStatus')}`
                            )
                        )
                        .set(
                            'reason',
                            status.get('reason')
                                ? this.props.intl.formatMessage({
                                      id: `models.kpiSettings.invalidReasons.${status.get(
                                          'reason'
                                      )}`,
                                  })
                                : NO_RESULT_STRING
                        );
                }

                if (this.shouldDisplayValue(status)) {
                    // If we want to display the value, let's first build our (min, max) bracket;
                    // In case value is 0, check to see if it's null
                    let min;
                    const rawMin = status.get('minimum');
                    if (rawMin !== null) {
                        min = this.getTranslation('minValue', { value: rawMin });
                    }

                    let max;
                    const rawMax = status.get('maximum');
                    if (rawMax !== null) {
                        max = this.getTranslation('maxValue', { value: rawMax });
                    }

                    // Build up (min, max) bracket string based on min & max values
                    let bracket = '';
                    if (min && max) {
                        bracket = ` (${min}, ${max})`;
                    } else if (min) {
                        bracket = ` (${min})`;
                    } else if (max) {
                        bracket = ` (${max})`;
                    }

                    interactiveRow = interactiveRow.set(
                        'value',
                        `${status.get('value') || NO_RESULT_STRING}${bracket}`
                    );
                }

                // Set "dynamic" id for row key
                interactiveRow = interactiveRow.set('id', `${keyPrefix}-${kpi.get('id')}`);

                return interactiveRow;
            })
            .filter(Boolean) // remove nulls/false elements.
            .toJS();
    };

    shouldDisplayCalculationStatus = (status: ImmutableKPIStatus) =>
        Boolean(status.has('calculationStatus'));

    shouldDisplayAtLeastOneCalculationStatus = () =>
        this.props.statuses.find(this.shouldDisplayCalculationStatus);

    shouldDisplayValidityStatus = (status: ImmutableKPIStatus) =>
        Boolean(status.has('validityStatus'));

    shouldDisplayAtLeastOneValidityStatus = () =>
        this.props.statuses.find(this.shouldDisplayValidityStatus);

    shouldDisplayValue = (status: ImmutableKPIStatus) => Boolean(status.has('value'));

    shouldDisplayAtLeastOneValues = () => this.props.statuses.find(this.shouldDisplayValue);

    render() {
        return <Table header={this.getTableHeader()} rows={this.getTableRows()} subtleStyling />;
    }
}

export default injectIntl(KPIStatusTable);
