// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import LanguageSelectModal from 'components/Modals/LanguageSelectModal';

// Types
import type { ErrorType, ReduxDispatch } from 'types';
import type { ImmutableUser, GlobalUserPreferences } from 'services/Authentication/types';

// Services
import { updateUserPreferences } from 'services/Authentication/thunks';
import {
    selectUser,
    selectUserPreferencesIsUpdating,
    selectErrors,
} from 'services/Authentication/selectors';

type Props = {
    user: ImmutableUser,
    onClose?: ?() => void,

    errors: ErrorType,
    isUpdating: boolean,
    updateUserPreferences: (id: number, preferences: GlobalUserPreferences) => void,
};

/**
 * The container that handles all the user settings modal logic
 */
class LanguageModalContainer extends React.PureComponent<Props, null> {
    static defaultProps = {
        onClose: null,
    };

    /**
     * When the component updates (called when updating the settings or when fetching the user)
     * check if the update finished, and if so, close the modal.
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        if (!this.props.onClose) return; // If there is no close handler, do nothing

        const wasDoingAction = prevProps.isUpdating;
        const isDoingAction = this.props.isUpdating;

        if (wasDoingAction && !isDoingAction) {
            this.props.onClose();
        }
    }

    /**
     * Handle the save button clicked.
     */
    handleSaveClicked = (newPreferences: GlobalUserPreferences) => {
        if (!this.props.user) {
            console.error('Received null user to update modal.');
            return;
        }
        this.props.updateUserPreferences(this.props.user, newPreferences);
    };

    render() {
        return (
            <LanguageSelectModal
                user={this.props.user}
                errors={this.props.errors}
                loading={this.props.isUpdating}
                onConfirm={this.handleSaveClicked}
                onCancel={this.props.onClose}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
    isUpdating: selectUserPreferencesIsUpdating(),
    errors: selectErrors(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            updateUserPreferences,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LanguageModalContainer)
);
