// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Styles
import { Common, PrimaryButton, TertiaryButton, Modal, InputNumber } from 'components/_ReactUI_V1';

import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    FooterButtons,
} from './styles';

// Components
import { Title } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';

// Services
import {
    selectIsUpdatingKPISettings,
    selectUpdateKPISettingsErrors,
    selectKPISetting,
} from 'services/KPISetting/selectors';
import { updateKPISettings } from 'services/KPISetting/thunks';

// Constant
import {
    MODAL_WIDTH,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
} from 'utils/constants';
import { TARGET_TYPES } from 'utils/kpiConstants';

// Helpers
import { getKPISettingUnit } from 'utils/kpiHelpers';
import { tryParseNumberOrNull } from 'utils/helpers';

// Types
import type { IntlType, ImmutableList, InputEvent, ErrorType, ReduxDispatch } from 'types';
import type { KPISetting, ImmutableKPISetting } from 'services/KPISetting/types';

type Props = {
    kpiId: number,
    kpiSetting: KPISetting,
    onCloseModal: () => void,

    intl: IntlType,
    isUpdatingKPISettings: boolean,
    updateKPISettingsErrors: ErrorType,
    updateKPISettings: (kpiId: number, updateData: $Shape<KPISetting>) => void,
};

type State = $Shape<KPISetting>;

/**
 * Shown to a user when they want to edit a KPI's settings via dashboard
 */
class EditKPISettingsModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            lowTarget: this.props.kpiSetting.get(TARGET_TYPES.LOW_TARGET),
            highTarget: this.props.kpiSetting.get(TARGET_TYPES.HIGH_TARGET),
            mainTarget: this.props.kpiSetting.get(TARGET_TYPES.MAIN_TARGET),
        };
    }

    /**
     * When the component updates,
     * we need to check if we are done updating
     *      If we are, then close the modal.
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.isUpdatingKPISettings && !this.props.isUpdatingKPISettings) {
            if (this.props.updateKPISettingsErrors.isEmpty()) {
                this.props.onCloseModal();
            }
            // Else: errors will be displayed by the renderError() function if prop errors not empty
        }
    }

    getErrorTypeForKPIMainTarget(): ?string {
        if (this.state.mainTarget) {
            if (this.state.highTarget && this.state.mainTarget > this.state.highTarget) {
                return 'feedback.error.mainTargetTooHighError';
            }
            if (this.state.lowTarget && this.state.mainTarget < this.state.lowTarget) {
                return 'feedback.error.mainTargetTooLowError';
            }
        }
    }

    getErrorCodeKpiLowHighTargets(): ?string {
        if (
            this.state.lowTarget &&
            this.props.kpiSetting.get('minValid') &&
            this.state.lowTarget < this.props.kpiSetting.get('minValid')
        ) {
            return 'feedback.error.lowTargetLowerThanMinError';
        }
        if (
            this.state.highTarget &&
            this.props.kpiSetting.get('maxValid') &&
            this.state.highTarget > this.props.kpiSetting.get('maxValid')
        ) {
            return 'feedback.error.maxTargetHigherThanMaxError';
        }
    }

    getTranslation = (id: string, data?: any = {}) =>
        this.props.intl.formatMessage(
            {
                id: `components.EditKPISettingsModal.${id}`,
            },
            data
        );

    /**
     * When the user clicks on the Save button
     */
    handleConfirm = () => {
        this.props.updateKPISettings(this.props.kpiId, this.state);
    };

    /**
     * Updates our state with the new value supplied in the input box.
     * @param {*} e The event tied to the input box
     */
    handleChangeValue = (e: InputEvent) => {
        const stateKey = e.target.name;
        this.setState({
            [stateKey]: tryParseNumberOrNull(e.target.value),
        });
    };

    getKPIWithUnit = () => {
        const unit = getKPISettingUnit(this.props.kpiSetting, this.props.intl);
        const kpiName = this.props.kpiSetting.get('name');
        if (!unit) {
            // if there is no unit only render the name.
            return kpiName;
        }

        return this.props.intl.formatMessage(
            {
                id: `components.KPIGraph.kpiNameWithUnit`,
            },
            {
                kpiName,
                unit,
            }
        );
    };

    getError = () => {
        const targetBoundaryError = this.getErrorCodeKpiLowHighTargets();
        const mainTargetError = this.getErrorTypeForKPIMainTarget();
        return targetBoundaryError || mainTargetError;
    };

    saveIsDisabled = () => this.props.isUpdatingKPISettings || this.getError();

    renderErrors = () => {
        const frontEndError = this.getError();
        const fallbackErrorCode = 'feedback.error.updateKpiSettingsFailure';
        if (!this.props.updateKPISettingsErrors.isEmpty() || frontEndError) {
            return (
                <ErrorMessage
                    errorType={this.props.updateKPISettingsErrors}
                    errorCode={frontEndError || fallbackErrorCode}
                    style={{
                        marginBottom: '10px',
                        marginTop: '0',
                        textAlign: 'center',
                    }}
                    isRed
                    isSmall
                />
            );
        }
    };

    render() {
        const unit = getKPISettingUnit(this.props.kpiSetting, this.props.intl);
        return (
            <Modal
                onHandleClose={this.props.isUpdatingKPISettings ? null : this.props.onCloseModal}
                modalWidth={MODAL_WIDTH.SMALL}
            >
                <Wrapper>
                    <Header>
                        <Title>{this.getKPIWithUnit()}</Title>
                    </Header>
                    <Body>
                        <Field>
                            <Label>{this.getTranslation(TARGET_TYPES.LOW_TARGET)}</Label>
                            <InputNumber
                                name={TARGET_TYPES.LOW_TARGET}
                                max={null}
                                value={this.state.lowTarget || ''}
                                onChange={this.handleChangeValue}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                                unit={unit}
                            />
                        </Field>
                        <Field>
                            <Label>{this.getTranslation(TARGET_TYPES.HIGH_TARGET)}</Label>
                            <InputNumber
                                name={TARGET_TYPES.HIGH_TARGET}
                                max={null}
                                value={this.state.highTarget || ''}
                                onChange={this.handleChangeValue}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                                unit={unit}
                            />
                        </Field>
                        <Field>
                            <Label>{this.getTranslation(TARGET_TYPES.MAIN_TARGET)}</Label>
                            <InputNumber
                                name={TARGET_TYPES.MAIN_TARGET}
                                max={null}
                                value={this.state.mainTarget || ''}
                                onChange={this.handleChangeValue}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                                unit={unit}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        {this.renderErrors()}
                        <FooterButtons>
                            <TertiaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.cancelButton',
                                })}
                                disabled={this.props.isUpdatingKPISettings}
                                onClick={this.props.onCloseModal}
                            />
                            <PrimaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.saveButton',
                                })}
                                disabled={this.saveIsDisabled()}
                                loading={this.props.isUpdatingKPISettings}
                                onClick={this.handleConfirm}
                            />
                        </FooterButtons>
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

const mapStateToProps = (_, ownProps: Props) =>
    createStructuredSelector({
        isUpdatingKPISettings: selectIsUpdatingKPISettings(),
        updateKPISettingsErrors: selectUpdateKPISettingsErrors(),
        kpiSetting: selectKPISetting(ownProps.kpiId),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            updateKPISettings,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EditKPISettingsModal));
