// @flow strict

import styled from 'styled-components';

export const LegendWrapper = styled.div`
    display: flex;
    height: 35px;
    align-items: ${({ direction }) => (direction === 'ROW' ? 'center' : 'start')};
    flex-direction: ${({ direction }) => direction};
    flex-shrink: 0;
`;

export const DiagramLegendItem = styled.span`
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    color: ${({ color }) => color};
    font-size: 14px;

    white-space: wrap;

    & + & {
        margin-${({ direction }) => (direction === 'ROW' ? 'left' : 'top')}: 16px;
    }
`;
