/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { LegacyTheme, ButtonHover } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

const cardWidth = 200;
const plantCardStyleWidth = 240;
const sideBannerWidth = 5;
const textLengthBeforeBreakpoint = 7;

export const KPICardFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 35px;

    margin-left: -5px;
    padding-left: 5px;

    font-size: 15px;
    line-height: normal;
    padding: 12px 20px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        font-size: 12px;
        padding: 10px;
        svg {
            width: 15px;
        }
    }

    transition: 0.2s;
    color: ${LegacyTheme.defaultSubtleColor};
    ${({ empty }: { empty: true }) =>
        empty ? '' : `border-top: 1px solid ${LegacyTheme.defaultBorder}`};

    ${(props: Object) =>
        props.active &&
        `
            color: ${props.color};
        `};
`;

export const SettingsButtonHover = styled(ButtonHover)`
    padding: 2px 3px 1px;
    display: flex;
    align-items: center;
    min-height: unset;

    color: ${LegacyTheme.defaultSubtleColor7B};

    &:hover {
        color: ${LegacyTheme.defaultColor};
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: row;

    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;

    width: ${({ plantCardStyle }: Object) => (plantCardStyle ? '100%' : 'auto')};
    max-width: ${({ plantCardStyle }: Object) =>
        plantCardStyle ? `${plantCardStyleWidth}px` : `${cardWidth}px`};
    min-width: ${cardWidth}px;

    min-height: 150px; // Allows unit to wrap without breaking bounds of card

    margin: 10px;

    transition: box-shadow ease 0.25s;

    cursor: pointer;

    ${(props: Object) =>
        props.active &&
        `
            cursor: default;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        `};

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

        ${KPICardFooter} {
            color: ${({ activeColor }: { activeColor: string }) =>
                activeColor || LegacyTheme.buttonSecondaryHoverColor};
        }
    }

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        max-width: ${({ plantCardStyle }: Object) => (plantCardStyle ? '314px' : '150px')};
        min-width: 150px;
        margin: ${({ plantCardStyle }: Object) => (plantCardStyle ? '10px 8px' : '10px 5px')};
        overflow: hidden;
    }
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    // Card width - SideBanner width - border
    max-width: ${({ plantCardStyle }) =>
        plantCardStyle ? 314 - sideBannerWidth - 2 : cardWidth - sideBannerWidth - 2}px;
`;

export const CardTitle = styled.div`
    padding: 16px 16px 10px 16px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding-left: 10px;
        max-width: 150px;
    }
`;

export const CardTitleMain = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    color: ${LegacyTheme.defaultColor};
    font-size: 15px;
    font-weight: normal;
    letter-spacing: -0.3px;
    line-height: 18px;
`;

export const KPIName = styled.div`
    margin-right: 10px;

    width: 100%;

    word-break: break-all;
`;

export const CardValueWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;

    padding: 16px;
    padding-top: 0;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding-left: 10px;
    }
`;

export const CardValue = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Value = styled.div`
    font-size: ${({ textLength }) => (textLength > textLengthBeforeBreakpoint ? '20px' : '40px')};
    line-height: normal;

    margin-right: 8px;

    color: ${({ color }: { color: string }) => color};

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        display: inline-block;
        margin-right: 5px;

        font-size: ${({ textLength }) =>
            textLength > textLengthBeforeBreakpoint ? '16px' : '28px'};
    }
`;

export const Unit = styled.span`
    font-size: 14px;
    height: 16px;
    color: ${LegacyTheme.defaultSubtleColor};

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        font-size: ${(props: Object) => (props.small ? '11px' : '12px')};
    }
`;

export const SideBanner = styled.div`
    height: 100%;
    width: ${sideBannerWidth}px;
    background-color: ${(props: Object) => props.active && props.color};
    z-index: 1;

    border-radius: 4px 0px 0px 4px;
`;
