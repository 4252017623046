// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import Graph3D from 'react-graph3d-vis';

// Styles
import { Wrapper, DiagramHeader, ChartWrapper, tooltipStyle } from './styles';
import { threeDSensitivityDiagramColors } from 'styles/colors';

// Components
import DiagramLegend from 'components/DiagramLegend';

// Helpers
import { round } from 'utils/helpers';

// Types
import type { IntlType } from 'types';
import type {
    ImmutableThreeDAnalysis,
    SensitivityOptionConstant,
    ZSensitivityOptionConstant,
    ImmutableThreeDDataPoint,
} from 'services/Dataset/types';

type DataPoint = {
    x: number,
    y: number,
    z: number,
};

type Props = {
    intl: IntlType,
    sensitivityOptionX: SensitivityOptionConstant,
    sensitivityOptionY: SensitivityOptionConstant,
    outputOptionType: ZSensitivityOptionConstant,

    threeDAnalysis: ?ImmutableThreeDAnalysis,

    handleTooltipHover: (data: DataPoint) => void,
};

/**
 * Displays the 3d graph
 */
class ThreeDSensitivityDiagram extends React.Component<Props> {
    getData = () =>
        this.props.threeDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableThreeDDataPoint) => ({
                /* Prevents crashing if user selects wrong options in sidebar. */
                x: dataPoint.get('xVariable') || 0,
                y: dataPoint.get('yVariable') || 0,
                z:
                    dataPoint.get('zVariable') ||
                    0 /* Prevents crashing if user selects wrong options in sidebar. */,
            }))
            .toJS();

    getXAxisName = () =>
        this.props.intl.formatMessage({
            id: `constants.SensitivityOptions.${this.props.sensitivityOptionX}`,
        });

    getYAxisName = () =>
        this.props.intl.formatMessage({
            id: `constants.SensitivityOptions.${this.props.sensitivityOptionY}`,
        });

    getZAxisName = () =>
        this.props.intl.formatMessage({
            id: `components.SensitivityDiagrams.Axis.${this.props.outputOptionType}`,
        });

    /**
     * Provided hovered dataPoint to container for display in footer
     */
    handleToolTipHover = (data: DataPoint) => {
        this.props.handleTooltipHover(data);

        return `${this.getXAxisName()}: ${round(data.x)}<br/>${this.getYAxisName()}: ${round(
            data.y
        )}<br/>${this.getZAxisName()}: ${round(data.z)}`;
    };

    // https://visjs.github.io/vis-graph3d/docs/graph3d/index.html
    getOptions = () => ({
        style: 'surface',
        width: '100%',
        height: '100%',
        showPerspective: true,
        showLegend: false,
        showGrid: true,
        showShadow: false,
        keepAspectRatio: false,
        verticalRatio: 0.5,
        xLabel: this.getXAxisName(),
        yLabel: this.getYAxisName(),
        zLabel: this.getZAxisName(),
        // Option tooltip can be true, false, or a function returning a string with HTML contents
        tooltip: this.handleToolTipHover,
        // Tooltip default styling can be overridden
        tooltipStyle,
        // showAnimationControls: true,
        // animationInterval: 1000,
        // animationPreload: true,
        // animationAutoStart: true,
        cameraPosition: {
            horizontal: -2,
            vertical: 0,
            distance: 1.7,
        },
    });

    render() {
        return (
            <Wrapper>
                {/* Currently Graph3D does not accept multiple axis colors, so this Legend is not needed */}
                {/* <DiagramHeader>
                    <DiagramLegend
                        legendItems={[
                            {
                                color: threeDSensitivityDiagramColors.xAxis,
                                label: this.getXAxisName(),
                            },
                            {
                                color: threeDSensitivityDiagramColors.yAxis,
                                label: this.getYAxisName(),
                            },
                            {
                                color: threeDSensitivityDiagramColors.zAxis,
                                label: this.getZAxisName(),
                            },
                        ]}
                    />
                </DiagramHeader> */}
                <ChartWrapper>
                    <Graph3D
                        data={this.getData()}
                        options={this.getOptions()}
                        // cameraPositionChangeHandler={(event) => console.log('cameraPositionChangeHandler, event'}
                    />
                </ChartWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(ThreeDSensitivityDiagram);
