// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type { ImmutableReagentState } from './types';

/**
 * Direct selector to the reagent state domain
 */
export const selectReagentState = () => (state: ImmutableReduxState) => state.get('reagent');

/**
 * Select list of reagents
 */
export const selectAllReagents = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('reagents').toList()
    );

/**
 * Select reagent errors
 */
export const selectReagentErrors = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('errors')
    );

/**
 * Select reagent update errors
 */
export const selectReagentUpdateErrors = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('errorsUpdate')
    );

/**
 * Gets isFetching status while reagents are being fetched: to hide list or show loading animation
 */
export const selectReagentsAreFetching = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('isFetching')
    );

/**
 * Gets isUpdating status while reagents are being updated: to disable inputs
 */
export const selectReagentsAreUpdating = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('isUpdating')
    );

/**
 * Gets isFetching status while reagents are being fetched: to hide list or show loading animation
 */
export const selectReagentsAreDeleting = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('isDeleting')
    );

/**
 * Gets isUpdating status while reagents are being updated: to disable inputs
 */
export const selectReagentsAreCreating = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('isCreating')
    );

/**
 * Select list of queried reagents results
 */
export const selectQueriedReagents = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.getIn(['query', 'data']).toList()
    );

/**
 * Select last page (page count) of queried reagents
 */
export const selectQueriedReagentsLastPage = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.getIn(['query', 'lastPage'])
    );

/**
 * Gets isQuerying status while reagents are being queried: to hide list or show loading animation
 */
export const selectReagentsAreQuerying = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('isQuerying')
    );

/**
 * Gets isQuerying status while reagents are being queried: to hide list or show loading animation
 */
export const selectReagentIsSettingPlantAccess = () =>
    createSelector(
        selectReagentState(),
        (substate: ImmutableReagentState) => substate.get('isSettingPlantAccess')
    );
