// @flow

import React from 'react';

import type { InputEventType } from '../../types';

// Styles
import { Checkbox, CheckboxLabel, HiddenInput } from './styles';

type Props = {
    checked: boolean,
    disabled?: boolean,
    label?: string,
    labelRenderer?: (checked: boolean) => void,
    onClickHandler: (event: InputEventType) => void,
    name?: string,
    styles?: Object,
};

export class CheckBoxV2 extends React.Component<Props, State> {
    static defaultProps = {
        disabled: false,
        label: '',
        labelRenderer: null,
        name: 'checkbox',
        styles: null,
    };

    render() {
        return (
            <CheckboxLabel
                key={this.props.name}
                disabled={this.props.disabled}
                style={this.props.styles}
            >
                <Checkbox checked={this.props.checked} disabled={this.props.disabled} />
                {this.props.label}
                {this.props.labelRenderer && this.props.labelRenderer(this.props.checked)}
                <HiddenInput
                    key={this.props.name}
                    checked={this.props.checked}
                    id={this.props.name}
                    name={this.props.name}
                    type="checkbox"
                    onChange={!this.props.disabled ? this.props.onClickHandler : null}
                    value={this.props.checked}
                />
            </CheckboxLabel>
        );
    }
}
