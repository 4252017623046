/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { STYLE_VALUES } from 'utils/constants';
import { LegacyTheme } from 'components/_ReactUI_V1';

const PROGRESS_TRANSITION_SPEED = 0.2;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    // Full width of parent minus its margins + one step width to offset first & last dot to edge of wrapper
    width: calc(
        (100% - ${STYLE_VALUES.GUTTERS.LARGE} - ${STYLE_VALUES.GUTTERS.LARGE}) +
            calc(100% / ${({ stepCount }: Object) => stepCount})
    );
    max-width: calc(
        (
                ${({ maxWidthValue }: Object) => maxWidthValue || STYLE_VALUES.SCREEN.MAX_WIDTH} -
                    ${STYLE_VALUES.GUTTERS.LARGE} - ${STYLE_VALUES.GUTTERS.LARGE}
            ) +
            calc(
                (
                        ${({ maxWidthValue }: Object) =>
                                maxWidthValue || STYLE_VALUES.SCREEN.MAX_WIDTH} -
                            ${STYLE_VALUES.GUTTERS.LARGE} - ${STYLE_VALUES.GUTTERS.LARGE}
                    ) / ${({ stepCount }: Object) => stepCount}
            )
    );
`;

export const Steps = styled.ul`
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 0;

    > li {
        width: 100%;
    }
`;

export const Step = styled.li`
    list-style-type: none;

    float: left;
    font-size: 13px;
    position: relative;
    text-align: center;
    color: ${({ active }: Object) =>
        active ? LegacyTheme.defaultColor : LegacyTheme.radiobuttonLabelColor};

    // DOT:
    :before {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        display: block;
        content: '';
        margin: 0 auto 16px auto;
        z-index: 2;

        background: linear-gradient(
            90deg,
            ${LegacyTheme.radiobuttonActive} 0%,
            ${LegacyTheme.radiobuttonActive} 50%,
            ${LegacyTheme.radiobuttonLabelDisabledColor} 50%,
            ${LegacyTheme.radiobuttonLabelDisabledColor} 100%
        );
        background-size: 200% 200%;
        background-position: ${({ active }: Object) => (active ? '0% 100%' : '100% 100%')};

        transition: background-position 0s ease-out;
        transition-delay: ${({ active }: Object) => (active ? PROGRESS_TRANSITION_SPEED : '0')}s;
        will-change: background-position;
    }

    // LINE:
    :after {
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        top: 6.5px;
        left: -50%;
        z-index: -1;

        background: linear-gradient(
            90deg,
            ${LegacyTheme.radiobuttonActive} 0%,
            ${LegacyTheme.radiobuttonActive} 50%,
            ${LegacyTheme.radiobuttonLabelDisabledColor} 50%,
            ${LegacyTheme.radiobuttonLabelDisabledColor} 100%
        );
        background-size: 200% 200%;
        background-position: ${({ active }: Object) => (active ? '0% 100%' : '100% 100%')};

        transition: background-position ${PROGRESS_TRANSITION_SPEED}s ease-out;
        transition-delay: ${({ active }: Object) => (!active ? PROGRESS_TRANSITION_SPEED : '0')}s;
        will-change: background-position;
    }

    :first-child:after {
        content: none;
    }
`;
