// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { KPISetting } from 'services/KPISetting/types';

export const UPDATE_KPI_SETTINGS_SUCCESS = 'app/services/Trends/UPDATE_KPI_SETTINGS_SUCCESS';
export const UPDATE_KPI_SETTINGS_FAILURE = 'app/services/Trends/UPDATE_KPI_SETTINGS_FAILURE';
export const IS_UPDATING_KPI_SETTINGS = 'app/services/Trends/IS_UPDATING_KPI_SETTINGS';

export const receivedKPISettingsUpdateSuccess = (data: KPISetting) => ({
    type: UPDATE_KPI_SETTINGS_SUCCESS,
    payload: { data },
});

export const receivedKPISettingsUpdateFailure = (errors: {}) => ({
    type: UPDATE_KPI_SETTINGS_FAILURE,
    payload: { errors },
});

export const setIsUpdatingKPISettings = (isUpdatingKPISetting: boolean = true) => ({
    type: IS_UPDATING_KPI_SETTINGS,
    payload: { isUpdatingKPISetting },
});
