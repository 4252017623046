// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Constants
import { ROUTES } from 'utils/constants';

// Container
import ReagentManagementContainer, {
    MANAGEMENT_TYPES,
} from 'containers/ReagentManagementContainer';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
};

export class ReagentManagement extends React.PureComponent<Props> {
    getType() {
        const url = this.props.match.url;
        if (url.toLowerCase() === ROUTES.MDR.path) {
            return MANAGEMENT_TYPES.MDR;
        } else if (url.toLowerCase() === ROUTES.REAGENTS.path) {
            return MANAGEMENT_TYPES.REAGENTS;
        } else if (url.toLowerCase() === ROUTES.CYANEX_REAGENTS.path) {
            return MANAGEMENT_TYPES.CYANEX_REAGENTS;
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.ReagentManagement.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.ReagentManagement.helmetDescription',
                        })}
                    />
                </Helmet>
                <ReagentManagementContainer type={this.getType()} />
            </React.Fragment>
        );
    }
}

export default injectIntl(ReagentManagement);
