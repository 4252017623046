// @flow strict

import styled from 'styled-components';

import { LegacyTheme, InputNumber } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    height: 100%;
`;

export const SectionTitle = styled.h1`
    color: ${LegacyTheme.black};
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 16px;
    }
`;

export const Label = styled.label`
    min-width: 130px;
    font-size: 13px;
    line-height: 16px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    margin-bottom: ${({ noMargin }) => (!noMargin ? '5px' : '')};
`;

export const SelectField = styled(Field)`
    flex-direction: column;
    align-items: flex-start;
`;

export const ErrorLabel = styled.p`
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    color: ${LegacyTheme.defaultWarningColor};
    margin: 0;
`;

export const StageInputNumber = styled(InputNumber)`
    max-width: 62px;
    height: 36px;
    text-align: center;
    padding: 0px 0px 0px 10px;
`;

export const FlexboxSpacer = styled.div``;

export const SidebarSectionWrapper = styled.div`
    border-bottom: 1px solid ${LegacyTheme.defaultBorder};
`;
