// @flow

import React from 'react';

// Styles
import { Ellipsis, Controls, NavigationButton, PageLink } from './styles';

type Props = {
    currentPage: number,
    isLoading: boolean,
    pagesTotal: number,
    onPageSelection: (number, number) => void,
};

export const PaginationControls = (props: Props) => {
    if (!props) {
        return null;
    }

    const maxPagesToDisplay = 3;

    // Create the page range to be displayed based on the page at hand and the total vs the maximum pages to be displayed at once in the pagination control
    const pageRange = [
        ...Array(
            props.pagesTotal <= maxPagesToDisplay ? props.pagesTotal : maxPagesToDisplay
        ).keys(),
    ].map((currentValue: number) => {
        // Cases when there is now overflow of pages or where are viewing the leftmost pages (starting at 1)
        if (
            props.pagesTotal <= maxPagesToDisplay ||
            props.currentPage <= Math.floor(maxPagesToDisplay / 2) + 1
        ) {
            return currentValue + 1;
            // Case when there is overflow of pages and where are viewing the rightmost pages (ending at the very last page)
        } else if (
            props.currentPage >=
            props.pagesTotal -
                Math.floor(maxPagesToDisplay / 2) +
                (maxPagesToDisplay % 2 === 0 ? 1 : 0)
        ) {
            return (
                props.pagesTotal -
                Math.floor(maxPagesToDisplay / 2) +
                currentValue -
                Math.floor(maxPagesToDisplay / 2) +
                (maxPagesToDisplay % 2 === 0 ? 1 : 0)
            );
            // Case when there is overflow of pages and where are viewing a mid-section range of pages (not any of the edges)
        } else {
            return (
                props.currentPage +
                currentValue -
                Math.floor(maxPagesToDisplay / 2) +
                (props.currentPage <= Math.floor(maxPagesToDisplay / 2)
                    ? Math.floor(maxPagesToDisplay / 2)
                    : 0)
            );
        }
    });

    const pageList = pageRange.map((singleItem: number, index: number) => (
        <PageLink
            key={singleItem}
            selected={singleItem === props.currentPage}
            includeSeparator={index !== 0}
            onClick={
                // eslint-disable-next-line no-undefined
                !props.isLoading ? props.onPageSelection(singleItem, props.pagesTotal) : undefined
            }
        >
            {singleItem}
        </PageLink>
    ));

    return (
        <Controls isLoading={props.isLoading}>
            {props.currentPage !== 1 && (
                <NavigationButton
                    navigationSense="left"
                    onClick={
                        !props.isLoading
                            ? props.onPageSelection(props.currentPage - 1, props.pagesTotal)
                            : // eslint-disable-next-line no-undefined
                              undefined
                    }
                />
            )}
            <Ellipsis
                hidden={
                    props.pagesTotal <= maxPagesToDisplay ||
                    props.currentPage <= Math.floor(maxPagesToDisplay / 2) + 1
                }
                paddingRight
            />
            {props.pagesTotal > 1 && pageList}
            <Ellipsis
                hidden={
                    props.currentPage >=
                    props.pagesTotal -
                        Math.floor(maxPagesToDisplay / 2) +
                        (maxPagesToDisplay % 2 === 0 ? 1 : 0)
                }
                paddingLeft
            />
            {props.currentPage !== props.pagesTotal && (
                <NavigationButton
                    navigationSense="right"
                    onClick={
                        !props.isLoading
                            ? props.onPageSelection(props.currentPage + 1, props.pagesTotal)
                            : // eslint-disable-next-line no-undefined
                              undefined
                    }
                />
            )}
        </Controls>
    );
};
