// @flow

import colors from '../colors';

const primaryColor200 = '#50f298';
const primaryColor400 = 'darkseagreen';
const primaryColor600 = '#729672';
const secondaryColor400 = 'rebeccapurple';
const secondaryColor600 = '#51287A';

const greyKeys = Object.keys(colors).filter((key: string) => key.includes('grey'));
const greyThumbs: Array<{ value: string }> = greyKeys.map((key: string) => ({
    value: colors[key],
}));

const themeColors = [
    {
        title: 'Primary',
        thumbs: [
            {
                label: 'Primary General',
                value: primaryColor400,
            },
            {
                label: 'Primary Hover',
                value: primaryColor600,
            },
        ],
    },
    {
        title: 'Alternative',
        thumbs: [
            {
                label: 'Alternative General',
                value: secondaryColor400,
            },
            {
                label: 'Alternative Hover',
                value: secondaryColor600,
            },
        ],
    },
    {
        title: 'Feedback',
        thumbs: [
            {
                label: 'Success',
                value: colors.green6AB,
            },
            {
                label: 'Fail/Error',
                value: colors.redE20,
            },
        ],
    },
    {
        title: 'Grey',
        thumbs: greyThumbs,
    },
];

const alternativeTheme = {
    name: 'Alternative',
    themeColors,
    colors: {
        primary: primaryColor400,
        primaryHover: primaryColor600,
        alternative: secondaryColor400,
        alternativeHover: secondaryColor600,
    },
    buttonHover: {
        backgroundColor: primaryColor400,
        backgroundColorActive: primaryColor600,
        backgroundColorHover: primaryColor600,
    },
    buttons: {
        height: 'auto',
        padding: '10px 30px',
        borderRadius: '0px',
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '0px',
        primary: {
            backgroundColor: primaryColor400,
            backgroundColorHover: primaryColor600,
            color: colors.white,
            colorHover: colors.white,
        },
        secondary: {
            backgroundColor: secondaryColor400,
            backgroundColorHover: secondaryColor600,
            color: colors.white,
            colorHover: colors.white,
        },
        buttonGrid: {
            color: colors.white,
            colorActive: colors.white,
            colorDanger: colors.white,
            backgroundColor: primaryColor400,
            backgroundColorActive: primaryColor600,
            backgroundColorDanger: colors.redDE0,
            backgroundColorHover: primaryColor600,
            borderColor: primaryColor400,
            borderColorDanger: colors.redDE0,
        },
    },
    hr: {
        borderColor: primaryColor200,
        borderStyle: 'dashed',
        borderWidth: '3px',
    },
    dotMenu: {
        color: colors.white,
    },
    messageBlock: {
        color: colors.white,
        backgroundColor: primaryColor400,
        borderRadius: '0px',
        padding: '24px 32px',
        icon: {
            backgroundColor: colors.white,
            color: primaryColor400,
        },
    },
    subtleLink: {
        color: primaryColor400,
        colorHover: primaryColor600,
    },
    formElements: {
        radioButton: {
            color: primaryColor400,
            colorDisabled: colors.greyFC,
            backgroundColor: colors.white,
            backgroundColorHover: primaryColor200,
            border: primaryColor200,
            borderHover: primaryColor600,
            dotColorActive: primaryColor600,
            dotColorDisabled: colors.greyFC,
        },
        toggle: {
            borderRadius: '0px',
            width: '60px',
            height: '24px',
            toggler: {
                width: '20px',
                height: '18px',
                margin: '3px',
            },
            checked: {
                backgroundColor: colors.white,
                borderColor: primaryColor400,
                togglerColor: primaryColor400,
                disabled: {
                    backgroundColor: colors.greyF2,
                    borderColor: primaryColor400,
                    togglerColor: primaryColor200,
                },
                hover: {
                    backgroundColor: colors.white,
                    borderColor: primaryColor200,
                    togglerColor: primaryColor200,
                },
            },
            unchecked: {
                backgroundColor: colors.white,
                borderColor: colors.grey9C,
                togglerColor: colors.grey9C,
                disabled: {
                    backgroundColor: colors.greyF2,
                    borderColor: colors.greyE6,
                    togglerColor: colors.greyC6,
                },
                hover: {
                    backgroundColor: colors.white,
                    borderColor: colors.grey7B,
                    togglerColor: colors.grey7B,
                },
            },
        },
    },
    loader: {
        color: primaryColor600,
        backgroundColor: primaryColor200,
    },
    tabNav: {
        color: primaryColor400,
        colorActive: primaryColor600,
        border: primaryColor400,
        borderHover: primaryColor200,
    },
    navDropdown: {
        width: '160px',
        padding: '10px 24px',
        backgroundColor: colors.greyF2,
        borderColor: colors.greyE6,
        color: colors.grey33,
        colorHover: colors.grey1A,
        fontSize: '16px',
        dropdown: {
            backgroundColor: colors.greyF2,
            color: colors.grey33,
            colorHover: colors.grey1A,
        },
    },
    removableLabel: {
        backgroundColor: primaryColor600,
        color: colors.white,
        closeButton: {
            fill: colors.white,
            backgroundColor: primaryColor600,
        },
    },
    tagList: {
        item: {
            height: '30px',
            margin: '4px 8px 4px 0',
            padding: '4px 16px',
            backgroundColor: primaryColor200,
            boxShadow: `0 2px 4px 0 ${colors.black05}`,
            color: colors.white,
            borderRadius: '4px',
            fontSize: '15px',
            close: {
                fill: 'white',
            },
        },
    },
    icons: {
        plusDotIcon: {
            backgroundColor: primaryColor200,
            color: primaryColor600,
        },
    },
};

export default alternativeTheme;
