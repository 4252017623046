// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { fromJS } from 'immutable';
import momentTZ from 'moment-timezone';
// Constants
import { Column, DotMenu, Pagination, PrimaryButton, Row, Table, ToolTip } from 'components/_ReactUI_V1';
import { NAVIGATION_ROUTES } from 'utils/constants';

import { PLANT_STATUSES } from 'utils/constants';

// Components
import ConfirmationModal from 'components/Modals/ConfirmationModal';

// Styles
import { ControlWrapper, TableContainer, ToolTipContent } from './styles';
import { BlankButton, Count, Title } from 'styles/common';

// Types
import type { ErrorType, HistoryType, ImmutableList, IntlType, SearchCriteria } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';

type Props = {
    errors: ErrorType,
    handleDeletePlant: (id: number) => void,
    intl: IntlType,
    history: HistoryType,
    lastPage: number,
    onHandlePaginationPageSelection: (number, number) => () => null | void,
    onHandleSortBy: (SearchCriteria) => void,
    pagination: { page: number },
    plantIsDeleting: boolean,
    plants: ImmutableList<ImmutablePlant>,
    plantsAreQuerying: boolean,
    searchCriteria: SearchCriteria,
    onUpdatePlantProcess: (ImmutablePlant) => void,
    handleDownloadClicked: (plantId: number, fileName: string) => void,
    onPlantConfigV2: (ImmutablePlant) => void,
};

type State = {
    enableAddRow: boolean,
    idOfPlantToDelete: ?number,
};

class PlantManagerTable extends React.Component<Props, State> {
    state = {
        enableAddRow: true,
        idOfPlantToDelete: null,
    };

    /**
     * On componentDidUpdate check if props.plants has changed, if so reset UI via state
     */
    componentDidUpdate(prevProps: Props) {
        const { plants } = this.props;
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (prevProps.plants !== plants) {
            this.setState({
                enableAddRow: true,
                idOfPlantToDelete: null,
            });
        }
    }

    handleCreatePlant = () => this.props.history.push(NAVIGATION_ROUTES.PLANT_CREATION_V2);

    handleUpdatePlant = (plant: ImmutablePlant) => () => this.props.onUpdatePlantProcess(plant);

    handleUpdatePlantV2 = (plant: ImmutablePlant) => () => this.props.onPlantConfigV2(plant);

    /**
     * On Click, set idOfPlantToDelete in state for this.renderDeleteConfirmationModal()
     */
    handleSetRowIdToDelete = (plantId: ?number) => () => {
        this.setState({
            idOfPlantToDelete: plantId,
        });
    };

    handleDeletePlant = (id: number) => () => this.props.handleDeletePlant(id);

    handleDownloadClicked = (plantId: number) => () =>
        this.props.handleDownloadClicked(
            plantId,
            this.props.intl.formatMessage(
                {
                    id: 'components.PlantManager.PlantSetupSteps.DONE.download.FileName',
                },
                { plantId }
            )
        );

    /**
     * Provide row content
     */
    provideRow = (plant: ImmutablePlant) => {
        const editPlantLabel = this.props.intl.formatMessage({
            id: 'components.PlantManager.PlantManagerTable.editPlant',
        });

        const editPlantV2Label = this.props.intl.formatMessage({
            id: 'components.PlantManager.PlantManagerTable.editPlantV2',
        });

        return plant
            .set(
                'language',
                this.props.intl.formatMessage({
                    id: `constants.Languages.${plant.get('language')}`,
                })
            )
            .set('client', plant.getIn(['client', 'name']))
            .set(
                'status',
                this.props.intl.formatMessage({
                    id: `components.PlantManager.PlantManagerTable.statuses.${plant.get('status')}`,
                })
            )
            .set(
                'controls',
                <ControlWrapper>
                    <ToolTip
                        content={
                            <ToolTipContent>
                                <li>
                                    <BlankButton
                                        onClick={this.handleUpdatePlant(plant)}
                                        title={editPlantLabel}
                                    >
                                        {editPlantLabel}
                                    </BlankButton>
                                </li>
                                <li>
                                    <BlankButton
                                        onClick={this.handleUpdatePlantV2(plant)}
                                        title={editPlantV2Label}
                                    >
                                        {editPlantV2Label}
                                    </BlankButton>
                                </li>
                                <li>{this.renderDownloadButton(plant)}</li>
                                <li>{this.renderDeleteButton(plant)}</li>
                            </ToolTipContent>
                        }
                        position="bottom"
                        trigger={<DotMenu />}
                        triggerType="click"
                        closeOnInternalClick
                        interactive
                    />
                </ControlWrapper>
            );
    };

    provideTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.PlantManager.valueHeaders.name',
            }),
            id: 'name',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.PlantManager.valueHeaders.client',
            }),
            id: 'client',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.PlantManager.valueHeaders.language',
            }),
            id: 'language',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.PlantManager.valueHeaders.timezone',
            }),
            id: 'timezone',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.PlantManager.valueHeaders.status',
            }),
            id: 'status',
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    /**
     * Provided this.state.idOfPlantToDelete is set, display Modal and PlantToDeleteModalContent
     */
    renderDeleteConfirmationModal = () =>
        this.state.idOfPlantToDelete && (
            <ConfirmationModal
                title={this.props.intl.formatMessage({
                    id: 'components.PlantManager.PlantManagerTable.deleteModal.title',
                })}
                confirmButtonText={this.props.intl.formatMessage({
                    id: 'components.PlantManager.PlantManagerTable.deleteModal.confirmButton',
                })}
                errors={this.props.errors}
                loading={this.props.plantIsDeleting}
                onConfirm={
                    this.state.idOfPlantToDelete
                        ? this.handleDeletePlant(this.state.idOfPlantToDelete)
                        : null
                }
                onCancel={this.handleSetRowIdToDelete(null)}
                danger
            />
        );

    /**
     * Render JSON template download button
     */
    renderDownloadButton = (plant: ImmutablePlant) => (
        <BlankButton onClick={this.handleDownloadClicked(plant.get('id'))}>
            {this.props.intl.formatMessage({
                id: 'components.PlantManager.PlantSetupSteps.DONE.download.Label',
            })}
        </BlankButton>
    );

    /**
     * Render delete button, onClick sets state.idOfPlantToDelete with provided plant's id
     */
    renderDeleteButton = (plant: ImmutablePlant) => (
        <BlankButton onClick={this.handleSetRowIdToDelete(plant.get('id'))}>
            {this.props.intl.formatMessage({
                id: 'components.PlantManager.PlantManagerTable.deletePlant',
            })}
        </BlankButton>
    );

    render() {
        const rows = this.props.plants
            .map((plant: ImmutablePlant) => this.provideRow(plant))
            .toJS();

        return (
            <React.Fragment>
                {this.renderDeleteConfirmationModal()}
                <Row alignItems="center" flex="0">
                    <Column>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.PlantManager.PlantManagerTable.title',
                            })}
                            <Count>({this.props.plants.size})</Count>
                        </Title>
                    </Column>
                    <Column alignItems="flex-end">
                        <PrimaryButton
                            disabled={!this.state.enableAddRow || this.props.plantsAreQuerying}
                            onClick={this.handleCreatePlant}
                            text={this.props.intl.formatMessage({
                                id: 'components.PlantManager.PlantManagerTable.createNew',
                            })}
                        />
                    </Column>
                </Row>
                <TableContainer>
                    <Table
                        currentSorting={this.props.searchCriteria}
                        header={this.provideTableHeaders()}
                        loading={this.props.plantsAreQuerying}
                        onSortBy={this.props.onHandleSortBy}
                        rows={rows}
                        footerMessage={
                            !this.props.plantsAreQuerying &&
                            !rows.length &&
                            this.props.intl.formatMessage({
                                id: 'components.PlantManager.PlantManagerTable.table.noPlants',
                            })
                        }
                    />
                    <Pagination
                        currentPage={this.props.pagination.page}
                        isLoading={this.props.plantsAreQuerying}
                        onPageSelection={this.props.onHandlePaginationPageSelection}
                        pagesTotal={this.props.lastPage}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryJoiner',
                        })}
                    />
                </TableContainer>
            </React.Fragment>
        );
    }
}

export default withRouter(injectIntl(PlantManagerTable));
