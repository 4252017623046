// @flow strict

import { push } from 'react-router-redux';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import { NAVIGATION_ROUTES, ISOTHERM_VALUE_MODES } from 'utils/constants';

import {
    create,
    find,
    update,
    destroy,
    compute,
    compute2D,
    compute3D,
    performCompute2DDataset,
    performExport2DSensitivityToCSV,
    runScenario,
    postStreamValue,
    putStreamValue,
    postDatasetValue,
    putDatasetValue,
    postStageValue,
    putStageValue,
} from 'services/Dataset/resources';

import {
    receivedCreateSuccess,
    receivedCreateFailure,
    // receivedFetchListSuccess,
    // receivedFetchListFailure,
    receivedFetchSuccess,
    receivedFetchFailure,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    receivedDestroySuccess,
    receivedDestroyFailure,
    receivedComputeSuccess,
    receivedComputeFailure,
    receivedCompute2DSuccess,
    receivedCompute2DFailure,
    receivedCompute2DDatasetSuccess,
    receivedCompute2DDatasetFailure,
    receivedExport2DSensitivityCSVSuccess,
    receivedExport2DSensitivityCSVFailure,
    receivedCompute3DSuccess,
    receivedCompute3DFailure,
    setIsFetchingStatus,
    setIsUpdatingStatus,
    setIsDeletingStatus,
    setIsCreatingStatus,
    setIsComputingStatus,
    setIsComputing2DStatus,
    setIsExportingStatus,
    setIsComputing3DStatus,
    setIsComputing2DDatasetStatus,
    setIsScenarioSubmittingStatus,
    receivedSubmitScenarioSuccess,
    receivedSubmitScenarioFailure,
    clearScenarioResults,
    receivedDatasetValueCreateOrUpdateSuccess,
    receivedDatasetValueCreateOrUpdateFailure,
    setIsCreatingOrUpdatingDatasetValue,
    receivedStreamValueCreateOrUpdateSuccess,
    receivedStreamValueCreateOrUpdateFailure,
    setIsCreatingOrUpdatingStreamValue,
    receivedStageValueCreateOrUpdateSuccess,
    receivedStageValueCreateOrUpdateFailure,
    setIsCreatingOrUpdatingStageValue,
    setIsDatasetComputed,
    setIsDatasetSaved,
} from 'services/Dataset/actions';

import {
    setIsCreatingStatus as setIsothermIsCreatingStatus,
    receivedCreateSuccess as receivedIsothermCreateSuccess,
} from 'services/Isotherm/actions';

import { createUntranslatedFeedback } from 'services/Feedback/actions';

import type {
    Dataset,
    DatasetValue,
    ImmutableDataset,
    IsothermStageValue,
    RawDatasetValue,
    RawStageValue,
    RawStreamValue,
    ScenarioKpi,
    SensitivityOptionConstant,
    StageValue,
    StreamValue,
    ZSensitivityOptionConstant,
} from 'services/Dataset/types';
import { report } from 'services/Errors/resources';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Updates Isotherm list in redux store by calling services/Isotherm actions
 */
const updateIsothermsFromIsothermsStageValues = (
    isothermStageValues: Array<IsothermStageValue>
) => (dispatch: ReduxDispatch) => {
    isothermStageValues.forEach((isothermStageValue: IsothermStageValue) => {
        if (
            isothermStageValue.valueType === ISOTHERM_VALUE_MODES.SELECT &&
            isothermStageValue.isotherm
        ) {
            dispatch(setIsothermIsCreatingStatus());
            dispatch(receivedIsothermCreateSuccess(isothermStageValue.isotherm));
        }
    });
};

/**
 * Create new dataset
 */
export const createDataset = (dataset: ImmutableDataset) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingStatus());
    create(dataset)
        .then((response: Dataset) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createDatasetSuccess')
            );
            updateIsothermsFromIsothermsStageValues(response.isothermStageValues)(dispatch);
            const datasetId = response.id;
            const circuitId = response.circuitId;
            dispatch(
                push(
                    `${NAVIGATION_ROUTES.COMPUTATION}${circuitId}${
                        NAVIGATION_ROUTES.COMPUTATION_DATASET
                    }${datasetId}`
                )
            );
            dispatch(setIsDatasetSaved(true));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createDatasetFailed'));
        });
};

/**
 * Fetch a specific dataset
 */
export const fetchDataset = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingStatus());
    find(id)
        .then((response: ImmutableDataset) => {
            dispatch(receivedFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchDatasetFailed'));
        });
};

/**
 * Update dataset
 */
export const updateDataset = (id: number, dataset: ImmutableDataset) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingStatus());
    update(id, dataset)
        .then((response: ImmutableDataset) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateDataset'));
            updateIsothermsFromIsothermsStageValues(response.isothermStageValues)(dispatch);
            dispatch(setIsDatasetSaved(true));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateDatasetFailed'));
        });
};

/**
 * Compute a dataset
 */
export const destroyDataset = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsDeletingStatus());
    destroy(id)
        .then((response: ImmutableDataset) => {
            dispatch(receivedDestroySuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.destroyDatasetSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDestroyFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.destroyDatasetFailed'));
        });
};

/**
 * Compute dataset
 */
export const computeDataset = (
    dataset: ImmutableDataset,
    cascadeRelaxationFactor: number,
    returnRelaxationFactor: number
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsComputingStatus());
    compute(dataset, cascadeRelaxationFactor, returnRelaxationFactor)
        .then((response: ImmutableDataset) => {
            dispatch(setIsDatasetSaved(false));
            dispatch(receivedComputeSuccess(response));
            if (response.converged) {
                dispatch(
                    createUntranslatedFeedback(
                        'SUCCESS',
                        'feedback.success.computeDatasetConvergence'
                    )
                );
            } else {
                dispatch(
                    createUntranslatedFeedback('ERROR', 'feedback.error.computeDatasetConvergence')
                );
            }
            dispatch(setIsDatasetComputed(true));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedComputeFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.computeDatasetFailed'));
        });
};

/**
 * Perform 2D sensitivity analysis
 * {
 *   "dataset_id": 145,
 *   "sensitivity_variable": "PLS_CU",
 *   "range_min": 1,
 *   "range_max": 3
 * }
 */
export const compute2DSensitivity = (
    datasetId: number,
    variable: SensitivityOptionConstant,
    rangeMin: number,
    rangeMax: number
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsComputing2DStatus());
    compute2D(datasetId, variable, rangeMin, rangeMax)
        .then((response: ImmutableDataset) => {
            dispatch(receivedCompute2DSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.compute2DSensitivity')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCompute2DFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.compute2DSensitivityFailed')
            );
        });
};

export const export2DSensitivityToCSV = (settings: Object) => (dispatch: ReduxDispatch) => {
    dispatch(setIsExportingStatus());
    return performExport2DSensitivityToCSV(settings)
        .then((response: ImmutableDataset) => {
            dispatch(receivedExport2DSensitivityCSVSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.export2DSensitivityCSV')
            );
            dispatch(setIsExportingStatus(false));
            return true;
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedExport2DSensitivityCSVFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.export2DSensitivityCSVFailed')
            );
            dispatch(setIsExportingStatus(false));
            return false;
        });
};

/**
 * Perform 3D sensitivity analysis
 */
export const compute3DSensitivity = (
    datasetId: number,
    xVariable: SensitivityOptionConstant,
    xRangeMin: number,
    xRangeMax: number,
    yVariable: SensitivityOptionConstant,
    yRangeMin: number,
    yRangeMax: number,
    zVariable: ZSensitivityOptionConstant
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsComputing3DStatus());
    compute3D(
        datasetId,
        xVariable,
        xRangeMin,
        xRangeMax,
        yVariable,
        yRangeMin,
        yRangeMax,
        zVariable
    )
        .then((response: ImmutableDataset) => {
            dispatch(receivedCompute3DSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.compute3DSensitivity')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCompute3DFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.compute3DSensitivityFailed')
            );
        });
};

export const compute2DDataset = (
    datasetId: number,
    variable: SensitivityOptionConstant,
    variableValue: number
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsComputing2DDatasetStatus());
    performCompute2DDataset(datasetId, variable, variableValue)
        .then((response: ImmutableDataset) => {
            if (response.converged) {
                dispatch(receivedCompute2DDatasetSuccess(response));
                dispatch(
                    createUntranslatedFeedback('SUCCESS', 'feedback.success.compute2DDataset')
                );
            } else {
                dispatch(receivedCompute2DDatasetFailure(response));
                dispatch(
                    createUntranslatedFeedback('ERROR', 'feedback.error.compute2DDatasetFailed')
                );
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCompute2DDatasetFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.compute2DDatasetFailed'));
        });
};

export const submitScenario = (circuitId: number, datasetId: number, kpis: Array<ScenarioKpi>) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsScenarioSubmittingStatus());
    runScenario(circuitId, datasetId, kpis)
        .then((response: ImmutableDataset) => {
            dispatch(receivedSubmitScenarioSuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.runScenario'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedSubmitScenarioFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.runScenarioFailed'));
        });
};

export const clearScenario = () => (dispatch: ReduxDispatch) => dispatch(clearScenarioResults());

export const createDatasetValue = (
    circuitId: number,
    datasetId: number,
    data: $Shape<RawDatasetValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingDatasetValue());
    postDatasetValue(circuitId, datasetId, data)
        .then((response: DatasetValue) => {
            dispatch(receivedDatasetValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createDatasetValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDatasetValueCreateOrUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.createDatasetValueFailure')
            );
        });
};

export const updateDatasetValue = (
    circuitId: number,
    datasetId: number,
    datasetValueId: number,
    data: $Shape<DatasetValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingDatasetValue());
    putDatasetValue(circuitId, datasetId, datasetValueId, data)
        .then((response: DatasetValue) => {
            dispatch(receivedDatasetValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.updateDatasetValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDatasetValueCreateOrUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateDatasetValueFailure')
            );
        });
};

export const createStreamValue = (
    circuitId: number,
    datasetId: number,
    data: $Shape<RawStreamValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingStreamValue());
    postStreamValue(circuitId, datasetId, data)
        .then((response: StreamValue) => {
            dispatch(receivedStreamValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createStreamValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedStreamValueCreateOrUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.createStreamValueFailure')
            );
        });
};

export const updateStreamValue = (
    circuitId: number,
    datasetId: number,
    streamValueId: number,
    data: $Shape<StreamValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingStreamValue());
    putStreamValue(circuitId, datasetId, streamValueId, data)
        .then((response: StreamValue) => {
            dispatch(receivedStreamValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.updateStreamValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedStreamValueCreateOrUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateStreamValueFailure')
            );
        });
};

export const createStageValue = (
    circuitId: number,
    datasetId: number,
    data: $Shape<RawStageValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingStageValue());
    postStageValue(circuitId, datasetId, data)
        .then((response: StageValue) => {
            dispatch(receivedStageValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createStageValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedStageValueCreateOrUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createStageValueFailure'));
        });
};

export const updateStageValue = (
    circuitId: number,
    datasetId: number,
    stageValueId: number,
    data: $Shape<StageValue>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingOrUpdatingStageValue());
    putStageValue(circuitId, datasetId, stageValueId, data)
        .then((response: StageValue) => {
            dispatch(receivedStageValueCreateOrUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.updateStageValueSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedStageValueCreateOrUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateStageValueFailure'));
        });
};
