// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import {
    RadioButtonSet,
    PrimaryButton,
    CheckBoxV2,
    NavigationChevron,
    LegacyTheme,
    LoadingDots,
} from 'components/_ReactUI_V1';

// Constants
import {
    DESIGN_PRESET_STRUCTURE,
    DATASET_MODES,
    STAGE_VALUE_TYPES,
    STAGE_TYPES,
    ISOTHERM_BASE_CONSTANT,
    DESIGN_PRESET_TYPES,
    VALUE_STATUS,
} from 'utils/constants';
import { areAllStageIsothermsPredicted } from 'utils/helpers';

// Types
import type { IntlType } from 'types';
import type { IsothermBaseConstant } from 'services/Isotherm/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type {
    Preset,
    ImmutableDataset,
    DatasetModesConstant,
    ExtractionComputeConstant,
    ExtractionComputeUsingConstant,
    StrippingComputeConstant,
    StrippingComputeUsingConstant,
} from 'services/Dataset/types';

// Styles
import {
    SidebarBody,
    BodySection,
    SectionTitle,
    SubRadioSetWrapper,
    SubRadioLabel,
    FooterSection,
} from '../styles';
import {
    HeaderTitleWrapper,
    HeaderTitle,
    HeaderSubtitle,
    PredictIsothermCheckboxStyle,
} from './styles';

type OptionType = { label: string, value: number | string };

export type State = {
    confirmedMode: boolean,
    datasetMode: DatasetModesConstant,

    predictAllIsotherms: boolean,
    activeExtractType: ?ExtractionComputeConstant,
    activeExtractSubtype: ?ExtractionComputeUsingConstant,

    activeStripType: ?StrippingComputeConstant,
    activeStripSubtype: ?StrippingComputeUsingConstant,
};

type Props = {
    intl: IntlType,
    circuit: ImmutableCircuit,
    dataset: ?ImmutableDataset,
    isDisabled: boolean,

    hasExtractors: boolean,
    hasStrippers: boolean,

    loadingCircuit: boolean,
    datasetMode: ?DatasetModesConstant,
    isGenerated: boolean,
    areAdvancedExtractStreams: boolean,
    areAdvancedStripStreams: boolean,
    displayedDiagramPreset: ?Preset,
    onHandleGenerateDiagramClicked: (state: State) => void,
    onHandleConfirmMode: (datasetMode: ?DatasetModesConstant) => void,
};

class MimicSidebarSection extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.getDefaultState(null);
    }

    /**
     * If the dataset changes, reset the state.
     */
    componentDidUpdate(prevProps: Props) {
        if (
            (this.props.dataset && this.props.dataset.get('id')) !==
            (prevProps.dataset && prevProps.dataset.get('id'))
        ) {
            this.setState((prevState: State) => this.getDefaultState(prevState));
        }
        if (
            // Check if the predict all isotherms option has changed.
            // This will happen when a user first selected "Predict All Isotherms" then manually changed an isotherm to selected.
            this.props.displayedDiagramPreset &&
            prevProps.displayedDiagramPreset &&
            prevProps.displayedDiagramPreset.predictIsotherm !==
                this.props.displayedDiagramPreset.predictIsotherm
        ) {
            this.setState({
                predictAllIsotherms: this.props.displayedDiagramPreset.predictIsotherm,
            });
        }
    }

    /**
     * Check if we have extractors, selected activeExtractType and activeExtractSubtype for a valid preset.
     * Return true if there are no extractors.
     */
    validExtraсtSidebarSelection = (): boolean =>
        this.state.datasetMode === DATASET_MODES.ANALYSIS_MODE ||
        !this.props.hasExtractors ||
        this.state.activeExtractType === STAGE_VALUE_TYPES.OA_RATIO ||
        Boolean(this.state.activeExtractType && this.state.activeExtractSubtype);

    /**
     * Check if we have strippers and selected activeStripType and activeExtractSubtype for a valid preset.
     * In case the subtype is OA, no activeStripSubtype required.
     * for a valid preset.
     * Return true if there are no strippers.
     */
    validStripSidebarSelection = (): boolean =>
        this.state.datasetMode === DATASET_MODES.ANALYSIS_MODE ||
        !this.props.hasStrippers ||
        this.state.activeStripType === STAGE_VALUE_TYPES.OA_RATIO ||
        Boolean(this.state.activeStripType && this.state.activeStripSubtype);

    /**
     * Check if the presets actually displayed are the same as the ones on the sidebar
     */
    arePresetDisplayedIdentical = () => {
        if (this.state.datasetMode === DATASET_MODES.ANALYSIS_MODE) {
            return (
                this.props.displayedDiagramPreset &&
                this.props.displayedDiagramPreset.predictIsotherm === this.state.predictAllIsotherms
            );
        }
        // if have both
        if (this.props.hasExtractors && this.props.hasStrippers) {
            return (
                this.props.displayedDiagramPreset &&
                this.props.displayedDiagramPreset.predictIsotherm ===
                    this.state.predictAllIsotherms &&
                this.props.displayedDiagramPreset.EXTRACT &&
                this.props.displayedDiagramPreset.EXTRACT.compute ===
                    this.state.activeExtractType &&
                this.props.displayedDiagramPreset.EXTRACT.computeUsing ===
                    this.state.activeExtractSubtype &&
                this.props.displayedDiagramPreset.STRIP &&
                this.props.displayedDiagramPreset.STRIP.compute === this.state.activeStripType &&
                this.props.displayedDiagramPreset.STRIP.computeUsing ===
                    this.state.activeStripSubtype &&
                (!this.props.displayedDiagramPreset.STRIP.computeUsing ||
                    this.props.displayedDiagramPreset.STRIP.computeUsing ===
                        this.state.activeStripSubtype)
            );
        } else if (this.props.hasExtractors && !this.props.hasStrippers) {
            // if has only extractors
            return (
                this.props.displayedDiagramPreset &&
                this.props.displayedDiagramPreset.predictIsotherm ===
                    this.state.predictAllIsotherms &&
                this.props.displayedDiagramPreset.EXTRACT &&
                this.props.displayedDiagramPreset.EXTRACT.compute ===
                    this.state.activeExtractType &&
                this.props.displayedDiagramPreset.EXTRACT.computeUsing ===
                    this.state.activeExtractSubtype
            );
        } else if (!this.props.hasExtractors && this.props.hasStrippers) {
            // if has only strippers
            return (
                this.props.displayedDiagramPreset &&
                this.props.displayedDiagramPreset.predictIsotherm ===
                    this.state.predictAllIsotherms &&
                this.props.displayedDiagramPreset.STRIP &&
                this.props.displayedDiagramPreset.STRIP.compute === this.state.activeStripType &&
                (!this.props.displayedDiagramPreset.STRIP.computeUsing ||
                    this.props.displayedDiagramPreset.STRIP.computeUsing ===
                        this.state.activeStripSubtype)
            );
        } else {
            // there is nothing to change on the sidebar since there are no stages
            return false;
        }
    };

    /**
     *  Enable generated button when all necessary preset values have been selected and
     *  when the preset are different from the one displayed
     */
    isGenerateButtonEnabled = () =>
        this.validExtraсtSidebarSelection() &&
        this.validStripSidebarSelection() &&
        (!this.arePresetDisplayedIdentical() || !this.props.isGenerated);

    /**
     * Gets the default state of the mimic diagram sidebar.
     */
    getDefaultState = (prevState: ?State): State => ({
        confirmedMode: Boolean(this.props.dataset) || Boolean(prevState && prevState.confirmedMode),
        predictAllIsotherms: areAllStageIsothermsPredicted(this.props.circuit, this.props.dataset),
        datasetMode: this.props.datasetMode || DATASET_MODES.DESIGN_MODE,
        activeExtractType: this.props.dataset && this.props.dataset.get('extractCompute'),
        activeExtractSubtype: this.props.dataset && this.props.dataset.get('extractComputeUsing'),
        activeStripType: this.props.dataset && this.props.dataset.get('stripCompute'),
        activeStripSubtype: this.props.dataset && this.props.dataset.get('stripComputeUsing'),
    });

    /**
     * Default values to compute from for extractor stages
     */
    getDefaultExtractSubType = (activeType: ExtractionComputeConstant): string => {
        if (
            this.props.areAdvancedExtractStreams &&
            (activeType === DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.RECOVERY_RAFFINATE ||
                activeType === DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.REAGENT_CONCENTRATION)
        ) {
            return DESIGN_PRESET_STRUCTURE.EXTRACT[activeType][1]; // 1 is for the flowrates
        } else {
            return DESIGN_PRESET_STRUCTURE.EXTRACT[activeType].length > 0
                ? DESIGN_PRESET_STRUCTURE.EXTRACT[activeType][0]
                : '';
        }
    };

    /**
     * Default values to compute from for stripping stages
     */
    getDefaultStripSubType = (activeType: StrippingComputeConstant): string => {
        if (
            this.props.areAdvancedStripStreams &&
            activeType === DESIGN_PRESET_TYPES.STRIP.COMPUTE.ADVANCE
        ) {
            return DESIGN_PRESET_STRUCTURE.STRIP[activeType][1]; // 1 is for the flowrates
        } else {
            return DESIGN_PRESET_STRUCTURE.STRIP[activeType].length > 0
                ? DESIGN_PRESET_STRUCTURE.STRIP[activeType][0]
                : '';
        }
    };

    /**
     * Gets the available extractor options.
     */
    getExtractorOptions = (): Array<OptionType> =>
        Object.keys(DESIGN_PRESET_STRUCTURE[STAGE_TYPES.EXTRACT])
            .map((key: string) => {
                if (
                    this.props.areAdvancedExtractStreams &&
                    key === DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE].OA_RATIO
                ) {
                    return null;
                }
                return {
                    label: this.props.intl.formatMessage({
                        id: `components.CircuitComputationSidebar.MimicDiagram.DesignMode.RadioOptions.${key}`,
                    }),
                    value: key,
                };
            })
            .filter(Boolean);

    /**
     * Gets the available stripping options.
     */
    getStrippingOptions = (): Array<OptionType> =>
        Object.keys(DESIGN_PRESET_STRUCTURE.STRIP).map((key: string) => ({
            label: this.props.intl.formatMessage({
                id: `components.CircuitComputationSidebar.MimicDiagram.DesignMode.RadioOptions.${key}`,
            }),
            value: key,
        }));

    /**
     * Gets the dataset modes available
     */
    getDatasetModesOptions = (): Array<OptionType> =>
        Object.keys(DATASET_MODES).map((key: string) => ({
            label: this.props.intl.formatMessage({
                id: `components.CircuitComputationSidebar.MimicDiagram.modes.${key}.label`,
            }),
            value: key,
        }));

    /**
     * When the user exits the selected mode (back arrow), therefore unselect the mode.
     */
    handleReturnClicked = () =>
        this.setState({ confirmedMode: false }, this.props.onHandleConfirmMode(null));

    /**
     * When the user changes the mode type without clicking on "Next"
     */
    handleModeRadioButtonChange = (datasetMode: DatasetModesConstant) =>
        this.setState({
            datasetMode,
            confirmedMode: false,

            predictAllIsotherms: false,
            activeExtractType: null,
            activeExtractSubtype: null,
            activeStripType: null,
            activeStripSubtype: null,
        });

    /**
     * When the user selects 'Predict All Isotherms' checkbox in prediction mode.
     */
    handlePredictAllIsothermsClicked = (event: InputEvent) =>
        this.setState({ predictAllIsotherms: Boolean(event.target.checked) });

    /**
     * When user presses "Next" and selects design mode.
     */
    handleConfirmMode = () =>
        this.setState(
            { confirmedMode: true },
            this.props.onHandleConfirmMode(this.state.datasetMode)
        );

    /**
     * When the extract type changes
     */
    handleExtractTypeChange = (activeExtractType: ExtractionComputeConstant) => {
        let enforcePredictAllIsotherms = false;
        if (activeExtractType === DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.REAGENT_CONCENTRATION) {
            enforcePredictAllIsotherms = true;
        }
        this.setState((prevState: State) => ({
            predictAllIsotherms: enforcePredictAllIsotherms || prevState.predictAllIsotherms,
            activeExtractType,
            activeExtractSubtype: this.getDefaultExtractSubType(activeExtractType),
        }));
    };

    isIsothermPredictionEnforced = () => {
        const calculatingReagentConcentration =
            DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.REAGENT_CONCENTRATION;
        return this.state.activeExtractType === calculatingReagentConcentration;
    };

    /**
     * When the extract subtype changes
     */
    handleExtractSubtypeChange = (activeExtractSubtype: ExtractionComputeUsingConstant) =>
        this.setState({ activeExtractSubtype });

    /**
     * When the strip type changes
     */
    handleStripTypeChange = (activeStripType: StrippingComputeConstant) =>
        this.setState({
            activeStripType,
            activeStripSubtype: this.getDefaultStripSubType(activeStripType),
        });

    /**
     * When the strip subtype changes
     */
    handleStripSubtypeChange = (activeStripSubtype: StrippingComputeUsingConstant) =>
        this.setState({ activeStripSubtype });

    /**
     * When the generate diagram button is clicked in either cases.
     */
    handleGenerateDiagramClicked = () => this.props.onHandleGenerateDiagramClicked(this.state);

    renderRadioButton = (stageType: IsothermBaseConstant) => (
        selectedMainOption: string,
        isActive: boolean
    ) => {
        if (!isActive) return null;

        const mainOption = DESIGN_PRESET_STRUCTURE[stageType][selectedMainOption];
        const activeValue =
            stageType === STAGE_TYPES.EXTRACT
                ? this.state.activeExtractSubtype
                : this.state.activeStripSubtype;
        const clickHandler =
            stageType === STAGE_TYPES.EXTRACT
                ? this.handleExtractSubtypeChange
                : this.handleStripSubtypeChange;

        let subOptions = mainOption.map((key: string) => ({
            label: this.props.intl.formatMessage({
                id: `components.CircuitComputationSidebar.MimicDiagram.DesignMode.RadioSubOptions.${key}`,
            }),
            value: key,
        }));

        // Disable suboptions if there are advanced streams between extract stages or if there are advanced streams between strip stages
        if (
            (this.props.areAdvancedExtractStreams &&
                stageType === STAGE_TYPES.EXTRACT &&
                // Disable sub options if the option is not OA ratio
                selectedMainOption !==
                    DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE].OA_RATIO) ||
            (this.props.areAdvancedStripStreams && stageType === STAGE_TYPES.STRIP)
        ) {
            subOptions = [];
        }

        return (
            subOptions.length !== 0 && (
                <SubRadioSetWrapper>
                    <RadioButtonSet
                        value={activeValue}
                        options={subOptions}
                        onClick={clickHandler}
                        styles={{
                            labelFontSize: '13px',
                            labelColor: LegacyTheme.defaultColor,
                        }}
                        disabled={this.props.isDisabled}
                    />
                </SubRadioSetWrapper>
            )
        );
    };

    renderDesignModeBody = () => {
        if (this.props.loadingCircuit) {
            return <LoadingDots />;
        }

        return [
            <BodySection key="isotherms-section">
                <SectionTitle>
                    {this.props.intl.formatMessage({
                        id: 'components.CircuitComputationSidebar.MimicDiagram.Isotherm.title',
                    })}
                </SectionTitle>
                <CheckBoxV2
                    checked={this.state.predictAllIsotherms}
                    label={this.props.intl.formatMessage({
                        id: 'components.CircuitComputationSidebar.MimicDiagram.Isotherm.predictAll',
                    })}
                    onClickHandler={this.handlePredictAllIsothermsClicked}
                    styles={PredictIsothermCheckboxStyle}
                    disabled={this.props.isDisabled || this.isIsothermPredictionEnforced()}
                />
            </BodySection>,

            this.props.hasExtractors && (
                <BodySection key="extract-section">
                    <SectionTitle>
                        {this.props.intl.formatMessage({
                            id:
                                'components.CircuitComputationSidebar.MimicDiagram.DesignMode.Extractor.title',
                        })}
                    </SectionTitle>
                    <RadioButtonSet
                        value={this.state.activeExtractType}
                        options={this.getExtractorOptions()}
                        onClick={this.handleExtractTypeChange}
                        renderSuffix={this.renderRadioButton(ISOTHERM_BASE_CONSTANT.EXTRACT)}
                        styles={{
                            labelFontSize: '13px',
                            labelColor: LegacyTheme.defaultColor,
                        }}
                        disabled={this.props.isDisabled}
                    />
                </BodySection>
            ),

            this.props.hasStrippers && (
                <BodySection key="stripper-section">
                    <SectionTitle>
                        {this.props.intl.formatMessage({
                            id:
                                'components.CircuitComputationSidebar.MimicDiagram.DesignMode.Stripping.title',
                        })}
                    </SectionTitle>
                    <RadioButtonSet
                        value={this.state.activeStripType}
                        options={this.getStrippingOptions()}
                        onClick={this.handleStripTypeChange}
                        renderSuffix={this.renderRadioButton(ISOTHERM_BASE_CONSTANT.STRIP)}
                        styles={{
                            labelFontSize: '13px',
                            labelColor: LegacyTheme.defaultColor,
                        }}
                        disabled={this.props.isDisabled}
                    />
                </BodySection>
            ),
        ];
    };

    renderAnalysisModeBody = () => {
        if (this.props.loadingCircuit) {
            return <LoadingDots />;
        }

        return (
            <BodySection>
                <SectionTitle>
                    {this.props.intl.formatMessage({
                        id: 'components.CircuitComputationSidebar.MimicDiagram.Isotherm.title',
                    })}
                </SectionTitle>
                <CheckBoxV2
                    checked={this.state.predictAllIsotherms}
                    label={this.props.intl.formatMessage({
                        id: 'components.CircuitComputationSidebar.MimicDiagram.Isotherm.predictAll',
                    })}
                    onClickHandler={this.handlePredictAllIsothermsClicked}
                    styles={PredictIsothermCheckboxStyle}
                    disabled={this.props.isDisabled}
                />
            </BodySection>
        );
    };

    renderConfirmedMode = () => (
        <SidebarBody>
            <BodySection header>
                <HeaderTitleWrapper onClick={this.handleReturnClicked}>
                    <NavigationChevron width="7px" height="10px" margin="5px 9px 5px 5px" />
                    <HeaderTitle>
                        {this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.MimicDiagram.modes.${
                                this.state.datasetMode
                            }.label`,
                        })}
                    </HeaderTitle>
                </HeaderTitleWrapper>
                <HeaderSubtitle>
                    {this.props.intl.formatMessage({
                        id: `components.CircuitComputationSidebar.MimicDiagram.modes.${
                            this.state.datasetMode
                        }.subLabel`,
                    })}
                </HeaderSubtitle>
            </BodySection>

            {this.state.datasetMode === DATASET_MODES.DESIGN_MODE
                ? this.renderDesignModeBody()
                : this.renderAnalysisModeBody()}

            <FooterSection>
                <PrimaryButton
                    text={this.props.intl.formatMessage({
                        id: `components.CircuitComputationSidebar.MimicDiagram.${
                            this.props.isGenerated ? 'updateDiagramButton' : 'generateDiagramButton'
                        }`,
                    })}
                    disabled={!this.isGenerateButtonEnabled() || this.props.isDisabled}
                    onClick={this.handleGenerateDiagramClicked}
                />
            </FooterSection>
        </SidebarBody>
    );

    renderMainRadioSuffix = (value: DatasetModesConstant) => (
        <SubRadioLabel>
            {this.props.intl.formatMessage({
                id: `components.CircuitComputationSidebar.MimicDiagram.modes.${value}.subLabel`,
            })}
        </SubRadioLabel>
    );

    renderNoMode = () => (
        <SidebarBody>
            <RadioButtonSet
                value={this.state.datasetMode}
                options={this.getDatasetModesOptions()}
                onClick={this.handleModeRadioButtonChange}
                renderSuffix={this.renderMainRadioSuffix}
                styles={{
                    labelFontSize: '17px',
                    labelColor: LegacyTheme.black,
                }}
            />
            <FooterSection withMargin>
                <PrimaryButton
                    text={this.props.intl.formatMessage({
                        id: 'components.CircuitComputationSidebar.MimicDiagram.nextButton',
                    })}
                    onClick={this.handleConfirmMode}
                />
            </FooterSection>
        </SidebarBody>
    );

    render() {
        if (this.state.confirmedMode) {
            return this.renderConfirmedMode();
        }
        return this.renderNoMode();
    }
}

export default injectIntl(MimicSidebarSection);
