// @noflow

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

// Assets
import externalLinkIconSource from '../../base/assets/ExternalLink/default.png';

const StyledSubtleBase = css`
    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['subtleLink', 'color'])};

    &:hover {
        color: ${({ theme }: Object) => themePropertyFetcher(theme, ['subtleLink', 'colorHover'])};
    }

    font-size: 12px;
    line-height: 1.2;

    cursor: ${({ disabled }: Object) => (disabled ? 'no-entry' : 'pointer')};

    text-decoration ${({ onClick, noUnderline }: Object) =>
        onClick && !noUnderline ? 'underline' : 'none'};

    transition: color ease 0.2s;

    // TODO: Replace image for StyledSVG
    ${({ externalLinkIcon }: Object) =>
        externalLinkIcon &&
        `
        &::before {
            content: '';
            display: inline-block;
            width: 30px;
            height: 30px;

            background-image: url(${externalLinkIconSource});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            vertical-align: -10px;
        }
    `};
`;

export const StyledSubtleAnchor = styled('a')`
    ${StyledSubtleBase};
`;

export const StyledSubtleLink = styled(Link)`
    ${StyledSubtleBase};
`;
