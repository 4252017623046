// @flow strict

import { decamelize, decamelizeKeys } from 'humps';

import request from 'services/request';

import type { SearchCriteria } from 'types';
import type { Oxime } from './types';

/**
 * Create oxime
 *
 * @param {Oxime} oxime
 */
export const create = (oxime: Oxime) =>
    request('oximes', {
        data: oxime,
        method: 'POST',
    });

/**
 * Fetch all oximes
 */
export const index = () =>
    request('oximes', {
        method: 'GET',
    });

/**
 * Update oxime
 *
 * @param {number} id
 * @param {Oxime} oxime
 */
export const update = (id: number, oxime: Oxime) =>
    request(`oximes/${id}`, {
        data: oxime,
        method: 'PUT',
    });

/**
 * Destroy oxime
 *
 * @param {number} id
 */
export const destroy = (id: number) => request(`oximes/${id}`, { method: 'DELETE' });

/**
 * Query all oximes
 */
export const query = (
    searchCriteria: SearchCriteria = {},
    page: number = 1,
    perPage: number = 10
) => {
    const options = decamelizeKeys({
        page,
        perPage,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder: searchCriteria.sortOrder ? searchCriteria.sortOrder.toLowerCase() : '',
    });
    return request('oximes/query', { method: 'GET' }, { ...options });
};
