// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { InfoIcon, Modal, GearIcon } from 'components/_ReactUI_V1';

import MinorKPIModal from 'components/Modals/MinorKPIModal';

// Constants
import { DATASET_VALUE_TYPES, PLANT_VALUE_TYPES, MODAL_WIDTH } from 'utils/constants';
import { TARGET_TYPES } from 'utils/kpiConstants';

// Helpers
import { getKPISettingUnit } from 'utils/kpiHelpers';
import {
    getDisplayValue,
    getValueColor,
    shouldShowKPITarget,
    getValue,
    renderIndicator,
} from './helpers';

// styles
import {
    Card,
    CardContent,
    CardTitle,
    CardTitleMain,
    KPIName,
    CardValueWrapper,
    CardValue,
    Value,
    Unit,
    KPICardFooter,
    SideBanner,
    SettingsButtonHover,
} from './styles';
import { KPIGraphColorSchemes } from 'styles/colors';

// Types
import type { IntlType, KPIColorSchemeType, ReduxDispatch } from 'types';
import type { TrendsPeriodConstant, ImmutableKPIHistory } from 'services/Trends/types';
import type { ImmutableKPICard, ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutableStage } from 'services/Circuit/types';

type Props = {
    intl: IntlType,
    userLanguage: string,
    timezone: string,

    kpi: ImmutableKPICard,
    kpiSetting: ImmutableKPISetting,
    // for minor kpis:
    kpiHistory?: ImmutableKPIHistory,

    stages?: ?ImmutableList<ImmutableStage>,

    isMajorKPI?: boolean,
    colorScheme?: KPIColorSchemeType,
    showMaxCuTransfer: boolean,
    plantCardStyle?: boolean,

    period?: ?TrendsPeriodConstant,
    active?: boolean,
    onChangeCurrentKPI?: (kpi: ImmutableKPICard) => void,
    onChangePeriodForMinor?: (kpiId: number, newPeriod: TrendsPeriodConstant) => void,
};

/*
    Major KPI card
 */
class KPICard extends React.PureComponent<Props> {
    static defaultProps = {
        active: false,
        colorScheme: KPIGraphColorSchemes[0],
        isMajorKPI: false,
        showMaxCuTransfer: false,
        onChangeCurrentKPI: () => undefined,
        onChangePeriodForMinor: () => undefined,
    };

    /* Display target for Cu Recovery or max for Cu Transfer */
    getHighlightedText = () => {
        const kpiSetting = this.props.kpiSetting;
        if (
            kpiSetting.get('kpiType') === DATASET_VALUE_TYPES.CU_TRANSFERRED ||
            kpiSetting.get('kpiType') === PLANT_VALUE_TYPES.OVERALL_CU_TRANSFER
        ) {
            // only display high target on cu transfer
            return this.props.intl.formatMessage(
                {
                    id: 'components.KPICard.maximum',
                },
                {
                    value: kpiSetting.get(TARGET_TYPES.HIGH_TARGET),
                    unit: getKPISettingUnit(kpiSetting, this.props.intl),
                }
            );
        }

        const target = kpiSetting.get(TARGET_TYPES.MAIN_TARGET);
        if (this.props.isMajorKPI && target) {
            return this.props.intl.formatMessage(
                {
                    id: 'components.KPICard.target',
                },
                {
                    value: target,
                    unit: getKPISettingUnit(kpiSetting, this.props.intl),
                }
            );
        }
    };

    // handleSettingsClicked = () => {

    // };

    handleOnCardClick = () =>
        this.props.onChangeCurrentKPI && this.props.onChangeCurrentKPI(this.props.kpi);

    renderSideBanner() {
        let sideBanner = <SideBanner active={false} color={null} />;
        if (this.props.isMajorKPI && this.props.colorScheme) {
            sideBanner = (
                <SideBanner active={this.props.active} color={this.props.colorScheme.targetLine} />
            );
        }
        return sideBanner;
    }

    renderFooter() {
        const colorScheme = this.props.colorScheme;
        if (!colorScheme) {
            return null;
        }
        if (!this.props.isMajorKPI) {
            return null;
        }

        const showTarget = shouldShowKPITarget(this.props.kpiSetting, this.props.showMaxCuTransfer);

        const settingsIcon = null;
        // (
        //     <SettingsButtonHover onClick={this.handleSettingsClicked}>
        //         <GearIcon width="15px" height="15px" margin="0" />
        //     </SettingsButtonHover>
        // );

        if (showTarget) {
            return (
                <KPICardFooter active={this.props.active} color={colorScheme.targetLine}>
                    {this.getHighlightedText()}
                    {settingsIcon}
                </KPICardFooter>
            );
        } else {
            return (
                <KPICardFooter empty>
                    <div />
                    {settingsIcon}
                </KPICardFooter>
            );
        }
    }

    render() {
        const kpiSetting = this.props.kpiSetting;

        const kpiValue = getDisplayValue(this.props.kpi, kpiSetting, this.props.intl);
        const kpiValueStringLength = kpiValue !== null ? kpiValue.toString().length : 0;

        const card = (
            <Card
                active={this.props.active}
                activeColor={
                    this.props.isMajorKPI &&
                    this.props.colorScheme &&
                    this.props.colorScheme.targetLine
                }
                onClick={
                    !this.props.active && this.props.isMajorKPI ? this.handleOnCardClick : null
                }
                plantCardStyle={this.props.plantCardStyle}
            >
                {this.renderSideBanner()}
                <CardContent plantCardStyle={this.props.plantCardStyle}>
                    <CardTitle>
                        <CardTitleMain>
                            <KPIName>{kpiSetting.get('name')}</KPIName>
                            {renderIndicator(this.props.kpi)}
                        </CardTitleMain>
                    </CardTitle>
                    <CardValueWrapper>
                        <CardValue>
                            <Value
                                color={getValueColor(
                                    this.props.kpi,
                                    kpiSetting,
                                    this.props.showMaxCuTransfer
                                )}
                                textLength={kpiValueStringLength}
                            >
                                {kpiValue}
                            </Value>
                            {getValue(this.props.kpi) !== null ? (
                                <Unit>{getKPISettingUnit(kpiSetting, this.props.intl)}</Unit>
                            ) : null}
                        </CardValue>
                    </CardValueWrapper>
                    {this.renderFooter()}
                </CardContent>
            </Card>
        );

        if (this.props.isMajorKPI) {
            return card;
        }

        return (
            <Modal trigger={card} padding="none" modalWidth={MODAL_WIDTH.XLARGE} modalHeight="100%">
                <MinorKPIModal
                    kpiSetting={kpiSetting}
                    kpiHistory={this.props.kpiHistory}
                    stages={this.props.stages}
                    timezone={this.props.timezone}
                    userLanguage={this.props.userLanguage}
                    onChangePeriodForMinor={this.props.onChangePeriodForMinor}
                />
            </Modal>
        );
    }
}

export default injectIntl(KPICard);
