// @flow strict

import styled from 'styled-components';

import { LegacyTheme, ButtonHover } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

export const SettingsButtonHover = styled(ButtonHover)`
    padding: 5px;
    display: flex;
    align-items: center;
    min-height: unset;

    color: ${LegacyTheme.defaultSubtleColor};

    &:hover {
        color: ${LegacyTheme.defaultSubtleColor7B};
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 30px 50px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: ${STYLE_VALUES.GUTTERS.NORMAL} 0;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: center;

    margin-top: 25px;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    margin: 12px 0;

    min-height: 120px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    border-top: 1px solid ${LegacyTheme.disabledBorder};
    padding: 16px 24px 0px;
    margin-bottom: -4px;
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    z-index: 2;
`;

export const Title = styled.div`
    font-size: 20px;
    color: ${LegacyTheme.defaultColor};
`;
