// @flow strict

import { decamelize, decamelizeKeys } from 'humps';

import request from 'services/request';

import type { SearchCriteria } from 'types';
import type { ImmutableClient } from './types';

/**
 * Create client
 *
 * @param {ImmutableClient} client
 */
export const create = (client: ImmutableClient) =>
    request('clients', {
        data: client.toJS(),
        method: 'POST',
    });

/**
 * Fetches all clients
 */
export const index = () => request('clients', { method: 'GET' });

/**
 * Update client
 *
 * @param {number} id
 * @param {ImmutableClient} client
 */
export const update = (id: number, client: ImmutableClient) =>
    request(`clients/${id}`, {
        data: client.toJS(),
        method: 'PUT',
    });

/**
 * Destroy client
 *
 * @param {number} id
 */
export const destroy = (id: number) => request(`clients/${id}`, { method: 'DELETE' });

/**
 * Query all clients
 */
export const query = (
    searchCriteria: SearchCriteria = {},
    page: number = 1,
    perPage: number = 10
) => {
    const options = decamelizeKeys({
        page,
        perPage,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder: searchCriteria.sortOrder ? searchCriteria.sortOrder.toLowerCase() : '',
    });
    return request('clients/query', { method: 'GET' }, { ...options });
};
