// @flow strict

import React from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { QuickNavigatorObservable } from 'components/_FrontendObservables';

// Components
import CircuitElevationPlantStep from 'components/CircuitElevationSteps/PlantStep';
import CircuitElevationMajorKpiStep from 'components/CircuitElevationSteps/MajorKpiStep';
import CircuitElevationMinorKpiStep from 'components/CircuitElevationSteps/MinorKpiStep';
import CircuitElevationDecisionTreeOptionsStep from 'components/CircuitElevationSteps/DecisionTreeOptionsStep';
import CircuitElevationReviewStep from 'components/CircuitElevationSteps/ReviewStep';
import CircuitElevationDoneStep from 'components/CircuitElevationSteps/DoneStep';

// Constants
import {
    CIRCUIT_ELEVATION_STEPS,
    CIRCUIT_TYPES,
    DECISION_TREE_OPTIONS,
    DECISION_TREE_RECOVERY_TYPES,
    DEFAULT_CASCADE_RELAXATION_FACTOR,
    DEFAULT_MAXIMUM_REAGENT_CONCENTRATION_DELTA,
    DEFAULT_MAXIMUM_RECOMMENDATIONS,
    DEFAULT_RETURN_RELAXATION_FACTOR,
    KPI_SETTING_PROPERTIES,
    KPI_TYPES_WITH_MAIN_TARGET,
    KPI_TYPES_WITH_RECOMMENDATIONS,
    MAJOR_KPIS,
    MINOR_KPI_SECTIONS,
    NAVIGATION_ROUTES,
    STREAM_TYPES,
    STYLE_VALUES,
} from 'utils/constants';
import { TARGET_TYPES } from 'utils/kpiConstants';

// Container
import ElevationContainer, { NEXT_BUTTON_TYPES } from 'containers/ElevationContainer';

// Helpers
import {
    getAllKPIsForCircuit,
    getKPISettingContextId,
    getKPISettingRelatedName,
    getKPISection,
} from 'utils/kpiHelpers';
import { immutableMapKeyRemover } from 'utils/helpers';

// Services
import {
    selectAllInactivePlants,
    selectPlantsAreFetching,
    selectIsDownloadingTemplate,
} from 'services/Plant/selectors';
import { fetchAllPlants, downloadTemplate } from 'services/Plant/thunks';
import {
    selectCircuitIsElevating,
    selectCircuitElevationErrors,
    selectSolvExtractCircuitQuery,
} from 'services/Circuit/selectors';
import { elevateToSolvExtractCircuit } from 'services/Circuit/thunks';

// Types
import type {
    ReduxDispatch,
    IntlType,
    ImmutableList,
    ErrorType,
    LooseKeyArrayType,
    LooseInputValueTypes,
    HistoryType,
} from 'types';
import type {
    ImmutableCircuit,
    CircuitElevationStepConstant,
    ImmutableStream,
    ImmutableCircuitSettings,
} from 'services/Circuit/types';
import type { ImmutableKPISetting, KPISectionType, AllKpiTypes } from 'services/KPISetting/types';
import type { ImmutablePlant } from 'services/Plant/types';
import type {
    DecisionTreeOptionConstant,
    DecisionTreeRecoveryType,
    ImmutableDecisionTreeOptions,
    ImmutableRecoveryKPIOption,
} from 'services/DecisionTreeOptions/types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';

type Props = {
    intl: IntlType,
    history: HistoryType,

    circuit: ImmutableCircuit,
    solvExtractCircuits: ImmutableList<ImmutableCircuit>, // used for the done page.

    onReturnToMimicDiagram: () => void,

    allInactivePlants: ImmutableList<ImmutablePlant>,
    isFetchingPlants: boolean,
    fetchAllPlants: () => void,

    downloadTemplate: (plantId: number, fileName: string) => void,
    isDownloadingTemplate: boolean,

    errors: ErrorType,
    isElevatingCircuit: boolean,
    elevateToSolvExtractCircuit: (
        circuitId: number,
        plantId: number,
        kpiData: ImmutableList<ImmutableKPISetting>,
        decisionTreeOptions: ImmutableDecisionTreeOptions,
        circuitSettings: ImmutableCircuitSettings,
        reagentId: number
    ) => void,
};

export type KPISettingsSubStateType = 'majorKPISettings' | 'minorKPISettings';
export type MinorKPISettingsSubStateKeys = $Keys<typeof MINOR_KPI_SECTIONS>;
export type MinorKPISettingSubState = {
    [key: MinorKPISettingsSubStateKeys]: ImmutableList<ImmutableKPISetting>,
};

type State = {
    circuitName: ?string,
    plantId: ?number,

    majorKPISettings: ImmutableList<ImmutableKPISetting>,
    minorKPISettings: MinorKPISettingSubState,

    remainingOptionalKPISettings: ImmutableList<ImmutableKPISetting>,

    decisionTreeOptions: ImmutableDecisionTreeOptions,
    circuitSettings: ImmutableCircuitSettings,

    reagent: ImmutableReagent,

    disableNext: boolean,
    elevationStep: CircuitElevationStepConstant,
    isModified: boolean,
};

/**
 * TODO: MS-531 - Merge this container into CircuitElevationContainer - Left in current dir so diff can be seen
 */
class SolvExtractElevationContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const circuit = this.props.circuit;

        this.state = {
            circuitName: this.props.circuit.get('name'),
            plantId: this.props.circuit.get('plantId'),

            ...this.getKPISettingsForState(circuit),

            decisionTreeOptions: this.getDecisionTreeOptionsForState(),
            circuitSettings: this.getCircuitSettingsForState(),

            reagent: this.props.circuit.get('reagent'),

            disableNext: false,
            elevationStep: CIRCUIT_ELEVATION_STEPS.PLANT,
            isModified: false,
        };
    }

    componentDidMount() {
        if (this.isCircuitElevating() && this.props.allInactivePlants.isEmpty()) {
            this.props.fetchAllPlants();
        }
    }

    /**
     * On componentDidUpdate check if props.isElevatingCircuit has changed, is so reset UI and push to final step
     * If props.errors have changed, re-enable next button to allow user to resubmit
     */
    componentDidUpdate(prevProps: Props) {
        const hasErrors = this.props.errors && !this.props.errors.isEmpty();
        if (!hasErrors && prevProps.isElevatingCircuit && !this.props.isElevatingCircuit) {
            this.setState(
                {
                    isModified: false,
                    disableNext: false,
                    elevationStep: CIRCUIT_ELEVATION_STEPS.DONE,
                },
                () => QuickNavigatorObservable.removeQuickNavigator()
            );
        } else if (prevProps.errors && !prevProps.errors.equals(this.props.errors)) {
            this.setState({
                disableNext: false,
            });
        }
    }

    /**
     * Gets and filters kpi settings from state based on type: Major / Minor (Not Major)
     */
    filterKPIsByType = (
        kpiSettings: ImmutableList<ImmutableKPISetting>,
        getMajorKPIs: boolean = true
    ) => {
        if (!kpiSettings) {
            throw new Error(
                'No KPI Settings provided, either from backend or by default via circuit'
            );
        }

        return kpiSettings.filter((kpiSetting: ImmutableKPISetting) => {
            const areMajor = MAJOR_KPIS.CIRCUIT.includes(kpiSetting.get('kpiType'));

            if (getMajorKPIs) {
                return areMajor;
            }

            return !areMajor;
        });
    };

    getMinorKPISettingsForState = (
        circuit: ImmutableCircuit,
        kpiSettings: ImmutableList<ImmutableKPISetting>,
        section: KPISectionType
    ) => {
        let kpis = this.filterKPIsByType(kpiSettings, false);

        // If KPI Settings are from the backend, we need to add their section and context
        if (!this.isCircuitElevating()) {
            kpis = kpis.map((minorKPISetting: ImmutableKPISetting) => {
                const newMinorKPISetting = minorKPISetting
                    .set('context', getKPISettingRelatedName(minorKPISetting, circuit, false))
                    .set('section', getKPISection(minorKPISetting, circuit));

                return newMinorKPISetting;
            });
        }

        return kpis
            .filter(
                (minorKPISetting: ImmutableKPISetting) => minorKPISetting.get('section') === section
            )
            .map((minorKPISetting: ImmutableKPISetting, index: number) =>
                this.prepareKPISettingForKPISetupTable(circuit, minorKPISetting, index + 1)
            );
    };

    /**
     * To be used in constructor: Separates provided kpis into Major and Minor (Not Major)
     */
    getKPISettingsForState = (circuit: ImmutableCircuit) => {
        const allPossibleKpiSettings = getAllKPIsForCircuit(circuit);

        // The KPIs that we must display in the tables is a mix of:
        //      The existing KPIs (those that have been saved already)
        //      The KPIs that are remaining that are required. This could occur when new KPIs are added or when circuit configuration changes.
        const requiredDefaultKpiSettings = allPossibleKpiSettings.filter(
            (possibleKpi: ImmutableKPISetting) =>
                possibleKpi.get('isDefault') ||
                possibleKpi.get('isRequired') ||
                possibleKpi.get('isForcedRequire') ||
                possibleKpi.get('isUndeletable')
        );

        // if the circuit is elevating (therefore, was never saved, there are no existing KPIs)
        // however if the circuit is updating, we potentially will have KPIs.
        let existingKpis = this.isCircuitElevating()
            ? requiredDefaultKpiSettings
            : circuit.get('kpiSettings').map((kpi: ImmutableKPISetting) => {
                  const defaultKPI = allPossibleKpiSettings.find(
                      (defaultKpi: ImmutableKPISetting) =>
                          getKPISettingContextId(kpi) === getKPISettingContextId(defaultKpi) &&
                          kpi.get('kpiType') === defaultKpi.get('kpiType') &&
                          kpi.get('specificityType') === defaultKpi.get('specificityType')
                  );
                  return kpi
                      .set('section', getKPISection(kpi, circuit))
                      .set(
                          'isForcedRequire',
                          defaultKPI ? defaultKPI.get('isForcedRequire') : false
                      )
                      .set('isUndeletable', defaultKPI ? defaultKPI.get('isUndeletable') : false);
              });

        // Find any missing KPI settings
        const missingRequiredKpiSettings = requiredDefaultKpiSettings.filter(
            (requiredDefaultKpi: ImmutableKPISetting) => {
                const foundKpi = existingKpis.find(
                    (existing: ImmutableKPISetting) =>
                        getKPISettingContextId(requiredDefaultKpi) ===
                            getKPISettingContextId(existing) &&
                        requiredDefaultKpi.get('kpiType') === existing.get('kpiType') &&
                        requiredDefaultKpi.get('specificityType') ===
                            existing.get('specificityType')
                );
                return !foundKpi; // if we cant find the KPI then it is a missing KPI.
            }
        );
        existingKpis = existingKpis.concat(missingRequiredKpiSettings);

        // Find all the optional KPI settings.
        // These include all KPIs that could exist that haven't been saved yet (in the case of updating)
        // Or all the KPIs that haven't been added yet
        const remainingOptionalKPISettings = allPossibleKpiSettings
            .filter((possibleKpi: ImmutableKPISetting) => {
                const foundExistingKpi = existingKpis.find(
                    (existingKpi: ImmutableKPISetting) =>
                        getKPISettingContextId(possibleKpi) ===
                            getKPISettingContextId(existingKpi) &&
                        possibleKpi.get('kpiType') === existingKpi.get('kpiType') &&
                        possibleKpi.get('specificityType') === existingKpi.get('specificityType')
                );
                // If we found an existing KPI, then it is not a remaining KPI.
                return !foundExistingKpi;
            })
            .map((minorKPISetting: ImmutableKPISetting, index: number) =>
                this.prepareKPISettingForKPISetupTable(circuit, minorKPISetting, index + 1)
            );

        // Formatting for state objects
        const minorKPISettings = Object.fromEntries(
            Object.entries(MINOR_KPI_SECTIONS).map(([key, value]) => [
                key,
                this.getMinorKPISettingsForState(circuit, existingKpis, key),
            ])
        );
        const majorKPISettings = this.filterKPIsByType(existingKpis).map(
            (kpiSetting: ImmutableKPISetting, index: number) =>
                this.prepareKPISettingForKPISetupTable(circuit, kpiSetting, index + 1)
        );

        return {
            majorKPISettings,
            minorKPISettings,
            remainingOptionalKPISettings,
        };
    };

    /**
     * Add key and order value to KPISetting;
     * !! Key will be used to match KPI Setting for reordering/removing, see handleReorderMinorKPISetting/handleRemoveMinorKPISetting !!
     *
     * Use this function within a map and provide an orderValue (index + 1)
     */
    prepareKPISettingForKPISetupTable = (
        circuit: ImmutableCircuit,
        kpiSetting: ImmutableKPISetting,
        orderValue: number
    ) => {
        let kpi = kpiSetting;

        // Build up unique key for table row
        const entityId = getKPISettingContextId(kpiSetting);
        // If specificityType found a matched but the given "entityId" was null
        if (!entityId) {
            throw new Error(
                'KPI does not have an expected entity id based on the specificity type.'
            );
        }
        const key = `${kpiSetting.get('section')}_${kpiSetting.get('kpiType')}_${kpiSetting.get(
            'specificityType'
        )}_${entityId}`;

        /**
         * Remove the recommendation fields from those KPIs which are not recommendable
         * TODO: MS-603 caused by MS-540
         */
        let streamIsLeanFeedBleed;
        if (kpi.get('streamId')) {
            const stream = circuit
                .get('streams')
                .find((s: ImmutableStream) => s.get('id') === kpi.get('streamId'));
            streamIsLeanFeedBleed =
                stream && stream.get('streamType') === STREAM_TYPES.LEAN_FEED_BLEED;
        }
        if (
            !KPI_TYPES_WITH_RECOMMENDATIONS.includes(kpiSetting.get('kpiType')) ||
            streamIsLeanFeedBleed
        ) {
            kpi = immutableMapKeyRemover(kpi, Object.keys(KPI_SETTING_PROPERTIES.RECOMMENDABLE));
        }
        if (!KPI_TYPES_WITH_MAIN_TARGET.includes(kpiSetting.get('kpiType'))) {
            kpi = immutableMapKeyRemover(kpi, Object.keys(KPI_SETTING_PROPERTIES.WITH_MAIN_TARGET));
        }

        return kpi.set('key', key).set('order', orderValue);
    };

    /**
     * Map the circuit's decision tree options (from backend) to the required frontend format
     */
    prepareCircuitDecisionTreeOptions = () => {
        const decisionTreeTypeOrder = this.props.circuit.get('decisionTreeOptions');
        return fromJS({})
            .set(
                'highRecoveryKpiOrder',
                decisionTreeTypeOrder.filter(
                    (o: ImmutableRecoveryKPIOption) =>
                        o.get('recoveryType') === DECISION_TREE_RECOVERY_TYPES.HIGH_RECOVERY
                )
            )
            .set(
                'lowRecoveryKpiOrder',
                decisionTreeTypeOrder.filter(
                    (o: ImmutableRecoveryKPIOption) =>
                        o.get('recoveryType') === DECISION_TREE_RECOVERY_TYPES.LOW_RECOVERY
                )
            );
    };

    getCircuitSettingsForState = () =>
        this.isCircuitElevating()
            ? this.getDefaultCircuitSettings()
            : this.props.circuit.get('settings');

    getDecisionTreeOptionsForState = () =>
        this.isCircuitElevating()
            ? this.getDefaultDecisionTreeOptions()
            : this.prepareCircuitDecisionTreeOptions();

    getDefaultCircuitSettings = () =>
        fromJS({
            // Circuit settings:
            showRecoveryIndicator: true,
            cascadeRelaxationFactor: DEFAULT_CASCADE_RELAXATION_FACTOR,
            returnRelaxationFactor: DEFAULT_RETURN_RELAXATION_FACTOR,
            defaultMaxLoadOffset: null,

            // DT Settings
            cuTransferRecommendationsEnabled: true,
            maxReagentConcentrationDelta: DEFAULT_MAXIMUM_REAGENT_CONCENTRATION_DELTA,
            maxTotalPlsFlowrate: null,
            maxRecommendations: DEFAULT_MAXIMUM_RECOMMENDATIONS,
            hideStageEfficienciesForPm: false,
        });

    /**
     * Get all of the decision tree options.
     */
    getDefaultDecisionTreeOptions = () =>
        fromJS({
            // KPI Order:
            highRecoveryKpiOrder: this.getDefaultRecoveryDecisionTreeOptions(
                DECISION_TREE_RECOVERY_TYPES.HIGH_RECOVERY
            ),
            lowRecoveryKpiOrder: this.getDefaultRecoveryDecisionTreeOptions(
                DECISION_TREE_RECOVERY_TYPES.LOW_RECOVERY
            ),
        });

    /**
     * Get default decision tree kpi options from constants
     * Add 1 to index since the order of kpis is no zero based
     */
    getDefaultRecoveryDecisionTreeOptions = (recoveryType: DecisionTreeRecoveryType) =>
        Object.keys(DECISION_TREE_OPTIONS[recoveryType]).map(
            (key: DecisionTreeOptionConstant, index: number) => ({
                optionType: key,
                recoveryType,
                order: index + 1,
            })
        );

    /**
     * Get each step with their keys, and the translation label used for the progress bar
     */
    getSteps = () =>
        Object.keys(CIRCUIT_ELEVATION_STEPS).map((key: CircuitElevationStepConstant) => ({
            key,
            label: this.props.intl.formatMessage({
                id: `components.CircuitElevation.${key}.shortTitle`,
            }),
        }));

    /**
     * On the review page, the next button should be the confirm button.
     * Otherwise it is the Next button.
     */
    getNextButtonType = () => {
        if (this.state.elevationStep === CIRCUIT_ELEVATION_STEPS.REVIEW) {
            return NEXT_BUTTON_TYPES.CONFIRM;
        }
        if (this.state.elevationStep === CIRCUIT_ELEVATION_STEPS.DONE) {
            return NEXT_BUTTON_TYPES.DONE;
        }
        return NEXT_BUTTON_TYPES.NEXT;
    };

    /**
     * Get list of plants;
     * If circuit is being elevated, offer inactive plants (circuit should not be elevated to an active plant)
     * If circuit has already been elevated, get it's plants (there should only be one, but the relationship provides multiple)
     */
    getPlants = () =>
        this.isCircuitElevating()
            ? this.props.allInactivePlants
            : fromJS([this.props.circuit.get('plant')]);

    // TODO: MS-602 - Added Lean Feed Bleed in Circuit Elevation, move to Mimic Diagram

    isCircuitElevating = () =>
        Boolean(this.props.circuit.get('type') === CIRCUIT_TYPES.MINCHEM_CIRCUIT);

    isPlantStepValid = () => {
        if (!this.state.circuitName) {
            return false;
        }

        if (!this.state.plantId) {
            return false;
        }

        if (!this.state.reagent) {
            return false;
        }

        return true;
    };

    isMajorKPIsStepValid = () => {
        if (!this.state.majorKPISettings) {
            return false;
        }

        // Find a kpiSetting without the required fields
        const kpiSettingWithoutRequiredInformation = this.state.majorKPISettings.find(
            (kpi: ImmutableKPISetting) =>
                !kpi.get('kpiType') ||
                !kpi.get('name') ||
                !kpi.get('specificityType') ||
                typeof kpi.get('minValid') !== 'number' ||
                typeof kpi.get('maxValid') !== 'number' ||
                typeof kpi.get(TARGET_TYPES.LOW_TARGET) !== 'number' ||
                typeof kpi.get(TARGET_TYPES.HIGH_TARGET) !== 'number' ||
                typeof kpi.get(TARGET_TYPES.MAIN_TARGET) !== 'number'
        );
        return !kpiSettingWithoutRequiredInformation;
    };

    isMinorKPIsStepValid = () => {
        const sectionWithoutAllInfo = Object.values(this.state.minorKPISettings).find(
            (kpis: ImmutableList<ImmutableKPISetting>) => {
                // Find a kpiSetting without the required fields
                const kpiSettingWithoutRequiredInformation = kpis.find(
                    (kpi: ImmutableKPISetting) =>
                        !kpi.get('kpiType') || !kpi.get('name') || !kpi.get('specificityType')
                );
                return kpiSettingWithoutRequiredInformation;
            }
        );

        // All stage efficiencies must have a main target.
        const stageEfficienciesWithoutTargets = this.state.minorKPISettings.STAGE_EFFICIENCIES.find(
            (kpi: ImmutableKPISetting) => typeof kpi.get(TARGET_TYPES.MAIN_TARGET) !== 'number'
        );

        return !sectionWithoutAllInfo && !stageEfficienciesWithoutTargets;
    };

    /**
     * Decision tree options are valid if all required fields are filled
     */
    isDecisionTreeOptionsStepValid = () => {
        const decisionTreeOptions = this.state.decisionTreeOptions;
        const circuitSettings = this.state.circuitSettings;
        // Check that all required fields have positive values
        if (
            !circuitSettings.get('maxReagentConcentrationDelta') ||
            circuitSettings.get('maxReagentConcentrationDelta') < 0 ||
            (!circuitSettings.get('maxTotalPlsFlowrate') ||
                circuitSettings.get('maxTotalPlsFlowrate') < 0) ||
            (!circuitSettings.get('maxRecommendations') ||
                circuitSettings.get('maxRecommendations') < 0)
        ) {
            return false;
        }
        // Check high recovery options are present and it has at least 1 kpi option
        if (
            !decisionTreeOptions.get('highRecoveryKpiOrder') ||
            decisionTreeOptions.get('highRecoveryKpiOrder').size < 1
        ) {
            return false;
        }
        // Check low recovery options are present and it has at least 1 kpi option
        if (
            !decisionTreeOptions.get('lowRecoveryKpiOrder') ||
            decisionTreeOptions.get('lowRecoveryKpiOrder').size < 1
        ) {
            return false;
        }
        return true;
    };

    isNextEnabled = () => {
        if (this.props.isElevatingCircuit || this.state.disableNext) {
            return false;
        }

        switch (this.state.elevationStep) {
            default:
            case CIRCUIT_ELEVATION_STEPS.PLANT:
                return this.isPlantStepValid();
            case CIRCUIT_ELEVATION_STEPS.MAJOR_KPIS:
                return this.isMajorKPIsStepValid();
            case CIRCUIT_ELEVATION_STEPS.MINOR_KPIS:
                return this.isMinorKPIsStepValid();
            case CIRCUIT_ELEVATION_STEPS.DECISION_TREE:
                return this.isDecisionTreeOptionsStepValid();
            case CIRCUIT_ELEVATION_STEPS.REVIEW:
                return (
                    this.isPlantStepValid() &&
                    this.isMajorKPIsStepValid() &&
                    this.isMinorKPIsStepValid() &&
                    this.isDecisionTreeOptionsStepValid()
                );
        }
    };

    /**
     * When the user wants to change the plant.
     */
    handleChangePlant = (plantId: number) =>
        this.setState({
            plantId,
            isModified: true,
        });

    /**
     * When the user wants to change circuit name.
     */
    handleChangeCircuitName = (circuitName: string) =>
        this.setState({
            circuitName,
            isModified: true,
        });

    /**
     * When the elevation container changes steps
     */
    handleChangeStep = (elevationStep: CircuitElevationStepConstant) => {
        // is the user going to the DONE screen?
        // if so, we want to confirm and send the data, and display a loader.
        if (elevationStep === CIRCUIT_ELEVATION_STEPS.DONE) {
            return this.setState(
                {
                    disableNext: true,
                },
                this.handleConfirm
            );
        }
        // If they are not going to the done screen,
        // then just move on to the the changed step.
        this.setState({
            elevationStep,
        });
    };

    /**
     * Handles updating local state
     */
    handleSetInState = (
        statePrefix: KPISettingsSubStateType,
        key: LooseKeyArrayType,
        value: LooseInputValueTypes,
        kpiSection: MinorKPISettingsSubStateKeys = null
    ) =>
        this.setState((prevState: State) => {
            let subState = prevState[statePrefix];
            if (!subState) {
                throw new Error(
                    `Unable to locate ${statePrefix} in local state, something is wrong`
                );
            }

            if (kpiSection) {
                subState = subState[kpiSection];
            }
            if (!subState) {
                throw new Error(
                    `Unable to locate ${statePrefix}.${kpiSection} in local state, something is wrong`
                );
            }

            if (Array.isArray(key)) {
                subState = subState.setIn(key, value);
            } else {
                subState = subState.set(key, value);
            }

            if (kpiSection) {
                return {
                    [statePrefix]: {
                        ...prevState[statePrefix],
                        [kpiSection]: subState,
                    },
                    isModified: true,
                };
            } else {
                return {
                    [statePrefix]: subState,
                    isModified: true,
                };
            }
        });

    /**
     * Handles state changes for Minor KPIs (within sectioned (subStateKey) structure)
     */
    handleMinorKPISettingInputChange = (kpiSection: MinorKPISettingsSubStateKeys) => (
        key: LooseKeyArrayType,
        value: LooseInputValueTypes
    ) => this.handleSetInState('minorKPISettings', key, value, kpiSection);

    /**
     * Handles state changes for Major KPIs
     */
    handleMajorKPISettingInputChange = (key: LooseKeyArrayType, value: LooseInputValueTypes) =>
        this.handleSetInState('majorKPISettings', key, value);

    handleSwitchKpis = (
        subStateKey: MinorKPISettingsSubStateKeys,
        prevState: State,
        newKpi: ImmutableKPISetting,
        oldKpi: ImmutableKPISetting
    ): $Shape<State> => {
        const subState = prevState.minorKPISettings[subStateKey];
        if (!subState) {
            throw new Error(`Unable to locate ${subStateKey} in local state, something is wrong`);
        }

        // remove the old KPI:
        const oldKpiIdx = subState.findIndex(
            (kpi: ImmutableKPISetting) => kpi.get('key') === oldKpi.get('key')
        );
        const oldOrder = oldKpi.get('order');
        // eslint-disable-next-line no-param-reassign
        oldKpi = oldKpi
            .remove('order')
            .set('disableKpiTypeInput', newKpi.get('disableKpiTypeInput'));

        // remove the new kpi from the optionals
        const remainingOptionalKPISettings = prevState.remainingOptionalKPISettings
            .filter((kpi: ImmutableKPISetting) => kpi.get('key') !== newKpi.get('key'))
            .unshift(oldKpi); // add the old kpi to our remaining optional KPIs.

        const hasMultipleTypes =
            remainingOptionalKPISettings
                .filter((kpi: ImmutableKPISetting) => kpi.get('section') === oldKpi.get('section'))
                .groupBy((kpi: ImmutableKPISetting) => kpi.get('kpiType')).size > 0;

        const newSwitchedKpi = newKpi
            .set('order', oldOrder)
            .set(
                'disableKpiTypeInput',
                !hasMultipleTypes || newKpi.get('isRequired') || newKpi.get('isUndeletable')
            );

        // set the new kpi in place of the old kpi.
        const newSubState = subState
            .setIn([oldKpiIdx], newSwitchedKpi) // add our switched kpi.
            .map((kpi: ImmutableKPISetting) => {
                // for each KPI in our current state,
                if (kpi.get('section') !== oldKpi.get('section')) {
                    return kpi; // do not update if not in the same section.
                }
                const hasMultipleContexts =
                    remainingOptionalKPISettings
                        .filter(
                            (remainingKpi: ImmutableKPISetting) =>
                                remainingKpi.get('kpiType') === kpi.get('kpiType') &&
                                remainingKpi.get('section') === oldKpi.get('section')
                        )
                        .groupBy((remainingKpi: ImmutableKPISetting) => remainingKpi.get('context'))
                        .size > 0;
                return kpi.set(
                    'disableKpiContextInput',
                    !hasMultipleContexts || kpi.get('isRequired') || kpi.get('isUndeletable')
                );
            });

        return {
            minorKPISettings: {
                ...prevState.minorKPISettings,
                [subStateKey]: newSubState,
            },
            remainingOptionalKPISettings,
            isModified: true,
        };
    };

    /**
     * Change the context of a kpi setting.
     *
     * Removes the old KPI, and sets the new KPI with the proper context
     */
    handleChangeMinorKPISettingContext = (kpiSection: MinorKPISettingsSubStateKeys) => (
        oldKpi: ImmutableKPISetting,
        newContextId: number
    ) =>
        this.setState((prevState: State) => {
            const newKpi = prevState.remainingOptionalKPISettings.find(
                (kpi: ImmutableKPISetting) =>
                    kpi.get('kpiType') === oldKpi.get('kpiType') && // must be of the same type
                    kpi.get('specificityType') === oldKpi.get('specificityType') && // and must be pointing to the same thing
                    getKPISettingContextId(kpi) === newContextId // but find the specific context wanted.
            );
            if (!newKpi) {
                return; // do not allow the user to change type if a new KPI cannot replace it.
            }

            return this.handleSwitchKpis(kpiSection, prevState, newKpi, oldKpi);
        });

    /**
     * Handles a user changing the type of an optional minor kpi.
     *
     * Removes the old KPI, and places it in the optional kpi list.
     * Adds the first KPI with the new type wanted to the table.
     */
    handleChangeMinorKPISettingType = (kpiSection: MinorKPISettingsSubStateKeys) => (
        oldKpi: ImmutableKPISetting,
        newType: AllKpiTypes
    ) =>
        this.setState((prevState: State) => {
            // get the first kpi of the wanted new type.
            const newKpi = prevState.remainingOptionalKPISettings.find(
                (kpi: ImmutableKPISetting) =>
                    kpi.get('section') === oldKpi.get('section') && kpi.get('kpiType') === newType // make sure the KPIs are in the same section // find the first kpi of the same type.
            );
            if (!newKpi) {
                return; // do not allow the user to change type if a new KPI cannot replace it.
            }

            return this.handleSwitchKpis(kpiSection, prevState, newKpi, oldKpi);
        });

    /**
     * Reorder a Minor KPI Setting within a circuit
     */
    handleReorderMinorKPISetting = (kpiSection: MinorKPISettingsSubStateKeys) => (
        originalKPISetting: ImmutableKPISetting
    ) => (newOrder: number) =>
        this.setState((prevState: State) => {
            const subState = prevState.minorKPISettings[kpiSection];
            if (!subState) {
                throw new Error(
                    `Unable to locate ${kpiSection} in local state, something is wrong`
                );
            }

            const newSubState = subState.update(
                (minorKPISettings: ImmutableList<ImmutableKPISetting>) =>
                    minorKPISettings
                        .map((kpiSetting: ImmutableKPISetting) => {
                            // if the new order is occupied by a kpiSetting already, change the order
                            // to the changed kpiSetting order
                            if (kpiSetting.get('order') === newOrder) {
                                return kpiSetting.set('order', originalKPISetting.get('order'));
                            }
                            // if the kpiSetting is the one we want
                            if (kpiSetting.get('key') === originalKPISetting.get('key')) {
                                return kpiSetting.set('order', newOrder);
                            }

                            // if it is neither, the previous order, or the changed kpiSetting, do not update.
                            return kpiSetting;
                        })
                        .sort(
                            (k1: ImmutableKPISetting, k2: ImmutableKPISetting) =>
                                k1.get('order') - k2.get('order')
                        )
            );
            return {
                minorKPISettings: {
                    ...prevState.minorKPISettings,
                    [kpiSection]: newSubState,
                },
                isModified: true,
            };
        });

    /*
     * Adds provided KPISetting: ImmutableKPISetting to proper minor kpiSetting list
     */
    handleAddMinorKPISetting = (
        kpiSection: MinorKPISettingsSubStateKeys,
        kpiSetting: ImmutableKPISetting
    ) =>
        this.setState((prevState: State) => {
            const subState = prevState.minorKPISettings[kpiSection];
            if (!subState) {
                throw new Error(`Unable to locate ${subState} in local state, something is wrong`);
            }

            const newSubState = subState.update(
                (minorKPISettings: ImmutableList<ImmutableKPISetting>) =>
                    minorKPISettings.push(kpiSetting)
            );

            // remove the kpi we are adding from our optional remaining kpis.
            const remainingOptionalKPISettings = prevState.remainingOptionalKPISettings.filter(
                (kpi: ImmutableKPISetting) => kpi.get('key') !== kpiSetting.get('key')
            );

            return {
                minorKPISettings: {
                    ...prevState.minorKPISettings,
                    [kpiSection]: newSubState,
                },
                // remove the first element
                remainingOptionalKPISettings,
                isModified: true,
            };
        });

    /**
     * Remove a KPI Setting from a circuit & reset order of those "below it"
     */
    handleRemoveMinorKPISetting = (kpiSection: MinorKPISettingsSubStateKeys) => (
        kpiSettingToRemove: ImmutableKPISetting
    ) => () =>
        this.setState((prevState: State) => {
            const subState = prevState.minorKPISettings[kpiSection];
            if (!subState) {
                throw new Error(`Unable to locate ${subState} in local state, something is wrong`);
            }

            const kIdx = subState.findIndex(
                (k: ImmutableKPISetting) => k.get('key') === kpiSettingToRemove.get('key')
            );

            const oldKpi = subState.get(kIdx);
            const oldOrder = oldKpi.get('order');

            const newSubState = subState
                .delete(kIdx)
                .update((minorKPISettings: ImmutableList<ImmutableKPISetting>) =>
                    minorKPISettings.map((item: ImmutableKPISetting) => {
                        if (item.get('order') > oldOrder) {
                            // anything above the field that we deleted, needs to have a lower order.
                            return item.set('order', item.get('order') - 1);
                        }
                        return item;
                    })
                );

            const remainingOptionalKPISettings = prevState.remainingOptionalKPISettings.unshift(
                oldKpi
            );

            return {
                minorKPISettings: {
                    ...prevState.minorKPISettings,
                    [kpiSection]: newSubState,
                },
                remainingOptionalKPISettings,
                isModified: true,
            };
        });

    /**
     * Update provided field by key of local
     */
    handleChangeCircuitSettings = (key: LooseKeyArrayType, value: LooseInputValueTypes) =>
        this.setState((prevState: State) => {
            return {
                circuitSettings: prevState.circuitSettings.set(key, value),
                isModified: true,
            };
        });

    handleReorderDecisionTreeKPI = (originalKPIOption: ImmutableRecoveryKPIOption) => (
        newOrder: number
    ) => {
        const recoveryKPIOrder =
            originalKPIOption.get('recoveryType') === DECISION_TREE_RECOVERY_TYPES.HIGH_RECOVERY
                ? 'highRecoveryKpiOrder'
                : 'lowRecoveryKpiOrder';
        this.setState((prevState: State) => ({
            decisionTreeOptions: prevState.decisionTreeOptions.update(
                recoveryKPIOrder,
                (recoveryKPIOrderOptions: ImmutableList<ImmutableRecoveryKPIOption>) =>
                    recoveryKPIOrderOptions
                        .map((kpiOption: ImmutableRecoveryKPIOption) => {
                            // if the new order is occupied by a kpiOption already, change the order
                            // to the changed kpiOption order
                            if (kpiOption.get('order') === newOrder) {
                                return kpiOption.set('order', originalKPIOption.get('order'));
                            }
                            // if the kpiOption is the one we want
                            if (
                                kpiOption.get('optionType') === originalKPIOption.get('optionType')
                            ) {
                                return kpiOption.set('order', newOrder);
                            }
                            // if it is neither, the previous order, or the changed kpiOption, do not update.
                            return kpiOption;
                        })
                        .sort(
                            (k1: ImmutableRecoveryKPIOption, k2: ImmutableRecoveryKPIOption) =>
                                k1.get('order') - k2.get('order')
                        )
            ),
            isModified: true,
        }));
    };

    /**
     * Change the reagent for the circuit.
     */
    handleChangeReagent = (reagent: ImmutableReagent) => {
        this.setState((prevState: State) => ({
            reagent,
        }));
    };

    /**
     * Did the user press the confirm button and save the circuit elevation?
     */
    handleConfirm = () => {
        if (!this.isPlantStepValid()) {
            throw new Error('Plant data is not valid but somehow got to the confirmation phase?');
        }
        if (!this.isMajorKPIsStepValid()) {
            throw new Error(
                'Major KPI data is not valid but somehow got to the confirmation phase?'
            );
        }
        if (!this.isMinorKPIsStepValid()) {
            throw new Error(
                'Minor KPI data is not valid but somehow got to the confirmation phase?'
            );
        }
        if (!this.isDecisionTreeOptionsStepValid()) {
            throw new Error(
                'Decision tree options data is not valid but somehow got to the confirmation phase?'
            );
        }

        // Add back fields that are required for KPI Setting API Validation
        // TODO: Replace this "bypass" for proper backend validation (MS-451)
        const majorKPISettings = this.state.majorKPISettings.map(
            (kpiSetting: ImmutableKPISetting) =>
                kpiSetting
                    .set('minRecommend', null)
                    .set('maxRecommend', null)
                    .set('roundTo', null)
                    .set('isRequired', false)
        );

        const allKPISettings = majorKPISettings.concat(
            ...Object.values(this.state.minorKPISettings)
        );

        // In order to have better performance, kpis have been broken into sections up until this point
        // And thus the order values are currently relative to these sections
        // We now need them to be relative to the full list
        const allKPISettingsWithCumulativeOrder = allKPISettings.map(
            (kpiSetting: ImmutableKPISetting, index: number) => kpiSetting.set('order', index + 1)
        );

        const reagentId = this.state.reagent.get('id');

        this.props.elevateToSolvExtractCircuit(
            this.props.circuit.get('id'),
            this.state.circuitName,
            this.state.plantId,
            allKPISettingsWithCumulativeOrder,
            this.state.decisionTreeOptions,
            this.state.circuitSettings,
            reagentId
        );
    };

    /**
     * Allow user to edit a previous step from REVIEW step
     */
    handleReturnToStep = (elevationStep: CircuitElevationStepConstant) => () =>
        this.setState({ elevationStep });

    /**
     * Handles forward exit of Elevation Container;
     * Reset header state & redirect to homepage to avoid return to Circuit Setup
     */
    handleExitForwards = () => {
        this.props.onReturnToMimicDiagram();
    };

    renderStepContent = () => {
        switch (this.state.elevationStep) {
            default:
            case CIRCUIT_ELEVATION_STEPS.PLANT: {
                return (
                    <CircuitElevationPlantStep
                        circuit={this.props.circuit}
                        isCircuitElevating={this.isCircuitElevating()}
                        plantId={this.state.plantId}
                        isLoading={this.props.isFetchingPlants}
                        plants={this.getPlants()}
                        circuitSettings={this.state.circuitSettings}
                        onChangePlant={this.handleChangePlant}
                        circuitName={this.state.circuitName}
                        onChangeCircuitName={this.handleChangeCircuitName}
                        onChangeCircuitSettings={this.handleChangeCircuitSettings}
                        reagent={this.state.reagent}
                        onChangeReagent={this.handleChangeReagent}
                    />
                );
            }
            case CIRCUIT_ELEVATION_STEPS.MAJOR_KPIS: {
                return (
                    <CircuitElevationMajorKpiStep
                        units={this.props.circuit.get('circuitUnits')}
                        majorKPISettings={this.state.majorKPISettings}
                        onInputChange={this.handleMajorKPISettingInputChange}
                        isCircuitElevating={this.isCircuitElevating()}
                    />
                );
            }
            case CIRCUIT_ELEVATION_STEPS.MINOR_KPIS: {
                return (
                    <CircuitElevationMinorKpiStep
                        units={this.props.circuit.get('circuitUnits')}
                        minorKPISettings={this.state.minorKPISettings}
                        remainingOptionalKPISettings={this.state.remainingOptionalKPISettings}
                        isCircuitElevating={this.isCircuitElevating()}
                        circuitSettings={this.state.circuitSettings}
                        onAddKPI={this.handleAddMinorKPISetting}
                        onInputChange={this.handleMinorKPISettingInputChange}
                        onRemoveKPI={this.handleRemoveMinorKPISetting}
                        onReorderKPI={this.handleReorderMinorKPISetting}
                        onChangeKPIType={this.handleChangeMinorKPISettingType}
                        onChangeKPIContext={this.handleChangeMinorKPISettingContext}
                        onChangeCircuitSettings={this.handleChangeCircuitSettings}
                    />
                );
            }
            case CIRCUIT_ELEVATION_STEPS.DECISION_TREE: {
                return (
                    <CircuitElevationDecisionTreeOptionsStep
                        decisionTreeOptions={this.state.decisionTreeOptions}
                        circuitSettings={this.state.circuitSettings}
                        onInputChange={this.handleChangeCircuitSettings}
                        onReorderKPI={this.handleReorderDecisionTreeKPI}
                    />
                );
            }
            case CIRCUIT_ELEVATION_STEPS.REVIEW: {
                return (
                    <CircuitElevationReviewStep
                        circuit={this.props.circuit}
                        plants={this.getPlants()}
                        plantId={this.state.plantId}
                        majorKPISettings={this.state.majorKPISettings}
                        minorKPISettings={this.state.minorKPISettings}
                        decisionTreeOptions={this.state.decisionTreeOptions}
                        circuitSettings={this.state.circuitSettings}
                        circuitName={this.state.circuitName}
                        onReturnToStep={this.handleReturnToStep}
                        isCircuitElevating={this.isCircuitElevating()}
                        reagent={this.state.reagent}
                    />
                );
            }
            case CIRCUIT_ELEVATION_STEPS.DONE: {
                const savedCircuit = this.props.solvExtractCircuits
                    .get('data')
                    .find(
                        (circuit: ImmutableCircuit) =>
                            circuit.get('id') === this.props.circuit.get('id')
                    );
                if (!savedCircuit) {
                    throw new Error('The circuit was not saved properly...');
                }
                return (
                    <CircuitElevationDoneStep
                        plantId={savedCircuit.get('plantId')}
                        isDownloadingTemplate={this.props.isDownloadingTemplate}
                        handleDownloadClicked={this.props.downloadTemplate}
                    />
                );
            }
        }
    };

    render() {
        return (
            <ElevationContainer
                currentStep={this.state.elevationStep}
                elevationSteps={this.getSteps()}
                isModified={this.state.isModified}
                isNextEnabled={this.isNextEnabled()}
                isNextLoading={this.props.isElevatingCircuit}
                nextButtonType={this.getNextButtonType()}
                onChangeStep={this.handleChangeStep}
                onExitBackwards={this.props.onReturnToMimicDiagram}
                onExitForwards={this.handleExitForwards}
                maxWidthValue={STYLE_VALUES.XLARGE.MAX_WIDTH}
            >
                {this.renderStepContent()}
            </ElevationContainer>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        allInactivePlants: selectAllInactivePlants(),
        isFetchingPlants: selectPlantsAreFetching(),

        isElevatingCircuit: selectCircuitIsElevating(),
        errors: selectCircuitElevationErrors(),

        solvExtractCircuits: selectSolvExtractCircuitQuery(),
        isDownloadingTemplate: selectIsDownloadingTemplate(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllPlants,
            elevateToSolvExtractCircuit,
            downloadTemplate,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(SolvExtractElevationContainer))
);
