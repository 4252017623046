// @flow

import React from 'react';

import type { InputValueType } from '../../types';

// Styles
import { InnerInput, InputWrapper, SearchButton } from './styles';

type Props = {
    disabled?: boolean,
    handleOnClear?: () => void,
    handleOnClick?: () => void,
    id?: number,
    key?: ?string,
    name?: string,
    placeholder?: string,
    type?: string,
    value?: InputValueType,
    withSearch?: boolean,
};

export default class InputSearch extends React.PureComponent<Props> {
    static defaultProps = {
        disabled: false,
        handleOnClear: null,
        handleOnClick: null,
        id: null,
        key: null,
        name: 'InputSearch',
        placeholder: '',
        type: 'text',
        value: '',
        withSearch: false,
    };

    render() {
        if (!this.props.handleOnClick || !this.props.handleOnClear) {
            return null;
        }

        // Loop over passed props and filter out those not for search input
        // Used to avoid '[propeName]' prop on a DOM element warning
        const inputProps = {};
        Object.keys(this.props).forEach((key: string) => {
            if (key !== 'handleOnClear' && key !== 'handleOnClick' && key !== 'withSearch') {
                return (inputProps[key] = this.props[key]);
            }
        });

        // If disabled, return null
        // else if withSearch is true return onClear else onClick
        let onClickFunction = null;
        if (!this.props.disabled) {
            onClickFunction = this.props.withSearch
                ? this.props.handleOnClear
                : this.props.handleOnClick;
        }

        return (
            <InputWrapper>
                <InnerInput
                    key={this.props.key || this.props.name || this.props.id}
                    type={this.props.type}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    {...inputProps}
                />
                <SearchButton onClick={onClickFunction} withSearch={this.props.withSearch} />
            </InputWrapper>
        );
    }
}
