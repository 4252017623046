// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Containers
import CircuitSetupContainer from 'containers/CircuitSetupContainer';
import MinchemPreferencesFetcher from 'containers/MinchemPreferencesFetcher';

// Types
import type { IntlType, MatchType, Navigation } from 'types';

type Props = {
    intl: IntlType,
    match: MatchType,
};

export class CircuitSetup extends React.PureComponent<Props, null> {
    getCircuitId() {
        const circuitId = this.props.match.params.circuitId;
        if (circuitId) {
            return parseInt(circuitId, 10);
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.CircuitSetup.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.CircuitSetup.helmetDescription',
                        })}
                    />
                </Helmet>
                <MinchemPreferencesFetcher>
                    <CircuitSetupContainer circuitId={this.getCircuitId()} />
                </MinchemPreferencesFetcher>
            </React.Fragment>
        );
    }
}

export default injectIntl(CircuitSetup);
