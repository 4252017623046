// @flow

import styled, { css } from 'styled-components';
import LegacyTheme from '../../themes/legacyTheme';

export const InputBase = css`
    width: 100%;
    min-height: 30px;
    padding: 5px 16px;

    font-size: 12px;
    color: ${({ disabled }: Object) =>
        disabled ? LegacyTheme.disabledInputColor : LegacyTheme.defaultColor};
    &::placeholder {
        color: ${({ disabled }: Object) =>
            disabled ? LegacyTheme.disabledPlaceholder : LegacyTheme.disabledInputColor};
    }

    background-color: ${LegacyTheme.defaultBackground};
    border-radius: 4px;
    border: 1px solid
        ${({ disabled }: Object) =>
            disabled ? LegacyTheme.disabledBorder : LegacyTheme.defaultBorder};

    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: ${LegacyTheme.buttonPrimaryHover};
    }
`;

export const InputValue = styled.span`
    font-size: inherit;
    color: ${LegacyTheme.defaultColor};
`;
