// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import {
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryGroup,
    VictoryLine,
    VictoryScatter,
} from 'victory';
import { range } from 'lodash';

// Components
import DiagramLegend from 'components/DiagramLegend';

// Helpers
import { getPostDecimalZeroCount, round } from 'utils/helpers';

// Styles
import {
    Wrapper,
    GraphWrapper,
    ChartWrapper,
    LegendWrapper,
    HeaderWrapper,
    Header,
} from './styles';
import GraphStyles from './GraphStyles';
import { isothermMDRCurvesColors } from 'styles/colors';

// Types
import type { ImmutableList, IntlType, ReactNode } from 'types';

const GRAPH_WIDTH = 1440;
const GRAPH_HEIGHT = 768;

type DataPoint = {
    x: number,
    y: number,
};

type DataSetType = {
    data: ImmutableList<DataPoint>,
    id: number,
    modifier: string,
};

type Props = {
    dataSets: ImmutableList<DataSetType>,
    intl: IntlType,
    xMin: number,
    xMax: number,
};

const reversePow = (base, power) => {
    let p = 1;
    for (let i = 0; i < power; i++) {
        p *= base;
    }
    return p;
};

class MDRCurvesDiagram extends React.Component<Props> {
    /**
     * Provide formatting for xAxis, where the value is rounded to 2 decimal places
     */
    getRoundedValue = (t: number): number => round(t, 2);

    /**
     * Provide formatting for yAxis, where the value is converted to 10 to the power of x (10 e x)
     * TODO: Replace for universal helper util to get the power of 10 from value
     */
    getTenToThePowerOfLabel = (t: number): string => {
        if (t === 1) {
            return '10 e 0';
        } else if (t === 100) {
            return '10 e +2';
        } else if (t > 100) {
            return t.toString();
        }
        const powerOf = getPostDecimalZeroCount(t) + 1;
        return `10 e ${t < 1 ? '-' : '+'}${powerOf}`;
    };

    /**
     * From provided dataSets, create VictoryGroups containing Line & Scatter graphs for each set of data
     */
    getDataSetsAsVictoryGroups = () =>
        this.props.dataSets.map(
            (dataSet: DataSetType, index: number): VictoryGroup => (
                <VictoryGroup key={dataSet.id} data={dataSet.data}>
                    <VictoryLine
                        name="line"
                        style={{
                            data: {
                                ...GraphStyles.line.data,
                                stroke: isothermMDRCurvesColors.accent[index],
                            },
                        }}
                        interpolation="natural"
                    />
                    <VictoryScatter
                        name="dotScatter"
                        style={{
                            data: {
                                ...GraphStyles.dotScatter.data,
                                fill: isothermMDRCurvesColors.primary[index],
                            },
                        }}
                        size={7}
                    />
                </VictoryGroup>
            )
        );

    /**
     * Provide "range" of tick values between provided xMin and xMax by multiples of 10
     * After 10, add 100 to array, assuming concentrations will never be over 100
     */
    getXAxisTickValues = () => {
        const array = [];
        for (let i = 0; i < 8; i++) {
            const value = this.props.xMin * Number(`1${'0'.repeat(i)}`);
            if (value > 10) {
                break;
            }
            array.push(value);
        }

        const lastValue = array[array.length - 1];

        // Assume values will never be greater than 100
        if (lastValue < this.props.xMax) {
            array.push(100);
        }

        return [...new Set(array)];
    };

    /**
     * Render yAxis
     */
    renderYAxis = (): VictoryAxis => (
        <VictoryAxis
            name="yAxis"
            label={this.props.intl.formatMessage({
                id: `components.MDRCurvesDiagram.Axis.metalInOrganic`,
            })}
            style={GraphStyles.axisY}
            tickFormat={this.getRoundedValue}
            tickCount={5}
            tickLabelComponent={
                <VictoryLabel
                    dy={GraphStyles.axisYTickLabelTranslate.dy}
                    dx={GraphStyles.axisYTickLabelTranslate.dx}
                    textAnchor="end"
                />
            }
            dependentAxis
        />
    );

    /**
     * Render xAxis
     */
    renderXAxis = (): VictoryAxis => {
        const tickValues = this.getXAxisTickValues();
        const largestTickValue = tickValues[tickValues.length - 1];

        return (
            <VictoryAxis
                name="xAxis"
                label={this.props.intl.formatMessage({
                    id: `components.MDRCurvesDiagram.Axis.mdr`,
                })}
                style={GraphStyles.axisX}
                tickFormat={this.getTenToThePowerOfLabel}
                tickValues={tickValues}
                domain={[
                    this.props.xMin,
                    largestTickValue > this.props.xMax ? largestTickValue : this.props.xMax,
                ]}
            />
        );
    };

    /**
     * Render diagram legend with Modifier values & their corresponding colors
     */
    renderModifiersLegend = (): ReactNode =>
        this.props.dataSets && (
            <LegendWrapper>
                <HeaderWrapper>
                    <Header>
                        {this.props.intl.formatMessage({
                            id: `components.MDRCurvesDiagram.Legend.Header`,
                        })}
                    </Header>
                </HeaderWrapper>
                <DiagramLegend
                    direction="COLUMN"
                    legendItems={this.props.dataSets
                        .map((dataSet: DataSetType, index: number) => ({
                            color: isothermMDRCurvesColors.primary[index],
                            label: round(dataSet.modifier).toString(),
                        }))
                        .toJS()}
                />
            </LegendWrapper>
        );

    render() {
        return (
            <Wrapper>
                <ChartWrapper>
                    <GraphWrapper graphWidth={GRAPH_WIDTH} graphHeight={GRAPH_HEIGHT}>
                        <VictoryChart
                            height={GRAPH_HEIGHT}
                            width={GRAPH_WIDTH}
                            style={GraphStyles.mainGraph}
                            padding={GraphStyles.mainGraphPadding}
                            scale={{ x: 'log', y: 'linear' }}
                        >
                            {/* Axis Components */}
                            {this.renderYAxis()}
                            {this.renderXAxis()}

                            {/* Line & Scatter Components */}
                            {this.getDataSetsAsVictoryGroups()}
                        </VictoryChart>
                    </GraphWrapper>
                </ChartWrapper>
                {this.renderModifiersLegend()}
            </Wrapper>
        );
    }
}

export default injectIntl(MDRCurvesDiagram);
