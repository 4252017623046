// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Common, InputField, InputSelect, SecondaryButton, Toggle } from 'components/_ReactUI_V1';
import SelectTimezoneField from 'components/SelectTimezoneField';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import {
    UNIT_TYPES,
    LANGUAGE_OPTIONS,
    PLANT_STATUSES,
    PRODUCTION_UNIT_TYPES,
    STYLE_VALUES,
    PLANT_CREATION_STEPS,
} from 'utils/constants';

// Styles
import { FormBlock, FormContainer, FormLabel, Subtitle, Title, SimpleList } from 'styles/common';

// Types
import type {
    ImmutableList,
    InputEvent,
    IntlType,
    LooseInputValueTypes,
    LooseKeyArrayType,
    ReactSelectObject,
    UnitsConstant,
    ProductionUnitsConstant,
} from 'types';
import type { ImmutablePlant, PlantCreationStepConstant } from 'services/Plant/types';
import type { ImmutableClient } from 'services/Client/types';

type Props = {
    clients: ImmutableList<ImmutableClient>,
    intl: IntlType,
    onInputChange?: ?(key: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onReturnToStep?: ?(step: PlantCreationStepConstant) => () => void,
    plant: ImmutablePlant,
};

class PlantStep extends React.PureComponent<Props> {
    static defaultProps = {
        onInputChange: null,
        onReturnToStep: null,
    };

    STEP_KEY = PLANT_CREATION_STEPS.PLANT;

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.PlantManager.PlantSetupSteps.${this.STEP_KEY}.${id}`,
        });

    /**
     * Convert the provided client_id to a ReactSelectObject
     */
    getReactSelectObjectFromClientId = (clientId?: number): ?ReactSelectObject => {
        if (!clientId || (this.props.clients && this.props.clients.isEmpty())) {
            return null;
        }

        const foundImmutableClient = this.props.clients.find(
            (client: ImmutableClient) => client.get('id') === clientId
        );

        if (!foundImmutableClient) {
            return null;
        }

        return {
            value: foundImmutableClient.get('id'),
            label: foundImmutableClient.get('name'),
        };
    };

    /**
     * Convert the language to a drop down select object
     */
    getLanguageValueToReactSelectObject = (value: ?string): ?ReactSelectObject =>
        value
            ? {
                  value,
                  label: this.props.intl.formatMessage({
                      id: `constants.Languages.${value}`,
                  }),
              }
            : null;

    getPlantUnitToReactSelectObject = (plantUnit?: UnitsConstant): ?ReactSelectObject =>
        plantUnit
            ? {
                  value: plantUnit,
                  label: this.props.intl.formatMessage({ id: `constants.Units.${plantUnit}` }),
              }
            : null;

    getProductionUnitToReactSelectObject = (
        productionUnit?: ProductionUnitsConstant
    ): ?ReactSelectObject =>
        productionUnit
            ? {
                  value: productionUnit,
                  label: this.props.intl.formatMessage({
                      id: `constants.ProductionUnits.${productionUnit}`,
                  }),
              }
            : null;

    handleInputChange = (key: string) => (event: InputEvent) =>
        this.props.onInputChange && this.props.onInputChange(key, event.target.value);

    handleInputSelectChange = (key: string) => (selectedObject: ReactSelectObject) =>
        this.props.onInputChange && this.props.onInputChange(key, selectedObject.value);

    handleToggleChange = (key: string) => () => {
        const active = PLANT_STATUSES.ACTIVE;
        const inactive = PLANT_STATUSES.INACTIVE;
        const newStatus = this.props.plant.get('status') === active ? inactive : active;
        if (this.props.onInputChange) {
            this.props.onInputChange(key, newStatus);
        }
    };

    renderListOfCircuits = () => {
        const listOfCircuits = this.props.plant
            .get('circuits')
            .map((circuit: ImmutableCircuit) => (
                <li key={circuit.get('id')}>{circuit.get('name')}</li>
            ));
        return (
            <FormBlock>
                <FormLabel>{this.getTranslation('circuitsLabel')}</FormLabel>
                <SimpleList>{listOfCircuits}</SimpleList>
            </FormBlock>
        );
    };

    render() {
        const { plant } = this.props;
        const plantIsUpdating = plant.get('id');
        const displayAsReadOnly = Boolean(this.props.onReturnToStep);
        const plantHasAtLeastOneCircuit =
            plant.has('circuitCount') && plant.get('circuitCount') >= 1;

        return (
            <FormContainer>
                <Common.Row alignItems="center">
                    <Common.Column>
                        {displayAsReadOnly ? (
                            <Subtitle>{this.getTranslation('title')}</Subtitle>
                        ) : (
                            <Title>{this.getTranslation('title')}</Title>
                        )}
                    </Common.Column>
                    {displayAsReadOnly && (
                        <Common.Column alignItems="flex-end">
                            <SecondaryButton
                                text={this.getTranslation('edit')}
                                onClick={
                                    this.props.onReturnToStep &&
                                    this.props.onReturnToStep(this.STEP_KEY)
                                }
                            />
                        </Common.Column>
                    )}
                </Common.Row>

                <FormBlock>
                    <FormLabel>
                        {this.props.intl.formatMessage({
                            id: 'components.PlantManager.valueHeaders.name',
                        })}
                    </FormLabel>
                    <InputField
                        onChange={this.handleInputChange('name')}
                        placeholder={this.getTranslation('placeholder.name')}
                        value={plant.get('name') || ''}
                        renderString={displayAsReadOnly}
                    />
                </FormBlock>

                <FormBlock>
                    <FormLabel>
                        {this.props.intl.formatMessage({
                            id: 'components.PlantManager.valueHeaders.client',
                        })}
                    </FormLabel>
                    <InputSelect
                        selectedOption={this.getReactSelectObjectFromClientId(
                            plant.get('clientId')
                        )}
                        options={this.props.clients
                            .map((client: ImmutableClient) => ({
                                value: client.get('id'),
                                label: client.get('name'),
                            }))
                            .toJS()}
                        onSelect={this.handleInputSelectChange('clientId')}
                        placeholder={this.getTranslation('placeholder.client')}
                        isDisabled={plantIsUpdating}
                        renderString={displayAsReadOnly}
                        controlShouldRenderValue
                    />
                </FormBlock>

                <FormBlock>
                    <FormLabel>
                        {this.props.intl.formatMessage({
                            id: 'components.PlantManager.valueHeaders.language',
                        })}
                    </FormLabel>
                    <InputSelect
                        selectedOption={this.getLanguageValueToReactSelectObject(
                            plant.get('language')
                        )}
                        options={Object.keys(LANGUAGE_OPTIONS).map((key: string) => ({
                            value: key,
                            label: this.props.intl.formatMessage({
                                id: `constants.Languages.${LANGUAGE_OPTIONS[key]}`,
                            }),
                        }))}
                        onSelect={this.handleInputSelectChange('language')}
                        placeholder={this.getTranslation('placeholder.language')}
                        renderString={displayAsReadOnly}
                        controlShouldRenderValue
                    />
                </FormBlock>

                <SelectTimezoneField
                    selectedTimezone={plant.get('timezone')}
                    onSelect={this.handleInputSelectChange('timezone')}
                    renderString={displayAsReadOnly}
                />

                <FormBlock>
                    <FormLabel>
                        {this.props.intl.formatMessage({
                            id: 'components.PlantManager.valueHeaders.inputUnits',
                        })}
                    </FormLabel>
                    <InputSelect
                        selectedOption={this.getPlantUnitToReactSelectObject(plant.get('units'))}
                        options={Object.keys(UNIT_TYPES).map(this.getPlantUnitToReactSelectObject)}
                        maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                        onSelect={this.handleInputSelectChange('units')}
                        placeholder={this.getTranslation('placeholder.inputUnits')}
                        isDisabled={plantIsUpdating}
                        renderString={displayAsReadOnly}
                        controlShouldRenderValue
                    />
                </FormBlock>

                <FormBlock>
                    <FormLabel>
                        {this.props.intl.formatMessage({
                            id: 'components.PlantManager.valueHeaders.outputUnits',
                        })}
                    </FormLabel>
                    <InputSelect
                        selectedOption={this.getProductionUnitToReactSelectObject(
                            plant.get('productionUnits')
                        )}
                        options={Object.keys(PRODUCTION_UNIT_TYPES).map(
                            this.getProductionUnitToReactSelectObject
                        )}
                        maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                        onSelect={this.handleInputSelectChange('productionUnits')}
                        placeholder={this.getTranslation('placeholder.outputUnits')}
                        isDisabled={plantIsUpdating}
                        renderString={displayAsReadOnly}
                        controlShouldRenderValue
                    />
                </FormBlock>

                <FormBlock>
                    <FormLabel>
                        {this.props.intl.formatMessage({
                            id: 'components.PlantManager.valueHeaders.status',
                        })}
                    </FormLabel>
                    <Toggle
                        checked={plant.get('status') === PLANT_STATUSES.ACTIVE}
                        onClickHandler={this.handleToggleChange('status')}
                        label={this.getTranslation(`statuses.${plant.get('status')}`)}
                        disabled={!plantHasAtLeastOneCircuit || displayAsReadOnly}
                    />
                    {!plantHasAtLeastOneCircuit && !displayAsReadOnly && (
                        <ErrorMessage
                            errorMessage={this.getTranslation('statusMessage')}
                            isRed
                            isSmall
                        />
                    )}
                </FormBlock>

                {plantHasAtLeastOneCircuit && this.renderListOfCircuits()}
            </FormContainer>
        );
    }
}

export default injectIntl(PlantStep);
