// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { QuickNavigatorObservable } from 'components/_FrontendObservables';
import { NAVBAR_DASHBOARD_V2_REDIRECT } from 'env';

import {
    selectSolvExtractCircuitQuery,
    selectCircuitsAreCreating,
    selectCircuitErrors,
    selectCircuitIsFetchingStatus,
} from 'services/Circuit/selectors';
import { fetchCircuit, exportCircuitToMinchem } from 'services/Circuit/thunks';

// Components
import {
    SidebarLayout,
    OverflowBody,
    OverflowContainer,
    OverflowEnd,
    NotificationBell,
} from 'components/_ReactUI_V1';

import AppLoader from 'components/AppLoader';
import TrendsPageDashboard from 'components/TrendsPageDashboard';
import MimicDiagram from 'components/MimicDiagram';
import TrendsMimicDiagramFooter from 'components/TrendsMimicDiagramFooter';
import ErrorMessage from 'components/ErrorMessage';
import TrendsSidebarContainer from 'containers/TrendsSidebarContainer';
import CircuitDetailsModal from 'components/Modals/CircuitDetailsModal';

// Constants
import {
    NAVIGATION_ROUTES,
    STYLE_VALUES,
    STAGE_VALUE_TYPES,
    DIAGRAM_DISPLAY_MODES,
    TRENDS_PAGE_TYPE,
    USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES,
    STREAM_TYPES,
    STAGE_TYPES,
    STAGE_TYPES_WITH_ISOTHERMS,
} from 'utils/constants';

// Helpers
import { getFirstStageInCascadeForStage } from 'utils/helpers';
import { isSysAdmin } from 'utils/authentication';
import { SolvExtractNavigation } from 'utils/SolvExtractNavigation';

// Services
import {
    selectAllCircuitTrends,
    selectCircuitTrendsAreFetching,
    selectAllPlantTrends,
    selectPlantTrendsAreFetching,
} from 'services/Trends/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { selectAllClients } from 'services/Client/selectors';
import { selectDatasetsAreFetching, selectAllDatasets } from 'services/Dataset/selectors';
import { fetchAllClients } from 'services/Client/thunks';
import { fetchCircuitTrend, fetchPlantTrend } from 'services/Trends/thunks';
import { fetchDataset } from 'services/Dataset/thunks';
import {
    selectCircuitRecommendations,
    selectIsLoadingCircuitRecommendation,
} from 'services/Recommendation/selectors';

// Types
import type { ReduxDispatch, ImmutableList, IntlType, HistoryType, ErrorType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type {
    ImmutableCircuitTrend,
    ImmutablePlantTrend,
    TrendsPageType,
} from 'services/Trends/types';
import type { ImmutableClient } from 'services/Client/types';
import type { ImmutableStage, ImmutableStream } from 'services/Circuit/types';
import type {
    ImmutableStageValue,
    ImmutableIsothermStageValue,
    ImmutableDataset,
} from 'services/Dataset/types';

type Props = {
    history: HistoryType,

    trendType: TrendsPageType,
    user: ImmutableUser,

    // if trendType === CIRCUIT
    circuitId?: ?number,
    circuitTrends: ImmutableList<ImmutableCircuitTrend>,
    circuitTrendsAreLoading: boolean,
    fetchCircuitTrend: (circuitId: number) => void,

    // For exporting to Minchem
    circuitErrors: ErrorType,
    isCreatingCircuit: boolean,
    exportCircuitToMinchem: () => void,

    // For mimic diagram and recommendation sidebar:
    datasets: ImmutableList<ImmutableDataset>,
    datasetIsFetching: boolean,
    fetchDataset: (datasetId: number) => void,

    fetchCircuit: (circuitId: number) => void,
    isFetchingCircuit: boolean,
    solvExtractCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,

    // if trendType === PLANT
    plantId?: ?number,
    plantTrends: ImmutableList<ImmutablePlantTrend>,
    plantTrendsAreLoading: boolean,
    fetchPlantTrend: (plantId: number) => void,

    clients: ImmutableList<ImmutableClient>,
    fetchAllClients: () => void, // for navigation.

    intl: IntlType,
};

type State = {
    showMimicDiagram: boolean,
    sidebarIsCollapsed: boolean,
    displayExportModal: boolean,
};

class TrendsContainer extends React.PureComponent<Props, State> {
    static defaultProps = {
        circuitId: null,
        plantId: null,
    };

    state = {
        showMimicDiagram: false,
        sidebarIsCollapsed: false,
        displayExportModal: false,
    };

    getCircuit = (): ?ImmutableCircuit => {
        const solvExtractCircuit = this.props.solvExtractCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === this.props.circuitId);
        if (solvExtractCircuit) {
            return solvExtractCircuit;
        }
    };

    shouldCheckRedirect = () => {
        // TODO: DGM-3646 Remove legacy navigation
        // If we are on a page that contains the circuit ID in the params, then that means we are on a circuit page.
        // Because of this, we want to redirect to the new dashboard.
        // If we don't have a circuitId in our props/params, then it means we're on a plant trends page, which doesn't have the redirect.
        return NAVBAR_DASHBOARD_V2_REDIRECT && this.props.circuitId;
    };

    doRedirect = (plantId: number, circuitId: number) => {
        // TODO: DGM-3646 Remove legacy navigation
        if (!plantId || !circuitId) {
            console.error('PlantId or CircuitId is null, cannot redirect to the v2 dashboard.');
            return false;
        }
        this.props.history.push(SolvExtractNavigation.getCircuitDashboardUrl(plantId, circuitId));
        return true;
    };

    /**
     * When the component mounts, we want to reset the navigation and fetch the circuit.
     */
    componentDidMount() {
        if (this.shouldCheckRedirect()) {
            // TODO: DGM-3646 Remove legacy navigation
            const circuit = this.getCircuit();
            if (!circuit) {
                this.props.fetchCircuit(this.props.circuitId);
                // will be caught by the componentDidUpdate afterwards.
                return;
            } else {
                // circuit already loaded, probably came from home page or something.
                // redirect to the new dashboard.
                if (this.doRedirect(circuit.get('plantId'), circuit.get('id'))) {
                    return;
                }
            }
        }

        const trend = this.getTrend();
        if (!trend) {
            this.fetchTrend();
        }

        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT && trend) {
            const dataset = this.getDataset(trend.get('datasetId'));
            if (!dataset) {
                this.props.fetchDataset(trend.get('datasetId'));
            }
        }

        // for navigation.
        if (this.props.clients.isEmpty()) {
            this.props.fetchAllClients();
        }

        QuickNavigatorObservable.setQuickNavigatorSolvExtract();
    }

    /**
     * When our props updates, check to see if they are different then update our state depending
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        // If we have a circuit id and the redirect flag is set to true, we want to redirect to the circuit dashboard page.
        if (this.shouldCheckRedirect()) {
            // TODO: DGM-3646 Remove legacy navigation
            const circuit = this.getCircuit();
            if (circuit) {
                const circuitId = circuit.get('id');
                const plantId = circuit.get('plantId');

                if (this.doRedirect(plantId, circuitId)) {
                    return;
                }
            }
            // TODO: To keep or to remove this console debug?
            // else {
            //     console.debug('We were supposed to redirect to the circuit dashboard v2, however the component updated and didn\'t have the circuit loaded yet. This is most likely normal since component update will be called multiple times.')
            // }
        }

        // get the trend.
        let currentTrend = null;
        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT) {
            currentTrend = this.getCircuitTrend();
        } else {
            currentTrend = this.getPlantTrend();
        }
        if (
            !currentTrend &&
            (this.props.trendType !== prevProps.trendType ||
                this.props.circuitId !== prevProps.circuitId ||
                this.props.plantId !== prevProps.plantId)
        ) {
            this.setState(
                {
                    showMimicDiagram: false,
                    sidebarIsCollapsed: false,
                },
                () => this.fetchTrend()
            );
        }

        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT && currentTrend) {
            const currentDatasetId = currentTrend.get('datasetId');
            const dataset = this.getDataset(currentDatasetId);
            if (!this.props.datasetIsFetching && !dataset) {
                this.props.fetchDataset(currentDatasetId);
            }
        }

        // Were we exporting the circuit and dataset to Minchem? And the create stopped.
        // Aka was the create successful or unsuccessful?
        if (prevProps.isCreatingCircuit && !this.props.isCreatingCircuit) {
            this.handleHideExportModal();
        }
    }

    fetchTrend = () => {
        // we changed trend types fetch the opposite trend.
        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT) {
            if (!this.props.circuitId) {
                throw new Error('No circuit id provided to container.');
            }
            this.props.fetchCircuitTrend(this.props.circuitId);
        } else {
            if (!this.props.plantId) {
                throw new Error('No plant id provided to container.');
            }
            this.props.fetchPlantTrend(this.props.plantId);
        }
    };

    getTrend = () =>
        this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT
            ? this.getCircuitTrend()
            : this.getPlantTrend();

    /**
     * Get the trend for the circuit id requested.
     */
    getCircuitTrend = (): ?ImmutableCircuitTrend =>
        this.props.circuitTrends.find(
            (trend: ImmutableCircuitTrend) => trend.get('circuitId') === this.props.circuitId
        );

    /**
     * Get the trend for the circuit id requested.
     */
    getPlantTrend = (): ?ImmutablePlantTrend =>
        this.props.plantTrends.find(
            (trend: ImmutablePlantTrend) => trend.get('plantId') === this.props.plantId
        );

    getDataset = (datasetId: number): ?ImmutableDataset =>
        this.props.datasets.find((dataset: ImmutableDataset) => dataset.get('id') === datasetId);

    /**
     * This is used to resize the Major KPI graph
     */
    handleSidebarCollapse = (isCollapsed: boolean) =>
        this.setState({
            sidebarIsCollapsed: isCollapsed,
        });

    getCurrentRecommendationSize = () => {
        const currentCircuitRecommendationSets = this.props.recommendationSets.find(
            (recommendationSet: ImmutableRecommendationSet) =>
                recommendationSet.get('circuitId') === this.props.circuitId
        );
        const currentRecommendationSize =
            currentCircuitRecommendationSets?.get('recommendations')?.size || 0;
        return currentRecommendationSize;
    };

    getResponsiveSidebarTitle = () => {
        const currentRecommendationSize = this.getCurrentRecommendationSize();
        const unreadRecommendations = currentRecommendationSize ?? 0; // TODO: MS-609 - get unread recommendations from props

        return this.props.intl.formatMessage(
            { id: `components.RecommendationSidebar.mobileRecommendationTitle` },
            {
                number: unreadRecommendations > 0 ? `(${unreadRecommendations})` : '',
            }
        );
    };

    getPlantDataset = () => {
        const trend = this.getTrend();
        if (!trend) {
            throw new Error('Cannot get plant dataset if there is no trend.');
        }

        return trend.get('dataset');
    };

    getOnSettingsClickHandler = () => {
        if (!isSysAdmin(this.props.user)) {
            return undefined;
        }
        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT) {
            return this.handleCircuitTrendSettingsClick;
        }
        if (this.props.trendType === TRENDS_PAGE_TYPE.PLANT) {
            return this.handlePlantTrendSettingsClick;
        }
        return undefined;
    };

    handleCircuitTrendSettingsClick = () =>
        this.props.circuitId &&
        this.props.history.push(
            `${NAVIGATION_ROUTES.CIRCUIT}${this.props.circuitId}${NAVIGATION_ROUTES.ELEVATE}`
        );

    // TODO: MS-339 - Should redirect user to specific plant, however plant edit flow is not route based
    handlePlantTrendSettingsClick = () =>
        this.props.history.push(USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS);

    handleShowMimicDiagram = () =>
        this.setState({
            showMimicDiagram: true,
        });

    handleHideMimicDiagram = () =>
        this.setState({
            showMimicDiagram: false,
        });

    /**
     *  Circuit/dataset export to Minchem
     */
    handleShowExportModal = () => {
        this.setState({
            displayExportModal: true,
        });
    };

    handleHideExportModal = () => {
        this.setState({
            displayExportModal: false,
        });
    };

    handleSaveAsConfirm = (name: string, plantName: string, comments: string) => {
        const dataset = this.props.datasets.find(
            (ds) => ds.get('circuitId') === this.props.circuitId
        );

        const requestData = {
            circuitId: this.props.circuitId,
            datasetId: dataset.get('id'),
            name,
            plantName,
            comments,
        };

        this.props.exportCircuitToMinchem(requestData);
    };

    renderMimicDiagram = () => {
        if (this.props.datasetIsFetching) {
            return (
                <AppLoader
                    // messageId="components.TrendsPageDashboard.loadingTitle"
                    loading
                />
            );
        }

        const trend = this.getTrend();
        if (!trend) {
            return (
                <ErrorMessage
                    errorCode="components.TrendsPageDashboard.noTrendsLoaded"
                    errorMessage="Trend is null in renderMimicDiagram"
                />
            );
        }
        if (this.props.trendType !== TRENDS_PAGE_TYPE.CIRCUIT) {
            throw new Error('Cannot view mimic diagram on a non-circuit trend.');
        }

        const dataset = this.getDataset(trend.get('datasetId'));
        if (!dataset) {
            throw new Error('No dataset loaded for mimic diagram view.');
        }

        const circuit = dataset.get('circuit');
        const stages = circuit
            .get('stages')
            .map((stage: ImmutableStage) => {
                let stageValues = dataset
                    .get('stageValues')
                    .filter(
                        (stageValue: ImmutableStageValue) =>
                            stageValue.get('stageId') === stage.get('id')
                    );
                if (STAGE_TYPES_WITH_ISOTHERMS.includes(stage.get('stageType'))) {
                    const firstStage = getFirstStageInCascadeForStage(circuit, stage);
                    let isotherm = dataset
                        .get('isothermStageValues')
                        .find(
                            (isothermStageValue: ImmutableIsothermStageValue) =>
                                isothermStageValue.get('stageId') === firstStage.get('id')
                        );
                    isotherm = isotherm.set('isothermMode', isotherm.get('valueType'));
                    isotherm = isotherm.set('valueType', STAGE_VALUE_TYPES.ISOTHERM);
                    stageValues = stageValues.push(isotherm);
                }
                return stage.set('values', stageValues);
            })
            .toJS();
        // TODO: MS-610 caused by MS-540
        const streams = circuit
            .get('streams')
            .filter(
                (stream: ImmutableStream) =>
                    stream.get('streamType') !== STREAM_TYPES.LEAN_FEED_BLEED
            )
            .toJS();
        return (
            <OverflowContainer>
                <OverflowBody>
                    <MimicDiagram
                        displayMode={DIAGRAM_DISPLAY_MODES.COMPUTED}
                        fullDisplay={false}
                        loadingCircuit={false}
                        circuit={circuit}
                        stages={stages}
                        streams={streams}
                        streamValues={dataset.get('streamValues').toJS()}
                        datasetValues={dataset.get('datasetValues').toJS()}
                        circuitUnits={circuit.get('circuitUnits')}
                    />
                </OverflowBody>
                <OverflowEnd>
                    <TrendsMimicDiagramFooter
                        onHideMimicDiagramClicked={this.handleHideMimicDiagram}
                        onExportToMinchemClicked={this.handleShowExportModal}
                    />
                </OverflowEnd>
            </OverflowContainer>
        );
    };

    /**
     * Render the dashboard.
     */
    renderMainContent = () => {
        if (this.state.showMimicDiagram) {
            return this.renderMimicDiagram();
        }

        let loading = false;
        if (
            this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT &&
            this.props.circuitTrendsAreLoading
        ) {
            loading = true;
        } else if (
            this.props.trendType === TRENDS_PAGE_TYPE.PLANT &&
            this.props.plantTrendsAreLoading
        ) {
            loading = true;
        }
        if (this.props.isFetchingCircuit) {
            loading = true;
        }
        if (loading) {
            return (
                <AppLoader
                    loading={loading}
                    messageId="components.TrendsPageDashboard.loadingTitle"
                />
            );
        }

        const trend = this.getTrend();
        if (!trend) {
            return null;
        }

        let circuit = null;
        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT) {
            const dataset = this.getDataset(trend.get('datasetId'));
            circuit = dataset && dataset.get('circuit');
        }

        return (
            <TrendsPageDashboard
                user={this.props.user}
                clients={this.props.clients}
                sidebarIsCollapsed={this.state.sidebarIsCollapsed}
                trend={trend}
                loading={loading}
                circuit={circuit}
                trendType={this.props.trendType}
                onSettingsClicked={this.getOnSettingsClickHandler()}
                onViewMimicClicked={this.handleShowMimicDiagram}
            />
        );
    };

    /**
     * Render the sidebar.
     */
    renderSidebar = () => {
        const loadingTrend = this.props.circuitTrendsAreLoading || this.props.plantTrendsAreLoading;
        const trend = this.getTrend();
        if (!trend) {
            return null;
        }
        const dataset = this.getDataset(trend.get('datasetId'));
        const circuit = dataset && dataset.get('circuit');
        const loadingDataset = !circuit && this.props.datasetIsFetching;
        return (
            <TrendsSidebarContainer
                trend={trend}
                circuit={circuit}
                trendType={this.props.trendType}
                circuitId={this.props.circuitId}
                plantId={this.props.plantId}
                isLoadingTrend={loadingTrend}
                isLoadingDataset={loadingDataset}
            />
        );
    };

    renderExportModal = () => (
        <CircuitDetailsModal
            title={this.props.intl.formatMessage({
                id: 'components.CircuitModals.SaveAsModal.title',
            })}
            confirmButtonText={this.props.intl.formatMessage({
                id: 'components.CircuitModals.SaveAsModal.confirmButton',
            })}
            errors={this.props.circuitErrors}
            loading={this.props.isCreatingCircuit}
            onConfirm={this.handleSaveAsConfirm}
            onCancel={this.handleHideExportModal}
        />
    );

    render() {
        return (
            <React.Fragment>
                <SidebarLayout
                    styles={{
                        sidebar: {
                            height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        },
                        main: {
                            height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                            overflowY: 'hidden',
                        },
                    }}
                    renderMain={this.renderMainContent}
                    renderSidebar={this.renderSidebar}
                    sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                    onSidebarCollapseToggled={this.handleSidebarCollapse}
                    responsiveMaxDeviceWidth={STYLE_VALUES.RESPONSIVE_AT_LARGE}
                    responsiveTitle={this.getResponsiveSidebarTitle()}
                    sidebarTogglerIcon={
                        Boolean(this.getCurrentRecommendationSize() > 0) && (
                            <NotificationBell
                                fill="white"
                                width="20px"
                                exclamationBell={true}
                                exclamationBell={true}
                            />
                        )
                    }
                    responsive
                    collapsible
                    flush
                    mainFlush
                    closedByDefault={!(this.getCurrentRecommendationSize() > 0)}
                />
                {this.state.displayExportModal && this.renderExportModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        circuitTrendsAreLoading: selectCircuitTrendsAreFetching(),
        circuitTrends: selectAllCircuitTrends(),

        isCreatingCircuit: selectCircuitsAreCreating(),
        isFetchingCircuit: selectCircuitIsFetchingStatus(),
        circuitErrors: selectCircuitErrors(),

        plantTrendsAreLoading: selectPlantTrendsAreFetching(),
        plantTrends: selectAllPlantTrends(),

        solvExtractCircuitQuery: selectSolvExtractCircuitQuery(),

        user: selectUser(),

        clients: selectAllClients(),

        datasets: selectAllDatasets(),
        datasetIsFetching: selectDatasetsAreFetching(),
        recommendationSets: selectCircuitRecommendations(),
        isLoadingCircuitRecommendation: selectIsLoadingCircuitRecommendation(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchCircuitTrend,
            fetchPlantTrend,
            fetchAllClients,
            fetchDataset,
            exportCircuitToMinchem,
            fetchCircuit,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(TrendsContainer))
);
