// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { PageTitleObservable, QuickNavigatorObservable } from 'components/_FrontendObservables';

// Components
import PlantConfiguration from 'microfrontends/PlantConfiguration';

// Constants
import { USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES } from 'utils/constants';

// Styles
import { PlantConfigurationWrapper } from './styles';

// Types
import type { IntlType } from 'types';

type Props = {
    routeTitle: String,
    intl: IntlType,
    plantId: number,
};

class PlantConfigurationContainer extends React.PureComponent<Props> {
    componentDidMount() {
        QuickNavigatorObservable.setQuickNavigatorBackToCallback(
            this.props.intl.formatMessage({
                id: 'components.Header.BackToNavigator.plants',
            }),
            () => this.props.history.push(USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS)
        );
        PageTitleObservable.setPageTitle(
            this.props.intl.formatMessage({
                id: `components.Header.routeTitles.${this.props.routeTitle}`,
            })
        );
    }

    componentWillUnmount() {
        QuickNavigatorObservable.removeQuickNavigator();
        PageTitleObservable.removePageTitle();
    }

    render() {
        const handleExitMfe = () => {
            QuickNavigatorObservable.removeQuickNavigator();
            PageTitleObservable.removePageTitle();
            this.props.history.push(USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS);
        };

        return (
            <PlantConfigurationWrapper>
                <PlantConfiguration plantId={this.props.plantId} onExitMfe={handleExitMfe} />
            </PlantConfigurationWrapper>
        );
    }
}

export default withRouter(injectIntl(PlantConfigurationContainer));
