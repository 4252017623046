// @flow strict

import { decamelize, decamelizeKeys } from 'humps';

import request from 'services/request';

import type { SearchCriteria, CircuitTypes, ImmutableList } from 'types';

import type {
    ImmutableCircuit,
    ImmutableStream,
    ImmutableCircuitSettings,
    CircuitExportRequestData,
} from 'services/Circuit/types';
import type { ImmutableDecisionTreeOptions } from 'services/DecisionTreeOptions/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

/**
 * Create circuit
 *
 * @param {Circuit} circuit
 */
export const create = (circuit: ImmutableCircuit) =>
    request('circuits', {
        data: circuit,
        method: 'POST',
    });

/**
 * Create advanced circuit
 *
 * @param {Circuit} circuit
 */
export const createAdvanced = (circuit: ImmutableCircuit) =>
    request('circuits/advanced', {
        data: circuit,
        method: 'POST',
    });

/**
 *
 * @param {CircuitExportRequestData} requestData
 */
export const exportToMinchem = (requestData: CircuitExportRequestData) =>
    request('circuits/export', {
        data: requestData,
        method: 'POST',
    });

/**
 * Transform a regular minchem circuit into a SolvExtract circuit.
 * @param {ImmutableCircuit} circuit A minchem circuit with the plant id provided
 * @param {ImmutableList<ImmutableKPISetting>} kpiData All the KPI settings for this circuit
 * @param {ImmutableDecisionTreeOptions} decisionTreeOptions All the decision tree options for this circuit
 */
export const elevateToSolvExtract = (
    circuitId: number,
    circuitName: string,
    plantId: number,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    decisionTreeOptions: ImmutableDecisionTreeOptions,
    circuitSettings: ImmutableCircuitSettings,
    reagentId: ?number
) =>
    request(`circuits/${circuitId}/elevate`, {
        data: {
            circuitName,
            plantId,
            kpiSettings: kpiSettings.toJS(),
            decisionTreeOptions: decisionTreeOptions.toJS(),
            circuitSettings: circuitSettings.toJS(),
            reagentId,
        },
        method: 'POST',
    });

/**
 * Query all circuits with provided type
 */
export const query = (
    type: CircuitTypes,
    searchCriteria: SearchCriteria = {},
    page: number = 1
) => {
    const options = decamelizeKeys({
        page,
        sortBy: searchCriteria && searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder:
            searchCriteria && searchCriteria.sortOrder
                ? searchCriteria.sortOrder.toLowerCase()
                : '',
        search: (searchCriteria && searchCriteria.search) || '',
        type,
    });
    return request('circuits/query', { method: 'GET' }, { ...options });
};

/**
 * Finds a specific circuits
 */
export const find = (id: number) => request(`circuits/${id}`, { method: 'GET' });

/**
 * Update circuit
 *
 * @param {number} id
 * @param {Circuit} circuit
 */
export const update = (id: number, circuit: ImmutableCircuit) =>
    request(`circuits/${id}`, {
        data: circuit,
        method: 'PUT',
    });

/**
 * Update advanced circuit
 *
 * @param {number} id
 * @param {Circuit} circuit
 */
export const updateAdvanced = (id: number, circuit: ImmutableCircuit) =>
    request(`circuits/${id}/advanced`, {
        data: circuit,
        method: 'PUT',
    });

/**
 * Destroy circuit
 *
 * @param {number} id
 */
export const destroy = (id: number) => request(`circuits/${id}`, { method: 'DELETE' });
