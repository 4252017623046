// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

// Components
import { SidebarLayout, SidebarTab } from 'components/_ReactUI_V1';

// Containers
import ReagentContainer from './ReagentContainer';
import OximeContainer from './OximeContainer';
import ReagentsManagement from '../../microfrontends/ReagentsManagement';

// Constants
import { APPLICATION_TYPES, ROUTES, STYLE_VALUES } from 'utils/constants';

// Types
import type { IntlType, ReactNode } from 'types';

export const MANAGEMENT_TYPES = {
    CYANEX_REAGENTS: 'CYANEX_REAGENTS',
    MDR: 'MDR',
    REAGENTS: 'REAGENTS',
};

export type ManagementType = $Keys<typeof MANAGEMENT_TYPES>;

type ItemType = { component: ReactNode, label: string, key: ManagementType };

type Props = {
    intl: IntlType,

    type: ?ManagementType,
};

type State = {
    activeSidebarTabKey: ManagementType,
};

class ReagentManagementContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const sidebarItems = this.provideSidebarItems();

        this.state = {
            activeSidebarTabKey: this.props.type || sidebarItems.shift().key,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.type !== prevProps.type) {
            const sidebarItems = this.provideSidebarItems();
            this.setState({
                activeSidebarTabKey: this.props.type || sidebarItems.shift().key,
            });
        }
    }

    onSidebarTabSelection = (sidebarTabKey: ManagementType) => () =>{
            // Only change tabs when the sidebar tab key is not the current location.
            this.state.activeSidebarTabKey !== sidebarTabKey &&
            this.props.history.push(ROUTES[sidebarTabKey].path);
    }

    provideSidebarItems = (): Array<ItemType> => [
        {
            label: this.props.intl.formatMessage({
                id: 'containers.ReagentManagementContainer.sidebar.Reagents',
            }),
            key: MANAGEMENT_TYPES.REAGENTS,
            component: <ReagentContainer />,
        },
        {
            label: this.props.intl.formatMessage({
                id: 'containers.ReagentManagementContainer.sidebar.MDRData',
            }),
            key: MANAGEMENT_TYPES.MDR,
            component: <OximeContainer />,
        },
        {
            label: this.props.intl.formatMessage({
                id: 'containers.ReagentManagementContainer.sidebar.CyanexReagents',
            }),
            key: MANAGEMENT_TYPES.CYANEX_REAGENTS,
            component: <ReagentsManagement application={APPLICATION_TYPES.CYANEX} />,
        },
    ];

    provideSidebarItemByKey = (sidebarTabKey: ManagementType): ?ItemType => {
        const items = this.provideSidebarItems();

        if (!items || !sidebarTabKey) {
            return null;
        }

        return items.find((item: ItemType) => sidebarTabKey === item.key);
    };

    renderMain = () => {
        const activeItem: ?ItemType = this.provideSidebarItemByKey(this.state.activeSidebarTabKey);
        return activeItem && activeItem.component;
    };

    renderSidebar = (): ?Array<ReactNode> => {
        const items: Array<ItemType> = this.provideSidebarItems();

        return (
            items &&
            items.map((item: ItemType) => (
                <SidebarTab
                    key={item.key}
                    active={item.key === this.state.activeSidebarTabKey}
                    handleOnHeaderClick={
                        item.key !== this.state.activeSidebarTabKey
                            ? this.onSidebarTabSelection(item.key)
                            : null
                    }
                    title={item.label}
                />
            ))
        );
    };

    render() {
        return (
            <SidebarLayout
                styles={{
                    sidebar: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                    main: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        overflowY: 'hidden',
                    },
                }}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                renderMain={this.renderMain}
                renderSidebar={this.renderSidebar}
                collapsible
                mainFlush
                flush
            />
        );
    }
}

export default withRouter(injectIntl(ReagentManagementContainer));
