// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 20px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: 0px;
    }
`;

export const Message = styled.div`
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-size: 17px;
    line-height: 20px;
    margin: 0;
`;
