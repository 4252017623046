// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';
import { QuickNavigatorObservable } from 'components/_FrontendObservables';

// Helpers
import { SidebarLayout } from 'components/_ReactUI_V1';

import { getTwoDAndThreeDRestrictions } from './helpers';

// Authentication Helpers
import { isSolvayUser } from 'utils/authentication';

// Services
import { fetchCircuit } from 'services/Circuit/thunks';
import { fetchDataset } from 'services/Dataset/thunks';
import {
    selectMinChemCircuitQuery,
    selectMinChemIsQueryingStatus,
    selectCircuitIsFetchingStatus,
    selectCircuitFetchErrors,
} from 'services/Circuit/selectors';
import {
    selectAllDatasets,
    selectDatasetsAreFetching,
    selectDatasetFetchErrors,
} from 'services/Dataset/selectors';
import { selectUser } from 'services/Authentication/selectors';

// Containers
import MimicContainer from 'containers/CircuitComputationContainer/MimicContainer';
import MSMcCabeThieleContainer from 'containers/CircuitComputationContainer/MSMcCabeThieleContainer';
import TwoDSensitivityContainer from 'containers/CircuitComputationContainer/TwoDSensitivityContainer';
import ThreeDSensitivityContainer from 'containers/CircuitComputationContainer/ThreeDSensitivityContainer';

// Components
import { ContainerCentered } from 'styles/common';
import AppLoader from 'components/AppLoader';
import CircuitComputationSidebar from 'components/CircuitComputationSidebar';
import ErrorMessage from 'components/ErrorMessage';

// Types
import type {
    ReduxDispatch,
    ImmutableList,
    ErrorType,
    ImmutableQueryStructure,
    SidebarStateType,
} from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableDataset } from 'services/Dataset/types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { DatasetIdType } from 'containers/HeaderContainer';

// Constants
import {
    DESIGN_PRESET_TYPES,
    STYLE_VALUES,
    BLANK_TEMPLATE,
    CIRCUIT_COMPUTE_SIDEBAR_ITEMS,
    SIDEBAR_STATES,
} from 'utils/constants';

export type SidebarSectionType = $Values<typeof CIRCUIT_COMPUTE_SIDEBAR_ITEMS>;

export type SidebarRenderer = {
    sidebarType: SidebarStateType,
    node: React.Node,
};

type Props = {
    circuitId: number,
    datasetId: DatasetIdType,

    minChemCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,
    loadingCircuits: boolean,
    fetchCircuit: (circuitId: number) => void,
    fetchingSingleCircuit: boolean,

    datasets: ImmutableList<ImmutableDataset>,

    circuitErrors: ErrorType,
    fetchDataset: (datasetId: number) => void,
    loadingDataset: boolean,
    datasetErrors: ErrorType,

    user: ImmutableUser,
};

type State = {
    activeContainer: SidebarSectionType,
    activeContainerSidebar: ?SidebarRenderer,
    sidebarNode: ?HTMLDivElement,
};

/**
 * The container that holds computation mode sidebar and state
 */
class CircuitComputationContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeContainer: CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MIMIC,
            activeContainerSidebar: null,
            sidebarNode: null,
        };
    }

    /**
     * When this container is mounted, fetch isothemrs, circuit
     * and dataset
     */
    componentDidMount() {
        const circuit = this.getCircuit();
        // If we have a circuit, change our navigation circuit.
        if (!circuit && this.props.datasetId === BLANK_TEMPLATE) {
            // If we don't have the current circuit
            // Fetch the circuit only if we are making a new dataset.
            this.props.fetchCircuit(this.props.circuitId);
        }

        const dataset = this.getDataset();
        // If we have a dataset, change our navigation dataset.
        if (!dataset && this.props.datasetId !== BLANK_TEMPLATE) {
            // Otherwise, fetch our dataset only if we are not on a BLANK_TEMPLATE page.
            this.props.fetchDataset(this.props.datasetId);
        }

        QuickNavigatorObservable.setQuickNavigatorMinChem();
    }

    /**
     * Update circuit or dataset navigation upon user interaction with the sidebar
     */
    componentDidUpdate(prevProps: Props) {
        const circuit = this.getCircuit();
        // Was our circuit loaded?
        const dataset = this.getDataset();
        // Was our dataset loaded?
        if (
            !dataset &&
            circuit &&
            this.props.datasetId === BLANK_TEMPLATE &&
            prevProps.datasetId !== this.props.datasetId
        ) {
            // If we have a circuit, and our dataset id is a new dataset, then change our navigation dataset to the blank one.
            this.setState({
                activeContainer: 'MIMIC',
                activeContainerSidebar: null,
                sidebarNode: null,
            });
        }
        if (
            !dataset &&
            prevProps.datasetId !== this.props.datasetId &&
            this.props.datasetId !== BLANK_TEMPLATE &&
            !Number.isNaN(this.props.datasetId) // If we dont do this, this.props.datasetId !== prevDatasetId is always true.
        ) {
            // Otherwise, we don't have a dataset or the dataset id changed.
            // Therefore we need to fetch it.
            this.props.fetchDataset(this.props.datasetId);
        }
    }

    getCircuit = (): ?ImmutableCircuit => {
        const dataset = this.getDataset();
        if (dataset) {
            return dataset.get('circuit');
        }
        return this.props.minChemCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === this.props.circuitId);
    };

    getDataset = (): ?ImmutableDataset =>
        this.props.datasetId !== BLANK_TEMPLATE
            ? this.props.datasets.find(
                  (dataset: ImmutableDataset) => dataset.get('id') === this.props.datasetId // TODO: add logic for computed dataset
              )
            : null;

    /**
     * Set active container through the sidebar (Mimic, McCabe, 2D or 3D)
     */
    onSidebarToggle = (activeContainer: SidebarSectionType) =>
        this.setState({
            activeContainer,
            activeContainerSidebar: null,
            sidebarNode: null,
        });

    onReceiveSidebarNode = (sidebarNode: ?HTMLDivElement) =>
        this.setState({
            sidebarNode,
        });

    onReceiveSidebarContent = (activeContainerSidebar: SidebarRenderer) =>
        this.setState({ activeContainerSidebar });

    /**
     * Render right side content according to sidebar selection
     */
    renderMainContent = () => {
        let mainContainer = null;
        switch (this.state.activeContainer) {
            case CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MIMIC:
                mainContainer = this.renderMimicContainer();
                break;
            case CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MCCABE:
                mainContainer = this.renderMcCabeContainer();
                break;
            case CIRCUIT_COMPUTE_SIDEBAR_ITEMS.TWO_D:
                mainContainer = this.render2DContainer();
                break;
            case CIRCUIT_COMPUTE_SIDEBAR_ITEMS.THREE_D:
                mainContainer = this.render3DContainer();
                break;
            default:
                break;
        }
        return mainContainer;
    };

    renderMimicContainer = () => (
        <MimicContainer
            dataset={this.getDataset()}
            circuit={this.getCircuit()}
            loadingCircuitOrDataset={
                this.props.loadingCircuits ||
                this.props.fetchingSingleCircuit ||
                this.props.loadingDataset
            }
            handleSidebarContent={this.onReceiveSidebarContent}
        />
    );

    renderMcCabeContainer = () => (
        <MSMcCabeThieleContainer
            circuitId={this.props.circuitId}
            circuit={this.getCircuit()}
            datasetId={this.props.datasetId === BLANK_TEMPLATE ? null : this.props.datasetId}
            dataset={this.getDataset()}
            sidebarNodeRef={this.state.sidebarNode}
        />
    );

    render2DContainer = () =>
        isSolvayUser(this.props.user) &&
        this.props.datasetId !== BLANK_TEMPLATE && (
            <TwoDSensitivityContainer
                datasetId={this.props.datasetId}
                handleSidebarContent={this.onReceiveSidebarContent}
                sensitivityOptionAvailability={getTwoDAndThreeDRestrictions(this.getDataset())}
            />
        );

    render3DContainer = () =>
        isSolvayUser(this.props.user) &&
        this.props.datasetId !== BLANK_TEMPLATE && (
            <ThreeDSensitivityContainer
                datasetId={this.props.datasetId}
                handleSidebarContent={this.onReceiveSidebarContent}
            />
        );

    renderSidebarContent = () => {
        // Was a ref set? If so, do not render anything, let the portal do the job.
        if (this.state.sidebarNode) {
            return;
        }
        // No ref was set, so render whatever the active container wants to render
        if (this.state.activeContainerSidebar) {
            return this.state.activeContainerSidebar.node;
        }
        return null;
    };

    renderSidebar = () => {
        const sidebarData = this.state.activeContainerSidebar;
        const dataset = this.getDataset();
        const recoveryRaffinatePresetSelected = Boolean(
            dataset &&
                dataset.get('extractCompute') ===
                    DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.RECOVERY_RAFFINATE
        );
        if (sidebarData && sidebarData.sidebarType === SIDEBAR_STATES.FULL) {
            return sidebarData.node;
        } else {
            return (
                <CircuitComputationSidebar
                    activeSection={this.state.activeContainer}
                    handleSetTabNode={this.onReceiveSidebarNode}
                    handleSectionActivate={this.onSidebarToggle}
                    recoveryRaffinatePresetSelected={recoveryRaffinatePresetSelected}
                    sectionBody={this.renderSidebarContent()}
                    user={this.props.user}
                />
            );
        }
    };

    render() {
        // If we don't have any circuit or any dataset
        if (!this.getCircuit() && !this.getDataset()) {
            if (!this.props.datasetErrors.isEmpty() || !this.props.circuitErrors.isEmpty()) {
                let errorCode = this.props.datasetErrors.get('error');
                if (!this.props.circuitErrors.isEmpty()) {
                    errorCode = this.props.circuitErrors.get('error');
                }
                let message = this.props.datasetErrors.get('message');
                if (!this.props.circuitErrors.isEmpty()) {
                    message = this.props.circuitErrors.get('message');
                }
                return (
                    <ContainerCentered>
                        <ErrorMessage
                            errorCode={`feedback.error.${errorCode}`}
                            errorMessage={message}
                        />
                    </ContainerCentered>
                );
            }
            return (
                <AppLoader
                    messageId="containers.CircuitComputationContainer.loadingDataset"
                    loading
                />
            );
        }
        // When a user first loads the page, the circuit is loaded.
        // However they can use the circuit navigation to change datasets.
        // When that happens, we have the circuit so the first IF above will not be called.
        // We should only show the loader if the dataset the user wants is not a new one.
        if (
            !this.getDataset() &&
            this.props.datasetId !== BLANK_TEMPLATE &&
            this.props.loadingDataset
        ) {
            return (
                <AppLoader
                    messageId="containers.CircuitComputationContainer.loadingDataset"
                    loading
                />
            );
        }
        return (
            <SidebarLayout
                styles={{
                    sidebar: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                    main: { height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})` },
                }}
                renderMain={this.renderMainContent}
                renderSidebar={this.renderSidebar}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                collapsible
                flush
                mainFlush
            />
        );
    }
}

const mapStateToProps = (state: State, ownProps: Props) =>
    createStructuredSelector({
        minChemCircuitQuery: selectMinChemCircuitQuery(),
        loadingCircuits: selectMinChemIsQueryingStatus(),
        fetchingSingleCircuit: selectCircuitIsFetchingStatus(),

        circuitErrors: selectCircuitFetchErrors(),
        loadingDataset: selectDatasetsAreFetching(),
        datasetErrors: selectDatasetFetchErrors(),

        datasets: selectAllDatasets(),

        user: selectUser(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchCircuit,
            fetchDataset,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CircuitComputationContainer)
);
