// @flow strict

/**
 * Recursively checks all response objects for the `legacyStageType` key to remap it.
 * @param {*} obj
 * @returns
 */
const _remapLegacyCircuitKpiSettings = (obj: object) => {
    if (!obj) {
        return;
    }
    if (obj instanceof Array) {
        obj.forEach((item) => _remapLegacyCircuitKpiSettings(item));
        return;
    }
    if (typeof obj !== 'object') {
        return;
    }
    const keys = Object.keys(obj);
    keys.forEach((key: string) => {
        if (key === 'legacyKpiSettings') {
            console.debug('Remapped a legacy kpiSettings', obj.id);
            obj.kpiSettings = obj[key];
        } else {
            _remapLegacyCircuitKpiSettings(obj[key]);
        }
    });
};

/**
 * This function will remap all objects with the key `legacyKpiSettings`
 * Currently only on circuits. Used in the elevation phase mainly. Perhaps other places.
 *
 * Why is this needed?
 * The backend will format the response with:
 * circuit.kpiSettings = the kpis that is on the CIRCUIT only. (i.e. not streams etc)
 * circuit.legacyKpiSettings = all KPIs that is related to the circuit (i.e. stream KPIs and stage KPIs too)
 *
 * This frontend expects all KPIs to be received in the .kpiSettings field therefore a remapping must occur
 * In this frontend:
 * circuit.kpiSettings = circuit.legacyKpiSettings
 *
 * There is no need map the key when making requests to the backend.
 *
 * @deprecated
 */
const _legacyCircuitKpiSettingsMapper = (response: object) => {
    try {
        _remapLegacyCircuitKpiSettings(response);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('[LegacyMapper] Tried mapping v2 stage type to legacy stage type.', err);
        throw err;
    }
    return response;
};

/**
 * Called when the backend responds to a request
 */
export const legacyBackwardMappers = (response: object): PromiseLike => {
    try {
        _legacyCircuitKpiSettingsMapper(response);
    } catch {
        console.warn(
            'Failed backward mappers. Potential failure possible elsewhere. Returning OK for now'
        );
    }
    return Promise.resolve(response);
};
