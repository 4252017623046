// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';

// Components
import { SidebarLayout } from 'components/_ReactUI_V1';

// Services
import { QuickNavigatorObservable, PageTitleObservable } from 'components/_FrontendObservables';

// Constants
import { STYLE_VALUES, USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES } from 'utils/constants';

// Container
import PlantTableContainer from 'containers/ClientPlantUserManagementContainer/PlantManagementContainer/PlantTableContainer';
import PlantSetupContainer from 'containers/ClientPlantUserManagementContainer/PlantManagementContainer/PlantSetupContainer';

// Types
import type { ImmutablePlant } from 'services/Plant/types';

import type { HistoryType } from 'types';

type Props = {
    history: HistoryType,
};

type State = {
    plantToCreate?: ImmutablePlant,
};

class PlantManagementContainer extends React.PureComponent<Props, State> {
    state = {
        plantToCreate: null,
    };

    componentWillUnmount() {
        QuickNavigatorObservable.removeQuickNavigator();
        PageTitleObservable.removePageTitle();
    }

    handleCreatePlantV1 = (plant: ImmutablePlant) =>
        this.setState({
            plantToCreate: plant,
        });

    handleOnPlantConfigV2 = (plant: ImmutablePlant) => {
        this.props.history.replace(
            `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS}/${plant.get('id')}`
        );
    };

    handleExitCreateOrUpdateProcess = () => {
        this.setState({
            plantToCreate: null,
        });
    };

    renderPlantSetupContainer = () => (
        <PlantSetupContainer
            plant={this.state.plantToCreate}
            onExitCreateOrUpdateProcess={this.handleExitCreateOrUpdateProcess}
        />
    );

    renderPlantConfig = () => this.state.plantToCreate && this.renderPlantSetupContainer();

    renderPlantTableContainer = () => (
        <div style={{ height: `calc(100% - ${STYLE_VALUES.HEADER.HEIGHT})` }}>
            <PlantTableContainer
                onUpdatePlantProcess={this.handleCreatePlantV1}
                onPlantConfigV2={this.handleOnPlantConfigV2}
            />
        </div>
    );

    render() {
        return (
            <SidebarLayout
                styles={{
                    main: { overflowY: 'auto' },
                    container: {
                        height: '100%',
                    },
                }}
                renderMain={
                    this.state.plantToCreate
                        ? this.renderPlantConfig
                        : this.renderPlantTableContainer
                }
                mainFlush
                flush
            />
        );
    }
}

export default withRouter(PlantManagementContainer);
