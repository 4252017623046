// @flow strict

import styled from 'styled-components';

import { STYLE_VALUES } from 'utils/constants';
import { LegacyTheme } from 'components/_ReactUI_V1';

export const MajorKPIBlocksWrapper = styled.div`
    display: flex;
    flex-direction: column;

    margin-right: 28px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        flex-direction: row;
        justify-content: center;

        margin-right: 0px;
        padding-top: 40px;
    }
`;

export const StatusWrapper = styled.div`
    color: ${LegacyTheme.defaultSubtleColor};
    margin: 10px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        display: none;
    }
`;

export const Separator = styled.div`
    display: flex;
    flex: 0;
    height: 1px;
    border-top: 1px solid ${LegacyTheme.defaultBorder};

    margin: 0 10px 10px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        display: none;
    }
`;
