// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { colors } from 'styles/colors';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-width: 450px;
    margin: 14px 0;

    border-radius: 4px;
    border: 1px solid ${colors.blue019};
    border: ${({ editing }) => (editing ? `1px solid ${colors.greyE6}` : ` 1px solid${colors.blue019}`)};
    background-color: ${colors.white};


    background-color: ${({ darken }) =>
        darken ? LegacyTheme.collapsibleBlockHeaderBackground : colors.white};

    &:last-child {
        margin-bottom: 0;
    }
`;

export const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 40px;
    padding-left: 20px;
    padding-right: 10px;

    background-color: ${({ darken, editing }) => (darken ? colors.greyDD : editing ? colors.greyE6 : colors.blue019)};
`;

export const CardTitle = styled.h3`
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    color: ${({ darken,editing }) =>
        darken ? LegacyTheme.defaultSubtleColor7B : editing ? LegacyTheme.defaultColor :colors.white};

    margin: 0;
`;

export const CardBodyText = styled.span`
    font-size: 14px;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;

    color: ${({ darken }) =>
        darken ? LegacyTheme.defaultSubtleColor7B : colors.greyFA};

    padding: 20px;
    padding-top: 16px;

    > textarea {
        font-size: 13px;
    }

    > span {
        // input text as a renderString
        color: ${({ darken }) =>
            darken ? LegacyTheme.defaultSubtleColor7B : colors.greyFA};
    }

    > label {
        // checkbox
        margin: 24px 4px 4px 4px;
        font-size: 13px;
    }

    ${({ hasHorizontalRule }) =>
        hasHorizontalRule &&
        `
        border-top: 1px solid ${LegacyTheme.defaultBorder};
    `}
`;

export const CardBodyTitle = styled.p`
    margin: 0;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: ${colors.greyFA};
`;

export const FeedbackWrapper = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: ${({ submitted }) => (submitted ? '0' : '10px')};
`;

export const FeedbackLabel = styled.div`
    margin: 10px 0 5px 0;
    font-size: 14px;
    line-height: 24px;
    color: ${colors.greyFA};
`;

export const FeedbackText = styled.div`
    margin: 10px 0 5px 0;
    font-size: 14px;
    line-height: 24px;
    color: ${colors.greyFA};
`;

export const FeedbackButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;

    ${({ hasFeedback }) =>
        hasFeedback &&
        `
        > * {
            width: 50%;
        }
    `}
`;

export const FeedbackAuthorWrapper = styled.div`
    display: flex;
    flex-direction: column;

    margin-left: 10px;
`;

export const FeedbackAuthorName = styled.div`
    margin: 3px 0;
    font-size: 11px;
    color: ${LegacyTheme.defaultSubtleColor7B};
`;

export const FeedbackDate = styled.div`
    margin-bottom: 3px;
    font-size: 11px;
    color: ${LegacyTheme.defaultSubtleColor7B};
`;

export const RecommendationImpactWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${({ withoutMargin }) =>
        !withoutMargin &&
        `
        margin-top: 16px;
    `}
`;

export const CuTransferImpactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 24px;
`;

export const CuTransferImpactLabel = styled.label`
    font-size: 14px;
    color: ${LegacyTheme.buttonPrimary};
    margin-bottom: 4px;
`;
