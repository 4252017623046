// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import SidebarTabInline from 'components/SidebarTabInline';

// Constants
import { TRENDS_PAGE_TYPE } from 'utils/constants';

// Containers
import RecommendationsSidebarContainer from 'containers/TrendsSidebarContainer/RecommendationsSidebarContainer';
import ScenarioContainer from 'containers/TrendsSidebarContainer/ScenarioContainer';

// Selectors
import { selectUser } from 'services/Authentication/selectors';

// Types
import type { IntlType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { TrendsPageType, ImmutableTrendData } from 'services/Trends/types';

// Utils
import { isClientUser } from 'utils/authentication';

export const TREND_SIDEBAR_SECTIONS = {
    RECOMMENDATIONS: 'RECOMMENDATIONS',
    SCENARIO: 'SCENARIO',
};

type TrendSidebarSections = $Keys<typeof TREND_SIDEBAR_SECTIONS>;

type InjectedProps = {
    intl: IntlType,
    user: ImmutableUser,
};

type Props = InjectedProps & {
    circuit: ImmutableCircuit | null,
    circuitId: number | null,

    isLoadingDataset: boolean,
    isLoadingTrend: boolean,

    plantId: number | null,

    trend: ImmutableTrendData,
    trendType: TrendsPageType,
};

type State = {
    activeSection: TrendSidebarSections,
};

class TrendsSidebarContainer extends React.PureComponent<Props, State> {
    static defaultProps = {
        circuitId: null,
        plantId: null,
    };

    state = {
        activeSection: TREND_SIDEBAR_SECTIONS.RECOMMENDATIONS,
    };

    createSectionTitleClickHandler = (activeSection: TrendSidebarSections) => () => {
        if (this.state.activeSection === activeSection) {
            return;
        }
        this.setState({
            activeSection,
        });
    };

    canAccessScenarioSection = () =>
        this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT &&
        this.props.circuitId &&
        isClientUser(this.props.user);

    renderRecommendationsSidebar = () => (
        <RecommendationsSidebarContainer
            trend={this.props.trend}
            circuit={this.props.circuit}
            trendType={this.props.trendType}
            circuitId={this.props.circuitId}
            plantId={this.props.plantId}
            isLoadingTrend={this.props.isLoadingTrend}
            isLoadingDataset={this.props.isLoadingDataset}
        />
    );

    renderScenarioSidebar = () => (
        <ScenarioContainer
            trend={this.props.trend}
            circuitId={this.props.circuitId}
            circuit={this.props.circuit}
            isLoadingTrend={this.props.isLoadingTrend}
            isLoadingDataset={this.props.isLoadingDataset}
        />
    );

    render() {
        return (
            <React.Fragment>
                <SidebarTabInline
                    handleOnHeaderClick={this.createSectionTitleClickHandler(
                        TREND_SIDEBAR_SECTIONS.RECOMMENDATIONS
                    )}
                    active={this.state.activeSection === TREND_SIDEBAR_SECTIONS.RECOMMENDATIONS}
                    title={this.props.intl.formatMessage({
                        id: 'components.RecommendationSidebar.title',
                    })}
                    body={this.renderRecommendationsSidebar()}
                    backgroundStyling={true}
                />
                {this.canAccessScenarioSection() && (
                    <SidebarTabInline
                        handleOnHeaderClick={this.createSectionTitleClickHandler(
                            TREND_SIDEBAR_SECTIONS.SCENARIO
                        )}
                        active={this.state.activeSection === TREND_SIDEBAR_SECTIONS.SCENARIO}
                        title={this.props.intl.formatMessage({
                            id: 'components.ScenarioSidebar.title',
                        })}
                        body={this.renderScenarioSidebar()}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
    });

export default connect(mapStateToProps)(injectIntl(TrendsSidebarContainer));
