// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import PlantManagerTable from 'components/PlantManager/PlantManagerTable';

// Selectors
import {
    selectQueriedPlants,
    selectQueriedPlantsLastPage,
    selectPlantErrors,
    selectPlantIsDeleting,
    selectPlantsAreQuerying,
} from 'services/Plant/selectors';

// Styles
import { LayoutContainer } from 'styles/common';

// Thunks
import { destroyPlant, downloadTemplate, queryAllPlants } from 'services/Plant/thunks';

// Types
import type { ErrorType, ImmutableList, ReduxDispatch, SearchCriteria } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';

// Constants (not imported)
const DEFAULT_PAGE = 1;
const DEFAULT_SORTBY = 'name';
const DEFAULT_SORTORDER = 'asc';

type Props = {
    errors: ErrorType,

    queriedPlants: ImmutableList<ImmutablePlant>,
    plantIsDeleting: boolean,
    plantsAreQuerying: boolean,
    lastPage: number,

    destroyPlant: (number) => void,
    queryAllPlants: (SearchCriteria, number) => void,
    downloadTemplate: (plantId: number, fileName: string) => void,
    onUpdatePlantProcess: (plant?: ImmutablePlant) => void,
    onPlantConfigV2: (plant?: ImmutablePlant) => void,
};

type State = {
    pagination: {
        page: number,
    },
    searchCriteria: SearchCriteria,
};

class PlantTableContainer extends React.PureComponent<Props, State> {
    state = {
        pagination: {
            page: DEFAULT_PAGE,
        },
        searchCriteria: {
            sortBy: DEFAULT_SORTBY,
            sortOrder: DEFAULT_SORTORDER,
        },
    };

    componentDidMount() {
        // TODO: Rethink this situation;
        // If a user visits a plant trend/archive directly, we put the plant entity into the query object within state
        // However this list will only contain the one plant, and without all of it's information
        // Therefore if a user then goes to edit that plant, the application crashes
        // Perhaps we should not push the plant into the query list?
        // Quick fix: Always re-query plants on component mount
        this.props.queryAllPlants(this.state.searchCriteria, this.state.pagination.page);
    }

    /**
     * On pagination change, update state with provided page
     * Fire props.queryAllPlants() with updated searchCriteria and page
     */
    handlePaginationPageSelection = (page: number, total: number) => () => {
        if (page > total || page < 0) {
            return null;
        }

        return this.setState(
            {
                pagination: {
                    page,
                },
            },
            () => this.onQueryAllPlants()
        );
    };

    /**
     * On table sorting, set provided sortBy & sortOrder to state.searchCriteria
     * Reset state.pagination.page to default
     * Fire props.queryAllPlants() with updated searchCriteria and page
     */
    handleSortBy = (searchCriteria: SearchCriteria) => {
        this.setState(
            {
                searchCriteria,
                pagination: {
                    page: DEFAULT_PAGE,
                },
            },
            () => this.onQueryAllPlants()
        );
    };

    handleDownloadClicked = (plantId: number, fileName: string) => {
        this.props.downloadTemplate(plantId, fileName);
    };

    /**
     * Fires props.queryAllPlants
     * If provided reset boolean is true, reset searchCriteria to defaults first.
     */
    onQueryAllPlants = (reset: boolean = false) => {
        if (reset) {
            this.setState(
                {
                    searchCriteria: {
                        sortBy: DEFAULT_SORTBY,
                        sortOrder: DEFAULT_SORTORDER,
                    },
                    pagination: {
                        page: DEFAULT_PAGE,
                    },
                },
                () =>
                    this.props.queryAllPlants(this.state.searchCriteria, this.state.pagination.page)
            );
        } else {
            return this.props.queryAllPlants(this.state.searchCriteria, this.state.pagination.page);
        }
    };

    render() {
        return (
            <LayoutContainer fullWidth>
                <PlantManagerTable
                    errors={this.props.errors}
                    handleDeletePlant={this.props.destroyPlant}
                    lastPage={this.props.lastPage}
                    onHandlePaginationPageSelection={this.handlePaginationPageSelection}
                    onHandleSortBy={this.handleSortBy}
                    pagination={this.state.pagination}
                    plantIsDeleting={this.props.plantIsDeleting}
                    plantsAreQuerying={this.props.plantsAreQuerying}
                    searchCriteria={this.state.searchCriteria}
                    plants={this.props.queriedPlants}
                    onUpdatePlantProcess={this.props.onUpdatePlantProcess}
                    handleDownloadClicked={this.handleDownloadClicked}
                    onPlantConfigV2={this.props.onPlantConfigV2}
                    handleDownloadClicked={this.handleDownloadClicked}
                />
            </LayoutContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectPlantErrors(),
    lastPage: selectQueriedPlantsLastPage(),
    queriedPlants: selectQueriedPlants(),
    plantIsDeleting: selectPlantIsDeleting(),
    plantsAreQuerying: selectPlantsAreQuerying(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            destroyPlant,
            queryAllPlants,
            downloadTemplate,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PlantTableContainer)
);
