// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper } from './styles';

// Components
import BasicSidebarSection from 'components/CircuitSetupSidebar/BasicSidebarSection';
import StagesSidebarSection from 'components/CircuitSetupSidebar/StagesSidebarSection';
import ReagentSidebarSection from 'components/CircuitSetupSidebar/ReagentSidebarSection';

import type { IntlType, ImmutableList, UnitsConstant, ProductionUnitsConstant } from 'types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';
import type { WashPosition } from 'containers/CircuitSetupContainer/MimicDiagramContainer';
import type { ImmutableMetal } from 'services/Metal/types';
import type { StageTypeConstant } from 'services/Circuit/types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    loadingCircuit: boolean,
    extractStageCount: number,
    stripStageCount: number,
    washerETSStageActive: boolean,
    washerSTEStageActive: boolean,
    loadedOrganicTankActive: boolean,
    selectedReagent: ?ImmutableReagent,
    selectedOxime: ?ImmutableOxime,

    metals: ImmutableList<ImmutableMetal>,
    selectedMetal: ?ImmutableMetal,
    loadingMetals: boolean,
    selectedCircuitUnit: ?UnitsConstant,
    selectedProductionUnit: ?ProductionUnitsConstant,
    isothermStoichiometryFactor: number,

    onStageCountChange: (type: StageTypeConstant, value: number) => void,
    onWashActiveChange: (location: WashPosition, active: boolean) => void,
    onTankChange: (type: StageTypeConstant, active: boolean) => void,
    onSelectReagent: (selectedReagent: ImmutableReagent) => void,
    onSelectOxime: (selectedOxime: ImmutableOxime) => void,
    onSelectMetal: (selectedMetal: ImmutableMetal) => void,
    onSelectCircuitUnit: (circuitUnit: ?UnitsConstant) => void,
    onSelectProductionUnit: (productionUnit: ?ProductionUnitsConstant) => void,
    onChangeIsothermStoichiometryFactor: (value: number) => void,
};

class CircuitSetupSidebar extends React.PureComponent<Props, null> {
    render() {
        return (
            <Wrapper>
                <BasicSidebarSection {...this.props} />
                <StagesSidebarSection {...this.props} />
                <ReagentSidebarSection {...this.props} />
            </Wrapper>
        );
    }
}

export default injectIntl(CircuitSetupSidebar);
