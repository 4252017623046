// @flow

import React from 'react';

import { StyledMessageBlock, StyledMessageBlockIcon } from './styles';

const MessageBlock = (props: { color?: string, icon?: Object, text?: string }) => (
    <StyledMessageBlock color={props.color}>
        {props.icon && <StyledMessageBlockIcon>{props.icon}</StyledMessageBlockIcon>}
        {props.text}
    </StyledMessageBlock>
);

MessageBlock.defaultProps = {
    color: '',
    icon: null,
    text: 'Default text',
};

export default MessageBlock;
