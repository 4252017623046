// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import {
    Field,
    WeekDayToggleWrapper,
    WeekDayToggleLabel,
    WeekDayTimeWrapper,
    WeekDayTimeLabel,
    WeekDayStartTime,
    WeekDayEndTime,
} from './styles';

// Components
import { Toggle, InputNumber } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import type { ImmutableNotificationSchedule } from 'services/Notifications/types';

// Constants
import { WEEK_DAYS } from 'utils/constants';

type Props = {
    intl: IntlType,

    schedule: ImmutableNotificationSchedule,
    dayName: string,
    onToggleScheduleDay: (dayName: string) => void,
    onChangeScheduleDayTime: (dayName: string, timeKey: string, timeValue: string) => void,
};

class UserNotificationScheduleDay extends React.PureComponent<Props> {
    onChange = (event: InputEvent, dayName: string, timeKey: string) => {
        const timeValue = event.target.value;
        this.props.onChangeScheduleDayTime(dayName, timeKey, timeValue);
    };

    onClick = (dayName: string) => {
        this.props.onToggleScheduleDay(dayName);
    };

    render() {
        const weekDayData = this.props.schedule.find((el) => el.get('day') === this.props.dayName);

        return (
            <Field>
                <WeekDayToggleWrapper>
                    <WeekDayToggleLabel>
                        {this.props.intl.formatMessage({
                            id: `common.weekDays.${this.props.dayName}`,
                        })}
                    </WeekDayToggleLabel>
                    <Toggle
                        checked={Boolean(weekDayData)}
                        onClickHandler={() => this.onClick(this.props.dayName)}
                    />
                </WeekDayToggleWrapper>

                {weekDayData && (
                    <WeekDayTimeWrapper>
                        <WeekDayStartTime>
                            <WeekDayTimeLabel>
                                {this.props.intl.formatMessage({ id: `common.startTime` })}
                            </WeekDayTimeLabel>
                            <InputNumber
                                handleOnChange={(e) =>
                                    this.onChange(e, weekDayData.get('day'), 'startingTime')
                                }
                                type={'time'}
                                value={weekDayData.get('startingTime')}
                            />
                        </WeekDayStartTime>

                        <WeekDayEndTime>
                            <WeekDayTimeLabel>
                                {this.props.intl.formatMessage({ id: `common.endTime` })}
                            </WeekDayTimeLabel>
                            <InputNumber
                                handleOnChange={(e) =>
                                    this.onChange(e, weekDayData.get('day'), 'endingTime')
                                }
                                type={'time'}
                                value={weekDayData.get('endingTime')}
                            />
                        </WeekDayEndTime>
                    </WeekDayTimeWrapper>
                )}
            </Field>
        );
    }
}

export default injectIntl(UserNotificationScheduleDay);
