// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import type {
    IMimicCircuit,
    IMimicStage,
    IContinueStream,
    IMimicLoadedOrganicTank,
} from 'components/_McCabeThiele';

// Styles
import { CheckBox, InputSelect } from 'components/_ReactUI_V1';

import {
    Wrapper,
    SelectUnitWrapper,
    CheckboxAndRadioStyles,
    InputStyles,
    CheckBoxBody,
} from './styles';

// Types
import type { IntlType, InputEvent, ReactSelectObject } from 'types';

// constants
import { STAGE_TYPES, NEW_TANK_OPTION } from 'utils/constants';

export const TANK_STREAM_DIRECTIONS = {
    TO_TANK: 'TO_TANK',
    FROM_TANK: 'FROM_TANK',
};

export type TankStreamDirections = $Keys<typeof TANK_STREAM_DIRECTIONS>;

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    mimicCircuit: IMimicCircuit,
    mimicStream: IContinueStream,
    streamDirection: TankStreamDirections,
    isBlendMode: boolean,
    hasNewToTank: boolean,

    onSelect: (tank: IMimicLoadedOrganicTank | typeof NEW_TANK_OPTION | null) => void,
    onSelectToPreviousLoadedOrganic: (isSelected: boolean) => void,
};

type State = {
    isEnabled: boolean,
    isToPreviousLOTankSelected: boolean,
    selectedTankOption: ReactSelectObject | null, // initially null
};

/**
 * Rendered in the organic stream select modal.
 * Allows the user to select the organic bypassing from/to tanks.
 */
class TankSelectBody extends React.PureComponent<Props, State> {
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        /**
         * When we are the "fromTank" selector, then we must check that
         * the option for new tanks is properly set.
         * The from tank can only be set to new, when the to tank is also new.
         * If this requirement is not fulfilled, then the `selectedTankOption` is reset.
         */
        if (
            nextProps.streamDirection === TANK_STREAM_DIRECTIONS.FROM_TANK &&
            !nextProps.hasNewToTank &&
            prevState.selectedTankOption &&
            prevState.selectedTankOption.value === NEW_TANK_OPTION
        ) {
            return {
                isEnabled: false,
                isToPreviousLOTankSelected: false,
                selectedTankOption: null,
            };
        }
        return prevState;
    }

    state = {
        isEnabled: false,
        isToPreviousLOTankSelected: false,
        selectedTankOption: null,
    };

    isExtractSection = (): boolean =>
        this.props.mimicStream.toStage.stageType === STAGE_TYPES.EXTRACT;

    isStripSection = (): boolean => this.props.mimicStream.toStage.stageType === STAGE_TYPES.STRIP;

    getFromStageCode = () => {
        return this.props.mimicStream.fromStage.getCode();
    };

    getToStageCode = () => {
        return this.props.mimicStream.toStage.getCode();
    };

    getTanksBeforeStage(stage: IMimicStage): Array<IMimicLoadedOrganicTank> {
        const allTanks = this.props.mimicCircuit.getTanks();
        return allTanks.filter((tank: IMimicLoadedOrganicTank) => {
            const extractor = tank.getFirstConnectedFromStage();
            return extractor.location < stage.location;
        });
    }

    getTanksAfterStage(stage: IMimicStage): Array<IMimicLoadedOrganicTank> {
        const allTanks = this.props.mimicCircuit.getTanks();
        return allTanks.filter((tank: IMimicLoadedOrganicTank) => {
            const extractor = tank.getFirstConnectedFromStage();
            return extractor.location > stage.location;
        });
    }

    /**
     * returns a list of organic tanks that can replace an organic continue by
     * an organic bypass. In the case of a FROM tank, we are converting to a
     * bypass bleed. A bypass bleed can come from an LO tank.
     *
     * If in extract: returns all the tanks AFTER the continue stream's toStage
     * If in strip: returns all the tanks BEFORE the continue stream's toStage
     */
    getFromTanks = (): Array<IMimicLoadedOrganicTank> => {
        if (this.isExtractSection()) {
            return this.getTanksAfterStage(this.props.mimicStream.toStage);
        } else if (this.isStripSection()) {
            return this.props.mimicCircuit.getTanks();
        }
        throw new Error('GetFromTanks called in a non proper context.');
    };

    /**
     * Returns a list of organic tanks that can accept an organic bypass feed
     * from an organic continue in extract being converted into a bypass.
     *
     * If in extract: returns all the tanks BEFORE the from extract stage
     * If in strip: not possible yet, BO tanks necessary not LO tanks.
     */
    getToTanks = (): Array<IMimicLoadedOrganicTank> => {
        if (this.isExtractSection()) {
            return this.getTanksBeforeStage(this.props.mimicStream.fromStage);
        } else if (this.isStripSection()) {
            return [];
        }
        throw new Error('GetToTanks called in a non proper context.');
    };

    getTanks = (): Array<IMimicLoadedOrganicTank> => {
        switch (this.props.streamDirection) {
            case TANK_STREAM_DIRECTIONS.FROM_TANK:
                return this.getFromTanks();
            case TANK_STREAM_DIRECTIONS.TO_TANK:
                return this.getToTanks();
            default:
                throw new Error('Unknown tank stream direction type');
        }
    };

    /**
     * The user can create a tank at this location if:
     * - This tank select body is for the outflow of a stream.
     * - there are no tanks present at that location.
     *      Note that this is impossible currently, you can only access the
     *      tank select body from the organic stream select modal, which is only
     *      possible when the organic stream is a continue stream.
     */
    canCreateTank = (): boolean => {
        if (!this.isExtractSection()) {
            return false;
        }
        if (this.props.isBlendMode) {
            return false;
        }
        if (this.props.streamDirection === TANK_STREAM_DIRECTIONS.FROM_TANK) {
            return this.props.hasNewToTank;
        }
        return true;
    };

    getTranslation(id: string, data: any) {
        return this.props.intl.formatMessage(
            {
                id: `components.TankSelectBody.${id}`,
            },
            data
        );
    }

    itemToReactSelectObject = (tank: IMimicLoadedOrganicTank): ReactSelectObject => ({
        value: `${tank.location}`,
        label: `${tank.getCode()}`,
    });

    /**
     * Get the flow unit options from the current location
     */
    getTankOptions = (): Array<ReactSelectObject> => {
        const tanks = this.getTanks();
        const options = tanks.map(this.itemToReactSelectObject);

        if (this.canCreateTank()) {
            options.push({
                value: NEW_TANK_OPTION,
                label: this.getTranslation(`newTankOption.${this.props.streamDirection}`, {
                    fromStageCode: this.getFromStageCode(),
                    toStageCode: this.getToStageCode(),
                }),
            });
        }
        return options;
    };

    shouldShowCheckboxToPreviousLoadedOrganicTank = () => {
        return (
            this.props.hasNewToTank &&
            this.state.selectedTankOption &&
            this.state.selectedTankOption.value === NEW_TANK_OPTION &&
            this.props.streamDirection === TANK_STREAM_DIRECTIONS.FROM_TANK
        );
    };

    /**
     * When the checkbox to enable the to tank stream option in bypassing mode.
     */
    handleToggleTank = (event: InputEvent) => {
        this.setState(
            {
                isEnabled: event.target.value === 'true',
                selectedTankOption: null,
            },
            () => this.props.onSelect(null)
        );
    };

    updateParentState = () => {
        const selectedOption = this.state.selectedTankOption;
        if (!selectedOption) {
            return this.props.onSelect(null);
        }

        if (selectedOption.value === NEW_TANK_OPTION) {
            return this.props.onSelect(NEW_TANK_OPTION);
        }

        const selectedTank = this.getTanks().find(
            (tank: IMimicLoadedOrganicTank) => tank.location.toString() === selectedOption.value
        );
        if (!selectedTank) {
            throw new Error('Unknown tank selected via the dropdown?');
        }
        this.props.onSelect(selectedTank);
    };

    /**
     * When the user changes the selected option in the drop down.
     */
    handleChangeTankOption = (selectedTankOption: ReactSelectObject) => {
        this.setState(
            {
                selectedTankOption,
            },
            this.updateParentState
        );
    };

    handleToggleToPreviousLOTank = (event: InputEvent) => {
        this.setState(
            {
                isToPreviousLOTankSelected: event.target.value === 'true',
            },
            () => this.props.onSelectToPreviousLoadedOrganic(this.state.isToPreviousLOTankSelected)
        );
    };

    render() {
        const headerStageCodes = {
            fromStageCode: this.getFromStageCode(),
            toStageCode: this.getToStageCode(),
        };
        return (
            <Wrapper>
                <CheckBox
                    checked={this.state.isEnabled}
                    label={this.getTranslation(
                        `Enable.${this.props.streamDirection}`,
                        headerStageCodes
                    )}
                    onClickHandler={this.handleToggleTank}
                    styles={CheckboxAndRadioStyles}
                />
                <CheckBoxBody>
                    {this.state.isEnabled && (
                        <React.Fragment>
                            <SelectUnitWrapper>
                                <span>{this.getTranslation('selectUnit')}</span>
                                <InputSelect
                                    selectedOption={this.state.selectedTankOption}
                                    isDisabled={!this.state.isEnabled}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'components.Modals.selectButton',
                                    })}
                                    options={this.getTankOptions()}
                                    onSelect={this.handleChangeTankOption}
                                    styles={InputStyles}
                                    controlShouldRenderValue
                                />
                            </SelectUnitWrapper>
                            {this.shouldShowCheckboxToPreviousLoadedOrganicTank() && (
                                <CheckBox
                                    checked={this.state.isToPreviousLOTankSelected}
                                    label={this.getTranslation(
                                        `Enable.${
                                            this.props.streamDirection
                                        }.toPreviousLoadedOrganic`
                                    )}
                                    onClickHandler={this.handleToggleToPreviousLOTank}
                                    styles={CheckboxAndRadioStyles}
                                />
                            )}
                        </React.Fragment>
                    )}
                </CheckBoxBody>
            </Wrapper>
        );
    }
}

export default injectIntl(TankSelectBody);
