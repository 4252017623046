// @noflow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { ContainerCentered } from 'styles/common';
import { Wrapper, LoaderText } from './styles';
import { Loader } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,

    loading: boolean,
    messageId?: string,
};

/**
 * Used to display a loader and a message when the entire container should be in a loading state
 */
class AppLoader extends React.PureComponent<Props> {
    static defaultProps = {
        messageId: 'components.AppLoader.loaderTitle',
    };

    render() {
        if (!this.props.loading) return null;
        return (
            <ContainerCentered>
                <Wrapper>
                    <Loader
                        title={this.props.intl.formatMessage({
                            id: 'components.AppLoader.loaderAlt',
                        })}
                        loading
                    />
                    <LoaderText>
                        {this.props.intl.formatMessage({
                            id: this.props.messageId,
                        })}
                    </LoaderText>
                </Wrapper>
            </ContainerCentered>
        );
    }
}

export default injectIntl(AppLoader);
