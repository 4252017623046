// @flow strict

import { fromJS } from 'immutable';

// TODO: MS-281 - Add KPI Related helper into this file (including app/components/KPICard/helpers.js)

// Constants
import {
    STAGE_TYPES,
    STREAM_TYPES,
    STREAM_CIRCUITS,
    STAGE_VALUE_TYPES,
    STREAM_VALUE_TYPES,
    DATASET_VALUE_TYPES,
    KPI_SPECIFICITY_TYPES,
    MINOR_KPI_SECTIONS,
    KPI_SETTING_PROPERTIES,
    PLANT_MINOR_KPI_TYPES_PER_KPI_SECTION,
    CIRCUIT_MINOR_KPI_TYPES_PER_KPI_SECTION,
    KPI_TYPES_WITH_RECOMMENDATIONS,
    KPI_TYPES_WITH_MAIN_TARGET,
    KPI_TYPE_TO_UNIT_TYPE,
    UNITS_FOR_UNIT_TYPE,
    COMPLEX_KPI_UNIT_DELIMITER,
    LOCALITY_DEFAULT_UNITS_FOR_UNIT_TYPE,
    UNIT_NAMES,
    METAL_TYPES,
} from 'utils/constants';
import { DEFAULT_PRECISION, KPI_INVALID_VALUE_REASONS } from 'utils/kpiConstants';

// Helpers
import {
    round,
    roundToNearest,
    getStreamById,
    getStageById,
    getStageCode,
    getStreamCode,
    getStripperStages,
    getExtractorStages,
} from 'utils/helpers';
import {
    hasExtractBypass,
    hasStripBypass,
    hasOrganicBlend,
    isStreamInterstageBleed,
    getAdvancedStreamForContinue,
} from 'components/MimicDiagram/helpers';
import { isTankFlowrateComputeUsing } from 'containers/CircuitComputationContainer/MimicContainer/helpers';

// Types
import type { ImmutableList, KpiUnitType, UnitsConstant, KpiUnitName, IntlType } from 'types';
import type {
    ImmutableKPISetting,
    AllKpiTypes,
    KPISectionType,
    KPIInvalidValueReasonType,
} from 'services/KPISetting/types';
import type {
    ImmutableCircuit,
    ImmutableStream,
    ImmutableStage,
    StreamEntity,
} from 'services/Circuit/types';
import type {
    ImmutableDataset,
    ImmutableDatasetValue,
    ImmutableStageValue,
    ImmutableStreamValue,
} from 'services/Dataset/types';
import type { ImmutablePlantDataset, ImmutablePlantValue } from 'services/PlantDataset/types';

type MinorKpiTypesPerSection = {
    [x: KPISectionType]: Array<AllKpiTypes>,
};

/**
 * Round the value to the kpi's nearest roundTo value, else use precision to round
 */
export const getRoundedValue = (value: number, kpi: ImmutableKPISetting) => {
    const kpiRoundTo = kpi && kpi.get('roundTo');
    const kpiPrecision =
        kpi && kpi.get('precision') !== null ? kpi.get('precision') : DEFAULT_PRECISION;

    if (kpiRoundTo) {
        return roundToNearest(value, kpiRoundTo);
    }

    return round(value, kpiPrecision);
};

export const getKPISettingContextId = (kpiSetting: ImmutableKPISetting) => {
    switch (kpiSetting.get('specificityType')) {
        case KPI_SPECIFICITY_TYPES.PLANT: {
            return kpiSetting.get('plantId');
        }
        case KPI_SPECIFICITY_TYPES.CIRCUIT: {
            return kpiSetting.get('circuitId');
        }
        case KPI_SPECIFICITY_TYPES.STAGE: {
            return kpiSetting.get('stageId');
        }
        case KPI_SPECIFICITY_TYPES.STREAM: {
            return kpiSetting.get('streamId');
        }
        default:
            throw new Error('Unknown KPI specificity type.');
    }
};

/**
 * From a KPI Setting and a given plant dataset,
 * returns the PlantValue
 */
export const getPlantValueDataFromPlantDataset = (
    kpiSetting: ImmutableKPISetting,
    plantDataset: ImmutablePlantDataset
): ImmutablePlantValue | null => {
    if (!kpiSetting) {
        throw new Error('KPI Setting not provided, something went wrong');
    }

    if (kpiSetting.get('specificityType') !== KPI_SPECIFICITY_TYPES.PLANT) {
        throw new Error('Cannot use getPlantValueDataFromPlantDataset for non-plant values.');
    }

    return plantDataset
        .get('plantValues', [])
        .find(
            (plantValue: ImmutablePlantValue) =>
                plantValue.get('valueType') === kpiSetting.get('kpiType')
        );
};

/**
 * From a KPI Setting and a given dataset,
 * returns the StageValue, StreamValue or DatasetValue
 */
export const getCircuitValueDataFromDataset = (
    kpiSetting: ImmutableKPISetting,
    dataset: ImmutableDataset
): ImmutableDatasetValue | ImmutableStreamValue | ImmutableStageValue | null => {
    const contextId = getKPISettingContextId(kpiSetting);
    if (!kpiSetting) {
        throw new Error('KPI Setting not provided, something went wrong');
    }

    switch (kpiSetting.get('specificityType')) {
        case KPI_SPECIFICITY_TYPES.PLANT: {
            throw new Error('Cannot use getCircuitValueDataFromDataset for plant values.');
        }
        case KPI_SPECIFICITY_TYPES.CIRCUIT: {
            return dataset
                .get('datasetValues', [])
                .find(
                    (datasetValue: ImmutableDatasetValue) =>
                        datasetValue.get('valueType') === kpiSetting.get('kpiType')
                );
        }
        case KPI_SPECIFICITY_TYPES.STAGE: {
            return dataset
                .get('stageValues', [])
                .find(
                    (stageValue: ImmutableStageValue) =>
                        stageValue.get('valueType') === kpiSetting.get('kpiType') &&
                        stageValue.get('stageId') === contextId
                );
        }
        case KPI_SPECIFICITY_TYPES.STREAM: {
            return dataset
                .get('streamValues', [])
                .find(
                    (streamValue: ImmutableStreamValue) =>
                        streamValue.get('valueType') === kpiSetting.get('kpiType') &&
                        streamValue.get('streamId') === contextId
                );
        }
        default:
            throw new Error('Unknown KPI specificity type.');
    }
};

export const getKPISettingRelatedName = (
    kpi: ImmutableKPISetting,
    circuit: ImmutableCircuit,
    strict?: boolean = true
) => {
    switch (kpi.get('specificityType')) {
        case KPI_SPECIFICITY_TYPES.PLANT:
            throw new Error('KPI Setting get related name with plant type not supported yet.');
        case KPI_SPECIFICITY_TYPES.CIRCUIT:
            return circuit.get('name');
        case KPI_SPECIFICITY_TYPES.STAGE: {
            const stageId = kpi.get('stageId');
            if (!stageId) {
                throw new Error('KPI Specificity of type stage, but has no stage ID supplied.');
            }
            const stage = getStageById(circuit, stageId);
            return getStageCode(stage.toJS());
        }
        case KPI_SPECIFICITY_TYPES.STREAM: {
            const streamId = kpi.get('streamId');
            if (!streamId) {
                throw new Error('KPI Specificity of type stream, but has no stream ID supplied.');
            }
            const stream = getStreamById(circuit, streamId);
            const toStageId = stream.get('toStageId');
            const toStage = toStageId && getStageById(circuit, toStageId);
            const toStageCode = toStage && getStageCode(toStage.toJS());

            const fromStageId = stream.get('fromStageId');
            const fromStage = fromStageId && getStageById(circuit, fromStageId);
            const fromStageCode = fromStage && getStageCode(fromStage.toJS());

            return getStreamCode(fromStageCode, toStageCode);
        }
        case KPI_SPECIFICITY_TYPES.CASCADE: {
            if (strict) {
                throw new Error(`Not implemented yet.`);
            }
            return null;
        }
        default:
            throw new Error(`Unknown kpi specificity type: ${kpi.get('specificityType')}`);
    }
};

/**
 * Provide the matching KPI_SPECIFICITY_TYPES based on where the provided kpiType is found
 */
export const getSpecificityTypeByKpiType = (kpiType: AllKpiTypes) => {
    if (Object.keys(STAGE_VALUE_TYPES).includes(kpiType)) {
        return KPI_SPECIFICITY_TYPES.STAGE;
    }

    if (Object.keys(STREAM_VALUE_TYPES).includes(kpiType)) {
        return KPI_SPECIFICITY_TYPES.STREAM;
    }

    if (Object.keys(DATASET_VALUE_TYPES).includes(kpiType)) {
        return KPI_SPECIFICITY_TYPES.CIRCUIT;
    }

    throw new Error(
        'Unable to find the specificity type for the provided kpi, something went wrong'
    );
};

/**
 * Get the section of a kpi given the KPI type.
 * @param {AllKpiTypes} kpiType The value type of the KPI
 * @param {MinorKpiTypesPerSection} minorKpisPerSection An object denoting all overwritten KPIs per section
 * @param {Optional KPISectionType} fallback A fallback section if the KPI section is not defined. Defaults to OTHER.
 */
const getSectionFromKPI = (
    kpiType: AllKpiTypes,
    minorKpisPerSection: MinorKpiTypesPerSection,
    fallback: KPISectionType = MINOR_KPI_SECTIONS.OTHER
): KPISectionType => {
    const foundSection = Object.keys(minorKpisPerSection).find(
        (minorKpiSection: KPISectionType) =>
            minorKpisPerSection[minorKpiSection].findIndex(
                (minorKpiType: AllKpiTypes) => minorKpiType === kpiType
            ) !== -1
    );
    return foundSection || fallback;
};

/**
 * Get the KPI Section for the KPI Setting.
 * @param {ImmutableKPISetting} kpi The immutable KPI setting we want the section of
 * @param {Optional ImmutableCircuit} circuit Required if KPI Specificity type is of type stage or stream to get the proper fallback.
 */
export const getKPISection = (
    kpi: ImmutableKPISetting,
    circuit?: ImmutableCircuit
): KPISectionType => {
    const kpiType = kpi.get('kpiType');

    switch (kpi.get('specificityType')) {
        case KPI_SPECIFICITY_TYPES.PLANT: {
            return getSectionFromKPI(kpiType, PLANT_MINOR_KPI_TYPES_PER_KPI_SECTION);
        }
        case KPI_SPECIFICITY_TYPES.CIRCUIT: {
            return getSectionFromKPI(kpiType, CIRCUIT_MINOR_KPI_TYPES_PER_KPI_SECTION);
        }
        case KPI_SPECIFICITY_TYPES.STAGE: {
            if (!circuit) {
                throw new Error(
                    "Get KPI Section with no circuit for kpi of specificity type 'stage'."
                );
            }
            const stage = getStageById(circuit, kpi.get('stageId'));
            let fallback = null;
            switch (stage.get('stageType')) {
                case STAGE_TYPES.EXTRACT:
                    fallback = MINOR_KPI_SECTIONS.EXTRACTION;
                    break;
                case STAGE_TYPES.STRIP:
                    fallback = MINOR_KPI_SECTIONS.STRIPPING;
                    break;
                case STAGE_TYPES.ORGANIC_TANK:
                    fallback = MINOR_KPI_SECTIONS.ORGANIC;
                    break;
                case STAGE_TYPES.WASHER:
                    fallback = MINOR_KPI_SECTIONS.ORGANIC;
                    break;
                default:
                    throw new Error('Unknown stage type provided to getKPISection');
            }
            return getSectionFromKPI(kpiType, CIRCUIT_MINOR_KPI_TYPES_PER_KPI_SECTION, fallback);
        }
        case KPI_SPECIFICITY_TYPES.STREAM: {
            if (!circuit) {
                throw new Error(
                    "Get KPI Section with no circuit for kpi of specificity type 'stream'."
                );
            }
            const stream = getStreamById(circuit, kpi.get('streamId'));
            let fallback = null;
            switch (stream.get('streamCircuit')) {
                case STREAM_CIRCUITS.AQUEOUS:
                    fallback = MINOR_KPI_SECTIONS.EXTRACTION;
                    break;
                case STREAM_CIRCUITS.ORGANIC:
                    fallback = MINOR_KPI_SECTIONS.ORGANIC;
                    break;
                case STREAM_CIRCUITS.ELECTROLYTE:
                    fallback = MINOR_KPI_SECTIONS.STRIPPING;
                    break;
                default:
                    throw new Error('Unknown stream type provided to getKPISection');
            }
            return getSectionFromKPI(kpiType, CIRCUIT_MINOR_KPI_TYPES_PER_KPI_SECTION, fallback);
        }
        // case KPI_SPECIFICITY_TYPES.CASCADE: {
        // }
        default: {
            throw new Error('Cannot handle KPI Specificity type provided.');
        }
    }
};

/**
 * Get all circuit level KPIs for the circuit
 * @param {ImmutableCircuit} circuit the circuit
 */
export const getMinorMajorKPIsForCircuit = (
    circuit: ImmutableCircuit
): ImmutableList<ImmutableKPISetting> => {
    const circuitId = circuit.get('id');
    const baseCircuitKpi = {
        ...KPI_SETTING_PROPERTIES.BASE,
        specificityType: KPI_SPECIFICITY_TYPES.CIRCUIT,
        circuitId,
    };

    const kpis = [
        {
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: true, // will plants ever provide this?
            isDefault: true,
            name: DATASET_VALUE_TYPES.OVERALL_RECOVERY,
            kpiType: DATASET_VALUE_TYPES.OVERALL_RECOVERY,
        },
        {
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: true, // will plants ever provide this?
            isDefault: true,
            name: DATASET_VALUE_TYPES.CU_TRANSFERRED,
            kpiType: DATASET_VALUE_TYPES.CU_TRANSFERRED,
        },
        {
            ...baseCircuitKpi,
            isRequired: true,
            isForcedRequire: true,
            name: DATASET_VALUE_TYPES.MAX_LOAD,
            kpiType: DATASET_VALUE_TYPES.MAX_LOAD,
        },
        {
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: false,
            isDefault: true,
            name: DATASET_VALUE_TYPES.REAGENT_CONCENTRATION,
            kpiType: DATASET_VALUE_TYPES.REAGENT_CONCENTRATION,
        },
        {
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: false,
            name: DATASET_VALUE_TYPES.ELECTROLYTE_MASS_BALANCE,
            kpiType: DATASET_VALUE_TYPES.ELECTROLYTE_MASS_BALANCE,
        },
        {
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: false,
            name: DATASET_VALUE_TYPES.ORGANIC_MASS_BALANCE,
            kpiType: DATASET_VALUE_TYPES.ORGANIC_MASS_BALANCE,
        },
        {
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: false,
            name: DATASET_VALUE_TYPES.PLS_MASS_BALANCE,
            kpiType: DATASET_VALUE_TYPES.PLS_MASS_BALANCE,
        },
        {
            ...baseCircuitKpi,
            name: DATASET_VALUE_TYPES.TSS,
            kpiType: DATASET_VALUE_TYPES.TSS,
        },
    ];

    if (circuit.get('oximeId') !== null) {
        kpis.push({
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: false,
            name: DATASET_VALUE_TYPES.OXIME_RATIO,
            kpiType: DATASET_VALUE_TYPES.OXIME_RATIO,
        });
        kpis.push({
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: false,
            name: DATASET_VALUE_TYPES.ALDOXIME_PERCENT,
            kpiType: DATASET_VALUE_TYPES.ALDOXIME_PERCENT,
        });
        kpis.push({
            ...baseCircuitKpi,
            isRequired: false,
            isForcedRequire: false,
            name: DATASET_VALUE_TYPES.KETOXIME_PERCENT,
            kpiType: DATASET_VALUE_TYPES.KETOXIME_PERCENT,
        });
    }

    return fromJS(kpis);
};

/**
 * Get all of the possible KPIs for the given stream.
 * @param {ImmutableStream} stream The stream for which we want all the kpis
 * @param {ImmutableCircuit} circuit The circuit
 */
export const getMinorKPIsForStream = (
    stream: ImmutableStream,
    circuit: ImmutableCircuit
): ImmutableList<ImmutableKPISetting> => {
    const streamId = stream.get('id');
    const streamType = stream.get('streamType');
    const streamCircuit = stream.get('streamCircuit');

    const streamJS = stream.toJS();
    const stages = circuit.get('stages').toJS();
    const streams = circuit.get('streams').toJS();

    const fromStageId = stream.get('fromStageId');
    const fromStage = fromStageId !== null ? getStageById(circuit, fromStageId) : null;
    const toStageId = stream.get('toStageId');
    const toStage = toStageId !== null ? getStageById(circuit, toStageId) : null;

    const streamKpiBase = {
        ...KPI_SETTING_PROPERTIES.BASE,
        name: streamType,
        specificityType: KPI_SPECIFICITY_TYPES.STREAM,
        streamId,
        circuitId: circuit.get('id'),
    };

    const kpis = [];

    function addFeedMetalCompositions() {
        Object.keys(METAL_TYPES).forEach((metal) => {
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`${metal}_COMPOSITION`],
                name: `${metal} In`,
            });
        });
    }
    function addBleedMetalCompositions() {
        Object.keys(METAL_TYPES).forEach((metal) => {
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`${metal}_COMPOSITION`],
                name: `${metal} Out`,
            });
        });
    }
    function addWashBleedKpis() {
        addBleedMetalCompositions();
        kpis.push({
            ...streamKpiBase,
            kpiType: STREAM_VALUE_TYPES.CHEMICAL_FE_TRANSFER,
            name: `Chemical Fe Transfer to Wash`,
        });
        kpis.push({
            ...streamKpiBase,
            kpiType: STREAM_VALUE_TYPES.PHYSICAL_FE_TRANSFER,
            name: `Physical Fe Transfer to Wash`,
        });
        kpis.push({
            ...streamKpiBase,
            kpiType: STREAM_VALUE_TYPES.ESTIMATED_ORGANIC_FE,
            name: `Estimated Organic Fe`,
            kpiUnit: `${UNIT_NAMES.MILLIGRAM}/${UNIT_NAMES.LITRE}`,
        });
        kpis.push({
            ...streamKpiBase,
            isRequired: false,
            isForcedRequire: false,
            name: 'Wash AinO Entrainment Average',
            kpiType: STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE,
            kpiUnitType: KPI_TYPE_TO_UNIT_TYPE[STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE],
            kpiUnit: UNIT_NAMES.PARTS_PER_MILLION,
        });
        Object.keys(METAL_TYPES).forEach((metal) => {
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`TOTAL_${metal}_TRANSFER`],
                name: `${metal} Washed`,
            });
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`AINO_ENTRAINMENT_${metal}`],
                name: `Wash ${metal} AinO Entrainment`,
                kpiUnitType: KPI_TYPE_TO_UNIT_TYPE[STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE],
                kpiUnit: UNIT_NAMES.PARTS_PER_MILLION,
            });
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`TOTAL_${metal}_WASHED_PERCENT`],
                name: `% Total ${metal} Washed`,
            });
        });
    }
    function addElectrolyteBleedMetalCompositions() {
        Object.keys(METAL_TYPES).forEach((metal) => {
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`${metal}_COMPOSITION`],
                name: `${metal} Out`,
            });
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`TOTAL_${metal}_TRANSFER`],
                name: `${metal} Stripped`,
            });
            kpis.push({
                ...streamKpiBase,
                kpiType: STREAM_VALUE_TYPES[`AINO_ENTRAINMENT_${metal}`],
                name: `${metal} AinO Entrainment`,
                kpiUnitType: KPI_TYPE_TO_UNIT_TYPE[STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE],
                kpiUnit: UNIT_NAMES.PARTS_PER_MILLION,
            });
        });
    }
    switch (streamType) {
        case STREAM_TYPES.BLEND:
        case STREAM_TYPES.FEED: {
            addFeedMetalCompositions();
            kpis.push({
                ...streamKpiBase,
                isRequired: true,
                isUndeletable: true,
                isDefault: true,
                isForcedRequire: streamType === STREAM_TYPES.FEED, // If the stream is a feed, then force require. Blends are optional.
                kpiType: STREAM_VALUE_TYPES.FLOWRATE,
            });
            if (streamCircuit === STREAM_CIRCUITS.AQUEOUS) {
                kpis.push({
                    ...streamKpiBase,
                    isDefault: true,
                    isRequired: false,
                    isUndeletable: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.PLS,
                });
                kpis.push({
                    ...streamKpiBase,
                    isDefault: true,
                    isRequired: false,
                    isUndeletable: false, // technically either this or the Fixed PH is required.
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.PH,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isUndeletable: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.FIXED_PH,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false, // if this KPI is added then we assume it will be required
                    isUndeletable: false,
                    isForcedRequire: false,
                    name: 'Pre-adjustment PLS Cu',
                    kpiType: STREAM_VALUE_TYPES.PRE_PLS_CU,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false, // if this KPI is added then we assume it will be required
                    isUndeletable: false,
                    isForcedRequire: false,
                    name: 'Pre-adjustment PLS pH',
                    kpiType: STREAM_VALUE_TYPES.PRE_PH,
                });
            } else if (streamCircuit === STREAM_CIRCUITS.ELECTROLYTE) {
                kpis.push({
                    ...streamKpiBase,
                    isDefault: true,
                    isRequired: true,
                    isForcedRequire: true,
                    isUndeletable: true,
                    kpiType: STREAM_VALUE_TYPES.SPENT,
                });
                kpis.push({
                    ...streamKpiBase,
                    isDefault: true,
                    isRequired: true,
                    isForcedRequire: true,
                    isUndeletable: true,
                    kpiType: STREAM_VALUE_TYPES.ACID,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.WATER_MAKEUP,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    streamId,
                    kpiType: STREAM_VALUE_TYPES.ACID_MAKEUP,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.WATER_MAKEUP_TREND,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    streamId,
                    kpiType: STREAM_VALUE_TYPES.ACID_MAKEUP_TREND,
                });
                kpis.push({
                    ...streamKpiBase,
                    kpiType: STREAM_VALUE_TYPES.LEAN_FE_MN_RATIO,
                    name: `Lean Fe/Mn Ratio`,
                });
            } else if (streamCircuit === STREAM_CIRCUITS.ORGANIC) {
                throw new Error('SolvExtract circuits should not have organic feeds/blends.');
            } else {
                throw new Error(`Unknown stream circuit type. ${streamCircuit}`);
            }
            break;
        }
        case STREAM_TYPES.BLEED: {
            if (streamCircuit === STREAM_CIRCUITS.AQUEOUS) {
                const isFlowrateRequired = isStreamInterstageBleed(streamJS, streams);
                kpis.push({
                    ...streamKpiBase,
                    isDefault: isFlowrateRequired,
                    isRequired: isFlowrateRequired,
                    isForcedRequire: isFlowrateRequired,
                    isUndeletable: isFlowrateRequired,
                    streamId,
                    kpiType: STREAM_VALUE_TYPES.FLOWRATE,
                });
                kpis.push({
                    ...streamKpiBase,
                    isDefault: true,
                    isRequired: true,
                    isForcedRequire: true,
                    isUndeletable: true,
                    kpiType: STREAM_VALUE_TYPES.RAFFINATE,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.RECOVERY_PERCENT,
                });
            } else if (streamCircuit === STREAM_CIRCUITS.ELECTROLYTE) {
                kpis.push({
                    ...streamKpiBase,
                    isDefault: true,
                    isRequired: true,
                    isForcedRequire: true,
                    isUndeletable: true,
                    kpiType: STREAM_VALUE_TYPES.ADVANCE,
                });
                addElectrolyteBleedMetalCompositions();
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    name: STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE,
                    kpiType: STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE,
                    kpiUnitType: KPI_TYPE_TO_UNIT_TYPE[STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE],
                    kpiUnit: UNIT_NAMES.PARTS_PER_MILLION,
                });
                kpis.push({
                    ...streamKpiBase,
                    kpiType: STREAM_VALUE_TYPES.CHEMICAL_FE_TRANSFER,
                    name: `Chemical Fe Transfer to Strip`,
                });
                kpis.push({
                    ...streamKpiBase,
                    kpiType: STREAM_VALUE_TYPES.PHYSICAL_FE_TRANSFER,
                    name: `Physical Fe Transfer to Strip`,
                });
                kpis.push({
                    ...streamKpiBase,
                    kpiType: STREAM_VALUE_TYPES.STRIPPED_FE_MN_RATIO,
                    name: `Stripped Fe/Mn Ratio`,
                });
            } else if (streamCircuit === STREAM_CIRCUITS.ORGANIC) {
                throw new Error('SolvExtract circuits should not have organic bleeds.');
            } else {
                throw new Error(`Unknown stream circuit type. ${streamCircuit}`);
            }
            break;
        }
        case STREAM_TYPES.CONTINUE: {
            if (!fromStage || !toStage) {
                throw new Error(
                    '[KpiHelpers] An organic continue stream is missing either the from stage or the to stage.'
                );
            }

            if (fromStage.get('stageType') === STAGE_TYPES.WASHER) {
                // We don't need to add conditions for other streams because
                // the other streams will be of type !== CONTINUE and
                // therefore these Wash Bleed KPIs will not be added twice
                addWashBleedKpis();
            }
            if (toStage.get('stageType') === STAGE_TYPES.WASHER) {
                addFeedMetalCompositions();
            }

            /**
             * === Extract to Strip KPIs:
             */

            if (
                fromStage.get('stageType') === STAGE_TYPES.EXTRACT &&
                [STAGE_TYPES.STRIP, STAGE_TYPES.ORGANIC_TANK, STAGE_TYPES.WASHER].includes(
                    toStage.get('stageType')
                )
            ) {
                // An organic continue stream from extract, to either the terminal LO tank, the EtS washer or to strip
                // could require a pre-composition when there are extract bypassing streams present.
                if (hasExtractBypass(streams, stages)) {
                    // if there is at least 1 extract bypass feed, then there is a pre-composition
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: false,
                        isForcedRequire: false,
                        kpiType: STREAM_VALUE_TYPES.PRE_COMPOSITION,
                    });
                }
            }
            if (
                toStage.get('stageType') === STAGE_TYPES.STRIP &&
                toStage.get('location') === 1 &&
                fromStage.get('location') === 1
            ) {
                // Loaded organic stream
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.LOADED_PERCENT,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.COMPOSITION,
                });
                if (fromStage.get('stageType') !== STAGE_TYPES.ORGANIC_TANK) {
                    // Flowrate must be present if it doesn't come from the terminal LO tank
                    // The terminal LO tank takes care of the flowrate KPI
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: true,
                        isForcedRequire: true,
                        isUndeletable: true,
                        name: `${streamCircuit}_${STREAM_VALUE_TYPES.FLOWRATE}`,
                        kpiType: STREAM_VALUE_TYPES.FLOWRATE,
                    });
                }
                // are there any bypasses?
                // Going to the first stripper from the terminal left stages
                if (hasStripBypass(streams, stages)) {
                    // if there is at least 1 strip bypass feed, then there is a flowrate percent.
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: false,
                        isForcedRequire: true, // this will be computed.
                        kpiType: STREAM_VALUE_TYPES.FLOWRATE_PERCENT,
                    });
                }
            }

            /**
             * === Strip to Extract KPIs:
             */
            const stripStages = getStripperStages(circuit);
            const lastStripStage = stripStages.last();
            const extractStages = getExtractorStages(circuit);
            const lastExtractStage = extractStages.last();

            // From the last strip to
            // the StE washer, or to the last extractor
            if (
                fromStage.get('stageType') === STAGE_TYPES.STRIP &&
                fromStage.get('location') === lastStripStage.get('location') &&
                ((toStage.get('stageType') === STAGE_TYPES.WASHER &&
                    toStage.get('location') === 2) ||
                    (toStage.get('stageType') === STAGE_TYPES.EXTRACT &&
                        toStage.get('location') === lastExtractStage.get('location')))
            ) {
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.STRIPPED_PERCENT,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.NET_TRANSFER,
                });
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.BARREN_ORGANIC_TANK_LEVEL,
                    name: STREAM_VALUE_TYPES.BARREN_ORGANIC_TANK_LEVEL,
                });

                if (toStage.get('stageType') === STAGE_TYPES.EXTRACT) {
                    // We are going directly to extract, no StE washer
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: false,
                        isForcedRequire: false,
                        kpiType: STREAM_VALUE_TYPES.COMPOSITION,
                    });

                    // are there any bypasses?
                    if (hasExtractBypass(streams, stages) || hasOrganicBlend(streams)) {
                        // if there is at least 1 extract bypass feed or an organic blend, then there is a flowrate percent.
                        kpis.push({
                            ...streamKpiBase,
                            isRequired: false,
                            isForcedRequire: true, // this value will be computed.
                            kpiType: STREAM_VALUE_TYPES.FLOWRATE_PERCENT,
                        });
                    }
                }

                if (hasStripBypass(streams, stages)) {
                    // if there is at least 1 strip bypass feed, then there is a pre-composition
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: false,
                        isForcedRequire: false,
                        kpiType: STREAM_VALUE_TYPES.PRE_COMPOSITION,
                    });
                }
            }

            /**
             * === Organic to and from the same section
             */
            if (toStage.get('stageType') === fromStage.get('stageType')) {
                const advancedStreams = getAdvancedStreamForContinue(streamJS, stages, streams);
                if (!advancedStreams) {
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: false,
                        isForcedRequire: false,
                        kpiType: STREAM_VALUE_TYPES.COMPOSITION,
                    });
                } else {
                    // advanced stream in organic continue streams can only mean bleed/blend.
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: false,
                        isForcedRequire: false,
                        kpiType: STREAM_VALUE_TYPES.PRE_COMPOSITION,
                    });
                    kpis.push({
                        ...streamKpiBase,
                        isRequired: false,
                        isForcedRequire: false,
                        kpiType: STREAM_VALUE_TYPES.POST_COMPOSITION,
                    });
                }
            }

            break;
        }
        case STREAM_TYPES.SKIP: {
            kpis.push({
                ...streamKpiBase,
                isRequired: false,
                isForcedRequire: false,
                kpiType: STREAM_VALUE_TYPES.COMPOSITION,
            });
            break;
        }
        case STREAM_TYPES.BYPASS_FEED:
        case STREAM_TYPES.BYPASS_BLEND: {
            kpis.push({
                ...streamKpiBase,
                isDefault: true,
                isRequired: true,
                isForcedRequire: true,
                isUndeletable: true,
                kpiType: STREAM_VALUE_TYPES.FLOWRATE_PERCENT,
            });
            break;
        }
        case STREAM_TYPES.BYPASS_BLEED: {
            if (
                toStage &&
                toStage.get('stageType') === STAGE_TYPES.ORGANIC_TANK &&
                fromStage &&
                fromStage.get('stageType') === STAGE_TYPES.ORGANIC_TANK
            ) {
                kpis.push({
                    ...streamKpiBase,
                    isDefault: true,
                    isRequired: true,
                    isUndeletable: true,
                    isForcedRequire: true,
                    kpiType: STREAM_VALUE_TYPES.FLOWRATE_PERCENT,
                });
            } else {
                kpis.push({
                    ...streamKpiBase,
                    isRequired: false,
                    isForcedRequire: false,
                    kpiType: STREAM_VALUE_TYPES.COMPOSITION,
                });
            }
            break;
        }
        case STREAM_TYPES.LEAN_FEED_BLEED: {
            kpis.push({
                ...streamKpiBase,
                isRequired: false,
                isForcedRequire: false,
                kpiType: STREAM_VALUE_TYPES.FLOWRATE,
                name: `${STREAM_TYPES.LEAN_FEED_BLEED}_${STREAM_VALUE_TYPES.FLOWRATE}`,
            });
            Object.keys(METAL_TYPES).forEach((metal) => {
                kpis.push({
                    ...streamKpiBase,
                    kpiType: STREAM_VALUE_TYPES[`LEAN_${metal}_BLED`],
                    name: `${metal} Bled`,
                });
                kpis.push({
                    ...streamKpiBase,
                    kpiType: STREAM_VALUE_TYPES[`REQUIRED_LEAN_${metal}_BLEED`],
                    name: `Required ${metal} Bleed`,
                });
            });
            break;
        }
        default:
            throw new Error(`Unknown stream type: ${streamType}`);
    }

    return fromJS(kpis);
};

/**
 * Returns the unit value from an option.
 * @param {KpiUnitName | Array<KpiUnitName>} option If this is an array then this is multiple units (ex: m/h)
 */
export const getKPIUnitValueFromOptions = (option: KpiUnitName | Array<KpiUnitName>): string => {
    let value = option;
    if (Array.isArray(option)) {
        value = option.join(COMPLEX_KPI_UNIT_DELIMITER);
    }
    return value;
};

/**
 * Returns the default KPI unit for the unit type.
 * @param {KpiUnitType} unitType The unit type of a kpi
 * @param {UnitsConstant} circuitUnits The unit locality of the circuit/plant
 */
export const getDefaultKPIUnitValueForUnitType = (
    unitType: KpiUnitType,
    circuitUnits: UnitsConstant
): string => {
    if (!unitType) {
        return null;
    }

    // If this unitType has a locality, then use the default specified,
    const unitsOptionForLocalities = LOCALITY_DEFAULT_UNITS_FOR_UNIT_TYPE[unitType];
    if (unitsOptionForLocalities) {
        if (!circuitUnits) {
            // This is most likely caused by a mis-configuration of the LOCALITY_DEFAULT_UNITS_FOR_UNIT_TYPE
            throw new Error('Trying to get default KPI unit but the circuit unit was null...');
        }
        return getKPIUnitValueFromOptions(unitsOptionForLocalities[circuitUnits]);
    }

    // if it doesn't then return the first unit combination.
    return getKPIUnitValueFromOptions(UNITS_FOR_UNIT_TYPE[unitType][0]);
};

/**
 * Get the Unit type for the KPI type
 * @param {} kpiType
 * @returns Percent, Mass, Current, Frequency, Distance, Temp, Area, or Time or a complex unit.
 */
export const getKPIUnitType = (kpiType: AllKpiTypes): KpiUnitType => {
    if (!Object.keys(KPI_TYPE_TO_UNIT_TYPE).includes(kpiType)) {
        return null;
    }

    return KPI_TYPE_TO_UNIT_TYPE[kpiType];
};

/**
 * Returns the translated unit
 * @param {string} unitCode the code of the unit.
 * @param {*} intl the internationalization object
 */
export const getKPIUnitLabelFromValue = (unitCode: string, intl: IntlType): string | null => {
    if (!unitCode) {
        return null;
    }
    const option = unitCode.includes(COMPLEX_KPI_UNIT_DELIMITER)
        ? unitCode.split(COMPLEX_KPI_UNIT_DELIMITER)
        : [unitCode];
    return option
        .map((unitName: KpiUnitName) =>
            intl.formatMessage({
                id: `constants.KpiUnits.${unitName}`,
            })
        )
        .join(
            intl.formatMessage({
                id: 'constants.ComplexKpiUnitSeparator',
            })
        );
};

export const getKPISettingUnit = (kpiSetting: ImmutableKPISetting, intl: IntlType): string => {
    return getKPIUnitLabelFromValue(kpiSetting.get('kpiUnit'), intl);
};

export const getValueTypeUnit = (
    valueType: AllKpiTypes,
    units: ?UnitsConstant,
    intl: IntlType
): string => {
    const unitType = getKPIUnitType(valueType);
    const unitCode = getDefaultKPIUnitValueForUnitType(unitType, units);
    return getKPIUnitLabelFromValue(unitCode, intl);
};

export const getAllKPIsForCircuit = (
    circuit: ImmutableCircuit
): ImmutableList<ImmutableKPISetting> => {
    const circuitKPIs = getMinorMajorKPIsForCircuit(circuit);
    const streamKPIs = circuit
        .get('streams')
        .reduce(
            (list: ImmutableList<ImmutableKPISetting>, stream: ImmutableStream) =>
                list.concat(getMinorKPIsForStream(stream, circuit)),
            fromJS([])
        );

    return circuitKPIs.concat(streamKPIs).map((kpiSetting: ImmutableKPISetting) => {
        const kpiType = kpiSetting.get('kpiType');
        let updatedKpiSetting = kpiSetting
            .set('context', getKPISettingRelatedName(kpiSetting, circuit, false))
            .set('section', getKPISection(kpiSetting, circuit));

        if (KPI_TYPES_WITH_RECOMMENDATIONS.indexOf(kpiType) !== -1) {
            const recommendableProperties = fromJS(KPI_SETTING_PROPERTIES.RECOMMENDABLE);
            updatedKpiSetting = updatedKpiSetting.merge(recommendableProperties);
        }
        if (KPI_TYPES_WITH_MAIN_TARGET.indexOf(kpiType) !== -1) {
            const mainTargetProperty = fromJS(KPI_SETTING_PROPERTIES.WITH_MAIN_TARGET);
            updatedKpiSetting = updatedKpiSetting.merge(mainTargetProperty);
        }

        const kpiUnitType = getKPIUnitType(kpiType);
        if (!updatedKpiSetting.get('kpiUnitType') || !updatedKpiSetting.get('kpiUnit')) {
            updatedKpiSetting = updatedKpiSetting
                .set('kpiUnitType', kpiUnitType)
                .set(
                    'kpiUnit',
                    getDefaultKPIUnitValueForUnitType(
                        kpiUnitType,
                        circuit.get('circuitUnits'),
                        kpiType
                    )
                );
        }

        return updatedKpiSetting;
    });
};

/**
 * Based on the provided value and the min & max, return the invalid value reason
 */
export const getKPIInvalidValueReason = (
    value: number | null,
    min: number | null,
    max: number | null
): KPIInvalidValueReasonType | null => {
    let invalidReason = null;
    if (value !== null) {
        if (min !== null && max !== null && (value < min || value > max)) {
            invalidReason = KPI_INVALID_VALUE_REASONS.OUTSIDE_MIN_AND_MAX;
        } else if (min !== null && value < min && max === null) {
            invalidReason = KPI_INVALID_VALUE_REASONS.BELOW_MIN;
        } else if (max !== null && value > max && min === null) {
            invalidReason = KPI_INVALID_VALUE_REASONS.ABOVE_MAX;
        }
    }

    return invalidReason;
};

/**
 * Filters out non-recommendable kpis
 */
export const getRecommendableKPIs = (
    circuit: ImmutableCircuit,
    kpiSettings: ImmutableList<ImmutableKPISetting>
): ImmutableList<ImmutableKPISetting> =>
    kpiSettings.filter((kpi: ImmutableKPISetting) => {
        const kpiType = kpi.get('kpiType');

        // Ensure KPI is recommendable
        const isNotRecommendable = KPI_TYPES_WITH_RECOMMENDATIONS.indexOf(kpiType) !== -1;

        // Ensure KPI is not a Stage Efficiency
        const isStageEfficiency = kpiType === STAGE_VALUE_TYPES.STAGE_EFFICIENCY;

        // TODO: MS-750 - If there is a lean feed bleed flowrate present and it's tied to a Lean Feed Bleed stream, exclude it
        const hasLeanFeedBleedStream =
            kpiType === STREAM_VALUE_TYPES.FLOWRATE &&
            getStreamById(circuit, kpi.get('streamId')).get('streamType') ===
            STREAM_TYPES.LEAN_FEED_BLEED;

        /**
         * When there is a terminal LO tank, it is the one that holds the organic flowrate.
         * This is a mess.
         */
        let isTankFlowrateTheOrganicFlowrate = false;
        if (kpiType === STAGE_VALUE_TYPES.TANK_FLOWRATE) {
            const tank = getStageById(circuit, kpi.get('stageId'));
            isTankFlowrateTheOrganicFlowrate = isTankFlowrateComputeUsing(circuit, tank);
        }

        return (
            isNotRecommendable ||
            isStageEfficiency ||
            hasLeanFeedBleedStream ||
            isTankFlowrateTheOrganicFlowrate
        );
    });
