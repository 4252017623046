// @flow strict

import { decamelize, decamelizeKeys } from 'humps';

import request from 'services/request';

import type { SearchCriteria } from 'types';
import type { Reagent, SetReagentPlantAccessStructure } from 'services/Reagent/types';

/**
 * Create reagent
 *
 * @param {Reagent} reagent
 */
export const create = (reagent: Reagent) =>
    request('reagents', {
        data: reagent,
        method: 'POST',
    });

/**
 * Fetches all reagents
 */
export const index = () => request('reagents', { method: 'GET' });

/**
 * Update reagent
 *
 * @param {number} id
 * @param {Reagent} reagent
 */
export const update = (id: number, reagent: Reagent) =>
    request(`reagents/${id}`, {
        data: reagent,
        method: 'PUT',
    });

/**
 * Destroy reagent
 *
 * @param {number} id
 */
export const destroy = (id: number) => request(`reagents/${id}`, { method: 'DELETE' });

/**
 * Query all reagents
 */
export const query = (
    searchCriteria: SearchCriteria = {},
    page: number = 1,
    perPage: number = 10
) => {
    const options = decamelizeKeys({
        page,
        perPage,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder: searchCriteria.sortOrder ? searchCriteria.sortOrder.toLowerCase() : '',
    });
    return request('reagents/query', { method: 'GET' }, { ...options });
};

/**
 * Set the list of plants that can access a particular reagent.
 *
 * @param {Reagent} reagent
 */
export const _setReagentPlantAccess = (id: number, data: SetReagentPlantAccessStructure) =>
    request(`reagents/${id}/plants`, {
        data,
        method: 'POST',
    });
