// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';

// Components
import {
    ButtonWrapper,
    PrimaryButton,
    ToolTip,
    DotMenu,
    TrendArrowIcon,
    LegacyTheme,
} from 'components/_ReactUI_V1';
import CardList from 'components/CardList';
import KPIValue from 'components/KPIValue';
import KPICard from 'components/KPICard';

// Styles
import {
    PlantCardWrapper,
    PlantCardHeader,
    PlantCardHeaderTitle,
    PlantCardHeaderControls,
    PlantCardBody,
    PlantKPIsWrapper,
    PlantKPIsNA,
    CircuitsCardsWrapper,
    RowExtraWrapper,
    MenuToolTip,
    MenuToolTipContent,
    ToolTipButton,
    BtnIconWrapper,
} from './styles';

// Constants
import {
    DATASET_VALUE_TYPES,
    NAVIGATION_ROUTES,
    NO_RESULT_STRING,
    PLANT_VALUE_TYPES,
} from 'utils/constants';
import { KPIGraphColorSchemes } from 'styles/colors';

// Helpers
import { isSysAdmin, getUsersLanguage } from 'utils/authentication';
import { SolvExtractNavigation } from 'utils/SolvExtractNavigation';

// Types
import type { IntlType, HistoryType } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableKPISetting, ImmutableKPICard } from 'services/KPISetting/types';

type Props = {
    intl: IntlType,
    plant: ImmutablePlant,
    user: Immutableuser,
    history: HistoryType,
};

class PlantCard extends React.Component<Props> {
    getPlantId = () => this.props.plant.get('id');

    getTranslation = (key: string) =>
        this.props.intl.formatMessage({
            id: `components.SolvExtractDashboard.${key}`,
        });

    getTableHeader = () =>
        [
            { id: 'name', label: this.getTranslation('Header.name'), sortable: false },
            { id: 'cuRecovery', label: this.getTranslation('Header.cuRecovery'), sortable: false },
            { id: 'cuTransfer', label: this.getTranslation('Header.cuTransfer'), sortable: false },
            isSysAdmin(this.props.user) && { id: 'extra', label: '', sortable: false },
        ]
            .filter(Boolean) // Filters out null/false values
            .map((header) => ({
                ...header,
                styles: {
                    backgroundColor: LegacyTheme.white,
                    color: LegacyTheme.black,
                    fontSize: '13px',
                    height: 'auto',
                },
            }));

    getKPIValueByType = (circuit: ImmutableCircuit, kpiType: string) => {
        const majorKpiTrendData = circuit.get('majorKpiTrendData');
        if (!majorKpiTrendData) {
            throw new Error(
                'Circuit must have KPISetting to be a SolvExtract Circuit, something is wrong'
            );
        }

        const kpi = majorKpiTrendData.find(
            (k: ImmutableKPISetting) => k.getIn(['kpiSetting', 'kpiType']) === kpiType
        );

        if (!kpi) {
            return this.props.intl.formatMessage({
                id: 'components.TrendsPageDashboard.valueIsNA',
            });
        }

        return (
            <KPIValue
                kpiCard={kpi}
                noValueText={this.props.intl.formatMessage({
                    id: 'components.TrendsPageDashboard.valueIsNA',
                })}
                inCard
            />
        );
    };

    getKPISettingForKPICard = (kpi: ImmutableKPICard): ImmutableKPISetting =>
        this.props.plant
            .get('kpiSettings')
            .find(
                (kpiSetting: ImmutableKPISetting) =>
                    kpiSetting.get('id') === kpi.get('kpiSettingId')
            );

    handleOnClickCircuitTrends = (circuitId: number) => () =>
        this.props.history.push(
            SolvExtractNavigation.getCircuitDashboardUrl(this.getPlantId(), circuitId)
        );

    handleOnClickCircuitArchive = (circuitId: number) => () =>
        this.props.history.push(
            `${NAVIGATION_ROUTES.CIRCUIT}${circuitId}${NAVIGATION_ROUTES.ARCHIVE}`
        );

    handleOnClickCircuitElevate = (circuitId: number) => () =>
        this.props.history.push(
            SolvExtractNavigation.getPlantEditorUrl(this.getPlantId(), circuitId)
        );

    handleOnClickPlantTrends = (plantId: number) => () =>
        this.props.history.push(`${NAVIGATION_ROUTES.PLANT}${plantId}${NAVIGATION_ROUTES.TRENDS}`);

    handleOnClickPlantArchive = (plantId: number) => () =>
        this.props.history.push(`${NAVIGATION_ROUTES.PLANT}${plantId}${NAVIGATION_ROUTES.ARCHIVE}`);

    getRowExtra = (circuit: ImmutableCircuit) => (
        <RowExtraWrapper>
            <PrimaryButton
                text={this.getTranslation('trends')}
                icon={
                    <BtnIconWrapper>
                        <TrendArrowIcon />
                    </BtnIconWrapper>
                }
                onClick={this.handleOnClickCircuitTrends(circuit.get('id'))}
            />

            <MenuToolTip>
                <ToolTip
                    content={
                        <MenuToolTipContent>
                            <ToolTipButton
                                onClick={this.handleOnClickCircuitArchive(circuit.get('id'))}
                            >
                                {this.getTranslation('archive')}
                            </ToolTipButton>
                            {isSysAdmin(this.props.user) && (
                                <ToolTipButton
                                    onClick={this.handleOnClickCircuitElevate(circuit.get('id'))}
                                >
                                    {this.getTranslation('Menu.edit')}
                                </ToolTipButton>
                            )}
                        </MenuToolTipContent>
                    }
                    position="bottom"
                    triggerType="click"
                    trigger={<DotMenu />}
                    interactive
                />
            </MenuToolTip>
        </RowExtraWrapper>
    );

    getTableRows = () => {
        return this.props.plant
            .get('circuits')
            .map((circuit: ImmutableCircuit) => ({
                id: circuit.get('id'),
                name: circuit.get('name'),
                plantName: circuit.getIn(['plant', 'name']) || NO_RESULT_STRING,
                clientName: circuit.getIn(['plant', 'client', 'name']) || NO_RESULT_STRING,
                cuRecovery: this.getKPIValueByType(circuit, DATASET_VALUE_TYPES.OVERALL_RECOVERY),
                cuTransfer: this.getKPIValueByType(circuit, DATASET_VALUE_TYPES.CU_TRANSFERRED),
                extra: this.getRowExtra(circuit),
                onClick: this.handleOnClickCircuitTrends(circuit.get('id')),
            }))
            .toArray();
    };

    getMajorKPIs = () => {
        const kpis = this.props.plant.get('kpis');

        if (!kpis || kpis.size === 0) {
            return null;
        }

        return kpis;
    };

    render() {
        const usersUiLanguage = getUsersLanguage(this.props.user);
        const majorKPIs = this.getMajorKPIs();

        return (
            <PlantCardWrapper key={this.props.plant.get('id')}>
                <PlantCardHeader>
                    <PlantCardHeaderTitle>
                        {this.props.plant.get('name')} -{' '}
                        {this.props.plant.getIn(['client', 'name'])}
                    </PlantCardHeaderTitle>

                    <PlantCardHeaderControls>
                        <PrimaryButton
                            text={this.getTranslation('trends')}
                            icon={
                                <BtnIconWrapper>
                                    <TrendArrowIcon />
                                </BtnIconWrapper>
                            }
                            onClick={this.handleOnClickPlantTrends(this.props.plant.get('id'))}
                        />
                        <MenuToolTip>
                            <ToolTip
                                content={
                                    <MenuToolTipContent>
                                        <ToolTipButton
                                            onClick={this.handleOnClickPlantArchive(
                                                this.props.plant.get('id')
                                            )}
                                        >
                                            {this.getTranslation('archive')}
                                        </ToolTipButton>
                                    </MenuToolTipContent>
                                }
                                position="bottom"
                                triggerType="click"
                                trigger={<DotMenu />}
                                interactive
                            />
                        </MenuToolTip>
                    </PlantCardHeaderControls>
                </PlantCardHeader>

                <PlantCardBody>
                    <PlantKPIsWrapper>
                        {majorKPIs ? (
                            majorKPIs.map((kpi, index: number) => (
                                <KPICard
                                    key={kpi.get('kpiSettingId')}
                                    kpi={kpi}
                                    kpiSetting={this.getKPISettingForKPICard(kpi)}
                                    active={true}
                                    timezone={this.props.plant.get('timezone')}
                                    userLanguage={usersUiLanguage}
                                    colorScheme={
                                        KPIGraphColorSchemes[index % KPIGraphColorSchemes.length]
                                    }
                                    showMaxCuTransfer={true}
                                    isMajorKPI
                                    plantCardStyle
                                />
                            ))
                        ) : (
                            <PlantKPIsNA>{this.getTranslation('valueIsNA')}</PlantKPIsNA>
                        )}
                    </PlantKPIsWrapper>

                    <CircuitsCardsWrapper>
                        <CardList
                            header={this.getTableHeader()}
                            rows={this.getTableRows()}
                            titleId="name"
                            controlsId="extra"
                            loading={false}
                            cardMaxWidth="332px"
                            cardMargin="10px 8px"
                            flexDirection="row"
                            solvExtract
                        />
                    </CircuitsCardsWrapper>
                </PlantCardBody>
            </PlantCardWrapper>
        );
    }
}

export default withRouter(injectIntl(PlantCard));
