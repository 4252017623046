// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import {
    ButtonWrapper,
    Column,
    DotMenu,
    InputField,
    InputSelect,
    Pagination,
    PrimaryButton,
    Row,
    SecondaryButton,
    Table,
    ToolTip,
    Toggle,
} from 'components/_ReactUI_V1';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';
import PreventDataLossModal from 'components/Modals/PreventDataLossModal';
import ReagentPlantAssignmentModal from 'components/Modals/ReagentPlantAssignmentModal';

// Constants
import { NUMBER_INPUT_PLACEHOLDER } from 'utils/constants';

// Styles
import { TableContainer, ToolTipContent, Wrapper } from './styles';
import { BlankButton, Title } from 'styles/common';

// Types
import type {
    ErrorType,
    ImmutableList,
    InputEvent,
    IntlType,
    ReactSelectObject,
    SearchCriteria,
} from 'types';
import type { ImmutableReagent, Reagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';

type Props = {
    errors: ErrorType,
    oximes: ImmutableList<ImmutableOxime>,
    intl: IntlType,
    handleDeleteReagent: (number) => void,
    handleCreateNewReagent: (ImmutableReagent) => void,
    handleUpdateReagent: (number, Reagent) => void,
    onHandlePaginationPageSelection: (number, number) => void,
    onHandleSortBy: (SearchCriteria) => void,
    reagents: ImmutableList<ImmutableReagent>,
    reagentsAreQuerying: boolean,
    reagentIsDeleting: boolean,
    reagentIsUpdating: boolean,
    lastPage: number,
    pagination: {
        page: number,
    },
    searchCriteria: SearchCriteria,
};

type State = {
    activeRow: ?ImmutableReagent,
    isModifiedRow: boolean,
    enableAddRow: boolean,
    disabledInputs: boolean,
    reagentIdToDelete: ?number,
    showDataPreventionModal: boolean,
    dataPreventionHandler: ?Function,
    reagentForPlantAssignmentModal: ?ImmutableReagent,
};

class ReagentManager extends React.Component<Props, State> {
    state = {
        activeRow: null,
        isModifiedRow: false,
        enableAddRow: true,
        disabledInputs: false,
        reagentIdToDelete: null,
        showDataPreventionModal: false,
        dataPreventionHandler: null,
        reagentForPlantAssignmentModal: null,
    };

    /**
     * On componentDidUpdate check if props.reagents have changed, if so reset UI via state
     * If props.errors have changed, re-enable inputs to allow user to continue
     */
    componentDidUpdate(prevProps: Props) {
        const { reagents, errors } = this.props;
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (prevProps.reagents !== reagents) {
            this.setState({
                activeRow: null,
                isModifiedRow: false,
                disabledInputs: false,
                enableAddRow: true,
                reagentIdToDelete: null,
                showDataPreventionModal: false,
                dataPreventionHandler: null,
            });
        } else if (prevProps.errors && !prevProps.errors.equals(errors)) {
            this.setState({
                disabledInputs: false,
            });
        }
    }

    handleOnDelete = (id: number) => () => this.props.handleDeleteReagent(id);

    /**
     * Update provided field by key of active row within state
     */
    handleActiveRowChange = (key: string) => (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => ({
            activeRow: prevState.activeRow && prevState.activeRow.set(key, value),
            isModifiedRow: Boolean(prevState.activeRow),
            disabledInputs: false,
        }));
    };

    /**
     * On row cancel (within row edit/create) update local state
     * If id is not provided (assume unsaved row) and delete first item from list
     * Else (assume saved row), nullify state.activeRow
     * Set enableAddRow to true to re-allow [Create New]
     */
    handleCancelActiveRow = () => {
        this.setState({
            enableAddRow: true,
            activeRow: null,
            isModifiedRow: false,
        });
    };

    /**
     * On row creation, insert "empty" object to list of entities & set as activeRow within state
     * Set enableAddRow to false to disable [Create New] button
     */
    handleCreateNewReagentConfirmed = () => {
        const emptyRow = fromJS({
            id: null,
            name: null,
        });

        this.setState({
            showDataPreventionModal: false,
            dataPreventionHandler: null,
            activeRow: emptyRow,
            isModifiedRow: false,
            enableAddRow: false,
        });
    };

    handleCreateNewReagent = () => {
        if (this.state.activeRow && this.state.isModifiedRow) {
            this.setState({
                showDataPreventionModal: true,
                dataPreventionHandler: this.handleCreateNewReagentConfirmed,
            });
        } else {
            this.handleCreateNewReagentConfirmed();
        }
    };

    /**
     * On save, disable inputs for further editing
     * If state.activeRow has an id (assume update) and fire props.handleUpdateReagent()
     * Else (assume create) and fire props.handleCreateNewReagent()
     */
    handleSaveActiveRow = () => {
        this.setState(
            {
                disabledInputs: true, // Disable inputs to avoid unexpected duplicate requests
            },
            () => {
                const { activeRow } = this.state;
                if (activeRow && activeRow.get('id')) {
                    this.props.handleUpdateReagent(
                        activeRow.get('id'),
                        activeRow.delete('oxime').toJS()
                    );
                } else {
                    this.props.handleCreateNewReagent(this.state.activeRow);
                }
            }
        );
    };

    /**
     * Handle selection via InputSelect: ReactSelect for Reagent's Oxime relationship
     */
    handleSelectOxime = (selectedOximeObject: ReactSelectObject) => {
        const selectedOxime = this.props.oximes.find(
            (oxime: ImmutableOxime) => oxime.get('id') === selectedOximeObject.value
        );

        this.setState((prevState: State) => ({
            activeRow:
                prevState.activeRow &&
                prevState.activeRow.set('oximeId', selectedOxime.get('id')).set(
                    'oxime',
                    fromJS({
                        id: selectedOxime.get('id'),
                        name: selectedOxime.get('name'),
                    })
                ),
            isModifiedRow: true,
            disabledInputs: false,
        }));
    };

    /**
     * On row edit, set provided entity as activeRow
     * If reagent is not provided nullify (reset UI) activeRow
     */
    handleSetActiveRowConfirmed = (reagent?: ImmutableReagent) => () => {
        this.setState({
            activeRow: reagent || null,
            isModifiedRow: false,
            showDataPreventionModal: false,
            dataPreventionHandler: null,
            enableAddRow: true,
        });
    };

    handleSetActiveRow = (reagent?: ImmutableReagent) => () => {
        if (reagent && this.state.activeRow && this.state.isModifiedRow) {
            this.setState({
                showDataPreventionModal: true,
                dataPreventionHandler: this.handleSetActiveRowConfirmed(reagent),
            });
        } else {
            this.handleSetActiveRowConfirmed(reagent)();
        }
    };

    /**
     * On Click, set reagentIdToDelete in state for this.renderConditionalDeleteModal()
     */
    handleSetReagentIdToDelete = (reagentId?: number) => () => {
        this.setState({
            reagentIdToDelete: reagentId || null,
        });
    };

    /**
     * When the user clicks on a reagent tooltip, and select the Update Assigned Plants button
     */
    handleShowReagentPlantAssignmentModal = (reagent: ImmutableReagent) => () => {
        this.setState({
            reagentForPlantAssignmentModal: reagent,
        });
    };

    handleCancelDataLossModal = () =>
        this.setState({
            showDataPreventionModal: false,
            dataPreventionHandler: null,
        });

    /**
     * Will close the reagent plant assignment modal.
     */
    handleCloseReagentPlantAssignmentModal = () =>
        this.setState({
            reagentForPlantAssignmentModal: null,
        });

    provideTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.ReagentManager.table.header.name',
            }),
            id: 'name',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ReagentManager.table.header.MDRData',
            }),
            id: 'oximeName',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ReagentManager.table.header.strength',
            }),
            id: 'strength',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ReagentManager.table.header.oxmod',
            }),
            id: 'oximeRatio',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ReagentManager.table.header.specificGravity',
            }),
            id: 'specificGravity',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ReagentManager.table.header.uptakeFactor',
            }),
            id: 'uptakeFactor',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ReagentManager.table.header.isCustom',
            }),
            id: 'isCustom',
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    /**
     * Provide interactive row (static with controls)
     */
    provideInteractiveRow = (reagent: ImmutableReagent) =>
        reagent
            .set('oximeName', reagent.getIn(['oxime', 'name']))
            .set('strength', reagent.get('strength'))
            .set('oximeRatio', reagent.get('oximeRatio'))
            .set('specificGravity', reagent.get('specificGravity'))
            .set('uptakeFactor', reagent.get('uptakeFactor'))
            .set('isCustom', <Toggle checked={reagent.get('isCustom') || false} disabled />)
            .set(
                'controls',
                <div style={{ textAlign: 'right' }}>
                    <ToolTip
                        content={
                            <ToolTipContent>
                                <li>
                                    <BlankButton
                                        onClick={this.handleSetActiveRow(reagent)}
                                        title={this.props.intl.formatMessage({
                                            id: 'components.ReagentManager.editReagent',
                                        })}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: 'components.ReagentManager.editReagent',
                                        })}
                                    </BlankButton>
                                </li>
                                <li>{this.renderDeleteButton(reagent)}</li>
                                <li>{this.renderShowReagentPlantAssignmentModalButton(reagent)}</li>
                            </ToolTipContent>
                        }
                        position="bottom"
                        trigger={<DotMenu />}
                        triggerType="click"
                        interactive
                    />
                </div>
            );

    /**
     * Provide editable row
     */
    provideEditableRow = () => {
        const reagent = this.state.activeRow;

        if (!reagent) {
            return null;
        }

        // Create ReactSelectObject from reagent.oxime
        // Or null if none is saved to reagent
        const selectedOximeOption: ?ReactSelectObject = reagent.hasIn(['oxime', 'id'])
            ? {
                  value: reagent.getIn(['oxime', 'id']),
                  label: reagent.getIn(['oxime', 'name']),
              }
            : null;

        const numberInputInlineStyle = { maxWidth: '72px', textAlign: 'center' };

        // Required fields
        const enableSave =
            reagent.get('name') &&
            reagent.get('oxime') &&
            reagent.get('strength') &&
            reagent.get('oximeRatio') &&
            reagent.get('specificGravity') &&
            reagent.get('uptakeFactor');

        return reagent
            .set(
                'name',
                <InputField
                    disabled={this.state.disabledInputs}
                    onChange={this.handleActiveRowChange('name')}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.ReagentManager.placeholder.name',
                    })}
                    value={reagent.get('name') || ''}
                />
            )
            .set(
                'oximeName',
                <div style={{ minWidth: '124px' }}>
                    <InputSelect
                        isDisabled={this.state.disabledInputs}
                        onSelect={this.handleSelectOxime}
                        options={this.props.oximes.map((oxime: ImmutableOxime) => ({
                            value: oxime.get('id'),
                            label: oxime.get('name'),
                        }))}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.ReagentManager.placeholder.oxime',
                        })}
                        selectedOption={selectedOximeOption}
                        controlShouldRenderValue
                    />
                </div>
            )
            .set(
                'strength',
                <InputField
                    disabled={this.state.disabledInputs}
                    onChange={this.handleActiveRowChange('strength')}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    style={numberInputInlineStyle}
                    type="number"
                    value={reagent.get('strength') || ''}
                />
            )
            .set(
                'oximeRatio',
                <InputField
                    disabled={this.state.disabledInputs}
                    onChange={this.handleActiveRowChange('oximeRatio')}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    style={numberInputInlineStyle}
                    type="number"
                    value={reagent.get('oximeRatio') || ''}
                />
            )
            .set(
                'specificGravity',
                <InputField
                    disabled={this.state.disabledInputs}
                    onChange={this.handleActiveRowChange('specificGravity')}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    style={numberInputInlineStyle}
                    type="number"
                    value={reagent.get('specificGravity') || ''}
                />
            )
            .set(
                'uptakeFactor',
                <InputField
                    disabled={this.state.disabledInputs}
                    onChange={this.handleActiveRowChange('uptakeFactor')}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    style={numberInputInlineStyle}
                    type="number"
                    value={reagent.get('uptakeFactor') || ''}
                />
            )
            .set('isCustom', <Toggle checked={reagent.get('isCustom') || false} disabled />)
            .set(
                'controls',
                <ButtonWrapper right>
                    <PrimaryButton
                        disabled={
                            this.state.disabledInputs || !enableSave || !this.state.isModifiedRow
                        }
                        loading={this.props.reagentIsUpdating}
                        onClick={this.handleSaveActiveRow}
                        text={this.props.intl.formatMessage({
                            id: 'components.ReagentManager.save',
                        })}
                    />
                    <SecondaryButton
                        disabled={this.state.disabledInputs}
                        onClick={this.handleCancelActiveRow}
                        text={this.props.intl.formatMessage({
                            id: 'components.ReagentManager.cancel',
                        })}
                    />
                </ButtonWrapper>
            );
    };

    /**
     * Map over Reagents and provide either interactive or editable row based on state.activeRow
     */
    provideTableRows = () => {
        const rows = this.props.reagents.map((reagent: ImmutableReagent) => {
            if (this.state.activeRow && this.state.activeRow.get('id') === reagent.get('id')) {
                return this.provideEditableRow();
            } else {
                return this.provideInteractiveRow(reagent);
            }
        });

        // If state.activeRow is an emptyRow, insert empty editable row at start of row
        let newRows;
        if (this.state.activeRow && !this.state.activeRow.get('id')) {
            newRows = rows.insert(0, this.provideEditableRow());
        }

        return (newRows || rows).toJS();
    };

    /**
     * Render delete button, onClick sets state.reagentIdToDelete with provided reagent's id
     */
    renderDeleteButton = (reagent: ImmutableReagent) => (
        <BlankButton onClick={this.handleSetReagentIdToDelete(reagent.get('id'))}>
            {this.props.intl.formatMessage({
                id: 'components.ReagentManager.deleteReagent',
            })}
        </BlankButton>
    );

    /**
     * Render delete button, onClick sets state.reagentIdToDelete with provided reagent's id
     */
    renderShowReagentPlantAssignmentModalButton = (reagent: ImmutableReagent) => (
        <BlankButton onClick={this.handleShowReagentPlantAssignmentModal(reagent)}>
            {this.props.intl.formatMessage({
                id: 'components.ReagentManager.plantAssignmentButton',
            })}
        </BlankButton>
    );

    /**
     * Provided this.state.reagentIdToDelete is set, display Modal and ReagentToDeleteModalContent
     */
    renderConditionalDeleteModal = () =>
        Boolean(this.state.reagentIdToDelete) && (
            <ConfirmationModal
                title={this.props.intl.formatMessage({
                    id: 'components.ReagentManager.DeleteModal.title',
                })}
                confirmButtonText={this.props.intl.formatMessage({
                    id: 'components.ReagentManager.DeleteModal.confirmButton',
                })}
                errors={this.props.errors}
                loading={this.props.reagentIsDeleting}
                onConfirm={
                    this.state.reagentIdToDelete
                        ? this.handleOnDelete(this.state.reagentIdToDelete)
                        : null
                }
                onCancel={this.handleSetReagentIdToDelete()}
                danger
            />
        );

    renderConditionalDataPreventionModal = () =>
        Boolean(this.state.showDataPreventionModal) && (
            <PreventDataLossModal
                isPageNavigation={false}
                onLeave={this.state.dataPreventionHandler}
                onCancel={this.handleCancelDataLossModal}
            />
        );

    renderReagentPlantAssignmentModal = () =>
        Boolean(this.state.reagentForPlantAssignmentModal) && (
            <ReagentPlantAssignmentModal
                reagent={this.state.reagentForPlantAssignmentModal}
                onCloseModal={this.handleCloseReagentPlantAssignmentModal}
            />
        );

    render() {
        return (
            <Wrapper>
                {this.renderConditionalDeleteModal()}
                {this.renderReagentPlantAssignmentModal()}
                {this.renderConditionalDataPreventionModal()}
                <Row alignItems="center" flex="0" style={{ margin: '0', marginBottom: '20px' }}>
                    <Column>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.ReagentManager.title',
                            })}
                        </Title>
                    </Column>
                    <Column alignItems="flex-end">
                        <PrimaryButton
                            disabled={!this.state.enableAddRow || this.props.reagentsAreQuerying}
                            onClick={this.handleCreateNewReagent}
                            text={this.props.intl.formatMessage({
                                id: 'components.ReagentManager.createNew',
                            })}
                        />
                    </Column>
                </Row>
                <TableContainer>
                    <Table
                        currentSorting={this.props.searchCriteria}
                        header={this.provideTableHeaders()}
                        loading={this.props.reagentsAreQuerying}
                        onSortBy={this.props.onHandleSortBy}
                        rows={this.provideTableRows()}
                    />
                    <Pagination
                        currentPage={this.props.pagination.page}
                        isLoading={this.props.reagentsAreQuerying}
                        onPageSelection={this.props.onHandlePaginationPageSelection}
                        pagesTotal={this.props.lastPage}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryJoiner',
                        })}
                    />
                </TableContainer>
                <PreventNavigationPrompt
                    shouldBlock={Boolean(this.state.activeRow) && this.state.isModifiedRow}
                />
            </Wrapper>
        );
    }
}

export default injectIntl(ReagentManager);
