// @flow

import styled, { css } from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';

export const SliderContainer = styled.div`
    display: flex;
    font-size: 10px;
    color: ${legacyTheme.sliderTextColor};
`;

export const SliderStart = styled.div`
    background-color: ${legacyTheme.sliderOffsideBackgroudColor};
    height: 4px;
    width: 100%;
    border-radius: 6px;
    margin-right: 5px;
`;

export const SliderEnd = styled.div`
    background-color: ${legacyTheme.sliderOffsideBackgroudColor};
    height: 4px;
    width: 100%;
    border-radius: 6px;
    margin-left: 5px;
`;

export const SliderStartWrapper = styled.div`
    width: ${({ width }: Object) => width || 0}%;
    min-width: 15px;
    padding-right: 8px;
    display: flex;
    align-items: center;
`;

export const SliderEndWrapper = styled.div`
    width: ${({ width }: Object) => width || 0}%;
    min-width: 20px;
    padding-left: 8px;
    display: flex;
    align-items: center;
`;

export const SliderRail = styled.div`
    background-color: ${legacyTheme.sliderTackBackgroudColor};
    height: 4px;
    width: 100%;
    border-radius: 6px;
    cursor: default;
`;

export const HandleTooltip = styled.div`
    color: ${legacyTheme.black};
    font-size: 12px;
    position: absolute;
    top: -15px;

    ${({ value }: Object) =>
        value &&
        `
        left: ${value === 100 ? '-10px' : '-2px'}
    `};
`;

export const handleTooltipComp = (percent: number, disabled: boolean) => ({
    left: `${percent}%`,
    position: 'absolute',
    marginLeft: '-7px',
    marginTop: '-9px',
    zIndex: 2,
    width: 14,
    height: 14,
    cursor: `${disabled ? 'default' : 'pointer'}`,
    borderRadius: '50%',
    backgroundColor: `${disabled ? legacyTheme.sliderHandleDisabled : legacyTheme.sliderHandle}`,
});

export const SliderWrapper = styled.div`
    width: ${({ width }: Object) => width || 0}%;
    padding-top: 5px;

    ${({ disabled }: Object) =>
        disabled &&
        css`
            ${SliderRail} {
                background-color: ${legacyTheme.sliderTackBackgroudColorDisabled};
            }
            ${HandleTooltip} {
                color: ${legacyTheme.defaultSubtleColor};
            }
        `}
`;
