// @flow strict

import { replace } from 'react-router-redux';

import { report } from 'services/Errors/resources';
import {
    receivedAcceptDisclaimerSuccess,
    receivedAcceptDisclaimerFail,
    setAcceptDisclaimerSaving,
    getUnacceptedDisclaimersFail,
    getUnacceptedDisclaimersSuccess,
    setGetUnacceptedDisclaimersPending,
    receivedWhoamiSuccess,
    receivedWhoamiFail,
    receivedLogoutSuccess,
    receivedLogoutFail,
    setUserIsFetching,
    setIsUpdatingMinchemPreferencesStatus,
    receivedUpdateMinchemPreferencesSuccess,
    receivedUpdateMinchemPreferencesFailure,
    setIsGettingMinchemPreferencesStatus,
    receivedGetMinchemPreferencesSuccess,
    receivedGetMinchemPreferencesFailure,
    setIsUpdatingUserPreferencesStatus,
    receivedUpdateUserPreferencesSuccess,
    receivedUpdateUserPreferencesFailure,
    setPhoneVerificationIsLoading,
    receivedPhoneVerificationStartSuccess,
    receivedPhoneVerificationStartFailure,
    receivedPhoneVerificationResendSuccess,
    receivedPhoneVerificationResendFailure,
    receivedPhoneVerificationCheckSuccess,
    receivedPhoneVerificationCheckFailure,
    setIsGettingPhoneNumber,
    receivedGetPhoneNumberSuccess,
    receivedGetPhoneNumberFailure,
} from './actions';

import { createUntranslatedFeedback } from 'services/Feedback/actions';
import {
    acceptDisclaimer,
    getUnacceptedDisclaimers,
    logout,
    getAuthenticatedUser,
    updatePreferences,
    getMinchemPreferencesRequest,
    updateMinchemPreferencesRequest,
    startPhoneVerification,
    resendPhoneVerificationOTC,
    checkPhoneVerification,
    getPhoneNumberRequest,
} from './resources';

import type { ImmutableList, ReduxDispatch, ResponseErrorType } from 'types';
import type {
    ImmutableUser,
    PhoneVerificationRequestId,
    PhoneCheckResponse,
} from 'services/Authentication/types';
import type { ImmutableDisclaimer } from 'services/Disclaimer/types';

import { getFirebaseAuth } from 'utils/authentication';
import { NAVIGATION_ROUTES } from '../../utils/constants';

const LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY = 'X-Solvay-LastPageLocation';

/**
 * This is used when the user logs out
 * Removes the user's authentication token from local storage
 * Removes the last page location from local storage
 */
const clearLocalStorage = () => {
    localStorage.removeItem('api_token');
    localStorage.removeItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY);
};

/**
 * Saves the last page location in local storage
 * This is used to redirect the user back to the page they were on after logging in again
 * This is used in the `whoami` request
 */
const saveLastPageLocation = () => {
    const currentLocation = window.location.pathname;
    localStorage.setItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY, currentLocation);
};

export const forwardToPage = (pageUrl: string) => (dispatch: ReduxDispatch) => {
    dispatch(replace(pageUrl));
};

/**
 * Checks the user authentication token
 */
export const whoami = () => (dispatch: ReduxDispatch) => {
    dispatch(setUserIsFetching());
    getAuthenticatedUser()
        .then((response: {}) => {
            if (response) {
                dispatch(receivedWhoamiSuccess(response));
                const lastPageLocation = localStorage.getItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY);
                if (lastPageLocation) {
                    localStorage.removeItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY);
                    dispatch(forwardToPage(lastPageLocation));
                }
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedWhoamiFail(error));
            dispatch(forwardToPage(NAVIGATION_ROUTES.HOME));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.whoAmIFailed'));
        });
};

/**
 * Invalidates a user token, if clientOnly is true simply clear localStorage and redirect user to login view
 */
export const logoutUser = (clientOnly?: boolean = false) => async (dispatch: ReduxDispatch) => {
    try {
        if (clientOnly) {
            // logout from the frontend only
            await getFirebaseAuth().signOut();
        } else {
            // logout from the backend and the frontend
            await Promise.all([
                logout(), // invalidates user's refresh tokens in the backend
                getFirebaseAuth().signOut(), // log the user out of firebase in the frontend client
            ]);
        }

        clearLocalStorage();
        saveLastPageLocation();
        dispatch(receivedLogoutSuccess(true)); // remove the user from the redux store - this will trigger a redirect to the login page (see `loginRedirect` in `app/routes.js`)
    } catch (error) {
        report(error);
        dispatch(receivedLogoutFail(error));
        dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.logoutUserFailed'));
    }
};

export const userAcceptDisclaimer = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setAcceptDisclaimerSaving());
    acceptDisclaimer(id)
        .then((response: {}) => {
            if (response) {
                dispatch(receivedAcceptDisclaimerSuccess(response));
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedAcceptDisclaimerFail(error));
        });
};

export const userGetUnacceptedDisclaimers = () => (dispatch: ReduxDispatch) => {
    dispatch(setGetUnacceptedDisclaimersPending());
    getUnacceptedDisclaimers()
        .then((response: { unacceptedDisclaimers: ImmutableList<ImmutableDisclaimer> }) => {
            dispatch(getUnacceptedDisclaimersSuccess(response.unacceptedDisclaimers));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(getUnacceptedDisclaimersFail(error));
        });
};

/**
 * Update the Minchem Preferences
 */
export const updateMinchemPreferences = (id: number, minchemPreferences: MinchemPreferences) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingMinchemPreferencesStatus());
    updateMinchemPreferencesRequest(id, minchemPreferences)
        .then((response: MinchemPreferences) => {
            dispatch(receivedUpdateMinchemPreferencesSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateMinchemPreferences'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateMinchemPreferencesFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateMinchemPreferencesFailed')
            );
        });
};

/**
 * Fetches the Minchem Preferences
 */
export const getMinchemPreferences = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsGettingMinchemPreferencesStatus());
    getMinchemPreferencesRequest(id)
        .then((response: MinchemPreferences) => {
            dispatch(receivedGetMinchemPreferencesSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedGetMinchemPreferencesFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.getMinchemPreferencesFailed')
            );
        });
};

/**
 * Update the user preferences
 */
export const updateUserPreferences = (user: ImmutableUser, preferences: GlobalUserPreferences) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingUserPreferencesStatus());
    updatePreferences(user.get('id'), preferences)
        .then((response: GlobalUserPreferences) => {
            dispatch(receivedUpdateUserPreferencesSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateUserPreferences'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateUserPreferencesFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateUserPreferencesFailed')
            );
        });
};

/**
 * Start the user's phone verification
 */
export const startUserPhoneVerification = (userId: number, phoneNumber: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setPhoneVerificationIsLoading());
    startPhoneVerification(userId, phoneNumber)
        .then((response: PhoneVerificationRequestId) => {
            dispatch(receivedPhoneVerificationStartSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPhoneVerificationStartFailure(error));
        });
};

/**
 * Re-send the phone verification OTC
 */
export const resendUserPhoneVerificationOTC = (userId: number, requestId: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setPhoneVerificationIsLoading());
    resendPhoneVerificationOTC(userId, requestId)
        .then(() => {
            dispatch(receivedPhoneVerificationResendSuccess());
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPhoneVerificationResendFailure(error));
        });
};

/**
 * Validate the OTC and write the user's phone number
 */
export const checkUserPhoneVerification = (userId: number, requestId: string, otc: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setPhoneVerificationIsLoading());
    checkPhoneVerification(userId, requestId, otc)
        .then((response: PhoneCheckResponse) => {
            dispatch(receivedPhoneVerificationCheckSuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.phoneVerification'));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPhoneVerificationCheckFailure(error));
        });
};

export const getPhoneNumber = () => (dispatch: ReduxDispatch) => {
    dispatch(setIsGettingPhoneNumber());
    getPhoneNumberRequest()
        .then((response: { phoneNumber: string }) => {
            dispatch(receivedGetPhoneNumberSuccess(response.phoneNumber));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedGetPhoneNumberFailure(error));
        });
};
