// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Helpers
import { Modal, PrimaryButton, TertiaryButton, InputNumber } from 'components/_ReactUI_V1';

import { tryParseNumberOrNull } from 'utils/helpers';
import { getKPISettingUnit, getKPIInvalidValueReason } from 'utils/kpiHelpers';

// Components
import ErrorMessage from 'components/ErrorMessage';

// Constants
import {
    NUMBER_INPUT_PLACEHOLDER,
    MODAL_WIDTH,
    KPI_SPECIFICITY_TYPES,
    VALUE_STATUS,
    STYLE_VALUES,
} from 'utils/constants';

// Styles
import { Wrapper, Header, Title, Footer, Field, Label, Body } from './styles';

// Types
import type { IntlType, InputEvent, ReduxDispatch, ErrorType, LooseNumberType } from 'types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type {
    ImmutableCircuitKPI,
    LooseDatasetValue,
    ImmutableDatasetValue,
    ImmutableStageValue,
    StageValue,
    ImmutableStreamValue,
    StreamValue,
} from 'services/Dataset/types';
import type { ImmutablePlantValue, LoosePlantValue } from 'services/PlantDataset/types';

// Services
import { selectIsUpdatingValue } from 'services/Dataset/selectors';
import { selectTrendsErrors } from 'services/Trends/selectors';
import {
    createStreamValue,
    updateStreamValue,
    createStageValue,
    updateStageValue,
    createDatasetValue,
    updateDatasetValue,
} from 'services/Dataset/thunks';
import { createPlantValue, updatePlantValue } from 'services/PlantDataset/thunks';

type Props = {
    intl: IntlType,
    valueData:
        | ImmutableDatasetValue
        | ImmutableStreamValue
        | ImmutableStageValue
        | ImmutablePlantValue
        | null,
    datasetId: number | null,
    plantDatasetId: number | null,
    isUpdatingValue: boolean,
    kpiSetting: ImmutableKPISetting,
    errors: ErrorType,
    onCloseModal: () => void,
    createDatasetValue: (
        circuitId: number,
        datasetId: number,
        data: $Shape<LooseDatasetValue>
    ) => void,
    updateDatasetValue: (
        circuitId: number,
        datasetId: number,
        datasetValueId: number,
        data: $Shape<LooseDatasetValue>
    ) => void,
    createStreamValue: (circuitId: number, datasetId: number, data: $Shape<StreamValue>) => void,
    updateStreamValue: (
        circuitId: number,
        datasetId: number,
        streamValueId: number,
        data: $Shape<StreamValue>
    ) => void,
    createStageValue: (circuitId: number, datasetId: number, data: $Shape<StageValue>) => void,
    updateStageValue: (
        circuitId: number,
        datasetId: number,
        stageValueId: number,
        data: $Shape<StageValue>
    ) => void,
    createPlantValue: (plantId: number, plantDatasetId: number, data: $Shape<PlantValue>) => void,
    updatePlantValue: (
        plantId: number,
        plantDatasetId: number,
        plantValueId: number,
        data: $Shape<PlantValue>
    ) => void,
};

type State = {
    value: number,
};

class EditKPIValueModal extends React.PureComponent<Props, State> {
    state = {
        value: this.props.valueData ? this.props.valueData.get('value') : null,
    };

    componentDidUpdate(prevProps: Props) {
        // If isUpdatingValue flag went from true to false & there are no errors, close modal
        if (
            prevProps.isUpdatingValue !== this.props.isUpdatingValue &&
            this.props.errors.isEmpty()
        ) {
            this.props.onCloseModal();
        }
    }

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id,
            },
            data
        );

    getUnsavedValueData = (override = {}) => ({
        value: this.state.value || null,
        status: VALUE_STATUS.COMPUTE,
        valueType: this.props.kpiSetting.get('kpiType'),
        kpiTypeId: this.props.kpiSetting.get('kpiTypeId'),
        ...override,
    });

    getValueDataWithLocalValue = () => {
        const data = this.props.valueData.toJS();
        // Update valueData with user inputted value to be sent to backend
        data.value = this.state.value;
        return data;
    };

    handleConfirm = () => {
        const plantId = this.props.kpiSetting.get('plantId');
        const circuitId = this.props.kpiSetting.get('circuitId');
        const datasetId = this.props.datasetId;
        const plantDatasetId = this.props.plantDatasetId;

        const valueDataId = this.props.valueData && this.props.valueData.get('id', null);

        const updatedValueData = this.props.valueData ? this.getValueDataWithLocalValue() : null;

        if (datasetId) {
            switch (this.props.kpiSetting.get('specificityType')) {
                case KPI_SPECIFICITY_TYPES.CIRCUIT: {
                    if (updatedValueData && valueDataId) {
                        return this.props.updateDatasetValue(
                            circuitId,
                            datasetId,
                            valueDataId,
                            updatedValueData
                        );
                    } else {
                        return this.props.createDatasetValue(
                            circuitId,
                            datasetId,
                            this.getUnsavedValueData({ datasetId })
                        );
                    }
                }
                case KPI_SPECIFICITY_TYPES.STREAM: {
                    if (updatedValueData && valueDataId) {
                        return this.props.updateStreamValue(
                            circuitId,
                            datasetId,
                            valueDataId,
                            updatedValueData
                        );
                    } else {
                        return this.props.createStreamValue(
                            circuitId,
                            datasetId,
                            this.getUnsavedValueData({
                                datasetId,
                                streamId: this.props.kpiSetting.get('streamId'),
                            })
                        );
                    }
                }
                case KPI_SPECIFICITY_TYPES.STAGE: {
                    if (updatedValueData && valueDataId) {
                        return this.props.updateStageValue(
                            circuitId,
                            datasetId,
                            valueDataId,
                            updatedValueData
                        );
                    } else {
                        return this.props.createStageValue(
                            circuitId,
                            datasetId,
                            this.getUnsavedValueData({
                                datasetId,
                                stageId: this.props.kpiSetting.get('stageId'),
                            })
                        );
                    }
                }
                default: {
                    throw new Error('Unexpected KPI specificity type provided');
                }
            }
        }

        if (
            plantDatasetId &&
            this.props.kpiSetting.get('specificityType') === KPI_SPECIFICITY_TYPES.PLANT
        ) {
            if (updatedValueData && valueDataId) {
                return this.props.updatePlantValue(
                    plantId,
                    plantDatasetId,
                    valueDataId,
                    updatedValueData
                );
            } else {
                return this.props.createPlantValue(
                    circuitId,
                    plantDatasetId,
                    this.getUnsavedValueData({ plantDatasetId })
                );
            }
        }

        throw new Error('No dataset id provided, something went wrong');
    };

    handleChangeValue = (e: InputEvent) => {
        const stateKey = e.target.name;
        this.setState({
            [stateKey]: tryParseNumberOrNull(e.target.value),
        });
    };

    getError = () => {
        const invalidReason = getKPIInvalidValueReason(
            this.state.value,
            this.props.kpiSetting.get('minValid'),
            this.props.kpiSetting.get('maxValid')
        );
        return invalidReason ? `models.kpiSettings.invalidReasonsWithData.${invalidReason}` : null;
    };

    getUnit = () => getKPISettingUnit(this.props.kpiSetting, this.props.intl);

    isSaveDisabled = () =>
        this.state.value === null || this.props.isUpdatingValue || this.getError();

    renderErrors = () => {
        const errorCode = this.getError();
        if (errorCode) {
            return (
                <ErrorMessage
                    errorCode={errorCode}
                    intlData={{
                        min: this.props.kpiSetting.get('minValid'),
                        max: this.props.kpiSetting.get('maxValid'),
                        unit: this.getUnit(),
                    }}
                    style={{
                        marginBottom: '10px',
                        marginTop: '10px',
                        textAlign: 'left',
                    }}
                    isRed
                    isSmall
                />
            );
        }
    };

    renderEditModal() {
        const unit = this.getUnit();
        const minValid = this.props.kpiSetting.get('minValid', null);
        const maxValid = this.props.kpiSetting.get('maxValid', null);
        return (
            <Modal
                padding="none"
                modalWidth={MODAL_WIDTH.SMALL}
                modalHeight="100%"
                onHandleClose={this.props.onCloseModal}
            >
                <Wrapper>
                    <Header>
                        <Title>{this.props.kpiSetting.get('name')}</Title>
                    </Header>
                    <Body>
                        {minValid !== null && (
                            <Field>
                                <Label>
                                    {this.getTranslation('models.kpiSettings.minValid', {
                                        value: minValid,
                                        unit,
                                    })}
                                </Label>
                            </Field>
                        )}
                        {maxValid !== null && (
                            <Field>
                                <Label>
                                    {this.getTranslation('models.kpiSettings.maxValid', {
                                        value: maxValid,
                                        unit,
                                    })}
                                </Label>
                            </Field>
                        )}
                        <Field>
                            <Label>{this.getTranslation('models.kpiSettings.newValue')}</Label>
                            <InputNumber
                                name="value"
                                max={minValid}
                                max={maxValid}
                                value={this.state.value !== null ? this.state.value : ''}
                                onChange={this.handleChangeValue}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                            />
                            <Label>{unit}</Label>
                        </Field>
                        {this.renderErrors()}
                    </Body>
                    <Footer>
                        <TertiaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            disabled={this.props.isUpdatingValue}
                            onClick={this.props.onCloseModal}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.saveButton',
                            })}
                            disabled={this.isSaveDisabled()}
                            loading={this.props.isUpdatingValue}
                            onClick={this.handleConfirm}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }

    render() {
        return this.renderEditModal();
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        errors: selectTrendsErrors(),
        isUpdatingValue: selectIsUpdatingValue(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createStreamValue,
            updateStreamValue,
            createDatasetValue,
            updateDatasetValue,
            createStageValue,
            updateStageValue,
            createPlantValue,
            updatePlantValue,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EditKPIValueModal));
