// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { Title } from 'styles/common';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    padding: 0px 24px 24px;

    border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 20px 24px;

    max-height: 420px;
    overflow-y: visible;
`;

export const Subtitle = styled(Title)`
    color: ${LegacyTheme.defaultColor};
`;

export const Footer = styled.div`
    padding: 16px 24px 0px;
    border-top: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
`;

export const HorizontalField = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const HorizontalFieldLabel = styled.label`
    color: ${LegacyTheme.defaultColor};
    font-size: 14px;
`;

export const ColumnTitle = styled.h3`
    font-size: 16px;
    color: black;
    font-weight: normal;
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    }
`;

export const FieldLabel = styled.label`
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 13px;

    margin-bottom: 8px;
`;

export const InputSelectWrapper = styled.div`
    min-width: 70px;
    margin-left: 16px;
`;
