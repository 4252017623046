// @flow

import type { ReactNode } from '../../types';

export type Props = {
    backgroundColor?: ?string,
    borderRadius?: ?number,
    disabled?: boolean,
    handleOnClick?: ?() => void,
    hoverBackgroundColor?: ?string,
    hoverTextColor?: ?string,
    inline?: boolean,
    nowrap?: boolean,
    onClick?: ?() => void,
    style?: Object,
    text?: ?string,
    textColor?: ?string,
    loading?: boolean,
    icon?: ?ReactNode,
};

export const defaultProps = {
    backgroundColor: null,
    borderRadius: null,
    disabled: false,
    handleOnClick: null,
    hoverBackgroundColor: null,
    hoverTextColor: null,
    inline: true,
    loading: false,
    nowrap: true,
    onClick: null,
    style: {},
    text: 'Click',
    textColor: null,
    icon: null,
};
