// @flow strict

import type { ReduxDispatch, ResponseErrorType, SearchCriteria } from 'types';
import { createUntranslatedFeedback } from 'services/Feedback/actions';
import { report } from 'services/Errors/resources';

import {
    receivedCreateSuccess,
    receivedCreateFailure,
    receivedFetchListSuccess,
    receivedFetchListFailure,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    receivedDestroySuccess,
    receivedDestroyFailure,
    receivedQueryListSuccess,
    receivedQueryListFailure,
    setIsCreatingStatus,
    setIsDeletingStatus,
    setIsFetchingStatus,
    setIsUpdatingStatus,
    setIsQueryStatus,
} from './actions';
import type { ImmutableClient, Client, ClientQueryType } from './types';
import { create, index, update, destroy, query } from './resources';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Create new client
 */
export const createClient = (client: ImmutableClient) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingStatus());
    create(client)
        .then((response: Client) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.createClientSuccess'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createClientFailed'));
        });
};

/**
 * Fetch all clients
 */
export const fetchAllClients = () => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingStatus());
    index()
        .then((response: Array<Client>) => {
            dispatch(receivedFetchListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchAllClientsFailed'));
        });
};

/**
 * Query (search, pagination, etc) all clients
 */
export const queryAllClients = (searchCriteria: SearchCriteria, page: number, perPage: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsQueryStatus());
    query(searchCriteria, page, perPage)
        .then((response: ClientQueryType) => {
            dispatch(receivedQueryListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedQueryListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.queryAllClientsFailed'));
        });
};

/**
 * Update client
 */
export const updateClient = (id: number, client: ImmutableClient) => (dispatch: ReduxDispatch) => {
    dispatch(setIsUpdatingStatus());
    update(id, client)
        .then((response: Client) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateClient'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateClientFailed'));
        });
};

/**
 * Destroy client
 */
export const destroyClient = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsDeletingStatus());
    destroy(id)
        .then((response: Client) => {
            dispatch(receivedDestroySuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.destroyClientSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDestroyFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.destroyClientFailed'));
        });
};
