// @flow strict

import styled from 'styled-components';

import { LegacyTheme, ButtonHover } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const MajorKPIWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    padding: 32px 48px;
    min-height: 450px;
    max-height: 650px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        flex-direction: column-reverse;
        justify-content: flex-start;

        padding: 0px;

        max-height: 100%;
        height: 100%;
    }
`;

export const ControlsAndGraphWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
`;

export const GraphWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    max-height: calc(100% - 34px);
    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        max-height: none;
    }
`;

export const Controls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: ${LegacyTheme.defaultSubtleColor};

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        justify-content: center;
    }
`;

export const RightControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    height: 100%;

    > * + * {
        margin-left: 16px;
    }

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        display: none;
    }
`;

export const SettingsButtonHover = styled(ButtonHover)`
    padding: 2.5px;
`;

export const NoDataMessageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    padding: ${STYLE_VALUES.GUTTERS.NORMAL};
    margin-top: 50px;

    text-align: center;
`;

export const MobileStatusBar = styled.div`
    display: none;
    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        top: ${STYLE_VALUES.HEADER.HEIGHT};
        z-index: 4;
        min-width: ${STYLE_VALUES.MOBILE.MIN_WIDTH};
        height: ${STYLE_VALUES.MAJOR_KPI_STATUS_HEADER.HEIGHT};
        width: 100%;

        padding: 10px;
        border-bottom: 1px solid ${LegacyTheme.defaultBorder};
        background-color: ${LegacyTheme.white};

        display: flex;
        flex-direction: row;
        justify-content: center;
    }
`;

export const MobileStatusBarItem = styled.div`
    display: none;
    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        color: ${LegacyTheme.defaultSubtleColor};
        font-size: 13px;
        display: flex;

        & + & {
            margin-left: 10px;
        }
    }
`;
