// @flow

import legacyTheme from '../../themes/legacyTheme';

export const victoryPropertyAxis = (main: boolean, wide: boolean) => ({
    axisLabel: {
        padding: wide ? 30 : 15,
        fontSize: 9,
        fill: legacyTheme.graphAxisLabelColor,
        fontFamily: 'Helvetica, Arial, sans-serif',
    },
    axis: {
        stroke: legacyTheme.graphGridBorder,
        strokeWidth: 1,
    },
    grid: {
        stroke: main ? legacyTheme.graphGridBorder : 'transparent', // Avoid overlap strokes which cause's poor rendering
        strokeWidth: 1,
        strokeDasharray: null,
    },
});

export const VictoryData = {
    data: {
        fillOpacity: 0.2,
        strokeWidth: 1,
    },
};
