// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-self: flex-end;
    flex-shrink: 0;

    justify-content: space-between;

    padding: 8px 24px;

    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;

export const GroupWrapper = styled.div`
    display: flex;
    flex-direction: row;

    > button + button {
        // Export / copy to clipboard
        margin-left: 7px;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-right: 32px;

    & > span {
        color: ${LegacyTheme.defaultColor};
        font-size: 13px;
        line-height: 16px;
        margin-right: 8px;
    }
`;
