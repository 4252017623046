// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Helpers
import { clamp, formatNumberAsStringValue } from 'utils/helpers';

// Styles
import { Wrapper, GroupWrapper, InputWrapper } from './styles';

// Components
import { InputNumber, PrimaryButton, SecondaryButton } from 'components/_ReactUI_V1';

// Constants
import { NUMBER_INPUT_PLACEHOLDER, STYLE_VALUES } from 'utils/constants';

// Types
import type { IntlType, InputEvent } from 'types';
import type { SensitivityOption } from 'containers/CircuitComputationContainer/ThreeDSensitivityContainer';

type Props = {
    intl: IntlType,

    loading: boolean,
    isDisplayingMimic: boolean,

    minSensitivityOptionValue: number,
    maxSensitivityOptionValue: number,
    sensitivityOption: SensitivityOption,
    sensitivityOptionValue: ?number,
    handleSensitivityOptionInteraction: (
        sensitivityOptionValue: ?number,
        replaceWithNearest: boolean
    ) => void,

    minRecoveryPercent: number,
    maxRecoveryPercent: number,
    recoveryPercent: ?number,
    handleRecoveryPercentInteraction: (
        recoveryPercent: ?number,
        replaceWithNearest: boolean
    ) => void,

    hasNetTransfer: boolean,
    minNetTransfer: number,
    maxNetTransfer: number,
    netTransfer: ?number,
    handleNetTransferInteraction: (netTransfer: ?number, replaceWithNearest: boolean) => void,

    copyToClipboardDisabled: boolean,
    onCopyToClipboardClick: () => void,

    computeDisabled: boolean,
    handleComputeClicked: () => void,

    exportDisabled: boolean,
    handleExportClicked: () => void,

    onHandleHideMimicDiagram: () => void,
};

const inputNumberStyleObj = {
    ...STYLE_VALUES.DEFAULT_INPUT_NUMBER_STYLES,
    width: '64px',
};

/**
 * The footer with the controls used in the 2D Sensitivity plot
 */
class TwoDSensitivityFooter extends React.PureComponent<Props> {
    /**
     * Given which option we want, what is the associated min max?
     */
    getMinMax = (valueType: 'SENSITIVITY_OPTION' | 'RECOVERY_PERCENT' | 'NET_TRANSFER') => {
        if (valueType === 'SENSITIVITY_OPTION') {
            return {
                min: this.props.minSensitivityOptionValue,
                max: this.props.maxSensitivityOptionValue,
            };
        } else if (valueType === 'RECOVERY_PERCENT') {
            // TODO: Get min/max from the 2d plot graph
            return {
                min: this.props.minRecoveryPercent,
                max: this.props.maxRecoveryPercent,
            };
        } else {
            // NET_TRANSFER
            // TODO: Get min/max from the 2d plot graph
            return {
                min: this.props.minNetTransfer,
                max: this.props.maxNetTransfer,
            };
        }
    };

    /**
     * Sanitize user inputted numbers
     */
    sanitizeNumber = (
        valueType: 'SENSITIVITY_OPTION' | 'RECOVERY_PERCENT' | 'NET_TRANSFER',
        value: string
    ): ?number => {
        // if input is empty, or the input isn't a number
        if (!value || value === '' || Number.isNaN(value)) return null;

        const minMax = this.getMinMax(valueType);
        return clamp(
            Number(value) || 0, // try to cast the number if not a number, use 0
            minMax.min,
            minMax.max
        );
    };

    /**
     * Is computable will be true when all 3 fields are not null.
     */
    isComputable = () => this.props.sensitivityOptionValue !== null;

    /**
     * When the compute button is clicked,
     * check if the sensitivity can be computed and call parent.
     */
    handleComputeClicked = () => this.isComputable() && this.props.handleComputeClicked();

    // handles the change in the sensitivity option input box
    handleSensitivityOptionInteraction = (replaceWithNearest: boolean = false) => (
        event: InputEvent
    ) =>
        this.props.handleSensitivityOptionInteraction(
            replaceWithNearest
                ? this.sanitizeNumber('SENSITIVITY_OPTION', event.target.value)
                : Number(event.target.value),
            replaceWithNearest
        );

    // handles the change in the recovery percent input box
    handleRecoveryPercentInteraction = (replaceWithNearest: boolean = false) => (
        event: InputEvent
    ) =>
        this.props.handleRecoveryPercentInteraction(
            replaceWithNearest
                ? this.sanitizeNumber('RECOVERY_PERCENT', event.target.value)
                : Number(event.target.value),
            replaceWithNearest
        );

    // handles the change in the net transfer input box
    handleNetTransferInteraction = (replaceWithNearest: boolean = false) => (event: InputEvent) =>
        this.props.handleNetTransferInteraction(
            replaceWithNearest
                ? this.sanitizeNumber('NET_TRANSFER', event.target.value)
                : Number(event.target.value),
            replaceWithNearest
        );

    render() {
        return (
            <Wrapper>
                <GroupWrapper>
                    <InputWrapper>
                        <span>
                            {this.props.intl.formatMessage({
                                id: `constants.SensitivityOptions.${this.props.sensitivityOption}`,
                            })}
                        </span>
                        <InputNumber
                            handleOnChange={this.handleSensitivityOptionInteraction()}
                            onBlur={this.handleSensitivityOptionInteraction(true)}
                            max={this.props.maxSensitivityOptionValue}
                            min={this.props.minSensitivityOptionValue}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            disabled={this.props.isDisplayingMimic || this.props.loading}
                            style={inputNumberStyleObj}
                            value={formatNumberAsStringValue(this.props.sensitivityOptionValue)}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <span>
                            {this.props.intl.formatMessage({
                                id: 'components.2DSensitivityFooter.recoveryPercentLabel',
                            })}
                        </span>
                        <InputNumber
                            handleOnChange={this.handleRecoveryPercentInteraction()}
                            onBlur={this.handleRecoveryPercentInteraction(true)}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            disabled={this.props.isDisplayingMimic || this.props.loading}
                            style={inputNumberStyleObj}
                            value={formatNumberAsStringValue(this.props.recoveryPercent)}
                        />
                    </InputWrapper>
                    {this.props.hasNetTransfer && (
                        <InputWrapper>
                            <span>
                                {this.props.intl.formatMessage({
                                    id: 'components.2DSensitivityFooter.netTransferLabel',
                                })}
                            </span>
                            <InputNumber
                                handleOnChange={this.handleNetTransferInteraction()}
                                onBlur={this.handleNetTransferInteraction(true)}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                disabled={this.props.isDisplayingMimic || this.props.loading}
                                style={inputNumberStyleObj}
                                value={formatNumberAsStringValue(this.props.netTransfer)}
                            />
                        </InputWrapper>
                    )}
                    <PrimaryButton
                        text={
                            this.props.isDisplayingMimic
                                ? this.props.intl.formatMessage({
                                      id: 'components.2DSensitivityFooter.returnToGraph',
                                  })
                                : this.props.intl.formatMessage({
                                      id: 'components.2DSensitivityFooter.compute',
                                  })
                        }
                        disabled={
                            this.props.computeDisabled || !this.isComputable() || this.props.loading
                        }
                        onClick={
                            this.props.isDisplayingMimic
                                ? this.props.onHandleHideMimicDiagram
                                : this.handleComputeClicked
                        }
                        loading={this.props.loading}
                    />
                </GroupWrapper>

                <GroupWrapper>
                    <SecondaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.2DSensitivityFooter.copyToClipboard',
                        })}
                        disabled={this.props.copyToClipboardDisabled}
                        onClick={this.props.onCopyToClipboardClick}
                    />
                    <PrimaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.2DSensitivityFooter.exportButton',
                        })}
                        disabled={this.props.exportDisabled}
                        onClick={this.props.handleExportClicked}
                    />
                </GroupWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(TwoDSensitivityFooter);
