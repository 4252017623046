// @flow strict

import type { ImmutableThreeDAnalysis, ImmutableThreeDDataPoint } from 'services/Dataset/types';

export const getXMin = (threeDAnalysis: ImmutableThreeDAnalysis) =>
    Math.min(
        ...threeDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableThreeDDataPoint) => dataPoint.get('xVariable'))
    );

export const getXMax = (threeDAnalysis: ImmutableThreeDAnalysis) =>
    Math.max(
        ...threeDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableThreeDDataPoint) => dataPoint.get('xVariable'))
    );

export const getYMin = (threeDAnalysis: ImmutableThreeDAnalysis) =>
    Math.min(
        ...threeDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableThreeDDataPoint) => dataPoint.get('yVariable'))
    );

export const getYMax = (threeDAnalysis: ImmutableThreeDAnalysis) =>
    Math.max(
        ...threeDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableThreeDDataPoint) => dataPoint.get('yVariable'))
    );

export const getZMin = (threeDAnalysis: ImmutableThreeDAnalysis) =>
    Math.min(
        ...threeDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableThreeDDataPoint) => dataPoint.get('zVariable'))
    );

export const getZMax = (threeDAnalysis: ImmutableThreeDAnalysis) =>
    Math.max(
        ...threeDAnalysis
            .get('analysis')
            .map((dataPoint: ImmutableThreeDDataPoint) => dataPoint.get('zVariable'))
    );
