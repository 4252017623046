// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Helpers
import { getFormattedDateFromString } from 'utils/dateHelpers';

// Components
import { PrimaryButton, TextField } from 'components/_ReactUI_V1';

// Styles
import {
    Label,
    Instructions,
    SidebarBody,
    DisplayMessages,
    Spacer,
    SidebarFooter,
    InputLimitCount,
} from './styles';

// Types
import type { IntlType, InputEvent, LanguageCodeConstant } from 'types';
import type { ImmutablePlantDatasetMessage } from 'services/Recommendation/types';

type Props = {
    intl: IntlType,
    userLocale: LanguageCodeConstant,
    isUserPM: boolean,
    timezone: string,

    plantDatasetId: number,
    plantDatasetMessage: ?ImmutablePlantDatasetMessage,

    loadingSubmittingPlantDatasetMessage: boolean,
    onSubmitPlantDatasetMessage: (message: ImmutablePlantDatasetMessage) => void,
};

type State = {
    plantDatasetMessage: ?ImmutablePlantDatasetMessage,
    characterCount: number,
};

const COMMENT_MAX_CHARACTERS = 1000;

/**
 * Plant Dataset Message Sidebar component
 */
class PlantDatasetMessageSidebar extends React.PureComponent<Props, State> {
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (!nextProps.plantDatasetMessage) {
            return {
                plantDatasetMessage: null,
            };
        }
        if (nextProps.plantDatasetMessage && !prevState.plantDatasetMessage) {
            return {
                plantDatasetMessage: nextProps.plantDatasetMessage,
            };
        }
        if (nextProps.plantDatasetMessage.get('id') && !prevState.plantDatasetMessage.get('id')) {
            return {
                plantDatasetMessage: nextProps.plantDatasetMessage,
            };
        }
        if (
            prevState.plantDatasetMessage.get('plantDatasetId') !==
            nextProps.plantDatasetMessage.get('plantDatasetId')
        ) {
            return {
                plantDatasetMessage: nextProps.plantDatasetMessage,
            };
        }
    }

    state = {
        plantDatasetMessage: null,
        characterCount: COMMENT_MAX_CHARACTERS,
    };

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.PlantDatasetMessageSidebar.${id}`,
            },
            data
        );

    wasPlantDatasetMessageSubmitted = () =>
        Boolean(this.state.plantDatasetMessage && this.state.plantDatasetMessage.get('id'));

    isSubmitDisabled = () => {
        if (!this.canSubmit()) {
            return true;
        }

        // If the plant dataset message has a comment, allow SAM to submit
        return !(this.state.plantDatasetMessage && this.state.plantDatasetMessage.get('comment'));
    };

    canSubmit = () => {
        // If user is a PM, they cannot submit a message
        if (this.props.isUserPM) {
            return false;
        }
        // If there is no recommendation set, there is nothing to submit.
        if (!this.state.plantDatasetMessage) {
            return false;
        }
        // if the user is not a PM, the user cannot submit if they've already submitted a plant dataset message.
        return !this.wasPlantDatasetMessageSubmitted();
    };

    canSAMModify = () => this.canSubmit() && !this.props.isUserPM;

    /**
     * Change the text of message
     */
    handleChangeMessage = (event: InputEvent) => {
        const comment = event.target.value;
        this.setState((prevState: State) => {
            return {
                plantDatasetMessage: prevState.plantDatasetMessage.set('comment', comment),
                characterCount: COMMENT_MAX_CHARACTERS - comment.length,
            };
        });
    };

    /**
     * When the SAM presses submit.
     */
    handleSubmit = () =>
        !this.props.isUserPM
            ? this.props.onSubmitPlantDatasetMessage(this.state.plantDatasetMessage)
            : null;

    /**
     * Render the plant dataset message
     */
    renderPlantDatasetMessage = () => {
        const { plantDatasetMessage } = this.state;

        let inner = null;
        if (this.canSAMModify()) {
            // the user is a sam or an admin:
            inner = (
                <React.Fragment>
                    <TextField
                        minHeight="75px"
                        value={(plantDatasetMessage && plantDatasetMessage.get('comment')) || ''}
                        onChange={this.handleChangeMessage}
                        placeholder={this.getTranslation('plantDatasetMessagePlaceholder')}
                        disabled={this.props.loadingSubmittingPlantDatasetMessage}
                        maxLength={COMMENT_MAX_CHARACTERS}
                    />
                    <InputLimitCount>
                        {this.state.characterCount}{' '}
                        {this.getTranslation('plantDatasetMessageCharactersRemaining')}
                    </InputLimitCount>
                </React.Fragment>
            );
        } else {
            if (!plantDatasetMessage || (plantDatasetMessage && !plantDatasetMessage.get('id'))) {
                return <Instructions>{this.getTranslation('noMessage')}</Instructions>;
            }

            const samName = plantDatasetMessage.get('sentByName');
            const date = getFormattedDateFromString(
                plantDatasetMessage.get('createdAt'),
                this.props.userLocale,
                {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                    timeZone: this.props.timezone || '',
                    timeZoneName: 'short',
                }
            );

            inner = (
                <React.Fragment>
                    <p>{plantDatasetMessage.get('comment')}</p>
                    <Label>{this.getTranslation('submittedBy', { samName })}</Label>
                    <Label>{this.getTranslation('submittedAt', { date })}</Label>
                </React.Fragment>
            );
        }
        return <DisplayMessages>{inner}</DisplayMessages>;
    };

    renderFooter = () => (
        <SidebarFooter style={{ padding: '8px 24px' }}>
            <PrimaryButton
                onClick={this.handleSubmit}
                disabled={this.isSubmitDisabled()}
                text={this.getTranslation('submitPlantDatasetMessageButton')}
                loading={this.props.loadingSubmittingPlantDatasetMessage}
            />
        </SidebarFooter>
    );

    render() {
        return (
            <React.Fragment>
                <SidebarBody>
                    {this.renderPlantDatasetMessage()}
                    <Spacer />
                </SidebarBody>
                {this.canSubmit() && this.renderFooter()}
            </React.Fragment>
        );
    }
}

export default injectIntl(PlantDatasetMessageSidebar);
