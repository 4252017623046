// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Components
import TrendsContainer from 'containers/TrendsContainer';

// Constants
import { TRENDS_PAGE_TYPE } from 'utils/constants';

// Types
import type { IntlType, Navigation } from 'types';

type Props = {
    intl: IntlType,
    match: {
        params: {
            circuitId: string,
        },
    },
};

export class CircuitTrends extends React.PureComponent<Props> {

    getCircuitId() {
        const circuitId = this.props.match.params.circuitId;
        if (circuitId) {
            return parseInt(circuitId, 10);
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.CircuitTrends.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.CircuitTrends.helmetDescription',
                        })}
                    />
                </Helmet>
                <TrendsContainer
                    trendType={TRENDS_PAGE_TYPE.CIRCUIT}
                    circuitId={this.getCircuitId()}
                />
            </React.Fragment>
        );
    }
}

export default injectIntl(CircuitTrends);
