// @flow strict

import type { ImmutableError } from 'services/Errors/types';
import type { ImmutableList, ImmutableMap } from 'types';

import { WEEK_DAYS } from 'utils/constants';

export type Notification = {
    id: number,
    subject: string,
    title: string,
    content: string,
    link: string,
    createdAt: string,
    notificationType: string,
};

export type ImmutableNotification = ImmutableMap<string, Notification>;

export type ImmutableNotifications = ImmutableList<string, ImmutableNotification>;

export type NotificationReadState = {
    id: number,
    read: boolean,
};

export type ImmutableNotificationReadState = ImmutableMap<string, NotificationReadState>;

export type TotalUnreadNotifications = { totalUnreadNotifications: number };

export type ImmutableTotalUnreadNotifications = ImmutableMap<string, TotalUnreadNotifications>;

export type NotificationSetting = {
    emailNotification: boolean,
    smsNotification: boolean,
    notificationType: string,
    notificationTypeApplication: string,
    active: boolean,
};

export type ImmutableNotificationSettings = ImmutableList<string, NotificationSetting>;

export type NotificationPlantSetting = {
    plantId: number,
    plantName: string,
    active: boolean,
};

export type ImmutableNotificationPlantSettings = ImmutableList<string, NotificationPlantSetting>;

export type NotificationScheduleDay = {
    day: $Keys<typeof WEEK_DAYS>,
    startingTime: string,
    endingTime: string,
};

export type ImmutableNotificationSchedule = ImmutableList<string, NotificationScheduleDay>;

export type NotificationTypeUserStatus = {
    userId: number,
    emailNotification: boolean,
    smsNotification: boolean,
};

export type ImmutableNotificationTypeUsersStatuses = ImmutableList<
    string,
    NotificationTypeUserStatus
>;

export type ImmutableNotificationsState = ImmutableMap<
    string,
    {
        errors: ImmutableError,
        notificationsAreQuerying: boolean,
        queriedNotifications: ImmutableNotifications,
        currentPage: number,
        lastPage: number,
        notificationsAreUpdating: boolean,
        markAllAsReadIsUpdating: boolean,
        unreadTotalIsFetching: boolean,
        unreadTotal: number,
        settingsAreFetching: boolean,
        settingsAreUpdating: boolean,
        settings: ImmutableNotificationSettings,
        scheduleIsFetching: boolean,
        scheduleIsUpdating: boolean,
        schedule: ImmutableNotificationSchedule,
        notificationTypeUsersStatusesAreFetching: boolean,
        notificationTypeUsersStatuses: ImmutableNotificationTypeUsersStatuses,
    }
>;

export type NotificationChannel = 'email' | 'sms';
export type PhoneRemovalResponse = () => void;
