// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { CalculatorIcon, InputNumber } from 'components/_ReactUI_V1';

import {
    StreamValuesBodyWrapper,
    StreamInputLabelWrapper,
    StreamInputWrapper,
    StreamUnitWrapper,
} from './styles';
import { streamCircuitColors } from 'styles/colors';

// Components
import DiagramValueBox from 'components/MimicDiagram/DiagramValueBox';

// Constants
import {
    STREAM_VALUE_TYPES,
    STREAM_VALUE_LIMITS,
    DIAGRAM_DISPLAY_MODES,
    DATASET_MODES,
    VALUE_STATUS,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
    STREAM_VALUES_SIGNIFICANT_FIGURES,
} from 'utils/constants';

// helpers
import { getValueTypeUnit } from 'utils/kpiHelpers';
import { clamp, round } from 'utils/helpers';

// Types
import type { InputEvent, IntlType, LooseNumberType, UnitsConstant } from 'types';
import type { StreamCircuitConstant } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';
import type { DiagramDisplayModesConstant } from 'components/MimicDiagram/types';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    intl: IntlType,
    streamValue: LooseStreamValue,
    streamCircuit: StreamCircuitConstant,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: DatasetModesConstant,
    setStreamValue: SetStreamValueFunction,
    circuitUnits: UnitsConstant,
};
/**
 * Stream Value Input box is the input box for entering numberial stream values for further calculations
 */
class StreamValueInputBox extends React.PureComponent<Props> {
    /**
     * Helper function to update ComputeCircuitContainer state that holds all compute values
     * @param {LooseNumberType} value
     */
    setStreamValueWithValue = (value: ?LooseNumberType) => {
        if (value !== this.props.streamValue.value) {
            this.props.setStreamValue(
                this.props.streamValue.streamId,
                this.props.streamValue.valueType,
                value
            );
        }
    };

    isComputedValue = () => this.props.streamValue.status === VALUE_STATUS.COMPUTE;

    getPrecision = (): number | null => {
        const streamValuePrecision = this.props.streamValue.precision;
        if (streamValuePrecision !== null && streamValuePrecision !== undefined) {
            return streamValuePrecision;
        }
        // $FlowIgnore
        return STREAM_VALUES_SIGNIFICANT_FIGURES[this.props.streamValue.valueType] || null;
    };

    getValue = () => {
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.COMPUTED) {
            // If streamValue has a precision value, use it to round or fallback to STREAM_VALUES_SIGNIFICANT_FIGURES for type

            return round(this.props.streamValue.value || 0, this.getPrecision());
        }
        if (
            this.props.streamValue.value === null ||
            (this.props.displayMode !== DIAGRAM_DISPLAY_MODES.COMPUTED && this.isComputedValue())
        ) {
            return null;
        }
        return this.props.streamValue.value;
    };

    getColors = () => {
        return streamCircuitColors[this.props.streamCircuit];
    };

    /**
     * Get stream values unit according to its value type
     */
    getUnits = () =>
        getValueTypeUnit(
            this.props.streamValue.valueType,
            this.props.circuitUnits,
            this.props.intl
        );

    isMandatory = () => {
        const isMandatory = this.props.streamValue.status === VALUE_STATUS.COMPUTE_USING;
        const shouldShowMandatory =
            isMandatory && this.props.datasetMode === DATASET_MODES.ANALYSIS_MODE;
        return shouldShowMandatory;
    };

    getLabel = () =>
        this.props.intl.formatMessage({
            id: `components.MimicDiagram.StreamValues.${this.props.streamValue.valueType}`,
        });

    getMinValue = () => STREAM_VALUE_LIMITS[this.props.streamValue.valueType].MINIMUM;

    getMaxValue = () => STREAM_VALUE_LIMITS[this.props.streamValue.valueType].MAXIMUM;

    render() {
        const colors = this.getColors();
        return (
            <DiagramValueBox
                label={this.getLabel()}
                units={this.getUnits()}
                precision={this.getPrecision()}
                minValue={this.getMinValue()}
                maxValue={this.getMaxValue()}
                value={this.getValue()}
                setValue={this.setStreamValueWithValue}
                backgroundColor={colors.streamValuesBackground}
                calculatorColor={colors.main}
                hasBeenCalculated={this.props.displayMode === DIAGRAM_DISPLAY_MODES.COMPUTED}
                isCalculated={this.isComputedValue()}
                isMandatory={this.isMandatory()}
            />
        );
    }
}

export default injectIntl(StreamValueInputBox);
