// @flow strict

import type { ReduxDispatch, ResponseErrorType, SearchCriteria } from 'types';
import { createUntranslatedFeedback } from 'services/Feedback/actions';
import { report } from 'services/Errors/resources';

import {
    receivedCreateSuccess,
    receivedCreateFailure,
    receivedFetchListSuccess,
    receivedFetchListFailure,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    receivedDestroySuccess,
    receivedDestroyFailure,
    receivedQueryListSuccess,
    receivedQueryListFailure,
    setIsCreatingStatus,
    setIsDeletingStatus,
    setIsFetchingStatus,
    setIsUpdatingStatus,
    setIsQueryStatus,
    setIsSettingReagentPlantAccessStatus,
    receivedSetReagentPlantAccessSuccess,
    receivedSetReagentPlantAccessFailure,
} from './actions';
import {
    create,
    index,
    update,
    destroy,
    query,
    _setReagentPlantAccess,
} from 'services/Reagent/resources';
import type {
    Reagent,
    ReagentQueryType,
    SetReagentPlantAccessStructure,
} from 'services/Reagent/types';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Create new reagent
 */
export const createReagent = (reagent: Reagent) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCreatingStatus());
    create(reagent)
        .then((response: Reagent) => {
            dispatch(receivedCreateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.createReagentSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.createReagentFailed'));
        });
};

/**
 * Fetch all reagents
 */
export const fetchAllReagents = () => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingStatus());
    index()
        .then((response: Array<Reagent>) => {
            dispatch(receivedFetchListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchAllReagentsFailed'));
        });
};

/**
 * Query (search, pagination, etc) all reagents
 */
export const queryAllReagents = (searchCriteria: SearchCriteria, page: number, perPage: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsQueryStatus());
    query(searchCriteria, page, perPage)
        .then((response: ReagentQueryType) => {
            dispatch(receivedQueryListSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedQueryListFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.queryAllReagentsFailed'));
        });
};

/**
 * Update reagent
 */
export const updateReagent = (id: number, reagent: Reagent) => (dispatch: ReduxDispatch) => {
    dispatch(setIsUpdatingStatus());
    update(id, reagent)
        .then((response: Reagent) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateReagent'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.updateReagentFailed'));
        });
};

/**
 * Destroy reagent
 */
export const destroyReagent = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsDeletingStatus());
    destroy(id)
        .then((response: Reagent) => {
            dispatch(receivedDestroySuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.destroyReagentSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDestroyFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.destroyReagentFailed'));
        });
};

/**
 * Set the list of plants that can access a reagent
 */
export const setReagentPlantAccess = (id: number, data: SetReagentPlantAccessStructure) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsSettingReagentPlantAccessStatus());
    _setReagentPlantAccess(id, data)
        .then((response: Reagent) => {
            dispatch(receivedSetReagentPlantAccessSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.setReagentPlantAccessSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedSetReagentPlantAccessFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.setReagentPlantAccessFailed')
            );
        });
};
