/* eslint-disable flowtype/require-compound-type-alias */
// @flow strict

import type { LanguageCodeConstant } from 'types';
import { DEFAULT_LOCALE } from 'services/Language/actions';

type LooseDate = string | Date;

function validateDate(d: LooseDate) {
    return d && d instanceof Date && !Number.isNaN(d) && d.toString() !== 'Invalid Date';
}

export function parseStringToUTCDate(d: string): Date | null {
    if (!d || typeof d !== 'string') {
        return null;
    }

    // For chrome as it's able to parse date
    if (validateDate(d)) {
        return d;
    }

    // If unable to parse date, use regex to split & rebuild date
    const splitDate = d.split(/[^0-9]/);

    // Creates new UTC Date as we're storing dates as UTC in database
    const newDate = new Date(
        Date.UTC(
            Number(splitDate[0]),
            Number(splitDate[1]) - 1,
            Number(splitDate[2]),
            splitDate[3] ? Number(splitDate[3]) : 0,
            splitDate[4] ? Number(splitDate[4]) : 0,
            splitDate[5] ? Number(splitDate[5]) : 0
        )
    );

    if (validateDate(newDate)) {
        return newDate;
    }

    // Fallback to null to avoid incorrect date
    return null;
}

export function getFormattedDateFromString(
    date: string,
    locale: LanguageCodeConstant,
    dateTimeFormatOptions: {
        weekday?: 'narrow' | 'short' | 'long',
        era?: 'narrow' | 'short' | 'long',
        year?: 'numeric' | '2-digit',
        month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
        day?: 'numeric' | '2-digit',
        hour?: 'numeric' | '2-digit',
        minute?: 'numeric' | '2-digit',
        second?: 'numeric' | '2-digit',
        timeZoneName?: 'short' | 'long',
        timeZone?: string,
        hour12?: boolean,
    }
) {
    const validDate = parseStringToUTCDate(date);

    const l = locale || DEFAULT_LOCALE;
    if (!locale) {
        // TODO: This should throw an error as we should always have a locale provided
        // In some cases when a user is logged out, we loose the value but still call this function
        console.error('Locale not provided to getFormattedDateFromString, using default');
    }

    return validDate && validDate instanceof Date
        ? new Intl.DateTimeFormat(l.toLowerCase(), {
              timeZone: 'UTC',
              ...dateTimeFormatOptions,
          }).format(validDate)
        : date;
}

export function getCurrentTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
