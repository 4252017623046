// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { Plant, PlantQueryType } from 'services/Plant/types';

export const CREATE_SUCCESS = 'app/services/Plant/CREATE_SUCCESS';
export const CREATE_FAILURE = 'app/services/Plant/CREATE_FAILURE';
export const FETCH_LIST_SUCCESS = 'app/services/Plant/FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'app/services/Plant/FETCH_LIST_FAILURE';
export const UPDATE_SUCCESS = 'app/services/Plant/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Plant/UPDATE_FAILURE';
export const DESTROY_SUCCESS = 'app/services/Plant/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'app/services/Plant/DESTROY_FAILURE';
export const QUERY_LIST_SUCCESS = 'app/services/Plants/QUERY_LIST_SUCCESS';
export const QUERY_LIST_FAILURE = 'app/services/Plants/QUERY_LIST_FAILURE';
export const DASHBOARD_PLANTS_LIST_SUCCESS = 'app/services/Plants/DASHBOARD_PLANTS_LIST_SUCCESS';
export const DASHBOARD_PLANTS_LIST_FAILURE = 'app/services/Plants/DASHBOARD_PLANTS_LIST_FAILURE';
export const IS_FETCHING = 'app/services/Plant/IS_FETCHING';
export const IS_UPDATING = 'app/services/Plant/IS_UPDATING';
export const IS_DELETING = 'app/services/Plant/IS_DELETING';
export const IS_CREATING = 'app/services/Plant/IS_CREATING';
export const IS_QUERYING = 'app/services/Plant/IS_QUERYING';
export const IS_QUERYING_DASHBOARD_PLANTS = 'app/services/Plant/IS_QUERYING_DASHBOARD_PLANTS';
export const IS_DOWNLOADING_TEMPLATE = 'app/services/Plant/IS_DOWNLOADING_TEMPLATE';
export const DOWNLOAD_TEMPLATE_SUCCESS = 'app/services/Plant/DOWNLOAD_TEMPLATE_SUCCESS';
export const DOWNLOAD_TEMPLATE_FAILURE = 'app/services/Plant/DOWNLOAD_TEMPLATE_FAILURE';

export const receivedCreateSuccess = (data: Plant) => ({
    type: CREATE_SUCCESS,
    payload: { data },
});

export const receivedCreateFailure = (errors: {}) => ({
    type: CREATE_FAILURE,
    payload: { errors },
});

export const receivedFetchListSuccess = (data: Array<Plant>) => ({
    type: FETCH_LIST_SUCCESS,
    payload: { data },
});

export const receivedFetchListFailure = (errors: {}) => ({
    type: FETCH_LIST_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: Plant) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const receivedDestroySuccess = (data: Plant) => ({
    type: DESTROY_SUCCESS,
    payload: { data },
});

export const receivedDestroyFailure = (errors: {}) => ({
    type: DESTROY_FAILURE,
    payload: { errors },
});

export const receivedQueryListSuccess = (data: PlantQueryType) => ({
    type: QUERY_LIST_SUCCESS,
    payload: { data },
});

export const receivedDashboardPlantsListSuccess = (data: PlantQueryType) => ({
    type: DASHBOARD_PLANTS_LIST_SUCCESS,
    payload: { data },
});

export const receivedQueryListFailure = (errors: {}) => ({
    type: QUERY_LIST_FAILURE,
    payload: { errors },
});

export const receivedDashboardPlantsListFailure = (errors: {}) => ({
    type: DASHBOARD_PLANTS_LIST_FAILURE,
    payload: { errors },
});

export const setIsFetchingStatus = (isFetching: boolean = true) => ({
    type: IS_FETCHING,
    payload: { isFetching },
});

export const setIsUpdatingStatus = (isUpdating: boolean = true) => ({
    type: IS_UPDATING,
    payload: { isUpdating },
});

export const setIsDeletingStatus = (isDeleting: boolean = true) => ({
    type: IS_DELETING,
    payload: { isDeleting },
});

export const setIsCreatingStatus = (isCreating: boolean = true) => ({
    type: IS_CREATING,
    payload: { isCreating },
});

export const setIsQueryStatus = (isQuerying: boolean = true) => ({
    type: IS_QUERYING,
    payload: { isQuerying },
});

export const setIsDashboardPlantsStatus = (isQueryingDashboardPlants: boolean = true) => ({
    type: IS_QUERYING_DASHBOARD_PLANTS,
    payload: { isQueryingDashboardPlants },
});

export const setIsDownloadingTemplateStatus = (isDownloadingTemplate: boolean = true) => ({
    type: IS_DOWNLOADING_TEMPLATE,
    payload: { isDownloadingTemplate },
});

export const receivedDownloadTemplateSuccess = (fileName: string, blob: Blob) => ({
    type: DOWNLOAD_TEMPLATE_SUCCESS,
    payload: { fileName, blob },
});

export const receivedDownloadTemplateFailure = (errors: {}) => ({
    type: DOWNLOAD_TEMPLATE_FAILURE,
    payload: { errors },
});
