// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import ScenarioSidebar from 'components/ScenarioSidebar';

// Styles
import { SidebarWrapper, Instructions } from './styles';

// Selectors
import { selectAllKPISettings } from 'services/KPISetting/selectors';
import {
    selectScenarioResults,
    selectScenarioIsSubmitting,
    selectErrors,
} from 'services/Dataset/selectors';

// Thunks
import { submitScenario, clearScenario } from 'services/Dataset/thunks';

// Types
import type { ReduxDispatch, ImmutableList, IntlType } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ScenarioKpi, ImmutableScenarioResults } from 'services/Dataset/types';
import type { ImmutableTrendData } from 'services/Trends/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutableError } from 'services/Errors/types';

type InjectedProps = {
    intl: IntlType,

    kpiSettings: ImmutableList<ImmutableKPISetting>,
    scenarioResults: ImmutableScenarioResults | null,
    errors: ImmutableError | null,
    isSubmittingScenario: boolean,
};

type Props = InjectedProps & {
    trend: ImmutableTrendData,
    isLoadingTrend: boolean,

    submitScenario: (circuitId: number, datasetId: number, kpis: Array<ScenarioKpi>) => void,
    clearScenario: () => void,

    circuitId: number,
    circuit: ImmutableCircuit,
    isLoadingDataset: boolean,
};

class ScenarioContainer extends React.PureComponent<Props> {
    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `containers.ScenarioContainer.${id}`,
            },
            data
        );

    getCircuitKPISettings = () =>
        this.props.kpiSettings.filter(
            (kpi: ImmutableKPISetting) => kpi.get('circuitId') === this.props.circuitId
        );

    handleRunScenario = (kpis: Array<ScenarioKpi>) =>
        this.props.submitScenario(this.props.circuitId, this.props.trend.get('datasetId'), kpis);

    render() {
        return (
            <SidebarWrapper>
                <ScenarioSidebar
                    circuit={this.props.circuit}
                    kpiSettings={this.getCircuitKPISettings()}
                    trend={this.props.trend}
                    isLoadingData={this.props.isLoadingDataset || this.props.isLoadingTrend}
                    submittingScenario={this.props.isSubmittingScenario}
                    runScenario={this.handleRunScenario}
                    clearScenarioResults={this.props.clearScenario}
                    scenarioResults={this.props.scenarioResults}
                    errors={this.props.errors}
                />
            </SidebarWrapper>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        kpiSettings: selectAllKPISettings(),
        scenarioResults: selectScenarioResults(),
        isSubmittingScenario: selectScenarioIsSubmitting(),
        errors: selectErrors(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            submitScenario,
            clearScenario,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ScenarioContainer));
