// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableAuthenticationState, ImmutableUser } from 'services/Authentication/types';

/**
 * Direct selector to the auth state domain
 *
 * TODO: Replace Object type for proper Redux Store Type
 */
export const selectAuthState = () => (state: Object) => state.get('auth');

/**
 * Select the auth state
 */

export const selectUser = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('user')
    );

export const selectUserEmail = () =>
    createSelector(
        selectUser(),
        (substate: ImmutableAuthenticationState) => substate && substate.get('email')
    );

export const selectUserPhoneNumber = () =>
    createSelector(
        selectUser(),
        (substate: ImmutableAuthenticationState) => substate && substate.get('phoneNumber')
    );

export const selectUserIsFetching = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('userIsFetching')
    );

export const selectAcceptDisclaimerSaving = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('acceptDisclaimerSaving')
    );

export const selectUnacceptedDisclaimers = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('unacceptedDisclaimers')
    );

export const selectUnacceptedDisclaimersIsFetching = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('unacceptedDisclaimersIsFetching')
    );

export const selectPhoneNumber = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('phoneNumber')
    );

export const selectPhoneNumberIsFetching = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('phoneNumberIsFetching')
    );

export const selectIsGettingMinchemPreferences = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('isGettingMinchemPreferences')
    );

export const selectMinchemPreferencesIsUpdating = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('isUpdatingMinchemPreferences')
    );

export const selectUserPreferencesIsUpdating = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('isUpdatingUserPreferences')
    );

export const selectErrors = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('errors')
    );

export const selectPhoneVerificationRequestId = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('phoneVerificationRequestId')
    );

export const selectPhoneVerificationIsLoading = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('phoneVerificationIsLoading')
    );

export const selectPhoneVerificationErrors = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('phoneVerificationErrors')
    );
