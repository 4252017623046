// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { ShowForDesktopOnly, ShowForMobileOnly } from 'styles/responsive';
import PlantCard from 'components/DashboardPlantsCards/PlantCard';
import { Loader } from 'components/_ReactUI_V1';

// Styles
import { LoaderWrapper } from './styles';

// Types
import type { ImmutableList, SearchCriteria } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    plants?: ImmutableList<ImmutablePlant>,
    isFetchingPlants: boolean,
    user: ImmutableUser,
};

class PlantsCards extends React.Component<Props> {
    render() {
        if (this.props.isFetchingPlants) {
            return (
                <LoaderWrapper>
                    <Loader loading />
                </LoaderWrapper>
            );
        }

        return (
            <React.Fragment>
                {this.props.plants.map((plant) => (
                    <PlantCard key={plant.get('id')} plant={plant} user={this.props.user} />
                ))}
            </React.Fragment>
        );
    }
}

export default injectIntl(PlantsCards);
