// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Constants
import { LegacyTheme, InputNumber, RadioButtonSet, PrimaryButton } from 'components/_ReactUI_V1';

import {
    SENSITIVITY_OPTIONS,
    SENSITIVITY_OPTION_LIMITS,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
} from 'utils/constants';

// Helpers
import { clamp } from 'utils/helpers';

// Styles
import { SidebarBody, BodySection, FooterSection } from '../styles';
import {
    RangeWrapper,
    RangeTitle,
    RangeInputWrapper,
    RangeInput,
    VariableBodySection,
    VariableTitle,
} from './styles';

// Components

// Types
import type { IntlType, InputEvent } from 'types';
import type {
    SensitivityOptionConstant,
    SensitivityOptionsAvailability,
} from 'services/Dataset/types';

type Props = {
    intl: IntlType,

    loading: boolean,

    handleGenerateDiagramClicked: (
        sensitivityOptionConstant: SensitivityOptionConstant,
        startX: ?number,
        finishX: ?number
    ) => void,
    sensitivityOptionAvailability: SensitivityOptionsAvailability,
};

type State = {
    selectedVariable: ?SensitivityOptionConstant,
    startX: ?number,
    finishX: ?number,
};

const INPUT_STYLES = {
    ...STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES,
    marginRight: '0px',
};

/**
 * The sidebar body section for the 2D sensitivity plot.
 */
class TwoDSensitivitySidebarSection extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedVariable: null,
            startX: null,
            finishX: null,
        };
    }

    /**
     * Gets the radio button options for the variable to vary
     * depending their availability
     */
    getVariableRadioOptions = () =>
        Object.keys(SENSITIVITY_OPTIONS)
            .filter((optionKey: string) =>
                Object.keys(this.props.sensitivityOptionAvailability).indexOf(optionKey) !== 1
                    ? this.props.sensitivityOptionAvailability[optionKey]
                    : true
            )
            .map((value: string) => ({
                value,
                label: this.props.intl.formatMessage({
                    id: `constants.SensitivityOptions.${value}`,
                }),
            }));

    getVariableMin = () =>
        this.state.selectedVariable &&
        SENSITIVITY_OPTION_LIMITS[this.state.selectedVariable].MINIMUM;

    getVariableMax = () =>
        this.state.selectedVariable &&
        SENSITIVITY_OPTION_LIMITS[this.state.selectedVariable].MAXIMUM;

    getVariableDefaultMin = () =>
        this.state.selectedVariable &&
        SENSITIVITY_OPTION_LIMITS[this.state.selectedVariable].DEFAULT_MINIMUM;

    getVariableDefaultMax = () =>
        this.state.selectedVariable &&
        SENSITIVITY_OPTION_LIMITS[this.state.selectedVariable].DEFAULT_MAXIMUM;

    isRangeOkay = () => {
        if (!this.state.selectedVariable) {
            return false;
        }

        // Make sure that if the user has specified both, that the startX is less than the finishX.
        if (this.state.startX !== null && this.state.finishX !== null) {
            if (this.state.startX >= this.state.finishX) {
                return false;
            }
        }

        const min = this.getVariableMin();
        const max = this.getVariableMax();

        if (this.state.startX !== null && min !== null) {
            return this.state.startX >= min;
        }
        if (this.state.finishX !== null && max !== null) {
            return this.state.finishX <= max;
        }
        return true;
    };

    /**
     * Gets whether the generate button is enabled.
     */
    isGenerateButtonEnabled = () =>
        !this.props.loading && Boolean(this.state.selectedVariable) && this.isRangeOkay();

    /**
     * Handle radio button change
     */
    handleVariableSelected = (selectedVariable: SensitivityOptionConstant) =>
        this.setState({
            selectedVariable,
            startX: SENSITIVITY_OPTION_LIMITS[selectedVariable].DEFAULT_MINIMUM,
            finishX: SENSITIVITY_OPTION_LIMITS[selectedVariable].DEFAULT_MAXIMUM,
        });

    /**
     * Handle the change in range start or finish.
     */
    handleRangeChange = (stateKey: 'startX' | 'finishX') => (event: InputEvent) => {
        if (event.target.value === '') {
            // the pressed backspace.
            this.setState({ [stateKey]: null });
            return;
        }
        this.setState({ [stateKey]: event.target.value });
    };

    handleOnBlur = (stateKey: 'startX' | 'finishX') => (event: InputEvent) => {
        if (event.target.value === '') {
            // the pressed backspace.
            this.setState({ [stateKey]: null });
            return;
        }
        // "Clasp" the input value between the min and max of the variable.
        const value = clamp(
            Number(event.target.value),
            this.getVariableMin(),
            this.getVariableMax()
        );
        this.setState({ [stateKey]: value });
    };

    /**
     * Generate the diagram.
     */
    handleGenerateDiagramClicked = () =>
        this.isGenerateButtonEnabled() &&
        this.props.handleGenerateDiagramClicked(
            this.state.selectedVariable,
            this.state.startX,
            this.state.finishX
        );

    render() {
        return (
            <SidebarBody>
                <BodySection>
                    <VariableBodySection>
                        <VariableTitle>
                            <span>
                                {this.props.intl.formatMessage({
                                    id: `components.CircuitComputationSidebar.2DSensitivity.variable.title`,
                                })}
                            </span>
                        </VariableTitle>
                        <RadioButtonSet
                            value={this.state.selectedVariable}
                            options={this.getVariableRadioOptions()}
                            onClick={this.handleVariableSelected}
                            disabled={this.props.loading}
                            styles={{
                                labelColor: LegacyTheme.defaultColor,
                                labelFontSize: '13px',
                            }}
                        />
                        <RangeWrapper>
                            <RangeTitle>
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: `components.CircuitComputationSidebar.2DSensitivity.range.title`,
                                    })}
                                </span>
                            </RangeTitle>
                            <RangeInputWrapper>
                                <RangeInput>
                                    <span>
                                        {this.props.intl.formatMessage({
                                            id: `components.CircuitComputationSidebar.2DSensitivity.range.startLabel`,
                                        })}
                                    </span>
                                    <InputNumber
                                        handleOnChange={this.handleRangeChange('startX')}
                                        onBlur={this.handleOnBlur('startX')}
                                        value={this.state.startX || ''}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        textAlign="center"
                                        disabled={this.props.loading}
                                        min={this.getVariableMin()}
                                        max={this.getVariableMax()}
                                        style={INPUT_STYLES}
                                    />
                                </RangeInput>
                                <RangeInput>
                                    <span>
                                        {this.props.intl.formatMessage({
                                            id: `components.CircuitComputationSidebar.2DSensitivity.range.endLabel`,
                                        })}
                                    </span>
                                    <InputNumber
                                        handleOnChange={this.handleRangeChange('finishX')}
                                        onBlur={this.handleOnBlur('finishX')}
                                        value={this.state.finishX || ''}
                                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                                        textAlign="center"
                                        disabled={this.props.loading}
                                        min={this.getVariableMin()}
                                        max={this.getVariableMax()}
                                        style={INPUT_STYLES}
                                    />
                                </RangeInput>
                            </RangeInputWrapper>
                        </RangeWrapper>
                    </VariableBodySection>
                </BodySection>
                <FooterSection withMargin>
                    <PrimaryButton
                        text={this.props.intl.formatMessage({
                            id: `components.CircuitComputationSidebar.2DSensitivity.generatePlotButton`,
                        })}
                        loading={this.props.loading}
                        disabled={!this.isGenerateButtonEnabled() || this.props.loading}
                        onClick={this.handleGenerateDiagramClicked}
                    />
                </FooterSection>
            </SidebarBody>
        );
    }
}

export default injectIntl(TwoDSensitivitySidebarSection);
