// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header } from './styles';

// Components
import { Title } from 'styles/common';
import { Common, PrimaryButton, Pagination } from 'components/_ReactUI_V1';
import MinChemCircuitTable from 'components/CircuitTables/MinChemCircuitTable';

// Types
import type { IntlType, ImmutableList, SearchCriteria } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { OpenModalFunction } from 'containers/DashboardContainer';

type Props = {
    intl: IntlType,
    circuits: ImmutableList<ImmutableCircuit>,
    page: number,
    lastPage: number,
    userLocale: string,

    isFetchingCircuits: boolean,

    onOpenModal: OpenModalFunction,
    onHandleCircuitsSortBy: (structure: ?SearchCriteria) => void,
    onHandleCircuitChangePage: (page: number) => void,
};

class MinChemDashboard extends React.PureComponent<Props> {
    handleCreateClicked = () => this.props.onOpenModal('CREATE_CIRCUIT', null);

    render() {
        return (
            // Reset flexbox to avoid collapse on iOS by setting a non-flex block element
            <div>
                <Wrapper>
                    <Header>
                        <Common.Column flex={1} justifyContent="center" alignItems="flex-start">
                            <Title>
                                {this.props.intl.formatMessage({
                                    id: 'components.MinChemDashboard.title',
                                })}
                            </Title>
                        </Common.Column>
                        <Common.Column flex={1} justifyContent="center" alignItems="flex-end">
                            <PrimaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.MinChemDashboard.createNewButton',
                                })}
                                onClick={this.handleCreateClicked}
                            />
                        </Common.Column>
                    </Header>
                    <MinChemCircuitTable
                        circuits={this.props.circuits}
                        isFetching={this.props.isFetchingCircuits}
                        userLocale={this.props.userLocale}
                        onSortBy={this.props.onHandleCircuitsSortBy}
                        onOpenModal={this.props.onOpenModal}
                    />
                    <Pagination
                        currentPage={this.props.page}
                        isLoading={this.props.isFetchingCircuits}
                        onPageSelection={this.props.onHandleCircuitChangePage}
                        pagesTotal={this.props.lastPage}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryJoiner',
                        })}
                    />
                </Wrapper>
            </div>
        );
    }
}

export default injectIntl(MinChemDashboard);
