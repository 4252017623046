// @flow

import React from 'react';

// Components
import { Slider as SliderBase, Handles } from 'react-compound-slider';
import { Handle } from './sliderComponents';

// Styles
import {
    SliderContainer,
    SliderWrapper,
    SliderStartWrapper,
    SliderEndWrapper,
    SliderStart,
    SliderEnd,
    SliderRail,
} from './styles';

const sliderStyle = {
    position: 'relative',
    width: '100%',
};

type Props = {
    disabled?: boolean,
    max?: number,
    min?: number,
    onChangeHandler?: () => void,
    onSlideEndHandler?: () => void,
    value?: number,
};

type State = {
    values: Array<number>,
};

export default class Slider extends React.Component<Props, State> {
    static defaultProps = {
        disabled: false,
        max: 100,
        min: 0,
        onChangeHandler: null,
        onSlideEndHandler: null,
        value: 0,
    };

    state = {
        values: [this.props.value],
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.value !== prevProps.value) {
            /* eslint-disable-next-line react/no-did-update-set-state */
            this.setState({ values: [this.props.value] });
        }
    }

    handleChange = (values: Array<number>) => {
        if (this.props.onChangeHandler) {
            this.setState({ values });
            this.props.onChangeHandler(this.state.values[0]);
        }
    };

    handleSlideEnd = (values: Array<number>) => {
        if (this.props.onSlideEndHandler) {
            this.setState({ values });
            this.props.onSlideEndHandler(this.state.values[0]);
        }
    };

    onGetDisabledHandleProps = () => ({});

    render() {
        const { values } = this.state;

        const disabled = this.props.disabled;

        const max = this.props.max < 100 && (
            <SliderEndWrapper width={100 - this.props.max}>
                {this.props.max}
                <SliderEnd />
            </SliderEndWrapper>
        );

        const min = this.props.min > 0 && (
            <SliderStartWrapper width={this.props.min}>
                <SliderStart />
                {this.props.min}
            </SliderStartWrapper>
        );

        return (
            <SliderContainer>
                {min}
                <SliderWrapper disabled={disabled} width={this.props.max - this.props.min}>
                    <SliderBase
                        mode={1}
                        step={1}
                        domain={[this.props.min, this.props.max]}
                        rootStyle={sliderStyle}
                        onUpdate={this.handleUpdate}
                        onChange={this.handleChange}
                        onSlideEnd={this.handleSlideEnd}
                        values={values}
                    >
                        <SliderRail />
                        <Handles>
                            {({ handles, getHandleProps }: Object) => (
                                <div className={`slider-handles${disabled ? ' disabled' : ''}`}>
                                    {handles.map((handle: Object) => (
                                        <Handle
                                            key={handle.id}
                                            handle={handle}
                                            domain={[this.props.min, this.props.max]}
                                            getHandleProps={
                                                disabled
                                                    ? this.onGetDisabledHandleProps
                                                    : getHandleProps
                                            }
                                            disabled={disabled}
                                        />
                                    ))}
                                </div>
                            )}
                        </Handles>
                    </SliderBase>
                </SliderWrapper>
                {max}
            </SliderContainer>
        );
    }
}
