// @flow strict

import styled from 'styled-components';
import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SelectUnitWrapper = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        flex-basis: 50%;
    }

    > span {
        font-size: 12px;
        color: ${LegacyTheme.defaultColor};
        display: flex;
        align-items: center;
    }
`;

export const CheckBoxBody = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 35px;
`;

export const AddTankButtonStyles = {
    width: '50%',
    alignSelf: 'flex-end',
    marginTop: '5px',
};

export const CheckboxAndRadioStyles = {
    fontSize: '13px',
    color: LegacyTheme.defaultColor,
    marginTop: '5px',
};

export const InputStyles = {
    valueContainerFontSize: '12px',
};
