// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Services
import { selectIsGettingMinchemPreferences } from 'services/Authentication/selectors';
import { getMinchemPreferences } from 'services/Authentication/thunks';
import { selectUser } from 'services/Authentication/selectors';

// Types
import type { ChildrenType } from 'types';
import type { ImmutableUser, MinchemPreferences } from 'services/Authentication/types';

type Props = {
    children: ChildrenType,

    user: ImmutableUser,
    isGettingMinchemPreferences: boolean,
    getMinchemPreferences: (id: number) => void,
};

type State = {};

/**
 * The MinchemPreferencesFetcher is the top-level container for the Minchem application pages.
 * It is responsible for fetching the user's Minchem preferences on mount and rendering the children components.
 * It should be used to wrap Minchem-specific pages.
 */
class MinchemPreferencesFetcher extends React.PureComponent<Props> {
    componentDidMount() {
        if (!this.props.user) {
            throw new Error('No user supplied to MinchemPreferencesFetcher');
        }

        // only fetch MinChem preferences if they haven't been fetched yet
        if (!this.props.user.getIn[('preferences', 'minchem')]) {
            this.props.getMinchemPreferences(this.props.user.get('id'));
        }
    }

    render() {
        return this.props.children;
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
    isGettingMinchemPreferences: selectIsGettingMinchemPreferences(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            getMinchemPreferences,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MinchemPreferencesFetcher);
