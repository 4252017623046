// @flow strict

import React, { ChildrenArray } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Styles
import { ContainerCentered, DetailsErrorBlock } from 'styles/common';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
    children: ChildrenArray,
};

type State = {
    error: ?any,
    errorInfo: ?any,
};

/**
 * An error boundary is a component that catches error in the React DOM while rendering.
 * Instead of crashing the entire app, the error boundary's componentDidCatch is triggered and
 * the error boundary's render method is called instead of the crashed component.
 * Multiple error boundaries can be placed within the app to create an error handling heirarchy.
 */
class GlobalErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
        console.error(error, errorInfo);
    }

    /**
     * Reset the state of errors when a user navigates back to the home page.
     */
    handleResetState = () =>
        this.setState({
            error: null,
            errorInfo: null,
        });

    render() {
        // Do we have an error?
        if (this.state.errorInfo) {
            // Display the default error screen.
            return (
                <ContainerCentered>
                    <div style={{ maxWidth: '90%' }}>
                        <h4>
                            {this.props.intl.formatMessage({
                                id: 'components.ErrorBoundary.title',
                            })}
                        </h4>
                        {this.state.errorInfo && (
                            <DetailsErrorBlock>
                                {this.state.error && this.state.error.toString()}
                                <br />
                                {this.state.errorInfo.componentStack}
                            </DetailsErrorBlock>
                        )}
                        <Link to="/" onClick={this.handleResetState}>
                            {this.props.intl.formatMessage({
                                id: 'components.ErrorBoundary.returnToDashboard',
                            })}
                        </Link>
                    </div>
                </ContainerCentered>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default injectIntl(GlobalErrorBoundary);
