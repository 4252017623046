// @flow strict

import React from 'react';
import type { Record } from 'immutable';
import { fromJS } from 'immutable';
import { debounce } from 'lodash';

// Components
import { InputSelect } from 'components/_ReactUI_V1';

// Constants
import { STYLE_VALUES } from 'utils/constants';

// Styles
import { FormBlock, FormLabel } from 'styles/common';

// Types
import type {
    IntlType,
    ReactSelectObject,
    ImmutableList,
    QueryStructure,
    ImmutableQueryStructure,
} from 'types';

const DEFAULT_SEARCH_DEBOUNCE_TIME = 500;
const DEFAULT_DATA_ID_KEY = 'id';
const DEFAULT_DATA_LABEL_KEY = 'name';
const SEARCH_INPUT_ACTION_TYPE = 'input-change'; // comes from the select library we use in React ui.
const DEFAULT_PAGE = 1;

type OptionalProps<ItemType> = {
    // initialOptionId used to determine the first selected option.
    // If you want to update this value, change the `key` of the `SelectWithInfiniteScroll` component
    // in the parent to force a recreation within React.
    initialOptionId: ?number,

    // Optional Config:
    additionalSearchCriteria: Object, // any additional criteria to send while searching
    searchDebounceTime: number, // the min amount of idle time before doing a search, in Milliseconds
    dataIdKey: string, // the key on the entity representing its unique id. Usually "id"
    dataLabelKey: string, // could be the entity's "name"
    filterSelect: (item: ItemType) => boolean,
};
type Props<ItemType> = OptionalProps<ItemType> & {
    isLoading: boolean,
    placeholder: string,
    noOptionsMessage: string,

    onSelect: (item: ItemType) => ?boolean, // return true to clear the selection.

    queryData: ?ImmutableQueryStructure<ItemType>,
    queryPage: (searchCriteria: Object, page?: number, perPage?: number) => void,
};

type State = {
    selectedOptionId: ?number,
    searchValue: string,
    currentPage: number,
};

class SelectWithInfiniteScroll<ItemType: Record> extends React.PureComponent<
    Props<ItemType>,
    State
> {
    static defaultProps: OptionalProps<ItemType> = {
        filterSelect: (_: ItemType) => true,
        initialOptionId: null,
        additionalSearchCriteria: {},
        searchDebounceTime: DEFAULT_SEARCH_DEBOUNCE_TIME,
        dataIdKey: DEFAULT_DATA_ID_KEY,
        dataLabelKey: DEFAULT_DATA_LABEL_KEY,
    };

    selectComponentRef = React.createRef();

    state = {
        searchValue: '',
        selectedOptionId: this.props.initialOptionId,
        currentPage: 0,
    };

    componentDidMount() {
        this.queryNextPage(); // will always query the first page.
    }

    /**
     * We  need to check if the page is done loading,
     * if we are, then scroll to the top of the InputSelect menu list
     */
    componentDidUpdate(prevProps: Props<ItemType>) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.resetCurrentPageWithData();
            this.scrollListToTop();
        }
    }

    /**
     * Resets our local state with the redux state of the current page.
     */
    resetCurrentPageWithData = () => {
        this.setState({
            // ensure we're never out of sync with the data:
            currentPage: this.props.queryData
                ? this.props.queryData.get('currentPage', DEFAULT_PAGE)
                : DEFAULT_PAGE,
        });
    };

    /**
     * Scrolls the opened list to the top
     */
    scrollListToTop = () => {
        if (
            this.selectComponentRef &&
            this.selectComponentRef.current &&
            this.selectComponentRef.current.select &&
            this.selectComponentRef.current.select.menuListRef
        ) {
            this.selectComponentRef.current.select.menuListRef.scrollTop = 0;
        }
    };

    /**
     * Clears the selected option id from the dropdown box.
     */
    clearSelection = () => {
        this.setState({
            selectedOptionId: null,
        });
    };

    /**
     * Get whether there are options left in the list.
     * @returns boolean
     */
    hasOptionsLeft = () => this.getOptions().size > 0;

    /**
     * When the user searches.
     * Note this function is debounced to ensure users can type multiple letters before searching occurs
     * Each time a request is made, the input fields are disabled therefore this would become super annoying without the debouce
     */
    triggerSearch = debounce(() => {
        this.props.queryPage({
            ...this.props.additionalSearchCriteria,
            search: this.state.searchValue,
        });
    }, this.props.searchDebounceTime);

    /**
     * Queries the next page of data for the current search values, and page.
     */
    queryNextPage = () =>
        this.setState(
            (prevState: State) => ({
                currentPage: (prevState.currentPage += 1),
            }),
            () => {
                this.props.queryPage(
                    {
                        ...this.props.additionalSearchCriteria,
                        search: this.state.searchValue,
                    },
                    this.state.currentPage
                );
            }
        );

    /**
     * Get an item as a ReactSelect item.
     * @param {ItemType} item An item in the data list object
     * @returns A ReactSelectObject for the UI library
     */
    getOptionToReactSelectItem = (item: ItemType): ReactSelectObject => ({
        value: item.get(this.props.dataIdKey),
        label: item.get(this.props.dataLabelKey),
    });

    getSelectedOptionRSO = (): ?ReactSelectObject => {
        const selectedOption = this.getSelectedItem();
        if (!selectedOption) {
            return null;
        }
        return this.getOptionToReactSelectItem(selectedOption);
    };

    getSelectedItem = (): ?ItemType => {
        if (!this.state.selectedOptionId || !this.props.queryData) {
            return null;
        }
        const selectedOption: ItemType = this.props.queryData
            .get('data', [])
            .find((item: ItemType) => item.get('id') === this.state.selectedOptionId);
        return selectedOption;
    };

    /**
     * Get all the user options for the dropdown
     */
    getOptions = () =>
        this.props.queryData
            ? this.props.queryData
                  .get('data', [])
                  .filter(this.props.filterSelect)
                  .map(this.getOptionToReactSelectItem)
            : fromJS([]);

    /**
     * Prevent search/clearing user search query on blur
     */
    handleSelectBlur = () => null; // purposefully null.

    /**
     * When the user scrolls to the bottom of the select menu we should query the next page
     */
    handleScrollToBottom = () => this.queryNextPage();

    /**
     * When the user types in the input search box.
     * @param {*} text the search text
     * @param {*} action the action that was performed on the InputSelect. This callback is called for multiple reasons
     */
    handleSearchChanged = (text: string, action: any) => {
        if (action.action !== SEARCH_INPUT_ACTION_TYPE) {
            // this comes from selecting a user from the drop down.
            // Do not consider this a change in search state, the handleSelectUser will handle it.
            return;
        }
        if (this.state.searchValue === text) {
            // If there is no change in the search value, don't search again.
            return;
        }
        this.setState(
            {
                searchValue: text,
                currentPage: DEFAULT_PAGE,
            },
            this.triggerSearch
        );
    };

    /**
     * When an option is selected from the dropdown.
     * Also clears any search value
     */
    handleSelectUser = (rso: ReactSelectObject) => {
        this.setState(
            {
                selectedOptionId: Number(rso.value),
                searchValue: '',
            },
            () => {
                if (!this.state.selectedOptionId) {
                    return;
                }
                const selectedItem = this.getSelectedItem();
                if (!selectedItem) {
                    return;
                }
                const clearState = this.props.onSelect(selectedItem);
                if (clearState) {
                    this.setState({
                        selectedOptionId: null,
                    });
                }
            }
        );
    };

    render() {
        return (
            <InputSelect
                innerSelectRef={this.selectComponentRef}
                selectedOption={this.getSelectedOptionRSO()}
                options={this.getOptions()}
                onSelect={this.handleSelectUser}
                onSearch={this.handleSearchChanged}
                onScrollToBottom={this.handleScrollToBottom}
                onBlur={this.handleSelectBlur}
                placeholder={this.props.placeholder}
                noOptionsMessage={this.props.noOptionsMessage}
                isDisabled={this.props.isLoading}
                isLoading={this.props.isLoading}
                maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.MEDIUM}
                style={{
                    flexShrink: 1,
                    width: 'unset',
                }}
                controlShouldRenderValue
            />
        );
    }
}

export default SelectWithInfiniteScroll;
