// @flow strict

import styled from 'styled-components';

import { SVG_LAYER_Z_INDEX } from 'components/MimicDiagram/styles';

import { colors } from 'styles/colors';

export const DiagramEntityWrapper = styled.div`
    grid-column-start: ${({ startingColumn }) => startingColumn};
    grid-column-end: span ${({ columnSpan }) => columnSpan};
    grid-row-start: ${({ startingRow }) => startingRow};
    grid-row-end: span ${({ rowSpan }) => rowSpan};

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    overflow: hidden;
    position: relative;

    ${({ borderColor }) =>
        borderColor &&
        `
        border: 1px solid ${borderColor};
        border-radius: 4px;
    `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        `
        background-color: ${backgroundColor};
    `}

    z-index: ${SVG_LAYER_Z_INDEX + 2}; // must be > SVG layer for click.
    &:hover {
        ${({ onClick }) =>
            onClick !== null &&
            `
            cursor: pointer;
            background-color: ${colors.greyBB};
        `}
    }
    transition: background-color ease 0.25s;
`;

export const HighLightOnHover = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #00000000;

    :hover {
        background-color: #00000011;
        z-index: 1;
    }

    transition: background-color ease 0.25s;
`;
