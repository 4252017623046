// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Mimic Engine
import type { IMimicCascade } from 'components/_McCabeThiele';

// Components
import { PrimaryButton, SecondaryButton, Modal, CheckBox } from 'components/_ReactUI_V1';

import { Title } from 'styles/common';
import CascadeDescription from 'components/CascadeDescription';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    WrapperCheckboxTextInput,
    InputNameField,
    CheckBoxStyle,
    NameWrapper,
} from './styles';

// Types
import type { IntlType, InputEvent } from 'types';
import type { ImmutableDataset } from 'services/Dataset/types';
import { validateIsothermName } from 'services/Isotherm/helpers';
import { ISOTHERM_NAME_MAX_LENGTH } from 'services/Isotherm/types';

// Constants
import { MODAL_WIDTH } from 'utils/constants';

type CascadeObject = {
    cascade: IMimicCascade,
    name: string,
    checked: boolean,
};

type Props = {
    intl: IntlType,
    // errors: ErrorType,
    loading: boolean,
    saveAs?: boolean,

    dataset: ?ImmutableDataset,
    cascades: Array<IMimicCascade>,

    onConfirm: (saveDataset: boolean, datasetName: string, cascades: Array<IMimicCascade>) => void,
    onCancel: () => void,
};

type State = {
    datasetChecked: boolean,
    datasetName: string,
    isothermsDisplayed: Array<CascadeObject>,
};

/**
 * Shown when the save Button is clicked
 */
class DatasetSaveModal extends React.PureComponent<Props, State> {
    static defaultProps = {
        saveAs: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            datasetChecked: true,
            datasetName:
                !this.props.saveAs && this.props.dataset ? this.props.dataset.get('name') : '',
            isothermsDisplayed: props.cascades.map((cascade: IMimicCascade) => ({
                cascade,
                name: '',
                checked: false,
            })),
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.dataset && this.props.dataset !== prevProps.dataset) {
            this.setState({
                datasetName: this.props.dataset.get('name'),
            });
        }
    }

    getTranslation = (key: string) =>
        this.props.intl.formatMessage({ id: `components.Modals.DatasetSaveModal.${key}` });

    /**
     * If the dataset name is checked, check that the name is not empty.
     * @returns boolean
     */
    isDatasetNameValid = () => (this.state.datasetChecked ? Boolean(this.state.datasetName) : true);

    /**
     * Loops through the checked isotherms, and validates that the isotherm name is not empty
     * @returns boolean
     */
    areAllIsothermsNamesValid = () => {
        const savableIsotherms = this.getSaveableIsotherms();
        if (savableIsotherms.length === 0) {
            return true;
        }

        return savableIsotherms.every((selectedCascade: CascadeObject) =>
            Boolean(selectedCascade.name)
        );
    };

    /**
     * Isotherm cascades that are checked in local state
     * @returns the list of isotherms that the user wants to save
     */
    getSaveableIsotherms = () =>
        this.state.isothermsDisplayed.filter((stateCascade: CascadeObject) => stateCascade.checked);

    /**
     * Get whether the save button is enabled or not
     */
    isSaveButtonEnabled = () => {
        const datasetIsValid = this.isDatasetNameValid();
        const isothermsAreValid = this.areAllIsothermsNamesValid();

        const hasAtLeastOne = this.state.datasetChecked || this.getSaveableIsotherms().length > 0;

        return hasAtLeastOne && isothermsAreValid && datasetIsValid;
    };

    /**
     * Get whether the isotherm passed as argument is selected in the state.
     */
    isCascadeSelected = (cascade: IMimicCascade): boolean =>
        Boolean(
            this.state.isothermsDisplayed.find((stateCascade: CascadeObject) => {
                // TODO: the inequality occurs in cascades.stages not sure why
                return (
                    cascade.isotherm === stateCascade.cascade.isotherm &&
                    cascade.location === stateCascade.cascade.location &&
                    cascade.type === stateCascade.cascade.type &&
                    stateCascade.checked
                );
            })
        );

    /**
     * Get the isotherm name from the isotherm object in the state
     */
    getIsothermName = (cascade: IMimicCascade) => {
        const stateIsotherm = this.state.isothermsDisplayed.find((stateCascade: CascadeObject) => {
            // TODO: the inequality occurs in cascades.stages not sure why
            return (
                cascade.isotherm === stateCascade.cascade.isotherm &&
                cascade.location === stateCascade.cascade.location &&
                cascade.type === stateCascade.cascade.type
            );
        });

        return stateIsotherm ? stateIsotherm.name : '';
    };

    /**
     * When the save button is clicked, call our parent with the options selected
     */
    handleSaveButtonClicked = () => {
        return this.props.onConfirm(
            this.state.datasetChecked,
            this.state.datasetName,
            this.state.isothermsDisplayed
                .filter((cascade: CascadeObject) => cascade.checked)
                .map((cascade: CascadeObject) => ({
                    ...cascade.cascade,
                    name: cascade.name,
                }))
        );
    };

    /**
     * When the dataset checkbox is checked
     */
    handleDatasetChecked = (event: InputEvent) =>
        this.setState({ datasetChecked: event.target.value === 'true' });

    /**
     * When the dataset name is changed in the input field
     */
    handleDatasetNameChange = (event: InputEvent) =>
        this.setState({ datasetName: event.target.value });

    handleUpdateState = (
        cascade: IMimicCascade,
        stateKey: 'checked' | 'name',
        value: string | boolean
    ) =>
        this.setState((prevState: State) => {
            const existingCascade = prevState.isothermsDisplayed.find(
                (stateCascade: CascadeObject) => cascade === stateCascade.cascade
            );
            return {
                isothermsDisplayed: [
                    ...prevState.isothermsDisplayed.filter(
                        (stateCascade: CascadeObject) => cascade !== stateCascade.cascade
                    ),
                    {
                        ...existingCascade,
                        [stateKey]: value,
                    },
                ],
            };
        });

    /**
     * Handle an isotherm checkbox being clicked.
     */
    handleIsothermCheckboxClicked = (cascade: IMimicCascade) => (event: InputEvent) => {
        const checked = event.target.value === 'true';
        this.handleUpdateState(cascade, 'checked', checked);
    };

    /**
     * Handle an isotherm name being changed
     */
    handleIsothermNameChange = (cascade: IMimicCascade) => (event: InputEvent) => {
        // $FlowIgnore - event.target.value will be a string
        const newName = validateIsothermName(event.target.value);
        this.handleUpdateState(cascade, 'name', newName);
    };

    /**
     * Render the isotherm checkboxes from a list of isotherm checkboxes.
     */
    renderIsothermCheckboxesInputFields = (): React.Node => {
        return (
            <Field>
                <Label boldLabel>{this.getTranslation('isothermsTitle')}</Label>
                {this.props.cascades.map((cascade: IMimicCascade) => {
                    const name = this.getIsothermName(cascade);
                    const key = `${cascade.type}-${cascade.location}`;
                    const isSelected = this.isCascadeSelected(cascade);

                    return (
                        <WrapperCheckboxTextInput key={key}>
                            <CheckBox
                                checked={isSelected}
                                labelRenderer={() => <CascadeDescription cascade={cascade} />}
                                onClickHandler={this.handleIsothermCheckboxClicked(cascade)}
                                styles={CheckBoxStyle}
                                disabled={this.props.loading}
                            />
                            <NameWrapper>
                                <Label noMargin>{this.getTranslation('as')}</Label>
                                <InputNameField
                                    placeholder={this.getTranslation('isothermNamePlaceholder')}
                                    value={name}
                                    maxLength={ISOTHERM_NAME_MAX_LENGTH}
                                    onChange={this.handleIsothermNameChange(cascade)}
                                    disabled={!isSelected || this.props.loading}
                                />
                            </NameWrapper>
                        </WrapperCheckboxTextInput>
                    );
                })}
            </Field>
        );
    };

    render() {
        let titleKey = 'titleCreate';
        if (this.props.saveAs) {
            titleKey = 'titleSaveAs';
        } else if (this.props.dataset) {
            titleKey = 'titleSave';
        }

        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>{this.getTranslation(titleKey)}</Title>
                    </Header>
                    <Body>
                        <Field>
                            {this.state.isothermsDisplayed.length > 0 && (
                                <Label boldLabel>{this.getTranslation('datasetSubTitle')}</Label>
                            )}
                            <WrapperCheckboxTextInput>
                                {// The dataset checkbox is shown only when there are new isotherms, so user can choose what to save,
                                // otherwise only the dataset can be saved so checkbox is useless.
                                this.state.isothermsDisplayed.length > 0 ? (
                                    <CheckBox
                                        checked={this.state.datasetChecked}
                                        onClickHandler={this.handleDatasetChecked}
                                        label={this.getTranslation('datasetNameCheckbox')}
                                        styles={CheckBoxStyle}
                                        disabled={this.props.loading}
                                    />
                                ) : (
                                    <Label noMargin>
                                        {this.getTranslation('datasetNameCheckbox')}
                                    </Label>
                                )}
                                <NameWrapper>
                                    <Label noMargin>{this.getTranslation('as')}</Label>
                                    <InputNameField
                                        name="datasetName"
                                        placeholder={this.getTranslation('datasetNamePlaceholder')}
                                        value={this.state.datasetName}
                                        onChange={this.handleDatasetNameChange}
                                        disabled={
                                            (!this.props.saveAs && this.props.dataset) ||
                                            !this.state.datasetChecked ||
                                            this.props.loading
                                        }
                                    />
                                </NameWrapper>
                            </WrapperCheckboxTextInput>
                        </Field>
                        {this.state.isothermsDisplayed.length > 0 &&
                            this.renderIsothermCheckboxesInputFields()}
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: `components.Modals.${
                                    this.props.dataset ? 'saveButton' : 'createButton'
                                }`,
                            })}
                            onClick={this.handleSaveButtonClicked}
                            loading={this.props.loading}
                            disabled={!this.isSaveButtonEnabled()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(DatasetSaveModal);
