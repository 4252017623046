// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Helpers
import { OverflowBody, OverflowContainer, OverflowEnd } from 'components/_ReactUI_V1';

import { nodeToClipboard, getFirstStageInCascadeForStage } from 'utils/helpers';
import {
    getDomainMin,
    getDomainMax,
    getRecoveryMin,
    getRecoveryMax,
    getNetTransferMin,
    getNetTransferMax,
    hasNetTransfer,
} from './helpers';

// Styles
import { ExportFixedContainer } from 'styles/common';

// Constants
import {
    SENSITIVITY_OPTION_LIMITS,
    DIAGRAM_DISPLAY_MODES,
    STAGE_VALUE_TYPES,
    SIDEBAR_STATES,
} from 'utils/constants';

// Components
import MimicDiagram from 'components/MimicDiagram';
import Export2DSensitivityCSVModalContainer from 'containers/Export2DSensitivityCSVModalContainer';
import ComputationInstructions from 'components/ComputationInstructions';
import TwoDSensitivityDiagram from 'components/TwoDSensitivityDiagram';
import TwoDSensitivityFooter from 'components/TwoDSensitivityFooter';
import TwoDSensitivitySidebarSection from 'components/CircuitComputationSidebar/TwoDSensitivitySidebarSection';
import SensitivitySidebarSummarySection from 'components/CircuitComputationSidebar/SensitivitySidebarSummarySection';

// Services
import { newFeedback } from 'services/Feedback/thunks';
import { compute2DSensitivity, compute2DDataset } from 'services/Dataset/thunks';
import {
    select2DAnalysis,
    selectDatasetIsComputing2D,
    select2DDataset,
    selectIsComputing2DDataset,
} from 'services/Dataset/selectors';

// Types
import type { ImmutableList, ReduxDispatch } from 'types';
import type { SidebarRenderer } from 'containers/CircuitComputationContainer';
import type { FeedbackType } from 'services/Feedback/types';
import type { ImmutableStream, ImmutableStage } from 'services/Circuit/types';
import type {
    SensitivityOptionConstant,
    ImmutableTwoDAnalysis,
    IsothermStageValue,
    StageValue,
    StreamValue,
    SensitivityOptionsAvailability,
} from 'services/Dataset/types';

const SIDEBAR_TYPES = {
    SUMMARY: 'SUMMARY', // after computing
    SELECTION: 'SELECTION', // before computing
};

type SidebarType = $Keys<typeof SIDEBAR_TYPES>;

const DEFAULT_DOMAIN_MIN = 0.001;
const DEFAULT_DOMAIN_MAX = 10;

type DataPoint = ImmutableList<number>; // Will hold only 2 numbers.
type DataPointList = ImmutableList<DataPoint>;

type Props = {
    datasetId: number,
    isComputing2D: boolean,
    twoDAnalysis: ?ImmutableTwoDAnalysis,
    compute2DSensitivity: (
        datasetId: number,
        variable: SensitivityOptionConstant,
        rangeMin: number,
        rangeMax: number
    ) => void,

    isComputing2DDataset: boolean,
    twoDDataset: ?ImmutableDataset,
    compute2DDataset: (
        datasetId: number,
        variable: SensitivityOptionConstant,
        variableValue: number
    ) => void,

    handleSidebarContent: (sidebarContent: SidebarRenderer) => void,
    newFeedback: (feedbackType: FeedbackType, message: string) => void,
    match: {
        params: {
            circuitId: string,
            datasetId: string,
        },
    },
    sensitivityOptionAvailability: SensitivityOptionsAvailability,
};

type State = {
    sidebarType: SidebarType,
    generateDiagram: boolean,
    showTwoDDataset: boolean,
    sensitivityOption: ?SensitivityOptionConstant,
    startX: number,
    finishX: number,

    sensitivityOptionValue: ?number,
    recoveryPercent: ?number,
    netTransfer: ?number,

    openedExportFixedContainer: boolean,

    showExportCSVModal: boolean,
};

/**
 * The container that holds the 2d sensitivity plot and handles the sidebar state
 */
class TwoDSensitivityContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.exportTarget = React.createRef();

        this.state = this.getDefaultState();
    }

    /**
     * When this container is mounted, set sidebar default settings,
     */
    componentDidMount() {
        this.props.handleSidebarContent(this.renderSidebar());
    }

    componentDidUpdate(prevProps: Props) {
        // When we change datasets, reset our state.
        if (prevProps.datasetId !== this.props.datasetId) {
            return this.setState(this.getDefaultState(), () =>
                this.props.handleSidebarContent(this.renderSidebar())
            );
        }
        // Were we computing 2D and now were done computing (and we have an analysis)
        if (prevProps.isComputing2D && !this.props.isComputing2D && this.props.twoDAnalysis) {
            return this.setState(
                {
                    generateDiagram: true,
                    sidebarType: SIDEBAR_TYPES.SUMMARY,
                },
                () => this.props.handleSidebarContent(this.renderSidebar())
            );
        }
        // Update the button to loading & disable states.
        if (!prevProps.isComputing2D && this.props.isComputing2D) {
            this.props.handleSidebarContent(this.renderSidebar());
        }
    }

    exportTarget = null;

    getDefaultState = () => ({
        // Sidebar
        sidebarType: SIDEBAR_TYPES.SELECTION,
        generateDiagram: false,
        showTwoDDataset: false,
        sensitivityOption: null,
        startX: DEFAULT_DOMAIN_MIN,
        finishX: DEFAULT_DOMAIN_MAX,

        // Diagram/main content
        sensitivityOptionValue: null,
        recoveryPercent: null,
        netTransfer: null,

        openedExportFixedContainer: false,

        showExportCSVModal: false,
    });

    getIsReagentOrOxime = () =>
        this.props.twoDAnalysis &&
        this.props.twoDAnalysis.get('circuit').has('reagent') &&
        this.props.twoDAnalysis.get('circuit').has('reagent') !== null
            ? 'reagent'
            : 'oxime';

    /**
     * Reset the active mode to the selection mode.
     */
    onReturnToSelectionClicked = () =>
        this.setState(
            {
                sidebarType: SIDEBAR_TYPES.SELECTION,
                generateDiagram: false,
                showTwoDDataset: false,
            },
            () => this.props.handleSidebarContent(this.renderSidebar())
        );

    onHandleComputeClick = () =>
        this.setState(
            {
                showTwoDDataset: true,
            },
            () =>
                this.props.compute2DDataset(
                    this.props.datasetId,
                    this.state.sensitivityOption,
                    this.state.sensitivityOptionValue
                )
        );

    onExportClick = () =>
        this.setState((prevState: State) => ({
            showExportCSVModal: !prevState.showExportCSVModal,
        }));

    /**
     * When copy to clipboard button clicked
     */
    handleCopyToClipboardClick = () => {
        this.setState(
            {
                openedExportFixedContainer: true,
            },
            () =>
                nodeToClipboard(
                    this.exportTarget.current,
                    (feedbackType: FeedbackType, messageId: string) =>
                        this.props.newFeedback(
                            feedbackType,
                            this.props.intl.formatMessage({
                                id: messageId,
                            })
                        )
                )
        );
    };

    /**
     * With provided source (twoDAnalysis.getIn(['analysis', source])), match either index (0, 1) with provided value
     * Returns matching twoDAnalysis.getIn(['analysis', source]) item
     */
    getMatchingDataPointWithValue = (matchKey: string, matchValue: number): ?DataPointList => {
        const src = this.props.twoDAnalysis.get('analysis');

        if (!src) {
            return null;
        }

        return src.reduce(
            (prev: DataPointList, curr: DataPointList): number =>
                Math.abs(curr.get(matchKey) - matchValue) <
                Math.abs(prev.get(matchKey) - matchValue)
                    ? curr
                    : prev
        );
    };

    /**
     * On Sensitivity Option Interaction (onChange & onBlur) in both cases, replace Overall Recovery & Net Transfer with nearest value
     * onChange replace Sensitivity Option with inputted value
     * onBlur replace Sensitivity Option with nearest value
     */
    onHandleSensitivityOptionInteraction = (
        sensitivityOptionValue: number,
        replaceWithNearest: boolean = false
    ) => {
        const closestDataPoint = this.getMatchingDataPointWithValue(
            'xVariable',
            sensitivityOptionValue
        );

        this.setState({
            netTransfer: closestDataPoint ? closestDataPoint.get('netTransfer') : null,
            recoveryPercent: closestDataPoint ? closestDataPoint.get('overallRecovery') : null,
            sensitivityOptionValue:
                closestDataPoint && replaceWithNearest
                    ? closestDataPoint.get('xVariable')
                    : sensitivityOptionValue,
        });
    };

    /**
     * On Recovery Percent Interaction (onChange & onBlur) in both cases, replace Sensitivity Option Value & Net Transfer with nearest value
     * onChange replace Overall Recovery with inputted value
     * onBlur replace Overall Recovery with nearest value
     */
    onHandleRecoveryPercentInteraction = (
        recoveryPercent: number,
        replaceWithNearest: boolean = false
    ) => {
        const closestDataPoint = this.getMatchingDataPointWithValue(
            'overallRecovery',
            recoveryPercent
        );

        if (!closestDataPoint) {
            return;
        }

        this.setState({
            netTransfer: closestDataPoint.get('netTransfer'),
            recoveryPercent: replaceWithNearest
                ? closestDataPoint.get('overallRecovery')
                : recoveryPercent,
            sensitivityOptionValue: closestDataPoint.get('xVariable'),
        });
    };

    /**
     * On Net Transfer Interaction (onChange & onBlur) in both cases, replace Sensitivity Option Value & Overall Recovery with nearest value
     * onChange replace Net Transfer with inputted value
     * onBlur replace Net Transfer with nearest value
     */
    onHandleNetTransferInteraction = (netTransfer: number, replaceWithNearest: boolean = false) => {
        const closestDataPoint = this.getMatchingDataPointWithValue('netTransfer', netTransfer);

        if (!closestDataPoint) {
            return;
        }

        this.setState({
            netTransfer: replaceWithNearest ? closestDataPoint.get('netTransfer') : netTransfer,
            recoveryPercent: closestDataPoint.get('overallRecovery'),
            sensitivityOptionValue: closestDataPoint.get('xVariable'),
        });
    };

    /**
     * When Generate Diagram button clicked
     */
    onGenerateDiagramClicked = (
        sensitivityOption: SensitivityOptionConstant,
        startX: ?number,
        finishX: ?number
    ) => {
        this.setState(
            {
                sensitivityOption,
                startX: startX || SENSITIVITY_OPTION_LIMITS[sensitivityOption].DEFAULT_MINIMUM,
                finishX: finishX || SENSITIVITY_OPTION_LIMITS[sensitivityOption].DEFAULT_MAXIMUM,
            },
            () =>
                this.props.compute2DSensitivity(
                    this.props.datasetId,
                    sensitivityOption,
                    this.state.startX,
                    this.state.finishX
                )
        );
    };

    handleHideMimicDiagram = () =>
        this.setState({
            showTwoDDataset: false,
        });

    renderSidebar = (): SidebarRenderer => ({
        sidebarType:
            this.state.sidebarType === SIDEBAR_TYPES.SELECTION
                ? SIDEBAR_STATES.TAB
                : SIDEBAR_STATES.FULL,
        node:
            this.state.sidebarType === SIDEBAR_TYPES.SELECTION ? (
                <TwoDSensitivitySidebarSection
                    loading={this.props.isComputing2D}
                    generatedDiagram={this.state.generateDiagram}
                    handleGenerateDiagramClicked={this.onGenerateDiagramClicked}
                    sensitivityOptionAvailability={this.props.sensitivityOptionAvailability}
                />
            ) : (
                <SensitivitySidebarSummarySection
                    returnTo="2D"
                    circuit={this.props.twoDAnalysis && this.props.twoDAnalysis.get('circuit')}
                    dataset={this.props.twoDAnalysis && this.props.twoDAnalysis.get('dataset')}
                    variableX={this.state.sensitivityOption}
                    variableXMin={getDomainMin(this.props.twoDAnalysis)}
                    variableXMax={getDomainMax(this.props.twoDAnalysis)}
                    handleReturnToSelection={this.onReturnToSelectionClicked}
                />
            ),
    });

    renderMimicDiagram = (fullDisplay: boolean) => {
        const dataset = this.props.twoDDataset.get('dataset');
        const circuit = this.props.twoDDataset.get('circuit');
        const stages = circuit
            .get('stages')
            .map((stage: ImmutableStage) => {
                const firstStage = getFirstStageInCascadeForStage(circuit, stage);
                let isotherm = dataset
                    .get('isothermStageValues')
                    .find(
                        (isothermStageValue: IsothermStageValue) =>
                            isothermStageValue.get('stageId') === firstStage.get('id')
                    );
                isotherm = isotherm.set('isothermMode', isotherm.get('valueType'));
                isotherm = isotherm.set('valueType', STAGE_VALUE_TYPES.ISOTHERM);
                return stage.set(
                    'values',
                    dataset
                        .get('stageValues')
                        .filter(
                            (stageValue: StageValue) =>
                                stageValue.get('stageId') === stage.get('id')
                        )
                        .push(isotherm)
                );
            })
            .toJS();
        const streams = circuit.get('streams').toJS();
        return (
            <MimicDiagram
                displayMode={DIAGRAM_DISPLAY_MODES.COMPUTED}
                datasetMode={dataset.get('datasetMode')}
                fullDisplay={fullDisplay}
                loadingCircuit={false}
                stages={stages}
                streams={streams}
                streamValues={dataset.get('streamValues').toJS()}
                circuit={circuit}
                datasetValues={dataset.get('datasetValues').toJS()}
                circuitUnits={circuit.get('circuitUnits')}
            />
        );
    };

    renderTwoDSensitivityDiagram = () => {
        // Display the net transfer line if the circuit has a reagent
        const hasReagent =
            this.props.twoDAnalysis &&
            this.props.twoDAnalysis.getIn(['circuit', 'reagent'], null) !== null;
        return (
            <TwoDSensitivityDiagram
                loading={this.props.isComputing2D}
                twoDAnalysis={this.props.twoDAnalysis}
                sensitivityOption={this.state.sensitivityOption}
                sensitivityOptionValue={this.state.sensitivityOptionValue}
                showNetTransferIfProvided={hasReagent}
                handleHover={this.onHandleSensitivityOptionInteraction}
            />
        );
    };

    render() {
        // Did the user ask the diagram to be generated?
        if (
            !this.state.generateDiagram ||
            (!this.props.twoDAnalysis && !this.props.isComputing2D)
        ) {
            return <ComputationInstructions messageId="2DSensitivity.selectVariable" />;
        }

        const mustShowMimic = Boolean(
            this.state.showTwoDDataset && this.props.twoDDataset && !this.props.isComputing2DDataset
        );

        const twoDSensitivityDiagram = mustShowMimic
            ? this.renderMimicDiagram(this.state.openedExportFixedContainer)
            : this.renderTwoDSensitivityDiagram();

        return (
            <OverflowContainer>
                <OverflowBody>{twoDSensitivityDiagram}</OverflowBody>
                <OverflowEnd>
                    {this.props.twoDAnalysis && (
                        <TwoDSensitivityFooter
                            // Get the sensitivity option.
                            sensitivityOption={this.state.sensitivityOption}
                            minSensitivityOptionValue={getDomainMin(this.props.twoDAnalysis)}
                            maxSensitivityOptionValue={getDomainMax(this.props.twoDAnalysis)}
                            sensitivityOptionValue={this.state.sensitivityOptionValue}
                            handleSensitivityOptionInteraction={
                                this.onHandleSensitivityOptionInteraction
                            }
                            // Get the recovery percent min/max
                            minRecoveryPercent={getRecoveryMin(this.props.twoDAnalysis)}
                            maxRecoveryPercent={getRecoveryMax(this.props.twoDAnalysis)}
                            recoveryPercent={this.state.recoveryPercent}
                            handleRecoveryPercentInteraction={
                                this.onHandleRecoveryPercentInteraction
                            }
                            // Get the net transfer min/max
                            hasNetTransfer={hasNetTransfer(this.props.twoDAnalysis)}
                            minNetTransfer={getNetTransferMin(this.props.twoDAnalysis)}
                            maxNetTransfer={getNetTransferMax(this.props.twoDAnalysis)}
                            netTransfer={this.state.netTransfer}
                            handleNetTransferInteraction={this.onHandleNetTransferInteraction}
                            // Extras
                            handleComputeClicked={this.onHandleComputeClick}
                            onHandleHideMimicDiagram={this.handleHideMimicDiagram}
                            onCopyToClipboardClick={this.handleCopyToClipboardClick}
                            handleExportClicked={this.onExportClick}
                            computeDisabled={false}
                            copyToClipboardDisabled={false}
                            exportDisabled={false}
                            loading={this.props.isComputing2DDataset}
                            isDisplayingMimic={mustShowMimic}
                        />
                    )}
                </OverflowEnd>
                {this.state.openedExportFixedContainer && (
                    <ExportFixedContainer ref={this.exportTarget}>
                        {twoDSensitivityDiagram}
                    </ExportFixedContainer>
                )}
                {this.state.showExportCSVModal && (
                    <Export2DSensitivityCSVModalContainer
                        circuitId={Number(this.props.match.params.circuitId)}
                        datasetId={Number(this.props.match.params.datasetId)}
                        sensitivityVariable={this.state.sensitivityOption}
                        rangeMin={this.state.startX}
                        rangeMax={this.state.finishX}
                        handleOnClose={this.onExportClick}
                    />
                )}
            </OverflowContainer>
        );
    }
}

const mapStateToProps = (state: State, ownProps: Props) =>
    createStructuredSelector({
        twoDAnalysis: select2DAnalysis(),
        isComputing2D: selectDatasetIsComputing2D(),

        twoDDataset: select2DDataset(),
        isComputing2DDataset: selectIsComputing2DDataset(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            newFeedback,
            compute2DSensitivity,
            compute2DDataset,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(TwoDSensitivityContainer))
);
