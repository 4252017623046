// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

// Mimic Engine
import { MimicCircuit } from 'components/_McCabeThiele';

import { McCabeThieleContainer, setupApi as setupMcCabeThieleApis } from 'components/_McCabeThiele';

// Services
import { selectComputedDataset } from 'services/Dataset/selectors';
import { selectUser } from 'services/Authentication/selectors';

import { getUsersLanguage } from 'utils/authentication';

// Types
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableDataset } from 'services/Dataset/types';

import { appEnvironment } from 'env';

// Sets up the McCabe Thiele API
setupMcCabeThieleApis(appEnvironment);

type Props = {
    circuitId: number,
    circuit: ?ImmutableCircuit,
    datasetId: ?number,
    computedDataset: ?ImmutableDataset,

    sidebarNodeRef: ?HTMLDivElement,
};

/**
 * The container that holds the McCabe Thiele diagrams, and that handles the sidebar state
 */
class MSMcCabeThieleContainer extends React.Component<Props> {
    render() {
        if (!this.props.circuit) {
            // Not yet loaded
            return null;
        }
        const userLocale = getUsersLanguage(this.props.user);

        const mimicCircuit = new MimicCircuit(this.props.circuit.toJS());

        const sidebarDomNode = this.props.sidebarNodeRef;
        if (!sidebarDomNode) {
            // TODO: Fix this such that the ref is available the first time we render this
            // component. This will be set the next time setState is called.
            return null;
        }

        return (
            <McCabeThieleContainer
                circuitId={this.props.circuitId}
                circuit={mimicCircuit}
                datasetId={this.props.datasetId} // DatasetId or ComputedDataset must not be null
                computedDataset={
                    this.props.computedDataset ? this.props.computedDataset.toJS() : null
                } // DatasetId or ComputedDataset must not be null
                sidebarDOMElement={sidebarDomNode}
                locale={userLocale}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    computedDataset: selectComputedDataset(),
    user: selectUser(),
});

export default withRouter(connect(mapStateToProps)(MSMcCabeThieleContainer));
