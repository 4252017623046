// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { Metal } from './types';

export const FETCH_LIST_SUCCESS = 'app/services/Metal/FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'app/services/Metal/FETCH_LIST_FAILURE';
export const IS_FETCHING = 'app/services/Metal/IS_FETCHING';

export const receivedFetchListSuccess = (data: Array<Metal>) => ({
    type: FETCH_LIST_SUCCESS,
    payload: { data },
});

export const receivedFetchListFailure = (errors: {}) => ({
    type: FETCH_LIST_FAILURE,
    payload: { errors },
});

export const setIsFetchingStatus = (isFetching: boolean = true) => ({
    type: IS_FETCHING,
    payload: { isFetching },
});
