/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { LegacyTheme } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

import { colors } from 'styles/colors';

// Components
export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: ${STYLE_VALUES.HEADER.HEIGHT};

    border-bottom: 1px solid ${LegacyTheme.defaultBorder};

    z-index: ${({ overlayOpened }: { overlayOpened: boolean }) => (overlayOpened ? 51 : 6)};
`;

export const SidebarNav = styled.div`
    display: flex;
    flex-direction: row;
    width: 360px;

    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        width: auto;
        flex-grow: ${({ flexGrow }: { flexGrow: boolean }) => (flexGrow ? 1 : 'unset')};
    }

    border-right: ${(props: Object) =>
        props.displayDivider ? `1px solid ${LegacyTheme.defaultBorder}` : '0 none'};
`;

export const MainNav = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;

    height: 100%;
    min-width: 100px;

    justify-content: flex-end;
    min-height: 60px;
`;

export const Left = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;

    ${({ hideOnMobile }: { hideOnMobile: boolean }) =>
        hideOnMobile &&
        `
        @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
            display: none;
        }
    `}
`;

export const Right = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    color: ${colors.greyAF};
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 25px;
    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        margin-right: 12px;
    }

    height: 100%;
    width: 30px;

    :hover {
        cursor: pointer;
        color: ${colors.grey7B};
    }
`;

export const DesktopLogo = styled.img`
    display: block;
    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        display: none;
    }
`;

export const MobileLogo = styled.img`
    display: none;
    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        display: block;
    }
`;

export const LogoLink = styled(Link)`
    display: block;

    cursor: pointer;
    text-decoration: none;

    > ${DesktopLogo} {
        width: 100%;
        max-width: 108px;
        height: auto;

        margin: 5px 0;
    }

    > ${MobileLogo} {
        height: ${STYLE_VALUES.HEADER.HEIGHT_RAW - 1}px;
    }
`;

export const LogoContainer = styled.div`
    display: flex;

    ${LogoLink} {
        margin: 0px 9px 0px 21px;
        @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
            margin: 0;
        }
    }
`;

export const HelpLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    height: 100%;

    color: ${LegacyTheme.defaultIconColor};
    cursor: pointer;

    font-size: 11px;
    text-decoration: none;
`;
