// @flow strict

import { twoDSensitivityDiagramColors } from 'styles/colors';
import { LegacyTheme } from 'components/_ReactUI_V1';

type DataPoint = {
    x: number,
    y: number,
    fill?: string,
};

export const lineGraphStylesByType = (type: string) => {
    if (type === 'overallRecovery') {
        return {
            data: {
                stroke: twoDSensitivityDiagramColors.recoveryPercent.primaryColor,
                strokeWidth: '4px',
                opacity: 0.75,
            },
            dot: {
                fill: twoDSensitivityDiagramColors.recoveryPercent.primaryColor,
            },
        };
    }

    if (type === 'netTransfer') {
        return {
            data: {
                stroke: twoDSensitivityDiagramColors.netTransfer.primaryColor,
                strokeWidth: '4px',
                opacity: 0.75,
            },
            dot: {
                fill: twoDSensitivityDiagramColors.netTransfer.primaryColor,
            },
        };
    }

    return {};
};

export const GraphStyles = {
    mainGraph: {
        parent: {
            overflow: 'visible',
            boxSizing: 'border-box',
            fontFamily: 'Helvetica',
        },
    },

    mainGraphPadding: {
        top: 34,
        bottom: 100,
        left: 200,
        right: 200,
    },

    hoverDotScatter: (activeX: ?number, fill: string) => ({
        data: {
            opacity: (datum: DataPoint) => (activeX === datum.x ? 1 : 0),
            fill,
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 2,
        },
    }),

    axisY: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        ticks: {
            size: 55,
            strokeWidth: 1,
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            padding: 5,
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontFamily: 'inherit',
            fontSize: 12,
            padding: 100,
        },
    },

    axisYTickLabelTranslate: {
        dy: -20,
        dx: 40, // axisY.ticks.size - dx = spacing between axis and tick number.
    },

    recoveryPercentAxis: {
        tickLabels: {
            fill: twoDSensitivityDiagramColors.recoveryPercent.primaryColor,
            fontFamily: 'inherit',
            fontSize: 12,
        },
    },

    netTransferAxis: {
        tickLabels: {
            fill: twoDSensitivityDiagramColors.netTransfer.primaryColor,
            fontFamily: 'inherit',
            fontSize: 12,
        },
    },

    axisX: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        tickLabels: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 12,
            fontFamily: 'inherit',
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 12,
            fontFamily: 'inherit',
            padding: 74,
        },
    },
};
