// @flow

import styled, { createGlobalStyle } from 'styled-components';
import * as ReactDraftWysiwygCSS from 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { StyledInputBase } from '../input-field';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

export const ReactDraftWysiwygStyles = createGlobalStyle`${ReactDraftWysiwygCSS}`;

export const Wrapper = styled.div`
    ${StyledInputBase}

    height: 100%;
    padding: 0;

    .rdw-editor-toolbar {
        margin-bottom: 0;

        border: 0 none;
        border-bottom: 1px solid
            ${({ theme }: Object) =>
                themePropertyFetcher(theme, [
                    'formElements',
                    'textFieldRich',
                    'toolbar',
                    'borderColor',
                ])};
    }

    .rdw-editor-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .rdw-editor-main {
        padding: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['formElements', 'textFieldRich', 'padding'])};

        font-size: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['formElements', 'textFieldRich', 'fontSize'])};
    }
`;
