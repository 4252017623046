// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { SecondaryButton, ProfileIcon, Close, GearIcon, ToolTipTrigger } from 'components/_ReactUI_V1';

// assets
import HeartMonitorIcon from 'assets/icons/heart-monitor-icon';
import solvayLogoMobile from 'assets/favicon/180.png';

// Constants & Helpers
import {
    NAVIGATION_ROUTES,
    HELP_LINK,
    PM_NAVIGATION_ROUTES,
    ADMIN_NAVIGATION_ROUTES,
    SAM_NAVIGATION_ROUTES,
    ROUTES,
} from 'utils/constants';
import { isSolvayUser, isSysAdmin } from 'utils/authentication';

// styles
import { colors } from 'styles/colors';
import {
    Overlay,
    Wrapper,
    BodyWrapper,
    Navigation,
    NavigationItem,
    NavigationItemExternal,
    NavigationItemIcon,
    HeaderWrapper,
    ProfileWrapper,
    Profile,
    ProfileText,
    ProfileName,
    ProfileRole,
    CloseButton,
} from './styles';

// Types
import type { IntlType, PageNameConstant } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';

type NavigationItemType = {
    url: string,
    label: string,
    active: boolean,
};

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    page: PageNameConstant,

    onLogout: () => void,
    onClose: () => void,
};

class OverlayNavigation extends React.PureComponent<Props> {
    getRoleText = () =>
        this.props.intl.formatMessage({
            id: `roles.fullName.${this.props.user.get('roles').last()}`,
        });

    renderNavigationItem = (route: typeof ROUTES, icon: React.ReactNode) => (
        <NavigationItem to={route.path} bordered={0} active={this.props.page === route.name}>
            <NavigationItemIcon>{icon}</NavigationItemIcon>
            {this.props.intl.formatMessage({
                id: `components.Header.routeTitles.${route.name}`,
            })}
        </NavigationItem>
    );

    renderHelpLink = () =>
        HELP_LINK && (
            <NavigationItemExternal href={HELP_LINK} target="_blank" rel="noopener noreferrer">
                <NavigationItemIcon>
                    <ToolTipTrigger size={25} style={{ fontSize: '15px' }} />
                </NavigationItemIcon>
                {this.props.intl.formatMessage({
                    id: 'components.Header.helpLink',
                })}
            </NavigationItemExternal>
        );

    render() {
        const isAdmin = isSysAdmin(this.props.user);
        const isSAM = isSolvayUser(this.props.user);

        let routes = PM_NAVIGATION_ROUTES;
        if (isAdmin) {
            routes = ADMIN_NAVIGATION_ROUTES;
        } else if (isSAM) {
            routes = SAM_NAVIGATION_ROUTES;
        }

        routes = routes.map((routeName: PageNameConstant) => {
            let routeKey = routeName;
            let active = this.props.page === routeKey;

            let navigationRoute = NAVIGATION_ROUTES[routeKey];

            // If NAVIGATION_ROUTES[routeName] is a string, continue, else get first "child" route
            if (typeof navigationRoute === 'object') {
                const subNavigationRoutes = Object.keys(navigationRoute);

                routeKey = subNavigationRoutes[0];
                navigationRoute = navigationRoute[routeKey];
                active = subNavigationRoutes.includes(this.props.page);
            }

            return {
                url: navigationRoute,
                label: this.props.intl.formatMessage({
                    id: `components.Header.routeTitles.${routeName}`,
                }),
                active,
            };
        });

        return (
            <Overlay>
                <Wrapper>
                    <BodyWrapper>
                        <HeaderWrapper>
                            <ProfileWrapper>
                                <Profile>
                                    <ProfileIcon
                                        defaultImage={solvayLogoMobile}
                                        profileImage={this.props.user.get('photoUrl')}
                                        size="60px"
                                    />
                                    <ProfileText>
                                        <ProfileName>
                                            {this.props.user.get('displayName')}
                                        </ProfileName>
                                        <ProfileRole data-testid="profile-role">{this.getRoleText()}</ProfileRole>
                                    </ProfileText>
                                </Profile>

                                <SecondaryButton
                                    onClick={this.props.onLogout}
                                    text={this.props.intl.formatMessage({
                                        id: 'components.Header.logout',
                                    })}
                                    style={{ width: '115px' }}
                                />
                            </ProfileWrapper>
                            <CloseButton onClick={this.props.onClose}>
                                <Close fill="useCurrent" width="35px" margin="none" clickable />
                            </CloseButton>
                        </HeaderWrapper>
                        <Navigation>
                            {routes.map((route: NavigationItemType) => (
                                <NavigationItem
                                    key={route.url}
                                    to={route.url}
                                    // Fixes an issue where styled components would complain about boolean values
                                    active={route.active ? 1 : 0}
                                    bordered={1}
                                >
                                    {route.label}
                                </NavigationItem>
                            ))}
                        </Navigation>
                    </BodyWrapper>
                    <Navigation small>
                        {this.renderNavigationItem(
                            ROUTES.SETTINGS,
                            <GearIcon width="25px" height="25px" />
                        )}
                        {this.renderHelpLink()}
                        {(isAdmin || isSAM) &&
                            this.renderNavigationItem(
                                ROUTES.SYSTEM_STATUS,
                                <HeartMonitorIcon fill={colors.grey7B} />
                            )}
                    </Navigation>
                </Wrapper>
            </Overlay>
        );
    }
}

export default injectIntl(OverlayNavigation);
