// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { colors } from 'styles/colors';

export const NotificationsListItem = styled.li`
    cursor: pointer;
    border-top: 1px solid ${LegacyTheme.defaultBorder};
    padding: 16px 10px;
    margin-right: 10px;

    &:hover {
        background-color: ${LegacyTheme.defaultSubtleColorFA};
    }
`;

export const NotificationTitle = styled.p`
    color: ${LegacyTheme.black};
    line-height: 1.4;
    margin: 0;
`;

export const NotificationTime = styled.span`
    color: ${(props: Object) => props.color};
    font-size: 14px;
`;

export const NotificationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const NotificationContent = styled.p`
    color: ${colors.grey7B};
    line-height: 1.4;
    margin: 0;
`;

export const DotWrapper = styled.div`
    height: 26px;
    width: 26px;
    margin-left: 8px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${LegacyTheme.defaultBorder};
    }
`;

export const ToolTipContent = styled.div`
    color: ${colors.grey7B};
    font-size: 15px;
    padding: 6px;
`;
