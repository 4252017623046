// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { MicroFrontEnds } from '../../microfrontendURIs';

import MicroFrontend from '..';
import { ContainerCentered } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';

import { fetchAllClients } from 'services/Client/thunks';
import { selectAllClients } from 'services/Client/selectors';

import type { HistoryType, ImmutableList, IntlType, ReduxDispatch } from 'types';
import type { ImmutableClient } from 'services/Client/types';

type Props = {
    intl: IntlType,
    history: HistoryType,
    plantId: number,

    clients: ImmutableList<ImmutableClient>,
    fetchAllClients: () => void, // for navigation.
};

type State = {
    isUnLoadable: boolean,
};

class ReportGenerationSystem extends React.PureComponent<Props, State> {
    state = {
        isUnLoadable: false,
    };

    static getHost = () => {
        return MicroFrontEnds.reportGenerationSystem;
    };

    static getUniqueName = () => {
        return 'ReportGenerationSystem';
    };

    componentDidMount() {
        // for navigation.
        if (this.props.clients.isEmpty()) {
            this.props.fetchAllClients();
        }
    }

    getMountProps = () => {
        return {
            locale: this.props.intl.locale,
            history: this.props.history,
            plantId: this.props.plantId,
        };
    };

    onError = () => {
        this.setState({ isUnLoadable: true });
    };

    render() {
        if (this.state.isUnLoadable) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: 'views.ReportGenerationSystem.anErrorOccurredDuringLoad',
                        })}
                    />
                </ContainerCentered>
            );
        }
        return (
            <MicroFrontend
                name={ReportGenerationSystem.getUniqueName()}
                host={ReportGenerationSystem.getHost()}
                mountedMicroFrontendProps={this.getMountProps()}
                onError={this.onError}
                isLegacyScriptModule // TODO: Remove this once RGS MFE is monorepo compatible.
                mfeIsInFullScreen
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        clients: selectAllClients(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllClients,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReportGenerationSystem));
