// @flow strict

import styled from 'styled-components';
import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const NavigationTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;

    margin: 0 48px;
    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        margin: 0 12px;
    }
`;

export const NavigationTitle = styled.h1`
    font-size: 20px;
    line-height: 24px;
    color: ${LegacyTheme.defaultColor};
    font-weight: normal;

    @media (max-width: ${STYLE_VALUES.HEADER.RESPONSIVE_AT}) {
        font-size: 16px;
        line-height: normal;
    }
`;
