// @flow strict

import {
    setNotificationsAreQuerying,
    receivedNotificationsQuerySuccess,
    receivedNotificationsQueryFailure,
    setReadState,
    receivedReadStateUpdateSuccess,
    receivedReadStateUpdateFailure,
    setMarkAllAsReadIsUpdating,
    receivedMarkAllAsReadUpdateSuccess,
    receivedMarkAllAsReadUpdateFailure,
    setUnreadTotalIsFetching,
    receivedUnreadTotalFetchSuccess,
    receivedUnreadTotalFetchFailure,
    setSettingsAreFetching,
    receivedSettingsFetchSuccess,
    receivedSettingsFetchFailure,
    setSettingsAreUpdating,
    receivedSettingsUpdateSuccess,
    receivedSettingsUpdateFailure,
    setPlantSettingsAreFetching,
    receivedPlantSettingsFetchSuccess,
    receivedPlantSettingsFetchFailure,
    setPlantSettingsAreUpdating,
    receivedPlantSettingsUpdateSuccess,
    receivedPlantSettingsUpdateFailure,
    setScheduleIsFetching,
    receivedScheduleFetchSuccess,
    receivedScheduleFetchFailure,
    setScheduleIsUpdating,
    receivedScheduleUpdateSuccess,
    receivedScheduleUpdateFailure,
    setNotificationTypeUsersStatusesAreFetching,
    receivedNotificationTypeUsersStatusesFetchSuccess,
    receivedNotificationTypeUsersStatusesFetchFailure,
    receivedPhoneRemovalSuccess,
    receivedPhoneRemovalFailure,
    setPhoneRemovalIsLoading,
} from './actions';

import {
    queryNotifications,
    updateReadState,
    markAllAsRead,
    fetchUnreadTotal,
    indexSettings,
    updateSettings,
    indexPlantSettings,
    updatePlantSettings,
    indexSchedule,
    updateSchedule,
    indexNotificationTypeUsersStatuses,
    removePhoneNumber,
} from './resources';

import type { QueryStructure, ReduxDispatch, ResponseErrorType, ImmutableList } from 'types';

import {
    Notification,
    TotalUnreadNotifications,
    ImmutableNotificationSettings,
    NotificationSetting,
    ImmutableNotificationPlantSettings,
    NotificationPlantSetting,
    ImmutableNotificationSchedule,
    NotificationScheduleDay,
    NotificationTypeUserStatus,
} from 'services/Notifications/types';

import { createUntranslatedFeedback } from 'services/Feedback/actions';

/**
 * Query user notifications
 */
export const queryUserNotifications = (userId: number, page?: number, perPage?: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setNotificationsAreQuerying());
    queryNotifications(userId, page, perPage)
        .then((response: QueryStructure) => {
            dispatch(receivedNotificationsQuerySuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedNotificationsQueryFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchNotifications'));
        });
};

/**
 * Update a user notification's read state
 */
export const updateUserNotificationReadState = (
    userId: number,
    notificationId: number,
    readState: boolean
) => (dispatch: ReduxDispatch) => {
    dispatch(setReadState(notificationId, readState));
    updateReadState(userId, notificationId, readState)
        .then((response: Array<Notification>) => {
            dispatch(receivedReadStateUpdateSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedReadStateUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateNotificationReadState')
            );
        });
};

export const markAllNotificationsAsRead = (userId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setMarkAllAsReadIsUpdating());
    markAllAsRead(userId)
        .then(() => {
            dispatch(receivedMarkAllAsReadUpdateSuccess());
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedMarkAllAsReadUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateNotificationReadState')
            );
        });
};

/**
 * Fetch user's unread notifications total
 */
export const fetchTotalUnreadNotifications = (userId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setUnreadTotalIsFetching());
    fetchUnreadTotal(userId)
        .then((response: TotalUnreadNotifications) => {
            dispatch(receivedUnreadTotalFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedUnreadTotalFetchFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchTotalUnreadNotifications')
            );
        });
};

/**
 * Fetch user notification settings
 */
export const fetchNotificationSettings = (userId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setSettingsAreFetching());
    indexSettings(userId)
        .then((response: Array<NotificationSetting>) => {
            dispatch(receivedSettingsFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedSettingsFetchFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchNotificationSettings')
            );
        });
};

/**
 * Fetch user notification plant settings
 */
export const fetchNotificationPlantSettings = (userId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setPlantSettingsAreFetching());
    indexPlantSettings(userId)
        .then((response: Array<NotificationPlantSetting>) => {
            dispatch(receivedPlantSettingsFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPlantSettingsFetchFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchNotificationPlantSettings')
            );
        });
};

/**
 * Fetch user notification schedule
 */
export const fetchNotificationSchedule = (userId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setScheduleIsFetching());
    indexSchedule(userId)
        .then((response: Array<NotificationScheduleDay>) => {
            dispatch(receivedScheduleFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedScheduleFetchFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchNotificationSchedule')
            );
        });
};

/**
 * Update user notification settings
 */
export const updateNotificationSettings = (
    userId: number,
    settings: ImmutableNotificationSettings
) => (dispatch: ReduxDispatch) => {
    dispatch(setSettingsAreUpdating());
    updateSettings(userId, settings)
        .then((response: Array<NotificationSetting>) => {
            dispatch(receivedSettingsUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'INFO',
                    'feedback.success.updateUserNotificationSettings'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedSettingsUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateUserNotificationSettings')
            );
        });
};

/**
 * Update user notification plant settings
 */
export const updateNotificationPlantSettings = (
    userId: number,
    settings: ImmutableNotificationPlantSettings
) => (dispatch: ReduxDispatch) => {
    dispatch(setPlantSettingsAreUpdating());
    updatePlantSettings(userId, settings)
        .then((response: Array<NotificationPlantSetting>) => {
            dispatch(receivedPlantSettingsUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'INFO',
                    'feedback.success.updateUserNotificationPlantSettings'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPlantSettingsUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.updateUserNotificationPlantSettings'
                )
            );
        });
};

/**
 * Update user notification schedule
 */
export const updateNotificationSchedule = (
    userId: number,
    schedule: ImmutableNotificationSchedule
) => (dispatch: ReduxDispatch) => {
    dispatch(setScheduleIsUpdating());
    updateSchedule(userId, schedule)
        .then((response: Array<NotificationScheduleDay>) => {
            dispatch(receivedScheduleUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'INFO',
                    'feedback.success.updateUserNotificationSchedule'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedScheduleUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateUserNotificationSchedule')
            );
        });
};

/**
 * Fetch notification type users statuses
 */
export const fetchNotificationTypeUsersStatuses = (
    notificationTypeName: string,
    userIDs: string
) => (dispatch: ReduxDispatch) => {
    dispatch(setNotificationTypeUsersStatusesAreFetching());
    indexNotificationTypeUsersStatuses(notificationTypeName, userIDs)
        .then((response: Array<NotificationTypeUserStatus>) => {
            dispatch(receivedNotificationTypeUsersStatusesFetchSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedNotificationTypeUsersStatusesFetchFailure(error));
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.fetchNotificationTypeUsersStatuses'
                )
            );
        });
};
/**
 * Remove the user's phone number & update their sms notificatoin settings
 */
export const removeUserPhoneNumber = (userId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setPhoneRemovalIsLoading());
    removePhoneNumber(userId)
        .then((response: Array<NotificationTypeUserStatus>) => {
            dispatch(receivedPhoneRemovalSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.removePhoneNumberSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPhoneRemovalFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.removePhoneNumberFailed'));
        });
};
