// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { PageTitleObservable, QuickNavigatorObservable } from 'components/_FrontendObservables';

// Components
import PlantStep from 'components/PlantManager/PlantSetupSteps/PlantStep';
import KPIStep from 'components/PlantManager/PlantSetupSteps/KPIStep';
import ReviewStep from 'components/PlantManager/PlantSetupSteps/ReviewStep';
import DoneStep from 'components/PlantManager/PlantSetupSteps/DoneStep';

// Constants
import { PLANT_CREATION_STEPS } from 'utils/constants';

// Container
import ElevationContainer, { NEXT_BUTTON_TYPES } from 'containers/ElevationContainer';

// Helpers
import { parseStringToUTCDate } from 'utils/dateHelpers';

// Selectors
import {
    selectPlantErrors,
    selectPlantsAreUpdating,
    selectPlantIsCreating,
    selectIsDownloadingTemplate,
    selectAllPlants,
} from 'services/Plant/selectors';
import { selectAllClients } from 'services/Client/selectors';
import { selectUser } from 'services/Authentication/selectors';

// Thunks
import { createPlant, updatePlant, downloadTemplate } from 'services/Plant/thunks';
import { fetchAllClients } from 'services/Client/thunks';

// Types
import type {
    LooseInputValueTypes,
    LooseKeyArrayType,
    ErrorType,
    ImmutableList,
    IntlType,
    ReduxDispatch,
} from 'types';
import type { ImmutablePlant, PlantCreationStepConstant } from 'services/Plant/types';
import type { ImmutableClient } from 'services/Client/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

type Props = {
    errors: ErrorType,

    plant: ImmutablePlant,
    plants: ImmutableList<ImmutablePlant>,

    plantIsCreating: boolean,
    plantIsUpdating: boolean,

    updatePlant: (number, ImmutablePlant) => void,
    createPlant: (ImmutablePlant) => void,

    clients: ImmutableList<ImmutableClient>,
    fetchAllClients: () => void,

    intl: IntlType,

    downloadTemplate: (plantId: number, fileName: string) => void,
    isDownloadingTemplate: boolean,

    onExitCreateOrUpdateProcess: () => void,
};

type State = {
    currentStep: PlantCreationStepConstant,
    plant: ImmutablePlant,
    isModified: boolean,
};

class PlantSetupContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentStep: PLANT_CREATION_STEPS.PLANT,
            isModified: false,
            plant: this.getPlantForState(),
        };
    }

    componentDidMount() {
        if (this.props.clients.isEmpty()) {
            this.props.fetchAllClients();
        }

        QuickNavigatorObservable.setQuickNavigatorBackToCallback(
            this.props.intl.formatMessage({
                id: 'components.Header.BackToNavigator.plants',
            }),
            this.props.onExitCreateOrUpdateProcess
        );
        PageTitleObservable.setPageTitle(
            this.props.intl.formatMessage({
                id: `components.Header.routeTitles.PLANT_CREATION_V1`,
            })
        );
    }

    componentWillUnmount() {
        QuickNavigatorObservable.removeQuickNavigator();
        PageTitleObservable.removePageTitle();
    }

    /**
     * Upon successful creation/update of a plant;
     * Replace local plant with saved plant, reset UI + move to DONE step
     */
    componentDidUpdate(prevProps: Props) {
        // if the state was modified and we finished updating/creating
        if (
            (prevProps.plantIsCreating || prevProps.plantIsUpdating) &&
            !(this.props.plantIsCreating || this.props.plantIsUpdating)
        ) {
            // if there is no errors
            if (this.props.errors.isEmpty()) {
                // then set the state to no longer modified
                this.setState((prevState: State) => {
                    let plant;
                    if (prevState.plant && prevState.plant.get('id')) {
                        // replace unsaved plant with updated plant
                        plant = this.props.plants.find(
                            (p: ImmutablePlant) => p.get('id') === prevState.plant.get('id')
                        );
                    } else {
                        // replace unsaved plant with latest saved plant
                        plant = this.props.plants
                            .sort(
                                (p: ImmutablePlant, nextPlant: ImmutablePlant) =>
                                    parseStringToUTCDate(p.get('createdAt')) >
                                    parseStringToUTCDate(nextPlant.get('createdAt'))
                            )
                            .first();
                    }
                    if (!plant) {
                        throw new Error('Unable to access created/updated plant...');
                    }

                    return {
                        plant,
                        isModified: false,
                        currentStep: PLANT_CREATION_STEPS.DONE, // move to the done page after saving.
                    };
                });
            }
        }
    }

    /**
     * To be used in constructor: Get provided plant and remove unused fields form KPISettings before providing to state
     */
    getPlantForState = () => {
        const plantFromDB = this.props.plant;
        const plantWithPreparedKPISettings = plantFromDB.updateIn(
            ['kpiSettings'],
            (kpiSettings: ImmutableList<ImmutableKPISetting>) =>
                kpiSettings.map((kpiSetting: ImmutableKPISetting) =>
                    kpiSetting
                        .delete('roundTo')
                        .delete('minRecommend')
                        .delete('maxRecommend')
                )
        );
        return plantWithPreparedKPISettings;
    };

    /**
     * Get the translation for this container.
     */
    getTranslation = (translationId: string) =>
        this.props.intl.formatMessage({
            id: `containers.PlantManagementContainer.PlantSetupContainer.${translationId}`,
        });

    /**
     * Get each step with their keys, and the translation label used for the progress bar
     */
    getSteps = () =>
        Object.keys(PLANT_CREATION_STEPS).map((key: PlantCreationStepConstant) => ({
            key,
            label: this.getTranslation(key),
        }));

    /**
     * On the review page, the next button should be the confirm button.
     * Otherwise it is the Next button.
     *
     * TODO: Should the `DONE` step have another button type?
     */
    getNextButtonType = () => {
        if (this.state.currentStep === PLANT_CREATION_STEPS.REVIEW) {
            return NEXT_BUTTON_TYPES.CONFIRM;
        }
        if (this.state.currentStep === PLANT_CREATION_STEPS.DONE) {
            return NEXT_BUTTON_TYPES.DONE;
        }
        return NEXT_BUTTON_TYPES.NEXT;
    };

    /**
     * Validate the PLANT Step has all required information
     */
    isPlantStepValid = (): boolean => {
        const { plant } = this.state;
        if (!plant) {
            return false;
        }

        return Boolean(
            plant &&
                plant.get('name') &&
                plant.get('clientId') &&
                plant.get('language') &&
                plant.get('units') &&
                plant.get('productionUnits')
        );
    };

    /**
     * Validate the KPI Step has all required information
     */
    isKPIStepValid = (): boolean => {
        const { plant } = this.state;
        if (!plant) {
            return false;
        }

        // Find a kpiSetting without the required fields
        const kpiSettingWithoutRequiredInformation =
            plant &&
            plant.get('kpiSettings').size &&
            plant.get('kpiSettings').find(
                (kpi: ImmutableKPISetting) =>
                    !kpi.get('kpiType') ||
                    !kpi.get('name') ||
                    !kpi.get('specificityType') ||
                    (kpi.get('isRequired') && (!kpi.get('minValid') || !kpi.get('maxValid'))) // If a kpi isRequired, it must have minValid & maxValid
            );
        return !kpiSettingWithoutRequiredInformation;
    };

    /**
     * Revaluate if user can continue to the next step in the creation/updating of a plant
     */
    isNextStepEnabled = (): boolean => {
        const { plant } = this.state;
        if (!plant) {
            return false;
        }

        switch (this.state.currentStep) {
            default:
            case PLANT_CREATION_STEPS.PLANT:
                return this.isPlantStepValid();
            case PLANT_CREATION_STEPS.KPIS:
                return this.isKPIStepValid();
            case PLANT_CREATION_STEPS.REVIEW:
                return this.isPlantStepValid() && this.isKPIStepValid();
            case PLANT_CREATION_STEPS.DONE:
                return true;
        }
    };

    /*
     * Adds provided KPISetting: ImmutableKPISetting to plant's kpiSettings list
     */
    handleAddKPISettingToPlant = (emptyKPISetting: ImmutableKPISetting) =>
        this.setState((prevState: State) => {
            const plant: ImmutablePlant = prevState.plant.updateIn(
                ['kpiSettings'],
                (kpiSettings: ImmutableList<ImmutableKPISetting>) =>
                    kpiSettings.push(emptyKPISetting)
            );
            return {
                plant,
            };
        });

    /**
     * Reorder a KPI Setting within a plant
     */
    handleReorderKPISetting = (originalKPISetting: ImmutableKPISetting) => (newOrder: number) =>
        this.setState((prevState: State) => ({
            plant:
                prevState.plant &&
                prevState.plant.update(
                    'kpiSettings',
                    (kpiSettings: ImmutableList<ImmutableKPISetting>) =>
                        kpiSettings
                            .map((kpiSetting: ImmutableKPISetting) => {
                                // if the new order is occupied by a kpiSetting already, change the order
                                // to the changed kpiSetting order
                                if (kpiSetting.get('order') === newOrder) {
                                    return kpiSetting.set('order', originalKPISetting.get('order'));
                                }
                                // if the kpiSetting is the one we want
                                if (
                                    kpiSetting.get('kpiType') === originalKPISetting.get('kpiType')
                                ) {
                                    return kpiSetting.set('order', newOrder);
                                }
                                // if it is neither, the previous order, or the changed kpiSetting, do not update.
                                return kpiSetting;
                            })
                            .sort(
                                (k1: ImmutableKPISetting, k2: ImmutableKPISetting) =>
                                    k1.get('order') - k2.get('order')
                            )
                ),
            isModified: true,
        }));

    /**
     * Remove a KPI Setting from a plant & reset order of those "below it"
     */
    handleRemoveKPISetting = (kpiSetting: ImmutableKPISetting) => () =>
        this.setState((prevState: State) => {
            if (!prevState.plant) {
                throw new Error('No local plant, something has gone wrong.');
            }

            // Since Plant KPIs have unique kpiTypes, find setting to delete based on sed value
            const kIdx = prevState.plant
                .get('kpiSettings')
                .findIndex(
                    (k: ImmutableKPISetting) => k.get('kpiType') === kpiSetting.get('kpiType')
                );

            if (kIdx === -1) {
                throw new Error(
                    `Cannot find kpi with type ${kpiSetting.get('kpiType')} in local plant.`
                );
            }

            return {
                plant: prevState.plant
                    .deleteIn(['kpiSettings', kIdx])
                    .updateIn(['kpiSettings'], (kpiSettings: ImmutableList<ImmutableKPISetting>) =>
                        kpiSettings.map((item: ImmutableKPISetting) => {
                            if (item.get('order') > kIdx) {
                                // anything above the field that we deleted, needs to have a lower order.
                                return item.set('order', item.get('order') - 1);
                            }
                            return item;
                        })
                    ),
                isModified: true,
            };
        });

    /**
     * Update provided field by key of local plant
     */
    handleInputChange = (key: LooseKeyArrayType, value: LooseInputValueTypes) =>
        this.setState((prevState: State) => {
            let plant: ImmutablePlant = prevState.plant;
            if (Array.isArray(key)) {
                // Append 'kpiSettings' to keys as KPISetupTable requires more generic logic
                key.unshift('kpiSettings');
                plant = plant.setIn(key, value);
            } else {
                plant = plant.set(key, value);
            }
            return {
                plant,
                isModified: true,
            };
        });

    /**
     * When the elevation container changes steps
     */
    handleChangeStep = (currentStep: PlantCreationStepConstant) => {
        // is the user going to the DONE screen?
        // if so, we want to confirm and send the data, and display a loader.
        if (currentStep === PLANT_CREATION_STEPS.DONE) {
            return this.handleConfirm();
        }

        // If they are not going to the done screen,
        // then just move on to the the changed step.
        this.setState({
            currentStep,
        });
    };

    /**
     * Did the user press the confirm button and save the plant?
     */
    handleConfirm = () => {
        const { plant } = this.state;
        if (plant && plant.get('id')) {
            this.props.updatePlant(plant.get('id'), plant);
        } else if (plant) {
            this.props.createPlant(plant);
        } else {
            throw Error('No local plant to save or update');
        }
    };

    /**
     * Allow user to edit a previous step from REVIEW step
     */
    handleReturnToStep = (currentStep: PlantCreationStepConstant) => () =>
        this.setState({ currentStep });

    renderElevationStepContent = () => {
        let content;
        switch (this.state.currentStep) {
            default:
            case PLANT_CREATION_STEPS.PLANT:
                content = (
                    <PlantStep
                        plant={this.state.plant}
                        clients={this.props.clients}
                        onInputChange={this.handleInputChange}
                    />
                );
                break;
            case PLANT_CREATION_STEPS.KPIS:
                content = (
                    <KPIStep
                        plant={this.state.plant}
                        onInputChange={this.handleInputChange}
                        onAddKPI={this.handleAddKPISettingToPlant}
                        onRemoveKPI={this.handleRemoveKPISetting}
                        onReorderKPI={this.handleReorderKPISetting}
                    />
                );
                break;
            case PLANT_CREATION_STEPS.REVIEW:
                content = (
                    <ReviewStep
                        clients={this.props.clients}
                        onReturnToStep={this.handleReturnToStep}
                        plant={this.state.plant}
                    />
                );
                break;
            case PLANT_CREATION_STEPS.DONE:
                content = (
                    <DoneStep
                        plant={this.state.plant}
                        isDownloadingTemplate={this.props.isDownloadingTemplate}
                        handleDownloadClicked={this.props.downloadTemplate}
                    />
                );
                break;
        }

        return content;
    };

    render() {
        return (
            <ElevationContainer
                elevationSteps={this.getSteps()}
                currentStep={this.state.currentStep}
                isModified={this.state.isModified}
                isNextEnabled={this.isNextStepEnabled()}
                isNextLoading={this.props.plantIsCreating || this.props.plantIsUpdating}
                nextButtonType={this.getNextButtonType()}
                onChangeStep={this.handleChangeStep}
                onExitBackwards={this.props.onExitCreateOrUpdateProcess}
                onExitForwards={this.props.onExitCreateOrUpdateProcess}
            >
                {this.renderElevationStepContent()}
            </ElevationContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectPlantErrors(),
    plantIsCreating: selectPlantIsCreating(),
    plantIsUpdating: selectPlantsAreUpdating(),
    user: selectUser(),
    clients: selectAllClients(),
    plants: selectAllPlants(),
    isDownloadingTemplate: selectIsDownloadingTemplate(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createPlant,
            updatePlant,
            fetchAllClients,
            downloadTemplate,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(PlantSetupContainer))
);
