// @flow strict

import styled from 'styled-components';
import { transparentize } from 'polished';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const LayoutContainer = styled.div`
    position: relative;

    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1;
`;

export const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50%;
    left: 0;

    background-image: url(${({ backgroundImage }: { backgroundImage: string }) => backgroundImage});
    background-position: center;
    background-size: cover;

    z-index: 0;
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0 32px;
    margin: 0 24px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 24px;

    font-size: 20px;
    line-height: normal;
    text-align: center;
`;

export const AppBlockingMessage = styled.div`
    text-align: center;
    background-color: ${LegacyTheme.loginLayout};
    box-shadow: 0 2px 10px 3px ${transparentize(0.75, LegacyTheme.loginLayoutShadow)};
    border-radius: 4px;
    padding: 15px 20px;
    margin: 0 auto;
    max-width: 300px;
    min-width: 200px;
`;

export const Content = styled.div`
    display: flex;
    min-height: 150px;
    max-height: 424px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        max-height: 255px;
    }

    color: ${LegacyTheme.defaultColor};
    font-size: 14px;
    line-height: 1.24;

    border: 1px solid ${LegacyTheme.defaultBorder};

    > div {
        width: 100%;

        padding: 24px;
        overflow-y: scroll;
    }

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;

    text-align: right;
`;

export const Note = styled.p`
    margin-top: 0;

    color: ${({ warning }: { warning: boolean }) =>
        warning ? LegacyTheme.defaultWarningColor : LegacyTheme.defaultColor};

    font-size: 14px;
`;

export const HiddenButton = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
`;
