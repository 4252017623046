// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';

import icon120 from 'assets/favicon/120.png';
import icon128 from 'assets/favicon/128.png';
import icon152 from 'assets/favicon/152.png';
import icon167 from 'assets/favicon/167.png';
import icon180 from 'assets/favicon/180.png';
import icon192 from 'assets/favicon/192.png';

import manifest from 'assets/favicon/site.webmanifest';

const MobileApp: React.FC = () => {
    return (
        <Helmet>
            <link href={icon120} rel="apple-touch-icon" />
            <link href={icon152} rel="apple-touch-icon" sizes="152x152" />
            <link href={icon167} rel="apple-touch-icon" sizes="167x167" />
            <link href={icon180} rel="apple-touch-icon" sizes="180x180" />
            <link href={icon192} rel="icon" sizes="192x192" />
            <link href={icon128} rel="icon" sizes="128x128" />
            <meta name="apple-mobile-web-app-title" content="SolvExtract™️" />
            <link href={manifest} rel="manifest" />
        </Helmet>
    );
};

export default MobileApp;
