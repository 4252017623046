// @flow strict

import styled from 'styled-components';

export const ErrorMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    details {
        max-width: 90%;
    }
`;
