// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Label = styled.label`
    min-width: 130px;
    font-size: 13px;
    line-height: 16px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    margin-bottom: 5px;
`;

export const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 16px;
    }
`;

export const SelectField = styled(Field)`
    flex-direction: column;
    align-items: flex-start;
`;
