// @flow strict

import { decamelize, decamelizeKeys } from 'humps';

import request from 'services/request';

import type { NewUser, UserSearchCriteria } from './types';
import type { ImmutableUser } from 'services/Authentication/types';

/**
 * Create user
 */
export function create(user: NewUser): Promise<NewUser> {
    return request(`users`, {
        method: 'POST',
        data: user,
    });
}

/**
 * Index users
 */
export const index = () => request('v2/users', { method: 'GET' });

/**
 * Query users
 */
export const query = (
    searchCriteria: UserSearchCriteria,
    page: number = 1,
    perPage: number = 10
) => {
    const options = decamelizeKeys({
        page,
        perPage,
        ...searchCriteria,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
    });
    return request('users', { method: 'GET' }, { ...options });
};
/**
 * Query users v2
 */
export const queryV2 = (
    searchCriteria: UserSearchCriteria,
    page: number = 1,
    perPage: number = 10
) => {
    const options = decamelizeKeys({
        page,
        perPage,
        ...searchCriteria,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
    });
    return request('v2/users', { method: 'GET' }, { ...options });
};
/**
 * Update user
 */
export const update = (user: ImmutableUser) =>
    request(`users/${user.id}`, {
        method: 'PUT',
        data: user,
    });

/**
 * Delete user
 */
export const del = (userEmail: string) =>
    request(`users`, {
        method: 'DELETE',
        data: {
            email: userEmail,
        },
    });

/**
 * Index roles
 */
export const indexRoles = () =>
    request('roles', {
        method: 'GET',
    });
