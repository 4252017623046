// @flow strict

/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl';

import flatten from 'flat';

import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import zhLocaleData from 'react-intl/locale-data/zh';

import { DEFAULT_LOCALE } from 'services/Language/actions';

// Translations
import enTranslationMessages from 'translations/en.json';
import esTranslationMessages from 'translations/es.json';
import zhTranslationMessages from 'translations/zh.json';

export const appLocales = ['en', 'es', 'zh'];

addLocaleData(enLocaleData);
addLocaleData(esLocaleData);
addLocaleData(zhLocaleData);

export const formatTranslationMessages = (locale: string, messages: Object) => {
    const defaultFormattedMessages =
        locale !== DEFAULT_LOCALE
            ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
            : {};
    const formattedMessages = {};
    const messageKeys = Object.keys(messages);

    for (const messageKey of messageKeys) {
        if (locale === DEFAULT_LOCALE) {
            formattedMessages[messageKey] = messages[messageKey];
        } else {
            formattedMessages[messageKey] =
                messages[messageKey] || defaultFormattedMessages[messageKey];
        }
    }

    return formattedMessages;
};

export const translationMessages = {
    en: formatTranslationMessages('en', flatten(enTranslationMessages)),
    es: formatTranslationMessages('es', flatten(esTranslationMessages)),
    zh: formatTranslationMessages('zh', flatten(zhTranslationMessages)),
};
