// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Services
import { queryAllPlants } from 'services/Plant/thunks';
import { selectPlantsAreQuerying, selectPlantQuery } from 'services/Plant/selectors';

import { setReagentPlantAccess } from 'services/Reagent/thunks';
import {
    selectReagentIsSettingPlantAccess,
    selectReagentUpdateErrors,
} from 'services/Reagent/selectors';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    DeleteButtonWrapper,
    ListWrapper,
    ListRow,
    OptionalLabel,
    FooterButtons,
} from './styles';
import CircleDeleteIcon from 'assets/icons/circle-delete-icon';
import { Common, PrimaryButton, TertiaryButton, Modal, Toggle } from 'components/_ReactUI_V1';

// Components
import { Title } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';
import SelectWithInfiniteScroll from 'components/SelectWithInfiniteScroll';

// Types
import type {
    IntlType,
    ReduxDispatch,
    ReactSelectObject,
    ImmutableList,
    ErrorType,
    ImmutableQueryStructure,
} from 'types';
import type {
    ImmutableReagent,
    ImmutableReagentPlantAccess,
    SetReagentPlantAccessStructure,
} from 'services/Reagent/types';
import type { ImmutablePlant, PlantSearchCriteria, PlantQueryType } from 'services/Plant/types';

// Constant
import { MODAL_WIDTH, STYLE_VALUES, ROLES } from 'utils/constants';

type Props = {
    intl: IntlType,
    reagent: ImmutableReagent,

    isLoadingPlants: boolean,
    plantQueryData: ?PlantQueryType,
    queryAllPlants: (searchCriteria: PlantSearchCriteria, page?: number, perPage?: number) => void,

    isSettingPlantAccess: boolean,
    reagentUpdateErrors: ErrorType,
    setReagentPlantAccess: (id: number, access: SetReagentPlantAccessStructure) => void,
    onCloseModal: () => void,
};

type State = {
    showErrors: boolean,

    selectedPlant: ?ImmutablePlant,

    isCustom: boolean,
    plantAccesses: ImmutableList<ImmutableReagentPlantAccess>,
};

/**
 * Shown to a SAM or Admin when they want to share an isotherm with PMs or Plants.
 */
class ReagentPlantAssignmentModal extends React.PureComponent<Props, State> {
    userListScrollRef = React.createRef();

    plantSelectorRef = React.createRef();

    state = {
        showErrors: false, // no error has occurred yet.

        selectedPlant: null,
        isCustom: this.props.reagent.get('isCustom', false),

        plantAccesses: this.props.reagent.get('reagentPlantAccesses', []),
    };

    /**
     * When the component updates,
     * we need to check if we are done saving
     *      If we are, then close the modal.
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.isSettingPlantAccess && !this.props.isSettingPlantAccess) {
            if (this.props.reagentUpdateErrors.isEmpty()) {
                this.props.onCloseModal();
            }
            // Else: errors will be displayed by the renderError() function if prop errors not empty
        }
    }

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.Modals.ReagentPlantAssignmentModal.${id}`,
            },
            data
        );

    getPlantsLeftToQuery = (): number => {
        const plantQuery = this.props.plantQueryData;
        if (!plantQuery) {
            // By default the application has a null object for the plantQueryData.
            // This indicates that we’ve never fetched a plant query.
            // If we’ve never fetched one, we should forcefully fetch by saying there is 1 left to query.
            // After the initial query, the plantQueryData will no longer be null and will contain the real information of how many plants are left.
            return 1;
        }
        return plantQuery.get('total') - plantQuery.get('to');
    };

    /**
     * When a user clicks on the "Allow" button we need to add a new plant access to our state
     */
    handleAddPlant = () => {
        return this.setState(
            (prevState: State) => {
                const selectedPlant = prevState.selectedPlant;
                if (!selectedPlant) {
                    throw new Error('No plant to add...');
                }
                const newPlantAccess = fromJS({
                    plantId: selectedPlant.get('id'),
                    plantName: selectedPlant.get('name'),
                });
                return {
                    selectedPlant: null,
                    plantAccesses: prevState.plantAccesses.unshift(newPlantAccess),
                };
            },
            () => {
                if (this.plantSelectorRef && this.plantSelectorRef.current) {
                    const plantSelector = this.plantSelectorRef.current;
                    plantSelector.clearSelection();

                    // Did we reach the end of the list?
                    // And are there still plants left in the backend?
                    if (this.getPlantsLeftToQuery() > 0 && !plantSelector.hasOptionsLeft()) {
                        if (this.props.isLoadingPlants) {
                            return;
                        }
                        plantSelector.queryNextPage();
                    }
                }
            }
        );
    };

    handleSelectPlant = (selectedPlant: ImmutablePlant) => {
        this.setState({
            selectedPlant,
        });
    };

    /**
     * A plant is not selectable if there is already an access for that plant.
     */
    handleFilterSelectablePlants = (plant: ImmutablePlant) => {
        return !this.state.plantAccesses.find(
            (plantAccess: ImmutableReagentPlantAccess) =>
                plant.get('id') === plantAccess.get('plantId')
        );
    };

    /**
     * Removes the plant from the reagent's access
     */
    handleRemovePlant = (plantAccess: ImmutableReagentPlantAccess) => () =>
        this.setState((prevState: State) => {
            const idx = prevState.plantAccesses.findIndex(
                (statePlantAccess: ImmutableReagentPlantAccess) =>
                    plantAccess.get('plantId') === statePlantAccess.get('plantId')
            );
            if (idx === -1) {
                return;
            }
            return {
                plantAccesses: prevState.plantAccesses.remove(idx),
            };
        });

    /**
     * A reagent must be marked as custom before being able to assign a plant to it.
     */
    handleToggleIsCustom = () =>
        this.setState((prevState: State) => ({
            isCustom: !prevState.isCustom,
        }));

    /**
     * Sends the request to the backend.
     */
    handleConfirm = () =>
        this.setState(
            {
                showErrors: true,
            },
            () => {
                const plantIds = this.state.plantAccesses
                    .map((plantAccess: ImmutableReagentPlantAccess) => plantAccess.get('plantId'))
                    .toJS();
                const access = {
                    isCustom: this.state.isCustom,
                    plantIds,
                };
                this.props.setReagentPlantAccess(this.props.reagent.get('id'), access);
            }
        );

    /**
     * Renders the list of plants with direct access or renders an optional label
     */
    renderPlantAccesses = () => {
        const plants = this.state.plantAccesses;
        const title = plants.size > 0 ? 'ListOfPlants' : 'NoPlantAccess';
        const LabelClass = plants.size > 0 ? Label : OptionalLabel;
        return (
            <Common.Column>
                <LabelClass>{this.getTranslation(title)}</LabelClass>
                {plants.size > 0 && (
                    <ListWrapper ref={this.userListScrollRef}>
                        {plants.map((plantAccess: ImmutableReagentPlantAccess) => (
                            <ListRow key={plantAccess.get('plantId')}>
                                <p>{plantAccess.get('plantName')}</p>
                                {!this.props.isSettingPlantAccess && (
                                    <DeleteButtonWrapper
                                        onClick={this.handleRemovePlant(plantAccess)}
                                    >
                                        <CircleDeleteIcon />
                                    </DeleteButtonWrapper>
                                )}
                            </ListRow>
                        ))}
                    </ListWrapper>
                )}
            </Common.Column>
        );
    };

    /**
     * Renders any errors that have occurred.
     */
    renderErrors = () => {
        if (this.props.isSettingPlantAccess) {
            return null;
        }
        if (this.props.reagentUpdateErrors.isEmpty()) {
            return null;
        }
        const fallbackCode = 'feedback.error.updateReagentFailed';
        return (
            <ErrorMessage
                errorCode={fallbackCode}
                errorType={this.props.reagentUpdateErrors}
                style={{
                    marginBottom: '10px',
                    marginTop: '0',
                    textAlign: 'center',
                }}
                isRed
                isSmall
            />
        );
    };

    render() {
        return (
            <Modal onHandleClose={this.props.onCloseModal} modalWidth={MODAL_WIDTH.SMALL}>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.getTranslation('title', {
                                reagentName: this.props.reagent.get('name'),
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <Label>{this.getTranslation('isCustom.Label')}</Label>
                            <Toggle
                                checked={this.state.isCustom}
                                onClickHandler={this.handleToggleIsCustom}
                                disabled={
                                    this.state.plantAccesses.size !== 0 ||
                                    this.props.isSettingPlantAccess
                                }
                            />
                            <OptionalLabel>
                                {this.getTranslation('isCustom.instructions')}
                            </OptionalLabel>
                        </Field>
                        {this.state.isCustom && (
                            <Field>
                                <Label>{this.getTranslation('plantAccesses.Label')}</Label>
                                <Common.Row
                                    style={{
                                        marginTop: 0,
                                    }}
                                >
                                    <SelectWithInfiniteScroll
                                        ref={this.plantSelectorRef}
                                        placeholder={this.getTranslation(
                                            'plantAccesses.Placeholder'
                                        )}
                                        noOptionsMessage={this.getTranslation(
                                            'plantAccesses.NoPlants'
                                        )}
                                        isLoading={this.props.isLoadingPlants}
                                        onSelect={this.handleSelectPlant}
                                        queryData={this.props.plantQueryData}
                                        queryPage={this.props.queryAllPlants}
                                        dataIdKey="id"
                                        dataLabelKey="name"
                                        filterSelect={this.handleFilterSelectablePlants}
                                    />
                                    <PrimaryButton
                                        text={this.getTranslation('plantAccesses.AddPlantButton')}
                                        onClick={this.handleAddPlant}
                                        disabled={!this.state.selectedPlant}
                                        style={{
                                            flexShrink: 0,
                                            marginLeft: '10px',
                                        }}
                                    />
                                </Common.Row>
                                {this.renderPlantAccesses()}
                            </Field>
                        )}
                    </Body>
                    <Footer>
                        {this.renderErrors()}
                        <FooterButtons>
                            <TertiaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.cancelButton',
                                })}
                                onClick={this.props.onCloseModal}
                            />
                            <PrimaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.saveButton',
                                })}
                                loading={this.props.isSettingPlantAccess}
                                onClick={this.handleConfirm}
                            />
                        </FooterButtons>
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

const mapStateToProps = (_, props: Props) =>
    createStructuredSelector({
        plantQueryData: selectPlantQuery(),
        isLoadingPlants: selectPlantsAreQuerying(),

        isSettingPlantAccess: selectReagentIsSettingPlantAccess(),
        reagentUpdateErrors: selectReagentUpdateErrors(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            queryAllPlants,
            setReagentPlantAccess,
        },
        dispatch
    );

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReagentPlantAssignmentModal)
);
