// @flow

import styled from 'styled-components';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

const StyledRemovableLabel = styled.div`
    width: 100%;
    margin-top: ${({ marginTop }: Object) => marginTop || '0px'};
    background-color: transparent;
    position: relative;
`;

StyledRemovableLabel.Label = styled.div`
    display: flex;
    justify-content: center;

    padding: 10px 5px;

    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['removableLabel', 'color'])};
    font-size: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['removableLabel', 'fontSize'])};

    border-radius: 4px;
    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['removableLabel', 'backgroundColor'])};
    box-shadow: inset 0 -1px 0 0 ${({ theme }: Object) => themePropertyFetcher(theme, ['removableLabel', 'backgroundColor'])},
        inset 0 1px 0 0
            ${({ theme }: Object) =>
                themePropertyFetcher(theme, ['removableLabel', 'backgroundColor'])},
        0 1px 3px 0 rgba(0, 0, 0, 0.05);
`;

StyledRemovableLabel.CloseButtonWrapper = styled.div`
    display: flex;
    align-iterms: center;

    width: 13px;
    height: 13px;
    padding: 2px;
    border-radius: 50%;

    position: absolute;
    top: -10px;
    left: calc(100% + 2px);

    margin-top: 0px;

    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['removableLabel', 'backgroundColor'])};
    box-shadow: inset 0 -1px 0 0 ${({ theme }: Object) => themePropertyFetcher(theme, ['removableLabel', 'backgroundColor'])},
        inset 0 1px 0 0
            ${({ theme }: Object) =>
                themePropertyFetcher(theme, ['removableLabel', 'backgroundColor'])},
        0 1px 3px 0 rgba(0, 0, 0, 0.05);
`;

export default StyledRemovableLabel;
