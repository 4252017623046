// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type { ImmutableClientState } from './types';

/**
 * Direct selector to the client state domain
 */
export const selectClientState = () => (state: ImmutableReduxState) => state.get('client');

/**
 * Select list of clients
 */
export const selectAllClients = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.get('clients').toList()
    );

/**
 * Select client errors
 */
export const selectClientErrors = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.get('errors')
    );

/**
 * Gets isFetching status while clients are being fetched: to hide list or show loading animation
 */
export const selectClientsAreFetching = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.get('isFetching')
    );

/**
 * Gets isUpdating status while clients are being updated: to disable inputs
 */
export const selectClientsAreUpdating = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.get('isUpdating')
    );

/**
 * Gets isDeleting status while clients are being fetched: to hide list or show loading animation
 */
export const selectClientsAreDeleting = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.get('isDeleting')
    );

/**
 * Gets isCreating status while clients are being updated: to disable inputs
 */
export const selectClientsAreCreating = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.get('isCreating')
    );

/**
 * Select list of queried clients results
 */
export const selectQueriedClients = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.getIn(['query', 'data']).toList()
    );

/**
 * Select last page (page count) of queried clients
 */
export const selectQueriedClientsLastPage = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.getIn(['query', 'lastPage'])
    );

/**
 * Gets isQuerying status while clients are being queried: to hide list or show loading animation
 */
export const selectClientsAreQuerying = () =>
    createSelector(
        selectClientState(),
        (substate: ImmutableClientState) => substate.get('isQuerying')
    );
