// @flow strict

import type { InputEvent } from 'types';

export const CHANGE_LOCALE = 'app/Language/CHANGE_LOCALE';
export const DEFAULT_LOCALE = 'en';

import { getFirebaseAuth } from 'utils/authentication';

export function changeLocale({ target: { value: languageLocale } }: InputEvent) {
    getFirebaseAuth().languageCode = languageLocale;

    return {
        type: CHANGE_LOCALE,
        payload: {
            locale: languageLocale,
        },
    };
}
