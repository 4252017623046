// @flow

import styled from 'styled-components';
import { StyledInputBase } from '../input-field';
import LegacyTheme from '../../themes/legacyTheme';

export const CloseIcon = styled.div`
    display: flex;

    margin-right: ${({ position }: Object) => (position === 'left' ? `12px` : '0px')};
    margin-left: ${({ position }: Object) => (position === 'right' ? `12px` : '0px')};
    border-radius: 50%;

    padding: 4px;

    &:hover {
        background-color: ${LegacyTheme.removableLabelBackground};
        color: ${LegacyTheme.removableLabelCloseButtonFill};
    }

    cursor: ${({ onClick }: Object) => (onClick ? 'pointer' : 'default')};

    svg {
        display: block;
    }
`;

export const MultipleInput = styled.input`
    ${StyledInputBase}
`;

export const MultipleInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    margin-bottom: 8px;
`;
