// @flow strict

import React from 'react';

// Mimic Engine
import type { IMimicStream } from 'components/_McCabeThiele';

// Constants
import {
    ORGANIC_CIRCUIT_TYPE,
    STREAM_PATH_TYPES,
    SETUP_GRID,
    COMPUTE_GRID,
    PATH_TYPES,
} from 'components/MimicDiagram/constants';
import { STREAM_CIRCUITS, STREAM_TYPES, STAGE_TYPES, DIAGRAM_DISPLAY_MODES } from 'utils/constants';

// Styles
import { ArrowPath } from './styles';

// Helpers
import {
    getPortPositions,
    renderPath,
    getPosition,
    getSkipTopOffsetPosition,
} from 'components/MimicDiagram/Stream/helpers';
import { getStreamKey } from 'utils/helpers';

// Types
import type {
    StageTypeConstant,
    StreamEntity,
    StreamTypeConstant,
    StreamCircuitConstant,
    OrganicCircuitConstant,
} from 'services/Circuit/types';
import type {
    DiagramDisplayModesConstant,
    GridType,
    PathType,
} from 'components/MimicDiagram/types';

type Props = {
    displayMode: DiagramDisplayModesConstant,
    organicCircuitType: OrganicCircuitConstant,

    lastStageColumn: number,

    mimicStream: IMimicStream,

    isAdvancedStream: boolean, // is this stream an advanced stream (only used for bleed/blends in Aqueous)
    isEdgeStream: boolean, // is this an edge stream? These are calculated differently.
    isSkipInOutStream: boolean, // is this a stream that goes in/out of a skip stream?
    streamData: StreamEntity,
    isFromNearestExtractorStreamToLOTank: boolean,
    isToNearestExtractorStreamFromLOTank: boolean,

    // Feeds don't have a fromStage.
    fromStageColumn: ?number,
    fromStageRow: ?number,
    fromStageType: ?StageTypeConstant,
    // Bleeds don't have a toStage.
    toStageColumn: ?number,
    toStageRow: ?number,
    toStageType: ?StageTypeConstant,

    // hasExtractBypass: boolean, // for proper calculation of washer / stream values positioning.
    // hasStripBypass: boolean, // for proper calculation of washer / stream values positioning.
};

/**
 * The stream path component represents the SVG path of a stream.
 * This class is responsible for rendering the stream itself, not the values.
 */
export default class StreamPath extends React.PureComponent<Props> {
    getStreamCircuit = (): StreamCircuitConstant => this.props.streamData.streamCircuit;

    getStreamType = (): StreamTypeConstant => this.props.streamData.streamType;

    getStreamId = (): number => this.props.streamData.id;

    getStreamKey = (): number | string => getStreamKey(this.props.streamData);

    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * Get SVG path of the following format: M<X,Y> L<X,Y> [L<X,Y>, L<X,Y>]
     */
    getPath = (pathType: PathType): string => {
        if (!pathType) {
            return '';
        }
        const grid = this.getGrid();

        const ports = getPortPositions(pathType, grid, this.props.displayMode);

        let from = null;
        let to = null;

        if (this.props.isSkipInOutStream) {
            let columnOffset = grid.STREAM_VALUES.COLUMN_SPAN;

            // $FlowIgnore -- Any stream that is `in/out` of a skip stream will have the parent stream as an ISkipStream.
            const skipStream: ISkipStream = this.props.mimicStream.parentStream;

            const nestedSkipStreams = skipStream.getSkipStreamCountBelow();
            const totalSkipOffset = grid.SKIP.TOP_OFFSET * nestedSkipStreams;

            const isBleed = !this.props.toStageType;
            const isBlend = !this.props.fromStageType;

            if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
                if (isBleed) {
                    ports.from.x = ports.from.x + grid.GRID.COLUMN_GAP;
                    ports.from.y = ports.from.y - grid.GRID.ROW_GAP / 2;

                    ports.to.x = ports.to.x - grid.GRID.COLUMN_GAP;
                    ports.to.y = ports.to.y - grid.GRID.ROW_GAP / 2;
                } else if (isBlend) {
                    ports.from.x = ports.from.x + grid.GRID.COLUMN_GAP;

                    ports.to.x = ports.to.x - grid.GRID.COLUMN_GAP;
                    ports.to.y = ports.to.y - grid.GRID.ROW_GAP / 2;
                }
            } else {
                if (isBleed) {
                    columnOffset = 0;
                    ports.to.x = ports.to.x + grid.GRID.COLUMN_WIDTH / 2;

                    ports.from.x = ports.to.x;
                    ports.from.y = ports.to.y + grid.GRID.ROW_HEIGHT / 2 + grid.GRID.ROW_GAP;
                } else if (isBlend) {
                    ports.from.x = ports.from.x + grid.GRID.COLUMN_WIDTH + grid.GRID.COLUMN_GAP;

                    ports.to.x = ports.to.x + grid.GRID.COLUMN_GAP;
                    ports.to.y = ports.to.y - grid.GRID.ROW_HEIGHT / 2;
                }
            }

            if (isBleed) {
                // Bleed stream from skip stream
                from = getPosition(
                    this.props.fromStageColumn + columnOffset,
                    this.props.fromStageRow - totalSkipOffset,
                    ports.from,
                    grid
                );
                // Bleed stream from skip stream
                to = getPosition(
                    this.props.fromStageColumn + columnOffset,
                    this.props.fromStageRow - totalSkipOffset,
                    ports.to,
                    grid
                );
            } else if (isBlend) {
                // Blend stream into skip
                from = getPosition(
                    this.props.toStageColumn - columnOffset,
                    this.props.toStageRow - totalSkipOffset,
                    ports.from,
                    grid
                );
                to = getPosition(
                    this.props.toStageColumn - columnOffset,
                    this.props.toStageRow - totalSkipOffset,
                    ports.to,
                    grid
                );
            }
        } else {
            if (!this.props.fromStageType) {
                // If the from stage is null it is a feed. therefore only has a to stage
                from = getPosition(
                    this.props.toStageColumn,
                    this.props.toStageRow,
                    ports.from,
                    grid
                );
            } else {
                from = getPosition(
                    this.props.fromStageColumn,
                    this.props.fromStageRow,
                    ports.from,
                    grid
                );
            }
            if (!this.props.toStageType) {
                // If the from stage is null it is a feed. therefore only has a to stage
                to = getPosition(
                    this.props.fromStageColumn,
                    this.props.fromStageRow,
                    ports.to,
                    grid
                );
            } else {
                to = getPosition(this.props.toStageColumn, this.props.toStageRow, ports.to, grid);
            }
        }

        const deltaX = to.x - from.x; // This is why bypasses with > x is wrong.
        const deltaY = to.y - from.y;
        const outPortX = from.x;
        const outPortY = from.y;
        const inPortX = to.x;
        const inPortY = to.y;
        return `M${outPortX},${outPortY} ${renderPath(
            pathType,
            grid,
            outPortX, // start x,
            outPortY, // start y
            Math.abs(deltaX), // width
            Math.abs(deltaY), // height
            this.props.lastStageColumn,
            inPortX, // end x
            inPortY, // end y
            this.props.isEdgeStream,
            this.props.mimicStream
        )}`;
    };

    /**
     * Get the appropriate path type depending on strema circuit,
     * if there are extraction and/or stripping stages
     * and if there are more of certain types of stages.
     */
    getPathType = (): PathType => {
        const streamType = this.props.streamData.streamType;
        const streamCircuit = this.props.streamData.streamCircuit;
        let pathType = null;
        if (streamCircuit === STREAM_CIRCUITS.ORGANIC) {
            let subType = this.props.streamData.streamType;

            if (this.props.organicCircuitType === ORGANIC_CIRCUIT_TYPE.BOTH) {
                if (!this.props.fromStageType || !this.props.toStageType) {
                    throw new Error('Organic circuit type = both, but no fromStage or toStage');
                }

                // Get advanced streams path types. Advanced organic stream can only occur between stages of different type.
                if (
                    this.props.fromStageType !== this.props.toStageType &&
                    streamType !== STREAM_TYPES.CONTINUE
                ) {
                    if (
                        streamType === STREAM_TYPES.BYPASS_FEED ||
                        streamType === STREAM_TYPES.BYPASS_BLEND
                    ) {
                        if (this.props.fromStageType === STAGE_TYPES.ORGANIC_TANK) {
                            if (this.props.isToNearestExtractorStreamFromLOTank) {
                                subType = `${this.props.fromStageType}_TO_${
                                    this.props.toStageType
                                }_${streamType}_FIRST`;
                            } else {
                                subType = `${this.props.fromStageType}_TO_${
                                    this.props.toStageType
                                }_${streamType}`;
                            }
                        } else {
                            subType = `${this.props.toStageType}_${streamType}`;
                        }
                    } else if (
                        streamType === STREAM_TYPES.BYPASS_BLEED &&
                        (this.props.toStageType === STAGE_TYPES.ORGANIC_TANK ||
                            this.props.fromStageType === STAGE_TYPES.ORGANIC_TANK)
                    ) {
                        if (this.props.isFromNearestExtractorStreamToLOTank) {
                            subType = `${this.props.fromStageType}_${streamType}_TO_${
                                this.props.toStageType
                            }_FIRST`;
                        } else {
                            subType = `${this.props.fromStageType}_${streamType}_TO_${
                                this.props.toStageType
                            }`;
                        }
                    } else if (streamType === STREAM_TYPES.BYPASS_BLEED) {
                        subType = `${this.props.fromStageType}_${streamType}`;
                    }
                } else {
                    subType = `${this.props.fromStageType}-${this.props.toStageType}`;
                    if (
                        this.props.fromStageType === STAGE_TYPES.STRIP &&
                        this.props.toStageType === STAGE_TYPES.EXTRACT
                    ) {
                        if (this.props.toStageColumn > this.props.fromStageColumn) {
                            subType += '-MORE_EXTRACT';
                        } else if (this.props.toStageColumn < this.props.fromStageColumn) {
                            subType += '-MORE_STRIP';
                        }
                    } else if (
                        this.props.fromStageType === STAGE_TYPES.EXTRACT &&
                        this.props.toStageType === STAGE_TYPES.ORGANIC_TANK
                    ) {
                        subType = `${this.props.fromStageType}_${streamType}_TO_${
                            this.props.toStageType
                        }`;
                    } else if (
                        this.props.fromStageType === STAGE_TYPES.ORGANIC_TANK &&
                        this.props.toStageType === STAGE_TYPES.STRIP
                    ) {
                        subType = `${this.props.fromStageType}_${streamType}_TO_${
                            this.props.toStageType
                        }`;
                    } else if (
                        this.props.fromStageType === STAGE_TYPES.ORGANIC_TANK &&
                        this.props.toStageType === STAGE_TYPES.ORGANIC_TANK
                    ) {
                        subType = `${this.props.fromStageType}_TO_${
                            this.props.toStageType
                        }_${streamType}`;
                    }
                }
            }

            pathType =
                STREAM_PATH_TYPES[`${STREAM_CIRCUITS.ORGANIC}-${this.props.organicCircuitType}`][
                    subType
                ];
        } else {
            pathType =
                STREAM_PATH_TYPES[this.props.streamData.streamCircuit][
                    this.props.streamData.streamType
                ];
        }

        if (
            [
                PATH_TYPES.RIGHT_FEED,
                PATH_TYPES.LEFT_FEED,
                PATH_TYPES.LEFT_BLEED,
                PATH_TYPES.RIGHT_BLEED,
            ].indexOf(pathType) !== -1 &&
            (this.props.isEdgeStream || this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) // setup mode feed/bleeds are all edge feed/bleeds
        ) {
            // If this is an edge stream and a bleed/feed, then it is calculated differently.
            pathType = `${pathType}_EDGE`;
        }
        if (
            [PATH_TYPES.RIGHT_BLEED, PATH_TYPES.BLEND].indexOf(pathType) !== -1 &&
            this.props.isAdvancedStream
        ) {
            pathType = `${pathType}_ADVANCED`;
        }

        return pathType;
    };

    hasEndArrow = (pathType: PathType) => {
        const pathsWithoutArrows = [PATH_TYPES.WASHER_TO_ORGANIC_TANK];
        return !pathsWithoutArrows.includes(pathType);
    };

    render() {
        const pathType = this.getPathType();
        const streamCircuit = this.getStreamCircuit();
        const markerEnd = this.hasEndArrow(pathType) ? `url(#${streamCircuit})` : null;
        return (
            <ArrowPath
                d={this.getPath(pathType)}
                data-pathtype={pathType}
                markerEnd={markerEnd}
                shapeRendering="crispEdges"
                streamCircuit={streamCircuit}
            />
        );
    }
}
