// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type { ImmutableOximeState } from './types';

/**
 * Direct selector to the oxime state domain
 */
export const selectOximeState = () => (state: ImmutableReduxState) => state.get('oxime');

/**
 * Select list of oximes
 */
export const selectAllOximes = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.get('oximes').toList()
    );

/**
 * Select oxime errors
 */
export const selectOximeErrors = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.get('errors')
    );

/**
 * Gets isFetching status while oximes are being fetched: to hide list or show loading animation
 */
export const selectOximesAreFetching = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.get('isFetching')
    );

/**
 * Gets isUpdating status while oximes are being updated: to disable inputs
 */
export const selectOximesAreUpdating = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.get('isUpdating')
    );

/**
 * Gets isFetching status while oximes are being fetched: to hide list or show loading animation
 */
export const selectOximesAreDeleting = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.get('isDeleting')
    );

/**
 * Gets isUpdating status while oximes are being updated: to disable inputs
 */
export const selectOximesAreCreating = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.get('isCreating')
    );

/**
 * Select list of queried oximes results
 */
export const selectQueriedOximes = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.getIn(['query', 'data']).toList()
    );

/**
 * Select last page (page count) of queried oximes
 */
export const selectQueriedOximesLastPage = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.getIn(['query', 'lastPage'])
    );

/**
 * Gets isQuerying status while oximes are being queried: to hide list or show loading animation
 */
export const selectOximesAreQuerying = () =>
    createSelector(
        selectOximeState(),
        (substate: ImmutableOximeState) => substate.get('isQuerying')
    );
