// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { CheckBox } from 'components/_ReactUI_V1';

import {
    Field,
    FieldHeader,
    CheckBoxStyle,
    Label,
    NotificationTypesWrapper,
    NotificationTypeRow,
    MessageWrapper,
} from './styles';

// Components

// Types
import type { IntlType } from 'types';
import type {
    ImmutableNotificationSettings,
    NotificationChannel,
} from 'services/Notifications/types';

type Props = {
    intl: IntlType,
    phoneNumberExists: boolean,
    notificationSettings: ImmutableNotificationSettings,
    onClickNotificationSetting: (notificationType: string, channel: NotificationChannel) => void,
};

class UserNotificationTypes extends React.PureComponent<Props> {
    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.UserSettings.NotificationSettings.${key}`,
            },
            data
        );

    handleToggleCheck = (notificationType: string, channel: NotificationChannel) => {
        this.props.onClickNotificationSetting(notificationType, channel);
    };

    render() {
        return (
            <Field>
                <FieldHeader>{this.getTranslation('notificationTypesTitle')}</FieldHeader>

                <NotificationTypesWrapper>
                    <NotificationTypeRow>
                        <Label>{this.getTranslation('inApp')}</Label>
                    </NotificationTypeRow>
                    <NotificationTypeRow>
                        <Label>{this.getTranslation('email')}</Label>
                    </NotificationTypeRow>
                    <NotificationTypeRow>
                        <Label>{this.getTranslation('sms')}</Label>
                    </NotificationTypeRow>
                    <Label>{this.getTranslation('description')}</Label>
                </NotificationTypesWrapper>

                {this.props.notificationSettings.map((notificationSetting) => (
                    <NotificationTypesWrapper key={notificationSetting.get('notificationTypeId')}>
                        <NotificationTypeRow>
                            {/* This is the in-app notification settings */}
                            <CheckBox readOnlyChecked disabled styles={CheckBoxStyle} />
                        </NotificationTypeRow>
                        <NotificationTypeRow>
                            <CheckBox
                                checked={notificationSetting.get('emailNotification')}
                                onClickHandler={() =>
                                    this.handleToggleCheck(
                                        notificationSetting.get('notificationType'),
                                        'email'
                                    )
                                }
                                styles={CheckBoxStyle}
                            />
                        </NotificationTypeRow>
                        <NotificationTypeRow>
                            <CheckBox
                                disabled={!this.props.phoneNumberExists}
                                checked={
                                    this.props.phoneNumberExists &&
                                    notificationSetting.get('smsNotification')
                                }
                                onClickHandler={() =>
                                    this.handleToggleCheck(
                                        notificationSetting.get('notificationType'),
                                        'sms'
                                    )
                                }
                                styles={CheckBoxStyle}
                            />
                        </NotificationTypeRow>
                        <Label>
                            {this.props.intl.formatMessage({
                                id: `notifications.types.${notificationSetting.get(
                                    'notificationType'
                                )}`,
                            })}
                        </Label>
                    </NotificationTypesWrapper>
                ))}
                {!this.props.phoneNumberExists && (
                    <MessageWrapper>
                        <Label>
                            {this.props.intl.formatMessage({
                                id:
                                    'components.UserSettings.SolvExtractSettings.missingPhoneNumber',
                            })}
                        </Label>
                    </MessageWrapper>
                )}
            </Field>
        );
    }
}

export default injectIntl(UserNotificationTypes);
