// @flow strict

/*
 *
 * Client reducer
 *
 */

import { fromJS } from 'immutable';

import type { GenericActionType } from 'types';
import { deleteEntityFromSubStateLists, setEntityIntoSubStateLists } from '../utils';

import {
    CREATE_SUCCESS,
    CREATE_FAILURE,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DESTROY_SUCCESS,
    DESTROY_FAILURE,
    QUERY_LIST_SUCCESS,
    QUERY_LIST_FAILURE,
    IS_FETCHING,
    IS_UPDATING,
    IS_DELETING,
    IS_CREATING,
    IS_QUERYING,
} from './actions';

import type { ImmutableClient, ImmutableClientState } from './types';

const initialState: ImmutableClientState = fromJS({
    clients: [],
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isQuerying: false,
    query: {
        data: [],
        lastPage: 0,
    },
    errors: {},
});

function clientServiceReducer(
    state: ImmutableClientState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);
        case IS_DELETING:
            return state.set('isDeleting', action.payload.isDeleting);
        case IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);
        case IS_QUERYING:
            return state.set('isQuerying', action.payload.isQuerying);

        case CREATE_SUCCESS: {
            const client = fromJS(action.payload.data);

            const newState = setEntityIntoSubStateLists(state, 'clients', client);
            return newState.set('errors', fromJS({})).set('isCreating', false);
        }
        case CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);

        case QUERY_LIST_SUCCESS: {
            const data = action.payload.data;

            return state
                .set('query', fromJS(data || {}))
                .set('errors', fromJS({}))
                .set('isQuerying', false);
        }
        case QUERY_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isQuerying', false);

        case FETCH_LIST_SUCCESS: {
            const clients = action.payload.data;

            return state
                .set('clients', fromJS(clients || []))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        }
        case FETCH_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isFetching', false);

        case UPDATE_SUCCESS: {
            const client = fromJS(action.payload.data);

            const newState = setEntityIntoSubStateLists(state, 'clients', client);
            return newState.set('errors', fromJS({})).set('isUpdating', false);
        }
        case UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isUpdating', false);

        case DESTROY_SUCCESS: {
            const client = fromJS(action.payload.data);

            const newState = deleteEntityFromSubStateLists(state, 'clients', client);
            return newState.set('errors', fromJS({})).set('isDeleting', false);
        }
        case DESTROY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isDeleting', false);

        default:
            return state;
    }
}

export default clientServiceReducer;
