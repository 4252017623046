// @flow strict

import styled from 'styled-components';

import { LegacyTheme, ButtonHover } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 30px 0px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 25px;
    margin-bottom: 20px;
    text-align: center;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0px 24px 31px 24px;

    border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 16px 16px 0px;

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 12px;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    line-height: 17px;
    flex-shrink: 0;
    margin-right: 13px;
    color: ${LegacyTheme.defaultColor};
    font-weight: ${({ boldLabel }) => (boldLabel ? 'bold' : 'normal')};
`;

export const Title = styled.div`
    font-size: 20px;
    color: ${LegacyTheme.defaultColor};
`;
