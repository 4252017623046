// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

// Components
import { ShowForDesktopOnly, ShowForMobileOnly } from 'styles/responsive';
import { Caret, Table } from 'components/_ReactUI_V1';
import SolvExtractCircuitTable from 'components/CircuitTables/SolvExtractCircuitTable';
import KPIValue from 'components/KPIValue';

// Constants
import { PLANT_VALUE_TYPES, NAVIGATION_ROUTES, APPLICATION_TYPES } from 'utils/constants';

// Styles
import { ControlWrapper, TableContainer } from './styles';
import { DataToggler } from 'styles/common';

// Types
import type { ImmutableList, SearchCriteria } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableUser } from 'services/Authentication/types';

import { getLoginUserName } from 'utils/authentication';
import { logUserAnalyticsInteraction } from 'utils/userAnalytics';

type Props = {
    plants?: ImmutableList<ImmutablePlant>,
    isFetchingPlants: boolean,
    user: ImmutableUser,
    onHandleSortBy: (structure: ?SearchCriteria) => void,
};

type State = {
    expandedRowsIDs: Array<number>,
};

class PlantsTable extends React.Component<Props, State> {
    state = {
        expandedRowsIDs: [],
    };

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isFetchingPlants && !this.props.isFetchingPlants && this.props.plants) {
            this.setState({
                expandedRowsIDs: this.props.plants.map((plant: ImmutablePlant) => plant.get('id')),
            });
        }
    }

    provideTableHeaders = (viewport: string) => {
        let headers = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.SolvExtractPlantsTable.header.plantName',
                }),
                id: 'name',
                sortable: true,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.SolvExtractPlantsTable.header.client',
                }),
                id: 'clientName',
                sortable: true,
            },
        ];

        if (viewport === 'desktop') {
            headers = [
                ...headers,
                {
                    label: this.props.intl.formatMessage({
                        id: 'components.SolvExtractPlantsTable.header.cuRecovery',
                    }),
                    id: 'cuRecovery',
                    sortable: false,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'components.SolvExtractPlantsTable.header.cuTransfer',
                    }),
                    id: 'cuTransfer',
                    sortable: false,
                },
            ];
        }

        return [
            ...headers,
            {
                label: '',
                id: 'controls',
            },
        ];
    };

    handleToggleExpandedContent = (plantId?: number) => (e) => {
        e.stopPropagation();
        const ids = [...this.state.expandedRowsIDs];
        const index = ids.indexOf(plantId);
        if (index > -1) {
            ids.splice(index, 1);
        } else {
            ids.push(plantId);
        }

        this.setState((prevState: State) => ({
            expandedRowsIDs: ids,
        }));
    };

    handleOnClickPlantTrends = (plant) => () => {
        this.props.history.push(`${NAVIGATION_ROUTES.PLANT}${plant.get('id')}${NAVIGATION_ROUTES.TRENDS}`);

        //Piwik pro log
        logUserAnalyticsInteraction({moduleName: APPLICATION_TYPES.SOLVEXTRACT, plant: plant.get('name'),
            userName: getLoginUserName(this.props.user) });
    };

    getKPI = (plant: ImmutablePlant, kpiType: string) => {
        let kpiSetting;
        let kpiValue;

        const kpiSettings = plant.get('kpiSettings');
        const kpis = plant.get('kpis');

        if (!kpiSettings || !kpis) {
            return { kpiSetting, kpiValue };
        }

        kpiSetting = kpiSettings.find((k) => k.get('kpiType') === kpiType);
        const kpi = kpis.find((k) => k.get('kpiType') === kpiType);

        if (!kpiSetting || !kpi) {
            return { kpiSetting, kpiValue };
        }

        return { kpiSetting, kpiValue: kpi.get('value') };
    };

    provideInteractiveRow = (plant) => {
        const rowIsExpanded = this.state.expandedRowsIDs.includes(plant.get('id'));

        const cuRecovery = this.getKPI(plant, PLANT_VALUE_TYPES.OVERALL_RECOVERY);
        const cuTransfer = this.getKPI(plant, PLANT_VALUE_TYPES.OVERALL_CU_TRANSFER);

        return plant
            .set('clientName', plant.getIn(['client', 'name']))
            .set(
                'cuRecovery',
                <KPIValue
                    kpiSetting={cuRecovery.kpiSetting}
                    value={cuRecovery.kpiValue}
                    noValueText={this.props.intl.formatMessage({
                        id: 'components.TrendsPageDashboard.valueIsNA',
                    })}
                />
            )
            .set(
                'cuTransfer',
                <KPIValue
                    kpiSetting={cuTransfer.kpiSetting}
                    value={cuTransfer.kpiValue}
                    noValueText={this.props.intl.formatMessage({
                        id: 'components.TrendsPageDashboard.valueIsNA',
                    })}
                />
            )
            .set(
                'controls',
                <ControlWrapper>
                    <DataToggler onClick={this.handleToggleExpandedContent(plant.get('id'))}>
                        <span>
                            {this.props.intl.formatMessage({
                                id: rowIsExpanded
                                    ? 'components.SolvExtractPlantsTable.circuit.expandedTitle'
                                    : 'components.SolvExtractPlantsTable.circuit.collapsedTitle',
                            })}
                        </span>
                        <Caret
                            up={!rowIsExpanded}
                            down={rowIsExpanded}
                            margin="0px"
                            style={{ minWidth: '10px' }}
                            black
                        />
                    </DataToggler>
                </ControlWrapper>
            )
            .set('expandedContent', this.renderRowExpandedContent(plant))
            .set('onClick', this.handleOnClickPlantTrends(plant));
    };

    provideTableRows = () => {
        const rows = this.props.plants.map((plant) => this.provideInteractiveRow(plant));
        return rows.toJS();
    };

    renderRowExpandedContent = (plant) => {
        if (!plant || !this.state.expandedRowsIDs.includes(plant.get('id'))) {
            return null;
        }

        return (
            <SolvExtractCircuitTable
                plantId={plant.get('id')}
                circuits={plant.get('circuits')}
                isFetching={false}
                user={this.props.user}
            />
        );
    };

    render() {
        return (
            <TableContainer>
                <ShowForDesktopOnly>
                    <Table
                        header={this.provideTableHeaders('desktop')}
                        loading={this.props.isFetchingPlants}
                        onSortBy={this.props.onHandleSortBy}
                        rows={this.provideTableRows()}
                        expandedContentMaxHeight="480px"
                        tdPadding="0"
                        tdHeight="auto"
                    />
                </ShowForDesktopOnly>

                <ShowForMobileOnly>
                    <Table
                        header={this.provideTableHeaders('mobile')}
                        loading={this.props.isFetchingPlants}
                        onSortBy={this.props.onHandleSortBy}
                        rows={this.provideTableRows()}
                        expandedContentMaxHeight="480px"
                        tdPadding="0"
                        tdHeight="auto"
                    />
                </ShowForMobileOnly>
            </TableContainer>
        );
    }
}

export default withRouter(injectIntl(PlantsTable));
