// @flow strict

import {
    createUntranslatedFeedback,
    createTranslatedFeedback,
    destroyFeedback,
    destroyAllFeedbacks,
} from './actions';

import { TOAST_DURATION } from 'utils/constants';

import type { FeedbackType } from 'services/Feedback/types';
import type { ReduxDispatch } from 'types';

/**
 * Creates a feedback that is translated
 *
 * This SHOULD be used in 99% of cases.
 * However in thunks we do not have access to translation so we
 */
export const newFeedback = (
    feedbackType: FeedbackType,
    message: string,
    duration?: number = TOAST_DURATION
) => (dispatch: ReduxDispatch) => {
    dispatch(createTranslatedFeedback(feedbackType, message, duration));
};

/**
 * @deprecated translate feedbacks before passing them!
 */
export const newUntranslatedFeedback = (
    feedbackType: FeedbackType,
    messageId: string,
    data?: any,
    duration?: number = TOAST_DURATION
) => (dispatch: ReduxDispatch) => {
    dispatch(createUntranslatedFeedback(feedbackType, messageId, data, duration));
};

/**
 * Destroy a feedback
 */
export const deleteFeedback = (feedbackId: number) => (dispatch: ReduxDispatch) => {
    dispatch(destroyFeedback(feedbackId));
};

/**
 * Destroy all feedbacks
 */
export const deleteAllFeedbacks = () => (dispatch: ReduxDispatch) => {
    dispatch(destroyAllFeedbacks());
};
