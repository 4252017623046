// @flow strict

import styled from 'styled-components';

import { LegacyTheme, ButtonHover } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 30px 50px;

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: ${STYLE_VALUES.GUTTERS.NORMAL} 0;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    margin-top: 25px;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    margin-top: 12px;
`;

export const DotLoaderWrapper = styled.div`
    margin-right: 8px;
    margin-left: 8px;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20px;
    margin-top: 40px;
`;

export const Title = styled.div`
    font-size: 20px;
    color: ${LegacyTheme.defaultColor};
`;

export const KPIGraph = styled.div`
    width: 100%;
    height: 420px;

    border: 1px solid black;
`;

export const SettingsButtonHover = styled(ButtonHover)`
    padding: 5px;
    display: flex;
    align-items: center;
    min-height: unset;

    color: ${LegacyTheme.defaultSubtleColor};

    &:hover {
        color: ${LegacyTheme.defaultSubtleColor7B};
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    > button + button {
        margin-left: 6px;
    }
`;
