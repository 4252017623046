// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import { Message } from './styles';

import type { IntlType, ErrorType } from 'types';

type Props = {
    intl: IntlType,
    errorCode?: ?string,
    errorMessage?: ?string,
    errorType?: ?ErrorType,
    intlData?: Object | null,
    isRed?: boolean,
    isSmall?: boolean,
    noMargins?: boolean,
    style?: Object,
};

class ErrorMessage extends React.PureComponent<Props, null> {
    static defaultProps = {
        errorCode: null,
        errorMessage: null,
        errorType: null,
        intlData: null,
        isRed: false,
        isSmall: false,
        noMargins: false,
        style: {},
    };

    getErrorMessage() {
        let errorMessage = null;
        if (this.props.errorMessage) errorMessage = this.props.errorMessage;
        if (this.props.errorCode) {
            errorMessage = this.props.intl.formatMessage(
                {
                    id: this.props.errorCode,
                },
                this.props.intlData || {}
            );
        }
        if (this.props.errorType) {
            errorMessage = this.props.intl.formatMessage(
                {
                    id: `errorCodes.${this.props.errorType.get('error')}`,
                    defaultMessage: errorMessage,
                },
                this.props.intlData || {}
            );
        }
        return errorMessage;
    }

    render() {
        return (
            <Message
                isRed={this.props.isRed}
                isSmall={this.props.isSmall}
                noMargins={this.props.noMargins}
                style={this.props.style}
            >
                {this.getErrorMessage()}
            </Message>
        );
    }
}

export default injectIntl(ErrorMessage);
