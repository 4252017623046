// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type {
    ImmutableRecommendationImpactCalculation,
    ImmutableRecommendationState,
} from './types';

/**
 * Direct selector to the recommendation state domain
 */
export const selectRecommendationState = () => (state: ImmutableReduxState) =>
    state.get('recommendations');

/**
 * Gets isSubmitting status while recommendation set are being submitted
 */
export const selectRecommendationSetIsSubmitting = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('isSubmitting')
    );

/**
 * Gets isUpdating status while recommendations are being created/updated
 */
export const selectRecommendationSetIsUpdating = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('isUpdating')
    );

/**
 * Gets isUpdating status while recommendations are being created/updated
 */
export const selectRecommendationSetSubmissionErrors = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('errors')
    );

/**
 * Gets isFeedbackSubmitting status while recommendation feedbacks are being submitted
 */
export const selectFeedbackIsSubmitting = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('isFeedbackSubmitting')
    );

/**
 * Gets isFeedbackSubmitting status while recommendations feedbacks are being created
 */
export const selectFeedbackSubmissionErrors = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('errors')
    );

/**
 * Gets isRunningRecommendations status while recommendations are being run for a circuit
 */
export const selectIsRunningRecommendations = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('isRunningRecommendations')
    );

/**
 * Gets isSubmittingPlantDatasetMessage status
 */
export const selectIsSubmittingPlantDatasetMessage = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('isSubmittingPlantDatasetMessage')
    );

/**
 * Gets isSubmittingSCircuitDatasetMessage status
 */
export const selectIsLoadingCircuitRecommendation = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('isFetchingCircuitRecommendation')
    );

/**
 * Gets is loading plant recommendation status
 */
export const selectIsLoadingPlantRecommendation = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('isFetchingPlantRecommendation')
    );

/**
 * Gets isSubmittingPlantDatasetMessage status
 */
export const selectCircuitRecommendations = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('recommendationSets')
    );

/**
 * Gets isSubmittingPlantDatasetMessage status
 */
export const selectPlantRecommendations = () =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) => substate.get('plantDatasetMessages')
    );

/**
 * Gets is loading plant recommendation status
 */
export const selectIsCalculatingRecommendationImpact = (recommendationId: number) =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) =>
            Boolean(substate.getIn(['isCalculatingRecommendationImpacts', recommendationId]))
    );

/**
 * Gets isSubmittingPlantDatasetMessage status
 */
export const selectCalculatedRecommendationImpact = (recommendationId: number) =>
    createSelector(
        selectRecommendationState(),
        (substate: ImmutableRecommendationState) =>
            substate
                .get('calculatedRecommendationImpacts')
                .find(
                    (cri: ImmutableRecommendationImpactCalculation) =>
                        cri.get('recommendationId') === recommendationId
                )
    );
