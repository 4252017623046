// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import type { OrderedMap } from 'immutable';

import MDRCurvesSidebarSection from 'components/IsothermManagementSidebar/MDRCurvesSidebarSection';
import MDRCurvesDiagram from 'components/MDRCurvesDiagram';
import ComputationInstructions from 'components/ComputationInstructions';

// Helpers
import { getPostDecimalZeroCount, round } from 'utils/helpers';

// Services
import { selectAllOximes, selectOximesAreFetching } from 'services/Oxime/selectors';
import { selectReagentsAreFetching } from 'services/Reagent/selectors';
import { fetchAllOximes } from 'services/Oxime/thunks';

// Types
import type { ImmutableList, ReduxDispatch } from 'types';
import type { SidebarRenderer } from 'containers/IsothermManagementContainer';
import type {
    ImmutableDataPoint,
    ImmutableOxime,
    ImmutableOximeConcentration,
} from 'services/Oxime/types';

type VictoryPoint = { y: number, x: number };

type DataPoint = {
    x: number,
    y: number,
};

type xDomainsType = {
    min: number,
    max: number,
};

type DataSetType = {
    data: ImmutableList<DataPoint>,
    id: number,
    modifier: string,
    xDomains: xDomainsType,
};

type Props = {
    fetchAllOximes: () => void,
    handleSidebarContent: (sidebarContent: SidebarRenderer) => void,
    oximes: ImmutableList<ImmutableOxime>,
    oximesAreFetching: boolean,
    reagentsAreFetching: boolean,
};

type State = {
    activeOximeId: ?number,
    activeOximesGroupedConcentrations?: OrderedMap<
        string,
        ImmutableList<ImmutableOximeConcentration>
    >,
    activeOximesModifiers?: ImmutableList<ImmutableOximeConcentration>,
    activeOximesConcentrationId?: number,
};

/**
 * Main content area container for the MDR curves option in the Isotherm Management view
 */
class MDRCurvesContainer extends React.Component<Props, State> {
    state = {
        activeOximeId: null,
        activeOximesGroupedConcentrations: null,
        activeOximesModifiers: null,
        activeOximesConcentrationId: '',
    };

    /**
     * When this container is mounted, set sidebar default settings
     */
    componentDidMount() {
        this.props.handleSidebarContent(this.renderSidebar());

        if (this.props.oximes.isEmpty()) {
            this.props.fetchAllOximes();
        }
    }

    /**
     * To ensure component does not re-render graph on concentration update,
     * only allow when other (specific) state values have changed
     */
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            this.state.activeOximeId !== nextState.activeOximeId ||
            this.state.activeOximesModifiers !== nextState.activeOximesModifiers ||
            this.props.oximes !== nextProps.oximes
        );
    }

    componentDidUpdate() {
        this.props.handleSidebarContent(this.renderSidebar());
    }

    /**
     * Provide js array of provided oximes
     */
    getOximeList = () => this.props.oximes.toJS();

    /**
     * Handle selection of oxime, setting activeOximeId & its "grouped" list of concentrations
     */
    handleSelectOxime = (selectedOximeId: number) => {
        let activeOximeId;
        let orderedMap = null;
        if (selectedOximeId && this.props.oximes.size) {
            const activeOxime = this.props.oximes.find(
                (oxime: ImmutableOxime) => oxime.get('id') === selectedOximeId
            );
            if (activeOxime) {
                activeOximeId = activeOxime.get('id');
                orderedMap = activeOxime
                    .get('concentrations')
                    .groupBy((concentration: ImmutableOximeConcentration) =>
                        concentration.get('concentration')
                    );
            }
        }

        return this.setState({
            activeOximeId,
            activeOximesGroupedConcentrations: orderedMap,
        });
    };

    /**
     * Handle selection of concentration, setting state.activeOximesConcentrationId with selected value
     */
    handleSelectConcentration = (selectedConcentrationValue: ?number) => {
        this.setState({
            activeOximesConcentrationId: selectedConcentrationValue,
        });
    };

    /**
     * When the visualize button is clicked
     */
    onVisualizeButtonClicked = () => {
        this.setState((prevState: State) => ({
            activeOximesModifiers: prevState.activeOximesGroupedConcentrations.findEntry(
                // eslint-disable-next-line no-unused-vars
                (value: ImmutableList<ImmutableOximeConcentration>, oximeConcentrationId: string) =>
                    oximeConcentrationId === prevState.activeOximesConcentrationId
            )[1],
        }));
    };

    /**
     * Provided data sets from state.activeOximeModifiers, mapping y: Metal In Organic and x: MDR
     */
    getDataSets = (): ImmutableList<DataSetType> =>
        this.state.activeOximesModifiers &&
        this.state.activeOximesModifiers.map((concentration: ImmutableOximeConcentration) => {
            const data = concentration
                .get('dataPoints')
                .map((d: ImmutableDataPoint) => ({
                    y: Number(d.get('metalInOrganic')),
                    x: Number(d.get('mdr')),
                }))
                .toJS();

            const xValues = data.map((d: VictoryPoint) => d.x);
            return {
                data,
                id: concentration.get('id'),
                modifier: concentration.get('modifier'),
                xDomains: {
                    min: Math.min(...xValues),
                    max: Math.max(...xValues),
                },
            };
        });

    /**
     * Provide x min & max based on provided dataSets
     */
    getXDomains = (dataSets: ImmutableList<DataSetType>) => {
        const xDomains = dataSets.map((d: DataSetType) => d.xDomains);

        const xMaxValues = xDomains.map((d: xDomainsType) => d.max);
        const xMinValues = xDomains.map((d: xDomainsType) => d.min);

        const xMaxValue = Math.max(...xMaxValues);
        const xMinValue = Math.min(...xMinValues);

        const max = xMaxValue > 10 ? round(xMaxValue, 0) : 10;

        const xMinValueZeroCount = getPostDecimalZeroCount(xMinValue);
        const min = Number(`0.${'0'.repeat(xMinValueZeroCount)}1`);

        return {
            max,
            min,
        };
    };

    renderSidebar = (): SidebarRenderer => {
        const sidebarType = 'TAB';
        const node = (
            <MDRCurvesSidebarSection
                oximeList={this.getOximeList()}
                concentrationList={this.state.activeOximesGroupedConcentrations}
                onSelectOxime={this.handleSelectOxime}
                onSelectConcentration={this.handleSelectConcentration}
                handleVisualizeButtonClicked={this.onVisualizeButtonClicked}
                oximesAreLoading={this.props.oximesAreFetching || this.props.reagentsAreFetching}
            />
        );
        return {
            sidebarType,
            node,
        };
    };

    render() {
        const dataSets = this.getDataSets();

        if (dataSets) {
            const xDomains = this.getXDomains(dataSets);
            return <MDRCurvesDiagram dataSets={dataSets} xMin={xDomains.min} xMax={xDomains.max} />;
        } else {
            return <ComputationInstructions messageId="MDRCurvesContainer.selectOptionsMessage" />;
        }
    }
}

const mapStateToProps = createStructuredSelector({
    oximes: selectAllOximes(),
    oximesAreFetching: selectOximesAreFetching(),
    reagentsAreFetching: selectReagentsAreFetching(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllOximes,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MDRCurvesContainer)
);
