// @flow

import React from 'react';
import {
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryLabel,
    VictoryLine,
    VictoryScatter,
} from 'victory'; // https://github.com/FormidableLabs/victory

// Styles
import legacyTheme from '../../themes/legacyTheme';
import {
    victoryChartDimensions,
    victoryChartPadding,
    victoryData,
    victoryAxisProperties,
    victoryYAxisSpecificProperties,
} from './styles';

// Types
import type { ReactNode } from '../../types';

type DataType = {
    x: number,
    y: number,
};

type DataSetType = {
    id: number,
    data: Array<DataType>,
    scatterData?: Array<DataType>,
    lineColor?: string,
    lineWidth?: number,
    scatterColor?: string,
    scatterStrokeColor?: string,
    scatterStrokeWidth?: number,
    scatterSize?: number,
};

type Props = {
    xAxisLabel?: string,
    yAxisLabel?: string,
    xAxisDomain?: Array<number>,
    yAxisDomain?: Array<number>,
    xAxisTickValues?: Array<number>,
    yAxisTickValues?: Array<number>,
    xAxisTickCount?: number,
    yAxisTickCount?: number,
    dataSets?: Array<DataSetType>,
    chartHeight?: number,
    chartWidth?: number,
    chartPadding?: {
        top: number,
        bottom: number,
        left: number,
        right: number,
    },
    renderChartBody?: () => ReactNode,
};

export default class Graph2DPlot extends React.PureComponent<Props> {
    static defaultProps = {
        chartHeight: null,
        chartPadding: null,
        chartWidth: null,
        dataSets: null,
        renderChartBody: null,
        xAxisDomain: null,
        xAxisLabel: null,
        xAxisTickCount: null,
        xAxisTickValues: null,
        yAxisDomain: null,
        yAxisLabel: null,
        yAxisTickCount: null,
        yAxisTickValues: null,
    };

    renderVictoryAxises = () => {
        const xAxisStyles = {
            ...victoryAxisProperties,
        };

        const yAxisStyles = {
            ...victoryAxisProperties,
            ...victoryYAxisSpecificProperties,
        };

        return [
            <VictoryAxis
                key={0}
                label={this.props.xAxisLabel}
                orientation="bottom"
                style={xAxisStyles}
                domain={this.props.xAxisDomain || null}
                tickValues={this.props.xAxisTickValues || null}
                tickCount={this.props.xAxisTickCount || 10}
            />,
            <VictoryAxis
                key={1}
                label={this.props.yAxisLabel}
                orientation="left"
                style={yAxisStyles}
                domain={this.props.yAxisDomain || null}
                tickValues={this.props.yAxisTickValues || null}
                tickCount={this.props.yAxisTickCount || 10}
                tickLabelComponent={<VictoryLabel dy={-15} dx={15} textAnchor="start" />}
                dependentAxis
            />,
        ];
    };

    renderVictoryLines = (): ?Array<VictoryLine> =>
        this.props.dataSets &&
        this.props.dataSets.map((set: DataSetType) => {
            const styles = {
                data: {
                    ...victoryData.data,
                    stroke: set.lineColor || legacyTheme.graphDataLineColor,
                    strokeWidth: set.lineWidth || victoryData.data.strokeWidth,
                },
            };

            return (
                <VictoryLine key={set.id} data={set.data} interpolation="natural" style={styles} />
            );
        });

    renderVictoryScatter = (): ?Array<VictoryScatter> =>
        this.props.dataSets &&
        this.props.dataSets.map((set: DataSetType) => {
            const styles = {
                data: {
                    fill: set.scatterColor || legacyTheme.graphDataScatterColor,
                },
            };
            if (set.scatterStrokeColor) {
                styles.data = {
                    ...styles.data,
                    stroke: set.scatterStrokeColor,
                    strokeWidth: set.scatterStrokeWidth || 1,
                };
            }

            return (
                <VictoryScatter
                    key={set.id}
                    name="scatterPoints"
                    size={set.scatterSize || 7}
                    style={styles}
                    data={set.scatterData || set.data}
                />
            );
        });

    render() {
        if (!this.props.xAxisLabel || !this.props.yAxisLabel) {
            return null;
        }

        const isEmptyGraph = !this.props.dataSets;

        const chartPadding = this.props.chartPadding || victoryChartPadding;
        const height = this.props.chartHeight
            ? this.props.chartHeight
            : victoryChartDimensions.height;
        const width = this.props.chartWidth ? this.props.chartWidth : victoryChartDimensions.width;

        return (
            <VictoryChart
                // Width and height adjusted with chart inner padding in consideration otherwise SVG generated by Victory crops elements out of boundaries by those amounts
                height={height + chartPadding.top + chartPadding.bottom}
                width={width + chartPadding.left + chartPadding.right}
                domainPadding={15}
                padding={chartPadding}
                responsive
            >
                {this.renderVictoryAxises()}
                {!isEmptyGraph && (
                    <VictoryGroup>
                        {this.renderVictoryLines()}
                        {this.renderVictoryScatter()}
                        {this.props.renderChartBody && this.props.renderChartBody()}
                    </VictoryGroup>
                )}
            </VictoryChart>
        );
    }
}
