// @flow strict

import React from 'react';

import { StyledSVG } from './styles';

export default function CircleDeleteIcon(props?: { fill?: string, width?: number }) {
    return (
        <StyledSVG viewBox="0 0 20 20" width={`${props.width}px`} height={`${props.width}px`}>
            <path
                d="M9.99984 1.66675C5.39984 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.39984 18.3334 9.99984 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.99984 1.66675ZM14.1665 10.8334H5.83317V9.16675H14.1665V10.8334Z"
                fill={props.fill}
            />
        </StyledSVG>
    );
}

CircleDeleteIcon.defaultProps = {
    fill: 'currentColor',
    width: 20,
};
