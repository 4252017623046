// @flow strict

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Constants
import { NAVIGATION_ROUTES, BLANK_TEMPLATE, NEW_DATASET_POSTFIX } from 'utils/constants';
import { appBlockingMessage } from 'env';

// Components
import Header from 'components/Header';

// Helpers
import { isSysAdmin } from 'utils/authentication';

// Services
import { logoutUser } from 'services/Authentication/thunks';

// Types
import type {
    HistoryType,
    LocationType,
    ReduxDispatch,
    RouteType,
} from 'types';
import type { ImmutableUser, LogoutUser } from 'services/Authentication/types';

type Props = {
    user?: ImmutableUser,
    userIsFetching: boolean,
    userIsReady?: boolean,
    route: RouteType,

    location: LocationType,
    history: HistoryType,
    logoutUser: LogoutUser,
};

class HeaderContainer extends React.PureComponent<Props> {
    static defaultProps = {
        user: null,
        userIsReady: false,
    };

    componentDidUpdate() {
        // If there is an app blocking message, and the user is not an admin, log them out
        if (appBlockingMessage && this.props.user && !isSysAdmin(this.props.user)) {
            this.props.logoutUser();
        }
    }

    getCurrentLocation = () => this.props.location.pathname;

    handleLogoClick = () => (this.getCurrentLocation() === '/' ? this.props.history.go(0) : null);

    handleLogout = () => this.props.logoutUser();

    handleChangeLocation = (location: string) => this.props.history.push(location);

    render() {
        return (
            <Header
                route={this.props.route}
                user={this.props.user}
                userIsFetching={this.props.userIsFetching}
                userIsReady={this.props.userIsReady || false}
                currentLocation={this.getCurrentLocation()}
                onLogoClick={this.handleLogoClick}
                onLogout={this.handleLogout}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({ });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            logoutUser,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HeaderContainer)
);
