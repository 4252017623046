// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { colors } from 'styles/colors';

export const RecommendationItemListWrapper = styled.div`
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 12px;
    }
`;

export const RecommendationItemListUL = styled.ul`
    font-size: 14px;
    padding-left: 24px;
    line-height: 24px;
    margin: 0;
`;

export const RecommendationItemInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
`;

export const RecommendationItemInputRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > * + * {
        margin-left: 8px;
    }
`;

export const AddItemButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 9px;
    cursor: pointer;
    border-radius: 3px;

    border: 1px dashed ${LegacyTheme.defaultBorder};
    color: ${LegacyTheme.defaultSubtleColor};

    & > svg {
        color: ${LegacyTheme.buttonPrimary};
    }

    &:hover {
        color: ${LegacyTheme.defaultColor};
        border: 1px dashed ${LegacyTheme.defaultSubtleColor};

        & > svg {
            color: ${LegacyTheme.buttonPrimaryHover};
        }
    }

    transition: color 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
`;

export const AddItemButtonText = styled.p`
    margin: 0;
    font-size: 13px;
    line-height: 14px;
`;

export const DeleteButtonWrapper = styled.div`
    color: ${LegacyTheme.defaultBorder};
    cursor: pointer;

    &:hover {
        color: ${LegacyTheme.buttonPrimaryHover};
    }

    transition: color 0.2s ease-in-out;
`;
