// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const KPIValueWrapper = styled.div`
    color: ${(props: Object) => props.color};
`;

export const KPIValueUnitWrapper = styled.div`
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: ${(fontSize) => fontSize ? `${fontSize}px` : '13px'};
    color: ${(props: Object) => props.color};
    margin-top: 4px;
`;
