/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { colors, streamCircuitColors } from 'styles/colors';

export const SVG_LAYER_Z_INDEX = 0;

export const DiagramWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    padding: ${({ topPadding }: Object) => (topPadding ? `11` : `0`)}px
        ${({ largerPadding }: Object) => (largerPadding ? `42` : `22`)}px 22px;
    width: ${({ isLoading }: Object) => (isLoading ? `100%` : `fit-content`)};
`;

export const DiagramHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 24px 20px 10px 40px;
`;

export const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

// Diagram
export const WidgetWrapper = styled.div`
    position: relative;
    z-index: ${SVG_LAYER_Z_INDEX + 1};

    width: fit-content;
`;

// SVG Layer:
export const MarkerPath = styled.path`
    fill: ${({ streamCircuit }: Object) => streamCircuitColors[streamCircuit].main};
    stroke: ${({ streamCircuit }: Object) => streamCircuitColors[streamCircuit].main};
`;

export const ArrowPath = styled.path`
    stroke-width: 1px;
    fill: none;
    stroke: ${({ streamCircuit }: Object) => streamCircuitColors[streamCircuit].main};
    stroke-opacity: 1;
`;

export const LoadingDotsWrapper = styled.div`
    margin-top: 24px;
    margin-left: 40px;
`;

export const DiagramWidget = styled.div`
    display: grid;
    grid-auto-columns: ${({ grid }: Object) => grid.GRID.COLUMN_WIDTH}px;
    grid-auto-rows: ${({ grid }: Object) => grid.GRID.ROW_HEIGHT}px;
    grid-row-gap: ${({ grid }: Object) => grid.GRID.ROW_GAP}px;
    grid-column-gap: ${({ grid }: Object) => grid.GRID.COLUMN_GAP}px;

    width: fit-content;
`;

export const SVGLayer = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${SVG_LAYER_Z_INDEX};
    overflow: visible !important;
`;

export const ColumnSpacer = styled.div`
    grid-column-start: ${({ grid, startsAt }: Object) => startsAt + grid.STAGE.COLUMN_SPAN};
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
`;

export const RowSpacer = styled.div`
    grid-row-start: ${({ grid, startsAt }: Object) => startsAt + grid.STAGE.ROW_SPAN};
    grid-row-end: span 2;
    grid-column-start: 0;
    grid-column-end: span 1;
`;
