// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const SidebarNavigationHeader = styled.div`
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid ${LegacyTheme.defaultBorder};
    padding-bottom: 20px;
`;

export const HeaderTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;

    cursor: pointer;
    color: ${LegacyTheme.black};

    :hover {
        color: ${LegacyTheme.defaultColor};
    }
`;

export const HeaderTitle = styled.h2`
    font-size: 17px;
    font-weight: normal;
    margin: 0;
    line-height: 20px;
`;

export const HeaderSubtitle = styled.h3`
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    margin-left: 23px;
    margin-top: 8px;
    color: ${LegacyTheme.defaultColor};
`;

export const PredictIsothermCheckboxStyle = {
    fontSize: '13px',
    marginLeft: '8px',
    color: LegacyTheme.defaultColor,
};
