// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import {
    Caret,
    ButtonWrapper,
    Column,
    DotMenu,
    InputField,
    Pagination,
    PrimaryButton,
    Row,
    SecondaryButton,
    Table,
    ToolTip,
} from 'components/_ReactUI_V1';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import PreventDataLossModal from 'components/Modals/PreventDataLossModal';
import OximeConcentrationTable from 'components/OximeConcentrationTable';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';

// Helpers
import { containsDuplicates, provideEmptyDataPoints } from 'utils/helpers';

// Styles
import { ControlWrapper, TableContainer, ToolTipContent, Wrapper } from './styles';
import { BlankButton, DataToggler, Title } from 'styles/common';

// Types
import type {
    ErrorType,
    ImmutableList,
    InputEvent,
    IntlType,
    LooseNumberType,
    ReactSelectObject,
    SearchCriteria,
} from 'types';
import type {
    ImmutableDataPoint,
    ImmutableOxime,
    ImmutableOximeConcentration,
    Oxime,
} from 'services/Oxime/types';

type LooseKeyArrayType = string | Array<LooseNumberType>;

type Props = {
    errors: ErrorType,
    intl: IntlType,
    handleDeleteOxime: (number) => void,
    handleCreateNewOxime: (ImmutableOxime) => void,
    handleUpdateOxime: (number, Oxime) => void,
    onHandlePaginationPageSelection: (number, number) => void,
    onHandleSortBy: (SearchCriteria) => void,
    oximes: ImmutableList<ImmutableOxime>,
    oximesAreQuerying: boolean,
    oximeIsDeleting: boolean,
    oximeIsUpdating: boolean,
    lastPage: number,
    pagination: {
        page: number,
    },
    searchCriteria: SearchCriteria,
};

type State = {
    activeRow: ?ImmutableOxime,
    isModifiedRow: boolean,
    enableAddRow: boolean,
    disabledInputs: boolean,
    oximeIdToDelete: ?number,
    expandedRowId: ?number,
    showDataPreventionModal: boolean,
    dataPreventionHandler: ?Function,
};

class OximeManager extends React.Component<Props, State> {
    state = {
        activeRow: null,
        isModifiedRow: false,
        enableAddRow: true,
        disabledInputs: false,
        oximeIdToDelete: null,
        expandedRowId: null,
        showDataPreventionModal: false,
        dataPreventionHandler: null,
    };

    /**
     * On componentDidUpdate check if props.oximes has changed, if so reset UI via state
     * If props.errors have changed, re-enable inputs to allow user to continue
     */
    componentDidUpdate(prevProps: Props) {
        const { errors, oximes } = this.props;
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (prevProps.oximes !== oximes) {
            this.setState({
                activeRow: null,
                isModifiedRow: false,
                disabledInputs: false,
                enableAddRow: true,
                oximeIdToDelete: null,
                showDataPreventionModal: false,
                dataPreventionHandler: null,
            });
        } else if (prevProps.errors && !prevProps.errors.equals(errors)) {
            this.setState({
                disabledInputs: false,
            });
        }
    }

    handleOnDelete = (id: number) => () => this.props.handleDeleteOxime(id);

    /**
     * Update provided field by key of active row within state
     *
     * If provided key is an Array: perform setIn() else set()
     */
    handleActiveRowChange = (key: LooseKeyArrayType) => (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => {
            let activeRow = prevState.activeRow;
            if (typeof key === 'string') {
                activeRow = prevState.activeRow.set(key, value);
            } else {
                activeRow = prevState.activeRow.setIn(key, value);
            }

            return {
                activeRow,
                isModifiedRow: true,
                disabledInputs: false,
            };
        });
    };

    /**
     * Changes the activeRow to an empty row when creating a new oxime.
     */
    handleCreateNewOximeConfirmed = () => {
        const emptyRow = fromJS({
            id: null,
            concentrations: [this.provideEmptyConcentration(0)],
            name: null,
        });

        this.setState({
            showDataPreventionModal: false,
            dataPreventionHandler: null,
            activeRow: emptyRow,
            isModifiedRow: false,
            enableAddRow: false,
            expandedRowId: null,
        });
    };

    /**
     * On row cancel (within row edit/create) update nullify activeRow & reset enableAddRow
     */
    handleCancelActiveRow = () => {
        this.setState({
            activeRow: null,
            isModifiedRow: false,
            enableAddRow: true,
            expandedRowId: null,
        });
    };

    /**
     * Handles change of Oxime.concentration data values
     */
    handleConcentrationChange = (keyArray: Array<string>) => (event: InputEvent) => {
        const newKeyArray = ['concentrations'].concat(keyArray);
        return this.handleActiveRowChange(newKeyArray)(event);
    };

    /**
     * Deletes concentration at provided index for Oxime.concentrations
     */
    handleConcentrationDelete = (concentrationIdx: number) => () =>
        this.setState((prevState: State) => ({
            activeRow: prevState.activeRow.deleteIn(['concentrations', concentrationIdx]),
            isModifiedRow: true,
        }));

    /**
     * On row creation, insert "empty" object to list of entities & set as activeRow within state
     * Set enableAddRow to false to disable [Create New] button
     */
    handleCreateNewOxime = () => {
        if (this.state.activeRow && this.state.isModifiedRow) {
            this.setState({
                showDataPreventionModal: true,
                dataPreventionHandler: this.handleCreateNewOximeConfirmed,
            });
        } else {
            this.handleCreateNewOximeConfirmed();
        }
    };

    /**
     * On save, disable inputs for further editing
     * If state.activeRow has an id (assume update) and fire props.handleUpdateOxime()
     * Else (assume create) and fire props.handleCreateNewOxime()
     */
    handleSaveActiveRow = () => {
        this.setState(
            {
                disabledInputs: true, // Disable inputs to avoid unexpected duplicate requests
            },
            () => {
                const { activeRow } = this.state;
                if (activeRow && activeRow.get('id')) {
                    this.props.handleUpdateOxime(
                        activeRow.get('id'),
                        activeRow.delete('oxime').toJS()
                    );
                } else {
                    this.props.handleCreateNewOxime(this.state.activeRow);
                }
            }
        );
    };

    handleSetActiveRowConfirmed = (oxime?: ImmutableOxime) => () => {
        this.setState({
            activeRow: oxime || null,
            isModifiedRow: false,
            expandedRowId: oxime.get('id'),
            showDataPreventionModal: false,
            dataPreventionHandler: null,
        });
    };

    /**
     * On row edit, set provided entity as activeRow
     * If oxime is not provided nullify (reset UI) activeRow
     */
    handleSetActiveRow = (oxime?: ImmutableOxime) => () => {
        if (oxime && this.state.activeRow && this.state.isModifiedRow) {
            this.setState({
                showDataPreventionModal: true,
                dataPreventionHandler: this.handleSetActiveRowConfirmed(oxime),
            });
        } else {
            this.handleSetActiveRowConfirmed(oxime)();
        }
    };

    /**
     * On Click, set oximeIdToDelete in state for this.renderConditionalDeleteModal()
     */
    handleSetOximeIdToDelete = (oximeId?: number) => () => {
        this.setState({
            oximeIdToDelete: oximeId || null,
        });
    };

    /**
     * Provided oximeId is passed, toggle state.expandedRowId else nullify to conditional renderRowExpandedContent
     */
    handleToggleExpandedContent = (oximeId?: number) => () => {
        this.setState((prevState: State) => ({
            expandedRowId: oximeId === prevState.expandedRowId || !oximeId ? null : oximeId,
        }));
    };

    /**
     * Adds new empty concentration to be concatenated into table rows
     */
    handleAddEmptyRow = () => () =>
        this.setState(
            (prevState: State): ImmutableList<ImmutableOximeConcentration> => {
                const count = prevState.activeRow.get('concentrations').size;

                return {
                    isModifiedRow: true,
                    activeRow: prevState.activeRow.updateIn(
                        ['concentrations'],
                        (arr: ImmutableList<ImmutableOximeConcentration>) =>
                            arr.push(fromJS(this.provideEmptyConcentration(count + 1)))
                    ),
                };
            }
        );

    /**
     * Hide the data loss modal. when it is canceled. This prevents state change.
     */
    handleCancelDataLossModal = () =>
        this.setState({
            showDataPreventionModal: false,
        });

    /**
     * Provide editable row
     */
    provideEditableRow = () => {
        const oxime = this.state.activeRow;

        if (!oxime) {
            return null;
        }

        // Check if each dataPoints' mdr values are unique with it's concentration
        const concentrationsWithDuplicates = oxime
            .get('concentrations')
            .filter((concentration: ImmutableOximeConcentration) => {
                const values =
                    concentration &&
                    concentration
                        .get('dataPoints')
                        .map(
                            (dataPoint: ImmutableDataPoint) =>
                                dataPoint && Number(dataPoint.get('mdr'))
                        );
                return containsDuplicates(values);
            });
        const hasDuplicates = concentrationsWithDuplicates.size;

        // Required fields
        // TODO: Refactor the following, this works but is rather "dumb"
        const enableSave =
            oxime.get('name') &&
            // Ensure oxime has at least one concentration with concentration & modifier values
            oxime.getIn(['concentrations', 0, 'concentration']) &&
            oxime.getIn(['concentrations', 0, 'modifier']) &&
            // Ensure first concentration's first dataPoint has mdr & metalInOrganic values
            oxime.getIn(['concentrations', 0, 'dataPoints', 0, 'mdr']) &&
            oxime.getIn(['concentrations', 0, 'dataPoints', 0, 'metalInOrganic']) &&
            // Ensure first concentration's second dataPoint has mdr & metalInOrganic values
            oxime.getIn(['concentrations', 0, 'dataPoints', 1, 'mdr']) &&
            oxime.getIn(['concentrations', 0, 'dataPoints', 1, 'metalInOrganic']) &&
            !hasDuplicates;

        return oxime
            .set(
                'name',
                <InputField
                    disabled={this.state.disabledInputs}
                    onChange={this.handleActiveRowChange('name')}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.OximeManager.placeholder.name',
                    })}
                    value={oxime.get('name') || ''}
                />
            )
            .set(
                'controls',
                <ButtonWrapper style={{ width: '100%' }} right>
                    <PrimaryButton
                        disabled={
                            this.state.disabledInputs || !enableSave || !this.state.isModifiedRow
                        }
                        loading={this.props.oximeIsUpdating}
                        onClick={this.handleSaveActiveRow}
                        text={this.props.intl.formatMessage({
                            id: 'components.OximeManager.save',
                        })}
                    />
                    <SecondaryButton
                        disabled={this.state.disabledInputs}
                        onClick={this.handleCancelActiveRow}
                        text={this.props.intl.formatMessage({
                            id: 'components.OximeManager.cancel',
                        })}
                    />
                </ButtonWrapper>
            )
            .set('expandedContent', this.renderRowExpandedContent(oxime));
    };

    /**
     * Provide interactive row (static with controls)
     */
    provideInteractiveRow = (oxime: ImmutableOxime) => {
        const rowIsExpanded = this.state.expandedRowId === oxime.get('id');

        return oxime
            .set(
                'controls',
                <ControlWrapper>
                    <DataToggler onClick={this.handleToggleExpandedContent(oxime.get('id'))}>
                        <span>
                            {this.props.intl.formatMessage({
                                id: rowIsExpanded
                                    ? 'components.OximeManager.data.expandedTitle'
                                    : 'components.OximeManager.data.collapsedTitle',
                            })}
                        </span>
                        <Caret
                            up={!rowIsExpanded}
                            down={rowIsExpanded}
                            margin="0px"
                            style={{ minWidth: '10px' }}
                            black
                        />
                    </DataToggler>
                    <ToolTip
                        content={
                            <ToolTipContent>
                                <li>
                                    <BlankButton
                                        onClick={this.handleSetActiveRow(oxime)}
                                        title={this.props.intl.formatMessage({
                                            id: 'components.OximeManager.editOxime',
                                        })}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: 'components.OximeManager.editOxime',
                                        })}
                                    </BlankButton>
                                </li>
                                <li>{this.renderDeleteButton(oxime)}</li>
                            </ToolTipContent>
                        }
                        position="bottom"
                        trigger={<DotMenu />}
                        triggerType="click"
                        interactive
                    />
                </ControlWrapper>
            )
            .set('expandedContent', this.renderRowExpandedContent(oxime));
    };

    provideTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeManager.table.header.name',
            }),
            id: 'name',
            sortable: true,
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    /**
     * Map over Oximes and provide either interactive or editable row based on state.activeRow
     */
    provideTableRows = () => {
        const rows = this.props.oximes.map((oxime: ImmutableOxime) => {
            if (this.state.activeRow && this.state.activeRow.get('id') === oxime.get('id')) {
                return this.provideEditableRow();
            } else {
                return this.provideInteractiveRow(oxime);
            }
        });

        // If state.activeRow is an emptyRow, insert empty editable row at start of row
        let newRows;
        if (this.state.activeRow && !this.state.activeRow.get('id')) {
            newRows = rows.insert(0, this.provideEditableRow());
        }

        return (newRows || rows).toJS();
    };

    /**
     * Provide table header array for expandedContent / data section for each Oxime
     */
    provideDataTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeManager.data.header.concentration',
            }),
            id: 'concentration',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeManager.data.header.modifier',
            }),
            id: 'modifier',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeManager.data.header.mdr',
            }),
            id: 'mdr',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeManager.data.header.metalInOrganic',
            }),
            id: 'metalInOrganic',
        },
    ];

    /**
     * Provide empty concentration object for new entity
     */
    provideEmptyConcentration = (concentrationIdx: number): ImmutableOximeConcentration => ({
        id: `new-${concentrationIdx}`,
        concentration: undefined,
        modifier: undefined,
        dataPoints: provideEmptyDataPoints(concentrationIdx),
    });

    renderConditionalDataPreventionModal = () =>
        Boolean(this.state.showDataPreventionModal) && (
            <PreventDataLossModal
                isPageNavigation={false}
                onLeave={this.state.dataPreventionHandler}
                onCancel={this.handleCancelDataLossModal}
            />
        );

    /**
     * Provided this.state.oximeIdToDelete is set, display Modal and OximeToDeleteModalContent
     */
    renderConditionalDeleteModal = () =>
        Boolean(this.state.oximeIdToDelete) && (
            <ConfirmationModal
                title={this.props.intl.formatMessage({
                    id: 'components.OximeManager.DeleteModal.title',
                })}
                confirmButtonText={this.props.intl.formatMessage({
                    id: 'components.OximeManager.DeleteModal.confirmButton',
                })}
                errors={this.props.errors}
                loading={this.props.oximeIsDeleting}
                onConfirm={
                    this.state.oximeIdToDelete
                        ? this.handleOnDelete(this.state.oximeIdToDelete)
                        : null
                }
                onCancel={this.handleSetOximeIdToDelete()}
                danger
            />
        );

    /**
     * Render delete button, onClick sets state.oximeIdToDelete with provided oxime's id
     */
    renderDeleteButton = (oxime: ImmutableOxime) => (
        <BlankButton onClick={this.handleSetOximeIdToDelete(oxime.get('id'))}>
            {this.props.intl.formatMessage({
                id: 'components.OximeManager.deleteOxime',
            })}
        </BlankButton>
    );

    /**
     * Helper function which returns the JSX for the expandable details of a row
     */
    renderRowExpandedContent = (oxime: ImmutableOxime) => {
        if (
            !oxime ||
            this.state.expandedRowId !== oxime.get('id') ||
            !oxime.get('concentrations')
        ) {
            return null;
        }

        return (
            <OximeConcentrationTable
                concentrations={oxime.get('concentrations')}
                disabledInputs={this.state.disabledInputs}
                editMode={Boolean(
                    this.state.activeRow && this.state.activeRow.get('id') === oxime.get('id')
                )}
                onConcentrationChange={this.handleConcentrationChange}
                onConcentrationDelete={this.handleConcentrationDelete}
                onHandleAddEmptyRow={this.handleAddEmptyRow}
            />
        );
    };

    render() {
        return (
            <Wrapper>
                {this.renderConditionalDeleteModal()}
                {this.renderConditionalDataPreventionModal()}
                <Row alignItems="center" flex="0" style={{ margin: '0', marginBottom: '20px' }}>
                    <Column>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.OximeManager.title',
                            })}
                        </Title>
                    </Column>
                    <Column alignItems="flex-end">
                        <PrimaryButton
                            disabled={!this.state.enableAddRow || this.props.oximesAreQuerying}
                            onClick={this.handleCreateNewOxime}
                            text={this.props.intl.formatMessage({
                                id: 'components.OximeManager.createNew',
                            })}
                        />
                    </Column>
                </Row>
                <TableContainer>
                    <Table
                        currentSorting={this.props.searchCriteria}
                        header={this.provideTableHeaders()}
                        loading={this.props.oximesAreQuerying}
                        onSortBy={this.props.onHandleSortBy}
                        rows={this.provideTableRows()}
                        expandedContentMaxHeight="480px"
                    />
                    <Pagination
                        currentPage={this.props.pagination.page}
                        isLoading={this.props.oximesAreQuerying}
                        onPageSelection={this.props.onHandlePaginationPageSelection}
                        pagesTotal={this.props.lastPage}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryJoiner',
                        })}
                    />
                </TableContainer>
                <PreventNavigationPrompt
                    shouldBlock={Boolean(this.state.activeRow) && this.state.isModifiedRow}
                />
            </Wrapper>
        );
    }
}

export default injectIntl(OximeManager);
