// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import PubSub from 'pubsub-js';

import { ToastObservable } from 'components/_FrontendObservables';

// services
import { selectAllFeedbacks } from 'services/Feedback/selectors';
import {
    deleteFeedback,
    deleteAllFeedbacks,
    newFeedback,
    newUntranslatedFeedback,
} from 'services/Feedback/thunks';

// types
import type { ImmutableList, ReduxDispatch } from 'types';
import type { Feedback, FeedbackType } from 'services/Feedback/types';

// component
import Toast from 'components/Toast';
import { SnackBar } from 'styles/common';

// constants
import { TOAST_DURATION } from 'utils/constants';

type ToastTimer = {
    timeoutId: TimeoutID,
    feedbackId: number,
};
type Props = {
    feedbacks: ImmutableList<Feedback>,
    deleteFeedback: (feedbackId: number) => void,
    deleteAllFeedbacks: () => void,
    newFeedback: (feedbackType: FeedbackType, message: string) => void,
    newUntranslatedFeedback: (
        feedbackType: FeedbackType,
        messageId: string,
        data?: any,
        duration?: number
    ) => void,
};
type State = {
    timers: Array<ToastTimer>,
};

/**
 * This container hold the state for the feedbacks
 * It handles the feedback toasts and their timers
 */
class ToastContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            timers: [],
        };
    }

    componentDidMount() {
        // catch children micro frontends feedbacks
        ToastObservable.observable.subscribe(this.appendLegacyMicroFrontendFeedback);

        const MONOREPO_TOAST_TOPIC = 'solvay_mining_platform.v2.toast';
        PubSub.subscribe(MONOREPO_TOAST_TOPIC, this.appendMonorepoFeedbacks);
    }

    /**
     * When our props update, check to see if they are different, then update if necessary:
     * delete feedbacks for which the timer ended and add timer for the new feedbacks
     */
    componentDidUpdate(prevProps: Props) {
        if (this.props.feedbacks.equals(prevProps.feedbacks)) {
            return;
        }
        const newFeedbacks = this.props.feedbacks.filter(
            (feedback: Feedback) =>
                !this.state.timers.find(
                    (timer: ToastTimer) => timer.feedbackId === feedback.feedbackId
                )
        );
        const newTimers = newFeedbacks.map((feedback: Feedback) => ({
            timeoutId: setTimeout(
                this.clearFeedback(feedback.feedbackId),
                feedback.duration || TOAST_DURATION
            ),
            feedbackId: feedback.feedbackId,
        }));
        this.setState((prevState: State) => ({
            timers: prevState.timers.concat(...newTimers),
        }));
    }

    /**
     * when component unmount, destroy all feedbacks in redux store
     */
    componentWillUnmount() {
        ToastObservable.observable.unsubscribe(this.appendLegacyMicroFrontendFeedback);
        PubSub.unsubscribe(this.appendMonorepoFeedbacks);

        this.state.timers.forEach((timer: ToastTimer) => clearTimeout(timer.timeoutId));
        this.props.deleteAllFeedbacks();
    }

    /**
     * appends microfrontend feedback to feedbacks list
     *
     * These are UNTRANSLATED FEEDBACKS
     *
     * @param {string} message
     */
    appendLegacyMicroFrontendFeedback = (topic: string, toast: Toast) => {
        console.debug(
            `[Toaster] Received a new Legacy MFE toast on ${topic} containing ${toast.toastType} ${
                toast.message
            }`
        );
        if (!toast.messageId) {
            if (toast.message) {
                // no toast message ID but there is a message create a new toast directly instead
                this.props.newFeedback(toast.toastType, toast.message);
            } else {
                // no message id and no message exit
                return;
            }
        }
        this.props.newUntranslatedFeedback(
            toast.toastType,
            toast.messageId,
            toast.data,
            toast.duration
        );
    };

    appendMonorepoFeedbacks = (topic: string, toast: Toast) => {
        console.debug(
            `[Toaster] Received an v2 toast on ${topic} containing ${toast.toastType} ${
                toast.message
            }`
        );
        this.props.newFeedback(toast.toastType, toast.message);
    };

    /**
     * Destroy one specific feedback in redux store
     */
    clearFeedback = (feedbackId: number) => () =>
        this.setState(
            (prevState: State) => ({
                timers: prevState.timers.filter(
                    (timer: ToastTimer) => timer.feedbackId !== feedbackId
                ),
            }),
            () => this.props.deleteFeedback(feedbackId)
        );

    render() {
        return (
            <SnackBar>
                {this.props.feedbacks.map((feedback: Feedback) => (
                    <Toast key={feedback.feedbackId} feedback={feedback} />
                ))}
            </SnackBar>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    feedbacks: selectAllFeedbacks(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            deleteFeedback,
            deleteAllFeedbacks,
            newFeedback,
            newUntranslatedFeedback,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ToastContainer)
);
