// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Assets
import SeriesStreamImage from 'assets/mimic-diagrams-stream-images/electrolyte-series-configuration.jpg';
import ParallelStreamImage from 'assets/mimic-diagrams-stream-images/electrolyte-parallel-configuration.jpg';
// Styles
import { Wrapper, Header, Body, Footer, Field, Label, RadioStyles, ImageStyle } from './styles';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, TertiaryButton, RadioButtonSet, Modal } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import type { ElectrolyteOptionConstant } from 'services/Circuit/types';

// constants
import { MODAL_WIDTH, ELECTROLYTE_STREAM_OPTIONS } from 'utils/constants';

type OptionType = { label: string, value: ElectrolyteOptionConstant };

type Props = {
    intl: IntlType,
    currentLocation: number,

    onConfirm: (option: ElectrolyteOptionConstant, location: number) => void,
    onCancel: () => void,
};

type State = {
    // the keys used by the radio button set
    selectedElectrolyteStreamOption: ElectrolyteOptionConstant,
};

/**
 * Shown when the mimic diagram edit button is clicked in between two electroyte stages
 */
class ElectrolyteStreamSelectModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedElectrolyteStreamOption: ELECTROLYTE_STREAM_OPTIONS.SERIES,
        };
    }

    /**
     * Get the electrolyte stream radio options
     */
    getElectrolyteStreamOptions = (): Array<OptionType> =>
        Object.keys(ELECTROLYTE_STREAM_OPTIONS).map((value: ElectrolyteOptionConstant) => ({
            value,
            label: this.props.intl.formatMessage({
                id: `components.Modals.ElectrolyteStreamSelectModal.${value}`,
            }),
        }));

    /**
     * Get the Schema associated with the radio option selected
     */
    getStreamOptionImage = (optionValue: ElectrolyteOptionConstant) => {
        switch (optionValue) {
            case ELECTROLYTE_STREAM_OPTIONS.SERIES:
                return SeriesStreamImage;
            case ELECTROLYTE_STREAM_OPTIONS.PARALLEL:
                return ParallelStreamImage;
            default:
                return null;
        }
    };

    /**
     * Buttons is disabled when Series configuration is selected since it is the default selection and does not require any change
     */
    isSelectButtonDisabled = (): boolean =>
        this.state.selectedElectrolyteStreamOption === ELECTROLYTE_STREAM_OPTIONS.SERIES;

    /**
     * When the select button is clicked, call our parent with the options selected
     */
    handleSelectClicked = () =>
        this.props.onConfirm(
            this.state.selectedElectrolyteStreamOption,
            this.props.currentLocation
        );

    /**
     * When the Cascade RF radio buttons is changed
     */
    handleElectrolyteStreamOptionChange = (
        selectedElectrolyteStreamOption: ElectrolyteOptionConstant
    ) => this.setState({ selectedElectrolyteStreamOption });

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.ElectrolyteStreamSelectModal.title',
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <RadioButtonSet
                                value={this.state.selectedElectrolyteStreamOption}
                                options={this.getElectrolyteStreamOptions()}
                                onClick={this.handleElectrolyteStreamOptionChange}
                                styles={RadioStyles}
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.Modals.preview',
                                })}
                            </Label>
                            <img
                                src={this.getStreamOptionImage(
                                    this.state.selectedElectrolyteStreamOption
                                )}
                                alt={this.props.intl.formatMessage({
                                    id: `components.Modals.ElectrolyteStreamSelectModal.${
                                        this.state.selectedElectrolyteStreamOption
                                    }`,
                                })}
                                style={ImageStyle}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <TertiaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.selectButton',
                            })}
                            onClick={this.handleSelectClicked}
                            disabled={this.isSelectButtonDisabled()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(ElectrolyteStreamSelectModal);
