// @flow

import React from 'react';

import type { InputEventType, ReactNode } from '../../types';

// Components
import legacyTheme from '../../themes/legacyTheme';
import { Close } from '../../icons';

// Styles
import { ErrorMessage, FileName, HiddenInput, Input, Label } from './styles';

// Types
type Props = {
    accept?: string,
    buttonComponent?: ReactNode,
    singleUse?: boolean,
    displayInput?: boolean,
    disabled?: boolean,
    enableThumbnail?: boolean,
    fileSizeLimitErrorMessage?: ReactNode,
    fileName?: string,
    fileSizeLimit?: number,
    name: string,
    onChange?: ?(event: InputEventType) => void,
    onRemove?: (index: number) => void,
    placeholder?: string,
};

type State = {
    fileName?: string,
    fileSizeExceeded?: boolean,
};

export default class InputFile extends React.Component<Props, State> {
    static defaultProps = {
        accept: '',
        buttonComponent: null,
        disabled: false,
        displayInput: false,
        enableThumbnail: false,
        fileName: '',
        fileSizeLimit: 5000000,
        fileSizeLimitErrorMessage: null,
        onChange: null,
        onRemove: null,
        placeholder: 'Select a file...',
        singleUse: false,
    };

    state = {
        fileName: this.props.fileName || '',
        fileSizeExceeded: false,
    };

    /**
     * Handles change of input
     * Gets first file form target and sets its name to state
     * Fires props.onChange(file)
     */
    handleChange = (event: InputEventType) => {
        const file = event.target.files && event.target.files[0];

        if (this.props.fileSizeLimit && file.size > this.props.fileSizeLimit) {
            this.handleSetFileSizeErrorMessage();
            return;
        }

        const fileName = file && file.name;

        if (fileName) {
            this.setState(
                {
                    fileName,
                },
                () => {
                    if (this.props.onChange) {
                        this.props.onChange(file);
                    }
                }
            );
        }
    };

    handleSetFileSizeErrorMessage = () => {
        this.setState({
            fileSizeExceeded: true,
        });
    };

    /**
     * Handles onClick for provided button & input
     * Relies on id (props.name) and therefore must be unique
     * Simulates onClick of <HiddenInput />
     */
    handleOnClick = () => {
        const selector = document.getElementById(this.props.name);
        if (selector) {
            selector.click();
        }
    };

    /**
     * Render method for button: React.cloneElement in order to replace disabled & onClick props
     */
    renderButton = (buttonComponent: ReactNode) =>
        React.Children.map(buttonComponent, (child: ReactNode) =>
            React.cloneElement(child, {
                disabled: this.props.disabled,
                onClick: !this.props.disabled ? this.handleOnClick : null,
            })
        );

    render() {
        // Needs a unique name/id for label htmlFor
        if (!this.props.name) {
            return null;
        }

        let content;

        if (this.props.singleUse && this.props.onRemove && this.state.fileName) {
            content = (
                <Label
                    onClick={!this.props.disabled && this.props.onRemove}
                    thumbnail={this.props.enableThumbnail}
                    fullWidth
                    repeatable
                >
                    <FileName>{this.state.fileName}</FileName>
                    <Close
                        strokeWidth={0}
                        width="16px"
                        height="16px"
                        fill={legacyTheme.defaultIconColor}
                        clickable
                    />
                </Label>
            );
        } else {
            content = (
                <React.Fragment>
                    <Label
                        disabled={this.props.disabled}
                        fullWidth={this.props.displayInput}
                        htmlFor={this.props.name}
                    >
                        {this.renderButton(this.props.buttonComponent)}
                        {this.props.displayInput ? (
                            <Input
                                accept={this.props.accept}
                                disabled={this.props.disabled}
                                name={this.props.name}
                                onClick={!this.props.disabled ? this.handleOnClick : null}
                                placeholder={this.props.placeholder}
                                type="text"
                                value={this.state.fileName}
                                withButton={this.props.buttonComponent}
                                readOnly
                            />
                        ) : (
                            <FileName>{this.state.fileName}</FileName>
                        )}
                        <HiddenInput
                            id={this.props.name}
                            accept={this.props.accept}
                            name={this.props.name}
                            onChange={this.handleChange}
                            type="file"
                        />
                    </Label>
                    {this.state.fileSizeExceeded && this.props.fileSizeLimitErrorMessage && (
                        <ErrorMessage>{this.props.fileSizeLimitErrorMessage}</ErrorMessage>
                    )}
                </React.Fragment>
            );
        }

        return content;
    }
}
