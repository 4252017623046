// @flow

import React from 'react';
import { Caret } from '../../icons';

// Types
import type { ChildrenType, ReactNode } from '../../types';

// Styles
import {
    CollapseIconWrapper,
    ContentWrapper,
    LayoutContainer,
    Main,
    SidebarWrapper,
    Sidebar,
    StyledSidebarFooter,
    StyledSidebarSection,
    ResponsiveTitleWrapper,
    ResponsiveTitle,
    DesktopCollapseIconWrapper,
    MobileCollapseIconWrapper,
} from './styles';

type Props = {
    styles?: ?{
        container: Object,
        main: Object,
        sidebar: Object,
        collapsibleIcon: Object,
    },
    collapsible?: boolean,
    closedByDefault?: boolean,
    flush?: boolean,
    mainFlush?: boolean,
    mainId?: string,
    mainMaxWidth?: ?string,
    renderMain?: ?(props: Props) => ReactNode,
    renderSidebar?: ?(props: Props) => ReactNode,
    responsive?: boolean,
    responsiveMaxDeviceWidth?: string,
    responsiveTitle?: string,
    sidebarId?: string,
    sidebarWidth?: string,
    collapsedWidth?: string,
    onSidebarCollapseToggled?: (isCollapsed: boolean) => void,
    sidebarTogglerIcon?: ReactNode | undefined,
};

type State = {
    open: boolean,
};

// Remove PureComponent due to current implementation of renderMain & renderSidebar
export class SidebarLayout extends React.Component<Props, State> {
    static defaultProps = {
        closedByDefault: false,
        collapsedWidth: '15px',
        collapsible: false,
        flush: false,
        mainFlush: false,
        mainId: 'main',
        mainMaxWidth: null,
        onSidebarCollapseToggled: () => null,
        renderMain: null,
        renderSidebar: null,
        responsive: false,
        responsiveMaxDeviceWidth: '540px', // sidebar width + 1/2 sidebar width, less than iPad but more than Pixel 2
        responsiveTitle: 'Default Sidebar Title',
        sidebarId: 'sidebar',
        sidebarWidth: '360px',
        styles: {
            container: {},
            main: {},
            sidebar: {},
            collapsibleIcon: {},
        },
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            open: this.isOpenByDefault(),
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.closedByDefault !== this.props.closedByDefault) {
            this.setState({
                open: this.isOpenByDefault(this.props),
            });
        }
    }

    /**
     * Collapsible sidebar is open by default if it is not collapsible or collapsible
     * but not closed by default and either not responsive or if responsive, not in mobile view.
     */
    isOpenByDefault = () =>
        this.props.sidebarTogglerIcon ||
        ((!this.props.collapsible || (this.props.collapsible && !this.props.closedByDefault)) &&
            (!this.props.responsive || (this.props.responsive && !this.isMobile())));

    isMobile = (): boolean =>
        Boolean(
            this.props.responsive &&
                window.innerWidth <= parseInt(this.props.responsiveMaxDeviceWidth, 10)
        );

    /**
     * If SidebarLayout is responsive and in mobile view, it is always collapsible
     */
    isCollapsible = (): boolean =>
        Boolean(this.props.collapsible || (this.props.responsive && this.isMobile()));

    /**
     * Position of the collapsible icon depends on the sidebar width.
     * Shifts to the left if sidebar is collapsed and not in mobile view.
     */
    getCollapsibleIconLeftOffset = () => {
        if (this.isMobile()) {
            return 'auto';
        }
        if (this.state.open) {
            return this.props.styles &&
                this.props.styles.collapsibleIcon &&
                this.props.styles.collapsibleIcon.left
                ? this.props.styles.collapsibleIcon.left
                : this.props.sidebarWidth;
        } else {
            return this.props.collapsedWidth;
        }
    };

    /**
     * Width is unchanged and 100% in mobile view.
     * Keep sidebarWidth prop to support older version.
     * Return the available sidebar width from props or from default sidebarWidth prop.
     */
    getSidebarWidth = () => {
        if (this.isMobile()) {
            return '100%';
        } else if (this.isCollapsible() && !this.state.open) {
            return this.props.collapsedWidth;
        } else if (
            !this.isCollapsible() &&
            this.props.styles &&
            this.props.styles.sidebar &&
            this.props.styles.sidebar.width
        ) {
            return this.props.styles.sidebar.width;
        } else {
            return this.props.sidebarWidth;
        }
    };

    getSidebarStyles = () =>
        this.props.styles && this.props.styles.sidebar && !this.isMobile()
            ? this.props.styles.sidebar
            : null;

    handleToggleOpenState = () =>
        this.setState(
            (prevState: State) => ({
                open: !prevState.open,
            }),
            () => this.props.onSidebarCollapseToggled(!this.state.open)
        );

    renderCollapseIconWrapper = (
        desktopIcon: boolean = false,
        sidebarTogglerIcon: boolean = false
    ) => (
        <CollapseIconWrapper
            style={this.props.styles && this.props.styles.collapsibleIcon}
            leftOffset={this.getCollapsibleIconLeftOffset()}
            responsive={this.props.responsive}
            responsiveMaxDeviceWidth={this.props.responsiveMaxDeviceWidth}
            open={this.state.open}
            onClick={desktopIcon ? this.handleToggleOpenState : null}
            inverseBackgroundColor={this.props.sidebarTogglerIcon}
            data-testid="collapse-icon"
        >
            {this.props.sidebarTogglerIcon ? (
                this.props.sidebarTogglerIcon
            ) : (
                <Caret
                    right={!this.isMobile() && !this.state.open}
                    left={!this.isMobile() && this.state.open}
                    down={this.isMobile()}
                    grey={!this.isMobile()}
                    blue={this.isMobile()}
                />
            )}
        </CollapseIconWrapper>
    );

    render() {
        const isCollapsible = this.isCollapsible();
        const sidebarContent =
            this.props.renderSidebar && this.props.renderSidebar({ ...this.props });
        const mainContent = this.props.renderMain && this.props.renderMain({ ...this.props });

        const maxWidth =
            isCollapsible && !this.state.open ? this.props.collapsedWidth : this.getSidebarWidth();

        return (
            <React.Fragment>
                <LayoutContainer style={this.props.styles && this.props.styles.container}>
                    {sidebarContent && (
                        <SidebarWrapper>
                            <DesktopCollapseIconWrapper>
                                {isCollapsible && this.renderCollapseIconWrapper(true)}
                            </DesktopCollapseIconWrapper>
                            <Sidebar
                                id={this.props.sidebarId}
                                style={this.getSidebarStyles()}
                                sidebarWidth={this.getSidebarWidth()}
                                maxWidth={maxWidth}
                                open={this.state.open}
                                responsive={this.props.responsive}
                                responsiveMaxDeviceWidth={this.props.responsiveMaxDeviceWidth}
                            >
                                {isCollapsible && (
                                    <ResponsiveTitleWrapper
                                        responsive={this.props.responsive}
                                        responsiveMaxDeviceWidth={
                                            this.props.responsiveMaxDeviceWidth
                                        }
                                        onClick={this.handleToggleOpenState}
                                    >
                                        <ResponsiveTitle
                                            responsive={this.props.responsive}
                                            responsiveMaxDeviceWidth={
                                                this.props.responsiveMaxDeviceWidth
                                            }
                                        >
                                            {this.props.responsiveTitle}
                                        </ResponsiveTitle>
                                        <MobileCollapseIconWrapper>
                                            {this.renderCollapseIconWrapper()}
                                        </MobileCollapseIconWrapper>
                                    </ResponsiveTitleWrapper>
                                )}
                                <ContentWrapper
                                    flush={this.props.flush}
                                    minWidth={this.props.sidebarWidth}
                                    responsiveMaxDeviceWidth={this.props.responsiveMaxDeviceWidth}
                                >
                                    {sidebarContent}
                                </ContentWrapper>
                            </Sidebar>
                        </SidebarWrapper>
                    )}
                    <Main
                        id={this.props.mainId}
                        fullWidth={!sidebarContent}
                        hasContent={Boolean(mainContent)}
                        style={this.props.styles && this.props.styles.main}
                        responsive={this.props.responsive}
                        responsiveMaxDeviceWidth={this.props.responsiveMaxDeviceWidth}
                    >
                        <ContentWrapper
                            mainMaxWidth={this.props.mainMaxWidth}
                            flush={this.props.mainFlush}
                        >
                            {mainContent}
                        </ContentWrapper>
                    </Main>
                </LayoutContainer>
            </React.Fragment>
        );
    }
}

export function SidebarFooter(props: { children: ChildrenType }) {
    return <StyledSidebarFooter>{props.children}</StyledSidebarFooter>;
}

export function SidebarSection(props: { children: ChildrenType }) {
    return <StyledSidebarSection>{props.children}</StyledSidebarSection>;
}
