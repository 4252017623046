// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Assets
import { Loader, CheckBox, Common, Dot, GearIcon } from 'components/_ReactUI_V1';

import CopyIcon from 'assets/icons/copy-icon';

// Helpers
import { getKPISettingUnit } from 'utils/kpiHelpers';
import { isSuperPMUser, isSolvayUser } from 'utils/authentication';
import { nodeToClipboard } from 'utils/helpers';

// Components
import KPIGraph from 'components/KPIGraph';
import GraphPeriodSelector from 'components/GraphPeriodSelector';
import EditKPISettingsModal from 'components/Modals/EditKPISettingsModal';

// Constants
import { DEFAULT_PERIOD, KPI_SPECIFICITY_TYPES, STAGE_TYPES } from 'utils/constants';
import { KPI_OVERLAY_OPTIONS, UNIVERSAL_KPI_OVERLAY_OPTIONS } from 'utils/kpiConstants';

// Services
import { selectKPIHistories } from 'services/Trends/selectors';
import { selectAllKPISettings } from 'services/KPISetting/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { newFeedback } from 'services/Feedback/thunks';

// Styles
import {
    Wrapper,
    Header,
    Title,
    Body,
    LoaderWrapper,
    DotLoaderWrapper,
    SettingsButtonHover,
    ButtonContainer,
} from './styles';
import { KPIGraphColorSchemes } from 'styles/colors';

// Types
import type { IntlType, ImmutableList, ReduxDispatch } from 'types';
import type { FeedbackType } from 'services/Feedback/types';
import type { TrendsPeriodConstant, ImmutableKPIHistory } from 'services/Trends/types';
import type { ImmutableKPISetting, AllKpiTypes } from 'services/KPISetting/types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableStage } from 'services/Circuit/types';

type Props = {
    intl: IntlType,
    userLanguage: string,
    user: ImmutableUser,
    timezone: string,
    kpiSetting: ImmutableKPISetting,
    kpiHistory: ?ImmutableKPIHistory,
    stages?: ?ImmutableList<ImmutableStage>,

    kpiSettings: ImmutableList<ImmutableKPISetting>,
    kpiHistories: ImmutableList<ImmutableKPIHistory>,

    onChangePeriodForMinor: (kpiId: number, newPeriod: TrendsPeriodConstant) => void,

    newFeedback: (feedbackType: FeedbackType, message: string) => void,
};

type State = {
    overlaidKpis: Array<number>,
    showEditSettingsModal: boolean,
};

class MinorKPIModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.exportTarget = React.createRef();
        this.state = {
            overlaidKpis: [],
            showEditSettingsModal: false,
        };
    }

    componentDidMount() {
        if (
            !this.props.kpiHistory ||
            (!this.props.kpiHistory.get('isLoading') &&
                this.props.kpiHistory.get('history') === null)
        ) {
            this.handleChangePeriod(DEFAULT_PERIOD);
        }
    }

    getTranslation = (messageId: string, data: ?object) =>
        this.props.intl.formatMessage(
            {
                id: messageId,
            },
            data
        );

    refetchAllHistories = (newPeriod?: TrendsPeriodConstant) => {
        const currentPeriod = newPeriod || this.props.kpiHistory.get('period');
        const mustRefetch = this.state.overlaidKpis.filter(
            (kpiId) =>
                // if we cannot find an existing kpi history for the current period,
                // then we must refetch it.
                !this.props.kpiHistories.find(
                    (kpiHistory: ImmutableKPIHistory) =>
                        kpiHistory.get('kpiSettingId') === kpiId &&
                        kpiHistory.get('period') === currentPeriod
                )
        );
        mustRefetch.forEach((kpiId) => this.props.onChangePeriodForMinor(kpiId, currentPeriod));
    };

    getFilteredKPISettings = () => {
        if (this.props.kpiSetting.get('specificityType') === KPI_SPECIFICITY_TYPES.PLANT) {
            const plantId = this.props.kpiSetting.get('plantId');
            return this.props.kpiSettings.filter(
                (kpi: ImmutableKPISetting) => kpi.get('plantId') === plantId
            );
        } else {
            const circuitId = this.props.kpiSetting.get('circuitId');
            return this.props.kpiSettings.filter(
                (kpi: ImmutableKPISetting) => kpi.get('circuitId') === circuitId && kpi.get('show')
            );
        }
    };

    getKPIOverlayOptions = () => {
        const kpiType = this.props.kpiSetting.get('kpiType');
        const kpiOverlayOptions =
            KPI_OVERLAY_OPTIONS.find(
                (kpiOverlayOption: Array<AllKpiTypes>) => kpiOverlayOption.indexOf(kpiType) !== -1
            ) || [];
        kpiOverlayOptions.push(...UNIVERSAL_KPI_OVERLAY_OPTIONS);
        if (kpiOverlayOptions.length === 0) {
            return null;
        }
        // if the KPI is in our current circuit/plant context
        // and the KPI is in our kpi overlay options, display those options
        return this.getFilteredKPISettings().filter(
            (kpi: ImmutableKPISetting) =>
                kpiOverlayOptions.indexOf(kpi.get('kpiType')) !== -1 &&
                kpi.get('id') !== this.props.kpiSetting.get('id')
        );
    };

    getOverlaidKPIHistories = () =>
        this.state.overlaidKpis.map((kpiId: number) =>
            this.props.kpiHistories.find(
                (kpiHistory: ImmutableKPIHistory) =>
                    kpiHistory.get('kpiSettingId') === kpiId && !kpiHistory.get('isLoading')
            )
        );

    getKPIWithUnit = () => {
        const unit = getKPISettingUnit(this.props.kpiSetting, this.props.intl);
        const kpiName = this.props.kpiSetting.get('name');
        if (!unit) {
            // if there is no unit only render the name.
            return kpiName;
        }

        return this.props.intl.formatMessage(
            {
                id: `components.KPIGraph.kpiNameWithUnit`,
            },
            {
                kpiName,
                unit,
            }
        );
    };

    /**
     * A user may only edit the KPI's settings if it is a Super-PM (SPM) or a Solvay (SAM/ADMIN)
     * @returns Whether the button is visible or not
     */
    shouldShowSettingsButton = (): boolean => {
        const canAccessKPISettings =
            isSuperPMUser(this.props.user) || isSolvayUser(this.props.user);
        return canAccessKPISettings;
    };

    /**
     * When copy to clipboard button clicked
     */
    handleCopyToClipboardClick = () =>
        nodeToClipboard(
            this.exportTarget.current,
            (feedbackType: FeedbackType, messageId: string) =>
                this.props.newFeedback(feedbackType, this.getTranslation(messageId))
        );

    handleToggleKPIOverlay = (kpiId: number) => (event) =>
        this.setState(
            (prevState: State) => {
                if (!event.target.checked) {
                    return {
                        overlaidKpis: prevState.overlaidKpis.filter(
                            (overlaidKpiId) => overlaidKpiId !== kpiId
                        ),
                    };
                } else {
                    return {
                        overlaidKpis: [...prevState.overlaidKpis, kpiId],
                    };
                }
            },
            () => this.refetchAllHistories()
        );

    handleChangePeriod = (newPeriod: TrendsPeriodConstant) => {
        this.refetchAllHistories(newPeriod);
        this.props.onChangePeriodForMinor(this.props.kpiSetting.get('id'), newPeriod);
    };

    handleSettingsClicked = () =>
        this.setState({
            showEditSettingsModal: true,
        });

    handleHideSettingsModal = () =>
        this.setState({
            showEditSettingsModal: false,
        });

    getSortedOverlayOptions = (
        options: ImmutableList<ImmutableKPISetting>
    ): ImmutableList<ImmutableKPISetting> => {
        if (!this.props.stages) {
            return options;
        }

        const areStageSpecific = options.every(
            (el: ImmutableKPISetting) => el.get('specificityType') === KPI_SPECIFICITY_TYPES.STAGE
        );

        if (!areStageSpecific) {
            return options;
        }

        // The order of the stage types in that array determines the order of display
        const stageTypes = [STAGE_TYPES.EXTRACT, STAGE_TYPES.STRIP];

        const sortedOptions = options.sort((a: ImmutableKPISetting, b: ImmutableKPISetting) => {
            const aStage = this.props.stages.find(
                (el: ImmutableStage) => el.get('id') === a.get('stageId')
            );
            const bStage = this.props.stages.find(
                (el: ImmutableStage) => el.get('id') === b.get('stageId')
            );

            const aStageType = aStage.get('stageType');
            const bStageType = bStage.get('stageType');

            const aStageLocation = aStage.get('location');
            const bStageLocation = bStage.get('location');

            return (
                stageTypes.indexOf(aStageType) - stageTypes.indexOf(bStageType) ||
                aStageLocation - bStageLocation
            );
        });

        return sortedOptions;
    };

    renderOverlayOptions = () => {
        const options = this.getKPIOverlayOptions();
        if (!options || options.size === 0) {
            return null;
        }

        const sortedOptions = this.getSortedOverlayOptions(options);

        return (
            <Common.Row style={{ width: 'unset' }}>
                {sortedOptions.map((kpi: ImmutableKPISetting, idx) => {
                    const overlaidIndex = this.state.overlaidKpis.findIndex(
                        (kpiId) => kpiId === kpi.get('id')
                    );
                    const kpiId = kpi.get('id');
                    const overlaidItem =
                        overlaidIndex !== -1 ? this.state.overlaidKpis[overlaidIndex] : null;
                    const overlaidHistory =
                        overlaidIndex === -1
                            ? false
                            : this.props.kpiHistories.find(
                                  (kpiHistory: ImmutableKPIHistory) =>
                                      kpiHistory.get('kpiSettingId') === kpiId
                              );
                    const isLoading = overlaidHistory && overlaidHistory.get('isLoading');
                    return (
                        <React.Fragment key={kpiId}>
                            {overlaidItem && !isLoading && (
                                // If you change this index make sure you change the KPIGraph index as well.
                                <Dot
                                    fill={
                                        KPIGraphColorSchemes[
                                            (overlaidIndex + 1) % KPIGraphColorSchemes.length
                                        ].targetLine
                                    }
                                    margin="0 8px 0 8px"
                                />
                            )}
                            {isLoading && (
                                <DotLoaderWrapper>
                                    <Loader loading size={'16px'} width={'2px'} />
                                </DotLoaderWrapper>
                            )}
                            <CheckBox
                                label={kpi.get('name')}
                                name={kpiId}
                                checked={Boolean(overlaidItem)}
                                onClickHandler={this.handleToggleKPIOverlay(kpiId)}
                                styles={!overlaidItem && idx !== 0 ? { marginLeft: '8px' } : {}}
                            />
                        </React.Fragment>
                    );
                })}
            </Common.Row>
        );
    };

    renderContent = () =>
        this.props.kpiHistory.get('isLoading') ? (
            <LoaderWrapper>
                <Loader loading />
            </LoaderWrapper>
        ) : (
            <KPIGraph
                kpiId={this.props.kpiSetting.get('id')}
                kpiHistory={this.props.kpiHistory}
                additionalKpiHistories={this.getOverlaidKPIHistories()}
                period={this.props.kpiHistory.get('period')}
                timezone={this.props.timezone}
                userLanguage={this.props.userLanguage}
                noDomain={this.state.overlaidKpis.length > 0}
                showViewAverageCheckbox={true}
                showTargetRange={this.state.overlaidKpis.length === 0}
                hideMainTarget={this.state.overlaidKpis.length > 0}
                hideAreaChart={this.state.overlaidKpis.length > 0}
                showYAxisInputs={true}
            />
        );

    renderCopyToClipboardButton = () => (
        <SettingsButtonHover
            onClick={this.handleCopyToClipboardClick}
            title={this.props.intl.formatMessage({
                id: 'common.buttons.copyToClipboard',
            })}
        >
            <CopyIcon width={18} height={18} margin="0" />
        </SettingsButtonHover>
    );

    renderSettingsButton = () => (
        <SettingsButtonHover onClick={this.handleSettingsClicked}>
            <GearIcon width="20px" height="20px" margin="0" />
        </SettingsButtonHover>
    );

    renderEditSettingsModal = () => (
        <EditKPISettingsModal
            kpiId={this.props.kpiSetting.get('id')}
            onCloseModal={this.handleHideSettingsModal}
        />
    );

    render() {
        if (!this.props.kpiHistory) {
            return null;
        }
        return (
            <Wrapper ref={this.exportTarget}>
                <Header>
                    <div />
                    <Title>{this.getKPIWithUnit()}</Title>
                    <ButtonContainer>
                        {this.renderCopyToClipboardButton()}
                        {this.shouldShowSettingsButton() ? this.renderSettingsButton() : <div />}
                    </ButtonContainer>
                </Header>
                <Body>
                    <GraphPeriodSelector
                        period={this.props.kpiHistory.get('period')}
                        loading={this.props.kpiHistory.get('isLoading')}
                        onChangePeriod={this.handleChangePeriod}
                        center
                    />
                    {this.renderContent()}
                    {this.renderOverlayOptions()}
                </Body>
                {this.state.showEditSettingsModal && this.renderEditSettingsModal()}
            </Wrapper>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
        kpiSettings: selectAllKPISettings(),
        kpiHistories: selectKPIHistories(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            newFeedback,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MinorKPIModal));
