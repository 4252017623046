// @flow strict

import React from 'react';
import { matchPath } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import { TabNav } from 'components/_ReactUI_V1';

// Constants & Helpers
import { NAVIGATION_ROUTES, BLANK_TEMPLATE, NEW_DATASET_POSTFIX } from 'utils/constants';
import { tryParseNumberOrNull } from 'utils/helpers';

// Types
import type { IntlType, RouteType } from 'types';

type Props = {
    intl: IntlType,

    route: RouteType,
    currentLocation: string,
};

class MinChemPageNav extends React.PureComponent<Props> {
    getParams = () => {
        const path = this.props.route.path;
        const match = matchPath(this.props.currentLocation, {
            path: this.props.route.path,
            exact: true,
            strict: false,
        });
        if (!match) {
            return null;
        }
        return match.params;
    };

    getCircuitId = () => {
        const params = this.getParams();
        if (!params || !params.circuitId) {
            return null;
        }
        const circuitId = tryParseNumberOrNull(params.circuitId);
        return circuitId;
    };

    getDatasetId = () => {
        const params = this.getParams();
        if (!params || !params.datasetId) {
            return null;
        }
        if (params.datasetId === NEW_DATASET_POSTFIX) {
            return NEW_DATASET_POSTFIX;
        }
        const datasetId = tryParseNumberOrNull(params.datasetId);
        return datasetId;
    };

    render() {
        const circuitId = this.getCircuitId();
        if (!circuitId) {
            return null;
        }

        const options = [];
        options.push({
            label: this.props.intl.formatMessage({ id: `navigation.CircuitSetup` }),
            to: `${NAVIGATION_ROUTES.CIRCUIT}${circuitId}`,
        });

        const datasetId = this.getDatasetId();
        if (datasetId && datasetId !== BLANK_TEMPLATE) {
            options.push({
                label: this.props.intl.formatMessage({ id: `navigation.Computation` }),
                to: `${NAVIGATION_ROUTES.COMPUTATION}${circuitId}${
                    NAVIGATION_ROUTES.COMPUTATION_DATASET
                }${datasetId}`,
            });
        } else {
            options.push({
                label: this.props.intl.formatMessage({ id: `navigation.Computation` }),
                to: `${NAVIGATION_ROUTES.COMPUTATION}${circuitId}${
                    NAVIGATION_ROUTES.COMPUTATION_DATASET
                }${NEW_DATASET_POSTFIX}`,
            });
        }

        return <TabNav current={this.props.currentLocation} options={options} maxWidth="320" />;
    }
}

export default injectIntl(MinChemPageNav);
