// @flow strict

import React from 'react';

// Styles
import { Wrapper, Steps, Step } from './styles';

// Types
import type { ElevationStepType } from 'containers/ElevationContainer';

type Props = {
    currentStep: ElevationStepType,
    elevationSteps: Array<ElevationStepType>,
    maxWidthValue?: string,
};

class ElevationProgress extends React.PureComponent<Props> {
    static defaultProps = {
        maxWidthValue: '',
    };

    render() {
        const activeIndex = this.props.elevationSteps.findIndex(
            (step: ElevationStepType) => step.key === this.props.currentStep.key
        );
        return (
            <Wrapper
                stepCount={this.props.elevationSteps.length}
                maxWidthValue={this.props.maxWidthValue}
            >
                <Steps>
                    {this.props.elevationSteps.map((step: ElevationStepType, index: number) => (
                        <Step key={step.key} active={index <= activeIndex}>
                            {step.label}
                        </Step>
                    ))}
                </Steps>
            </Wrapper>
        );
    }
}

export default ElevationProgress;
