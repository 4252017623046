// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';

export const Wrapper = styled.div`
    border: 1px solid ${legacyTheme.defaultBorder};
    border-radius: 4px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    padding: 12px 16px;

    background-color: ${legacyTheme.collapsibleBlockHeaderBackground};

    ${({ open }: Object) =>
        open &&
        `
        border-bottom : 1px solid ${legacyTheme.defaultBorder};
    `}

    cursor: ${({ onClick }: Object) => (onClick ? 'pointer' : 'default')};

    color: ${legacyTheme.collapsibleBlockHeader};
    font-size: 14px;
`;

export const Title = styled.div``;

export const CaretWrapper = styled.div`
    margin-left: 16px;
`;

export const Content = styled.div`
    padding: ${({ padding }: Object) => padding || '24px 36px'};
`;
