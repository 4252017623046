/* eslint-disable flowtype/no-weak-types */
// @flow strict

import React from 'react';

// Components
import { Table } from 'components/_ReactUI_V1';
import CardList from 'components/CardList';

// Styles
import { ShowForDesktopOnly, ShowForMobileOnly } from 'styles/responsive';

// Types
import type { TableHeaderType } from 'types';

type Props = {
    currentSorting?: Object,
    expandedContentMaxHeight?: ?string,
    gridStyling?: boolean,
    header: Array<TableHeaderType>,
    headerColor?: ?string,
    footerMessage?: ?string,
    loading: boolean,
    onAddEmptyRow?: ?() => void,
    onSortBy?: ?(Object) => void,
    overlay?: Node,
    rows: Array<Object>,
    subtleStyling?: boolean,
    tdColor?: ?string,
    tdMaxWidth?: ?string,
    tdMinHeight?: ?string,
    tdVerticalAlign?: ?string,
    tdWordBreak?: ?string,
    tdFontSize?: ?string,
    tdHeight?: ?string,
    tdPadding?: ?string,
    trBackgroundColor?: ?string,
    trBackgroundColorHover?: ?string,
    titleId?: string,
    controlsId?: string,
    mobileOnlyHeaders?: Array<string>,
};

class ResponsiveTable extends React.PureComponent<Props> {
    static defaultProps = {
        mobileOnlyHeaders: [],
    };

    render() {
        return (
            <React.Fragment>
                <ShowForDesktopOnly>
                    <Table
                        expandedContentMaxHeight={this.props.expandedContentMaxHeight}
                        footerMessage={this.props.footerMessage}
                        gridStyling={this.props.gridStyling}
                        header={this.props.header.filter(
                            (h: TableHeaderType) => !this.props.mobileOnlyHeaders.includes(h.id)
                        )}
                        headerColor={this.props.headerColor}
                        loading={this.props.loading}
                        onAddEmptyRow={this.props.onAddEmptyRow}
                        onSortBy={this.props.onSortBy}
                        overlay={this.props.overlay}
                        rows={this.props.rows}
                        subtleStyling={this.props.subtleStyling}
                        tdColor={this.props.tdColor}
                        tdMaxWidth={this.props.tdMaxWidth}
                        tdMinHeight={this.props.tdMinHeight}
                        tdVerticalAlign={this.props.tdVerticalAlign}
                        tdWordBreak={this.props.tdWordBreak}
                        tdFontSize={this.props.tdFontSize}
                        tdPadding={this.props.tdPadding}
                        tdHeight={this.props.tdHeight}
                        trBackgroundColor={this.props.trBackgroundColor}
                        trBackgroundColorHover={this.props.trBackgroundColorHover}
                    />
                </ShowForDesktopOnly>
                <ShowForMobileOnly>
                    <CardList
                        header={this.props.header}
                        rows={this.props.rows}
                        titleId={this.props.titleId}
                        controlsId={this.props.controlsId}
                        loading={this.props.loading}
                    />
                </ShowForMobileOnly>
            </React.Fragment>
        );
    }
}

export default ResponsiveTable;
