import { NAVIGATION_ROUTES, USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES } from 'utils/constants';
import { NAVBAR_DASHBOARD_V2_REDIRECT } from 'env';

const getCircuitDashboardUrl = (plantId: number, circuitId: number) => {
    if (!NAVBAR_DASHBOARD_V2_REDIRECT) {
        // This is the LEGACY url.
        // computed as: /circuit/456/trends
        // TODO: DGM-3646 Remove legacy navigation
        return `${NAVIGATION_ROUTES.CIRCUIT}${circuitId}${NAVIGATION_ROUTES.TRENDS}`;
    }

    // computed as: /plant/123/circuit/456
    return `${NAVIGATION_ROUTES.PLANT}${plantId}${NAVIGATION_ROUTES.CIRCUIT}${circuitId}`;
};

const getPlantEditorUrl = (plantId: number, circuitId: number) => {
    if (!NAVBAR_DASHBOARD_V2_REDIRECT) {
        // LEGACY, use v2 instead.
        // TODO: DGM-3646 Remove legacy navigation
        return `${NAVIGATION_ROUTES.CIRCUIT}${circuitId}${NAVIGATION_ROUTES.ELEVATE}`;
    }
    // computed as: /management/plants/123
    return `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS}/${plantId}`;
};

export const SolvExtractNavigation = {
    getCircuitDashboardUrl,
    getPlantEditorUrl,
};
