// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { PrimaryButton, TertiaryButton, RadioButtonSet, Modal, InputSelect } from 'components/_ReactUI_V1';

// Assets
import FeedStreamImage from 'assets/mimic-diagrams-stream-images/aqueous-add-new-feed.jpg';
import BlendStreamImage from 'assets/mimic-diagrams-stream-images/aqueous-blend-in-new-feed.jpg';
import BleedStreamImage from 'assets/mimic-diagrams-stream-images/aqueous-bleed-off-fraction.jpg';
import BleedBlendStreamImage from 'assets/mimic-diagrams-stream-images/aqueous-bleed-blend.jpg';
import SkipStreamImage from 'assets/mimic-diagrams-stream-images/aqueous-skip.jpg';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    RadioStyles,
    ImageStyle,
    InputStyles,
    InputSelectWrapper,
} from './styles';

// Components
import { Title } from 'styles/common';

// Types
import type { IntlType, ReactSelectObject } from 'types';
import type { PlsOptionConstant } from 'services/Circuit/types';

// Constant
import { MODAL_WIDTH, PLS_STREAM_OPTIONS } from 'utils/constants';

type OptionType = { label: string, value: PlsOptionConstant, disabled: boolean };
type SkipUnitOptionType = { label: string, value: string };

type Props = {
    intl: IntlType,
    currentLocation: number, // Stage location of the previous settler in the stream (= number of previous flow unit that can be skipped in the mimic diagram)
    stagesForSkipLocations: Array<number>,

    onConfirm: (
        plsStreamOption: PlsOptionConstant,
        currentLocation: number,
        selectedSkipUnitLocation: ?number
    ) => void,
    onCancel: () => void,
};

type State = {
    // the keys used by the radio button set
    selectedPlsStreamOption: PlsOptionConstant,
    selectedSkipUnit: ?SkipUnitOptionType,
};

/**
 * Shown when the mimic diagram edit button is clicked in between two aqueous stages
 */
class AqueousStreamSelectModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedPlsStreamOption: PLS_STREAM_OPTIONS.FEED,
            selectedSkipUnit: null,
        };
    }

    /**
     * Convert the item from the mimic diagram to a drop down select object
     */
    itemToReactSelectObject = (item: number): ReactSelectObject => ({
        value: `${item}`,
        label: `${item}`,
    });

    isOptionDisabled = (option: PlsOptionConstant): boolean =>
        option === PLS_STREAM_OPTIONS.SKIP && this.props.stagesForSkipLocations.length === 0;

    /**
     * Get the flow unit options from the current location
     */
    getFlowOptions = (): Array<ReactSelectObject> =>
        this.props.stagesForSkipLocations
            .slice()
            .reverse()
            .map(this.itemToReactSelectObject);

    /**
     * Get the PLS stream radio options
     */
    getPlsStreamOptions = (): Array<OptionType> =>
        Object.keys(PLS_STREAM_OPTIONS).map((value: PlsOptionConstant) => ({
            value,
            label: this.props.intl.formatMessage({
                id: `components.Modals.AqueousStreamSelectModal.${value}`,
            }),
            disabled: this.isOptionDisabled(value),
        }));

    /**
     * Get the Schema associated with the radio option selected
     */
    getStreamOptionImage = (optionValue: PlsOptionConstant) => {
        switch (optionValue) {
            case PLS_STREAM_OPTIONS.FEED:
                return FeedStreamImage;
            case PLS_STREAM_OPTIONS.BLEND:
                return BlendStreamImage;
            case PLS_STREAM_OPTIONS.BLEED:
                return BleedStreamImage;
            case PLS_STREAM_OPTIONS.BLEED_BLEND:
                return BleedBlendStreamImage;
            case PLS_STREAM_OPTIONS.SKIP:
                return SkipStreamImage;
            default:
                return null;
        }
    };

    /**
     * The aqueous stream select modal is only disabled when the skip stream is selected and no mixer/settler unit was selected.
     */
    isSelectButtonDisabled = () => {
        if (this.state.selectedPlsStreamOption === PLS_STREAM_OPTIONS.SKIP) {
            if (this.state.selectedSkipUnit === null) {
                return true;
            }
        }
        return false; // no other requirements.
    };

    /**
     * When the select button is clicked, call our parent with the options selected
     */
    handleSelectClicked = () => {
        this.props.onConfirm(
            this.state.selectedPlsStreamOption,
            this.props.currentLocation,
            this.state.selectedSkipUnit && Number(this.state.selectedSkipUnit.value)
        );
    };

    /**
     * When the Cascade RF radio buttons is changed
     */
    handlePlsStreamOptionChange = (selectedPlsStreamOption: PlsOptionConstant) =>
        selectedPlsStreamOption === PLS_STREAM_OPTIONS.SKIP
            ? this.setState({
                  selectedPlsStreamOption,
              })
            : this.setState({
                  selectedPlsStreamOption,
                  selectedSkipUnit: null,
              });

    /**
     * When the flow unit input select is changed
     */
    handleFlowUnitChange = (selectedSkipUnit: SkipUnitOptionType) =>
        this.setState({ selectedSkipUnit });

    /**
     * render the input select for the different flow unit
     */
    renderInputFieldUnitFlow = () => (value: PlsOptionConstant, active: boolean) =>
        value === PLS_STREAM_OPTIONS.SKIP && (
            <InputSelectWrapper>
                <InputSelect
                    selectedOption={this.state.selectedSkipUnit}
                    isDisabled={!active}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.Modals.selectButton',
                    })}
                    options={this.getFlowOptions()}
                    onSelect={this.handleFlowUnitChange}
                    styles={InputStyles}
                    controlShouldRenderValue
                />
            </InputSelectWrapper>
        );

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.AqueousStreamSelectModal.title',
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <RadioButtonSet
                                value={this.state.selectedPlsStreamOption}
                                options={this.getPlsStreamOptions()}
                                onClick={this.handlePlsStreamOptionChange}
                                styles={RadioStyles}
                                renderSuffix={this.renderInputFieldUnitFlow()}
                                suffixLocation="RIGHT"
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.Modals.preview',
                                })}
                            </Label>
                            <img
                                src={this.getStreamOptionImage(this.state.selectedPlsStreamOption)}
                                alt={this.props.intl.formatMessage({
                                    id: `components.Modals.AqueousStreamSelectModal.${
                                        this.state.selectedPlsStreamOption
                                    }`,
                                })}
                                style={ImageStyle}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <TertiaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.selectButton',
                            })}
                            disabled={this.isSelectButtonDisabled()}
                            onClick={this.handleSelectClicked}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(AqueousStreamSelectModal);
