// @flow

import styled from 'styled-components';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

export const StyledList = styled.ul`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;

    padding: 0;
    margin: ${({ theme }: Object) => themePropertyFetcher(theme, ['tagList', 'margin'])};

    list-style: none;
`;

export const StyledItem = styled.li`
    position: relative;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;

    height: ${({ theme }: Object) => themePropertyFetcher(theme, ['tagList', 'item', 'height'])};

    margin: ${({ theme }: Object) => themePropertyFetcher(theme, ['tagList', 'item', 'margin'])};
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    padding: ${({ theme }: Object) => themePropertyFetcher(theme, ['tagList', 'item', 'padding'])};

    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['tagList', 'item', 'backgroundColor'])};
    box-shadow: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['tagList', 'item', 'boxShadow'])};
    border-radius: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['tagList', 'item', 'borderRadius'])};

    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['tagList', 'item', 'color'])};

    font-size: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['tagList', 'item', 'fontSize'])};

    box-sizing: border-box;
`;

export const StyledClose = styled.div`
    display: block;
    padding: 4px;
    margin-left: 4px;

    cursor: pointer;
`;

export const StyledButtonItem = styled.li`
    display: flex;

    margin: ${({ theme }: Object) => themePropertyFetcher(theme, ['tagList', 'item', 'margin'])};

    svg {
        display: block;
    }
`;
