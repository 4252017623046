// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Title,
    Subtitle,
    RadioLabel,
    SubRadioLabel,
    TrashStyles,
    WrapperRow,
    RadioStyles,
} from './styles';

// Components
import {
    ButtonHover,
    PrimaryButton,
    SecondaryButton,
    Trash,
    RadioButton,
    Modal,
    LegacyTheme,
} from 'components/_ReactUI_V1';
import MultiList from 'components/MultiList';

// Types
import type { IntlType, ErrorType, ImmutableList } from 'types';
import type { OpenModalFunction } from 'containers/DashboardContainer';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableDataset } from 'services/Dataset/types';

// constants
import { MODAL_WIDTH } from 'utils/constants';

type RowData = {
    id: number,
    row: React.Node,
    onClick: () => void,
};

type Props = {
    intl: IntlType,
    errors: ErrorType,
    loading: boolean,
    circuit: ImmutableCircuit,
    hidden: boolean,

    onOpenDatasetModal: OpenModalFunction,
    onConfirm: (circuit: ImmutableCircuit, dataset: 'BLANK' | ImmutableDataset) => void,
    onCancel: () => void,
};

type State = {
    selectedDataset: 'BLANK' | ImmutableDataset,
};

/**
 * Renders the circuit select dataset modal.
 */
class DatasetSelectModal extends React.PureComponent<Props, State> {
    // Check whether the dataset that we have selected still exists in our next props,
    // when this component updates.
    static getDerivedStateFromProps(nextProps: Props, state: State): State {
        // Do not change state if we've selected blank dataset.
        if (state.selectedDataset !== 'BLANK') {
            // Otherwise find the dataset in our props
            const propDataset = nextProps.circuit
                .get('datasets')
                .find(
                    (dataset: ImmutableDataset) =>
                        dataset.get('id') === state.selectedDataset.get('id')
                );
            if (!propDataset) {
                // If we did not find our selectedDataset in our props, reselect the default option (blank dataset)
                return {
                    selectedDataset: 'BLANK',
                };
            }
        }
        return state; // Do not modify the state.
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedDataset: 'BLANK',
        };
    }

    /**
     * Get the translation message for the given key
     */
    getTranslation = (key: string) =>
        this.props.intl.formatMessage({
            id: `components.CircuitModals.SelectModal.${key}`,
        });

    /**
     * When the select button is clicked
     */
    handleSelectClicked = () =>
        this.props.onConfirm(this.props.circuit, this.state.selectedDataset);

    /**
     * Close the modal when cancel is clicked.
     */
    handleCancelClicked = () => this.props.onCancel();

    /**
     * Update the state with the selected dataset.
     */
    handleDatasetSelected = (selectedDataset: 'BLANK' | ImmutableDataset) => () =>
        this.setState({ selectedDataset });

    /**
     * Delete a dataset when trash is clicked.
     */
    handleDeleteDataset = (deleteDataset: ImmutableDataset) => (e) => {
        e.stopPropagation();
        this.props.onOpenDatasetModal('DELETE_DATASET', deleteDataset);
    };

    /**
     * Get the dataset row given a dataset.
     */
    getRow = (dataset: ImmutableDataset) => () => (
        <WrapperRow>
            <RadioButton
                key={dataset.get('id')}
                label={<RadioLabel>{dataset.get('name')}</RadioLabel>}
                value={dataset.get('id')}
                onClickHandler={this.handleDatasetSelected(dataset)}
                renderSuffix={this.renderDatasetMode(dataset)}
                wrapperOverflow="HIDDEN"
                styles={{ style: RadioStyles }}
                active={
                    this.state.selectedDataset !== 'BLANK' &&
                    this.state.selectedDataset.get('id') === dataset.get('id')
                }
            />
            <ButtonHover onClick={this.handleDeleteDataset(dataset)} style={TrashStyles}>
                <Trash />
            </ButtonHover>
        </WrapperRow>
    );

    /**
     * Get the blank dataset row.
     */
    getBlankRow = () => (
        <WrapperRow>
            <RadioButton
                key={-1}
                label={<RadioLabel>{this.getTranslation('BlankTemplate')}</RadioLabel>}
                value={-1}
                active={this.state.selectedDataset === 'BLANK'}
                styles={{ style: RadioStyles }}
                onClickHandler={this.handleDatasetSelected('BLANK')}
            />
        </WrapperRow>
    );

    /**
     * Get the rows for the MultiSelect Component
     * Prepend the blank row to the list of our datasets.
     */
    getDatasetRows = () =>
        [
            {
                id: -1,
                render: this.getBlankRow,
            },
        ].concat(
            this.props.circuit
                .get('datasets')
                .map((dataset: ImmutableDataset) => ({
                    id: dataset.get('id'),
                    render: this.getRow(dataset),
                }))
                .toArray()
        );

    /**
     * Renders the radio button's sub label, that displays the state of the dataset:
     * Renders either Prediction Mode, or Analysis Mode.
     */
    renderDatasetMode = (dataset: ImmutableDataset) => () => (
        <SubRadioLabel>
            {this.getTranslation(`datasetMode.${dataset.get('datasetMode')}`)}
        </SubRadioLabel>
    );

    render() {
        if (this.props.hidden || !this.props.circuit) return null;
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.BIG} disableClose>
                <Wrapper>
                    <Header>
                        <Title>{this.props.circuit.get('name')}</Title>
                        <Subtitle>{this.props.circuit.get('plantName')}</Subtitle>
                    </Header>
                    <Body>
                        <MultiList
                            title={this.getTranslation('TableHeader.name')}
                            rows={this.getDatasetRows()}
                            loading={this.props.loading}
                            styles={{ maxHeight: '330px', rowHeight: '70px' }}
                        />
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.CircuitModals.common.cancelButton',
                            })}
                            onClick={this.handleCancelClicked}
                        />
                        <PrimaryButton
                            text={this.getTranslation('selectButton')}
                            onClick={this.handleSelectClicked}
                            loading={this.props.loading}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(DatasetSelectModal);
