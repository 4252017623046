// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { QueryStructure, ImmutableList } from 'types';
import type {
    ImmutablePlantTrend,
    ImmutableCircuitTrend,
    ImmutablePlantDataset,
    TrendsPeriodConstant,
} from 'services/Trends/types';
import type { ImmutableDataset } from 'services/Dataset/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

export const FETCH_PLANT_TREND_SUCCESS = 'app/services/Trends/FETCH_PLANT_TREND_SUCCESS';
export const FETCH_PLANT_TREND_FAILURE = 'app/services/Trends/FETCH_PLANT_TREND_FAILURE';
export const IS_FETCHING_PLANT_TREND = 'app/services/Trends/IS_FETCHING_PLANT_TREND';

export const FETCH_CIRCUIT_TREND_SUCCESS = 'app/services/Trends/FETCH_CIRCUIT_TREND_SUCCESS';
export const FETCH_CIRCUIT_TREND_FAILURE = 'app/services/Trends/FETCH_CIRCUIT_TREND_FAILURE';
export const IS_FETCHING_CIRCUIT_TREND = 'app/services/Trends/IS_FETCHING_CIRCUIT_TREND';

export const FETCH_KPI_HISTORY_SUCCESS = 'app/services/Trends/FETCH_KPI_HISTORY_SUCCESS';
export const FETCH_KPI_HISTORY_FAILURE = 'app/services/Trends/FETCH_KPI_HISTORY_FAILURE';
export const IS_FETCHING_KPI_HISTORY = 'app/services/Trends/IS_FETCHING_KPI_HISTORY';

export const FETCH_DATASET_VALUES_SUCCESS = 'app/services/Trends/FETCH_DATASET_VALUES_SUCCESS';
export const FETCH_DATASET_VALUES_FAILURE = 'app/services/Trends/FETCH_DATASET_VALUES_FAILURE';
export const IS_FETCHING_DATASET_VALUES = 'app/services/Trends/IS_FETCHING_DATASET_VALUES';

export const FETCH_PLANT_ARCHIVE_SUCCESS = 'app/services/Plant/FETCH_PLANT_ARCHIVE_SUCCESS';
export const FETCH_PLANT_ARCHIVE_FAILURE = 'app/services/Plant/FETCH_PLANT_ARCHIVE_FAILURE';
export const IS_FETCHING_PLANT_ARCHIVE = 'app/services/Plant/IS_FETCHING_PLANT_ARCHIVE';

export const FETCH_CIRCUIT_ARCHIVE_SUCCESS = 'app/services/Circuit/FETCH_CIRCUIT_ARCHIVE_SUCCESS';
export const FETCH_CIRCUIT_ARCHIVE_FAILURE = 'app/services/Circuit/FETCH_CIRCUIT_ARCHIVE_FAILURE';
export const IS_FETCHING_CIRCUIT_ARCHIVE = 'app/services/Circuit/IS_FETCHING_CIRCUIT_ARCHIVE';

export const CLEAR_MODAL_DATASET_VALUES = 'app/services/Trends/CLEAR_MODAL_DATASET_VALUES';

export const receivedPlantTrendSuccess = (data: ImmutablePlantTrend) => ({
    type: FETCH_PLANT_TREND_SUCCESS,
    payload: { data },
});

export const receivedPlantTrendFailure = (errors: {}) => ({
    type: FETCH_PLANT_TREND_FAILURE,
    payload: { errors },
});

export const setIsFetchingPlantTrendStatus = (isFetchingPlantTrend: boolean = true) => ({
    type: IS_FETCHING_PLANT_TREND,
    payload: { isFetchingPlantTrend },
});

export const receivedCircuitTrendSuccess = (data: ImmutableCircuitTrend) => ({
    type: FETCH_CIRCUIT_TREND_SUCCESS,
    payload: { data },
});

export const receivedCircuitTrendFailure = (errors: {}) => ({
    type: FETCH_CIRCUIT_TREND_FAILURE,
    payload: { errors },
});

export const setIsFetchingCircuitTrendStatus = (isFetchingCircuitTrend: boolean = true) => ({
    type: IS_FETCHING_CIRCUIT_TREND,
    payload: { isFetchingCircuitTrend },
});

export const receivedKPITrendSuccess = (data: {}) => ({
    type: FETCH_KPI_HISTORY_SUCCESS,
    payload: { data },
});

export const receivedKPITrendFailure = (errors: {}) => ({
    type: FETCH_KPI_HISTORY_FAILURE,
    payload: { errors },
});

export const setIsFetchingKPIHistoryStatus = (kpiId: number, period: TrendsPeriodConstant) => ({
    type: IS_FETCHING_KPI_HISTORY,
    payload: { kpiId, period },
});

// Technically these should not be immutable but we have an issue with recursion
type PlantArchiveStructure = {
    datasets: QueryStructure<ImmutablePlantDataset>,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    timezone: string,
};

export const receivedPlantArchiveSuccess = (data: PlantArchiveStructure) => ({
    type: FETCH_PLANT_ARCHIVE_SUCCESS,
    payload: { data },
});

export const receivedPlantArchiveFailure = (errors: {}) => ({
    type: FETCH_PLANT_ARCHIVE_FAILURE,
    payload: { errors },
});

export const setIsFetchingPlantArchiveStatus = (isFetchingPlantArchive: boolean = true) => ({
    type: IS_FETCHING_PLANT_ARCHIVE,
    payload: { isFetchingPlantArchive },
});

// Technically these should not be immutable but we have an issue with recursion
type CircuitArchiveStructure = {
    circuit: ImmutableCircuit,
    datasets: QueryStructure<ImmutableDataset>,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    timezone: string,
};

export const receivedCircuitArchiveSuccess = (data: CircuitArchiveStructure) => ({
    type: FETCH_CIRCUIT_ARCHIVE_SUCCESS,
    payload: { data },
});

export const receivedCircuitArchiveFailure = (errors: {}) => ({
    type: FETCH_CIRCUIT_ARCHIVE_FAILURE,
    payload: { errors },
});

export const setIsFetchingCircuitArchiveStatus = (isFetchingCircuitArchive: boolean = true) => ({
    type: IS_FETCHING_CIRCUIT_ARCHIVE,
    payload: { isFetchingCircuitArchive },
});

export const receivedDatasetValuesSuccess = (data: {}) => ({
    type: FETCH_DATASET_VALUES_SUCCESS,
    payload: { data },
});

export const receivedDatasetValuesFailure = (errors: {}) => ({
    type: FETCH_DATASET_VALUES_FAILURE,
    payload: { errors },
});

export const setIsFetchingDatasetValuesStatus = (isFetchingDatasetValues: boolean = true) => ({
    type: IS_FETCHING_DATASET_VALUES,
    payload: { isFetchingDatasetValues },
});

export const clearModalDatasetValues = () => ({
    type: CLEAR_MODAL_DATASET_VALUES,
});
