// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const ToolTipContainer = styled.div``;

export const ToolTipWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    background-color: #ffffff;

    border: 1px solid ${LegacyTheme.toolTipBorder};
    border-radius: 4px;

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 1;
`;

export const ToolTipHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 9px;

    background-color: ${LegacyTheme.toolTipTriggerBackgroundColor};
    border-bottom: 1px solid ${LegacyTheme.toolTipBorder};

    color: ${LegacyTheme.toolTipColor};
    font-size: 12px;
    letter-spacing: -0.3px;
`;

export const ToolTipBody = styled.div`
    padding: 9px;

    font-size: 15px;
    letter-spacing: -0.4px;
`;

export const ToolTipDate = styled.div``;

const squareWidth = 15;
const squareDiagonal = Math.sqrt(Math.pow(squareWidth, 2) + Math.pow(squareWidth, 2));

export const ToolTipArrow = styled.div`
    position: relative;
    display: block;

    width: ${squareDiagonal}px;
    height: ${squareWidth}px;
    margin: -1px auto 0;

    border-radius: 1px;

    z-index: 2;

    overflow: hidden;

    &:after {
        content: '';
        display: block;

        width: ${squareWidth}px;
        height: ${squareWidth}px;
        margin: -10px auto 0;
        background-color: white;
        border: 1px solid ${LegacyTheme.toolTipBorder};
        transform: rotate(45deg);

        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
    }
`;
