// @flow strict

import React from 'react';

// Page
import DashboardPage from 'views/DashboardPage';
import MinchemDashboardPage from 'views/MinchemDashboardPage';
import SolvExtractDashboardPage from 'views/SolvExtractDashboardPage';

export class HomePage extends React.PureComponent<null, null> {
    render() {
        const locationUrl = window.location.href;

        // if the domain is one of these 2, we pre-assign the dashboard type
        if (locationUrl.includes('minchem.solvay.com')) {
            return <MinchemDashboardPage />;
        } else if (locationUrl.includes('solvextract.solvay.com')) {
            return <SolvExtractDashboardPage />;
        }

        // otherwise we simply return the Dashboard page and the default type will be chosen based on screen size
        return <DashboardPage />;
    }
}

export default HomePage;
