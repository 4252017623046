// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { range } from 'lodash';

// Assets
import BlendStreamImage from 'assets/mimic-diagrams-stream-images/pls-skip-blend.jpg';
import BleedStreamImage from 'assets/mimic-diagrams-stream-images/pls-skip-bleed.jpg';
import BleedBlendStreamImage from 'assets/mimic-diagrams-stream-images/pls-skip-bleed-blend.jpg';
import SkipStreamImage from 'assets/mimic-diagrams-stream-images/aqueous-skip.jpg';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    RadioStyles,
    ImageStyle,
    InputStyles,
    InputSelectWrapper,
} from './styles';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, TertiaryButton, RadioButtonSet, Modal, InputSelect } from 'components/_ReactUI_V1';

// Types
import type { IntlType, ReactSelectObject } from 'types';
import type { PlsOptionConstant } from 'services/Circuit/types';

// Constant
import { MODAL_WIDTH, PLS_STREAM_OPTIONS, STREAM_TYPES } from 'utils/constants';

type OptionType = { label: string, value: PlsOptionConstant };

type Props = {
    intl: IntlType,
    currentLocation: number,
    onConfirm: (plsStreamOption: PlsOptionConstant, currentLocation: number) => void,
    onCancel: () => void,
};

type State = {
    // the keys used by the radio button set
    selectedPlsStreamOption: PlsOptionConstant,
};

/**
 * Shown when the mimic diagram edit button is clicked in between two aqueous stages
 */
class PLSSkipStreamSelectModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedPlsStreamOption: PLS_STREAM_OPTIONS.BLEND,
        };
    }

    /**
     * Convert the item from the mimic diagram to a drop down select object
     */
    itemToReactSelectObject = (item: number): ReactSelectObject => ({
        value: `${item}`,
        label: `${item}`,
    });

    /**
     * Get the PLS stream radio options
     */
    getPlsStreamOptions = (): Array<OptionType> =>
        Object.keys(PLS_STREAM_OPTIONS)
            .filter((streamOption: PlsOptionConstant) => streamOption !== PLS_STREAM_OPTIONS.SKIP) // New feeds cannot be selected in skip streams.
            .filter((streamOption: PlsOptionConstant) => streamOption !== PLS_STREAM_OPTIONS.FEED) // New feeds cannot be selected in skip streams.
            .map((value: PlsOptionConstant) => ({
                value,
                label: this.props.intl.formatMessage({
                    id: `components.Modals.AqueousStreamSelectModal.${value}`,
                }),
            }));

    /**
     * Get the Schema associated with the radio option selected
     */
    getStreamOptionImage = (optionValue: PlsOptionConstant) => {
        switch (optionValue) {
            case PLS_STREAM_OPTIONS.SKIP:
                return SkipStreamImage;
            case PLS_STREAM_OPTIONS.BLEND:
                return BlendStreamImage;
            case PLS_STREAM_OPTIONS.BLEED:
                return BleedStreamImage;
            case PLS_STREAM_OPTIONS.BLEED_BLEND:
                return BleedBlendStreamImage;
            default:
                return null;
        }
    };

    /**
     * When the select button is clicked, call our parent with the options selected
     */
    handleSelectClicked = () => {
        this.props.onConfirm(this.state.selectedPlsStreamOption, this.props.currentLocation);
    };

    /**
     * When the Cascade RF radio buttons is changed
     */
    handlePlsStreamOptionChange = (selectedPlsStreamOption: PlsOptionConstant) =>
        this.setState({ selectedPlsStreamOption });

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.AqueousStreamSelectModal.title',
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <RadioButtonSet
                                value={this.state.selectedPlsStreamOption}
                                options={this.getPlsStreamOptions()}
                                onClick={this.handlePlsStreamOptionChange}
                                styles={RadioStyles}
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.Modals.preview',
                                })}
                            </Label>
                            <img
                                src={this.getStreamOptionImage(this.state.selectedPlsStreamOption)}
                                alt={this.props.intl.formatMessage({
                                    id: `components.Modals.AqueousStreamSelectModal.${
                                        this.state.selectedPlsStreamOption
                                    }`,
                                })}
                                style={ImageStyle}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <TertiaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.selectButton',
                            })}
                            onClick={this.handleSelectClicked}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(PLSSkipStreamSelectModal);
