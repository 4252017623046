// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { Loader, OverflowEnd, OverflowBody, LegacyTheme } from 'components/_ReactUI_V1';

import { MimicCircuit } from 'components/_McCabeThiele';
import type {
    IMimicCircuit,
    IMimicLoadedOrganicTank,
    IMimicWasher,
    IMimicExtractSection,
    IMimicStream,
} from 'components/_McCabeThiele';

// Helpers & Constants

import {
    UNIT_TYPES,
    STREAM_CIRCUITS,
    STAGE_TYPES,
    DIAGRAM_DISPLAY_MODES,
    ADVANCED_STREAMS_SETUP_TYPES,
    DATASET_MODES,
    STREAM_TYPES,
    STREAM_VALUE_TYPES,
} from 'utils/constants';
import { COMPUTE_GRID, ORGANIC_CIRCUIT_TYPE, SETUP_GRID } from './constants';
import {
    WASH_POSITIONS_TO_LOCATION,
    WASH_POSITIONS,
} from 'containers/CircuitSetupContainer/MimicDiagramContainer';
import {
    getStageKey,
    getStageFromKey,
    getStagesForStream,
    getPreviousStage,
    getFirstStageInCascadeForStage,
} from 'utils/helpers';

import {
    getPlsBlendToStage,
    getPlsFeedToStage,
    getPlsBleedFromStage,
    getOrganicBlendToStage,
    getOrganicBypassFeed,
    getOrganicBypassBleed,
    getElectrolyteBleedFromStage,
    hasExtractBypass,
    hasOrganicBlend,
    hasStripBypass,
    streamShouldHideEditButton,
    isStreamRemovable,
    isAdvancedStream,
    isEdgeStream,
    getMaxStreamValuesForOrganic,
    isStreamInterstageBleed,
    isMinChemStreamValue,
    isStreamIntoOrOutOfSkip,
    getPlsSkipToStage,
} from './helpers';

// Selectors
import { selectAllKPISettings } from 'services/KPISetting/selectors';

// Components
import MimicDiagramHeader from 'components/MimicDiagramHeader';

// Styles
import {
    DiagramWrapper,
    DiagramWidget,
    WidgetWrapper,
    SVGLayer,
    MarkerPath,
    LoadingWrapper,
    ColumnSpacer,
    RowSpacer,
} from './styles';
import { streamCircuitColors } from 'styles/colors';

// Diagram Elements
import DiagramStage from 'components/MimicDiagram/DiagramStage';
import Stream from 'components/MimicDiagram/Stream';
import StreamPath from 'components/MimicDiagram/Stream/StreamPath';

// Types
import type { IntlType, LooseNumberType, ImmutableList, ReduxDispatch, UnitsConstant } from 'types';
import type { LegendItem } from 'components/DiagramLegend';
import type {
    LooseStage,
    LooseStream,
    ImmutableCircuit,
    OrganicCircuitConstant,
    AdvancedStreamSetupConstant,
    AdvancedStreamTypeConstant,
    ImmutableStream,
    ImmutableStage,
} from 'services/Circuit/types';
import type {
    LooseStageValue,
    LooseDatasetValue,
    LooseStreamValue,
    DatasetModesConstant,
    AllLooseDatasetValues,
} from 'services/Dataset/types';
// Setup Mode
import type {
    OpenModalFunction,
    ChangeStagePropertiesFunction,
} from 'containers/CircuitSetupContainer/MimicDiagramContainer';
// Computation Mode
import type {
    SetStageValueFunction,
    SetStreamValueFunction,
    HandleDatasetValueChangeFunction,
    OpenIsothermSelectModalFunction,
} from 'containers/CircuitComputationContainer/MimicContainer';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

type Props = {
    intl: IntlType,
    datasetMode?: DatasetModesConstant,
    displayMode: DiagramDisplayModesConstant,
    fullDisplay?: boolean,
    loadingCircuit: boolean,

    // TODO: Can we refactor these to use the immutable circuit?
    stages: Array<LooseStage>,
    streams: Array<LooseStream>,

    // Setup mimic diagram
    onOpenModal?: OpenModalFunction,
    onChangeStageProperties?: ChangeStagePropertiesFunction,
    onRemoveLoTank?: (loTank: LooseStage) => void,

    // Computation mimic diagram
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    setStageValue?: SetStageValueFunction,
    setStreamValue?: SetStreamValueFunction,

    onOpenIsothermSelectModal?: OpenIsothermSelectModalFunction,

    onRemoveAdvancedStreamValueClicked?: () => void,
    // This should only be used in the computation mimic diagram.
    circuit?: ?ImmutableCircuit,
    circuitUnits?: UnitsConstant,
    streamValues?: Array<LooseStreamValue>,
    datasetValues?: Array<LooseDatasetValue>,
    onDatasetValueChange?: HandleDatasetValueChangeFunction,
};

/**
 * Component that holds all design logic for Mimic Diagram in both: setup and compute modes
 */
class MimicDiagram extends React.PureComponent<Props> {
    static defaultProps = {
        circuit: null,
        circuitUnits: UNIT_TYPES.METRIC,
        datasetValues: null,
        fullDisplay: false,
        onDatasetValueChange: () => null,
        onOpenIsothermSelectModal: () => null,
        onOpenModal: () => null,
        onRemoveLoTank: () => null,
        onRemoveAdvancedStreamValueClicked: () => null,
        setStageValue: () => null,
        setStreamValue: () => null,
        streamValues: null,
    };

    mimicCircuit: IMimicCircuit;

    constructor(props: Props) {
        super(props);
        this.mimicCircuit = new MimicCircuit({
            stages: this.props.stages,
            streams: this.props.streams,
        });
        this.mimicCircuit.setMode(this.props.displayMode);
    }

    getTanks = (): Array<LooseStage> =>
        this.props.stages.filter(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.ORGANIC_TANK
        );

    getWashers = (): Array<LooseStage> =>
        this.props.stages.filter((stage: LooseStage) => stage.stageType === STAGE_TYPES.WASHER);

    /**
     * Get the extractor stages
     */
    getExtractors = (): Array<LooseStage> =>
        this.props.stages.filter((stage: LooseStage) => stage.stageType === STAGE_TYPES.EXTRACT);

    /**
     * Get the stripper stages
     */
    getStrippers = (): Array<LooseStage> =>
        this.props.stages.filter((stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP);

    /**
     * What type of circuit is this?
     * @returns whether the circuit is extract stages only, strip stages only, or both
     */
    getOrganicCircuitType = (): OrganicCircuitConstant => {
        const extractors = this.getExtractors();
        const strippers = this.getStrippers();
        if (extractors.length !== 0) {
            if (strippers.length !== 0) {
                return ORGANIC_CIRCUIT_TYPE.BOTH;
            }
            return ORGANIC_CIRCUIT_TYPE.EXTRACT_ONLY;
        }
        return ORGANIC_CIRCUIT_TYPE.STRIP_ONLY;
    };

    isFromOrToLOTank = (stream: LooseStream): boolean => {
        const relatedStages = getStagesForStream(stream, this.props.stages);
        return Boolean(
            (relatedStages.to && relatedStages.to.stageType === STAGE_TYPES.ORGANIC_TANK) ||
            (relatedStages.from &&
                relatedStages.from.stageType === STAGE_TYPES.ORGANIC_TANK)
        );
    };

    isToNearestExtractorStreamFromLOTank = (stream: LooseStream): boolean => {
        const relatedStages = getStagesForStream(stream, this.props.stages);
        const isToExtract = relatedStages.to && relatedStages.to.stageType === STAGE_TYPES.EXTRACT;
        const isFromTank =
            relatedStages.from && relatedStages.from.stageType === STAGE_TYPES.ORGANIC_TANK;
        if (!isFromTank || !isToExtract) {
            return false;
        }
        const tank: IMimicLoadedOrganicTank = this.mimicCircuit.getStageByDescription(
            relatedStages.from.stageType,
            relatedStages.from.location
        );
        if (!tank) {
            throw new Error('Was not able to find the loaded organic tank for stream.');
        }
        const firstExtractor = tank.getFirstConnectedToExtractor();
        return relatedStages.to.location === firstExtractor.location;
    };

    isFromNearestExtractorStreamToLOTank = (stream: LooseStream): boolean => {
        const relatedStages = getStagesForStream(stream, this.props.stages);
        const isFromExtract =
            relatedStages.from && relatedStages.from.stageType === STAGE_TYPES.EXTRACT;
        const isToTank =
            relatedStages.to && relatedStages.to.stageType === STAGE_TYPES.ORGANIC_TANK;
        if (!isFromExtract || !isToTank) {
            return false;
        }
        const tank: IMimicLoadedOrganicTank = this.mimicCircuit.getStageByDescription(
            relatedStages.to.stageType,
            relatedStages.to.location
        );
        if (!tank) {
            throw new Error('Was not able to find the loaded organic tank for stream.');
        }
        const firstExtractor = tank.getFirstConnectedFromStage();
        return relatedStages.from.location === firstExtractor.location;
    };

    getTankGridPosition = (tank: IMimicLoadedOrganicTank, stageLocations): Object => {
        const fromStage = tank.getFirstConnectedFromStage();
        const fromStageLocations = stageLocations.get(fromStage.getCode());
        const grid =
            this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;
        let columnOffset = 0;
        let rowOffset = 0;
        const referenceStage = fromStageLocations;
        switch (fromStage.stageType) {
            case STAGE_TYPES.EXTRACT: {
                if (fromStage.location === 1) {
                    columnOffset = -grid.TANK.COLUMN_SPAN;
                } else {
                    columnOffset = grid.TANK.COLUMN_OFFSET_FROM_EXTRACT;
                }
                rowOffset = grid.STAGE.ROW_SPAN + grid.TANK.ROW_OFFSET_FROM_EXTRACT;
                break;
            }
            case STAGE_TYPES.ORGANIC_TANK: {
                throw new Error(
                    'Loaded organic tanks positioning should be either from a washer or from an extractor.'
                );
            }
            case STAGE_TYPES.WASHER: {
                rowOffset = grid.WASHER.ROW_SPAN;
                columnOffset = 0;
                break;
            }
            case STAGE_TYPES.STRIP: {
                throw new Error('Loaded organic tanks cannot come from a strip stage');
            }
            default:
                throw new Error(
                    `Unknown stage type provided to getTankGridPosition. Type: ${fromStage.stageType
                    }`
                );
        }
        return {
            startingColumn: referenceStage.startingColumn + columnOffset,
            startingRow: referenceStage.startingRow + rowOffset,
        };
    };

    getWasherGridPosition = (washer: IMimicWasher, stageLocations): Object => {
        // if ets washer, then get the first extractor, otherwise its an ste washer, get the last stripper
        const fromStage =
            washer.location === 1
                ? washer.getFirstConnectedFromStage()
                : washer.getLastConnectedFromStage();
        const fromStageLocations = stageLocations.get(fromStage.getCode());
        const toStage =
            washer.location === 1
                ? washer.getFirstConnectedToStage()
                : washer.getLastConnectedToStage();
        const toStageLocations = stageLocations.get(toStage.getCode());
        let columnOffset = 0;
        let rowOffset = 0;
        let referenceStage;
        const grid =
            this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;
        switch (fromStage.stageType) {
            case STAGE_TYPES.EXTRACT: {
                referenceStage = fromStageLocations;
                columnOffset = -grid.WASHER.COLUMN_SPAN;
                rowOffset = grid.STAGE.ROW_SPAN + grid.WASHER.ROW_OFFSET_FROM_EXTRACT;
                break;
            }
            case STAGE_TYPES.ORGANIC_TANK: {
                throw new Error(
                    'Washers should be before loaded organic tanks, and therefore cannot come from an LO tank.'
                );
            }
            case STAGE_TYPES.STRIP: {
                referenceStage = toStageLocations;
                columnOffset = grid.STAGE.COLUMN_SPAN;
                rowOffset = grid.STAGE.ROW_SPAN + grid.WASHER.ROW_OFFSET_FROM_EXTRACT;
                break;
            }
            default: {
                throw new Error('Unknown from stage type for washer.');
            }
        }
        return {
            startingColumn: referenceStage.startingColumn + columnOffset,
            startingRow: referenceStage.startingRow + rowOffset,
        };
    };

    getTankLocations = (stageLocations) => {
        this.mimicCircuit.getTanks().forEach((tank: IMimicLoadedOrganicTank) => {
            const position = this.getTankGridPosition(tank, stageLocations);
            stageLocations.set(tank.getCode(), position);
        });
    };

    getWasherLocations = (stageLocations) => {
        this.mimicCircuit.getWashers().forEach((washer: IMimicWasher) => {
            const position = this.getWasherGridPosition(washer, stageLocations);
            stageLocations.set(washer.getCode(), position);
        });
    };

    calculateETSWasherLocation = (stageLocations) => {
        const etsWasher = this.mimicCircuit
            .getWashers()
            .find((washer: IMimicWasher) => washer.location === 1);
        if (!etsWasher) {
            return;
        }
        const position = this.getWasherGridPosition(etsWasher, stageLocations);
        stageLocations.set(etsWasher.getCode(), position);
    };

    calculateSTEWasherLocation = (stageLocations) => {
        const steWasher = this.mimicCircuit
            .getWashers()
            .find((washer: IMimicWasher) => washer.location === 2);
        if (!steWasher) {
            return;
        }
        const position = this.getWasherGridPosition(steWasher, stageLocations);
        stageLocations.set(steWasher.getCode(), position);
    };

    getStageStartingColumn = (stage: LooseStage, previousStageLocation) => {
        const grid =
            this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

        if (stage.location === 1) {
            return grid.STAGE.STARTING_COLUMN;
        }

        // Space between stages is equal in Setup mode
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            return (
                grid.STAGE.STARTING_COLUMN +
                (stage.location - 1) *
                (grid.CONTINUE_STREAM_VALUES.COLUMN_SPAN + grid.STAGE.COLUMN_SPAN)
            );
        }
        if (!previousStageLocation) {
            return grid.STAGE.STARTING_COLUMN;
        }
        // check for advanced streams
        const advancedStreamTypeBeforeStage = this.getAdvancedStreamTypeBeforeStage(stage);
        return (
            previousStageLocation.startingColumn +
            COMPUTE_GRID.STAGE.COLUMN_SPAN +
            this.getAdvancedStreamNumberOfColumns(advancedStreamTypeBeforeStage)
        );
    };

    getStageStartingRow = (stage: LooseStage, previousStageLocation, stageLocations) => {
        if (previousStageLocation) {
            // if we have already calculated the row for a stage in the section, then all stages get the same starting row
            return previousStageLocation.startingRow;
        }
        const grid =
            this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            const extractors = this.getExtractors();

            if (stage.stageType === STAGE_TYPES.EXTRACT) {
                const extractSection: IMimicExtractSection = this.mimicCircuit.getSectionForStageType(
                    STAGE_TYPES.EXTRACT
                );
                return extractSection.getStartingRow(grid);
            } else if (extractors.length === 0) {
                return grid.STAGE.STARTING_ROW;
            } else if (stage.stageType === STAGE_TYPES.STRIP) {
                const tanks = this.getTanks();
                const washers = this.getWashers();
                let startingRow = grid.STAGE.STARTING_ROW;

                const bypassedStrip = hasStripBypass(this.props.streams, this.props.stages) ? 1 : 0;
                let organicRowOffsets = bypassedStrip;

                if (tanks.length > 0) {
                    const tankLocation = stageLocations.get(getStageKey(tanks[0]));
                    startingRow = tankLocation.startingRow + grid.TANK.ROW_SPAN;
                } else if (washers.length > 0) {
                    const washerLocation = stageLocations.get(getStageKey(washers[0]));
                    startingRow = washerLocation.startingRow + grid.WASHER.ROW_SPAN;
                } else {
                    const bypassedExtract =
                        hasExtractBypass(this.props.streams, this.props.stages) ||
                            hasOrganicBlend(this.props.streams)
                            ? 1
                            : 0;
                    organicRowOffsets += bypassedExtract;
                    const extractLocation = stageLocations.get(getStageKey(extractors[0]));
                    startingRow = extractLocation.startingRow + grid.STAGE.ROW_SPAN;
                    if (bypassedExtract && bypassedStrip) {
                        organicRowOffsets += 2;
                    }
                }

                return startingRow + organicRowOffsets + grid.STAGE.ORGANIC_STREAM_VALUES_ROW_SPAN;
            } else {
                throw new Error(
                    'Get stage starting row called with stage not of type Extract or Strip'
                );
            }
        }

        // Yikes this should be moved else where?
        const streamValues = this.props.streamValues.filter(isMinChemStreamValue);

        let extractStart = 0;

        // $FlowIgnore
        const extractSection: IMimicExtractSection = this.mimicCircuit.getSectionForStageType(
            STAGE_TYPES.EXTRACT
        );
        if (extractSection) {
            extractStart = extractSection.getStartingRow(grid, streamValues);
        }

        if (stage.stageType === STAGE_TYPES.EXTRACT) {
            return extractStart;
        }
        // We are a stripper:
        const tanks = this.getTanks();
        const washers = this.getWashers();
        let startingRow = grid.STAGE.STARTING_ROW;

        const bypassedStrip = hasStripBypass(this.props.streams, this.props.stages) ? 1 : 0;
        let organicRowOffsets = bypassedStrip;

        if (tanks.length > 0) {
            const tankLocation = stageLocations.get(getStageKey(tanks[0]));
            startingRow = tankLocation.startingRow + grid.TANK.ROW_SPAN;
            organicRowOffsets += grid.TANK.ROW_OFFSET_FROM_STRIP;
        } else if (washers.length > 0) {
            const washerLocation = stageLocations.get(getStageKey(washers[0]));
            startingRow = washerLocation.startingRow + grid.WASHER.ROW_SPAN;
            organicRowOffsets += grid.WASHER.ROW_OFFSET_FROM_STRIP;
        } else {
            startingRow = extractStart + grid.STAGE.ROW_SPAN;
            organicRowOffsets += getMaxStreamValuesForOrganic(
                this.props.stages,
                this.props.streams,
                streamValues
            );
        }

        return startingRow + organicRowOffsets;
    };

    getValuePrecision = (valueEntity: AllLooseDatasetValues, valueKey: string) => {
        if (!this.props.circuit || !this.props.kpiSettings || this.props.kpiSettings.isEmpty()) {
            return null;
        }

        let matchingKPISetting;

        // Find direct matching kpi setting
        matchingKPISetting = this.props.kpiSettings.find(
            (kpiSetting: ImmutableKPISetting) =>
                kpiSetting.get('kpiType') === valueEntity.valueType &&
                kpiSetting.get(valueKey) &&
                kpiSetting.get(valueKey) === valueEntity[valueKey]
        );

        // If no kpi setting was found, and if the entity is a stream, use backup;
        // find the matching kpi setting via cascades
        if (
            !matchingKPISetting &&
            valueEntity.valueType === STREAM_VALUE_TYPES.FLOWRATE &&
            valueKey === 'streamId'
        ) {
            const streams = this.props.circuit.get('streams');
            const stream = streams.find(
                (s: ImmutableStream) =>
                    s.get('id') === valueEntity[valueKey] &&
                    s.get('streamCircuit') === STREAM_CIRCUITS.AQUEOUS &&
                    s.get('streamType') === STREAM_TYPES.BLEED
            );
            if (stream) {
                const firstStage = getFirstStageInCascadeForStage(
                    this.props.circuit,
                    this.props.circuit
                        .get('stages')
                        .find(
                            (stage: ImmutableStage) => stage.get('id') === stream.get('fromStageId')
                        )
                );
                const streamForFirstStage = streams.find(
                    (s: ImmutableStream) =>
                        s.get('toStageId') === firstStage.get('id') &&
                        s.get('streamType') === STREAM_TYPES.FEED
                );

                matchingKPISetting = this.props.kpiSettings.find(
                    (kpiSetting: ImmutableKPISetting) =>
                        kpiSetting.get('kpiType') === valueEntity.valueType &&
                        kpiSetting.get(valueKey) &&
                        kpiSetting.get(valueKey) === streamForFirstStage.get('id')
                );
            }
        }

        if (!matchingKPISetting) {
            return null;
        }

        return matchingKPISetting.get('precision');
    };

    getDatasetValues = (datasetValues: Array<LooseDatasetValue>) =>
        datasetValues &&
        datasetValues.map((datasetValue: LooseDatasetValue) => ({
            ...datasetValue,
            precision: this.getValuePrecision(datasetValue, 'datasetId'),
        }));

    getStreamValues = (stream: LooseStream): Array<LooseStreamValue> => {
        return (
            this.props.streamValues &&
            this.props.streamValues
                .filter((streamValue: LooseStreamValue) => streamValue.streamId === stream.id)
                .filter(isMinChemStreamValue)
                .map((streamValue: LooseStreamValue) => ({
                    ...streamValue,
                    precision: this.getValuePrecision(streamValue, 'streamId'),
                }))
        );
    };

    getStageValues = (stage: LooseStage): Array<LooseStageValue> =>
        stage &&
        stage.values &&
        stage.values.map((stageValue: LooseStageValue) => ({
            ...stageValue,
            precision: this.getValuePrecision(stageValue, 'stageId'),
        }));

    /**
     * Return furthest to the right stage's starting column.
     * Used in stream path calculations.
     */
    getLastStageStartingColumn = (stageLocations): number => {
        const extractors = this.getExtractors();
        const strippers = this.getStrippers();
        const lastExtractStage =
            extractors.length > 0 &&
            extractors
                .sort(
                    (currentStage: LooseStage, nextStage: LooseStage) =>
                        currentStage.location - nextStage.location
                )
                .pop();
        const lastExtractStartingColumn = lastExtractStage
            ? stageLocations.get(getStageKey(lastExtractStage)).startingColumn
            : 0;
        const lastStripStage =
            strippers.length > 0 &&
            strippers
                .sort(
                    (currentStage: LooseStage, nextStage: LooseStage) =>
                        currentStage.location - nextStage.location
                )
                .pop();
        const lastStripStartingColumn = lastStripStage
            ? stageLocations.get(getStageKey(lastStripStage)).startingColumn
            : 0;
        return Math.max(lastExtractStartingColumn, lastStripStartingColumn);
    };

    getLastStageStartingRow = (stageLocations): number => {
        const rows = [];
        stageLocations.forEach((location) => rows.push(location.startingRow));
        return Math.max(...rows);
    };

    /**
     * Get advanced stream setup type that comes before given stage.
     * It is used to calculate stage position on the diagram in Design/Analysis mode
     * thus return in order of advanced stream that will have the most space reserved for additional stream values or bypass SVG paths
     */
    getAdvancedStreamTypeBeforeStage = (stageData: LooseStage): AdvancedStreamTypeConstant => {
        if (stageData.stageType === STAGE_TYPES.EXTRACT) {
            // Check if there is a PLS feed into this stage
            const thereIsPLSFeed = getPlsFeedToStage(
                stageData,
                this.props.stages,
                this.props.streams
            );

            // Check if there is a PLS blend into this stage
            const thereIsPLSBlend = getPlsBlendToStage(
                stageData,
                this.props.stages,
                this.props.streams
            );

            // Check if there is a bypass before this stage
            const thereIsBypassBleed = getOrganicBypassBleed(
                stageData,
                this.props.stages,
                this.props.streams
            );

            // Get previous stage
            const previousStage = getPreviousStage(stageData, this.props.stages);

            const currentMimicStage = this.mimicCircuit.getStageByDescription(
                stageData.stageType,
                stageData.location
            );
            const previousMimicStage = this.mimicCircuit.getStageByDescription(
                previousStage.stageType,
                previousStage.location
            );
            const currentBypassBleed =
                currentMimicStage &&
                currentMimicStage.getOutgoingOrganicStreamOfType(STREAM_TYPES.BYPASS_BLEED);
            const currentBypassFeed =
                previousMimicStage &&
                previousMimicStage.getIncomingOrganicStreamOfType(STREAM_TYPES.BYPASS_FEED);
            if (currentBypassBleed && currentBypassFeed) {
                const fromNearestTank = this.isFromNearestExtractorStreamToLOTank(
                    currentBypassBleed
                );
                const toNearestTank = this.isToNearestExtractorStreamFromLOTank(currentBypassFeed);
                if (toNearestTank && fromNearestTank) {
                    return ADVANCED_STREAMS_SETUP_TYPES.TANK_TO_AND_FROM_NEW_TANK;
                }
            }

            // Check if there is an Organic blend into previous stage
            const thereIsOrganicBlend = getOrganicBlendToStage(
                previousStage,
                this.props.stages,
                this.props.streams
            );

            // Check if there is a bleed
            const thereIsPlsBleed = getPlsBleedFromStage(
                previousStage,
                this.props.stages,
                this.props.streams
            );

            // check if there is skip
            const thereIsSkip = getPlsSkipToStage(stageData, this.props.stages, this.props.streams);

            // if both, blend and bleed
            if (thereIsOrganicBlend) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEND;
            } else if (thereIsBypassBleed) {
                return ADVANCED_STREAMS_SETUP_TYPES.BYPASS_BLEED;
            } else if (thereIsSkip) {
                // All skip stream cases can be handled with the basic configuration
                return ADVANCED_STREAMS_SETUP_TYPES.NONE;
            } else if (thereIsPLSBlend && thereIsPlsBleed) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEED_BLEND;
            } else if (thereIsPLSBlend) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEND;
            } else if (thereIsPLSFeed) {
                return ADVANCED_STREAMS_SETUP_TYPES.FEED;
            } else if (thereIsPlsBleed) {
                return ADVANCED_STREAMS_SETUP_TYPES.BLEED;
            }
        } else if (stageData.stageType === STAGE_TYPES.STRIP) {
            // Check if there is a bypass feed before because this will affect stage position
            const thereIsOrganicBypass = getOrganicBypassFeed(
                stageData,
                this.props.stages,
                this.props.streams
            );
            if (thereIsOrganicBypass) {
                return ADVANCED_STREAMS_SETUP_TYPES.BYPASS_FEED;
            }

            // Check if there is an electrolyte bleed from that stage
            const thereIsElectrolyteBleed = getElectrolyteBleedFromStage(
                stageData,
                this.props.stages,
                this.props.streams
            );
            if (thereIsElectrolyteBleed) {
                return ADVANCED_STREAMS_SETUP_TYPES.FEED;
            }
        }

        return ADVANCED_STREAMS_SETUP_TYPES.NONE;
    };

    /**
     * Calculate extra number of columns for Design/Analysis mode(with stream values) according to advanced stream if there is one
     */
    getAdvancedStreamNumberOfColumns = (advancedType: ?AdvancedStreamSetupConstant): number => {
        // Default is the width of stage and default space of one stream value column span to have space for in-out streams
        const defaultNumberOfColumns = COMPUTE_GRID.CONTINUE_STREAM_VALUES.COLUMN_SPAN;
        switch (advancedType) {
            case ADVANCED_STREAMS_SETUP_TYPES.TANK_TO_AND_FROM_NEW_TANK:
                return COMPUTE_GRID.TANK.COLUMN_SPAN_TO_AND_FROM_NEW_TANK;
            case ADVANCED_STREAMS_SETUP_TYPES.BLEND:
            case ADVANCED_STREAMS_SETUP_TYPES.BLEED_BLEND:
                // We already have space for one stream value by default: will be used for pre-composition
                // We need space of one stream value for in-out streams and one more for post-composition
                return COMPUTE_GRID.CONTINUE_BLEND_STREAM.COLUMN_SPAN;
            case ADVANCED_STREAMS_SETUP_TYPES.BYPASS:
            case ADVANCED_STREAMS_SETUP_TYPES.BYPASS_FEED:
            case ADVANCED_STREAMS_SETUP_TYPES.BYPASS_BLEED:
                return defaultNumberOfColumns + COMPUTE_GRID.CONTINUE_STREAM_VALUES.COLUMN_SPAN / 2;
            case ADVANCED_STREAMS_SETUP_TYPES.FEED:
            case ADVANCED_STREAMS_SETUP_TYPES.BLEED:
            default:
                return defaultNumberOfColumns;
        }
    };

    getCenteredStageLocations = () => {
        const stageLocations = new Map();
        let previousStagePositions = null;
        let previousStageType = null;
        this.getExtractors().forEach((stage: LooseStage) => {
            // did we go from extract to strip?
            if (previousStagePositions && stage.stageType !== previousStageType) {
                previousStagePositions = null;
            }
            const stageLocation = {
                startingColumn: this.getStageStartingColumn(stage, previousStagePositions),
                startingRow: this.getStageStartingRow(
                    stage,
                    previousStagePositions,
                    stageLocations
                ),
            };

            stageLocations.set(getStageKey(stage), stageLocation);
            previousStageType = stage.stageType;
            previousStagePositions = stageLocation;
        });
        this.calculateETSWasherLocation(stageLocations);
        this.calculateSTEWasherLocation(stageLocations);
        this.getTankLocations(stageLocations);
        this.getStrippers().forEach((stage: LooseStage) => {
            // did we go from extract to strip?
            if (previousStagePositions && stage.stageType !== previousStageType) {
                previousStagePositions = null;
            }
            const stageLocation = {
                startingColumn: this.getStageStartingColumn(stage, previousStagePositions),
                startingRow: this.getStageStartingRow(
                    stage,
                    previousStagePositions,
                    stageLocations
                ),
            };
            stageLocations.set(getStageKey(stage), stageLocation);
            previousStageType = stage.stageType;
            previousStagePositions = stageLocation;
        });

        // at this point the stages are not centered, so lets center them horizontally
        const extractors = this.getExtractors();
        const strippers = this.getStrippers();
        const lastExtractStage =
            extractors.length > 0 &&
            extractors
                .sort(
                    (currentStage: LooseStage, nextStage: LooseStage) =>
                        currentStage.location - nextStage.location
                )
                .pop();
        const lastExtractStartingColumn = lastExtractStage
            ? stageLocations.get(getStageKey(lastExtractStage)).startingColumn
            : 0;
        const lastStripStage =
            strippers.length > 0 &&
            strippers
                .sort(
                    (currentStage: LooseStage, nextStage: LooseStage) =>
                        currentStage.location - nextStage.location
                )
                .pop();
        const lastStripStartingColumn = lastStripStage
            ? stageLocations.get(getStageKey(lastStripStage)).startingColumn
            : 0;

        const delta = Math.floor(Math.abs(lastExtractStartingColumn - lastStripStartingColumn) / 2);
        if (delta !== 0) {
            // we must center a stage if at least 1 section has more stages than another.
            const stageTypeToCenter =
                lastExtractStartingColumn > lastStripStartingColumn
                    ? STAGE_TYPES.STRIP
                    : STAGE_TYPES.EXTRACT;

            // the different between the two, divided by 2 gives us the LEFT offset.
            stageLocations.forEach((stagePositions, stageKey: LooseNumberType) => {
                const stage = getStageFromKey(stageKey, this.props.stages);
                if (stage.stageType === stageTypeToCenter) {
                    stageLocations.set(stageKey, {
                        ...stagePositions,
                        startingColumn: stagePositions.startingColumn + delta,
                    });
                }
                // Reposition washers relative to the centered location
                if (stage.stageType === STAGE_TYPES.WASHER) {
                    if (stage.location === WASH_POSITIONS_TO_LOCATION[WASH_POSITIONS.STE]) {
                        const grid =
                            this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP
                                ? SETUP_GRID
                                : COMPUTE_GRID;
                        stageLocations.set(stageKey, {
                            ...stagePositions,
                            startingColumn:
                                Math.max(lastExtractStartingColumn, lastStripStartingColumn) +
                                grid.STAGE.COLUMN_SPAN,
                        });
                    }
                }
            });
        }
        return stageLocations;
    };

    /**
     * Returns extra information that the stream component requires
     */
    getStreamExtras = (stream: LooseStream, stageLocations) => {
        let fromStageColumn;
        let fromStageRow;
        let fromStageType;
        let fromStageLocation;
        let toStageColumn;
        let toStageRow;
        let toStageType;
        let toStageLocation;

        const relatedStages = getStagesForStream(stream, this.props.stages);
        if (relatedStages.from) {
            const locations = stageLocations.get(getStageKey(relatedStages.from));
            if (!locations) {
                throw new Error('Unknown starting location for stage.');
            }
            fromStageColumn = locations.startingColumn;
            fromStageRow = locations.startingRow;
            fromStageType = relatedStages.from.stageType;
            fromStageLocation = relatedStages.from.location;
        }
        if (relatedStages.to) {
            const locations = stageLocations.get(getStageKey(relatedStages.to));
            if (!locations) {
                throw new Error('Unknown starting location for stage.');
            }
            toStageColumn = locations.startingColumn;
            toStageRow = locations.startingRow;
            toStageType = relatedStages.to.stageType;
            toStageLocation = relatedStages.to.location;
        }

        return {
            fromStageColumn,
            fromStageRow,
            fromStageType,
            fromStageLocation,
            toStageColumn,
            toStageRow,
            toStageType,
            toStageLocation,
        };
    };

    /**
     * If the circuit is still being loaded, display a spinner
     * Gets the stream circuit legend items if there are any streams of that stream circuit type.
     */
    getLegendItems = (): Array<LegendItem> => {
        if (this.props.stages.length < 0) return [];

        const legendItems = [];
        // Aqueous legend
        const aqueousStreams = this.props.streams.find(
            (stream: LooseStream) => stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS
        );
        if (aqueousStreams) {
            legendItems.push({
                color: streamCircuitColors[STREAM_CIRCUITS.AQUEOUS].main,
                label: this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.Legend.aqueous',
                }),
            });
        }

        // Organic Legend
        const organicStreams = this.props.streams.find(
            (stream: LooseStream) => stream.streamCircuit === STREAM_CIRCUITS.ORGANIC
        );
        if (organicStreams) {
            legendItems.push({
                color: streamCircuitColors[STREAM_CIRCUITS.ORGANIC].main,
                label: this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.Legend.organic',
                }),
            });
        }

        // Electrolyte legend
        const electrolyteStreams = this.props.streams.find(
            (stream: LooseStream) => stream.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE
        );
        if (electrolyteStreams) {
            legendItems.push({
                color: streamCircuitColors[STREAM_CIRCUITS.ELECTROLYTE].main,
                label: this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.Legend.electrolyte',
                }),
            });
        }

        if (this.props.datasetMode === DATASET_MODES.ANALYSIS_MODE) {
            // If we are in analysis mode, the circuit has some optional fields,
            // and we want to display which are mandatory with a star.
            // As such we need to a dd a legend item with it.
            legendItems.push({
                color: LegacyTheme.defaultColor,
                hideDot: true,
                label: this.props.intl.formatMessage({
                    id: 'components.MimicDiagram.Legend.mandatoryField',
                }),
            });
        }

        return legendItems;
    };

    /**
     * If the circuit is still being loaded, display a spinner
     */
    renderLoading = (): React.Node => (
        <LoadingWrapper>
            <Loader loading={this.props.loadingCircuit} title={null} />
        </LoadingWrapper>
    );

    /**
     * Renders the mimic diagram header.
     * In setup mode, this shows the mimic diagram title and the legend.
     * In computation mode, this shows the reagent/oxime concentrations and the legend.
     */
    renderHeader = () => (
        <MimicDiagramHeader
            circuitUnits={this.props.circuitUnits}
            displayMode={this.props.displayMode}
            legendItems={this.getLegendItems()}
            circuit={this.props.circuit}
            datasetValues={this.getDatasetValues(this.props.datasetValues)}
            onDatasetValueChange={this.props.onDatasetValueChange}
        />
    );

    /**
     * Get the spacers of the mimic diagram. This is required in setup mode to properly calculate the width of the mimic diagram with streams.
     * In computation mode this is done for us by the stream values on edge feed/bleeds as well as the connector stream values.
     */
    renderSpacers = (
        grid: GridType,
        lastStageStartingColumn: number,
        lastStageStartingRow: number
    ) => {
        // only setup mode has spacer grid elements.
        if (this.props.displayMode !== DIAGRAM_DISPLAY_MODES.SETUP) {
            return null;
        }
        return [
            <ColumnSpacer key="column-spacer" grid={grid} startsAt={lastStageStartingColumn} />, // Column spacer at the end of the mimic diagram.
            <RowSpacer key="row-spacer" grid={grid} startsAt={lastStageStartingRow} />,
        ];
    };

    /**
     * Render the actual mimic diagram portion of the mimic diagram.
     */
    renderDiagram = (): React.ReactElement => {
        const stageLocations = this.getCenteredStageLocations();
        const organicCircuitType = this.getOrganicCircuitType();

        const containsExtractBypass = hasExtractBypass(this.props.streams, this.props.stages);
        const containsOrganicBlend = hasOrganicBlend(this.props.streams);
        const containsStripBypass = hasStripBypass(this.props.streams, this.props.stages);

        const isSetupMode = this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP;

        const grid = isSetupMode ? SETUP_GRID : COMPUTE_GRID;

        const lastStageStartingColumn = this.getLastStageStartingColumn(stageLocations);
        const lastStageStartingRow = this.getLastStageStartingRow(stageLocations);

        // store the extra stream data in a map.
        // where the map KEY is the stream key. and the value is all of the extra props for the given stream.
        // This is required to do less calculations since these props are used in 2 different places: <Stream /> and <StreamPath />
        const streamExtraData = new Map(
            this.mimicCircuit.streams.map((stream: IMimicStream) => {
                const streamJS = stream.toJSON();
                return [
                    stream.getCode(),
                    {
                        showEditLabel: !streamShouldHideEditButton(
                            streamJS,
                            this.props.stages,
                            this.props.streams
                        ),
                        isRemovableStream:
                            this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP &&
                            isStreamRemovable(streamJS, this.props.stages, this.props.streams),
                        isSkipInOutStream: isStreamIntoOrOutOfSkip(
                            streamJS,
                            this.props.stages,
                            this.props.streams
                        ),
                        isFromNearestExtractorStreamToLOTank: this.isFromNearestExtractorStreamToLOTank(
                            streamJS
                        ),
                        isToNearestExtractorStreamFromLOTank: this.isToNearestExtractorStreamFromLOTank(
                            streamJS
                        ),
                        isFromOrToLOTank: this.isFromOrToLOTank(streamJS),

                        isEdgeStream: isEdgeStream(streamJS, this.props.stages, this.props.streams),
                        isAdvancedStream:
                            this.props.displayMode !== DIAGRAM_DISPLAY_MODES.SETUP &&
                            isAdvancedStream(streamJS, this.props.stages, this.props.streams),
                        isStreamInterstageBleed: isStreamInterstageBleed(
                            streamJS,
                            this.props.streams
                        ),

                        ...this.getStreamExtras(streamJS, stageLocations),
                        values: this.getStreamValues(streamJS),
                    },
                ];
            })
        );

        return (
            <WidgetWrapper>
                <DiagramWidget grid={grid}>
                    {this.props.stages.map((stage: LooseStage) => (
                        <DiagramStage
                            key={getStageKey(stage)}
                            mimicCircuit={this.mimicCircuit}
                            displayMode={this.props.displayMode}
                            datasetMode={this.props.datasetMode}
                            isSetupMode={isSetupMode}
                            circuitUnits={this.props.circuitUnits}
                            stageData={{
                                ...stage,
                                values: this.getStageValues(stage),
                            }}
                            onChangeStageProperties={this.props.onChangeStageProperties}
                            onOpenIsothermSelectModal={this.props.onOpenIsothermSelectModal}
                            onRemoveLoTank={this.props.onRemoveLoTank}
                            setStageValue={this.props.setStageValue}
                            {...stageLocations.get(getStageKey(stage))}
                        />
                    ))}
                    {this.renderSpacers(grid, lastStageStartingColumn, lastStageStartingRow)}
                    {this.mimicCircuit.streams.map((stream: IMimicStream) => (
                        // disabling flow errors because it complains about props,
                        // however we are passing all the props in the spreaded "streamExtraData"
                        <Stream
                            key={stream.getCode()}
                            streamData={stream.toJSON()}
                            mimicStream={stream}
                            displayMode={this.props.displayMode}
                            datasetMode={this.props.datasetMode}
                            organicCircuitType={organicCircuitType}
                            circuitUnits={this.props.circuitUnits}
                            lastStageColumn={lastStageStartingColumn}
                            // stream extras:
                            {...streamExtraData.get(stream.getCode())}
                            // TODO: We should only pass these for connector streams.
                            hasExtractBypass={containsExtractBypass}
                            hasOrganicBlend={containsOrganicBlend}
                            hasStripBypass={containsStripBypass}
                            // handlers
                            setStreamValue={this.props.setStreamValue}
                            onOpenModal={this.props.onOpenModal}
                            onRemoveAdvancedStreamValueClicked={
                                this.props.onRemoveAdvancedStreamValueClicked
                            }
                        />
                    ))}
                </DiagramWidget>
                <SVGLayer>
                    <marker
                        id={STREAM_CIRCUITS.AQUEOUS}
                        markerWidth="25"
                        markerHeight="30"
                        refX="7"
                        refY="3"
                        orient="auto"
                        markerUnits="strokeWidth"
                        viewBox="0 0 20 10"
                    >
                        <MarkerPath d="M0,0 L0,6 L6,3 z" streamCircuit={STREAM_CIRCUITS.AQUEOUS} />
                    </marker>
                    <marker
                        id={STREAM_CIRCUITS.ORGANIC}
                        markerWidth="25"
                        markerHeight="30"
                        refX="7"
                        refY="3"
                        orient="auto"
                        markerUnits="strokeWidth"
                        viewBox="0 0 20 10"
                    >
                        <MarkerPath d="M0,0 L0,6 L6,3 z" streamCircuit={STREAM_CIRCUITS.ORGANIC} />
                    </marker>
                    <marker
                        id={STREAM_CIRCUITS.ELECTROLYTE}
                        markerWidth="25"
                        markerHeight="30"
                        refX="7"
                        refY="3"
                        orient="auto"
                        markerUnits="strokeWidth"
                        viewBox="0 0 20 10"
                    >
                        <MarkerPath
                            d="M0,0 L0,6 L6,3 z"
                            streamCircuit={STREAM_CIRCUITS.ELECTROLYTE}
                        />
                    </marker>
                    {this.mimicCircuit.streams.map((stream: IMimicStream) => (
                        // disabling flow errors because it complains about props,
                        // however we are passing all the props in the spreaded "streamExtraData"
                        <StreamPath
                            key={`stream-path-${stream.getCode()}`}
                            mimicStream={stream}
                            streamData={stream.toJSON()}
                            displayMode={this.props.displayMode}
                            organicCircuitType={organicCircuitType}
                            lastStageColumn={lastStageStartingColumn}
                            {...streamExtraData.get(stream.getCode())}
                        />
                    ))}
                </SVGLayer>
            </WidgetWrapper>
        );
    };

    render() {
        this.mimicCircuit = new MimicCircuit({
            stages: this.props.stages,
            streams: this.props.streams,
        });
        this.mimicCircuit.setMode(this.props.displayMode);

        const mimicDiagram = this.props.loadingCircuit
            ? this.renderLoading()
            : this.renderDiagram();

        // Render diagram outside of overflow container in order to take a proper image to copy to clipboard
        if (this.props.fullDisplay) {
            return (
                <React.Fragment>
                    {this.renderHeader()}
                    {mimicDiagram}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <OverflowEnd>{this.renderHeader()}</OverflowEnd>
                <OverflowBody>
                    <DiagramWrapper
                        isLoading={
                            this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP &&
                            this.props.loadingCircuit
                        }
                        topPadding={this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP}
                        largerPadding={this.props.displayMode !== DIAGRAM_DISPLAY_MODES.SETUP}
                    >
                        {mimicDiagram}
                    </DiagramWrapper>
                </OverflowBody>
            </React.Fragment>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        kpiSettings: selectAllKPISettings(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MimicDiagram));
