// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Authentication Helpers
import { SidebarTab } from 'components/_ReactUI_V1';

import { isSolvayUser } from 'utils/authentication';

// Components
import { CIRCUIT_COMPUTE_SIDEBAR_ITEMS } from 'utils/constants';

// Styles
import { Wrapper } from './styles';

// Types
import type { IntlType } from 'types';
import type { SidebarSectionType } from 'containers/CircuitComputationContainer';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    activeSection: SidebarSectionType,
    recoveryRaffinatePresetSelected: boolean,
    sectionBody: ?React.Node,
    handleSectionActivate: (activeSidebarSection: SidebarSectionType) => void,
    handleSetTabNode: (sidebarNode: ?HTMLDivElement) => void,
    user: ImmutableUser,
};

type SidebarTabNodesType = {
    [SidebarSectionType]: ?HTMLDivElement,
};

type State = {
    sidebarTabNodes: SidebarTabNodesType,
};

class CircuitComputationSidebar extends React.PureComponent<Props, State> {
    state = {
        sidebarTabNodes: {
            [CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MIMIC]: null,
            [CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MCCABE]: null,
            [CIRCUIT_COMPUTE_SIDEBAR_ITEMS.TWO_D]: null,
            [CIRCUIT_COMPUTE_SIDEBAR_ITEMS.THREE_D]: null,
        },
    };

    getSidebarTabNode = () => {
        if (this.state.sidebarTabNodes.hasOwnProperty(this.props.activeSection)) {
            return this.state.sidebarTabNodes[this.props.activeSection];
        }
        return null;
    };

    /**
     * When a sidebar tab section title is clicked, check to make sure its different than
     * our current section. If it's the same, do not toggle the section activation.
     */
    onSidebarSectionClickHandler = (newSidebarSection: SidebarSectionType) => () => {
        if (this.props.activeSection !== newSidebarSection) {
            this.props.handleSectionActivate(newSidebarSection);
        }
    };

    /**
     * When creating the sidebar tab, check to see if we should add the handler or not.
     * We should not even have the click handler if the section title is the same as the active
     * section title.
     */
    createSectionTitleClickHandler = (newSidebarSection: SidebarSectionType) => {
        if (this.props.activeSection === newSidebarSection) {
            return null;
        }
        return this.onSidebarSectionClickHandler(newSidebarSection);
    };

    setSidebarTabNode = (tab: SidebarSectionType) => (ref: ?HTMLDivElement) => {
        if (tab === this.props.activeSection) {
            this.props.handleSetTabNode(ref);
        }
    };

    render() {
        const solvayUser = isSolvayUser(this.props.user);
        return (
            <Wrapper>
                <SidebarTab
                    handleOnHeaderClick={this.createSectionTitleClickHandler(
                        CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MIMIC
                    )}
                    active={this.props.activeSection === CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MIMIC}
                    title={this.props.intl.formatMessage({
                        id: 'components.CircuitComputationSidebar.MimicDiagram.title',
                    })}
                    body={this.props.sectionBody}
                />
                <SidebarTab
                    handleOnHeaderClick={this.createSectionTitleClickHandler(
                        CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MCCABE
                    )}
                    active={this.props.activeSection === CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MCCABE}
                    title={this.props.intl.formatMessage({
                        id: 'components.CircuitComputationSidebar.McCabeThiele.title',
                    })}
                    body={
                        <div ref={this.setSidebarTabNode(CIRCUIT_COMPUTE_SIDEBAR_ITEMS.MCCABE)} />
                    }
                />
                {solvayUser && this.props.recoveryRaffinatePresetSelected && (
                    <SidebarTab
                        handleOnHeaderClick={this.createSectionTitleClickHandler(
                            CIRCUIT_COMPUTE_SIDEBAR_ITEMS.TWO_D
                        )}
                        active={this.props.activeSection === CIRCUIT_COMPUTE_SIDEBAR_ITEMS.TWO_D}
                        title={this.props.intl.formatMessage({
                            id: 'components.CircuitComputationSidebar.2DSensitivity.title',
                        })}
                        body={this.props.sectionBody}
                    />
                )}
                {solvayUser && this.props.recoveryRaffinatePresetSelected && (
                    <SidebarTab
                        handleOnHeaderClick={this.createSectionTitleClickHandler(
                            CIRCUIT_COMPUTE_SIDEBAR_ITEMS.THREE_D
                        )}
                        active={this.props.activeSection === CIRCUIT_COMPUTE_SIDEBAR_ITEMS.THREE_D}
                        title={this.props.intl.formatMessage({
                            id: 'components.CircuitComputationSidebar.3DSensitivity.title',
                        })}
                        body={this.props.sectionBody}
                    />
                )}
            </Wrapper>
        );
    }
}

export default injectIntl(CircuitComputationSidebar);
