// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import { PrimaryButton, SecondaryButton, Modal } from 'components/_ReactUI_V1';

import { Wrapper, Header, Body, Footer, Field, Label, InputNameField } from './styles';
import { Title } from 'styles/common';

import type { IntlType, InputEvent, ErrorType } from 'types';

import { MODAL_WIDTH } from 'utils/constants';
import { validateIsothermName } from 'services/Isotherm/helpers';
import { ISOTHERM_NAME_MAX_LENGTH } from 'services/Isotherm/types';

type Props = {
    intl: IntlType,
    errors: ErrorType,
    loading: boolean,
    updateIsothermName: ?string,

    onConfirm: (isothermName: string) => void,
    onCancel: () => void,
};

type State = {
    isothermName: string,
};

/**
 * Shown when the save Button is clicked
 */
class IsothermSaveModal extends React.PureComponent<Props, State> {
    static defaultProps = {
        updateIsothermName: '',
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            isothermName: this.props.updateIsothermName || '',
        };
    }

    /**
     * Get either the save button is enabled or not
     */
    isSaveButtonEnabled = () => this.state.isothermName;

    /**
     * When the save button is clicked, call our parent with the options selected
     */
    handleSaveButtonClicked = () => this.props.onConfirm(this.state.isothermName);

    /**
     * When the cancel button is clicked
     */
    handleCancelClicked = () => this.props.onCancel();

    /**
     * When the isotherm name is changed in the input field
     */
    handleIsothermNameChange = (event: InputEvent) => {
        // $FlowIgnore - event.target.value will be a string
        const newName = validateIsothermName(event.target.value);
        this.setState({ isothermName: newName });
    };

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.IsothermSaveModal.title',
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.Modals.IsothermSaveModal.inputLabel',
                                })}
                            </Label>
                            <InputNameField
                                name="isothermName"
                                placeholder={this.props.intl.formatMessage({
                                    id:
                                        'components.Modals.IsothermSaveModal.isothermNamePlaceholder',
                                })}
                                maxLength={ISOTHERM_NAME_MAX_LENGTH}
                                value={this.state.isothermName}
                                onChange={this.handleIsothermNameChange}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.handleCancelClicked}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.saveButton',
                            })}
                            onClick={this.handleSaveButtonClicked}
                            loading={this.props.loading}
                            disabled={!this.isSaveButtonEnabled()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(IsothermSaveModal);
