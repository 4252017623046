// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import {
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryGroup,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
    VictoryContainer,
    VictoryVoronoiContainer,
} from 'victory';
import { merge } from 'lodash';

// Components
import { Table, Loader } from 'components/_ReactUI_V1';

import DiagramLegend, { type LegendItem } from 'components/DiagramLegend';

// Styles
import {
    Wrapper,
    GraphWrapper,
    GraphCopyToClipboardTarget,
    ChartWrapper,
    LegendWrapper,
    DataTableContainer,
    LoadingWrapper,
} from './styles';
import { isothermColors } from 'styles/colors';
import { GraphStyles, SmallGraphStyles } from './GraphStyles';

// Constants
import { ISOTHERM_POLYNOMIAL_TYPE } from 'utils/constants';

// Types
import type { IntlType } from 'types';
import type {
    ImmutableIsotherm,
    ImmutableIsothermDataPoint,
    IsothermBaseConstant,
    ImmutableIsothermCoefficient,
} from 'services/Isotherm/types';
import { round } from 'utils/helpers';

const GRAPH_WIDTH = 1024;
const GRAPH_HEIGHT = 720;
const GRAPH_WIDTH_SMALL = 480;
const GRAPH_HEIGHT_SMALL = 386;
const SCATTER_DOTS_COUNT = 8;
const LINE_DATA_POINTS_COUNT = 50;

type Props = {
    intl: IntlType,

    loading: boolean,
    isotherms: Array<ImmutableIsotherm>,
    diagramType: IsothermBaseConstant,
    small?: boolean, // for the isotherm select modal
    setGraphRef: (ref: React.RefObject<HTMLDivElement>) => void,
};

type State = {
    graphStyles: any,
};

/**
 * Displays an isotherm diagram.
 */
class IsothermDiagram extends React.Component<Props, State> {
    static defaultProps = {
        small: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            graphStyles: this.props.small ? merge(GraphStyles, SmallGraphStyles) : GraphStyles,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.small !== this.props.small) {
            this.setState({
                graphStyles: this.props.small ? merge(GraphStyles, SmallGraphStyles) : GraphStyles,
            });
        }
    }

    /**
     * Returns a list of dataPoints with x and y keys and not aqueous and organic keys for Victory
     */
    getDataPoints = (dataPoints: Array<ImmutableIsothermDataPoint>) =>
        dataPoints.map((point: ImmutableIsothermDataPoint) => {
            const ac = parseFloat(point.get('aqueousConcentration'));
            const oc = parseFloat(point.get('organicConcentration'));
            if (this.props.diagramType === 'EXTRACT') {
                return {
                    x: ac,
                    y: oc,
                    isothermPointType: point.get('type'),
                };
            } else {
                return {
                    x: oc,
                    y: ac,
                    isothermPointType: point.get('type'),
                };
            }
        });

    renderLabel = (datum: Object) => `(${round(datum.x, 4)}, ${round(datum.y, 4)})`;

    /**
     * Renders the isotherm line.
     */
    renderIsotherms = () =>
        this.props.isotherms.map((isotherm: ImmutableIsotherm, index: number) => (
            <VictoryGroup key={isotherm.get('id')}>
                <VictoryLine
                    style={this.state.graphStyles.baseIsothermLine(
                        isothermColors[isotherm.get('isothermType')],
                        index
                    )}
                    interpolation="natural"
                    samples={LINE_DATA_POINTS_COUNT}
                    data={this.getDataPoints(isotherm.get('computedDataPoints'))}
                    name="computedDataPoints"
                />
                <VictoryScatter
                    name="scatterDots"
                    size={this.state.graphStyles.scatterSize}
                    style={this.state.graphStyles.scatterColor(
                        isothermColors[isotherm.get('isothermType')],
                        index
                    )}
                    samples={SCATTER_DOTS_COUNT}
                    data={this.getDataPoints(isotherm.get('referenceDataPoints'))}
                />
            </VictoryGroup>
        ));

    /**
     * Renders the Y axis.
     */
    renderYAxis = () => (
        <VictoryAxis
            name="yAxis"
            label={this.props.intl.formatMessage({
                id: `components.IsothermDiagram.yAxis.${this.props.diagramType}`,
            })}
            orientation="left"
            style={this.state.graphStyles.axisY}
            tickLabelComponent={
                <VictoryLabel
                    dy={this.state.graphStyles.axisYTickLabelTranslate.dy}
                    dx={this.state.graphStyles.axisYTickLabelTranslate.dx}
                    textAnchor="start"
                />
            }
            dependentAxis
        />
    );

    /**
     * Renders the X axis
     */
    renderXAxis = () => (
        <VictoryAxis
            name="xAxis"
            label={this.props.intl.formatMessage({
                id: `components.IsothermDiagram.xAxis.${this.props.diagramType}`,
            })}
            style={this.state.graphStyles.axisX}
        />
    );

    /**
     * Render the isotherm data points reference table
     */
    renderDataPointsTable = (isotherm: ImmutableIsotherm) => (
        <DataTableContainer>
            <Table
                header={[
                    {
                        label: this.props.intl.formatMessage({
                            id: 'components.IsothermDiagram.legend.aqueous',
                        }),
                        id: 'aqueousConcentration',
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'components.IsothermDiagram.legend.organic',
                        }),
                        id: 'organicConcentration',
                    },
                ]}
                rows={isotherm
                    .get('referenceDataPoints')
                    .map((point: ImmutableIsothermDataPoint) => ({
                        id: `${isotherm.get('isothermType')}-${point.get('id')}-${point.get(
                            'aqueousConcentration'
                        )}-${point.get('organicConcentration')}`,
                        aqueousConcentration: round(point.get('aqueousConcentration'), 6),
                        organicConcentration: round(point.get('organicConcentration'), 6),
                    }))
                    .toJS()}
                subtleStyling
            />
        </DataTableContainer>
    );

    /**
     * Renders the coefficients of the isotherm.
     */
    renderCoefficientsTable = (isotherm: ImmutableIsotherm) => (
        <DataTableContainer>
            <Table
                header={[
                    {
                        label: this.props.intl.formatMessage({
                            id: 'components.IsothermDiagram.coefficientsTable.index',
                        }),
                        id: 'coefficientIndex',
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'components.IsothermDiagram.coefficientsTable.coefficient',
                        }),
                        id: 'coefficientValue',
                    },
                ]}
                rows={isotherm
                    .get('coefficients')
                    .map((point: ImmutableIsothermCoefficient) => ({
                        id: point.get('id'),
                        coefficientIndex: point.get('coefficientIndex'),
                        coefficientValue: round(point.get('coefficient'), 8),
                    }))
                    .toJS()}
                subtleStyling
            />
        </DataTableContainer>
    );

    /**
     * Render Isotherm Legend;
     * if only one isotherm is selected & it is a predicted calculation type, render predicted reference datapoints
     * else, return colored label
     */
    renderIsothermLegend = () => {
        if (this.props.small) {
            return null;
        }

        if (this.props.isotherms.length === 1) {
            const isotherm = this.props.isotherms[0];
            if (isotherm.get('calculationType') === 'PREDICTED') {
                return this.renderDataPointsTable(isotherm);
            } else if (
                [
                    ISOTHERM_POLYNOMIAL_TYPE.MINCHEM,
                    ISOTHERM_POLYNOMIAL_TYPE.RATIONAL,
                    ISOTHERM_POLYNOMIAL_TYPE.SIMPLE,
                ].indexOf(isotherm.get('polynomialType')) !== -1
            ) {
                return this.renderCoefficientsTable(isotherm);
            }
            return null;
        }

        return (
            <LegendWrapper>
                <DiagramLegend
                    direction="COLUMN"
                    legendItems={this.props.isotherms.map(
                        (isotherm: ImmutableIsotherm, index: number): LegendItem => ({
                            color: isothermColors[isotherm.get('isothermType')].primary[index],
                            label:
                                isotherm.get('name') ||
                                this.props.intl.formatMessage({
                                    id: 'components.IsothermDiagram.legend.newIsothermName',
                                }),
                        })
                    )}
                />
            </LegendWrapper>
        );
    };

    render() {
        if (this.props.loading) {
            return (
                <LoadingWrapper>
                    <Loader loading={this.props.loading} title={null} />
                </LoadingWrapper>
            );
        }
        return (
            <Wrapper smallPadding={this.props.small}>
                <ChartWrapper>
                    <GraphWrapper>
                        <GraphCopyToClipboardTarget ref={this.props.setGraphRef}>
                            <VictoryChart
                                height={this.props.small ? GRAPH_HEIGHT_SMALL : GRAPH_HEIGHT}
                                width={this.props.small ? GRAPH_WIDTH_SMALL : GRAPH_WIDTH}
                                style={this.state.graphStyles.mainGraph}
                                padding={this.state.graphStyles.mainGraphPadding}
                                domainPadding={{ y: [0, 5], x: [0, 5] }}
                                containerComponent={
                                    this.props.small ? (
                                        <VictoryContainer />
                                    ) : (
                                        <VictoryVoronoiContainer
                                            labels={this.renderLabel}
                                            labelComponent={<VictoryTooltip />}
                                            voronoiBlacklist={[
                                                'xAxis',
                                                'yAxis',
                                                'computedDataPoints',
                                            ]}
                                            voronoiDimension="x"
                                            voronoiPadding={-3}
                                            radius={25}
                                        />
                                    )
                                }
                            >
                                {/* Axis Components */}
                                {this.renderYAxis()}
                                {this.renderXAxis()}

                                {/* Line Components */}
                                {this.renderIsotherms()}
                            </VictoryChart>
                        </GraphCopyToClipboardTarget>
                    </GraphWrapper>
                </ChartWrapper>
                {this.renderIsothermLegend()}
            </Wrapper>
        );
    }
}

export default injectIntl(IsothermDiagram);
