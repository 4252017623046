// @flow

import React from 'react';

// Types
import type { InputEventType } from '../../types';

// Styles
import { StyledToggle, StyledToggleLabel, HiddenInput } from './styles';

type Props = {
    checked: boolean,
    disabled?: boolean,
    label?: string,
    onClickHandler?: (InputEventType) => void,
    name?: string,
    styles?: Object,
};

export default class Toggle extends React.PureComponent<Props> {
    static defaultProps = {
        disabled: false,
        label: '',
        name: 'toggle',
        onClickHandler: null,
        styles: null,
    };

    handleChange = (event: InputEventType) =>
        this.props.onClickHandler &&
        this.props.onClickHandler(event.target.value !== 'true', event);

    render() {
        return (
            <StyledToggleLabel
                key={this.props.name}
                disabled={this.props.disabled}
                style={this.props.styles}
            >
                <StyledToggle checked={this.props.checked} disabled={this.props.disabled} />
                <span>{this.props.label}</span>
                <HiddenInput
                    key={this.props.name}
                    checked={this.props.checked}
                    id={this.props.name}
                    name={this.props.name}
                    type="checkbox"
                    onChange={!this.props.disabled ? this.handleChange : null}
                    disabled={this.props.disabled}
                    value={this.props.checked}
                />
            </StyledToggleLabel>
        );
    }
}
