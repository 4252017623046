// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Styles
import { Common, SecondaryButton } from 'components/_ReactUI_V1';

import { Title, Subtitle, FormContainer } from 'styles/common';

// Components
import MinorKPISectionFormBlock from './MinorKPISectionFormBlock';

// Constants
import { CIRCUIT_ELEVATION_STEPS, MINOR_KPI_SECTIONS } from 'utils/constants';

// Types
import type {
    IntlType,
    ImmutableList,
    LooseKeyArrayType,
    LooseInputValueTypes,
    UnitsConstant,
} from 'types';
import type {
    CircuitElevationStepConstant,
    ImmutableCircuitSettings,
} from 'services/Circuit/types';
import type { ImmutableKPISetting, AllKpiTypes, KPISectionType } from 'services/KPISetting/types';

import type {
    MinorKPISettingSubState,
    MinorKPISettingsSubStateKeys,
} from 'containers/CircuitSetupContainer/SolvExtractElevationContainer';

type Props = {
    intl: IntlType,
    units: UnitsConstant,
    isCircuitElevating: boolean,

    circuitSettings: ImmutableCircuitSettings,

    // The below lists accessed via bracket notion and therefore not being picked up by eslint
    minorKPISettings: MinorKPISettingSubState,

    // List of all the remaining optional KPIs.
    remainingOptionalKPISettings?: ImmutableList<ImmutableKPISetting>,

    onAddKPI?: ?(
        subStateKey: MinorKPISettingsSubStateKeys,
        kpiSetting: ImmutableKPISetting
    ) => void,
    onInputChange?: ?(
        subStateKey: MinorKPISettingsSubStateKeys
    ) => (keys: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onRemoveKPI?: ?(
        subStateKey: MinorKPISettingsSubStateKeys
    ) => (kpiSetting: ImmutableKPISetting) => () => void,
    onReorderKPI?: ?(
        subStateKey: MinorKPISettingsSubStateKeys
    ) => (originalKPISetting: ImmutableKPISetting) => (newOrder: number) => void,
    onChangeKPIType?: ?(
        subStateKey: MinorKPISettingsSubStateKeys
    ) => (kpiSetting: ImmutableKPISetting, newType: AllKpiTypes) => void,
    onChangeKPIContext?: ?(
        subStateKey: MinorKPISettingsSubStateKeys
    ) => (kpiSetting: ImmutableKPISetting, newContextId: number) => void,

    onReturnToStep?: ?(step: CircuitElevationStepConstant) => () => void,

    onChangeCircuitSettings?: ?(key: LooseKeyArrayType, value: LooseInputValueTypes) => void,
};

class CircuitElevationMinorKpiStep extends React.PureComponent<Props, null> {
    static defaultProps = {
        onAddKPI: null,
        onChangeKPIContext: null,
        onChangeKPIType: null,
        onInputChange: null,
        onRemoveKPI: null,
        onReorderKPI: null,
        onReturnToStep: null,
        remainingOptionalKPISettings: null,
    };

    STEP_KEY = CIRCUIT_ELEVATION_STEPS.MINOR_KPIS;

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.CircuitElevation.${this.STEP_KEY}.${id}`,
            },
            data
        );

    /**
     * TODO: MS-369 - In theory the implementation of the works, however it must be tested once optional kpis are required
     * Provide possible KPIs to be added to the table
     */
    getListOfPossibleKPITypes = (
        sectionKey: KPISectionType
    ): ImmutableList<ImmutableKPISetting> => {
        if (!this.props.remainingOptionalKPISettings || this.props.onReturnToStep) {
            return fromJS([]);
        }
        const possibleKPIs = this.props.remainingOptionalKPISettings.filter(
            (kpiSetting: ImmutableKPISetting) => kpiSetting.get('section') === sectionKey
        );

        if (!possibleKPIs.size) {
            return fromJS([]);
        }

        return possibleKPIs;
    };

    /**
     * Converts toggle handler event to props.onChangeCircuitSettings
     */
    handleChangeCircuitSettings = (key: LooseKeyArrayType) => (checked: boolean) =>
        this.props.onChangeCircuitSettings
            ? this.props.onChangeCircuitSettings(key, checked)
            : null;

    /**
     * TODO: MS-369 - In theory the implementation of the works, however it must be tested once optional kpis are required
     * Handles the addition of an empty kpi setting to the proper section
     */
    handleAddKPISetting = (
        sectionKey: KPISectionType,
        minorKpiSettingsPropKey: MinorKPISettingsSubStateKeys
    ) => () => {
        if (!this.props.onAddKPI) {
            return;
        }

        const possibleKPIs = this.props.remainingOptionalKPISettings.filter(
            (kpiSetting: ImmutableKPISetting) => kpiSetting.get('section') === sectionKey
        );

        if (!possibleKPIs.size) {
            return;
        }

        // Get the last setting's order value & add 1, else use 1 as default order value for first setting
        const orderValue = this.props.minorKPISettings[minorKpiSettingsPropKey].size
            ? this.props.minorKPISettings[minorKpiSettingsPropKey].last().get('order') + 1
            : 1;

        const hasMultipleTypes =
            possibleKPIs.groupBy((kpi: ImmutableKPISetting) => kpi.get('kpiType')).size > 1;

        // Get first possible kpi type as default
        let newKpi = possibleKPIs.first();
        newKpi = newKpi
            .set('order', orderValue)
            .set(
                'disableKpiTypeInput',
                !hasMultipleTypes || newKpi.get('isRequired') || newKpi.get('isUndeletable')
            ); // let user chose the KPI type they want if there's more than 1 kpi.

        const hasMultipleContexts =
            possibleKPIs
                .filter((kpi: ImmutableKPISetting) => kpi.get('kpiType') === newKpi.get('kpiType'))
                .groupBy((kpi: ImmutableKPISetting) => kpi.get('context')).size > 1; // if there is more than 1 context available, let the user chose the context
        newKpi = newKpi.set(
            'disableKpiContextInput',
            !hasMultipleContexts || newKpi.get('isRequired') || newKpi.get('isUndeletable')
        ); // let user chose the KPI context they want.

        if (this.props.onAddKPI) {
            this.props.onAddKPI(minorKpiSettingsPropKey, newKpi);
        }
    };

    renderHeader = (displayAsReadOnly: boolean) => (
        <Common.Row alignItems="center">
            <Common.Column>
                {displayAsReadOnly ? (
                    <Subtitle>{this.getTranslation('title')}</Subtitle>
                ) : (
                    <Title>{this.getTranslation('title')}</Title>
                )}
            </Common.Column>
            {displayAsReadOnly && (
                <Common.Column alignItems="flex-end">
                    <SecondaryButton
                        text={this.getTranslation('edit')}
                        onClick={
                            this.props.onReturnToStep && this.props.onReturnToStep(this.STEP_KEY)
                        }
                    />
                </Common.Column>
            )}
        </Common.Row>
    );

    /**
     * Render each minor KPI section as its own formBlock
     * So we can limit the amount of elements that need to be re-rendered on each value change
     */
    renderMinorKPISectionFormBlocks = (isReadOnly: boolean) => {
        const addAKPIText = this.getTranslation('addAKPI');
        const noKPIText = this.getTranslation('noKPIs');

        return Object.keys(MINOR_KPI_SECTIONS).map((sectionKey: KPISectionType) => {
            const minorKpiSettingsPropKey: MinorKPISettingsSubStateKeys =
                MINOR_KPI_SECTIONS[sectionKey];

            const kpis = this.props.minorKPISettings[minorKpiSettingsPropKey];
            if (!kpis) {
                throw new Error(
                    `Expected to find list of kpis with prop key of ${minorKpiSettingsPropKey}, something went wrong.`
                );
            }

            return (
                <MinorKPISectionFormBlock
                    key={minorKpiSettingsPropKey}
                    units={this.props.units}
                    addAKPIText={addAKPIText}
                    displayAsReadOnly={isReadOnly}
                    kpis={kpis}
                    noKPIText={noKPIText}
                    onAddKPI={this.handleAddKPISetting(sectionKey, minorKpiSettingsPropKey)}
                    onInputChange={
                        this.props.onInputChange &&
                        this.props.onInputChange(minorKpiSettingsPropKey)
                    }
                    onRemoveKPI={
                        this.props.onRemoveKPI && this.props.onRemoveKPI(minorKpiSettingsPropKey)
                    }
                    onReorderKPI={
                        this.props.onReorderKPI && this.props.onReorderKPI(minorKpiSettingsPropKey)
                    }
                    onChangeKPIType={
                        this.props.onChangeKPIType &&
                        this.props.onChangeKPIType(minorKpiSettingsPropKey)
                    }
                    onChangeKPIContext={
                        this.props.onChangeKPIContext &&
                        this.props.onChangeKPIContext(minorKpiSettingsPropKey)
                    }
                    possibleKPITypes={this.getListOfPossibleKPITypes(sectionKey)}
                    sectionTitle={this.props.intl.formatMessage({
                        id: `constants.MINOR_KPI_SECTIONS.${sectionKey}`,
                    })}
                    isUpdating={!this.props.isCircuitElevating}
                    sectionKey={sectionKey}
                    hideStageEfficienciesForPmText={this.getTranslation(
                        'hideStageEfficienciesForPm'
                    )}
                    circuitSettings={this.props.circuitSettings}
                    onChangeCircuitSettings={this.handleChangeCircuitSettings}
                />
            );
        });
    };

    render() {
        const isReadOnly = Boolean(this.props.onReturnToStep);

        return (
            <FormContainer>
                {this.renderHeader(isReadOnly)}
                <br />
                {this.renderMinorKPISectionFormBlocks(isReadOnly)}
            </FormContainer>
        );
    }
}

export default injectIntl(CircuitElevationMinorKpiStep);
