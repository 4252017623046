// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';

// Assets
import checkboxChecked from './assets/CheckBox/checkbox-checked-default.png';
import checkboxCheckedHover from './assets/CheckBox/checkbox-checked-hover.png';
import checkboxUnchecked from './assets/CheckBox/checkbox-unchecked-default.png';
import checkboxUncheckedHover from './assets/CheckBox/checkbox-unchecked-hover.png';

export const CheckboxLabel = styled.label`
    color: ${({ disabled }: Object) =>
        disabled ? legacyTheme.checkboxLabelDisabledColor : legacyTheme.checkboxLabelColor};

    font-size: 12px;

    cursor: ${({ disabled }: Object) => (!disabled ? 'pointer' : 'default')};

    > span {
        vertical-align: sub;
        &:first-child {
            margin-right: 10px;
        }
    }

    user-select: none;
`;

export const Checkbox = styled.span`
    height: 14px;
    width: 14px;
    background-size: cover;
    display: inline-block;

    cursor: ${({ disabled }: Object) => (!disabled ? 'pointer' : 'default')};

    ${CheckboxLabel}:hover & {
        ${({ disabled, checked }: Object) =>
            !disabled && checked
                ? `
            background-image: url(${checkboxCheckedHover});
        `
                : ''};
        ${({ disabled, checked }: Object) =>
            !disabled && !checked
                ? `
            background-image: url(${checkboxUncheckedHover});
        `
                : ''};
    }

    ${({ checked }: Object) =>
        checked
            ? `
        background-image: url(${checkboxChecked});
    `
            : ''};
    ${({ checked }: Object) =>
        !checked
            ? `
        background-image: url(${checkboxUnchecked});
    `
            : ''};
    ${({ disabled }: Object) =>
        disabled
            ? `
        opacity: .5
    `
            : ''};
`;

export const HiddenInput = styled.input`
    display: none;
`;
