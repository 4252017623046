// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';
// import ReactGA from 'react-ga';

import type { LocationType, HistoryType } from 'types';
import { PageViews } from '@piwikpro/react-piwik-pro';

type Props = {
    location: LocationType,
    history: HistoryType,
};

class Analytics extends React.Component<Props> {
    componentDidUpdate(prevProps: Props) {
        if (prevProps.location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (this.props.history.action === 'PUSH') {
            // ReactGA.pageview(window.location.pathname + window.location.search);
            if (process?.env?.NODE_ENV === 'production') {
                PageViews.trackPageView(window.location.pathname + window.location.search);
            }
        }
    }

    render() {
        return null;
    }
}

export default withRouter(Analytics);
