// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';
import { StyledInputBase } from '../input-field';

export const Input = styled.input`
    ${StyledInputBase};

    ${({ withButton }: Object) =>
        withButton &&
        `
        margin-left: 12px;
    `}
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const FileName = styled.span``;

export const Label = styled.label`
    ${({ fullWidth }: Object) =>
        fullWidth
            ? `
        display: flex;
        justify-content: space-between;
    `
            : `
        display: inline-block;
    `}

    ${({ repeatable, thumbnail }: Object) =>
        repeatable &&
        !thumbnail &&
        `
        margin-bottom: 12px;
    `}

    color: ${legacyTheme.defaultColor};
    font-size: 12px;

    cursor: ${({ disabled }: Object) => (disabled ? 'default' : 'pointer')};

    ${FileName} {
        display: flex;
        flex: 1;

        margin-right: 12px;
    }

    > svg {
        display: block;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;

    &:last-child {
        margin-bottom: 16px;
    }
`;

export const Thumbnail = styled.img`
    width: 120px;
    height: auto; // If want squares it's best to create a div with a fixed width and height with a background image.
    padding-right: 12px;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    min-height: 60px;
    padding-right: 12px;
`;

export const ErrorMessage = styled.div`
    display: flex;
    align-items: center;

    margin-left: 16px;
`;
