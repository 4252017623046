// @flow strict

export const RECOMMENDATION_TYPES = {
    // mainly for complex recommendation items (impact calcs)
    INCREASE_KPI: 'INCREASE_KPI',
    DECREASE_KPI: 'DECREASE_KPI',
    INCREASE_DILUENT: 'INCREASE_DILUENT',
    INCREASE_REAGENT: 'INCREASE_REAGENT',
    DECREASE_LEAN_CU: 'DECREASE_LEAN_CU',
    INCREASE_LEAN_ACID: 'INCREASE_LEAN_ACID',
    DECREASE_ORGANIC_FLOW: 'DECREASE_ORGANIC_FLOW',
    INCREASE_ORGANIC_FLOW: 'INCREASE_ORGANIC_FLOW',
    INCREASE_LEAN_FLOW: 'INCREASE_LEAN_FLOW',
    INCREASE_PLS_FLOW: 'INCREASE_PLS_FLOW',
    INCREASE_BLEND_PLS_FLOW: 'INCREASE_BLEND_PLS_FLOW',

    // as custom messages, with a potential of becoming complex
    RICH_MAX_NO_SOLUTION: 'RICH_MAX_NO_SOLUTION',
    RICH_MIN_NO_SOLUTION: 'RICH_MIN_NO_SOLUTION',
    WARNING_RICH_CU_OUTSIDE_TOLERANCE: 'WARNING_RICH_CU_OUTSIDE_TOLERANCE',
    INCREASE_LEAN_FLOW_LOWER_RICH: 'INCREASE_LEAN_FLOW_LOWER_RICH',
    DECREASE_LEAN_FLOW_RAISE_RICH: 'DECREASE_LEAN_FLOW_RAISE_RICH',
    LEAN_FLOW_MAY_INCREASE: 'LEAN_FLOW_MAY_INCREASE',
    LEAN_FLOW_MAY_DECREASE: 'LEAN_FLOW_MAY_DECREASE',
    MAINTAIN_PLS_FLOW: 'MAINTAIN_PLS_FLOW',
    DECREASE_PLS_FLOW: 'DECREASE_PLS_FLOW',
    SHIFT_PLS_FLOW: 'SHIFT_PLS_FLOW',
    SHIFT_PLS_FLOW_SET_TO: 'SHIFT_PLS_FLOW_SET_TO',

    // as message only
    VERIFY_MEASUREMENT_ACCURACY: 'VERIFY_MEASUREMENT_ACCURACY',
    REVIEW_MIXER_SPEEDS_AND_AUX: 'REVIEW_MIXER_SPEEDS_AND_AUX',
    VERIFY_AO_ENTRAINMENT: 'VERIFY_AO_ENTRAINMENT',
    REVIEW_MIXER_SPEEDS_LOW_RECOVERY: 'REVIEW_MIXER_SPEEDS_LOW_RECOVERY',
};

export const RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION = [
    RECOMMENDATION_TYPES.INCREASE_KPI,
    RECOMMENDATION_TYPES.DECREASE_KPI,
    RECOMMENDATION_TYPES.INCREASE_DILUENT,
    RECOMMENDATION_TYPES.INCREASE_REAGENT,
    RECOMMENDATION_TYPES.DECREASE_ORGANIC_FLOW,
    RECOMMENDATION_TYPES.INCREASE_ORGANIC_FLOW,

    RECOMMENDATION_TYPES.DECREASE_LEAN_CU,
    RECOMMENDATION_TYPES.INCREASE_LEAN_ACID,
    RECOMMENDATION_TYPES.INCREASE_LEAN_FLOW,
    RECOMMENDATION_TYPES.INCREASE_PLS_FLOW,
    RECOMMENDATION_TYPES.INCREASE_BLEND_PLS_FLOW,
];
export const RECOMMENDATION_RATIONALE_MAX_LENGTH = 500;
