// @flow strict

import React from 'react';

import { StyledSVG } from './styles';

export default function CaretIcon(props?: {
    stroke?: string,
    width?: number,
    strokeWidth?: number,
}) {
    return (
        <StyledSVG viewBox="0 0 24 24" width={`${props.width}px`} height={`${props.width}px`}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <line
                    x1="4"
                    y1="13"
                    x2="12"
                    y2="5"
                    stroke={props.stroke}
                    strokeWidth={props.strokeWidth}
                />
                <line
                    x1="12"
                    y1="5"
                    x2="20"
                    y2="13"
                    stroke={props.stroke}
                    strokeWidth={props.strokeWidth}
                />
            </g>
        </StyledSVG>
    );
}

CaretIcon.defaultProps = {
    stroke: 'currentColor',
    strokeWidth: 2,
    width: 15,
};
