// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Constants
import { ROUTES } from 'utils/constants';

// Container
import UserSettingsContainer from 'containers/UserSettingsContainer';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
};

export class UserSettings extends React.PureComponent<Props, null> {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.UserSettings.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.UserSettings.helmetDescription',
                        })}
                    />
                </Helmet>
                <UserSettingsContainer />
            </React.Fragment>
        );
    }
}

export default injectIntl(UserSettings);
