// @flow

import LegacyTheme from '../../themes/legacyTheme';

// See styling documentation: https://react-select.com/styles

// Returns an object which will be passed to React Select component to render styling.
// 'styles' is an optional object used for custom styling.
const inputSelectStyles = (styles: Object) => ({
    container: (provided: Object) => ({
        ...provided,
        width: '100%',
        minHeight: '30px',
        padding: '0px',
        fontSize: '11px',
    }),
    control: (
        provided: Object,
        { isFocused, isDisabled }: { isFocused: boolean, isDisabled: boolean }
    ) => ({
        ...provided,
        minHeight: '30px',
        backgroundColor: isDisabled
            ? LegacyTheme.disabledInputSelectColor
            : LegacyTheme.defaultBackground,
        border: `1px solid ${LegacyTheme.defaultBorder}`,
        paddingLeft: '16px',
        boxShadow: 'none',
        zIndex: 2,
        borderBottomRightRadius: isFocused ? '0px' : '4px',
        borderBottomLeftRadius: isFocused ? '0px' : '4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:hover': {
            border: `1px solid ${LegacyTheme.defaultBorder}`,
            cursor: 'text',
        },
    }),
    placeholder: (provided: Object) => ({
        ...provided,
        color: LegacyTheme.disabledInputColor,
    }),
    valueContainer: (provided: Object, { isLoading }: Object) => ({
        ...provided,
        position: 'relative',
        width: isLoading ? 'calc(100% - 70px)' : 'calc(100% - 30px)',
        padding: '0px',
        color: LegacyTheme.disabledInputColor,
        fontSize: styles.valueContainerFontSize || '12px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    clearIndicator: (provided: Object) => ({
        ...provided,
        padding: '4px',

        '&:hover': {
            cursor: 'pointer',
        },
    }),
    dropdownIndicator: (provided: Object) => ({
        ...provided,
        padding: '7px',

        '&:hover': {
            cursor: 'pointer',
        },
    }),
    option: (
        provided: Object,
        {
            isSelected,
            isFocused,
        }: {
            isSelected: boolean,
            isFocused: boolean,
        }
    ) => ({
        ...provided,
        paddingLeft: '18px',
        color: LegacyTheme.defaultColor,
        /* eslint-disable-next-line no-nested-ternary */
        backgroundColor: isSelected
            ? LegacyTheme.buttonSecondary
            : isFocused
            ? LegacyTheme.tableRowHover
            : null,
        cursor: 'pointer',
        borderRight: `0.5px solid ${LegacyTheme.defaultBorder}`,
        boxShadow: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    menu: (provided: Object) => ({
        ...provided,
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        marginTop: '-1px',
        border: `0.5px solid ${LegacyTheme.defaultBorder}`,
        boxShadow: 'none',
        zIndex: 99,
    }),
    groupHeading: (provided: Object) => ({
        ...provided,
        fontSize: styles.groupLabelFontSize,
        color: styles.groupLabelColor,
        textTransform: styles.groupLabelTextTransform,
    }),
});

export default inputSelectStyles;
