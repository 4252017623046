// @flow

import styled, { createGlobalStyle } from 'styled-components';
import { transparentize } from 'polished';
import legacyTheme from '../../themes/legacyTheme';

export const PauseScroll = createGlobalStyle`
    html {
        overflow: hidden;
    }
`;

export const Wrapper = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 320px;
    height: 100%;
    z-index: 999999;
    transform: translateZ(0);
    background-color: ${({ overlay }: Object) =>
        overlay ? transparentize(0.25, legacyTheme.modalOverlay) : 'transparent'};
    pointer-events: ${({ overlay }: Object) => (overlay ? 'auto' : 'none')};
`;

export const Close = styled.div`
    display: block;

    position: absolute;
    top: 0;
    right: 0;

    width: 30px;
    height: 30px;

    z-index: 5;

    cursor: pointer;

    &:after {
        content: '×';

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        text-align: center;
        font-size: 24px;
        color: ${legacyTheme.modalCloseColor};
    }
`;

export const Content = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: ${({ modalWidth }: Object) => modalWidth || '375px'};
    height: auto;
    min-height: 120px;
    max-height: ${({ modalHeight }: Object) => modalHeight || 'calc(100% - 30px)'};

    padding: ${({ padding }: Object) => padding || '24px 0 20px'};

    background-color: ${legacyTheme.modalContent};
    box-shadow: 0 0 10px 3px ${transparentize(0.9, legacyTheme.modalOverlay)};

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    box-sizing: border-box;

    pointer-events: auto;
`;

export const Trigger = styled.div`
    display: ${({ inline }: Object) => (inline ? 'inline-block' : 'block')};
    height: auto;
    cursor: pointer;
`;
