// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import RecommendationSidebar from 'components/RecommendationSidebar';
import PlantDatasetMessageSidebar from 'components/PlantDatasetMessageSidebar';
import RecommendationSetSubmissionModal from 'components/Modals/RecommendationSetSubmissionModal';

// Constants
import { TRENDS_PAGE_TYPE, RECOMMENDATION_NEW_ID, APPLICATION_TYPES } from 'utils/constants';

import { logUserAnalyticsInteraction } from 'utils/userAnalytics';

// Helpers
import { isClientUser, getUsersLanguage, getLoginUserName } from 'utils/authentication';
import { parseStringToUTCDate } from 'utils/dateHelpers';
import { getMajorKPIs } from 'containers/TrendsContainer/helpers';
import { getRecommendationItemFromRecommendationType } from 'utils/recommendationHelpers';

// Styles
import { SidebarWrapper, SidebarInstructions, Title, Instructions } from './styles';

// Selectors
import { selectUser } from 'services/Authentication/selectors';
import { selectAllKPISettings } from 'services/KPISetting/selectors';
import {
    selectRecommendationSetIsSubmitting,
    selectRecommendationSetSubmissionErrors,
    selectFeedbackIsSubmitting,
    selectIsRunningRecommendations,
    selectIsSubmittingPlantDatasetMessage,
    selectIsLoadingCircuitRecommendation,
    selectIsLoadingPlantRecommendation,
    selectCircuitRecommendations,
    selectPlantRecommendations,
} from 'services/Recommendation/selectors';
import { selectAllCircuitTrends, selectCircuitTrendsAreFetching } from 'services/Trends/selectors';

// Thunks
import {
    submitPlantDatasetMessage,
    submitRecommendationSet,
    submitFeedbacks,
    runRecommendationForCircuit,
    fetchCircuitRecommendation,
    fetchPlantRecommendation,
} from 'services/Recommendation/thunks';
import { fetchCircuitTrend } from 'services/Trends/thunks';

// Types
import type { ReduxDispatch, ImmutableList, IntlType, ErrorType, HistoryType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type {
    TrendsPageType,
    ImmutableTrendData,
    ImmutableCircuitTrend,
} from 'services/Trends/types';
import type {
    ImmutableRecommendationSet,
    ImmutableRecommendationFeedback,
    ImmutablePlantDatasetMessage,
    RecommendationSetIdType,
} from 'services/Recommendation/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

type Props = {
    history: HistoryType,
    user: ImmutableUser,
    intl: IntlType,

    trend: ImmutableTrendData,
    isLoadingTrend: boolean,
    trendType: TrendsPageType,

    kpiSettings: ImmutableList<ImmutableKPISetting>,

    circuitId?: number | null,
    isLoadingDataset: boolean,
    circuit?: ImmutableCircuit,
    isLoadingCircuitRecommendation: boolean,
    fetchCircuitRecommendation: (circuitId: number) => void,
    recommendationSets: ImmutableList<ImmutableRecommendationSet>,

    isSubmittingRecommendation: boolean,
    submissionErrors: ?ErrorType,
    submitRecommendationSet: (
        id: RecommendationSetIdType,
        recommendationSet: ImmutableRecommendationSet
    ) => void,

    plantId?: number | null,
    isLoadingPlantRecommendation: boolean,
    fetchPlantRecommendation: (plantId: number) => void,
    plantDatasetMessages: ImmutableList<ImmutablePlantDatasetMessage>,

    isSubmittingPlantDatasetMessage: boolean,
    submitPlantDatasetMessage: (
        plantId: number,
        plantDatasetId: number,
        message: ImmutablePlantDatasetMessage
    ) => void,

    isSubmittingFeedback: boolean,
    submitFeedbacks: (
        id: number,
        feedbacks: ImmutableList<ImmutableRecommendationFeedback>
    ) => void,

    runRecommendationForCircuit: (circuitId: number) => void,
    isRunningRecommendations: boolean,

    circuitTrends: ImmutableList<ImmutableCircuitTrend>,
    circuitTrendsAreLoading: boolean,
    fetchCircuitTrend: (circuitId: number) => void,
};

type State = {
    showRecommendationsSubmissionModal: boolean,
    recommendationSetToBeSubmitted: ?(ImmutableRecommendationSet | ImmutablePlantDatasetMessage),
};

class RecommendationsSidebarContainer extends React.PureComponent<Props, State> {
    static defaultProps = {
        circuitId: null,
        plantId: null,
    };

    state = {
        showRecommendationsSubmissionModal: false,
        recommendationSetToBeSubmitted: null,
    };

    /**
     * Get trend based on the page type.
     */
    getTrend = () => {
        if (this.props.trend) {
            return this.props.trend;
        }

        if (this.props.circuitId) {
            return this.props.circuitTrends?.find(
                (trend: ImmutableCircuitTrend) => trend.get('circuitId') === this.props.circuitId
            );
        }
    };

    componentDidMount() {
        const recommendation = this.getRecommendationSet();
        if (!recommendation) {
            this.fetchRecommendations();
        }
    }

    /**
     * When our props updates, check to see if they are different then update our state depending
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        const trend = this.getTrend();

        // If we have a recommendation and the trend is null, we want to fetch the trend.
        if (this.props.recommendationSets !== prevProps.recommendationSets && !trend) {
            this.fetchTrend();
        }

        if (!trend && prevProps.trend) {
            this.setState({
                showRecommendationsSubmissionModal: false,
                recommendationSetToBeSubmitted: null,
            });
            return;
        }

        if (
            this.props.trendType !== prevProps.trendType ||
            (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT &&
                !this.props.isLoadingCircuitRecommendation &&
                this.props.circuitId !== prevProps.circuitId) ||
            (this.props.trendType === TRENDS_PAGE_TYPE.PLANT &&
                !this.props.isLoadingPlantRecommendation &&
                this.props.plantId !== prevProps.plantId)
        ) {
            const recommendation = this.getRecommendationSet();
            this.setState(
                {
                    showRecommendationsSubmissionModal: false,
                    recommendationSetToBeSubmitted: recommendation,
                },
                () => {
                    if (
                        !recommendation &&
                        ((this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT &&
                            !this.props.isLoadingCircuitRecommendation) ||
                            (this.props.trendType === TRENDS_PAGE_TYPE.PLANT &&
                                !this.props.isLoadingPlantRecommendation))
                    ) {
                        this.fetchRecommendations();
                    }
                }
            );
        }

        if (
            prevProps.isSubmittingPlantDatasetMessage &&
            !this.props.isSubmittingPlantDatasetMessage
        ) {
            this.setState({
                showRecommendationsSubmissionModal: false,
                recommendationSetToBeSubmitted: null,
            });
        }

        if (prevProps.isSubmittingRecommendation && !this.props.isSubmittingRecommendation) {
            if (!this.props.submissionErrors || this.props.submissionErrors.isEmpty()) {
                this.handleHideRecommendationSubmissionModal();
            }
            // TODO: what to do if we have errors?
        }
    }

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.RecommendationSidebar.${id}`,
            },
            data
        );

    getCircuitKPISettings = () =>
        this.props.kpiSettings.filter(
            (kpi: ImmutableKPISetting) => kpi.get('circuitId') === this.props.circuitId
        );

    fetchRecommendations = () =>
        this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT
            ? this.props.fetchCircuitRecommendation(this.props.circuitId)
            : this.props.fetchPlantRecommendation(this.props.plantId);

    /**
     * Get recommendation set from trend, if no recommendation set create new one
     */
    getRecommendationSet = () => {
        const trend = this.getTrend();

        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT) {
            return this.props.recommendationSets.find(
                (recommendationSet: ImmutableRecommendationSet) =>
                    recommendationSet.get('circuitId') === this.props.circuitId
            );
        } else {
            return this.props.plantDatasetMessages.find(
                (recommendationSet: ImmutablePlantDatasetMessage) =>
                    recommendationSet.get('plantId') === trend.get('plantId')
            );
        }
    };

    fetchTrend = () => {
        if (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT) {
            this.props.fetchCircuitTrend(this.props.circuitId);
        }
    };

    handleShowRecommendationSubmissionModal = (
        recommendationSetToBeSubmitted: ImmutableRecommendationSet
    ) =>
        this.setState({
            showRecommendationsSubmissionModal: true,
            recommendationSetToBeSubmitted,
        });

    handleHideRecommendationSubmissionModal = () =>
        this.setState({
            showRecommendationsSubmissionModal: false,
            recommendationSetToBeSubmitted: null,
        });

    handleConfirmSubmitRecommendationSet = () => {
        if (this.state.recommendationSetToBeSubmitted) {
            this.props.submitRecommendationSet(
                this.state.recommendationSetToBeSubmitted.get('id') || RECOMMENDATION_NEW_ID,
                this.state.recommendationSetToBeSubmitted
            );

            // Piwik pro log
            logUserAnalyticsInteraction({
                moduleName: APPLICATION_TYPES.SOLVEXTRACT,
                circuit: this.props.circuit.get('name'),
                userName: getLoginUserName(this.props.user),
                searchKeyword: 'A new Recommendation added',
            });
        }
    };

    /**
     * Handles runRecommendationForCircuit if circuitId is present
     */
    handleRunDecisionTree = () =>
        this.props.circuitId ? this.props.runRecommendationForCircuit(this.props.circuitId) : null;

    /**
     * Returns boolean if conditions are met, used to provided this.handleRunDecisionTree or not
     * - User is a SAM
     * - And one of the following:
     *     - Trends recommendationSet is null but trend has datasets
     *     - There are more recent datasets than the recommendationSet
     */
    shouldAllowRunDecisionTree = (): boolean => {
        const trend = this.getTrend();

        // if there is no trend or if the user is a PM, return false
        if (!trend || isClientUser(this.props.user)) {
            return false;
        }

        const recommendation = this.getRecommendationSet();
        if (!recommendation) {
            return false;
        }

        // Get the createdAt date of the first dataset of the trend and parse to date
        const latestDatasetCreatedAtString = trend.get('createdAt');
        const latestDatasetCreatedAtDate = latestDatasetCreatedAtString
            ? parseStringToUTCDate(latestDatasetCreatedAtString)
            : null;
        // Get the createdAt date of the recommendation set and pare to data
        const recommendationSetCreatedAtString = recommendation.get('createdAt');
        const recommendationSetCreatedAtDate = recommendationSetCreatedAtString
            ? parseStringToUTCDate(recommendationSetCreatedAtString)
            : null;

        // Allow the DT to be ran if the recommendation set was never created
        // This will occur when the backend does not have a recommendation set saved from a DT run
        if (!recommendationSetCreatedAtDate) {
            return true;
        }

        // If both dates are present, check to see if the latestDataset is more recent than the recommendationSet
        const hasMoreRecentDataset =
            latestDatasetCreatedAtDate &&
            recommendationSetCreatedAtDate &&
            Boolean(latestDatasetCreatedAtDate > recommendationSetCreatedAtDate);

        return Boolean(hasMoreRecentDataset);
    };

    /**
     * When the PM user wants to send their feedbacks.
     */
    handleSubmitFeedback = (feedbacks: ImmutableList<ImmutableRecommendationFeedback>) => {
        const recommendationSet = this.getRecommendationSet();
        if (!recommendationSet) {
            return;
        }
        this.props.submitFeedbacks(recommendationSet.get('id'), feedbacks);
    };

    /**
     * When the SAME user wants to send a plant dataset message.
     */
    handleSubmitPlantDatasetMessage = (message: ImmutablePlantDatasetMessage) => {
        const trend = this.getTrend();

        if (!trend) {
            return;
        }

        this.props.submitPlantDatasetMessage(
            trend.get('plantId'),
            trend.get('plantDatasetId'),
            message
        );

        //Piwik pro log
        logUserAnalyticsInteraction({
            moduleName: APPLICATION_TYPES.SOLVEXTRACT,
            plant: trend.get('plantId'),
            userName: getLoginUserName(this.props.user),
            searchKeyword: 'A new recommendation added',
        });
    };

    renderRecommendationsSubmissionModal = () => {
        if (this.props.trendType !== TRENDS_PAGE_TYPE.CIRCUIT) {
            throw new Error('Cannot view recommendations to be submitted on plant trend.');
        }
        const trend = this.getTrend();
        if (!trend) {
            throw new Error('Submission modal opened for null trend');
        }
        const createdAt = trend.get('createdAt');
        return (
            <RecommendationSetSubmissionModal
                kpis={trend.get('kpis')}
                kpiSettings={this.getCircuitKPISettings()}
                majorKPIdata={getMajorKPIs(trend.get('kpis'), this.props.trendType)}
                datasetStatus={trend.get('status')}
                createdAt={createdAt}
                circuit={this.props.circuit}
                isUserPM={isClientUser(this.props.user)}
                timezone={trend.get('timezone')}
                userLocale={getUsersLanguage(this.props.user)}
                recommendationSet={this.state.recommendationSetToBeSubmitted}
                loading={this.props.isSubmittingRecommendation}
                onSubmit={this.handleConfirmSubmitRecommendationSet}
                onCancel={this.handleHideRecommendationSubmissionModal}
                isSingleCircuit={false} // TODO, for cu transfer targets in major kpi
            />
        );
    };

    renderCircuitSidebar = (isUserPM: boolean, trend: ImmutableTrendData) => {
        return (
            <RecommendationSidebar
                loadingSubmittingFeedback={this.props.isSubmittingFeedback}
                loadingRunDecisionTree={this.props.isRunningRecommendations}
                recommendationSet={this.getRecommendationSet()}
                kpis={trend.get('kpis')}
                kpiSettings={this.getCircuitKPISettings()}
                datasetStatus={trend.get('status')}
                circuit={this.props.circuit}
                isUserPM={isUserPM}
                timezone={trend.get('timezone')}
                userLocale={getUsersLanguage(this.props.user)}
                onSubmitRecommendationSet={this.handleShowRecommendationSubmissionModal}
                onSubmitFeedback={this.handleSubmitFeedback}
                onRunDecisionTree={
                    this.shouldAllowRunDecisionTree() ? this.handleRunDecisionTree : null
                }
            />
        );
    };

    renderPlantSidebar = (isUserPM: boolean, trend: ImmutableTrendData) => {
        return (
            <PlantDatasetMessageSidebar
                userLocale={getUsersLanguage(this.props.user)}
                isUserPM={isUserPM}
                plantDatasetId={trend.get('plantDatasetId')}
                plantDatasetMessage={this.getRecommendationSet()}
                timezone={trend.get('timezone')}
                loadingSubmittingPlantDatasetMessage={this.props.isSubmittingPlantDatasetMessage}
                onSubmitPlantDatasetMessage={this.handleSubmitPlantDatasetMessage}
            />
        );
    };

    render() {
        const isLoadingRecommendation =
            this.props.isLoadingCircuitRecommendation || this.props.isLoadingPlantRecommendation;

        const trend = this.getTrend();

        const isUserPM = isClientUser(this.props.user);

        const isDoneLoading =
            !this.props.isLoadingDataset &&
            !this.props.isLoadingTrend &&
            !this.props.circuitTrendsAreLoading &&
            !isLoadingRecommendation;

        return (
            <SidebarWrapper
                updatedBackgroundColor={this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT}
            >
                <SidebarInstructions>
                    {!isLoadingRecommendation &&
                        this.state.showRecommendationsSubmissionModal &&
                        this.renderRecommendationsSubmissionModal()}
                    {isLoadingRecommendation && (
                        <Instructions>
                            {this.getTranslation('recommendationIsLoading')}
                        </Instructions>
                    )}
                    {!isLoadingRecommendation &&
                        (this.props.isLoadingDataset ||
                            this.props.isLoadingTrend ||
                            this.props.circuitTrendsAreLoading) && (
                            <Instructions>{this.getTranslation('datasetIsLoading')}</Instructions>
                        )}
                    {!this.props.isLoadingTrend &&
                        !trend &&
                        !this.props.circuitTrendsAreLoading && (
                            <Instructions>{this.getTranslation('noTrendsAvailable')}</Instructions>
                        )}
                </SidebarInstructions>
                {isDoneLoading &&
                    trend &&
                    (this.props.trendType === TRENDS_PAGE_TYPE.CIRCUIT
                        ? this.renderCircuitSidebar(isUserPM, trend)
                        : this.renderPlantSidebar(isUserPM, trend))}
            </SidebarWrapper>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
        kpiSettings: selectAllKPISettings(),

        recommendationSets: selectCircuitRecommendations(),
        plantDatasetMessages: selectPlantRecommendations(),

        isLoadingCircuitRecommendation: selectIsLoadingCircuitRecommendation(),
        isLoadingPlantRecommendation: selectIsLoadingPlantRecommendation(),

        submissionErrors: selectRecommendationSetSubmissionErrors(),
        isSubmittingRecommendation: selectRecommendationSetIsSubmitting(),
        isSubmittingFeedback: selectFeedbackIsSubmitting(),
        isSubmittingPlantDatasetMessage: selectIsSubmittingPlantDatasetMessage(),

        isRunningRecommendations: selectIsRunningRecommendations(),

        circuitTrendsAreLoading: selectCircuitTrendsAreFetching(),
        circuitTrends: selectAllCircuitTrends(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            runRecommendationForCircuit,
            submitRecommendationSet,
            submitFeedbacks,
            submitPlantDatasetMessage,

            fetchCircuitRecommendation,
            fetchPlantRecommendation,
            fetchCircuitTrend,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(RecommendationsSidebarContainer))
);
