// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';

// Assets
import caretLeft from './assets/Caret/left-grey.png';
import caretRight from './assets/Caret/right-grey.png';

export const Ellipsis = styled.div`
    &::before {
        content: "[...]";
        padding-left: ${({ paddingLeft }: Object) => (paddingLeft ? '20px' : '0')};
        padding-right: ${({ paddingRight }: Object) => (paddingRight ? '20px' : '0')};
`;

export const StyledPagination = styled.div`
    color: ${legacyTheme.paginationColor};
    font-size: 14px;
`;

export const Controls = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    margin: 20px auto;

    ${({ isLoading }: Object) =>
        isLoading &&
        `
        > div {
            color: ${legacyTheme.disabledPlaceholder};
            cursor: not-allowed;
        };
    `}
`;

export const NavigationButton = styled.div`
    cursor: pointer;

    width: 30px;
    height: 30px;

    background-color: ${legacyTheme.paginationButton};
    background-size: auto 10px;
    background-position: center;
    background-repeat: no-repeat;

    ${({ navigationSense }: Object) =>
        navigationSense === 'left'
            ? `
        background-image: url(${caretLeft});
    `
            : `
        background-image: url(${caretRight});
    `};
`;

export const PageLink = styled.div`
    cursor: pointer;
    color: ${({ selected }: Object) =>
        selected ? legacyTheme.paginationActiveColor : legacyTheme.paginationColor};
    font-weight: ${({ selected }: Object) => (selected ? 'bold' : 'normal')};

    &::before {
        ${({ includeSeparator }: Object) =>
            includeSeparator &&
            `
            content: "-";
            padding: 0 15px;
            color: ${legacyTheme.paginationColor};

        `};
    }
`;
