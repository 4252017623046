// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Title } from 'styles/common';
import {
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
    InputSelect,
    Modal,
    Row,
    Column,
    InputField,
    ButtonWrapper,
} from 'components/_ReactUI_V1';

// Constants
import {
    MODAL_WIDTH,
    TWOD_EXPORT_DATA_KEYS,
    TWO_D_STEP_COUNT_VALUES,
    STYLE_VALUES,
} from 'utils/constants';

// Helpers
import { toColumnNameFromIndex } from 'utils/helpers';

// Styles
import {
    Wrapper,
    Header,
    Body,
    Footer,
    FieldLabel,
    Subtitle,
    HorizontalField,
    HorizontalFieldLabel,
    ColumnTitle,
    InputSelectWrapper,
} from './styles';

// Types
import type { IntlType, InputEvent, ReactSelectObject } from 'types';
import type { TwoDExport } from 'services/Dataset/types';

type State = TwoDExport;

type Props = {
    intl: IntlType,
    loading: boolean,
    onConfirm: (state: State) => void,
    onCancel: () => void,
};

const shotInputFieldStyleObject = {
    width: '40px',
    height: '30px',
};

/**
 * The export csv modal
 */
class Export2DSensitivityCSVModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.getDefaultState();
    }

    /**
     * Get a new state object from the prop user settings.
     */
    getDefaultState = (): State => ({
        title: '2DSensitivityCSVExport',
        stepCount: TWO_D_STEP_COUNT_VALUES[1],
        columns: {
            plsStreamsComposition: 1,
            plsStreamsPh: 2,
            plsStreamsFlowrate: 3,
            plsStreamsRaffinate: 4,
            plsStreamsRaffinateFlowrate: 5,
            plsStreamsRecoveryPercent: 6,
            plsStreamsOARatio: 7,
            plsStreamsStageEfficiency: 8,
            electrolyteStreamsSpentElectrolyte: 9,
            electrolyteStreamsSpentAcid: 10,
            electrolyteStreamsFlowrate: 11,
            electrolyteStreamsAdvanceElectrolyte: 12,
            electrolyteStreamsOARatio: 13,
            electrolyteStreamsStageEfficiency: 14,
            organicStreamsFlowrate: 15,
            organicStreamsPercentLoading: 16,
            organicStreamsPercentStripped: 17,
            organicStreamsNetTransfer: 18,
            circuitInformationReagentConcentration: 19,
            circuitInformationOxModRatio: 20,
            circuitInformationOverallRecovery: 21,
            circuitInformationExtractStageCount: 22,
            circuitInformationStripStageCount: 23,
        },
    });

    getReactSelectOptionFromValue = (
        options: Array<ReactSelectObject>,
        value: number
    ): ?ReactSelectObject =>
        options.find(
            (option: ReactSelectObject) => value && option.value.toString() === value.toString()
        );

    getCellReactSelectOptions = (): Array<ReactSelectObject> => {
        // const stateAsArray = Object.values(this.state);
        const cellCount = Object.keys(this.getDefaultState().columns).length;
        const range = Array(cellCount)
            .fill()
            // eslint-disable-next-line no-unused-vars, flowtype/require-parameter-type
            .map((_, idx: number) => 1 + idx);
        const options = range.map((number: number) => ({
            label: toColumnNameFromIndex(number),
            value: number,
            // isSelected: stateAsArray.includes(number)
        }));

        return options;
    };

    /**
     * Returns the step count that the 2D sensitivity export function expects.
     */
    getIntervalReactSelectOptions = () =>
        TWO_D_STEP_COUNT_VALUES.map((value: number) => ({
            label: value,
            value,
        }));

    /**
     * Validates values
     */
    isValid = (): boolean => true;

    /**
     * Handle the save button click
     */
    handleExportClicked = () => this.props.onConfirm(this.state);

    // TODO: Add duplicate cell validation? Nice to have
    // handleValidateDuplicateSelectedCellOptions = () => {
    //     const stateAsArray = Object.values(this.state);
    // };

    /**
     * Handlers reverting settings to default
     */
    handleRevertToDefault = () =>
        this.setState({
            ...this.getDefaultState(),
        });

    handleInputFieldChange = (event: InputEvent) =>
        this.setState({ [event.target.name]: event.target.value });

    handleInputSelectChange = (key: string, withinColumnsObject: boolean = false) => (
        selectedObject: ReactSelectObject
    ) => {
        if (withinColumnsObject) {
            this.setState((prevState: State) => ({
                columns: {
                    ...prevState.columns,
                    [key]: selectedObject.value,
                },
            }));
        } else {
            this.setState({
                [key]: selectedObject.value,
            });
        }
    };

    renderColumnFieldMarkup = (cellOptions: Array<ReactSelectObject>, key: string) => (
        <HorizontalField key={key}>
            <HorizontalFieldLabel>
                {this.props.intl.formatMessage({
                    id: `components.Export2DSensitivityCSVModal.columns.${key}`,
                })}
            </HorizontalFieldLabel>
            <InputSelectWrapper>
                <InputSelect
                    selectedOption={this.getReactSelectOptionFromValue(
                        cellOptions,
                        this.state.columns[key]
                    )}
                    name={key}
                    style={shotInputFieldStyleObject}
                    options={cellOptions}
                    onSelect={this.handleInputSelectChange(key, true)}
                    maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.SMALL}
                    controlShouldRenderValue
                />
            </InputSelectWrapper>
        </HorizontalField>
    );

    renderPLSFeedsColumn = (options: Array<ReactSelectObject>) => (
        <Column style={{ width: '33%' }}>
            <ColumnTitle>
                {this.props.intl.formatMessage({
                    id: 'components.Export2DSensitivityCSVModal.columns.PLSFeeds.title',
                })}
            </ColumnTitle>
            {TWOD_EXPORT_DATA_KEYS.PLS_STREAMS.map((key: string) =>
                this.renderColumnFieldMarkup(options, key)
            )}
        </Column>
    );

    renderElectrolyteColumn = (options: Array<ReactSelectObject>) => (
        <Column style={{ width: '33%' }}>
            <ColumnTitle>
                {this.props.intl.formatMessage({
                    id: 'components.Export2DSensitivityCSVModal.columns.electrolyte.title',
                })}
            </ColumnTitle>
            {TWOD_EXPORT_DATA_KEYS.ELECTROLYTE_STREAMS.map((key: string) =>
                this.renderColumnFieldMarkup(options, key)
            )}
        </Column>
    );

    renderOrganicCircuitColumn = (options: Array<ReactSelectObject>) => (
        <Column style={{ width: '33%' }}>
            <ColumnTitle>
                {this.props.intl.formatMessage({
                    id: 'components.Export2DSensitivityCSVModal.columns.organicCircuit.title',
                })}
            </ColumnTitle>
            {TWOD_EXPORT_DATA_KEYS.ORGANIC_STREAMS.map((key: string) =>
                this.renderColumnFieldMarkup(options, key)
            )}
        </Column>
    );

    renderCircuitData = (options: Array<ReactSelectObject>) => (
        <Column style={{ width: '33%' }}>
            <ColumnTitle>
                {this.props.intl.formatMessage({
                    id: 'components.Export2DSensitivityCSVModal.columns.circuitData.title',
                })}
            </ColumnTitle>
            {TWOD_EXPORT_DATA_KEYS.CIRCUIT_INFORMATION.map((key: string) =>
                this.renderColumnFieldMarkup(options, key)
            )}
        </Column>
    );

    render() {
        const cellOptions = this.getCellReactSelectOptions();
        const intervalOptions = this.getIntervalReactSelectOptions();
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.XLARGE} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Export2DSensitivityCSVModal.title',
                            })}
                        </Title>
                        <Row gutter={16} style={{ width: '50%', marginLeft: 0 }}>
                            <Column flex={3}>
                                <FieldLabel>
                                    {this.props.intl.formatMessage({
                                        id: 'components.Export2DSensitivityCSVModal.options.title',
                                    })}
                                </FieldLabel>
                                <InputField
                                    onChange={this.handleInputFieldChange}
                                    placeholder={this.props.intl.formatMessage({
                                        id:
                                            'components.Export2DSensitivityCSVModal.options.title.placeholder',
                                    })}
                                    name="title"
                                    type="text"
                                    value={this.state.title}
                                />
                            </Column>
                            <Column flex={1}>
                                <FieldLabel>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'components.Export2DSensitivityCSVModal.options.stepCount',
                                    })}
                                </FieldLabel>
                                <InputSelect
                                    selectedOption={this.getReactSelectOptionFromValue(
                                        intervalOptions,
                                        this.state.stepCount
                                    )}
                                    options={intervalOptions}
                                    onSelect={this.handleInputSelectChange('stepCount')}
                                    maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.SMALL}
                                    controlShouldRenderValue
                                />
                            </Column>
                        </Row>
                    </Header>
                    <Body>
                        <Row>
                            <Subtitle>
                                {this.props.intl.formatMessage({
                                    id: 'components.Export2DSensitivityCSVModal.columns.title',
                                })}
                            </Subtitle>
                        </Row>
                        <Row gutter={34}>
                            {this.renderPLSFeedsColumn(cellOptions)}
                            {this.renderElectrolyteColumn(cellOptions)}
                            {this.renderOrganicCircuitColumn(cellOptions)}
                            {this.renderCircuitData(cellOptions)}
                        </Row>
                    </Body>
                    <Footer>
                        <Row>
                            <Column>
                                <TertiaryButton
                                    text={this.props.intl.formatMessage({
                                        id:
                                            'components.Export2DSensitivityCSVModal.revertToDefaultButtonText',
                                    })}
                                    onClick={this.handleRevertToDefault}
                                />
                            </Column>
                            <Column justifyContent="flex-end">
                                <ButtonWrapper style={{ width: '100%', textAlign: 'right' }}>
                                    <SecondaryButton
                                        text={this.props.intl.formatMessage({
                                            id: 'components.Modals.cancelButton',
                                        })}
                                        onClick={this.props.onCancel}
                                    />
                                    <PrimaryButton
                                        text={this.props.intl.formatMessage({
                                            id:
                                                'components.Export2DSensitivityCSVModal.exportButtonText',
                                        })}
                                        onClick={this.handleExportClicked}
                                        loading={this.props.loading}
                                        disabled={!this.isValid()}
                                    />
                                </ButtonWrapper>
                            </Column>
                        </Row>
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(Export2DSensitivityCSVModal);
