// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header, Body, Footer, AlertText } from './styles';

// Constants
import { MODAL_WIDTH } from 'utils/constants';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, Modal } from 'components/_ReactUI_V1';

type Props = {
    title: string,
    alertText: string,
    acknowledgeButtonText: string,
    loading?: boolean,

    onAcknowledgement: () => void,
};

class AlertModal extends React.PureComponent<Props> {
    static defaultProps = {
        loading: false,
    };

    render() {
        return (
            <Modal
                modalWidth={MODAL_WIDTH.LARGE}
                style={{
                    wrapper: {
                        zIndex: '999999',
                    },
                }}
                disableClose
            >
                <Wrapper>
                    <Header>
                        <Title>{this.props.title}</Title>
                    </Header>
                    <Body>
                        <AlertText>{this.props.alertText}</AlertText>
                    </Body>
                    <Footer>
                        <PrimaryButton
                            text={this.props.acknowledgeButtonText}
                            onClick={this.props.onAcknowledgement}
                            loading={this.props.loading}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(AlertModal);
