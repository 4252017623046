// @flow

import React from 'react';
import { fromJS, Map } from 'immutable';

// Components
import { LoadingDots } from '../../icons/LoadingDots';
import InputFile from './InputFile';

// Styles
import { Container, Thumbnail, LoaderContainer } from './styles';

// Types
import type { ImmutableList, ImmutableMap, InputEventType, ReactNode } from '../../types';

// TODO: Look into FileType...
type FileType = Object;
type Props = {
    accept?: string,
    buttonComponent?: ReactNode,
    displayInput?: boolean,
    disabled?: boolean,
    enableThumbnail?: boolean,
    fileSizeLimitErrorMessage?: ReactNode,
    savedFiles?: ImmutableList<ImmutableMap>,
    files?: ImmutableList<ImmutableMap<string, FileType>>,
    fileSizeLimit?: number,
    name: string,
    onChange?: (event: InputEventType) => void,
    onRemove?: (file: File) => void,
    placeholder?: string,
};

type State = {
    files?: ImmutableList<ImmutableMap<string, FileType>>,
    thumbnails: ImmutableMap<string, Blob>,
};

export default class InputFileSet extends React.Component<Props, State> {
    static defaultProps = {
        accept: '',
        buttonComponent: null,
        disabled: false,
        displayInput: false,
        enableThumbnail: false,
        files: fromJS([{}]),
        fileSizeLimit: null,
        fileSizeLimitErrorMessage: null,
        onChange: null,
        onRemove: null,
        placeholder: '',
        savedFiles: fromJS([{}]),
    };

    state = {
        files:
            this.props.savedFiles && this.props.savedFiles.size
                ? this.props.savedFiles
                : this.props.files,
        thumbnails: fromJS({}),
    };

    /**
     * Creates onChange Handler, expects file index & file from InputFile.onChange
     * Sets new file with index, pushes empty object for following file
     * Fires props.onChange() and passes list of files (filtered to remove empty object)
     */
    createOnChangeHandler = (i: number) => (file: FileObject) => {
        this.setState(
            (prevState: State) => ({
                files: prevState.files.set(i, file).push({}),
            }),
            () => {
                this.handleCreateThumbnail(file);
                this.props.onChange(this.state.files.filter((f: FileObject) => f.name));
            }
        );
    };

    /**
     * Creates onRemove Handler, expects file index
     * Deletes file based on index
     * Fires props.onChange() and passes list of files (filtered to remove empty object).
     * If this.props.onRemove handler is provided, invoke it and pass the removed file.
     */
    createOnRemoveHandler = (i: number) => () => {
        let removedFile;

        this.setState(
            (prevState: State) => {
                removedFile = prevState.files.get(i);

                return {
                    files: prevState.files.delete(i),
                };
            },
            () => {
                if (this.props.onRemove) {
                    this.props.onRemove(removedFile);
                }

                if (this.props.onChange) {
                    this.props.onChange(this.state.files.filter((f: Object) => f.name));
                }
            }
        );
    };

    /**
     * Creates a thumbnail blob used to be a src for the thumbnail img
     */
    handleCreateThumbnail = (file: FileType) => {
        if (!this.props.enableThumbnail) {
            return;
        }

        const reader = new FileReader();

        // On load start, set thumbnails[file.name] to 'loading'. This will render the Loader component.
        reader.onloadstart = () => {
            this.setState((prevState: State) => ({
                thumbnails: prevState.thumbnails.set(file.name, 'loading'),
            }));
        };

        // On load, set thumbnails[file.name] to the base64 image. This will render the thumbnail.
        reader.onload = (event: Event) => {
            this.setState((prevState: State) => ({
                thumbnails: prevState.thumbnails.set(file.name, event.target.result),
            }));
        };

        reader.readAsDataURL(file);
    };

    /**
     * Helper function to render the thumbnail based on state.thumbnails.
     */
    renderThumbnail = (file: FileType) => {
        if (!this.state.thumbnails.get(file.name)) {
            return null;
        } else if (this.state.thumbnails.get(file.name) === 'loading') {
            return (
                <LoaderContainer>
                    <LoadingDots />
                </LoaderContainer>
            );
        } else {
            return <Thumbnail src={this.state.thumbnails.get(file.name)} />;
        }
    };

    render() {
        // Needs a unique name/id for label htmlFor
        // Needs state.files to not be empty
        if (!this.props.name || this.state.files.isEmpty()) {
            return null;
        }

        const files = this.state.files.map((file: ImmutableMap, i: number) => {
            const isSavedFile = Map.isMap(file) && file.get('id');
            let key = i;
            if (isSavedFile) {
                key = file.get('id');
            } else if (file && file.name) {
                key = file.name;
            }

            return (
                <Container key={key}>
                    {/* Thumbnail */}
                    {this.props.enableThumbnail && this.renderThumbnail(file)}
                    <InputFile
                        accept={this.props.accept}
                        buttonComponent={!isSavedFile ? this.props.buttonComponent : null}
                        disabled={this.props.disabled}
                        displayInput={!isSavedFile ? this.props.displayInput : false}
                        fileName={isSavedFile ? file.get('filename') : file.name}
                        fileSizeLimit={this.props.fileSizeLimit}
                        name={`${this.props.name}-${i}`}
                        onChange={!isSavedFile ? this.createOnChangeHandler(i) : null}
                        onRemove={this.createOnRemoveHandler(i)}
                        placeholder={this.props.placeholder}
                        enableThumbnail={this.props.enableThumbnail}
                        fileSizeLimitErrorMessage={this.props.fileSizeLimitErrorMessage}
                        singleUse
                    />
                </Container>
            );
        });

        return <React.Fragment>{files}</React.Fragment>;
    }
}
