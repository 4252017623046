// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    padding: 0px 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 320px;

    padding: 14px 24px 0;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    border-top: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const Text = styled.p`
    line-height: 1.4;
    font-size: 15px;
    margin: 0 0 14px;
    color: ${LegacyTheme.defaultSubtleColor7B};
`;

export const CTAText = styled(Text)`
    color: ${LegacyTheme.buttonPrimary};
    cursor: pointer;
    font-weight: bold;
    margin-left: 6px;

    &:hover {
        color: ${LegacyTheme.buttonPrimaryHover};
    }
`;

export const ResendWrapper = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const ResendTimer = styled.span`
    margin-left: 6px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
`;
