// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Components
import UserManager from 'components/UserManager';

// Constants
import { ROLES, NOTIFICATION_TYPES } from 'utils/constants';

// Authentication
import { isSysAdmin } from 'utils/authentication';

import { LayoutContainer } from 'styles/common';

// Services
import { selectUser } from 'services/Authentication/selectors';
import {
    selectErrors,
    selectQueriedUsers,
    selectTotalCount,
    selectTotalPageCount,
    selectUserIsCreating,
    selectUserIsUpdating,
    selectUserIsDeleting,
    selectUsers,
    selectUsersAreQuerying,
    selectWithSearch,
} from 'services/User/selectors';
import { selectAllReagents, selectReagentsAreFetching } from 'services/Reagent/selectors';
import { selectAllClients } from 'services/Client/selectors';
import { selectAllActivePlants } from 'services/Plant/selectors';
import { createUser, deleteUser, fetchUsers, updateUser, queryV2Users } from 'services/User/thunks';
import { fetchAllReagents } from 'services/Reagent/thunks';
import { fetchAllClients } from 'services/Client/thunks';
import { fetchAllPlants } from 'services/Plant/thunks';
import { fetchNotificationTypeUsersStatuses } from 'services/Notifications/thunks';
import {
    selectNotificationTypeUsersStatusesAreFetching,
    selectNotificationTypeUsersStatuses,
} from 'services/Notifications/selectors';

// Types
import type { ImmutableUser } from 'services/Authentication/types';
import type { UserSearchCriteria } from 'services/User/types';
import type { ErrorType, ImmutableList, IntlType, ReduxDispatch } from 'types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableClient } from 'services/Client/types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableNotificationTypeUsersStatuses } from 'services/Notifications/types';

const USER_DEFAULT_PAGE = 1;
const USER_DEFAULT_SORTBY = 'id';
const USER_DEFAULT_SORTORDER = 'desc';

const defaultSearchCriteria = {
    search: '',
    sortBy: USER_DEFAULT_SORTBY,
    sortOrder: USER_DEFAULT_SORTORDER,
};

type Props = {
    allUsers: ImmutableList<ImmutableUser>,
    clients: ImmutableList<ImmutableClient>,
    createUser: () => void,
    deleteUser: (email: string) => void,
    errors: ErrorType,
    fetchAllClients: () => void,
    fetchAllPlants: () => void,
    fetchAllReagents: () => void,
    fetchUsers: () => void,
    fetchNotificationTypeUsersStatuses: (string, string) => void,
    notificationTypeUsersStatusesAreFetching: boolean,
    notificationTypeUsersStatuses: ImmutableNotificationTypeUsersStatuses,
    intl: IntlType,
    pageCount: number,
    plants: ImmutableList<ImmutablePlant>,
    queriedUsers: ImmutableList<ImmutableUser>,
    queryV2Users: ({}, ?number) => void,
    reagents: ImmutableList<ImmutableReagent>,
    reagentsAreFetching: boolean,
    role: string,
    updateUser: (number) => void,
    user: ImmutableUser,
    userIsCreating: boolean,
    userIsUpdating: boolean,
    userIsDeleting: boolean,
    usersAreQuerying: boolean,
    usersTotal: number,
    withSearch: boolean,
};

type State = {
    searchCriteria: UserSearchCriteria,
};

export class UserManagementContainer extends React.PureComponent<Props, State> {
    componentDidMount() {
        this.fetchResourcesForActiveRole();
        this.queryV2UsersWithDefaults();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.role !== prevProps.role) {
            this.queryV2UsersWithDefaults();
        }

        if (
            this.props.queriedUsers !== prevProps.queriedUsers &&
            this.props.queriedUsers.size > 0
        ) {
            this.queryRecommendationEmailNotificationUsersStatuses();
        }
    }

    /**
     * Query users with default searchCriteria and provided role
     */
    queryV2UsersWithDefaults = () =>
        this.props.queryV2Users({
            ...defaultSearchCriteria,
            role: this.props.role,
        });

    queryRecommendationEmailNotificationUsersStatuses = () => {
        const userIDsQueryString = this.props.queriedUsers.map((el) => el.get('id')).join(',');

        this.props.fetchNotificationTypeUsersStatuses(
            NOTIFICATION_TYPES.SAM_SUBMITTED_RECOMMENDATION,
            userIDsQueryString
        );
    };

    /**
     * Based on the provided role, fetch the required entities
     */
    fetchResourcesForActiveRole = () => {
        // Based on current role, fetch resources if empty
        // eslint-disable-next-line default-case
        switch (this.props.role) {
            case ROLES.SAM:
                this.props.fetchUsers();
                if (this.props.clients.isEmpty()) {
                    this.props.fetchAllClients();
                }
                break;
            case ROLES.PM:
                // Only fetch plants if user is an admin
                if (isSysAdmin(this.props.user) && this.props.plants.isEmpty()) {
                    this.props.fetchAllPlants();
                }

                if (this.props.reagents.isEmpty()) {
                    this.props.fetchAllReagents();
                }
                break;
        }
    };

    /**
     * Handle re-fetching of users with provided searchCriteria & page
     */
    handleUsersReFetch = (searchCriteria: UserSearchCriteria, page: number) =>
        this.props.queryV2Users(
            {
                ...searchCriteria,
                role: this.props.role,
            },
            page
        );

    render() {
        const isAdmin = isSysAdmin(this.props.user);

        return (
            <LayoutContainer fullWidth>
                <UserManager
                    title={this.props.intl.formatMessage({
                        id: `containers.UserManagementContainer.titles.${this.props.role}`,
                    })}
                    activeRole={this.props.role}
                    allUsers={this.props.allUsers}
                    authUser={this.props.user}
                    clients={this.props.clients}
                    defaultPage={USER_DEFAULT_PAGE}
                    defaultSearchCriteria={defaultSearchCriteria}
                    errors={this.props.errors}
                    pageCount={this.props.pageCount}
                    plants={isAdmin && this.props.plants ? this.props.plants : undefined}
                    reagents={this.props.reagents}
                    reagentsAreFetching={this.props.reagentsAreFetching}
                    queriedUsers={this.props.queriedUsers}
                    createUser={isAdmin ? this.props.createUser : null}
                    updateUser={this.props.updateUser}
                    deleteUser={isAdmin ? this.props.deleteUser : null}
                    userIsCreating={this.props.userIsCreating}
                    userIsUpdating={this.props.userIsUpdating}
                    userIsDeleting={this.props.userIsDeleting}
                    usersAreQuerying={this.props.usersAreQuerying}
                    onUsersReFetch={this.handleUsersReFetch}
                    usersTotal={this.props.usersTotal}
                    withSearch={this.props.withSearch}
                    notificationTypeUsersStatusesAreFetching={
                        this.props.notificationTypeUsersStatusesAreFetching
                    }
                    notificationTypeUsersStatuses={this.props.notificationTypeUsersStatuses}
                />
            </LayoutContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allUsers: selectUsers(),
    clients: selectAllClients(),
    errors: selectErrors(),
    pageCount: selectTotalPageCount(),
    plants: selectAllActivePlants(),
    queriedUsers: selectQueriedUsers(),
    reagents: selectAllReagents(),
    reagentsAreFetching: selectReagentsAreFetching(),
    user: selectUser(),
    userIsCreating: selectUserIsCreating(),
    userIsUpdating: selectUserIsUpdating(),
    userIsDeleting: selectUserIsDeleting(),
    usersAreQuerying: selectUsersAreQuerying(),
    usersTotal: selectTotalCount(),
    withSearch: selectWithSearch(),
    notificationTypeUsersStatusesAreFetching: selectNotificationTypeUsersStatusesAreFetching(),
    notificationTypeUsersStatuses: selectNotificationTypeUsersStatuses(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createUser,
            deleteUser,
            fetchAllClients,
            fetchAllPlants,
            fetchAllReagents,
            fetchUsers,
            queryV2Users,
            updateUser,
            fetchNotificationTypeUsersStatuses,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(UserManagementContainer))
);
