// @flow

import React from 'react';
import { StyledInputValue as InputValue } from '../input-field';

// Types
import type { InputEventType, InputValueType, ReactNode } from '../../types';

// Components
import { RadioButton } from './RadioButton';

// Styles
import { StyledRadioButtonSet } from './styles';

type OptionType = {
    disabled?: boolean,
    icon?: string,
    label: string,
    value: InputValueType,
    radioButtonRef?: ?HTMLDivElement,
};
type OrientationType = 'HORIZONTAL' | 'VERTICAL';
type OverflowType = 'auto' | 'visible' | 'hidden';
type SuffixLocationType = 'BOTTOM' | 'RIGHT';

type Props = {
    disabled?: boolean,
    noOptionsBody?: ReactNode,
    onClick: (InputValueType) => void,
    options: Array<OptionType>,
    orientation?: OrientationType,
    renderString?: boolean,
    renderSuffix?: () => ReactNode,
    styles?: Object,
    suffixLocation?: SuffixLocationType,
    theme?: ?{},
    value?: string,
    wrapperOverflow?: OverflowType,
};

export class RadioButtonSet extends React.Component<Props> {
    static defaultProps = {
        disabled: false,
        noOptionsBody: null,
        orientation: 'VERTICAL',
        renderString: false,
        renderSuffix: null,
        styles: {},
        suffixLocation: 'BOTTOM',
        theme: null,
        value: '',
        wrapperOverflow: 'visible',
    };

    handleOnClick = (value: InputValueType) => (e: InputEventType) => {
        e.stopPropagation();
        this.props.onClick(value);
    };

    render() {
        const { options } = this.props;
        const currentlySelectedOption = options.find(
            (option: OptionType) => option.value === this.props.value
        );

        if (this.props.renderString && currentlySelectedOption) {
            return <InputValue>{currentlySelectedOption.label}</InputValue>;
        }

        const radioButtons = options.map((option: OptionType) => (
            <RadioButton
                key={option.value}
                radioButtonRef={option.radioButtonRef}
                active={option.value === this.props.value}
                disabled={this.props.disabled || option.disabled}
                label={option.label}
                onClickHandler={
                    this.props.disabled || option.disabled ? null : this.handleOnClick(option.value)
                }
                renderSuffix={this.props.renderSuffix}
                styles={this.props.styles}
                suffixLocation={this.props.suffixLocation}
                theme={this.props.theme}
                value={option.value}
                wrapperOverflow={this.props.wrapperOverflow}
            />
        ));

        return (
            <StyledRadioButtonSet orientation={this.props.orientation}>
                {radioButtons}
                {options.length === 0 ? this.props.noOptionsBody : null}
            </StyledRadioButtonSet>
        );
    }
}
