// @flow strict

import request, { cloudFunctionApiRequest } from 'services/request';

import type { GlobalUserPreferences, MinchemPreferences } from 'types';
import type { PhoneVerificationRequestId, PhoneCheckResponse } from './types';
import { APIs } from '../../apis';

export const WHOAMI_API_ENDPOINT = 'whoami';
export const LOGOUT_API_ENDPOINT = 'logout';

/**
 * GET Request which returns authenticated user
 */
export function getAuthenticatedUser(): Promise<User> {
    return cloudFunctionApiRequest(WHOAMI_API_ENDPOINT, {
        method: 'GET',
        apiUrl: APIs.platformAuthenticationApiCF,
    });
}

/**
 * Logs a user out by sending a request to invalidate the token
 */
export function logout(): Promise<boolean> {
    return cloudFunctionApiRequest(LOGOUT_API_ENDPOINT, {
        method: 'POST',
        apiUrl: APIs.platformAuthenticationApiCF,
    });
}

/**
 * User accepts disclaimer
 *
 * @param {number} id
 */
export function acceptDisclaimer(id: number): Promise<User> {
    return request('user/accept-disclaimer', {
        method: 'POST',
        data: {
            id,
        },
    });
}

/**
 * Get unaccepted disclaimers for the logged user
 * @returns {Promise<Array<Disclaimer>>}
 */
export function getUnacceptedDisclaimers(): Promise<Array<Disclaimer>> {
    return request('user/unaccepted-disclaimers', {
        method: 'GET',
    });
}

/**
 * Update Minchem preferences
 *
 * @param {number} id
 * @param {MinchemPreferences} minchemPreferences
 */
export const updateMinchemPreferencesRequest = (
    id: number,
    minchemPreferences: MinchemPreferences
): Promise<MinchemPreferences> => {
    // TODO: this should be removed once minchem preferences are no longer used in ms-backend! - for now, we need to keep making a request to the legacy api to ensure that the data used by the ms backend for circuit setup is up to date
    request(`user/${id}`, {
        data: minchemPreferences,
        method: 'PUT',
    });

    // make a request to the new api
    return cloudFunctionApiRequest(`minchem/users/${id}/preferences`, {
        data: minchemPreferences,
        method: 'PUT',
        apiUrl: APIs.minchemPreferencesCF,
    });
};

/**
 * Gets Minchem preferences
 *
 * @param {number} id user id
 */
export const getMinchemPreferencesRequest = (id: number): Promise<MinchemPreferences> => {
    return cloudFunctionApiRequest(`minchem/users/${id}/preferences`, {
        method: 'GET',
        apiUrl: APIs.minchemPreferencesCF,
    });
};

/**
 * Update user preferences
 *
 * @param {number} id
 * @param {GlobalUserPreferences} userPreferences
 */
export const updatePreferences = (
    id: number,
    userPreferences: GlobalUserPreferences
): Promise<GlobalUserPreferences> => {
    return cloudFunctionApiRequest(`user/preferences`, {
        data: userPreferences,
        method: 'PUT',
        apiUrl: APIs.userPreferencesCF,
    });
};

/**
 * Start phone verification
 *
 * @param {number} userID
 * @param {string} phoneNumber
 */
export const startPhoneVerification = (
    userId: number,
    phoneNumber: string
): Promise<PhoneVerificationRequestId> =>
    request(`user/${userId}/phone/verify/start`, {
        data: { phoneNumber },
        method: 'POST',
    });

/**
 * Re-send phone verification OTC
 *
 * @param {number} userId
 * @param {string} requestId
 */
export const resendPhoneVerificationOTC = (userId: number, requestId: string) =>
    request(`user/${userId}/phone/verify/resend`, {
        data: { requestId },
        method: 'POST',
    });

/**
 * Validate the OTC and write the user's phone number
 *
 * @param {number} userId
 * @param {string} requestId
 * @param {string} code
 */
export const checkPhoneVerification = (
    userId: number,
    requestId: string,
    otc: string
): Promise<PhoneCheckResponse> =>
    request(`user/${userId}/phone/verify/check`, {
        data: { requestId, code: otc },
        method: 'POST',
    });

export const getPhoneNumberRequest = () =>
    request(`user/phone-number`, {
        method: 'GET',
    });
