// @flow strict

import React from 'react';

import MinChemPageNav from '../MinChemPageNav/MinChemPageNav';
import SolvExtractPageNav from '../SolvExtractPageNav/SolvExtractPageNav';

import {
    APPLICATION_TYPES,
    MINCHEM_PAGES_WITH_NAVIGATION,
    SOLVEXTRACT_PAGES_WITH_NAVIGATION,
} from 'utils/constants';

import type { RouteType } from 'types';

type Props = {
    route: RouteType,
    currentLocation: string,
};

class PageNavigator extends React.PureComponent<Props> {
    getNavigatorType = () => {
        if (MINCHEM_PAGES_WITH_NAVIGATION.includes(this.props.route.name)) {
            return APPLICATION_TYPES.MINCHEM;
        }
        if (SOLVEXTRACT_PAGES_WITH_NAVIGATION.includes(this.props.route.name)) {
            return APPLICATION_TYPES.SOLVEXTRACT;
        }
        return null;
    };

    renderMinChemNavigator = () => {
        return (
            <MinChemPageNav currentLocation={this.props.currentLocation} route={this.props.route} />
        );
    };

    renderSolvExtractNavigator = () => {
        return (
            <SolvExtractPageNav
                currentLocation={this.props.currentLocation}
                route={this.props.route}
            />
        );
    };

    render() {
        switch (this.getNavigatorType()) {
            case APPLICATION_TYPES.MINCHEM:
                return this.renderMinChemNavigator();
            case APPLICATION_TYPES.SOLVEXTRACT:
                return this.renderSolvExtractNavigator();
            default:
                return null;
        }
    }
}

export default PageNavigator;
