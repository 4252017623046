// @flow strict

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { TankWrapper } from './styles';
import DiagramValueBox from '../DiagramValueBox';
import { streamCircuitColors } from 'styles/colors';

// Helpers
import { getValueTypeUnit } from 'utils/kpiHelpers';
import {
    DATASET_MODES,
    DIAGRAM_DISPLAY_MODES,
    VALUE_STATUS,
    DEFAULT_STAGE_VALUE_PRECISIONS,
} from 'utils/constants';

// Types
import type { IntlType, UnitsConstant } from 'types';
import type { DiagramDisplayModesConstant } from 'components/MimicDiagram/types';
import type { LooseStage } from 'services/Circuit/types';
import type {
    LooseStageValue,
    StageValuesConstant,
    DatasetModesConstant,
} from 'services/Dataset/types';
import type { SetStageValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    intl: IntlType,
    isSetupMode: boolean,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,
    circuitUnits: UnitsConstant,

    stageData: LooseStage,
    startingColumn: number,
    startingRow: number,

    setStageValue: SetStageValueFunction,
};

class DiagramOrganicTank extends React.PureComponent<Props> {
    setStageValue = (valueType: StageValuesConstant) => (newValue: number | null) => {
        this.props.setStageValue(this.props.stageData.id, valueType, newValue);
    };

    getUnits = (stageValue: LooseStageValue): string | null => {
        return getValueTypeUnit(stageValue.valueType, this.props.circuitUnits, this.props.intl);
    };

    getLabel = (stageValue: LooseStageValue): string => {
        const valueType = stageValue.valueType;
        return this.props.intl.formatMessage({
            id: `components.MimicDiagram.Stage.Values.${valueType}`,
        });
    };

    getPrecision = (stageValue: LooseStageValue): number => {
        const providedPrecision = stageValue.precision;
        if (providedPrecision === null || providedPrecision === undefined) {
            return DEFAULT_STAGE_VALUE_PRECISIONS[stageValue.valueType];
        }
        return providedPrecision;
    };

    renderStageValues = () => {
        const values = this.props.stageData.values;
        if (!values || values.length === 0) {
            // TODO: should we throw instead?
            return null;
        }
        return values.map((stageValue: LooseStageValue, idx: number) => (
            <DiagramValueBox
                key={stageValue.valueType}
                label={this.getLabel(stageValue)}
                units={this.getUnits(stageValue)}
                precision={this.getPrecision(stageValue)}
                value={stageValue.value}
                setValue={this.setStageValue(stageValue.valueType)}
                backgroundColor={streamCircuitColors.ORGANIC.streamValuesBackground}
                calculatorColor={streamCircuitColors.ORGANIC.main}
                hasBeenCalculated={this.props.displayMode === DIAGRAM_DISPLAY_MODES.COMPUTED}
                isCalculated={stageValue.status === VALUE_STATUS.COMPUTE}
                isMandatory={
                    this.props.datasetMode === DATASET_MODES.ANALYSIS_MODE &&
                    stageValue.status === VALUE_STATUS.COMPUTE_USING
                }
            />
        ));
    };

    render() {
        if (this.props.isSetupMode) {
            return null;
        }
        return this.renderStageValues();
    }
}

export default DiagramOrganicTank;
