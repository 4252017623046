// @flow strict

import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { combineReducers } from 'redux-immutable';

import languageProviderReducer from 'services/Language/reducer';

// Services
import authenticationServiceReducer from 'services/Authentication/reducer';
import circuitServiceReducer from 'services/Circuit/reducer';
import clientServiceReducer from 'services/Client/reducer';
import datasetServiceReducer from 'services/Dataset/reducer';
import disclaimerServiceReducer from 'services/Disclaimer/reducer';
import feedbackServiceReducer from 'services/Feedback/reducer';
import isothermServiceReducer from 'services/Isotherm/reducer';
import metalServiceReducer from 'services/Metal/reducer';
import oximeServiceReducer from 'services/Oxime/reducer';
import plantServiceReducer from 'services/Plant/reducer';
import reagentServiceReducer from 'services/Reagent/reducer';
import kpiSettingServiceReducer from 'services/KPISetting/reducer';
import recommendationServiceReducer from 'services/Recommendation/reducer';
import trendsServiceReducer from 'services/Trends/reducer';
import userServiceReducer from 'services/User/reducer';
import notificationsServiceReducer from 'services/Notifications/reducer';

// Types
import type { ImmutableMap, GenericActionType } from 'types';

// Initial routing state
const routeInitialState = fromJS({
    location: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(
    state: ImmutableMap<string, {}> = routeInitialState,
    action: GenericActionType
) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return state.merge({
                location: action.payload,
            });
        default:
            return state;
    }
}

const rootReducer = combineReducers({
    auth: authenticationServiceReducer,
    circuit: circuitServiceReducer,
    client: clientServiceReducer,
    dataset: datasetServiceReducer,
    disclaimers: disclaimerServiceReducer,
    feedback: feedbackServiceReducer,
    isotherm: isothermServiceReducer,
    language: languageProviderReducer,
    metal: metalServiceReducer,
    oxime: oximeServiceReducer,
    plant: plantServiceReducer,
    reagent: reagentServiceReducer,
    kpiSetting: kpiSettingServiceReducer,
    route: routeReducer,
    trends: trendsServiceReducer,
    users: userServiceReducer,
    recommendations: recommendationServiceReducer,
    notifications: notificationsServiceReducer,
});

export default rootReducer;
