// @flow strict

import { LegacyTheme } from 'components/_ReactUI_V1';

import type { KPIColorSchemeType } from 'types';

type Datum = {
    x: string,
    y: number,
    size?: number,
};

export const GraphStyles = (scheme: KPIColorSchemeType) => ({
    mainGraph: {
        parent: {
            overflow: 'visible',
            boxSizing: 'border-box',
            fontFamily: 'Helvetica',
        },
    },

    mainGraphPadding: {
        top: 34, // Keep even with bottom so the Y axis label inputs are aligned
        bottom: 34,
        left: 70,
        right: 70,
    },

    MainTargetLineStyles: {
        data: {
            stroke: scheme.targetLine,
            strokeWidth: '2px',
            opacity: 1,
        },
    },

    averageLineStyles: {
        data: {
            stroke: scheme.targetLine,
            strokeWidth: '2px',
            opacity: 1,
        },
    },

    averageDotColor: scheme.targetLine,

    TargetLineStyles: {
        data: {
            stroke: scheme.targetLine,
            strokeWidth: '2px',
            strokeDasharray: [5, 3],
            opacity: 1,
        },
    },

    AreaStyles: (hideChart: boolean) => ({
        data: {
            fill: hideChart ? 'none' : scheme.areaFill,
            fillOpacity: hideChart ? 'none' : 0.1,
            stroke: scheme.areaLine,
            strokeOpacity: 0.75,
            strokeWidth: 2,
        },
    }),

    ScatterStyles: {
        data: {
            opacity: (datum: Datum) => (datum.size ? 1 : 0),
            fill: (datum: Datum) => (datum.size ? scheme.areaFill : 'transparent'),
            strokeWidth: (datum: Datum) => (datum.size ? 2 : 0),
            stroke: (datum: Datum) => (datum.size ? scheme.areaLine : 'transparent'),
        },
    },

    axisY: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        ticks: {
            size: 30,
            strokeWidth: 1,
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            padding: 0,
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontFamily: 'inherit',
            fontSize: 12,
            padding: 40,
        },
        tickLabels: {
            fill: LegacyTheme.defaultSubtleColor,
            fontFamily: 'inherit',
            fontSize: 12,
            _padding: 5,
        },
    },

    axisX: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        tickLabels: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 12,
            fontFamily: 'inherit',
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 12,
            fontFamily: 'inherit',
            padding: 45,
        },
    },
});
