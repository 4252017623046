// @flow strict

/*
 *
 * Trends reducer
 *
 */

import { fromJS } from 'immutable';

import {
    IS_FETCHING_PLANT_TREND,
    FETCH_PLANT_TREND_SUCCESS,
    FETCH_PLANT_TREND_FAILURE,
    IS_FETCHING_CIRCUIT_TREND,
    FETCH_CIRCUIT_TREND_SUCCESS,
    FETCH_CIRCUIT_TREND_FAILURE,
    FETCH_KPI_HISTORY_SUCCESS,
    FETCH_KPI_HISTORY_FAILURE,
    IS_FETCHING_KPI_HISTORY,
    IS_FETCHING_PLANT_ARCHIVE,
    FETCH_PLANT_ARCHIVE_SUCCESS,
    FETCH_PLANT_ARCHIVE_FAILURE,
    FETCH_CIRCUIT_ARCHIVE_SUCCESS,
    FETCH_CIRCUIT_ARCHIVE_FAILURE,
    IS_FETCHING_CIRCUIT_ARCHIVE,
    IS_FETCHING_DATASET_VALUES,
    FETCH_DATASET_VALUES_SUCCESS,
    FETCH_DATASET_VALUES_FAILURE,
    CLEAR_MODAL_DATASET_VALUES,
} from './actions';
import {
    CREATE_OR_UPDATE_DATASET_VALUE_SUCCESS,
    CREATE_OR_UPDATE_DATASET_VALUE_FAILURE,
    IS_CREATING_OR_UPDATING_DATASET_VALUE,
    CREATE_OR_UPDATE_STREAM_VALUE_SUCCESS,
    CREATE_OR_UPDATE_STREAM_VALUE_FAILURE,
    IS_CREATING_OR_UPDATING_STREAM_VALUE,
    CREATE_OR_UPDATE_STAGE_VALUE_SUCCESS,
    CREATE_OR_UPDATE_STAGE_VALUE_FAILURE,
    IS_CREATING_OR_UPDATING_STAGE_VALUE,
} from 'services/Dataset/actions';
import {
    CREATE_OR_UPDATE_PLANT_VALUE_SUCCESS,
    CREATE_OR_UPDATE_PLANT_VALUE_FAILURE,
    IS_CREATING_OR_UPDATING_PLANT_VALUE,
} from 'services/PlantDataset/actions';
import { SUBMIT_FEEDBACK_SUCCESS as SUBMIT_RECOMMENDATION_FEEDBACK_SUCCESS } from '../Recommendation/actions';

import type { GenericActionType, ImmutableList } from 'types';
import type { ImmutableDataset, ImmutableDatasetValue } from 'services/Dataset/types';

import type {
    ImmutablePlantTrend,
    ImmutableCircuitTrend,
    ImmutableTrendsState,
    ImmutableKPIHistory,
} from 'services/Trends/types';

import { setValueInModalDatasetValuesAndTrends } from 'services/utils';

const initialState: ImmutableTrendsState = fromJS({
    plantTrends: [],
    plantArchive: [],
    plantKpiSettings: [], // TODO: Remove me
    plantArchiveQuery: {
        currentPage: 0,
        lastPage: 0,
    },

    circuitTrends: [],
    circuitArchive: [],
    circuitKpiSettings: [], // TODO: Remove me
    circuitArchiveQuery: {
        currentPage: 0,
        lastPage: 0,
    },

    kpiHistories: [],

    modalDatasetValues: {}, // TODO: Remove me?
    plantTimezone: '', // TODO: Remove me

    isFetchingKPIHistory: [],
    isFetchingCircuitArchive: false,
    isFetchingCircuitTrend: false,
    isFetchingPlantArchive: false,
    isFetchingPlantTrend: false,
    isFetchingDatasetValues: false,

    errors: {},

    /**
     * TODO: Refactor nested datasets and move to respective reducders
     */
    isCreatingOrUpdatingDatasetValue: false,
    isCreatingOrUpdatingStageValue: false,
    isCreatingOrUpdatingStreamValue: false,
    isCreatingOrUpdatingPlantValue: false,
});

function trendsServiceReducer(
    state: ImmutableTrendsState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case IS_FETCHING_PLANT_TREND:
            return state.set('isFetchingPlantTrend', action.payload.isFetchingPlantTrend);

        case IS_FETCHING_CIRCUIT_TREND:
            return state.set('isFetchingCircuitTrend', action.payload.isFetchingCircuitTrend);

        case IS_FETCHING_KPI_HISTORY: {
            const kpiSettingId = action.payload.kpiId;
            const period = action.payload.period;
            return state.updateIn(
                ['kpiHistories'],
                (kpiHistories: ImmutableList<ImmutableKPIHistory>) => {
                    const idx = kpiHistories.findIndex(
                        (kpiHistory: ImmutableKPIHistory) =>
                            kpiSettingId === kpiHistory.get('kpiSettingId')
                    );
                    return idx === -1
                        ? kpiHistories.unshift(
                              fromJS({
                                  kpiSettingId,
                                  period,
                                  isLoading: true,
                                  history: null,
                              })
                          )
                        : kpiHistories.updateIn([idx], (kpiHistory: ImmutableKPIHistory) =>
                              kpiHistory
                                  .set('isLoading', true)
                                  .set('period', period)
                                  .set('history', null)
                          );
                }
            );
        }

        case IS_FETCHING_DATASET_VALUES:
            return state.set('isFetchingDatasetValues', action.payload.isFetchingDatasetValues);

        case FETCH_PLANT_TREND_SUCCESS: {
            const plantTrend = action.payload.data;
            if (!plantTrend.initialHistory) plantTrend.initialHistory = {}; // DGM-1073: To handle null response and prevent crash

            const newPlantTrend = fromJS(plantTrend);
            const newInitialHistory = newPlantTrend.get('initialHistory').size ?
                newPlantTrend.get('initialHistory').set('isLoading', false) :
                newPlantTrend.get('initialHistory'); // DGM-1073: KPIGraph & Controls will not load since kpiHistory.size = 0

            return state
                .updateIn(['plantTrends'], (trends: ImmutableList<ImmutablePlantTrend>) => {
                    const idx = trends.findIndex(
                        (t: ImmutablePlantTrend) => t.get('id') === newPlantTrend.get('plantId')
                    );
                    return idx !== -1
                        ? trends.setIn([idx], newPlantTrend)
                        : trends.unshift(newPlantTrend);
                })
                .updateIn(['kpiHistories'], (kpiHistories: ImmutableList<ImmutableKPIHistory>) => {
                    const idx = kpiHistories.findIndex(
                        (kpiHistory: ImmutableKPIHistory) =>
                            kpiHistory.get('kpiSettingId') === newInitialHistory.get('kpiSettingId')
                    );
                    // DGM-1073: To update kpiHistories after empty history check
                    if (newInitialHistory.size) {
                        return (
                            idx !== -1 ?
                            kpiHistories.setIn([idx], newInitialHistory) :
                            kpiHistories.unshift(newInitialHistory)
                        );
                    } else {
                        return kpiHistories;
                    }
                })
                .set('errors', fromJS({}))
                .set('isFetchingPlantTrend', false);
        }

        case FETCH_CIRCUIT_TREND_SUCCESS: {
            const circuitTrend = action.payload.data;
            const newCircuitTrend = fromJS(circuitTrend);
            const newInitialHistory = newCircuitTrend.get('initialHistory').set('isLoading', false);

            return state
                .updateIn(['circuitTrends'], (trends: ImmutableList<ImmutableCircuitTrend>) => {
                    const idx = trends.findIndex(
                        (t: ImmutableCircuitTrend) =>
                            t.get('circuitId') === newCircuitTrend.get('circuitId')
                    );
                    return idx !== -1
                        ? trends.setIn([idx], newCircuitTrend)
                        : trends.unshift(newCircuitTrend);
                })
                .updateIn(['kpiHistories'], (kpiHistories: ImmutableList<ImmutableKPIHistory>) => {
                    const idx = kpiHistories.findIndex(
                        (kpiHistory: ImmutableKPIHistory) =>
                            kpiHistory.get('kpiSettingId') === newInitialHistory.get('kpiSettingId')
                    );
                    return idx !== -1
                        ? kpiHistories.setIn([idx], newInitialHistory)
                        : kpiHistories.unshift(newInitialHistory);
                })
                .set('errors', fromJS({}))
                .set('isFetchingCircuitTrend', false);
        }

        case FETCH_KPI_HISTORY_SUCCESS: {
            const newHistory = fromJS(action.payload.data).set('isLoading', false);
            return state
                .updateIn(['kpiHistories'], (kpiHistories: ImmutableList<ImmutableKPIHistory>) => {
                    const idx = kpiHistories.findIndex(
                        (kpiHistory: ImmutableKPIHistory) =>
                            kpiHistory.get('kpiSettingId') === newHistory.get('kpiSettingId')
                    );
                    return idx !== -1
                        ? kpiHistories.setIn([idx], newHistory)
                        : kpiHistories.unshift(newHistory);
                })
                .set('errors', fromJS({}))
                .set('isFetchingKPIHistory', false);
        }

        case FETCH_PLANT_TREND_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isFetchingPlantTrend', false);

        case FETCH_CIRCUIT_TREND_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isFetchingCircuitTrend', false);

        case FETCH_KPI_HISTORY_FAILURE:
            return state
                .set('isFetchingKPIHistory', fromJS([])) // Yikes?
                .set('errors', fromJS(action.payload.errors))
                .set('isFetchingKPIHistory', false);

        case CLEAR_MODAL_DATASET_VALUES: {
            if (state.get('modalDatasetValues').isEmpty()) {
                return state;
            }
            return state.set('modalDatasetValues', fromJS({}));
        }

        case FETCH_DATASET_VALUES_SUCCESS: {
            return state
                .set('modalDatasetValues', fromJS(action.payload.data))
                .set('errors', fromJS({}))
                .set('isFetchingDatasetValues', false);
        }

        case FETCH_DATASET_VALUES_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('modalDatasetValues', fromJS({})) // Remove data as it will show previous dataset upon failure
                .set('isFetchingDatasetValues', false);

        case IS_FETCHING_PLANT_ARCHIVE:
            return state.set('isFetchingPlantArchive', action.payload.isFetchingPlantArchive);
        case IS_FETCHING_CIRCUIT_ARCHIVE:
            return state.set('isFetchingCircuitArchive', action.payload.isFetchingCircuitArchive);

        case FETCH_PLANT_ARCHIVE_SUCCESS: {
            const { datasets, kpiSettings, timezone } = action.payload.data;

            const currentPage = fromJS(datasets).get('currentPage');
            const lastPage = fromJS(datasets).get('lastPage');
            const data = fromJS(datasets).get('data');

            return state
                .set(
                    'plantArchiveQuery',
                    fromJS({
                        currentPage,
                        lastPage,
                    })
                )
                .set('plantArchive', fromJS(data))
                .set('plantKpiSettings', fromJS(kpiSettings))
                .set('plantTimezone', timezone)
                .set('errors', fromJS({}))
                .set('isFetchingPlantArchive', false);
        }

        case SUBMIT_RECOMMENDATION_FEEDBACK_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            const datasetId = newRecommendationSet.get('datasetId');
            return state.updateIn(['circuitArchive'], (datasets: ImmutableList<ImmutableDataset>) =>
                datasets.map((dataset: ImmutableDataset) => {
                    if (dataset.get('id') !== datasetId) {
                        return dataset;
                    }
                    return dataset.set('recommendationSet', newRecommendationSet);
                })
            );
        }

        case FETCH_CIRCUIT_ARCHIVE_SUCCESS: {
            const { datasets, kpiSettings, circuit, timezone } = action.payload.data;

            const currentPage = fromJS(datasets).get('currentPage');
            const lastPage = fromJS(datasets).get('lastPage');
            const data = fromJS(datasets).get('data');

            return state
                .set(
                    'circuitArchiveQuery',
                    fromJS({
                        currentPage,
                        lastPage,
                    })
                )
                .set('circuitArchive', fromJS(data))
                .set('circuitKpiSettings', fromJS(kpiSettings))
                .set('plantTimezone', timezone)
                .set('circuit', fromJS(circuit))
                .set('errors', fromJS({}))
                .set('isFetchingCircuitArchive', false);
        }

        case FETCH_PLANT_ARCHIVE_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isFetchingPlantArchive', false);

        /**
         * Dataset Actions
         */

        case CREATE_OR_UPDATE_DATASET_VALUE_SUCCESS: {
            return setValueInModalDatasetValuesAndTrends(
                state,
                'datasetValues',
                'isCreatingOrUpdatingDatasetValue',
                action.payload.data
            );
        }
        case CREATE_OR_UPDATE_DATASET_VALUE_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isCreatingOrUpdatingDatasetValue', false);
        }
        case IS_CREATING_OR_UPDATING_DATASET_VALUE:
            return state.set(
                'isCreatingOrUpdatingDatasetValue',
                action.payload.isCreatingOrUpdatingDatasetValue
            );

        case CREATE_OR_UPDATE_STREAM_VALUE_SUCCESS: {
            return setValueInModalDatasetValuesAndTrends(
                state,
                'streamValues',
                'isCreatingOrUpdatingStreamValue',
                action.payload.data
            );
        }
        case CREATE_OR_UPDATE_STREAM_VALUE_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isCreatingOrUpdatingStreamValue', false);
        }
        case IS_CREATING_OR_UPDATING_STREAM_VALUE:
            return state.set(
                'isCreatingOrUpdatingStreamValue',
                action.payload.isCreatingOrUpdatingStreamValue
            );

        case CREATE_OR_UPDATE_STAGE_VALUE_SUCCESS: {
            return setValueInModalDatasetValuesAndTrends(
                state,
                'stageValues',
                'isCreatingOrUpdatingStageValue',
                action.payload.data
            );
        }
        case CREATE_OR_UPDATE_STAGE_VALUE_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isCreatingOrUpdatingStageValue', false);
        }
        case IS_CREATING_OR_UPDATING_STAGE_VALUE:
            return state.set(
                'isCreatingOrUpdatingStageValue',
                action.payload.isCreatingOrUpdatingStageValue
            );

        case CREATE_OR_UPDATE_PLANT_VALUE_SUCCESS: {
            return setValueInModalDatasetValuesAndTrends(
                state,
                'plantValues',
                'isCreatingOrUpdatingPlantValue',
                action.payload.data
            );
        }
        case CREATE_OR_UPDATE_PLANT_VALUE_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isCreatingOrUpdatingPlantValue', false);
        }
        case IS_CREATING_OR_UPDATING_PLANT_VALUE:
            return state.set(
                'isCreatingOrUpdatingPlantValue',
                action.payload.isCreatingOrUpdatingPlantValue
            );

        default:
            return state;
    }
}

export default trendsServiceReducer;
