// @flow strict

import React from 'react';

import { StyledSVG } from './styles';
import { colors } from 'styles/colors';

export default function CascadeArrow(props?: {
    fill?: string,
    margin?: string,
    width?: number,
    height?: number,
    strokeWidth?: number,
    direction?: 'RIGHT' | 'LEFT',
}) {
    const styles = {
        margin: props.margin,
    };

    return (
        <StyledSVG
            viewBox="0 0 21 10"
            width={`${props.width}px`}
            height={`${props.height}px`}
            style={styles}
        >
            <g
                stroke="none"
                strokeWidth={props.strokeWidth}
                fill="none"
                fillRule="evenodd"
                transform={`rotate(${props.direction === 'RIGHT' ? 180 : 0} ${props.width /
                    2} ${props.height / 2})`}
            >
                <g
                    transform="translate(-626.000000, -402.000000)"
                    fill={props.fill}
                    fillRule="nonzero"
                >
                    <g transform="translate(550.000000, 218.000000)">
                        <g transform="translate(16.000000, 174.000000)">
                            <path d="M69,14.75 L80.9260044,14.75 L80.9260044,15.75 L69,15.75 L69,19.75 L60,15.25 L69,10.75 L69,14.75 Z" />
                        </g>
                    </g>
                </g>
            </g>
        </StyledSVG>
    );
}

CascadeArrow.defaultProps = {
    fill: colors.purple94,
    height: 10,
    margin: 'auto',
    strokeWidth: 1,
    width: 21,
};
