// @flow

/**
 * Each component references the theme object which means edits are needed.
 * In order to do this we need to ensure we have all of the components up to date before proceeding.
 *
 * i.e import theme from 'react-ui_base'; -> import { theme } from '../../src';
 */

/**
 * Themes
 */
export { default as DefaultTheme } from './themes/defaultTheme';
export { default as AlternativeTheme } from './themes/alternativeTheme';
export { default as LegacyTheme } from './themes/legacyTheme';

/**
 * Icons
 */
export {
    CalculatorIcon,
    Caret,
    Checkmark,
    Close,
    Dot,
    FloppyDiskIcon,
    InfoIcon,
    NavigationChevron,
    Pencil,
    Trash,
    Triangle,
    GearIcon,
    NotificationBell,
    PlusDotIcon,
} from './icons';
export { LoadingDots } from './icons/LoadingDots';
export { ProfileIcon } from './icons/ProfileIcon';
export { GridLayoutIcon } from './icons/GridLayoutIcon';
export { ListLayoutIcon } from './icons/ListLayoutIcon';
export { TrendArrowIcon } from './icons/TrendArrowIcon';

/**
 * Base Styled Components
 */
export * as Common from './base';
export {
    BlankButton,
    Centered,
    Column,
    OverflowContainer,
    OverflowBody,
    OverflowEnd,
    Row,
} from './base';

/**
 * Components
 */

export { default as ButtonHover } from './components/button-hover';
export { default as DotMenu } from './components/dot-menu';
export { default as MessageBlock } from './components/message-block';
export { SubtleAnchor, SubtleLink } from './components/subtle-link';
export { default as Hr } from './components/hr';
export { ButtonGrid } from './components/button-grid';
export {
    StyledPrimaryButton,
    StyledSecondaryButton,
    StyledTertiaryButton,
    StyledInverseButton,
    StyledWarningButton,
} from './components/buttons/styles';
export {
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
    InverseButton,
    WarningButton,
    ButtonWrapper,
} from './components/buttons';
export { CheckBox } from './components/check-box';
export { CheckBoxV2 } from './components/check-box/v2';
export { default as CollapsibleBlock } from './components/collapsible-block';
export { default as Graph2DPlot } from './components/graph-2dplot';
export { default as GraphRadar } from './components/graph-radar';
export { default as InputField } from './components/input-field';
export { InputFile, InputFileSet } from './components/input-file';
export { default as InputMultiple } from './components/input-multiple';
export { default as InputNumber } from './components/input-number';
export { default as InputSearch } from './components/input-search';
export { default as InputSelect } from './components/input-select';
export { Loader } from './components/loader';
export { default as Modal } from './components/modal';
export { default as Pagination } from './components/pagination';
export { RadioButton, RadioButtonSet } from './components/radio-button';
export { default as Slider } from './components/slider';
export { TabNav } from './components/tab-nav';
export { default as NavDropdown } from './components/nav-dropdown';
export { default as Table } from './components/table';
export { default as TextField } from './components/textfield';
export {
    TextFieldRich,
    TextFieldRichGlobalStyles,
    editorStateFromHtml,
    htmlFromEditorState,
} from './components/textfield-rich';
export { default as Toggle } from './components/toggle';
export { ToolTip, ToolTipTrigger, ToolTipGlobalStyles } from './components/tool-tip';
export { default as RemovableLabel } from './components/removable-label';
export { default as TagList } from './components/tag-list';

/**
 * Layouts
 */

export { SidebarLayout, SidebarFooter, SidebarSection, SidebarTab } from './layouts/layout-sidebar';
