// @flow strict

import React from 'react';

// Utils
import { Prompt } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

// Components
import PreventDataLossModal from 'components/Modals/PreventDataLossModal';

// Types
import type { IntlType } from 'types';

type Props = {
    shouldBlock: boolean,
};

type State = {
    open: boolean,
    callback: ?(allowLeave: boolean) => void,
};

// This file is heavily based on:
// https://kamranicus.com/posts/2018-07-26-react-router-custom-transition-ui
export default class PreventNavigationPrompt extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.__triggerId = Symbol.for(`__PreventNavigationPrompt_${uuid()}`);

        this.state = {
            open: false,
            callback: null,
        };
    }

    /**
     * Attach global dialog trigger for this component
     * instance to our Symbol trigger
     */
    componentDidMount() {
        window[this.__triggerId] = this.show;
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.shouldBlock && !this.props.shouldBlock && this.state.open) {
            this.handleLeave();
        }
    }

    /**
     * Ensure we clean up and remove the reference
     * from the global object
     */
    componentWillUnmount() {
        delete window[this.__triggerId];
    }

    __triggerId = null;

    /**
     * Show the dialog. Invoked primarily from React Router transition
     * handler getUserConfirmation.
     *
     * @param allowTransitionCallback A function that accepts a flag whether or not to allow the route transition
     */
    show = (allowTransitionCallback: (allow: boolean) => void) => {
        // we are immediately preventing any transitions here
        // but could just as easily base this off a user interaction
        // or other state
        this.setState({ open: true, callback: allowTransitionCallback });
    };

    /**
     * Closes the dialog
     */
    handleLeave = () => {
        const callback = this.state.callback;
        this.setState({ open: false, callback: null }, () => callback && callback(true));
    };

    /**
     * Closes the dialog
     */
    handleClose = () => {
        const callback = this.state.callback;
        this.setState({ open: false, callback: null }, () => callback && callback(false));
    };

    /**
     * Handles the Router transition. Returns true if allowed
     * or the dialog trigger key to enable the dialog.
     *
     * This would be a good candidate to allow optionally
     * being passed as a callback prop to let
     * caller decide if transition is allowed.
     */
    getSymbolKey = () => {
        if (!this.__triggerId) {
            return true;
        }
        return Symbol.keyFor(this.__triggerId);
    };

    render() {
        return (
            <React.Fragment>
                <Prompt when={this.props.shouldBlock} message={this.getSymbolKey} />

                {this.state.open && (
                    <PreventDataLossModal onLeave={this.handleLeave} onCancel={this.handleClose} />
                )}
            </React.Fragment>
        );
    }
}
