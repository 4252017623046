// @flow strict

import { fromJS } from 'immutable';

// Actions
import {
    ROLES_FETCH_SUCCESS,
    ROLES_FETCH_FAILURE,
    USER_CREATE_SUCCESS,
    USER_CREATE_FAILURE,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAILURE,
    USER_IS_CREATING,
    USER_IS_UPDATING,
    USER_IS_DELETING,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILURE,
    USERS_ARE_FETCHING,
    USERS_ARE_QUERYING,
    USERS_FETCH_FAILURE,
    USERS_FETCH_SUCCESS,
    USERS_QUERY_FAILURE,
    USERS_QUERY_SUCCESS,
} from './actions';

// Utils
import { deleteEntityFromSubStateLists, setEntityIntoSubStateLists } from '../utils';

// Types
import type { GenericActionType, ImmutableList } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableUserState } from 'services/User/types';

const initialState: ImmutableUserState = fromJS({
    errors: {},
    lastPage: 0,
    queriedUsers: [],
    roles: [],
    totalCount: 0,
    usersLeft: 0, // Denotes the amount of users left in the backend for the current query
    userIsCreating: false,
    userIsUpdating: false,
    userIsDeleting: false,
    allUsers: [],
    usersAreFetching: false,
    usersAreQuerying: false,
    withSearch: false,
    query: null,
});

function userServiceReducer(state: ImmutableUserState = initialState, action: GenericActionType) {
    switch (action.type) {
        case USER_IS_CREATING: {
            return state.set('userIsCreating', action.payload.userIsCreating);
        }
        case USER_IS_UPDATING: {
            return state.set('userIsUpdating', action.payload.userIsUpdating);
        }
        case USER_IS_DELETING: {
            return state.set('userIsDeleting', action.payload.userIsDeleting);
        }
        case USERS_ARE_FETCHING: {
            return state.set('usersAreFetching', action.payload.usersAreFetching);
        }
        case USERS_ARE_QUERYING: {
            return state.set('usersAreQuerying', action.payload.usersAreQuerying);
        }

        case USERS_QUERY_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('query', fromJS(data))
                .set('queriedUsers', fromJS(data.data))
                .set('totalCount', data.total)
                .set('usersLeft', data.total - data.to)
                .set('lastPage', data.lastPage)
                .set('withSearch', data.withSearch)
                .set('usersAreQuerying', false)
                .set('errors', fromJS({}));
        }

        case USERS_QUERY_FAILURE:
            return state
                .set('usersAreQuerying', false)
                .set('errors', fromJS(action.payload.errors));

        case USERS_FETCH_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('allUsers', fromJS(data))
                .set('usersAreFetching', false)
                .set('errors', fromJS({}));
        }

        case USERS_FETCH_FAILURE:
            return state
                .set('usersAreFetching', false)
                .set('errors', fromJS(action.payload.errors));

        case ROLES_FETCH_SUCCESS: {
            return state.set('roles', fromJS(action.payload.data)).set('errors', fromJS({}));
        }

        case ROLES_FETCH_FAILURE:
            return state
                .set('usersAreFetching', false)
                .set('errors', fromJS(action.payload.errors));

        case USER_UPDATE_SUCCESS: {
            const user = fromJS(action.payload.data);
            return state
                .updateIn(['allUsers'], (entities: ImmutableList<ImmutableUser>) => {
                    const idx = entities.findIndex(
                        (e: ImmutableUser) => e.get('id') === user.get('id')
                    );
                    return idx !== -1 ? entities.setIn([idx], user) : entities.unshift(user);
                })
                .updateIn(['queriedUsers'], (entities: ImmutableList<ImmutableUser>) => {
                    const idx = entities.findIndex(
                        (e: ImmutableUser) => e.get('id') === user.get('id')
                    );
                    return idx !== -1 ? entities.setIn([idx], user) : entities.unshift(user);
                })
                .set('userIsUpdating', false)
                .set('errors', fromJS({}));
        }

        case USER_UPDATE_FAILURE:
            return state.set('userIsUpdating', false).set('errors', fromJS(action.payload.errors));

        case USER_DELETE_SUCCESS: {
            const userId = action.payload.data;
            return state
                .updateIn(['allUsers'], (entities: ImmutableList<ImmutableUser>) => {
                    const idx = entities.findIndex(
                        (e: ImmutableUser) => e.get('id') === parseInt(userId, 10)
                    );
                    return idx !== -1 ? entities.delete(idx) : entities;
                })
                .updateIn(['queriedUsers'], (entities: ImmutableList<ImmutableUser>) => {
                    const idx = entities.findIndex(
                        (e: ImmutableUser) => e.get('id') === parseInt(userId, 10)
                    );
                    return idx !== -1 ? entities.delete(idx) : entities;
                })
                .set('userIsDeleting', false)
                .set('errors', fromJS({}));
        }

        case USER_DELETE_FAILURE:
            return state.set('userIsDeleting', false).set('errors', fromJS(action.payload.errors));

        case USER_CREATE_SUCCESS: {
            const user = fromJS(action.payload.data);
            return state
                .set('userIsCreating', false)
                .set('usersAreFetching', false)
                .updateIn(['allUsers'], (entities: ImmutableList<ImmutableUser>) => {
                    const idx = entities.findIndex(
                        (e: ImmutableUser) => e.get('id') === user.get('id')
                    );
                    return idx !== -1 ? entities.setIn([idx], user) : entities.unshift(user);
                })
                .updateIn(['queriedUsers'], (entities: ImmutableList<ImmutableUser>) => {
                    const idx = entities.findIndex(
                        (e: ImmutableUser) => e.get('id') === user.get('id')
                    );
                    return idx !== -1 ? entities.setIn([idx], user) : entities.unshift(user);
                })
                .set('errors', fromJS({}));
        }

        case USER_CREATE_FAILURE:
            return state
                .set('userIsCreating', false)
                .set('usersAreFetching', false)
                .set('errors', fromJS(action.payload.errors));

        default:
            return state;
    }
}

export default userServiceReducer;
