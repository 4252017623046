// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { isEqual } from 'lodash';

// Components
import {
    ButtonWrapper,
    Common,
    DotMenu,
    InfoIcon,
    InputField,
    InputSearch,
    Pagination,
    PrimaryButton,
    SecondaryButton,
    Table,
    TagList,
    Toggle,
    ToolTip,
    NotificationBell,
} from 'components/_ReactUI_V1';

import ConfirmationModal from 'components/Modals/ConfirmationModal';
import AddItemToListModal from 'components/AddItemToListModal';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';

// Constants
import { NO_RESULT_STRING, ROLES } from 'utils/constants';

// Helpers
import { getCurrentTimeZone, getFormattedDateFromString } from 'utils/dateHelpers';
import { getUsersLanguage, isSuperPMUser } from 'utils/authentication';

// Styles
import {
    ActivityStatus,
    ActivityToolTipBottom,
    ActivityToolTipTop,
    ActivityToolTipWrapper,
    ActivityTrigger,
    BlankButton,
    StatusDot,
    StatusList,
    UserTableContainer,
    ToolTipContent,
    UserTableHeader,
    NotificationsWrapper,
    NotificationsText,
} from './styles';
import { Count, Title } from 'styles/common';

// Types
import type {
    ErrorType,
    ImmutableList,
    InputEvent,
    IntlType,
    ReactSelectObject,
    RoleConstant,
} from 'types';
import type { ImmutableUser, ImmutableUserUsers } from 'services/Authentication/types';
import type { UserSearchCriteria } from 'services/User/types';
import type { ImmutableReagent, ImmutableReagentAccess } from 'services/Reagent/types';
import type { ImmutableClient, ImmutableUserClient } from 'services/Client/types';
import type { ImmutablePlant, ImmutableUserPlant } from 'services/Plant/types';
import type { ImmutableNotificationTypeUsersStatuses } from 'services/Notifications/types';

const DATE_FORMAT = {
    minute: 'numeric',
    hour: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
};

type Props = {
    allUsers: ImmutableList<ImmutableUser>,
    activeRole: string,
    authUser: ImmutableUser,
    createUser: ?(?ImmutableUser) => void,
    clients?: ImmutableList<ImmutableClient>,
    defaultPage: number,
    defaultSearchCriteria: UserSearchCriteria,
    deleteUser: ?(email: string) => void,
    errors: ErrorType,
    intl: IntlType,
    onUsersReFetch: (searchCriteria: UserSearchCriteria, page: number) => void,
    pageCount: number,
    plants?: ImmutableList<ImmutablePlant>,
    queriedUsers: ImmutableList<ImmutableUser>,
    reagents: ImmutableList<ImmutableReagent>,
    reagentsAreFetching: boolean,
    title: string,
    updateUser: (ImmutableUser) => void,
    userIsCreating: boolean,
    userIsUpdating: boolean,
    userIsDeleting: boolean,
    usersAreQuerying: boolean,
    usersTotal: number,
    withSearch: boolean,
    notificationTypeUsersStatusesAreFetching: boolean,
    notificationTypeUsersStatuses: ImmutableNotificationTypeUsersStatuses,
};

type State = {
    activeRow?: ImmutableUser,
    disabledInputs: boolean,
    showAssignClientModal: boolean,
    showAssignReagentModal: boolean,
    showAssignPlantModal: boolean,
    showAssignPMUserModal: boolean,
    enableAddRow: boolean,
    pagination: { page: number },
    rowToDelete?: ImmutableUser,
    searchCriteria: UserSearchCriteria,
    isModified: boolean,
};

class UserManager extends React.Component<Props, State> {
    static defaultProps = {
        clients: undefined,
        plants: undefined,
    };

    state = {
        activeRow: undefined,
        disabledInputs: false,
        enableAddRow: true,
        pagination: {
            page: this.props.defaultPage,
        },
        searchCriteria: this.props.defaultSearchCriteria,
        rowToDelete: undefined,
        showAssignReagentModal: false,
        showAssignClientModal: false,
        showAssignPlantModal: false,
        showAssignPMUserModal: false,
        isModified: false,
    };

    /**
     * On componentDidUpdate check if props.users has changed, if so reset UI via state
     * If props.errors have changed, re-enable inputs to allow user to continue
     */
    componentDidUpdate(prevProps: Props) {
        const { errors, queriedUsers } = this.props;
        if (!isEqual(prevProps.queriedUsers, queriedUsers)) {
            this.setState({
                activeRow: undefined,
                disabledInputs: false,
                enableAddRow: true,
                rowToDelete: undefined,
                isModified: false,
            });
        } else if (prevProps.errors && !isEqual(prevProps.errors, errors)) {
            this.setState({
                disabledInputs: false,
            });
        }
    }

    validateADMINView = () =>
        Boolean(this.props.activeRole && this.props.activeRole.toUpperCase() === ROLES.ADMIN);

    validatePMView = () =>
        Boolean(this.props.activeRole && this.props.activeRole.toUpperCase() === ROLES.PM);

    validateSAMView = () =>
        Boolean(this.props.activeRole && this.props.activeRole.toUpperCase() === ROLES.SAM);

    /**
     * On search clear, reset searchCriteria.search & pagination.page
     * Fire props.onUsersReFetch() with updated searchCriteria and page
     */
    onSearchClear = () => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    search: '',
                },
                pagination: {
                    page: this.props.defaultPage,
                },
            }),
            () => this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * On Click, set oximeIdToDelete in state for this.renderConditionalDeleteModal()
     */
    handleSetRowToDelete = (user?: ImmutableUser) => () => {
        this.setState({
            rowToDelete: user || undefined,
        });
    };

    handleInactiveUsersOnly = () => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    inactiveUsersOnly: !prevState.searchCriteria.inactiveUsersOnly,
                },
                pagination: {
                    page: this.props.defaultPage,
                },
            }),
            () => this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * Set's event's value to provided name to state.activeRow
     */
    handleActiveRowChange = (name: string) => (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => ({
            activeRow: prevState.activeRow && prevState.activeRow.set(name, value),
            disabledInputs: false,
            isModified: true,
        }));
    };

    /**
     * On keypress, check for key === 'Enter' if so fire this.onSearchSubmit()
     */
    handleKeyPress = (event: InputEvent) => event && event.key === 'Enter' && this.onSearchSubmit();

    /**
     * On pagination change, update state with provided page
     * Fire props.onUsersReFetch() with updated searchCriteria and page
     */
    handlePaginationPageSelection = (page: number, total: number) => () => {
        if (page >= total && page < 0) {
            return null;
        }

        return this.setState(
            {
                pagination: {
                    page,
                },
            },
            () => this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * On search change set state.search with event's value
     */
    handleSearchOnChange = (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => ({
            searchCriteria: {
                ...prevState.searchCriteria,
                search: value,
            },
        }));
    };

    /**
     * On table sorting, set provided sortBy & sortOrder to state.searchCriteria
     * Reset state.pagination.page to default
     * Fire props.onUsersReFetch() with updated searchCriteria and page
     */
    handleSortBy = (criteria: UserSearchCriteria) => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    ...criteria,
                },
                pagination: {
                    page: this.props.defaultPage,
                },
            }),
            () => this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * On user creation, insert "empty" user to list of users & set as current user
     * Set enableAddRow to false to disable [Add User] button
     */
    handleUserCreate = () =>
        this.setState({
            activeRow: fromJS({
                id: null,
                email: null,
                roles: [this.props.activeRole],
                clients: [],
                directPlantAccesses: [],
                directReagentAccesses: [],
                users: [],
            }),
            enableAddRow: false, // Disable [Add User] button
        });

    /**
     * On user edit, set provided user as current user
     */
    handleUserEditable = (user: ImmutableUser) => () => {
        if (user) {
            this.setState({
                activeRow: user,
                enableAddRow: false,
            });
        }
    };

    /**
     * On user save, disable inputs for further editing
     * If state.activeRow has an id (assume update) and fire props.updateUser()
     * Else (assume create) reset search & pagination in state
     * On setState callback, fire props.createUser() & props.onUsersReFetch() with updated state on callback
     */
    handleUserSave = () => {
        this.setState(
            {
                disabledInputs: true, // Disable inputs to avoid unexpected duplicate requests
            },
            () => {
                const { activeRow } = this.state;
                if (activeRow && activeRow.get('id')) {
                    this.props.updateUser(activeRow.toJS());
                } else {
                    this.setState(
                        (prevState: State) => ({
                            searchCriteria: {
                                ...prevState.searchCriteria,
                                ...this.props.defaultSearchCriteria,
                            },
                            pagination: {
                                page: this.props.defaultPage,
                            },
                        }),
                        () =>
                            this.props.createUser &&
                            this.props.createUser(
                                this.state.activeRow && this.state.activeRow.toJS()
                            )
                    );
                }
            }
        );
    };

    /**
     * On user cancel (within user edit) update sate
     * If id is not provided (assume unsaved user) and delete first user from list
     * Else (assume saved user), nullify state.activeRow
     * Set enableAddRow to true to re-allow [Add User]
     */
    handleUserCancel = () =>
        this.setState({
            enableAddRow: true,
            activeRow: undefined,
            isModified: false,
        });

    handleAssignReagentModalVisibility = () => () =>
        this.setState((prevState: State) => ({
            showAssignReagentModal: !prevState.showAssignReagentModal,
        }));

    handleAssignClientModalVisibility = () => () =>
        this.setState((prevState: State) => ({
            showAssignClientModal: !prevState.showAssignClientModal,
        }));

    handleAssignPlantModalVisibility = () => () =>
        this.setState((prevState: State) => ({
            showAssignPlantModal: !prevState.showAssignPlantModal,
        }));

    handleAssignPMUserModelVisibility = () => () =>
        this.setState((prevState: State) => ({
            showAssignPMUserModal: !prevState.showAssignPMUserModal,
        }));

    /**
     * On user delete fire props.deleteUser()
     */
    handleUserDelete = (userEmail: string) => () => {
        if (userEmail && this.props.deleteUser) {
            this.props.deleteUser(userEmail);
        }
    };

    /**
     * When the user toggles the Super PM field for the active row.
     */
    handleIsSuperPMToggle = (isChecked: boolean) => {
        if (!this.state.activeRow) {
            return;
        }
        this.setState((prevState: State) => {
            const activeRow = prevState.activeRow;
            if (!activeRow) {
                return;
            }
            let currentRoles = activeRow.get('roles');
            if (isChecked && !currentRoles.includes(ROLES.SPM)) {
                currentRoles = currentRoles.push(ROLES.SPM);
            } else if (!isChecked && currentRoles.includes(ROLES.SPM)) {
                currentRoles = currentRoles.filter((role: RoleConstant) => role !== ROLES.SPM);
            }
            return {
                activeRow: activeRow.set('roles', currentRoles),
            };
        });
    };

    /**
     * Handles addition of reagent to active row's reagents list
     * Prevents duplicate reagents
     */
    handleAddReagentToActiveRow = (selectedObject: ReactSelectObject) =>
        this.setState((prevState: State) => {
            // If active row does not have a list of reagents, create one to push into
            const hasReagents =
                prevState.activeRow && prevState.activeRow.has('directReagentAccesses');
            const newActiveRow = !hasReagents
                ? prevState.activeRow &&
                  prevState.activeRow.set('directReagentAccesses', fromJS([]))
                : prevState.activeRow;
            const reagentIndex =
                hasReagents &&
                prevState.activeRow
                    .get('directReagentAccesses')
                    .findIndex(
                        (reagent: ImmutableReagentAccess) =>
                            reagent.get('reagentId') === selectedObject.value
                    );
            // Check for reagent's index in list of reagents, return early to avoid duplicate
            if (reagentIndex !== -1) {
                throw new Error('Active row already has assigned reagent, something went wrong.');
            }

            const newActiveRowWithReagent =
                newActiveRow &&
                newActiveRow.update(
                    'directReagentAccesses',
                    (reagents: ImmutableList<ImmutableReagentAccess>) =>
                        reagents.push(
                            fromJS({
                                reagentId: selectedObject.value,
                                reagentName: selectedObject.label,
                            })
                        )
                );
            return {
                showAssignReagentModal: false,
                activeRow: newActiveRowWithReagent,
            };
        });

    /**
     * Handles addition of a client to active row's client list
     * Prevents duplicate clients
     */
    handleAssignClientToActiveRow = (selectedObject: ReactSelectObject) =>
        this.setState((prevState: State) => {
            const idx =
                prevState.activeRow &&
                prevState.activeRow
                    .get('clients')
                    .findIndex(
                        (client: ImmutableUserClient) =>
                            Number(client.get('clientId')) === Number(selectedObject.value)
                    );
            if (idx !== -1) {
                throw new Error('Active row already has assigned client, something went wrong.');
            }

            const newActiveRowWithClients =
                prevState.activeRow &&
                prevState.activeRow.update(
                    'clients',
                    (clients: ImmutableList<ImmutableUserClient>) =>
                        clients.push(
                            fromJS({
                                clientId: selectedObject.value,
                                name: selectedObject.label,
                            })
                        )
                );

            return {
                showAssignClientModal: false,
                activeRow: newActiveRowWithClients,
                isModified: true,
            };
        });

    /**
     * Handles addition of a plant to active row's plant list
     * Prevents duplicate plants
     */
    handleAssignPlantToActiveRow = (selectedObject: ReactSelectObject) =>
        this.setState((prevState: State) => {
            const idx =
                prevState.activeRow &&
                prevState.activeRow
                    .get('directPlantAccesses')
                    .findIndex(
                        (plant: ImmutableUserPlant) =>
                            Number(plant.get('plantId')) === Number(selectedObject.value)
                    );
            if (idx !== -1) {
                throw new Error('Active row already has assigned plant, something went wrong.');
            }

            const newActiveRowWithPlants =
                prevState.activeRow &&
                prevState.activeRow.update(
                    'directPlantAccesses',
                    (plants: ImmutableList<ImmutableUserPlant>) =>
                        plants.push(
                            fromJS({
                                plantId: selectedObject.value,
                                plantName: selectedObject.label,
                            })
                        )
                );

            return {
                showAssignPlantModal: false,
                activeRow: newActiveRowWithPlants,
                isModified: true,
            };
        });

    /**
     * Handles addition of pm user to active row's users list
     * Prevents duplicate users
     */
    handleAssignPMUserToActiveRow = (selectedObject: ReactSelectObject) =>
        this.setState((prevState: State) => {
            // If active row does not have a list of users, create one to push into
            const hasUsers = prevState.activeRow && prevState.activeRow.has('users');
            const newActiveRow = !hasUsers
                ? prevState.activeRow && prevState.activeRow.set('users', fromJS([]))
                : prevState.activeRow;
            const userIndex =
                hasUsers &&
                prevState.activeRow &&
                prevState.activeRow
                    .get('users')
                    .findIndex(
                        (users: ImmutableUserUsers) =>
                            Number(users.get('pmId')) === Number(selectedObject.value)
                    );
            // Check for user's index in list of users, return early to avoid duplicate
            if (userIndex !== -1) {
                throw new Error('Active row already has assigned pm, something went wrong.');
            }

            const newActiveRowWithPMUser = newActiveRow.update(
                'users',
                (users: ImmutableList<ImmutableUserUsers>) =>
                    users.push(
                        fromJS({
                            pmId: selectedObject.value,
                            pm: {
                                id: selectedObject.value,
                                email: selectedObject.label,
                            },
                        })
                    )
            );
            return {
                showAssignPMUserModal: false,
                activeRow: newActiveRowWithPMUser,
                isModified: true,
            };
        });

    /**
     * Handles unassignment of client from active row's clients list
     */
    handleUnassignClientFromActiveRow = (id: number) => () =>
        this.setState((prevState: State) => {
            const idx =
                prevState.activeRow &&
                prevState.activeRow
                    .get('clients')
                    .findIndex((client: ImmutableUserClient) => client.get('clientId') === id);
            if (idx === -1) {
                throw new Error('Active row is not assigned to client, something went wrong.');
            }

            return {
                activeRow: prevState.activeRow && prevState.activeRow.deleteIn(['clients', idx]),
                isModified: true,
            };
        });

    /**
     * Handles unassignment of plant from active row's plants list
     */
    handleUnassignPlantFromActiveRow = (id: number) => () =>
        this.setState((prevState: State) => {
            const idx =
                prevState.activeRow &&
                prevState.activeRow
                    .get('directPlantAccesses')
                    .findIndex((plant: ImmutableUserClient) => plant.get('plantId') === id);
            if (idx === -1) {
                throw new Error('Active row is not assigned to plant, something went wrong.');
            }

            return {
                activeRow:
                    prevState.activeRow &&
                    prevState.activeRow.deleteIn(['directPlantAccesses', idx]),
                isModified: true,
            };
        });

    /**
     * Handles unassignment of pm user from active row's users list
     */
    handleUnassignPMUserFromActiveRow = (id: number) => () =>
        this.setState((prevState: State) => {
            const idx =
                prevState.activeRow &&
                prevState.activeRow
                    .get('users')
                    .findIndex((user: ImmutableUser) => user.get('pmId') === id);
            if (idx === -1) {
                throw new Error('Active row is not assigned to pm, something went wrong.');
            }
            return {
                activeRow: prevState.activeRow && prevState.activeRow.deleteIn(['users', idx]),
                isModified: true,
            };
        });

    /**
     * Handles removal of reagent from active row's reagents list
     */
    handleRemoveReagentFromActiveRow = (id: number) => () =>
        this.setState((prevState: State) => {
            const idx =
                prevState.activeRow &&
                prevState.activeRow
                    .get('directReagentAccesses')
                    .findIndex(
                        (reagentAccess: ImmutableReagentAccess) =>
                            reagentAccess.get('reagentId') === id
                    );
            if (idx === -1) {
                throw new Error('Active row is not assigned to reagent, something went wrong.');
            }
            return {
                activeRow:
                    prevState.activeRow &&
                    prevState.activeRow.deleteIn(['directReagentAccesses', idx]),
                isModified: true,
            };
        });

    /**
     * On submit of search, reset pagination
     * Fire props.onUsersReFetch() with current searchCriteria and updated page
     */
    onSearchSubmit = () => {
        this.setState(
            {
                pagination: {
                    page: this.props.defaultPage,
                },
            },
            () => this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    provideTableHeader = () => {
        const headers = [];

        headers.push({
            label: this.props.intl.formatMessage({
                id: 'components.UserManager.table.header.email',
            }),
            id: 'email',
            sortable: true,
        });

        if (this.validateSAMView()) {
            if (this.props.clients) {
                headers.push({
                    label: this.props.intl.formatMessage({
                        id: 'components.UserManager.table.header.clients',
                    }),
                    id: 'clients',
                });
            }
            if (this.props.allUsers) {
                headers.push({
                    label: this.props.intl.formatMessage({
                        id: 'components.UserManager.table.header.pmUsers',
                    }),
                    id: 'pmUsers',
                });
            }
        }

        if (this.validatePMView()) {
            headers.push({
                label: this.props.intl.formatMessage({
                    id: 'components.UserManager.table.header.reagents',
                }),
                id: 'reagents',
            });

            if (this.props.plants) {
                headers.push({
                    label: this.props.intl.formatMessage({
                        id: 'components.UserManager.table.header.plants',
                    }),
                    id: 'plants',
                });
            }

            headers.push({
                label: this.props.intl.formatMessage({
                    id: 'components.UserManager.table.header.isSuperPM',
                }),
                id: 'isSuperPM',
            });

            headers.push({
                label: (
                    <NotificationsWrapper>
                        <NotificationBell />
                    </NotificationsWrapper>
                ),
                id: 'notifications',
            });
        }

        headers.push({
            label: this.renderActivityTableHeader(),
            id: 'activity',
            sortable: true,
        });

        headers.push({
            label: '',
            id: 'controls',
        });

        return headers;
    };

    /**
     * Provides users for table
     * Maps over props.users to determine if editable or interactive
     * If the iterated user's id matches the id of the state.activeRow, render this.provideEditableRow()
     * Else return this.provideInteractiveRow()
     */
    provideTableRows = () => {
        const rows = this.props.queriedUsers.map((user: ImmutableUser) => {
            if (
                (user && user.get('id')) ===
                    (this.state.activeRow && this.state.activeRow.get('id')) ||
                !user.has('id')
            ) {
                return this.provideEditableRow();
            } else {
                return this.provideInteractiveRow(user);
            }
        });

        // If state.activeRow is an emptyRow, insert empty editable row at start of row
        let newRows;
        if (this.state.activeRow && !this.state.activeRow.get('id')) {
            newRows = rows.insert(0, this.provideEditableRow());
        }

        return (newRows || rows).toJS();
    };

    /**
     * Returns an "editable" user row with input/select for the state.activeRow
     */
    provideEditableRow = () => {
        const { activeRow } = this.state;

        if (!activeRow) {
            throw new Error('Active row was not provided, something went wrong.');
        }

        // If user is new (no id) Look for an existing user, do not allow save if we have a match
        // For performance, lets not do this over 100 users
        let foundExistingUser = false;
        if (!activeRow.get('id') && this.props.allUsers.size <= 100) {
            foundExistingUser = this.props.allUsers.find(
                (user: ImmutableUser) =>
                    user.get('email').includes(activeRow.get('email')) ||
                    user.get('email') === activeRow.get('email')
            );
        }

        const userIsSavable = Boolean(activeRow.get('email') && !foundExistingUser);

        const user = fromJS({});
        let returnUser = user
            .set(
                'email',
                activeRow.get('id') ? (
                    activeRow.get('email')
                ) : (
                    <InputField
                        onChange={this.handleActiveRowChange('email')}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.UserManager.emailPlaceholder',
                        })}
                        value={activeRow.get('email') || ''}
                    />
                )
            )
            .set('activity', this.provideUsersStatus(activeRow))
            .set(
                'controls',
                <ButtonWrapper right>
                    <PrimaryButton
                        disabled={this.state.disabledInputs || !userIsSavable}
                        onClick={this.handleUserSave}
                        loading={this.props.userIsUpdating || this.props.userIsCreating}
                        text={this.props.intl.formatMessage({
                            id: 'components.UserManager.userSave',
                        })}
                    />
                    <SecondaryButton
                        disabled={this.state.disabledInputs}
                        onClick={this.handleUserCancel}
                        text={this.props.intl.formatMessage({
                            id: 'components.UserManager.userCancel',
                        })}
                    />
                </ButtonWrapper>
            );

        if (this.validateSAMView()) {
            returnUser = returnUser.set(
                'clients',
                <TagList
                    onAddItem={
                        this.provideSelectableClientsArray().length &&
                        this.handleAssignClientModalVisibility
                    }
                    onRemoveItem={this.handleUnassignClientFromActiveRow}
                    list={this.provideClientsToTagList(activeRow.get('clients'))}
                />
            );

            returnUser = returnUser.set(
                'pmUsers',
                <TagList
                    onAddItem={
                        this.provideSelectablePMUsersArray().length &&
                        this.handleAssignPMUserModelVisibility
                    }
                    onRemoveItem={this.handleUnassignPMUserFromActiveRow}
                    list={this.providePMUsersToTagList(activeRow.get('users'))}
                />
            );
        }

        if (this.validatePMView()) {
            returnUser = returnUser.set(
                'plants',
                <TagList
                    onAddItem={
                        this.provideSelectablePlantsArray().length &&
                        this.handleAssignPlantModalVisibility
                    }
                    onRemoveItem={this.handleUnassignPlantFromActiveRow}
                    list={this.providePlantsToTagList(activeRow.get('directPlantAccesses'))}
                />
            );

            returnUser = returnUser.set(
                'reagents',
                <TagList
                    onAddItem={
                        this.provideSelectableReagentsArray().length &&
                        this.handleAssignReagentModalVisibility
                    }
                    onRemoveItem={this.handleRemoveReagentFromActiveRow}
                    list={this.provideReagentsToTagList(activeRow.get('directReagentAccesses'))}
                />
            );

            returnUser = returnUser.set(
                'isSuperPM',
                <Toggle
                    disabled={false}
                    checked={isSuperPMUser(activeRow)}
                    onClickHandler={this.handleIsSuperPMToggle}
                />
            );
        }

        return returnUser;
    };

    providePMUsersToTagList = (users: ImmutableList<ImmutableUser>) =>
        (users &&
            users
                .map((u: ImmutableUser) => ({
                    label: u.getIn(['pm', 'email']),
                    value: u.getIn(['pm', 'id']),
                }))
                .toJS()) ||
        [];

    provideReagentsToTagList = (reagents: ImmutableList<ImmutableReagentAccess>) =>
        (reagents &&
            reagents
                .map((r: ImmutableReagentAccess) => ({
                    label: r.get('reagentName'),
                    value: r.get('reagentId'),
                }))
                .toJS()) ||
        [];

    provideClientsToTagList = (clients: ImmutableList<ImmutableClient>) =>
        (clients &&
            clients
                .map((client: ImmutableClient) => ({
                    label: client.get('name') || client.getIn(['client', 'name']),
                    value: client.get('clientId') || client.get('id'),
                }))
                .toJS()) ||
        [];

    providePlantsToTagList = (plants: ImmutableList<ImmutableUserPlant>) =>
        (plants &&
            plants
                .map((plant: ImmutablePlant) => ({
                    label: plant.get('plantName'),
                    value: plant.get('plantId'),
                }))
                .toJS()) ||
        [];

    provideUsersStatus = (user: ImmutableUser) => (
        <ActivityStatus>{this.getLastActiveAt(user)}</ActivityStatus>
    );

    /**
     * Returns a formatted date according to user locale or if the date is not set
     */
    getLastActiveAt = (user: ImmutableUser) => {
        const lastActiveAt = user.get('lastActiveAt');
        if (lastActiveAt) {
            const userLocale = getUsersLanguage(user);
            return getFormattedDateFromString(lastActiveAt, userLocale, {
                ...DATE_FORMAT,
                timeZoned: getCurrentTimeZone(),
            });
        } else {
            return this.props.intl.formatMessage({
                id: 'components.UserManager.userNeverLoggedIn',
            });
        }
    };

    getPMRecommendationEmailActivationStatus = (user: ImmutableUser) =>
        this.props.notificationTypeUsersStatuses.findIndex(
            (el) => el.get('userId') === user.get('id') && el.get('emailNotification')
        ) > -1;

    /**
     * Returns an "interactive" user row for the provided user
     */
    provideInteractiveRow = (user: ImmutableUser) => {
        if (!user) {
            throw new Error('No row data was provided, something went wrong.');
        }

        const controlItems = [];
        // Do not render the edit button if it is the current authenticated user
        if (user.get('id') !== this.props.authUser.get('id')) {
            // Currently, there is not functionality to edit admins
            if (!this.validateADMINView()) {
                const editUserButtonText = this.props.intl.formatMessage({
                    id: 'components.UserManager.userEdit',
                });

                controlItems.push(
                    <li key="edit">
                        <BlankButton
                            onClick={this.handleUserEditable(user)}
                            title={editUserButtonText}
                        >
                            {editUserButtonText}
                        </BlankButton>
                    </li>
                );
            }

            controlItems.push(this.renderUserDeleteButton(user));
        }

        const usersClients = user.has('clients') ? user.get('clients') : fromJS([]);
        const usersPMUsers = user.has('users') ? user.get('users') : fromJS([]);
        const usersPlants = user.has('directPlantAccesses')
            ? user.get('directPlantAccesses')
            : fromJS([]);
        const usersReagents = user.has('directReagentAccesses')
            ? user.get('directReagentAccesses')
            : fromJS([]);
        const notificationsEnabled = this.getPMRecommendationEmailActivationStatus(user);

        let returnUser = user.set('activity', this.provideUsersStatus(user)).set(
            'controls',
            controlItems.length ? (
                <div style={{ textAlign: 'right' }}>
                    <ToolTip
                        content={<ToolTipContent>{controlItems}</ToolTipContent>}
                        position="bottom"
                        trigger={<DotMenu />}
                        triggerType="click"
                        interactive
                    />
                </div>
            ) : null
        );

        if (this.validateSAMView()) {
            returnUser = returnUser.set(
                'clients',
                !usersClients.isEmpty() ? (
                    <TagList list={this.provideClientsToTagList(usersClients)} />
                ) : (
                    NO_RESULT_STRING
                )
            );
            returnUser = returnUser.set(
                'pmUsers',
                !usersPMUsers.isEmpty() ? (
                    <TagList list={this.providePMUsersToTagList(usersPMUsers)} />
                ) : (
                    NO_RESULT_STRING
                )
            );
        }

        if (this.validatePMView()) {
            returnUser = returnUser.set(
                'plants',
                !usersPlants.isEmpty() ? (
                    <TagList list={this.providePlantsToTagList(usersPlants)} />
                ) : (
                    NO_RESULT_STRING
                )
            );

            returnUser = returnUser.set(
                'reagents',
                !usersReagents.isEmpty() ? (
                    <TagList list={this.provideReagentsToTagList(usersReagents)} />
                ) : (
                    NO_RESULT_STRING
                )
            );

            returnUser = returnUser.set(
                'isSuperPM',
                <Toggle
                    disabled={true}
                    checked={isSuperPMUser(user)}
                    onClickHandler={this.handleIsSuperPMToggle}
                />
            );

            returnUser = returnUser.set(
                'notifications',
                <NotificationsWrapper>
                    <NotificationsText active={notificationsEnabled}>
                        {this.props.intl.formatMessage({
                            id: `common.${notificationsEnabled ? 'enabled' : 'disabled'}`,
                        })}
                    </NotificationsText>
                </NotificationsWrapper>
            );
        }

        return returnUser;
    };

    /**
     * Map provided users to JS Array of ReactSelectObjects
     */
    provideSelectablePMUsersArray = (): Array<ReactSelectObject> =>
        (this.props.allUsers &&
            this.props.allUsers
                .filter((user: ImmutableUser) => {
                    const userIdx =
                        this.state.activeRow &&
                        this.state.activeRow.has('users') &&
                        this.state.activeRow
                            .get('users')
                            .findIndex(
                                (users: ImmutableUserUsers) => users.get('pmId') === user.get('id')
                            );
                    return user.get('roles').includes(ROLES.PM) && userIdx === -1;
                })
                .map(
                    (user: ImmutableUser): ReactSelectObject => ({
                        label: user.get('email'),
                        value: user.get('id'),
                    })
                )
                .toJS()) ||
        [];

    /**
     * Map provided clients to JS Array of ReactSelectObjects
     */
    provideSelectableClientsArray = (): Array<ReactSelectObject> =>
        (this.props.clients &&
            this.props.clients
                .filter((client: ImmutableClient) => {
                    const idx =
                        this.state.activeRow &&
                        this.state.activeRow.has('clients') &&
                        this.state.activeRow
                            .get('clients')
                            .findIndex(
                                (c: ImmutableUserClient) => c.get('clientId') === client.get('id')
                            );
                    return idx === -1;
                })
                .map(
                    (client: ImmutableClient): ReactSelectObject => ({
                        label: client.get('name'),
                        value: client.get('id'),
                    })
                )
                .toJS()) ||
        [];

    /**
     * Map provided plants to JS Array of ReactSelectObjects
     */
    provideSelectablePlantsArray = (): Array<ReactSelectObject> =>
        (this.props.plants &&
            this.props.plants
                .filter((plant: ImmutablePlant) => {
                    const idx =
                        this.state.activeRow &&
                        this.state.activeRow.has('directPlantAccesses') &&
                        this.state.activeRow
                            .get('directPlantAccesses')
                            .findIndex(
                                (pda: ImmutableUserPlant) => pda.get('plantId') === plant.get('id')
                            );
                    return idx === -1;
                })
                .map(
                    (plant: ImmutablePlant): ReactSelectObject => ({
                        label: plant.get('name'),
                        value: plant.get('id'),
                    })
                )
                .toJS()) ||
        [];

    /**
     * Map provided reagents to JS Array of ReactSelectObjects
     */
    provideSelectableReagentsArray = (): Array<ReactSelectObject> =>
        (this.props.reagents &&
            this.props.reagents
                .filter((reagent: ImmutableReagent) => {
                    const reagentIdx =
                        this.state.activeRow &&
                        this.state.activeRow.has('directReagentAccesses') &&
                        this.state.activeRow
                            .get('directReagentAccesses')
                            .findIndex(
                                (rda: ImmutableReagentAccess) =>
                                    rda.get('reagentId') === reagent.get('id')
                            );
                    return reagentIdx === -1;
                })
                .map(
                    (reagent: ImmutableReagent): ReactSelectObject => ({
                        label: reagent.get('name'),
                        value: reagent.get('id'),
                    })
                )
                .toJS()) ||
        [];

    renderAssignPMUserModal = () => {
        if (!this.state.showAssignPMUserModal) {
            return null;
        }

        return (
            <AddItemToListModal
                onConfirm={this.handleAssignPMUserToActiveRow}
                onCancel={this.handleAssignPMUserModelVisibility()}
                list={this.provideSelectablePMUsersArray()}
                strings={{
                    title: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPMUserModal.title',
                    }),
                    fieldTitle: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPMUserModal.fieldTitle',
                    }),
                    cancelButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPMUserModal.cancelButton',
                    }),
                    saveButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPMUserModal.saveButton',
                    }),
                }}
                loading={false}
            />
        );
    };

    renderAssignReagentModal = () => {
        if (!this.state.showAssignReagentModal) {
            return null;
        }

        return (
            <AddItemToListModal
                onConfirm={this.handleAddReagentToActiveRow}
                onCancel={this.handleAssignReagentModalVisibility()}
                strings={{
                    title: this.props.intl.formatMessage({
                        id: 'components.UserManager.AddReagentModal.title',
                    }),
                    fieldTitle: this.props.intl.formatMessage({
                        id: 'components.UserManager.AddReagentModal.fieldTitle',
                    }),
                    cancelButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AddReagentModal.cancelButton',
                    }),
                    saveButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AddReagentModal.saveButton',
                    }),
                }}
                loading={this.props.reagentsAreFetching}
                list={this.provideSelectableReagentsArray()}
            />
        );
    };

    renderAssignClientModal = () => {
        if (!this.state.showAssignClientModal) {
            return null;
        }

        return (
            <AddItemToListModal
                onConfirm={this.handleAssignClientToActiveRow}
                onCancel={this.handleAssignClientModalVisibility()}
                list={this.provideSelectableClientsArray()}
                strings={{
                    title: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignClientModal.title',
                    }),
                    fieldTitle: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignClientModal.fieldTitle',
                    }),
                    cancelButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignClientModal.cancelButton',
                    }),
                    saveButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignClientModal.saveButton',
                    }),
                }}
                loading={false}
            />
        );
    };

    renderAssignPlantModal = () => {
        if (!this.state.showAssignPlantModal) {
            return null;
        }

        return (
            <AddItemToListModal
                onConfirm={this.handleAssignPlantToActiveRow}
                onCancel={this.handleAssignPlantModalVisibility()}
                list={this.provideSelectablePlantsArray()}
                strings={{
                    title: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPlantModal.title',
                    }),
                    bodyMessage: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPlantModal.bodyMessage',
                    }),
                    fieldTitle: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPlantModal.fieldTitle',
                    }),
                    cancelButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPlantModal.cancelButton',
                    }),
                    saveButton: this.props.intl.formatMessage({
                        id: 'components.UserManager.AssignPlantModal.saveButton',
                    }),
                }}
                loading={false}
            />
        );
    };

    renderUserDeleteConfirmationModal = () => {
        const { rowToDelete } = this.state;
        if (!rowToDelete) {
            return null;
        }

        return (
            <ConfirmationModal
                title={this.props.intl.formatMessage(
                    {
                        id: 'components.UserManager.DeleteModal.title',
                    },
                    { email: rowToDelete.get('email') }
                )}
                confirmButtonText={this.props.intl.formatMessage({
                    id: 'components.UserManager.DeleteModal.confirmButton',
                })}
                errors={{}}
                loading={this.props.userIsDeleting}
                onConfirm={
                    rowToDelete ? this.handleUserDelete(rowToDelete.get('email')) : undefined
                }
                onCancel={this.handleSetRowToDelete()}
                danger
            />
        );
    };

    /**
     * If user is provided & is not props.activeRow return delete user confirmation modal
     */
    renderUserDeleteButton = (user: ImmutableUser) => {
        if (
            !this.props.deleteUser ||
            !user ||
            (user.get('id') === this.props.authUser && this.props.authUser.get('id'))
        ) {
            return null;
        }

        const buttonText = this.props.intl.formatMessage({
            id: 'components.UserManager.deleteUser',
        });

        return (
            <li key="delete">
                <BlankButton onClick={this.handleSetRowToDelete(user)} title={buttonText}>
                    {buttonText}
                </BlankButton>
            </li>
        );
    };

    renderActivityTableHeader = () => (
        <React.Fragment>
            {this.props.intl.formatMessage({
                id: 'components.UserManager.table.header.activity',
            })}
            <ToolTip
                content={
                    <ActivityToolTipWrapper>
                        <ActivityToolTipTop>
                            <StatusList>
                                <li>
                                    <StatusDot active />{' '}
                                    {this.props.intl.formatMessage({
                                        id: 'components.UserManager.userActiveLabel',
                                    })}
                                </li>
                                <li>
                                    <StatusDot />{' '}
                                    {this.props.intl.formatMessage({
                                        id: 'components.UserManager.userInactiveLabel',
                                    })}
                                </li>
                            </StatusList>
                            <p>
                                {this.props.intl.formatMessage({
                                    id: 'components.UserManager.userActivityNote',
                                })}
                            </p>
                        </ActivityToolTipTop>
                        <ActivityToolTipBottom>
                            <Toggle
                                checked={this.state.searchCriteria.inactiveUsersOnly}
                                onClickHandler={this.handleInactiveUsersOnly}
                                label={this.props.intl.formatMessage({
                                    id: 'components.UserManager.userActivityToggle',
                                })}
                            />
                        </ActivityToolTipBottom>
                    </ActivityToolTipWrapper>
                }
                trigger={
                    <ActivityTrigger active={this.state.searchCriteria.inactiveUsersOnly}>
                        <InfoIcon />
                    </ActivityTrigger>
                }
                triggerType="click"
                position="bottom"
                closeOnInternalClick
                interactive
            />
        </React.Fragment>
    );

    render() {
        const header = this.provideTableHeader();
        const rows = this.provideTableRows();
        return (
            <React.Fragment>
                {this.renderUserDeleteConfirmationModal()}
                {this.renderAssignPMUserModal()}
                {this.renderAssignReagentModal()}
                {this.renderAssignClientModal()}
                {this.renderAssignPlantModal()}
                <PreventNavigationPrompt shouldBlock={this.state.isModified} />
                <UserTableHeader alignItems="center" flex="initial">
                    <Common.Row gutter={12}>
                        <Common.Column flex={2} alignItems="flex-start">
                            <Common.Row gutter={24} alignItems="center">
                                <Common.Column flex={2}>
                                    <Title>
                                        {this.props.title}
                                        {this.props.usersTotal && !this.props.usersAreQuerying ? (
                                            <Count>({this.props.usersTotal})</Count>
                                        ) : null}
                                    </Title>
                                </Common.Column>
                                {this.props.createUser && (
                                    <Common.Column flex={1} alignItems="flex-end">
                                        <PrimaryButton
                                            disabled={
                                                !this.state.enableAddRow ||
                                                this.props.usersAreQuerying
                                            }
                                            onClick={this.handleUserCreate}
                                            text={this.props.intl.formatMessage({
                                                id: 'components.UserManager.addUser',
                                            })}
                                        />
                                    </Common.Column>
                                )}
                            </Common.Row>
                        </Common.Column>
                        <Common.Column flex={1} alignItems="flex-end">
                            <InputSearch
                                handleOnClear={this.onSearchClear}
                                handleOnClick={this.onSearchSubmit}
                                onChange={this.handleSearchOnChange}
                                onKeyDown={this.handleKeyPress}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.UserManager.searchPlaceholder',
                                })}
                                value={this.state.searchCriteria.search}
                                withSearch={this.props.withSearch}
                            />
                        </Common.Column>
                    </Common.Row>
                </UserTableHeader>
                <UserTableContainer>
                    <Table
                        currentSorting={{
                            sortBy: this.state.searchCriteria && this.state.searchCriteria.sortBy,
                            sortOrder:
                                this.state.searchCriteria && this.state.searchCriteria.sortOrder,
                        }}
                        header={header}
                        onSortBy={this.handleSortBy}
                        rows={rows}
                        overlay={this.props.usersAreQuerying && <div />}
                        loading={this.props.usersAreQuerying}
                        tdMinHeight="26px"
                        tdVerticalAlign="top"
                        footerMessage={
                            !this.props.usersAreQuerying &&
                            !rows.length &&
                            this.props.intl.formatMessage({
                                id: 'components.UserManager.table.noUsers',
                            })
                        }
                    />
                    <Pagination
                        currentPage={this.state.pagination.page}
                        isLoading={this.props.usersAreQuerying}
                        onPageSelection={this.handlePaginationPageSelection}
                        pagesTotal={this.props.pageCount}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.UserManager.paginationPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.UserManager.paginationJoinner',
                        })}
                    />
                </UserTableContainer>
            </React.Fragment>
        );
    }
}

export default injectIntl(UserManager);
