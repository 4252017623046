// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header, Body, Footer, Label } from './styles';

// Constants
import { MODAL_WIDTH } from 'utils/constants';

// Helpers
import {
    getRecommendationSetMessages,
    getIncludedRecommendations,
    getSortedRecommendations,
} from 'utils/recommendationHelpers';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, SecondaryButton, Modal } from 'components/_ReactUI_V1';

import RecommendationCard from 'components/RecommendationCard';
import MajorKPISummary from 'components/MajorKPISummary';

// Types
import type { IntlType, ImmutableList, LanguageCodeConstant } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableKPICard, ImmutableKPISetting } from 'services/KPISetting/types';
import type {
    ImmutableRecommendation,
    ImmutableRecommendationSet,
} from 'services/Recommendation/types';
import type { DatasetStatusType } from 'services/Dataset/types';

import type { ImmutableFormattedRecommendationSetMessage } from 'components/RecommendationSidebar';

type Props = {
    intl: IntlType,
    kpis: ImmutableList<ImmutableKPICard>,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    timezone: string,
    userLocale: LanguageCodeConstant,
    circuit: ImmutableCircuit,
    isUserPM: boolean,
    recommendationSet: ImmutableRecommendationSet,
    loading: boolean,

    majorKPIdata: ImmutableList<ImmutableKPICard>,
    datasetStatus: DatasetStatusType,
    createdAt: string,

    isSingleCircuit: boolean,

    onSubmit: () => void,
    onCancel: () => void,
};

class RecommendationSetSubmissionModal extends React.PureComponent<Props> {
    getTranslation = (key: string) =>
        this.props.intl.formatMessage({
            id: `components.Modals.RecommendationSetSubmissionModal.${key}`,
        });

    getCommentsCount = () => this.props.recommendationSet.get('recommendationSetMessages').size;

    getRecommendationCount = () => getIncludedRecommendations(this.props.recommendationSet).size;

    renderDisplayMessages = () => {
        const messages = getRecommendationSetMessages(
            this.props.recommendationSet,
            this.props.kpiSettings,
            this.props.circuit,
            this.props.intl
        ).map((dmItem: ImmutableFormattedRecommendationSetMessage) => (
            <li key={dmItem.get('id')}>{dmItem.get('value')}</li>
        ));

        if (messages.isEmpty()) {
            return;
        }

        return <ul>{messages}</ul>;
    };

    renderRecommendationCards = () =>
        getSortedRecommendations(this.props.recommendationSet, true).map(
            (recommendation: ImmutableRecommendation, index: number) => (
                <RecommendationCard
                    key={recommendation.get('id') || index}
                    recommendation={recommendation}
                    kpiSettings={this.props.kpiSettings}
                    circuit={this.props.circuit}
                    isUserPM={this.props.isUserPM}
                    userLocale={this.props.userLocale}
                    timezone={this.props.timezone}
                    cardIndex={index}
                    recommendationSetWasSubmitted={false}
                    showSubmissionTitle={false}
                    readOnly
                />
            )
        );

    renderMajorKPISummary = () => (
        <MajorKPISummary
            timezone={this.props.timezone}
            userLocale={this.props.userLocale}
            circuit={this.props.circuit}
            majorKPIdata={this.props.majorKPIdata}
            kpiSettings={this.props.kpiSettings}
            datasetStatus={this.props.datasetStatus}
            createdAt={this.props.createdAt}
            isSingleCircuit={this.props.isSingleCircuit}
        />
    );

    render() {
        return (
            <Modal
                modalWidth={MODAL_WIDTH.LARGE}
                onHandleClose={this.props.onCancel}
                style={{
                    wrapper: {
                        zIndex: '999999',
                    },
                }}
                disableClose
            >
                <Wrapper>
                    <Header>
                        <Title>{this.getTranslation('title')}</Title>
                    </Header>
                    <Body>
                        {this.renderMajorKPISummary()}
                        {this.getCommentsCount() > 0 && (
                            <Label>{this.getTranslation('commentsLabel')}</Label>
                        )}
                        {this.renderDisplayMessages()}
                        {this.getRecommendationCount() > 0 && (
                            <Label>{this.getTranslation('recommendationSetLabel')}</Label>
                        )}
                        {this.renderRecommendationCards()}
                        <Label>
                            {this.getTranslation(
                                this.props.recommendationSet.get('notifyPm')
                                    ? 'notifyPm'
                                    : 'doNotNotifyPm'
                            )}
                        </Label>
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.getTranslation('cancelButton')}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.getTranslation('submitButton')}
                            loading={this.props.loading}
                            disabled={this.props.loading}
                            onClick={this.props.onSubmit}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(RecommendationSetSubmissionModal);
