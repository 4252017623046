// @flow strict

import styled from 'styled-components';

export const TankWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    padding: 16px 16px;

    font-weight: normal;
    font-size: 11px;
    text-align: center;
`;
