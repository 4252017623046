// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const RecommendationsBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
export const MessageTitle= styled.h3`
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
`;
export const DisplayMessages = styled.div`
    display: flex;
    flex-direction: column;
    color: ${LegacyTheme.defaultColor};
    gap: 12px;
    font-size: 14px;
    margin-bottom: 10px;
`;

export const DecisionTreeButtonWrapper = styled.div`
    margin: 0 0 20px;
`;

export const Instructions = styled.p`
    font-size: 13px;
    line-height: 15px;

    color: ${LegacyTheme.defaultSubtleColor7B};
    margin-bottom: 6px;
    & + & {
        margin-top: 0;
    }
`;

export const SidebarBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    padding: 0px 24px;

    ${Instructions}:first-child {
        margin-top: 0;
    }
`;

export const SidebarFooter = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px 20px 20px 20px;

    > label {
        width: fit-content;
    }
`;

export const SidebarFooterButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
