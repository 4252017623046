// @flow

import React from 'react';
import { Close } from '../../icons';

// Styles

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

// Theme
import defaultTheme from '../../themes/defaultTheme';
import StyledRemovableLabel from './styles';

type Props = {
    text: string,
    showCloseButton?: boolean,
    style?: Object,
    onClose?: () => void,
    theme?: ?Object,
};

type State = {
    show: boolean,
};

export default class RemovableLabel extends React.PureComponent<Props, State> {
    static defaultProps = {
        onClose: () => null,
        showCloseButton: true,
        style: null,
        theme: defaultTheme,
    };

    state = {
        show: true,
    };

    handleCloseClicked = () => {
        this.setState(
            (prevState: State) => ({
                show: !prevState.show,
            }),
            this.props.onClose && this.props.onClose
        );
    };

    render() {
        const { style } = this.props;
        if (!this.state.show) {
            return null;
        }

        return (
            <StyledRemovableLabel theme={this.props.theme} style={style && style.wrapper}>
                {this.props.showCloseButton && (
                    <StyledRemovableLabel.CloseButtonWrapper
                        theme={this.props.theme}
                        onClick={this.handleCloseClicked}
                        style={this.props.style}
                    >
                        <Close
                            fill={themePropertyFetcher(this.props.theme, [
                                'removableLabel',
                                'closeButton',
                                'fill',
                            ])}
                            width="11px"
                            strokeWidth={1}
                            style={style && style.closeButton}
                            theme={this.props.theme}
                            clickable
                        />
                    </StyledRemovableLabel.CloseButtonWrapper>
                )}
                <StyledRemovableLabel.Label
                    theme={this.props.theme}
                    style={style && style.removableLabel}
                >
                    {this.props.text}
                </StyledRemovableLabel.Label>
            </StyledRemovableLabel>
        );
    }
}
