// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Helpers
import { OverflowBody, OverflowContainer, OverflowEnd } from 'components/_ReactUI_V1';

import { nodeToClipboard } from 'utils/helpers';
import { getXMin, getXMax, getYMin, getYMax } from './helpers';

// Constants
import {
    SENSITIVITY_OPTIONS,
    SENSITIVITY_OPTION_LIMITS,
    THREE_D_SENSITIVITY_OUTPUT_OPTIONS,
    SIDEBAR_STATES,
} from 'utils/constants';

// Components
import ComputationInstructions from 'components/ComputationInstructions';
import ThreeDSensitivityFooter from 'components/ThreeDSensitivityFooter';
import ThreeDSensitivitySidebarSection from 'components/CircuitComputationSidebar/ThreeDSensitivitySidebarSection';
import SensitivitySidebarSummarySection from 'components/CircuitComputationSidebar/SensitivitySidebarSummarySection';
import ThreeDSensitivityDiagram from 'components/ThreeDSensitivityDiagram';

// Styles
import { ExportFixedContainer } from 'styles/common';

// Services
import { newFeedback } from 'services/Feedback/thunks';
import { compute3DSensitivity } from 'services/Dataset/thunks';
import {
    selectDatasetIsComputing3D,
    select3DAnalysis,
    selectDatasetErrors,
} from 'services/Dataset/selectors';

// Types
import type { ReduxDispatch, ErrorType, IntlType } from 'types';
import type { SidebarRenderer } from 'containers/CircuitComputationContainer';
import type { FeedbackType } from 'services/Feedback/types';
import type {
    ImmutableThreeDAnalysis,
    SensitivityOptionConstant,
    ZSensitivityOptionConstant,
} from 'services/Dataset/types';

const SIDEBAR_TYPES = {
    SUMMARY: 'SUMMARY', // after computing
    SELECTION: 'SELECTION', // before computing
};

type SidebarType = $Keys<typeof SIDEBAR_TYPES>;

export type SensitivityOption = $Keys<typeof SENSITIVITY_OPTIONS>;
export type SensitivityOutputOption = $Keys<typeof THREE_D_SENSITIVITY_OUTPUT_OPTIONS>;

type DataPoint = {
    x: ?number,
    y: ?number,
    z: ?number,
};

type Props = {
    intl: IntlType,
    datasetId: number,
    threeDAnalysis: ?ImmutableThreeDAnalysis,
    isComputing3D: boolean,
    errors: ErrorType,

    compute3DSensitivity: (
        datasetId: number,
        xVariable: SensitivityOptionConstant,
        xRangeMin: number,
        xRangeMax: number,
        yVariable: SensitivityOptionConstant,
        yRangeMin: number,
        yRangeMax: number,
        zVariable: ZSensitivityOptionConstant
    ) => void,

    handleSidebarContent: (sidebarContent: SidebarRenderer) => void,
    newFeedback: (feedbackType: FeedbackType, message: string) => void,
};

type State = {
    sidebarType: SidebarType,
    generateDiagram: boolean,
    openedExportFixedContainer: boolean,

    optionsX: {
        sensitivityOption: SensitivityOptionConstant,
        minValue: ?number,
        maxValue: ?number,
    },
    optionsY: {
        sensitivityOption: SensitivityOptionConstant,
        minValue: ?number,
        maxValue: ?number,
    },
    optionsOutput: ZSensitivityOptionConstant,
    selectedData: DataPoint,
};

/**
 * The container that holds the 3d sensitivity plot and handles the sidebar state
 */
class ThreeDSensitivityContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.exportTarget = React.createRef();

        this.state = this.getDefaultState();
    }

    /**
     * When this container is mounted, set sidebar default settings,
     */
    componentDidMount() {
        this.props.handleSidebarContent(this.renderSidebar());
    }

    componentDidUpdate(prevProps: Props) {
        // When we change datasets, reset our state.
        if (prevProps.datasetId !== this.props.datasetId) {
            return this.setState(this.getDefaultState(), () =>
                this.props.handleSidebarContent(this.renderSidebar())
            );
        }
        // TODO: Implement error handling.
        if (prevProps.isComputing3D && !this.props.isComputing3D && this.props.errors.isEmpty()) {
            return this.setState(
                {
                    generateDiagram: true,
                    sidebarType: SIDEBAR_TYPES.SUMMARY,
                },
                () => this.props.handleSidebarContent(this.renderSidebar())
            );
        }
        // Show the loading button state when we start computing.
        if (!prevProps.isComputing3D && this.props.isComputing3D) {
            this.props.handleSidebarContent(this.renderSidebar());
        }
    }

    exportTarget = null;

    getDefaultState = () => ({
        sidebarType: SIDEBAR_TYPES.SELECTION,
        generateDiagram: false,
        optionsX: {
            sensitivityOption: SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION,
            minValue: this.getDefaultVariableMin(SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION),
            maxValue: this.getDefaultVariableMax(SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION),
        },
        optionsY: {
            sensitivityOption: SENSITIVITY_OPTIONS.OXMOD_RATIO,
            minValue: this.getDefaultVariableMin(SENSITIVITY_OPTIONS.OXMOD_RATIO),
            maxValue: this.getDefaultVariableMax(SENSITIVITY_OPTIONS.OXMOD_RATIO),
        },
        optionsOutput: THREE_D_SENSITIVITY_OUTPUT_OPTIONS.OVERALL_RECOVERY,

        openedExportFixedContainer: false,

        selectedData: {
            x: null,
            y: null,
            z: null,
        },
    });

    getDefaultVariableMin = (variable: SensitivityOptionConstant) =>
        SENSITIVITY_OPTION_LIMITS[variable].DEFAULT_MINIMUM;

    getDefaultVariableMax = (variable: SensitivityOptionConstant) =>
        SENSITIVITY_OPTION_LIMITS[variable].DEFAULT_MAXIMUM;

    /**
     * Reset the active mode to the selection mode.
     */
    onReturnToSelectionClicked = () =>
        this.setState(
            {
                sidebarType: SIDEBAR_TYPES.SELECTION,
                generateDiagram: false,
            },
            () => this.props.handleSidebarContent(this.renderSidebar())
        );

    /**
     * When copy to clipboard button clicked
     */
    handleCopyToClipboardClick = () => {
        this.setState(
            {
                openedExportFixedContainer: true,
            },
            () =>
                nodeToClipboard(
                    this.exportTarget.current,
                    (feedbackType: FeedbackType, messageId: string) =>
                        this.props.newFeedback(
                            feedbackType,
                            this.props.intl.formatMessage({
                                id: messageId,
                            })
                        )
                )
        );
    };

    /**
     * When Generate Diagram button clicked on sidebar
     */
    onGenerateDiagramClicked = (
        optionsX: {
            sensitivityOption: SensitivityOptionConstant,
            minValue: ?number,
            maxValue: ?number,
        },
        optionsY: {
            sensitivityOption: SensitivityOptionConstant,
            minValue: ?number,
            maxValue: ?number,
        },
        optionsOutput: ZSensitivityOptionConstant
    ) =>
        this.setState(
            {
                optionsX: {
                    sensitivityOption: optionsX.sensitivityOption,
                    minValue:
                        optionsX.start || this.getDefaultVariableMin(optionsX.sensitivityOption),
                    maxValue:
                        optionsX.finish || this.getDefaultVariableMax(optionsX.sensitivityOption),
                },
                optionsY: {
                    sensitivityOption: optionsY.sensitivityOption,
                    minValue:
                        optionsY.start || this.getDefaultVariableMin(optionsY.sensitivityOption),
                    maxValue:
                        optionsY.finish || this.getDefaultVariableMax(optionsY.sensitivityOption),
                },
                optionsOutput,
            },
            () =>
                this.props.compute3DSensitivity(
                    this.props.datasetId,
                    this.state.optionsX.sensitivityOption,
                    this.state.optionsX.minValue,
                    this.state.optionsX.maxValue,
                    this.state.optionsY.sensitivityOption,
                    this.state.optionsY.minValue,
                    this.state.optionsY.maxValue,
                    this.state.optionsOutput
                )
        );

    /**
     * Handles the diagram's hover event which will feed the footer values
     */
    onHandleTooltipHover = (data: DataPoint) =>
        this.setState({
            selectedData: data,
        });

    /**
     * Renders the 3D Sensitivity Sidebar.
     */
    renderSidebar = (): SidebarRenderer => ({
        sidebarType:
            this.state.sidebarType === SIDEBAR_TYPES.SELECTION
                ? SIDEBAR_STATES.TAB
                : SIDEBAR_STATES.FULL,
        node:
            this.state.sidebarType === SIDEBAR_TYPES.SELECTION ? (
                <ThreeDSensitivitySidebarSection
                    loading={this.props.isComputing3D}
                    generatedDiagram={this.state.generateDiagram}
                    handleGenerateDiagramClicked={this.onGenerateDiagramClicked}
                />
            ) : (
                <SensitivitySidebarSummarySection
                    returnTo="3D"
                    circuit={this.props.threeDAnalysis && this.props.threeDAnalysis.get('circuit')}
                    dataset={this.props.threeDAnalysis && this.props.threeDAnalysis.get('dataset')}
                    variableX={this.state.optionsX.sensitivityOption}
                    variableY={this.state.optionsY.sensitivityOption}
                    variableXMin={getXMin(this.props.threeDAnalysis)}
                    variableXMax={getXMax(this.props.threeDAnalysis)}
                    variableYMin={getYMin(this.props.threeDAnalysis)}
                    variableYMax={getYMax(this.props.threeDAnalysis)}
                    handleReturnToSelection={this.onReturnToSelectionClicked}
                />
            ),
    });

    render() {
        // Did the user ask the diagram to be generated?
        if (!this.state.generateDiagram || !this.props.threeDAnalysis) {
            return <ComputationInstructions messageId="3DSensitivity.selectVariable" />;
        }

        const threeDSensitivityDiagram = this.props.threeDAnalysis && (
            <ThreeDSensitivityDiagram
                loading={this.props.isComputing3D}
                threeDAnalysis={this.props.threeDAnalysis}
                sensitivityOptionX={this.state.optionsX.sensitivityOption}
                sensitivityOptionY={this.state.optionsY.sensitivityOption}
                outputOptionType={this.state.optionsOutput}
                handleTooltipHover={this.onHandleTooltipHover}
            />
        );

        return (
            <OverflowContainer>
                <OverflowBody>{threeDSensitivityDiagram}</OverflowBody>
                <OverflowEnd>
                    {this.props.threeDAnalysis && (
                        <ThreeDSensitivityFooter
                            onCopyToClipboardClick={this.handleCopyToClipboardClick}
                            copyToClipboardDisabled={false}
                            outputOptionType={this.state.optionsOutput}
                            sensitivityOptionX={this.state.optionsX.sensitivityOption}
                            sensitivityOptionY={this.state.optionsY.sensitivityOption}
                            selectedData={this.state.selectedData}
                        />
                    )}
                </OverflowEnd>
                {this.state.openedExportFixedContainer && (
                    <ExportFixedContainer ref={this.exportTarget}>
                        {threeDSensitivityDiagram}
                    </ExportFixedContainer>
                )}
            </OverflowContainer>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        threeDAnalysis: select3DAnalysis(),
        isComputing3D: selectDatasetIsComputing3D(),
        errors: selectDatasetErrors(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            newFeedback,
            compute3DSensitivity,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ThreeDSensitivityContainer))
);
