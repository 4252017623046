// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';

// Services
import { SecondaryButton, Caret, RadioButtonSet } from 'components/_ReactUI_V1';

import {
    selectMinChemCircuitQuery,
    selectMinChemIsQueryingStatus,
} from 'services/Circuit/selectors';
import { selectAllDatasets, selectDatasetsAreFetching } from 'services/Dataset/selectors';

// Components

// Styles
import {
    Wrapper,
    HeaderWrapper,
    CircuitDetails,
    CircuitCaret,
    CircuitName,
    CircuitSub,
    MenuToolTip,
    MenuToolTipContent,
    ToolTipSection,
    ToolTipSectionTitle,
    ToolTipSectionText,
    ScrollableField,
} from './styles';

// Helpers & constants
import { tryParseNumberOrNull } from 'utils/helpers';
import {
    BLANK_TEMPLATE,
    NAVIGATION_ROUTES,
    ROUTE_NAMES,
    NEW_DATASET_POSTFIX,
} from 'utils/constants';

// Types
import type {
    IntlType,
    ImmutableList,
    HistoryType,
    RouteType,
    ImmutableQueryStructure,
    ReduxDispatch,
    InputEvent,
} from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableDataset } from 'services/Dataset/types';

type CurrentDatasetType = 'new' | number;

type Props = {
    // Must be passed:
    route: RouteType,
    currentLocation: string,

    // Injected:
    intl: IntlType,
    history: HistoryType,

    isLoadingCircuit: boolean,
    minChemCircuits: ImmutableQueryStructure<ImmutableCircuit>,
    isLoadingDataset: boolean,
    datasets: ImmutableList<ImmutableDataset>,
};

type State = {
    isOpen: boolean,
    selectedDataset: CurrentDatasetType | null,
};

class MinchemQuickNavigator extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: false,
            selectedDataset: this.getDatasetId(),
        };
    }

    componentDidMount() {
        // $FlowIgnore
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * When the dataset in the props changes, change the default preselected dataset, probably due to dataset being loaded
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.route !== this.props.route) {
            this.setState({
                selectedDataset: this.getDatasetId(),
            });
        }
    }

    componentWillUnmount() {
        // $FlowIgnore
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    menuRef = React.createRef();

    getParams = () => {
        const match = matchPath(this.props.currentLocation, {
            path: this.props.route.path,
            exact: true,
            strict: false,
        });
        if (!match) {
            return null;
        }
        return match.params;
    };

    getCircuitId = () => {
        const params = this.getParams();
        if (!params || !params.circuitId) {
            return null;
        }
        const circuitId = tryParseNumberOrNull(params.circuitId);
        return circuitId;
    };

    getDatasetId = () => {
        const params = this.getParams();
        if (!params || !params.datasetId) {
            return null;
        }
        if (params.datasetId === NEW_DATASET_POSTFIX) {
            return NEW_DATASET_POSTFIX;
        }
        const datasetId = tryParseNumberOrNull(params.datasetId);
        return datasetId;
    };

    getCircuit = (): ImmutableCircuit | null => {
        const circuitId = this.getCircuitId();
        if (!circuitId) {
            return null;
        }
        const circuit = this.props.minChemCircuits
            .get('data', [])
            .find((c: ImmutableCircuit) => c.get('id') === circuitId);
        return circuit;
    };

    getDataset = (): ImmutableDataset | null => {
        const datasetId = this.getDatasetId();
        if (!datasetId) {
            return null;
        }
        const dataset = this.props.datasets.find(
            (d: ImmutableDataset) => d.get('id') === datasetId
        );
        return dataset;
    };

    isOnComputationPages = () => {
        return this.props.route.name === ROUTE_NAMES.DATASET_COMPUTATION;
    };

    /**
     * Get the blank template radio button option.
     */
    getBlankTemplateOption = () => ({
        label: this.props.intl.formatMessage({
            id: 'components.Header.MinchemQuickNavigator.blankTemplate',
        }),
        value: NEW_DATASET_POSTFIX,
    });

    handleClickOutside = (event: InputEvent) => {
        if (
            this.state.isOpen &&
            this.menuRef &&
            this.menuRef.current &&
            !this.menuRef.current.contains(event.target)
        ) {
            this.handleCircuitMenuToggle();
        }
    };

    /**
     * When the selected dataset changes
     */
    handleRadioChange = (selectedDataset: CurrentDatasetType) =>
        this.setState({
            selectedDataset,
        });

    /**
     * When the user clicks the update dataset button
     */
    handleUpdateDatasetClicked = () =>
        this.setState({ isOpen: false }, () =>
            this.handleChangeDataset(this.state.selectedDataset || NEW_DATASET_POSTFIX)
        );

    /**
     * Toggle the circuit menu.
     */
    handleCircuitMenuToggle = () => {
        this.setState((prevState: State) => ({
            isOpen: !prevState.isOpen,
            selectedDataset: this.getDatasetId(),
        }));
    };

    /**
     * Handles the change of a circuit in both MinChem & SolvExtract QuickNavigators
     * pageTypeOverride is used to override the current page type
     */
    handleChangeCircuit = () => {
        return this.props.history.push(NAVIGATION_ROUTES.HOME);
    };

    /**
     * Handles the change of a circuit in both MinChem & SolvExtract QuickNavigators
     * pageTypeOverride is used to override the current page type
     */
    handleChangeDataset = (datasetId: CurrentDatasetType) => {
        const circuitId = this.getCircuitId();
        if (!circuitId) {
            throw new Error('Trying to change dataset when no circuit id is available.');
        }

        // we are changing to a real dataset.
        const url = `${NAVIGATION_ROUTES.COMPUTATION}${circuitId}${
            NAVIGATION_ROUTES.COMPUTATION_DATASET
        }${datasetId}`;
        return this.props.history.push(url);
    };

    /**
     * Is the update dataset button enabled?
     */
    isUpdateDatasetEnabled = () => {
        const datasetId = this.getDatasetId();
        if (!datasetId) {
            return true; // enabled if we have no current dataset. aka on circuit setup page.
        }
        if (!this.state.selectedDataset) {
            return false; // disabled if we have no selected dataset.
        }
        // Enabled if the current dataset isn't the same as our selected dataset.
        return datasetId !== this.state.selectedDataset;
    };

    /**
     * Get the radio options for the dataset list.
     */
    getDatasetOptions = () => {
        const circuit = this.getCircuit();
        if (!circuit) {
            return [];
        }

        return [this.getBlankTemplateOption()].concat(
            circuit
                .get('datasets')
                .map((dataset: ImmutableDataset) => ({
                    label: dataset.get('name'),
                    value: dataset.get('id'),
                }))
                .toArray()
        );
    };

    getDatasetName = () => {
        const dataset = this.getDataset();
        if (dataset) {
            return dataset.get('name');
        }
        return this.props.intl.formatMessage({
            id: 'components.Header.MinchemQuickNavigator.blankTemplate',
        });
    };

    renderCircuitSelector = () => {
        const circuit = this.getCircuit();
        if (!circuit) {
            return null;
        }
        return (
            <ToolTipSection>
                <ToolTipSectionTitle>
                    {this.props.intl.formatMessage({
                        id: 'components.Header.MinchemQuickNavigator.circuitHeader',
                    })}
                </ToolTipSectionTitle>
                <ScrollableField>
                    <ToolTipSectionText>{circuit.get('name')}</ToolTipSectionText>
                    <ToolTipSectionText>{circuit.get('plantName')}</ToolTipSectionText>
                </ScrollableField>
                <SecondaryButton
                    text={this.props.intl.formatMessage({
                        id: 'components.Header.MinchemQuickNavigator.changeCircuitButton',
                    })}
                    onClick={this.handleChangeCircuit}
                />
            </ToolTipSection>
        );
    };

    renderDatasetSelector = () => {
        if (!this.isOnComputationPages()) {
            return null;
        }
        return (
            <ToolTipSection>
                <ToolTipSectionTitle>
                    {this.props.intl.formatMessage({
                        id: 'components.Header.MinchemQuickNavigator.datasetHeader',
                    })}
                </ToolTipSectionTitle>
                <ScrollableField>
                    <RadioButtonSet
                        value={this.state.selectedDataset}
                        options={this.getDatasetOptions()}
                        onClick={this.handleRadioChange}
                    />
                </ScrollableField>
                <SecondaryButton
                    text={this.props.intl.formatMessage({
                        id: 'components.Header.MinchemQuickNavigator.updateDatasetButton',
                    })}
                    onClick={this.handleUpdateDatasetClicked}
                    disabled={!this.isUpdateDatasetEnabled()}
                />
            </ToolTipSection>
        );
    };

    /**
     * Renders the circuit menu that opens/closes
     */
    renderMenuTooltip = () => (
        <MenuToolTip>
            <MenuToolTipContent>
                {this.renderCircuitSelector()}
                {this.renderDatasetSelector()}
            </MenuToolTipContent>
        </MenuToolTip>
    );

    renderCircuitName = () => {
        const circuit = this.getCircuit();
        if (!circuit) {
            return null;
        }
        return <CircuitName>{circuit.get('name')}</CircuitName>;
    };

    renderDatasetName = () => {
        if (!this.isOnComputationPages()) {
            return null;
        }
        if (this.props.isLoadingDataset) {
            return null;
        }
        return <CircuitSub>{this.getDatasetName()}</CircuitSub>;
    };

    render() {
        if (this.props.isLoadingCircuit) {
            return null;
        }
        if (this.isOnComputationPages() && this.props.isLoadingDataset) {
            return null;
        }
        return (
            <Wrapper ref={this.menuRef}>
                <HeaderWrapper onClick={this.handleCircuitMenuToggle}>
                    <CircuitDetails>
                        {this.renderCircuitName()}
                        {this.renderDatasetName()}
                    </CircuitDetails>
                    <CircuitCaret>
                        <Caret up={!this.state.isOpen} down={this.state.isOpen} black />
                    </CircuitCaret>
                </HeaderWrapper>
                {this.state.isOpen && this.renderMenuTooltip()}
            </Wrapper>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isLoadingCircuit: selectMinChemIsQueryingStatus(),
    minChemCircuits: selectMinChemCircuitQuery(),
    isLoadingDataset: selectDatasetsAreFetching(),
    datasets: selectAllDatasets(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(MinchemQuickNavigator))
);
