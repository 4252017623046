// @flow strict

import styled from 'styled-components';

import { LegacyTheme, Row } from 'components/_ReactUI_V1';
import { SimpleList } from 'styles/common';

export const Instructions = styled.p`
    font-size: 13px;
    line-height: 15px;

    color: ${LegacyTheme.defaultSubtleColor7B};

    & + & {
        margin-top: 0;
    }
`;

export const SidebarBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    padding: 0px 20px;
`;

export const SidebarFooter = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${LegacyTheme.defaultBorder};
    padding: 24px 20px 20px 20px;

    > label {
        width: fit-content;
    }
`;

export const Label = styled.label`
    font-size: 13px;
    line-height: 16px;
    color: ${LegacyTheme.defaultSubtleColor7B};
`;

export const KpiRow = styled(Row)`
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 24px;
    }
`;

export const TooltipContainer = styled.div`
    padding: 1px; // Tooltip does not respect the children's margin without a padding (or when set to 0px)
    text-align: left;
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 11px;

    ${SimpleList} {
        margin: 10px;
    }
`;

export const Spacer = styled.div`
    display: inline-block;
    width: 6px;
`;

export const CloseButton = styled.div`
    color: ${LegacyTheme.defaultSubtleColor};
    :hover:enabled {
        color: ${LegacyTheme.defaultColor};
    }
`;
