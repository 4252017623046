// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { NotificationBell, LoadingDots, ToolTip, Close } from 'components/_ReactUI_V1';
import { Title, Subtitle } from 'styles/common';
import NotificationRow from 'components/Notifications/NotificationRow';

// Services
import {
    queryUserNotifications,
    markAllNotificationsAsRead,
    updateUserNotificationReadState,
} from 'services/Notifications/thunks';
import {
    selectQueriedNotificationsCurrentPage,
    selectQueriedNotificationsLastPage,
    selectTotalUnreadNotifications,
    selectMarkAllAsReadIsUpdating,
} from 'services/Notifications/selectors';

// Styles
import {
    IconWrapper,
    NotificationsDrawerWrapper,
    NotificationsDrawerContent,
    CloseButton,
    NotificationsDrawerTopRow,
    NotificationsList,
    LoadingDotsWrapper,
    MarkAllAsRead,
    ToolTipContent,
    InlineLoadingDotsWrapper,
} from './styles';

// Types
import type { HistoryType, IntlType, ReduxDispatch } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableNotifications } from 'services/Notifications/types';

type InjectedProps = {
    intl: IntlType,
    history: HistoryType,
    currentPage: number,
    lastPage: number,
    markAllAsReadIsUpdating: boolean,
    queryUserNotifications: (userId: number, page?: number, perPage?: number) => void,
    markAllNotificationsAsRead: (userId: number) => void,
};

type Props = InjectedProps & {
    user: ImmutableUser,
    queriedNotifications: ImmutableNotifications,
    isQueryingNotifications: boolean,
    totalUnreadNotifications: number,
};

type State = {
    isOpen: boolean,
};

class NotificationsDrawer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: false,
            currentPage: this.props.currentPage,
        };
    }

    drawerRef = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: InputEvent) => {
        if (
            this.state.isOpen &&
            this.drawerRef &&
            this.drawerRef.current &&
            !this.drawerRef.current.contains(event.target)
        ) {
            this.handleDrawerToggle();
        }
    };

    handleDrawerToggle = () =>
        this.setState((prevState: State) => ({
            isOpen: !prevState.isOpen,
        }));

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;
        const isLastPage = this.props.currentPage === this.props.lastPage;

        if (bottom && !isLastPage && !this.props.isQueryingNotifications) {
            this.props.queryUserNotifications(
                this.props.user.get('id'),
                this.props.currentPage + 1
            );
        }
    };

    updateNotificationReadStatus = (notificationId: number, readState: boolean) => {
        this.props.updateUserNotificationReadState(
            this.props.user.get('id'),
            notificationId,
            readState
        );
    };

    handleClickToggle = (notificationId: number, readState: boolean) => {
        this.updateNotificationReadStatus(notificationId, readState);
    };

    handleClickRow = (
        notificationId: number,
        currentReadState: boolean,
        notificationLink: string
    ) => {
        if (!currentReadState) {
            this.updateNotificationReadStatus(notificationId, true);
        }

        this.handleDrawerToggle();

        const url = new URL(notificationLink);
        this.props.history.push(url.pathname);
    };

    handleClickMarkAllAsRead = () => {
        this.props.markAllNotificationsAsRead(this.props.user.get('id'));
    };

    renderNotificationsDrawer = () => (
        <NotificationsDrawerWrapper>
            <NotificationsDrawerContent>
                <CloseButton onClick={this.handleDrawerToggle}>
                    <Close fill="useCurrent" width="35px" margin="none" clickable />
                </CloseButton>

                <NotificationsDrawerTopRow>
                    <Title style={{ margin: '10px 0' }}>Notifications</Title>

                    {this.props.markAllAsReadIsUpdating && (
                        <InlineLoadingDotsWrapper>
                            <LoadingDots />
                        </InlineLoadingDotsWrapper>
                    )}

                    {!this.props.markAllAsReadIsUpdating &&
                        this.props.totalUnreadNotifications > 0 && (
                            <MarkAllAsRead onClick={this.handleClickMarkAllAsRead}>
                                {this.props.intl.formatMessage({
                                    id: `components.Header.NotificationsDrawer.markAllAsRead`,
                                })}
                            </MarkAllAsRead>
                        )}
                </NotificationsDrawerTopRow>

                <NotificationsList onScroll={this.handleScroll}>
                    {!this.props.isQueryingNotifications &&
                        this.props.queriedNotifications.size === 0 && (
                            <Subtitle style={{ margin: '12px 0' }}>
                                {this.props.intl.formatMessage({
                                    id: `components.Header.NotificationsDrawer.noNotifications`,
                                })}
                            </Subtitle>
                        )}

                    {this.props.queriedNotifications.map((notificationData) => (
                        <NotificationRow
                            key={notificationData.get('id')}
                            notificationData={notificationData}
                            onClickToggle={this.handleClickToggle}
                            onClickRow={this.handleClickRow}
                        />
                    ))}

                    {this.props.isQueryingNotifications && (
                        <LoadingDotsWrapper>
                            <LoadingDots />
                        </LoadingDotsWrapper>
                    )}
                </NotificationsList>
            </NotificationsDrawerContent>
        </NotificationsDrawerWrapper>
    );

    render() {
        return (
            <div ref={this.drawerRef}>
                <ToolTip
                    content={
                        <ToolTipContent>
                            {this.props.intl.formatMessage(
                                {
                                    id: `components.Header.NotificationsDrawer.totalUnreadNotifications${
                                        this.props.totalUnreadNotifications > 1 ? 'Plural' : ''
                                    }`,
                                },
                                {
                                    number: this.props.totalUnreadNotifications,
                                }
                            )}
                        </ToolTipContent>
                    }
                    position="left"
                    triggerType="mouseenter"
                    disabled={this.props.totalUnreadNotifications === 0}
                    interactive
                    trigger={
                        <IconWrapper onClick={this.handleDrawerToggle}>
                            <NotificationBell
                                fill="currentColor"
                                width="22px"
                                margin="0"
                                showDot={this.props.totalUnreadNotifications > 0}
                            />
                        </IconWrapper>
                    }
                />
                {this.state.isOpen && this.renderNotificationsDrawer()}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentPage: selectQueriedNotificationsCurrentPage(),
    lastPage: selectQueriedNotificationsLastPage(),
    totalUnreadNotifications: selectTotalUnreadNotifications(),
    markAllAsReadIsUpdating: selectMarkAllAsReadIsUpdating(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        { queryUserNotifications, updateUserNotificationReadState, markAllNotificationsAsRead },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(withRouter(NotificationsDrawer)));
