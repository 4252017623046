// @flow strict

import React from 'react';

// Components
import UserNotificationScheduleDay from './UserNotificationScheduleDay';

// Types
import type { ImmutableNotificationSchedule } from 'services/Notifications/types';

// Constants
import { WEEK_DAYS } from 'utils/constants';

type Props = {
    schedule: ImmutableNotificationSchedule,
    onToggleScheduleDay: (dayName: string) => void,
    onChangeScheduleDayTime: (dayName: string, timeKey: string, timeValue: string) => void,
};

class UserNotificationSchedule extends React.PureComponent<Props> {
    onChange = (event: InputEvent, dayName: string, timeKey: string) => {
        const timeValue = event.target.value;
        this.props.onChangeScheduleDayTime(dayName, timeKey, timeValue);
    };

    onClick = (dayName: string) => {
        this.props.onToggleScheduleDay(dayName);
    };

    render() {
        return (
            <React.Fragment>
                {Object.keys(WEEK_DAYS).map((dayName) => (
                    <UserNotificationScheduleDay
                        key={dayName}
                        schedule={this.props.schedule}
                        dayName={dayName}
                        onToggleScheduleDay={this.props.onToggleScheduleDay}
                        onChangeScheduleDayTime={this.props.onChangeScheduleDayTime}
                    />
                ))}
            </React.Fragment>
        );
    }
}

export default UserNotificationSchedule;
