// @noflow

import React from 'react';

// Styles
import { StyledButtonHover } from './styles';

// Types
import type { ChildrenType } from '../../types';

const ButtonHover = (props: { children: ChildrenType }) => (
    <StyledButtonHover {...props}>{props.children}</StyledButtonHover>
);

export default ButtonHover;
