// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const SidebarSectionWrapper = styled.div`
    border-bottom: 1px solid ${LegacyTheme.defaultBorder};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 360px;
    max-height: 100%;
    overflow: auto;
`;

export const SidebarBody = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled.h3`
    font-size: 15px;
    color: ${LegacyTheme.defaultColor};
    font-weight: normal;

    ${({ withSubtitle }) => withSubtitle && 'margin-bottom: 11px;'};
`;

export const BodySection = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0;
    font-size: 12px;

    & + & {
        border-top: 1px solid ${({ borderColor }) => borderColor || LegacyTheme.defaultBorder};
        margin-top: ${({ header }) => (header ? '20px' : '30px')};
        padding-top: 23px;
    }
`;

export const SubRadioSetWrapper = styled.div`
    margin-left: 30px;
    margin-top: 16px;
`;

export const SubRadioLabel = styled.p`
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    margin-left: 23px;
    margin-top: 8px;
    color: ${LegacyTheme.defaultColor};
`;

export const FooterSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 24px;
`;
