// @flow

import React from 'react';

import type { ChildrenType } from '../../types';

// Styles
import { StyledButtonWrapper } from './styles';

type Props = {
    children?: ChildrenType,
    gutter?: ?string,
    nowrap?: ?boolean,
    right?: ?boolean,
    style?: ?Object,
};

class ButtonWrapper extends React.PureComponent<Props> {
    static defaultProps = {
        children: null,
        gutter: null,
        nowrap: true,
        right: false,
        style: {},
    };

    render() {
        return (
            <StyledButtonWrapper
                gutter={this.props.gutter}
                nowrap={this.props.nowrap}
                right={this.props.right}
                style={this.props.style}
            >
                {this.props.children}
            </StyledButtonWrapper>
        );
    }
}

export default ButtonWrapper;
