// @flow strict

import React from 'react';

// Style
import { StreamValuesWrapper } from './styles';

// Constants
import {
    STREAM_VALUE_TYPES,
    STREAM_CIRCUITS,
    STAGE_TYPES,
    DIAGRAM_DISPLAY_MODES,
} from 'utils/constants';
import { COMPUTE_GRID, SETUP_GRID } from 'components/MimicDiagram/constants';

// Components
import StreamValueInputBox from 'components/MimicDiagram/Stream/StreamValueInputBox';
import EditButton from 'components/MimicDiagram/Stream/EditButton';

// Types
import type { UnitsConstant } from 'types';
import type { StreamEntity, StageTypeConstant } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';
import type { OpenModalFunction } from 'containers/CircuitSetupContainer/MimicDiagramContainer';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    streamData: StreamEntity,
    circuitUnits: UnitsConstant,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,
    showEditLabel: boolean,

    // Feeds dont have a fromStage.
    fromStageColumn: ?number,
    fromStageRow: ?number,
    fromStageType: ?StageTypeConstant,
    // Bleeds dont have a toStage.
    toStageColumn: ?number,
    // toStageRow: ?number,
    // toStageType: ?StageTypeConstant,

    values: ?Array<LooseStreamValue>,

    setStreamValue: SetStreamValueFunction,
    onOpenModal: OpenModalFunction,
};

/**
 * Vertical Stream Values are those that belong to organic circuit and placed between two stages of different type
 * on the vertical organic stream path.
 */
export default class ContinueStream extends React.PureComponent<Props, null> {
    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * Calculate edit button starting column position on the CSS grid
     */
    getEditButtonStartingColumn = () => {
        let startingColumn = null;
        if (
            this.props.streamData.streamCircuit === STREAM_CIRCUITS.AQUEOUS ||
            (this.props.fromStageType === STAGE_TYPES.STRIP &&
                this.props.streamData.streamCircuit === STREAM_CIRCUITS.ORGANIC)
        ) {
            // PLS circuit and organic strip circuit are calculated the same way.
            startingColumn = this.props.fromStageColumn;
        } else {
            // PLS circuit and organic strip circuit are calculated the same way.
            startingColumn = this.props.toStageColumn;
        }
        return startingColumn + this.getGrid().STAGE.COLUMN_SPAN;
    };

    getStartingColumn = (streamValue: LooseStreamValue) => {
        const grid = this.getGrid();
        if (this.props.streamData.streamCircuit === STREAM_CIRCUITS.AQUEOUS) {
            if (streamValue.valueType === STREAM_VALUE_TYPES.PRE_COMPOSITION) {
                // this can only be a PLS stream value.
                return this.props.fromStageColumn + grid.STAGE.COLUMN_SPAN;
            } else if (streamValue.valueType === STREAM_VALUE_TYPES.POST_COMPOSITION) {
                // this can only be a PLS stream value.
                return this.props.toStageColumn - grid.STREAM_VALUES.COLUMN_SPAN;
            }
        } else if (this.props.streamData.streamCircuit === STREAM_CIRCUITS.ORGANIC) {
            if (streamValue.valueType === STREAM_VALUE_TYPES.PRE_COMPOSITION) {
                // this can only be a PLS stream value.
                return this.props.fromStageColumn - grid.STREAM_VALUES.COLUMN_SPAN;
            } else if (streamValue.valueType === STREAM_VALUE_TYPES.POST_COMPOSITION) {
                // this can only be a PLS stream value.
                return this.props.toStageColumn + grid.STAGE.COLUMN_SPAN;
            }
        }
        // TODO: calculate the stream value position for advanced types since theres pre and post composition.
        // TODO: If we have an org bypass, this value will be 5.5, we need to center the continue stream within 3 columns...
        return Math.floor((this.props.fromStageColumn + this.props.toStageColumn) / 2); // if there is only 1 stream type.
    };

    /**
     * Calculate stream value starting row position on the CSS grid
     */
    getStartingRow = () => {
        if (
            this.props.streamData.streamCircuit === STREAM_CIRCUITS.AQUEOUS ||
            (this.props.fromStageType === STAGE_TYPES.STRIP &&
                this.props.streamData.streamCircuit === STREAM_CIRCUITS.ORGANIC)
        ) {
            // PLS circuit and organic strip circuit are calculated the same way.
            return this.props.fromStageRow; // toStageRow
        } else {
            // PLS organic circuit and Electrolyte circuit are calculated the same way.
            return this.props.fromStageRow + this.getGrid().STAGE.ROW_SPAN / 2; // TODO: Minus 1 row?
        }
    };

    // Check if it's a Setup mode
    shouldShowEditButton = (): boolean =>
        this.props.showEditLabel && this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP;

    renderStreamValues = () =>
        this.props.values.map((streamValue: LooseStreamValue) => (
            <StreamValuesWrapper
                key={`${streamValue.valueType}`}
                startingColumn={this.getStartingColumn(streamValue)}
                startingRow={this.getStartingRow()}
                columnSpan={COMPUTE_GRID.CONTINUE_STREAM_VALUES.COLUMN_SPAN}
                rowSpan={COMPUTE_GRID.CONTINUE_STREAM_VALUES.ROW_SPAN}
            >
                <StreamValueInputBox
                    streamValue={streamValue}
                    circuitUnits={this.props.circuitUnits}
                    streamCircuit={this.props.streamData.streamCircuit}
                    setStreamValue={this.props.setStreamValue}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                />
            </StreamValuesWrapper>
        ));

    renderEditButton = () => (
        <StreamValuesWrapper
            startingColumn={this.getEditButtonStartingColumn()}
            startingRow={this.getStartingRow()}
            columnSpan={SETUP_GRID.CONTINUE_STREAM_VALUES.COLUMN_SPAN}
            rowSpan={SETUP_GRID.CONTINUE_STREAM_VALUES.ROW_SPAN}
        >
            <EditButton onOpenModal={this.props.onOpenModal} streamData={this.props.streamData} />
        </StreamValuesWrapper>
    );

    renderSetup = () => this.shouldShowEditButton() && this.renderEditButton();

    render() {
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            return this.renderSetup();
        } else {
            return this.renderStreamValues();
        }
    }
}
