// @flow strict

import React from 'react';

// Components
import { LegacyTheme, Triangle, Close } from 'components/_ReactUI_V1';

// Styles
import { Controls, ReorderWrapper } from './styles';

type Props = {
    currentOrder: number,
    itemCount: number,
    displayVertically?: boolean,

    onReorderItem?: ?(newOrder: number) => void,
    onRemoveItem?: ?() => void,
};

class ReorderControls extends React.PureComponent<Props> {
    static defaultProps = {
        displayVertically: false,
        onRemoveItem: null,
        onReorderItem: null,
    };

    /**
     * When the user clicks on a up/down triangle button, we must reorder the entity
     */
    handleOrderClick = (currentOrder: number, delta: number) => () =>
        this.props.onReorderItem && this.props.onReorderItem(currentOrder + delta);

    /**
     * Render down button (increases the order)
     */
    renderDownButton = () =>
        Boolean(this.props.onReorderItem) &&
        this.props.currentOrder !== 1 && (
            <ReorderWrapper
                onClick={this.handleOrderClick(this.props.currentOrder, -1)}
                displayVertically={this.props.displayVertically}
            >
                <Triangle
                    color={LegacyTheme.white}
                    degrees={0}
                    height={6}
                    width={10}
                    margin="3px 4px 3px 4px"
                />
            </ReorderWrapper>
        );

    /**
     * Render up button (decreases order)
     */
    renderUpButton = () =>
        Boolean(this.props.onReorderItem) &&
        this.props.currentOrder !== this.props.itemCount && (
            <ReorderWrapper
                onClick={this.handleOrderClick(this.props.currentOrder, 1)}
                displayVertically={this.props.displayVertically}
            >
                <Triangle
                    color={LegacyTheme.white}
                    degrees={180}
                    height={6}
                    width={10}
                    margin="4px 4px 2px 4px"
                />
            </ReorderWrapper>
        );

    render() {
        // If itemCount is zero, return early
        if (!this.props.itemCount) {
            return null;
        }
        return (
            // Addition of key prevents awkward bounce (for first instance) due to re-render without up button
            <Controls
                key={this.props.currentOrder}
                displayVertically={this.props.displayVertically}
            >
                {this.renderDownButton()}
                {this.renderUpButton()}
                {Boolean(this.props.onRemoveItem) && (
                    <ReorderWrapper
                        onClick={this.props.onRemoveItem}
                        displayVertically={this.props.displayVertically}
                    >
                        <Close fill={LegacyTheme.white} width={10} margin="none" clickable />
                    </ReorderWrapper>
                )}
            </Controls>
        );
    }
}

export default ReorderControls;
