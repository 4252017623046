// @flow strict

import { decamelizeKeys, decamelize } from 'humps';

import request from 'services/request';

import { TRENDS_PAGE_TYPE } from 'utils/constants';
import type { SearchCriteria } from 'types';
import type {
    TrendsPeriodConstant,
    ImmutableDateRange,
    TrendsPageType,
} from 'services/Trends/types';

/**
 * Fetch the plant overview
 */
export const plantTrend = (plantId: number, period: TrendsPeriodConstant) => {
    const options = decamelizeKeys({
        period,
    });
    return request(`plants/${plantId}/trends`, { method: 'GET' }, { ...options });
};

/**
 * Fetch the circuit overview
 */
export const circuitTrend = (circuitId: number) => {
    return request(`circuits/${circuitId}/trends`, { method: 'GET' });
};

/**
 * Fetch the circuit trend for single KPI
 */
export const kpiTrend = (kpiId: number, period: TrendsPeriodConstant, sinceDate: string) => {
    const options = decamelizeKeys({
        period,
        sinceDate,
    });
    return request(`kpi/${kpiId}/history`, { method: 'GET' }, { ...options });
};

/**
 * Fetch the plant archive
 *
 * @param {number} plantId
 */
export const plantArchive = (
    plantId: number,
    dateRange: ?ImmutableDateRange,
    searchCriteria: ?SearchCriteria = {},
    page: ?number = 1
) => {
    if (!searchCriteria) {
        searchCriteria = {};
    }
    const options = decamelizeKeys({
        page,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder: searchCriteria.sortOrder ? searchCriteria.sortOrder.toLowerCase() : '',
        from: dateRange ? dateRange.get('from') : null,
        to: dateRange ? dateRange.get('to') : null,
    });
    return request(`plants/${plantId}/archive`, { method: 'GET' }, { ...options });
};

/**
 * Fetch the circuit archive
 *
 * @param {number} circuitId
 */
export const circuitArchive = (
    circuitId: number,
    dateRange?: ImmutableDateRange,
    submittedRecommendationsOnly?: boolean = false,
    searchCriteria: ?SearchCriteria = {},
    page: ?number = 1
) => {
    if (!searchCriteria) {
        searchCriteria = {};
    }
    const options = decamelizeKeys({
        page,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder: searchCriteria.sortOrder ? searchCriteria.sortOrder.toLowerCase() : '',
        from: dateRange ? dateRange.get('from') : null,
        to: dateRange ? dateRange.get('to') : null,
        submittedOnly: submittedRecommendationsOnly || false,
    });
    return request(`circuits/${circuitId}/archive`, { method: 'GET' }, { ...options });
};

/**
 * Fetch the dataset values for a circuit/plant
 */
export const datasetValues = (trendsPageType: TrendsPageType, id: number) => {
    const prefix = trendsPageType === TRENDS_PAGE_TYPE.CIRCUIT ? 'datasets' : 'plant-datasets';
    return request(`${prefix}/${id}/values`, { method: 'GET' });
};
