// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Types
import type { IntlType, ImmutableList } from 'types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';
import type { ImmutableUser } from 'services/Authentication/types';

// Components
import { SidebarSection } from 'components/_ReactUI_V1';
import ReagentSelectBody from 'components/ReagentSelectBody';
import IsothermStoichiometryInput from 'components/IsothermStoichiometryInput';

// Styles
import { Body, SectionTitle } from '../styles';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    selectedReagent: ?ImmutableReagent,
    selectedOxime: ?ImmutableOxime,
    loadingCircuit: boolean,
    isothermStoichiometryFactor: number,

    onSelectReagent: (selectedReagent: ImmutableReagent) => void,
    onSelectOxime: (selectedOxime: ImmutableOxime) => void,
    onChangeIsothermStoichiometryFactor: (value: number) => void,
};

class ReagentSidebarSection extends React.PureComponent<Props> {
    render() {
        return (
            <SidebarSection>
                <SectionTitle>
                    {this.props.intl.formatMessage({
                        id: 'components.CircuitSetupSidebar.reagentSection.title',
                    })}
                </SectionTitle>
                <Body>
                    <ReagentSelectBody
                        isLoading={this.props.loadingCircuit}
                        selectedOxime={this.props.selectedOxime}
                        selectedReagent={this.props.selectedReagent}
                        onSelectOxime={this.props.onSelectOxime}
                        onSelectReagent={this.props.onSelectReagent}
                    />
                    <IsothermStoichiometryInput
                        user={this.props.user}
                        loading={this.props.loadingCircuit}
                        isothermStoichiometryFactor={this.props.isothermStoichiometryFactor}
                        onChangeIsothermStoichiometryFactor={
                            this.props.onChangeIsothermStoichiometryFactor
                        }
                    />
                </Body>
            </SidebarSection>
        );
    }
}

export default injectIntl(ReagentSidebarSection);
