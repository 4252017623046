// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import {
    Wrapper,
    DiagramTitle,
    ValuesWrapper,
    ValueBox,
    ValueLabel,
    ValueBoxSection,
} from './styles';
import { colors } from 'styles/colors';

import DiagramLegend, { type LegendItem } from 'components/DiagramLegend';

// Components
import { CalculatorIcon, InputNumber } from 'components/_ReactUI_V1';

// Constants
import {
    DATASET_VALUE_TYPES,
    DATASET_VALUE_LIMITS,
    DIAGRAM_DISPLAY_MODES,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
    VALUE_STATUS,
    VALUE_TYPES_WITH_PRODUCTION_UNITS,
    CIRCUIT_TYPES,
    MIMIC_DIAGRAM_HEADER_VALUES_MEUM,
    MIMIC_DIAGRAM_HEADER_VALUES_SOLVEXTRACT,
} from 'utils/constants';

// helpers
import { clamp, round } from 'utils/helpers';
import { getValueTypeUnit } from 'utils/kpiHelpers';

// Types
import type { IntlType, InputEvent, LooseNumberType, UnitsConstant } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { DatasetValuesConstant, LooseDatasetValue } from 'services/Dataset/types';
import type { HandleDatasetValueChangeFunction } from 'containers/CircuitComputationContainer/MimicContainer';
import type { DiagramDisplayModesConstant } from 'components/MimicDiagram/types';

type Props = {
    intl: IntlType,
    displayMode: DiagramDisplayModesConstant,

    // Setup
    legendItems: Array<LegendItem>,

    // Computation
    circuit: ?ImmutableCircuit,
    circuitUnits: ?UnitsConstant,
    datasetValues: ?Array<LooseDatasetValue>,

    onDatasetValueChange: ?HandleDatasetValueChangeFunction,
};

type State = {};

const DEFAULT_INPUT_NUMBER_STYLES_WITH_MARGIN = {
    ...STYLE_VALUES.DEFAULT_INPUT_NUMBER_STYLES,
    width: '52px',
    marginRight: '3px',
};

/**
 * This class is used to display the mimic diagram header component.
 * In setup mode, this shows the mimic diagram title and the legend.
 * In computation mode, this shows the reagent/oxime concentrations and the legend.
 */
class MimicDiagramHeader extends React.PureComponent<Props, State> {
    /**
     * Helper function to update ComputeCircuitContainer state that holds all compute values
     * @param {LooseNumberType} value
     */
    setDatasetValueChange = (type: DatasetValuesConstant, value: ?LooseNumberType) => {
        const existing = this.props.datasetValues.find(
            (datasetValue: LooseDatasetValue) => datasetValue.valueType === type
        );
        if (existing && existing.value === value) {
            return; // No changes
        }
        if (this.props.onDatasetValueChange) {
            this.props.onDatasetValueChange(type, value);
        }
    };

    /**
     * On blur of input field, update ComputeCircuitContainer state that holds all compute values
     */
    handleStreamInputBlur = (type: DatasetValuesConstant) => (event: InputEvent) => {
        const value =
            event.target.value !== ''
                ? clamp(
                      Number(event.target.value),
                      DATASET_VALUE_LIMITS[type].MINIMUM,
                      DATASET_VALUE_LIMITS[type].MAXIMUM
                  )
                : null;
        this.setDatasetValueChange(type, value);
    };

    /**
     * Handles the change in a values for the circuit value type.
     */
    onValueChange = (type: DatasetValuesConstant) => (event: InputEvent) => {
        const value = event.target.value;
        this.setDatasetValueChange(type, value);
    };

    /**
     * Gets a translated message with the id requested appended to our component unique key.
     */
    getTranslatedMessage = (id: string, data?: {}) =>
        this.props.intl.formatMessage(
            {
                id: `components.MimicDiagramHeader.${id}`,
            },
            data
        );

    /**
     * Get the value for the given dataset value type.
     */
    getDatasetValue = (type: DatasetValuesConstant): ?LooseDatasetValue =>
        this.props.datasetValues &&
        this.props.datasetValues.find(
            (datasetValue: LooseDatasetValue) => datasetValue.valueType === type
        );

    /**
     * Get the label of the dataset value. If the dataset has not been computed yet, we want to show the bare label.
     * If it has been computed, we want to show the value and the unit.
     */
    getTranslatedDatasetValueLabel = (
        valueType: DatasetValuesConstant,
        value: ?float,
        unit: ?string
    ) =>
        this.props.displayMode !== DIAGRAM_DISPLAY_MODES.COMPUTED
            ? this.getTranslatedMessage(`${VALUE_STATUS.COMPUTE_USING}.Labels.${valueType}`)
            : this.getTranslatedMessage(`${VALUE_STATUS.COMPUTE}.Labels.${valueType}`, {
                  value,
                  unit,
              });

    /**
     * Get the unit for the dataset value.
     * @param {DatasetValue} datasetValue The dataset value in JS
     */
    getUnitForValue = (datasetValue: LooseDatasetValue) => {
        if (VALUE_TYPES_WITH_PRODUCTION_UNITS.indexOf(datasetValue.valueType) !== -1) {
            // TODO: MS-735 This should use the KPI setting of the dataset value.
            return this.props.intl.formatMessage({
                id: `constants.ProductionUnits.${this.props.circuit.get('productionUnits')}`,
            });
        }
        return getValueTypeUnit(
            datasetValue.valueType,
            this.props.circuit.get('circuitUnits'),
            this.props.intl
        );
    };

    /**
     * Renders the reagent portion of the header if it exists.
     */
    renderReagent = () => {
        const reagent = this.props.circuit && this.props.circuit.get('reagent');
        if (!reagent) return null;

        const datasetValue = this.getDatasetValue(DATASET_VALUE_TYPES.REAGENT_CONCENTRATION);
        if (!datasetValue) return null;
        const value =
            datasetValue.value === null ? '' : round(datasetValue.value, datasetValue.precision);
        const unit = this.getUnitForValue(datasetValue);
        const reagentName = reagent.get('name');
        return (
            <ValueBox value={!__PROD__ && `${reagentName} - ${value} ${unit}`}>
                <ValueLabel title={!__PROD__ ? reagentName : ''}>
                    {this.getTranslatedMessage('reagent.Label', { reagentName })}
                </ValueLabel>
                <ValueLabel title={!__PROD__ ? datasetValue.value : ''}>
                    {this.getTranslatedDatasetValueLabel(datasetValue.valueType, value, unit)}
                </ValueLabel>
                {datasetValue.status === VALUE_STATUS.COMPUTE_USING &&
                    this.props.displayMode !== DIAGRAM_DISPLAY_MODES.COMPUTED && (
                        <InputNumber
                            handleOnChange={this.onValueChange(datasetValue.valueType)}
                            onBlur={this.handleStreamInputBlur(datasetValue.valueType)}
                            max={DATASET_VALUE_LIMITS[datasetValue.valueType].MAXIMUM}
                            min={DATASET_VALUE_LIMITS[datasetValue.valueType].MINIMUM}
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            style={DEFAULT_INPUT_NUMBER_STYLES_WITH_MARGIN}
                            value={value}
                            unit={unit}
                            renderString={false}
                            noSpinner
                        />
                    )}
            </ValueBox>
        );
    };

    /**
     * Renders the oxime portion of the header if it exists.
     */
    renderOxime = () => {
        const oxime = this.props.circuit && this.props.circuit.get('oxime');
        if (!oxime) return null;

        const oximeGpl = this.getDatasetValue(DATASET_VALUE_TYPES.OXIME_GPL);
        if (!oximeGpl) return null;
        const oximeRatio = this.getDatasetValue(DATASET_VALUE_TYPES.OXIME_RATIO);
        if (!oximeRatio) return null;
        const gplUnit = this.getUnitForValue(oximeGpl);
        const oximeGplValue =
            oximeGpl.value === null || oximeGpl.value === ''
                ? ''
                : round(oximeGpl.value || 0, oximeGpl.precision);
        const oximeRatioValue =
            oximeRatio.value === null || oximeRatio.value === ''
                ? ''
                : round(oximeRatio.value || 0, oximeRatio.precision);
        return (
            <ValueBox>
                <ValueBoxSection>
                    <ValueLabel>
                        {this.getTranslatedMessage('oxime.Label', {
                            oximeName: oxime.get('name'),
                        })}
                    </ValueLabel>
                </ValueBoxSection>
                <ValueBoxSection>
                    <ValueLabel title={!__PROD__ ? oximeGpl.value : ''}>
                        {this.getTranslatedDatasetValueLabel(
                            oximeGpl.valueType,
                            oximeGplValue,
                            gplUnit
                        )}
                    </ValueLabel>
                    {oximeGpl.status === VALUE_STATUS.COMPUTE_USING &&
                        this.props.displayMode !== DIAGRAM_DISPLAY_MODES.COMPUTED && (
                            <InputNumber
                                handleOnChange={this.onValueChange(oximeGpl.valueType)}
                                onBlur={this.handleStreamInputBlur(oximeGpl.valueType)}
                                max={DATASET_VALUE_LIMITS[oximeGpl.valueType].MAXIMUM}
                                min={DATASET_VALUE_LIMITS[oximeGpl.valueType].MINIMUM}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={DEFAULT_INPUT_NUMBER_STYLES_WITH_MARGIN}
                                value={oximeGplValue}
                                unit={gplUnit}
                                renderString={false}
                                noSpinner
                            />
                        )}
                </ValueBoxSection>
                <ValueBoxSection>
                    <ValueLabel title={!__PROD__ ? oximeRatio.value : ''}>
                        {this.getTranslatedDatasetValueLabel(oximeRatio.valueType, oximeRatioValue)}
                    </ValueLabel>
                    {oximeRatio.status === VALUE_STATUS.COMPUTE_USING &&
                        this.props.displayMode !== DIAGRAM_DISPLAY_MODES.COMPUTED && (
                            <InputNumber
                                handleOnChange={this.onValueChange(oximeRatio.valueType)}
                                onBlur={this.handleStreamInputBlur(oximeRatio.valueType)}
                                max={DATASET_VALUE_LIMITS[oximeRatio.valueType].MAXIMUM}
                                min={DATASET_VALUE_LIMITS[oximeRatio.valueType].MINIMUM}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={DEFAULT_INPUT_NUMBER_STYLES_WITH_MARGIN}
                                value={oximeRatioValue}
                                renderString={false}
                                noSpinner
                            />
                        )}
                </ValueBoxSection>
            </ValueBox>
        );
    };

    /**
     * Render all the other simple dataset values.
     */
    renderExtraDatasetValues = () =>
        this.props.datasetValues &&
        this.props.datasetValues
            .filter((datasetValue: LooseDatasetValue) => {
                // if the circuit is solvextract, show the additional dataset KPIs in the list.
                if (
                    this.props.circuit &&
                    this.props.circuit.get('type') === CIRCUIT_TYPES.SOLVEXTRACT_CIRCUIT
                ) {
                    return MIMIC_DIAGRAM_HEADER_VALUES_SOLVEXTRACT.includes(datasetValue.valueType);
                } else {
                    // otherwise show the minchem values only.
                    return MIMIC_DIAGRAM_HEADER_VALUES_MEUM.includes(datasetValue.valueType);
                }
            })
            .map((datasetValue: LooseDatasetValue) => {
                const value =
                    datasetValue.value === null
                        ? ''
                        : round(datasetValue.value, datasetValue.precision);

                const unit = this.getUnitForValue(datasetValue);
                return (
                    <ValueBox
                        key={`${datasetValue.valueType}`}
                        title={!__PROD__ ? datasetValue.value : ''}
                    >
                        {datasetValue.status === VALUE_STATUS.COMPUTE && (
                            <CalculatorIcon margin="0 10px 0 0" fill={colors.grey7B} />
                        )}
                        <ValueLabel>
                            {this.getTranslatedDatasetValueLabel(
                                datasetValue.valueType,
                                value,
                                unit
                            )}
                        </ValueLabel>
                        {datasetValue.status === VALUE_STATUS.COMPUTE_USING &&
                            this.props.displayMode !== DIAGRAM_DISPLAY_MODES.COMPUTED && (
                                <InputNumber
                                    handleOnChange={this.onValueChange(datasetValue.valueType)}
                                    onBlur={this.handleStreamInputBlur(datasetValue.valueType)}
                                    max={DATASET_VALUE_LIMITS[datasetValue.valueType].MAXIMUM}
                                    min={DATASET_VALUE_LIMITS[datasetValue.valueType].MINIMUM}
                                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                                    style={DEFAULT_INPUT_NUMBER_STYLES_WITH_MARGIN}
                                    value={value}
                                    unit={unit}
                                    renderString={false}
                                    noSpinner
                                />
                            )}
                    </ValueBox>
                );
            });

    /**
     * Renders the title for setup mode.
     */
    renderTitle = () => <DiagramTitle>{this.getTranslatedMessage('title')}</DiagramTitle>;

    render() {
        return (
            <Wrapper smallerBottomPadding={this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP}>
                {this.props.displayMode !== DIAGRAM_DISPLAY_MODES.SETUP && this.props.circuit ? (
                    <ValuesWrapper>
                        {this.renderReagent()}
                        {this.renderOxime()}
                        {this.renderExtraDatasetValues()}
                    </ValuesWrapper>
                ) : (
                    this.renderTitle()
                )}
                <DiagramLegend legendItems={this.props.legendItems} />
            </Wrapper>
        );
    }
}

export default injectIntl(MimicDiagramHeader);
