// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { keys } from 'lodash';
import { fromJS } from 'immutable';

// Mimic Engine
import type { IMimicCascade, IMimicStage, IMimicCircuit } from 'components/_McCabeThiele';

// Assets
import {
    LegacyTheme,
    Modal,
    // CheckBox,
    InputSelect,
    PrimaryButton,
    SecondaryButton,
    RadioButtonSet,
} from 'components/_ReactUI_V1';

import calculatorIcon from 'assets/icon-calculator-grey.png';

// Constants
import {
    MODAL_WIDTH,
    STAGE_VALUE_TYPES,
    ISOTHERM_VALUE_MODES,
    DIAGRAM_DISPLAY_MODES,
} from 'utils/constants';

// Styles
import {
    Wrapper,
    Body,
    Footer,
    IsothermInputSelectWrapper,
    MainContentFrame,
    RadioButtonWrapper,
    SubTitleWrapper,
    TitleWrapper,
    RadioButtonSeperatorLine,
} from './styles';

// Components
import { Title } from 'styles/common';
import IsothermDiagram from 'components/IsothermDiagram';
import CascadeDescription from 'components/CascadeDescription';

// Types
import type { IntlType, ImmutableList, ReactSelectObject } from 'types';
import type { SetStageIsothermFunction } from 'containers/CircuitComputationContainer/MimicContainer';
import type { ImmutableStage, StageTypeConstant } from 'services/Circuit/types';
import type { IsothermValueModesConstant, ImmutableStageValue } from 'services/Dataset/types';
import type { ImmutableIsotherm } from 'services/Isotherm/types';

type Props = {
    intl: IntlType,
    mimicCircuit: IMimicCircuit,
    stage: ImmutableStage,

    isotherms: ImmutableList<ImmutableIsotherm>,
    loadingIsotherms: boolean,

    onSelectClicked: SetStageIsothermFunction,
    onCancel: () => void,
};

type State = {
    predictOrSelectIsothermRadioValue: IsothermValueModesConstant,
    selectedIsotherm?: ImmutableIsotherm, // null when predicting.
};

class IsothermSelectModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        // Preselect the isotherm option if it was saved
        const stageIsotherm = props.stage
            .get('values')
            .find(
                (stageValue: ImmutableStageValue) =>
                    stageValue.valueType === STAGE_VALUE_TYPES.ISOTHERM
            );

        if (stageIsotherm) {
            this.state = {
                predictOrSelectIsothermRadioValue: stageIsotherm.isothermMode,
                selectedIsotherm: fromJS(stageIsotherm.isotherm),
            };
        } else {
            // otherwise, show the default state
            this.state = {
                predictOrSelectIsothermRadioValue: ISOTHERM_VALUE_MODES.PREDICT,
                selectedIsotherm: null,
            };
        }
    }

    /**
     * Format single isotherm for `react-ui_input-select` (`options` prop)
     */
    isothermToReactSelectObject = (isotherm: ImmutableIsotherm): ReactSelectObject =>
        isotherm && {
            value: isotherm.get('id'),
            label: isotherm.get('name'),
        };

    /**
     *  Filters all isotherms into just the isotherms of the same type as our cascade.
     */
    isothermToCascadeTypeFilter = (stageType: StageTypeConstant) => (isotherm: ImmutableIsotherm) =>
        isotherm.get('isothermType') === stageType;

    /**
     * Get isotherms list formatted for `react-ui_input-select` (`options` prop)
     */
    getIsothermOptions = (): Array<ReactSelectObject> =>
        this.props.isotherms
            .filter(this.isothermToCascadeTypeFilter(this.props.stage.get('stageType')))
            .map(this.isothermToReactSelectObject)
            .toArray();

    /**
     * Get the radio button options. Either SELECT or PREDICT.
     */
    getRadioOptions = () =>
        keys(ISOTHERM_VALUE_MODES).map((value: string) => ({
            label: this.props.intl.formatMessage({
                id: `components.Modals.IsothermSelectModal.${value.toLowerCase()}Isotherm.RadioLabel`,
            }),
            icon: value === 'PREDICT' ? calculatorIcon : null,
            value,
        }));

    /**
     * Is the select button disabled?
     * It is disabled when we want to select an isotherm from the database, but haven't selected one from the dropdown.
     */
    isSelectButtonDisabled = () =>
        this.state.predictOrSelectIsothermRadioValue === 'SELECT' && !this.state.selectedIsotherm;

    /**
     * Handle isotherm selection on `react-ui_input-select` (`onSelect` prop)
     */
    handleIsothermSelected = (selectedOption: ReactSelectObject) => {
        if (this.props.isotherms) {
            const selectedIsotherm = this.props.isotherms.find(
                (isotherm: ImmutableIsotherm) => isotherm.get('id') === selectedOption.value
            );

            this.setState({ selectedIsotherm });
        }
    };

    /**
     * When a user clicks on a different isotherm radio button
     */
    handlePredictOrSelectIsothermRadioChange = (
        predictOrSelectIsothermRadioValue: IsothermValueModesConstant
    ) =>
        this.setState({
            predictOrSelectIsothermRadioValue,
            selectedIsotherm: null,
        });

    /**
     * Create handler for Isotherm selection
     */
    handleSelectClicked = () =>
        this.props.onSelectClicked(
            this.state.predictOrSelectIsothermRadioValue,
            this.props.stage,
            this.state.selectedIsotherm
        );

    /**
     * Renders the radio button horizontal bar / seperator between the two radio button options
     */
    renderRadioHorizontalBar = (value: string) =>
        value === 'PREDICT' && <RadioButtonSeperatorLine />;

    /**
     * Renders the cascade description (the subtitle)
     */
    renderCascadeDescription = () => {
        const mimicCircuit = this.props.mimicCircuit;
        mimicCircuit.setMode(DIAGRAM_DISPLAY_MODES.COMPUTE);
        const cascades = mimicCircuit.getCascades();

        const cascadeForStage = cascades.find((cascade: IMimicCascade) =>
            cascade.stages.find((stage: IMimicStage) => stage.id === this.props.stage.get('id'))
        );
        if (!cascadeForStage) {
            throw new Error('Unable to find cascade for the stage in Isotherm Select Modal');
        }

        return <CascadeDescription cascade={cascadeForStage} />;
    };

    render() {
        return (
            <Modal modalWidth={MODAL_WIDTH.LARGE} onHandleClose={this.props.onCancel} disableClose>
                <Wrapper>
                    <Body>
                        <TitleWrapper>
                            <Title>
                                {this.props.intl.formatMessage({
                                    id: 'components.Modals.IsothermSelectModal.title',
                                })}
                            </Title>
                        </TitleWrapper>
                        <SubTitleWrapper>{this.renderCascadeDescription()}</SubTitleWrapper>
                        <MainContentFrame>
                            <RadioButtonWrapper>
                                <RadioButtonSet
                                    onClick={this.handlePredictOrSelectIsothermRadioChange}
                                    options={this.getRadioOptions()}
                                    value={this.state.predictOrSelectIsothermRadioValue}
                                    styles={{
                                        labelColor: LegacyTheme.defaultColor,
                                        labelFontSize: '15px',
                                    }}
                                    renderSuffix={this.renderRadioHorizontalBar}
                                />
                            </RadioButtonWrapper>
                            <IsothermInputSelectWrapper>
                                <InputSelect
                                    selectedOption={this.isothermToReactSelectObject(
                                        this.state.selectedIsotherm
                                    )}
                                    options={this.getIsothermOptions()}
                                    onSelect={this.handleIsothermSelected}
                                    isDisabled={
                                        this.state.predictOrSelectIsothermRadioValue !==
                                        ISOTHERM_VALUE_MODES.SELECT
                                    }
                                    isLoading={this.props.loadingIsotherms}
                                    placeholder={this.props.intl.formatMessage({
                                        id:
                                            'components.Modals.IsothermSelectModal.selectIsotherm.SelectPlaceholder',
                                    })}
                                    controlShouldRenderValue
                                />
                            </IsothermInputSelectWrapper>
                            <IsothermDiagram
                                // Isotherm diagram expects an array of isotherms.
                                // However we don't want any nulls in this array, so filter with boolean.
                                isotherms={[this.state.selectedIsotherm].filter(Boolean)}
                                diagramType={this.props.stage.get('stageType')}
                                loading={this.props.loadingIsotherms}
                                small
                            />
                        </MainContentFrame>
                    </Body>
                    <Footer>
                        <div>
                            <SecondaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.cancelButton',
                                })}
                                onClick={this.props.onCancel}
                            />
                            <PrimaryButton
                                text={this.props.intl.formatMessage({
                                    id: 'components.Modals.IsothermSelectModal.selectButton',
                                })}
                                disabled={this.isSelectButtonDisabled()}
                                onClick={this.handleSelectClicked}
                            />
                        </div>
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(IsothermSelectModal);
