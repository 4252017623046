// @flow strict

import React from 'react';

// Styles
import { Common, SecondaryButton, ButtonWrapper, Toggle } from 'components/_ReactUI_V1';

import { Subtitle, FormBlock } from 'styles/common';

// Constants
import { KPI_SETUP_TABLE_CONTEXT_TYPES, MINOR_KPI_SECTIONS } from 'utils/constants';

// Components
import KPISetupTable from 'components/KPISetupTable';

// Types
import type { ImmutableList, LooseKeyArrayType, LooseInputValueTypes, UnitsConstant } from 'types';
import type { ImmutableKPISetting, AllKpiTypes, KPISectionType } from 'services/KPISetting/types';
import type { ImmutableCircuitSettings } from 'services/Circuit/types';

type Props = {
    units: UnitsConstant,
    kpis: ImmutableList<ImmutableKPISetting>,
    displayAsReadOnly: boolean,
    isUpdating: boolean,

    noKPIText: string,
    addAKPIText: string,
    sectionTitle: string,
    sectionKey: KPISectionType,
    circuitSettings: ImmutableCircuitSettings,

    possibleKPITypes: ImmutableList<ImmutableKPISetting>,

    onAddKPI?: ?() => void,
    onInputChange?: ?(keys: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onRemoveKPI?: ?(kpiSetting: ImmutableKPISetting) => () => void,
    onReorderKPI?: ?(kpiSetting: ImmutableKPISetting) => (newOrder: number) => void,
    onChangeKPIContext?: ?(kpiSetting: ImmutableKPISetting, newContextId: number) => void,
    onChangeKPIType?: ?(kpiSetting: ImmutableKPISetting, newType: AllKpiTypes) => void,

    hideStageEfficienciesForPmText?: string,
    onChangeCircuitSettings?: ?(key: LooseKeyArrayType) => (checked: boolean) => void,
};

class MinorKPISectionFormBlock extends React.Component<Props> {
    static defaultProps = {
        onAddKPI: null,
        onAddLeanFeedBleed: null,
        onChangeKPIContext: null,
        onChangeKPIType: null,
        onInputChange: null,
        onRemoveKPI: null,
        onReorderKPI: null,
        hasRemainingLeanFeedBleedKPIs: 0,
    };

    /**
     * Check to see if the provided kpis/decisionTreeOptions have changed, if so allow for an update
     */
    shouldComponentUpdate(nextProps: Props) {
        return (
            !nextProps.kpis.equals(this.props.kpis) ||
            !nextProps.circuitSettings.equals(this.props.circuitSettings)
        );
    }

    /**
     * Provided the current section key matches MINOR_KPI_SECTIONS.STAGE_EFFICIENCIES and the provided label
     */
    renderHideStageEfficienciesForPm = () =>
        this.props.sectionKey === MINOR_KPI_SECTIONS.STAGE_EFFICIENCIES &&
        this.props.circuitSettings &&
        this.props.hideStageEfficienciesForPmText ? (
            <Common.Column alignItems="flex-end">
                <Toggle
                    label={this.props.hideStageEfficienciesForPmText}
                    checked={this.props.circuitSettings.get('hideStageEfficienciesForPm')}
                    onClickHandler={
                        this.props.onChangeCircuitSettings &&
                        this.props.onChangeCircuitSettings('hideStageEfficienciesForPm')
                    }
                    disabled={this.props.displayAsReadOnly || !this.props.onChangeCircuitSettings}
                />
            </Common.Column>
        ) : null;

    render() {
        return (
            <FormBlock fullWidth>
                <Common.Row alignItems="center">
                    <Common.Column>
                        <Subtitle>{this.props.sectionTitle}</Subtitle>
                    </Common.Column>
                    {this.renderHideStageEfficienciesForPm()}
                    {!this.props.displayAsReadOnly && this.props.onAddKPI && (
                        <Common.Column alignItems="flex-end" flex={0}>
                            <SecondaryButton
                                onClick={this.props.onAddKPI}
                                text={this.props.addAKPIText}
                                disabled={!this.props.possibleKPITypes.size}
                            />
                        </Common.Column>
                    )}
                </Common.Row>
                <KPISetupTable
                    units={this.props.units}
                    kpis={this.props.kpis}
                    onInputChange={this.props.onInputChange}
                    onReorderKPI={this.props.onReorderKPI}
                    onRemoveKPI={this.props.onRemoveKPI}
                    onChangeKPIType={this.props.onChangeKPIType}
                    onChangeKPIContext={this.props.onChangeKPIContext}
                    possibleKPITypes={this.props.possibleKPITypes}
                    readonly={this.props.displayAsReadOnly}
                    noKPIMessage={this.props.noKPIText}
                    isUpdating={this.props.isUpdating}
                    context={KPI_SETUP_TABLE_CONTEXT_TYPES.CIRCUIT_MINOR_KPIS}
                />
            </FormBlock>
        );
    }
}

export default MinorKPISectionFormBlock;
