// @flow strict

import React from 'react';

// Mimic Engine
import type { IMimicStream, ISkipStream } from 'components/_McCabeThiele';

// Constants
import {
    STREAM_TYPES,
    VALUE_STATUS,
    DIAGRAM_DISPLAY_MODES,
    STREAM_VALUE_ORDER,
} from 'utils/constants';
import { COMPUTE_GRID, SETUP_GRID } from 'components/MimicDiagram/constants';

// Types
import type { UnitsConstant } from 'types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';
import type { LooseStream, StreamEntity } from 'services/Circuit/types';
import type { LooseStreamValue, DatasetModesConstant } from 'services/Dataset/types';

// Components
import StreamValueInputBox from 'components/MimicDiagram/Stream/StreamValueInputBox';
import SetupStreamOption from 'components/MimicDiagram/Stream/SetupStreamOption';

// Styles
import { StreamValuesWrapper } from './styles';

type Props = {
    mimicStream: IMimicStream,
    streamData: StreamEntity,
    circuitUnits: UnitsConstant,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,

    // compute mode:
    values: ?Array<LooseStreamValue>,

    // Either the from stage is null
    fromStageColumn: ?number,
    fromStageRow: ?number,
    // or the to stage is null. but both cannot be null.
    toStageColumn: ?number,
    toStageRow: ?number,

    setStreamValue: SetStreamValueFunction,
    onRemoveAdvancedStreamValueClicked: (streamData: LooseStream) => void,
};

/**
 * All stream values that belong to Feed or Bleed streams
 */
class SkipFeedBleedStream extends React.PureComponent<Props> {
    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    /**
     * Calculate the starting column of a feed or bleed stream values on the CSS grid.
     */
    getStartingColumn = (): number => {
        const streamType = this.props.streamData.streamType;
        const streamCircuit = this.props.streamData.streamCircuit;
        const grid = this.getGrid();

        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            if (streamType === STREAM_TYPES.BLEED) {
                const startColumn = this.props.fromStageColumn;
                // this is an interstage bleed stream.
                return startColumn + grid.STAGE.COLUMN_SPAN + grid.STREAM_VALUES.COLUMN_SPAN;
            } else if (streamType === STREAM_TYPES.BLEND) {
                const startColumn = this.props.toStageColumn;
                // this is an interstage feed/blend stream.
                return startColumn - grid.STREAM_VALUES.COLUMN_SPAN * 2;
            } else {
                throw new Error(`Unknown stream type ${streamType} passed to SkipFeedBleedStream`);
            }
        } else {
            // Compute mode:
            if (streamType === STREAM_TYPES.BLEED) {
                const startColumn = this.props.fromStageColumn;
                // this is an interstage bleed stream.
                return startColumn + grid.STAGE.COLUMN_SPAN;
            } else if (streamType === STREAM_TYPES.BLEND) {
                const startColumn = this.props.toStageColumn;
                // this is an interstage feed/blend stream.
                return startColumn - grid.STREAM_VALUES.COLUMN_SPAN * 2;
            } else {
                throw new Error(`Unknown stream type ${streamType} passed to SkipFeedBleedStream`);
            }
        }
    };

    getSkipOffset = () => {
        const grid = this.getGrid();
        // we can be sure that the mimic stream has a parent stream
        // because the parent components ensures it, before rendering this component.
        // $FlowIgnore
        const skipStream: ISkipStream = this.props.mimicStream.parentStream;
        const skipStreamsBelow = skipStream.getSkipStreamCountBelow();
        return grid.SKIP.TOP_OFFSET * skipStreamsBelow;
    };

    /**
     * Calculate the starting row of a feed or bleed stream values on the CSS grid.
     */
    getStartingRow = () => {
        const streamType = this.props.streamData.streamType;
        const grid = this.getGrid();

        let startRow = 0;

        if (streamType === STREAM_TYPES.BLEED) {
            startRow = this.props.fromStageRow;
        } else if (streamType === STREAM_TYPES.FEED || streamType === STREAM_TYPES.BLEND) {
            startRow = this.props.toStageRow;
        }
        return (
            startRow -
            this.getSkipOffset() -
            grid.STREAM_VALUES.ROW_SPAN -
            (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? 1 : 0)
        );
    };

    getStreamValueOrder = (streamValue: LooseStreamValue): ?number => {
        if (
            STREAM_VALUE_ORDER[streamValue.valueType] === null ||
            STREAM_VALUE_ORDER[streamValue.valueType] === undefined
        ) {
            return null;
        }

        return STREAM_VALUE_ORDER[streamValue.valueType];
    };

    onRemoveAdvancedValues = () =>
        this.props.onRemoveAdvancedStreamValueClicked &&
        this.props.onRemoveAdvancedStreamValueClicked(this.props.streamData);

    renderSetup = () => (
        <StreamValuesWrapper
            startingColumn={this.getStartingColumn()}
            startingRow={this.getStartingRow()}
            columnSpan={SETUP_GRID.STREAM_VALUES.COLUMN_SPAN}
            rowSpan={SETUP_GRID.STREAM_VALUES.ROW_SPAN}
        >
            <SetupStreamOption
                handleRemoveAdvancedValue={this.onRemoveAdvancedValues}
                streamType={this.props.streamData.streamType}
                streamCircuit={this.props.streamData.streamCircuit}
                showCloseButton
                isStreamInterstageBleed
            />
        </StreamValuesWrapper>
    );

    renderStreamValue = (streamValue: LooseStreamValue) => (
        <StreamValueInputBox
            streamValue={streamValue}
            circuitUnits={this.props.circuitUnits}
            streamCircuit={this.props.streamData.streamCircuit}
            setStreamValue={this.props.setStreamValue}
            isInput={streamValue.status === VALUE_STATUS.COMPUTE_USING}
            displayMode={this.props.displayMode}
            datasetMode={this.props.datasetMode}
        />
    );

    renderCompute = () => {
        // is the direction of the stream values going UPwards from the starting position?
        const isUpwardsDirection = true;
        return (
            this.props.values &&
            this.props.values
                .filter(
                    (steamValue: LooseStreamValue) => this.getStreamValueOrder(steamValue) !== null
                )
                .sort((currentStreamValue: LooseStreamValue, nextStreamValue: LooseStreamValue) => {
                    const first = this.getStreamValueOrder(currentStreamValue);
                    const second = this.getStreamValueOrder(nextStreamValue);
                    return first - second;
                })
                .map((sortedStreamValue: LooseStreamValue, index: number) => (
                    <StreamValuesWrapper
                        key={`${sortedStreamValue.streamId}-${sortedStreamValue.valueType}`}
                        startingColumn={this.getStartingColumn()}
                        startingRow={this.getStartingRow() + index * -1}
                        columnSpan={COMPUTE_GRID.STREAM_VALUES.COLUMN_SPAN}
                        rowSpan={COMPUTE_GRID.STREAM_VALUES.ROW_SPAN}
                    >
                        {this.renderStreamValue(sortedStreamValue)}
                    </StreamValuesWrapper>
                ))
        );
    };

    render() {
        return this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP
            ? this.renderSetup()
            : this.renderCompute();
    }
}

export default SkipFeedBleedStream;
