// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Assets
import { Table, DotMenu, ToolTip, Pagination, Modal, PrimaryButton } from 'components/_ReactUI_V1';

import CaretIcon from 'assets/icons/caret-icon';

// Components
import RecommendationCard from 'components/RecommendationCard';
import { STATUS_TYPES as KPI_STATUS_TYPES } from 'components/KPIStatusTables';
import KPIStatusModal from 'components/Modals/KPIStatusModal';
import DatasetValuesTableModal from 'components/Modals/DatasetValuesTableModal';
import KPIValue from 'components/KPIValue';

// Constants
import {
    NO_RESULT_STRING,
    MODAL_WIDTH,
    TRENDS_PAGE_TYPE,
    DATASET_VALUE_TYPES,
    RECOMMENDATION_FEEDBACK_TYPES,
} from 'utils/constants';

// Helpers
import { getFormattedDateFromString } from 'utils/dateHelpers';
import {
    getSortedRecommendations,
    getRecommendationSetMessages,
    getSubmittedBy,
} from 'utils/recommendationHelpers';

// Styles
import {
    Wrapper,
    MenuToolTip,
    MenuToolTipContent,
    ToolTipButton,
    HeaderWrapper,
    Header,
    CaretWrapper,
    RowExpander,
    Body,
    Label,
    TableWrapper,
    PaginationWrapper,
    SmallHeader,
    RecommendationCardWrapper,
    UpdatedAtLabel,
    DateContainer,
} from './styles';
import { colors } from 'styles/colors';
import { Subtitle, ColumnFlex } from 'styles/common';

// Thunks
import { submitFeedbacks } from 'services/Recommendation/thunks';

// Selectors
import { selectFeedbackIsSubmitting } from 'services/Recommendation/selectors';

// Types
import type { IntlType, ImmutableList, SearchCriteria, LanguageCodeConstant } from 'types';
import type { LooseImmutableDataset } from 'services/types';
import type { DatasetValuesConstant } from 'services/Dataset/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableKPISetting, ImmutableKPICard } from 'services/KPISetting/types';
import type { TrendsPageType } from 'services/Trends/types';
import type {
    ImmutableRecommendation,
    ImmutableRecommendationSet,
    ImmutableRecommendationFeedback,
    RecommendationFeedbackTypeConstants,
} from 'services/Recommendation/types';
import type { ImmutableFormattedRecommendationSetMessage } from 'components/RecommendationSidebar';

const DATE_FORMAT = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
};

type Props = {
    intl: IntlType,

    plantId: ?number, // null if archiveType !== plant
    loading: boolean,
    archiveType: TrendsPageType,
    archive: ImmutableList<LooseImmutableDataset>,
    page: number,
    lastPage: number,
    circuitId: ?number, // null if archiveType !== circuit
    circuit: ?ImmutableCircuit,
    isUserPM: boolean,
    userLocale: LanguageCodeConstant,
    kpiSettings: ?ImmutableList<ImmutableKPISetting>,
    plantTimezone: string,

    onSortBy: (structure: SearchCriteria) => void,
    onChangePage: (page: number) => void,

    isSubmittingFeedback: boolean,
    submitFeedbacks: (
        id: number,
        feedbacks: ImmutableList<ImmutableRecommendationFeedback>
    ) => void,
};

type State = {
    expandedDatasetId: ?number,
    openedMenuDataset: ?LooseImmutableDataset,
    openedModalKPIValues: ?LooseImmutableDataset,
    openedModalKPIStatuses: ?LooseImmutableDataset,

    recommendationFeedbacks: ImmutableList<ImmutableRecommendationFeedback>,
};

class ArchivePageTable extends React.PureComponent<Props, State> {
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const newRecommendationFeedbacks = prevState.recommendationFeedbacks.filter(
            (rf: ImmutableRecommendationFeedback) => {
                // if we can find the recommendation feedback
                // in the next props of the archive dataset's recommendations
                // then keep the recommendation feedback in state. Otherwise filter them out.
                return nextProps.archive.find((dataset: LooseImmutableDataset) => {
                    if (dataset.has('recommendationSet')) {
                        const recommendations = dataset.getIn([
                            'recommendationSet',
                            'recommendations',
                        ]);
                        if (!recommendations) {
                            return false;
                        }
                        return recommendations.find(
                            (r: ImmutableRecommendation) =>
                                rf.get('recommendationId') === r.get('id')
                        );
                    } else {
                        return false;
                    }
                });
            }
        );

        return {
            ...prevState,
            recommendationFeedbacks: newRecommendationFeedbacks,
        };
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            expandedDatasetId: null,
            openedMenuDataset: null,
            openedModalKPIValues: null,
            openedModalKPIStatuses: null,

            recommendationFeedbacks: fromJS([]),
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.isSubmittingFeedback && prevProps.isSubmittingFeedback) {
            this.setState({
                recommendationFeedbacks: fromJS([]),
            });
        }
    }

    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.ArchivePageTable.${key}`,
            },
            data
        );

    isMenuOpen = (dataset: LooseImmutableDataset) =>
        (this.state.openedMenuDataset && this.state.openedMenuDataset.get('id')) ===
        dataset.get('id');

    isDatasetValuesModalOpen = (dataset: LooseImmutableDataset) =>
        (this.state.openedModalKPIValues && this.state.openedModalKPIValues.get('id')) ===
        dataset.get('id');

    isDatasetRowExpanded = (dataset: LooseImmutableDataset) =>
        this.state.expandedDatasetId === dataset.get('id');

    isSubmitFeedbackDisabled = (recommendationSet: ImmutableRecommendationSet) => {
        if (this.props.isSubmittingFeedback) {
            return true;
        }
        return !this.state.recommendationFeedbacks.find((rf: ImmutableRecommendationFeedback) => {
            const recId = rf.get('recommendationId');
            // Find a recommendation in the recommendation set that has a feedback in state.
            return Boolean(
                recommendationSet
                    .get('recommendations', fromJS([]))
                    .find((r: ImmutableRecommendation) => r.get('id') === recId)
            );
        });
    };

    /**
     * Checks if the dataset has recommendation set and has at least one recommendation set message
     */
    hasRecommendationSetMessage = (dataset: LooseImmutableDataset): boolean =>
        Boolean(dataset.get('recommendationSet'));

    /**
     * Checks if the dataset has a plant dataset message
     */
    hasPlantDatasetMessage = (dataset: LooseImmutableDataset): boolean =>
        Boolean(dataset.get('plantDatasetMessage'));

    /**
     * Checks if the dataset has kpi statuses to display
     */
    hasKPIStatuses = (dataset: LooseImmutableDataset): boolean =>
        Boolean(
            (dataset.get(KPI_STATUS_TYPES.VALIDITY) &&
                !dataset.get(KPI_STATUS_TYPES.VALIDITY).isEmpty()) ||
                (dataset.get(KPI_STATUS_TYPES.CALCULATION) &&
                    !dataset.get(KPI_STATUS_TYPES.CALCULATION).isEmpty())
        );

    handleExpandDatasetRow = (dataset: LooseImmutableDataset) => () => {
        if (this.isDatasetRowExpanded(dataset)) {
            this.setState({ expandedDatasetId: null });
        } else {
            this.setState({ expandedDatasetId: dataset.get('id') });
        }
    };

    /**
     * Opens the tooltip menu for a given dataset, or closes it if it is already open
     * @param {LooseImmutableDataset} dataset The dataset row for which we want to open the tooltip menu
     */
    handleMenuOpen = (dataset: LooseImmutableDataset) => () => {
        if (this.isMenuOpen(dataset)) {
            // if the circuit menu is currently open, close it.
            this.setState({ openedMenuDataset: null });
        } else {
            this.setState({ openedMenuDataset: dataset });
        }
    };

    /**
     * Closes the tooltip menu for a given dataset row
     */
    handleMenuClose = () => this.setState({ openedMenuDataset: null });

    /**
     * Opens the KPI values modal
     * @param {LooseImmutableDataset} dataset The dataset for which we want to see the KPI values
     */
    handleDatasetValuesModalOpen = (dataset: LooseImmutableDataset) => () => {
        if (this.isDatasetValuesModalOpen(dataset)) {
            this.setState({ openedModalKPIValues: null });
        } else {
            this.setState({ openedModalKPIValues: dataset });
        }
    };

    /**
     * Closes the currently opened KPI values modal.
     */
    handleDatasetValuesModalClose = () => this.setState({ openedModalKPIValues: null });

    /**
     * Opens the KPI status modal
     * @param {LooseImmutableDataset} dataset The dataset for which we want to see the KPI statuses
     */
    handleOpenKPIStatusesModal = (dataset: LooseImmutableDataset) => () =>
        this.setState({ openedModalKPIStatuses: dataset });

    /**
     * Closes the currently opened KPI status modal
     */
    handleCloseKPIStatusesModal = () => this.setState({ openedModalKPIStatuses: null });

    handleSubmitFeedbacks = (recommendationSet: ImmutableRecommendationSet) => () => {
        const recommendationSetId = recommendationSet.get('id');
        const recommendations = recommendationSet.get('recommendations');
        const rfForRs = this.state.recommendationFeedbacks.filter(
            (rf: ImmutableRecommendationFeedback) =>
                recommendations.find(
                    (r: ImmutableRecommendation) => r.get('id') === rf.get('recommendationId')
                )
        );
        if (rfForRs.size === 0) {
            return;
        }
        this.props.submitFeedbacks(recommendationSetId, rfForRs);
    };

    /**
     * On PM change feedback (either feedback type or comment)
     */
    handleChangeFeedback = (
        recommendationId: number,
        feedbackType: ?RecommendationFeedbackTypeConstants,
        comment: ?string
    ) =>
        this.setState((prevState: State) => {
            const feedbackIdx = prevState.recommendationFeedbacks.findIndex(
                (feedback: ImmutableRecommendationFeedback) =>
                    feedback.get('recommendationId') === recommendationId
            );
            if (feedbackIdx === -1) {
                const newFeedback = fromJS({
                    recommendationId,
                    comment,
                    feedbackType,
                });
                return {
                    recommendationFeedbacks: prevState.recommendationFeedbacks.push(newFeedback),
                };
            }
            return {
                recommendationFeedbacks: prevState.recommendationFeedbacks.updateIn(
                    [feedbackIdx],
                    (feedback: ImmutableRecommendationFeedback) =>
                        feedback.set('comment', comment).set('feedbackType', feedbackType)
                ),
            };
        });

    getRowExtra = (dataset: LooseImmutableDataset) => (
        <MenuToolTip>
            <ToolTip
                content={
                    <MenuToolTipContent>
                        <ToolTipButton onClick={this.handleDatasetValuesModalOpen(dataset)}>
                            {this.getTranslation('viewDatasetValues')}
                        </ToolTipButton>
                        {this.hasKPIStatuses(dataset) && (
                            <ToolTipButton onClick={this.handleOpenKPIStatusesModal(dataset)}>
                                {this.getTranslation('viewKPIStatuses')}
                            </ToolTipButton>
                        )}
                    </MenuToolTipContent>
                }
                position="bottom"
                trigger={<DotMenu active={this.isMenuOpen(dataset)} />}
                triggerType="click"
                onOpen={this.handleMenuOpen(dataset)}
                onClose={this.handleMenuClose}
                interactive
                closeOnInternalClick
            />
        </MenuToolTip>
    );

    /**
     * Gets the KPI type from the major kpi trend data.
     * @param {*} dataset
     * @param {*} kpiType
     */
    getMajorKPITrendData = (dataset: LooseImmutableDataset, kpiType: DatasetValuesConstant) =>
        dataset
            .get('majorKpiTrendData')
            .find(
                (kpiCard: ImmutableKPICard) => kpiCard.getIn(['kpiSetting', 'kpiType']) === kpiType
            );

    /**
     * Renders the cu transfer label
     * @param {*} dataset
     */
    getCuTransfer = (dataset: LooseImmutableDataset) => {
        const kpi = this.getMajorKPITrendData(dataset, DATASET_VALUE_TYPES.CU_TRANSFERRED);
        if (!kpi) {
            return NO_RESULT_STRING;
        }
        return (
            <Label>
                {kpi.getIn(['kpiSetting', 'name'])}: <KPIValue kpiCard={kpi} />
            </Label>
        );
    };

    /**
     * Renders the overall recovery label
     * @param {*} dataset
     */
    getOverallRecovery = (dataset: LooseImmutableDataset) => {
        const kpi = this.getMajorKPITrendData(dataset, DATASET_VALUE_TYPES.OVERALL_RECOVERY);
        if (!kpi) {
            return NO_RESULT_STRING;
        }
        return (
            <Label>
                {kpi.getIn(['kpiSetting', 'name'])}: <KPIValue kpiCard={kpi} />
            </Label>
        );
    };

    /**
     * Renders the major KPI (current status) column cell.
     * @param {} dataset
     */
    getMajorKPIValues = (dataset: LooseImmutableDataset) => (
        <ColumnFlex>
            {this.getCuTransfer(dataset)}
            {this.getOverallRecovery(dataset)}
        </ColumnFlex>
    );

    getStatus = (dataset: LooseImmutableDataset) =>
        this.getTranslation(`status.${this.props.archiveType}.${dataset.get('status')}`);

    getTableHeader() {
        const columns = [
            {
                id: 'dates',
                label: this.getTranslation('Header.date'),
                sortable: true,
            },
            { id: 'status', label: this.getTranslation('Header.status'), sortable: true },
            {
                id: 'recommendationSet',
                label: this.getTranslation(
                    this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT
                        ? 'Header.recommendationSet'
                        : 'Header.plantDatasetMessage'
                ),
                sortable: false,
            },
        ];
        if (this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT) {
            columns.push({
                id: 'recommendationImplemented',
                label: (
                    <SmallHeader>
                        {this.getTranslation('Header.recommendationImplemented')}
                    </SmallHeader>
                ),
                sortable: false,
            });
            columns.push({
                id: 'maxCuTransferImpact',
                label: this.getTranslation('Header.recommendationImpact'),
                sortable: true,
            });
            columns.push({
                id: 'majorKpiValues',
                label: this.getTranslation('Header.currentStatus'),
                sortable: false,
            });
        }
        columns.push({ id: 'extra', label: '', sortable: false });
        return columns;
    }

    getFormattedDate = (date: string) => {
        const formattedDate = getFormattedDateFromString(date, this.props.userLocale, {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: this.props.plantTimezone,
            timeZoneName: 'short',
        });

        return this.getTranslation('submittedAt', {
            date: formattedDate,
        });
    };

    getSubmittedAtDate = (recommendationSet: ImmutableRecommendationSet) =>
        this.getFormattedDate(recommendationSet.get('sentAt'));

    getExpandedContent = (dataset: LooseImmutableDataset) => {
        const isPlant = this.props.archiveType === TRENDS_PAGE_TYPE.PLANT;

        let plantDatasetMessageContent;
        if (isPlant && this.hasPlantDatasetMessage(dataset)) {
            const plantDatasetMessage = dataset.get('plantDatasetMessage');
            plantDatasetMessageContent = (
                <React.Fragment>
                    <Subtitle>{this.getTranslation('plantDatasetMessage.label')}</Subtitle>
                    <div>
                        {plantDatasetMessage.get('commentedBy') && (
                            <Label>
                                {this.getTranslation('plantDatasetMessage.commentedBy', {
                                    name: plantDatasetMessage.getIn(['commentedBy', 'displayName']),
                                })}
                            </Label>
                        )}
                        {plantDatasetMessage.get('createdAt') && (
                            <Label>
                                {this.getFormattedDate(plantDatasetMessage.get('createdAt'))}
                            </Label>
                        )}
                        <ul>
                            <li>{plantDatasetMessage.get('comment')}</li>
                        </ul>
                    </div>
                </React.Fragment>
            );
        }

        let recommendationContent;
        if (!isPlant && this.hasRecommendationSetMessage(dataset)) {
            const recommendationSet = dataset.get('recommendationSet');
            recommendationContent = (
                <React.Fragment>
                    <Subtitle>{this.getTranslation('recommendationSet')}</Subtitle>
                    {this.renderDisplayMessages(recommendationSet)}
                    <RecommendationCardWrapper>
                        {this.renderRecommendationCards(recommendationSet)}
                    </RecommendationCardWrapper>
                    {this.props.isUserPM &&
                        this.hasRecommendationFeedbacksToSubmit(recommendationSet) && (
                            <PrimaryButton
                                onClick={this.handleSubmitFeedbacks(recommendationSet)}
                                disabled={this.isSubmitFeedbackDisabled(recommendationSet)}
                                text={this.getTranslation('submitAllRecommendationFeedbacksButton')}
                                loading={this.props.isSubmittingFeedback}
                                style={{ width: '175px', marginTop: '24px' }}
                            />
                        )}
                </React.Fragment>
            );
        }

        return (
            <Body>
                {recommendationContent}
                {plantDatasetMessageContent}
            </Body>
        );
    };

    getRecommendationSetRowExpander = (dataset: LooseImmutableDataset) => {
        const isPlant = this.props.archiveType === TRENDS_PAGE_TYPE.PLANT;
        const label = this.getTranslation(
            isPlant ? 'plantDatasetMessage.view' : 'viewRecommendationSet'
        );
        const hasRecommendationData = isPlant
            ? this.hasPlantDatasetMessage(dataset)
            : this.hasRecommendationSetMessage(dataset);

        if (hasRecommendationData) {
            return (
                <RowExpander onClick={this.handleExpandDatasetRow(dataset)}>
                    {label}
                    <CaretWrapper open={this.isDatasetRowExpanded(dataset)}>
                        <CaretIcon stroke={colors.grey7B} />
                    </CaretWrapper>
                </RowExpander>
            );
        } else {
            return NO_RESULT_STRING;
        }
    };

    getDateContent = (dataset: LooseImmutableDataset) => {
        const rawCreatedAt = dataset.get('createdAt');
        const rawUpdatedAt = dataset.get('updatedAt');

        const createdAt = getFormattedDateFromString(rawCreatedAt, this.props.userLocale, {
            ...DATE_FORMAT,
            timeZone: this.props.plantTimezone,
        });

        const updatedAt =
            rawCreatedAt !== rawUpdatedAt ? (
                <UpdatedAtLabel>
                    {this.props.intl.formatMessage(
                        {
                            id: `common.dates.updatedAt`,
                        },
                        {
                            date: getFormattedDateFromString(rawUpdatedAt, this.props.userLocale, {
                                ...DATE_FORMAT,
                                timeZone: this.props.plantTimezone,
                            }),
                        }
                    )}
                </UpdatedAtLabel>
            ) : null;

        return (
            <DateContainer>
                <div>{createdAt}</div>
                {updatedAt}
            </DateContainer>
        );
    };

    getTableRows = (expandedDatasetId: ?number) =>
        this.props.archive
            .map((dataset: LooseImmutableDataset) => ({
                id: dataset.get('id'),
                dates: this.getDateContent(dataset),
                status: this.getStatus(dataset),
                recommendationSet: this.getRecommendationSetRowExpander(dataset),
                extra: this.getRowExtra(dataset),
                expandedContent:
                    expandedDatasetId && expandedDatasetId === dataset.get('id')
                        ? this.getExpandedContent(dataset)
                        : null,

                ...(this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT
                    ? {
                          recommendationImplemented: this.getHasImplementedRecommendation(dataset),
                          maxCuTransferImpact: this.getRecommendationImpact(dataset),
                          majorKpiValues: this.getMajorKPIValues(dataset),
                      }
                    : {}),
            }))
            .toArray();

    /**
     * Renders the first recommendation impact with the highest Cu Transfer return if the archive type is circuit.
     * Otherwise renders nothing (and should not have been called.)
     * @param {LooseImmutableDataset} dataset
     */
    getRecommendationImpact = (dataset: LooseImmutableDataset) => {
        if (this.props.archiveType !== TRENDS_PAGE_TYPE.CIRCUIT) {
            return null;
        }
        let rSet: ImmutableList<ImmutableRecommendation> = dataset.getIn([
            'recommendationSet',
            'recommendations',
        ]);
        if (!rSet || rSet.isEmpty()) {
            return NO_RESULT_STRING;
        }
        const willDo = rSet.filter((r: ImmutableRecommendation) => {
            const feedback = r.getIn(['recommendationFeedback', 'feedbackType']);
            return feedback === RECOMMENDATION_FEEDBACK_TYPES.WILL_DO_IT;
        });
        if (willDo.size !== 0) {
            rSet = willDo;
        }
        // take the maximum
        const sortedRSet = rSet.sort((a: ImmutableRecommendation, b: ImmutableRecommendation) => {
            const aImpact = a.get('cuTransferImpact');
            const bImpact = b.get('cuTransferImpact');
            if (!aImpact && aImpact !== 0) {
                return 1;
            }
            if (!bImpact && bImpact !== 0) {
                return -1;
            }
            return bImpact - aImpact;
        });
        const firstRecommendation = sortedRSet.first();

        const cuTransferKpi = this.getMajorKPITrendData(
            dataset,
            DATASET_VALUE_TYPES.CU_TRANSFERRED
        );
        const overallRecoveryKpi = this.getMajorKPITrendData(
            dataset,
            DATASET_VALUE_TYPES.OVERALL_RECOVERY
        );
        const cuTransferKpiSetting = cuTransferKpi.get('kpiSetting');
        const overallRecoveryKpiSetting = overallRecoveryKpi.get('kpiSetting');

        return (
            <ColumnFlex>
                <Label>
                    {cuTransferKpiSetting.get('name')}:{' '}
                    <KPIValue
                        kpiSetting={cuTransferKpiSetting}
                        value={firstRecommendation.get('cuTransferImpact')}
                    />
                </Label>
                <Label>
                    {overallRecoveryKpiSetting.get('name')}:{' '}
                    <KPIValue
                        kpiSetting={overallRecoveryKpiSetting}
                        value={firstRecommendation.get('overallRecoveryImpact')}
                    />
                </Label>
            </ColumnFlex>
        );
    };

    /**
     * Returns YES if there is at least 1 will do on a recommendation.
     * Returns NO if there is no YES recommendation, and there is at least 1 no recommendation.
     * Returns -- otherwise.
     */
    getHasImplementedRecommendation = (dataset: LooseImmutableDataset) => {
        if (this.props.archiveType !== TRENDS_PAGE_TYPE.CIRCUIT) {
            return null;
        }
        const rSet = dataset.getIn(['recommendationSet', 'recommendations']);
        if (!rSet || rSet.isEmpty()) {
            return NO_RESULT_STRING;
        }

        let hasImplementedOneRecommendation = false;
        let hasDeclinedOneRecommendation = false;
        rSet.forEach((r: ImmutableRecommendation) => {
            const feedback = r.getIn(['recommendationFeedback', 'feedbackType']);
            switch (feedback) {
                case RECOMMENDATION_FEEDBACK_TYPES.WILL_DO_IT: {
                    hasImplementedOneRecommendation = true;
                    break;
                }
                case RECOMMENDATION_FEEDBACK_TYPES.OTHER: {
                    break;
                }
                case RECOMMENDATION_FEEDBACK_TYPES.DECLINE: {
                    hasDeclinedOneRecommendation = true;
                    break;
                }
                default:
                    break;
            }
        });
        if (hasImplementedOneRecommendation) {
            return this.getTranslation('implementedRecommendation.YES');
        } else if (hasDeclinedOneRecommendation) {
            return this.getTranslation('implementedRecommendation.NO');
        } else {
            return NO_RESULT_STRING;
        }
    };

    hasRecommendationFeedbacksToSubmit = (recommendationSet: ImmutableRecommendationSet) => {
        const recommendations = recommendationSet.get('recommendations');
        if (recommendations.size === 0) {
            return false;
        }
        // Find the first recommendation without a feedback.
        // if it can be found then we can submit this recommendation set.
        return Boolean(
            recommendations.find((r: ImmutableRecommendation) => !r.get('recommendationFeedback'))
        );
    };

    getRecommendationFeedback = (recommendation: ImmutableRecommendation) =>
        this.state.recommendationFeedbacks.find(
            (rf: ImmutableRecommendationFeedback) =>
                rf.get('recommendationId') === recommendation.get('id')
        );

    renderDisplayMessages = (recommendationSet: ImmutableRecommendationSet) => (
        <div>
            {!recommendationSet.get('sentAt') && (
                <Label>{this.getTranslation('wasNotSubmitted')}</Label>
            )}
            {recommendationSet.get('sentByName') && (
                <Label>{getSubmittedBy(recommendationSet, this.props.intl)}</Label>
            )}
            {recommendationSet.get('sentAt') && (
                <Label>{this.getSubmittedAtDate(recommendationSet)}</Label>
            )}
            <ul>
                {getRecommendationSetMessages(
                    recommendationSet,
                    this.props.kpiSettings,
                    this.props.circuit,
                    this.props.intl
                ).map((dmItem: ImmutableFormattedRecommendationSetMessage) => (
                    <li key={dmItem.get('id')}>{dmItem.get('value')}</li>
                ))}
            </ul>
        </div>
    );

    renderRecommendationCards = (recommendationSet: ImmutableRecommendationSet) =>
        getSortedRecommendations(recommendationSet, false).map(
            (recommendation: ImmutableRecommendation, index: number) => (
                <RecommendationCard
                    key={recommendation.get('id')}
                    recommendation={recommendation}
                    kpiSettings={this.props.kpiSettings}
                    circuit={this.props.circuit}
                    isUserPM={this.props.isUserPM}
                    userLocale={this.props.userLocale}
                    timezone={this.props.plantTimezone}
                    cardIndex={index}
                    recommendationSetWasSubmitted={recommendationSet.get('sentAt') !== null}
                    showSubmissionTitle={!this.props.isUserPM}
                    feedback={this.getRecommendationFeedback(recommendation)}
                    onChangeFeedback={this.handleChangeFeedback}
                    readOnly
                />
            )
        );

    renderDatasetValuesModal = () => {
        if (this.state.openedModalKPIValues) {
            const datasetId = this.state.openedModalKPIValues.get('id', null);
            if (!datasetId) {
                throw new Error(
                    'Unable to load dataset value model without a dataset, something went wrong.'
                );
            }
            let plantId = this.props.plantId;
            let plantDatasetId = datasetId;
            if (this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT) {
                plantDatasetId = this.state.openedModalKPIValues.get('plantDatasetId');
                plantId = this.props.circuit.get('plantId');
            }
            return (
                <Modal
                    padding="none"
                    modalWidth={MODAL_WIDTH.XLARGE}
                    modalHeight="90%"
                    onHandleClose={this.handleDatasetValuesModalClose}
                >
                    <DatasetValuesTableModal
                        key={`${this.props.archiveType}-${datasetId}`}
                        datasetId={datasetId}
                        plantId={plantId}
                        plantDatasetId={plantDatasetId}
                        trendsPageType={this.props.archiveType}
                    />
                </Modal>
            );
        }
    };

    renderValidityStatusModal = () =>
        this.state.openedModalKPIStatuses && (
            <Modal
                padding="none"
                modalWidth={MODAL_WIDTH.XLARGE}
                modalHeight="90%"
                onHandleClose={this.handleCloseKPIStatusesModal}
            >
                <KPIStatusModal
                    dataset={this.state.openedModalKPIStatuses}
                    kpiSettings={this.props.kpiSettings}
                />
            </Modal>
        );

    render() {
        const rows = this.getTableRows(this.state.expandedDatasetId);
        return (
            <Wrapper>
                {this.renderDatasetValuesModal()}
                {this.renderValidityStatusModal()}
                <HeaderWrapper>
                    <Header>{this.getTranslation('title')}</Header>
                    {/* TODO: MS-63
                    <SecondaryButton
                        text={this.getTranslation('exportButton')}
                        disabled={this.props.loading}
                    /> */}
                </HeaderWrapper>
                <TableWrapper>
                    <Table
                        header={this.getTableHeader()}
                        rows={rows}
                        loading={this.props.loading}
                        onSortBy={this.props.onSortBy}
                        tdVerticalAlign="top"
                        tdMaxWidth="500px"
                        tdWordBreak="break-word"
                        expandedDataset={this.state.expandedDatasetId}
                        footerMessage={
                            !rows.length && !this.props.loading
                                ? this.getTranslation('noRows')
                                : null
                        }
                    />
                </TableWrapper>
                <PaginationWrapper>
                    <Pagination
                        currentPage={this.props.page}
                        isLoading={this.props.loading}
                        onPageSelection={this.props.onChangePage}
                        pagesTotal={this.props.lastPage}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryJoiner',
                        })}
                    />
                </PaginationWrapper>
            </Wrapper>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        isSubmittingFeedback: selectFeedbackIsSubmitting(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            submitFeedbacks,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ArchivePageTable))
);
