// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Hr } from 'components/_ReactUI_V1';
import PlantStep from 'components/PlantManager/PlantSetupSteps/PlantStep';
import KPIStep from 'components/PlantManager/PlantSetupSteps/KPIStep';

// Constants
import { PLANT_CREATION_STEPS } from 'utils/constants';

// Styles
import { Title, FormContainer } from 'styles/common';
import { StepBlock } from './styles';

// Types
import type { ImmutableList, IntlType } from 'types';
import type { ImmutableClient } from 'services/Client/types';
import type { ImmutablePlant, PlantCreationStepConstant } from 'services/Plant/types';

type Props = {
    plant: ImmutablePlant,
    intl: IntlType,
    clients: ImmutableList<ImmutableClient>,
    onReturnToStep: (step: PlantCreationStepConstant) => () => void,
};

class ReviewStep extends React.PureComponent<Props> {
    STEP_KEY = PLANT_CREATION_STEPS.REVIEW;

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.PlantManager.PlantSetupSteps.${this.STEP_KEY}.${id}`,
        });

    render() {
        return (
            <FormContainer>
                <Title>{this.getTranslation('title')}</Title>

                <StepBlock>
                    <PlantStep
                        clients={this.props.clients}
                        plant={this.props.plant}
                        onReturnToStep={this.props.onReturnToStep}
                    />
                </StepBlock>

                <StepBlock>
                    <KPIStep plant={this.props.plant} onReturnToStep={this.props.onReturnToStep} />
                </StepBlock>
            </FormContainer>
        );
    }
}

export default injectIntl(ReviewStep);
