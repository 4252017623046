// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import momentTZ from 'moment-timezone';

// Components
import { InputSelect } from 'components/_ReactUI_V1';

// Constants
import { STYLE_VALUES } from 'utils/constants';

// Styles
import { FormBlock, FormLabel } from 'styles/common';

// Types
import type { IntlType, ReactSelectObject } from 'types';

type TimezoneType = {
    value: string,
    label: string,
};

type Props = {
    intl: IntlType,
    selectedTimezone: ?string,
    isDisabled?: boolean,
    renderString?: boolean,
    maxMenuHeight: ?string,

    onSelect: (key: string) => (value: ReactSelectObject) => void,
};
type State = {
    timezones: Array<TimezoneType>,
};

class SelectTimezoneField extends React.PureComponent<Props, State> {
    static defaultProps = {
        isDisabled: false,
        renderString: false,
        maxMenuHeight: STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE
    };

    state = {
        timezones: momentTZ.tz.names().map((name: string) => ({
            value: name,
            label: name,
        })),
    };

    /**
     * Convert the timezone to a drop down select object
     */
    getValueToReactSelectObject = (value: ?string): ?ReactSelectObject =>
        value
            ? {
                  value,
                  label: value,
              }
            : null;

    render() {
        return (
            <FormBlock>
                <FormLabel>
                    {this.props.intl.formatMessage({
                        id: 'components.SelectTimezoneField.header',
                    })}
                </FormLabel>
                <InputSelect
                    selectedOption={this.getValueToReactSelectObject(this.props.selectedTimezone)}
                    options={this.state.timezones}
                    maxMenuHeight={this.props.maxMenuHeight}
                    onSelect={this.props.onSelect}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.SelectTimezoneField.placeholder',
                    })}
                    isDisabled={this.props.isDisabled}
                    renderString={this.props.renderString}
                    controlShouldRenderValue
                />
            </FormBlock>
        );
    }
}

export default injectIntl(SelectTimezoneField);
