// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import type { DraftEditor } from 'react-draft-wysiwyg';

// Components
import {
    Column,
    PrimaryButton,
    Row,
    SidebarLayout,
    SidebarTab,
    TextFieldRich,
    TextFieldRichGlobalStyles,
    htmlFromEditorState,
} from 'components/_ReactUI_V1';

// Constants
import { STYLE_VALUES } from 'utils/constants';

// Styles
import { Info } from './styles';

// Types
import type { ErrorType, ReactNode, IntlType, ImmutableList } from 'types';
import type { ImmutableDisclaimer, DisclaimerTypes } from 'services/Disclaimer/types';

type Props = {
    error: ErrorType,
    disclaimers: ImmutableList<ImmutableDisclaimer>,
    onSave: (number, ImmutableDisclaimer) => void,
    intl: IntlType,
    isCreating: boolean,
};

type State = {
    activeDisclaimerType: DisclaimerTypes,
};

class DisclaimerManager extends React.Component<Props, State> {
    state = {
        activeDisclaimerType: this.props.disclaimers.getIn([0, 'type']),
    };

    editorReference = React.createRef();

    handleOnSave = (id: number) => () => {
        if (
            this.editorReference &&
            this.editorReference.props &&
            this.editorReference.props.editorState
        ) {
            const activeDisclaimer = this.getActiveDisclaimer();
            const disclaimerWithNewContent = activeDisclaimer.set(
                'content',
                htmlFromEditorState(this.editorReference.props.editorState)
            );

            this.props.onSave(id, disclaimerWithNewContent);
        }
    };

    onDisclaimerSelection = (type: DisclaimerTypes) => () => {
        this.setState({
            activeDisclaimerType: type,
        });
    };

    getActiveDisclaimer = (): ImmutableDisclaimer =>
        this.props.disclaimers.find(
            (disclaimer: ImmutableDisclaimer) =>
                disclaimer.get('type') === this.state.activeDisclaimerType
        );

    getTextFieldRichRef = (ref: DraftEditor) => {
        this.editorReference = ref;
    };

    renderMain = () => {
        const activeDisclaimer = this.getActiveDisclaimer();

        return (
            <React.Fragment key={this.state.activeDisclaimerType}>
                <TextFieldRichGlobalStyles />
                <Row>
                    <TextFieldRich
                        initialValue={activeDisclaimer.get('content')}
                        editorRef={this.getTextFieldRichRef}
                        disabled={this.props.isCreating}
                    />
                </Row>
                <Row flex="0">
                    <Column>
                        <Info>{activeDisclaimer.get('info')}</Info>
                    </Column>
                    <Column alignItems="flex-end">
                        <PrimaryButton
                            loading={this.props.isCreating}
                            onClick={this.handleOnSave(activeDisclaimer.get('id'))}
                            text={this.props.intl.formatMessage({
                                id: 'components.DisclaimerManager.actionButton',
                            })}
                        />
                    </Column>
                </Row>
            </React.Fragment>
        );
    };

    renderSidebar = (): Array<ReactNode> =>
        this.props.disclaimers &&
        this.props.disclaimers.map((disclaimer: ImmutableDisclaimer) => {
            return (
                <SidebarTab
                    key={disclaimer.get('type')}
                    active={disclaimer.get('type') === this.state.activeDisclaimerType}
                    handleOnHeaderClick={this.onDisclaimerSelection(disclaimer.get('type'))}
                    title={disclaimer.get('title')}
                />
            );
        });

    render() {
        if (!this.props.disclaimers) {
            return null;
        }

        return (
            <SidebarLayout
                styles={{
                    collapsibleIcon: {
                        top: `calc(20px + ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                    sidebar: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                    main: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        overflowY: 'hidden',
                    },
                }}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                renderMain={this.renderMain}
                renderSidebar={this.renderSidebar}
                collapsible
                flush
            />
        );
    }
}

export default injectIntl(DisclaimerManager);
