// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { BackendOxime, OximeQueryType } from './types';

export const CREATE_SUCCESS = 'app/services/Oxime/CREATE_SUCCESS';
export const CREATE_FAILURE = 'app/services/Oxime/CREATE_FAILURE';
export const FETCH_LIST_SUCCESS = 'app/services/Oxime/FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'app/services/Oxime/FETCH_LIST_FAILURE';
export const UPDATE_SUCCESS = 'app/services/Oxime/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Oxime/UPDATE_FAILURE';
export const DESTROY_SUCCESS = 'app/services/Oxime/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'app/services/Oxime/DESTROY_FAILURE';
export const QUERY_LIST_SUCCESS = 'app/services/Oxime/QUERY_LIST_SUCCESS';
export const QUERY_LIST_FAILURE = 'app/services/Oxime/QUERY_LIST_FAILURE';
export const IS_FETCHING = 'app/services/Oxime/IS_FETCHING';
export const IS_UPDATING = 'app/services/Oxime/IS_UPDATING';
export const IS_DELETING = 'app/services/Oxime/IS_DELETING';
export const IS_CREATING = 'app/services/Oxime/IS_CREATING';
export const IS_QUERYING = 'app/services/Oxime/IS_QUERYING';

export const receivedCreateSuccess = (data: BackendOxime) => ({
    type: CREATE_SUCCESS,
    payload: { data },
});

export const receivedCreateFailure = (errors: {}) => ({
    type: CREATE_FAILURE,
    payload: { errors },
});

export const receivedFetchListSuccess = (data: Array<BackendOxime>) => ({
    type: FETCH_LIST_SUCCESS,
    payload: { data },
});

export const receivedFetchListFailure = (errors: {}) => ({
    type: FETCH_LIST_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: BackendOxime) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const receivedDestroySuccess = (data: BackendOxime) => ({
    type: DESTROY_SUCCESS,
    payload: { data },
});

export const receivedDestroyFailure = (errors: {}) => ({
    type: DESTROY_FAILURE,
    payload: { errors },
});

export const setIsFetchingStatus = (isFetching: boolean = true) => ({
    type: IS_FETCHING,
    payload: { isFetching },
});

export const receivedQueryListSuccess = (data: OximeQueryType) => ({
    type: QUERY_LIST_SUCCESS,
    payload: { data },
});

export const receivedQueryListFailure = (errors: {}) => ({
    type: QUERY_LIST_FAILURE,
    payload: { errors },
});

export const setIsUpdatingStatus = (isUpdating: boolean = true) => ({
    type: IS_UPDATING,
    payload: { isUpdating },
});

export const setIsDeletingStatus = (isDeleting: boolean = true) => ({
    type: IS_DELETING,
    payload: { isDeleting },
});

export const setIsCreatingStatus = (isCreating: boolean = true) => ({
    type: IS_CREATING,
    payload: { isCreating },
});

export const setIsQueryStatus = (isQuerying: boolean = true) => ({
    type: IS_QUERYING,
    payload: { isQuerying },
});
