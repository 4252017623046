// @flow strict

import type { ImmutableMap, ImmutableList, ErrorType } from 'types';
import type { ImmutablePartialUser } from 'services/User/types';
import type { ImmutablePlant } from 'services/Plant/types';
import {
    ISOTHERM_BASE_CONSTANT,
    ISOTHERM_POLYNOMIAL_TYPE,
    ISOTHERM_DATAPOINTS_TYPE,
    ISOTHERM_SIDEBAR_TYPES,
} from 'utils/constants';

export type IsothermBaseConstant = $Keys<typeof ISOTHERM_BASE_CONSTANT>;

export type IsothermPolynomialType = $Keys<typeof ISOTHERM_POLYNOMIAL_TYPE>;

export type IsothermDataPointsType = $Keys<typeof ISOTHERM_DATAPOINTS_TYPE>;

export type IsothermSidebarTypes = $Keys<typeof ISOTHERM_SIDEBAR_TYPES>;

export type RawIsothermDataPoint = {
    aqueousConcentration: number,
    organicConcentration: number,
};

export type IsothermDataPoint = RawIsothermDataPoint & {
    id: number,
    isothermId: number,
};

export type RawIsothermCoefficient = {
    coefficient: number,
    coefficientIndex: number,
};

export type IsothermCoefficient = RawIsothermCoefficient & {
    id: number,
    isothermId: number,
};

export type RawIsotherm = {
    reagentId: ?number,
    oximeId: ?number,
    metalId: number,
    name: string,
    polynomialOrder: ?number,
    polynomialType: ?IsothermPolynomialType,
    isothermType: ?IsothermBaseConstant,

    // when reagent is a Solvay
    reagentConcentration: ?number,
    // When reagent is a Custom reagent
    oximeRatio: ?number,
    oximeGpl: ?number,

    // For isothermType === EXTRACT
    plsCu: ?number,
    plsPh: ?number,
    isothermStoichiometryFactor: ?number,
    // For isothermType === STRIP
    spentMetal: ?number,
    spentAcid: ?number,

    referenceDataPoints: Array<RawIsothermDataPoint>,
    computedDataPoints: ?Array<RawIsothermDataPoint>,
    coefficients: Array<RawIsothermCoefficient>,
};

export type ImmutableIsothermShareAccess = ImmutableMap<
    string,
    {
        sharedByName: string,
        sharedByUserId: string,
        // Will not be available if viewed by PMs
        sharedToUsers?: ImmutableList<ImmutablePartialUser>,
        sharedToPlant?: ImmutablePlant,
    }
>;

export const ISOTHERM_NAME_MAX_LENGTH = 255;

export type Isotherm = {
    id: number,
    createdAt: string,
    updatedAt: string,

    shareAccess: ImmutableIsothermShareAccess,

    userId: number,
    reagentId: number,
    oximeId: number,
    metalId: number,
    name: string,
    polynomialType: IsothermPolynomialType,
    isothermType: IsothermBaseConstant,

    // when reagent is a Solvay
    reagentConcentration: number,
    // When reagent is a Custom reagent
    oximeRatio: number,
    oximeGpl: number,

    // For isothermType === EXTRACT
    plsCu: number,
    plsPh: number,
    isothermStoichiometryFactor: ?number,
    // For isothermType === STRIP
    spentMetal: number,
    spentAcid: number,

    referenceDataPoints: Array<IsothermDataPoint>,
    computedDataPoints: Array<IsothermDataPoint>,
    coefficients: Array<IsothermCoefficient>,
};

export type ImmutableIsothermCoefficient = ImmutableMap<string, IsothermCoefficient>;

export type ImmutableIsotherm = ImmutableMap<string, Isotherm>;

export type ImmutableIsothermDataPoint = ImmutableMap<string, IsothermDataPoint>;

export type ImmutableIsothermState = ImmutableMap<
    string,
    {
        isotherms: ImmutableList<ImmutableIsotherm>,
        currentlyVisualizedIsotherm: RawIsotherm,
        isFetching: boolean,
        isUpdating: boolean,
        isDeleting: boolean,
        isCreating: boolean,
        errors?: ErrorType,
    }
>;
