// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { LegacyTheme } from 'components/_ReactUI_V1';

import { KPIValueWrapper, KPIValueUnitWrapper } from './styles';

// Helpers & Constants
import { DATASET_VALUE_TYPES, NO_RESULT_STRING } from 'utils/constants';
import { TARGET_TYPES } from 'utils/kpiConstants';
import { round } from 'utils/helpers';
import { getKPISettingUnit } from 'utils/kpiHelpers';

// Types
import type { IntlType } from 'types';
import type { ImmutableKPISetting, ImmutableKPICard } from 'services/KPISetting/types';

type Props = {
    intl: IntlType,

    kpiCard?: ImmutableKPICard,
    noValueText?: string,

    kpiSetting?: ImmutableKPISetting,
    value?: number,
    fontSize?: number;
    inCard?: boolean,
};

class KPIValue extends React.PureComponent<Props> {
    getValue = (kpiSetting: ImmutableKPISetting) => {
        const value = this.props.kpiCard ? this.props.kpiCard.get('value') : this.props.value;

        if (!value && value !== 0) {
            return null;
        }

        const roundedValue = round(value, kpiSetting.get('precision'));
        return roundedValue;
    };

    render() {
        const kpiSetting = this.props.kpiCard
            ? this.props.kpiCard.get('kpiSetting')
            : this.props.kpiSetting;

        // Get value from dataset, if falsey and not 0, return early with N/A
        const value = this.getValue(kpiSetting);
        if (value === null) {
            return (
                <KPIValueWrapper color={LegacyTheme.defaultColor}>
                    {this.props.noValueText || NO_RESULT_STRING}
                </KPIValueWrapper>
            );
        }

        let onTarget = false;
        let showTargetColor = false;
        const highTarget = kpiSetting.get(TARGET_TYPES.HIGH_TARGET);
        const lowTarget = kpiSetting.get(TARGET_TYPES.LOW_TARGET);
        if (value !== null && highTarget !== null && lowTarget !== null) {
            onTarget = value <= highTarget && value >= lowTarget;
            showTargetColor = true;
        }
        // TODO: show target should be displayed if Cu Transfer and only 1 circuit in plant
        const showTarget = kpiSetting.get('kpiType') === DATASET_VALUE_TYPES.OVERALL_RECOVERY;
        let valueColor = LegacyTheme.defaultColor;
        if (showTargetColor && showTarget) {
            if (onTarget) {
                valueColor = LegacyTheme.defaultSuccessColor;
            } else {
                valueColor = LegacyTheme.defaultWarningColor;
            }
        }

        const readableUnit = getKPISettingUnit(kpiSetting, this.props.intl);
        const roundedValue = round(value, kpiSetting.get('precision'));

        return (
            <React.Fragment>
                {this.props.inCard ? (
                    <React.Fragment>
                        <KPIValueWrapper color={valueColor}  fontsize={this.props.fontSize}>{roundedValue}</KPIValueWrapper>
                        <KPIValueUnitWrapper>{readableUnit}</KPIValueUnitWrapper>
                    </React.Fragment>
                ) : (
                    <KPIValueWrapper
                        fontsize={this.props.fontSize}
                        color={valueColor}
                    >{`${roundedValue} ${readableUnit}`}</KPIValueWrapper>
                )}
            </React.Fragment>
        );
    }
}

export default injectIntl(KPIValue);
