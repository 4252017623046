// @flow strict

import request from 'services/request';
import { selectUserEmail } from 'services/Authentication/selectors';
import { store } from '../..';

import type { ErrorType } from './types';

/**
 * Report error
 */
export const report = (error: ErrorType) => {
    const email = selectUserEmail()(store.getState());

    // If error.type is set to backend, do not POST as it's already recorded
    if (error && error.type === 'backend') {
        return false;
    }

    return request('errors', {
        method: 'POST',
        data: {
            type: error.type || 'front-end-error',
            message: error.message || 'No message',
            stackTrace:
                error instanceof Error
                    ? error.stack
                    : `Stringified Error: ${JSON.stringify(error)}`,
            email,
        },
    });
};
