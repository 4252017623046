// @flow strict

import styled from 'styled-components';

import { DataToggler } from 'styles/common';

export const TableContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    width: 100%;
`;

export const ControlWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    flex: 1;

    ${DataToggler} {
        padding: 15px 40px 15px 0;
    }
`;
