// @flow strict

import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import ReportGenerationSystemMFE from 'microfrontends/ReportGenerationSystem';

// Types
import type { IntlType, HistoryType } from 'types';

type Props = {
    intl: IntlType,
    history: HistoryType,
    match: {
        params: {
            plantId: string,
        },
    },
};

export class ReportGenerationSystem extends React.PureComponent<Props, null> {
    getPlantId() {
        const plantId = this.props.match.params.plantId;
        if (plantId) {
            return parseInt(plantId, 10);
        }
        return null;
    }

    render() {
        const plantId = this.getPlantId();
        if (plantId) {
            return <ReportGenerationSystemMFE history={this.props.history} plantId={plantId} />;
        } else {
            return (
                <React.Fragment>
                    {this.props.intl.formatMessage({
                        id: 'views.ReportGenerationSystem.badPlantIdProvided',
                    })}
                </React.Fragment>
            );
        }
    }
}

export default withRouter(injectIntl(ReportGenerationSystem));
