// @flow strict

import type { ImmutableList, ReduxDispatch, ResponseErrorType } from 'types';
import { createUntranslatedFeedback } from 'services/Feedback/actions';
import { report } from 'services/Errors/resources';
import {
    submit,
    update,
    submitFeedback,
    runRecommendation,
    getCircuitRecommendation,
    getPlantRecommendation,
    submitPlantDatasetMsg,
    _calculateRecommendationImpact,
} from './resources';

import {
    receivedSubmitFailure,
    receivedSubmitSuccess,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    setIsSubmittingStatus,
    setIsUpdatingStatus,
    receivedSubmitFeedbackSuccess,
    receivedSubmitFeedbackFailure,
    setIsFeedbackSubmittingStatus,
    receivedRunRecommendationsSuccess,
    receivedRunRecommendationsFailure,
    setIsRunningRecommendations,
    setIsLoadingCircuitRecommendation,
    receivedCircuitRecommendationSuccess,
    receivedCircuitRecommendationFailure,
    setIsLoadingPlantRecommendation,
    receivedPlantRecommendationSuccess,
    receivedPlantRecommendationFailure,
    setIsSubmittingPlantDatasetMessageStatus,
    receivedSubmitPlantDatasetMessageFailure,
    receivedSubmitPlantDatasetMessageSuccess,
    setIsCalculatingRecommendationImpactStatus,
    receivedCalculateRecommendationImpactSuccess,
    receivedCalculateRecommendationImpactFailure,
} from './actions';
import type {
    ImmutableRecommendationSet,
    ImmutableRecommendationFeedback,
    RecommendationSetIdType,
    ImmutablePlantDatasetMessage,
    ImmutableRecommendationImpactCalculation,
} from 'services/Recommendation/types';

/**
 * Submits recommendation set
 */
export const submitRecommendationSet = (
    idOrNew: RecommendationSetIdType,
    recommendationSet: ImmutableRecommendationSet
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsSubmittingStatus());
    submit(idOrNew, recommendationSet)
        .then((response: ImmutableRecommendationSet) => {
            dispatch(receivedSubmitSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.submitRecommendationSet')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedSubmitFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.submitRecommendationSetFailed')
            );
        });
};

/**
 * Submits recommendation feedback
 */
export const submitFeedbacks = (
    recommendationSetId: number,
    feedbacks: ImmutableList<ImmutableRecommendationFeedback>
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsFeedbackSubmittingStatus());
    submitFeedback(recommendationSetId, feedbacks)
        .then((response: ImmutableRecommendationSet) => {
            dispatch(receivedSubmitFeedbackSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.submitFeedbacksSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedSubmitFeedbackFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.submitFeedbacksFailed'));
        });
};

/**
 * Update recommendation
 */
export const updateRecommendationSet = (
    id: number,
    recommendationSet: ImmutableRecommendationSet
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsUpdatingStatus());
    update(id, recommendationSet)
        .then((response: ImmutableRecommendationSet) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateRecommendationSet'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateRecommendationSetFailed')
            );
        });
};

/**
 * Runs recommendation for circuit
 */
export const runRecommendationForCircuit = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsRunningRecommendations());
    runRecommendation(id)
        .then((response: ImmutableRecommendationSet) => {
            dispatch(receivedRunRecommendationsSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.runningRecommendationsSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedRunRecommendationsFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.runningRecommendationsFailed')
            );
        });
};

/**
 * Get recommendation for circuit
 */
export const fetchCircuitRecommendation = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsLoadingCircuitRecommendation());
    getCircuitRecommendation(id)
        .then((response: ImmutableRecommendationSet) => {
            dispatch(receivedCircuitRecommendationSuccess(response));
            // dispatch(
            //     createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchCircuitRecommendationSuccess')
            // );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCircuitRecommendationFailure(error));
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.fetchCircuitRecommendationFailed'
                )
            );
        });
};

/**
 * Get recommendation for plant
 */
export const fetchPlantRecommendation = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsLoadingPlantRecommendation());
    getPlantRecommendation(id)
        .then((response: ImmutableRecommendationSet) => {
            dispatch(receivedPlantRecommendationSuccess(response));
            // dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchPlantRecommendationSuccess'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedPlantRecommendationFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchPlantRecommendationFailed')
            );
        });
};

export const submitPlantDatasetMessage = (
    plantId: number,
    plantDatasetId: number,
    message: ImmutablePlantDatasetMessage
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsSubmittingPlantDatasetMessageStatus());
    submitPlantDatasetMsg(plantId, plantDatasetId, message)
        .then((response: ImmutablePlantDatasetMessage) => {
            dispatch(receivedSubmitPlantDatasetMessageSuccess(response));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.submitPlantDatasetMessageSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedSubmitPlantDatasetMessageFailure(error));
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.submitPlantDatasetMessageFailure'
                )
            );
        });
};

export const calculateRecommendationImpact = (
    circuitId: number,
    datasetId: number,
    recommendationId: number,
    data: any
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsCalculatingRecommendationImpactStatus(recommendationId));
    _calculateRecommendationImpact(circuitId, datasetId, data)
        .then((response: ImmutableRecommendationImpactCalculation) => {
            dispatch(receivedCalculateRecommendationImpactSuccess(response));
            dispatch(setIsCalculatingRecommendationImpactStatus(recommendationId, false));
            dispatch(
                createUntranslatedFeedback(
                    'SUCCESS',
                    'feedback.success.calculateRecommendationImpactSuccess'
                )
            );
        })
        .catch((error: ResponseErrorType) => {
            dispatch(setIsCalculatingRecommendationImpactStatus(recommendationId, false));
            report(error);
            dispatch(receivedCalculateRecommendationImpactFailure(recommendationId, error));
            dispatch(
                createUntranslatedFeedback(
                    'ERROR',
                    'feedback.error.calculateRecommendationImpactFailure'
                )
            );
        });
};
