// @flow

import styled from 'styled-components';
import legacyTheme from '../../themes/legacyTheme';

export const AvatarWrapper = styled.div`
    cursor: ${({ cursorPointer, onClick }: Object) =>
        cursorPointer || onClick ? 'pointer' : 'default'};
`;

export const Avatar = styled.div`
    width: ${({ size }: Object) => size};
    height: ${({ size }: Object) => size};

    border: 1px solid ${legacyTheme.defaultBorder};
    border-radius: 50%;

    background-image: url(${({ backgroundSrc }: Object) => backgroundSrc});
    background-size: cover;
    background-position: center;
`;
