// @flow strict

import { createUntranslatedFeedback } from 'services/Feedback/actions';
import { report } from 'services/Errors/resources';

import {
    receivedPlantTrendSuccess,
    receivedPlantTrendFailure,
    receivedKPITrendSuccess,
    receivedKPITrendFailure,
    setIsFetchingKPIHistoryStatus,
    setIsFetchingPlantTrendStatus,
    receivedCircuitTrendSuccess,
    receivedCircuitTrendFailure,
    setIsFetchingCircuitTrendStatus,
    receivedPlantArchiveSuccess,
    receivedPlantArchiveFailure,
    setIsFetchingPlantArchiveStatus,
    receivedCircuitArchiveSuccess,
    receivedCircuitArchiveFailure,
    setIsFetchingCircuitArchiveStatus,
    receivedDatasetValuesSuccess,
    receivedDatasetValuesFailure,
    setIsFetchingDatasetValuesStatus,
    clearModalDatasetValues,
} from './actions';
import {
    plantTrend,
    circuitTrend,
    kpiTrend,
    plantArchive,
    circuitArchive,
    datasetValues,
} from './resources';

import type { ReduxDispatch, ResponseErrorType, SearchCriteria, QueryStructure } from 'types';
import type {
    TrendsPeriodConstant,
    ImmutablePlantTrend,
    ImmutableCircuitTrend,
    ImmutableDateRange,
    TrendsPageType,
} from 'services/Trends/types';
import type { ImmutablePlantDataset } from 'services/PlantDataset/types';
import type { ImmutableDataset } from 'services/Dataset/types';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Fetch the plant trend.
 */
export const fetchPlantTrend = (id: number, period: TrendsPeriodConstant) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsFetchingPlantTrendStatus());
    plantTrend(id, period)
        .then((response: ImmutablePlantTrend) => {
            dispatch(receivedPlantTrendSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchPlantTrendSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedPlantTrendFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchPlantTrendFailure'));
        });
};

/**
 * Fetch the circuit trend.
 */
export const fetchCircuitTrend = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingCircuitTrendStatus());
    circuitTrend(id)
        .then((response: ImmutableCircuitTrend) => {
            dispatch(receivedCircuitTrendSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchCircuitTrendSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCircuitTrendFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchCircuitTrendFailure')
            );
        });
};

/**
 * Fetch a single KPI trend for a circuit.
 */
export const fetchKPITrend = (kpiId: number, period: TrendsPeriodConstant, sinceDate: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsFetchingKPIHistoryStatus(kpiId, period));
    kpiTrend(kpiId, period, sinceDate)
        .then((response: ImmutableCircuitTrend) => {
            dispatch(receivedKPITrendSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchKPITrendSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedKPITrendFailure(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.fetchKPITrendFailure'));
        });
};

/**
 * Fetch the plant archive.
 */
export const fetchPlantArchive = (
    id: number,
    dateRange: ImmutableDateRange,
    searchCriteria: ?SearchCriteria,
    page: ?number
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingPlantArchiveStatus());
    plantArchive(id, dateRange, searchCriteria, page)
        .then((response: QueryStructure<ImmutablePlantDataset>) => {
            dispatch(receivedPlantArchiveSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchPlantArchiveSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedPlantArchiveFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchPlantArchiveFailure')
            );
        });
};

/**
 * Fetch the Circuit archive.
 */
export const fetchCircuitArchive = (
    id: number,
    dateRange: ImmutableDateRange,
    submittedRecommendationsOnly: boolean,
    searchCriteria: ?SearchCriteria,
    page: number
) => (dispatch: ReduxDispatch) => {
    dispatch(setIsFetchingCircuitArchiveStatus());
    circuitArchive(id, dateRange, submittedRecommendationsOnly, searchCriteria, page)
        .then((response: QueryStructure<ImmutableDataset>) => {
            dispatch(receivedCircuitArchiveSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchCircuitArchiveSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCircuitArchiveFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchCircuitArchiveFailure')
            );
        });
};

/**
 * Fetch dataset values for a circuit/plant
 */
export const fetchDatasetValues = (trendsPageType: TrendsPageType, datasetId: number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsFetchingDatasetValuesStatus());
    dispatch(clearModalDatasetValues());
    datasetValues(trendsPageType, datasetId)
        .then((response: ImmutableCircuitTrend) => {
            dispatch(receivedDatasetValuesSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.fetchDatasetValuesSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDatasetValuesFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.fetchDatasetValuesFailure')
            );
        });
};
