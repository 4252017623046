// @flow strict

import styled from 'styled-components';

import { LegacyTheme, PrimaryButton } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    padding: 8px 24px;

    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;

export const FooterButton = styled(PrimaryButton)`
    width: fit-content;
`;
