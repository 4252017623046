// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type { ImmutableIsothermState } from './types';

/**
 * Direct selector to the isotherm state domain
 */
export const selectIsothermState = () => (state: ImmutableReduxState) => state.get('isotherm');

/**
 * Select list of isotherms
 */
export const selectAllIsotherms = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('isotherms').toList()
    );

/**
 * Select currently visualized isotherm
 */
export const selectCurrentlyVisualizedIsotherm = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('currentlyVisualizedIsotherm')
    );

/**
 * Select isotherm errors
 */
export const selectIsothermErrors = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('errors')
    );

/**
 * Gets isFetching status while isotherms are being fetched: to hide list or show loading animation
 */
export const selectIsothermsAreFetching = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('isFetching')
    );

/**
 * Gets isUpdating status while isotherms are being updated: to disable inputs
 */
export const selectIsothermsAreUpdating = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('isUpdating')
    );

/**
 * Gets isFetching status while isotherms are being fetched: to hide list or show loading animation
 */
export const selectIsothermsAreDeleting = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('isDeleting')
    );

/**
 * Gets isCreating status while isotherms are being created: to disable inputs
 */
export const selectIsothermsAreCreating = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('isCreating')
    );

/**
 * Gets isVisualizing status while isotherms are being visualized: to show loading animation
 */
export const selectIsothermIsVisualizing = () =>
    createSelector(
        selectIsothermState(),
        (substate: ImmutableIsothermState) => substate.get('isVisualizing')
    );
