// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import ElevationProgress from 'components/ElevationProgress';
import ElevationFooter from 'components/ElevationFooter';
import PreventDataLossModal from 'components/Modals/PreventDataLossModal';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';

// Constants
import { STYLE_VALUES } from 'utils/constants';

// Styles
import { ContainerCentered } from 'styles/common';
import { ElevationWrapper, ElevationContent } from './styles';

// Types
import type { ReduxDispatch, ChildrenType } from 'types';

export const NEXT_BUTTON_TYPES = {
    NEXT: 'NEXT',
    CONFIRM: 'CONFIRM',
    DONE: 'DONE',
};

export type ElevationStepType = {
    key: string,
    label: string,
};

export type NextButtonTypeConstant = $Keys<typeof NEXT_BUTTON_TYPES>;

type Props = {
    children: ChildrenType,
    maxWidthValue?: string,

    isModified: boolean,
    currentStep: string,
    elevationSteps: Array<ElevationStepType>,
    isNextLoading: boolean,
    isNextEnabled: boolean,
    nextButtonType: NextButtonTypeConstant,
    onChangeStep: (newStep: string) => void,
    onExitBackwards: () => void,
    onExitForwards: () => void,
};

type State = {
    handleDataPrevention: ?() => void, // callback when user wants to leave the page
    showDataPreventionModal: boolean,
};

class ElevationContainer extends React.PureComponent<Props, State> {
    static defaultProps = {
        maxWidthValue: STYLE_VALUES.SCREEN.MAX_WIDTH,
    };

    state = {
        handleDataPrevention: null,
        showDataPreventionModal: false,
    };

    /**
     * Get the index of our current step.
     */
    getStepIndex = () =>
        this.props.elevationSteps.findIndex(
            (step: ElevationStepType) => this.props.currentStep === step.key
        );

    getCurrentStep = () => this.props.elevationSteps[this.getStepIndex()];

    /**
     * When the back button is clicked.
     */
    handleBack = () => {
        const currentStepIndex = this.getStepIndex();
        // if we are on the first step, and we click back, return to the mimic diagram.
        if (currentStepIndex === 0) {
            return this.handleOnExitBackwards();
        }

        // otherwise, find the previous step key.
        this.props.onChangeStep(this.props.elevationSteps[currentStepIndex - 1].key);
    };

    /**
     * When the next button is clicked
     */
    handleNext = () => {
        const currentStepIndex = this.getStepIndex();
        // if we click on next, but we are on the last elevation step, throw an error.
        const keys = this.props.elevationSteps;
        if (currentStepIndex >= keys.length - 1) {
            return this.props.onExitForwards();
        }

        // otherwise, go to the next elevation step key.
        this.props.onChangeStep(keys[currentStepIndex + 1].key);
    };

    /**
     * Close the data prevention modal by cancel.
     */
    handleCancelDataLossModal = () =>
        this.setState({
            showDataPreventionModal: false,
            handleDataPrevention: null,
        });

    /**
     * On exit backwards (meaning they haven't saved any data)
     */
    handleOnExitBackwards = (): boolean => {
        if (this.props.isModified) {
            this.setState({
                showDataPreventionModal: true,
                handleDataPrevention: this.props.onExitBackwards,
            });
            return false;
        }
        this.props.onExitBackwards();
        return true;
    };

    /**
     * Render the data prevention modal.
     */
    renderDataPreventionModal = () =>
        Boolean(this.state.showDataPreventionModal) && (
            <PreventDataLossModal
                isPageNavigation={false}
                onLeave={this.state.handleDataPrevention}
                onCancel={this.handleCancelDataLossModal}
            />
        );

    render() {
        return (
            <ContainerCentered
                style={{ justifyContent: 'start', flexDirection: 'column' }}
                withHeader
            >
                {this.renderDataPreventionModal()}
                <PreventNavigationPrompt shouldBlock={this.props.isModified} />
                <ElevationWrapper>
                    <ElevationProgress
                        currentStep={this.getCurrentStep()}
                        elevationSteps={this.props.elevationSteps}
                        maxWidthValue={this.props.maxWidthValue}
                    />
                    <ElevationContent maxWidth={this.props.maxWidthValue}>
                        {this.props.children}
                    </ElevationContent>
                </ElevationWrapper>
                <ElevationFooter
                    nextButtonType={this.props.nextButtonType}
                    isNextEnabled={this.props.isNextEnabled}
                    isNextLoading={this.props.isNextLoading}
                    onBackClicked={this.handleBack}
                    onNextClicked={this.handleNext}
                    maxWidth={this.props.maxWidthValue}
                />
            </ContainerCentered>
        );
    }
}

const mapStateToProps = () => createStructuredSelector({});

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ElevationContainer)
);
