// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header, Title, Body } from './styles';

import KPIStatusTables from 'components/KPIStatusTables';

// Types
import type { IntlType, ImmutableList } from 'types';
import type { ImmutableDataset } from 'services/Dataset/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';

type Props = {
    intl: IntlType,

    kpiSettings: ImmutableList<ImmutableKPISetting>,
    dataset: ImmutableDataset,
};

class KPIStatusModal extends React.PureComponent<Props> {
    getTranslation = (key: string) =>
        this.props.intl.formatMessage({ id: `components.Modals.KPIStatusModal.${key}` });

    render() {
        return (
            <Wrapper>
                <Header>
                    <Title>{this.getTranslation('title')}</Title>
                </Header>
                <Body>
                    <KPIStatusTables
                        dataset={this.props.dataset}
                        kpiSettings={this.props.kpiSettings}
                    />
                </Body>
            </Wrapper>
        );
    }
}

export default injectIntl(KPIStatusModal);
