/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { LegacyTheme } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

import { colors } from 'styles/colors';

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    min-height: 100%;

    background-color: ${LegacyTheme.white};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    padding-bottom: 32px;
`;

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const NavigationItemCSS = css`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 40px;
    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding-left: 12px;
    }

    height: ${({ bordered }: Object) => (bordered ? `79px` : `50px`)};
    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        height: ${({ bordered }: Object) => (bordered ? `60px` : `50px`)};
    }

    width: 100%;
    ${({ bordered }: Object) =>
        bordered
            ? `
        border-top: 1px solid ${LegacyTheme.disabledBorder};

        &:last-child {
            border-bottom: 1px solid ${LegacyTheme.disabledBorder};
        }
    `
            : null}

    font-size: 20px;
    line-height: 24px;
    text-decoration: none;

    color: ${LegacyTheme.defaultSubtleColor7B};

    :visited {
        color: ${LegacyTheme.defaultSubtleColor7B};
    }

    :hover {
        color: ${LegacyTheme.defaultColor};
    }

    ${({ active }: Object) =>
        active &&
        `
        color: ${LegacyTheme.defaultColor}!important;
        pointer-events: none; // if the route is currently active, disable user clicking on this route again.
    `}
`;

// internal
export const NavigationItem = styled(Link)`
    ${NavigationItemCSS}
`;

export const NavigationItemIcon = styled.div`
    margin-right: 13px;
    width: 25px;
    display: flex;
    justify-content: center;
`;

// external
export const NavigationItemExternal = styled.a`
    ${NavigationItemCSS}

    :hover {
        > span {
            background-color: ${LegacyTheme.defaultColor};
        }
    }
`;

export const Navigation = styled.div`
    display: flex;
    flex-direction: column;

    ${NavigationItem}, ${NavigationItemExternal} {
        font-size: ${({ small }: { small: boolean }) => (small ? '14px' : '20px')};
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    padding: 24px 24px 40px;
    color: ${colors.greyAF};

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        padding: 14px 12px 24px;
    }
`;

export const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Profile = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 24px;
`;

export const ProfileText = styled.div`
    display: flex;
    flex-direction: column;

    margin-left: 16px;
`;

export const ProfileName = styled.div`
    font-size: 20px;
    line-height: 24px;

    margin-top: 4px;
`;

export const ProfileRole = styled.div`
    font-size: 20px;
    line-height: 24px;
    margin-top: 4px;
`;

export const CloseButton = styled.div`
    height: fit-content;
    margin-top: -12px; // Align with OverlayNavigation toggle

    @media (max-width: ${STYLE_VALUES.RESPONSIVE_AT}) {
        margin: 0;
        margin-right: -2px; // Align with OverlayNavigation toggle
    }

    :hover {
        color: ${LegacyTheme.defaultColor};
    }
`;
