// @noflow

import styled from 'styled-components';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

export const StyledDotMenu = styled.div`
    display: block;

    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['dotMenu', 'color'])};

    letter-spacing: 1px;
    font-size: 24px;
    line-height: 1;
`;
