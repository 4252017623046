// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header, Body, Footer } from './styles';

// Constants
import { MODAL_WIDTH } from 'utils/constants';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, WarningButton, Modal } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';

type Props = {
    intl: IntlType,
    isPageNavigation?: boolean, // is this prevent data loss modal for a page change?

    onLeave: ?() => void,
    onCancel: () => void,
};

class PreventDataLossModal extends React.PureComponent<Props> {
    static defaultProps = {
        isPageNavigation: true, // by default the prevent data loss modal is always for page changes.
    };

    getTranslation = (key: string) => ({
        id: `components.Modals.PreventDataLossModal.${key}`,
    });

    getTitle = () => this.getTranslation(`${this.props.isPageNavigation ? 'page' : 'data'}Title`);

    getBody = () => this.getTranslation(`${this.props.isPageNavigation ? 'page' : 'data'}Body`);

    getLeave = () => this.getTranslation(`${this.props.isPageNavigation ? 'page' : 'data'}Leave`);

    render() {
        return (
            <Modal
                modalWidth={MODAL_WIDTH.LARGE}
                onHandleClose={this.props.onCancel}
                style={{
                    wrapper: {
                        zIndex: '999999',
                    },
                }}
                disableClose
            >
                <Wrapper>
                    <Header>
                        <Title>{this.props.intl.formatMessage(this.getTitle())}</Title>
                    </Header>
                    <Body>{this.props.intl.formatMessage(this.getBody())}</Body>
                    <Footer>
                        <WarningButton
                            text={this.props.intl.formatMessage(this.getLeave())}
                            onClick={this.props.onLeave}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.PreventDataLossModal.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(PreventDataLossModal);
