// @flow strict

import styled, { keyframes } from 'styled-components';

const animateToastKeyframe = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const AnimatedToast = styled.div`
    animation: ${animateToastKeyframe} 0.4s ease;

    & + & {
        margin-top: 8px;
    }
`;
