// @flow

import React from 'react';

import { LoadingDots } from '../../icons/LoadingDots';

import type { Props } from './props';
import { defaultProps } from './props';

// Styles
import { StyledInverseButton } from './styles';

class InverseButton extends React.PureComponent<Props> {
    static defaultProps = defaultProps;

    render() {
        const { text, disabled, handleOnClick, onClick, icon, ...rest } = this.props;

        let onClickFunction;
        if (!(disabled || this.props.loading)) {
            // Is the button enabled and not loading?
            onClickFunction = onClick || handleOnClick;
        }

        return (
            <StyledInverseButton
                {...rest}
                disabled={disabled || this.props.loading}
                onClick={onClickFunction}
            >
                {this.props.loading ? (
                    <LoadingDots />
                ) : (
                    <React.Fragment>
                        {icon || null}
                        {text}
                    </React.Fragment>
                )}
            </StyledInverseButton>
        );
    }
}

export default InverseButton;
