// @flow strict

import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;

    button {
        width: 58px;
        height: 30px;
    }

    z-index: 2;
`;
