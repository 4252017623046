// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    padding: 0px 24px 12px 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0px 24px 24px 24px;

    > ul {
        padding-left: 27px;
        margin: 14px 0;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    border-top: 1px solid ${LegacyTheme.disabledBorder};
    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const Label = styled.p`
    margin: 0;
    font-size: 14px;
    color: ${LegacyTheme.defaultColor};
`;
