// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import {
    Common,
    Toggle,
    InputField,
    InputSelect,
    SecondaryButton,
    InputNumber,
} from 'components/_ReactUI_V1';

import { Title, Subtitle, FormContainer, FormBlock, FormLabel, SimpleList } from 'styles/common';
import { Dash } from 'assets/icons/styles';

// Components
import ErrorMessage from 'components/ErrorMessage';
import ReagentSelectBody from 'components/ReagentSelectBody';

// Constants
import {
    CIRCUIT_ELEVATION_STEPS,
    NUMBER_INPUT_PLACEHOLDER,
    DATASET_VALUE_TYPES,
} from 'utils/constants';
import { clamp } from 'utils/helpers';
import { getValueTypeUnit } from 'utils/kpiHelpers';

// Types
import type {
    IntlType,
    ImmutableList,
    ReactSelectObject,
    LooseKeyArrayType,
    LooseInputValueTypes,
    InputEvent,
    ReduxDispatch,
} from 'types';
import type { ImmutablePlant } from 'services/Plant/types';
import type {
    ImmutableCircuit,
    CircuitElevationStepConstant,
    ImmutableCircuitSettings,
} from 'services/Circuit/types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';
import type { ImmutableUser } from 'services/Authentication/types';

const INPUT_FIELD_STYLES = {
    width: '64px',
    paddingRight: '6px',
};

const CIRCUIT_NAME_MAX_CHARACTERS = 255;

type Props = {
    intl: IntlType,
    isCircuitElevating: boolean,
    circuit: ImmutableCircuit,
    circuitName: ?string,
    circuitSettings: ImmutableCircuitSettings,
    reagent: ?ImmutableReagent,

    plantId: ?number,
    isLoading: boolean,
    plants: ImmutableList<ImmutablePlant>,
    onChangePlant?: ?(plantId: number) => void,
    onChangeCircuitName?: ?(circuitName: string) => void,
    onReturnToStep?: ?(stepKey: CircuitElevationStepConstant) => () => void,
    onChangeCircuitSettings?: ?(key: LooseKeyArrayType, value: LooseInputValueTypes) => void,
    onChangeReagent?: ?(reagent: ImmutableReagent) => void,
    onChangeOxime?: ?(oxime: ImmutableOxime) => void,
};

class CircuitElevationPlantStep extends React.PureComponent<Props, null> {
    static defaultProps = {
        onChangePlant: null,
        onChangeCircuitName: null,
        onReturnToStep: null,
    };

    STEP_KEY = CIRCUIT_ELEVATION_STEPS.PLANT;

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.CircuitElevation.${this.STEP_KEY}.${id}`,
            },
            data
        );

    /**
     * Convert the provided plantId to a ReactSelectObject
     */
    getReactSelectObjectFromPlantId = (plantId: ?number): ?ReactSelectObject => {
        if (!plantId || this.props.plants.isEmpty()) {
            return null;
        }

        const selectedPlant = this.getSelectedPlant();

        if (!selectedPlant) {
            return null;
        }

        return {
            value: selectedPlant.get('id'),
            label: selectedPlant.get('name'),
        };
    };

    /**
     * When the user changes circuit name.
     */
    handleCircuitNameChange = (evt: InputEvent) =>
        this.props.onChangeCircuitName && this.props.onChangeCircuitName(evt.target.value);

    /**
     * Get all plant options as a list.
     */
    getPlantOptions = () =>
        this.props.plants
            .map((plant: ImmutablePlant) => ({
                value: plant.get('id'),
                label: plant.get('name'),
            }))
            .toJS();

    getSelectedPlant = (): ImmutablePlant | null =>
        this.props.plants.find((plant: ImmutablePlant) => plant.get('id') === this.props.plantId);

    /**
     * When the user selects a plant.
     */
    handlePlantSelect = (plantSelected: ReactSelectObject) =>
        this.props.onChangePlant && this.props.onChangePlant(plantSelected.value);

    /**
     * Update provided toggle input by key
     */
    handleToggleChange = (key: LooseKeyArrayType) => (checked: boolean) =>
        this.props.onChangeCircuitSettings && this.props.onChangeCircuitSettings(key, checked);

    /**
     * Update provided field by key
     */
    handleInputChange = (key: LooseKeyArrayType) => (event: InputEvent) =>
        this.props.onChangeCircuitSettings &&
        this.props.onChangeCircuitSettings(key, event.target.value);

    renderUnitWarning = () => {
        const selectedPlant = this.getSelectedPlant();
        if (!selectedPlant) {
            return null;
        }
        const isDiffCircuitUnit =
            this.props.circuit.get('circuitUnits') !== selectedPlant.get('units');
        const isDiffProductionUnit =
            this.props.circuit.get('productionUnits') !== selectedPlant.get('productionUnits');
        if (!isDiffCircuitUnit && !isDiffProductionUnit) {
            // the circuit units are the same.
            return null;
        }
        return (
            <ErrorMessage
                errorMessage={this.getTranslation('differentUnitsWarning')}
                isRed
                isSmall
            />
        );
    };

    renderListOfCircuits = () => {
        const selectedPlant = this.getSelectedPlant();
        if (!selectedPlant) {
            return null;
        }

        const listOfCircuits =
            selectedPlant.get('circuitCount') >= 1
                ? selectedPlant.get('circuits').map((circuit: ImmutableCircuit) => {
                      return <li key={circuit.get('id')}>{circuit.get('name')}</li>;
                  })
                : null;

        return (
            <FormBlock>
                <FormLabel>{this.getTranslation('plant.CircuitsLabel')}</FormLabel>
                {selectedPlant.get('circuitCount') >= 1 && (
                    <SimpleList>{listOfCircuits}</SimpleList>
                )}
                {selectedPlant.get('circuitCount') < 1 && <Dash height="1" />}
            </FormBlock>
        );
    };

    renderHeader = (displayAsReadOnly: boolean) => (
        <Common.Row alignItems="center">
            <Common.Column>
                {displayAsReadOnly ? (
                    <Subtitle>{this.getTranslation('subtitle')}</Subtitle>
                ) : (
                    <Title>{this.getTranslation('title')}</Title>
                )}
            </Common.Column>
            {displayAsReadOnly && (
                <Common.Column alignItems="flex-end">
                    <SecondaryButton
                        text={this.getTranslation('edit')}
                        onClick={
                            this.props.onReturnToStep && this.props.onReturnToStep(this.STEP_KEY)
                        }
                    />
                </Common.Column>
            )}
        </Common.Row>
    );

    renderCircuitName = (isReadOnly: boolean) => (
        <FormBlock>
            <FormLabel>{this.getTranslation('circuitName.Label')}</FormLabel>
            <InputField
                value={this.props.circuitName}
                onChange={!isReadOnly && this.handleCircuitNameChange}
                placeholder={this.getTranslation('circuitName.Placeholder')}
                renderString={isReadOnly}
                maxLength={CIRCUIT_NAME_MAX_CHARACTERS}
            />
        </FormBlock>
    );

    renderGeneralSettingsHeader = (isReadOnly: boolean) =>
        isReadOnly ? (
            <Subtitle>{this.getTranslation('generalSettingsTitle')}</Subtitle>
        ) : (
            <Title>{this.getTranslation('generalSettingsTitle')}</Title>
        );

    renderPlantSelector = (isReadOnly: boolean) => (
        <FormBlock>
            <FormLabel>{this.getTranslation('plant.Label')}</FormLabel>
            <InputSelect
                selectedOption={this.getReactSelectObjectFromPlantId(this.props.plantId)}
                options={this.getPlantOptions()}
                onSelect={!isReadOnly && this.handlePlantSelect}
                placeholder={this.getTranslation('plant.Placeholder')}
                isDisabled={this.props.isLoading || !this.props.isCircuitElevating}
                isLoading={this.props.isLoading}
                renderString={isReadOnly}
                controlShouldRenderValue
            />
            {this.renderUnitWarning()}
        </FormBlock>
    );

    renderGeneralSettings = (isReadOnly: boolean) => (
        <React.Fragment>
            <Common.Column style={{ marginBottom: '10px' }}>
                <Subtitle>{this.getTranslation('generalSettingsTitle')}</Subtitle>
            </Common.Column>
            <FormBlock>
                <FormLabel>{this.getTranslation('maxLoadOffset')}</FormLabel>
                <InputField
                    value={this.props.circuitSettings.get('defaultMaxLoadOffset') || ''}
                    onChange={this.handleInputChange('defaultMaxLoadOffset')}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    style={INPUT_FIELD_STYLES}
                    renderString={isReadOnly}
                    type="number"
                />
            </FormBlock>
            <FormBlock>
                <FormLabel>{this.getTranslation('cascadeRelaxationFactor')}</FormLabel>
                <InputField
                    value={this.props.circuitSettings.get('cascadeRelaxationFactor') || ''}
                    onChange={this.handleInputChange('cascadeRelaxationFactor')}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    style={INPUT_FIELD_STYLES}
                    renderString={isReadOnly}
                    type="number"
                    min="0"
                />
            </FormBlock>
            <FormBlock>
                <FormLabel>{this.getTranslation('returnRelaxationFactor')}</FormLabel>
                <InputField
                    value={this.props.circuitSettings.get('returnRelaxationFactor') || ''}
                    onChange={this.handleInputChange('returnRelaxationFactor')}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    style={INPUT_FIELD_STYLES}
                    renderString={isReadOnly}
                    type="number"
                    min="0"
                />
            </FormBlock>
            <FormBlock>
                <FormLabel>{this.getTranslation('showRecoveryIndicator')}</FormLabel>
                <Toggle
                    checked={this.props.circuitSettings.get('showRecoveryIndicator')}
                    onClickHandler={this.handleToggleChange('showRecoveryIndicator')}
                    disabled={isReadOnly}
                />
            </FormBlock>
        </React.Fragment>
    );

    renderReagentConfiguration = (isReadOnly: boolean) => (
        <React.Fragment>
            <Subtitle>{this.getTranslation('reagentInformation.reagentTitle')}</Subtitle>
            <FormBlock>
                <ReagentSelectBody
                    selectedReagent={this.props.reagent}
                    onSelectReagent={this.props.onChangeReagent}
                    isDisabled={this.props.isLoading}
                    isLoading={this.props.isLoading}
                    readOnly={isReadOnly}
                    reagentsOnly
                />
            </FormBlock>
        </React.Fragment>
    );

    render() {
        const isReadOnly = Boolean(this.props.onReturnToStep);

        return (
            <FormContainer>
                {this.renderHeader(isReadOnly)}
                {this.renderCircuitName(isReadOnly)}
                {this.renderPlantSelector(isReadOnly)}
                {this.renderListOfCircuits()}

                <Common.Row style={{ maxWidth: '1000px', margin: '10px 0' }}>
                    <Common.Column flex={1}>{this.renderGeneralSettings(isReadOnly)}</Common.Column>
                    <Common.Column flex={2}>
                        {this.renderReagentConfiguration(isReadOnly)}
                    </Common.Column>
                </Common.Row>
            </FormContainer>
        );
    }
}

export default injectIntl(CircuitElevationPlantStep);
