// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Actions
import { Loader, Table, Pencil, PrimaryButton } from 'components/_ReactUI_V1';

import { clearModalDatasetValues } from 'services/Trends/actions';

// Styles
import { Wrapper, Header, Title, Body, Overlay, SettingsButtonHover, Footer } from './styles';

// Components
import EditKPIValueModal from 'components/Modals/EditKPIValueModal';

// Constants
import { NO_RESULT_STRING, TRENDS_PAGE_TYPE, KPI_SPECIFICITY_TYPES } from 'utils/constants';

// Helpers
import { isSysAdmin, isSolvayUser } from 'utils/authentication';
import { round } from 'utils/helpers';
import {
    getKPISettingUnit,
    getCircuitValueDataFromDataset,
    getPlantValueDataFromPlantDataset,
} from 'utils/kpiHelpers';

// Selectors
import {
    selectDatasetValuesAreFetching,
    selectModalDatasetValuesData,
} from 'services/Trends/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { selectDatasetIsComputing } from 'services/PlantDataset/selectors';

// Thunks
import { recomputeSolvExtractDataset } from 'services/PlantDataset/thunks';
import { fetchDatasetValues } from 'services/Trends/thunks';

// Types
import type { IntlType, ReduxDispatch } from 'types';
import type { ImmutableDatasetValues, TrendsPageType } from 'services/Trends/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    user: ImmutableUser,

    trendsPageType: TrendsPageType,

    plantId?: number,
    plantDatasetId: number,
    datasetId: number,
    datasetValuesData: ?ImmutableDatasetValues,
    datasetValuesAreFetching: boolean,
    isRecomputingDataset: boolean,

    recomputeSolvExtractDataset: (datasetId: number) => void,
    fetchDatasetValues: (trendsPageType: TrendsPageType, datasetId: number) => void,
    clearModalDatasetValues: () => void,
};

type State = {
    kpiSettingOfValueToEdit: ImmutableKPISetting,
};

/**
 * Displays a table of values in a circuit/plant dataset (dataset, stream, stage & plant)
 */
class DatasetValuesTableModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            kpiSettingOfValueToEdit: null,
        };
    }

    componentDidMount() {
        this.handleFetchDatasetValues();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.datasetId !== this.props.datasetId && !this.props.datasetValuesAreFetching) {
            this.handleFetchDatasetValues();
        }
    }

    componentWillUnmount() {
        this.props.clearModalDatasetValues();
    }

    handleFetchDatasetValues = () => {
        this.props.fetchDatasetValues(this.props.trendsPageType, this.props.datasetId);
    };

    hasAccessToEdit = () => isSysAdmin(this.props.user) || isSolvayUser(this.props.user);

    hasAccessToRecompute = () => isSysAdmin(this.props.user) || isSolvayUser(this.props.user);

    isPlant = () => this.props.trendsPageType === TRENDS_PAGE_TYPE.PLANT;

    getTranslation = (key: string) =>
        this.props.intl.formatMessage({ id: `components.Modals.DatasetValuesTableModal.${key}` });

    getHeaders = (hasAccessToEdit: boolean) =>
        [
            {
                label: this.getTranslation('headers.kpiName'),
                id: 'kpiName',
            },
            {
                label: this.getTranslation('headers.value'),
                id: 'value',
            },
            {
                label: this.getTranslation('headers.min'),
                id: 'min',
            },
            {
                label: this.getTranslation('headers.max'),
                id: 'max',
            },
            hasAccessToEdit && {
                label: '',
                id: 'edit',
            },
        ].filter(Boolean);

    getValueDataFromKPI = (kpiSetting: ImmutableKPISetting) => {
        const dataset =
            this.props.datasetValuesData && this.props.datasetValuesData.get('dataset', null);
        if (!dataset) {
            throw new Error('No dataset values provided, something went wrong');
        }

        if (this.isPlant()) {
            return getPlantValueDataFromPlantDataset(kpiSetting, dataset);
        } else {
            return getCircuitValueDataFromDataset(kpiSetting, dataset);
        }
    };

    getValueAndUnitAsString = (kpiSetting: ImmutableKPISetting): string => {
        const valueData = this.getValueDataFromKPI(kpiSetting);
        const value = valueData ? valueData.get('value') : null;
        const unit = getKPISettingUnit(kpiSetting, this.props.intl);

        // If value is null, return with no result string
        if (value === null) {
            return NO_RESULT_STRING;
        }

        // If value is not null, contact rounded value and unit with space between them
        return [round(value, kpiSetting.get('precision')), unit].filter(Boolean).join(' ');
    };

    getRows = (hasAccessToEdit: boolean) => {
        if (!this.props.datasetValuesData || this.props.datasetValuesData.isEmpty()) {
            return [];
        }

        const rows = this.props.datasetValuesData
            .get('kpiSettings')
            .map((kpiSetting: ImmutableKPISetting) => {
                const minValid = kpiSetting.get('minValid', null);
                const maxValid = kpiSetting.get('maxValid', null);
                const row = {
                    id: kpiSetting.get('id'),
                    kpiName: kpiSetting.get('name'),
                    value: this.getValueAndUnitAsString(kpiSetting),
                    min: minValid !== null ? minValid : NO_RESULT_STRING,
                    max: maxValid !== null ? maxValid : NO_RESULT_STRING,
                };

                if (hasAccessToEdit) {
                    row.edit = (
                        <SettingsButtonHover
                            onClick={() => {
                                this.setState({
                                    kpiSettingOfValueToEdit: kpiSetting,
                                });
                            }}
                        >
                            <Pencil width="20px" height="20px" margin="0" />
                        </SettingsButtonHover>
                    );
                }

                return row;
            });

        return rows;
    };

    handleCloseValueEditModal = () =>
        this.setState({
            kpiSettingOfValueToEdit: null,
        });

    handleRecompute = () => {
        this.props.recomputeSolvExtractDataset(this.props.plantId, this.props.plantDatasetId);
    };

    renderEditKPIValueModal = () => {
        if (this.state.kpiSettingOfValueToEdit && !this.props.datasetValuesAreFetching) {
            const isPlant = this.isPlant();
            return (
                <EditKPIValueModal
                    valueData={this.getValueDataFromKPI(this.state.kpiSettingOfValueToEdit)}
                    kpiSetting={this.state.kpiSettingOfValueToEdit}
                    onCloseModal={this.handleCloseValueEditModal}
                    datasetId={!isPlant ? this.props.datasetId : null}
                    plantDatasetId={isPlant ? this.props.datasetId : null}
                />
            );
        }
    };

    renderRecomputeSolvExtractFooter = () => {
        if (!this.hasAccessToRecompute()) {
            return null;
        }
        return (
            <Footer>
                <PrimaryButton
                    loading={this.props.isRecomputingDataset}
                    disabled={this.props.isRecomputingDataset}
                    onClick={this.handleRecompute}
                    text={this.getTranslation('recomputeAllDatasets')}
                />
            </Footer>
        );
    };

    render() {
        let content;
        if (this.props.datasetValuesAreFetching) {
            content = (
                <Overlay>
                    <Loader loading />
                </Overlay>
            );
        } else {
            const hasAccessToEdit = this.hasAccessToEdit();
            const rows = this.getRows(hasAccessToEdit);
            content = (
                <React.Fragment>
                    {this.renderEditKPIValueModal()}
                    <Table
                        header={this.getHeaders(hasAccessToEdit)}
                        rows={rows}
                        footerMessage={
                            rows.length === 0 ? this.getTranslation('noDatasetValues') : null
                        }
                        subtleStyling
                    />
                </React.Fragment>
            );
        }

        return (
            <Wrapper>
                <Header>
                    <Title>{this.getTranslation('title')}</Title>
                </Header>
                <Body>{content}</Body>
                {this.renderRecomputeSolvExtractFooter()}
            </Wrapper>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
        datasetValuesAreFetching: selectDatasetValuesAreFetching(),
        datasetValuesData: selectModalDatasetValuesData(),
        isRecomputingDataset: selectDatasetIsComputing(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchDatasetValues,
            clearModalDatasetValues,
            recomputeSolvExtractDataset,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DatasetValuesTableModal));
