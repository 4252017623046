// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const Wrapper = styled.div`
    flex: 1;

    border-left: 1px solid ${LegacyTheme.defaultBorder};
    cursor: pointer;

    min-width: 0; // Text-overflow fix
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 100%;
`;

export const CircuitDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 16px;

    min-width: 0; // Text-overflow fix
`;

export const CircuitCaret = styled.div`
    padding: 15px 15px 15px 0px;

    position: relative;
`;

export const CircuitName = styled.p`
    font-size: 16px;
    line-height: 19px;
    color: ${LegacyTheme.black};
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CircuitSub = styled.p`
    font-size: 12px;
    line-height: 14px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const MenuToolTip = styled.div`
    display: flex;
    align-items: center;

    position: absolute;
    top: 60px;
    margin-left: -1px;
    z-index: 999;

    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 11px;
    text-decoration: none;
    white-space: nowrap;

    cursor: initial;
    > span {
        margin-right: 5px;
    }
`;

export const MenuToolTipContent = styled.div`
    border: 1px solid ${LegacyTheme.defaultBorder};
    background-color: ${LegacyTheme.white};
    width: 360px;

    display: flex;
    flex-direction: column;
`;

export const ToolTipSection = styled.div`
    display: flex;
    flex-direction: column;

    padding: 16px;

    & + & {
        border-top: 1px solid ${LegacyTheme.defaultBorder};
    }

    > button {
        margin-top: 16px;
        width: 125px;
        align-self: flex-end;
    }
`;

export const ToolTipSectionTitle = styled.h1`
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 16px;

    color: ${LegacyTheme.black};
`;

export const ToolTipSectionText = styled.p`
    font-size: 13px;
    line-height: 19px;
    color: ${LegacyTheme.defaultColor};

    margin: 0;
`;

export const ScrollableField = styled.div`
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 14px;
`;
