// @flow strict

import { decamelize, decamelizeKeys } from 'humps';

import request from 'services/request';
import { API_VER } from 'env';

import type { SearchCriteria } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';

/**
 * Create plant
 *
 * @param {ImmutablePlant} plant
 */
export const create = (plant: ImmutablePlant) =>
    request('plants', {
        data: plant.toJS(),
        method: 'POST',
    });

/**
 * Fetches all plants
 */
export const index = () => request('plants', { method: 'GET' });

/**
 * Update plant
 *
 * @param {number} id
 * @param {ImmutablePlant} plant
 */
export const update = (id: number, plant: ImmutablePlant) =>
    request(`plants/${id}`, {
        data: plant.toJS(),
        method: 'PUT',
    });

/**
 * Destroy plant
 *
 * @param {number} id
 */
export const destroy = (id: number) => request(`plants/${id}`, { method: 'DELETE' });

/**
 * Query all plants
 */
export const query = (
    searchCriteria: SearchCriteria = {},
    page: number = 1,
    perPage: number = 10
) => {
    const options = decamelizeKeys({
        page,
        perPage,
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder: searchCriteria.sortOrder ? searchCriteria.sortOrder.toLowerCase() : '',
    });
    return request('plants/query', { method: 'GET' }, { ...options });
};

/**
 * Query all user/role plants for dashboard
 */
export const queryDashboard = (searchCriteria: SearchCriteria = {}, page: number = 1) => {
    const options = decamelizeKeys({
        page,
        perPage: 4,
        sortBy: searchCriteria && searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
        sortOrder:
            searchCriteria && searchCriteria.sortOrder
                ? searchCriteria.sortOrder.toLowerCase()
                : '',
        search: (searchCriteria && searchCriteria.search) || '',
    });
    return request('plants/dashboard', { method: 'GET' }, { ...options });
};

/**
 * Download the plant's template.
 *
 * @param {number} id
 */
export const downloadTemplate = (id: number) =>
    request(
        `import/${id}/template`,
        { method: 'GET' },
        {}, // params
        API_VER, // api version
        false, // humps
        {
            responseType: 'blob',
        } // additional parameters
    );
