// @flow strict

import type { ReduxDispatch } from 'types';
import type { ErrorType } from './types';
import { report } from './resources';

export const reportError = (error: ErrorType) => (dispatch: ReduxDispatch) => {
    report(error)
        .then(() => {
            console.log('Error logged.');
        })
        .catch((e) => {
            console.error('Error was not logged successfully.', e);
        });
};
