// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;

    padding: 8px 24px;

    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > *:first-child {
        margin-right: 8px;
    }
`;
