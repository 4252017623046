// @flow

import React from 'react';

// Components
import ButtonHover from '../button-hover';

// Styles
import { StyledDotMenu } from './styles';

const DotMenu = (props: Object) => (
    <ButtonHover {...props}>
        <StyledDotMenu>•••</StyledDotMenu>
    </ButtonHover>
);

export default DotMenu;
