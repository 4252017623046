// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, ButtonWrapper, ButtonGroup } from './styles';

// Components
import { PrimaryButton, SecondaryButton } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';

type FooterMode = 'PRE_COMPUTED' | 'POST_COMPUTED';

type Props = {
    intl: IntlType,

    mode: FooterMode,

    computeDisabled: boolean,
    saveDisabled: boolean,
    saveAsDisabled: boolean,
    loadingComputation: boolean,

    onBackToEditClicked: () => void,
    onClearAllClicked: () => void,
    onComputeClicked: () => void,
    onCopyToClipboardClicked: () => void,
    onSaveClicked: () => void,
    onSaveAsClicked: () => void,
};

class CircuitComputeFooter extends React.PureComponent<Props, null> {
    getTranslation = (key: string) =>
        this.props.intl.formatMessage({ id: `components.CircuitComputeFooter.${key}` });

    getLeftButtons = () =>
        this.props.mode === 'PRE_COMPUTED' ? (
            <SecondaryButton
                text={this.getTranslation('clearAll')}
                onClick={this.props.onClearAllClicked}
            />
        ) : (
            <SecondaryButton
                text={this.getTranslation('backToEdit')}
                disabled={false}
                onClick={this.props.onBackToEditClicked}
            />
        );

    getCenterButtons = () =>
        this.props.mode === 'PRE_COMPUTED' && (
            <PrimaryButton
                text={this.getTranslation('compute')}
                style={{ width: '132px' }}
                disabled={this.props.computeDisabled || this.props.loadingComputation}
                onClick={this.props.onComputeClicked}
                loading={this.props.loadingComputation}
            />
        );

    getRightButtons = () => (
        <ButtonGroup>
            <SecondaryButton
                text={this.getTranslation('copyToClipboard')}
                onClick={this.props.onCopyToClipboardClicked}
            />
            <SecondaryButton
                text={this.getTranslation('saveAsButton')}
                disabled={this.props.saveAsDisabled}
                onClick={this.props.onSaveAsClicked}
            />
            <PrimaryButton
                text={this.getTranslation('saveButton')}
                disabled={this.props.saveDisabled}
                onClick={this.props.onSaveClicked}
            />
        </ButtonGroup>
    );

    render() {
        return (
            <Wrapper>
                <ButtonWrapper>
                    {this.getLeftButtons()}
                    {this.getCenterButtons()}
                    {this.getRightButtons()}
                </ButtonWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(CircuitComputeFooter);
