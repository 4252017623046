// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { CalculatorIcon, InputNumber, PlusDotIcon } from 'components/_ReactUI_V1';

import {
    StageBodyWrapper,
    StageBodyField,
    StageBodyFieldItem,
    IsothermSelectTrigger,
    IsothermNameFieldItem,
} from './styles';
import { colors } from 'styles/colors';

// Constants
import {
    STAGE_VALUE_TYPES,
    STAGE_VALUE_LIMITS,
    DIAGRAM_DISPLAY_MODES,
    VALUE_STATUS,
    ISOTHERM_VALUE_MODES,
    NUMBER_INPUT_PLACEHOLDER,
    STYLE_VALUES,
    DATASET_MODES,
    STAGE_TYPES,
} from 'utils/constants';

// Helpers
import { getValueTypeUnit } from 'utils/kpiHelpers';
import { clamp, getValueAsString, round } from 'utils/helpers';

// Types
import type { InputEvent, IntlType } from 'types';
import type { DiagramDisplayModesConstant } from 'components/MimicDiagram/types';
import type { LooseStage, StageTypeConstant } from 'services/Circuit/types';
import type {
    LooseStageValue,
    StageValuesConstant,
    IsothermStageValue,
    DatasetModesConstant,
} from 'services/Dataset/types';
import type {
    OpenIsothermSelectModalFunction,
    SetStageValueFunction,
} from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    intl: IntlType,
    isSetupMode: boolean,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,

    stageData: LooseStage,
    startingColumn: number,
    startingRow: number,

    setStageValue: SetStageValueFunction,
    onOpenIsothermSelectModal: OpenIsothermSelectModalFunction,
};

class DiagramMixerSettler extends React.PureComponent<Props> {
    // Get Stage database id
    getStageId = (): number => this.props.stageData.id;

    // Get stage type (extract or strip)
    getStageType = (): StageTypeConstant => this.props.stageData.stageType;

    // Get stage numeric location on the stream
    getStageLocation = () => this.props.stageData.location;

    /**
     * Get the given valueType from the stage values if it exists.
     */
    getStageValue = (valueType: StageValuesConstant): ?LooseStageValue => {
        if (this.props.stageData.values) {
            return this.props.stageData.values.find(
                (stageValue: LooseStageValue) => stageValue.valueType === valueType
            );
        }
        return null;
    };

    isComputedMode = () => this.props.displayMode === DIAGRAM_DISPLAY_MODES.COMPUTED;

    /**
     * When the isotherm (+) icon is clicked, or the isotherm name is clicked
     * we want to open the isotherm select modal.
     */
    handleModifyIsothermClicked = () =>
        this.props.onOpenIsothermSelectModal(this.props.stageData.id);

    // Set stage numeric value on change
    onHandleStageValueChange = (stageValueType: StageValuesConstant) => (event: InputEvent) => {
        const stageValue = this.getStageValue(stageValueType);
        const newStageValue = event.target.value;
        if (!stageValue || newStageValue !== stageValue.value) {
            this.props.setStageValue(this.getStageId(), stageValueType, newStageValue);
        }
    };

    /**
     * On blur of stage value input, perform clamp
     */
    handleStageValueBlur = (stageValueType: StageValuesConstant) => (event: InputEvent) => {
        const value =
            event.target.value !== ''
                ? clamp(Number(event.target.value), event.target.min, event.target.max)
                : null;
        this.props.setStageValue(this.getStageId(), stageValueType, value);
    };

    getLabel = (valueType: StageValuesConstant): string =>
        this.props.intl.formatMessage({
            id: `components.MimicDiagram.Stage.Values.${valueType}`,
        });

    /**
     * Renders the add icon for isotherms
     */
    renderIsothermIcon = () => (
        <IsothermSelectTrigger onClick={this.handleModifyIsothermClicked}>
            <PlusDotIcon />
        </IsothermSelectTrigger>
    );

    /**
     * Renders the stage efficiency portion of a stage
     */
    renderSEBody = () => {
        const valueType = STAGE_VALUE_TYPES.STAGE_EFFICIENCY;
        const seValue = this.getStageValue(valueType);
        if (!seValue) {
            return null;
        }

        const showCalculator = seValue.status === VALUE_STATUS.COMPUTE;
        const isComputed = this.isComputedMode();
        let value = getValueAsString(seValue.value);
        if (showCalculator && !isComputed) {
            value = '';
        }

        // FIXME: Replace null with `circuitUnits`. This will break if percents are given a locality in the array:
        // LOCALITY_DEFAULT_UNITS_FOR_UNIT_TYPE
        const unit = getValueTypeUnit(valueType, null, this.props.intl);

        const calculatorItem = showCalculator ? (
            <StageBodyFieldItem flex={2}>
                <CalculatorIcon fill={colors.grey7B} />
            </StageBodyFieldItem>
        ) : null;

        const defaultPrecision = 0;
        const roundedValue = round(
            value,
            this.props.datasetMode === DATASET_MODES.ANALYSIS_MODE
                ? 0
                : seValue.precision || defaultPrecision
        );
        const label = this.getLabel(valueType);

        let items;
        if (isComputed) {
            items = (
                <React.Fragment>
                    {calculatorItem}
                    <StageBodyFieldItem title={!__PROD__ ? `${value}${unit}` : ''} flex={7}>
                        {`${label} = ${roundedValue}${unit}`}
                    </StageBodyFieldItem>
                </React.Fragment>
            );
        } else {
            items = (
                <React.Fragment>
                    {calculatorItem}
                    <StageBodyFieldItem flex={calculatorItem ? 3 : 2}>{label}</StageBodyFieldItem>
                    <StageBodyFieldItem flex={5}>
                        <InputNumber
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            renderString={showCalculator}
                            min={STAGE_VALUE_LIMITS[STAGE_VALUE_TYPES.STAGE_EFFICIENCY].MINIMUM}
                            max={STAGE_VALUE_LIMITS[STAGE_VALUE_TYPES.STAGE_EFFICIENCY].MAXIMUM}
                            value={value}
                            handleOnChange={this.onHandleStageValueChange(
                                STAGE_VALUE_TYPES.STAGE_EFFICIENCY
                            )}
                            onBlur={this.handleStageValueBlur(STAGE_VALUE_TYPES.STAGE_EFFICIENCY)}
                            style={STYLE_VALUES.DEFAULT_INPUT_NUMBER_STYLES}
                            noSpinner
                        />
                    </StageBodyFieldItem>
                    <StageBodyFieldItem flex={1}>{!calculatorItem && unit}</StageBodyFieldItem>
                </React.Fragment>
            );
        }

        return <StageBodyField>{items}</StageBodyField>;
    };

    /**
     * Renders the oa ration portion of a stage
     */
    renderOABody = () => {
        const valueType = STAGE_VALUE_TYPES.OA_RATIO;
        const oaValue = this.getStageValue(valueType);

        if (!oaValue) {
            return null;
        }

        const showCalculator = oaValue.status === VALUE_STATUS.COMPUTE;
        const isComputed = this.isComputedMode();
        let value = getValueAsString(oaValue.value);
        if (showCalculator && !isComputed) {
            value = '';
        }

        // const unit = getValueTypeUnit(oaValue.valueType, null, this.props.intl);

        const calculatorItem = showCalculator ? (
            <StageBodyFieldItem flex={2}>
                <CalculatorIcon fill={colors.grey7B} />
            </StageBodyFieldItem>
        ) : null;

        const label = this.getLabel(valueType);
        const defaultPrecision = 2;
        const precision = oaValue.precision === null ? defaultPrecision : oaValue.precision;
        const roundedValue = round(value, precision);

        let items;
        if (isComputed) {
            items = (
                <React.Fragment>
                    {calculatorItem}
                    <StageBodyFieldItem title={!__PROD__ ? `${value}` : ''} flex={7}>
                        {`${label} = ${roundedValue}`}
                    </StageBodyFieldItem>
                </React.Fragment>
            );
        } else {
            items = (
                <React.Fragment>
                    {calculatorItem}
                    <StageBodyFieldItem flex={calculatorItem ? 3 : 2}>{label}</StageBodyFieldItem>
                    <StageBodyFieldItem flex={5}>
                        <InputNumber
                            placeholder={NUMBER_INPUT_PLACEHOLDER}
                            renderString={showCalculator}
                            min={STAGE_VALUE_LIMITS[STAGE_VALUE_TYPES.OA_RATIO].MINIMUM}
                            max={STAGE_VALUE_LIMITS[STAGE_VALUE_TYPES.OA_RATIO].MAXIMUM}
                            value={value}
                            handleOnChange={this.onHandleStageValueChange(
                                STAGE_VALUE_TYPES.OA_RATIO
                            )}
                            onBlur={this.handleStageValueBlur(STAGE_VALUE_TYPES.OA_RATIO)}
                            style={STYLE_VALUES.DEFAULT_INPUT_NUMBER_STYLES}
                            noSpinner
                        />
                    </StageBodyFieldItem>
                    {/* Unit spacer to align inputs with above row */}
                    <StageBodyFieldItem flex={1} />
                </React.Fragment>
            );
        }

        return <StageBodyField>{items}</StageBodyField>;
    };

    /**
     * Renders the selected isotherm name, or predicted if predicted is selected
     */
    renderIsothermSelection = (isothermStageValue: IsothermStageValue) => {
        const name =
            isothermStageValue.isothermMode === ISOTHERM_VALUE_MODES.PREDICT
                ? this.props.intl.formatMessage({
                      id: 'components.MimicDiagram.Stage.Values.Isotherm.Predicted',
                  })
                : isothermStageValue.isotherm && isothermStageValue.isotherm.name;
        return (
            <IsothermNameFieldItem
                disabled={this.props.displayMode === DIAGRAM_DISPLAY_MODES.COMPUTED}
                onClick={this.handleModifyIsothermClicked}
                title={name}
            >
                {name}
            </IsothermNameFieldItem>
        );
    };

    /**
     * Renders the isotherm portion of a stage
     */
    renderIsothermBody = () => {
        const isothermValue = this.getStageValue(STAGE_VALUE_TYPES.ISOTHERM);

        return (
            <StageBodyField>
                <StageBodyFieldItem flex="0">
                    {this.props.intl.formatMessage({
                        id: 'components.MimicDiagram.Stage.Values.Isotherm.Label',
                    })}
                    :
                </StageBodyFieldItem>
                {isothermValue
                    ? this.renderIsothermSelection(isothermValue)
                    : this.renderIsothermIcon()}
            </StageBodyField>
        );
    };

    render() {
        if (this.props.isSetupMode) {
            return null;
        }
        return (
            <StageBodyWrapper>
                {this.renderSEBody()}
                {this.renderOABody()}
                {this.renderIsothermBody()}
            </StageBodyWrapper>
        );
    }
}

export default injectIntl(DiagramMixerSettler);
