// @flow strict

import type { ErrorType, GenericActionType } from 'types';
import type { Disclaimer } from 'services/Disclaimer/types';

export const DISCLAIMERS_FETCH_FAIL = 'app/services/Disclaimer/DISCLAIMERS_FETCH_FAIL';
export const DISCLAIMERS_FETCH_SUCCESS = 'app/services/Disclaimer/DISCLAIMERS_FETCH_SUCCESS';
export const DISCLAIMERS_IS_FETCHING = 'app/services/Disclaimer/DISCLAIMERS_ARE_FETCH';
export const DISCLAIMER_CREATE_FAIL = 'app/services/Disclaimer/DISCLAIMER_CREATE_FAIL';
export const DISCLAIMER_CREATE_SUCCESS = 'app/services/Disclaimer/DISCLAIMER_CREATE_SUCCESS';
export const DISCLAIMER_IS_CREATING = 'app/services/Disclaimer/DISCLAIMER_IS_CREATING';

export const setIsFetching = (isFetching: boolean = true) => ({
    type: DISCLAIMERS_IS_FETCHING,
    payload: { isFetching },
});

export const setIsCreating = (isCreating: boolean = true) => ({
    type: DISCLAIMER_IS_CREATING,
    payload: { isCreating },
});

export const receivedDisclaimersListSuccess = (
    disclaimersList: Array<Disclaimer>
): GenericActionType => ({
    type: DISCLAIMERS_FETCH_SUCCESS,
    payload: { disclaimersList },
});

export const receivedDisclaimersListFail = (errors: ErrorType): GenericActionType => ({
    type: DISCLAIMERS_FETCH_FAIL,
    payload: { errors },
});

export const receivedDisclaimerUpdateSuccess = (disclaimer: Disclaimer): GenericActionType => ({
    type: DISCLAIMER_CREATE_SUCCESS,
    payload: { disclaimer },
});

export const receivedDisclaimerUpdateFail = (errors: ErrorType): GenericActionType => ({
    type: DISCLAIMER_CREATE_FAIL,
    payload: { errors },
});
