// @flow strict

import { LegacyTheme } from 'components/_ReactUI_V1';

const GraphStyles = {
    mainGraph: {
        parent: {
            overflow: 'visible',
            boxSizing: 'border-box',
            fontFamily: 'Helvetica',
        },
    },

    mainGraphPadding: {
        top: 34,
        bottom: 100,
        left: 200,
        right: 200,
    },

    line: {
        data: {
            strokeWidth: '4px',
            opacity: 0.75,
        },
    },

    dotScatter: {
        data: {
            stroke: 'white',
            strokeWidth: 2,
        },
    },

    axisY: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        ticks: {
            size: 55,
            strokeWidth: 1,
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            padding: 5,
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontFamily: 'inherit',
            fontSize: 20,
            padding: 100,
        },
        tickLabels: {
            fill: LegacyTheme.defaultSubtleColor,
            fontFamily: 'inherit',
            fontSize: 16,
            padding: 20,
        },
    },

    axisYTickLabelTranslate: {
        dy: -20,
        dx: 40, // axisY.ticks.size - dx = spacing between axis and tick number.
    },

    axisX: {
        axis: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        grid: {
            stroke: LegacyTheme.graphAxisGridStrokeColor,
            strokeWidth: 1,
        },
        tickLabels: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 16,
            fontFamily: 'inherit',
        },
        axisLabel: {
            fill: LegacyTheme.defaultSubtleColor,
            fontSize: 20,
            fontFamily: 'inherit',
            padding: 74,
        },
    },
};

export default GraphStyles;
