// @flow strict

import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    padding: 31px;
`;

export const DiagramHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
`;

export const GraphWrapper = styled.div`
    max-width: ${({ graphWidth }) => graphWidth}px;
    max-height: ${({ graphHeight }) => graphHeight}px;
`;

export const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
