// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Styles
import { MajorKPIBlocksWrapper, StatusWrapper, Separator } from './styles';

// Services
import { selectAllKPISettings } from 'services/KPISetting/selectors';

// Components
import KPICard from 'components/KPICard';

// constants
import { KPIGraphColorSchemes } from 'styles/colors';

// Types
import type { IntlType, ImmutableList, LanguageCodeConstant } from 'types';
import type { ImmutableKPICard, ImmutableKPISetting } from 'services/KPISetting/types';
import type { DatasetStatusType } from 'services/Dataset/types';

type Props = {
    intl: IntlType,
    kpis: ImmutableList<ImmutableKPICard>,
    currentKPISettingId: number,
    updatedAt: string,
    datasetStatus: DatasetStatusType,
    userLanguage: LanguageCodeConstant,
    timezone: string,
    showMaxCuTransfer: boolean,
    onChangeCurrentKPI: (newKPI: ImmutableKPICard) => void,
};

/*
    Major KPI Trends component
 */
class MajorKPIBlocks extends React.PureComponent<Props> {
    isActiveKPI = (kpi: ImmutableKPICard) =>
        this.props.currentKPISettingId === kpi.get('kpiSettingId');

    getUpdateAtText = () =>
        this.props.intl.formatMessage(
            { id: 'components.MajorKPITrends.UpdatedAt' },
            { date: this.props.updatedAt }
        );

    getDatasetStatusText = () => {
        const datasetStatus = this.props.intl.formatMessage({
            id: `components.MajorKPITrends.DatasetStatuses.${this.props.datasetStatus}`,
        });

        return this.props.intl.formatMessage(
            { id: 'components.MajorKPITrends.DatasetStatusLabel' },
            { status: datasetStatus }
        );
    };

    getKPISettingForKPICard = (kpi: ImmutableKPICard): ImmutableKPISetting =>
        this.props.kpiSettings.find(
            (kpiSetting: ImmutableKPISetting) => kpiSetting.get('id') === kpi.get('kpiSettingId')
        );

    render() {
        return (
            <MajorKPIBlocksWrapper>
                <StatusWrapper>
                    <span>{this.getUpdateAtText()}</span>
                    <span>{this.getDatasetStatusText()}</span>
                </StatusWrapper>
                <Separator />
                {this.props.kpis.map((kpi: ImmutableKPICard, index: number) => (
                    <KPICard
                        key={kpi.get('kpiSettingId')}
                        kpi={kpi}
                        kpiSetting={this.getKPISettingForKPICard(kpi)}
                        active={this.isActiveKPI(kpi)}
                        timezone={this.props.timezone}
                        userLanguage={this.props.userLanguage}
                        colorScheme={
                            index < KPIGraphColorSchemes.length
                                ? KPIGraphColorSchemes[index]
                                : KPIGraphColorSchemes[0]
                        }
                        showMaxCuTransfer={this.props.showMaxCuTransfer}
                        onChangeCurrentKPI={this.props.onChangeCurrentKPI}
                        isMajorKPI
                    />
                ))}
            </MajorKPIBlocksWrapper>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        kpiSettings: selectAllKPISettings(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MajorKPIBlocks));
