// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const Card = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${LegacyTheme.tableBackground};
    border: 1px solid ${LegacyTheme.tableBorder};
    border-radius: 4px;

    margin: ${({ cardMargin }: Object) => cardMargin || '10px 0'};
    width: ${({ cardWidth }: Object) => cardWidth || 'auto'};
`;

export const CardTitle = styled.div`
    padding: 6px 16px;

    background-color: ${LegacyTheme.tableHeader};
    border-bottom: 1px solid ${LegacyTheme.tableBorder};
`;

export const CardSection = styled.div`
    margin: ${({ solvExtract }: Object) => (solvExtract ? '0px 52px 0 8px' : '0 0 20px 0')};
`;

export const CardBody = styled.div`
    padding: ${({ solvExtract }) => (solvExtract ? '16px 24px 32px' : '16px 16px 0 16px')};
    font-size: ${({ solvExtract }) => (solvExtract ? '28px' : '15px')};
    display: ${({ solvExtract }) => (solvExtract ? 'flex' : 'block')};
`;

export const CardSectionHeader = styled.div`
    margin-bottom: ${({ solvExtract }) => (solvExtract ? '16px' : '3px')};
    color: ${({ solvExtract }: Object) =>
        solvExtract ? LegacyTheme.defaultColor : LegacyTheme.tableHeaderColor};
    font-size: 13px;
`;

export const CardListWrapper = styled.div`
    display: flex;
    flex-direction: ${({ flexDirection }: Object) => flexDirection || 'column'};
    flex-wrap: ${({ flexDirection }: Object) => (flexDirection ? 'wrap' : 'nowrap')};
`;

export const LoadingBlock = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: ${STYLE_VALUES.GUTTERS.NORMAL};
`;
