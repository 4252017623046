// @flow strict

import React from 'react';
import momentTZ from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

import { PrimaryButton, SecondaryButton, InputSelect, Modal } from 'components/_ReactUI_V1';

// Styles
import { Wrapper, Header, Body, Footer, Field, FieldHeader } from './styles';

// Components
import { FormBlock, FormLabel, Title } from 'styles/common';
import SelectTimezoneField from '../../SelectTimezoneField';

// Constants
import { LANGUAGE_OPTIONS, MODAL_WIDTH, STYLE_VALUES } from 'utils/constants';

// Types
import type { IntlType, LanguageCodeConstant, ReactSelectObject } from 'types';

import type { GlobalUserPreferences, ImmutableUser } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    loading: boolean,
    onConfirm: (userPreferences: GlobalUserPreferences) => void,
    onCancel: () => void,
};

type State = {
    language: LanguageCodeConstant,
    timezone: string,
};

/**
 * The user settings update modal
 */
class LanguageSelectModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.getFreshState();
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.user) {
            throw new Error('No user supplied to select language modal.');
        }
        // The component was loaded before the user was loaded, and now we have the user
        // or are the two users different?
        if (
            (!prevProps.user && this.props.user) ||
            prevProps.user.get('id') !== this.props.user.get('id')
        ) {
            this.setState(this.getFreshState());
        }
    }

    /**
     * Get a new state object from the prop user preferences.
     */
    getFreshState = (): State => {
        // Grab the preferences or use an empty preferences object.
        const globalUserPreferences = this.props.user.getIn(['preferences', 'global'], fromJS({}));

        const language = globalUserPreferences.get('language', LANGUAGE_OPTIONS.EN);
        const timezone =
            globalUserPreferences.get('timezone', fromJS({})) ?? this.getDefaultTimezone();

        return {
            language,
            timezone,
        };
    };

    /**
     * Is the current state savable.
     * It will not be savable if there have been no changes to the user settings.
     */
    isSavable = (): boolean => {
        const settings = this.getFreshState();

        // Find the key of the unchanged setting
        const haveSettingsBeenUpdated = fromJS(settings).findKey(
            (value: any, key: string) => this.state.hasOwnProperty(key) && value !== this.state[key]
        );

        return haveSettingsBeenUpdated;
    };

    getLanguageItemToReactObject = (key: string) => ({
        label: this.props.intl.formatMessage({
            id: `constants.Languages.${LANGUAGE_OPTIONS[key]}`,
        }),
        value: key,
    });

    /**
     * Get the radio button language options
     */
    getLanguageOptions = () => Object.keys(LANGUAGE_OPTIONS).map(this.getLanguageItemToReactObject);

    /**
     * Gets the default timezone for the user, guessed based on their location
     */
    getDefaultTimezone = () => {
        return momentTZ.tz.guess();
    };

    /**
     * Handle the save button click
     */
    handleSaveClicked = () => {
        if (!this.isSavable()) {
            return;
        }
        // Fire the save update to the container
        this.props.onConfirm({
            language: this.state.language,
            timezone: this.state.timezone,
        });
    };

    /**
     * When the language radio button is changed, update the state
     */
    handleLanguageChange = (option: ReactSelectObject) =>
        this.setState({
            language: option.value,
        });

    /**
     * When the language radio button is changed, update the state
     */
    handleTimezoneChange = (selectedOption: ReactSelectObject) =>
        this.setState({
            timezone: selectedOption.value,
        });

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} modalWidth={MODAL_WIDTH.SMALL} disableClose>
                <Wrapper>
                    <Header>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: 'components.Modals.LanguageSelectModal.title',
                            })}
                        </Title>
                    </Header>
                    <Body>
                        <Field>
                            <FormBlock>
                                <FormLabel>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'components.Modals.LanguageSelectModal.selectLanguageLabel',
                                    })}
                                </FormLabel>
                                <InputSelect
                                    options={this.getLanguageOptions()}
                                    onSelect={this.handleLanguageChange}
                                    selectedOption={this.getLanguageItemToReactObject(
                                        this.state.language
                                    )}
                                    isDisabled={this.props.loading}
                                    controlShouldRenderValue
                                    isSearchable
                                />
                            </FormBlock>
                            <SelectTimezoneField
                                selectedTimezone={this.state.timezone}
                                isDisabled={this.props.loading}
                                onSelect={this.handleTimezoneChange}
                                maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.MEDIUM}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.saveButton',
                            })}
                            onClick={this.handleSaveClicked}
                            loading={this.props.loading}
                            disabled={!this.isSavable()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(LanguageSelectModal);
