// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Constants
import { CIRCUIT_TYPES } from 'utils/constants';

// Containers
import MinchemPreferencesFetcher from 'containers/MinchemPreferencesFetcher';

// Components
import DashboardPage from '../DashboardPage';

// Types
import type { IntlType, CircuitTypes } from 'types';

type Props = {
    intl: IntlType,
    circuitType?: ?CircuitTypes,
};

export class MinchemDashboardPage extends React.PureComponent<Props, null> {
    render() {
        return (
            <MinchemPreferencesFetcher>
                <DashboardPage circuitType={CIRCUIT_TYPES.MINCHEM_CIRCUIT} />
            </MinchemPreferencesFetcher>
        );
    }
}

export default injectIntl(MinchemDashboardPage);
