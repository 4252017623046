// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Mimic Engine
import {
    PrimaryButton,
    TertiaryButton,
    WarningButton,
    Modal,
    InputField,
    InputNumber,
    RadioButtonSet,
} from 'components/_ReactUI_V1';

import { type IMimicStage } from 'components/_McCabeThiele';

// Styles

import {
    Wrapper,
    Header,
    Body,
    Footer,
    Field,
    Label,
    RadioStyles,
    RadioFieldStyles,
} from './styles';

// Components
import { Title } from 'styles/common';

// Constant
import {
    MODAL_WIDTH,
    STYLE_VALUES,
    NUMBER_INPUT_PLACEHOLDER,
    NO_RESULT_STRING,
    STREAM_WATER_TYPES,
    STAGE_TYPES,
} from 'utils/constants';

// Helpers
import { tryParseNumberOrNull } from 'utils/helpers';

// Types
import type { IntlType, InputEvent } from 'types';
import type { StageProperties, StreamWaterTypes } from 'services/Circuit/types';

type Props = {
    intl: IntlType,
    stage: IMimicStage,
    showRemoveStageButton: boolean,
    isAdmin: boolean,

    onSave: (stageProperties: StageProperties) => void,
    onRemoveStage: () => void,
    onCloseModal: () => void,
};

type State = StageProperties;

const NONE_RECYCLE_TYPE_VALUE = -1;

/**
 * Shown to a SAM or Admin when they want to share an isotherm with PMs or Plants.
 */
class StagePropertiesModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        let properties = {};
        if (this.props.stage.properties) {
            properties = this.props.stage.properties;
        }

        // $FlowIgnore
        this.state = {
            settlerLength: properties.settlerLength || null,
            settlerWidth: properties.settlerWidth || null,
            settlerHeight: properties.settlerHeight || null,
            mixerVolume: properties.mixerVolume || null,
            recycleType: properties.recycleType || null,
            tankDiameter: properties.tankDiameter || null,
            tankHeight: properties.tankHeight || null,
            name: properties.name || null,
        };
    }

    getTranslation = (id: string, data?: any = {}) =>
        this.props.intl.formatMessage(
            {
                id: `components.Modals.StagePropertiesModal.${id}`,
            },
            data
        );

    getTitle = () => {
        let titleKey = '';
        if (this.isMixerSettler()) {
            titleKey = 'title.mixerSettler';
        } else if (this.isOrganicTank()) {
            titleKey = 'title.organicTank';
        } else if (this.isWasher()) {
            titleKey = 'title.washer';
        } else {
            throw new Error('Unknown stage type in stage properties modal');
        }
        return this.getTranslation(titleKey, {
            stageCode: this.props.stage.getName(),
        });
    };

    isMixerSettler = () =>
        [STAGE_TYPES.EXTRACT, STAGE_TYPES.STRIP].includes(this.props.stage.stageType);

    isOrganicTank = () => [STAGE_TYPES.ORGANIC_TANK].includes(this.props.stage.stageType);

    isWasher = () => [STAGE_TYPES.WASHER].includes(this.props.stage.stageType);

    /**
     * Get all of the stage recycle options for the radio button.
     */
    getStageRecycleOptions = () =>
        Object.keys(STREAM_WATER_TYPES)
            .map((value: StreamWaterTypes) => ({
                value,
                label: this.props.intl.formatMessage({
                    id: `constants.StreamWaterTypes.${value}`,
                }),
            }))
            .concat({
                value: NONE_RECYCLE_TYPE_VALUE,
                label: this.props.intl.formatMessage({
                    id: 'common.none',
                }),
            });

    canUserEditAdditionalProperties = (): boolean => {
        if (!this.props.isAdmin) {
            return false;
        }
        return true;
    };

    /**
     * When the user clicks on the Save button, supply our state to the MixerSettler
     */
    handleConfirm = () => {
        this.props.onSave(this.state);
    };

    /**
     * Updates our state with the new value supplied in the input box.
     * @param {*} e The event tied to the input box
     */
    handleChangeValue = (e: InputEvent) => {
        const stateKey = e.target.name;
        let value = e.target.value;
        // if the text box is of type number, cast it to a number.
        if (e.target.type === 'number') {
            value = tryParseNumberOrNull(e.target.value);
        }

        this.setState({
            [stateKey]: value,
        });
    };

    /**
     * When the user changes the recycle type checkbox update our state with the new value
     */
    handleChangeRecycleType = (recycleType: StreamWaterTypes) =>
        this.setState({
            // $FlowIgnore
            recycleType: recycleType === NONE_RECYCLE_TYPE_VALUE ? null : recycleType,
        });

    /**
     * Specifically for mixer-settlers
     */
    renderMixerSettlerProperties = () => {
        const distanceUnit = this.props.intl.formatMessage({
            id: 'constants.KpiUnits.METER',
        });
        const volumeUnit = this.props.intl.formatMessage({
            id: 'constants.KpiUnits.CUBIC_METER',
        });
        return (
            <React.Fragment>
                <Field>
                    <Label>{this.getTranslation('name')}</Label>
                    <InputField
                        name="name"
                        onChange={this.handleChangeValue}
                        value={this.state.name || ''}
                        placeholder={NO_RESULT_STRING}
                        style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                        maxLength={4}
                    />
                </Field>
                {this.canUserEditAdditionalProperties() && (
                    <React.Fragment>
                        <Field>
                            <Label>{this.getTranslation('settlerLength')}</Label>
                            <InputNumber
                                name="settlerLength"
                                value={this.state.settlerLength || ''}
                                onChange={this.handleChangeValue}
                                min={0}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                                unit={distanceUnit}
                            />
                        </Field>
                        <Field>
                            <Label>{this.getTranslation('settlerWidth')}</Label>
                            <InputNumber
                                name="settlerWidth"
                                value={this.state.settlerWidth || ''}
                                onChange={this.handleChangeValue}
                                min={0}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                                unit={distanceUnit}
                            />
                        </Field>
                        <Field>
                            <Label>{this.getTranslation('settlerHeight')}</Label>
                            <InputNumber
                                name="settlerHeight"
                                value={this.state.settlerHeight || ''}
                                onChange={this.handleChangeValue}
                                min={0}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                                unit={distanceUnit}
                            />
                        </Field>
                        <Field>
                            <Label>{this.getTranslation('mixerVolume')}</Label>
                            <InputNumber
                                name="mixerVolume"
                                value={this.state.mixerVolume || ''}
                                onChange={this.handleChangeValue}
                                min={0}
                                placeholder={NUMBER_INPUT_PLACEHOLDER}
                                style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                                unit={volumeUnit}
                            />
                        </Field>
                        <Field style={RadioFieldStyles}>
                            <Label>{this.getTranslation('stageRecycle')}</Label>
                            <RadioButtonSet
                                value={this.state.recycleType || NONE_RECYCLE_TYPE_VALUE}
                                options={this.getStageRecycleOptions()}
                                onClick={this.handleChangeRecycleType}
                                styles={RadioStyles}
                                orientation="HORIZONTAL"
                            />
                        </Field>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    renderOrganicTankProperties = () => {
        const distanceUnit = this.props.intl.formatMessage({
            id: 'constants.KpiUnits.METER',
        });
        return (
            <React.Fragment>
                <Field>
                    <Label>{this.getTranslation('tankDiameter')}</Label>
                    <InputNumber
                        name="tankDiameter"
                        value={this.state.tankDiameter || ''}
                        onChange={this.handleChangeValue}
                        min={0}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                        unit={distanceUnit}
                    />
                </Field>
                <Field>
                    <Label>{this.getTranslation('tankHeight')}</Label>
                    <InputNumber
                        name="tankHeight"
                        value={this.state.tankHeight || ''}
                        onChange={this.handleChangeValue}
                        min={0}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={STYLE_VALUES.DEFAULT_SMALL_INPUT_NUMBER_STYLES}
                        unit={distanceUnit}
                    />
                </Field>
            </React.Fragment>
        );
    };

    render() {
        return (
            <Modal onHandleClose={this.props.onCloseModal} modalWidth={MODAL_WIDTH.SMALL}>
                <Wrapper>
                    <Header>
                        <Title>{this.getTitle()}</Title>
                    </Header>
                    <Body>
                        {(this.isMixerSettler() || this.isWasher()) &&
                            this.renderMixerSettlerProperties()}
                        {this.isOrganicTank() && this.renderOrganicTankProperties()}
                    </Body>
                    <Footer>
                        {this.props.showRemoveStageButton && (
                            <WarningButton
                                text={this.getTranslation('removeStageButton')}
                                onClick={this.props.onRemoveStage}
                            />
                        )}
                        <TertiaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCloseModal}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.saveButton',
                            })}
                            onClick={this.handleConfirm}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(StagePropertiesModal);
