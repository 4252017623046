// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { Circuit } from 'services/Circuit/types';
import type { QueryStructure } from 'types';

export const CREATE_FAILURE = 'app/services/Circuit/CREATE_FAILURE';
export const CREATE_SUCCESS = 'app/services/Circuit/CREATE_SUCCESS';
export const DESTROY_FAILURE = 'app/services/Circuit/DESTROY_FAILURE';
export const DESTROY_SUCCESS = 'app/services/Circuit/DESTROY_SUCCESS';
export const FETCH_FAILURE = 'app/services/Circuit/FETCH_FAILURE';
export const FETCH_SUCCESS = 'app/services/Circuit/FETCH_SUCCESS';
export const QUERY_MINCHEM_FAILURE = 'app/services/Circuit/QUERY_MINCHEM_FAILURE';
export const QUERY_MINCHEM_SUCCESS = 'app/services/Circuit/QUERY_MINCHEM_SUCCESS';
export const QUERY_SOLVEXTRACT_FAILURE = 'app/services/Circuit/QUERY_SOLVEXTRACT_FAILURE';
export const QUERY_SOLVEXTRACT_SUCCESS = 'app/services/Circuit/QUERY_SOLVEXTRACT_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Circuit/UPDATE_FAILURE';
export const UPDATE_SUCCESS = 'app/services/Circuit/UPDATE_SUCCESS';
export const ELEVATION_FAILURE = 'app/services/Circuit/ELEVATION_FAILURE';
export const ELEVATION_SUCCESS = 'app/services/Circuit/ELEVATION_SUCCESS';
export const IS_CREATING = 'app/services/Circuit/IS_CREATING';
export const IS_DELETING = 'app/services/Circuit/IS_DELETING';
export const IS_FETCHING = 'app/services/Circuit/IS_FETCHING';
export const IS_QUERYING_MINCHEM = 'app/services/Circuit/IS_QUERYING_MINCHEM';
export const IS_QUERYING_SOLVEXTRACT = 'app/services/Circuit/IS_QUERYING_SOLVEXTRACT';
export const IS_UPDATING = 'app/services/Circuit/IS_UPDATING';
export const IS_ELEVATING = 'app/services/Circuit/IS_ELEVATING';

export const receivedCreateSuccess = (data: Circuit) => ({
    type: CREATE_SUCCESS,
    payload: { data },
});

export const receivedCreateFailure = (errors: {}) => ({
    type: CREATE_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: Circuit) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const receivedDestroySuccess = (data: Circuit) => ({
    type: DESTROY_SUCCESS,
    payload: { data },
});

export const receivedDestroyFailure = (errors: {}) => ({
    type: DESTROY_FAILURE,
    payload: { errors },
});

export const receivedQueryMinChemSuccess = (data: QueryStructure<Circuit>) => ({
    type: QUERY_MINCHEM_SUCCESS,
    payload: { data },
});

export const receivedQueryMinChemFailure = (errors: {}) => ({
    type: QUERY_MINCHEM_FAILURE,
    payload: { errors },
});

export const receivedQuerySolvExtractSuccess = (data: QueryStructure<Circuit>) => ({
    type: QUERY_SOLVEXTRACT_SUCCESS,
    payload: { data },
});

export const receivedQuerySolvExtractFailure = (errors: {}) => ({
    type: QUERY_SOLVEXTRACT_FAILURE,
    payload: { errors },
});

export const receivedFetchSuccess = (data: {}) => ({
    type: FETCH_SUCCESS,
    payload: { data },
});

export const receivedFetchFailure = (errors: {}) => ({
    type: FETCH_FAILURE,
    payload: { errors },
});

export const receivedElevationSuccess = (data: Circuit) => ({
    type: ELEVATION_SUCCESS,
    payload: { data },
});

export const receivedElevationFailure = (errors: {}) => ({
    type: ELEVATION_FAILURE,
    payload: { errors },
});

export const setIsQueryingMinChemStatus = (isQueryingMinChem: boolean = true) => ({
    type: IS_QUERYING_MINCHEM,
    payload: { isQueryingMinChem },
});

export const setIsQueryingSolvExtractStatus = (isQueryingSolvExtract: boolean = true) => ({
    type: IS_QUERYING_SOLVEXTRACT,
    payload: { isQueryingSolvExtract },
});

export const setIsUpdatingStatus = (isUpdating: boolean = true) => ({
    type: IS_UPDATING,
    payload: { isUpdating },
});

export const setIsDeletingStatus = (isDeleting: boolean = true) => ({
    type: IS_DELETING,
    payload: { isDeleting },
});

export const setIsCreatingStatus = (isCreating: boolean = true) => ({
    type: IS_CREATING,
    payload: { isCreating },
});

export const setIsFetchingStatus = (isFetching: boolean = true) => ({
    type: IS_FETCHING,
    payload: { isFetching },
});

export const setIsElevatingStatus = (isElevating: boolean = true) => ({
    type: IS_ELEVATING,
    payload: { isElevating },
});
