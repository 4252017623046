// @flow

import React, { type Node } from 'react';
import { LoadingDots } from '../../icons/LoadingDots';
import { PlusDotIcon } from '../../icons';

import type { ReactNode } from '../../types';

// Components
import { StyledBlankButton } from '../../base/styles';
import { SortableTh } from './sortableTh';

// Styles
import {
    StyledExpandedContent,
    StyledFooterMessage,
    StyledTable,
    StyledTableContainer,
    StyledTBody,
    StyledTd,
    StyledTdVerticalSpacer,
    StyledThead,
    StyledTr,
    StyledOverlayDiv,
    StyledOverlayTr,
    StyledLoadingContent,
} from './styles';

// Types
type HeaderType = {
    label: string,
    id: string,
    sortable?: boolean,
    styles?: Object,
};

type SortingType = {
    sortBy: string,
    sortOrder: string,
};

type Props = {
    currentSorting?: SortingType,
    expandedContentMaxHeight?: ?string,
    gridStyling?: boolean,
    header: Array<HeaderType>,
    headerColor?: ?string,
    footerMessage?: ?string,
    loading?: boolean,
    onAddEmptyRow?: ?() => void,
    onSortBy?: ?(SortingType) => void,
    overlay?: Node,
    rows: Array<Object>,
    trBackgroundColor?: ?string,
    trBackgroundColorHover?: ?string,
    subtleStyling?: boolean,
    tdColor?: ?string,
    tdMaxWidth?: ?string,
    tdMinHeight?: ?string,
    tdVerticalAlign?: ?string,
    tdWordBreak?: ?string,
    tdFontSize?: ?string,
    tdHeight?: ?string,
    tdPadding?: ?string,
};

type State = {
    currentSorting: SortingType,
};

export default class Table extends React.Component<Props, State> {
    static defaultProps = {
        currentSorting: {
            sortBy: '',
            sortOrder: 'inactive',
        },
        expandedContentMaxHeight: null,
        footerMessage: null,
        gridStyling: false,
        headerColor: null,
        loading: false,
        onAddEmptyRow: null,
        onSortBy: null,
        overlay: null,
        subtleStyling: false,
        tdColor: null,
        tdMaxWidth: '100%',
        tdMinHeight: 'none',
        tdVerticalAlign: 'middle',
        tdWordBreak: 'normal',
    };

    state = {
        currentSorting: {
            sortBy: (this.props.currentSorting && this.props.currentSorting.sortBy) || '',
            sortOrder: (this.props.currentSorting && this.props.currentSorting.sortOrder) || '',
        },
    };

    handleSortBy = (newSortBy: string, newSortOrder: string): void => {
        this.setState(
            {
                currentSorting: {
                    sortBy: newSortOrder === 'inactive' ? '' : newSortBy,
                    sortOrder: newSortOrder === 'inactive' ? '' : newSortOrder,
                },
            },
            () => this.props.onSortBy && this.props.onSortBy(this.state.currentSorting)
        );
    };

    renderAddEmptyRowTrigger = (): ?ReactNode =>
        this.props.onAddEmptyRow ? (
            <StyledTr>
                <StyledTd colSpan={this.props.header.length}>
                    <StyledBlankButton onClick={this.props.onAddEmptyRow()}>
                        <PlusDotIcon />
                    </StyledBlankButton>
                </StyledTd>
            </StyledTr>
        ) : null;

    renderLoader = (): ?ReactNode =>
        this.props.loading ? (
            <StyledTr>
                <StyledTd colSpan={this.props.header.length} flush>
                    <StyledLoadingContent>
                        <LoadingDots />
                    </StyledLoadingContent>
                </StyledTd>
            </StyledTr>
        ) : null;

    renderOverlay = (): ?ReactNode =>
        this.props.overlay ? (
            <StyledOverlayTr>
                <StyledOverlayDiv>{this.props.overlay}</StyledOverlayDiv>
            </StyledOverlayTr>
        ) : null;

    renderFooterMessage = (): ?ReactNode =>
        this.props.footerMessage ? (
            <StyledTr>
                <StyledTd colSpan={this.props.header.length} flush>
                    <StyledFooterMessage>{this.props.footerMessage}</StyledFooterMessage>
                </StyledTd>
            </StyledTr>
        ) : null;

    renderRows = (): Array<Object> =>
        this.props.rows.map(
            (row: Object) =>
                row && (
                    <React.Fragment key={row.id || Object.keys(row)[0]}>
                        <StyledTr
                            onClick={row.onClick || null}
                            disabled={row.disabled}
                            trBackgroundColor={this.props.trBackgroundColor}
                            trBackgroundColorHover={this.props.trBackgroundColorHover}
                        >
                            {this.props.header.map((h: HeaderType) => (
                                <StyledTd
                                    key={h.id}
                                    id={h.id}
                                    tdColor={this.props.tdColor}
                                    tdVerticalAlign={this.props.tdVerticalAlign}
                                    tdMaxWidth={this.props.tdMaxWidth}
                                    tdWordBreak={this.props.tdWordBreak}
                                    tdFontSize={this.props.tdFontSize}
                                    tdHeight={this.props.tdHeight}
                                    tdPadding={this.props.tdPadding}
                                >
                                    <StyledTdVerticalSpacer minHeight={this.props.tdMinHeight}>
                                        {row[h.id]}
                                    </StyledTdVerticalSpacer>
                                </StyledTd>
                            ))}
                        </StyledTr>
                        {row.expandedContent && (
                            <StyledTr>
                                <StyledTd colSpan={this.props.header.length} flush>
                                    <StyledExpandedContent
                                        maxHeight={this.props.expandedContentMaxHeight}
                                    >
                                        {row.expandedContent}
                                    </StyledExpandedContent>
                                </StyledTd>
                            </StyledTr>
                        )}
                    </React.Fragment>
                )
        );

    render() {
        if (!this.props.rows || !this.props.header) {
            return null;
        }

        let hasLabels = false;
        if (this.props.header.find((row: HeaderType) => row.label)) {
            hasLabels = true;
        }

        return (
            <StyledTableContainer
                gridStyling={this.props.gridStyling}
                subtleStyling={this.props.subtleStyling}
            >
                <StyledTable>
                    {hasLabels && (
                        <StyledThead>
                            <StyledTr>
                                {this.props.header.map(
                                    (row: HeaderType): ReactNode => (
                                        <SortableTh
                                            key={row.id}
                                            currentSorting={this.state.currentSorting}
                                            label={row.label}
                                            name={row.id}
                                            onClick={row.sortable ? this.handleSortBy : null}
                                            headerColor={this.props.headerColor}
                                            styles={row.styles}
                                        />
                                    )
                                )}
                            </StyledTr>
                        </StyledThead>
                    )}
                    <StyledTBody>
                        {this.renderOverlay()}
                        {this.renderRows()}
                        {this.renderAddEmptyRowTrigger()}
                        {this.renderFooterMessage()}
                        {this.renderLoader()}
                    </StyledTBody>
                </StyledTable>
            </StyledTableContainer>
        );
    }
}
