// @flow

import React from 'react';
import { fromJS } from 'immutable';

// Types
import type { InputValueType } from '../../types';

// Components
import { SecondaryButton } from '../buttons';
import { Close } from '../../icons';

// Styles
import legacyTheme from '../../themes/legacyTheme';
import { CloseIcon, MultipleInput, MultipleInputContainer } from './styles';

type InputType = {
    placeholder: string,
    value?: InputValueType,
    index: number,
    id: number,
};

type PositionType = 'left' | 'right';

type Props = {
    buttonComponent?: React.Component,
    buttonText?: string,
    closePosition?: PositionType,
    disableAll?: boolean,
    initialValues?: ImmutableList<InputType>,
    onChange?: (index: number, inputs: ImmutableList<InputType>) => void,
    onChangeInput?: (id: number, value: string) => void,
    onRemoveInput?: (index: number) => void,
    placeholder?: string,
    isEmptiable?: boolean,
};

type State = {
    inputs: ImmutableList<InputValueType>,
    autoFocus: boolean,
};

export default class InputMultiple extends React.Component<Props, State> {
    static defaultProps = {
        buttonComponent: null,
        buttonText: 'Add',
        closePosition: 'right',
        disableAll: false,
        initialValues: null,
        isEmptiable: false,
        onChange: null,
        onChangeInput: null,
        onRemoveInput: null,
        placeholder: '',
    };

    state = {
        inputs: this.props.initialValues
            ? fromJS(this.props.initialValues)
            : fromJS([
                  {
                      placeholder: this.props.placeholder,
                      value: '',
                      id: Math.random(), // TODO Replace
                  },
              ]),
        autoFocus: false,
    };

    /**
     * If component is passed initialValues, force componentDidUpdate()
     */
    shouldComponentUpdate(nextProps: Props) {
        return Boolean(nextProps.initialValues);
    }

    /**
     * If props.initialValues has changed or if props.initialValues & state.inputs is empty
     * replace state with initial values
     */
    componentDidUpdate(prevProps: Props) {
        if (
            (prevProps.initialValues &&
                !prevProps.initialValues.equals(this.props.initialValues)) ||
            (this.props.initialValues &&
                !this.state.inputs.hasIn([0, 'value']) &&
                !this.props.isEmptiable)
        ) {
            // Case where we need to compare prevProps to update state.
            /* eslint-disable-next-line react/no-did-update-set-state */
            this.setState({ inputs: this.props.initialValues });
        }
    }

    /**
     * Pushes new/empty inputObject into state.inputs array
     */
    handleOnClickMultipleInputButton = () => {
        this.setState((prevState: State) => ({
            inputs: prevState.inputs.push(
                fromJS({
                    placeholder: this.props.placeholder,
                    value: '',
                    id: Math.random(), // TODO Replace
                })
            ),
        }));
    };

    /**
     * Deletes passed index from state.inputs array & fires props.onRemoveInput(index)
     */
    handleOnClickRemoveInput = (i: number) => () => {
        if (this.state.inputs.size === 1 && !this.props.isEmptiable) {
            return;
        }

        this.setState(
            (prevState: State) => ({
                inputs: prevState.inputs.splice(i, 1),
            }),
            () => {
                if (this.props.onRemoveInput && typeof this.props.onRemoveInput === 'function') {
                    this.props.onRemoveInput(i);
                }
            }
        );
    };

    /**
     * Sets updated value from passed event into states.input.index & fires props.onChange(index, state.inputs)
     */
    handleOnChangeInput = (i: number, id: number) => (event: Event) => {
        const value = event.target.value;
        this.setState(
            (prevState: State) => ({
                inputs: prevState.inputs.setIn([i, 'value'], value),
            }),
            () => {
                if (this.props.onChange && typeof this.props.onChange === 'function') {
                    this.props.onChange(this.state.inputs);
                }
                if (this.props.onChangeInput && typeof this.props.onChangeInput === 'function') {
                    this.props.onChangeInput(id, this.state.inputs.getIn([i, 'value']));
                }
            }
        );
    };

    /**
     * Create a new input when a user presses the Enter key.
     */
    handleEnterKeyPress = (event: InputEventType) => {
        if (event.key === 'Enter') {
            this.handleOnClickMultipleInputButton();
        }
    };

    /**
     * Toggles the boolean value of this.state.autoFocus
     */
    handleToggleAutoFocus = () =>
        this.setState((prevState: State) => ({ autoFocus: !prevState.autoFocus }));

    render() {
        const inputs = this.state.inputs.map((input: InputType, i: number) => {
            const key = input.get('id') || `news-${i}`;
            const showClose =
                this.props.onRemoveInput &&
                !this.props.disableAll &&
                (i > 0 || this.state.inputs.size > 1 || this.props.isEmptiable);
            const closeIcon = showClose && (
                <CloseIcon
                    key={key}
                    onClick={this.handleOnClickRemoveInput(i)}
                    position={this.props.closePosition}
                >
                    <Close
                        strokeWidth={0}
                        width="16px"
                        height="16px"
                        fill={legacyTheme.defaultIconColor}
                        clickable
                    />
                </CloseIcon>
            );

            return (
                <MultipleInputContainer key={key}>
                    {this.props.closePosition === 'left' && closeIcon}
                    <MultipleInput
                        placeholder={this.props.placeholder}
                        onChange={this.handleOnChangeInput(i, key)}
                        onKeyPress={this.handleEnterKeyPress}
                        defaultValue={input.get('value')} // Using defaultValue instead of value for uncontrolled components.
                        disabled={this.props.disableAll}
                        /* eslint-disable-next-line jsx-a11y/no-autofocus */
                        autoFocus={this.state.autoFocus}
                        onFocus={this.handleToggleAutoFocus}
                        onBlur={this.handleToggleAutoFocus}
                    />
                    {this.props.closePosition === 'right' && closeIcon}
                </MultipleInputContainer>
            );
        });

        return (
            <React.Fragment>
                {inputs}
                {this.props.buttonComponent ? (
                    React.createElement(this.props.buttonComponent)
                ) : (
                    <SecondaryButton
                        onClick={this.handleOnClickMultipleInputButton}
                        text={this.props.buttonText}
                        disabled={this.props.disableAll}
                    />
                )}
            </React.Fragment>
        );
    }
}
