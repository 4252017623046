import React from 'react';
import Fuse from 'fuse.js';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { List } from 'immutable';

import { ImmutableList, ImmutableClient, ImmutablePlant, ImmutableCircuit, IntlType } from 'types';

import { InputField } from 'components/_ReactUI_V1';

type Props = {
    /**
     * Immutable list of clients for the search feature
     */
    clients: ImmutableList<ImmutableClient>,

    /**
     * callback function to handle the search result by the parent
     * @param searchState - state of the current research
     * @param searchState.searchTerm - input of the current search
     * @param searchState.searchResult result of the search
     * @returns nothing
     */
    onSearch: (searchState: {
        searchTerm: string,
        searchResult: ImmutableList<ImmutableClient>,
    }) => void,

    /**
     * Search term initial value
     */
    searchTerm: string,

    // Injected:
    intl: IntlType,
};

type State = {};

class QuickNavigationSearchBar extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        /**
         * see https://www.fusejs.io/api/options.html#minmatchcharlength
         */
        const minMatchCharLength = 2;

        this.fuseOptions = {
            minMatchCharLength,
            keys: [
                {
                    name: 'clientName',
                    getFn: (client: ImmutableClient): string | string[] => client.get('name'),
                },
                {
                    name: 'plantName',
                    getFn: (client: ImmutableClient): string | string[] => {
                        const searchInputs = client
                            .get('plants')
                            .map((plant: ImmutablePlant) => plant.get('name'))
                            .toArray();
                        return searchInputs;
                    },
                },
                {
                    name: 'circuitName',
                    getFn: (client: ImmutableClient): string | string[] => {
                        const searchInputs = client
                            .get('plants')
                            .flatMap((plant: ImmutablePlant) => plant.get('circuits'))
                            .map((circuit: ImmutableCircuit) => circuit.get('name'))
                            .toArray();
                        return searchInputs;
                    },
                },
            ],
        };

        this.fuse = new Fuse(this.props.clients.toArray(), this.fuseOptions);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.clients !== this.props.clients) {
            this.fuse = new Fuse(this.props.clients.toArray(), this.fuseOptions);
            this.search(this.props.searchTerm, this.props.clients);
        }
    }

    render() {
        return (
            <InputField
                onChange={(event) => {
                    const newSearchTerm: string = event.target.value;
                    this.search(newSearchTerm, this.props.clients);
                }}
                placeholder={this.props.intl.formatMessage({
                    id: `components.Header.SolvExtractQuickNavigator.search`,
                })}
                value={this.props.searchTerm}
            />
        );
    }

    search(newSearchTerm: string, clients: ImmutableList<ImmutableClient>) {
        let filteredClients = clients;
        if (newSearchTerm.length > this.fuseOptions.minMatchCharLength) {
            /**
             * contains the item that match the result with additional metadata
             * related to the search.
             */
            const result = this.fuse.search(newSearchTerm);

            // Remove search related metadata from result and move result into an ImmutableList
            filteredClients = List(result.map((val) => val.item));
        }
        this.props.onSearch({
            searchTerm: newSearchTerm,
            searchResult: filteredClients,
        });
    }
}

export default withRouter(injectIntl(QuickNavigationSearchBar));
