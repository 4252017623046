// @flow strict

/*
 *
 * Oxime reducer
 *
 */

import { fromJS } from 'immutable';

import {
    FETCH_LIST_SUCCESS as REAGENT_FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE as REAGENT_FETCH_LIST_FAILURE,
    IS_FETCHING as REAGENT_IS_FETCHING,
} from 'services/Reagent/actions';

import {
    CREATE_SUCCESS,
    CREATE_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DESTROY_SUCCESS,
    DESTROY_FAILURE,
    QUERY_LIST_SUCCESS,
    QUERY_LIST_FAILURE,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE,
    IS_FETCHING,
    IS_UPDATING,
    IS_DELETING,
    IS_CREATING,
    IS_QUERYING,
} from './actions';

import type { GenericActionType, ImmutableList } from 'types';
import type { ImmutableOxime, ImmutableOximeState } from './types';

const initialState: ImmutableOximeState = fromJS({
    oximes: [],
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isQuerying: false,
    query: {
        data: [],
        lastPage: 0,
    },
    errors: {},
});

function oximeServiceReducer(state: ImmutableOximeState = initialState, action: GenericActionType) {
    switch (action.type) {
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);
        case IS_DELETING:
            return state.set('isDeleting', action.payload.isDeleting);
        case IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);
        case IS_QUERYING:
            return state.set('isQuerying', action.payload.isQuerying);
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);

        case CREATE_SUCCESS: {
            const reagent = action.payload.data;

            return state
                .updateIn(['oximes'], (oximes: ImmutableList<ImmutableOxime>) => {
                    if (oximes && !oximes.isEmpty()) {
                        const idx = oximes.findIndex(
                            (r: ImmutableOxime) => r.get('id') === reagent.id
                        );
                        return idx !== -1
                            ? oximes.setIn([idx], fromJS(reagent))
                            : oximes.unshift(fromJS(reagent));
                    }
                })
                .updateIn(['query', 'data'], (oximes: ImmutableList<ImmutableOxime>) => {
                    if (oximes && !oximes.isEmpty()) {
                        const idx = oximes.findIndex(
                            (r: ImmutableOxime) => r.get('id') === reagent.id
                        );
                        return idx !== -1
                            ? oximes.setIn([idx], fromJS(reagent))
                            : oximes.unshift(fromJS(reagent));
                    }
                })

                .set('errors', fromJS({}))
                .set('isCreating', false);
        }
        case CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);

        case QUERY_LIST_SUCCESS: {
            const data = action.payload.data;

            return state
                .set('query', fromJS(data || {}))
                .set('errors', fromJS({}))
                .set('isQuerying', false);
        }
        case QUERY_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isQuerying', false);

        case UPDATE_SUCCESS: {
            const reagent = action.payload.data;
            return state
                .updateIn(['oximes'], (oximes: ImmutableList<ImmutableOxime>) => {
                    if (oximes && !oximes.isEmpty()) {
                        const idx = oximes.findIndex(
                            (r: ImmutableOxime) => r.get('id') === reagent.id
                        );
                        return idx !== -1
                            ? oximes.setIn([idx], fromJS(reagent))
                            : oximes.unshift(fromJS(reagent));
                    }
                })
                .updateIn(['query', 'data'], (oximes: ImmutableList<ImmutableOxime>) => {
                    if (oximes && !oximes.isEmpty()) {
                        const idx = oximes.findIndex(
                            (r: ImmutableOxime) => r.get('id') === reagent.id
                        );
                        return idx !== -1
                            ? oximes.setIn([idx], fromJS(reagent))
                            : oximes.unshift(fromJS(reagent));
                    }
                })
                .set('errors', fromJS({}))
                .set('isUpdating', false);
        }
        case UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isUpdating', false);

        case DESTROY_SUCCESS: {
            const reagent = action.payload.data;
            return state
                .updateIn(['oximes'], (oximes: ImmutableList<ImmutableOxime>) => {
                    if (oximes && !oximes.isEmpty()) {
                        const idx = oximes.findIndex(
                            (r: ImmutableOxime) => r.get('id') === parseInt(reagent.id, 10)
                        );
                        return idx !== -1 ? oximes.delete(idx) : oximes;
                    }
                })
                .updateIn(['query', 'data'], (oximes: ImmutableList<ImmutableOxime>) => {
                    if (oximes && !oximes.isEmpty()) {
                        const idx = oximes.findIndex(
                            (r: ImmutableOxime) => r.get('id') === parseInt(reagent.id, 10)
                        );
                        return idx !== -1 ? oximes.delete(idx) : oximes;
                    }
                })
                .set('errors', fromJS({}))
                .set('isDeleting', false);
        }
        case DESTROY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isDeleting', false);

        case REAGENT_IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);

        case FETCH_LIST_SUCCESS: {
            const oximes = action.payload.data;

            return state
                .set('oximes', fromJS(oximes || []))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        }

        case REAGENT_FETCH_LIST_SUCCESS: {
            const oximes = action.payload.data.oximes;

            return state
                .set('oximes', fromJS(oximes || []))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        }
        case FETCH_LIST_FAILURE:
        case REAGENT_FETCH_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isFetching', false);

        default:
            return state;
    }
}

export default oximeServiceReducer;
