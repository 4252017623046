// @flow strict

import styled from 'styled-components';

import { LegacyTheme, InputField } from 'components/_ReactUI_V1';

export const DateInput = styled(InputField)``;

export const DateSection = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 12px;
    margin-left: 22px;
`;

export const DateInputSection = styled.div`
    display: flex;
    flex-direction: column;

    & + & {
        margin-top: 16px;
    }
`;

export const DateLabel = styled.div`
    margin-bottom: 8px;

    font-size: 13px;
    color: ${LegacyTheme.radiobuttonLabelColor};
    line-height: 16px;
`;
