// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { InputField, PrimaryButton, SecondaryButton, Modal } from 'components/_ReactUI_V1';

import { formatPhoneNumber } from 'utils/helpers';

// Styles
import { Wrapper, Header, Body, Footer, Text, CTAText, ResendWrapper, ResendTimer } from './styles';

// Components
import { Title, Subtitle } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import {
    MODAL_WIDTH,
    PHONE_VERIFICATION_CUSTOM_ERRORS,
    PHONE_VERIFICATION_RESEND_MINS_ALLOWED,
} from 'utils/constants';

// Types
import type { InputEvent, IntlType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableError } from 'services/Errors/types';
import type { FeedbackType } from 'services/Feedback/types';

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    loading: boolean,
    onCancel: () => void,
    requestId: string,
    errors: ImmutableError,
    userPhoneNumber: string | null,
    submitPhoneNumber: (phoneNumber: string) => void,
    submitResendOTC: (requestId: string) => void,
    submitOTC: (requestId: string, otc: string) => void,
    displayErrorFeedback: (feedbackType: FeedbackType, messageId: string) => void,
};

const PHONE_VERIFICATION_STEPS = {
    PHONE_NUMBER: 'PHONE_NUMBER',
    OTC: 'OTC',
};

type State = {
    step: $Keys<typeof PHONE_VERIFICATION_STEPS>,
    step2Instructions: string,
    phoneNumber: string,
    otc: string,
    error: string,
    hideResendSms: { status: boolean, timerMinutes: number, timerSeconds: number },
};

class PhoneVerificationModal extends React.PureComponent<Props, State> {
    state: State = {
        step: PHONE_VERIFICATION_STEPS.PHONE_NUMBER,
        step2Instructions: 'phoneVerificationInstruction2',
        phoneNumber: '',
        otc: '',
        error: '',
        hideResendSms: {
            status: false,
            timerMinutes: PHONE_VERIFICATION_RESEND_MINS_ALLOWED,
            timerSeconds: 0,
        },
    };

    startResendTimer() {
        this.resendInterval = setInterval(() => {
            const { timerMinutes, timerSeconds } = this.state.hideResendSms;

            if (timerSeconds > 0) {
                this.setState({
                    ...this.state,
                    hideResendSms: {
                        ...this.state.hideResendSms,
                        timerMinutes,
                        timerSeconds: timerSeconds - 1,
                    },
                });
            } else if (timerSeconds === 0) {
                if (timerMinutes === 0) {
                    const hideResendSms = { ...this.state.hideResendSms, status: true };
                    this.setState({ ...this.state, hideResendSms });
                    clearInterval(this.resendInterval);
                } else {
                    this.setState({
                        ...this.state,
                        hideResendSms: {
                            ...this.state.hideResendSms,
                            timerMinutes: timerMinutes - 1,
                            timerSeconds: 59,
                        },
                    });
                }
            }
        }, 1000);
    }

    componentDidUpdate(prevProps: Props) {
        // Step one is successful (phone number submit)
        if (this.props.requestId !== prevProps.requestId && Boolean(this.props.requestId)) {
            this.setState({ step: PHONE_VERIFICATION_STEPS.OTC });
        }

        // Step two is successful (OTC submit)
        if (
            this.props.userPhoneNumber !== prevProps.userPhoneNumber &&
            Boolean(this.props.userPhoneNumber)
        ) {
            this.handleCancel();
        }

        // Custom error handling
        if (this.props.errors !== prevProps.errors && Boolean(this.props.errors.get('error'))) {
            this.handleCustomErrors(this.props.errors.get('error'));
        }
    }

    componentWillUnmount() {
        if (this.resendInterval) {
            clearInterval(this.resendInterval);
        }
    }

    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.UserSettings.NotificationSettings.${key}`,
            },
            data
        );

    handleCustomErrors = (error: string) => {
        /**
         * Happens if the user submits the same phone number within 5 minutes,
         * and the request ID is still available in the state, example
         * if the user closes the modal at the OTC step. In that case, we
         * still want the user to be able to use the current received OTC.
         */
        if (
            error === PHONE_VERIFICATION_CUSTOM_ERRORS.CONCURRENT_SAME_NUMBER &&
            Boolean(this.props.requestId)
        ) {
            return this.setState({
                step: PHONE_VERIFICATION_STEPS.OTC,
                step2Instructions: 'phoneVerificationInstruction2concurrent',
            });
        }

        /**
         * Happens if the user submits the same phone number within 5 minutes,
         * but the request ID is not available anymore, example if the user
         * refreshes the page. In that case the OTC becomes useless without
         * the request ID, so we invite the user to try again in 5 minutes
         * (the time it takes for Nexmo to cancel a ongoing request).
         */
        if (
            error === PHONE_VERIFICATION_CUSTOM_ERRORS.CONCURRENT_SAME_NUMBER &&
            !this.props.requestId
        ) {
            return this.setState({
                error: this.getTranslation('phoneVerificationConcurrentNoRequestId'),
            });
        }

        /**
         * The first one means that the user entered to wrong OTC associated to the
         * request ID.
         * The second one means that the request is expired, example if the user kept
         * the OTC modal step opened for too long before submitting the OTC.
         * The third one means that phone has already been verified, example
         * if the OTC modal remained open after validating and the user re-submits.
         * The fourth one means that the verification code has already been re-sent once,
         * it cannot be re-sent again (no next event to trigger)
         */
        if (
            error === PHONE_VERIFICATION_CUSTOM_ERRORS.WRONG_OTC ||
            error === PHONE_VERIFICATION_CUSTOM_ERRORS.WRONG_REQUEST_ID ||
            error === PHONE_VERIFICATION_CUSTOM_ERRORS.CODE_ALREADY_VERIFIED ||
            error === PHONE_VERIFICATION_CUSTOM_ERRORS.CODE_ALREADY_RESENT
        ) {
            return this.setState({
                error: this.props.intl.formatMessage({
                    id: `errorCodes.${error}`,
                }),
            });
        }

        this.props.displayErrorFeedback('ERROR', 'feedback.error.phoneVerificationFailed');
    };

    handleCancel = () => {
        this.setState({ phoneNumber: '', error: '' });
        this.props.onCancel();
    };

    handleNext = () => {
        if (this.state.step === PHONE_VERIFICATION_STEPS.PHONE_NUMBER) {
            if (!isValidPhoneNumber(`+${this.state.phoneNumber}`)) {
                return this.setState({
                    error: this.getTranslation('phoneValidationError'),
                });
            }

            if (this.state.phoneNumber === this.props.userPhoneNumber) {
                return this.setState({
                    error: this.getTranslation('samePhoneError'),
                });
            }

            this.props.submitPhoneNumber(this.state.phoneNumber);
            this.startResendTimer();
        } else if (this.state.step === PHONE_VERIFICATION_STEPS.OTC) {
            if (this.state.otc.length !== 4) {
                return this.setState({
                    error: this.getTranslation('otcValidationError'),
                });
            }

            this.props.submitOTC(this.props.requestId, this.state.otc);
        }

        this.setState({ error: '', step2Instructions: 'phoneVerificationInstruction2' });
    };

    handleChangePhoneNumber = (phoneNumber: string) => {
        this.setState({ phoneNumber });
    };

    handleChangeOTC = (e: InputEvent) => {
        this.setState({ otc: e.target.value });
    };

    handleClickResend = () => {
        /* clear timer on click and update the state */
        if (this.resendInterval) clearInterval(this.resendInterval);
        const hideResendSms = { status: true, timerMinutes: 0, timerSeconds: 0 };
        this.setState({ ...this.state, error: '', hideResendSms });
        this.props.submitResendOTC(this.props.requestId);
    };

    render() {
        const { timerMinutes, timerSeconds, status } = this.state.hideResendSms;
        return (
            <Modal onHandleClose={this.handleCancel} modalWidth={MODAL_WIDTH.LARGE} disableClose>
                <Wrapper>
                    <Header>
                        <Title>{this.getTranslation('phoneVerificationTitle')}</Title>
                    </Header>
                    <Body>
                        {this.state.step === PHONE_VERIFICATION_STEPS.PHONE_NUMBER && (
                            <React.Fragment>
                                <Text>{this.getTranslation('phoneVerificationInstruction1')}</Text>

                                <Subtitle style={{ margin: '0 0 8px' }}>
                                    {this.getTranslation('enterPhoneNumber')}
                                </Subtitle>

                                <PhoneInput
                                    country={'us'}
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChangePhoneNumber}
                                />
                            </React.Fragment>
                        )}

                        {this.state.step === PHONE_VERIFICATION_STEPS.OTC && (
                            <React.Fragment>
                                <Text>
                                    {this.getTranslation(this.state.step2Instructions, {
                                        phoneNumber: formatPhoneNumber(this.state.phoneNumber),
                                    })}
                                </Text>

                                <Subtitle style={{ margin: '0 0 8px' }}>
                                    {this.getTranslation('enterOTC')}
                                </Subtitle>

                                <InputField
                                    value={this.state.otc}
                                    onChange={this.handleChangeOTC}
                                    placeholder={this.getTranslation('verificationCode')}
                                    style={{
                                        maxWidth: '200px',
                                        fontSize: '18px',
                                        textAlign: 'center',
                                        margin: '0 0',
                                    }}
                                    maxLength={4}
                                />

                                {!status && (
                                    <ResendWrapper>
                                        <Text>{this.getTranslation('otcNotReceived')}</Text>
                                        <CTAText onClick={this.handleClickResend}>
                                            {this.getTranslation('resendOTC')}
                                        </CTAText>
                                        <ResendTimer>
                                            {' '}
                                            {timerMinutes}:
                                            {timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}
                                        </ResendTimer>
                                    </ResendWrapper>
                                )}
                            </React.Fragment>
                        )}

                        {this.state.error && (
                            <ErrorMessage errorMessage={this.state.error} isRed isSmall />
                        )}
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.Modals.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        <PrimaryButton
                            text={this.props.intl.formatMessage({
                                id: 'common.next',
                            })}
                            onClick={this.handleNext}
                            loading={this.props.loading}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(PhoneVerificationModal);
