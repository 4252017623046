// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Container
import IsothermManagementContainer from 'containers/IsothermManagementContainer';
import MinchemPreferencesFetcher from 'containers/MinchemPreferencesFetcher';

// Types
import type { IntlType, Navigation } from 'types';

// Helpers
import { tryParseNumber } from 'utils/helpers';
import { ISOTHERM_SIDEBAR_TYPES } from 'utils/constants';

type Props = {
    intl: IntlType,
};

/**
 * Isotherm Management View
 */
export class IsothermManagement extends React.PureComponent<Props, null> {
    getIsothermId() {
        if (
            this.props.match.params.isothermId === ISOTHERM_SIDEBAR_TYPES.CREATE.toLowerCase() ||
            this.props.match.params.isothermId === ISOTHERM_SIDEBAR_TYPES.PREDICT.toLowerCase()
        ) {
            return this.props.match.params.isothermId.toUpperCase();
        }
        return tryParseNumber(this.props.match.params.isothermId);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.IsothermManagement.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.IsothermManagement.helmetDescription',
                        })}
                    />
                </Helmet>
                <MinchemPreferencesFetcher>
                    <IsothermManagementContainer isothermId={this.getIsothermId()} />
                </MinchemPreferencesFetcher>
            </React.Fragment>
        );
    }
}

export default injectIntl(IsothermManagement);
