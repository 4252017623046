// @flow

import styled from 'styled-components';
import defaultTheme from '../../themes/defaultTheme';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

const borderWidth = 1;

export const StyledToggleLabel = styled.label`
    font-size: 12px;
    color: ${({ theme, disabled }) =>
        themePropertyFetcher(theme, [
            'formElements',
            'toggle',
            disabled ? 'colorDisabled' : 'color',
        ])};

    cursor: ${({ disabled }: Object) => (disabled ? 'inherit' : 'pointer')};

    > span {
        vertical-align: middle;
        &:first-child {
            margin-right: 10px;
        }
    }
`;

export const StyledToggle = styled.span`
    position: relative;
    width: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['formElements', 'toggle', 'width'])};
    height: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['formElements', 'toggle', 'height'])};
    display: inline-block;

    cursor: ${({ disabled }: Object) => (disabled ? 'inherit' : 'pointer')};

    ${({ disabled, checked, theme }: Object) => {
        let background = '';
        let border = '';

        if (disabled) {
            if (checked) {
                background = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'checked',
                    'disabled',
                    'backgroundColor',
                ]);
                const borderColor = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'checked',
                    'disabled',
                    'borderColor',
                ]);
                border = `${borderWidth}px solid ${borderColor}`;
            } else {
                background = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'unchecked',
                    'disabled',
                    'backgroundColor',
                ]);
                const borderColor = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'unchecked',
                    'disabled',
                    'borderColor',
                ]);
                border = `${borderWidth}px solid ${borderColor}`;
            }
        } else {
            if (checked) {
                background = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'checked',
                    'backgroundColor',
                ]);
                const borderColor = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'checked',
                    'borderColor',
                ]);
                border = `${borderWidth}px solid ${borderColor}`;
            } else {
                background = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'unchecked',
                    'backgroundColor',
                ]);
                const borderColor = themePropertyFetcher(theme, [
                    'formElements',
                    'toggle',
                    'unchecked',
                    'borderColor',
                ]);
                border = `${borderWidth}px solid ${borderColor}`;
            }
        }

        return `
            background: ${background};
            border: ${border};
        `;
    }};

    ${StyledToggleLabel}:hover & {
        ${({ disabled, checked, theme }: Object) => {
            let background = '';
            let border = '';

            if (!disabled) {
                if (checked) {
                    background = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'checked',
                        'hover',
                        'backgroundColor',
                    ]);
                    const borderColor = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'checked',
                        'hover',
                        'borderColor',
                    ]);
                    border = `${borderWidth}px solid ${borderColor}`;
                } else {
                    background = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'unchecked',
                        'hover',
                        'backgroundColor',
                    ]);
                    const borderColor = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'unchecked',
                        'hover',
                        'borderColor',
                    ]);
                    border = `${borderWidth}px solid ${borderColor}`;
                }
            }
            return `
            background: ${background};
            border: ${border};
        `;
        }}
    }

    border-radius: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['formElements', 'toggle', 'borderRadius'])};
    box-sizing: border-box;

    // Toggler Styling

    &:after {
        content: '';
        position: absolute;
        top: -${borderWidth}px;
        left: -${borderWidth}px;

        display: block;
        width: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['formElements', 'toggle', 'toggler', 'width'])};
        height: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['formElements', 'toggle', 'toggler', 'height'])};
        margin: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['formElements', 'toggle', 'toggler', 'margin'])};

        ${({ disabled, checked, theme }: Object) => {
            let background = '';

            if (disabled) {
                if (checked) {
                    background = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'checked',
                        'disabled',
                        'togglerColor',
                    ]);
                } else {
                    background = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'unchecked',
                        'disabled',
                        'togglerColor',
                    ]);
                }
            } else {
                if (checked) {
                    background = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'checked',
                        'togglerColor',
                    ]);
                } else {
                    background = themePropertyFetcher(theme, [
                        'formElements',
                        'toggle',
                        'unchecked',
                        'togglerColor',
                    ]);
                }
            }

            return `
                background: ${background};
            `;
        }};

        border-radius: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['formElements', 'toggle', 'borderRadius'])};
        transition: left ease 0.2s;
    }

    ${({ checked, theme }: Object) =>
        checked &&
        `
        &:after {
            left: calc(100% - (${themePropertyFetcher(theme, [
                'formElements',
                'toggle',
                'toggler',
                'width',
            ])} + ${themePropertyFetcher(theme, [
            'formElements',
            'toggle',
            'toggler',
            'margin',
        ])} + ${borderWidth}px) - ${borderWidth}px);
        }
    `};

    ${StyledToggleLabel}:hover & {
        &:after {
            ${({ disabled, checked, theme }: Object) => {
                let background = '';

                if (!disabled) {
                    if (checked) {
                        background = themePropertyFetcher(theme, [
                            'formElements',
                            'toggle',
                            'checked',
                            'hover',
                            'togglerColor',
                        ]);
                    } else {
                        background = themePropertyFetcher(theme, [
                            'formElements',
                            'toggle',
                            'unchecked',
                            'hover',
                            'togglerColor',
                        ]);
                    }
                }

                return `
                    background: ${background};
                `;
            }}
        }
    }
`;

export const HiddenInput = styled.input`
    display: none;
`;
