// @flow strict

import { fromJS } from 'immutable';

import type { ImmutableList } from '../types';

import type { ImmutableClient, ImmutableClientState } from './Client/types';
import type { ImmutableCircuit, ImmutableCircuitState } from './Circuit/types';
import type { ImmutablePlant, ImmutablePlantState } from './Plant/types';
import type {
    ImmutableDataset,
    ImmutableDatasetValue,
    ImmutableStageValue,
    ImmutableStreamValue,
} from 'services/Dataset/types';
import type { ImmutablePlantValue } from 'services/PlantDataset/types';
import type { ImmutableTrendsState } from 'services/Trends/types';

type PossibleSubStateType = ImmutableClientState | ImmutablePlantState | ImmutableCircuitState;
type PossibleEntityType = ImmutableClient | ImmutablePlant | ImmutableCircuit;

export function setEntityIntoSubStateLists(
    subState: PossibleSubStateType,
    listKey: string,
    entity: PossibleEntityType,
    queryKey?: string = 'query'
): PossibleSubStateType {
    try {
        if (subState && !entity) {
            return subState;
        }

        let newSubState = subState;

        if (listKey) {
            newSubState = newSubState.updateIn(
                [listKey],
                (entities: ImmutableList<PossibleEntityType>) => {
                    const idx = entities.findIndex(
                        (e: PossibleEntityType) => e.get('id') === entity.get('id')
                    );
                    return idx !== -1 ? entities.setIn([idx], entity) : entities.unshift(entity);
                }
            );
        }

        if (queryKey) {
            newSubState = newSubState.updateIn(
                [queryKey, 'data'],
                (entities: ImmutableList<PossibleEntityType>) => {
                    const idx = entities.findIndex(
                        (e: PossibleEntityType) => e.get('id') === entity.get('id')
                    );
                    // Note that the unshift is very important here as this means the new entity will be added to the top of the list
                    // If this logic is to be adjusted, please validate
                    return idx !== -1 ? entities.setIn([idx], entity) : entities.unshift(entity);
                }
            );
        }

        return newSubState;
    } catch (error) {
        throw new Error(`Cannot set entity into substate lists: ${error.message}`);
    }
}

export function deleteEntityFromSubStateLists(
    subState: PossibleSubStateType,
    listKey: string,
    entity: PossibleEntityType,
    queryKey?: string = 'query'
): PossibleSubStateType {
    try {
        if (subState && !entity) {
            return subState;
        }

        let finalState = subState;

        if (listKey) {
            finalState = finalState.updateIn(
                [listKey],
                (entities: ImmutableList<PossibleEntityType>) => {
                    const idx = entities.findIndex(
                        (e: PossibleEntityType) => e.get('id') === parseInt(entity.get('id'), 10)
                    );
                    return idx !== -1 ? entities.delete(idx) : entities;
                }
            );
        }
        if (queryKey) {
            finalState = finalState.updateIn(
                [queryKey, 'data'],
                (entities: ImmutableList<PossibleEntityType>) => {
                    const idx = entities.findIndex(
                        (e: PossibleEntityType) => e.get('id') === parseInt(entity.get('id'), 10)
                    );
                    return idx !== -1 ? entities.delete(idx) : entities;
                }
            );
        }

        return finalState;
    } catch (error) {
        throw new Error(`Cannot delete entity from substate lists: ${error.message}`);
    }
}

// TODO: Convert to enum (ts)
type PossibleValueTypeKeys = 'datasetValues' | 'stageValues' | 'streamValues' | 'plantValues';
type PossibleListOfValueTypes = ImmutableList<
    ImmutableDatasetValue | ImmutableStageValue | ImmutableStreamValue | ImmutablePlantValue
>;

type PossibleUpdatingKey =
    | 'isCreatingOrUpdatingDatasetValue'
    | 'isCreatingOrUpdatingStageValue'
    | 'isCreatingOrUpdatingStreamValue'
    | 'isCreatingOrUpdatingPlantValue';

export const setValueInModalDatasetValuesAndTrends = (
    subState: ImmutableTrendsState,
    valueType: PossibleValueTypeKeys,
    updatingKey: PossibleUpdatingKey,
    data: Object
) => {
    // Upon a successful value update/create:
    // - Update new value & datasetUpdatedAt in modalDatasetValues
    // - Update updatedAt with datasetUpdatedAt in the plant/circuit archive list
    // - TODO: MS-1019 - Update value in trend
    const updatedValue = fromJS(data);
    const isPlant = valueType === 'plantValues';
    const datasetIdKey = isPlant ? 'plantDatasetId' : 'datasetId';
    const archiveKey = isPlant ? 'plantArchive' : 'circuitArchive';

    const archiveIdx = subState
        .get(archiveKey)
        .findIndex(
            (dataset: ImmutableDataset) => dataset.get('id') === updatedValue.get(datasetIdKey)
        );

    return (
        subState
            .updateIn(['modalDatasetValues', 'dataset'], (dataset: ImmutableDataset) => {
                const foundDatasetValues = dataset.get('id') === updatedValue.get(datasetIdKey);
                if (!foundDatasetValues) {
                    throw new Error('No matching dataset found');
                }
                const valueIdx = dataset
                    .get(valueType)
                    .findIndex(
                        (datasetValue: ImmutableDatasetValue) =>
                            datasetValue.get('id') === updatedValue.get('id')
                    );
                if (valueIdx === -1) {
                    return dataset
                        .set('updatedAt', updatedValue.get('datasetUpdatedAt'))
                        .updateIn([valueType], (values: PossibleListOfValueTypes) =>
                            values.push(updatedValue)
                        );
                }

                return dataset
                    .set('updatedAt', updatedValue.get('datasetUpdatedAt'))
                    .setIn([valueType, valueIdx, 'value'], updatedValue.get('value'));
            })
            // TODO: What happens if archiveIdx is -1? It should never be as a user can only access the Dataset Value modal via the list of archive circuit/plants...
            .updateIn([archiveKey, archiveIdx], (dataset: ImmutableDataset) =>
                dataset.set('updatedAt', updatedValue.get('datasetUpdatedAt'))
            )
            .set('errors', fromJS({}))
            .set(updatingKey, false)
    );

    // TODO: MS-1018
    // Add datasetId into the trend initialHistory/history objects
    // Add param trendKey: circuitTrends | plantTrends so this can be dynamic
    // const trendIdx = state.get('trendKey').findIndex((trend: ImmutableCircuitTrend | ImmutablePlantTrend) => {
    //     return trend.get(datasetIdKey) === updatedDatasetValue.get(datasetIdKey);
    // });
    // .updateIn(['trendKey', trendIdx], (trend: ImmutableCircuitTrend: ImmutablePlantTrend) => {
    //         // We need to provide the timestamp in the updatedDatasetValue..
    //         return trend;
    //     })
};
