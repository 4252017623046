// @flow

import React from 'react';

// Styles
import { StyledTh, SortToggler, SortToggle } from './styles';

type Props = {
    currentSorting: {
        sortBy: string,
        sortOrder: string,
    },
    headerColor?: string,
    label: string,
    name: string,
    onClick: ?(string, string) => void,
    styles?: Object,
};

type State = {
    sortBy: string,
    sortOrder: string,
};

/**
 * Sortable th component for table sorting
 */

export class SortableTh extends React.Component<Props, State> {
    static defaultProps = {
        headerColor: null,
        styles: null,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            sortBy: props.currentSorting.sortBy,
            sortOrder: props.currentSorting.sortOrder,
        };
    }

    componentDidUpdate(prevProps: Props) {
        // Case where we need to compare prevProps to update state.
        if (this.props.currentSorting.sortBy !== prevProps.currentSorting.sortBy) {
            /* eslint-disable-next-line react/no-did-update-set-state */
            this.setState({
                sortBy: this.props.currentSorting.sortBy || '',
            });
        }
    }

    handleSortToggle = (newSortBy: string, newSortOrder: string) => () => {
        // Possible toggle states
        const states = ['asc', 'desc', 'inactive'];
        // If less then the length of states && sortBy has not changed, add one else set to 0
        let i = states.indexOf(newSortOrder);
        if (i < states.length - 1 && this.state.sortBy === newSortBy) {
            ++i;
        } else {
            i = 0;
        }

        this.setState(
            {
                sortOrder: states[i],
            },
            () => this.props.onClick && this.props.onClick(newSortBy, states[i])
        );
    };

    render() {
        let content = '';
        // If onClick prop is passed, build out markup for SortableTh,
        // else return basic
        if (this.props.onClick) {
            content = (
                <SortToggle onClick={this.handleSortToggle(this.props.name, this.state.sortOrder)}>
                    <span>{this.props.label}</span>
                    <SortToggler
                        active={this.props.currentSorting.sortBy === this.props.name}
                        asc={this.state.sortOrder === 'asc'}
                        desc={this.state.sortOrder === 'desc'}
                    />
                </SortToggle>
            );
        } else {
            content = this.props.label;
        }

        return (
            <StyledTh headerColor={this.props.headerColor} style={this.props.styles || {}}>
                {content}
            </StyledTh>
        );
    }
}
