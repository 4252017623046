// @flow

import React from 'react';

// Components
import { Close, PlusDotIcon } from '../../icons';

// Styles
import { StyledButtonItem, StyledClose, StyledList, StyledItem } from './styles';
import { StyledBlankButton } from '../../base/styles';
import defaultTheme from '../../themes/defaultTheme';

// Types
import type { NumberOrStringType, ReactNode } from '../../types';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

type ItemType = {
    label: string,
    value: NumberOrStringType,
};

type Props = {
    list: Array<ItemType>,
    onAddItem?: ?() => void,
    onRemoveItem?: ?(value: NumberOrStringType) => void,
    theme: ?{},
};

export default class TagList extends React.Component<Props> {
    static defaultProps = {
        onAddItem: null,
        onRemoveItem: null,
    };

    renderAddItemTrigger = (): ?ReactNode =>
        this.props.onAddItem ? (
            <StyledButtonItem>
                <StyledBlankButton onClick={this.props.onAddItem()}>
                    <PlusDotIcon theme={this.props.theme} />
                </StyledBlankButton>
            </StyledButtonItem>
        ) : null;

    render() {
        const { list } = this.props;

        const items =
            list &&
            list.map((item: ItemType) => (
                <StyledItem key={item.label} theme={this.props.theme}>
                    {item.label}
                    {this.props.onRemoveItem && (
                        <StyledClose
                            onClick={this.props.onRemoveItem && this.props.onRemoveItem(item.value)}
                        >
                            <Close
                                fill={themePropertyFetcher(this.props.theme || defaultTheme, [
                                    'tagList',
                                    'item',
                                    'close',
                                    'fill',
                                ])}
                                margin="0"
                                strokeWidth={1}
                                width="10px"
                                style={{ display: 'block' }}
                                clickable
                            />
                        </StyledClose>
                    )}
                </StyledItem>
            ));

        return (
            <StyledList theme={this.props.theme}>
                {items}
                {this.renderAddItemTrigger()}
            </StyledList>
        );
    }
}
