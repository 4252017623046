// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { DotMenu, ToolTip, PrimaryButton } from 'components/_ReactUI_V1';
import ResponsiveTable from 'components/ResponsiveTable';

// Helpers
import { getCurrentTimeZone, getFormattedDateFromString } from 'utils/dateHelpers';

// Styles
import { Wrapper, MenuToolTip, MenuToolTipContent, ToolTipButton } from './styles';

// Types
import type { IntlType, ImmutableList, SearchCriteria } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { OpenModalFunction } from 'containers/DashboardContainer';

// Constants
const DATE_FORMAT = {
    minute: 'numeric',
    hour: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
};

type Props = {
    intl: IntlType,
    onOpenModal: OpenModalFunction,
    userLocale: string,

    isFetching: boolean,
    circuits: ImmutableList<ImmutableCircuit>,
    onSortBy: (structure: SearchCriteria) => void,
};

type State = {
    openedMenuCircuit: ?ImmutableCircuit,
};

class MinChemCircuitTable extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openedMenuCircuit: null,
        };
    }

    getTranslation = (key: string) =>
        this.props.intl.formatMessage({
            id: `components.MinChemCircuitTable.${key}`,
        });

    getTableHeader() {
        return [
            {
                id: 'name',
                label: this.getTranslation('Header.name'),
                sortable: true,
            },
            { id: 'plantName', label: this.getTranslation('Header.plantName'), sortable: true },
            {
                id: 'updatedAt',
                label: this.getTranslation('Header.updatedAt'),
                sortable: true,
            },
            { id: 'comments', label: this.getTranslation('Header.comments'), sortable: false },
            { id: 'extra', label: '', sortable: false },
            { id: 'actions', label: '', sortable: false },
        ];
    }

    isCircuitMenuOpen = (circuit: ImmutableCircuit) =>
        (this.state.openedMenuCircuit && this.state.openedMenuCircuit.get('id')) ===
        circuit.get('id');

    handleOpenModal = (
        circuit: ImmutableCircuit,
        modalType: 'SELECT_DATASET' | 'UPDATE_CIRCUIT' | 'DELETE_CIRCUIT'
    ) => () => this.props.onOpenModal(modalType, circuit);

    handleCircuitMenuOpen = (circuit: ImmutableCircuit) => () => {
        if (this.isCircuitMenuOpen(circuit)) {
            // if the circuit menu is currently open, close it.
            this.setState({ openedMenuCircuit: null });
        } else {
            this.setState({ openedMenuCircuit: circuit });
        }
    };

    handleCircuitMenuClose = () => this.setState({ openedMenuCircuit: null });

    getRowExtra = (circuit: ImmutableCircuit) => (
        <MenuToolTip>
            <ToolTip
                content={
                    <MenuToolTipContent>
                        <ToolTipButton onClick={this.handleOpenModal(circuit, 'UPDATE_CIRCUIT')}>
                            {this.getTranslation('Menu.edit')}
                        </ToolTipButton>
                        <ToolTipButton onClick={this.handleOpenModal(circuit, 'DELETE_CIRCUIT')}>
                            {this.getTranslation('Menu.delete')}
                        </ToolTipButton>
                    </MenuToolTipContent>
                }
                position="bottom"
                trigger={<DotMenu active={this.isCircuitMenuOpen(circuit)} />}
                triggerType="click"
                onOpen={this.handleCircuitMenuOpen(circuit)}
                onClose={this.handleCircuitMenuClose}
                interactive
                closeOnInternalClick
            />
        </MenuToolTip>
    );

    getTableRows() {
        const noDataDash = this.getTranslation('noDataDash');
        return this.props.circuits
            .map((circuit: ImmutableCircuit) => ({
                id: circuit.get('id'),
                name: circuit.get('name'),
                plantName: circuit.get('plantName') || noDataDash,
                updatedAt: getFormattedDateFromString(
                    circuit.get('updatedAt'),
                    this.props.userLocale,
                    {
                        ...DATE_FORMAT,
                        timeZone: getCurrentTimeZone(),
                    }
                ),
                comments: circuit.get('comments') || noDataDash,
                extra: this.getRowExtra(circuit),
                actions: (
                    <PrimaryButton
                        text={this.getTranslation('selectDataset')}
                        onClick={this.handleOpenModal(circuit, 'SELECT_DATASET')}
                    />
                ),
                onClick: this.handleOpenModal(circuit, 'SELECT_DATASET'),
            }))
            .toArray();
    }

    render() {
        const rows = this.getTableRows();
        return (
            <Wrapper>
                <ResponsiveTable
                    header={this.getTableHeader()}
                    rows={rows}
                    loading={this.props.isFetching}
                    onSortBy={this.props.onSortBy}
                    tdVerticalAlign="top"
                    tdMaxWidth="500px"
                    tdWordBreak="break-word"
                    footerMessage={
                        !rows.length && !this.props.isFetching
                            ? this.props.intl.formatMessage({
                                  id: 'components.MinChemCircuitTable.noRows',
                              })
                            : null
                    }
                    titleId="name"
                    controlsId="extra"
                    mobileOnlyHeaders={['actions']}
                />
            </Wrapper>
        );
    }
}

export default injectIntl(MinChemCircuitTable);
