// @flow

import styled from 'styled-components';

import legacyTheme from '../themes/legacyTheme';

// Assets
import caretDownBlack from './assets/Caret/down-black.png';
import caretUpBlack from './assets/Caret/up-black.png';
import caretLeftGrey from './assets/Caret/left-grey.png';
import caretRightGrey from './assets/Caret/right-grey.png';
import caretDownBlue from './assets/Caret/down-blue.png';

export const StyledCaret = styled.span`
    display: inline-block;
    margin: ${({ margin }: Object) => margin || 'auto'};

    ${({ black, grey, blue, down, up, left, right }: Object) => {
        let dynamicStyles = '';

        if (up || down) {
            dynamicStyles += `
                width: 10px;
                height: 8px;
            `;
        }

        if (left || right) {
            dynamicStyles += `
                width: 8px;
                height: 10px;
            `;
        }

        if (up && black) {
            dynamicStyles += `background-image: url(${caretUpBlack});`;
        }
        if (down && black) {
            dynamicStyles += `background-image: url(${caretDownBlack});`;
        }
        if (left && grey) {
            dynamicStyles += `background-image: url(${caretLeftGrey});`;
        }
        if (right && grey) {
            dynamicStyles += `background-image: url(${caretRightGrey});`;
        }
        if (down && blue) {
            dynamicStyles += `background-image: url(${caretDownBlue});`;
        }
        return `${dynamicStyles}`;
    }}

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

export const StyledTriangle = styled.span`
    display: inline-block;
    box-sizing: border-box;

    width: ${({ width }: Object) => (width ? `${width}px` : '10px')};
    height: ${({ height }: Object) => (height ? `${height}px` : '6px')};

    margin: ${({ margin }: Object) => margin || 'auto'};

    border-left: ${({ width }: Object) => (width ? `${width / 2}px` : '5px')} solid transparent;
    border-right: ${({ width }: Object) => (width ? `${width / 2}px` : '5px')} solid transparent;
    border-bottom: ${({ height }: Object) => (height ? `${height}px` : '6px')} solid
        ${({ color }: Object) => color || 'black'};

    transform: rotate(${({ degrees }: Object) => `${degrees || 0}deg`});

    vertical-align: middle;
`;

export const StyledSVG = styled.svg``;

export const AvatarWrapper = styled.div`
    cursor: ${({ cursorPointer, onClick }: Object) =>
        cursorPointer || onClick ? 'pointer' : 'default'};
`;

export const Avatar = styled.div`
    width: ${({ size }: Object) => size};
    height: ${({ size }: Object) => size};

    border: 1px solid ${legacyTheme.defaultBorder};
    border-radius: 50%;

    background-image: url(${({ backgroundSrc }: Object) => backgroundSrc});
    background-size: cover;
    background-position: center;
`;
