/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { streamCircuitColors } from 'styles/colors';

export const ArrowPath = styled.path`
    stroke-width: 1px;
    fill: none;
    stroke: ${({ streamCircuit }: Object) => streamCircuitColors[streamCircuit].main};
    stroke-opacity: 1;
`;
