// @flow strict

import React from 'react';

// Style
import { StreamValuesWrapper } from 'components/MimicDiagram/Stream/styles';

// Constants
import {
    STAGE_TYPES,
    VALUE_STATUS,
    DIAGRAM_DISPLAY_MODES,
    STREAM_VALUE_ORDER,
    STREAM_VALUE_TYPES,
    WASHER_TYPES,
} from 'utils/constants';
import { SETUP_GRID, COMPUTE_GRID } from 'components/MimicDiagram/constants';

// Components
import StreamValueInputBox from 'components/MimicDiagram/Stream/StreamValueInputBox';
import Washer from 'components/MimicDiagram/Stream/Washer';

// Types
import type { UnitsConstant } from 'types';
import type { StageTypeConstant, StreamEntity } from 'services/Circuit/types';
import type {
    LooseStreamValue,
    StreamValuesConstant,
    DatasetModesConstant,
} from 'services/Dataset/types';
import type { DiagramDisplayModesConstant, GridType } from 'components/MimicDiagram/types';
import type { SetStreamValueFunction } from 'containers/CircuitComputationContainer/MimicContainer';

type Props = {
    streamData: StreamEntity,
    circuitUnits: UnitsConstant,
    datasetMode: ?DatasetModesConstant,

    values: ?Array<LooseStreamValue>,

    fromStageColumn: number,
    fromStageRow: number,
    fromStageType: StageTypeConstant,
    fromStageLocation: number,
    toStageColumn: number,
    toStageRow: number,
    toStageLocation: number,
    toStageType: StageTypeConstant, // should this be kept to be consistent with other streams?

    displayMode: DiagramDisplayModesConstant,
    hasExtractBypass: boolean, // for proper calculation of washer / stream values positioning.
    hasOrganicBlend: boolean, // for proper calculation of washer/stream values positioning in the right side.
    hasStripBypass: boolean, // for proper calculation of washer / stream values positioning.

    setStreamValue: SetStreamValueFunction,
};

/**
 * Vertical Stream Values are those that belong to organic circuit and placed between two stages of different type
 * on the vertical organic stream path.
 */
export default class ConnectorStream extends React.PureComponent<Props> {
    getGrid = (): GridType =>
        this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP ? SETUP_GRID : COMPUTE_GRID;

    isLeftConnector = () =>
        this.props.fromStageType === STAGE_TYPES.EXTRACT ||
        this.props.fromStageType === STAGE_TYPES.ORGANIC_TANK ||
        // ETS washer:
        (this.props.fromStageType === STAGE_TYPES.WASHER && this.props.fromStageLocation === 1);

    /**
     * Calculates the starting column of the
     */
    getStartingColumn = () => {
        const grid = this.getGrid();
        if (this.isLeftConnector()) {
            const furthestStageColumn = Math.min(
                this.props.toStageColumn,
                this.props.fromStageColumn
            );
            if (furthestStageColumn === grid.GRID.COLUMN_INDEX) {
                return furthestStageColumn;
            }
            return furthestStageColumn - grid.STREAM_VALUES.COLUMN_SPAN;
        } else {
            const furthestStageColumn = Math.max(
                this.props.toStageColumn,
                this.props.fromStageColumn
            );
            if (
                this.props.fromStageType === STAGE_TYPES.WASHER ||
                this.props.toStageType === STAGE_TYPES.WASHER
            ) {
                // must be an ste washer
                return furthestStageColumn; // same column as the washer
            }
            return furthestStageColumn + grid.STAGE.COLUMN_SPAN;
        }
    };

    /**
     * Get the row position of the stream value.
     */
    getStreamValueRow = (streamValueType: StreamValuesConstant, streamValueIndex: number) => {
        const grid = this.getGrid();
        switch (streamValueType) {
            case STREAM_VALUE_TYPES.FLOWRATE_PERCENT: {
                // this is a bypass stream value. It must be positioned next to the toStage location.
                // This is the remainder flowrate percent.
                const toRow = this.props.toStageRow;
                if (this.props.toStageType === STAGE_TYPES.EXTRACT) {
                    return toRow + grid.STAGE.ROW_SPAN - 1;
                }

                return toRow - 1;
            }
            case STREAM_VALUE_TYPES.PRE_COMPOSITION: {
                // this is a bypass stream value. It must be positioned next to the fromStage location.
                const fromRow = this.props.fromStageRow;
                if (this.props.fromStageType === STAGE_TYPES.EXTRACT) {
                    return fromRow + grid.STAGE.ROW_SPAN;
                } else if (this.props.fromStageType === STAGE_TYPES.STRIP) {
                    return fromRow - 1;
                } else {
                    throw new Error(
                        'Invalid positioning of pre-composition on a connector stream. Must come from either extract or strip.'
                    );
                }
            }
            default: {
                let startRow = 0;
                let bypassOffset = 0;
                switch (this.props.fromStageType) {
                    case STAGE_TYPES.EXTRACT:
                        startRow = this.props.fromStageRow + grid.STAGE.ROW_SPAN;
                        if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                            if (this.props.toStageType === STAGE_TYPES.WASHER) {
                                // This should be treated as a pre-composition.
                                return startRow + 2;
                            }
                        }
                        break;
                    case STAGE_TYPES.ORGANIC_TANK:
                        startRow = this.props.fromStageRow + grid.TANK.ROW_SPAN + streamValueIndex;
                        return startRow;
                    case STAGE_TYPES.STRIP:
                        startRow = this.props.fromStageRow - 1;
                        if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                            if (this.props.toStageType === STAGE_TYPES.WASHER) {
                                // This should be treated as a pre-composition.
                                return startRow;
                            }
                        }
                        break;
                    case STAGE_TYPES.WASHER:
                        if (this.props.toStageType === STAGE_TYPES.STRIP) {
                            startRow = this.props.fromStageRow;
                            startRow += grid.WASHER.ROW_SPAN + streamValueIndex;
                            return startRow;
                        } else if (this.props.toStageType === STAGE_TYPES.EXTRACT) {
                            startRow = this.props.fromStageRow;
                            startRow += grid.WASHER.ROW_SPAN + streamValueIndex;
                            return startRow;
                        } else if (this.props.toStageType === STAGE_TYPES.ORGANIC_TANK) {
                            startRow = this.props.toStageRow;
                            startRow += grid.TANK.ROW_SPAN + streamValueIndex;
                            return startRow;
                        }
                        throw new Error('Unknown washer to stage type.');
                    default:
                        throw new Error(`Unknown stage type ${this.props.fromStageType}`);
                }
                if (
                    this.props.hasExtractBypass ||
                    (this.props.hasOrganicBlend && !this.isLeftConnector())
                ) {
                    bypassOffset = Math.floor(grid.BYPASS_STREAM.BLEED_VERTICAL_OFFSET);
                } else if (
                    !this.props.hasExtractBypass &&
                    this.props.hasOrganicBlend &&
                    this.isLeftConnector()
                ) {
                    bypassOffset = Math.floor(grid.BYPASS_STREAM.FEED_VERTICAL_OFFSET);
                } else if (this.props.hasStripBypass && !this.isLeftConnector()) {
                    bypassOffset = 1;
                }
                const areStreamValuesUpwards = this.props.fromStageType === STAGE_TYPES.STRIP;
                return (
                    startRow + (bypassOffset + streamValueIndex) * (areStreamValuesUpwards ? -1 : 1)
                );
            }
        }
    };

    /**
     * Get the rowspan for the stream value. Some stream values must be positioned in the center of 2 rows.
     * While others are just 1 row span.
     */
    getRowSpan = (streamValueType: StreamValuesConstant) => {
        const grid = this.getGrid();
        if (streamValueType === STREAM_VALUE_TYPES.FLOWRATE_PERCENT) {
            return grid.CONTINUE_STREAM_VALUES.ROW_SPAN;
        }
        return grid.STREAM_VALUES.ROW_SPAN;
    };

    /**
     * Get the value of the stream value.
     */
    getStreamValueOrder = (streamValue: LooseStreamValue): ?number => {
        if (streamValue.valueType === STREAM_VALUE_TYPES.PRE_COMPOSITION) {
            return STREAM_VALUE_ORDER[streamValue.valueType][
                `${this.props.fromStageType}-TO-${this.props.toStageType}`
            ];
        }

        if (
            STREAM_VALUE_ORDER[streamValue.valueType] === null ||
            STREAM_VALUE_ORDER[streamValue.valueType] === undefined
        ) {
            return null;
        }

        return STREAM_VALUE_ORDER[streamValue.valueType];
    };

    renderStreamValues = () => {
        if (!this.props.values) {
            throw new Error('No stream values tied to stream.');
        }
        // Sort stream values to keep the right order
        const sortedValues = this.props.values
            .filter((steamValue: LooseStreamValue) => this.getStreamValueOrder(steamValue) !== null)
            .sort(
                (currentStreamValue: LooseStreamValue, nextStreamValue: LooseStreamValue) =>
                    this.getStreamValueOrder(nextStreamValue) -
                    this.getStreamValueOrder(currentStreamValue)
            );
        return sortedValues.map((sortedStreamValue: LooseStreamValue, index: number) => (
            <StreamValuesWrapper
                key={`values-wrapper-${this.props.streamData.id}-${sortedStreamValue.valueType}`}
                startingColumn={this.getStartingColumn()}
                startingRow={this.getStreamValueRow(sortedStreamValue.valueType, index)}
                columnSpan={this.getGrid().CONTINUE_STREAM_VALUES.COLUMN_SPAN}
                rowSpan={this.getRowSpan(sortedStreamValue.valueType)}
            >
                <StreamValueInputBox
                    key={`value-input-${this.props.streamData.id}-${sortedStreamValue.valueType}`}
                    streamValue={sortedStreamValue}
                    circuitUnits={this.props.circuitUnits}
                    streamCircuit={this.props.streamData.streamCircuit}
                    setStreamValue={this.props.setStreamValue}
                    isInput={sortedStreamValue.status === VALUE_STATUS.COMPUTE_USING}
                    displayMode={this.props.displayMode}
                    datasetMode={this.props.datasetMode}
                />
            </StreamValuesWrapper>
        ));
    };

    render() {
        if (this.props.displayMode === DIAGRAM_DISPLAY_MODES.SETUP) {
            return null;
        }
        return (
            <React.Fragment key={`connector-frag-${this.props.streamData.id}`}>
                {this.renderStreamValues()}
            </React.Fragment>
        );
    }
}
