// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 0px 24px 0px 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px;

    border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    padding: 16px 24px 0px;
    margin-bottom: -4px;

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;

    & + & {
        margin-top: 7px;
    }
`;

export const Label = styled.label`
    font-size: 12px;
    line-height: 17px;
    color: ${LegacyTheme.black};
    flex-basis: 80px;
`;

export const RadioStyles = {
    labelFontSize: '14px',
    labelColor: LegacyTheme.defaultColor,
    labelMinWidth: '50px',
};

export const RadioFieldStyles = {
    alignItems: 'center',
    marginTop: '10px',
};
