// @flow

import React from 'react';

type LoadingDotsType = {
    beginStepSize?: ?number, // When will dots begin their animation, each dot begins at beginStepSize * dot index
    duration?: ?number,
    height?: ?number,
    width?: ?number,
};

export function LoadingDots(props: LoadingDotsType) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 60 20"
            style={{ fill: 'currentColor' }}
        >
            <circle cx="25%" cy="10" r="4">
                <animate
                    attributeName="opacity"
                    dur={`${props.duration || 1}s`}
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin={props.beginStepSize}
                />
            </circle>
            <circle cx="50%" cy="10" r="4">
                <animate
                    attributeName="opacity"
                    dur={`${props.duration || 1}s`}
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin={(props.beginStepSize || 0.1) * 2}
                />
            </circle>
            <circle cx="75%" cy="10" r="4">
                <animate
                    attributeName="opacity"
                    dur={`${props.duration || 1}s`}
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin={(props.beginStepSize || 0.1) * 3}
                />
            </circle>
        </svg>
    );
}

LoadingDots.defaultProps = {
    beginStepSize: 0.1,
    duration: 1,
    height: 20,
    width: 40,
};
