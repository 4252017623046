// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import { PageTitleObservable } from 'components/_FrontendObservables';

import { NavigationTitleWrapper, NavigationTitle } from './styles';

import type { RouteType, IntlType } from 'types';

type Props = {
    intl: IntlType,
    route: RouteType,
};

type State = {
    pageTitle: string | null,
};

class PageTitle extends React.PureComponent<Props, State> {
    state = {
        pageTitle: null,
    };

    componentDidMount() {
        PageTitleObservable.observable.subscribe(this.updatePageTitle);
    }

    componentWillUnmount() {
        PageTitleObservable.observable.unsubscribe(this.updatePageTitle);
    }

    updatePageTitle = (topic: string, pageTitle: string) => {
        console.debug(`[PageTitle] Received new page title ${pageTitle} on ${topic}`);
        this.setState({
            pageTitle,
        });
    };

    getTitle = () => {
        if (this.state.pageTitle) {
            return this.state.pageTitle;
        }
        const routeName = this.props.route.name;
        if (!routeName) {
            return null;
        }
        return this.props.intl.formatMessage({
            id: `components.Header.routeTitles.${routeName}`,
            defaultMessage: ' ',
        });
    };

    render() {
        const pageTitle = this.getTitle();
        if (!pageTitle) {
            return null;
        }
        return (
            <NavigationTitleWrapper>
                <NavigationTitle>{pageTitle}</NavigationTitle>
            </NavigationTitleWrapper>
        );
    }
}

export default injectIntl(PageTitle);
