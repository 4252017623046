// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Authentication Helpers
import { isSolvayUser } from 'utils/authentication';

// Constants
import { STYLE_VALUES } from 'utils/constants';

// Types
import type { IntlType, ImmutableList, ReactSelectObject, ReduxDispatch } from 'types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';
import type { ImmutableUser } from 'services/Authentication/types';

// Services
import { selectAllReagents, selectReagentsAreFetching } from 'services/Reagent/selectors';
import { selectAllOximes, selectOximesAreFetching } from 'services/Oxime/selectors';
import { fetchAllReagents } from 'services/Reagent/thunks';
import { selectUser } from 'services/Authentication/selectors';

// Components
import { InputSelect } from 'components/_ReactUI_V1';

// Styles
import { Label, SelectField, ErrorLabel } from './styles';

type Props = {
    selectedReagent: ?ImmutableReagent,
    selectedOxime: ?ImmutableOxime,
    readOnly?: boolean,
    reagentsOnly?: boolean, // set to true to disable the selection of oximes.
    isLoading?: boolean, // forces loading state
    isDisabled?: boolean,

    onSelectReagent?: (selectedReagent: ImmutableReagent) => void,
    onSelectOxime?: (selectedOxime: ImmutableOxime) => void,

    // Injected:
    intl: IntlType,
    user: ImmutableUser,
    loadingReagents: boolean,
    reagents: ImmutableList<ImmutableReagent>,
    loadingOximes: boolean,
    oximes: ImmutableList<ImmutableOxime>,
    fetchAllReagents: () => void,
};

type State = {
    showOximes: boolean,
};

/**
 * Reagent select used by the isotherm sidebar as well as the circuit setup
 */
class ReagentSelectBody extends React.PureComponent<Props, State> {
    static defaultProps = {
        readOnly: false,
        reagentsOnly: false,
        isLoading: false,
        isDisabled: false,
    };

    /**
     * Converts an immutable reagent into a react select option
     */
    static reagentToReactSelectObject = (reagent: ImmutableReagent): ReactSelectObject =>
        reagent && {
            value: reagent.get('id'),
            label: reagent.get('name'),
        };

    /**
     * Converts an immutable oxime into a react select option
     */
    static oximeToReactSelectObject = (oxime: ImmutableOxime): ReactSelectObject =>
        oxime && {
            value: oxime.get('id'),
            label: oxime.get('name'),
        };

    /**
     * Toggle the show oxime depending on the next props.
     * @param {Props} nextProps
     * @param {State} prevState
     */
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (!nextProps.selectedReagent && nextProps.selectedOxime) {
            return {
                showOximes: true,
            };
        }
        if (prevState.showOximes && !nextProps.selectedOxime && nextProps.selectedReagent) {
            return {
                showOximes: false,
            };
        }
        return prevState;
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            showOximes: Boolean(props.selectedOxime),
        };
    }

    componentDidMount() {
        if (this.props.reagents.isEmpty() && this.props.oximes.isEmpty()) {
            if (!this.props.loadingReagents || !this.props.loadingOximes) {
                // Avoid fetching while it is fetching.
                this.props.fetchAllReagents();
            }
        }
    }

    /**
     * The custom oxime option allows users to select a custom reagent
     */
    getOximeSelectObject = (): ReactSelectObject => ({
        value: -1,
        label: this.props.intl.formatMessage({
            id: 'components.CircuitSetupSidebar.reagentSection.reagentListCustomType.Label',
        }),
    });

    /**
     * Gets the reagent select options.
     * Also unshifts (adds to front) the custom oxime option if user isSolvayUser
     */
    getAllReagentsOptions = (): Array<ReactSelectObject> => {
        const reagents = this.props.reagents
            .map(ReagentSelectBody.reagentToReactSelectObject)
            .toArray();
        if (isSolvayUser(this.props.user) && !this.props.reagentsOnly) {
            reagents.unshift(this.getOximeSelectObject());
        }
        return reagents;
    };

    /**
     * Gets the select oxime options
     */
    getOximesOptions = (): Array<ReactSelectObject> =>
        this.props.oximes.map(ReagentSelectBody.oximeToReactSelectObject).toArray();

    /**
     * Finds an oxime by the ID
     */
    findOximeById = (id: number): ImmutableOxime =>
        this.props.oximes.find((oxime: ImmutableOxime) => oxime.get('id') === id);

    /**
     * Finds a reagent by the ID
     */
    findReagentById = (id: number): ImmutableReagent =>
        this.props.reagents.find((reagent: ImmutableReagent) => reagent.get('id') === id);

    /**
     * Handles the selection of the reagent
     *
     * If the selected option is the oxime select option, unselect the reagent
     * Otherwise find the reagent and select it.
     */
    handleReagentSelected = (selectedOption: ReactSelectObject) => {
        if (selectedOption.value === this.getOximeSelectObject().value) {
            this.setState({ showOximes: true }, this.props.onSelectReagent(null));
        } else {
            const reagent = this.findReagentById(selectedOption.value);
            this.setState({ showOximes: false }, () => this.props.onSelectReagent(reagent));
        }
    };

    /**
     * Handles the selection of the oxime
     */
    handleOximeSelected = (selectedOption: ReactSelectObject) => {
        const oxime = this.findOximeById(selectedOption.value);
        this.setState({ showOximes: false }, () => this.props.onSelectOxime(oxime));
    };

    /**
     * Renders the oxime select field
     * @returns ReactComponent | null
     */
    renderOximeSelector = (isLoading: boolean) => {
        if (!this.state.showOximes || this.props.reagentsOnly) {
            return null;
        }
        return (
            <SelectField>
                <Label>
                    {this.props.intl.formatMessage({
                        id: 'components.CircuitSetupSidebar.reagentSection.types.Label',
                    })}
                </Label>
                <InputSelect
                    selectedOption={ReagentSelectBody.oximeToReactSelectObject(
                        this.props.selectedOxime
                    )}
                    options={this.getOximesOptions()}
                    onSelect={this.handleOximeSelected}
                    isLoading={isLoading}
                    isDisabled={isLoading || this.props.readOnly}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.CircuitSetupSidebar.reagentSection.types.Placeholder',
                    })}
                    maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                    controlShouldRenderValue
                />
            </SelectField>
        );
    };

    render() {
        const hasReagents = !this.props.oximes.isEmpty() || !this.props.reagents.isEmpty();
        const isLoading =
            this.props.loadingReagents || this.props.loadingOximes || this.props.isLoading;
        if (!hasReagents && !isLoading) {
            return (
                <ErrorLabel>
                    {this.props.intl.formatMessage({
                        id: 'components.CircuitSetupSidebar.errors.noReagents',
                    })}
                </ErrorLabel>
            );
        }

        let selectedReagent;
        if (this.props.selectedReagent) {
            selectedReagent = ReagentSelectBody.reagentToReactSelectObject(
                this.props.selectedReagent
            );
        } else if (this.state.showOximes && !this.props.reagentsOnly) {
            selectedReagent = this.getOximeSelectObject();
        } else {
            selectedReagent = null;
        }

        return (
            <div>
                <SelectField>
                    <Label>
                        {this.props.intl.formatMessage({
                            id: 'components.CircuitSetupSidebar.reagentSection.products.Label',
                        })}
                    </Label>
                    <InputSelect
                        selectedOption={selectedReagent}
                        options={this.getAllReagentsOptions()}
                        onSelect={this.handleReagentSelected}
                        isDisabled={isLoading || this.props.readOnly}
                        isLoading={isLoading}
                        placeholder={this.props.intl.formatMessage({
                            id:
                                'components.CircuitSetupSidebar.reagentSection.products.Placeholder',
                        })}
                        maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                        controlShouldRenderValue
                    />
                </SelectField>
                {this.renderOximeSelector(isLoading)}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    reagents: selectAllReagents(),
    oximes: selectAllOximes(),
    loadingReagents: selectReagentsAreFetching(),
    loadingOximes: selectOximesAreFetching(),
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllReagents,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReagentSelectBody));
