// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { PrimaryButton} from 'components/_ReactUI_V1';

import { isSolvayUser } from 'utils/authentication';
import { JIRA_ISSUE_COLLECTOR_URL, JIRA_BUTTON_ID, JIRA_SCRIPT_ID } from 'utils/constants';



class JiraRequestButton extends React.PureComponent<Props> {

    submitRequest() {
        const jiraButton = document.getElementById(JIRA_BUTTON_ID);
        if (jiraButton)
            jiraButton.click();
    }

    render() {
        if (!isSolvayUser(this.props.user)) return null;

        if (!document.getElementById(JIRA_SCRIPT_ID)) {

            const script = document.createElement('script');

            script.src = JIRA_ISSUE_COLLECTOR_URL;
            script.id = JIRA_SCRIPT_ID;
            script.async = true;

            document.body.appendChild(script);

            // SetInterval checks if Jira-script-button was added
            const jiraButtonInterval = setInterval(() => {
                const jiraButton = document.getElementById(JIRA_BUTTON_ID);

                if (jiraButton) {
                    jiraButton.style.display = 'none'; // Hide the Jira-script-button
                    clearInterval(jiraButtonInterval);
                }
            }, 10);

            // Clear the interval after 10 seconds incase the Jira-script-button was not loaded
            setTimeout(() => clearInterval(jiraButtonInterval), 10000);
        }

        return <PrimaryButton
            onClick={this.submitRequest}
            text={this.props.intl.formatMessage({
                id: 'components.Header.jiraButtonText',
            })}
            style={{ marginRight: '8px' }}
        />
    }

}

export default injectIntl(JiraRequestButton);
