// @flow strict

import { fromJS } from 'immutable';

// Actions
import {
    CREATE_FEEDBACK,
    DESTROY_FEEDBACK,
    DESTROY_ALL_FEEDBACKS,
    CREATE_UNTRANSLATED_FEEDBACK,
} from './actions';

// Types
import type { GenericActionType, ImmutableList } from 'types';
import type { ImmutableFeedbackState, Feedback } from './types';

const initialState: ImmutableFeedbackState = fromJS({
    feedbacks: [],
});

const makeFeedbackId = () => Math.floor(Math.random() * 10000);

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */
function feedbackServiceReducer(
    state: ImmutableFeedbackState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        /**
         * @deprecated translate feedbacks before providing them to the toaster
         */
        case CREATE_UNTRANSLATED_FEEDBACK:
            return state.updateIn(['feedbacks'], (feedbacks: ImmutableList<Feedback>) =>
                feedbacks.push({
                    ...action.payload,
                    feedbackId: makeFeedbackId(),
                    __MUST_BE_TRANSLATED: true,
                })
            );
        case CREATE_FEEDBACK:
            return state.updateIn(['feedbacks'], (feedbacks: ImmutableList<Feedback>) =>
                feedbacks.push({
                    ...action.payload,
                    feedbackId: makeFeedbackId(),
                })
            );
        case DESTROY_FEEDBACK:
            return state.updateIn(['feedbacks'], (feedbacks: ImmutableList<Feedback>) =>
                feedbacks.filter(
                    (feedback: Feedback) => feedback.feedbackId !== action.payload.feedbackId
                )
            );
        case DESTROY_ALL_FEEDBACKS:
            return state.updateIn(['feedbacks'], () => fromJS([]));
        default:
            return state;
    }
}

export default feedbackServiceReducer;
