// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import { PrimaryButton, InputMultiple, CheckBox, SecondaryButton } from 'components/_ReactUI_V1';

import RecommendationCard from 'components/RecommendationCard';
import ErrorMessage from 'components/ErrorMessage';

// Helpers
import {
    getIncludedRecommendations,
    getSortedRecommendations,
    getRecommendationSetMessageCount,
    getRecommendationSetMessages,
    getSubmittedBy,
    getRecommendationItemFromRecommendationType,
} from 'utils/recommendationHelpers';
import { getFormattedDateFromString } from 'utils/dateHelpers';

// Constants
import { DATASET_STATUSES } from 'utils/constants';
import {
    RECOMMENDATION_TYPES,
    RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION,
} from 'utils/recommendationConstants';

// Styles
import {
    SidebarBody,
    SidebarFooter,
    DisplayMessages,
    RecommendationsBody,
    Instructions,
    DecisionTreeButtonWrapper,
    SidebarFooterButtonsWrapper,
    MessageTitle
} from './styles';

// Types
import type {
    IntlType,
    ImmutableList,
    ImmutableMap,
    LanguageCodeConstant,
    InputEvent,
} from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { DatasetStatusType } from 'services/Dataset/types';
import type { ImmutableKPICard, ImmutableKPISetting } from 'services/KPISetting/types';
import type {
    ImmutableRecommendationSet,
    ImmutableRecommendationSetMessage,
    ImmutableRecommendation,
    ImmutableRecommendationItem,
    ImmutableRecommendationFeedback,
    RecommendationFeedbackTypeConstants,
} from 'services/Recommendation/types';

export type ImmutableFormattedRecommendationSetMessage = ImmutableMap<
    string,
    {
        id: number,
        value: string,
    }
>;

type Props = {
    intl: IntlType,
    isUserPM: boolean,
    userLocale: LanguageCodeConstant,

    kpis: ?ImmutableList<ImmutableKPICard>,
    kpiSettings: ImmutableList<ImmutableKPISetting>,
    circuit: ?ImmutableCircuit,
    datasetStatus: DatasetStatusType,
    timezone: string,

    recommendationSet: ?ImmutableRecommendationSet,
    onSubmitRecommendationSet: (recommendationSet: ImmutableRecommendationSet) => void,

    loadingSubmittingFeedback: boolean,
    onSubmitFeedback: (feedbacks: ImmutableList<ImmutableRecommendationFeedback>) => void,

    loadingRunDecisionTree: boolean,
    onRunDecisionTree: ?() => void,
};

type State = {
    recommendationSet: ?ImmutableRecommendationSet,
    recommendationFeedbacks: ImmutableList<ImmutableRecommendationFeedback>, // feedbacks being modified
    recommendationsWithModifiedItems: Array<number>, // array of recommendation IDs with modified items
    isEditMode: boolean,
    initialRecommendationSet: ?ImmutableRecommendationSet,
};

/**
 * Recommendation Sidebar component
 */
class RecommendationSidebar extends React.PureComponent<Props, State> {
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (!prevState.recommendationSet && nextProps.recommendationSet) {
            // if we didn't have a recommendation and now we do.
            return {
                recommendationSet: nextProps.recommendationSet,
                recommendationFeedbacks: fromJS([]),
            };
        }
        if (
            prevState.recommendationSet &&
            nextProps.recommendationSet &&
            prevState.recommendationSet.get('id') !== nextProps.recommendationSet.get('id')
        ) {
            // if we changed recommendation sets in the props
            return {
                recommendationSet: nextProps.recommendationSet,
                recommendationFeedbacks: fromJS([]),
            };
        }
        if (
            prevState.recommendationSet &&
            nextProps.recommendationSet &&
            prevState.recommendationSet.get('sentAt') !== nextProps.recommendationSet.get('sentAt')
        ) {
            // if a SAM submitted the recommendation set
            return {
                recommendationSet: nextProps.recommendationSet,
                recommendationFeedbacks: fromJS([]),
                isEditMode: false,
            };
        }
        if (
            !RecommendationSidebar.hasSubmittedFeedbacks(prevState.recommendationSet) &&
            RecommendationSidebar.hasSubmittedFeedbacks(nextProps.recommendationSet)
        ) {
            // if a PM submitted feedback.
            return {
                recommendationSet: nextProps.recommendationSet,
                recommendationFeedbacks: fromJS([]),
            };
        }
        // the recommendation set in the props did not change, therefore do not change our state.
        return null;
    }

    /**
     * If all feedbacks were submitted
     */
    static hasSubmittedFeedbacks = (recommendationSet: ImmutableRecommendationSet) =>
        recommendationSet &&
        recommendationSet
            .get('recommendations')
            .find((recommendation: ImmutableRecommendation) =>
                Boolean(recommendation.get('recommendationFeedback'))
            );

    state = {
        recommendationSet: null,
        recommendationFeedbacks: fromJS([]),
        recommendationsWithModifiedItems: [],
        isEditMode: false,
        initialRecommendationSet: null,
    };

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.RecommendationSidebar.${id}`,
            },
            data
        );

    wasRecommendationSetSubmitted = () =>
        Boolean(
            this.state.recommendationSet && this.state.recommendationSet.get('sentAt') !== null
        );

    isSubmitDisabled = () => {
        if (!this.canSubmit()) {
            return true;
        }
        if (this.props.isUserPM) {
            return this.state.recommendationFeedbacks.size < 0;
        }
        // user is a sam/admin.
        // the sam must submit at least 1 recommendation or at least 1 recommendation set message.
        const includedRecommendations =
            this.state.recommendationSet &&
            getIncludedRecommendations(this.state.recommendationSet);

        return (
            !includedRecommendations ||
            !(
                includedRecommendations.size > 0 ||
                getRecommendationSetMessageCount(this.state.recommendationSet) > 0
            ) ||
            this.state.recommendationsWithModifiedItems.length > 0
        );
    };

    canSubmit = () => {
        // If there is no recommendation set, there is nothing to submit.
        if (!this.state.recommendationSet) {
            return false;
        }
        if (this.props.isUserPM) {
            // if the user is a PM, they cannot submit feedback if there are no recommendations,
            // or if a PM has already submitted feedbacks for this recommendation set.
            return (
                this.getRecommendationCount() > 0 &&
                !RecommendationSidebar.hasSubmittedFeedbacks(this.props.recommendationSet)
            );
        }

        if (this.props.recommendationSet && this.props.recommendationSet.get('sentAt')) {
            return this.state.isEditMode;
        }

        // if the user is not a PM, the user cannot submit if they've already submitted a recommendation set.
        return (
            !this.wasRecommendationSetSubmitted() ||
            this.state.recommendationsWithModifiedItems.length > 0
        );
    };

    canSAMModify = () => !this.props.isUserPM && this.canSubmit();

    canPMModify = () => this.props.isUserPM && this.canSubmit();

    canEdit = () =>
        !this.props.isUserPM && // Is SAM
        this.state.recommendationSet &&
        this.state.recommendationSet.toJS().sentAt;

    notificationAlreadySent = () =>
        this.state.initialRecommendationSet && this.state.initialRecommendationSet.toJS().notifyPm;

    getSubmitReadyRecommendationItem = (
        item: ImmutableRecommendationItem
    ): ?ImmutableRecommendationItem => {
        if (item.get('shouldRemoveId')) {
            item = item.delete('id').delete('shouldRemoveId');
        }

        // convert all recommendation types into
        if (item.get('recommendationType')) {
            const comment = getRecommendationItemFromRecommendationType(
                item,
                this.props.kpiSettings,
                this.props.circuit,
                this.props.intl
            );
            item = item
                .set('comment', comment)
                .set('recommendationType', null)
                .set('toKpiId', null)
                .set('fromKpiId', null)
                .set('setValueTo', null)
                .set('relatedValue', null);
        }

        if (!item.get('comment')) {
            return null; // remove null items.
        }

        return item;
    };

    /**
     * Prepare the recommendation set for submission.
     * This will remove any fields that should be removed, and convert all recommendation types into comments.
     */
    getSubmitReadyRecommendation = () =>
        this.state.recommendationSet &&
        this.state.recommendationSet
            .updateIn(
                ['recommendationSetMessages'],
                (messages: ImmutableList<ImmutableRecommendationSetMessage>) =>
                    messages.map((message: ImmutableRecommendationSetMessage) => {
                        if (message.get('shouldRemoveId')) {
                            return message.delete('id').delete('shouldRemoveId');
                        }
                        return message;
                    })
            )
            .updateIn(
                ['recommendations'],
                (recommendations: ImmutableList<ImmutableRecommendation>) =>
                    recommendations.map((recommendation: ImmutableRecommendation) => {
                        let returnRec = recommendation;
                        if (recommendation.get('shouldRemoveId')) {
                            returnRec = returnRec.delete('id').delete('shouldRemoveId');
                        }

                        return returnRec.updateIn(
                            ['recommendationItems'],
                            (items: ImmutableList<ImmutableRecommendationItem>) =>
                                items.map(this.getSubmitReadyRecommendationItem).filter(Boolean)
                        );
                    })
            );

    /**
     * Get the PM modifying feedback for the recommendation provided.
     */
    getModifyingFeedback = (recommendation: ImmutableRecommendation) =>
        this.state.recommendationFeedbacks.find(
            (feedback: ImmutableRecommendationFeedback) =>
                feedback.get('recommendationId') === recommendation.get('id')
        );

    getDate = () => {
        if (
            !this.state.recommendationSet ||
            (this.state.recommendationSet && !this.state.recommendationSet.get('createdAt'))
        ) {
            return null;
        }

        return getFormattedDateFromString(
            this.state.recommendationSet.get('createdAt'),
            this.props.userLocale,
            {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                timeZone: this.props.timezone,
                timeZoneName: 'short',
            }
        );
    };

    getRecommendationCount = () =>
        this.state.recommendationSet ? this.state.recommendationSet.get('recommendations').size : 0;

    getInstructionMessages = () => {
        const messages = [];

        const isPM = this.props.isUserPM;

        if (!this.state.recommendationSet) {
            // if the DT has not been ran yet
            if (isPM) {
                messages.push(this.getTranslation('noRecommendationsToReview'));
                return messages;
            } else {
                return messages; // TODO?
                // throw new Error('A SAM should always have a default recommendation set.');
            }
        }

        const date = this.getDate();
        if (date) {
            messages.push(
                this.getTranslation('generatedAt', {
                    date,
                })
            );
        }

        const wasSetSubmitted = this.wasRecommendationSetSubmitted();
        const reviewCount = this.getRecommendationCount();

        if (wasSetSubmitted) {
            const wasFeedbackSubmitted = RecommendationSidebar.hasSubmittedFeedbacks(
                this.props.recommendationSet
            );
            // if the recommendation set was submitted, show who it was submitted by.
            messages.unshift(getSubmittedBy(this.props.recommendationSet, this.props.intl));
            if (isPM && reviewCount === 0) {
                messages.unshift(this.getTranslation('noRecommendationsToReview'));
                return messages;
            }
            if (isPM && !wasFeedbackSubmitted) {
                // there are x recommendations to review
                messages.unshift(
                    this.getTranslation('recommendationsToReview', {
                        reviewCount,
                    })
                );
            }
            return messages;
        }

        // set was not submitted yet.
        if (isPM) {
            messages.unshift(this.getTranslation('noRecommendationsToReview'));
            return messages;
        }
        if (!this.state.recommendationSet.has('id')) {
            // DT was not ran and this is a default recommendation set.
            messages.unshift(this.getTranslation('decisionTreeNotRunForLatestDataset'));
            return messages;
        }
        if (reviewCount === 0) {
            messages.unshift(this.getTranslation('SAM.noRecommendationsGenerated'));
            return messages;
        }
        messages.unshift(
            this.getTranslation('recommendationsToReview', {
                reviewCount,
            })
        );
        return messages;
    };

    /**
     * A recommendation card can elect to completely overwrite the recommendation content.
     * This occurs when a recommendation is calculated.
     * @param {*} newRecommendation
     */
    handleResetRecommendation = (newRecommendation: ImmutableRecommendation) =>
        this.setState((prevState: State) => {
            if (!prevState.recommendationSet) {
                return prevState;
            }
            const rIdx = prevState.recommendationSet
                .get('recommendations')
                .findIndex(
                    (r: ImmutableRecommendation) => r.get('id') === newRecommendation.get('id')
                );
            return {
                recommendationSet: prevState.recommendationSet.setIn(
                    ['recommendations', rIdx],
                    newRecommendation
                ),
                recommendationsWithModifiedItems: prevState.recommendationsWithModifiedItems.filter(
                    (id: number) => id !== newRecommendation.get('id')
                ),
            };
        });

    /**
     * Reorder the specified recommendation
     */
    handleReorderRecommendation = (originalRecommendation: ImmutableRecommendation) => (
        newOrder: number
    ) =>
        this.setState((prevState: State) => ({
            recommendationSet:
                prevState.recommendationSet &&
                prevState.recommendationSet.update(
                    'recommendations',
                    (recommendations: ImmutableList<ImmutableRecommendation>) =>
                        recommendations.map((recommendation: ImmutableRecommendation) => {
                            // if the new order is occupied by a recommendation already, change the order
                            // to the changed recommendation order
                            if (recommendation.get('order') === newOrder) {
                                return recommendation.set(
                                    'order',
                                    originalRecommendation.get('order')
                                );
                            }
                            // if the recommendation is the one we want
                            if (recommendation.get('id') === originalRecommendation.get('id')) {
                                return recommendation.set('order', newOrder);
                            }
                            // if it is neither, the previous order, or the changed recommendation, do not update.
                            return recommendation;
                        })
                ),
        }));

    /**
     * Change the text of a display message
     */
    handleChangeDisplayMessage = (id: number, value: string) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            let messages = recommendationSet.get('recommendationSetMessages');
            const itemIdx = messages.findIndex(
                (message: ImmutableRecommendationSetMessage) => message.get('id') === id
            );
            if (itemIdx === -1) {
                const newItem = fromJS({
                    id, // this is a random number.
                    shouldRemoveId: true,
                    comment: value,
                    messageType: null,
                    kpiId: null,
                    order: messages.size + 1, // the order is always the index of the field
                    recommendationSetId: recommendationSet.get('id'),
                });
                messages = messages.push(newItem);
            } else {
                messages = messages.updateIn(
                    [itemIdx],
                    (message: ImmutableRecommendationSetMessage) =>
                        message
                            .set('comment', value)
                            .set('messageType', null) // clear the old decision tree information
                            .set('kpiId', null)
                );
            }

            return {
                recommendationSet: recommendationSet.set('recommendationSetMessages', messages),
            };
        });

    /**
     * Remove a display message
     */
    handleRemoveDisplayMessage = (index: number) =>
        this.setState((prevState: State) => ({
            recommendationSet:
                prevState.recommendationSet &&
                prevState.recommendationSet
                    .deleteIn(['recommendationSetMessages', index])
                    .update(
                        'recommendationSetMessages',
                        (rsms: ImmutableList<ImmutableRecommendationSetMessage>) =>
                            rsms.map((rsm: ImmutableRecommendationSetMessage) => {
                                if (rsm.get('order') > index) {
                                    // anything above the field that we deleted, needs to have a lower order.
                                    return rsm.set('order', rsm.get('order') - 1);
                                }
                                return rsm;
                            })
                    ),
        }));

    /**
     * When the user changes the text of a recommendation rationale.
     */
    handleChangeRecommendationRationale = (recommendation: ImmutableRecommendation) => (
        value: string
    ) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex(
                    (r: ImmutableRecommendation) => r.get('id') === recommendation.get('id')
                );

            return {
                recommendationSet: recommendationSet.updateIn(
                    ['recommendations', rIdx],
                    (r: ImmutableRecommendation) => r.set('rationale', value)
                ),
            };
        });

    /**
     * On PM change feedback (either feedback type or comment)
     */
    handleChangeFeedback = (
        recommendationId: number,
        feedbackType: ?RecommendationFeedbackTypeConstants,
        comment: ?string
    ) =>
        this.setState((prevState: State) => {
            const feedbackIdx = prevState.recommendationFeedbacks.findIndex(
                (feedback: ImmutableRecommendationFeedback) =>
                    feedback.get('recommendationId') === recommendationId
            );
            if (feedbackIdx === -1) {
                const newFeedback = fromJS({
                    recommendationId,
                    comment,
                    feedbackType,
                });
                return {
                    recommendationFeedbacks: prevState.recommendationFeedbacks.push(newFeedback),
                };
            }
            return {
                recommendationFeedbacks: prevState.recommendationFeedbacks.updateIn(
                    [feedbackIdx],
                    (feedback: ImmutableRecommendationFeedback) =>
                        feedback.set('comment', comment).set('feedbackType', feedbackType)
                ),
            };
        });

    /**
     * When a SAM wants to delete a recommendation card.
     */
    handleDeleteRecommendation = (recommendation: ImmutableRecommendation) => () =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex(
                    (r: ImmutableRecommendation) => r.get('id') === recommendation.get('id')
                );

            return {
                recommendationSet: recommendationSet
                    .deleteIn(['recommendations', rIdx])
                    .updateIn(['recommendations'], (rs: ImmutableList<ImmutableRecommendation>) =>
                        rs.map((r: ImmutableRecommendation) => {
                            if (r.get('order') > recommendation.get('order')) {
                                // anything above the field that we deleted, needs to have a lower order.
                                return r.set('order', r.get('order') - 1);
                            }
                            return r;
                        })
                    ),
                recommendationsWithModifiedItems: prevState.recommendationsWithModifiedItems.filter(
                    (id: number) => id !== recommendation.get('id')
                ),
            };
        });

    /**
     * When the SAM presses submit.
     */
    handleSubmit = () =>
        this.props.isUserPM
            ? this.props.onSubmitFeedback(this.state.recommendationFeedbacks)
            : this.props.onSubmitRecommendationSet(this.getSubmitReadyRecommendation());

    /**
     * When the SAM checks recommendation to be submitted.
     */
    handleChangeIncludeInSubmission = (recommendation: ImmutableRecommendation) => (
        isIncluded: boolean
    ) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }

            // Find recommendation to update and check that the includeInSubmission status have changed
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex(
                    (r: ImmutableRecommendation) => r.get('id') === recommendation.get('id')
                );

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'includeInSubmission'],
                    isIncluded
                ),
            };
        });

    /**
     * Handles the changes to the Cu Transfer impact.
     */
    handleChangeCuTransferImpact = (recommendation: ImmutableRecommendation) => (
        cuTransferImpact: number
    ) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }

            // Find recommendation to update and check that the includeInSubmission status have changed
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex(
                    (r: ImmutableRecommendation) => r.get('id') === recommendation.get('id')
                );

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'cuTransferImpact'],
                    cuTransferImpact
                ),
            };
        });

    /**
     * Changes the value of the overall recovery impact of the recommendation.
     * Currently this is only changed when the recommendation impact is calculated. SAMs cannot modify this directly.
     * @param {*} recommendation
     */
    handleChangeOverallRecoveryImpact = (recommendation: ImmutableRecommendation) => (
        overallRecoveryImpact: number
    ) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }

            // Find recommendation to update and check that the includeInSubmission status have changed
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex(
                    (r: ImmutableRecommendation) => r.get('id') === recommendation.get('id')
                );

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'overallRecoveryImpact'],
                    overallRecoveryImpact
                ),
            };
        });

    /**
     * When the SAM wants to add a new recommendation
     */
    handleAddRecommendation = () =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const newRecommendationId = Math.random();
            return {
                recommendationSet: recommendationSet.updateIn(
                    ['recommendations'],
                    (recommendations: ImmutableList<ImmutableRecommendation>) =>
                        recommendations.push(
                            fromJS({
                                id: newRecommendationId,
                                shouldRemoveId: true,
                                recommendationSetId: recommendationSet.get('id'),
                                cuTransferImpact: null,
                                overallRecoveryImpact: null,
                                order: recommendations.size + 1,
                                rationale: null,
                                includeInSubmission: true,

                                recommendationItems: [
                                    {
                                        id: Math.random(),
                                        shouldRemoveId: true,
                                        order: 1,
                                        comment: null,
                                        toKpiId: null,
                                        fromKpiId: null,
                                        recommendationType: RECOMMENDATION_TYPES.INCREASE_KPI,
                                        setValueTo: null,
                                        relatedValue: null,
                                    },
                                ],
                                recommendationFeedback: null,
                            })
                        )
                ),
                recommendationsWithModifiedItems: [
                    ...prevState.recommendationsWithModifiedItems,
                    newRecommendationId,
                ],
            };
        });

    /**
     * Converts a complex item to a simple/message box.
     * @param {*} oldR
     */
    handleChangeItemToCustom = (oldR: ImmutableRecommendation) => (itemId: number) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex((r: ImmutableRecommendation) => r.get('id') === oldR.get('id'));
            let items = recommendationSet.getIn(['recommendations', rIdx, 'recommendationItems']);
            const iIdx = items.findIndex(
                (rItem: ImmutableRecommendationItem) => rItem.get('id') === itemId
            );
            items = items.updateIn([iIdx], (item: ImmutableRecommendationItem) => {
                return item.set('recommendationType', null);
            });

            const recommendationsWithModifiedItems = prevState.recommendationsWithModifiedItems.filter(
                (id: number) => id !== oldR.get('id')
            );
            const isRecommendationCalculatable = items.filter(
                (rItem: ImmutableRecommendationItem) =>
                    RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION.indexOf(
                        rItem.get('recommendationType')
                    ) !== -1 &&
                    rItem.get('toKpiId') !== null &&
                    rItem.get('setValueTo') !== null &&
                    rItem.get('setValueTo') !== ''
            );
            if (isRecommendationCalculatable.size > 0) {
                recommendationsWithModifiedItems.push(oldR.get('id'));
            }

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'recommendationItems'],
                    items
                ),
                recommendationsWithModifiedItems,
            };
        });

    /**
     * Gets the KPI value for a KPIID from kpis array.
     * @param {*} kpiId
     */
    getKPIValue = (kpiId: number) => {
        if (!this.props.kpis) {
            return null;
        }
        const latestKPI = this.props.kpis.find(
            (kpi: ImmutableKPICard) => kpi.get('kpiSettingId') === kpiId
        );
        const value = latestKPI ? latestKPI.get('value') : null;

        return value;
    };

    /**
     * Handles the change in a complex item's selected KPI.
     * @param {*} oldR
     */
    handleChangeItemKPI = (oldR: ImmutableRecommendation) => (itemId: number, newKPIId: number) => {
        const initialValue = this.getKPIValue(newKPIId);

        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex((r: ImmutableRecommendation) => r.get('id') === oldR.get('id'));

            let items = recommendationSet.getIn(['recommendations', rIdx, 'recommendationItems']);
            const iIdx = items.findIndex(
                (rItem: ImmutableRecommendationItem) => rItem.get('id') === itemId
            );
            items = items.updateIn([iIdx], (item: ImmutableRecommendationItem) =>
                item.set('toKpiId', newKPIId).set('setValueTo', initialValue)
            );

            const recommendationsWithModifiedItems = prevState.recommendationsWithModifiedItems.filter(
                (id: number) => id !== oldR.get('id')
            );
            recommendationsWithModifiedItems.push(oldR.get('id'));

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'recommendationItems'],
                    items
                ),
                recommendationsWithModifiedItems,
            };
        });
    };

    /**
     * Handle the change of value of a recommendation item.
     * If the item is a "complex" item (meaning, it is adjusting a KPI), then the value is the setToValue of the KPI
     * Otherwise, the the value is the new comment
     * @param {*} oldR
     */
    handleChangeItemValue = (oldR: ImmutableRecommendation) => (
        itemId: number,
        newValue: number | string,
        isSetToValue: boolean
    ) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex((r: ImmutableRecommendation) => r.get('id') === oldR.get('id'));
            let items = recommendationSet.getIn(['recommendations', rIdx, 'recommendationItems']);
            const iIdx = items.findIndex(
                (rItem: ImmutableRecommendationItem) => rItem.get('id') === itemId
            );
            items = items.updateIn([iIdx], (item: ImmutableRecommendationItem) => {
                if (isSetToValue) {
                    return item.set('setValueTo', newValue).set('relatedValue', null);
                } else {
                    return item
                        .set('comment', newValue)
                        .set('recommendationType', null)
                        .set('setValueTo', null)
                        .set('relatedValue', null)
                        .set('toKpiId', null)
                        .set('fromKpiId', null);
                }
            });

            let recommendationsWithModifiedItems = prevState.recommendationsWithModifiedItems;
            if (isSetToValue) {
                recommendationsWithModifiedItems = prevState.recommendationsWithModifiedItems.filter(
                    (id: number) => id !== oldR.get('id')
                );
                recommendationsWithModifiedItems.push(oldR.get('id'));
            }

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'recommendationItems'],
                    items
                ),
                recommendationsWithModifiedItems,
            };
        });

    /**
     * Add a new recommendation item to a recommendation
     * By default this adds a complex recommendation item.
     * @param {*} oldR
     */
    handleAddItem = (oldR: ImmutableRecommendation) => () =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex((r: ImmutableRecommendation) => r.get('id') === oldR.get('id'));
            let items = recommendationSet.getIn(['recommendations', rIdx, 'recommendationItems']);

            items = items.push(
                fromJS({
                    id: Math.random(),
                    shouldRemoveId: true,
                    order: items.size + 1,
                    comment: null,
                    toKpiId: null,
                    fromKpiId: null,
                    recommendationType: RECOMMENDATION_TYPES.INCREASE_KPI,
                    setValueTo: null,
                    relatedValue: null,
                })
            );

            const recommendationsWithModifiedItems = prevState.recommendationsWithModifiedItems.filter(
                (id: number) => id !== oldR.get('id')
            );
            recommendationsWithModifiedItems.push(oldR.get('id'));

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'recommendationItems'],
                    items
                ),
                recommendationsWithModifiedItems,
            };
        });

    /**
     * Remove a recommendation item from the recommendation
     * @param {*} oldR
     */
    handleRemoveItem = (oldR: ImmutableRecommendation) => (itemId: number) =>
        this.setState((prevState: State) => {
            const recommendationSet = prevState.recommendationSet;
            if (!recommendationSet) {
                return;
            }
            const rIdx = recommendationSet
                .get('recommendations')
                .findIndex((r: ImmutableRecommendation) => r.get('id') === oldR.get('id'));
            let items = recommendationSet.getIn(['recommendations', rIdx, 'recommendationItems']);
            const iIdx = items.findIndex(
                (rItem: ImmutableRecommendationItem) => rItem.get('id') === itemId
            );
            items = items.removeIn([iIdx]);

            const recommendationsWithModifiedItems = prevState.recommendationsWithModifiedItems.filter(
                (id: number) => id !== oldR.get('id')
            );
            const isRecommendationCalculatable = items.filter(
                (rItem: ImmutableRecommendationItem) =>
                    RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION.indexOf(
                        rItem.get('recommendationType')
                    ) !== -1 &&
                    rItem.get('toKpiId') !== null &&
                    rItem.get('setValueTo') !== null &&
                    rItem.get('setValueTo') !== ''
            );
            if (isRecommendationCalculatable.size > 0) {
                recommendationsWithModifiedItems.push(oldR.get('id'));
            }

            return {
                recommendationSet: recommendationSet.setIn(
                    ['recommendations', rIdx, 'recommendationItems'],
                    items
                ),
                recommendationsWithModifiedItems,
            };
        });

    /**
     * Handles the notify PM checkbox being clicked
     */
    handleNotifyPMClicked = (event: InputEvent) => {
        const isChecked = event.target.value === 'true';
        return this.setState((prevState: State) => {
            const newRecommendationSet = prevState.recommendationSet.set('notifyPm', isChecked);
            return {
                recommendationSet: newRecommendationSet,
            };
        });
    };

    /**
     * Handles edit mode of recommendation set for SAM
     */
    handleEditRecommendationSet = () =>
        this.setState(() => {
            return {
                initialRecommendationSet: this.state.recommendationSet,
                isEditMode: true,
            };
        });

    cancelEditRecommendationSet = () =>
        this.setState(() => {
            return {
                recommendationSet: this.state.initialRecommendationSet,
                isEditMode: false,
            };
        });

    /**
     *
     */
    renderInstructions = () => {
        const messages = this.getInstructionMessages();
        return messages.map((message: string) => (
            <Instructions key={message}>{message}</Instructions>
        ));
    };

    /**
     * Render the update / comments on the entire recommendation set.
     */
    renderDisplayMessages = () => {
        const formattedMessages =
            this.state.recommendationSet &&
            getRecommendationSetMessages(
                this.state.recommendationSet,
                this.props.kpiSettings,
                this.props.circuit,
                this.props.intl
            );
        let inner = null;
        if (this.canSAMModify()) {
            // the user is a sam or an admin:
            inner = (
                <InputMultiple
                    buttonText={this.getTranslation('addRecommendationSetMessage')}
                    initialValues={formattedMessages}
                    onChangeInput={this.handleChangeDisplayMessage}
                    onRemoveInput={this.handleRemoveDisplayMessage}
                    placeholder={this.getTranslation('recommendationSetMessagePlaceholder')}
                    isEmptiable
                />
            );
        } else {
            if (!formattedMessages || (formattedMessages && formattedMessages.size === 0)) {
                return null;
            }
            inner = (formattedMessages.map((dmItem: ImmutableFormattedRecommendationSetMessage) => (
                        <div key={dmItem.get('id')}>{dmItem.get('value')}</div>
                    ))
            );
        }
        return   (<>
            <MessageTitle>{this.getTranslation('messageHeader')}</MessageTitle>
            <DisplayMessages>{inner}</DisplayMessages>
        </>);
    };

    /**
     * Render the recommendation cards
     */
    renderCards = () => {
        if (!this.state.recommendationSet) {
            return null;
        }
        const wasRecommendationSetSubmitted = this.wasRecommendationSetSubmitted();
        const canSubmit = this.canSubmit();
        const recommendationCount = this.getRecommendationCount();

        return getSortedRecommendations(this.state.recommendationSet, false).map(
            (recommendation: ImmutableRecommendation, index: number) => (
                <RecommendationCard
                    key={recommendation.get('id')}
                    datasetId={this.props.recommendationSet.get('datasetId')}
                    feedback={this.getModifyingFeedback(recommendation)}
                    recommendation={recommendation}
                    kpiSettings={this.props.kpiSettings}
                    circuit={this.props.circuit}
                    maxRecommendationCount={recommendationCount}
                    cardIndex={index}
                    readOnly={!canSubmit}
                    loadingSubmittingFeedback={this.props.loadingSubmittingFeedback}
                    recommendationSetWasSubmitted={wasRecommendationSetSubmitted}
                    isUserPM={this.props.isUserPM}
                    showSubmissionTitle={false}
                    userLocale={this.props.userLocale}
                    timezone={this.props.timezone}
                    hasModifiedItems={Boolean(
                        this.state.recommendationsWithModifiedItems.find(
                            (id: number) => id === recommendation.get('id')
                        )
                    )}
                    onReorder={this.handleReorderRecommendation(recommendation)}
                    onChangeRationale={this.handleChangeRecommendationRationale(recommendation)}
                    onChangeFeedback={this.handleChangeFeedback}
                    onDeleteRecommendation={this.handleDeleteRecommendation(recommendation)}
                    onChangeIncludeInSubmission={this.handleChangeIncludeInSubmission(
                        recommendation
                    )}
                    onChangeOverallRecoveryImpact={this.handleChangeOverallRecoveryImpact(
                        recommendation
                    )}
                    onChangeCuTransferImpact={this.handleChangeCuTransferImpact(recommendation)}
                    onChangeItemToCustom={this.handleChangeItemToCustom(recommendation)}
                    onChangeItemKPI={this.handleChangeItemKPI(recommendation)}
                    onChangeItemValue={this.handleChangeItemValue(recommendation)}
                    onAddItem={this.handleAddItem(recommendation)}
                    onRemoveItem={this.handleRemoveItem(recommendation)}
                    onResetRecommendation={this.handleResetRecommendation}
                />
            )
        );
    };

    /**
     * Conditionally render SidebarHeader Elements based on if the DT can be run or not
     */
    renderSidebarHeaderElements = () => {
        const canRunDecisionTree = Boolean(this.props.onRunDecisionTree);
        const isRunDecisionTreeDisabled =
            canRunDecisionTree && this.props.datasetStatus !== DATASET_STATUSES.CONVERGED;

        const decisionTreeButtonWrapper = canRunDecisionTree ? (
            <DecisionTreeButtonWrapper>
                <PrimaryButton
                    onClick={this.props.onRunDecisionTree}
                    text={this.getTranslation('runDecisionTree')}
                    loading={this.props.loadingRunDecisionTree}
                    disabled={isRunDecisionTreeDisabled}
                />
                {isRunDecisionTreeDisabled && (
                    <ErrorMessage
                        errorMessage={this.getTranslation('latestDatasetNotConverged')}
                        isRed
                        isSmall
                    />
                )}
            </DecisionTreeButtonWrapper>
        ) : null;

        return (
            <React.Fragment>

                {decisionTreeButtonWrapper}

                {this.renderDisplayMessages()}
                {this.canSAMModify() && (
                    <PrimaryButton
                        onClick={this.handleAddRecommendation}
                        text={this.getTranslation('addRecommendationButton')}
                    />
                )}
            </React.Fragment>
        );
    };

    /**
     * Render the Sidebars Body & Footer elements
     */
    renderSidebarFooter = () =>
        (this.canSubmit() || this.canEdit()) && (
            <SidebarFooter style={{ padding: '14px 24px' }}>

                {this.canSubmit() && (
                    <React.Fragment>
                        <SidebarFooterButtonsWrapper
                            style={{ flexDirection: this.state.isEditMode ? 'row' : 'column' }}
                        >
                            {this.state.isEditMode && (
                                <SecondaryButton
                                    onClick={this.cancelEditRecommendationSet}
                                    text={this.getTranslation('cancel')}
                                />
                            )}

                            <PrimaryButton
                                onClick={this.handleSubmit}
                                disabled={this.isSubmitDisabled()}
                                text={this.getTranslation('submitRecommendationButton')}
                                loading={this.props.loadingSubmittingFeedback}
                            />
                        </SidebarFooterButtonsWrapper>

                        {this.canSAMModify() && (
                            <React.Fragment>
                                {!this.notificationAlreadySent() && (
                                    <CheckBox
                                        styles={{ margin: '14px auto 0' }}
                                        label={this.getTranslation('notifyPMsCheckboxLabel')}
                                        checked={this.state.recommendationSet.get('notifyPm')}
                                        disabled={this.isSubmitDisabled()}
                                        onClickHandler={this.handleNotifyPMClicked}
                                    />
                                )}

                                {this.state.isEditMode && this.notificationAlreadySent() && (
                                    <Instructions style={{ textAlign: 'center' }}>
                                        {this.getTranslation('PMsAlreadyNotified')}
                                    </Instructions>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {this.canEdit() && !this.state.isEditMode && (
                    <PrimaryButton
                        onClick={this.handleEditRecommendationSet}
                        text={this.getTranslation('edit')}
                        styles={{ width: 'auto', alignSelf: 'unset' }}
                    />
                )}
            </SidebarFooter>
        );

    render() {
        return (
            <React.Fragment>
                <SidebarBody>
                    {this.renderSidebarHeaderElements()}
                    <RecommendationsBody>{this.renderCards()} {this.renderInstructions()}</RecommendationsBody>

                </SidebarBody>
                {this.renderSidebarFooter()}
            </React.Fragment>
        );
    }
}

export default injectIntl(RecommendationSidebar);
