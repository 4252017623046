// @flow strict

import { STAGE_TYPES, STREAM_CIRCUITS, STREAM_TYPES } from 'utils/constants';

export const ORGANIC_CIRCUIT_TYPE = {
    BOTH: 'BOTH',
    EXTRACT_ONLY: 'EXTRACT_ONLY',
    STRIP_ONLY: 'STRIP_ONLY',
};

/**
 * Compute grid design(css grid layout) configuration
 */
export const COMPUTE_GRID = {
    GRID: {
        COLUMN_INDEX: 1, // CSS grids start at index 1.
        COLUMN_WIDTH: 72,
        COLUMN_GAP: 16,
        ROW_HEIGHT: 34,
        ROW_GAP: 4,
        MAX_NUMBER_OF_ROWS: 26,
        STAGE_SIDE_MARGIN: 0,
        PADDING: 44,
    },
    STAGE: {
        STARTING_COLUMN: 3,
        STARTING_ROW: 4,
        COLUMN_SPAN: 2,
        ROW_SPAN: 4,
        STRIP_STREAM_VALUES_ROW_SPAN: 3, // a maximum of 3 stream values will be below the electrolyte section.
        ORGANIC_STREAM_VALUES_ROW_SPAN: 7, // a maximum of 7 stream values will be below the organic streams.
    },
    TANK: {
        COLUMN_SPAN: 2,
        ROW_SPAN: 3, // 1 for the header, 2 for the tank values
        COLUMN_OFFSET_FROM_EXTRACT: -3, // for the outgoing bypass bleed stream value
        COLUMN_SPAN_TO_AND_FROM_NEW_TANK: 4, // 2*2 stream values.
        ROW_OFFSET_FROM_EXTRACT: 3,
        ROW_OFFSET_FROM_STRIP: 3,
    },
    STREAM_VALUES: {
        STARTING_COLUMN: 1,
        STARTING_ROW: 1,
        COLUMN_SPAN: 2,
        ROW_SPAN: 1,
        FEED_BLEED_OFFSET: 0, // how many rows above the stage should feed bleeds start
    },
    CONTINUE_STREAM_VALUES: {
        COLUMN_SPAN: 2,
        ROW_SPAN: 2,
    },
    CONTINUE_BLEND_STREAM: {
        COLUMN_SPAN: 4, // CONTINUE_STREAM_VALUES.COLUMN_SPAN * 2
    },
    BYPASS_STREAM: {
        COLUMN_SPAN: 2,
        FEED_ROW_SPAN: 2,
        BLEED_ROW_SPAN: 2,
        FEED_VERTICAL_OFFSET: 2,
        BLEED_VERTICAL_OFFSET: 2.5,
    },
    TANK_BYPASS_STREAM: {
        COLUMN_SPAN: 2,
        ROW_SPAN: 1,
        FEED_HORIZONTAL_OFFSET: 2,
        FEED_HORIZONTAL_OFFSET_FIRST: -1, // 1 cell from the left of the stage
        FEED_VERTICAL_OFFSET: 3, // 3 cells below the extractor stage
        BLEED_VERTICAL_OFFSET: 3.5,
        BLEED_VERTICAL_OFFSET_TO_TANK: 0.5, // half a cell above the to tank
        BLEED_HORIZONTAL_OFFSET_FROM_TANK: -1, // a cell to the left of the tank
    },
    SKIP: {
        TOP_OFFSET: 4,
        STAGE_Y_OFFSET: 0.45,
    },
    WASHER: {
        COLUMN_SPAN: 2,
        ROW_SPAN: 4, // 3 for stage values, 1 for the header
        COLUMN_OFFSET_FROM_EXTRACT: -3, // for the outgoing bypass bleed stream value
        ROW_OFFSET_FROM_EXTRACT: 3,
        ROW_OFFSET_FROM_STRIP: 3,
    },
};

/**
 * Setup grid design(css grid layout) configuration
 */
export const SETUP_GRID = {
    GRID: {
        COLUMN_INDEX: 1, // CSS grids start at index 1.
        COLUMN_WIDTH: 62,
        COLUMN_GAP: 6,
        ROW_HEIGHT: 30,
        ROW_GAP: 6,
        MAX_NUMBER_OF_ROWS: 11,
        STAGE_SIDE_MARGIN: 0,
        PADDING: 22,
    },
    STAGE: {
        STARTING_COLUMN: 2,
        STARTING_ROW: 2,
        COLUMN_SPAN: 2,
        ROW_SPAN: 2,
        STRIP_STREAM_VALUES_ROW_SPAN: 3, // Leave 3 rows for the feed/bleeds
        ORGANIC_STREAM_VALUES_ROW_SPAN: 3, // Leave 3 rows for the feed/bleeds
    },
    TANK: {
        COLUMN_SPAN: 1,
        ROW_SPAN: 2,
        COLUMN_OFFSET_FROM_EXTRACT: -1,
        COLUMN_SPAN_TO_AND_FROM_NEW_TANK: 2, // 1 for the bypass bleed and 1 for the bypass feed.
        ROW_OFFSET_FROM_EXTRACT: 3,
        ROW_OFFSET_FROM_STRIP: 0,
    },
    STREAM_VALUES: {
        STARTING_COLUMN: 1,
        COLUMN_SPAN: 1,
        ROW_SPAN: 1,
        FEED_BLEED_OFFSET: 0.5, // how many rows above the stage should feed bleeds start
    },
    CONTINUE_STREAM_VALUES: {
        COLUMN_SPAN: 2,
        ROW_SPAN: 1,
    },
    CONTINUE_BLEND_STREAM: {
        COLUMN_SPAN: 2, // CONTINUE_STREAM_VALUES.COLUMN_SPAN
    },
    BYPASS_STREAM: {
        COLUMN_SPAN: 0.5,
        FEED_ROW_SPAN: 1,
        BLEED_ROW_SPAN: 1,
        FEED_VERTICAL_OFFSET: 1,
        BLEED_VERTICAL_OFFSET: 2,
    },
    TANK_BYPASS_STREAM: {
        COLUMN_SPAN: 0.5,
        ROW_SPAN: 0,
        FEED_HORIZONTAL_OFFSET: 1,
        FEED_HORIZONTAL_OFFSET_FIRST: 0,
        FEED_VERTICAL_OFFSET: 2,
        BLEED_VERTICAL_OFFSET: 3,
        BLEED_VERTICAL_OFFSET_TO_TANK: 0.5, // half a cell above the to tank
        BLEED_HORIZONTAL_OFFSET_FROM_TANK: 0, // a cell to the left of the tank
    },
    SKIP: {
        TOP_OFFSET: 3,
        STAGE_Y_OFFSET: 0,
    },
    WASHER: {
        COLUMN_SPAN: 1,
        ROW_SPAN: 2,
        COLUMN_OFFSET_FROM_EXTRACT: -1,
        ROW_OFFSET_FROM_EXTRACT: 3,
        ROW_OFFSET_FROM_STRIP: 0,
    },
};

/**
 * Path Types are a direction of the arrow and type of stream.
 * For example:
 *   aqueous feed arrow direction would be to the right thus RIGHT_FEED.
 *   organic continue arrow between two stripping stages will have arrow direction to the left, thus LEFT_CONTINUE.
 *   organic continue path between extraction stage and stripping stage on the left side would have bottom direction thus BOTTOM_CONTINUE.
 *   organic continue path between extraction stage and stripping stage on the right side when there are more extraction stages than stripping stages
 *       would be of a top direction and the bottom path will be longer, thus TOP_CONTINUE_LONGER_BOTTOM
 */
export const PATH_TYPES = {
    RIGHT_FEED: 'RIGHT_FEED',
    RIGHT_BLEED: 'RIGHT_BLEED',
    RIGHT_FEED_EDGE: 'RIGHT_FEED_EDGE',
    RIGHT_BLEED_EDGE: 'RIGHT_BLEED_EDGE',
    RIGHT_BLEED_ADVANCED: 'RIGHT_BLEED_ADVANCED', // advanced bleed with pls blend
    RIGHT_CONTINUE: 'RIGHT_CONTINUE',
    LEFT_FEED: 'LEFT_FEED',
    LEFT_BLEED: 'LEFT_BLEED',
    LEFT_FEED_EDGE: 'LEFT_FEED_EDGE',
    LEFT_BLEED_EDGE: 'LEFT_BLEED_EDGE',
    LEFT_CONTINUE: 'LEFT_CONTINUE',
    BOTTOM_CONTINUE: 'BOTTOM_CONTINUE',
    STRAIGHT_DOWN: 'STRAIGHT_DOWN',
    TOP_CONTINUE: 'TOP_CONTINUE',
    TOP_CONTINUE_LONGER_TOP: 'TOP_CONTINUE_LONGER_TOP',
    TOP_CONTINUE_LONGER_BOTTOM: 'TOP_CONTINUE_LONGER_BOTTOM',
    // Advanced:
    BLEND: 'BLEND',
    BLEND_ADVANCED: 'BLEND_ADVANCED', // The blend has a bleed.
    SKIP: 'SKIP',
    // Bypasses
    EXTRACT_BYPASS_FEED: 'EXTRACT_BYPASS_FEED',
    EXTRACT_BYPASS_BLEED: 'EXTRACT_BYPASS_BLEED',
    EXTRACT_BYPASS_BLEND: 'EXTRACT_BYPASS_BLEND',
    STRIP_BYPASS_FEED: 'STRIP_BYPASS_FEED',
    STRIP_BYPASS_BLEED: 'STRIP_BYPASS_BLEED',
    // Bypassing from/to LO tanks
    ORGANIC_TANK_BYPASS_BLEED: 'ORGANIC_TANK_BYPASS_BLEED',
    ORGANIC_TANK_TO_ORGANIC_TANK_BYPASS_BLEED:
        'ORGANIC_TANK_TO_ORGANIC_TANK_BYPASS_BLEED',

    // LO and Extract:
    EXTRACT_CONTINUE_TO_ORGANIC_TANK: 'EXTRACT_CONTINUE_TO_ORGANIC_TANK',
    EXTRACT_BYPASS_TO_ORGANIC_TANK_FIRST: 'EXTRACT_BYPASS_TO_ORGANIC_TANK_FIRST',
    EXTRACT_BYPASS_BLEED_TO_ORGANIC_TANK: 'EXTRACT_BYPASS_BLEED_TO_ORGANIC_TANK',
    ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED: 'ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED',
    ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED_FIRST:
        'ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED_FIRST',
    ORGANIC_TANK_TO_EXTRACT_BYPASS_BLEND: 'ORGANIC_TANK_TO_EXTRACT_BYPASS_BLEND',
    ORGANIC_TANK_BYPASS_FEED_TO_EXTRACT_FIRST:
        'ORGANIC_TANK_BYPASS_FEED_TO_EXTRACT_FIRST',
    ORGANIC_TANK_BYPASS_FEED_TO_EXTRACT: 'ORGANIC_TANK_BYPASS_FEED_TO_EXTRACT',

    // LO and Strip:
    ORGANIC_TANK_CONTINUE_TO_STRIP: 'ORGANIC_TANK_CONTINUE_TO_STRIP',
    ORGANIC_TANK_TO_STRIP_BYPASS_FEED: 'ORGANIC_TANK_TO_STRIP_BYPASS_FEED',
    ORGANIC_TANK_BYPASS_BLEED_TO_STRIP: 'ORGANIC_TANK_BYPASS_BLEED_TO_STRIP',

    // Washers:
    EXTRACT_TO_WASHER: 'EXTRACT_TO_WASHER',
    WASHER_TO_ORGANIC_TANK: 'WASHER_TO_ORGANIC_TANK',
    ORGANIC_TANK_BYPASS_BLEED_TO_WASHER: 'ORGANIC_TANK_BYPASS_BLEED_TO_WASHER',
    WASHER_TO_STRIP: 'WASHER_TO_STRIP',
    WASHER_TO_EXTRACT: 'WASHER_TO_EXTRACT',
    STRIP_TO_WASHER: 'STRIP_TO_WASHER',
};

export const PORT_TYPES = {
    TOP_LEFT: 'TOP_LEFT',
    TOP_RIGHT: 'TOP_RIGHT',
    TOP_CENTER: 'TOP_CENTER',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT',
    TANK_BOTTOM_CENTER: 'TANK_BOTTOM_CENTER',
    WASHER_BOTTOM_CENTER: 'WASHER_BOTTOM_CENTER',
};

/**
 * Define path types according to stream types
 */
export const STREAM_PATH_TYPES = {
    [STREAM_CIRCUITS.AQUEOUS]: {
        [STREAM_TYPES.FEED]: PATH_TYPES.RIGHT_FEED,
        [STREAM_TYPES.BLEED]: PATH_TYPES.RIGHT_BLEED,
        [STREAM_TYPES.BLEND]: PATH_TYPES.BLEND,
        [STREAM_TYPES.CONTINUE]: PATH_TYPES.RIGHT_CONTINUE,
        [STREAM_TYPES.SKIP]: PATH_TYPES.SKIP,
    },
    [STREAM_CIRCUITS.ELECTROLYTE]: {
        [STREAM_TYPES.FEED]: PATH_TYPES.LEFT_FEED,
        [STREAM_TYPES.BLEED]: PATH_TYPES.LEFT_BLEED,
        [STREAM_TYPES.CONTINUE]: PATH_TYPES.LEFT_CONTINUE,
    },
    [`${STREAM_CIRCUITS.ORGANIC}-EXTRACT_ONLY`]: {
        [STREAM_TYPES.FEED]: PATH_TYPES.LEFT_FEED,
        [STREAM_TYPES.BLEED]: PATH_TYPES.LEFT_BLEED,
        [STREAM_TYPES.BYPASS_BLEND]: PATH_TYPES.EXTRACT_BYPASS_BLEND,
        [STREAM_TYPES.BYPASS_FEED]: PATH_TYPES.EXTRACT_BYPASS_FEED,
        [STREAM_TYPES.BYPASS_BLEED]: PATH_TYPES.EXTRACT_BYPASS_BLEED,
        [STREAM_TYPES.CONTINUE]: PATH_TYPES.LEFT_CONTINUE,
    },
    [`${STREAM_CIRCUITS.ORGANIC}-STRIP_ONLY`]: {
        [STREAM_TYPES.FEED]: PATH_TYPES.RIGHT_FEED,
        [STREAM_TYPES.BLEED]: PATH_TYPES.RIGHT_BLEED,
        [STREAM_TYPES.BLEND]: PATH_TYPES.RIGHT_FEED,
        [STREAM_TYPES.BYPASS_FEED]: PATH_TYPES.STRIP_BYPASS_FEED,
        [STREAM_TYPES.BYPASS_BLEED]: PATH_TYPES.STRIP_BYPASS_BLEED,
        [STREAM_TYPES.CONTINUE]: PATH_TYPES.RIGHT_CONTINUE,
    },
    [`${STREAM_CIRCUITS.ORGANIC}-BOTH`]: {
        [`${STAGE_TYPES.EXTRACT}-${STAGE_TYPES.WASHER}`]: PATH_TYPES.EXTRACT_TO_WASHER,
        [`${STAGE_TYPES.WASHER}-${STAGE_TYPES.ORGANIC_TANK
            }`]: PATH_TYPES.WASHER_TO_ORGANIC_TANK,
        [`${STAGE_TYPES.WASHER}-${STAGE_TYPES.STRIP}`]: PATH_TYPES.WASHER_TO_STRIP,
        [`${STAGE_TYPES.WASHER}-${STAGE_TYPES.EXTRACT}`]: PATH_TYPES.WASHER_TO_EXTRACT,
        [`${STAGE_TYPES.STRIP}-${STAGE_TYPES.WASHER}`]: PATH_TYPES.STRIP_TO_WASHER,
        [`${STAGE_TYPES.EXTRACT}-${STAGE_TYPES.EXTRACT}`]: PATH_TYPES.LEFT_CONTINUE,
        [`${STAGE_TYPES.STRIP}-${STAGE_TYPES.STRIP}`]: PATH_TYPES.RIGHT_CONTINUE,
        [`${STAGE_TYPES.EXTRACT}-${STAGE_TYPES.STRIP}`]: PATH_TYPES.BOTTOM_CONTINUE,
        // When extractors # and strippers # is equal
        [`${STAGE_TYPES.STRIP}-${STAGE_TYPES.EXTRACT}`]: PATH_TYPES.TOP_CONTINUE,
        // When extractors # and strippers # is not equal
        [`${STAGE_TYPES.STRIP}-${STAGE_TYPES.EXTRACT
            }-MORE_EXTRACT`]: PATH_TYPES.TOP_CONTINUE_LONGER_BOTTOM,
        [`${STAGE_TYPES.STRIP}-${STAGE_TYPES.EXTRACT
            }-MORE_STRIP`]: PATH_TYPES.TOP_CONTINUE_LONGER_TOP,
        // Bypasses
        [`${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_FEED}`]: PATH_TYPES.EXTRACT_BYPASS_FEED,
        [`${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_BLEED}`]: PATH_TYPES.EXTRACT_BYPASS_BLEED,
        [`${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_BLEND}`]: PATH_TYPES.EXTRACT_BYPASS_BLEND,
        [`${STAGE_TYPES.STRIP}_${STREAM_TYPES.BYPASS_FEED}`]: PATH_TYPES.STRIP_BYPASS_FEED,
        [`${STAGE_TYPES.STRIP}_${STREAM_TYPES.BYPASS_BLEED}`]: PATH_TYPES.STRIP_BYPASS_BLEED,
        // Streams to handle Organic Tanks:
        [`${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.CONTINUE}_TO_${STAGE_TYPES.ORGANIC_TANK
            }`]: PATH_TYPES.EXTRACT_CONTINUE_TO_ORGANIC_TANK,
        [`${STAGE_TYPES.ORGANIC_TANK}_${STREAM_TYPES.CONTINUE}_TO_${STAGE_TYPES.STRIP
            }`]: PATH_TYPES.ORGANIC_TANK_CONTINUE_TO_STRIP,
        [`${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_BLEED}_TO_${STAGE_TYPES.ORGANIC_TANK
            }_FIRST`]: PATH_TYPES.EXTRACT_BYPASS_TO_ORGANIC_TANK_FIRST,
        [`${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_BLEED}_TO_${STAGE_TYPES.ORGANIC_TANK
            }`]: PATH_TYPES.EXTRACT_BYPASS_BLEED_TO_ORGANIC_TANK,
        [`${STAGE_TYPES.ORGANIC_TANK}_TO_${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_FEED
            }`]: PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED,
        [`${STAGE_TYPES.ORGANIC_TANK}_TO_${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_BLEND
            }`]: PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_BLEND,
        [`${STAGE_TYPES.ORGANIC_TANK}_${STREAM_TYPES.BYPASS_FEED}_TO_${STAGE_TYPES.EXTRACT
            }_FIRST`]: PATH_TYPES.ORGANIC_TANK_BYPASS_FEED_TO_EXTRACT_FIRST,
        [`${STAGE_TYPES.ORGANIC_TANK}_TO_${STAGE_TYPES.EXTRACT}_${STREAM_TYPES.BYPASS_FEED
            }_FIRST`]: PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED_FIRST,
        [`${STAGE_TYPES.ORGANIC_TANK}_TO_${STAGE_TYPES.ORGANIC_TANK}_${STREAM_TYPES.BYPASS_BLEED
            }`]: PATH_TYPES.ORGANIC_TANK_TO_ORGANIC_TANK_BYPASS_BLEED,
        [`${PATH_TYPES.ORGANIC_TANK_TO_STRIP_BYPASS_FEED
            }`]: PATH_TYPES.ORGANIC_TANK_TO_STRIP_BYPASS_FEED,
        [`${PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_WASHER
            }`]: PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_WASHER,
        [`${PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_STRIP
            }`]: PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_STRIP,
    },
};
