/* eslint-disable no-console */
// @flow

import defaultTheme from '../../themes/defaultTheme';

/**
 * Function which fetches nested value of provided theme with nested keys
 * i.e. theme.key1.key2.key3
 *
 * @param {object} theme
 * @param {array} keys
 */
export const themePropertyFetcher = (theme: Object, keys: Array<string>) => {
    const inherit = 'inherit';

    if (!theme || !keys || !Array.isArray(keys)) {
        if (!theme) {
            console.log('No theme provided.');
        }
        if (!keys) {
            console.log('No keys provided.');
        }
        if (!Array.isArray(keys)) {
            console.log('Provided keys is not an array');
        }
        return inherit;
    }

    // If provided theme does not contain first key, fallback with default theme
    const activeTheme = theme[keys[0]] ? theme : defaultTheme;

    let value = inherit;
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        value = value && value !== inherit ? value[key] : activeTheme[key];
    }

    // If provided theme does not have value at provided key, fetch from default theme
    let defaultValue = inherit;
    if (value === 'undefined' || !value) {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            defaultValue =
                defaultValue && defaultValue !== inherit ? defaultValue[key] : defaultTheme[key];
        }
    }

    return value || defaultValue;
};
