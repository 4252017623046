// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import { SidebarLayout } from 'components/_ReactUI_V1';

import ClientManager from 'components/ClientManager';

// Constants
import { STYLE_VALUES } from 'utils/constants';

// Selectors
import {
    selectQueriedClients,
    selectQueriedClientsLastPage,
    selectClientErrors,
    selectClientsAreDeleting,
    selectClientsAreQuerying,
    selectClientsAreUpdating,
} from 'services/Client/selectors';

// Styles
import { LayoutContainer } from 'styles/common';

// Thunks
import { createClient, destroyClient, queryAllClients, updateClient } from 'services/Client/thunks';

// Types
import type { SearchCriteria, ImmutableList, ReduxDispatch, ErrorType } from 'types';
import type { ImmutableClient } from 'services/Client/types';

type Props = {
    errors: ErrorType,

    queriedClients: ImmutableList<ImmutableClient>,
    clientIsDeleting: boolean,
    clientIsUpdating: boolean,
    clientsAreQuerying: boolean,
    lastPage: number,

    destroyClient: (number) => void,
    queryAllClients: (SearchCriteria, number) => void,
    updateClient: (number, ImmutableClient) => void,
    createClient: (ImmutableClient) => void,
};

type State = {
    pagination: {
        page: number,
    },
    searchCriteria: SearchCriteria,
};

// Constants
const DEFAULT_PAGE = 1;
const DEFAULT_SORTBY = 'name';
const DEFAULT_SORTORDER = 'asc';

class ClientManagementContainer extends React.PureComponent<Props, State> {
    state = {
        pagination: {
            page: DEFAULT_PAGE,
        },
        searchCriteria: {
            sortBy: DEFAULT_SORTBY,
            sortOrder: DEFAULT_SORTORDER,
        },
    };

    componentDidMount() {
        if (this.props.queriedClients.isEmpty()) {
            this.props.queryAllClients(this.state.searchCriteria, this.state.pagination.page);
        }
    }

    /**
     * On pagination change, update state with provided page
     * Fire props.queryAllClients() with updated searchCriteria and page
     */
    handlePaginationPageSelection = (page: number, total: number) => () => {
        if (page > total || page < 0) {
            return null;
        }

        return this.setState(
            {
                pagination: {
                    page,
                },
            },
            () => this.onQueryAllClients()
        );
    };

    /**
     * On table sorting, set provided sortBy & sortOrder to state.searchCriteria
     * Reset state.pagination.page to default
     * Fire props.queryAllClients() with updated searchCriteria and page
     */
    handleSortBy = (searchCriteria: SearchCriteria) => {
        this.setState(
            {
                searchCriteria,
                pagination: {
                    page: DEFAULT_PAGE,
                },
            },
            () => this.onQueryAllClients()
        );
    };

    onCreateClient = (client: ImmutableClient) => {
        this.props.createClient(client);
        this.onQueryAllClients(true);
    };

    /**
     * Fires props.queryAllClients
     * If provided reset boolean is true, reset searchCriteria to defaults first.
     */
    onQueryAllClients = (reset: boolean = false) => {
        if (reset) {
            this.setState(
                {
                    searchCriteria: {
                        sortBy: DEFAULT_SORTBY,
                        sortOrder: DEFAULT_SORTORDER,
                    },
                    pagination: {
                        page: DEFAULT_PAGE,
                    },
                },
                () =>
                    this.props.queryAllClients(
                        this.state.searchCriteria,
                        this.state.pagination.page
                    )
            );
        } else {
            return this.props.queryAllClients(
                this.state.searchCriteria,
                this.state.pagination.page
            );
        }
    };

    // Render container for the main area content
    renderMainContent = () => (
        <LayoutContainer fullWidth>
            <ClientManager
                clientIsDeleting={this.props.clientIsDeleting}
                clientIsUpdating={this.props.clientIsUpdating}
                clients={this.props.queriedClients}
                clientsAreQuerying={this.props.clientsAreQuerying}
                errors={this.props.errors}
                handleCreateNewClient={this.onCreateClient}
                handleDeleteClient={this.props.destroyClient}
                handleUpdateClient={this.props.updateClient}
                lastPage={this.props.lastPage}
                onHandlePaginationPageSelection={this.handlePaginationPageSelection}
                onHandleSortBy={this.handleSortBy}
                pagination={this.state.pagination}
                searchCriteria={this.state.searchCriteria}
            />
        </LayoutContainer>
    );

    render() {
        return (
            <SidebarLayout
                styles={{
                    main: { overflowY: 'auto' },
                    container: {
                        height: `calc(100% - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                }}
                renderMain={this.renderMainContent}
                mainFlush
                flush
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectClientErrors(),
    lastPage: selectQueriedClientsLastPage(),
    queriedClients: selectQueriedClients(),
    clientIsDeleting: selectClientsAreDeleting(),
    clientIsUpdating: selectClientsAreUpdating(),
    clientsAreQuerying: selectClientsAreQuerying(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createClient,
            destroyClient,
            queryAllClients,
            updateClient,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ClientManagementContainer)
);
