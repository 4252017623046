// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

// Mimic Engine
import { type IMimicCircuit, type IMimicStage } from 'components/_McCabeThiele';

// Styles
import { colors } from 'styles/colors';
import { StageHeader, StageHeaderDescription, StageHeaderLabel } from './styles';

// Services
import { selectUser } from 'services/Authentication/selectors';

// Authentication
import { isSysAdmin } from 'utils/authentication';

// Diagram Components
import DiagramEntity from 'components/MimicDiagram/DiagramEntity';
import DiagramOrganicTank from 'components/MimicDiagram/DiagramOrganicTank';
import DiagramWasher from 'components/MimicDiagram/DiagramWasher';
import DiagramMixerSettler from 'components/MimicDiagram/DiagramMixerSettler';
import { SETUP_GRID, COMPUTE_GRID } from 'components/MimicDiagram/constants';

// Components
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import StagePropertiesModal from 'components/Modals/StagePropertiesModal';

// Constants
import { STAGE_TYPES } from 'utils/constants';

// Types
import type { IntlType, UnitsConstant } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { LooseStage, StageProperties } from 'services/Circuit/types';
import type { ChangeStagePropertiesFunction } from 'containers/CircuitSetupContainer/MimicDiagramContainer';
import type { DiagramDisplayModesConstant } from 'components/MimicDiagram/types';
import type { DatasetModesConstant } from 'services/Dataset/types';
import type {
    OpenIsothermSelectModalFunction,
    SetStageValueFunction,
} from 'containers/CircuitComputationContainer/MimicContainer';

const SMALL_STAGE_TYPES = [STAGE_TYPES.ORGANIC_TANK, STAGE_TYPES.WASHER];

type InjectedProps = {
    intl: IntlType,
    user: ImmutableUser,
};

type Props = InjectedProps & {
    mimicCircuit: IMimicCircuit,
    isSetupMode: boolean,
    displayMode: DiagramDisplayModesConstant,
    datasetMode: ?DatasetModesConstant,
    circuitUnits: UnitsConstant,

    stageData: LooseStage,
    startingColumn: number,
    startingRow: number,

    setStageValue: SetStageValueFunction,

    onChangeStageProperties: ?ChangeStagePropertiesFunction,
    onOpenIsothermSelectModal: OpenIsothermSelectModalFunction,
    onRemoveLoTank: (loTank: LooseStage) => void,
};

type State = {
    showPropertiesModal: boolean,
    showRemoveStageModal: boolean,
};

class DiagramStage extends React.PureComponent<Props, State> {
    state = {
        showPropertiesModal: false,
        showRemoveStageModal: false,
    };

    getStageLabel = (): string => {
        const stage = this.getMimicStage();
        return stage.getName();
    };

    getMimicStage = (): IMimicStage => {
        let stage;
        if (!this.props.stageData || !this.props.stageData.id) {
            stage = this.props.mimicCircuit.getStageByDescription(
                this.props.stageData.stageType,
                this.props.stageData.location
            );
        } else {
            stage = this.props.mimicCircuit.getStageById(this.props.stageData.id);
        }
        return stage;
    };

    /**
     * The user can only remove Loaded Organic tanks
     */
    canUserRemoveStage = (): boolean => {
        if (!this.props.isSetupMode) {
            return false;
        }
        if (this.props.stageData.stageType === STAGE_TYPES.ORGANIC_TANK) {
            const amountOfTanks = this.props.mimicCircuit.getTanks().length;
            // A user may only remove the last tank
            return this.props.stageData.location === amountOfTanks;
        }
        return false;
    };

    canUserOpenProperties = (): boolean => {
        if (!this.props.isSetupMode) {
            return false;
        }
        return true;
    };

    getStageClickHandler = (): ?Function => {
        if (this.canUserOpenProperties()) {
            return this.handleStageClicked;
        }
        if (this.canUserRemoveStage()) {
            return this.handleRemoveStageClicked;
        }
        return null;
    };

    handleRemoveStageClicked = () => {
        if (this.canUserRemoveStage()) {
            this.setState({
                showRemoveStageModal: true,
            });
        }
    };

    /**
     * When the user clicks on a mixer settler in the mimic diagram (setup mode only)
     */
    handleStageClicked = () => {
        if (this.canUserOpenProperties()) {
            this.setState({
                showPropertiesModal: true,
            });
        }
    };

    /**
     * When the properties modal is closed via the save button,
     * we must save the stage properties.
     * @param {*} newStageProperties
     */
    handleMixerSettlerPropertySave = (newStageProperties: StageProperties) => {
        if (!this.props.isSetupMode) {
            return;
        }
        // Close our modal and let the parent update the properties
        this.setState(
            {
                showPropertiesModal: false,
            },
            () =>
                this.props.onChangeStageProperties &&
                this.props.onChangeStageProperties(this.props.stageData, newStageProperties)
        );
    };

    /**
     * Triggered when the user confirms that they want to remove this stage.
     */
    handleConfirmRemoveStage = () => {
        this.setState(
            {
                showPropertiesModal: false,
                showRemoveStageModal: false,
            },
            () => {
                this.props.onRemoveLoTank(this.props.stageData);
            }
        );
    };

    /**
     * Close the properties modal
     */
    handleClosePropertiesModal = () =>
        this.setState({
            showPropertiesModal: false,
        });

    /**
     * Closes the modal that confirms the stage deletion
     */
    handleCloseRemoveStageModal = () =>
        this.setState({
            showRemoveStageModal: false,
        });

    /**
     * Get the cell dimensions of a stage
     * @returns the [column span, row span] of a stage.
     */
    getStageCellSpans = () => {
        const grid = this.props.isSetupMode ? SETUP_GRID : COMPUTE_GRID;
        switch (this.props.stageData.stageType) {
            case STAGE_TYPES.ORGANIC_TANK: {
                return [grid.TANK.COLUMN_SPAN, grid.TANK.ROW_SPAN];
            }
            case STAGE_TYPES.EXTRACT:
            case STAGE_TYPES.STRIP: {
                return [grid.STAGE.COLUMN_SPAN, grid.STAGE.ROW_SPAN];
            }
            case STAGE_TYPES.WASHER: {
                return [grid.WASHER.COLUMN_SPAN, grid.WASHER.ROW_SPAN];
            }
            default:
                throw new Error('Unknown stage type provided to DiagramStage');
        }
    };

    getStageTitle = () => {
        switch (this.props.stageData.stageType) {
            case STAGE_TYPES.ORGANIC_TANK: {
                return this.props.intl.formatMessage({
                    id: `components.MimicDiagram.Tank.Title`,
                });
            }
            case STAGE_TYPES.EXTRACT:
            case STAGE_TYPES.STRIP: {
                return this.props.intl.formatMessage({
                    id: `components.MimicDiagram.Stage.Title`,
                });
            }
            case STAGE_TYPES.WASHER: {
                return this.props.intl.formatMessage({
                    id: `components.MimicDiagram.Washer`,
                });
            }
            default:
                throw new Error('Unknown stage type provided to DiagramStage');
        }
    };

    renderStage = () => {
        switch (this.props.stageData.stageType) {
            case STAGE_TYPES.ORGANIC_TANK: {
                return <DiagramOrganicTank {...this.props} />;
            }
            case STAGE_TYPES.EXTRACT:
            case STAGE_TYPES.STRIP: {
                return <DiagramMixerSettler {...this.props} />;
            }
            case STAGE_TYPES.WASHER: {
                return <DiagramWasher {...this.props} />;
            }
            default:
                throw new Error('Unknown stage type provided to DiagramStage');
        }
    };

    render() {
        const isSmallStage = SMALL_STAGE_TYPES.includes(this.props.stageData.stageType);

        const cellSpans = this.getStageCellSpans();
        const columnSpan = cellSpans[0];
        const rowSpan = cellSpans[1];

        let bgColor = colors.greyE5;
        const borderColor = colors.greyE5;
        if (!this.props.isSetupMode && isSmallStage) {
            bgColor = null;
        }

        return (
            <React.Fragment>
                {this.state.showPropertiesModal && (
                    <StagePropertiesModal
                        stage={this.getMimicStage()}
                        showRemoveStageButton={this.canUserRemoveStage()}
                        onSave={this.handleMixerSettlerPropertySave}
                        onCloseModal={this.handleClosePropertiesModal}
                        onRemoveStage={this.handleRemoveStageClicked}
                        isAdmin={isSysAdmin(this.props.user)}
                    />
                )}
                {this.state.showRemoveStageModal && (
                    <ConfirmationModal
                        title={this.props.intl.formatMessage(
                            {
                                id: `components.Modals.RemoveStageModal.title`,
                            },
                            { stageCode: this.getStageLabel() }
                        )}
                        areYouSureStart={this.props.intl.formatMessage({
                            id: `components.Modals.RemoveStageModal.areYouSureStart`,
                        })}
                        areYouSureDanger={this.props.intl.formatMessage(
                            {
                                id: `components.Modals.RemoveStageModal.areYouSureDanger`,
                            },
                            { stageCode: this.getStageLabel() }
                        )}
                        areYouSureEnd={this.props.intl.formatMessage({
                            id: `components.Modals.RemoveStageModal.areYouSureEnd`,
                        })}
                        confirmButtonText={this.props.intl.formatMessage({
                            id: `components.ConfirmationModal.confirmButton`,
                        })}
                        onConfirm={this.handleConfirmRemoveStage}
                        onCancel={this.handleCloseRemoveStageModal}
                    />
                )}
                <DiagramEntity
                    startingColumn={this.props.startingColumn}
                    columnSpan={columnSpan}
                    startingRow={this.props.startingRow}
                    rowSpan={rowSpan}
                    onClick={this.getStageClickHandler()}
                    backgroundColor={bgColor}
                    borderColor={borderColor}
                >
                    <StageHeader isSetupMode={this.props.isSetupMode} isSmallStage={isSmallStage}>
                        <StageHeaderDescription>{this.getStageTitle()}</StageHeaderDescription>
                        <StageHeaderLabel isSmallStage={isSmallStage}>
                            {this.getStageLabel()}
                        </StageHeaderLabel>
                    </StageHeader>
                    {this.renderStage()}
                </DiagramEntity>
            </React.Fragment>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
    });

export default connect(mapStateToProps)(injectIntl(DiagramStage));
