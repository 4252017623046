// @flow strict

import React, { type Node } from 'react';

// Styles
import { SidebarTabHeader, SidebarTabTitle, StyledSidebarTabBody } from './styles';

type Props = {
    title: string,
    body: Node,
    active: boolean,
    handleOnHeaderClick: () => void,
    backgroundStyling?: boolean;
};

export default class SidebarTabInline extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <SidebarTabHeader
                    onClick={this.props.handleOnHeaderClick}
                    active={this.props.active}
                >
                    <SidebarTabTitle>{this.props.title}</SidebarTabTitle>
                </SidebarTabHeader>
                {this.props.active && this.props.body && (
                    <StyledSidebarTabBody backgroundStyling={!this.props.backgroundStyling}>{this.props.body}</StyledSidebarTabBody>
                )}
            </React.Fragment>
        );
    }
}
