// @flow strict

import React from 'react';

// Constants
import {
    REFRESH_FIREBASE_TOKEN_INTERVAL_VALUE,
    REFRESH_FIREBASE_TOKEN_INTERVAL_LIMIT,
} from 'utils/constants';

import { getFirebaseAuth } from 'utils/authentication';

/**
 * Provided we have a firebase user authenticated, refresh their token via getIdToken() on an interval
 * Stop refresh interval when count hits limit; this means if a user is on a page for too long (interval * limit) we no longer refresh their token
 */
class RefreshFirebaseToken extends React.PureComponent<{}> {
    componentDidMount() {
        this.intervalId = setInterval(
            this.refreshFirebaseToken,
            REFRESH_FIREBASE_TOKEN_INTERVAL_VALUE
        );
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    intervalId = null;

    intervalCount = 0;

    refreshFirebaseToken = () => {
        const currentUser = getFirebaseAuth().currentUser;
        if (currentUser && this.intervalCount < REFRESH_FIREBASE_TOKEN_INTERVAL_LIMIT) {
            currentUser
                // Refresh users token
                .getIdToken(true)
                .then((idToken: string) => {
                    localStorage.setItem('api_token', idToken);
                    this.intervalCount = this.intervalCount + 1;
                })
                .catch((error: {}) => {
                    throw new Error(error);
                });
        } else if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    };

    render() {
        return null;
    }
}

export default RefreshFirebaseToken;
