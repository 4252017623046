// @flow strict

import { fromJS } from 'immutable';

// Actions
import {
    IS_SUBMITTING,
    IS_UPDATING,
    SUBMIT_SUCCESS,
    SUBMIT_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    IS_FEEDBACK_SUBMITTING,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAILURE,
    RUN_RECOMMENDATIONS_SUCCESS,
    RUN_RECOMMENDATIONS_FAILURE,
    IS_RUNNING_RECOMMENDATIONS,
    SUBMIT_PLANT_DATASET_MESSAGE_SUCCESS,
    SUBMIT_PLANT_DATASET_MESSAGE_FAILURE,
    IS_SUBMITTING_PLANT_DATASET_MESSAGE,
    FETCH_CIRCUIT_RECOMMENDATION_FAILURE,
    FETCH_CIRCUIT_RECOMMENDATION_SUCCESS,
    IS_FETCHING_CIRCUIT_RECOMMENDATION,
    FETCH_PLANT_RECOMMENDATION_FAILURE,
    FETCH_PLANT_RECOMMENDATION_SUCCESS,
    IS_FETCHING_PLANT_RECOMMENDATION,
    IS_CALCULATING_RECOMMENDATION_IMPACT,
    CALCULATE_RECOMMENDATION_IMPACT_SUCCESS,
    CALCULATE_RECOMMENDATION_IMPACT_FAILURE,
} from './actions';

// Types
import type { GenericActionType, ImmutableList } from 'types';
import type {
    ImmutableRecommendationState,
    ImmutableRecommendationSet,
    ImmutablePlantDatasetMessage,
    ImmutableRecommendationImpactCalculation,
} from 'services/Recommendation/types';

const initialState: ImmutableRecommendationState = fromJS({
    isSubmitting: false,
    isUpdating: false,
    isFeedbackSubmitting: false,
    isRunningRecommendations: false,
    isSubmittingPlantDatasetMessage: false,

    isFetchingCircuitRecommendation: false,
    recommendationSets: [],

    isFetchingPlantRecommendation: false,
    plantDatasetMessages: [],

    isCalculatingRecommendationImpacts: {},
    calculatedRecommendationImpacts: [],

    errors: {},
});

const putRecommendationInState = (newRecommendationSet: ImmutableRecommendationSet) => (
    recommendationSets: ImmutableList<ImmutableRecommendationSet>
) => {
    const idx = recommendationSets.findIndex(
        (rSet: ImmutableRecommendationSet) => rSet.get('id') === newRecommendationSet.get('id')
    );
    return idx === -1
        ? recommendationSets.unshift(newRecommendationSet)
        : recommendationSets.setIn([idx], newRecommendationSet);
};

const putPlantRecommendationInState = (newRecommendationSet: ImmutablePlantDatasetMessage) => (
    recommendationSets: ImmutableList<ImmutablePlantDatasetMessage>
) => {
    const idx = recommendationSets.findIndex((rSet: ImmutablePlantDatasetMessage) => {
        if (rSet.has('id')) {
            return rSet.get('id') === newRecommendationSet.get('id');
        } else {
            return rSet.get('plantDatasetId') === newRecommendationSet.get('plantDatasetId');
        }
    });
    return idx === -1
        ? recommendationSets.unshift(newRecommendationSet)
        : recommendationSets.setIn([idx], newRecommendationSet);
};

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */
function recommendationServiceReducer(
    state: ImmutableRecommendationState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case IS_FEEDBACK_SUBMITTING:
            return state.set('isFeedbackSubmitting', action.payload.isFeedbackSubmitting);
        case IS_SUBMITTING:
            return state.set('isSubmitting', action.payload.isSubmitting);
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);

        case IS_RUNNING_RECOMMENDATIONS:
            return state.set('isRunningRecommendations', action.payload.isRunningRecommendations);

        case SUBMIT_FEEDBACK_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            return state
                .set('errors', fromJS({}))
                .set('isFeedbackSubmitting', false)
                .updateIn(['recommendationSets'], putRecommendationInState(newRecommendationSet));
        }
        case SUBMIT_FEEDBACK_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isFeedbackSubmitting', false);

        case SUBMIT_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            return state
                .set('errors', fromJS({}))
                .set('isSubmitting', false)
                .updateIn(['recommendationSets'], putRecommendationInState(newRecommendationSet));
        }
        case SUBMIT_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isSubmitting', false);

        case UPDATE_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            return state
                .set('errors', fromJS({}))
                .set('isUpdating', false)
                .updateIn(['recommendationSets'], putRecommendationInState(newRecommendationSet));
        }
        case UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isUpdating', false);

        case RUN_RECOMMENDATIONS_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isRunningRecommendations', false);

        case RUN_RECOMMENDATIONS_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            return state
                .set('errors', fromJS({}))
                .set('isRunningRecommendations', false)
                .updateIn(['recommendationSets'], putRecommendationInState(newRecommendationSet));
        }

        case FETCH_CIRCUIT_RECOMMENDATION_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isFetchingCircuitRecommendation', false);
        }
        case FETCH_CIRCUIT_RECOMMENDATION_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            return state
                .set('errors', fromJS({}))
                .set('isFetchingCircuitRecommendation', false)
                .updateIn(['recommendationSets'], putRecommendationInState(newRecommendationSet));
        }
        case IS_FETCHING_CIRCUIT_RECOMMENDATION: {
            return state
                .set('errors', fromJS({}))
                .set('isFetchingCircuitRecommendation', action.payload.isFetchingRecommendation);
        }

        case FETCH_PLANT_RECOMMENDATION_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isFetchingPlantRecommendation', false);
        }
        case FETCH_PLANT_RECOMMENDATION_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            return state
                .set('errors', fromJS({}))
                .set('isFetchingPlantRecommendation', false)
                .updateIn(
                    ['plantDatasetMessages'],
                    putPlantRecommendationInState(newRecommendationSet)
                );
        }
        case IS_FETCHING_PLANT_RECOMMENDATION: {
            return state
                .set('errors', fromJS({}))
                .set('isFetchingPlantRecommendation', action.payload.isFetchingRecommendation);
        }

        /**
         * Success action to submit a plant dataset message for the most recent plant dataset
         */
        case SUBMIT_PLANT_DATASET_MESSAGE_SUCCESS: {
            const newRecommendationSet = fromJS(action.payload.data);
            return state
                .updateIn(
                    ['plantDatasetMessages'],
                    putPlantRecommendationInState(newRecommendationSet)
                )
                .set('isSubmittingPlantDatasetMessage', false);
        }

        /**
         * Failure action to submit a plant dataset message for the most recent plant dataset
         */
        case SUBMIT_PLANT_DATASET_MESSAGE_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isSubmittingPlantDatasetMessage', false);

        /**
         * Set status action for when a plant dataset message is being submitted for the most recent plant dataset
         */
        case IS_SUBMITTING_PLANT_DATASET_MESSAGE:
            return state.set(
                'isSubmittingPlantDatasetMessage',
                action.payload.isSubmittingPlantDatasetMessage
            );

        case IS_CALCULATING_RECOMMENDATION_IMPACT:
            return state.setIn(
                ['isCalculatingRecommendationImpacts', action.payload.recommendationId],
                action.payload.isCalculatingRecommendationImpact
            );

        case CALCULATE_RECOMMENDATION_IMPACT_SUCCESS: {
            const impact = fromJS(action.payload.data);

            const cris = state.get('calculatedRecommendationImpacts');
            const criIdx = cris.findIndex(
                (cri: ImmutableRecommendationImpactCalculation) =>
                    cri.get('recommendationId') === impact.get('recommendationId')
            );
            if (criIdx === -1) {
                return state.set('calculatedRecommendationImpacts', cris.unshift(impact));
            } else {
                return state.setIn(['calculatedRecommendationImpacts', criIdx], impact);
            }
        }
        case CALCULATE_RECOMMENDATION_IMPACT_FAILURE: {
            const recommendationId = action.payload.recommendationId;
            const cris = state.get('calculatedRecommendationImpacts');
            const criIdx = cris.findIndex(
                (cri: ImmutableRecommendationImpactCalculation) =>
                    cri.get('recommendationId') === recommendationId
            );
            if (criIdx !== -1) {
                return state.removeIn(['calculatedRecommendationImpacts', criIdx]);
            }
            break;
        }

        default:
            return state;
    }
}

export default recommendationServiceReducer;
