// @flow strict

// Mimic Engine
import type { IMimicStream, ISkipStream } from 'components/_McCabeThiele';

import { PATH_TYPES, PORT_TYPES } from 'components/MimicDiagram/constants';
import { DIAGRAM_DISPLAY_MODES } from 'utils/constants';
import type {
    GridType,
    PathType,
    PortType,
    PortPosition,
    StreamPorts,
    AbsolutePosition,
    DiagramDisplayModesConstant,
} from 'components/MimicDiagram/types';

/**
 * @params {number} span the amount of columns you want to calculate the gaps in between
 * @params {number} gapSize gap size in pixels
 */
export const getGap = (span: number, gapSize: number) => Math.max(gapSize * (span - 1), 0);

/**
 * Calculates an offset for skip streams.
 * This takes into account the bleed/blend stream types.
 * @param {*} grid
 */
export const getSkipTopOffsetPosition = (grid: GridType) => {
    return (
        grid.SKIP.TOP_OFFSET * grid.GRID.ROW_HEIGHT +
        getGap(grid.SKIP.TOP_OFFSET + 1, grid.GRID.ROW_GAP)
    );
};

// Used in the calculation of feed/bleed offsets
const getPortXOffset = (
    grid: GridType,
    isNegative: boolean,
    isEdge: boolean
): $Shape<PortPosition> => ({
    x: (grid.STREAM_VALUES.COLUMN_SPAN / (isEdge ? 2 : 4)) * (isNegative ? -1 : 1),
    xGap: grid.GRID.COLUMN_GAP * 1.5 * (isNegative ? -1 : 1),
});

// Used in the calculation of feed/bleed offsets
const getPortYOffset = (grid: GridType, isNegative: boolean): $Shape<PortPosition> => ({
    y: (grid.STREAM_VALUES.ROW_SPAN + grid.STREAM_VALUES.FEED_BLEED_OFFSET) * (isNegative ? -1 : 1),
    yGap:
        ((grid.STREAM_VALUES.FEED_BLEED_OFFSET > 0 ? grid.GRID.ROW_GAP : 0) + grid.GRID.ROW_GAP) *
        (isNegative ? -1 : 1),
});

const getPortPosition = (portType: PortType, grid: GridType): PortPosition => {
    switch (portType) {
        case PORT_TYPES.TOP_LEFT: {
            return {
                x: 0,
                xGap: grid.GRID.STAGE_SIDE_MARGIN,
                y: grid.STAGE.ROW_SPAN * 0.25,
                yGap: getGap(grid.STAGE.ROW_SPAN * 0.25, grid.GRID.ROW_GAP) + grid.GRID.ROW_GAP / 2,
            };
        }
        case PORT_TYPES.TOP_RIGHT: {
            return {
                x: grid.STAGE.COLUMN_SPAN,
                xGap:
                    getGap(grid.STAGE.COLUMN_SPAN, grid.GRID.COLUMN_GAP) -
                    grid.GRID.STAGE_SIDE_MARGIN,
                y: grid.STAGE.ROW_SPAN * 0.25,
                yGap: getGap(grid.STAGE.ROW_SPAN * 0.25, grid.GRID.ROW_GAP) + grid.GRID.ROW_GAP / 2,
            };
        }
        case PORT_TYPES.TOP_CENTER: {
            return {
                x: grid.TANK.COLUMN_SPAN / 2,
                xGap: getGap(grid.TANK.COLUMN_SPAN, grid.GRID.COLUMN_GAP) / 2,
                y: 0,
                yGap: 0,
            };
        }
        case PORT_TYPES.BOTTOM_LEFT: {
            return {
                x: 0,
                xGap: grid.GRID.STAGE_SIDE_MARGIN,
                y: grid.STAGE.ROW_SPAN * 0.75,
                yGap: getGap(grid.STAGE.ROW_SPAN * 0.75, grid.GRID.ROW_GAP) + grid.GRID.ROW_GAP / 2,
            };
        }
        case PORT_TYPES.BOTTOM_RIGHT: {
            return {
                x: grid.STAGE.COLUMN_SPAN,
                xGap:
                    getGap(grid.STAGE.COLUMN_SPAN, grid.GRID.COLUMN_GAP) -
                    grid.GRID.STAGE_SIDE_MARGIN,
                y: grid.STAGE.ROW_SPAN * 0.75,
                yGap: getGap(grid.STAGE.ROW_SPAN * 0.75, grid.GRID.ROW_GAP) + grid.GRID.ROW_GAP / 2,
            };
        }
        case PORT_TYPES.TANK_BOTTOM_CENTER: {
            return {
                x: grid.TANK.COLUMN_SPAN / 2,
                xGap: getGap(grid.TANK.COLUMN_SPAN, grid.GRID.COLUMN_GAP) / 2,
                y: grid.TANK.ROW_SPAN,
                yGap: getGap(grid.TANK.ROW_SPAN, grid.GRID.ROW_GAP),
            };
        }
        case PORT_TYPES.WASHER_BOTTOM_CENTER: {
            return {
                x: grid.WASHER.COLUMN_SPAN / 2,
                xGap: getGap(grid.WASHER.COLUMN_SPAN, grid.GRID.COLUMN_GAP) / 2,
                y: grid.WASHER.ROW_SPAN,
                yGap: getGap(grid.WASHER.ROW_SPAN, grid.GRID.ROW_GAP),
            };
        }
        default:
            throw new Error(`Unknown port type ${portType}`);
    }
};

/**
 *
 * @param {PortType} portType The type of port this offset is for
 * @param {GridType} grid The grid used to calculate positions
 * @param {boolean} isEdge Is this port for an edge stream? if so the offset is calculated differently.
 */
const getPortOffset = (portType: PortType, grid: GridType, isEdge: boolean): PortPosition => {
    const isLeftOffset = portType === PORT_TYPES.TOP_LEFT || portType === PORT_TYPES.BOTTOM_LEFT;
    const isTopOffset =
        portType === PORT_TYPES.TOP_LEFT ||
        portType === PORT_TYPES.TOP_RIGHT ||
        portType === PORT_TYPES.TOP_CENTER;

    return {
        ...getPortXOffset(grid, isLeftOffset, isEdge),
        ...getPortYOffset(grid, isTopOffset),
    };
};

const getAbsolutePosition = (
    portPosition: PortPosition,
    grid: GridType,
    offset?: PortPosition
): AbsolutePosition => {
    const columnPosition = (portPosition.x + (offset ? offset.x : 0)) * grid.GRID.COLUMN_WIDTH;
    const totalColumnGap = portPosition.xGap + (offset ? offset.xGap : 0);
    const rowPosition = (portPosition.y + (offset ? offset.y : 0)) * grid.GRID.ROW_HEIGHT;
    const totalRowGap = portPosition.yGap + (offset ? offset.yGap : 0);
    return {
        x: columnPosition + totalColumnGap,
        y: rowPosition + totalRowGap,
    };
};

/**
 * Get the position of the ports relative to the column start and row start of the stage(s).
 * @param {PathType} pathType The type of path the stream is
 * @param {GridType} grid The grid used to calculate positions
 *
 * @returns {StreamPorts} Returns the ports used for this stream
 */
export const getPortPositions = (
    pathType: PathType,
    grid: GridType,
    displayMode: DiagramDisplayModesConstant
): StreamPorts => {
    switch (pathType) {
        case PATH_TYPES.BLEND_ADVANCED:
        case PATH_TYPES.BLEND: {
            // pls blend.
            const toPort = getPortPosition(PORT_TYPES.TOP_LEFT, grid);
            let portOffset = null;
            if (displayMode !== DIAGRAM_DISPLAY_MODES.SETUP) {
                portOffset = {
                    x: -grid.CONTINUE_BLEND_STREAM.COLUMN_SPAN / 2,
                    xGap:
                        -1 *
                        (getGap(grid.CONTINUE_BLEND_STREAM.COLUMN_SPAN / 2, grid.GRID.COLUMN_GAP) +
                            grid.GRID.COLUMN_GAP * 1.5 +
                            grid.GRID.STAGE_SIDE_MARGIN),
                    ...getPortYOffset(grid, true),
                };
            } else {
                portOffset = getPortOffset(
                    PORT_TYPES.TOP_LEFT,
                    grid,
                    displayMode === DIAGRAM_DISPLAY_MODES.SETUP
                );
            }
            return {
                from: getAbsolutePosition(toPort, grid, portOffset),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.RIGHT_FEED_EDGE:
        case PATH_TYPES.RIGHT_FEED: {
            const toPort = getPortPosition(PORT_TYPES.TOP_LEFT, grid);
            const portOffset = getPortOffset(PORT_TYPES.TOP_LEFT, grid, pathType.endsWith('_EDGE'));
            return {
                from: getAbsolutePosition(toPort, grid, portOffset),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.LEFT_BLEED_EDGE:
        case PATH_TYPES.LEFT_BLEED: {
            const fromPort = getPortPosition(PORT_TYPES.BOTTOM_LEFT, grid);
            const portOffset = getPortOffset(
                PORT_TYPES.BOTTOM_LEFT,
                grid,
                pathType.endsWith('_EDGE')
            );
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(fromPort, grid, portOffset),
            };
        }
        case PATH_TYPES.LEFT_FEED_EDGE:
        case PATH_TYPES.LEFT_FEED: {
            const toPort = getPortPosition(PORT_TYPES.BOTTOM_RIGHT, grid);
            const portOffset = getPortOffset(
                PORT_TYPES.BOTTOM_RIGHT,
                grid,
                pathType.endsWith('_EDGE')
            );
            return {
                from: getAbsolutePosition(toPort, grid, portOffset),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.RIGHT_BLEED_ADVANCED: {
            // pls bleed. without a PLS blend. but with a continue stream.
            const fromPort = getPortPosition(PORT_TYPES.TOP_RIGHT, grid);
            const portOffset = {
                x: 0,
                xGap: grid.GRID.COLUMN_GAP / 2 + grid.GRID.STAGE_SIDE_MARGIN,
                ...getPortYOffset(grid, true),
            };
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(fromPort, grid, portOffset),
            };
        }
        case PATH_TYPES.RIGHT_BLEED_EDGE: // the end bleed.
        case PATH_TYPES.RIGHT_BLEED: {
            // a new feed/skip bleed
            const fromPort = getPortPosition(PORT_TYPES.TOP_RIGHT, grid);
            const portOffset = getPortOffset(
                PORT_TYPES.TOP_RIGHT,
                grid,
                pathType.endsWith('_EDGE')
            );
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(fromPort, grid, portOffset),
            };
        }
        case PATH_TYPES.SKIP: // pls skip is like the right continue calcs
        case PATH_TYPES.RIGHT_CONTINUE: {
            const fromPort = getPortPosition(PORT_TYPES.TOP_RIGHT, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_LEFT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.LEFT_CONTINUE: {
            const fromPort = getPortPosition(PORT_TYPES.BOTTOM_LEFT, grid);
            const toPort = getPortPosition(PORT_TYPES.BOTTOM_RIGHT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.BOTTOM_CONTINUE: {
            const fromPort = getPortPosition(PORT_TYPES.BOTTOM_LEFT, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_LEFT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.TOP_CONTINUE_LONGER_TOP:
        case PATH_TYPES.TOP_CONTINUE_LONGER_BOTTOM:
        case PATH_TYPES.TOP_CONTINUE: {
            const fromPort = getPortPosition(PORT_TYPES.TOP_RIGHT, grid);
            const toPort = getPortPosition(PORT_TYPES.BOTTOM_RIGHT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        // Bypasses
        case PATH_TYPES.EXTRACT_BYPASS_BLEND:
        case PATH_TYPES.STRIP_BYPASS_BLEED:
        case PATH_TYPES.EXTRACT_BYPASS_FEED: {
            // TODO: Handle port position where it comes from null.
            const fromPort = getPortPosition(PORT_TYPES.TOP_RIGHT, grid);
            const toPort = getPortPosition(PORT_TYPES.BOTTOM_RIGHT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.STRIP_BYPASS_FEED: // bypass strip feed and bypass extract bleed use the same port locations
        case PATH_TYPES.EXTRACT_BYPASS_BLEED: {
            // TODO: Handle port position where it comes from null.
            const fromPort = getPortPosition(PORT_TYPES.BOTTOM_LEFT, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_LEFT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.EXTRACT_BYPASS_TO_ORGANIC_TANK_FIRST:
        case PATH_TYPES.EXTRACT_BYPASS_BLEED_TO_ORGANIC_TANK:
        case PATH_TYPES.EXTRACT_CONTINUE_TO_ORGANIC_TANK: {
            const fromPort = getPortPosition(PORT_TYPES.BOTTOM_LEFT, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_CENTER, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_BLEND:
        case PATH_TYPES.ORGANIC_TANK_BYPASS_FEED_TO_EXTRACT_FIRST:
        case PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED_FIRST:
        case PATH_TYPES.ORGANIC_TANK_BYPASS_FEED_TO_EXTRACT:
        case PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED: {
            const fromPort = getPortPosition(PORT_TYPES.TANK_BOTTOM_CENTER, grid);
            const toPort = getPortPosition(PORT_TYPES.BOTTOM_RIGHT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.ORGANIC_TANK_TO_ORGANIC_TANK_BYPASS_BLEED: {
            const fromPort = getPortPosition(PORT_TYPES.TANK_BOTTOM_CENTER, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_CENTER, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_STRIP:
        case PATH_TYPES.ORGANIC_TANK_TO_STRIP_BYPASS_FEED:
        case PATH_TYPES.ORGANIC_TANK_CONTINUE_TO_STRIP: {
            const fromPort = getPortPosition(PORT_TYPES.TANK_BOTTOM_CENTER, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_LEFT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        // Washers:
        case PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_WASHER: {
            const fromPort = getPortPosition(PORT_TYPES.TANK_BOTTOM_CENTER, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_CENTER, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.WASHER_TO_ORGANIC_TANK: {
            const fromPort = getPortPosition(PORT_TYPES.WASHER_BOTTOM_CENTER, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_CENTER, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.EXTRACT_TO_WASHER: {
            const fromPort = getPortPosition(PORT_TYPES.BOTTOM_LEFT, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_CENTER, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.WASHER_TO_STRIP: {
            const fromPort = getPortPosition(PORT_TYPES.WASHER_BOTTOM_CENTER, grid);
            const toPort = getPortPosition(PORT_TYPES.TOP_LEFT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.WASHER_TO_EXTRACT: {
            const fromPort = getPortPosition(PORT_TYPES.TOP_CENTER, grid);
            const toPort = getPortPosition(PORT_TYPES.BOTTOM_RIGHT, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        case PATH_TYPES.STRIP_TO_WASHER: {
            const fromPort = getPortPosition(PORT_TYPES.TOP_RIGHT, grid);
            const toPort = getPortPosition(PORT_TYPES.WASHER_BOTTOM_CENTER, grid);
            return {
                from: getAbsolutePosition(fromPort, grid),
                to: getAbsolutePosition(toPort, grid),
            };
        }
        default:
            throw new Error(`Unknown path type: ${pathType}`);
    }
};

export const getPosition = (
    columnStart: number,
    rowStart: number,
    portPosition: AbsolutePosition,
    grid: GridType
): AbsolutePosition => ({
    x:
        (columnStart - 1) * grid.GRID.COLUMN_WIDTH +
        getGap(columnStart, grid.GRID.COLUMN_GAP) +
        portPosition.x,
    y: (rowStart - 1) * grid.GRID.ROW_HEIGHT + getGap(rowStart, grid.GRID.ROW_GAP) + portPosition.y,
});

/**
 * Render path method return SVG path values for the format: L<number,number> [L<number,number>, L<number,number>]
 * @param {PathType} pathType The path type of the stream.
 * @param {GridType} grid The grid used to compute positions
 * @param {number} startX The start X pixel stream port
 * @param {number} startY The start Y pixel stream port
 * @param {number} width the width of the stream
 * @param {number} height the height of the stream
 * @param {number} lastStageColumn The starting column of the last stage
 * @param {number} endX the end x pixel of the stream port
 * @param {number} endY the end y pixel of the stream port
 * @param {boolean} isEdge Used to determine if a bypass bleed connects to the horizontal bypass bleed.
 *
 * @returns {string} a path string for the SVG PATH element.
 */
export const renderPath = (
    pathType: PathType,
    grid: GridType,
    startX: number,
    startY: number,
    width: number,
    height: number,
    lastStageColumn: number,
    endX: number,
    endY: number,
    isEdge: boolean, // only used for bypass bleeds.
    mimicStream: IMimicStream
): string => {
    const halfColumnWidth = grid.GRID.COLUMN_WIDTH / 2 + grid.GRID.COLUMN_GAP;
    const stageMargin = grid.GRID.STAGE_SIDE_MARGIN;

    const halfStreamValue =
        (grid.STREAM_VALUES.COLUMN_SPAN / 2) * grid.GRID.COLUMN_WIDTH + grid.GRID.COLUMN_GAP * 1.5;

    const bypassFeedOffset =
        grid.BYPASS_STREAM.FEED_VERTICAL_OFFSET * grid.GRID.ROW_HEIGHT +
        grid.BYPASS_STREAM.FEED_VERTICAL_OFFSET * grid.GRID.ROW_GAP;
    const bypassBleedOffset =
        grid.BYPASS_STREAM.BLEED_VERTICAL_OFFSET * grid.GRID.ROW_HEIGHT +
        getGap(grid.BYPASS_STREAM.BLEED_VERTICAL_OFFSET + 1, grid.GRID.ROW_GAP) +
        grid.GRID.ROW_GAP;

    const organicTankBypassFeedHorizontalOffset =
        grid.TANK_BYPASS_STREAM.FEED_HORIZONTAL_OFFSET * grid.GRID.COLUMN_WIDTH -
        getGap(grid.TANK_BYPASS_STREAM.FEED_HORIZONTAL_OFFSET, grid.GRID.COLUMN_GAP) +
        grid.GRID.COLUMN_GAP;

    switch (pathType) {
        case PATH_TYPES.RIGHT_FEED_EDGE:
        case PATH_TYPES.RIGHT_FEED: {
            return `L${startX},${startY + height} L${endX},${endY}`;
        }
        case PATH_TYPES.RIGHT_BLEED_ADVANCED:
        case PATH_TYPES.RIGHT_BLEED_EDGE:
        case PATH_TYPES.RIGHT_BLEED: {
            return `L${startX + width},${startY} L${endX},${endY}`;
        }
        case PATH_TYPES.LEFT_FEED_EDGE:
        case PATH_TYPES.LEFT_FEED: {
            return `L${startX},${startY - height} L${endX},${endY}`;
        }
        case PATH_TYPES.LEFT_BLEED_EDGE:
        case PATH_TYPES.LEFT_BLEED: {
            return `L${startX - width},${startY} L${endX},${endY}`;
        }
        case PATH_TYPES.BLEND_ADVANCED:
        case PATH_TYPES.BLEND: {
            // pls blend
            return `L${startX},${endY}`;
        }
        case PATH_TYPES.SKIP: {
            const skipOffset = getSkipTopOffsetPosition(grid);
            // $FlowIgnore -- we know that the mimic stream will be a skip stream because of the path type case
            const skipStream: ISkipStream = mimicStream;
            const nestedSkipStreams = skipStream.getSkipStreamCountBelow();
            const totalSkipOffset = skipOffset * nestedSkipStreams;

            const stageYOffset = grid.SKIP.STAGE_Y_OFFSET * grid.GRID.ROW_HEIGHT;

            return `
                L${startX + halfColumnWidth},${startY}
                L${startX + halfColumnWidth},${startY -
                totalSkipOffset -
                grid.GRID.ROW_GAP / 2 -
                stageYOffset}
                L${startX + width - halfColumnWidth},${startY -
                totalSkipOffset -
                grid.GRID.ROW_GAP / 2 -
                stageYOffset}
                L${startX + width - halfColumnWidth},${startY}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.LEFT_CONTINUE:
        case PATH_TYPES.RIGHT_CONTINUE: {
            return `L${endX},${endY}`;
        }
        case PATH_TYPES.BOTTOM_CONTINUE: {
            const leftPosition = endX > startX ? startX : endX;
            return `
                L${leftPosition - halfStreamValue - stageMargin},${startY}
                L${leftPosition - halfStreamValue - stageMargin},${endY}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.TOP_CONTINUE: {
            return `
                L${startX + halfStreamValue + stageMargin},${startY}
                L${startX + halfStreamValue + stageMargin},${endY}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.TOP_CONTINUE_LONGER_TOP: {
            return `
                L${startX + halfStreamValue + stageMargin},${startY}
                L${startX + halfStreamValue + stageMargin},${endY}
                L${startX - width},${endY}
            `;
        }
        case PATH_TYPES.TOP_CONTINUE_LONGER_BOTTOM: {
            return `
                L${startX + halfStreamValue + stageMargin + width},${startY}
                L${startX + halfStreamValue + stageMargin + width},${endY}
                L${endX},${endY}
            `;
        }
        // Bypasses
        case PATH_TYPES.EXTRACT_BYPASS_FEED: {
            const endStageColumn = lastStageColumn + grid.STAGE.COLUMN_SPAN - 1;
            const endingXPosition =
                endStageColumn * grid.GRID.COLUMN_WIDTH +
                getGap(endStageColumn, grid.GRID.COLUMN_GAP) +
                halfStreamValue;

            return `
                M${endingXPosition},${startY - height + bypassFeedOffset}
                L${endX + stageMargin + halfColumnWidth},${startY - height + bypassFeedOffset}
                L${endX + stageMargin + halfColumnWidth},${endY}
                L${endX},${endY}`;
        }
        case PATH_TYPES.EXTRACT_BYPASS_BLEED: {
            const streamValueWidth = grid.BYPASS_STREAM.COLUMN_SPAN * grid.GRID.COLUMN_WIDTH;
            const streamValueGap =
                getGap(grid.BYPASS_STREAM.COLUMN_SPAN, grid.GRID.COLUMN_GAP) +
                grid.GRID.COLUMN_GAP / 2;

            const bypassEdge =
                (grid.STREAM_VALUES.COLUMN_SPAN / 2) * grid.GRID.COLUMN_WIDTH +
                getGap(grid.STREAM_VALUES.COLUMN_SPAN, grid.GRID.COLUMN_GAP);

            return `
                L${startX -
                stageMargin -
                streamValueWidth -
                streamValueGap -
                grid.GRID.COLUMN_GAP},${startY}
                L${startX -
                stageMargin -
                streamValueWidth -
                streamValueGap -
                grid.GRID.COLUMN_GAP},${startY + bypassBleedOffset}
                ${isEdge
                    ? `L${bypassEdge - grid.GRID.COLUMN_GAP / 2},${startY + bypassBleedOffset}`
                    : ''
                }`;
        }
        case PATH_TYPES.EXTRACT_BYPASS_BLEND: {
            const endStageColumn = lastStageColumn + grid.STAGE.COLUMN_SPAN - 1;
            const endingXPosition =
                getGap(endStageColumn, grid.GRID.COLUMN_GAP) +
                endStageColumn * grid.GRID.COLUMN_WIDTH +
                halfStreamValue;

            const blendSpan = grid.CONTINUE_BLEND_STREAM.COLUMN_SPAN / 2;
            const halfBlendStreamValue =
                blendSpan * grid.GRID.COLUMN_WIDTH + grid.GRID.COLUMN_GAP * 2.5 + stageMargin;

            return `
                M${endingXPosition},${endY + bypassFeedOffset}
                L${endX + halfBlendStreamValue},${endY + bypassFeedOffset}
                L${endX + halfBlendStreamValue},${endY}`;
        }
        case PATH_TYPES.ORGANIC_TANK_TO_STRIP_BYPASS_FEED:
        case PATH_TYPES.STRIP_BYPASS_FEED: {
            const bypassEdge =
                (grid.STREAM_VALUES.COLUMN_SPAN / 2) * grid.GRID.COLUMN_WIDTH +
                getGap(grid.STREAM_VALUES.COLUMN_SPAN, grid.GRID.COLUMN_GAP);

            return `
                M${bypassEdge - grid.GRID.COLUMN_GAP / 2},${startY + height - bypassFeedOffset}
                L${endX - stageMargin - halfColumnWidth},${startY + height - bypassFeedOffset}
                L${endX - stageMargin - halfColumnWidth},${startY + height}
                L${endX},${endY}`;
        }
        case PATH_TYPES.STRIP_BYPASS_BLEED: {
            const endStageColumn = lastStageColumn + grid.STAGE.COLUMN_SPAN - 1;
            const endingXPosition =
                getGap(endStageColumn, grid.GRID.COLUMN_GAP) +
                endStageColumn * grid.GRID.COLUMN_WIDTH +
                halfStreamValue;

            const streamValueWidth = grid.BYPASS_STREAM.COLUMN_SPAN * grid.GRID.COLUMN_WIDTH;
            const streamValueGap =
                getGap(grid.BYPASS_STREAM.COLUMN_SPAN, grid.GRID.COLUMN_GAP) +
                grid.GRID.COLUMN_GAP / 2;

            return `
                L${startX +
                stageMargin +
                streamValueWidth +
                streamValueGap +
                grid.GRID.COLUMN_GAP},${startY}
                L${startX +
                stageMargin +
                streamValueWidth +
                streamValueGap +
                grid.GRID.COLUMN_GAP},${startY - bypassBleedOffset}
                ${isEdge ? `L${endingXPosition},${startY - bypassBleedOffset}` : ''}`;
        }

        // Streams for Organic Tanks:
        case PATH_TYPES.EXTRACT_BYPASS_TO_ORGANIC_TANK_FIRST:
        case PATH_TYPES.EXTRACT_CONTINUE_TO_ORGANIC_TANK: {
            return `
                L${startX - width},${startY}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.ORGANIC_TANK_CONTINUE_TO_STRIP: {
            return `
                L${startX},${startY + height}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.EXTRACT_BYPASS_BLEED_TO_ORGANIC_TANK: {
            const streamValueWidth = grid.TANK_BYPASS_STREAM.COLUMN_SPAN * grid.GRID.COLUMN_WIDTH;
            const streamValueGap =
                getGap(grid.TANK_BYPASS_STREAM.COLUMN_SPAN, grid.GRID.COLUMN_GAP) +
                grid.GRID.COLUMN_GAP / 2;

            const bypassVerticalBleedOffset =
                grid.TANK_BYPASS_STREAM.BLEED_VERTICAL_OFFSET * grid.GRID.ROW_HEIGHT +
                getGap(grid.TANK_BYPASS_STREAM.BLEED_VERTICAL_OFFSET + 1, grid.GRID.ROW_GAP) +
                grid.GRID.ROW_GAP;

            return `
                L${startX -
                stageMargin -
                streamValueWidth -
                streamValueGap -
                grid.GRID.COLUMN_GAP},${startY}
                L${startX -
                stageMargin -
                streamValueWidth -
                streamValueGap -
                grid.GRID.COLUMN_GAP},${startY + bypassVerticalBleedOffset}
                L${endX},${startY + bypassVerticalBleedOffset}
            `;
        }

        case PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED_FIRST: {
            const bypassVerticalFeedOffset =
                grid.TANK_BYPASS_STREAM.FEED_VERTICAL_OFFSET * grid.GRID.ROW_HEIGHT +
                getGap(grid.TANK_BYPASS_STREAM.FEED_VERTICAL_OFFSET + 1, grid.GRID.ROW_GAP);

            return `
                L${startX},${startY + grid.GRID.ROW_HEIGHT / 2 + grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${startY +
                grid.GRID.ROW_HEIGHT / 2 +
                grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${endY +
                bypassVerticalFeedOffset}
                L${endX + stageMargin + halfColumnWidth},${endY + bypassVerticalFeedOffset}
                L${endX + stageMargin + halfColumnWidth},${endY}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_BLEND: {
            const blendSpan = grid.CONTINUE_BLEND_STREAM.COLUMN_SPAN / 2;
            const halfBlendStreamValue =
                blendSpan * grid.GRID.COLUMN_WIDTH + grid.GRID.COLUMN_GAP * 2.5 + stageMargin;

            const bypassVerticalFeedOffset =
                grid.TANK_BYPASS_STREAM.FEED_VERTICAL_OFFSET * grid.GRID.ROW_HEIGHT +
                getGap(grid.TANK_BYPASS_STREAM.FEED_VERTICAL_OFFSET + 1, grid.GRID.ROW_GAP);

            return `
                L${startX},${startY + grid.GRID.ROW_HEIGHT / 2 + grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${startY +
                grid.GRID.ROW_HEIGHT / 2 +
                grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${endY +
                bypassVerticalFeedOffset}
                L${endX + halfBlendStreamValue},${endY + bypassVerticalFeedOffset}
                L${endX + halfBlendStreamValue},${endY}
            `;
        }
        case PATH_TYPES.ORGANIC_TANK_TO_EXTRACT_BYPASS_FEED: {
            const bypassVerticalFeedOffset =
                grid.TANK_BYPASS_STREAM.FEED_VERTICAL_OFFSET * grid.GRID.ROW_HEIGHT +
                getGap(grid.TANK_BYPASS_STREAM.FEED_VERTICAL_OFFSET + 1, grid.GRID.ROW_GAP);

            return `
                L${startX},${startY + grid.GRID.ROW_HEIGHT / 2 + grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${startY +
                grid.GRID.ROW_HEIGHT / 2 +
                grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${endY +
                bypassVerticalFeedOffset}
                L${endX + stageMargin + halfColumnWidth},${endY + bypassVerticalFeedOffset}
                L${endX + stageMargin + halfColumnWidth},${endY}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_STRIP: {
            const bypassEdge =
                (grid.STREAM_VALUES.COLUMN_SPAN / 2) * grid.GRID.COLUMN_WIDTH +
                getGap(grid.STREAM_VALUES.COLUMN_SPAN, grid.GRID.COLUMN_GAP) / 2;
            const verticalOffsetBelowTank = startY + grid.GRID.ROW_HEIGHT / 2 + grid.GRID.ROW_GAP;
            return `
                L${startX},${startY + grid.GRID.ROW_HEIGHT / 2 + grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${verticalOffsetBelowTank}
                L${bypassEdge},${verticalOffsetBelowTank}
            `;
        }
        case PATH_TYPES.ORGANIC_TANK_BYPASS_BLEED_TO_WASHER:
        case PATH_TYPES.ORGANIC_TANK_TO_ORGANIC_TANK_BYPASS_BLEED: {
            const bypassBleedHorizontalOffset =
                grid.TANK.COLUMN_SPAN * grid.GRID.COLUMN_WIDTH +
                getGap(grid.TANK.COLUMN_SPAN + 1, grid.GRID.COLUMN_GAP);
            const bypassBleedVerticalOffsetToStage =
                grid.TANK_BYPASS_STREAM.BLEED_VERTICAL_OFFSET_TO_TANK * grid.GRID.ROW_HEIGHT;
            return `
                L${startX},${startY + grid.GRID.ROW_HEIGHT / 2 + grid.GRID.ROW_GAP}
                L${startX - organicTankBypassFeedHorizontalOffset},${startY +
                grid.GRID.ROW_HEIGHT / 2 +
                grid.GRID.ROW_GAP}
                L${endX + bypassBleedHorizontalOffset},${startY +
                grid.GRID.ROW_HEIGHT / 2 +
                grid.GRID.ROW_GAP}
                L${endX + bypassBleedHorizontalOffset},${endY - bypassBleedVerticalOffsetToStage}
                ${isEdge ? `L${endX},${endY - bypassBleedVerticalOffsetToStage}` : ''}
            `;
        }
        // Washers:
        case PATH_TYPES.WASHER_TO_ORGANIC_TANK: {
            return `
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.EXTRACT_TO_WASHER: {
            return `
                L${startX - width},${startY}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.WASHER_TO_STRIP: {
            return `
                L${startX},${startY + height}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.WASHER_TO_EXTRACT: {
            return `
                L${startX},${startY - height}
                L${endX},${endY}
            `;
        }
        case PATH_TYPES.STRIP_TO_WASHER: {
            return `
                L${startX + width},${startY}
                L${endX},${endY}
            `;
        }

        default:
            console.error(`Render path wrong path type: ${pathType}`);
            return '';
    }
};
