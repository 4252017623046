// @flow

import React from 'react';

import type { InputValueType } from '../../types';

import { StyledInputValue as InputValue } from '../input-field';

// Styles
import { Textarea } from './styles';

type Props = {
    disabled?: boolean,
    id?: number,
    key?: ?string,
    name?: string,
    placeholder?: string,
    renderString?: boolean,
    unit?: ?string,
    value?: InputValueType,
};

export default class TextField extends React.PureComponent<Props> {
    static defaultProps = {
        disabled: false,
        id: null,
        key: null,
        name: 'TextField',
        placeholder: '',
        renderString: false,
        unit: null,
        value: '',
    };

    render() {
        if (this.props.renderString) {
            return (
                <InputValue>
                    {this.props.value}
                    {this.props.unit}
                </InputValue>
            );
        }

        return (
            <Textarea
                key={this.props.key || this.props.name || this.props.id}
                value={this.props.value}
                disabled={this.props.disabled && this.props.disabled !== false}
                placeholder={this.props.placeholder}
                {...this.props}
            />
        );
    }
}
