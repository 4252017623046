// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { SidebarSectionWrapper, SectionTitle, SectionBody } from '../styles';
import { DateSection, DateInputSection, DateLabel, DateInput } from './styles';

// Components
import { RadioButtonSet } from 'components/_ReactUI_V1';

// types
import type { IntlType } from 'types';

import { type DateRangeStatus, DATE_RANGE_RADIO_STATUS } from '..';

type Props = {
    intl: IntlType,

    disabled: boolean,
    radioStatus: DateRangeStatus,
    from: ?string,
    to: ?string,

    onRadioClick: (radioStatus: DateRangeStatus) => void,
    onFromChange: (newFrom: string) => void,
    onToChange: (newTo: string) => void,
};

class DateRangeSection extends React.PureComponent<Props> {
    getTranslation = (id: string) => {
        return this.props.intl.formatMessage({
            id: `components.ArchiveSidebar.DateRangeSection.${id}`,
        });
    };

    renderDateInputs = (value: DateRangeStatus) => {
        if (value !== DATE_RANGE_RADIO_STATUS.RANGED) {
            return null; // nothing is rendered if not ranged.
        }
        const isActive = this.props.radioStatus === DATE_RANGE_RADIO_STATUS.RANGED;
        const today = new Date();
        const month = `${today.getMonth() + 1}`.padStart(2, '0');
        const date = `${today.getDate()}`.padStart(2, '0');
        const maxDate = `${today.getFullYear()}-${month}-${date}`;
        return (
            <DateSection>
                <DateInputSection>
                    <DateLabel>{this.getTranslation('DateLabel.start')}</DateLabel>
                    <DateInput
                        type="date"
                        max={maxDate}
                        value={this.props.from || ''}
                        disabled={this.props.disabled || !isActive}
                        onChange={this.props.onFromChange}
                    />
                </DateInputSection>
                <DateInputSection>
                    <DateLabel>{this.getTranslation('DateLabel.end')}</DateLabel>
                    <DateInput
                        type="date"
                        min={this.props.from || ''}
                        max={maxDate}
                        value={this.props.to || ''}
                        disabled={this.props.disabled || !isActive}
                        onChange={this.props.onToChange}
                    />
                </DateInputSection>
            </DateSection>
        );
    };

    renderRadioOptions = () => (
        <RadioButtonSet
            onClick={this.props.onRadioClick}
            options={[
                {
                    label: this.getTranslation('RadioOptions.All'),
                    value: DATE_RANGE_RADIO_STATUS.ALL,
                },
                {
                    label: this.getTranslation('RadioOptions.Ranged'),
                    value: DATE_RANGE_RADIO_STATUS.RANGED,
                },
            ]}
            disabled={this.props.disabled}
            value={this.props.radioStatus}
            renderSuffix={this.renderDateInputs}
        />
    );

    render() {
        return (
            <SidebarSectionWrapper>
                <SectionTitle>{this.getTranslation('title')}</SectionTitle>
                <SectionBody>{this.renderRadioOptions()}</SectionBody>
            </SidebarSectionWrapper>
        );
    }
}

export default injectIntl(DateRangeSection);
