// @flow strict

// Constants
import {
    STREAM_VALUE_TYPES,
    DATASET_VALUE_TYPES,
    STAGE_VALUE_TYPES,
    PLANT_VALUE_TYPES,
} from 'utils/constants';

export const DEFAULT_PRECISION = 2;

export const TREND_INDICATOR_TYPES = {
    UNCHANGED: 'UNCHANGED',
    INCREASING: 'INCREASING',
    DECREASING: 'DECREASING',
};

/**
 * This array of arrays defines all the KPIs that can be overlaid together in the minor KPI graph.
 */
export const KPI_OVERLAY_OPTIONS = [
    [
        DATASET_VALUE_TYPES.ELECTROLYTE_MASS_BALANCE,
        DATASET_VALUE_TYPES.PLS_MASS_BALANCE,
        DATASET_VALUE_TYPES.ORGANIC_MASS_BALANCE,
    ],
    [STAGE_VALUE_TYPES.PRIMARY_MIXER_RETENTION_TIME],
    [STAGE_VALUE_TYPES.SETTLER_SPECIFIC_FLOW],
    [
        STAGE_VALUE_TYPES.SETTLER_ORGANIC_PHASE_VELOCITY,
        STAGE_VALUE_TYPES.SETTLER_AQUEOUS_PHASE_VELOCITY,
    ],
    [
        STAGE_VALUE_TYPES.SETTLER_ORGANIC_RETENTION_TIME,
        STAGE_VALUE_TYPES.SETTLER_AQUEOUS_RETENTION_TIME,
    ],
];

export const UNIVERSAL_KPI_OVERLAY_OPTIONS = [
    // STREAM_VALUE_TYPES.PLS, // This is disabled because a lot of work must be done to normalize the graphs
];

export const TARGET_TYPES = {
    MAIN_TARGET: 'mainTarget',
    LOW_TARGET: 'lowTarget',
    HIGH_TARGET: 'highTarget',
};

export const KPI_INVALID_VALUE_REASONS = {
    BELOW_MIN: 'BELOW_MIN',
    ABOVE_MAX: 'ABOVE_MAX',
    OUTSIDE_MIN_AND_MAX: 'OUTSIDE_MIN_AND_MAX',
    ZERO_OR_NULL: 'ZERO_OR_NULL'
};
