// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { ToolTip, DotMenu, LegacyTheme, Table } from 'components/_ReactUI_V1';

import KPIValue from 'components/KPIValue';

// Constants
import {
    CIRCUIT_ELEVATION_STEPS,
    DATASET_VALUE_TYPES,
    NAVIGATION_ROUTES,
    NO_RESULT_STRING,
} from 'utils/constants';

// Services
import { downloadTemplate } from 'services/Plant/thunks';
import { newFeedback } from 'services/Feedback/thunks';

// Helpers
import { isSysAdmin } from 'utils/authentication';
import { SolvExtractNavigation } from 'utils/SolvExtractNavigation';

// Styles
import { Wrapper, RowExtraWrapper, MenuToolTip, MenuToolTipContent, ToolTipButton } from './styles';

// Types
import type { HistoryType, IntlType, ImmutableList } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { FeedbackType } from 'services/Feedback/types';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    isFetching: boolean,
    plantId: number,
    circuits: ImmutableList<ImmutableCircuit>,
    history: HistoryType,
    newFeedback: (feedbackType: FeedbackType, message: string, timer?: number) => void,
    downloadTemplate: (plantId: number, fileName: string) => void,
};

class SolvExtractCircuitTable extends React.PureComponent<Props> {
    STEP_KEY = CIRCUIT_ELEVATION_STEPS.DONE;

    handleDownloadClicked = () => () => {
        this.props.newFeedback(
            'INFO',
            this.props.intl.formatMessage({
                id: 'feedback.success.downloadPlantTemplateDownloading',
            }),
            3000
        );
        this.props.downloadTemplate(
            this.props.plantId,
            this.getDownloadTranslation('download.FileName', {
                plantId: this.props.plantId,
            })
        );
    };

    handleOnClickTrends = (circuitId: number) => () =>
        this.props.history.push(
            SolvExtractNavigation.getCircuitDashboardUrl(this.props.plantId, circuitId)
        );

    handleOnClickArchive = (circuitId: number) => () =>
        this.props.history.push(
            `${NAVIGATION_ROUTES.CIRCUIT}${circuitId}${NAVIGATION_ROUTES.ARCHIVE}`
        );

    handleOnClickElevate = (circuitId: number) => () =>
        this.props.history.push(
            SolvExtractNavigation.getPlantEditorUrl(this.props.plantId, circuitId)
        );

    getTranslation = (key: string) =>
        this.props.intl.formatMessage({
            id: `components.SolvExtractDashboard.${key}`,
        });

    getDownloadTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.CircuitElevation.${this.STEP_KEY}.${id}`,
            },
            data
        );

    getTableHeader = () =>
        [
            { id: 'name', label: this.getTranslation('Header.name'), sortable: false },
            { id: 'cuRecovery', label: this.getTranslation('Header.cuRecovery'), sortable: false },
            { id: 'cuTransfer', label: this.getTranslation('Header.cuTransfer'), sortable: false },
            isSysAdmin(this.props.user) && { id: 'extra', label: '', sortable: false },
        ]
            .filter(Boolean) // Filters out null/false values
            .map((header) => ({
                ...header,
                styles: {
                    backgroundColor: LegacyTheme.white,
                    color: LegacyTheme.black,
                    fontSize: '13px',
                    height: 'auto',
                },
            }));

    getKPIValueByType = (circuit: ImmutableCircuit, kpiType: string) => {
        const majorKpiTrendData = circuit.get('majorKpiTrendData');
        if (!majorKpiTrendData) {
            throw new Error(
                'Circuit must have KPISetting to be a SolvExtract Circuit, something is wrong'
            );
        }

        const kpi = majorKpiTrendData.find(
            (k: ImmutableKPISetting) => k.getIn(['kpiSetting', 'kpiType']) === kpiType
        );

        if (!kpi) {
            return this.props.intl.formatMessage({
                id: 'components.TrendsPageDashboard.valueIsNA',
            });
        }

        return (
            <KPIValue
                kpiCard={kpi}
                noValueText={this.props.intl.formatMessage({
                    id: 'components.TrendsPageDashboard.valueIsNA',
                })}
            />
        );
    };

    getRowExtra = (circuit: ImmutableCircuit) => (
        <RowExtraWrapper>
            <MenuToolTip>
                <ToolTip
                    content={
                        <MenuToolTipContent>
                            <ToolTipButton onClick={this.handleOnClickArchive(circuit.get('id'))}>
                                {this.getTranslation('archive')}
                            </ToolTipButton>
                            <ToolTipButton onClick={this.handleDownloadClicked()}>
                                {this.props.intl.formatMessage({
                                    id: `components.CircuitElevation.DONE.download.Label`,
                                })}
                            </ToolTipButton>
                            {isSysAdmin(this.props.user) && (
                                <ToolTipButton
                                    onClick={this.handleOnClickElevate(circuit.get('id'))}
                                >
                                    {this.getTranslation('Menu.edit')}
                                </ToolTipButton>
                            )}
                        </MenuToolTipContent>
                    }
                    position="bottom"
                    triggerType="click"
                    trigger={<DotMenu />}
                    interactive
                />
            </MenuToolTip>
        </RowExtraWrapper>
    );

    getTableRows() {
        return this.props.circuits
            .map((circuit: ImmutableCircuit) => ({
                id: circuit.get('id'),
                name: circuit.get('name'),
                plantName: circuit.getIn(['plant', 'name']) || NO_RESULT_STRING,
                clientName: circuit.getIn(['plant', 'client', 'name']) || NO_RESULT_STRING,
                cuRecovery: this.getKPIValueByType(circuit, DATASET_VALUE_TYPES.OVERALL_RECOVERY),
                cuTransfer: this.getKPIValueByType(circuit, DATASET_VALUE_TYPES.CU_TRANSFERRED),
                extra: this.getRowExtra(circuit),
                onClick: this.handleOnClickTrends(circuit.get('id')),
            }))
            .toArray();
    }

    render() {
        const rows = this.getTableRows();

        return (
            <Wrapper>
                <Table
                    gridStyling={this.props.gridStyling}
                    header={this.getTableHeader()}
                    loading={this.props.isFetching}
                    rows={rows}
                    tdMaxWidth="500px"
                    tdVerticalAlign="middle"
                    tdWordBreak="break-word"
                    tdFontSize="13px"
                    tdPadding="5px 10px"
                    tdHeight="39px"
                    trBackgroundColor={LegacyTheme.tableHeader}
                    trBackgroundColorHover={LegacyTheme.defaultBorder}
                    footerMessage={
                        !rows.length && !this.props.isFetching
                            ? this.getTranslation('noRows')
                            : null
                    }
                />
            </Wrapper>
        );
    }
}

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            newFeedback,
            downloadTemplate,
        },
        dispatch
    );

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(injectIntl(SolvExtractCircuitTable))
);
