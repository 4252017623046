/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

export const StreamValuesWrapper = styled.div`
    grid-column-start: ${({ startingColumn }: Object) => startingColumn};
    grid-column-end: span ${({ columnSpan }: Object) => columnSpan};
    grid-row-start: ${({ startingRow }: Object) => startingRow};
    grid-row-end: span ${({ rowSpan }: Object) => rowSpan};
    display: flex;
    align-items: center;
    position: relative;

    z-index: 2;
`;
