// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import { TextField, InputSelect, InputNumber } from 'components/_ReactUI_V1';

import CircleDeleteIcon from 'assets/icons/circle-delete-icon';
import AddIcon from 'assets/icons/add-icon';

// Styles
import {
    RecommendationItemListWrapper,
    RecommendationItemListUL,
    RecommendationItemInputRow,
    RecommendationItemInputWrapper,
    AddItemButton,
    AddItemButtonText,
    DeleteButtonWrapper,
} from './styles';
import { Note } from 'styles/common';

// Constants & helpers
import { STYLE_VALUES, NUMBER_INPUT_PLACEHOLDER } from 'utils/constants';
import { RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION } from 'utils/recommendationConstants';
import { tryParseNumberOrNull, clamp, getStreamById } from 'utils/helpers';
import {
    getKPISettingUnit,
    getKPIInvalidValueReason,
    getRecommendableKPIs,
} from 'utils/kpiHelpers';
import { getRecommendationItemFromRecommendationType } from 'utils/recommendationHelpers';

// Types
import type { IntlType, ImmutableList, InputEvent, ImmutableMap } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { ImmutableKPISetting, KPIInvalidValueReasonType } from 'services/KPISetting/types';
import type { ImmutableRecommendationItem } from 'services/Recommendation/types';

const CUSTOM_MESSAGE_ID = 'CUSTOM';

type Props = {
    intl: IntlType,
    isUserPM: boolean,

    kpiSettings: ImmutableList<ImmutableKPISetting>,
    circuit: ImmutableCircuit,
    items: ImmutableList<ImmutableRecommendationItem>,
    readOnly?: boolean,

    onChangeItemToCustom?: ?(itemId: number) => void,
    onChangeItemKPI?: ?(itemId: number, newKPIId: number) => void,
    onChangeItemValue?: ?(itemId: number, newValue: number | string, isSetToValue: boolean) => void,
    onAddItem?: ?() => void,
    onRemoveItem?: ?(itemId: number) => void,

    onInvalidValueCheck: (value: boolean) => void,
};

type State = {
    // Where the key is the item id & the value is if the value is invalid (not between recommended values)
    invalidReasons: ImmutableMap<string, { [key: number]: KPIInvalidValueReasonType }>,
};

/**
 * Recommendation Sidebar component
 */
class RecommendationItemList extends React.PureComponent<Props, State> {
    static defaultProps = {
        readOnly: true,
        onChangeItemToCustom: null,
        onChangeItemKPI: null,
        onChangeItemValue: null,
        onAddItem: null,
        onRemoveItem: null,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            invalidReasons: fromJS({}),
        };
    }

    // eslint-disable-next-line flowtype/no-weak-types
    getComponentTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.RecommendationCard.${id}`,
            },
            data
        );

    // eslint-disable-next-line flowtype/no-weak-types
    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id,
            },
            data
        );

    getRecommendableKPIs = () => getRecommendableKPIs(this.props.circuit, this.props.kpiSettings);

    wasKPISelected = (kpi: ImmutableKPISetting) =>
        Boolean(
            this.props.items.find(
                (existingItem: ImmutableRecommendationItem) =>
                    existingItem.get('toKpiId') === kpi.get('id')
            )
        );

    handleChangeItemValue = (item: ImmutableRecommendationItem, shouldClamp: boolean = false) => (
        event: InputEvent
    ) => {
        const itemId = item.get('id');

        let value = event.target.value;
        if (!this.props.onChangeItemValue) {
            return;
        }
        let isSetToValue = false;
        if (
            RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION.indexOf(item.get('recommendationType')) ===
            -1
        ) {
            if (item.get('comment') !== value) {
                this.props.onChangeItemValue(itemId, value, isSetToValue);
            }
            return;
        }

        value = tryParseNumberOrNull(value);

        isSetToValue = true;
        const min = tryParseNumberOrNull(event.target.min);
        const max = tryParseNumberOrNull(event.target.max);

        if (item.get('toKpiId') !== null && shouldClamp) {
            value = clamp(value, min, max);
        }

        // Validate the run-time value (possibly clamped)
        this.handleInputValueValidation(itemId, value, min, max);

        if (item.get('setValueTo') !== value) {
            this.props.onChangeItemValue(itemId, value, isSetToValue);
        }
    };

    /**
     * Set invalidReasons[itemId]: InvalidValueReason if value is not valid
     */
    handleInputValueValidation = (
        itemId: number,
        value: number | null,
        min: number | null,
        max: number | null
    ) => {
        // If input (target) has a min & max, check if the value is valid or not and update state.invalidReasons to inform ui
        if (value !== null) {
            const invalidReason = getKPIInvalidValueReason(value, min, max);
            if (itemId) {
                this.setState((prevState: State) => ({
                    ...prevState,
                    invalidReasons: prevState.invalidReasons.set(itemId, invalidReason),
                }));
            }
            this.props.onInvalidValueCheck(invalidReason !== null);
        }
    };

    handleSelectComplexOption = (item: ImmutableRecommendationItem) => (selectedItem) => {
        const idOrCustom = selectedItem.value;
        if (idOrCustom === CUSTOM_MESSAGE_ID && this.props.onChangeItemToCustom) {
            this.props.onChangeItemToCustom(item.get('id'));
            return;
        }
        if (this.props.onChangeItemKPI) {
            if (item.get('toKpiId') !== idOrCustom) {
                // at this point only an ID can be in the value.
                this.props.onChangeItemKPI(item.get('id'), idOrCustom);
            }
        }
    };

    handleRemoveItem = (item: ImmutableRecommendationItem) => () =>
        this.props.onRemoveItem(item.get('id'));

    handleAddItem = () => this.props.onAddItem();

    /**
     * Render the controls to reorder the recommendations
     */
    renderDelete = (item: ImmutableRecommendationItem) =>
        this.props.onRemoveItem && (
            <DeleteButtonWrapper onClick={this.handleRemoveItem(item)}>
                <CircleDeleteIcon />
            </DeleteButtonWrapper>
        );

    /**
     * Renders an item as a complex item, where a select box for the tokpiid can be selected
     * as well as its value. This is used to compute the recommendation impact
     */
    renderComplexItem = (item: ImmutableRecommendationItem) => {
        const selectedKPI = this.props.kpiSettings.find(
            (kpi: ImmutableKPISetting) => kpi.get('id') === item.get('toKpiId')
        );
        const kpiOptions = this.getRecommendableKPIs()
            .filter(
                (kpi: ImmutableKPISetting) =>
                    !this.wasKPISelected(kpi) ||
                    (selectedKPI && kpi.get('id') === selectedKPI.get('id'))
            )
            .map((kpi: ImmutableKPISetting) => ({
                label: kpi.get('name'),
                value: kpi.get('id'),
            }))
            .toJS();
        kpiOptions.unshift({
            label: this.getComponentTranslation('recommendationItemCustomMessage'),
            value: CUSTOM_MESSAGE_ID,
        });
        const selectedOption = kpiOptions.find((option) => option.value === item.get('toKpiId'));
        let value = item.get('setValueTo');
        if (!value && value !== 0) {
            value = '';
        }
        return (
            <RecommendationItemInputRow data-testid="recommendation-input">
                <InputSelect
                    options={kpiOptions}
                    selectedOption={selectedOption}
                    onSelect={this.handleSelectComplexOption(item)}
                    maxMenuHeight={STYLE_VALUES.INPUT_SELECT_MAX_MENU_HEIGHTS.LARGE}
                    style={{
                        width: '140px',
                        height: '30px',
                    }}
                    disabled={this.props.readOnly || this.props.isUserPM}
                    controlShouldRenderValue
                />
                <InputNumber
                    value={value}
                    onChange={this.handleChangeItemValue(item)}
                    onBlur={this.handleChangeItemValue(item, true)}
                    min={selectedKPI ? selectedKPI.get('minRecommend') : null}
                    max={selectedKPI ? selectedKPI.get('maxRecommend') : null}
                    step={selectedKPI ? selectedKPI.get('roundTo') : null}
                    renderString={this.props.readOnly}
                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                    disabled={this.props.readOnly || !selectedOption || this.props.isUserPM}
                    height={'32px'}
                    style={{
                        width: '50%',
                    }}
                />
            </RecommendationItemInputRow>
        );
    };

    /**
     * Renders a recommendation item as an input field.
     * This is used for comments or for RecommendationTypes generated from the DT.
     */
    renderSimpleItem = (item: ImmutableRecommendationItem) => {
        let value = item.get('comment');
        const recommendationType = item.get('recommendationType');
        if (recommendationType) {
            value = getRecommendationItemFromRecommendationType(
                item,
                this.props.kpiSettings,
                this.props.circuit,
                this.props.intl
            );
        }

        return (
            <TextField
                value={value || ''}
                minHeight={'28px'}
                disabled={this.props.readOnly || this.props.isUserPM}
                onChange={this.handleChangeItemValue(item)}
            />
        );
    };

    renderItem = (item: ImmutableRecommendationItem) => {
        if (
            RECOMMENDATION_TYPES_FOR_IMPACT_COMPUTATION.indexOf(item.get('recommendationType')) !==
            -1
        ) {
            return this.renderComplexItem(item);
        } else {
            return this.renderSimpleItem(item);
        }
    };

    renderReadOnlyItem = (item: ImmutableRecommendationItem) => item.get('comment');

    renderInvalidInputNote = (item: ImmutableRecommendationItem) => {
        const isInvalidReason = this.state.invalidReasons.get(item.get('id'), null);
        // Only display message if the current item has been set to the invalidReasons map
        if (!isInvalidReason) {
            return;
        }

        // In order to be set as an invalidReasons entry, there must be a min & max recommended provided from a found kpi setting
        const foundKpiSetting = this.props.kpiSettings.find(
            (kpi: ImmutableKPISetting) => kpi.get('id') === item.get('toKpiId')
        );

        if (foundKpiSetting) {
            return (
                <Note warning>
                    {this.getTranslation(
                        `models.kpiSettings.invalidReasonsWithData.${isInvalidReason}`,
                        {
                            min: foundKpiSetting.get('minRecommend'),
                            max: foundKpiSetting.get('maxRecommend'),
                            unit: getKPISettingUnit(foundKpiSetting, this.props.intl),
                        }
                    )}
                </Note>
            );
        }
    };

    renderRecommendationItems = () => {
        if (this.props.readOnly || this.props.isUserPM) {
            return (
                <RecommendationItemListUL>
                    {this.props.items.map((item: ImmutableRecommendationItem, index: number) => (
                        <li key={item.get('id') || index}>{this.renderReadOnlyItem(item)}</li>
                    ))}
                </RecommendationItemListUL>
            );
        } else {
            return this.props.items.map((item: ImmutableRecommendationItem) => (
                <RecommendationItemInputWrapper key={item.get('id')}>
                    <RecommendationItemInputRow>
                        {this.renderItem(item)}
                        {this.renderDelete(item)}
                    </RecommendationItemInputRow>
                    {this.renderInvalidInputNote(item)}
                </RecommendationItemInputWrapper>
            ));
        }
    };

    render() {
        const canSAMEdit = !this.props.readOnly && !this.props.isUserPM;

        return (
            <RecommendationItemListWrapper>
                {this.renderRecommendationItems()}
                {canSAMEdit && (
                    <AddItemButton onClick={this.handleAddItem}>
                        <AddItemButtonText>
                            {this.getComponentTranslation('addRecommendationItemButton')}
                        </AddItemButtonText>
                        <AddIcon />
                    </AddItemButton>
                )}
            </RecommendationItemListWrapper>
        );
    }
}

export default injectIntl(RecommendationItemList);
