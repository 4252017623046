// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    min-width: calc(1280px - 260px);

    padding: 22px 42px
        ${({ smallerBottomPadding }: Object) => (smallerBottomPadding ? `11` : `22`)}px 42px;
    box-sizing: border-box;
`;

export const DiagramTitle = styled.div`
    font-family: 'Helvetica';
    font-size: 20px;
    line-height: 24px;
    color: ${LegacyTheme.black};
`;

export const ValuesWrapper = styled.div`
    min-height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap; /* Allow ValueBoxes to wrap if needed */

    margin: -4px; /* Compensate for ValueBox's margin */
`;

export const ValueBoxSection = styled.div`
    display: flex;
    align-items: center;
`;

export const ValueBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0px 10px;

    border-radius: 4px;
    overflow: hidden;

    background-color: ${LegacyTheme.defaultSubtleColorFA};

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    height: 35px;

    color: ${LegacyTheme.defaultColor};
    font-size: 12px;

    white-space: nowrap; /* Prevent text wrap for when there are longer/many ValueBoxes */

    margin: 4px;

    ${ValueBoxSection} {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
`;

export const ValueLabel = styled.label`
    :not(:first-child) {
        margin-left: 8px;
    }
    margin-right: 5px;
`;
