// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { PrimaryButton, Loader } from 'components/_ReactUI_V1';

import { areObjectsDifferent } from 'utils/helpers';

// Styles
import { Content, Footer, Label, LoaderWrapper } from './styles';

// Components
import UserNotificationTypes from './UserNotificationTypes';
import UserNotificationPlantSettings from './UserNotificationPlantSettings';
import { Title } from 'styles/common';

// Types
import type { IntlType, ErrorType } from 'types';
import type {
    ImmutableNotificationSettings,
    ImmutableNotificationPlantSettings,
    NotificationChannel,
    NotificationSetting,
    NotificationPlantSetting,
} from 'services/Notifications/types';

// Constants
import { APPLICATION_TYPES } from 'utils/constants';

// Service
import {
    selectNotificationSettings,
    selectNotificationSettingsAreFetching,
    selectNotificationPlantSettings,
    selectNotificationPlantSettingsAreFetching,
    selectErrors,
} from 'services/Notifications/selectors';
import { getChannelKey } from 'services/Notifications/helpers';

type InjectedProps = {
    intl: IntlType,
    isFetchingSettings: boolean,
    isFetchingPlantSettings: boolean,
    notificationSettings: ImmutableNotificationSettings,
    notificationPlantSettings: ImmutableNotificationPlantSettings,
    errors: ErrorType,
};

type Props = InjectedProps & {
    loading: boolean,
    onConfirmNotificationSettings: (
        notificationSettings: ?ImmutableNotificationSettings,
        notificationPlantSettings: ?ImmutableNotificationPlantSettings
    ) => void,
    phoneNumberExists: boolean,
};

type State = {
    notificationSettings: ImmutableNotificationSettings,
    notificationPlantSettings: ImmutableNotificationPlantSettings,
};

/**
 * The user SolvExtract settings
 */
class SolvExtractSettings extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            notificationSettings: this.props.notificationSettings,
            notificationPlantSettings: this.props.notificationPlantSettings,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.isFetchingSettings &&
            !this.props.isFetchingSettings &&
            this.props.errors.isEmpty()
        ) {
            this.setState({ notificationSettings: this.props.notificationSettings });
        }

        if (
            prevProps.isFetchingPlantSettings &&
            !this.props.isFetchingPlantSettings &&
            this.props.errors.isEmpty()
        ) {
            this.setState({ notificationPlantSettings: this.props.notificationPlantSettings });
        }
    }

    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.UserSettings.SolvExtractSettings.${key}`,
            },
            data
        );

    handleClickNotificationSetting = (notificationType: string, channel: NotificationChannel) => {
        const channelKey = getChannelKey(channel);

        const updatedNotificationSettings = this.state.notificationSettings.update(
            this.state.notificationSettings.findIndex(
                (el: NotificationSetting) => el.get('notificationType') === notificationType
            ),
            (el: NotificationSetting) => el.set(channelKey, !el.get(channelKey))
        );

        this.setState({ notificationSettings: updatedNotificationSettings });
    };

    handleToggleNotificationPlantSetting = (plantId: number) => {
        const idx = this.state.notificationPlantSettings.findIndex(
            (el: NotificationPlantSetting) => el.get('plantId') === plantId
        );

        const updatedNotificationPlantSettings = this.state.notificationPlantSettings.update(
            idx,
            (el: NotificationPlantSetting) => el.set('active', !el.get('active'))
        );

        this.setState({ notificationPlantSettings: updatedNotificationPlantSettings });
    };

    notificationSettingsAreModified = (): boolean => {
        const initialNotificationSettings = this.props.notificationSettings.toJS();
        const updatedNotificationSettings = this.state.notificationSettings.toJS();

        return areObjectsDifferent(initialNotificationSettings, updatedNotificationSettings);
    };

    notificationPlantSettingsAreModified = (): boolean => {
        const initialNotificationPlantSettings = this.props.notificationPlantSettings.toJS();
        const updatedNotificationPlantSettings = this.state.notificationPlantSettings.toJS();

        return areObjectsDifferent(
            initialNotificationPlantSettings,
            updatedNotificationPlantSettings
        );
    };

    handleSaveClicked = () => {
        const notificationSettings = this.notificationSettingsAreModified()
            ? this.state.notificationSettings
            : null;
        const notificationPlantSettings = this.notificationPlantSettingsAreModified()
            ? this.state.notificationPlantSettings
            : null;

        this.props.onConfirmNotificationSettings(notificationSettings, notificationPlantSettings);
    };

    getSolvextractNotificationSettings = (notificationSettings: NotificationSettings) =>
        notificationSettings.filter(
            (el: NotificationSetting) =>
                el.get('notificationTypeApplication') === APPLICATION_TYPES.SOLVEXTRACT
        );

    hasSolvextractNotificationSettings = () =>
        this.getSolvextractNotificationSettings(this.state.notificationSettings).size > 0;

    render() {
        const loading = this.props.isFetchingSettings || this.props.isFetchingPlantSettings;
        return (
            <React.Fragment>
                <Content>
                    <Title>{this.getTranslation('title')}</Title>

                    {!loading && this.hasSolvextractNotificationSettings() && (
                        <UserNotificationTypes
                            notificationSettings={this.getSolvextractNotificationSettings(
                                this.state.notificationSettings
                            )}
                            phoneNumberExists={this.props.phoneNumberExists}
                            onClickNotificationSetting={this.handleClickNotificationSetting}
                        />
                    )}
                    {!loading &&
                        this.state.notificationPlantSettings.size > 0 &&
                        this.hasSolvextractNotificationSettings() && (
                            <UserNotificationPlantSettings
                                notificationPlantSettings={this.state.notificationPlantSettings}
                                onToggleNotificationPlantSetting={
                                    this.handleToggleNotificationPlantSetting
                                }
                            />
                        )}

                    {!loading && !this.hasSolvextractNotificationSettings() && (
                        <Label>{this.getTranslation('noSettings')}</Label>
                    )}

                    {loading && (
                        <LoaderWrapper>
                            <Loader
                                title={this.props.intl.formatMessage({
                                    id: 'components.AppLoader.loaderAlt',
                                })}
                                loading
                            />
                        </LoaderWrapper>
                    )}
                </Content>
                <Footer>
                    <PrimaryButton
                        text={this.getTranslation('saveButtonText')}
                        loading={this.props.loading}
                        onClick={this.handleSaveClicked}
                        disabled={
                            !this.notificationSettingsAreModified() &&
                            !this.notificationPlantSettingsAreModified()
                        }
                    />
                </Footer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    notificationSettings: selectNotificationSettings(),
    notificationPlantSettings: selectNotificationPlantSettings(),
    isFetchingSettings: selectNotificationSettingsAreFetching(),
    isFetchingPlantSettings: selectNotificationPlantSettingsAreFetching(),
    errors: selectErrors(),
});

export default connect(mapStateToProps)(injectIntl(SolvExtractSettings));
