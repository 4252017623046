// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Title, FormContainer, FormBlock, FormLabel } from 'styles/common';
import { Message } from './styles';

// Components
import { PrimaryButton } from 'components/_ReactUI_V1';

// Constants
import { PLANT_CREATION_STEPS } from 'utils/constants';

// Helpers
import { getImportURLForPlant } from 'utils/helpers';

// Types
import type { IntlType } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';

type Props = {
    intl: IntlType,
    plant: ImmutablePlant,
    isDownloadingTemplate: boolean,
    handleDownloadClicked: (plantId: number, fileName: string) => void,
};

class DoneStep extends React.PureComponent<Props, null> {
    componentDidMount() {
        if (!this.props.plant && this.props.plant.get('id')) {
            throw new Error('Attempted to render content dependant on undefined plant...');
        }
    }

    STEP_KEY = PLANT_CREATION_STEPS.DONE;

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.PlantManager.PlantSetupSteps.${this.STEP_KEY}.${id}`,
            },
            data
        );

    /**
     * When the user clicks the download button
     */
    handleDownloadClicked = () => {
        const plantId = this.props.plant.get('id');
        return this.props.handleDownloadClicked(
            plantId,
            this.getTranslation('download.FileName', {
                plantId,
            })
        );
    };

    render() {
        return (
            <FormContainer>
                <Title>{this.getTranslation('title')}</Title>

                <FormBlock>
                    <FormLabel>{this.getTranslation('instructions.Label')}</FormLabel>
                    <Message>
                        {this.getTranslation('instructions.Message', {
                            plantName: this.props.plant.get('name'),
                        })}
                    </Message>
                </FormBlock>

                <FormBlock fullWidth>
                    <FormLabel>{this.getTranslation('api.Label')}</FormLabel>
                    <Message>{getImportURLForPlant(this.props.plant.get('id'))}</Message>
                </FormBlock>

                <FormBlock>
                    <FormLabel>{this.getTranslation('download.Label')}</FormLabel>
                    <Message>{this.getTranslation('download.Message')}</Message>
                    <PrimaryButton
                        text={this.getTranslation('download.Button')}
                        loading={this.props.isDownloadingTemplate}
                        onClick={this.handleDownloadClicked}
                    />
                </FormBlock>
            </FormContainer>
        );
    }
}

export default injectIntl(DoneStep);
