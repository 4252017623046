// @flow strict

import { fromJS } from 'immutable';

import type { GenericActionType, ImmutableList } from 'types';
import type { ImmutableIsothermState, ImmutableIsotherm, Isotherm } from './types';

// Actions
import {
    CREATE_SUCCESS,
    CREATE_MANY_FAILURE,
    CREATE_MANY_SUCCESS,
    CREATE_FAILURE,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DESTROY_SUCCESS,
    DESTROY_FAILURE,
    VISUALIZE_SUCCESS,
    VISUALIZE_FAILURE,
    DEVISUALIZE,
    IS_FETCHING,
    IS_UPDATING,
    IS_DELETING,
    IS_CREATING,
    IS_VISUALIZING,
} from './actions';

const initialState: ImmutableIsothermState = fromJS({
    isotherms: [],
    currentlyVisualizedIsotherm: null,
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isVisualizing: false,
    errors: {},
});

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function isothermServiceReducer(
    state: ImmutableIsothermState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);
        case IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);
        case IS_DELETING:
            return state.set('isDeleting', action.payload.isDeleting);
        case IS_VISUALIZING:
            return state.set('isVisualizing', action.payload.isVisualizing);

        case CREATE_SUCCESS: {
            const newIsotherm = fromJS(action.payload.data);
            return state
                .updateIn(['isotherms'], (isotherms: ImmutableList<ImmutableIsotherm>) => {
                    const idx = isotherms.findIndex(
                        (isotherm: ImmutableIsotherm) =>
                            isotherm.get('id') === newIsotherm.get('id')
                    );
                    return idx !== -1
                        ? isotherms.setIn([idx], newIsotherm)
                        : isotherms.unshift(newIsotherm);
                })
                .set('errors', fromJS({}))
                .set('isCreating', false);
        }
        case CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);

        case CREATE_MANY_SUCCESS: {
            const newIsotherms = fromJS(action.payload.data);
            return state
                .updateIn(['isotherms'], (isotherms: ImmutableList<ImmutableIsotherm>) => {
                    return isotherms.concat(newIsotherms);
                })
                .set('errors', fromJS({}))
                .set('isCreating', false);
        }
        case CREATE_MANY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);

        case FETCH_LIST_SUCCESS: {
            return state
                .set('isotherms', fromJS(action.payload.data))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        }
        case FETCH_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isFetching', false);

        case UPDATE_SUCCESS: {
            const updatedIsotherm = fromJS(action.payload.data);
            return state
                .updateIn(['isotherms'], (isotherms: ImmutableList<ImmutableIsotherm>) => {
                    const idx = isotherms.findIndex(
                        (isotherm: ImmutableIsotherm) =>
                            isotherm.get('id') === updatedIsotherm.get('id')
                    );
                    return idx !== -1
                        ? isotherms.setIn([idx], updatedIsotherm)
                        : isotherms.unshift(updatedIsotherm);
                })
                .set('errors', fromJS({}))
                .set('isUpdating', false);
        }

        case UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isUpdating', false);

        case DESTROY_SUCCESS: {
            const deletedIsotherm = fromJS(action.payload.data);
            return state
                .updateIn(['isotherms'], (isotherms: ImmutableList<ImmutableIsotherm>) => {
                    const idx = isotherms.findIndex(
                        (isotherm: ImmutableIsotherm) =>
                            `${isotherm.get('id')}` === deletedIsotherm.get('id')
                    );
                    return idx !== -1 ? isotherms.delete(idx) : isotherms;
                })
                .set('errors', fromJS({}))
                .set('isDeleting', false);
        }

        case DESTROY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isDeleting', false);

        case VISUALIZE_SUCCESS: {
            const isotherm = action.payload.data;
            return state
                .set('currentlyVisualizedIsotherm', fromJS(isotherm))
                .set('errors', fromJS({}))
                .set('isVisualizing', false);
        }
        case VISUALIZE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isVisualizing', false);

        case DEVISUALIZE:
            return state.set('currentlyVisualizedIsotherm', null);

        default:
            return state;
    }
}

export default isothermServiceReducer;
