// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import {
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryLabel,
    VictoryLine,
    VictoryScatter,
    VictoryVoronoiContainer,
} from 'victory';

// Styles
import { Loader } from 'components/_ReactUI_V1';

import { Wrapper, DiagramHeader, GraphWrapper, ChartWrapper, LoadingWrapper } from './styles';
import { twoDSensitivityDiagramColors } from 'styles/colors';
import { GraphStyles, lineGraphStylesByType } from './GraphStyles';

// Components
import DiagramLegend from 'components/DiagramLegend';

// Helpers
import {
    getDomainMin,
    getDomainMax,
    hasNetTransfer,
} from 'containers/CircuitComputationContainer/TwoDSensitivityContainer/helpers';
import { round } from 'utils/helpers';

// Types
import type { IntlType, ImmutableList } from 'types';
import type { SensitivityOptionConstant, ImmutableTwoDAnalysis } from 'services/Dataset/types';

const GRAPH_WIDTH = 1024;
const GRAPH_HEIGHT = 720;

const KPI_LINE_TYPES = {
    OVERALL_RECOVERY: 'overallRecovery',
    NET_TRANSFER: 'netTransfer',
};

const KPI_AXIS_NAMES = {
    overallRecovery: 'OVERALL_RECOVERY',
    netTransfer: 'NET_TRANSFER',
};

type KPILineType = $Values<typeof KPI_LINE_TYPES>;

type DataPoint = {
    x: number,
    y: number,
};

type CurveData = {
    curveType: KPILineType,
    curve: Array<DataPoint>,
    minima: number,
    maxima: number,
};

type Props = {
    intl: IntlType,
    loading: boolean,
    twoDAnalysis: ?ImmutableTwoDAnalysis,
    sensitivityOptionValue: ?number, // This is the hover location x.
    sensitivityOption: SensitivityOptionConstant,
    showNetTransferIfProvided: boolean,
    handleHover: (x: number) => void,
};

type State = {
    curves: Array<CurveData>,
};

class TwoDSensitivityDiagram extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        if (this.props.twoDAnalysis) {
            this.state = {
                curves: this.getCurvesData(),
            };
        } else {
            this.state = {
                curves: [],
            };
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.twoDAnalysis !== this.props.twoDAnalysis && this.props.twoDAnalysis) {
            this.setState({
                curves: this.getCurvesData(),
            });
        }
    }

    getXAxisDomain = (): Array<number> => [
        getDomainMin(this.props.twoDAnalysis),
        getDomainMax(this.props.twoDAnalysis),
    ];

    getRoundedValue = (kpiLineType: KPILineType, value: number): number => {
        const roundTo = kpiLineType === KPI_LINE_TYPES.OVERALL_RECOVERY ? 2 : 3;
        return round(value, roundTo);
    };

    getScaledDataPoints = (
        yPoints: Array<DataPoint>,
        minima: number,
        maxima: number
    ): Array<DataPoint> => {
        return yPoints.map((dp: DataPoint) => ({
            x: dp.x,
            y: (dp.y - minima) / (maxima - minima),
        }));
    };

    getCurveData = (kpiLineType: KPILineType): CurveData => {
        const curveDataPoints = this.mapArrayToDataPoint(
            this.props.twoDAnalysis.get('analysis'),
            kpiLineType
        );
        const yPoints = curveDataPoints.map((d) => d.y);
        const minima = Math.min(...yPoints);
        const maxima = Math.max(...yPoints);
        return {
            curveType: kpiLineType,
            curve: this.getScaledDataPoints(curveDataPoints, minima, maxima),
            minima,
            maxima,
        };
    };

    getCurvesData = (): Array<CurveData> => {
        const showNetTransfer =
            this.props.showNetTransferIfProvided && hasNetTransfer(this.props.twoDAnalysis);

        const curves: Array<CurveData> = [];

        const showOverallRecovery = true;
        if (showOverallRecovery) {
            curves.push(this.getCurveData(KPI_LINE_TYPES.OVERALL_RECOVERY));
        }
        if (showNetTransfer) {
            curves.push(this.getCurveData(KPI_LINE_TYPES.NET_TRANSFER));
        }

        return curves;
    };

    /**
     * Converts the list of points to an array of data point used by victory.
     */
    mapArrayToDataPoint = (
        points: ImmutableList<ImmutableList<number>>,
        targetKey: string
    ): Array<DataPoint> =>
        points
            .map((point: ImmutableList<number>) => ({
                x: point.get('xVariable'),
                y: point.get(targetKey),
            }))
            .toJS();

    handleHover = (activePoints: Array<DataPoint>) => {
        // Get x location from first active point.
        if (activePoints.length === 0) return; // there is no line at that location

        this.props.handleHover(activePoints[0].x);
    };

    /**
     * Renders the Y axis for the kpiLineType
     */
    renderYAxis = (kpiLineType: KPILineType, minima: number, maxima: number) => {
        // eslint-disable-next-line flowtype/no-weak-types
        function getKeyByValue(object: any, value: any): string {
            const kpiKey = Object.keys(object).find((key: string) => object[key] === value);
            if (!kpiKey) {
                throw new Error(
                    'Could not find Y Axis name via the kpi line value type. This is a dev error.'
                );
            }
            return kpiKey;
        }
        return (
            <VictoryAxis
                key={`yAxis-${kpiLineType}`}
                name={`yAxis-${kpiLineType}`}
                label={this.props.intl.formatMessage({
                    id: `components.SensitivityDiagrams.Axis.${getKeyByValue(
                        KPI_LINE_TYPES,
                        kpiLineType
                    )}`,
                })}
                orientation={kpiLineType === KPI_LINE_TYPES.OVERALL_RECOVERY ? 'left' : 'right'}
                style={{
                    ...GraphStyles.axisY,
                    ...(kpiLineType === KPI_LINE_TYPES.OVERALL_RECOVERY
                        ? GraphStyles.recoveryPercentAxis
                        : GraphStyles.netTransferAxis),
                }}
                axisLabelComponent={<VictoryLabel dy={-12} />}
                tickValues={[0, 0.25, 0.5, 0.75, 1]}
                tickFormat={(tick: number) =>
                    this.getRoundedValue(kpiLineType, tick * (maxima - minima) + minima)
                }
                tickLabelComponent={
                    <VictoryLabel
                        dy={GraphStyles.axisYTickLabelTranslate.dy}
                        dx={
                            GraphStyles.axisYTickLabelTranslate.dx *
                            (kpiLineType === KPI_LINE_TYPES.OVERALL_RECOVERY ? 1 : -1)
                        }
                        textAnchor={
                            kpiLineType === KPI_LINE_TYPES.OVERALL_RECOVERY ? 'end' : 'start'
                        }
                    />
                }
                dependentAxis
            />
        );
    };

    /**
     * Renders the X Axis
     */
    renderXAxis = () => (
        <VictoryAxis
            name="xAxis"
            label={this.props.intl.formatMessage({
                id: `constants.SensitivityOptions.${this.props.sensitivityOption}`,
            })}
            standalone={false}
            style={GraphStyles.axisX}
            domain={this.getXAxisDomain()}
        />
    );

    /**
     * Renders the Overall Recovery / Net Transfer line & scatter
     */
    renderCurveByKPILineType = (kpiLineType: KPILineType, dataPoints: Array<DataPoint>) => {
        return (
            <VictoryGroup data={dataPoints}>
                <VictoryLine
                    name={kpiLineType}
                    style={lineGraphStylesByType(kpiLineType)}
                    interpolation="natural"
                />
                <VictoryScatter
                    style={GraphStyles.hoverDotScatter(
                        this.props.sensitivityOptionValue,
                        lineGraphStylesByType(kpiLineType).dot.fill
                    )}
                    size={7}
                />
            </VictoryGroup>
        );
    };

    /**
     * If the circuit is still being loaded, display a spinner
     */
    renderLoading = () => (
        <LoadingWrapper>
            <Loader
                loading={this.props.loading}
                title={this.props.intl.formatMessage({ id: 'common.loadingData' })}
            />
        </LoadingWrapper>
    );

    render() {
        if (this.props.loading) return this.renderLoading();
        const diagramLegendItems = [
            {
                color: twoDSensitivityDiagramColors.recoveryPercent.primaryColor,
                label: this.props.intl.formatMessage({
                    id: 'components.SensitivityDiagrams.Legend.recoveryPercent',
                }),
            },
        ];

        const showNetTransfer =
            this.props.showNetTransferIfProvided && hasNetTransfer(this.props.twoDAnalysis);
        if (showNetTransfer) {
            diagramLegendItems.push({
                color: twoDSensitivityDiagramColors.netTransfer.primaryColor,
                label: this.props.intl.formatMessage({
                    id: 'components.SensitivityDiagrams.Legend.netTransfer',
                }),
            });
        }

        return (
            <Wrapper>
                <DiagramHeader>
                    <DiagramLegend legendItems={diagramLegendItems} />
                </DiagramHeader>

                <ChartWrapper>
                    <GraphWrapper graphWidth={GRAPH_WIDTH} graphHeight={GRAPH_HEIGHT}>
                        <VictoryChart
                            height={GRAPH_HEIGHT}
                            width={GRAPH_WIDTH}
                            style={GraphStyles.mainGraph}
                            padding={GraphStyles.mainGraphPadding}
                            domain={{ y: [0, 1] }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    onActivated={this.handleHover}
                                    voronoiBlacklist={[
                                        'hoverDotScatter',
                                        'xAxis',
                                        `yAxis-${KPI_LINE_TYPES.OVERALL_RECOVERY}`,
                                        `yAxis-${KPI_LINE_TYPES.NET_TRANSFER}`,
                                    ]}
                                    voronoiDimension="x"
                                    voronoiPadding={-3}
                                    radius={5}
                                />
                            }
                        >
                            {/* Axis Components */}
                            {this.renderXAxis()}
                            {this.state.curves.map((curve: CurveData) =>
                                this.renderYAxis(curve.curveType, curve.minima, curve.maxima)
                            )}
                            {/* Line & Scatter Components */}
                            {this.state.curves.map((curve: CurveData) =>
                                this.renderCurveByKPILineType(curve.curveType, curve.curve)
                            )}
                        </VictoryChart>
                    </GraphWrapper>
                </ChartWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(TwoDSensitivityDiagram);
