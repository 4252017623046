// @flow strict

import React from 'react';

import { StyledSVG } from './styles';

export default function HamburgerMenuIcon(props?: {
    stroke?: string,
    width?: number,
    strokeWidth?: number,
}) {
    return (
        <StyledSVG viewBox="0 0 24 24" width={`${props.width}px`} height={`${props.width}px`}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <line
                    x1="0"
                    y1="18.5"
                    x2="24"
                    y2="18.5"
                    stroke={props.stroke}
                    strokeWidth={`${props.strokeWidth}`}
                />
                <line
                    x1="0"
                    y1="5.5"
                    x2="24"
                    y2="5.5"
                    stroke={props.stroke}
                    strokeWidth={`${props.strokeWidth}`}
                />
                <line
                    x1="0"
                    y1="12"
                    x2="24"
                    y2="12"
                    stroke={props.stroke}
                    strokeWidth={`${props.strokeWidth}`}
                />
            </g>
        </StyledSVG>
    );
}

HamburgerMenuIcon.defaultProps = {
    stroke: 'currentColor',
    strokeWidth: 3,
    width: 25,
};
