// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Title, FormContainer } from 'styles/common';
import { StepBlock } from './styles';

// Components
import ErrorMessage from 'components/ErrorMessage';
import CircuitElevationPlantStep from 'components/CircuitElevationSteps/PlantStep';
import CircuitElevationMajorKpiStep from 'components/CircuitElevationSteps/MajorKpiStep';
import CircuitElevationMinorKpiStep from 'components/CircuitElevationSteps/MinorKpiStep';
import CircuitElevationDecisionTreeOptionsStep from 'components/CircuitElevationSteps/DecisionTreeOptionsStep';

// Constants
import { CIRCUIT_ELEVATION_STEPS } from 'utils/constants';

// Types
import type { IntlType, ImmutableList } from 'types';
import type {
    ImmutableCircuit,
    CircuitElevationStepConstant,
    ImmutableCircuitSettings,
} from 'services/Circuit/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableDecisionTreeOptions } from 'services/DecisionTreeOptions/types';
import type { MinorKPISettingSubState } from 'containers/CircuitSetupContainer/SolvExtractElevationContainer';
import type { ImmutableReagent } from 'services/Reagent/types';

type Props = {
    intl: IntlType,
    isCircuitElevating: boolean,
    circuit: ImmutableCircuit,
    plants: ImmutableList<ImmutablePlant>,

    plantId: number,
    majorKPISettings: ImmutableList<ImmutableKPISetting>,
    minorKPISettings: MinorKPISettingSubState,
    reagent: ImmutableReagent,

    decisionTreeOptions: ImmutableDecisionTreeOptions,
    circuitSettings: ImmutableCircuitSettings,
    circuitName: string,
    onReturnToStep: (step: CircuitElevationStepConstant) => () => void,
};

class CircuitElevationReviewStep extends React.PureComponent<Props, null> {
    STEP_KEY = CIRCUIT_ELEVATION_STEPS.REVIEW;

    getTranslation = (id: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.CircuitElevation.${this.STEP_KEY}.${id}`,
            },
            data
        );

    renderDatasetDeletionWarning = () => {
        if (this.props.circuit.get('datasets').isEmpty()) {
            return null;
        }
        return (
            <ErrorMessage
                errorMessage={this.getTranslation('datasetsWillBeDeletedWarning')}
                isRed
                isSmall
            />
        );
    };

    render() {
        return (
            <FormContainer>
                <Title>{this.getTranslation('title')}</Title>
                {this.renderDatasetDeletionWarning()}

                <StepBlock>
                    <CircuitElevationPlantStep
                        plants={this.props.plants}
                        circuit={this.props.circuit}
                        plantId={this.props.plantId}
                        isLoading={false}
                        isCircuitElevating={this.props.isCircuitElevating}
                        circuitSettings={this.props.circuitSettings}
                        circuitName={this.props.circuitName}
                        onReturnToStep={this.props.onReturnToStep}
                        reagent={this.props.reagent}
                    />
                </StepBlock>
                <StepBlock>
                    <CircuitElevationMajorKpiStep
                        units={this.props.circuit.get('circuitUnits')}
                        majorKPISettings={this.props.majorKPISettings}
                        onReturnToStep={this.props.onReturnToStep}
                        isCircuitElevating={this.props.isCircuitElevating}
                    />
                </StepBlock>
                <StepBlock>
                    <CircuitElevationMinorKpiStep
                        units={this.props.circuit.get('circuitUnits')}
                        minorKPISettings={this.props.minorKPISettings}
                        onReturnToStep={this.props.onReturnToStep}
                        isCircuitElevating={this.props.isCircuitElevating}
                        circuitSettings={this.props.circuitSettings}
                    />
                </StepBlock>
                <StepBlock>
                    <CircuitElevationDecisionTreeOptionsStep
                        circuitSettings={this.props.circuitSettings}
                        decisionTreeOptions={this.props.decisionTreeOptions}
                        onReturnToStep={this.props.onReturnToStep}
                    />
                </StepBlock>
            </FormContainer>
        );
    }
}

export default injectIntl(CircuitElevationReviewStep);
