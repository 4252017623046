// @noflow

import styled from 'styled-components';

import { themePropertyFetcher } from '../../tools/helpers';

export const StyledButtonHover = styled.button`
    padding: 4px 6px;
    min-height: 34px;

    border: 0 none;
    border-radius: 4px;

    cursor: pointer;

    background-color: ${({ active, theme }: Object) =>
        active
            ? themePropertyFetcher(theme, ['buttonHover', 'backgroundColorActive'])
            : themePropertyFetcher(theme, ['buttonHover', 'backgroundColor'])};
    &:hover {
        background-color: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['buttonHover', 'backgroundColorHover'])};
    }

    &:focus {
        outline: none;
    }
`;
