// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Utils
import { themePropertyFetcher } from '../../tools/helpers';

export const ActiveItem = styled.div`
    margin-right: 10px;

    ${({ activeItem }: Object) =>
        activeItem &&
        `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `};
`;

export const ActiveItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'padding'])};

    box-sizing: border-box;

    &:hover {
        color: ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'colorHover'])};
    }
`;

export const List = styled.div`
    position: absolute;
    top: 100%;
    left: -1px;
    margin-right: -1px;

    width: 180%;
    padding: ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'padding'])};

    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['navDropdown', 'dropdown', 'backgroundColor'])};
    border: 1px solid
        ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'borderColor'])};
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    width: ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'width'])};

    background-color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['navDropdown', 'backgroundColor'])};
    border: 1px solid
        ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'borderColor'])};
    border-bottom: 0 none;
    border-top: 0 none;

    color: ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'color'])};
    font-size: ${({ theme }: Object) => themePropertyFetcher(theme, ['navDropdown', 'fontSize'])};

    cursor: pointer;
    z-index: 1;

    box-sizing: border-box;
`;

const ItemBase = css`
    display: flex;
    flex: 1;

    padding: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['navDropdown', 'dropdown', 'padding'])};

    align-items: center;
    justify-content: flex-start;

    color: ${({ theme }: Object) =>
        themePropertyFetcher(theme, ['navDropdown', 'dropdown', 'color'])};

    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${({ theme }: Object) =>
            themePropertyFetcher(theme, ['navDropdown', 'dropdown', 'colorHover'])};
    }

    transition: all ease 0.25s;
`;

const ItemLink = styled(Link)`
    ${ItemBase};
`;

const CurrentItemLink = styled(ItemLink)``;

// Span "demo" version (No react-router's Link)
const ItemSpan = styled.span`
    ${ItemBase};
`;

const CurrentItemSpan = styled(ItemSpan)``;

export const Item = ({ current, demo, ...props }: { current: boolean, demo: boolean }) => {
    let Component;
    if (demo) {
        Component = current ? CurrentItemSpan : ItemSpan;
    } else {
        Component = current ? CurrentItemLink : ItemLink;
    }

    return <Component {...props} />;
};
