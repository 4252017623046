// @flow strict

import request from 'services/request';

import type {
    ImmutableDataset,
    SensitivityOptionConstant,
    ZSensitivityOptionConstant,
    ScenarioKpi,
    RawDatasetValue,
    RawStageValue,
    RawStreamValue,
    DatasetValue,
    StageValue,
    StreamValue,
} from 'services/Dataset/types';

/**
 * Create dataset
 *
 * @param {Dataset} dataset
 */
export const create = (dataset: ImmutableDataset) =>
    request('datasets', {
        data: dataset,
        method: 'POST',
    });

/**
 * Finds a specific dataset
 */
export const find = (id: number) => request(`datasets/${id}`, { method: 'GET' });

/**
 * Update dataset
 *
 * @param {number} id
 * @param {Dataset} dataset
 */
export const update = (id: number, dataset: ImmutableDataset) =>
    request(`datasets/${id}`, {
        data: dataset,
        method: 'PUT',
    });

/**
 * Destroy dataset
 *
 * @param {number} id
 */
export const destroy = (id: number) => request(`datasets/${id}`, { method: 'DELETE' });

/**
 * Compute a dataset
 *
 * @param {Dataset} dataset
 */
export const compute = (
    dataset: ImmutableDataset,
    cascadeRelaxationFactor: number,
    returnRelaxationFactor: number
) =>
    request(`datasets/compute`, {
        data: {
            dataset,
            cascadeRelaxationFactor,
            returnRelaxationFactor,
        },
        method: 'POST',
    });

/**
 * Compute 2D sensitivity analysis
 *
 * @param {Dataset} dataset
 */
export const compute2D = (
    datasetId: number,
    sensitivityVariable: SensitivityOptionConstant,
    rangeMin: number,
    rangeMax: number
) =>
    request(`datasets/sensitivity/2d`, {
        data: {
            datasetId,
            sensitivityVariable,
            rangeMin,
            rangeMax,
        },
        method: 'POST',
    });

export const performExport2DSensitivityToCSV = (settings: Object) =>
    request(`datasets/sensitivity/2d/export`, {
        data: settings,
        method: 'POST',
    });

/**
 * Compute 3D sensitivity analysis
 *
 * @param {Dataset} dataset
 */
export const compute3D = (
    datasetId: number,
    xSensitivityVariable: SensitivityOptionConstant,
    xRangeMin: number,
    xRangeMax: number,
    ySensitivityVariable: SensitivityOptionConstant,
    yRangeMin: number,
    yRangeMax: number,
    zVariable: ZSensitivityOptionConstant
) =>
    request(`datasets/sensitivity/3d`, {
        data: {
            datasetId,
            xSensitivityVariable,
            xRangeMin,
            xRangeMax,
            ySensitivityVariable,
            yRangeMin,
            yRangeMax,
            zVariable,
        },
        method: 'POST',
    });

/*
 * Compute 2D dataset
 *
 * @param {number} datasetId
 * @param {SensitivityOptionConstant} sensitivityVariable
 * @param {number} variableValue
 */
export const performCompute2DDataset = (
    datasetId: number,
    sensitivityVariable: SensitivityOptionConstant,
    variableValue: number
) =>
    request(`datasets/sensitivity/2d/dataset`, {
        data: {
            datasetId,
            sensitivityVariable,
            variableValue,
        },
        method: 'POST',
    });

/*
 * Run dataset scenario
 *
 * @param {number} circuitId
 * @param {number} datasetId
 * @param {ScenarioKpi[]} sensitivityVariable
 */
export const runScenario = (circuitId: number, datasetId: number, kpis: Array<ScenarioKpi>) =>
    request(`circuit/${circuitId}/dataset/${datasetId}/scenario`, {
        data: {
            kpis,
        },
        method: 'POST',
    });

export const putDatasetValue = (
    circuitId: number,
    datasetId: number,
    datasetValueId: number,
    data: $Shape<DatasetValue>
) => {
    return request(`circuit/${circuitId}/dataset/${datasetId}/dataset-value/${datasetValueId}`, {
        data,
        method: 'PUT',
    });
};

export const postDatasetValue = (
    circuitId: number,
    datasetId: number,
    data: $Shape<RawDatasetValue>
) => {
    return request(`circuit/${circuitId}/dataset/${datasetId}/dataset-value/`, {
        data,
        method: 'POST',
    });
};

export const putStageValue = (
    circuitId: number,
    datasetId: number,
    stageValueId: number,
    data: $Shape<StageValue>
) => {
    return request(`circuit/${circuitId}/dataset/${datasetId}/stage-value/${stageValueId}`, {
        data,
        method: 'PUT',
    });
};

export const postStageValue = (
    circuitId: number,
    datasetId: number,
    data: $Shape<RawStageValue>
) => {
    return request(`circuit/${circuitId}/dataset/${datasetId}/stage-value`, {
        data,
        method: 'POST',
    });
};

export const putStreamValue = (
    circuitId: number,
    datasetId: number,
    streamValueId: number,
    data: $Shape<StreamValue>
) => {
    return request(`circuit/${circuitId}/dataset/${datasetId}/stream-value/${streamValueId}`, {
        data,
        method: 'PUT',
    });
};

export const postStreamValue = (
    circuitId: number,
    datasetId: number,
    data: $Shape<RawStreamValue>
) => {
    return request(`circuit/${circuitId}/dataset/${datasetId}/stream-value`, {
        data,
        method: 'POST',
    });
};
