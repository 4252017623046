// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { QuickNavigatorObservable } from 'components/_FrontendObservables';

// Authentication Helpers
import { SidebarLayout } from 'components/_ReactUI_V1';

import { isSolvayUser } from 'utils/authentication';

// Containers
import IsothermsContainer from './IsothermsContainer';
import MDRCurvesContainer from './MDRCurvesContainer';

// Components
import IsothermManagementSidebar from 'components/IsothermManagementSidebar';

// Constants
import { STYLE_VALUES, SIDEBAR_STATES } from 'utils/constants';

// Services
import { selectUser } from 'services/Authentication/selectors';

// Types
import type { ReduxDispatch, SidebarType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';

import type { IsothermIdRouteType } from 'containers/IsothermManagementContainer/IsothermsContainer';

export type IsothermManagementSidebarSectionsType = 'ISOTHERMS' | 'MDR_CURVES';
export type SidebarRenderer = {
    sidebarType: SidebarType,
    node: React.ReactNode,
};

type Props = {
    isothermId: IsothermIdRouteType,
    user: ImmutableUser,
};

type State = {
    activeContainer: IsothermManagementSidebarSectionsType,
    activeContainerSidebar: ?SidebarRenderer,
};

/**
 * Isotherm Management view's main container.
 * Holds the different Sidebar options and related Content.
 */
class IsothermManagementContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        // Default sidebar option
        this.state = {
            activeContainer: 'ISOTHERMS',
            activeContainerSidebar: null,
        };
    }

    componentDidMount() {
        QuickNavigatorObservable.removeQuickNavigator();
    }

    /**
     * Set active container through the sidebar (Mimic, McCabe, 2D or 3D)
     */
    onSidebarToggle = (activeContainer: IsothermManagementSidebarSectionsType) =>
        this.setState({ activeContainer });

    /**
     * When the sidebar renderer is created from child.
     */
    onReceiveSidebarContent = (activeContainerSidebar: SidebarRenderer) =>
        this.setState({ activeContainerSidebar });

    // Render container for the main area content
    renderMainContent = () => {
        let mainContainer = null;
        switch (this.state.activeContainer) {
            case 'ISOTHERMS':
                mainContainer = this.renderIsothermsContainer();
                break;
            case 'MDR_CURVES':
                mainContainer = this.renderMDRCurvesContainer();
                break;
            default:
                break;
        }
        return mainContainer;
    };

    // Renders the sidebar content.
    renderSidebar = () => {
        if (!this.state.activeContainerSidebar) return null;

        const sidebarData = this.state.activeContainerSidebar;
        if (sidebarData.sidebarType === SIDEBAR_STATES.FULL) {
            return sidebarData.node;
        } else {
            return (
                <IsothermManagementSidebar
                    activeSection={this.state.activeContainer}
                    handleSectionActivate={this.onSidebarToggle}
                    sectionBody={sidebarData.node}
                    user={this.props.user}
                />
            );
        }
    };

    // Render container for Isotherms sub-section
    renderIsothermsContainer = () => (
        <IsothermsContainer
            isothermId={this.props.isothermId}
            handleSidebarContent={this.onReceiveSidebarContent}
        />
    );

    // Render container for MDR curves sub-section
    renderMDRCurvesContainer = () =>
        isSolvayUser(this.props.user) && (
            <MDRCurvesContainer handleSidebarContent={this.onReceiveSidebarContent} />
        );

    render() {
        return (
            <SidebarLayout
                styles={{
                    main: { overflowY: 'auto' },
                    container: {
                        height: `calc(100% - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                }}
                renderMain={this.renderMainContent}
                renderSidebar={this.renderSidebar}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                collapsible
                mainFlush
                flush
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(IsothermManagementContainer)
);
