// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';
import { STYLE_VALUES } from 'utils/constants';

export const SidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
`;

export const SidebarHeader = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px 20px 10px 20px;
`;

export const Title = styled.h2`
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    color: ${LegacyTheme.defaultColor};
    font-weight: normal;
`;

export const Instructions = styled.p`
    font-size: 13px;
    line-height: 15px;

    color: ${LegacyTheme.defaultSubtleColor7B};

    & + & {
        margin-top: 0;
    }
`;

export const SidebarBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    padding: 0px 20px 20px 20px;
    margin-bottom: 20px;
`;

export const Spacer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const DisplayMessages = styled.div`
    display: flex;
    flex-direction: column;
    color: ${LegacyTheme.defaultColor};

    > button {
        margin-bottom: 20px;
    }
`;

export const SidebarFooter = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    border-top: 1px solid ${LegacyTheme.defaultBorder};
    padding: 24px 20px 20px 20px;

    > label {
        width: fit-content;
    }

    > button {
        width: fit-content;
        align-self: flex-end;
    }
`;

export const Label = styled.p`
    font-size: 13px;
    margin-bottom: 4px;
    color: ${LegacyTheme.defaultSubtleColor7B};

    & + & {
        margin-top: 4px;
        margin-bottom: 20px;
    }
`;

export const InputLimitCount = styled.p`
    text-align: right;
    font-size: 12px;
    margin: 4px 2px;
    color: ${LegacyTheme.defaultSubtleColor};
`;
