// @flow strict

import React from 'react';

import { StyledSVG } from './styles';

export default function AddIcon(props?: { fill?: string, width?: number }) {
    return (
        <StyledSVG viewBox="0 0 16 16" width={`${props.width}px`} height={`${props.width}px`}>
            <path
                d="M12.6668 8.66659H8.66683V12.6666H7.3335V8.66659H3.3335V7.33325H7.3335V3.33325H8.66683V7.33325H12.6668V8.66659Z"
                fill={props.fill}
            />
        </StyledSVG>
    );
}

AddIcon.defaultProps = {
    fill: 'currentColor',
    width: 16,
};
