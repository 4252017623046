/* eslint-disable flowtype/no-weak-types */
// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

import { colors } from 'styles/colors';

import { SVG_LAYER_Z_INDEX } from 'components/MimicDiagram/styles';

export const StageBodyWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 0 16px;

    background-color: ${LegacyTheme.white};
    font-size: 12px;

    z-index: ${SVG_LAYER_Z_INDEX + 1};
`;

export const StageBodyFieldItem = styled.div`
    flex: ${({ flex }: Object) => flex || 'auto'};
    font-size: 12px;
`;

export const StageBodyField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    max-width: 100%;
    min-height: 30px; // Avoids shift with renderString

    > ${StageBodyFieldItem} {
        margin-right: 6px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const IsothermNameFieldItem = styled.div`
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ disabled }: Object) =>
        disabled ? LegacyTheme.defaultColor : LegacyTheme.buttonPrimary};

    ${({ disabled }: Object) =>
        !disabled &&
        `
        &:hover {
            cursor: pointer;
            color: ${LegacyTheme.buttonPrimaryHover};
        }
    `};
`;

export const LeftPortsWrapper = styled.div`
    width: 0px;
    height: 100%;
    overflow: hidden;
    position: absolute;
`;

export const RightPortsWrapper = styled.div`
    width: 0px;
    height: 100%;
    overflow: hidden;
    right: 0;
    position: absolute;
`;

export const IsothermSelectTrigger = styled.div`
    cursor: pointer;

    border-radius: 50%;

    > svg {
        display: block;
    }

    &:hover {
        box-shadow: 0 0 4px 1px ${LegacyTheme.buttonPrimaryFocus};
    }
`;
