// @flow

import React from 'react';

type GridLayoutIconType = {
    height?: ?number,
    width?: ?number,
    fill?: ?string,
};

export function GridLayoutIcon(props: GridLayoutIconType) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 19 15"
            fill={props.fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0V15H19V0H0ZM11.1765 2.30769V6.34615H7.82353V2.30769H11.1765ZM2.23529 2.30769H5.58824V6.34615H2.23529V2.30769ZM2.23529 12.6923V8.65385H5.58824V12.6923H2.23529ZM7.82353 12.6923V8.65385H11.1765V12.6923H7.82353ZM16.7647 12.6923H13.4118V8.65385H16.7647V12.6923ZM13.4118 6.34615V2.30769H16.7647V6.34615H13.4118Z" />
        </svg>
    );
}

GridLayoutIcon.defaultProps = {
    height: 20,
    width: 40,
    fill: 'currentColor',
};
