// @flow

/**
 * Legacy Theme (originally base/legacyTheme.js)
 *
 * Exported as LegacyTheme for older projects which reference defined colors outside of the React UI Library
 *
 * DO NOT UPDATE unless 100% Necessary
 */

import colors from '../colors';

const LegacyTheme = {
    black: colors.black,
    white: colors.white,

    // Defaults
    defaultColor: colors.grey33,
    defaultBoldColor: colors.black,
    defaultSubtleColor: colors.grey9C,
    defaultSubtleColor7B: colors.grey7B,
    defaultSubtleColorFA: colors.greyFA,
    defaultBackground: colors.white,
    defaultBorder: colors.greyE6,
    defaultWarningColor: colors.redE20,
    defaultSuccessColor: colors.green6AB,
    defaultIconColor: colors.greyC6,

    // Login Layout
    loginLayout: colors.white,
    loginLayoutShadow: colors.black,

    // Disabled State
    disabledBorder: colors.greyF2,
    disabledInputColor: colors.grey9C,
    disabledInputSelectColor: colors.greyFA,
    disabledPlaceholder: colors.greyC6,

    // Checkboxes
    checkboxLabelColor: colors.grey7B,
    checkboxLabelDisabledColor: colors.greyC6,

    // Radiobutton
    radiobuttonActive: colors.blue007,
    radiobuttonBackground: colors.white,
    radiobuttonBorder: colors.greyC6,
    radiobuttonLabelColor: colors.grey7B,
    radiobuttonHoverBorder: colors.grey9C,
    radiobuttonHoverBackground: colors.greyFA,
    radiobuttonLabelDisabledColor: colors.greyC6,

    // Buttons
    buttonDisabled: colors.greyC6,
    buttonDisabledColor: colors.white,

    buttonPrimary: colors.blue007,
    buttonPrimaryColor: colors.white,
    buttonPrimaryHover: colors.blue009,
    buttonPrimaryHoverColor: colors.white,
    buttonPrimaryFocus: colors.blue009,

    buttonSecondary: colors.blueEBF,
    buttonSecondaryColor: colors.blue007,
    buttonSecondaryHover: colors.blueEBF,
    buttonSecondaryHoverColor: colors.blue007,

    buttonTertiary: colors.greyC6,
    buttonTertiaryColor: colors.white,
    buttonTertiaryHover: colors.greyC6,
    buttonTertiaryHoverColor: colors.white,

    buttonWarning: colors.redDE0,
    buttonWarningColor: colors.white,
    buttonWarningHover: colors.redDE0,
    buttonWarningHoverColor: colors.white,

    buttonInverse: colors.blue007,
    buttonInverseColor: colors.blue007,
    buttonInverseHover: colors.blue009,
    buttonInverseHoverColor: colors.white,

    buttonGridActiveColor: colors.blue007,
    buttonGridColor: colors.grey9C,
    buttonGrid: colors.greyFC,
    buttonGridActive: colors.white,
    buttonGridBorder: colors.greyE6,
    buttonGridLabelDanger: colors.redE20,
    buttonGridLabel: colors.greyE6,

    buttonHover: colors.transparent,
    buttonActiveHover: colors.greyF2,

    searchButtonBackground: colors.blueEBF,

    buildingBlockButtonPrimary: colors.blue007,
    buildingBlockButtonSecondary: colors.greyFA,
    buildingBlockButtonSecondaryColor: colors.grey9C,

    // Modal
    modalContent: colors.white,
    modalOverlay: colors.black,
    modalCloseColor: colors.grey7B,
    modalTitle: colors.grey1A,

    // Loader
    loaderSecondary: colors.blueEBF,
    loaderPrimary: colors.blue009,

    // Sidebar Layout
    sidebarLayoutSidebar: colors.greyFA,
    sidebarLayoutMain: colors.white,
    sidebarLayoutSidebarSubcontent: colors.greyF2,
    sidebarLayoutResponsiveTitle: colors.blue007,
    sidebarLayoutResponsiveCaretColor: colors.blue007,

    // TabNav
    tabNavColor: colors.grey7B,
    tabNavActiveColor: colors.grey33,
    tabNavBorder: colors.blue007,
    tabNavHoverBorder: colors.greyF2,

    // Tool Tip
    toolTip: colors.greyFC,
    toolTipColor: colors.grey33,
    toolTipBorder: colors.greyE6,
    toolTipShadow: colors.black,
    toolTipTrigger: colors.greyC6,
    toolTipTriggerBorderColor: colors.greyF2,
    toolTipTriggerBackgroundColor: colors.greyF2,
    toolTipTriggerColor: colors.white,
    toolTipTriggerColor9C: colors.grey9C,
    toolTipLink: colors.black,

    dotMenu: colors.grey9C,

    // Table
    tableBackground: colors.white,
    tableColor: colors.grey33,
    tableBorder: colors.greyE6,
    tableHeader: colors.greyF2,
    tableHeaderColor: colors.grey7B,
    tableExpanded: colors.greyFC,
    tableRowHover: colors.greyFA,
    tableRowDisabledBackgroundColor: colors.greyFA,

    // Inner/Subtle Table
    tableSubtleBorder: colors.greyF2,
    tableSubtleColor: colors.grey7B,
    tableSubtleHeader: colors.greyFA,
    tableSubtleHeaderColor: colors.grey9C,
    tableSubtleTitleColor: colors.grey7B,

    // Pagination
    paginationColor: colors.grey7B,
    paginationButton: colors.white,
    paginationButtonShadow: colors.black,
    paginationActiveColor: colors.black,

    // Slider
    sliderTextColor: colors.grey7B,
    sliderOffsideBackgroudColor: colors.greyE9,
    sliderTackBackgroudColor: colors.blueC2D,
    sliderHandle: colors.blue007,
    sliderHandleDisabled: colors.grey9C,
    sliderTackBackgroudColorDisabled: colors.greyE6,

    // Building Block
    defaultBuildingBlockBorder: colors.greyE6,
    defaultBuildingBlockBackground: colors.greyF2,
    defaultBuildingBlockDivider: colors.greyC6,

    // Collapsible Block
    collapsibleBlockHeader: colors.grey7B,
    collapsibleBlockHeaderBackground: colors.greyF2,

    // Dropdown Menu
    dropdownMenuGroupLabelColor: colors.greyC6,

    // Graph Defaults
    graphAxisStrokeColor: colors.greyDD,
    graphAxisGridStrokeColor: colors.greyF2,
    graphAxisLabelColor: colors.greyA6,
    graphGridBorder: colors.greyE6,
    graphDataScatterColor: colors.purple946,
    graphDataLineColor: colors.purpleB19,

    // User Management
    statusColorActive: colors.blue007,
    statusColorInactive: colors.greyC6,

    // Removable Label
    removableLabelCloseButtonFill: colors.grey9B,
    removableLabelBackground: colors.greyF2,
    removableLabelFontColor: colors.black,

    // Notification:
    notificationDotColor: colors.pinkE3,
};

export default LegacyTheme;
