// @flow strict

import styled from 'styled-components';

import { Controls as ReorderControls } from 'components/ReorderControls/styles';

export const TableContainer = styled.div`
    overflow-x: auto;

    ${ReorderControls} {
        width: ${({ displayReorderControlsVertically }: Object) =>
            displayReorderControlsVertically
                ? '20px'
                : '80px'}; // Avoids resize within table context when control is removed
    }
`;
