// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableDisclaimerState } from 'services/Disclaimer/types';

/**
 * Direct selector to the disclaimer state domain
 *
 * TODO: Replace Object type for proper Redux Store Type
 */
export const selectDisclaimerState = () => (state: ImmutableDisclaimerState) =>
    state.get('disclaimers');

/**
 * Select disclaimers.isFetching
 */
export const selectDisclaimersAreFetching = () =>
    createSelector(
        selectDisclaimerState(),
        (substate: ImmutableDisclaimerState) => substate.get('isFetching')
    );

/**
 * Select disclaimers.isCreating
 */
export const selectDisclaimerIsBeingCreated = () =>
    createSelector(
        selectDisclaimerState(),
        (substate: ImmutableDisclaimerState) => substate.get('isCreating')
    );

/**
 * Select disclaimers.disclaimersList
 */
export const selectDisclaimers = () =>
    createSelector(
        selectDisclaimerState(),
        (substate: ImmutableDisclaimerState) => substate.get('disclaimersList')
    );

/**
 * Select disclaimers.errors
 */
export const selectErrors = () =>
    createSelector(
        selectDisclaimerState(),
        (substate: ImmutableDisclaimerState) => substate.get('errors')
    );
