// @flow strict

import { createSelector } from 'reselect';

import { PLANT_STATUSES } from 'utils/constants';

import type { ImmutableReduxState } from '../types';
import type { ImmutablePlant, ImmutablePlantState } from 'services/Plant/types';

/**
 * Direct selector to the plant state domain
 */
export const selectPlantState = () => (state: ImmutableReduxState) => state.get('plant');

/**
 * Select list of plants
 */
export const selectAllPlants = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('plants').toList()
    );

/**
 * Select list of plants filtered by status = ACTIVE
 */
export const selectAllActivePlants = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) =>
            substate
                .get('plants')
                .toList()
                .filter((plant: ImmutablePlant) => plant.get('status') === PLANT_STATUSES.ACTIVE)
    );

/**
 * Select list of plants filtered by status = ACTIVE
 */
export const selectAllInactivePlants = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) =>
            substate
                .get('plants')
                .toList()
                .filter((plant: ImmutablePlant) => plant.get('status') === PLANT_STATUSES.INACTIVE)
    );

/**
 * Select plant errors
 */
export const selectPlantErrors = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('errors')
    );

/**
 * Gets isFetching status while plants are being fetched: to hide list or show loading animation
 */
export const selectPlantsAreFetching = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('isFetching')
    );

/**
 * Gets isUpdating status while plants are being updated: to disable inputs
 */
export const selectPlantsAreUpdating = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('isUpdating')
    );

/**
 * Gets isDeleting status while plants are being fetched: to hide list or show loading animation
 */
export const selectPlantIsDeleting = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('isDeleting')
    );

/**
 * Gets isCreating status while plants are being updated: to disable inputs
 */
export const selectPlantIsCreating = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('isCreating')
    );

/**
 * Select list of queried plants results
 */
export const selectQueriedPlants = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.getIn(['query', 'data']).toList()
    );

/**
 * Select list of queried plants results
 */
export const selectPlantQuery = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('query')
    );

/**
 * Select list of queried dashboard plants results
 */
export const selectSolvExtractDashboardPlants = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('dashboardPlants')
    );

export const selectQueryingDashboardPlantsStatus = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('isQueryingDashboardPlants')
    );

/**
 * Select last page (page count) of queried plants
 */
export const selectQueriedPlantsLastPage = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.getIn(['query', 'lastPage'])
    );

/**
 * Gets isQuerying status while plants are being queried: to hide list or show loading animation
 */
export const selectPlantsAreQuerying = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('isQuerying')
    );

export const selectIsDownloadingTemplate = () =>
    createSelector(
        selectPlantState(),
        (substate: ImmutablePlantState) => substate.get('isDownloadingTemplate')
    );
