// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Authentication Helpers
import { isSolvayUser } from 'utils/authentication';

// Styles
import { Wrapper } from './styles';

// Components
import { SidebarTab } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import type { IsothermManagementSidebarSectionsType } from 'containers/IsothermManagementContainer';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    activeSection: IsothermManagementSidebarSectionsType,
    sectionBody: React.Node,
    handleSectionActivate: (activeSidebarSection: IsothermManagementSidebarSectionsType) => void,
    user: ImmutableUser,
};

/*
    Isotherm Management Sidebar component
 */
class IsothermManagementSidebar extends React.PureComponent<Props, null> {
    onSectionTitleClicked = (activeSidebarSection: IsothermManagementSidebarSectionsType) => () =>
        this.props.handleSectionActivate(activeSidebarSection);

    render() {
        return (
            <Wrapper>
                <SidebarTab
                    handleOnHeaderClick={this.onSectionTitleClicked('ISOTHERMS')}
                    active={this.props.activeSection === 'ISOTHERMS'}
                    title={this.props.intl.formatMessage({
                        id: 'components.IsothermManagementSidebar.Isotherms.title',
                    })}
                    body={this.props.sectionBody}
                />
                {isSolvayUser(this.props.user) && (
                    <SidebarTab
                        handleOnHeaderClick={this.onSectionTitleClicked('MDR_CURVES')}
                        active={this.props.activeSection === 'MDR_CURVES'}
                        title={this.props.intl.formatMessage({
                            id: 'components.IsothermManagementSidebar.MDRCurves.title',
                        })}
                        body={this.props.sectionBody}
                    />
                )}
            </Wrapper>
        );
    }
}

export default injectIntl(IsothermManagementSidebar);
