// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const StyledSVG = styled.svg``;

export const Dash = styled.span`
    display: inline-block;
    width: ${(props) => props.width || 14}px;
    height: ${(props) => props.height || 3}px;
    background-color: ${LegacyTheme.defaultColor};

    vertical-align: inherit;

    ${(props) =>
        props.margin &&
        `
        margin: ${props.margin};
        `};
`;
