// @flow strict

export const QUICK_NAVIGATOR_TYPES = {
    NOTHING: 'NOTHING',
    QUICK_NAVIGATOR_BACK: 'QUICK_NAVIGATOR_BACK',
    MINCHEM_QUICK_NAVIGATOR: 'MINCHEM_QUICK_NAVIGATOR',
    SOLVEXTRACT_QUICK_NAVIGATOR: 'SOLVEXTRACT_QUICK_NAVIGATOR',
    CUSTOM_QUICK_NAVIGATOR: 'CUSTOM_QUICK_NAVIGATOR',
}

export type QuickNavigatorTypes = $Keys<typeof QUICK_NAVIGATOR_TYPES>;
