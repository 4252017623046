// @flow strict

import React from 'react';

// Assets
import loginBackground from 'assets/login-background.jpg';

// Config
import { appBlockingMessage } from 'env';

// Components
import { Common, Modal } from 'components/_ReactUI_V1';
import ModalContent from './ModalContent';
import PlatformAuthenticationLogin from '../../microfrontends/PlatformAuthenticationLogin';

// Constants
import { APPLICATION_TYPES } from 'utils/constants';

// Styles
import { BackgroundImage, LayoutContainer, HiddenButton, AppBlockingMessage } from './styles';
import { ContainerCentered } from 'styles/common';

// Types
import type { ReactNode, ImmutableList } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableDisclaimer } from 'services/Disclaimer/types';

type Props = {
    disclaimers?: ImmutableList<ImmutableDisclaimer>,
    onDisclaimerAcceptance: (id: number) => void,
    isAcceptingDisclaimerPending: boolean,
    user?: ?ImmutableUser,
};

type State = {
    appBlockingMessage: string,
};

export default class Login extends React.Component<Props, State> {
    static defaultProps = {
        disclaimers: null,
        user: null,
    };

    state = {
        appBlockingMessage,
    };

    getIsLoggedAndAuthorized = () =>
        this.props.user && this.props.user.has('roles') && this.props.user.get('roles').size > 0;

    getShouldAcceptDisclaimers = () =>
        this.getIsLoggedAndAuthorized() && !this.props.disclaimers.isEmpty();

    onAgreement = (id: number) => () => this.props.onDisclaimerAcceptance(id);

    handleDisableAppBlockingMessage = () =>
        this.setState({
            appBlockingMessage: '',
        });

    renderDisclaimerModal = () => {
        if (!this.getShouldAcceptDisclaimers()) {
            return null;
        }

        // Set first disclaimer as active
        const disclaimer = this.props.disclaimers && this.props.disclaimers.get(0);
        return (
            <Modal
                key={disclaimer.get('key')}
                onHandleClose={null}
                modalHeight="600px"
                modalWidth="1088px"
                overlay={false}
                disableClose
                hideCloseIcon
                inlineTrigger
            >
                <ModalContent
                    actionButtonText={disclaimer.get('actionButtonText')}
                    disclaimerId={disclaimer.get('id')}
                    content={disclaimer.get('content')}
                    handleAgreement={this.onAgreement(disclaimer.get('id'))}
                    title={disclaimer.get('title')}
                    uiIsLoading={this.props.isAcceptingDisclaimerPending}
                    warningNote={disclaimer.get('warningNote')}
                />
            </Modal>
        );
    };

    renderContent = () => {
        if (this.state.appBlockingMessage) {
            return (
                <AppBlockingMessage
                    dangerouslySetInnerHTML={{ __html: this.state.appBlockingMessage }}
                />
            );
        }

        if (this.getShouldAcceptDisclaimers()) {
            return this.renderDisclaimerModal();
        }

        return <PlatformAuthenticationLogin />;
    };

    render() {
        return (
            <ContainerCentered>
                {this.state.appBlockingMessage && (
                    <HiddenButton onClick={this.handleDisableAppBlockingMessage} />
                )}
                <LayoutContainer>
                    <Common.Row alignItems="center" justifyContent="center">
                        {this.renderContent()}
                    </Common.Row>
                </LayoutContainer>
                <BackgroundImage backgroundImage={loginBackground} />
            </ContainerCentered>
        );
    }
}
