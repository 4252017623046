// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { QuickNavigatorObservable } from 'components/_FrontendObservables';

// Components
import { SidebarLayout } from 'components/_ReactUI_V1';

import { LayoutContainer } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';
import ArchiveSidebar from 'components/ArchiveSidebar';
import ArchivePageTable from 'components/ArchivePageTable';

// Constants
import { STYLE_VALUES, TRENDS_PAGE_TYPE } from 'utils/constants';

// Helpers
import { isClientUser, getUsersLanguage } from 'utils/authentication';

// Services
import {
    selectCircuit,
    selectCircuitArchive,
    selectCircuitArchiveIsFetching,
    selectCircuitArchiveKpiSettings,
    selectCircuitArchivePlantTimezone,
    selectCircuitArchiveQuery,
    selectPlantArchive,
    selectPlantArchiveIsFetching,
    selectPlantArchiveKpiSettings,
    selectPlantArchiveQuery,
} from 'services/Trends/selectors';
import { selectAllClients } from 'services/Client/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { fetchAllClients } from 'services/Client/thunks';
import { fetchCircuitArchive, fetchPlantArchive } from 'services/Trends/thunks';

// Types
import type { ReduxDispatch, ImmutableList, SearchCriteria, QueryStructure, IntlType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableClient } from 'services/Client/types';
import type {
    ImmutablePlantDataset,
    ImmutableDateRange,
    TrendsPageType,
} from 'services/Trends/types';
import type { ImmutableKPISetting } from 'services/KPISetting/types';
import type { ImmutableDataset } from 'services/Dataset/types';

import type { ImmutableCircuit } from 'services/Circuit/types';

type Props = {
    archiveType: TrendsPageType,
    user: ImmutableUser,

    plantTimezone?: string,

    // if archiveType === CIRCUIT
    circuitId?: ?number,
    circuit?: ImmutableCircuit,
    circuitArchive: ImmutableList<ImmutableDataset>,
    circuitArchiveIsLoading: boolean,
    circuitArchiveQuery: QueryStructure<ImmutableDataset>,
    circuitKpiSettings?: ImmutableList<ImmutableKPISetting>,
    fetchCircuitArchive: (
        circuitId: number,
        dateRange?: ImmutableDateRange,
        recommendationOnly?: boolean,
        structure?: ?SearchCriteria,
        page?: ?number
    ) => void,

    // if archiveType === PLANT
    plantId?: ?number,
    plantArchive: ImmutableList<ImmutablePlantDataset>,
    plantArchiveIsLoading: boolean,
    plantArchiveQuery: QueryStructure<ImmutablePlantDataset>,
    plantKpiSettings?: ImmutableList<ImmutableKPISetting>,
    fetchPlantArchive: (
        plantId: number,
        dateRange?: ImmutableDateRange,
        structure?: ?SearchCriteria,
        page?: ?number
    ) => void,

    clients: ImmutableList<ImmutableClient>,
    fetchAllClients: () => void, // for navigation.

    intl: IntlType,
};

type State = {
    dateRange: ?ImmutableDateRange,
    submittedRecommendationsOnly: boolean,
    currentFetch: ?SearchCriteria,
};

class TrendsArchiveContainer extends React.PureComponent<Props, State> {
    static defaultProps = {
        circuit: null,
        circuitId: null,
        circuitKpiSettings: null,
        plantId: null,
        plantKpiSettings: null,
        plantTimezone: '',
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            dateRange: null,
            submittedRecommendationsOnly: false,
            currentFetch: null,
        };
    }

    componentDidMount() {
        if (this.isInvalidId()) {
            return;
        }

        // On mount, fetch the archive
        this.fetchArchive();

        // for navigation in the quicknavigator
        if (this.props.clients.isEmpty()) {
            this.props.fetchAllClients();
        }

        // TODO: Why was this removed?
        // QuickNavigatorObservable.removeQuickNavigator();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.isInvalidId()) {
            return;
        }

        // If the type, or related id change, re-fetch
        if (
            this.props.archiveType !== prevProps.archiveType ||
            this.props.circuitId !== prevProps.circuitId ||
            this.props.plantId !== prevProps.plantId
        ) {
            this.fetchArchive();
        }
    }

    getKpiSettings = () =>
        this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT
            ? this.props.circuitKpiSettings
            : this.props.plantKpiSettings;

    getArchive = () =>
        this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT
            ? this.props.circuitArchive
            : this.props.plantArchive;

    getArchiveIsLoading = () =>
        this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT
            ? this.props.circuitArchiveIsLoading
            : this.props.plantArchiveIsLoading;

    getArchiveFetch = () =>
        this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT
            ? this.props.circuitArchiveQuery
            : this.props.plantArchiveQuery;

    isInvalidId = () => {
        if (this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT && !this.props.circuitId) {
            return true;
        }
        if (this.props.archiveType === TRENDS_PAGE_TYPE.PLANT && !this.props.plantId) {
            return true;
        }
        return false;
    };

    fetchArchive = (
        dateRange?: ?ImmutableDateRange,
        submittedRecommendationsOnly?: boolean,
        structure?: ?SearchCriteria,
        page?: ?number
    ) =>
        this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT
            ? this.props.fetchCircuitArchive(
                  this.props.circuitId,
                  dateRange,
                  submittedRecommendationsOnly,
                  structure,
                  page
              )
            : this.props.fetchPlantArchive(this.props.plantId, dateRange, structure, page);

    /**
     * Queries the archive
     */
    handleSortBy = (structure: ?SearchCriteria) => {
        // If the current request is not the same as the new sort by, trigger it.
        if (this.getArchiveIsLoading() && this.state.currentFetch === structure) {
            return;
        }

        let currentFetch = structure;
        if (structure && (!structure.sortBy || !structure.sortOrder)) {
            // If the structure inside is null, set the state to null.
            currentFetch = null;
        }
        this.setState({ currentFetch }, () =>
            this.fetchArchive(
                this.state.dateRange,
                this.state.submittedRecommendationsOnly,
                this.state.currentFetch,
                1
            )
        );
    };

    /**
     * Fetch a specific page using the current fetch order
     */
    handleChangePage = (page: number, total: number) => () => {
        if (page > total || page < 0) {
            return null;
        }
        this.fetchArchive(
            this.state.dateRange,
            this.state.submittedRecommendationsOnly,
            this.state.currentFetch,
            page
        );
    };

    handleFilter = (dateRange: ?ImmutableDateRange, submittedRecommendationsOnly: boolean) => {
        this.setState(
            {
                dateRange,
                submittedRecommendationsOnly,
            },
            () =>
                this.fetchArchive(
                    this.state.dateRange,
                    this.state.submittedRecommendationsOnly,
                    this.state.currentFetch,
                    1
                )
        );
    };

    /**
     * If the user manually entered an ID in the URL and it is not a number
     */
    renderIDError = () => (
        <LayoutContainer>
            <ErrorMessage
                errorCode={`components.ArchivePageTable.errors.invalidId.${this.props.archiveType}`}
                errorMessage="Invalid ID supplied to TrendsArchiveContainer"
            />
        </LayoutContainer>
    );

    /**
     * Render the dashboard
     */
    renderMainContent = () => {
        if (this.isInvalidId()) {
            return this.renderIDError();
        }
        const archive = this.getArchive();
        const archiveFetch = this.getArchiveFetch();
        return (
            <ArchivePageTable
                archiveType={this.props.archiveType}
                archive={archive}
                circuitId={this.props.circuitId}
                plantId={this.props.plantId}
                loading={this.getArchiveIsLoading()}
                page={archiveFetch && archiveFetch.get('currentPage')}
                lastPage={archiveFetch && archiveFetch.get('lastPage')}
                circuit={this.props.circuit}
                isUserPM={isClientUser(this.props.user)}
                userLocale={getUsersLanguage(this.props.user)}
                kpiSettings={this.getKpiSettings()}
                plantTimezone={this.props.plantTimezone}
                onSortBy={this.handleSortBy}
                onChangePage={this.handleChangePage}
            />
        );
    };

    /**
     * Render the sidebar
     */
    renderSidebar = () => (
        <ArchiveSidebar
            circuitId={this.props.circuitId}
            archiveType={this.props.archiveType}
            loading={this.getArchiveIsLoading()}
            onFilter={this.handleFilter}
        />
    );

    render() {
        return (
            <SidebarLayout
                styles={{
                    sidebar: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                    main: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        overflowY: 'hidden',
                    },
                }}
                renderMain={this.renderMainContent}
                renderSidebar={this.renderSidebar}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                responsiveMaxDeviceWidth={STYLE_VALUES.RESPONSIVE_AT_LARGE}
                responsiveTitle={this.props.intl.formatMessage({
                    id: 'containers.TrendsArchiveContainer.mobileSidebarTitle',
                })}
                responsive
                collapsible
                flush
                mainFlush
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
        clients: selectAllClients(),

        plantTimezone: selectCircuitArchivePlantTimezone(),

        circuit: selectCircuit(),
        circuitArchive: selectCircuitArchive(),
        circuitArchiveQuery: selectCircuitArchiveQuery(),
        circuitArchiveIsLoading: selectCircuitArchiveIsFetching(),
        circuitKpiSettings: selectCircuitArchiveKpiSettings(),

        plantArchive: selectPlantArchive(),
        plantArchiveQuery: selectPlantArchiveQuery(),
        plantArchiveIsLoading: selectPlantArchiveIsFetching(),
        plantKpiSettings: selectPlantArchiveKpiSettings(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllClients,
            fetchCircuitArchive,
            fetchPlantArchive,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(TrendsArchiveContainer))
);
