// @flow strict

import { createSelector } from 'reselect';

import type { ImmutableReduxState } from '../types';
import type { ImmutableCircuitState } from 'services/Circuit/types';

/**
 * Direct selector to the Circuit state domain
 */
export const selectCircuitState = () => (state: ImmutableReduxState) => state.get('circuit');

/**
 * Get the circuit fetch for the current request.
 */
export const selectMinChemCircuitQuery = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('queryMinChem')
    );

/**
 * Get the circuit fetch for the current request.
 */
export const selectSolvExtractCircuitQuery = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('querySolvExtract')
    );

/**
 * Get the circuit errors
 */
export const selectCircuitErrors = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('errors')
    );

/**
 * Get the circuit fetch errors
 */
export const selectCircuitFetchErrors = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('fetchErrors')
    );

/**
 * Gets isQueryingMinChem status while MinChem Circuits are being fetched: to hide list or show loading animation
 */
export const selectMinChemIsQueryingStatus = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('isQueryingMinChem')
    );

/**
 * Gets isQueryingSolvExtract status while SolvExtract Circuits are being fetched: to hide list or show loading animation
 */
export const selectSolvExtractIsQueryingStatus = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('isQueryingSolvExtract')
    );

/**
 * Gets isFetching status while Circuits are being fetched: to hide list or show loading animation
 */
export const selectCircuitIsFetchingStatus = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('isFetching')
    );

/**
 * Gets isUpdating status while Circuits are being updated: to disable inputs
 */
export const selectCircuitsAreUpdating = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('isUpdating')
    );

/**
 * Gets isDeleting status while Circuits are being fetched: to hide list or show loading animation
 */
export const selectCircuitsAreDeleting = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('isDeleting')
    );

/**
 * Gets isUpdating status while Circuits are being updated: to disable inputs
 */
export const selectCircuitsAreCreating = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('isCreating')
    );

/**
 * Get if the circuit is being elevated to a sovlextract circuit
 */
export const selectCircuitIsElevating = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('isElevating')
    );

/**
 * Get the circuit elevation errors
 */
export const selectCircuitElevationErrors = () =>
    createSelector(
        selectCircuitState(),
        (substate: ImmutableCircuitState) => substate.get('elevationErrors')
    );
