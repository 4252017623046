// @flow strict

import styled from 'styled-components';

export const List = styled.ul`
    margin: 0;
    padding: 0;

    li {
        display: block;
        margin: 12px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const DataPointRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const DataPointColumn = styled.div`
    display: flex;
    flex: 1;
`;

export const DataPointContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    ${DataPointRow} {
        padding: 6px 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    ${DataPointColumn} {
        padding: 0 12px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
`;
