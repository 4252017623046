// @flow strict

import styled from 'styled-components';

import { Row } from 'components/_ReactUI_V1';

export const IsothermNavigationButtonStyles = {
    width: '100%',
    height: '36px',
};

// TODO: This currently breaks because the React UI is not built properly? Some css classes arent exported properly.
// this is a way to force our UI to use the proper styling.
export const CustomRow = styled(Row)`
    > * {
        padding: 0 12px;
    }

    *:first-child {
        padding-left: 0;
    }
    *:last-child {
        padding-right: 0;
    }
`;

export const Label = styled.span`
    font-size: 15px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    margin-right: 13px;
`;

export const IconsWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;

export const CheckboxLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 240px;
    overflow: hidden;
    align-items: center;
    padding-right: 13px;
`;

export const TrashStyles = {
    width: '34px',
    height: '34px',
    flexShrink: '0',
    color: '#C6C7C8',
};
