// @flow

import React from 'react';

type ListLayoutIconType = {
    height?: ?number,
    width?: ?number,
    fill?: ?string,
};

export function ListLayoutIcon(props: ListLayoutIconType) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 17 16"
            fill={props.fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15.2105 1.77778V5.33333H1.78947V1.77778H15.2105ZM15.2105 10.6667V14.2222H1.78947V10.6667H15.2105ZM16.1053 0H0.894737C0.402632 0 0 0.4 0 0.888889V6.22222C0 6.71111 0.402632 7.11111 0.894737 7.11111H16.1053C16.5974 7.11111 17 6.71111 17 6.22222V0.888889C17 0.4 16.5974 0 16.1053 0ZM16.1053 8.88889H0.894737C0.402632 8.88889 0 9.28889 0 9.77778V15.1111C0 15.6 0.402632 16 0.894737 16H16.1053C16.5974 16 17 15.6 17 15.1111V9.77778C17 9.28889 16.5974 8.88889 16.1053 8.88889Z" />
        </svg>
    );
}

ListLayoutIcon.defaultProps = {
    height: 20,
    width: 40,
    fill: 'currentColor',
};
