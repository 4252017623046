/* @flow strict */

/**
 * Gets the URL of the circuit setup in MinChemV3.
 * @param {*} circuitId
 * @returns a URL
 */
const getCircuitSetupUrl = (circuitId: number) => `/minchem/circuit/${circuitId}/setup`

/**
 * Gets the URL of a dataset in MinChemV3.
 * @param {*} circuitId
 * @param {*} datasetId
 * @returns a URL
 */
const getCircuitDatasetUrl = (circuitId: number, datasetId: number) => `/minchem/circuit/${circuitId}/dataset/${datasetId}`

export const MinChemMfeUrls = {
    getCircuitSetupUrl,
    getCircuitDatasetUrl
}
