// @flow

import React from 'react';
import { VictoryArea, VictoryChart, VictoryGroup, VictoryLabel, VictoryPolarAxis } from 'victory'; // https://github.com/FormidableLabs/victory

// Styles
import { VictoryData, victoryPropertyAxis } from './styles';

type DataType = {};

type DataSetType = {
    color: string,
    data: DataType,
    id: number,
    name: string,
};

type Props = {
    axisLabels?: Array<string>,
    dataSets?: Array<DataSetType>,
    maxValue?: number,
};

type State = {
    dataSets?: ?Array<DataSetType>,
    maxima?: ?Object,
};

export default class GraphRadar extends React.PureComponent<Props, State> {
    static defaultProps = {
        axisLabels: null,
        dataSets: null,
        maxValue: null,
    };

    constructor(props: Props) {
        super(props);
        const hasRealData = Boolean(this.props.dataSets && this.props.dataSets.length > 0);
        this.state = {
            dataSets: hasRealData ? this.processData(this.props.dataSets) : null,
            maxima: hasRealData ? this.getMaxima(this.props.dataSets) : null,
        };
    }

    componentDidUpdate(prevProps: Props) {
        const hasRealData = Boolean(this.props.dataSets && this.props.dataSets.length > 0);
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (this.props.dataSets !== prevProps.dataSets) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                dataSets: hasRealData ? this.processData(this.props.dataSets) : null,
                maxima: hasRealData ? this.getMaxima(this.props.dataSets) : null,
            });
        }
    }

    getMaxima = (dataSets: Array<DataSetType>) => {
        const groupedData = Object.keys(dataSets[0].data).reduce((memo: Object, key: string) => {
            memo[key] = dataSets.map((d: DataSetType) => d.data[key]);
            return memo;
        }, {});
        return Object.keys(groupedData).reduce((memo: Object, key: string) => {
            memo[key] = this.props.maxValue || Math.max(...groupedData[key]);
            return memo;
        }, {});
    };

    processData = (dataSets: Array<DataSetType>) => {
        const maxByGroup = this.getMaxima(dataSets);
        const makeDataArray = (d: DataType) =>
            Object.keys(d).map((key: string) => ({
                x: key,
                y: d[key] / maxByGroup[key],
            }));

        return dataSets.map((dataSet: DataSetType) => ({
            id: dataSet.id,
            data: makeDataArray(dataSet.data),
            color: dataSet.color,
        }));
    };

    renderEmpty = () => '';

    render() {
        if (!this.state.dataSets && !this.props.axisLabels) {
            return null;
        }

        // Start of graph, note the graph starts at 3 o'clock and rotates counter clockwise
        const startAngle = 90;

        const isEmptyGraph = !this.state.maxima;
        const labelsArray = isEmptyGraph ? this.props.axisLabels : Object.keys(this.state.maxima);

        if (!labelsArray) {
            return null;
        }

        const dataCount = labelsArray && labelsArray.length;

        const victoryAreas =
            this.state.dataSets &&
            this.state.dataSets.map((set: DataSetType) => {
                const styles = {
                    data: {
                        ...VictoryData.data,
                        fill: set.color,
                    },
                };
                return <VictoryArea key={set.id} style={styles} data={set.data} />;
            });

        const victoryAxises = labelsArray.map((key: string, i: number) => {
            // Gets angle of axis based on amount of axises + start angle
            const angle = dataCount ? (360 / dataCount) * i + startAngle : null;
            return (
                <VictoryPolarAxis
                    key={key}
                    style={victoryPropertyAxis(i === 1, [162, 378].includes(angle))}
                    // circularAxisComponent={/* TODO: Hexagon <path /> */}
                    axisLabelComponent={<VictoryLabel />}
                    labelPlacement="vertical"
                    axisAngle={angle}
                    startAngle={90}
                    label={key}
                    tickFormat={this.renderEmpty}
                    tickValues={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
                    dependentAxis
                />
            );
        });

        return (
            <VictoryChart
                domain={{ y: [0, 1] }}
                width={400}
                height={400}
                startAngle={startAngle}
                endAngle={360 + startAngle}
                responsive
                polar
            >
                {victoryAxises}
                {!isEmptyGraph && <VictoryGroup>{victoryAreas}</VictoryGroup>}
            </VictoryChart>
        );
    }
}
