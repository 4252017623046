// @flow strict

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// Components
import TrendsArchiveContainer from 'containers/TrendsArchiveContainer';

// constants
import { TRENDS_PAGE_TYPE } from 'utils/constants';

// Types
import type { IntlType, Navigation } from 'types';

type Props = {
    intl: IntlType,
    match: {
        params: {
            plantId: string,
        },
    },
};

export class PlantArchive extends React.PureComponent<Props, null> {

    getPlantId() {
        const plantId = this.props.match.params.plantId;
        if (plantId) {
            return parseInt(plantId, 10);
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.CircuitDatasetArchive.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.CircuitDatasetArchive.helmetDescription',
                        })}
                    />
                </Helmet>
                <TrendsArchiveContainer
                    archiveType={TRENDS_PAGE_TYPE.PLANT}
                    plantId={this.getPlantId()}
                />
            </React.Fragment>
        );
    }
}

export default injectIntl(PlantArchive);
