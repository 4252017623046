// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

export const SummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 14px 0;

    border-radius: 4px;
    border: 1px solid ${LegacyTheme.defaultBorder};

    &:last-child {
        margin-bottom: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 40px;
    padding: 0 12px 0 12px;

    color: ${LegacyTheme.defaultColor};
    background-color: ${LegacyTheme.defaultBackground};
`;

export const DateTitle = styled.h3`
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    color: ${LegacyTheme.defaultColor};

    margin: 0;
`;

export const Dot = styled.span`
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: black;
`;

export const Label = styled.span`
    display: flex;
    color: ${LegacyTheme.defaultColor};
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;

    color: ${(props: Object) => props.color};

    ${Dot} {
        margin-right: 10px;
        background-color: ${(props: Object) => props.color};
    }

    & + & {
        margin-left: 24px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: ${LegacyTheme.defaultBuildingBlockBackground};
    color: ${LegacyTheme.defaultColor};

    padding: 20px 12px;
`;

export const ClientTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const PlantName = styled.div`
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 14px;
    line-height: normal;
    margin-bottom: 10px;
`;

export const CircuitName = styled.div`
    color: ${LegacyTheme.defaultBoldColor};
    font-size: 25px;
    line-height: 25px;
`;

export const MajorKPIWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
`;

export const KpiCard = styled.div`
    display: flex;
    flex-direction: column;
    & + & {
        margin-left: 12px;
    }
`;

export const KpiCardHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-direction: space-between;
    margin-bottom: 5px;
`;

export const KpiName = styled.div`
    color: ${LegacyTheme.defaultColor};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.3px;
`;

export const KpiIndicator = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
`;

export const KpiValue = styled.div`
    font-size: 30px;
    color: ${({ color }: { color: string }) => color};
    margin-bottom: 5px;
`;

export const KpiUnit = styled.div`
    font-size: 14px;
    color: ${LegacyTheme.defaultSubtleColor};
`;
