// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import PlantsTable from 'components/DashboardPlantsTable/PlantsTable';
import PlantsCards from 'components/DashboardPlantsCards/PlantsCards';
import {
    Common,
    LegacyTheme,
    Pagination,
    InputSearch,
    GridLayoutIcon,
    ListLayoutIcon,
} from 'components/_ReactUI_V1';

// Styles
import { Title } from 'styles/common';
import { Header, Wrapper, Statuses, Status, Label, Dot, IconsWrapper, IconWrapper } from './styles';
import { ColumnForDesktopOnly, ShowForDesktopOnly, ShowForMobileOnly } from 'styles/responsive';

// Types
import type { ImmutableList, SearchCriteria } from 'types';
import type { ImmutablePlant } from 'services/Plant/types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    user: ImmutableUser,
    isAdmin: boolean,
    plants: ImmutableList<ImmutablePlant>,
    isFetchingPlants: boolean,
    page: number,
    lastPage?: number,
    onHandlePlantsChangePage: (page: number) => void,
    onHandlePlantsSortBy: (structure: ?SearchCriteria) => void,

    onSearchClear: () => void,
    onSearchSubmit: () => void,
    onHandleSearchOnChange: () => void,
    onHandleKeyPress: () => void,
    searchValue?: string,
    withSearch?: boolean,
};

type State = {
    displayMode: 'list' | 'grid',
};

class SolvExtractDashboard extends React.Component<Props, State> {
    state = {
        displayMode: this.props.isAdmin ? 'list' : 'grid',
    };

    getTranslation = (key: string) =>
        this.props.intl.formatMessage({
            id: `components.SolvExtractDashboard.${key}`,
        });

    toggleDisplayMode = () => {
        const update = this.state.displayMode === 'list' ? 'grid' : 'list';
        this.setState({ displayMode: update });
    };

    render() {
        return (
            // Reset flexbox to avoid collapse on iOS by setting a non-flex block element
            <div>
                <Wrapper>
                    <Header>
                        <Common.Column flex={5} justifyContent="center" alignItems="flex-start">
                            <Title>{this.getTranslation('title')}</Title>
                        </Common.Column>
                        <ColumnForDesktopOnly flex={5} justifyContent="center" alignItems="center">
                            <Statuses>
                                <Status color={LegacyTheme.defaultSuccessColor}>
                                    <Dot />
                                    <Label>{this.getTranslation('onTarget')}</Label>
                                </Status>
                                <Status color={LegacyTheme.defaultWarningColor}>
                                    <Dot />
                                    <Label>{this.getTranslation('offTarget')}</Label>
                                </Status>
                            </Statuses>
                        </ColumnForDesktopOnly>
                        <ColumnForDesktopOnly flex={5} alignItems="flex-end">
                            <InputSearch
                                handleOnClear={this.props.onSearchClear}
                                handleOnClick={this.props.onSearchSubmit}
                                onChange={this.props.onHandleSearchOnChange}
                                onKeyDown={this.props.onHandleKeyPress}
                                placeholder={this.getTranslation('searchPlaceholder')}
                                value={this.props.searchValue}
                                withSearch={this.props.withSearch}
                            />
                        </ColumnForDesktopOnly>

                        <ColumnForDesktopOnly flex={1} alignItems="flex-end">
                            <IconsWrapper>
                                <IconWrapper
                                    onClick={this.toggleDisplayMode}
                                    active={this.state.displayMode === 'grid'}
                                >
                                    <GridLayoutIcon height="31" width="20" />
                                </IconWrapper>

                                <IconWrapper
                                    onClick={this.toggleDisplayMode}
                                    active={this.state.displayMode === 'list'}
                                >
                                    <ListLayoutIcon height="31" width="20" />
                                </IconWrapper>
                            </IconsWrapper>
                        </ColumnForDesktopOnly>
                    </Header>

                    <ShowForDesktopOnly>
                        {this.state.displayMode === 'list' && (
                            <PlantsTable
                                plants={this.props.plants}
                                isFetchingPlants={this.props.isFetchingPlants}
                                user={this.props.user}
                                onHandleSortBy={this.props.onHandlePlantsSortBy}
                            />
                        )}

                        {this.state.displayMode === 'grid' && (
                            <PlantsCards
                                plants={this.props.plants}
                                isFetchingPlants={this.props.isFetchingPlants}
                                user={this.props.user}
                            />
                        )}
                    </ShowForDesktopOnly>

                    <ShowForMobileOnly>
                        <PlantsCards
                            plants={this.props.plants}
                            isFetchingPlants={this.props.isFetchingPlants}
                            user={this.props.user}
                        />
                    </ShowForMobileOnly>

                    <Pagination
                        currentPage={this.props.page}
                        isLoading={this.props.isFetchingPlants}
                        onPageSelection={this.props.onHandlePlantsChangePage}
                        pagesTotal={this.props.lastPage}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'components.Pagination.summaryJoiner',
                        })}
                    />
                </Wrapper>
            </div>
        );
    }
}

export default injectIntl(SolvExtractDashboard);
