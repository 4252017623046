// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { PageTitleObservable, QuickNavigatorObservable } from 'components/_FrontendObservables';

// Components
import AppLoader from 'components/AppLoader';

// Containers
import SolvExtractElevationContainer from 'containers/CircuitSetupContainer/SolvExtractElevationContainer';

// Services
import {
    selectCircuitIsFetchingStatus,
    selectMinChemCircuitQuery,
    selectSolvExtractCircuitQuery,
} from 'services/Circuit/selectors';
import { fetchCircuit } from 'services/Circuit/thunks';

// Types
import type { ImmutableQueryStructure, ReduxDispatch, HistoryType, IntlType } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';

type Props = {
    intl: IntlType,
    history: HistoryType,

    circuitId: number,

    minChemCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,
    solvExtractCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,

    fetchingSingleCircuit: boolean,
    fetchCircuit: (circuitId: number) => void,
};

type State = {
    circuitToElevate?: ImmutableCircuit,
};

/**
 * TODO: MS-531 - Merge CircuitSetupContainer & SolvExtractElevationContainer
 * This double container was partially removed when we went to a circuit elevate route
 */
class CircuitElevationContainer extends React.PureComponent<Props, State> {
    state = {
        circuitToElevate: null,
    };

    componentDidMount() {
        // Load the circuit in question, because the following process need specific data,
        // don't rely on our local lists of circuits
        this.props.fetchCircuit(this.props.circuitId);
        QuickNavigatorObservable.setQuickNavigatorBackToCallback(
            this.props.intl.formatMessage({
                id: `components.Header.BackToNavigator.circuitOrCircuits`,
            }),
            this.handleReturnToMimicDiagram
        );
        PageTitleObservable.setPageTitle(
            this.props.intl.formatMessage({
                id: `components.Header.routeTitles.CIRCUIT_ELEVATION`,
            })
        );
    }

    /**
     * Once the component updated and is no longer fetching the circuit,
     * assign it to state to be passed down
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.fetchingSingleCircuit && !this.props.fetchingSingleCircuit) {
            this.setState({
                circuitToElevate: this.getCircuit(),
            });
        }
    }

    componentWillUnmount() {
        PageTitleObservable.removePageTitle();
    }

    /**
     * Get the circuit from the list of circuits
     * Possible refactor would be to save a "singleCircuit" in redux, rather than searching through our lists
     */
    getCircuit = () => {
        if (this.props.fetchingSingleCircuit) {
            return null;
        }

        const minChemCircuit = this.props.minChemCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === this.props.circuitId);
        if (minChemCircuit) {
            return minChemCircuit;
        }

        const solvExtractCircuit = this.props.solvExtractCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === this.props.circuitId);
        if (solvExtractCircuit) {
            return solvExtractCircuit;
        }

        throw new Error('Unable to find the requested circuit, something went wrong.');
    };

    /**
     * When a user wants to return to the mimic diagram (pressing back in header or back in the elevation footer)
     */
    handleReturnToMimicDiagram = () =>
        this.setState(
            {
                circuitToElevate: null,
            },
            () => {
                this.props.history.goBack();
            }
        );

    render() {
        const circuit = this.state.circuitToElevate;

        // While we're fetching our circuit to elevate or update, display a loader
        if (!circuit || this.props.fetchingSingleCircuit) {
            return (
                <AppLoader
                    messageId="containers.CircuitElevationContainer.loadingCircuit"
                    loading
                />
            );
        }

        return (
            <SolvExtractElevationContainer
                circuit={circuit}
                onReturnToMimicDiagram={this.handleReturnToMimicDiagram}
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        fetchingSingleCircuit: selectCircuitIsFetchingStatus(),
        minChemCircuitQuery: selectMinChemCircuitQuery(),
        solvExtractCircuitQuery: selectSolvExtractCircuitQuery(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchCircuit,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(CircuitElevationContainer))
);
