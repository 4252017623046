// @flow strict

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

import type { PlantValue } from 'services/PlantDataset/types';

/**
 * Applied via the Trends reducer
 *
 * TODO: Refactor trends store to not have nested datasets
 */
export const CREATE_OR_UPDATE_PLANT_VALUE_SUCCESS =
    'app/services/PlantDataset/CREATE_OR_UPDATE_PLANT_VALUE_SUCCESS';
export const CREATE_OR_UPDATE_PLANT_VALUE_FAILURE =
    'app/services/PlantDataset/CREATE_OR_UPDATE_PLANT_VALUE_FAILURE';
export const IS_CREATING_OR_UPDATING_PLANT_VALUE =
    'app/services/PlantDataset/IS_CREATING_OR_UPDATING_PLANT_VALUE';
export const IS_COMPUTING = 'app/services/PlantDataset/IS_COMPUTING';

export const receivedPlantValueCreateOrUpdateSuccess = (data: PlantValue) => ({
    type: CREATE_OR_UPDATE_PLANT_VALUE_SUCCESS,
    payload: { data },
});

export const receivedPlantValueCreateOrUpdateFailure = (errors: {}) => ({
    type: CREATE_OR_UPDATE_PLANT_VALUE_FAILURE,
    payload: { errors },
});

export const setIsCreatingOrUpdatingPlantValue = (
    isCreatingOrUpdatingPlantValue: boolean = true
) => ({
    type: IS_CREATING_OR_UPDATING_PLANT_VALUE,
    payload: { isCreatingOrUpdatingPlantValue },
});

export const setIsComputingStatus = (isComputing: boolean = true) => ({
    type: IS_COMPUTING,
    payload: { isComputing },
});
