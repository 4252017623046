// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Containers
import { Centered, SidebarLayout, SidebarTab } from 'components/_ReactUI_V1';

import UserManagementContainer from 'containers/ClientPlantUserManagementContainer/UserManagementContainer';
import PlantManagementContainer from 'containers/ClientPlantUserManagementContainer/PlantManagementContainer';
import PlantConfigurationContainer from 'containers/ClientPlantUserManagementContainer/PlantManagementContainer/PlantConfigurationContainer';
import ClientManagementContainer from 'containers/ClientPlantUserManagementContainer/ClientManagementContainer';

// Components

// Services
import { selectUser } from 'services/Authentication/selectors';

// Types
import type { ImmutableUser } from 'services/Authentication/types';
import type {
    ClientPlantUserPageNameConstant,
    HistoryType,
    IntlType,
    LocationType,
    PageNameConstant,
    ReactNode,
    RouteType,
    Navigation,
} from 'types';

// Constants
import { NAVIGATION_ROUTES, ROUTES, ROUTE_NAMES, ROLES, STYLE_VALUES } from 'utils/constants';

// Authentication
import { isSolvayUser, isSysAdmin } from 'utils/authentication';

type Props = {
    location: LocationType,
    intl: IntlType,
    user: ImmutableUser,
    history: HistoryType,
};

type State = {
    activeRouteNameAsSidebarKey: ?PageNameConstant,
};

export class ClientPlantUserManagementContainer extends React.PureComponent<Props, State> {
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const route = ClientPlantUserManagementContainer.getRoute(nextProps.location);
        if (prevState.activeRouteNameAsSidebarKey !== route.name) {
            return {
                activeRouteNameAsSidebarKey: (route && route.name) || ROUTE_NAMES.USERS_PM,
            };
        }
        return null;
    }

    static getRoute = (location: LocationType): RouteType =>
        Object.values(ROUTES).find((route: RouteType) =>
            Boolean(location.pathname.match(route.match))
        );

    constructor(props: Props) {
        super(props);

        const route = ClientPlantUserManagementContainer.getRoute(this.props.location);

        this.state = {
            activeRouteNameAsSidebarKey: (route && route.name) || ROUTE_NAMES.USERS_PM,
        };
    }

    onSidebarTabClick = (path: string) => () => this.props.history.push(path);

    renderMain = () => {
        switch (ClientPlantUserManagementContainer.getRoute(this.props.location).name) {
            case ROUTE_NAMES.USERS_ADMIN:
                return <UserManagementContainer role={ROLES.ADMIN} />;
            case ROUTE_NAMES.USERS_SAM:
                return <UserManagementContainer role={ROLES.SAM} />;
            case ROUTE_NAMES.USERS_PM:
                return <UserManagementContainer role={ROLES.PM} />;
            case ROUTE_NAMES.CLIENTS:
                return <ClientManagementContainer />;
            case ROUTE_NAMES.PLANTS:
                return <PlantManagementContainer />;
            case ROUTE_NAMES.PLANT_CONFIGURATION:
                return <PlantConfigurationContainer routeTitle={ROUTE_NAMES.PLANT_CONFIGURATION} />;
            case ROUTE_NAMES.PLANT_CREATION_V2:
                return <PlantConfigurationContainer routeTitle={ROUTE_NAMES.PLANT_CREATION_V2} />;
            default:
                return (
                    <Centered>
                        {this.props.intl.formatMessage({
                            id: 'containers.ClientPlantUserManagementContainer.nothingToManage',
                        })}
                    </Centered>
                );
        }
    };

    renderSidebar = (): ?Array<ReactNode> => {
        let routes = [];
        if (isSysAdmin(this.props.user)) {
            routes = Object.keys(NAVIGATION_ROUTES.USERS_CLIENTS_PLANTS_ADMIN);
        } else if (isSolvayUser(this.props.user)) {
            routes = Object.keys(NAVIGATION_ROUTES.USERS_CLIENTS_PLANTS_SAM);
        }

        return routes.map((routeName: ClientPlantUserPageNameConstant) => {
            const path = NAVIGATION_ROUTES.USERS_CLIENTS_PLANTS_ADMIN[routeName];
            return (
                <SidebarTab
                    key={routeName}
                    active={routeName === this.state.activeRouteNameAsSidebarKey}
                    handleOnHeaderClick={this.onSidebarTabClick(path)}
                    title={this.props.intl.formatMessage({
                        id: `containers.ClientPlantUserManagementContainer.sidebarTabs.${routeName}`,
                    })}
                />
            );
        });
    };

    render() {
        return (
            <SidebarLayout
                key={this.state.activeRouteNameAsSidebarKey}
                styles={{
                    sidebar: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },

                    main: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        overflowY: 'hidden',
                    },
                }}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                renderMain={this.renderMain}
                renderSidebar={this.renderSidebar}
                collapsible
                mainFlush
                flush
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
});

export default withRouter(connect(mapStateToProps)(injectIntl(ClientPlantUserManagementContainer)));
