// @flow strict

/*
 *
 * Reagent reducer
 *
 */

import { fromJS } from 'immutable';

import type { GenericActionType, ImmutableList } from 'types';

import {
    CREATE_SUCCESS,
    CREATE_FAILURE,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    DESTROY_SUCCESS,
    DESTROY_FAILURE,
    QUERY_LIST_SUCCESS,
    QUERY_LIST_FAILURE,
    IS_FETCHING,
    IS_UPDATING,
    IS_DELETING,
    IS_CREATING,
    IS_QUERYING,
    IS_SETTING_PLANT_ACCESS,
    SET_PLANT_ACCESS_SUCCESS,
    SET_PLANT_ACCESS_FAILURE,
} from 'services/Reagent/actions';

import type { ImmutableReagent, ImmutableReagentState } from 'services/Reagent/types';

const initialState: ImmutableReagentState = fromJS({
    reagents: [],
    query: {
        data: [],
        lastPage: 0,
    },
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isQuerying: false,
    isSettingPlantAccess: false,
    errors: {},
    errorsUpdate: {},
});

function updateReagentInState(reagent: ImmutableReagent, state: ImmutableReagentState) {
    return state
        .updateIn(['reagents'], (reagents: ImmutableList<ImmutableReagent>) => {
            if (reagents && !reagents.isEmpty()) {
                const idx = reagents.findIndex(
                    (r: ImmutableReagent) => r.get('id') === reagent.get('id')
                );
                return idx !== -1 ? reagents.setIn([idx], reagent) : reagents.unshift(reagent);
            }
        })
        .updateIn(['query', 'data'], (reagents: ImmutableList<ImmutableReagent>) => {
            if (reagents && !reagents.isEmpty()) {
                const idx = reagents.findIndex(
                    (r: ImmutableReagent) => r.get('id') === reagent.get('id')
                );
                return idx !== -1 ? reagents.setIn([idx], reagent) : reagents.unshift(reagent);
            }
        });
}

function reagentServiceReducer(
    state: ImmutableReagentState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case IS_FETCHING:
            return state.set('isFetching', action.payload.isFetching);
        case IS_UPDATING:
            return state.set('isUpdating', action.payload.isUpdating);
        case IS_DELETING:
            return state.set('isDeleting', action.payload.isDeleting);
        case IS_CREATING:
            return state.set('isCreating', action.payload.isCreating);
        case IS_QUERYING:
            return state.set('isQuerying', action.payload.isQuerying);
        case IS_SETTING_PLANT_ACCESS:
            return state.set('isSettingPlantAccess', action.payload.isSettingPlantAccess);

        case CREATE_SUCCESS: {
            const reagent = fromJS(action.payload.data);
            return updateReagentInState(reagent, state)
                .set('errors', fromJS({}))
                .set('isCreating', false);
        }
        case UPDATE_SUCCESS: {
            const reagent = fromJS(action.payload.data);
            return updateReagentInState(reagent, state)
                .set('errorsUpdate', fromJS({}))
                .set('isUpdating', false);
        }
        case SET_PLANT_ACCESS_SUCCESS: {
            const reagent = fromJS(action.payload.data);
            return updateReagentInState(reagent, state)
                .set('errorsUpdate', fromJS({}))
                .set('isSettingPlantAccess', false);
        }

        case QUERY_LIST_SUCCESS: {
            const data = action.payload.data;

            return state
                .set('query', fromJS(data || {}))
                .set('errors', fromJS({}))
                .set('isQuerying', false);
        }
        case FETCH_LIST_SUCCESS: {
            const reagents = action.payload.data.reagents;

            return state
                .set('reagents', fromJS(reagents || []))
                .set('errors', fromJS({}))
                .set('isFetching', false);
        }
        case QUERY_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isQuerying', false);
        case FETCH_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isFetching', false);
        case CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isCreating', false);
        case UPDATE_FAILURE:
            return state
                .set('errorsUpdate', fromJS(action.payload.errors))
                .set('isUpdating', false);
        case SET_PLANT_ACCESS_FAILURE:
            return state
                .set('errorsUpdate', fromJS(action.payload.errors))
                .set('isSettingPlantAccess', false);

        case DESTROY_SUCCESS: {
            const reagent = action.payload.data;
            return state
                .updateIn(['reagents'], (reagents: ImmutableList<ImmutableReagent>) => {
                    if (reagents && !reagents.isEmpty()) {
                        const idx = reagents.findIndex(
                            (r: ImmutableReagent) => r.get('id') === parseInt(reagent.id, 10)
                        );
                        return idx !== -1 ? reagents.delete(idx) : reagents;
                    }
                })
                .updateIn(['query', 'data'], (reagents: ImmutableList<ImmutableReagent>) => {
                    if (reagents && !reagents.isEmpty()) {
                        const idx = reagents.findIndex(
                            (r: ImmutableReagent) => r.get('id') === parseInt(reagent.id, 10)
                        );
                        return idx !== -1 ? reagents.delete(idx) : reagents;
                    }
                })
                .set('errors', fromJS({}))
                .set('isDeleting', false);
        }
        case DESTROY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('isDeleting', false);

        default:
            return state;
    }
}

export default reagentServiceReducer;
