// @flow strict

import styled from 'styled-components';

import { LegacyTheme } from 'components/_ReactUI_V1';

import { STYLE_VALUES } from 'utils/constants';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    display: flex;
    =flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: 100%;
    margin: 0 auto;
    padding: 24px 48px;

    height: calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT} - ${STYLE_VALUES.FOOTER.MIN_HEIGHT});
    overflow-y: auto;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    border-top: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    padding: 16px;
    height: ${STYLE_VALUES.FOOTER.MIN_HEIGHT};

    button + button {
        margin-left: 16px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${LegacyTheme.defaultBuildingBlockBackground};
    }
`;

export const FieldHeader = styled.h2`
    line-height: 17px;
    color: ${LegacyTheme.defaultColor};
    font-size: 14px;
    font-weight: bold;

    margin-bottom: 28px;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 24px;
    }
`;

export const CheckBoxNumberField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > *:last-child {
        margin-left: 11px;
    }
`;

export const CheckBoxStyle = {
    fontSize: '14px',
    lineHeight: '17px',
};

export const Label = styled.label`
    min-width: 130px;
    font-size: 14px;
    line-height: 17px;
    color: ${LegacyTheme.defaultSubtleColor7B};
    margin-right: 16px;
    margin-bottom: 5px;
`;

export const WeekDayToggleWrapper = styled.div`
    display: flex;
`;

export const WeekDayToggleLabel = styled.span`
    flex-basis: 168px;
`;

export const WeekDayTimeWrapper = styled.div`
    display: flex;
    margin-top: 16px;
`;

export const WeekDayTimeLabel = styled(Label)`
    margin-left: 10px;
`;

export const WeekDayStartTime = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 12px;
`;

export const WeekDayEndTime = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LoaderWrapper = styled.div`
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NotificationTypesWrapper = styled.div`
    display: flex;

    div {
        height: 30px;
    }
`;

export const PlantSettingWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 12px;
`;

export const NotificationTypeRow = styled.div`
    flex-basis: 82px;
`;

export const PhoneNumber = styled.span`
    color: ${LegacyTheme.defaultSubtleColor7B};
`;

export const MessageWrapper = styled.div`
    margin: 24px 0;
`;
