// @flow strict

import styled from 'styled-components';

import { STREAM_CIRCUITS } from 'utils/constants';

export const RemovableLabelWrapper = styled.div`
    width: ${({ width }) => width};
    margin-left: auto;
    margin-right: auto;
`;
