// @flow strict

import request from 'services/request';

import type { PlantValue, RawPlantValue } from 'services/PlantDataset/types';

export const postPlantValue = (
    plantId: number,
    plantDatasetId: number,
    data: $Shape<RawPlantValue>
) => {
    return request(`plant/${plantId}/plant-dataset/${plantDatasetId}/plant-value`, {
        data,
        method: 'POST',
    });
};

export const putPlantValue = (
    plantId: number,
    plantDatasetId: number,
    plantValueId: number,
    data: $Shape<PlantValue>
) => {
    return request(`plant/${plantId}/plant-dataset/${plantDatasetId}/plant-value/${plantValueId}`, {
        data,
        method: 'PUT',
    });
};

export const _recomputeSolvExtractDataset = (plantId: number, plantDatasetId: number) => {
    return request(`plant/${plantId}/dataset/${plantDatasetId}/recompute`, {
        method: 'POST',
    });
};
