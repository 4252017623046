// @flow strict

import { fromJS } from 'immutable';

// Constants
import { MAJOR_KPIS, KPI_SPECIFICITY_TYPES, DATASET_STATUSES } from 'utils/constants';
import { datasetStatusColors } from 'styles/colors';

// Helpers
import { parseStringToUTCDate } from 'utils/dateHelpers';

// Types
import type { ImmutableList } from 'types';
import type { LooseImmutableDataset } from 'services/types';
import type {
    ImmutableCircuitTrend,
    ImmutablePlantTrend,
    ImmutableKPIDataPoint,
    ImmutablePlantValue,
    TrendsPageType,
    ImmutablePlantDataset,
} from 'services/Trends/types';
import type {
    ImmutableDatasetValue,
    ImmutableStageValue,
    ImmutableStreamValue,
    ImmutableDataset,
    DatasetStatusType,
} from 'services/Dataset/types';
import type { ImmutableKPISetting, ImmutableKPICard } from 'services/KPISetting/types';

export type ImmutableTrend = ImmutableCircuitTrend | ImmutablePlantTrend;

export const getValueFromPlantDatasetByKpiSpecificity = (
    plantDataset: ImmutablePlantDataset,
    kpiSetting: ImmutableKPISetting
) => {
    switch (kpiSetting.get('specificityType')) {
        case KPI_SPECIFICITY_TYPES.PLANT: {
            return plantDataset
                .get('plantValues')
                .find(
                    (plant: ImmutablePlantValue) =>
                        plant.get('valueType') === kpiSetting.get('kpiType')
                );
        }
        default: {
            throw new Error('Cannot handle KPI Specificity type provided.');
        }
    }
};

export const getValueFromDatasetByKpiSpecificity = (
    dataset: ImmutableDataset,
    kpiSetting: ImmutableKPISetting
) => {
    switch (kpiSetting.get('specificityType')) {
        case KPI_SPECIFICITY_TYPES.CIRCUIT: {
            return dataset
                .get('datasetValues')
                .find(
                    (datasetValue: ImmutableDatasetValue) =>
                        datasetValue.get('valueType') === kpiSetting.get('kpiType')
                );
        }
        case KPI_SPECIFICITY_TYPES.STAGE: {
            return dataset
                .get('stageValues')
                .find(
                    (stageValue: ImmutableStageValue) =>
                        stageValue.get('valueType') === kpiSetting.get('kpiType') &&
                        stageValue.get('stageId') === kpiSetting.get('stageId')
                );
        }
        case KPI_SPECIFICITY_TYPES.STREAM: {
            return dataset
                .get('streamValues')
                .find(
                    (streamValue: ImmutableStreamValue) =>
                        streamValue.get('valueType') === kpiSetting.get('kpiType') &&
                        streamValue.get('streamId') === kpiSetting.get('streamId')
                );
        }
        // case KPI_SPECIFICITY_TYPES.CASCADE: {
        // }
        default: {
            throw new Error('Cannot handle KPI Specificity type provided.');
        }
    }
};

export const getMinorKPIs = (
    kpis: ImmutableList<ImmutableKPICard>,
    trendType: TrendsPageType
): ImmutableList<ImmutableKPICard> => {
    if (!trendType) {
        throw new Error('Get Minor KPI data called on null trend');
    }
    const majorKpis = MAJOR_KPIS[trendType];
    return kpis.filter((kpi: ImmutableKPICard) => majorKpis.indexOf(kpi.get('kpiType')) === -1);
};

export const getMajorKPIs = (
    kpis: ImmutableList<ImmutableKPICard>,
    trendType: TrendsPageType
): ImmutableList<ImmutableKPICard> => {
    if (!trendType) {
        throw new Error('Get Major KPI data called on null trend');
    }
    const majorKpis = MAJOR_KPIS[trendType];
    return kpis.filter((kpi: ImmutableKPICard) => majorKpis.indexOf(kpi.get('kpiType')) !== -1);
};

/**
 * Get dataset status color for: invalid, converged or diverged
 */
export const getDatasetStatusDotColor = (datasetStatus: DatasetStatusType) => {
    switch (datasetStatus) {
        case DATASET_STATUSES.CONVERGED:
        case DATASET_STATUSES.ALL_CONVERGED:
            return datasetStatusColors.converged;
        case DATASET_STATUSES.DIVERGED:
        case DATASET_STATUSES.SOME_DIVERGED:
            return datasetStatusColors.diverged;
        case DATASET_STATUSES.NOT_COMPUTED_YET:
        case DATASET_STATUSES.SOME_NOT_COMPUTED_YET:
            return datasetStatusColors.notComputedYet;
        case DATASET_STATUSES.INVALID:
        case DATASET_STATUSES.SOME_INVALID:
            return datasetStatusColors.invalid;
        default:
            return 'useCurrent';
    }
};
