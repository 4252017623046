// @flow strict

import { createUntranslatedFeedback } from 'services/Feedback/actions';
import { report } from 'services/Errors/resources';

import {
    receivedKPISettingsUpdateSuccess,
    receivedKPISettingsUpdateFailure,
    setIsUpdatingKPISettings,
} from './actions';
import { _updateKPISettings } from './resources';

import type { ReduxDispatch, ResponseErrorType, SearchCriteria, QueryStructure } from 'types';
import type { KPISetting } from 'services/KPISetting/types';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Updates the KPI's settings
 */
export const updateKPISettings = (kpiId: number, data: $Shape<KPISetting>) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingKPISettings());
    _updateKPISettings(kpiId, data)
        .then((response: KPISetting) => {
            dispatch(receivedKPISettingsUpdateSuccess(response));
            dispatch(
                createUntranslatedFeedback('SUCCESS', 'feedback.success.updateKpiSettingsSuccess')
            );
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedKPISettingsUpdateFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateKpiSettingsFailure')
            );
        });
};
