// @flow

import React from 'react';
import Select, { components } from 'react-select';

// Styles
import { Caret } from '../../icons';
import reactSelectCustomStyles from './styles';
import { InputValue as StyledInputValue } from '../input-field/base';

type OptionType = { value: string, label: string };

type Props = {
    innerSelectRef?: React.Ref,
    renderString?: boolean,
    controlShouldRenderValue?: boolean,
    placeholder?: string,
    selectedOption?: OptionType,
    options?: Array<OptionType>,
    isDisabled?: boolean,
    isLoading?: boolean,
    isSearchable?: boolean,
    maxMenuHeight?: ?number,
    noOptionsMessage?: string,
    hideDropdownIndicator?: boolean,
    onSelect: (OptionType) => void,
    onSearch?: ?(text: string) => void,
    onFocus?: ?(event: InputEvent) => void,
    onBlur?: ?(event: InputEvent) => void,
    onScrollToBottom?: ?() => void,
    styles?: {
        valueContainerFontSize?: string,
        groupLabelFontSize?: string,
        groupLabelColor?: string,
        groupLabelTextTransform?: string,
    },
};

// Custom dropdown indicator for React Select
const DropdownIndicator = (props: Object) =>
    components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <Caret margin="0 0 0 5px" down black />
        </components.DropdownIndicator>
    );

export default class InputSelect extends React.PureComponent<Props> {
    static defaultProps = {
        controlShouldRenderValue: false,
        hideDropdownIndicator: false,
        isDisabled: false,
        isLoading: false,
        isSearchable: true,
        maxMenuHeight: null,
        noOptionsMessage: 'no options',
        onScrollToBottom: null,
        onSearch: null,
        onFocus: null,
        onBlur: null,
        options: [],
        placeholder: 'Select...',
        renderString: false,
        selectedOption: null,
        styles: {},
    };

    // Select component expects a function which returns the no options message.
    getNoOptionsMessage = () => this.props.noOptionsMessage;

    // Mapping custom components to React-Select via returned object.
    getCustomComponents = () => ({
        DropdownIndicator: this.props.hideDropdownIndicator ? null : DropdownIndicator,
    });

    // Returns an object used to style the Select Input.
    // Styles is an object containing JavaScript styles like { fontSize: '16px' }
    getCustomStyles = () => reactSelectCustomStyles(this.props.styles);

    render() {
        if (this.props.renderString) {
            return (
                <StyledInputValue>
                    {(this.props.selectedOption && this.props.selectedOption.label) || '—'}
                </StyledInputValue>
            );
        }

        return (
            <Select
                ref={this.props.innerSelectRef}
                components={this.getCustomComponents()}
                controlShouldRenderValue={this.props.controlShouldRenderValue}
                isDisabled={this.props.isDisabled}
                isLoading={this.props.isLoading}
                isSearchable={this.props.isSearchable}
                noOptionsMessage={this.getNoOptionsMessage}
                onChange={this.props.onSelect}
                onInputChange={this.props.onSearch}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                maxMenuHeight={this.props.maxMenuHeight}
                options={this.props.options}
                placeholder={this.props.placeholder}
                styles={this.getCustomStyles()}
                value={this.props.selectedOption}
                onMenuScrollToBottom={this.props.onScrollToBottom}
            />
        );
    }
}
