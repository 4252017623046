// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { SidebarSection, CheckBox } from 'components/_ReactUI_V1';

import {
    Body,
    Label,
    Field,
    StageInputNumber,
    FlexboxSpacer,
    SidebarSectionWrapper,
    SectionTitle,
} from '../styles';

// Components

// Types
import type { IntlType, InputEvent } from 'types';
import type { WashPosition } from 'containers/CircuitSetupContainer';
import type { StageTypeConstant } from 'services/Circuit/types';
import { STAGE_TYPES } from '../../../utils/constants';

type Props = {
    intl: IntlType,
    loadingCircuit: boolean,
    extractStageCount: number,
    stripStageCount: number,
    washerETSStageActive: boolean,
    washerSTEStageActive: boolean,
    loadedOrganicTankActive: boolean,

    onStageCountChange: (type: StageTypeConstant, value: number) => void,
    onWashActiveChange: (location: WashPosition, active: boolean) => void,
    onTankChange: (type: StageTypeConstant, active: boolean) => void,
};

class StagesSidebarSection extends React.PureComponent<Props, null> {
    handleStageCountChange = (type: StageTypeConstant) => (event: InputEvent) =>
        this.props.onStageCountChange(type, Number(event.target.value));

    handleWashStageChange = (type: WashPosition) => (event: InputEvent) =>
        this.props.onWashActiveChange(type, Boolean(event.target.checked));

    handleTankStageChange = (type: StageTypeConstant) => (event: InputEvent) =>
        this.props.onTankChange(type, Boolean(event.target.checked));

    render() {
        return (
            <SidebarSectionWrapper>
                <SidebarSection>
                    <SectionTitle>
                        {this.props.intl.formatMessage({
                            id: 'components.CircuitSetupSidebar.stagesSection.title',
                        })}
                    </SectionTitle>
                    <Body>
                        <Field>
                            <Label noMargin>
                                {this.props.intl.formatMessage({
                                    id:
                                        'components.CircuitSetupSidebar.stagesSection.extractionUnits.Label',
                                })}
                            </Label>
                            <StageInputNumber
                                value={this.props.extractStageCount || ''}
                                disabled={this.props.loadingCircuit}
                                onChange={this.handleStageCountChange('EXTRACT')}
                                min={0}
                                max={10}
                                style={{ height: '30px', width: '62px' }}
                            />
                            <FlexboxSpacer />
                        </Field>
                        <Field>
                            <Label noMargin>
                                {this.props.intl.formatMessage({
                                    id:
                                        'components.CircuitSetupSidebar.stagesSection.strippingUnits.Label',
                                })}
                            </Label>
                            <StageInputNumber
                                value={this.props.stripStageCount || ''}
                                disabled={this.props.loadingCircuit}
                                onChange={this.handleStageCountChange('STRIP')}
                                min={0}
                                max={10}
                                style={{ height: '30px', width: '62px' }}
                            />
                            <FlexboxSpacer />
                        </Field>
                    </Body>
                </SidebarSection>
                {this.props.extractStageCount > 0 && this.props.stripStageCount > 0 && (
                    <SidebarSection>
                        <SectionTitle>
                            {this.props.intl.formatMessage({
                                id: 'components.CircuitSetupSidebar.washingSection.title',
                            })}
                        </SectionTitle>
                        <Body>
                            <Field>
                                <CheckBox
                                    label={this.props.intl.formatMessage({
                                        id:
                                            'components.CircuitSetupSidebar.washingSection.extractToStrip.Label',
                                    })}
                                    checked={this.props.washerETSStageActive}
                                    disabled={this.props.loadingCircuit}
                                    onClickHandler={this.handleWashStageChange('ETS')}
                                    name="washerETSCheckbox"
                                />
                            </Field>
                            <Field>
                                <CheckBox
                                    label={this.props.intl.formatMessage({
                                        id:
                                            'components.CircuitSetupSidebar.washingSection.stripToExtract.Label',
                                    })}
                                    checked={this.props.washerSTEStageActive}
                                    disabled={this.props.loadingCircuit}
                                    onClickHandler={this.handleWashStageChange('STE')}
                                    name="washerSTECheckbox"
                                />
                            </Field>
                        </Body>
                    </SidebarSection>
                )}
                {this.props.extractStageCount > 0 && this.props.stripStageCount > 0 && (
                    <SidebarSection>
                        <SectionTitle>
                            {this.props.intl.formatMessage({
                                id: 'components.CircuitSetupSidebar.tankSection.title',
                            })}
                        </SectionTitle>
                        <Body>
                            <Field>
                                <CheckBox
                                    label={this.props.intl.formatMessage({
                                        id:
                                            'components.CircuitSetupSidebar.tankSection.loadedOrganicTank.Label',
                                    })}
                                    checked={this.props.loadedOrganicTankActive}
                                    disabled={this.props.loadingCircuit}
                                    onClickHandler={this.handleTankStageChange(
                                        STAGE_TYPES.ORGANIC_TANK
                                    )}
                                    name="loadedOrganicTankCheckbox"
                                />
                            </Field>
                        </Body>
                    </SidebarSection>
                )}
            </SidebarSectionWrapper>
        );
    }
}

export default injectIntl(StagesSidebarSection);
