// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { PrimaryButton, TertiaryButton } from 'components/_ReactUI_V1';

// Styles
import { Content, Footer, Header, Modal, Note } from './styles';

// Services
import { reportError } from 'services/Errors/thunks';

// Types
import type { ReduxDispatch } from 'types';
import type { ErrorType } from 'services/Errors/types';

type Props = {
    actionButtonText: string,
    content: string,
    disclaimerId: number,
    handleAgreement: () => void,
    reportError: (error: ErrorType) => void,
    title: string,
    uiIsLoading: boolean,
    warningNote: string,
};

type State = {
    displayWarning?: boolean,
    enableButton?: boolean,
};

const outerPadding = 24;
const prepNumber = (number: number) => Math.round(Math.abs(Number(number)));

export class ModalContent extends React.Component<Props, State> {
    outer: Element;

    inner: Element;

    state = {
        displayWarning: false,
        enableButton: false,
    };

    /**
     * On mount of the component, initialize.
     */
    componentDidMount() {
        this.handleInitialization();
    }

    componentDidUpdate(nextProps: Props) {
        // If disclaimer has changed, reinitialize
        if (nextProps.disclaimerId !== this.props.disclaimerId) {
            this.handleInitialization();
        }
    }

    /**
     * Validate if inner and outer elements can be found
     * If so, ensure the disclaimer content is larger than the outer height, else unblock ui
     * Else, disable the bottom and scroll to top of outer div (new disclaimer)
     */
    handleInitialization = () => {
        const inner = this.inner && this.inner.getBoundingClientRect();
        const outer = this.outer && this.outer.getBoundingClientRect();

        if (!this.inner || !this.outer) {
            this.handleReportError();
        } else {
            // If the outer (scroll) height is larger than the inner (text), enable button as user cannot scroll
            if (prepNumber(outer.height) >= prepNumber(inner.height)) {
                this.handleUnblockUI();
            } else {
                this.setState(
                    {
                        enableButton: false,
                    },
                    () => {
                        this.outer.scrollTop = 0;
                    }
                );
            }
        }
    };

    handleUnblockUI = () =>
        this.setState({
            displayWarning: false,
            enableButton: true,
        });

    handleReportError = (context: string = 'on scroll') => {
        this.props.reportError({
            message: `Unable to access getBoundingClientRect of the outer/inner element ${context}, allow user to accept disclaimer regardless.`,
        });
        this.handleUnblockUI();
    };

    /**
     * Track scrolling; on scroll check when at bottom of inner div and set enableButton to true, reset displayWarning
     */
    handleScroll = (event: { target: HTMLElement }) => {
        const outer = event.target;
        const inner = event.target.getElementsByTagName('div')[0];

        // Edge case; we've had some reports of users not being able to submit the disclaimer
        if (!inner || !outer) {
            this.handleReportError();
        }

        const outerBoundingClientRect = outer.getBoundingClientRect();
        const innerBoundingClientRect = inner.getBoundingClientRect();

        const expected = prepNumber(outerBoundingClientRect.bottom);
        const actual = prepNumber(innerBoundingClientRect.bottom + outerPadding);

        // Check if scroll distance is within 2% of what it should be, if so enable button
        if (expected / actual >= 0.95) {
            this.handleUnblockUI();
        }
    };

    createMarkup = () => ({ __html: this.props.content });

    /**
     * Set displayWarning to true
     */
    handleDisplayWarning = () =>
        this.setState({
            displayWarning: true,
        });

    render() {
        let button;
        if (this.props.handleAgreement) {
            if (this.state.enableButton) {
                button = (
                    <PrimaryButton
                        loading={this.props.uiIsLoading}
                        onClick={!this.props.uiIsLoading ? this.props.handleAgreement : null}
                        text={this.props.actionButtonText}
                    />
                );
            } else {
                button = (
                    <TertiaryButton
                        onClick={this.handleDisplayWarning}
                        style={{
                            cursor: 'not-allowed',
                        }}
                        text={this.props.actionButtonText}
                    />
                );
            }
        }

        return (
            <Modal>
                <Header>{this.props.title}</Header>
                <Content>
                    <div
                        ref={(elem: Element) => (this.outer = elem)}
                        onScroll={this.handleScroll}
                        style={{ padding: outerPadding, paddingBottom: 0 }}
                    >
                        <div ref={(elem: Element) => (this.inner = elem)}>
                            <div dangerouslySetInnerHTML={this.createMarkup()} />
                            {/* Browser bug, bottom padding of outer element not being applied - https://bugzilla.mozilla.org/show_bug.cgi?id=748518 */}
                            <div style={{ height: outerPadding }} />
                        </div>
                    </div>
                </Content>
                <Footer>
                    <div>
                        {this.props.warningNote && (
                            <Note warning={this.state.displayWarning}>
                                {this.state.displayWarning && '* '}
                                {this.props.warningNote}
                            </Note>
                        )}
                        {button}
                    </div>
                </Footer>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            reportError,
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(ModalContent);
