// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import { PrimaryButton } from 'components/_ReactUI_V1';
import DateRangeSection from 'components/ArchiveSidebar/DateRangeSection';
import RecommendationSetsOptionsSection from 'components/ArchiveSidebar/RecommendationSetsOptionsSection';

// Constants
import { TRENDS_PAGE_TYPE } from 'utils/constants';

// Styles
import { Wrapper, SidebarBody, FooterSection } from './styles';

// Types
import type { IntlType, InputEvent } from 'types';
import type { ImmutableDateRange, TrendsPageType } from 'services/Trends/types';

export const DATE_RANGE_RADIO_STATUS = {
    ALL: 'ALL',
    RANGED: 'RANGED',
};

export const RECOMMENDATION_SETS_OPTIONS = {
    ALL: 'ALL',
    WITH_SUBMITTED_RECOMMENDATIONS: 'WITH_SUBMITTED_RECOMMENDATIONS',
};

export type DateRangeStatus = $Keys<typeof DATE_RANGE_RADIO_STATUS>;
export type RecommendationSetsOptions = $Keys<typeof RECOMMENDATION_SETS_OPTIONS>;

type DateKey = 'dateFrom' | 'dateTo';

type Props = {
    intl: IntlType,

    archiveType: TrendsPageType,
    circuitId?: number,

    loading: boolean,

    onFilter: (dateRange: ImmutableDateRange) => void,
};

type State = {
    hasSomethingChanged: boolean,

    dateRangeStatus: DateRangeStatus,
    dateFrom: ?string,
    dateTo: ?string,
    submittedRecommendationsOnly: RecommendationSetsOptions,
};

class ArchiveSidebar extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasSomethingChanged: false,

            dateRangeStatus: DATE_RANGE_RADIO_STATUS.ALL,
            dateFrom: null,
            dateTo: null,
            submittedRecommendationsOnly: RECOMMENDATION_SETS_OPTIONS.ALL,
        };
    }

    componentDidUpdate(prevProps: Props) {
        // If the circuit has changed, reset the filter to match the base query
        if (prevProps.circuitId !== this.props.circuitId) {
            this.setState({
                submittedRecommendationsOnly: RECOMMENDATION_SETS_OPTIONS.ALL,
                dateRangeStatus: DATE_RANGE_RADIO_STATUS.ALL,
                dateFrom: null,
                dateTo: null,
            });
        }
    }

    getTranslation = (id: string) =>
        this.props.intl.formatMessage({
            id: `components.ArchiveSidebar.${id}`,
        });

    /**
     * Get the date range to be filtered.
     * If the range is not specified (i.e. we want all datasets) then return null.
     */
    getDateRange = () => {
        if (this.state.dateRangeStatus === DATE_RANGE_RADIO_STATUS.RANGED) {
            return fromJS({
                from: this.state.dateFrom,
                to: this.state.dateTo,
            });
        }
        return null;
    };

    getSubmittedRecommendationsOnly = () =>
        this.state.submittedRecommendationsOnly ===
        RECOMMENDATION_SETS_OPTIONS.WITH_SUBMITTED_RECOMMENDATIONS;

    handleDateRadioChange = (dateRangeStatus: DateRangeStatus) =>
        this.setState({
            hasSomethingChanged: true,
            dateRangeStatus,
        });

    handleDateChange = (key: DateKey) => (event: InputEvent) => {
        const value = event.target.value;
        this.setState({
            [key]: value,
            hasSomethingChanged: true,
        });
    };

    handleRecommendationSetsOptionChange = (
        submittedRecommendationsOnly: RecommendationSetsOptions
    ) =>
        this.setState({
            hasSomethingChanged: true,
            submittedRecommendationsOnly,
        });

    handleFilterClicked = () =>
        this.setState(
            {
                hasSomethingChanged: false,
            },
            () => this.props.onFilter(this.getDateRange(), this.getSubmittedRecommendationsOnly())
        );

    /**
     * The filter is disabled if we are fetching datasets;
     * If nothing has changed since the last filter;
     * If the dates are not valid when using ranged dates;
     */
    isFilterDisabled = () =>
        this.props.loading ||
        !this.state.hasSomethingChanged ||
        (this.state.dateRangeStatus === DATE_RANGE_RADIO_STATUS.RANGED &&
            (!this.state.dateFrom || !this.state.dateTo));

    render() {
        return (
            <Wrapper>
                <SidebarBody>
                    <DateRangeSection
                        from={this.state.dateFrom}
                        to={this.state.dateTo}
                        radioStatus={this.state.dateRangeStatus}
                        onRadioClick={this.handleDateRadioChange}
                        onFromChange={this.handleDateChange('dateFrom')}
                        onToChange={this.handleDateChange('dateTo')}
                        disabled={this.props.loading}
                    />
                    {this.props.archiveType === TRENDS_PAGE_TYPE.CIRCUIT && (
                        <RecommendationSetsOptionsSection
                            radioStatus={this.state.submittedRecommendationsOnly}
                            onRadioClick={this.handleRecommendationSetsOptionChange}
                            disabled={this.props.loading}
                        />
                    )}
                </SidebarBody>
                <FooterSection>
                    <PrimaryButton
                        disabled={this.isFilterDisabled()}
                        text={this.getTranslation('filterButtonText')}
                        onClick={this.handleFilterClicked}
                    />
                </FooterSection>
            </Wrapper>
        );
    }
}

export default injectIntl(ArchiveSidebar);
