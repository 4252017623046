// @flow strict

import type { Role, User } from 'services/Authentication/types';
import type { ErrorType, QueryStructure } from 'types';

export const ROLES_FETCH_FAILURE = 'app/services/User/ROLES_FAILURE';
export const ROLES_FETCH_SUCCESS = 'app/services/User/ROLES_SUCCESS';
export const USER_CREATE_FAILURE = 'app/services/User/USER_CREATE_FAILURE';
export const USER_CREATE_SUCCESS = 'app/services/User/USER_CREATE_SUCCESS';
export const USER_DELETE_FAILURE = 'app/services/User/USER_DELETE_FAILURE';
export const USER_DELETE_SUCCESS = 'app/services/User/USER_DELETE_SUCCESS';
export const USER_IS_CREATING = 'app/services/User/USER_IS_CREATING';
export const USER_IS_UPDATING = 'app/services/User/USER_IS_UPDATING';
export const USER_IS_DELETING = 'app/services/User/USER_IS_DELETING';
export const USER_UPDATE_FAILURE = 'app/services/User/USER_UPDATE_FAILURE';
export const USER_UPDATE_SUCCESS = 'app/services/User/USER_UPDATE_SUCCESS';
export const USERS_ARE_FETCHING = 'app/services/User/USERS_ARE_FETCHING';
export const USERS_ARE_QUERYING = 'app/services/User/USERS_ARE_QUERYING';
export const USERS_FETCH_FAILURE = 'app/services/User/USERS_FETCH_FAILURE';
export const USERS_FETCH_SUCCESS = 'app/services/User/USERS_FETCH_SUCCESS';
export const USERS_QUERY_FAILURE = 'app/services/User/USERS_QUERY_FAILURE';
export const USERS_QUERY_SUCCESS = 'app/services/User/USERS_QUERY_SUCCESS';

export const receivedRolesFetchSuccess = (data: Array<Role>) => ({
    type: ROLES_FETCH_SUCCESS,
    payload: { data },
});

export const receivedRolesFetchFailure = (errors: ErrorType) => ({
    type: ROLES_FETCH_FAILURE,
    payload: { errors },
});

export const receivedUserCreateFailure = (errors: ErrorType) => ({
    type: USER_CREATE_FAILURE,
    payload: { errors },
});

export const receivedUserCreateSuccess = (data: User) => ({
    type: USER_CREATE_SUCCESS,
    payload: { data },
});

export const receivedUserDeleteFailure = (errors: ErrorType) => ({
    type: USER_DELETE_FAILURE,
    payload: { errors },
});

export const receivedUserDeleteSuccess = (data: number) => ({
    type: USER_DELETE_SUCCESS,
    payload: { data },
});

export const receivedUserUpdateSuccess = (data: User) => ({
    type: USER_UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUserUpdateFailure = (errors: ErrorType) => ({
    type: USER_UPDATE_FAILURE,
    payload: { errors },
});

export const setUserIsCreatingStatus = (userIsCreating: boolean = true) => ({
    type: USER_IS_CREATING,
    payload: { userIsCreating },
});

export const setUserIsUpdatingStatus = (userIsUpdating: boolean = true) => ({
    type: USER_IS_UPDATING,
    payload: { userIsUpdating },
});

export const setUserIsDeletingStatus = (userIsDeleting: boolean = true) => ({
    type: USER_IS_DELETING,
    payload: { userIsDeleting },
});

export const setUsersAreFetching = (usersAreFetching: boolean = true) => ({
    type: USERS_ARE_FETCHING,
    payload: { usersAreFetching },
});

export const setUsersAreQuerying = (usersAreQuerying: boolean = true) => ({
    type: USERS_ARE_QUERYING,
    payload: { usersAreQuerying },
});

export const receivedUsersFetchFailure = (errors: ErrorType) => ({
    type: USERS_FETCH_FAILURE,
    payload: { errors },
});

export const receivedUsersFetchSuccess = (data: Array<User>) => ({
    type: USERS_FETCH_SUCCESS,
    payload: { data },
});

export const receivedUsersQueryFailure = (errors: ErrorType) => ({
    type: USERS_QUERY_FAILURE,
    payload: { errors },
});

export const receivedUsersQuerySuccess = (data: QueryStructure) => ({
    type: USERS_QUERY_SUCCESS,
    payload: { data },
});
