// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import { InputField, Table, Trash } from 'components/_ReactUI_V1';

// Styles
import { DataPointRow, DataPointColumn, DataPointContainer, List } from './styles';
import { BlankButton } from 'styles/common';

// Types
import type { ImmutableList, ImmutableMap, InputEvent, IntlType, LooseNumberType } from 'types';
import type { ImmutableDataPoint, ImmutableOximeConcentration } from 'services/Oxime/types';

// Utils
import { provideEmptyDataPoints, round } from 'utils/helpers';
import { NUMBER_INPUT_PLACEHOLDER, OXIME_CONCENTRATION_DATA_POINT_COUNT } from 'utils/constants';

// Constants
const DATA_POINTS_DECIMAL_COUNT = 6;
const NUMBER_INPUT_STYLE_OBJ = { maxWidth: '120px', textAlign: 'center', paddingRight: 6 };

type Props = {
    concentrations: ImmutableList<ImmutableOximeConcentration>,
    onConcentrationChange: (Array<LooseNumberType>) => (InputEvent) => void,
    onConcentrationDelete: (number) => void,
    disabledInputs: boolean,
    editMode: boolean,
    intl: IntlType,
    onHandleAddEmptyRow: () => void,
};

class OximeConcentrationTable extends React.PureComponent<Props> {
    handleConcentrationChange = (keyArray: Array<LooseNumberType>) => (event: InputEvent) =>
        this.props.onConcentrationChange(keyArray)(event);

    /**
     * Provide editable row
     */
    provideEditableRow = (
        concentration: ImmutableOximeConcentration,
        concentrationIdx: number,
        concentrationsCount: number
    ) => {
        // Get concentrations dataPoints
        const savedDataPoints = concentration.get('dataPoints');
        // Add empty dataPoints to existing to display inputs for user
        const dataPoints = savedDataPoints.concat(
            fromJS(
                provideEmptyDataPoints(
                    concentration.get('id'),
                    OXIME_CONCENTRATION_DATA_POINT_COUNT - savedDataPoints.size
                )
            )
        );

        return (
            concentration
                .set('key', concentration.get('id'))
                .set(
                    'concentration',
                    <InputField
                        disabled={this.props.disabledInputs}
                        onChange={this.handleConcentrationChange([
                            concentrationIdx,
                            'concentration',
                        ])}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={NUMBER_INPUT_STYLE_OBJ}
                        type="number"
                        value={concentration.get('concentration')}
                    />
                )
                .set(
                    'modifier',
                    <InputField
                        disabled={this.props.disabledInputs}
                        onChange={this.handleConcentrationChange([concentrationIdx, 'modifier'])}
                        placeholder={NUMBER_INPUT_PLACEHOLDER}
                        style={NUMBER_INPUT_STYLE_OBJ}
                        type="number"
                        value={concentration.get('modifier')}
                    />
                )
                // M20-555
                // .set('datapoints', this.renderEditableDataPoints(dataPoints, concentrationIdx))
                .set('mdr', this.renderInputFieldsWithinList(dataPoints, concentrationIdx, 'mdr'))
                .set(
                    'metalInOrganic',
                    this.renderInputFieldsWithinList(dataPoints, concentrationIdx, 'metalInOrganic')
                )
                // Set delete icon if concentration count is over 1 - minium 1 concentration
                .set(
                    'controls',
                    concentrationsCount > 1 && (
                        <BlankButton onClick={this.props.onConcentrationDelete(concentrationIdx)}>
                            <Trash width="15px" />
                        </BlankButton>
                    )
                )
        );
    };

    /**
     * Provide interactive row (static with controls)
     */
    provideReadOnlyRow = (concentration: ImmutableOximeConcentration) => {
        const dataPoints = concentration.get('dataPoints');

        return (
            concentration
                .set('key', concentration.get('id'))
                .set(
                    'concentration',
                    round(concentration.get('concentration'), DATA_POINTS_DECIMAL_COUNT)
                )
                .set('modifier', round(concentration.get('modifier'), DATA_POINTS_DECIMAL_COUNT))
                // M20-555
                // .set('datapoints', this.renderReadOnlyDataPoints(dataPoints))
                .set('modifier', round(concentration.get('modifier'), DATA_POINTS_DECIMAL_COUNT))
                .set('mdr', this.renderRoundedValuesWithinList(dataPoints, 'mdr'))
                .set(
                    'metalInOrganic',
                    this.renderRoundedValuesWithinList(dataPoints, 'metalInOrganic')
                )
        );
    };

    /**
     * Provide table header array for expandedContent / data section for each Oxime
     */
    provideTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeConcentrationTable.header.concentration',
            }),
            id: 'concentration',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeConcentrationTable.header.modifier',
            }),
            id: 'modifier',
        },
        {
            // Space "hack" to push secondary columns to right
            label: '',
            id: 'spacer',
        },
        // M20-555
        // {
        //     label: (
        //         <DataPointContainer>
        //             <DataPointRow>
        //                 <DataPointColumn>
        //                     {this.props.intl.formatMessage({
        //                         id: 'components.OximeConcentrationTable.header.mdr',
        //                     })}
        //                 </DataPointColumn>
        //                 <DataPointColumn>
        //                     {this.props.intl.formatMessage({
        //                         id: 'components.OximeConcentrationTable.header.metalInOrganic',
        //                     })}
        //                 </DataPointColumn>
        //             </DataPointRow>
        //         </DataPointContainer>
        //     ),
        //     id: 'datapoints',
        // },
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeConcentrationTable.header.mdr',
            }),
            id: 'mdr',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.OximeConcentrationTable.header.metalInOrganic',
            }),
            id: 'metalInOrganic',
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    /**
     * Map over Oximes and provide either interactive or editable row based on state.activeRow
     */
    provideTableRows = () => {
        const concentrationCount = this.props.concentrations.size;
        const functionalRows = this.props.concentrations.map(
            (concentration: ImmutableOximeConcentration, idx: number) =>
                this.props.editMode
                    ? this.provideEditableRow(concentration, idx, concentrationCount)
                    : this.provideReadOnlyRow(concentration)
        );

        return (
            functionalRows
                // Space "hack" to push secondary columns to right
                .setIn([0, 'spacer'], <div style={{ minWidth: '300px' }}>&nbsp;</div>)
                .toJS()
        );
    };

    /**
     * Helper function to provide editable InputField for a Data Point
     */
    provideDataPointInput = (
        dataPoint: ImmutableDataPoint,
        dataPointIdx: number,
        concentrationIdx: number,
        key: string,
        previous: ImmutableDataPoint
    ) =>
        dataPoint && (
            <InputField
                key={dataPoint.get('id') || `new-${dataPointIdx}`}
                disabled={this.props.disabledInputs || (previous && !previous.get(key))}
                onChange={this.handleConcentrationChange([
                    concentrationIdx,
                    'dataPoints',
                    dataPointIdx,
                    key,
                ])}
                placeholder={NUMBER_INPUT_PLACEHOLDER}
                style={NUMBER_INPUT_STYLE_OBJ}
                type="number"
                value={dataPoint.get(key) || ''}
            />
        );

    /**
     * Helper function to provide a map of editable InputFields for list of provided Data Points
     *
     * Save previous item before returning; disable input if previous value is undefined
     */
    provideDataPointInputs = (
        dataPoints: ImmutableList<ImmutableDataPoint>,
        concentrationIdx: number,
        key: string
    ) => {
        let previous;
        return (
            dataPoints &&
            dataPoints.map((dataPoint: ImmutableDataPoint, dataPointIdx: number) => {
                const item = this.provideDataPointInput(
                    dataPoint,
                    dataPointIdx,
                    concentrationIdx,
                    key,
                    previous
                );
                previous = dataPoint;
                return item;
            })
        );
    };

    /**
     * Helper function to provide <List> of with editable InputFields for each dataPoint
     *
     * Save previous item before returning; disable input if previous value is undefined
     */
    renderInputFieldsWithinList = (
        dataPoints: ImmutableList<ImmutableDataPoint>,
        concentrationIdx: number,
        key: string
    ) => {
        let previous;
        return (
            dataPoints && (
                <List>
                    {dataPoints.map((dataPoint: ImmutableDataPoint, dataPointIdx: number) => {
                        const item = dataPoint && (
                            <li key={dataPoint.get('id') || `new-${dataPointIdx}`}>
                                <InputField
                                    disabled={
                                        this.props.disabledInputs ||
                                        (previous && !previous.get(key))
                                    }
                                    onChange={this.handleConcentrationChange([
                                        concentrationIdx,
                                        'dataPoints',
                                        dataPointIdx,
                                        key,
                                    ])}
                                    placeholder={NUMBER_INPUT_PLACEHOLDER}
                                    style={NUMBER_INPUT_STYLE_OBJ}
                                    type="number"
                                    value={dataPoint.get(key) || ''}
                                />
                            </li>
                        );

                        previous = dataPoint;
                        return item;
                    })}
                </List>
            )
        );
    };

    /**
     * Helper function to provide <List> of round(item.key, decimalCount)
     */
    renderRoundedValuesWithinList = (
        // eslint-disable-next-line flowtype/no-weak-types
        items: ImmutableList<ImmutableMap<string, Object>>,
        key: string,
        decimalCount: number = DATA_POINTS_DECIMAL_COUNT
    ) => (
        <List>
            {/* eslint-disable-next-line flowtype/no-weak-types */}
            {items.map((item: ImmutableMap<string, Object>, i: number) => (
                <li key={item.get('id') || `${i}-${item.get('key')}`}>
                    {round(item.get(key), decimalCount)}
                </li>
            ))}
        </List>
    );

    // M20-555
    // /**
    //  * Helper function that renders a container od Data Points containing rows of mdr & metalInOrganic inputs
    //  */
    // renderEditableDataPoints = (
    //     dataPoints: ImmutableList<ImmutableDataPoint>,
    //     concentrationIdx: number
    // ) => {
    //     const dataPointRows = [];

    //     const mdrList = this.provideDataPointInputs(dataPoints, concentrationIdx, 'mdr');
    //     const metalInOrganicList = this.provideDataPointInputs(
    //         dataPoints,
    //         concentrationIdx,
    //         'metalInOrganic'
    //     );

    //     // Loop over max possible data points pushing a row containing mdr and metalInOrganic inputs for that index
    //     for (let step = 0; step < OXIME_CONCENTRATION_DATA_POINT_COUNT; step++) {
    //         dataPointRows.push(
    //             <DataPointRow>
    //                 <DataPointColumn>{mdrList.get(step)}</DataPointColumn>
    //                 <DataPointColumn>{metalInOrganicList.get(step)}</DataPointColumn>
    //             </DataPointRow>
    //         );
    //     }

    //     return <DataPointContainer>{dataPointRows}</DataPointContainer>;
    // };

    /**
     * Render list of provided datapoints
     */
    renderListOfValues = (
        items: ImmutableList<ImmutableMap<string, Object>>,
        key: string,
        decimalCount: number = DATA_POINTS_DECIMAL_COUNT
    ) => (
        <List>
            {/* eslint-disable-next-line flowtype/no-weak-types */}
            {items.map((item: ImmutableMap<string, Object>, i: number) => {
                const value = item.get(key);
                return (
                    <li key={item.get('id') || `${i}-${value}`}>{round(value, decimalCount)}</li>
                );
            })}
        </List>
    );

    // M20-555
    // /**
    //  * Render DataPoints Container with list views of mdr & metalInOrganic
    //  */
    // renderReadOnlyDataPoints = (
    //     items: ImmutableList<ImmutableMap<string, Object>>,
    //     decimalCount: number = DATA_POINTS_DECIMAL_COUNT
    // ) => (
    //     <DataPointContainer>
    //         <DataPointRow>
    //             <DataPointColumn>
    //                 {this.renderListOfValues(items, 'mdr', decimalCount)}
    //             </DataPointColumn>
    //             <DataPointColumn>
    //                 {this.renderListOfValues(items, 'metalInOrganic', decimalCount)}
    //             </DataPointColumn>
    //         </DataPointRow>
    //     </DataPointContainer>
    // );

    render() {
        if (
            this.props.concentrations &&
            this.props.concentrations.isEmpty() &&
            !this.props.editMode
        ) {
            return this.props.intl.formatMessage({
                id: 'components.OximeConcentrationTable.noResults',
            });
        }

        return (
            <Table
                header={this.provideTableHeaders()}
                rows={this.provideTableRows()}
                onAddEmptyRow={this.props.editMode ? this.props.onHandleAddEmptyRow : null}
                tdVerticalAlign="top"
                subtleStyling
            />
        );
    }
}

export default injectIntl(OximeConcentrationTable);
