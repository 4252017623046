// @flow

import React from 'react';

import type { InputCheckedType, InputEventType } from '../../types';

// Styles
import { Checkbox, CheckboxLabel, HiddenInput } from './styles';

type Props = {
    checked: boolean,
    disabled?: boolean,
    readOnlyChecked?: boolean,
    label?: string,
    labelRenderer?: (checked: boolean) => void,
    onClickHandler?: (event: InputEventType) => void,
    name?: string,
    stateless?: boolean,
    styles?: Object,
};

type State = {
    checked: InputCheckedType,
};

export class CheckBox extends React.Component<Props, State> {
    static defaultProps = {
        disabled: false,
        readOnlyChecked: false,
        label: '',
        labelRenderer: null,
        name: 'checkbox',
        onClickHandler: null,
        stateless: false,
        styles: null,
    };

    state = {
        checked: Boolean(this.props.checked),
    };

    componentDidUpdate(prevProps: Props) {
        // If props.checked is externally changed, set state.
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (this.props.checked !== prevProps.checked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                checked: this.props.checked,
            });
        }
    }

    handleChange = (event: InputEventType) => {
        event.persist();
        this.setState(
            (prevState: State) => ({
                checked: !prevState.checked,
            }),
            () => {
                // Set target.value to state.checked, return event to parent for click handling
                event.target.isCheckbox = true;
                event.target.value = Boolean(this.state.checked);
                if (this.props.onClickHandler) {
                    this.props.onClickHandler(event);
                }
            }
        );
    };

    render() {
        const checkedState = Boolean(
            this.props.stateless ? this.props.checked : this.state.checked
        );

        return (
            <CheckboxLabel
                key={this.props.name}
                disabled={this.props.disabled}
                style={this.props.styles}
            >
                <Checkbox
                    checked={checkedState || this.props.readOnlyChecked}
                    disabled={this.props.disabled}
                />
                {this.props.label}
                {this.props.labelRenderer && this.props.labelRenderer(checkedState)}
                <HiddenInput
                    key={this.props.name}
                    checked={checkedState}
                    id={this.props.name}
                    name={this.props.name}
                    type="checkbox"
                    onChange={!this.props.disabled ? this.handleChange : null}
                    value={checkedState}
                />
            </CheckboxLabel>
        );
    }
}
