// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import type { Location } from 'react-router-dom';

import { MicroFrontEnds } from '../../microfrontendURIs';

import MicroFrontend from '..';
import { ContainerCentered } from 'styles/common';

// Components
import RecommendationsSidebarContainer from 'containers/TrendsSidebarContainer/RecommendationsSidebarContainer';
import { SidebarLayout, NotificationBell } from 'components/_ReactUI_V1';
import ErrorMessage from 'components/ErrorMessage';
import { QuickNavigatorObservable } from 'components/_FrontendObservables';
import SidebarTabInline from 'components/SidebarTabInline';

// Services
import { selectUser } from 'services/Authentication/selectors';
import { fetchAllClients } from 'services/Client/thunks';
import { fetchCircuit } from 'services/Circuit/thunks';
import {
    selectMinChemCircuitQuery,
    selectSolvExtractCircuitQuery,
} from 'services/Circuit/selectors';
import { selectAllClients } from 'services/Client/selectors';
import { selectCircuitRecommendations } from 'services/Recommendation/selectors';

// Types
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableClient } from 'services/Client/types';
import type { ImmutableCircuit } from 'services/Circuit/types';
import type { IntlType, ImmutableQueryStructure } from 'types';

// Constants
import { TRENDS_PAGE_TYPE, STYLE_VALUES } from 'utils/constants';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    location: Location,
    match: {
        params: {
            plantId: string,
            circuitId: string,
        },
    },

    // For recommendation sidebar:
    recommendationSets: ImmutableList<ImmutableRecommendationSet>,

    fetchCircuit: (circuitId: number) => void,
    minChemCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,
    solvExtractCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,

    clients: ImmutableList<ImmutableClient>,
    fetchAllClients: () => void, // for navigation.
};

type State = {
    isUnLoadable: boolean,
    sidebarIsCollapsed: boolean,
};

class PlantDashboard extends React.PureComponent<Props, State> {
    state = {
        isUnLoadable: false,
        sidebarIsCollapsed: false,
    };

    static getHost = () => {
        return MicroFrontEnds.plantDashboard; // name must match the secret object's structure in GCP
    };

    static getUniqueName = () => {
        return 'PlantDashboardMFE'; // name must match the container name in the MFE code
    };

    componentDidMount() {
        const circuitId = this.getCircuitId();

        if (circuitId) {
            this.props.fetchCircuit(circuitId);
        }

        // for navigation.
        if (this.props.clients.isEmpty()) {
            this.props.fetchAllClients();
        }

        QuickNavigatorObservable.setQuickNavigatorSolvExtract();
    }

    getCircuit = (): ?ImmutableCircuit => {
        const circuitId = this.getCircuitId();

        const minChemCircuit = this.props.minChemCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === circuitId);
        if (minChemCircuit) {
            return minChemCircuit;
        }

        const solvExtractCircuit = this.props.solvExtractCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === circuitId);
        if (solvExtractCircuit) {
            return solvExtractCircuit;
        }
    };

    getMountProps = () => {
        return {
            locale: this.props.intl.locale.toUpperCase(),
            userRoles: [...new Set(this.props.user.get('roles'))],
        };
    };

    onError = () => {
        this.setState({ isUnLoadable: true });
    };

    getPlantId() {
        const plantId = this.props.match.params.plantId;
        if (plantId) {
            return parseInt(plantId, 10);
        }
        return null;
    }

    getCircuitId() {
        const circuitId = this.props.match.params.circuitId;
        if (circuitId) {
            return parseInt(circuitId, 10);
        }
        return null;
    }

    /**
     * This is used to resize the main content
     */
    handleSidebarCollapse = (isCollapsed: boolean) =>
        this.setState({
            sidebarIsCollapsed: isCollapsed,
        });

    getCurrentRecommendationSize = () => {
        const currentCircuitRecommendationSets = this.props.recommendationSets.find(
            (recommendationSet: ImmutableRecommendationSet) =>
                recommendationSet.get('circuitId') === this.getCircuitId()
        );
        const currentRecommendationSize =
            currentCircuitRecommendationSets?.get('recommendations')?.size || 0;
        return currentRecommendationSize;
    };

    getResponsiveSidebarTitle = () => {
        const currentRecommendationSize = this.getCurrentRecommendationSize();
        const unreadRecommendations = this.props.isLoadingCircuitRecommendation
            ? 0
            : currentRecommendationSize; // TODO: MS-609 - get unread recommendations from props

        return this.props.intl.formatMessage(
            { id: `components.RecommendationSidebar.mobileRecommendationTitle` },
            {
                number: unreadRecommendations > 0 ? `(${unreadRecommendations})` : '',
            }
        );
    };

    /**
     * Render the sidebar.
     */
    renderSidebar = () => {
        const circuit = this.getCircuit();

        if (circuit) {
            const circuitId = this.getCircuitId();
            const plantId = this.getPlantId();

            return (
                <>
                    <SidebarTabInline
                        title={this.props.intl.formatMessage({
                            id: 'components.RecommendationSidebar.title',
                        })}
                    />
                    <RecommendationsSidebarContainer
                        circuit={circuit}
                        circuitId={circuitId}
                        plantId={plantId}
                        trendType={TRENDS_PAGE_TYPE.CIRCUIT}
                    />
                </>
            );
        }

        return null;
    };

    renderMainContent = () => {
        return (
            <MicroFrontend
                name={PlantDashboard.getUniqueName()}
                host={PlantDashboard.getHost()}
                mountedMicroFrontendProps={this.getMountProps()}
                onError={this.onError}
                // the legacy sidebar means this app is "not" in full screen
                // because the sidebar layout component already removes the header's 61px height
                mfeIsInFullScreen={false}
            />
        );
    };

    render() {
        if (this.state.isUnLoadable) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: `views.${PlantDashboard.getUniqueName()}.anErrorOccurredDuringLoad`,
                        })}
                    />
                </ContainerCentered>
            );
        }

        return (
            <React.Fragment>
                <SidebarLayout
                    styles={{
                        sidebar: {
                            height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        },
                        main: {
                            height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                            overflowY: 'hidden',
                        },
                    }}
                    renderMain={this.renderMainContent}
                    renderSidebar={this.renderSidebar}
                    sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                    onSidebarCollapseToggled={this.handleSidebarCollapse}
                    responsiveMaxDeviceWidth={STYLE_VALUES.RESPONSIVE_AT_LARGE}
                    responsiveTitle={this.getResponsiveSidebarTitle()}
                    sidebarTogglerIcon={
                        this.getCurrentRecommendationSize() > 0 ? (
                            <NotificationBell fill="white" width="20px" exclamationBell={true} />
                        ) : (
                            undefined
                        )
                    }
                    responsive
                    collapsible
                    flush
                    mainFlush
                    closedByDefault={!(this.getCurrentRecommendationSize() > 0)}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
        recommendationSets: selectCircuitRecommendations(),

        minChemCircuitQuery: selectMinChemCircuitQuery(),
        solvExtractCircuitQuery: selectSolvExtractCircuitQuery(),
        clients: selectAllClients(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchCircuit,
            fetchAllClients,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(PlantDashboard));
