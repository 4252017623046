// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header, Body, Footer, Field, Label, LargeInputBox } from './styles';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, SecondaryButton, InputField, Modal } from 'components/_ReactUI_V1';

// Types
import type { IntlType, InputEvent, ErrorType } from 'types';
import type { ImmutableCircuit } from 'services/Circuit/types';

type Props = {
    intl: IntlType,
    errors: ErrorType,
    loading: boolean,
    circuit?: ImmutableCircuit,
    title: string,
    confirmButtonText: string,

    onConfirm: (circuitName: string, plantName?: string, comments?: string) => void,
    onCancel: () => void,
};

type State = {
    circuitName: string,
    plantName: string,
    comments: string,
};

class CircuitDetailsModal extends React.PureComponent<Props, State> {
    static defaultProps = {
        circuit: null,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            circuitName: this.props.circuit ? this.props.circuit.get('name') : '',
            plantName: this.props.circuit ? this.props.circuit.get('plantName') : '',
            comments: this.props.circuit ? this.props.circuit.get('comments') : '',
        };
    }

    /**
     * Is the button enabled?
     * If props.circuit is not null, the update button is enable if at least one input changed
     * If props.circuit is null, the create button is enable if the circuitName input is not empty
     */
    isButtonEnabled = () =>
        this.props.circuit
            ? (this.state.circuitName !== this.props.circuit.get('name') &&
                  this.state.circuitName !== '') ||
              this.state.plantName !== this.props.circuit.get('plantName') ||
              this.state.comments !== this.props.circuit.get('comments')
            : this.state.circuitName !== '';

    handleCreateClicked = () =>
        this.props.onConfirm(this.state.circuitName, this.state.plantName, this.state.comments);

    handleCancelClicked = () => this.props.onCancel();

    handleCircuitNameChange = (evt: InputEvent) => this.setState({ circuitName: evt.target.value });

    handlePlantNameChange = (evt: InputEvent) => this.setState({ plantName: evt.target.value });

    handleCommentsChange = (evt: InputEvent) => this.setState({ comments: evt.target.value });

    render() {
        return (
            <Modal onHandleClose={this.props.onCancel} disableClose>
                <Wrapper>
                    <Header>
                        <Title>{this.props.title}</Title>
                    </Header>
                    <Body>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.CircuitModals.common.circuitName.Label',
                                })}
                            </Label>
                            <InputField
                                name="circuitName"
                                value={this.state.circuitName}
                                onChange={this.handleCircuitNameChange}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.CircuitModals.common.circuitName.Placeholder',
                                })}
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.CircuitModals.common.plantName.Label',
                                })}
                            </Label>
                            <InputField
                                name="plantName"
                                value={this.state.plantName || ''}
                                onChange={this.handlePlantNameChange}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.CircuitModals.common.plantName.Placeholder',
                                })}
                            />
                        </Field>
                        <Field>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.CircuitModals.common.comments.Label',
                                })}
                            </Label>
                            <LargeInputBox
                                name="circuitComments"
                                value={this.state.comments || ''}
                                onChange={this.handleCommentsChange}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.CircuitModals.common.comments.Placeholder',
                                })}
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.CircuitModals.common.cancelButton',
                            })}
                            onClick={this.handleCancelClicked}
                        />
                        <PrimaryButton
                            text={this.props.confirmButtonText}
                            onClick={this.handleCreateClicked}
                            loading={this.props.loading}
                            disabled={!this.isButtonEnabled()}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(CircuitDetailsModal);
