// @flow

import styled from 'styled-components';
import LegacyTheme from '../../themes/legacyTheme';

export const NumberInput = styled.input`
    width: ${({ width }: Object) => width};
    height: ${({ height }: Object) => height};
    margin-right: 5px;
    padding: ${({ padding }: Object) => padding};
    min-width: ${({ minWidth }: Object) => minWidth};
    text-align: ${({ textAlign }: Object) => textAlign};

    background-color: ${LegacyTheme.defaultBackground};
    border-radius: 4px;
    border: 1px solid
        ${({ disabled }: Object) =>
            disabled ? LegacyTheme.disabledBorder : LegacyTheme.defaultBorder};

    font-size: 16px;
    color: ${({ disabled }: Object) =>
        disabled ? LegacyTheme.disabledInputColor : LegacyTheme.defaultColor};
    &::placeholder {
        color: ${({ disabled }: Object) =>
            disabled ? LegacyTheme.disabledPlaceholder : LegacyTheme.disabledInputColor};
    }

    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: ${LegacyTheme.buttonPrimaryHover};
    }

    ${({ noSpinner }: Object) =>
        noSpinner &&
        `
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }`}
`;

export const InputWrapper = styled.div`
    font-size: 14px;
`;
