// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

// Mimic engine
import { MimicCircuit, type IMimicCascade } from 'components/_McCabeThiele';

// Components
import { OverflowEnd } from 'components/_ReactUI_V1';

import PreventNavigationPrompt from 'components/PreventNavigationPrompt';
import MimicDiagram from 'components/MimicDiagram';
import ComputationInstructions from 'components/ComputationInstructions';
import CircuitComputeFooter from 'components/CircuitComputeFooter';
import MimicSidebarSection, {
    type State as MimicSidebarState,
} from 'components/CircuitComputationSidebar/MimicSidebarSection';
import IsothermSelectModal from 'components/Modals/IsothermSelectModal';
import CircuitConvergenceModal from 'components/Modals/CircuitConvergenceModal';
import DatasetSaveModal from 'components/Modals/DatasetSaveModal';
import MimicDiagramErrorBoundary from 'components/MimicDiagramErrorBoundary';

// Constants
import {
    STAGE_TYPES,
    DATASET_MODES,
    STREAM_TYPES,
    STREAM_CIRCUITS,
    VALUE_STATUS,
    STAGE_VALUE_TYPES,
    STREAM_VALUE_TYPES,
    DATASET_VALUE_TYPES,
    PRESET_STAGE_VALUE_STATUSES,
    DEFAULT_STREAM_VALUE_STATUSES,
    DIAGRAM_DISPLAY_MODES,
    DESIGN_PRESET_TYPES,
    DEFAULT_CASCADE_RELAXATION_FACTOR,
    DEFAULT_RETURN_RELAXATION_FACTOR,
    ISOTHERM_VALUE_MODES,
    ADVANCED_STREAMS_SETUP_TYPES,
    DATASET_VALUE_ORDER,
    ANALYSIS_MODE_STREAM_VALUE_STATUSES,
    ANALYSIS_PRESET_TYPES,
    DATASET_STATUSES,
    STAGE_TYPES_WITH_ISOTHERMS,
} from 'utils/constants';
// Helpers
import {
    hasExtractorStages,
    hasStripperStages,
    getStageById,
    getFirstStageInCascadeForStage,
    getStagesInCascadeForStage,
    areAllStageIsothermsPredicted,
    nodeToClipboard,
    getStreamAdvancedType,
    isStreamValueEnabled,
    getFirstStageUntilNewFeedBlendStage,
    getExtractorStages,
} from 'utils/helpers';
import {
    shouldShowLocalRecovery,
    hasExtractBypass,
    hasStripBypass,
    hasOrganicBlend,
    getOrganicBlendToStage,
    getPlsBleedFromStage,
    getPlsFeedToStage,
    getPlsBlendToStage,
    getElectrolyteFeedToStage,
} from 'components/MimicDiagram/helpers';

import { getOrganicStreamDirectionKey, isValidPreset, isTankFlowrateComputeUsing } from './helpers';

// Services
import {
    selectAllIsotherms,
    selectIsothermsAreFetching,
    selectIsothermsAreCreating,
} from 'services/Isotherm/selectors';
import { fetchAllIsotherms, createManyIsotherms } from 'services/Isotherm/thunks';
import { newFeedback } from 'services/Feedback/thunks';
import {
    selectDatasetIsComputing,
    selectDatasetErrors,
    selectComputedDataset,
    selectDatasetsAreCreating,
    selectDatasetsAreUpdating,
    selectIsComputeCompleted,
    selectIsDatasetSaved,
} from 'services/Dataset/selectors';

import { computeDataset, createDataset, updateDataset } from 'services/Dataset/thunks';
import { selectUser } from 'services/Authentication/selectors';

// Styles
import { ExportFixedContainer, HeaderOverflowContainer } from 'styles/common';

// Types
import type { ReduxDispatch, ImmutableList, ErrorType, IntlType } from 'types';
import type { ImmutableIsotherm } from 'services/Isotherm/types';
import type {
    ImmutableCircuit,
    ImmutableStage,
    ImmutableStream,
    Stage,
    StreamEntity,
    LooseStream,
} from 'services/Circuit/types';
import type {
    LooseStageValue,
    LooseStreamValue,
    LooseDatasetValue,
    DatasetValuesConstant,
    ImmutableDataset,
    DatasetModesConstant,
    Preset,
    StageValue,
    StreamValue,
    StageValuesConstant,
    StreamValuesConstant,
    ValueStatusConstant,
    IsothermStageValue,
    IsothermValueModesConstant,
    ImmutableIsothermStageValue,
} from 'services/Dataset/types';
import type { SidebarRenderer } from 'containers/CircuitComputationContainer';
import type { ImmutableUser } from 'services/Authentication/types';
import type { FeedbackType } from 'services/Feedback/types';

export type OpenIsothermSelectModalFunction = (stage: Stage) => void;
export type SetStageValueFunction = (
    stageId: number,
    valueType: StageValuesConstant,
    value: ?number
) => void;
export type SetStreamValueFunction = (
    streamId: number,
    valueType: StreamValuesConstant,
    value: ?number
) => void;
export type SetStageIsothermFunction = (
    predictOrSelectIsothermRadioValue: IsothermValueModesConstant,
    stage: ImmutableStage,
    selectedIsotherm?: ImmutableIsotherm
) => void;

export type HandleDatasetValueChangeFunction = (type: DatasetValuesConstant, value: number) => void;

type MimicModalsTypes = 'ISOTHERM_SELECT' | 'DATASET_SAVE' | 'CIRCUIT_CONVERGENCE';

type SaveableIsothermCascade = {
    isotherm: ImmutableIsotherm,
    stageIds: Array<number>,
};

type Props = {
    intl: IntlType,

    loadingCircuitOrDataset: boolean,
    dataset: ?ImmutableDataset,
    circuit: ImmutableCircuit,

    loadingComputation: boolean,
    computedDataset: ?ImmutableDataset,
    computeDataset: (
        dataset: ImmutableDataset,
        cascadeRelaxationFactor: number,
        returnRelaxationFactor: number
    ) => void,
    datasetErrors: ErrorType,
    datasetIsCreating: boolean,
    createDataset: (dataset: ImmutableDataset, saveableIsotherms: SaveableIsothermCascade) => void,
    datasetIsUpdating: boolean,
    updateDataset: (
        id: number,
        dataset: ImmutableDataset,
        saveableIsotherms: SaveableIsothermCascade
    ) => void,
    createManyIsotherms: (isotherms: Array<RawIsotherm>) => void,
    isothermIsCreating: boolean,
    fetchAllIsotherms: () => void,
    isotherms: ImmutableList<ImmutableIsotherm>,
    loadingIsotherms: boolean,
    handleSidebarContent: (sidebarContent: SidebarRenderer) => void,

    user: ImmutableUser,

    newFeedback: (feedbackType: FeedbackType, message: string) => void,
};

type State = {
    generateDiagram: boolean,
    datasetMode: DatasetModesConstant,
    viewComputedDataset: boolean,
    isModified: boolean,

    preset: ?Preset,
    isothermStageValues: Array<IsothermStageValue>,
    stageValues: Array<LooseStageValue>,
    streams: Array<LooseStream>,
    streamValues: Array<LooseStreamValue>,
    datasetValues: Array<LooseDatasetValue>,

    cascadeRelaxationFactor: number,
    returnRelaxationFactor: number,

    openedModal: ?{
        type: MimicModalsTypes,
        stage?: ImmutableStage, // Only when opened modal is of type ISOTHERM_SELECT.
    },

    openedExportFixedContainer: boolean,
};

/**
 * The container that holds the Mimic Diagram(computation mode thus under CircuitComputationContainer) and handles the sidebar state
 */
class MimicContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.exportTarget = React.createRef();

        const dataset = this.props.computedDataset || this.props.dataset || null;

        const preset = this.getDatasetPreset(dataset);
        const datasetMode = dataset ? dataset.get('datasetMode') : DATASET_MODES.DESIGN_MODE;

        this.state = {
            generateDiagram: Boolean(dataset),
            datasetMode,
            openedModal: null,

            preset,
            streams: this.props.circuit && this.props.circuit.get('streams').toJS(),
            isothermStageValues: this.getDatasetIsothermStageValues(preset, dataset),
            // checking against circuit instead of dataset, because if we dont have a dataset we want the defaults.
            stageValues: this.getDatasetStageValues(preset, datasetMode, dataset),
            streamValues: this.getDatasetStreamValues(preset, datasetMode, dataset),
            datasetValues: this.getDatasetValues(preset, datasetMode, dataset),

            isModified: Boolean(this.props.computedDataset), // coming back from the McCabe Thiele, the user might then want to save the computed dataset.
            viewComputedDataset: Boolean(dataset), // if we have either a computed dataset or a dataset, then we must show the computed view
            cascadeRelaxationFactor: DEFAULT_CASCADE_RELAXATION_FACTOR,
            returnRelaxationFactor: DEFAULT_RETURN_RELAXATION_FACTOR,

            openedExportFixedContainer: false,
        };
    }

    /**
     * When this container is mounted load the sidebar
     */
    componentDidMount() {
        this.props.handleSidebarContent(this.renderSidebar());
        if (this.props.isotherms.isEmpty()) {
            this.props.fetchAllIsotherms();
        }
    }

    /**
     * Everytime set state is called, we want to also call sidebar props
     */
    componentDidUpdate(prevProps: Props) {
        if (
            (this.props.dataset && !prevProps.dataset) || // did we first load a dataset
            (this.props.circuit && !prevProps.circuit) || // did we first load the circuit
            this.props.dataset !== prevProps.dataset || // did we change datasets
            this.props.circuit !== prevProps.circuit // did the circuit change?
        ) {
            const newDataset = this.props.dataset;
            const preset = this.getDatasetPreset(newDataset);
            const datasetMode = newDataset
                ? newDataset.get('datasetMode')
                : DATASET_MODES.DESIGN_MODE;
            this.setState(
                {
                    generateDiagram: Boolean(newDataset),
                    viewComputedDataset: Boolean(newDataset),
                    datasetMode,
                    openedModal: null,

                    preset,
                    isothermStageValues: this.getDatasetIsothermStageValues(preset, newDataset),
                    stageValues: this.getDatasetStageValues(preset, datasetMode, newDataset),
                    streamValues: this.getDatasetStreamValues(preset, datasetMode, newDataset),
                    datasetValues: this.getDatasetValues(preset, datasetMode, newDataset),
                    isModified: false,

                    cascadeRelaxationFactor: DEFAULT_CASCADE_RELAXATION_FACTOR,
                    returnRelaxationFactor: DEFAULT_RETURN_RELAXATION_FACTOR,
                },
                () => this.props.handleSidebarContent(this.renderSidebar())
            );
        }

        // TODO: evaluate if this following if statement still applies.
        if (prevProps.isotherms.isEmpty() && !this.props.isotherms.isEmpty()) {
            this.setState((prevState: State) => ({
                isothermStageValues: this.getDatasetIsothermStageValues(prevState.preset),
            }));
        }

        if (prevProps.isotherms !== this.props.isotherms) {
            this.setState((prevState: State) => ({
                isothermStageValues: this.getDatasetIsothermStageValues(
                    prevState.preset,
                    this.props.dataset
                ),
            }));
        }

        // If we use the circuit navigation and change our dataset to 'Blank Dataset', we must reset out state.
        if (!this.props.dataset && this.props.circuit && prevProps.dataset) {
            const preset = this.getDefaultPreset();
            const datasetMode = DATASET_MODES.DESIGN_MODE;
            this.setState(
                {
                    datasetMode,
                    openedModal: null,
                    generateDiagram: false,
                    viewComputedDataset: false,
                    preset,
                    isothermStageValues: this.getDatasetIsothermStageValues(preset),
                    stageValues: this.getDatasetStageValues(preset, datasetMode),
                    streamValues: this.getDatasetStreamValues(preset, datasetMode),
                    streams: this.props.circuit && this.props.circuit.get('streams').toJS(),
                    datasetValues: this.getDatasetValues(preset, datasetMode),
                    cascadeRelaxationFactor: DEFAULT_CASCADE_RELAXATION_FACTOR,
                    returnRelaxationFactor: DEFAULT_RETURN_RELAXATION_FACTOR,
                    isModified: false,
                },
                () => this.props.handleSidebarContent(this.renderSidebar())
            );
        }

        if (
            prevProps.computedDataset !== this.props.computedDataset &&
            this.state.generateDiagram
        ) {
            // Close our modal if we've computed a dataset.
            let openedModal = null;
            if (
                this.props.datasetErrors &&
                this.props.datasetErrors.has('converged') &&
                !this.props.datasetErrors.get('converged')
            ) {
                openedModal = {
                    type: 'CIRCUIT_CONVERGENCE',
                };
            }
            this.setState({
                openedModal,
                viewComputedDataset: Boolean(this.props.computedDataset),
                isModified: Boolean(this.props.computedDataset),
            });
        }

        if (
            (prevProps.datasetIsCreating && !this.props.datasetIsCreating) ||
            (prevProps.datasetIsUpdating && !this.props.datasetIsUpdating) ||
            (prevProps.isothermIsCreating && !this.props.isothermIsCreating)
        ) {
            // Close the save dataset modal once it is saved.
            this.setState((prevState: State) => ({
                isModified:
                    this.props.datasetErrors &&
                    !this.props.datasetErrors.isEmpty() &&
                    prevState.isModified, // If the dataset has errors, use the prev state is modified value.
                openedModal: null,
            }));
        }

        // If the computation status changed, update the sidebar as well.
        if (prevProps.loadingComputation !== this.props.loadingComputation) {
            this.props.handleSidebarContent(this.renderSidebar());
        }
    }

    exportTarget = null;

    /**
     * Get the default preset.
     */
    getDefaultPreset = (): Preset => {
        // Check if there are advanced streams, if so, default compute using cannot be an OA_RATIO, so set it to flowrates instead
        let extractComputeUsing =
            DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING].OA_RATIO;
        let stripComputeUsing =
            DESIGN_PRESET_TYPES[STAGE_TYPES.STRIP][VALUE_STATUS.COMPUTE_USING].OA_RATIO;
        if (this.areAdvancedExtractStreamsPresent()) {
            extractComputeUsing =
                DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING].FLOWRATES;
        } else if (this.areAdvancedStripStreamsPresent()) {
            stripComputeUsing =
                DESIGN_PRESET_TYPES[STAGE_TYPES.STRIP][VALUE_STATUS.COMPUTE_USING].FLOWRATES;
        }

        return {
            predictIsotherm: false,
            [STAGE_TYPES.EXTRACT]: {
                compute:
                    DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE]
                        .RECOVERY_RAFFINATE,
                computeUsing: extractComputeUsing,
            },
            [STAGE_TYPES.STRIP]: {
                compute: DESIGN_PRESET_TYPES[STAGE_TYPES.STRIP][VALUE_STATUS.COMPUTE].ADVANCE,
                computeUsing: stripComputeUsing,
            },
        };
    };

    /**
     * Get the isotherm stage values from the dataset backend object to the frontend object.
     */
    getDatasetIsothermStageValues = (preset: Preset, dataset?: ?ImmutableDataset = null) => {
        // Get all of the isotherm stage values that were already saved.
        const existingValues = dataset ? dataset.get('isothermStageValues') : fromJS([]);

        // For each stage in the circuit, find either the isotherm stage value for it, or
        // Check if the presets require it to be predicted.
        // If the presets do not state to predict the isotherm, keep that value as null.
        const allValues = this.props.circuit
            .get('stages')
            .map((stage: ImmutableStage) =>
                this.getMissingIsothermStageValue(preset, stage, existingValues)
            )
            .toJS()
            .filter(Boolean); // Filter nulls out.

        return allValues;
    };

    /**
     * Get all the dataset values
     */
    getDatasetValues = (
        preset: Preset,
        datasetMode: DatasetModesConstant,
        dataset?: ?ImmutableDataset = null
    ): Array<LooseDatasetValue> => {
        const existingValues = dataset ? dataset.get('datasetValues').toJS() : [];
        const datasetValues = existingValues.concat(
            this.getMissingDatasetValues(existingValues, preset, datasetMode)
        );
        return datasetValues.sort(
            (currentDatasetValue: LooseDatasetValue, nextDatasetValue: LooseDatasetValue) =>
                DATASET_VALUE_ORDER[currentDatasetValue.valueType] -
                DATASET_VALUE_ORDER[nextDatasetValue.valueType]
        );
    };

    /**
     * Get the stage values for the current dataset.
     */
    getDatasetStageValues = (
        preset: Preset,
        datasetMode: DatasetModesConstant,
        dataset?: ?ImmutableDataset = null
    ): Array<LooseStageValue> => {
        if (!this.props.circuit) {
            throw new Error('Trying to get stage values with no circuit.');
        }
        let stageValues = [];
        this.props.circuit.get('stages').forEach((stage: ImmutableStage) => {
            const existingValues = dataset
                ? dataset
                      .get('stageValues')
                      .filter(
                          (stageValue: StageValue) => stageValue.get('stageId') === stage.get('id')
                      )
                      .toJS()
                : [];
            stageValues = stageValues.concat(
                this.getMissingStageValues(existingValues, stage, preset, datasetMode),
                existingValues.map((stageValue: StageValue) => ({
                    ...stageValue,
                    status: this.getStageValueStatus(
                        stageValue.valueType,
                        stage,
                        preset,
                        datasetMode
                    ),
                }))
            );
        });
        return stageValues.filter(
            (stageValue: LooseStageValue) => stageValue.status !== VALUE_STATUS.HIDDEN
        );
    };

    /**
     * Get the stream values for the current dataset.
     */
    getDatasetStreamValues = (
        preset: Preset,
        datasetMode: DatasetModesConstant,
        dataset?: ?ImmutableDataset = null
    ): Array<LooseStreamValue> => {
        if (!this.props.circuit) {
            throw new Error('Trying to get stream values with no circuit.');
        }
        const isothermStageValues = dataset ? dataset.get('isothermStageValues').toJS() : [];
        let streamValues = [];
        this.props.circuit.get('streams').forEach((stream: ImmutableStream) => {
            const existingValues = dataset
                ? dataset
                      .get('streamValues')
                      .filter(
                          (streamValue: StreamValue) =>
                              streamValue.get('streamId') === stream.get('id')
                      )
                      .toJS()
                : [];
            const jsStream = stream.toJS();
            streamValues = streamValues.concat(
                this.getMissingStreamValues(
                    existingValues,
                    jsStream,
                    preset,
                    datasetMode,
                    isothermStageValues
                ),
                existingValues.map((streamValue: LooseStreamValue) => ({
                    ...streamValue,
                    status: this.getStreamValueStatus(
                        streamValue.valueType,
                        jsStream,
                        preset,
                        datasetMode,
                        isothermStageValues
                    ),
                }))
            );
        });
        return streamValues.filter(
            (streamValue: LooseStreamValue) => streamValue.status !== VALUE_STATUS.HIDDEN
        );
    };

    /**
     * Given the stage value, get the status of that stage value.
     */
    getStageValueStatus = (
        valueType: StageValuesConstant,
        stage: ImmutableStage,
        preset: Preset,
        datasetMode: DatasetModesConstant
    ): ValueStatusConstant => {
        const stageType = stage.get('stageType');
        if (stageType === STAGE_TYPES.ORGANIC_TANK) {
            // All loaded organic tank values must be computed.
            // Except the first LO tank where the flowrate is used as input for the
            // total organic flowrate
            if (valueType === STAGE_VALUE_TYPES.TANK_FLOWRATE) {
                if (datasetMode === DATASET_MODES.ANALYSIS_MODE) {
                    return VALUE_STATUS.HIDDEN;
                } else {
                    if (isTankFlowrateComputeUsing(this.props.circuit, stage)) {
                        return VALUE_STATUS.COMPUTE_USING;
                    }
                    return VALUE_STATUS.COMPUTE;
                }
            }
            if (valueType === STAGE_VALUE_TYPES.TANK_CU_COMPOSITION) {
                if (datasetMode === DATASET_MODES.DESIGN_MODE) {
                    return VALUE_STATUS.COMPUTE;
                } else {
                    return VALUE_STATUS.COMPUTE_USING;
                }
            }
            throw new Error(
                `[LOTank] Unknown stage value type provided to getStageValueStatus for the LO tanks. Received: ${valueType}`
            );
        } else if (stageType === STAGE_TYPES.WASHER) {
            return (
                PRESET_STAGE_VALUE_STATUSES[stageType][datasetMode][valueType] ||
                VALUE_STATUS.HIDDEN
            );
        }
        if (datasetMode === DATASET_MODES.DESIGN_MODE) {
            const compute = preset[stageType].compute;
            const computeUsing = preset[stageType].computeUsing;
            if (computeUsing) {
                return (
                    PRESET_STAGE_VALUE_STATUSES[stageType][compute][computeUsing][valueType] ||
                    VALUE_STATUS.HIDDEN
                );
            } else {
                return (
                    PRESET_STAGE_VALUE_STATUSES[stageType][compute][valueType] ||
                    VALUE_STATUS.HIDDEN
                );
            }
        } else {
            return (
                PRESET_STAGE_VALUE_STATUSES[stageType][ANALYSIS_PRESET_TYPES.STAGE_EFFICIENCY][
                    valueType
                ] || VALUE_STATUS.HIDDEN
            );
        }
    };

    /**
     * Given the dataset value type, get the status of the value.
     */
    getDatasetValueStatus = (
        valueType: DatasetValuesConstant,
        preset: Preset,
        datasetMode: DatasetModesConstant
    ): ValueStatusConstant => {
        switch (valueType) {
            case DATASET_VALUE_TYPES.CU_TRANSFERRED: {
                if (
                    hasExtractorStages(this.props.circuit) &&
                    preset[STAGE_TYPES.EXTRACT].computeUsing ===
                        DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING]
                            .FLOWRATES
                ) {
                    return VALUE_STATUS.COMPUTE;
                }
                return VALUE_STATUS.HIDDEN;
            }
            case DATASET_VALUE_TYPES.OVERALL_RECOVERY: {
                // Hide overall recovery in analysis mode.
                if (
                    datasetMode !== DATASET_MODES.ANALYSIS_MODE &&
                    hasExtractorStages(this.props.circuit)
                ) {
                    if (
                        hasExtractorStages(this.props.circuit) &&
                        preset[STAGE_TYPES.EXTRACT].compute ===
                            DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE]
                                .REAGENT_CONCENTRATION
                    ) {
                        return VALUE_STATUS.COMPUTE_USING;
                    }
                    return VALUE_STATUS.COMPUTE;
                }
                return VALUE_STATUS.HIDDEN;
            }
            case DATASET_VALUE_TYPES.REAGENT_CONCENTRATION: {
                return this.props.circuit.get('reagent')
                    ? VALUE_STATUS.COMPUTE_USING
                    : VALUE_STATUS.HIDDEN;
            }
            case DATASET_VALUE_TYPES.OXIME_GPL:
            case DATASET_VALUE_TYPES.OXIME_RATIO: {
                return this.props.circuit.get('oxime')
                    ? VALUE_STATUS.COMPUTE_USING
                    : VALUE_STATUS.HIDDEN;
            }
            default:
                return VALUE_STATUS.HIDDEN;
        }
    };

    /**
     * Given the stream value, current preset selection and dataset mode, get the status of that stream value.
     */
    getStreamValueStatus = (
        streamValueType: StreamValuesConstant,
        stream: StreamEntity,
        preset: Preset,
        datasetMode: DatasetModesConstant,
        isothermStageValues: Array<IsothermStageValue>
    ): ValueStatusConstant => {
        const streamCircuit = stream.streamCircuit;
        const streamType = stream.streamType;
        // If there is a problem, we should just never show the stream value.
        let defaultStatus = VALUE_STATUS.HIDDEN;

        let organicKey = null;
        if (
            streamCircuit === STREAM_CIRCUITS.ORGANIC &&
            [
                STREAM_TYPES.BYPASS_FEED,
                STREAM_TYPES.BYPASS_BLEED,
                STREAM_TYPES.BYPASS_BLEND,
            ].indexOf(streamType) === -1
        ) {
            organicKey = getOrganicStreamDirectionKey(this.props.circuit, stream);
        }
        // Get all the default stream values
        let allValues = null;
        if (datasetMode === DATASET_MODES.DESIGN_MODE) {
            allValues = DEFAULT_STREAM_VALUE_STATUSES;
        } else {
            allValues = ANALYSIS_MODE_STREAM_VALUE_STATUSES;
        }
        allValues = allValues[streamType][streamCircuit];
        // If our circuit is currently organic, we have to know which organic stream we are.
        if (organicKey) {
            allValues = allValues[organicKey];
        }
        defaultStatus = allValues[streamValueType];

        if (
            streamType === STREAM_TYPES.BYPASS_BLEED &&
            streamValueType === STREAM_VALUE_TYPES.FLOWRATE_PERCENT
        ) {
            const fromStage = getStageById(this.props.circuit, stream.fromStageId);
            const toStage = getStageById(this.props.circuit, stream.toStageId);
            if (!fromStage || !toStage) {
                throw new Error('Bypass bleed without a from and to stage?');
            }
            if (
                fromStage.get('stageType') === STAGE_TYPES.ORGANIC_TANK &&
                toStage.get('stageType') === STAGE_TYPES.ORGANIC_TANK
            ) {
                if (datasetMode === DATASET_MODES.ANALYSIS_MODE) {
                    return VALUE_STATUS.HIDDEN;
                }
                return VALUE_STATUS.COMPUTE_USING;
            } else {
                return VALUE_STATUS.HIDDEN;
            }
        }

        // Check if we are using predict all isotherms, in that case, show pH and acid stream values
        // TODO: Is this needed because the below function should take care of it...
        if (
            preset.predictIsotherm &&
            (streamValueType === STREAM_VALUE_TYPES.PH ||
                streamValueType === STREAM_VALUE_TYPES.ACID)
        ) {
            defaultStatus = VALUE_STATUS.COMPUTE_USING;
        }

        // Check if we are using predicted isotherm then enable pH/Acid stream values
        if (
            stream.streamType === STREAM_TYPES.FEED &&
            (streamValueType === STREAM_VALUE_TYPES.PH ||
                streamValueType === STREAM_VALUE_TYPES.ACID)
        ) {
            const isothermStageValue = isothermStageValues.find(
                (isoStageValue: IsothermStageValue) => isoStageValue.stageId === stream.toStageId
            );
            if (
                isothermStageValue &&
                isothermStageValue.isothermMode === ISOTHERM_VALUE_MODES.PREDICT
            ) {
                defaultStatus = VALUE_STATUS.COMPUTE_USING;
            }
        }

        // Check if it's a composition on PLS continue stream. If there is an advanced stream between two stages, then hide composition and show pre-post compositions instead
        if (
            stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
            (stream.streamType === STREAM_TYPES.CONTINUE || stream.streamType === STREAM_TYPES.SKIP)
        ) {
            // Check if there are advanced streams between two same type stages
            const immutableFromStage = getStageById(this.props.circuit, stream.fromStageId);
            const immutableToStage = getStageById(this.props.circuit, stream.toStageId);
            if (immutableFromStage.get('stageType') === immutableToStage.get('stageType')) {
                const streams = this.props.circuit.get('streams').toJS();
                const stages = this.props.circuit.get('stages').toJS();
                const blendStream = getPlsBlendToStage(immutableToStage.toJS(), stages, streams);
                const bleedStream = getPlsBleedFromStage(
                    immutableFromStage.toJS(),
                    stages,
                    streams
                );

                if (datasetMode === DATASET_MODES.DESIGN_MODE) {
                    // If there is a blend between two stages(regardless with or without bleed), show pre and post compositions
                    if (blendStream) {
                        if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        } else if (
                            streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION ||
                            streamValueType === STREAM_VALUE_TYPES.POST_COMPOSITION
                        ) {
                            defaultStatus = VALUE_STATUS.COMPUTE;
                        }
                        // If there is no blend but there is a bleed, show post composition
                    } else if (bleedStream) {
                        if (
                            streamValueType === STREAM_VALUE_TYPES.COMPOSITION ||
                            streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION
                        ) {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        } else if (streamValueType === STREAM_VALUE_TYPES.POST_COMPOSITION) {
                            defaultStatus = VALUE_STATUS.COMPUTE;
                        }
                        // If there is no blend and no bleed, show only composition
                    } else {
                        if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                            defaultStatus = VALUE_STATUS.COMPUTE;
                        } else if (
                            streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION ||
                            streamValueType === STREAM_VALUE_TYPES.POST_COMPOSITION
                        ) {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        }
                    }
                } else {
                    // If there is a blend between two stages(regardless with or without bleed), show pre and post compositions
                    if (blendStream) {
                        if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        } else if (
                            streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION ||
                            streamValueType === STREAM_VALUE_TYPES.POST_COMPOSITION
                        ) {
                            defaultStatus = VALUE_STATUS.COMPUTE_USING_OPTIONAL;
                        }
                        // If there is no blend but there is a bleed, show post composition
                    } else if (bleedStream) {
                        if (
                            streamValueType === STREAM_VALUE_TYPES.COMPOSITION ||
                            streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION
                        ) {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        } else if (streamValueType === STREAM_VALUE_TYPES.POST_COMPOSITION) {
                            defaultStatus = VALUE_STATUS.COMPUTE_USING_OPTIONAL;
                        }
                        // If there is no blend and no bleed, show only composition
                    } else {
                        if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                            defaultStatus = VALUE_STATUS.COMPUTE_USING_OPTIONAL;
                        } else if (
                            streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION ||
                            streamValueType === STREAM_VALUE_TYPES.POST_COMPOSITION
                        ) {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        }
                    }
                }
            }
        }

        if (
            stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
            stream.streamType === STREAM_TYPES.CONTINUE
        ) {
            // Check if there is an advanced blend stream between two extraction stages
            const immutableFromStage = getStageById(this.props.circuit, stream.fromStageId);
            const immutableToStage = getStageById(this.props.circuit, stream.toStageId);

            if (
                immutableFromStage.get('stageType') === immutableToStage.get('stageType') &&
                immutableFromStage.get('stageType') === STAGE_TYPES.EXTRACT
            ) {
                const streams = this.props.circuit.get('streams').toJS();
                const stages = this.props.circuit.get('stages').toJS();
                const blendStream = getOrganicBlendToStage(
                    immutableToStage.toJS(),
                    stages,
                    streams
                );
                // Is there a blend in the ORGANIC circuit?
                // If there is a blend between two stages show pre and post compositions
                if (blendStream) {
                    if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                        defaultStatus = VALUE_STATUS.HIDDEN;
                    } else if (
                        streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION ||
                        streamValueType === STREAM_VALUE_TYPES.POST_COMPOSITION
                    ) {
                        if (datasetMode === DATASET_MODES.ANALYSIS_MODE) {
                            defaultStatus = VALUE_STATUS.COMPUTE_USING;
                        } else {
                            defaultStatus = VALUE_STATUS.COMPUTE;
                        }
                    }
                    // If there is no blend and no bleed, show only composition
                }
            } else if (
                immutableFromStage.get('stageType') !== immutableToStage.get('stageType') &&
                (immutableToStage.get('stageType') === STAGE_TYPES.EXTRACT ||
                    immutableToStage.get('stageType') === STAGE_TYPES.STRIP)
            ) {
                // the stream is a connector stream.
                const leftSide = Boolean(immutableToStage.get('stageType') === STAGE_TYPES.STRIP);
                const rightSide = Boolean(
                    immutableToStage.get('stageType') === STAGE_TYPES.EXTRACT
                );

                const streams = this.props.circuit.get('streams').toJS();
                const stages = this.props.circuit.get('stages').toJS();
                const organicBlend = hasOrganicBlend(streams);
                const containsExtractBypass = hasExtractBypass(streams, stages);
                const containsStripBypass = hasStripBypass(streams, stages);

                if (
                    datasetMode === DATASET_MODES.DESIGN_MODE &&
                    streamValueType === STREAM_VALUE_TYPES.FLOWRATE_PERCENT
                ) {
                    if (leftSide) {
                        if (containsStripBypass) {
                            defaultStatus = VALUE_STATUS.COMPUTE;
                        }
                    }
                    if (rightSide) {
                        if (containsExtractBypass || organicBlend) {
                            defaultStatus = VALUE_STATUS.COMPUTE;
                        }
                    }
                }
            }
            if (
                immutableFromStage.get('stageType') !== immutableToStage.get('stageType') &&
                (immutableFromStage.get('stageType') === STAGE_TYPES.EXTRACT ||
                    immutableFromStage.get('stageType') === STAGE_TYPES.STRIP)
            ) {
                // the stream is a connector stream.
                const leftSide = Boolean(
                    immutableFromStage.get('stageType') === STAGE_TYPES.EXTRACT
                );
                const rightSide = Boolean(
                    immutableFromStage.get('stageType') === STAGE_TYPES.STRIP
                );

                const streams = this.props.circuit.get('streams').toJS();
                const stages = this.props.circuit.get('stages').toJS();
                const organicBlend = hasOrganicBlend(streams);
                const containsExtractBypass = hasExtractBypass(streams, stages);
                const containsStripBypass = hasStripBypass(streams, stages);
                const goesToWasherOrLOTank =
                    immutableToStage.get('stageType') === STAGE_TYPES.WASHER ||
                    immutableToStage.get('stageType') === STAGE_TYPES.ORGANIC_TANK;

                if (streamValueType === STREAM_VALUE_TYPES.COMPOSITION) {
                    if (rightSide && !containsStripBypass && goesToWasherOrLOTank) {
                        defaultStatus = VALUE_STATUS.HIDDEN;
                    } else if (leftSide && !containsExtractBypass && goesToWasherOrLOTank) {
                        defaultStatus = VALUE_STATUS.HIDDEN;
                    } else {
                        if (datasetMode === DATASET_MODES.ANALYSIS_MODE) {
                            defaultStatus = VALUE_STATUS.COMPUTE_USING;
                        } else {
                            defaultStatus = VALUE_STATUS.COMPUTE;
                        }
                    }
                } else if (streamValueType === STREAM_VALUE_TYPES.PRE_COMPOSITION) {
                    if (leftSide) {
                        if (containsExtractBypass) {
                            if (datasetMode === DATASET_MODES.ANALYSIS_MODE) {
                                defaultStatus = VALUE_STATUS.COMPUTE_USING;
                            } else {
                                defaultStatus = VALUE_STATUS.COMPUTE;
                            }
                        } else {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        }
                    } else if (rightSide) {
                        if (containsStripBypass) {
                            if (datasetMode === DATASET_MODES.ANALYSIS_MODE) {
                                defaultStatus = VALUE_STATUS.COMPUTE_USING;
                            } else {
                                defaultStatus = VALUE_STATUS.COMPUTE;
                            }
                        } else {
                            defaultStatus = VALUE_STATUS.HIDDEN;
                        }
                    }
                }
            }
        }

        // If we want to compute the OA, then we should show the raffinate as compute_using, otherwise it is always computed.
        if (streamValueType === STREAM_VALUE_TYPES.RAFFINATE) {
            if (datasetMode === DATASET_MODES.DESIGN_MODE) {
                if (
                    preset[STAGE_TYPES.EXTRACT].compute ===
                        DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING]
                            .OA_RATIO &&
                    preset[STAGE_TYPES.EXTRACT].computeUsing ===
                        DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING]
                            .RAFFINATE
                ) {
                    // We want to compute the OA using the raffinates.
                    // Since the Compute OA option is only available on simple circuits, we do not need to check
                    // whether this stage is the last stage in a cascade.
                    // However theoretically, if this were allowed on complex circuits, this value should only be on
                    // the last stage of a cascade.
                    defaultStatus = VALUE_STATUS.COMPUTE_USING;
                } else {
                    // if the preset is not compute OA, using raffinate, then we compute the raffinate.
                    defaultStatus = VALUE_STATUS.COMPUTE;
                }
            } else {
                const stage = getStageById(this.props.circuit, stream.fromStageId);
                const stages = getStagesInCascadeForStage(this.props.circuit, stage);
                const lastStage = stages[stages.length - 1];
                // If we are in analysis mode, then the advanced bleeds are optional,
                // But the cascade terminals are mandatory.
                defaultStatus =
                    stage.get('location') === lastStage.get('location')
                        ? VALUE_STATUS.COMPUTE_USING
                        : VALUE_STATUS.COMPUTE_USING_OPTIONAL;
            }
        }

        // Hide the net transfer if the mimic diagram doesn't have a reagent.
        if (
            streamValueType === STREAM_VALUE_TYPES.NET_TRANSFER &&
            !this.props.circuit.get('reagent')
        ) {
            defaultStatus = VALUE_STATUS.HIDDEN;
        }

        if (datasetMode === DATASET_MODES.ANALYSIS_MODE) {
            if (streamValueType === STREAM_VALUE_TYPES.RECOVERY_PERCENT) {
                // in analysis mode, we should hide the recovery percent if the circuit is a complex circuit.
                if (!shouldShowLocalRecovery(this.props.circuit)) {
                    defaultStatus = VALUE_STATUS.HIDDEN;
                }
            }

            // There are no presets in analysis mode, therefore return with the values that have already
            // been modified.
            return defaultStatus;
        }

        if (!preset) {
            return defaultStatus;
        }

        // Make sure we have the proper flowrates in the aqueous phase
        if (
            streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
            streamValueType === STREAM_VALUE_TYPES.FLOWRATE &&
            preset.EXTRACT
        ) {
            if (
                preset.EXTRACT.computeUsing ===
                DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING].FLOWRATES
            ) {
                if (
                    stream.streamType === STREAM_TYPES.FEED ||
                    stream.streamType === STREAM_TYPES.BLEND
                ) {
                    defaultStatus = VALUE_STATUS.COMPUTE_USING;
                } else if (stream.streamType === STREAM_TYPES.BLEED) {
                    defaultStatus = VALUE_STATUS.COMPUTE;
                    if (this.areAdvancedExtractStreamsPresent()) {
                        const stage = getStageById(this.props.circuit, stream.fromStageId);
                        const stages = getStagesInCascadeForStage(this.props.circuit, stage);
                        const lastStage = stages[stages.length - 1];
                        defaultStatus =
                            stage.get('location') !== lastStage.get('location')
                                ? VALUE_STATUS.COMPUTE_USING
                                : VALUE_STATUS.COMPUTE;
                    }
                }
            } else {
                defaultStatus = VALUE_STATUS.HIDDEN;
            }
        }

        // Make sure we have the proper flowrates in the organic phase
        if (
            streamCircuit === STREAM_CIRCUITS.ORGANIC &&
            streamValueType === STREAM_VALUE_TYPES.FLOWRATE
        ) {
            if (
                (preset.EXTRACT &&
                    preset.EXTRACT.computeUsing ===
                        DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING]
                            .FLOWRATES) ||
                (preset.STRIP &&
                    preset.STRIP.computeUsing ===
                        DESIGN_PRESET_TYPES[STAGE_TYPES.STRIP][VALUE_STATUS.COMPUTE_USING]
                            .FLOWRATES)
            ) {
                defaultStatus = VALUE_STATUS.COMPUTE_USING;
            } else {
                defaultStatus = VALUE_STATUS.HIDDEN;
            }
        }

        // Make sure we have the proper flowrates in the electrolyte phase
        if (
            streamCircuit === STREAM_CIRCUITS.ELECTROLYTE &&
            streamValueType === STREAM_VALUE_TYPES.FLOWRATE
        ) {
            if (
                preset.STRIP.computeUsing ===
                DESIGN_PRESET_TYPES[STAGE_TYPES.STRIP][VALUE_STATUS.COMPUTE_USING].FLOWRATES
            ) {
                defaultStatus = VALUE_STATUS.COMPUTE_USING;
            } else {
                defaultStatus = VALUE_STATUS.HIDDEN;
            }
        }

        // If we want to compute the OA, then we should show the recovery as compute_using, otherwise it is always computed.
        if (streamValueType === STREAM_VALUE_TYPES.RECOVERY_PERCENT) {
            // If we want to compute the extract OA using the recovery%, then show it.
            // This has the same restrictions as the raffinate in design mode above.
            if (
                preset[STAGE_TYPES.EXTRACT].compute ===
                    DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE].OA_RATIO &&
                preset[STAGE_TYPES.EXTRACT].computeUsing ===
                    DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING]
                        .RECOVERY_PERCENT
            ) {
                defaultStatus = VALUE_STATUS.COMPUTE_USING;
            } else {
                if (
                    preset[STAGE_TYPES.EXTRACT].compute ===
                    DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE]
                        .RECOVERY_RAFFINATE
                ) {
                    const stage = getStageById(this.props.circuit, stream.fromStageId);
                    const stages = getStagesInCascadeForStage(this.props.circuit, stage);
                    const lastStage = stages[stages.length - 1];
                    defaultStatus =
                        stage.get('location') !== lastStage.get('location')
                            ? VALUE_STATUS.HIDDEN
                            : VALUE_STATUS.COMPUTE;
                } else if (
                    preset[STAGE_TYPES.EXTRACT].computeUsing ===
                    DESIGN_PRESET_TYPES[STAGE_TYPES.EXTRACT][VALUE_STATUS.COMPUTE_USING].RAFFINATE
                ) {
                    // When we want to compute OA using raffinate, recovery percent will be computed
                    defaultStatus = VALUE_STATUS.COMPUTE;
                } else {
                    defaultStatus = VALUE_STATUS.HIDDEN;
                }
            }
        }

        // Is the advance computed? Or do we use it to compute the OA ratios?
        if (streamValueType === STREAM_VALUE_TYPES.ADVANCE) {
            if (
                preset.STRIP.compute ===
                DESIGN_PRESET_TYPES[STAGE_TYPES.STRIP][VALUE_STATUS.COMPUTE].OA_RATIO
            ) {
                defaultStatus = VALUE_STATUS.COMPUTE_USING;
            } else if (
                preset.STRIP.compute ===
                DESIGN_PRESET_TYPES[STAGE_TYPES.STRIP][VALUE_STATUS.COMPUTE].ADVANCE
            ) {
                defaultStatus = VALUE_STATUS.COMPUTE;
            }
        }

        return defaultStatus;
    };

    /**
     * Given the stage values on a given stage, find the missing values for the given preset.
     */
    getMissingStageValues = (
        stageValues: Array<LooseStageValue>,
        stage: ImmutableStage,
        preset: Preset,
        datasetMode: DatasetModesConstant
    ) => {
        const stageType = stage.get('stageType');
        let allValues = null;

        if (stageType === STAGE_TYPES.ORGANIC_TANK) {
            allValues = PRESET_STAGE_VALUE_STATUSES[stageType][datasetMode];
        } else if (stageType === STAGE_TYPES.WASHER) {
            allValues = PRESET_STAGE_VALUE_STATUSES[stageType][datasetMode];
        } else if (datasetMode === DATASET_MODES.DESIGN_MODE) {
            const compute = preset[stageType].compute;
            const computeUsing = preset[stageType].computeUsing;
            if (computeUsing) {
                allValues = PRESET_STAGE_VALUE_STATUSES[stageType][compute][computeUsing];
            } else {
                allValues = PRESET_STAGE_VALUE_STATUSES[stageType][compute];
            }
        } else {
            allValues =
                PRESET_STAGE_VALUE_STATUSES[stageType][ANALYSIS_PRESET_TYPES.STAGE_EFFICIENCY];
        }

        // Get the missing values by filtering all those we already have in our values
        const missingValues = Object.keys(allValues).filter(
            (stageValueType: StageValuesConstant) =>
                !stageValues.find((value: LooseStageValue) => value.valueType === stageValueType)
        );
        return missingValues.map((valueType: StageValuesConstant) => {
            const status = this.getStageValueStatus(valueType, stage, preset, datasetMode);
            // If user has defaults enabled, use default values for strip stage and extract stage efficiencies,
            let value = null;
            if (
                valueType === STAGE_VALUE_TYPES.STAGE_EFFICIENCY &&
                status === VALUE_STATUS.COMPUTE_USING
            ) {
                let stageTypeKey = null;
                let stageTypeEnabledKey = null;
                if (stageType === STAGE_TYPES.EXTRACT) {
                    stageTypeKey = 'defaultExtractStageEfficiency';
                    stageTypeEnabledKey = 'defaultExtractStageEfficiencyEnabled';
                } else {
                    stageTypeKey = 'defaultStripStageEfficiency';
                    stageTypeEnabledKey = 'defaultStripStageEfficiencyEnabled';
                }

                value =
                    this.props.user.getIn(['preferences', 'minchem', stageTypeEnabledKey]) &&
                    this.props.user.getIn(['preferences', 'minchem', stageTypeKey]);
            }

            return {
                datasetId: this.props.dataset && this.props.dataset.get('id'),
                stageId: stage.get('id'),
                valueType,
                value,
                status,
            };
        });
    };

    /**
     * Get the missing stream values in a given stream.
     */
    getMissingStreamValues = (
        values: Array<LooseStreamValue>,
        stream: StreamEntity,
        preset: Preset,
        datasetMode: DatasetModesConstant,
        isothermStageValues: Array<IsothermStageValue>
    ): Array<LooseStreamValue> => {
        const streamCircuit = stream.streamCircuit;
        const streamType = stream.streamType;
        let organicKey = null;
        if (
            streamCircuit === STREAM_CIRCUITS.ORGANIC &&
            [
                STREAM_TYPES.BYPASS_FEED,
                STREAM_TYPES.BYPASS_BLEED,
                STREAM_TYPES.BYPASS_BLEND,
            ].indexOf(streamType) === -1
        ) {
            organicKey = getOrganicStreamDirectionKey(this.props.circuit, stream);
        }
        // Get all the default stream values
        let allValues = null;
        if (datasetMode === DATASET_MODES.DESIGN_MODE) {
            allValues = DEFAULT_STREAM_VALUE_STATUSES;
        } else {
            allValues = ANALYSIS_MODE_STREAM_VALUE_STATUSES;
        }
        allValues = allValues[streamType][streamCircuit];

        if (streamType === STREAM_TYPES.BYPASS_BLEED) {
            const fromStage = getStageById(this.props.circuit, stream.fromStageId);
            const toStage = getStageById(this.props.circuit, stream.toStageId);
            if (!fromStage || !toStage) {
                throw new Error('Bypass bleed without a from and to stage?');
            }
            if (
                fromStage.get('stageType') === STAGE_TYPES.ORGANIC_TANK &&
                toStage.get('stageType') === STAGE_TYPES.ORGANIC_TANK
            ) {
                allValues = {
                    [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.COMPUTE_USING,
                };
            }
        }

        // If our circuit is currently organic, we have to know which organic stream we are.
        if (organicKey) {
            allValues = allValues[organicKey];
        }

        // Get the missing values by filtering all those we already have in our values and check if they are enabled in user settings
        const missingValues = Object.keys(allValues).filter(
            (streamValueType: StreamValuesConstant) =>
                !values.find((value: LooseStreamValue) => value.valueType === streamValueType) &&
                isStreamValueEnabled(this.props.user, streamValueType)
        );

        return missingValues.map((streamValueType: StreamValuesConstant) => ({
            datasetId: this.props.dataset ? this.props.dataset.get('id') : null,
            streamId: stream.id,
            valueType: streamValueType,
            value: null,
            status: this.getStreamValueStatus(
                streamValueType,
                stream,
                preset,
                datasetMode,
                isothermStageValues
            ),
        }));
    };

    /**
     * Get the missing dataset values as per the preset for the current existing values.
     */
    getMissingDatasetValues = (
        existingValues: Array<LooseDatasetValue>,
        preset: Preset,
        datasetMode: DatasetModesConstant
    ): Array<LooseDatasetValue> =>
        Object.keys(DATASET_VALUE_TYPES)
            .filter(
                (valueType: string) =>
                    !existingValues.find(
                        (datasetValue: LooseDatasetValue) => datasetValue.valueType === valueType
                    ) && isStreamValueEnabled(this.props.user, valueType)
            )
            .map((valueType: DatasetValuesConstant) => ({
                valueType,
                value: null,
                datasetId: this.props.dataset ? this.props.dataset.get('id') : null,
                status: this.getDatasetValueStatus(valueType, preset, datasetMode),
            }));

    /**
     * Get the isotherm stage value for the given stage as a stage value.
     * If the presets require isotherms to be predicted, and it was not found in our array, create a new isotherm stage value.
     * Otherwise, return nothing for that stage.
     */
    getMissingIsothermStageValue = (
        preset: Preset,
        stage: ImmutableStage,
        isothermStageValues: ImmutableList<ImmutableIsothermStageValue>
    ) => {
        const firstFeedBlendStage = getFirstStageUntilNewFeedBlendStage(this.props.circuit, stage);
        const found = isothermStageValues.find(
            (isoStageValue: ImmutableIsothermStageValue) =>
                isoStageValue.get('stageId') === firstFeedBlendStage.get('id')
        );
        // We are not predicting isotherms in our preset, and the dataset did not have an isotherm saved for this stage.
        const firstStage = getFirstStageInCascadeForStage(this.props.circuit, stage);
        const enforcePredict = firstStage.get('id') !== firstFeedBlendStage.get('id');
        const mustPredict = preset.predictIsotherm;

        if (found) {
            if (mustPredict) {
                // If weve found it, but the presets require us to predict isotherms, explicitly change the isotherm stage value to it.
                return found
                    .set('isotherm', null)
                    .set('isothermId', null)
                    .set('stageId', firstFeedBlendStage.get('id'))
                    .set('isothermMode', ISOTHERM_VALUE_MODES.PREDICT)
                    .set('valueType', STAGE_VALUE_TYPES.ISOTHERM);
            }
            // Otherwise, retain the original structure of the isotherm stage value.
            return found
                .set('isothermMode', found.get('valueType'))
                .set('stageId', firstFeedBlendStage.get('id'))
                .set('valueType', STAGE_VALUE_TYPES.ISOTHERM);
        }
        // If we have not found our isotherm stage value from the backend,
        // then we must create it, if we are predicting isotherms in our preset.
        if (mustPredict) {
            return fromJS({
                datasetId: this.props.dataset && this.props.dataset.get('id'),
                isothermId: null,
                stageId: firstFeedBlendStage.get('id'),
                isotherm: null,
                isothermMode: ISOTHERM_VALUE_MODES.PREDICT,
                valueType: STAGE_VALUE_TYPES.ISOTHERM,
            });
        }
        // Then the user did not want to predict, or it is a mandatory predicted isotherm.
        return null;
    };

    /**
     * Get the preset tied to the dataset if the dataset exists.
     */
    getDatasetPreset = (dataset: ?ImmutableDataset): Preset =>
        dataset
            ? {
                  predictIsotherm: areAllStageIsothermsPredicted(this.props.circuit, dataset),
                  [STAGE_TYPES.EXTRACT]: {
                      compute: dataset.get('extractCompute'),
                      computeUsing: dataset.get('extractComputeUsing'),
                  },
                  [STAGE_TYPES.STRIP]: {
                      compute: dataset.get('stripCompute'),
                      computeUsing: dataset.get('stripComputeUsing'),
                  },
                  [STAGE_TYPES.LOADED_ORGANIC]: null,
              }
            : this.getDefaultPreset();

    /**
     * Check that all stages have all the value
     */
    areStagesComputeReady = () =>
        this.state.stageValues.every((stageValue: LooseStageValue) => {
            if (stageValue.status === VALUE_STATUS.COMPUTE_USING) {
                return stageValue.value !== null;
            }
            return true;
        });

    /**
     * Check that all streams have all the value
     */
    areStreamsComputeReady = () =>
        this.state.streamValues.every((streamValue: LooseStreamValue) => {
            if (streamValue.status === VALUE_STATUS.COMPUTE_USING) {
                // If the stream value is optional (i.e. VALUE_STATUS.COMPUTE_USING_OPTIONAL), then we do not need to verify the value.
                return streamValue.value !== null;
            }
            return true;
        });

    /**
     * Check that all stages have an isotherm assigned to them.
     */
    areIsothermsComputeReady = () =>
        this.props.circuit
            .get('stages')
            .filter((stage: ImmutableStage) =>
                STAGE_TYPES_WITH_ISOTHERMS.includes(stage.get('stageType'))
            )
            .every((stage: ImmutableStage) => {
                const firstFeedBlendStage = getFirstStageUntilNewFeedBlendStage(
                    this.props.circuit,
                    stage
                );
                return Boolean(
                    this.state.isothermStageValues.find(
                        (isothermStageValue: IsothermStageValue) =>
                            isothermStageValue.stageId === firstFeedBlendStage.get('id')
                    )
                );
            });

    /**
     * Check that all streams have all the value
     */
    areDatasetValuesComputeReady = () => {
        const allFilled = this.state.datasetValues.every((datasteValue: LooseDatasetValue) => {
            if (datasteValue.status === VALUE_STATUS.COMPUTE_USING) {
                return datasteValue.value !== null;
            }
            return true;
        });

        function isValueOk(value: LooseDatasetValue) {
            return value.status === VALUE_STATUS.COMPUTE_USING && value.value !== null;
        }

        let isConcentrationOk = false;
        const reagentConcentration = this.state.datasetValues.find(
            (datasetValue: LooseDatasetValue) =>
                datasetValue.valueType === DATASET_VALUE_TYPES.REAGENT_CONCENTRATION &&
                datasetValue.status !== VALUE_STATUS.HIDDEN
        );
        const oximeGpl = this.state.datasetValues.find(
            (datasetValue: LooseDatasetValue) =>
                datasetValue.valueType === DATASET_VALUE_TYPES.OXIME_GPL &&
                datasetValue.status !== VALUE_STATUS.HIDDEN
        );
        const oximeRatio = this.state.datasetValues.find(
            (datasetValue: LooseDatasetValue) =>
                datasetValue.valueType === DATASET_VALUE_TYPES.OXIME_RATIO &&
                datasetValue.status !== VALUE_STATUS.HIDDEN
        );
        if (reagentConcentration && !oximeGpl && !oximeRatio) {
            isConcentrationOk = isValueOk(reagentConcentration);
        }
        if (!reagentConcentration && oximeGpl && oximeRatio) {
            isConcentrationOk = isValueOk(oximeGpl) && isValueOk(oximeRatio);
        }

        return allFilled && isConcentrationOk;
    };

    /**
     * In the dataset's current state, can it be computed?
     */
    isDatasetComputeReady = () =>
        this.areStagesComputeReady() &&
        this.areStreamsComputeReady() &&
        this.areIsothermsComputeReady() &&
        this.areDatasetValuesComputeReady();

    /**
     * Check if there are advanced streams between extraction stages.
     *
     * TODO: MS-532 Remove from this container.
     */
    areAdvancedExtractStreamsPresent = () => {
        const advancedStream = this.props.circuit.get('streams').find((stream: ImmutableStream) => {
            const advancedType = getStreamAdvancedType(stream, this.props.circuit);
            const streamCircuit = stream.get('streamCircuit');
            if (
                advancedType !== ADVANCED_STREAMS_SETUP_TYPES.NONE &&
                streamCircuit === STREAM_CIRCUITS.AQUEOUS
            ) {
                return true;
            } else if (
                advancedType !== ADVANCED_STREAMS_SETUP_TYPES.NONE &&
                streamCircuit === STREAM_CIRCUITS.ORGANIC
            ) {
                // Check if it's an advanced stream in extraction section
                const fromStage = getStageById(this.props.circuit, stream.get('fromStageId'));
                const toStage = getStageById(this.props.circuit, stream.get('toStageId'));
                if (
                    fromStage.get('stageType') === STAGE_TYPES.EXTRACT &&
                    fromStage.get('location') !== 1
                ) {
                    return true;
                } else if (toStage.get('stageType') === STAGE_TYPES.EXTRACT) {
                    // If it an extraction section bypass feed if the toStage is not the last extractor
                    const numbreOfExtractors = getExtractorStages(this.props.circuit).size;
                    if (toStage.get('location') < numbreOfExtractors) {
                        return true;
                    }
                }
            }
            return false;
        });
        const streams = this.props.circuit.get('streams').toJS();
        const stages = this.props.circuit.get('stages').toJS();
        return (
            Boolean(advancedStream) || hasExtractBypass(streams, stages) || hasOrganicBlend(streams)
        );
    };

    /**
     * Check if there are advanced streams between stipping stages.
     *
     * TODO: MS-532 Remove from this container.
     */
    areAdvancedStripStreamsPresent = () => {
        const advancedStream = this.props.circuit
            .get('streams')
            .find(
                (stream: ImmutableStream) =>
                    getStreamAdvancedType(stream, this.props.circuit) !==
                        ADVANCED_STREAMS_SETUP_TYPES.NONE &&
                    stream.get('streamCircuit') === STREAM_CIRCUITS.ELECTROLYTE
            );
        return Boolean(advancedStream);
    };

    /**
     * Get the presets with which we can save/compute the dataset.
     */
    getComputePresets = () =>
        this.state.preset && {
            extractCompute: this.state.preset.EXTRACT && this.state.preset.EXTRACT.compute,
            extractComputeUsing:
                this.state.preset.EXTRACT && this.state.preset.EXTRACT.computeUsing,
            stripCompute: this.state.preset.STRIP && this.state.preset.STRIP.compute,
            stripComputeUsing: this.state.preset.STRIP && this.state.preset.STRIP.computeUsing,
        };

    /**
     * Gets the dataset structure which can be save/compute
     */
    getSaveableDataset = () =>
        this.props.computedDataset && {
            circuitId: this.props.circuit.get('id'),
            datasetMode: this.state.datasetMode,
            status: DATASET_STATUSES.CONVERGED,
            stageValues: this.props.computedDataset.get('stageValues').toJS(),
            streamValues: this.props.computedDataset.get('streamValues').toJS(),
            isothermStageValues: this.props.computedDataset.get('isothermStageValues').toJS(),
            extractCompute: this.props.computedDataset.get('extractCompute', null),
            extractComputeUsing: this.props.computedDataset.get('extractComputeUsing', null),
            stripCompute: this.props.computedDataset.get('stripCompute', null),
            stripComputeUsing: this.props.computedDataset.get('stripComputeUsing', null),
            datasetValues: this.props.computedDataset.get('datasetValues').toJS(),
        };

    /**
     * Get the isotherms that could be potentially saved if the user wanted to.
     */
    getSaveableCascadeIsotherms = () => {
        const mimicCircuit = new MimicCircuit(this.props.circuit.toJS());
        mimicCircuit.setMode(DIAGRAM_DISPLAY_MODES.COMPUTED);
        const cascades = mimicCircuit.getCascades();

        const allIsotherms = this.props.computedDataset.get('isothermStageValues');

        // Get all cascades with their isotherms
        return cascades
            .map((cascade: IMimicCascade) => {
                // Get all the stage IDs for this cascade.
                const stageId = cascade.stages[0].id;
                // Get all the isotherm for the stages, this will be used to test that all isotherms are the same.
                const isothermForStage = allIsotherms.find(
                    (isothermStageValue: ImmutableIsothermStageValue) =>
                        stageId === isothermStageValue.get('stageId') &&
                        isothermStageValue.get('valueType') === ISOTHERM_VALUE_MODES.PREDICT
                );
                if (!isothermForStage) {
                    return null; // This cascade cannot be saved because it is a selected isotherm. (or there was another problem.)
                }
                cascade.setIsotherm(isothermForStage.get('isotherm'));
                return cascade;
            })
            .filter(Boolean); // Remove nulls.
    };

    /**
     * Get the dataset that can be computed.
     */
    getComputableDataset = () => ({
        circuitId: this.props.circuit.get('id'),
        datasetMode: this.state.datasetMode,
        stageValues: this.state.stageValues
            .map((stageValue: LooseStageValue) => {
                if (stageValue.status !== VALUE_STATUS.COMPUTE_USING) {
                    // Reset the value if we computing it or its hidden.
                    stageValue.value = null;
                }
                return stageValue;
            })
            .filter((stageValue: LooseStageValue) => stageValue.status !== VALUE_STATUS.HIDDEN),
        streamValues: this.state.streamValues
            .map((streamValue: LooseStreamValue) => {
                if (
                    streamValue.status !== VALUE_STATUS.COMPUTE_USING &&
                    streamValue.status !== VALUE_STATUS.COMPUTE_USING_OPTIONAL
                ) {
                    // Reset the value if we computing it or its hidden.
                    streamValue.value = null;
                }
                return streamValue;
            })
            .filter((streamValue: LooseStreamValue) => streamValue.status !== VALUE_STATUS.HIDDEN),
        isothermStageValues: this.state.isothermStageValues.map(
            (isothermStageValue: IsothermStageValue) => ({
                stageId: isothermStageValue.stageId,
                valueType: isothermStageValue.isothermMode,
                isothermId: isothermStageValue.isotherm && isothermStageValue.isotherm.id,
            })
        ),
        ...this.getComputePresets(),
        datasetValues: this.state.datasetValues
            .map((datasetValue: LooseDatasetValue) => {
                if (datasetValue.status !== VALUE_STATUS.COMPUTE_USING) {
                    // Reset the value if we computing it or its hidden.
                    datasetValue.value = null;
                }
                return datasetValue;
            })
            .filter(
                (datasetValue: LooseDatasetValue) => datasetValue.status !== VALUE_STATUS.HIDDEN
            ),
    });

    /**
     * Append the values to the stage values.
     */
    getStageWithValues = (stage: ImmutableStage): ImmutableStage => {
        const values = this.state.stageValues.filter(
            (stageValue: LooseStageValue) => stageValue.stageId === stage.get('id')
        );
        // Add the isotherm if we are not a tank
        if (STAGE_TYPES_WITH_ISOTHERMS.includes(stage.get('stageType'))) {
            const firstFeedBlendStage = getFirstStageUntilNewFeedBlendStage(
                this.props.circuit,
                stage
            );
            const isothermValue = this.state.isothermStageValues.find(
                (isoStageValue: IsothermStageValue) =>
                    isoStageValue.stageId === firstFeedBlendStage.get('id')
            );
            if (isothermValue) {
                // TODO: isothermMode should be conserved here
                // TODO: valueType should be changed to "ISOTHERM" here.
                values.push(isothermValue);
            }
        }
        return stage.set('values', values);
    };

    /**
     * Get all the stages of the circuit with stage values attached to it.
     */
    getStagesWithValues = (): Array<Stage> =>
        this.props.circuit
            .get('stages')
            .map(this.getStageWithValues)
            .toJS();

    /**
     * Get all the streams of the circuit with stream values attached to it.
     */
    getStreamsWithValues = (): Array<StreamEntity> =>
        this.props.circuit
            .get('streams')
            .map(
                (stream: ImmutableStream) =>
                    stream.set(
                        'values',
                        this.state.streamValues &&
                            this.state.streamValues.filter(
                                (streamValue: LooseStreamValue) =>
                                    streamValue.streamId === stream.get('id')
                            )
                    )
                // .set('advancedType', getStreamAdvancedType(stream, this.props.circuit))
            )
            .toJS();

    /**
     * Set the new stage value into the stage.
     * This will either update the value of our stage, or create a new one if it doesn't exist.
     */
    setStageValue = (stageId: number, valueType: StageValuesConstant, value: ?number) =>
        this.setState((prevState: State) => {
            const filteredStageValues = prevState.stageValues.filter(
                (stageValue: LooseStageValue) =>
                    !(stageValue.stageId === stageId && stageValue.valueType === valueType)
            );
            const existingStageValue = prevState.stageValues.find(
                (stageValue: LooseStageValue) =>
                    // If this isn't the stage the value is meant to update,
                    // Then return the stage and nothing will have changed.
                    stageValue.stageId === stageId && stageValue.valueType === valueType
            );
            let stageValue = null;
            if (existingStageValue) {
                stageValue = {
                    ...existingStageValue,
                    status: VALUE_STATUS.COMPUTE_USING,
                    value,
                };
            } else {
                stageValue = {
                    datasetId: this.props.dataset && this.props.dataset.get('id'),
                    stageId,
                    valueType,
                    value,
                    status: VALUE_STATUS.COMPUTE_USING,
                };
            }
            return {
                isModified: true,
                stageValues: filteredStageValues.concat(stageValue),
            };
        });

    /**
     * Update the stream value if we have one already from the backend, otherwise create a new one.
     */
    setStreamValue = (streamId: number, valueType: StreamValuesConstant, value: ?number) =>
        this.setState((prevState: State) => {
            const filteredStreamValues = prevState.streamValues.filter(
                (streamValue: LooseStreamValue) =>
                    !(streamValue.streamId === streamId && streamValue.valueType === valueType)
            );
            const existingStreamValue = prevState.streamValues.find(
                (streamValue: LooseStreamValue) =>
                    // If this isn't the stage the value is meant to update,
                    // Then return the stage and nothing will have changed.
                    streamValue.streamId === streamId && streamValue.valueType === valueType
            );
            let streamValue = null;
            if (existingStreamValue) {
                streamValue = {
                    ...existingStreamValue,
                    value,
                };
            } else {
                streamValue = {
                    datasetId: this.props.dataset && this.props.dataset.get('id'),
                    streamId,
                    valueType,
                    value,
                    status: VALUE_STATUS.COMPUTE_USING,
                };
            }
            return {
                isModified: true,
                streamValues: filteredStreamValues.concat(streamValue),
            };
        });

    /**
     * Update our state stages and streams to reflect our changes in presets.
     * This will remove or add missing values to our stages and streams.
     */
    updateStateForPresets = (
        preset: Preset,
        datasetMode: DatasetModesConstant,
        prevState: State
    ) => {
        let stageValues = [];
        this.props.circuit.get('stages').forEach((stage: ImmutableStage) => {
            const existingValues = prevState.stageValues.filter(
                (stageValue: LooseStageValue) => stageValue.stageId === stage.get('id')
            );
            stageValues = stageValues.concat(
                this.getMissingStageValues(existingValues, stage, preset, datasetMode),
                existingValues.map((stageValue: LooseStageValue) => {
                    const newStatus = this.getStageValueStatus(
                        stageValue.valueType,
                        stage,
                        preset,
                        datasetMode
                    );
                    return {
                        ...stageValue,
                        value: newStatus === VALUE_STATUS.COMPUTE_USING ? stageValue.value : null, // Reset the value if the status is not compute using
                        status: newStatus,
                    };
                })
            );
        });

        let isothermStageValues = prevState.isothermStageValues;
        if (preset.predictIsotherm) {
            // Change all existing isotherm stage values to predict.
            isothermStageValues = isothermStageValues.map(
                (isothermStageValue: IsothermStageValue) => ({
                    ...isothermStageValue,
                    isothermId: null,
                    isotherm: null,
                    isothermMode: ISOTHERM_VALUE_MODES.PREDICT,
                })
            );

            // Map all the stages to add missing isotherm stage values to predicted mode.
            isothermStageValues = this.props.circuit
                .get('stages')
                .filter((stage: ImmutableStage) =>
                    STAGE_TYPES_WITH_ISOTHERMS.includes(stage.get('stageType'))
                )
                .map((stage: ImmutableStage) =>
                    this.getMissingIsothermStageValue(preset, stage, fromJS(isothermStageValues))
                )
                .toJS()
                .filter(Boolean);
        } else if (prevState.preset.predictIsotherm) {
            // If we went from predict all isotherms to not predict all isotherms, delete all non-mandatory predicted isotherms.
            isothermStageValues = [];
        }

        let streamValues = [];
        this.props.circuit
            .get('streams')
            .toJS()
            .forEach((stream: StreamEntity) => {
                const existingValues = prevState.streamValues.filter(
                    (streamValue: LooseStreamValue) => streamValue.streamId === stream.id
                );
                streamValues = streamValues.concat(
                    this.getMissingStreamValues(
                        existingValues,
                        stream,
                        preset,
                        datasetMode,
                        isothermStageValues
                    ),
                    existingValues.map((streamValue: LooseStreamValue) => {
                        const newStatus = this.getStreamValueStatus(
                            streamValue.valueType,
                            stream,
                            preset,
                            datasetMode,
                            isothermStageValues
                        );
                        return {
                            ...streamValue,
                            value:
                                newStatus === VALUE_STATUS.COMPUTE_USING ? streamValue.value : null, // Reset the value if the status is not compute using
                            status: newStatus,
                        };
                    })
                );
            });

        /**
         * Get the dataset values and make sure they have the proper status.
         */
        const existingDatasetValues = prevState.datasetValues.map(
            (datasetValue: LooseDatasetValue) => ({
                ...datasetValue,
                status: this.getDatasetValueStatus(datasetValue.valueType, preset, datasetMode),
            })
        );
        const datasetValues = existingDatasetValues.concat(
            this.getMissingDatasetValues(existingDatasetValues, preset, datasetMode)
        );

        return {
            isothermStageValues,
            stageValues: stageValues.filter(
                (stageValue: LooseStageValue) => stageValue.status !== VALUE_STATUS.HIDDEN
            ),
            streamValues: streamValues.filter(
                (streamValue: LooseStreamValue) => streamValue.status !== VALUE_STATUS.HIDDEN
            ),
            datasetValues: datasetValues.filter(
                (datasetValue: LooseDatasetValue) => datasetValue.status !== VALUE_STATUS.HIDDEN
            ),
        };
    };

    /**
     * Prepopulates stream inputs associated to stage custom Isotherms
     */
    setStreamValuesForSelectedIsotherm(
        selectedIsotherm: ImmutableIsotherm,
        stage: ImmutableStage,
        streamValues: Array<LooseStreamValue>
    ) {
        if (selectedIsotherm !== null) {
            const streamInputsToMap = [
                { valueType: STREAM_VALUE_TYPES.PLS, isothermKey: 'plsCu' },
                { valueType: STREAM_VALUE_TYPES.PH, isothermKey: 'plsPh' },
                { valueType: STREAM_VALUE_TYPES.SPENT, isothermKey: 'spentMetal' },
                { valueType: STREAM_VALUE_TYPES.ACID, isothermKey: 'spentAcid' },
            ];

            const looseStage = stage.toJS();
            const looseStages = this.props.circuit.get('stages').toJS();
            const looseStreams = this.props.circuit.get('streams').toJS();

            const plsFeed = getPlsFeedToStage(looseStage, looseStages, looseStreams);
            const plsBlend = getPlsBlendToStage(looseStage, looseStages, looseStreams);
            const electrolyteFeed = getElectrolyteFeedToStage(
                looseStage,
                looseStages,
                looseStreams
            );

            const stageFeedOrBlendStream = plsFeed || plsBlend || electrolyteFeed;

            if (stageFeedOrBlendStream) {
                streamInputsToMap.forEach((el) => {
                    const streamData = streamValues.find(
                        (streamVal) =>
                            streamVal.streamId === stageFeedOrBlendStream.id &&
                            streamVal.valueType === el.valueType
                    );

                    if (streamData && streamData.value === null) {
                        streamData.value = selectedIsotherm.get(el.isothermKey) || null;
                    }
                });
            }
        }

        return streamValues;
    }

    /**
     * Handles the isotherm selection from a given stage.
     */
    handleSelectIsotherm = (
        isothermMode: IsothermValueModesConstant,
        stage: ImmutableStage,
        selectedIsotherm: ?ImmutableIsotherm
    ) => {
        // if the mode is select, but we dont have an isotherm throw an error.
        if (isothermMode === ISOTHERM_VALUE_MODES.SELECT && !selectedIsotherm) {
            throw new Error('Tried to select an isotherm with no isotherm provided...');
        }

        const firstStageUntilNewFeedBlendStage = getFirstStageUntilNewFeedBlendStage(
            this.props.circuit,
            stage
        );

        const stageId = firstStageUntilNewFeedBlendStage.get('id');

        // If the stage where we selected the isotherm is an extract stage,
        // we wish to modify the aqueous circuit, otherwise the electrolyte circuit.
        const wantedStreamCircuit =
            stage.get('stageType') === STAGE_TYPES.EXTRACT
                ? STREAM_CIRCUITS.AQUEOUS
                : STREAM_CIRCUITS.ELECTROLYTE;

        // Get the wanted stream based on the wanted stream circuit.
        const wantedStream = this.props.circuit
            .get('streams')
            .find(
                (stream: ImmutableStream) =>
                    stream.get('toStageId') === stageId &&
                    stream.get('streamCircuit') === wantedStreamCircuit &&
                    (stream.get('streamType') === STREAM_TYPES.FEED ||
                        stream.get('streamType') === STREAM_TYPES.BLEND)
            );

        // If in extract, modify the PH, in electrolyte modify the acid.
        const wantedStreamValueType =
            stage.get('stageType') === STAGE_TYPES.EXTRACT
                ? STREAM_VALUE_TYPES.PH
                : STREAM_VALUE_TYPES.ACID;

        // Update the state with our new isotherm.
        this.setState(
            (prevState: State) => {
                // Update the isotherm stage values.
                const isothermStageValues = prevState.isothermStageValues
                    .filter(
                        (isoStageValue: IsothermStageValue) => isoStageValue.stageId !== stageId
                    )
                    // But add in our updated isotherm selection to all the stages in our cascade.
                    .concat({
                        stageId,
                        isothermId: selectedIsotherm && selectedIsotherm.get('id'),
                        isotherm: selectedIsotherm && selectedIsotherm.toJS(),
                        valueType: STAGE_VALUE_TYPES.ISOTHERM,
                        isothermMode,
                    });

                let predictAllIsotherm = Boolean(
                    prevState.preset && prevState.preset.predictIsotherm
                );

                predictAllIsotherm = isothermStageValues.every(
                    (isoStageValue: IsothermStageValue) =>
                        isoStageValue.isothermMode === ISOTHERM_VALUE_MODES.PREDICT
                );

                const streamValues = this.setStreamValuesForSelectedIsotherm(
                    selectedIsotherm,
                    firstStageUntilNewFeedBlendStage,
                    prevState.streamValues
                );

                return {
                    isModified: true,
                    preset: {
                        ...prevState.preset,
                        predictIsotherm: predictAllIsotherm,
                    },
                    isothermStageValues,
                    streamValues,
                };
            },
            () => {
                this.handleHideModal();
                // Since we potentially change the predict isotherm sidebar option, we must rerender the sidebar.
                this.props.handleSidebarContent(this.renderSidebar());
            }
        );
    };

    /**
     * When Generate Diagram button clicked
     */
    handleGenerateDiagramClicked = (sidebarState: MimicSidebarState) => {
        let preset = null;
        if (sidebarState.datasetMode === DATASET_MODES.DESIGN_MODE) {
            // Update state values
            const extractPreset = hasExtractorStages(this.props.circuit)
                ? {
                      compute: sidebarState.activeExtractType,
                      computeUsing: sidebarState.activeExtractSubtype,
                  }
                : null;
            const stripPreset = hasStripperStages(this.props.circuit)
                ? {
                      compute: sidebarState.activeStripType,
                      computeUsing: sidebarState.activeStripSubtype,
                  }
                : null;
            preset = {
                predictIsotherm: sidebarState.predictAllIsotherms,
                EXTRACT: extractPreset,
                STRIP: stripPreset,
            };
            if (!isValidPreset(this.props.circuit, preset)) {
                throw new Error('Invalid preset combination selected.');
            }
        } else {
            // analysis mode:
            preset = {
                predictIsotherm: sidebarState.predictAllIsotherms,
                EXTRACT: ANALYSIS_PRESET_TYPES.STAGE_EFFICIENCY,
                STRIP: ANALYSIS_PRESET_TYPES.STAGE_EFFICIENCY,
            };
        }

        this.setState(
            (prevState: State) => ({
                datasetMode: sidebarState.datasetMode,
                generateDiagram: true,
                viewComputedDataset: false,
                preset,
                cascadeRelaxationFactor: DEFAULT_CASCADE_RELAXATION_FACTOR,
                returnRelaxationFactor: DEFAULT_RETURN_RELAXATION_FACTOR,
                ...this.updateStateForPresets(preset, sidebarState.datasetMode, prevState),
            }),
            () => this.props.handleSidebarContent(this.renderSidebar())
        );
    };

    /**
     * When the user confirms the mode. (confirms design mode by clicking "Next" butotn)
     */
    handleConfirmMode = (datasetMode: DatasetModesConstant) =>
        this.setState(
            (prevState: State) => ({
                datasetMode,
                generateDiagram: datasetMode ? prevState.generateDiagram : false, // ungenerate diagram when return is clicked.
            }),
            () => this.props.handleSidebarContent(this.renderSidebar())
        );

    /**
     * When Clear All button clicked
     */
    handleClearAllClick = () =>
        this.setState((prevState: State) => ({
            stageValues: prevState.stageValues.map((stageValue: LooseStageValue) => ({
                ...stageValue,
                value: null,
            })),
            streamValues: prevState.streamValues.map((streamValue: LooseStreamValue) => ({
                ...streamValue,
                value: null,
            })),
            isModified: true,
        }));

    /**
     * When Compute button clicked
     */
    handleComputeClicked = () =>
        this.props.computeDataset(
            this.getComputableDataset(),
            this.state.cascadeRelaxationFactor,
            this.state.returnRelaxationFactor
        );

    /**
     * When copy to clipboard button clicked
     */
    handleCopyToClipboardClick = () => {
        this.setState(
            {
                openedExportFixedContainer: true,
            },
            () =>
                nodeToClipboard(
                    this.exportTarget.current,
                    (feedbackType: FeedbackType, messageId: string) =>
                        this.props.newFeedback(
                            feedbackType,
                            this.props.intl.formatMessage({ id: messageId })
                        ),
                    () => this.setState({ openedExportFixedContainer: false })
                )
        );
    };

    /**
     * When save button clicked
     */
    handleSaveClick = () =>
        this.setState({
            openedModal: {
                type: 'DATASET_SAVE',
            },
        });

    /**
     * When save as button clicked
     */
    handleSaveAsClick = () =>
        this.setState({
            openedModal: {
                type: 'DATASET_SAVE_AS',
            },
        });

    /**
     * When the isotherm + icon is clicked, open the modal here.
     */
    handleOpenIsothermSelectModal = (stageId: number) =>
        this.setState({
            openedModal: {
                type: 'ISOTHERM_SELECT',
                stage: this.getStageWithValues(
                    this.props.circuit
                        .get('stages')
                        .find((stage: ImmutableStage) => stage.get('id') === stageId)
                ),
            },
        });

    /**
     * Common function that hides any active modals
     */
    handleHideModal = () =>
        this.setState({ openedModal: null }, () => document.activeElement.blur());

    /**
     * Handles the convergence compute button clicked in the circuit convergence modal.
     */
    handleConvergenceCompute = (cascadeRelaxationFactor: number, returnRelaxationFactor: number) =>
        this.setState(
            {
                cascadeRelaxationFactor,
                returnRelaxationFactor,
            },
            () =>
                this.props.computeDataset(
                    this.getSaveableDataset(),
                    this.state.cascadeRelaxationFactor,
                    this.state.returnRelaxationFactor
                )
        );

    /**
     * When the save dataset button is clicked in the save dataset modal
     */
    handleSaveDatasetConfirm = (saveAs: boolean = false) => (
        saveDataset: boolean,
        datasetName: string,
        cascades: Array<IMimicCascade>
    ) => {
        if (!(this.props.computedDataset && this.state.viewComputedDataset)) {
            return;
        }

        const dataset = this.getSaveableDataset();
        if (!dataset) return;
        if (!saveDataset) {
            this.handleSaveIsotherms(dataset, cascades);
        } else {
            this.handleSaveEntireDataset(saveAs, datasetName, dataset, cascades);
        }
    };

    /**
     * When the save dataset button is clicked in the save dataset modal and entire dataset checked
     */
    handleSaveEntireDataset = (
        saveAs: boolean,
        datasetName: string,
        dataset: ImmutableDataset,
        cascades: Array<IMimicCascade>
    ) => {
        dataset.name = datasetName;

        // Filter out all the isotherm stage values that the user wants to save
        // Because the backend will create those isothermStageValues.
        dataset.isothermStageValues = dataset.isothermStageValues.map(
            (isoStageVal: IsothermStageValue) => {
                const mustSaveCascade = cascades.find(
                    (cascade: IMimicCascade) => cascade.stages[0].id === isoStageVal.stageId
                );
                let isotherm = isoStageVal.isotherm;
                if (!mustSaveCascade) {
                    if (isoStageVal.valueType === ISOTHERM_VALUE_MODES.PREDICT) {
                        isotherm = null;
                    }
                    return {
                        ...isoStageVal,
                        isotherm,
                    };
                }
                isotherm.name = mustSaveCascade.name;
                return {
                    ...isoStageVal,
                    // A predicted isotherm becomes a selected, but predicted isotherm here.
                    valueType: ISOTHERM_VALUE_MODES.SELECT,
                    isotherm, // Pass in the name of the isotherm.
                };
            }
        );

        if (this.props.dataset && !saveAs) {
            this.props.updateDataset(this.props.dataset.get('id'), dataset);
            return;
        }
        this.props.createDataset(dataset);
    };

    /**
     * When the save dataset button is clicked in the save dataset modal  and entire dataset unchecked
     */
    handleSaveIsotherms = (dataset: ImmutableDataset, cascades: Array<IMimicCascade>) => {
        const isotherms = dataset.isothermStageValues
            .map((isoStageVal: IsothermStageValue) => {
                const mustSaveCascade = cascades.find(
                    (cascade: IMimicCascade) => cascade.stages[0].id === isoStageVal.stageId
                );
                if (!mustSaveCascade) {
                    return null;
                }

                const isotherm = isoStageVal.isotherm;
                isotherm.name = mustSaveCascade.name;
                return isotherm;
            })
            .filter(Boolean); // Remove null isotherms (they shouldn't be saved.)
        // no update isotherm?
        if (isotherms && isotherms.length >= 1) {
            this.props.createManyIsotherms(isotherms);
        }
    };

    /**
     * When the user is viewing a computed dataset and they now want to
     * view the mimic diagram with the input boxes (pre-computed view)
     */
    handleBackToEditClick = () =>
        this.setState({
            viewComputedDataset: false,
            openedModal: null,
            cascadeRelaxationFactor: DEFAULT_CASCADE_RELAXATION_FACTOR,
            returnRelaxationFactor: DEFAULT_RETURN_RELAXATION_FACTOR,
        });

    /**
     * Handles the change in circuit values (i.e. the boxes in the header of the mimic diagram.)
     */
    handleDatasetValueChange = (valueType: DatasetValuesConstant, value: number) =>
        this.setState((prevState: State) => {
            const filteredValues = prevState.datasetValues.filter(
                (datasetValue: LooseDatasetValue) => datasetValue.valueType !== valueType
            );
            const existingValue = prevState.datasetValues.find(
                (datasetValue: LooseDatasetValue) => datasetValue.valueType === valueType
            );
            let datasetValue = null;
            if (existingValue) {
                datasetValue = {
                    ...existingValue,
                    value,
                };
            } else {
                datasetValue = {
                    valueType,
                    value,
                    status: VALUE_STATUS.COMPUTE_USING,
                };
            }
            return {
                isModified: true,
                datasetValues: filteredValues.concat(datasetValue),
            };
        });

    renderSidebar = (): SidebarRenderer => ({
        sidebarType: 'TAB',
        node: (
            <MimicSidebarSection
                hasExtractors={hasExtractorStages(this.props.circuit)}
                hasStrippers={hasStripperStages(this.props.circuit)}
                dataset={this.props.computedDataset || this.props.dataset}
                circuit={this.props.circuit}
                isDisabled={this.props.loadingComputation}
                isGenerated={this.state.generateDiagram}
                loadingCircuit={this.props.loadingCircuitOrDataset}
                areAdvancedExtractStreams={this.areAdvancedExtractStreamsPresent()}
                areAdvancedStripStreams={this.areAdvancedStripStreamsPresent()}
                datasetMode={this.state.datasetMode}
                displayedDiagramPreset={this.state.preset}
                onHandleGenerateDiagramClicked={this.handleGenerateDiagramClicked}
                onHandleConfirmMode={this.handleConfirmMode}
            />
        ),
    });

    renderModal = () => {
        if (!this.state.openedModal) return null;

        switch (this.state.openedModal.type) {
            case 'ISOTHERM_SELECT': {
                return (
                    <IsothermSelectModal
                        stage={this.state.openedModal.stage}
                        mimicCircuit={new MimicCircuit(this.props.circuit.toJS())}
                        isotherms={this.props.isotherms}
                        loadingIsotherms={this.props.loadingIsotherms}
                        onSelectClicked={this.handleSelectIsotherm}
                        onCancel={this.handleHideModal}
                    />
                );
            }
            case 'CIRCUIT_CONVERGENCE': {
                return (
                    <CircuitConvergenceModal
                        errors={this.props.datasetErrors}
                        loading={this.props.loadingComputation}
                        onConfirm={this.handleConvergenceCompute}
                        onCancel={this.handleHideModal}
                        selectedCascadeRelaxationFactor={this.state.cascadeRelaxationFactor}
                        selectedReturnRelaxationFactor={this.state.returnRelaxationFactor}
                    />
                );
            }
            case 'DATASET_SAVE': {
                return (
                    <DatasetSaveModal
                        loading={
                            this.props.datasetIsCreating ||
                            this.props.datasetIsUpdating ||
                            this.props.isothermIsCreating
                        }
                        cascades={this.getSaveableCascadeIsotherms()}
                        dataset={this.props.dataset}
                        onConfirm={this.handleSaveDatasetConfirm()}
                        onCancel={this.handleHideModal}
                    />
                );
            }
            case 'DATASET_SAVE_AS': {
                return (
                    <DatasetSaveModal
                        loading={
                            this.props.datasetIsCreating ||
                            this.props.datasetIsUpdating ||
                            this.props.isothermIsCreating
                        }
                        cascades={this.getSaveableCascadeIsotherms()}
                        dataset={this.props.dataset}
                        onConfirm={this.handleSaveDatasetConfirm(true)}
                        onCancel={this.handleHideModal}
                        saveAs
                    />
                );
            }
            default:
                return null;
        }
    };

    renderMimicDiagram = (fullDisplay: boolean = false) => {
        if ((this.props.dataset || this.props.computedDataset) && this.state.viewComputedDataset) {
            const dataset =
                this.props.isComputeCompleted && this.props.isDatasetSaved
                    ? this.props.dataset
                    : this.props.computedDataset || this.props.dataset; // Computed datasets take precedence on saved dataset.

            const stages = this.props.circuit
                .get('stages')
                .map((stage: ImmutableStage) => {
                    let stageValues = dataset
                        .get('stageValues')
                        .filter(
                            (stageValue: StageValue) =>
                                stageValue.get('stageId') === stage.get('id')
                        );

                    if (STAGE_TYPES_WITH_ISOTHERMS.includes(stage.get('stageType'))) {
                        // Add the isotherm as a stage value
                        const firstStage = getFirstStageUntilNewFeedBlendStage(
                            this.props.circuit,
                            stage
                        );
                        let isotherm = dataset
                            .get('isothermStageValues')
                            .find(
                                (isothermStageValue: IsothermStageValue) =>
                                    isothermStageValue.get('stageId') === firstStage.get('id')
                            );
                        if (isotherm) {
                            isotherm = isotherm
                                .set('isothermMode', isotherm.get('valueType'))
                                .set('valueType', STAGE_VALUE_TYPES.ISOTHERM);
                            stageValues = stageValues.push(isotherm);
                        }
                    }
                    return stage.set('values', stageValues);
                })
                .toJS();
            const streams = this.props.circuit.get('streams').toJS();
            return (
                <MimicDiagram
                    displayMode={DIAGRAM_DISPLAY_MODES.COMPUTED}
                    datasetMode={dataset.get('datasetMode')}
                    fullDisplay={fullDisplay}
                    loadingCircuit={false}
                    stages={stages}
                    streams={streams}
                    streamValues={dataset.get('streamValues').toJS()}
                    circuit={this.props.circuit}
                    datasetValues={dataset.get('datasetValues').toJS()}
                    circuitUnits={this.props.circuit.get('circuitUnits')}
                    renderFooter={this.renderFooter}
                />
            );
        } else {
            return (
                <MimicDiagram
                    displayMode={DIAGRAM_DISPLAY_MODES.COMPUTE}
                    datasetMode={this.state.datasetMode}
                    fullDisplay={fullDisplay}
                    loadingCircuit={this.props.loadingCircuitOrDataset}
                    stages={this.getStagesWithValues()}
                    streams={this.props.circuit.get('streams').toJS()}
                    streamValues={this.state.streamValues}
                    setStageValue={this.setStageValue}
                    setStreamValue={this.setStreamValue}
                    onOpenIsothermSelectModal={this.handleOpenIsothermSelectModal}
                    handleOpenModal={this.state.openedModal}
                    circuit={this.props.circuit}
                    datasetValues={this.state.datasetValues}
                    onDatasetValueChange={this.handleDatasetValueChange}
                    circuitUnits={this.props.circuit.get('circuitUnits')}
                    renderFooter={this.renderFooter}
                />
            );
        }
    };

    renderFooter = () => {
        const isSaveable = Boolean(
            this.props.computedDataset &&
                this.state.viewComputedDataset &&
                (!this.props.datasetErrors.has('converged') ||
                    this.props.datasetErrors.get('converged'))
        );

        return (
            <CircuitComputeFooter
                mode={this.state.viewComputedDataset ? 'POST_COMPUTED' : 'PRE_COMPUTED'}
                onBackToEditClicked={this.handleBackToEditClick}
                onClearAllClicked={this.handleClearAllClick}
                onComputeClicked={this.handleComputeClicked}
                onCopyToClipboardClicked={this.handleCopyToClipboardClick}
                onSaveAsClicked={this.handleSaveAsClick}
                onSaveClicked={this.handleSaveClick}
                computeDisabled={!this.state.viewComputedDataset && !this.isDatasetComputeReady()}
                loadingComputation={this.props.loadingComputation}
                saveDisabled={!isSaveable}
                saveAsDisabled={!isSaveable || !this.props.dataset}
            />
        );
    };

    render() {
        // Did the user ask the diagram to be generated?
        if (!this.state.generateDiagram) {
            if (this.state.datasetMode === DATASET_MODES.DESIGN_MODE) {
                // They've selected design mode, but didn't generate.
                return <ComputationInstructions messageId="Mimic.selectDesignOptions" />;
            } else {
                // They've selected analysis mode, but didn't generate.
                return <ComputationInstructions messageId="Mimic.selectMode" />;
            }
        }

        const isSaveable = Boolean(
            this.props.computedDataset &&
                this.state.viewComputedDataset &&
                this.props.datasetErrors?.get('converged')
        );

        return (
            <MimicDiagramErrorBoundary>
                <HeaderOverflowContainer>
                    {this.renderMimicDiagram()}
                    <OverflowEnd>{this.renderFooter()}</OverflowEnd>
                </HeaderOverflowContainer>
                {this.state.openedExportFixedContainer && (
                    <ExportFixedContainer ref={this.exportTarget}>
                        {this.renderMimicDiagram(this.state.openedExportFixedContainer)}
                    </ExportFixedContainer>
                )}
                {this.state.openedModal && this.renderModal()}
                <PreventNavigationPrompt shouldBlock={this.state.isModified || isSaveable} />
            </MimicDiagramErrorBoundary>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        loadingIsotherms: selectIsothermsAreFetching(),
        isotherms: selectAllIsotherms(),
        loadingComputation: selectDatasetIsComputing(),
        datasetErrors: selectDatasetErrors(),
        computedDataset: selectComputedDataset(),
        datasetIsCreating: selectDatasetsAreCreating(),
        datasetIsUpdating: selectDatasetsAreUpdating(),
        isothermIsCreating: selectIsothermsAreCreating(),
        user: selectUser(),
        isDatasetSaved: selectIsDatasetSaved(),
        isComputeCompleted: selectIsComputeCompleted(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllIsotherms,
            createManyIsotherms,
            computeDataset,
            createDataset,
            updateDataset,
            newFeedback,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(MimicContainer))
);
