// @flow strict

import React from 'react';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { PrimaryButton, Loader } from 'components/_ReactUI_V1';

import { areObjectsDifferent } from 'utils/helpers';

// Styles
import { Content, Footer, Field, FieldHeader, LoaderWrapper } from './styles';

// Components
import { Title } from 'styles/common';
import UserPhone from './UserPhone';
import UserNotificationSchedule from './UserNotificationSchedule';

// Types
import type { IntlType, ErrorType, ReduxDispatch } from 'types';
import type {
    ImmutableNotificationSchedule,
    NotificationScheduleDay,
} from 'services/Notifications/types';

import type { ImmutableUser } from 'services/Authentication/types';

// Constants
import { DEFAULT_NOTIFICATION_SCHEDULE } from 'utils/constants';

// Service
import {
    fetchNotificationSchedule,
    updateNotificationSchedule,
} from 'services/Notifications/thunks';
import {
    selectNotificationScheduleIsFetching,
    selectNotificationScheduleIsUpdating,
    selectErrors,
    selectNotificationSchedule,
} from 'services/Notifications/selectors';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
    isFetching: boolean,
    isUpdating: boolean,
    errors: ErrorType,
    schedule: ImmutableNotificationSchedule,
    fetchNotificationSchedule: (userId: number) => void,
    updateNotificationSchedule: (userId: number, schedule: ImmutableNotificationSchedule) => void,
};

type State = {
    notificationSchedule: ImmutableNotificationSchedule,
};

class UserNotificationGeneralSettings extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            schedule: this.props.schedule,
        };
    }

    componentDidMount() {
        if (this.props.schedule.isEmpty()) {
            this.props.fetchNotificationSchedule(this.props.user.get('id'));
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isFetching && !this.props.isFetching && this.props.errors.isEmpty()) {
            this.setState({ schedule: this.props.schedule });
        }
    }

    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.UserSettings.NotificationSettings.${key}`,
            },
            data
        );

    isModified = (): boolean => {
        const initialSchedule = this.props.schedule.toJS();
        const updatedSchedule = this.state.schedule.toJS();
        return areObjectsDifferent(initialSchedule, updatedSchedule);
    };

    handleScheduleToggleClicked = (dayName: string) => {
        const scheduleDay: NotificationScheduleDay = {
            day: dayName,
            startingTime: DEFAULT_NOTIFICATION_SCHEDULE.STARTING_TIME,
            endingTime: DEFAULT_NOTIFICATION_SCHEDULE.ENDING_TIME,
        };
        const updatedSchedule = [...this.state.schedule.toJS()];
        const index = updatedSchedule.findIndex(
            (el: NotificationScheduleDay) => el.day === dayName
        );

        if (index > -1) {
            updatedSchedule.splice(index, 1);
        } else {
            updatedSchedule.push(scheduleDay);
        }

        this.setState({ schedule: fromJS(updatedSchedule) });
    };

    handleScheduleTimeChange = (dayName: string, timeKey: string, timeValue: string) => {
        const updatedSchedule = this.state.schedule.update(
            this.state.schedule.findIndex(
                (el: NotificationScheduleDay) => el.get('day') === dayName
            ),
            (el: NotificationScheduleDay) => el.set(timeKey, `${timeValue}:00`)
        );

        this.setState({ schedule: updatedSchedule });
    };

    handleSaveClicked = () => {
        this.props.updateNotificationSchedule(this.props.user.get('id'), this.state.schedule);
    };

    render() {
        return (
            <React.Fragment>
                <Content>
                    <Title>{this.getTranslation('title')}</Title>

                    <Field>
                        <FieldHeader>{this.getTranslation('notificationPhoneTitle')}</FieldHeader>

                        <UserPhone user={this.props.user} />
                    </Field>

                    {this.props.isFetching && (
                        <LoaderWrapper>
                            <Loader
                                title={this.props.intl.formatMessage({
                                    id: 'components.AppLoader.loaderAlt',
                                })}
                                loading
                            />
                        </LoaderWrapper>
                    )}

                    {!this.props.isFetching && (
                        <React.Fragment>
                            <Field>
                                <FieldHeader>
                                    {this.getTranslation('notificationScheduleTitle')}
                                </FieldHeader>
                            </Field>
                            <UserNotificationSchedule
                                schedule={this.state.schedule}
                                onToggleScheduleDay={this.handleScheduleToggleClicked}
                                onChangeScheduleDayTime={this.handleScheduleTimeChange}
                            />
                        </React.Fragment>
                    )}
                </Content>
                <Footer>
                    <PrimaryButton
                        text={this.getTranslation('saveButtonText')}
                        loading={this.props.isUpdating}
                        onClick={this.handleSaveClicked}
                        disabled={!this.isModified() || this.props.isFetching}
                    />
                </Footer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isFetching: selectNotificationScheduleIsFetching(),
    isUpdating: selectNotificationScheduleIsUpdating(),
    errors: selectErrors(),
    schedule: selectNotificationSchedule(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchNotificationSchedule,
            updateNotificationSchedule,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(UserNotificationGeneralSettings));
